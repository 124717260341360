export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/sample-data-detail',
            name: 'admin-sample-data-detail',
            component: () => import(/* webpackChunkName: "page-admin-default-sample-data-detail" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `示例数据详情`,
                },
                entity_list_for_breadcrumb: [
                    {name: `示例功能`},
                    {name: `示例数据`},
                    {name: `示例数据详情`},
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        // if (!pParameter) pParameter = {};
                        // const all_route_list = pParameter.all_route_list;
                        return `admin-sample-data-list`;
                    },
                },
            },
        },
    ],
};

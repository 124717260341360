// /consumer/web/company/getCompanyInsurance
// consumer_web_company_getCompanyInsurance
const __request = require(`./__request/__request_contentType_json`);
const consumer_web_company_getCompanyInsurance = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/company/getCompanyInsurance',
        data: pParameter,
        // check_token: false
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_company_getCompanyInsurance;
export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/hotel-order/hotel-merger-list',
            name: 'hotel-merger-list',
            component: () => import(/* webpackChunkName: "page-admin-order-management-hotel-hotel-merger-list" */ `./1.2.4/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `已销售的酒店订单`,
                },
                entity_list_for_breadcrumb: [
                    { name: `酒店订单` },
                    { name: `已销售的酒店订单` },
                ],
            },
        },
    ],
};

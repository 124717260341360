import router from '@/router'

const redirect_to_login_page = function (pParameter) {
    router.push({
        name: `login`,
    });
    return;

    const environment = global.appConfig.environment;
    if (environment === `alpha`) {
        router.push({
            name: `login`,
        });
        return;
    }

    const login_page_url = global.appConfig.login_page_url;
    global.location.replace(login_page_url);
};

export default redirect_to_login_page;

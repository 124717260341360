// consumer_web_company_addkeyacDetails
const __request = require(`./__request/__request_contentType_json`);
const consumer_web_company_addkeyacDetails = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/web/company/addkeyacDetails',
    data: pParameter
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_web_company_addkeyacDetails;

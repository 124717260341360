const __request = require(`./__request/__request_contentType_json`)
const consumer_flight_report_order_addExpressRecordList = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/flight/report/order/addExpressRecordList',
        data: data
    };
    return __request(pParameter)
};
export default consumer_flight_report_order_addExpressRecordList

export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/offline-car-create-order-pay',
            name: 'admin-offline-car-create-order-pay',
            component: () => import(/* webpackChunkName: "page-admin-order-management-car-offline-car-create-order-pay" */ `./1.0.0/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `确认页面`,
                },
                entity_list_for_breadcrumb: [
                    {name: `线下用车管理`},
                    {name: `确认页面`},
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        return `admin-offline-car-order-list`;
                    },
                },
            },
        },
    ],
};

import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'
import queryUtil from 'yinzhilv-js-sdk/frontend/common-project/lib/util/query_filter_util.js';
import excelUtil from 'yinzhilv-js-sdk/frontend/common-project/lib/util/excel_util.js';

import reportRequest from '@/lib/data-service/report/report_hotel_request'

import moment from "moment";
import { awaitWrap } from '@/page/admin/replace-orders/travel-reserve/common/unit';

export default {
    data() {
        return {
            loading: false,
            sourceTypeList:[
                {value: null, label: '全部'},
                {value: 1, label: '国内'},
                {value: 2, label: '国际'},
            ],
            searchForm: {
                modifiedTimeStart: null,// 操作日期-开始
                modifiedTimeEnd: null,// 操作日期-结束
                orderNo: null,// 订单号
                sourceType: null,// 机票类型
                operationUser: null,// 操作客服
            },
            pageBean: {
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            },
            totalCount: 0,   //总条数
            list: [],
            modifiedTime: this.initModifiedTime()
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    activated() {
        this.init()
    },
    methods: {
        //初始化
        async init() {
            this.changeModifiedTimeArr(this.modifiedTime);
            await this.getList();
        },
        initModifiedTime() {
            let arr = [];
            arr[0] = moment().subtract(7, 'days').format('YYYY-MM-DD');
            arr[1] = moment().format('YYYY-MM-DD');
            return arr;
        },
        //搜索
        search() {
            this.pageBean.currentPage = 1
            this.getList()
        },
        //重置
        reset() {
            let searchForm = {
                modifiedTimeStart: null,// 操作日期-开始
                modifiedTimeEnd: null,// 操作日期-结束
                orderNo: null,// 订单号
                sourceType: null,// 机票类型
                operationUser: null,// 操作客服
            };
            this.searchForm = searchForm;
            this.totalCount = 0;
            this.search()
        },
        getQueryParams() {
            let params = queryUtil.getBaseParams(this.pageBean,null,null,null);
            if (this.searchForm.modifiedTimeStart) {
                params.querys.push(queryUtil.createQueryParams("operation_time",this.searchForm.modifiedTimeStart,queryUtil.QueryOP.GE))
            }
            if (this.searchForm.modifiedTimeEnd) {
                params.querys.push(queryUtil.createQueryParams("operation_time",this.searchForm.modifiedTimeEnd,queryUtil.QueryOP.LE))
            }
            if (this.searchForm.orderNo) {
                params.querys.push(queryUtil.createQueryParams("order_no",this.searchForm.orderNo))
            }
            if (this.searchForm.sourceType) {
                params.querys.push(queryUtil.createQueryParams("source_type",this.searchForm.sourceType))
            }
            if (this.searchForm.operationUser) {
                params.querys.push(queryUtil.createQueryParams("operation_user",this.searchForm.operationUser,queryUtil.QueryOP.LK))
            }
            return params;
        },
        //获取列表
        getList() {
            this.loading = true
            this.list = []

            reportRequest.report_page_list(this.getQueryParams()).then(res => {
                this.loading = false
                this.list = res.datas.list
                this.totalCount = res.datas.totalCount
            }).catch(() => {
                this.loading = false
                this.list = []
            })
        },
        //导出账单
        async exportExcel() {
            this.loading = true
            const param = this.getQueryParams();
            param.pageBean.currentPage = -1;
            param.pageBean.pageSize = -1;
            let [err, res] = await awaitWrap(reportRequest.report_page_list(param))
            if (err) {
                this.loading = false
                return
            }
            let arr = [];
            let h = [
                '订单号'
                , '酒店类型'
                , '客人名称'
                , '酒店名称'
                , '房型'
                , '入住时间'
                , '离店时间'
                , '入住天数'
                , '订单来源'
                , '订单状态'
                , '订单金额'
                , '采购价'
                , '利润'
                , '操作客服'
                , '操作日期'
                , '客户名称'
                , '备注'
            ]
            let data = res.datas.list
            arr.push(h);
            if (data && data.length>0) {
                for (const item of data) {
                    arr.push([
                        item.orderNo
                        , item.sourceTypeText
                        , item.psgName
                        , item.hotelName
                        , item.hotelRoomTypeName
                        , item.checkInDate
                        , item.checkOutDate
                        , item.stayNight
                        , item.soureName
                        , item.orderStatusText
                        , item.orderAmount
                        , item.supPrice
                        , item.profits
                        , item.operationUser
                        , item.operationTime
                        , item.companyName
                        , item.remark
                    ])
                }
            }
            excelUtil.exportToExcel('客服酒店报表', arr);
            this.loading = false
        },
        //搜索出票日期改变时
        changeModifiedTimeArr (val) {
            if (val === '' || val === null) {
                this.searchForm.modifiedTimeStart = null
                this.searchForm.modifiedTimeEnd = null
            } else {
                this.searchForm.modifiedTimeStart = val[0] + ' 00:00:00'
                this.searchForm.modifiedTimeEnd = val[1] + ' 23:59:59'
            }
        }
    }
}
import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'
import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'

// 开票方式类型枚举接口
import consumer_admin_hotel_order_queryHotelOrderListByIfInvoice from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_queryHotelOrderListByIfInvoice'
import consumer_admin_hotel_order_getHotelBusinessClassEnum
  from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_getHotelBusinessClassEnum'
// 订单来源枚举接口
import consumer_admin_hotel_order_getOrderSourceEnum from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_getOrderSourceEnum'
// 订单渠道枚举接口
import consumer_admin_hotel_order_getOrderChannelEnum from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_getOrderChannelEnum'
// 平台酒店订单列表
import consumer_admin_hotel_order_orderList from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_orderList"
import consumer_hotelInit_listMergeRecord from "@/lib/data-service/hotel/consumer_hotelInit_listMergeRecord"
import consumer_hotelInit_handleMergeRecord from "@/lib/data-service/hotel/consumer_hotelInit_handleMergeRecord"
// 取消订单-酒店
import req_cancel_order from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_cancelOrder"
// 导出
import consumer_admin_hotel_order_hotelListReportExcel from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_hotelListReportExcel'
// 消息模板
import consumer_admin_hotel_copyMessageTemplate from '@/lib/data-service/haolv-default/consumer_admin_hotel_copyMessageTemplate'
import CopyReportPopup from '@/component/copyReportPopup/import_latest_version_module'

export default {
  data() {
    return {
      selectDataList: [],
      fileUrl: '',
      loading: false,
      createTime: [],   //下单日期
      searchForm: {
        orderNo: '',   //订单编号
        supplierOrderNo: '',   //供应商订单号
        evectionUserName: '',   //旅客姓名/出行人名字
        certificatesNumber: '',   //证件信息
        orderSource: 0,//订单来源
        orderChannel: 0,//订单渠道
        ifInvoice: 0,//开票方式
        businessClassCode: -1,//业务类别
        businessClass: '',//业务类别
        isBehalf: '',   //是否代客下单 1-是,0-否
        orderTimeStart: '',   //下单开始日期
        orderTimeEnd: '',   //下单结束日期
        checkInDate: '',//入住时间
        companyName: '',   //公司id
        evectionType: 0,   //预定方式：0：全部 1：因私，2：因公
        status: null,   //订单状态
        hotelName: '',   //酒店名称
        initialName: '',   //操作客服
        tagId: '',//标签

        currentPage: 1,   //当前页码
        pageSize: 10   //每页记录条数
      },
      totalCount: 0,   //总条数
      orderSourceList: [],

      behalfOption: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '是'
        },
        {
          value: 0,
          label: '否'
        }
      ],
      evectionTypeOption: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 2,
          label: '因公'
        },
        {
          value: 1,
          label: '因私'
        }
      ],

      activeName: '0',
      tabList: [
        {
          name: 0,
          label: '全部',
        },{
          name: 1,
          label: '待合并',
        },{
          name: 2,
          label: '已合并',
        },{
          name: 3,
          label: '不合并',
        },

      ],
      tabNumberList: [],
      tableLoading: false,
      orderList: [],
      orderList1: [],
      orderChannelList: [
        {
          code: 0,
          msg: '全部'

        },
        {
          code: 1,
          msg: '农行api'
        }
      ],
      ifInvoiceList: [],
      businessClassList: [],
      tagList: [
        {
          tagId: '',
          tagName: '全部'
        },
        {
          tagId: 1,
          tagName: 'VP'
        }
      ],
      copyDialog: false,
      copyLoading: false,
      copyTextarea: '',
    }
  },
  components: {
    SearchBox,
    Pagination,
    RouterLinkButton,
    CopyReportPopup
  },
  activated() {
    console.log(0)
    this.getOrderList();
    this.getByIfInvoice();
    this.getBusinessClassList();
    this.getOrderSourceEnum();
    // this.getOrderChannelEnum();
  },
  methods: {
    // 导出
    toPreview(){
      this.loading = true;
      const _http = document.location.protocol + '//';
      consumer_admin_hotel_order_hotelListReportExcel(this.searchForm).then(res => {
        this.fileUrl = _http + res.datas;
        this.$nextTick(() => {
          this.$refs.downloadBtn.click()
        })
        this.loading = false;
      });
    },
    //后台生单
    toRecordOrder() {
      this.$router.push({
        name: "admin-hotel-create-order",
        query: {
          type: 'create'
        }
      })
    },
    //平台酒店订单列表
    async getOrderList() {
      try {
        this.tableLoading = true;

     let data = {
          currentPage:this.searchForm.currentPage,
          pageSize:this.searchForm.pageSize,
          specSearchTargetName: this.searchForm.hotelName,
          isMergeStatus:this.activeName,
        };
        const res1 = await consumer_hotelInit_listMergeRecord(data);
        this.orderList1 = res1.pageResult.pageData;
        // this.totalCount = res1.
        this.tableLoading = false;
        this.totalCount = res1.pageResult.totalCount;

        this.searchForm.currentPage =  res1.pageResult.currentPage;
        this.searchForm.pageSize = res1.pageResult.pageSize;

      } catch (error) {
        this.orderList = [];
        this.totalCount = 0;
        this.$message({
          type: "error",
          message: "获取列表失败！"
        })
      } finally {
        this.$nextTick(() => {
          this.$refs.mainTable.doLayout()
        })
        this.tableLoading = false
      }
    },
    //搜索
    onSearch() {
      this.searchForm.currentPage = 1
      this.getOrderList()
    },
    //重置
    onReset() {
      this.searchForm = {
        hotelName: '',   //酒店名称
        currentPage: 1,   //当前页码
        pageSize: 10   //每页记录条数
      };
      this.createTime = [];   //下单日期
      this.onSearch()
    },
    getOrderChannelEnum(){
      consumer_admin_hotel_order_getOrderChannelEnum().then(res => {
        this.orderChannelList = res.datas;
      });
    },
    getOrderSourceEnum() {
     // 订单来源
      consumer_admin_hotel_order_getOrderSourceEnum().then(res => {
        this.orderSourceList = res.datas;
      });
    },
    getByIfInvoice() {
      // 开票方式
      consumer_admin_hotel_order_queryHotelOrderListByIfInvoice().then(res => {
        this.ifInvoiceList = res.datas;
      });
    },

    //获取业务类别枚举值
    async getBusinessClassList() {
      let res = await consumer_admin_hotel_order_getHotelBusinessClassEnum();
      let list = res.datas;
      let category = {};
      let businessClassList = [
        {
          code: -1,
          msg: '全部'
        }
      ];
      list.forEach((item, index) => {
        category = {
          code: index,
          msg: item
        }
        businessClassList.push(category)
      });
      this.businessClassList = businessClassList;
    },

    //改变业务类别code时
    changeBusinessClassCode() {
      let msg = this.businessClassList.find(item => {
        return item.code === this.searchForm.businessClassCode;
      }).msg;
      this.searchForm.businessClass = msg === '全部' ? '' : msg;
    },

    getEvectionTypeToClass(val) {
      //出差单类型：1.因私，2.因公
      switch (val) {
        case (1):
          return 'grey'
        case (2):
          return 'orange'
        default: return ''
      }
    },
    changeCreateTime(val) {
      if(!val) {
        this.searchForm.orderTimeStart = 0;
        this.searchForm.orderTimeEnd = 0;
      } else {
        this.searchForm.orderTimeStart = val[0] + ' 00:00:00';
        this.searchForm.orderTimeEnd = val[1] + ' 23:59:59';
      }
    },
    //查看订单
    handleDetailClick(val) {
      return {
        name: 'admin-hotel-order-sold-detail',
        query: {
          id: val.orderNo,
          orderSource: val.orderSource
        }
      }
    },
    mergeYes(val){
      this.$confirm("是否确认合并?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.loading = true;
        consumer_hotelInit_handleMergeRecord({
          mergeRecordId: val.id,
          isMerge: true
        }).then((res) => {
          this.$message({
            type: 'success',
            message: '合并成功！'
          })
          this.getOrderList()
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })

      }).catch(() => { })
    },
    mergeNo(val){
      this.$confirm("是否确认不合并?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.loading = true;
        consumer_hotelInit_handleMergeRecord({
          mergeRecordId: val.id,
          isMerge: false
        }).then((res) => {
          this.$message({
            type: 'success',
            message: '操作成功！'
          })
          this.getOrderList()
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })

      }).catch(() => { })
    },
    selectClick(item){
      this.selectDataList = item;
    },
    selectClickYes(){
      let idList = [];
      this.selectDataList.forEach((one,index) => {
        idList.push({
          mergeRecordId: one.id,
          isMerge: true,
        });
      });
      this.$confirm("是否确认合并?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.loading = true;
        consumer_hotelInit_handleMergeRecord({
            mergeRecordList: idList
        }).then((res) => {
          this.$message({
            type: 'success',
            message: '操作成功！'
          })
          this.getOrderList()
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })

      }).catch(() => { })
    },
    selectClickNo(){
      let idList = [];
      this.selectDataList.forEach((one,index) => {
        idList.push({
          mergeRecordId: one.id,
          isMerge: false,
        });
      });
      this.$confirm("是否确认不合并?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.loading = true;
        consumer_hotelInit_handleMergeRecord({
            mergeRecordList: idList
        }).then((res) => {
          this.$message({
            type: 'success',
            message: '操作成功！'
          })
          this.getOrderList()
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })

      }).catch(() => { })
    },
    //取消订单
    handleCancelClick(val) {
      this.$confirm("是否确认取消订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.loading = true;
        req_cancel_order({
          orderNo: val.orderNo,
          cancelRemark: ""
        }).then((res) => {
          this.$message({
            type: 'success',
            message: '取消订单成功！'
          })
          this.getOrderList()
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })

      }).catch(() => { })
    },
    //去支付
    handlePayClick(val) {
      // 判断是线上还是线下
      if (val.orderType !== 3) {
        this.$router.push({
          name: "admin-hotel-payment",
          query: {
            orderNo: val.orderNo,
            orderType: 3,
            pageType: 2
          }
        })
      } else {
        // 线下单支付
        this.$router.push({
          name: "admin-hotel-create-order-pay",
          query: {
            orderNo: val.orderNo,
            behalfCompanyId: val.companyId
          }
        })
      }
    },
    //退订
    handleRefundClick(val) {
      this.$router.push({
        name: "admin-hotel-order-refund",
        query: {
          orderNo: val.orderNo
        }
      })
    },
    //线下退订
    handleOfflineRefundClick(val) {
      this.$router.push({
        name: "admin-hotel-order-offline-cancel-handle",
        query: {
          orderNo: val.orderNo
        }
      })
    },
    //编辑线下单
    handleEditClick(val) {
      this.$router.push({
        name: "admin-hotel-create-order",
        query: {
          type: 'edit',
          orderNo: val.orderNo
        }
      })
    },
    //确认出房
    handleConfirmRoomClick(val) {
      this.$router.push({
        name: "admin-hotel-confirm-room",
        query: {
          type: 'edit',
          orderNo: val.orderNo
        }
      })
    },
    //消息模板
    handleMessage(val) {
      this.copyDialog = true;
      this.copyLoading = true;
      this.copyTextarea = '';
      // 调接口
      consumer_admin_hotel_copyMessageTemplate({orderNo: val.orderNo}).then(res => {
        const datas = res.datas;
        let message = '';
        message += `预定成功：\n`;
        datas.hotelPsgList.forEach((value, index) => {
          message = message + '房间' + (index+1) + '：' + value + '\n';
        })
        message = message + '入住：' + datas.checkInDate + '\n';
        message = message + '离店：' + datas.checkOutDate + '  共' + datas.roomNum + '间' + datas.stayNights + '晚' + '\n';
        message = message + '酒店：' + datas.hotelName + '\n';
        message = message + '房型：' + datas.hotelRoomTypeName + '\n';
        message = message + '酒店地址：' + datas.hotelAddress + '  ' + '酒店电话：' + datas.hotelPhone + '\n';
        message += `请入住人持证件到酒店前台登记入住。\n`;
        this.copyTextarea = message
      }).finally(() => {
        this.copyLoading = false;
      })
    }
  }
}

import consumer_trains_order_getTrainsOrderDetail
  from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetail'
import consumer_trains_order_getTrainsChangeOrderDetail
  from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsChangeOrderDetail' // 改签详情
import consumer_trains_order_changeOrderBooking
  from '@/lib/data-service/haolv-default/consumer_trains_order_changeOrderBooking' // 改签提交
import consumer_admin_trains_order_applyForChange
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_applyForChange' // 改签提交New
import consumer_trains_order_changeToCheck from '@/lib/data-service/haolv-default/consumer_trains_order_changeToCheck' // 改签校验
import consumer_admin_trains_order_applyForChangeCost
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_applyForChangeCost'
import consumer_t_od_trains_order_change_changePolling
  from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_change_changePolling'
import consumer_t_od_trains_order_change_changePayCost
  from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_change_changePayCost'
// import CheckTravel from '@/component/checkTravel/index.vue'
import TrainSearch from "@/page/admin/replace-orders/train-search/index.vue";
import moment from 'moment'

export default {
  data() {
    return {
      changeConfirmationLoading: false,
      changeConfirmation: false,
      trainNumber: [],
      handleBtnOff: null,
      loading: false,
      reasonRadio: '',
      orderNo: '',
      changeNo: '',
      orderInfo: {},
      params: {
        startTime: ''
      },
      rule: {
        startTime: [
          {required: true, message: '请选择出发时间', trigger: 'change'}
        ]
      },
      pickerOptions: {
        disabledDate: this.disabledDate
      },
      changeTrainInfo: null,
      personalDialogVisible: false,
      evectionDialogVisible: false,
      changeResult: null, // 改签结果返回
      canSubmit: true,
      titleOffOreder: false,
      titleOffRefund: true,
      passengerSnsData: [], //存储选择乘客
      changeSubmitInfo: {
        passengers: [],
      },
    }
  },
  components: {
    // CheckTravel,
    TrainSearch
  },
  activated() {
    this.orderNo = this.$route.query.orderNo;
    this.changeNo = this.$route.query.changeNo ? this.$route.query.changeNo : '';
    this.reasonRadio = this.$route.query.reasonRadioText ? this.$route.query.reasonRadioText : '';

    this.getDetail();
    if (this.changeNo !== '') {
      this.getChangeDetail()
    }
    //this.initCheckTravelBox()
  },
  deactivated() {
  },
  destroyed() {
  },
  watch: {},
  computed: {},
  filters: {
    filterPassengerType(val) {
      // 1:成人 2：儿童 3：学生票 4：残军票
      if (val === 1) {
        return '成人'
      } else if (val === 2) {
        return '儿童'
      } else if (val === 3) {
        return '学生票'
      } else if (val === 4) {
        return '残军票'
      } else {
        return ''
      }
    },
    filterPassengerCardType(val) {
      // 1：身份证2：儿童无证件3：港澳通行证4：台 湾通行证5：护照
      if (val === '1') {
        return '身份证'
      } else if (val === '2') {
        return '儿童无证件'
      } else if (val === '3') {
        return '港澳通行证'
      } else if (val === '4') {
        return '台湾通行证'
      } else if (val === '5') {
        return '护照'
      } else {
        return ''
      }
    },
    filterOrderStatus(val) {
      // 0:全部 1:-确认中 2- 待支付 3-待出票 4 -出票成功 5-出票失败 6-取消
      if (val === 1) {
        return '确认中'
      } else if (val === 2) {
        return '待支付'
      } else if (val === 3) {
        return '待出票'
      } else if (val === 4) {
        return '出票成功'
      } else if (val === 5) {
        return '出票失败'
      } else if (val === 6) {
        return '取消'
      } else {
        return ''
      }
    },
    gmtChangeStartFilter(val) {
      return moment(val).format('YYYY-MM-DD HH:mm')
    },
    filterTicketStatus(val) {
      // 0：未出票，1：待出票，2：出票中，3：出票完成，4：出票失败,5:退票已申请,6:退票处理中,7:退票完成，8: 退票失败，9：改签已申请，10：改签中， 11：改签完成，12：改签失败，13：改签已取消
      if (val === 0) {
        return '未出票'
      } else if (val === 1) {
        return '待出票'
      } else if (val === 2) {
        return '出票中'
      } else if (val === 3) {
        return '出票完成'
      } else if (val === 4) {
        return '出票失败'
      } else if (val === 5) {
        return '退票已申请'
      } else if (val === 6) {
        return '退票处理中'
      } else if (val === 7) {
        return '退票完成'
      } else if (val === 8) {
        return '退票失败'
      } else if (val === 9) {
        return '改签已申请'
      } else if (val === 10) {
        return '改签中'
      } else if (val === 11) {
        return '改签完成'
      } else if (val === 12) {
        return '改签失败'
      } else if (val === 13) {
        return '改签已取消'
      } else {
        return ''
      }
    },
  },
  methods: {
    back() {
      this.$router.push({
        name: 'admin-train-order-list'
      })
    },
    titleClickRefund() {
      this.titleOffRefund = !this.titleOffRefund;
    },
    titleClickOrder() {
      this.titleOffOreder = !this.titleOffOreder;
    },
    selectablePassenger(val, index) {
      if (val.ticketStatus === 3) {
        return true
      } else {
        return false
      }
    },
    // selectablePassenger(val, index) {
    //   let currentNum = 0;
    //   let currentIndex = 0;
    //   this.orderInfo.passengers.forEach((value, vIndex) => {
    //     if (value.passengerName === val.passengerName) {
    //       currentNum++;
    //       currentIndex = vIndex // 记录所有车次信息中最后的vIndex
    //     }
    //   });
    //
    //   if (val.ticketStatus === 3) { //判断车牌状态：出票完成(3)
    //     if (currentNum === 1) {
    //       //判断所点击的车次，在所有的车次中是否有重复---结果为非重复
    //       this.handleBtnOff = true;
    //       return true
    //     } else if (
    //       currentNum > 1
    //       &&
    //       (this.orderInfo.passengers[currentIndex].ticketStatus === 12 || this.orderInfo.passengers[currentIndex].ticketStatus === 13)) {
    //       //判断所点击的车次，在所有的车次中是否有重复---结果为重复 && (判断最后的车次信息中车票状态是否为改签失败 || 判断最后的车次信息中车票状态是否为改签已取消)
    //       this.handleBtnOff = true;
    //       return true
    //     } else {
    //       this.handleBtnOff = false;
    //       return false
    //     }
    //   } else {
    //     this.handleBtnOff = false;
    //     return false
    //   }
    // },
    disabledDate(time) {
      let month = this.$moment().month()
      let day = 0
      if (month === 0 || month === 1) {
        day = 61
      } else if (month === 6) {
        day = 64
      } else {
        day = 63
      }
      return (time.getTime() < Date.now() - 8.64e7) || (time.getTime() > (Date.now() - 8.64e7 + day * 24 * 60 * 60 * 1000))
    },
    gmtStartFormatter(val) {
      return this.$moment(val.gmtStart).format('YYYY-MM-DD HH:mm')
    },
    gmtArriveFormatter(val) {
      return this.$moment(val.gmtArrive).format('YYYY-MM-DD HH:mm')
    },
    getDetail() {
      this.loading = true;
      consumer_trains_order_getTrainsOrderDetail({orderNo: this.orderNo}).then(res => {
        let orderInfo = res.datas;
        orderInfo.passengers.forEach(value => {
          value.active = false;
          value.passenger = [value]
        });
        this.orderInfo = orderInfo;

        this.trainNumber = [
          {
            trainNo: this.orderInfo.trainNo,
            fromStationName: this.orderInfo.fromStationName,
            toStationName: this.orderInfo.toStationName,
            gmtStart: this.orderInfo.gmtStart,
            gmtArrive: this.orderInfo.gmtArrive,
            seatName: this.orderInfo.seatName,
            arriveDate: this.orderInfo.arriveDate,
            arriveTime: this.orderInfo.arriveTime,
            startDate: this.orderInfo.startDate,
            startTime: this.orderInfo.startTime,
          }
        ];
          // orderInfo.passengers
        //  从选择改签车次页面返回自动勾选，选过的乘客
        if (this.$route.query.snText) {
          this.$route.query.snText.split(',').forEach((item,index) => {
            if (item) {
              this.orderInfo.passengers.forEach((passengersItem,passengersIndex) => {
                  if (passengersItem.sn == item && this.selectablePassenger(passengersItem)) {
                      passengersItem.active = true;
                      this.passengerSnsData.push(passengersItem);
                  }
              })
            }
          })
        }

        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    getChangeDetail() {
      let dataBox = [];
      consumer_trains_order_getTrainsChangeOrderDetail({orderNo: this.changeNo}).then(res => {
        dataBox[0] = res.datas;
        this.changeTrainInfo = dataBox;
      })
    },
    changeCheckbox(item, index) {
      let switchOFF = true;
      this.passengerSnsData.forEach((PItem,PIndex) => {
        if (PItem.sn == item.row.sn) {
          this.passengerSnsData.splice(PIndex,1);
          switchOFF = false;
        }
      });
      if (switchOFF) {
        this.passengerSnsData.push(item.row);
      }
    },
    toSearchTrain() {
      let snText = '';
      this.passengerSnsData.forEach((item,index) => {
        snText = `${snText},${item.sn}`;
      });
      this.passengerSnsData = [];
      this.$router.push({
        name: 'admin-train-change-search',
        query: {
          fromStationCode: this.orderInfo.fromStationCode,
          toStationCode: this.orderInfo.toStationCode,
          time: this.params.startTime,
          onlySearch: 0,
          orderNo: this.orderNo,
          changeNo: this.changeNo,
          reasonRadioText: this.reasonRadio,
          snText,
        }
      })
    },
    confirmRefund() {
      // this.changeConfirmation = false;
      this.submit();
    },
    clickCancel() {
      this.changeConfirmation = false;
    },
    clickSubmit() {
      if (this.passengerSnsData[0] == undefined) {
        this.$message({
          type: 'warning',
          message: '请先勾选改签的乘客'
        });
        this.canSubmit = true;
        return
      }
      // 判断是否勾选了乘客
      let passengerSns = [];
      let changeHeadCounts = 0;
      let changeAdultCounts = 0;
      let changeChildrenCounts = 0;
      let userIds = [];
      this.passengerSnsData.forEach(value => {
        passengerSns.push(value.sn);
        userIds.push(value.userId);
        changeHeadCounts++;
        if (value.passengerType === 2) {
          changeChildrenCounts++
        } else {
          changeAdultCounts++
        }
      });

      // 判断是否选择了车次
      if (this.changeTrainInfo === null) {
        this.$message({
          type: 'warning',
          message: '请先选择车次'
        })
        this.canSubmit = true;
        return
      }
      if (this.reasonRadio === '') {
        this.$message({
          type: 'warning',
          message: '请先选择原因'
        });
        this.canSubmit = true;
        return
      }
      if (!this.canSubmit) {
        return
      }
      this.changeConfirmation = true;
      this.changeConfirmationLoading = true;
      //调用接口获取信息
      const params = {
        list: passengerSns,
        orderNo: this.changeNo
      };
      consumer_admin_trains_order_applyForChangeCost(params).then(res => {
        this.changeSubmitInfo = res.datas
      }).finally(() => {
        this.changeConfirmationLoading = false;
      })
    },
    submit() {
      if (this.passengerSnsData[0] == undefined) {
        this.$message({
          type: 'warning',
          message: '请先勾选改签的乘客'
        });
        this.canSubmit = true;
        return
      }
      // 判断是否勾选了乘客
      let passengerSns = [];
      let changeHeadCounts = 0;
      let changeAdultCounts = 0;
      let changeChildrenCounts = 0;
      let userIds = [];
      this.passengerSnsData.forEach(value => {
        passengerSns.push(value.sn);
        userIds.push(value.userId);
        changeHeadCounts++;
        if (value.passengerType === 2) {
          changeChildrenCounts++
        } else {
          changeAdultCounts++
        }
      });

      // 判断是否选择了车次
      if (this.changeTrainInfo === null) {
        this.$message({
          type: 'warning',
          message: '请先选择车次'
        })
        this.canSubmit = true;
        return
      }
      if (this.reasonRadio === '') {
        this.$message({
          type: 'warning',
          message: '请先选择原因'
        });
        this.canSubmit = true;
        return
      }

      // 超标校验
      /*if (this.orderInfo.scheduledWay === 2) {
          // 2: 出差单预定 1 :个人
          this.$refs.checkTravelBox.run()
          this.canSubmit = true;
          return
      }*/
      if (!this.canSubmit) {
        return
      }
      this.canSubmit = false;

      // 提交
      let params = {
        changeAdultCounts: changeAdultCounts, // 改签成人人数
        changeChildrenCounts: changeChildrenCounts, // 改签儿童人数
        changeHeadCounts: changeHeadCounts, // 改签总人数
        orderNo: this.orderNo,
        passengerSns: passengerSns, // 乘客sn数组
        changeNo: this.changeNo,
        overproofApplyList: [], // 超标人信息列表
        reason: this.reasonRadio,
        scheduledWay: this.orderInfo.scheduledWay
      }
      consumer_admin_trains_order_applyForChange(params).then(res => {
        let __this = this;
        __this.$message({
          message: '改签已成功提交',
          type: 'success'
        });
        __this.changeConfirmationLoading = false;
        __this.changeConfirmation = false;

        setTimeout(() => {
          __this.$router.push({
            name: 'admin-train-change-order-list',
            query: {
              behalfCompanyId: 2,
              evectionType: 2,
            }
          })
        }, 1000)

      }).catch(() => {
        this.canSubmit = true
      })
    },
    goPay() {
      this.personalDialogVisible = false;
      this.$router.push({
        name: 'admin-train-payment-change',
        query: {
          orderType: 2,
          evenctionNo: this.orderInfo.evectionNo,
          changeNo: this.changeNo,
          orderNo: this.orderNo,
          pageType: 3
        }
      })
    },
    goMyOrderList() {
      this.personalDialogVisible = false;
      this.$router.push({
        name: 'admin-train-order-list',
      })
    },
    goMyChangeOrderList() {
      this.evectionDialogVisible = false;
      this.$router.push({
        name: 'admin-train-change-order-list',
      })
    },
    closeToPayDialog(done) {
      this.getDetail();
      if (this.changeNo !== '') {
        this.getChangeDetail()
      }
      //this.initCheckTravelBox()

      done()
    },
    handleBtn(index, row, col, event) {
      if (this.handleBtnOff) {
        row.flag = !row.flag;
        this.$refs[`passengerTable${index}`][0].toggleRowSelection(row, row.flag)
      }
    }
  }
}

import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'
import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'

import consumer_admin_hotel_order_partialCancelList
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_partialCancelList";

export default {
    data() {
        return {
            searchForm: {
                orderNo: '',   //订单编号
                thirdpartyOrderNo: '',   //供应商单号
                person: '',   //入住人
                isBehalf: '',   //是否代客下单 1-是,0-否
                hotelName: '', //酒店名称
                orderTimeStart: '', //下单时间or开始时间
                orderTimeEnd: '', //下单时间or结束时间
                companyName: '',   //所属企业
                evectionType: 0,   //因公因私
                initialName: '',   //操作客服
                resultFlag: '-2',//订单状态

                currentPage: 1,   //当前页码
                pageSize: 10,   //每页记录条数
                totalCount: 0 //总条数
            },
            createTime: [],   //申请时间
            activeName: '-2',
            tabList: [
                {
                    name: -2,
                    label: '全部',
                    value: 0
                },
                {
                    name: -1,
                    label: '待处理',
                    value: 0
                },
                {
                    name: 0,
                    label: '退订成功',
                    value: 0
                },
                {
                    name: 1,
                    label: '退订失败',
                    value: 0
                }
            ],
            tableData: [],
            orderNo: '',
            companyName: '',
            realName: '',
            cancelStatus: -1,
            cancelStatusObj: {
                0: '退订申请',
                1: '退订成功',
                2: '待平台确认',
                3: '退订失败',
                4: '未退订 待审批',
            },
            isBehalf: '',
            hotelName: '',
            initialName: '',
            evectionUserName: '',
            options: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: 0,
                    label: '否',
                },
                {
                    value: 1,
                    label: '是',
                },
            ],
            evectionTypeList: [
                {
                    value: 0,
                    label: '全部',
                },
                {
                    value: 1,
                    label: '因公',
                },
                {
                    value: 2,
                    label: '因私',
                },
            ],
            loading: true,
        };
    },
    components: {
        SearchBox,
        Pagination,
        RouterLinkButton
    },
    methods: {
        //修改筛选条件-申请时间
        changeCreateTime(val) {
            if(!val) {
                this.searchForm.orderTimeStart = 0;
                this.searchForm.orderTimeEnd = 0;
            } else {
                this.searchForm.orderTimeStart = val[0] + ' 00:00:00';
                this.searchForm.orderTimeEnd = val[1] + ' 23:59:59';
            }
        },

        //搜索
        onSearch() {
            this.searchForm.currentPage = 1
            this.searchForm.resultFlag = this.activeName
            this.getOrderList()
        },

        //获取列表详情
        getOrderList() {
            this.loading = true;
            consumer_admin_hotel_order_partialCancelList(this.searchForm).then(res => {
                let data = res.pageResult
                this.tableData = data.pageData;
                if (res.hotelOrderNumberResults) {
                    res.hotelOrderNumberResults.forEach(item => {
                        switch (item.status) {
                            case -2:
                                this.tabList[0].value = item.orderNumber;
                                return;
                            case -1:
                                this.tabList[1].value = item.orderNumber;
                                return;
                            case 0:
                                this.tabList[2].value = item.orderNumber;
                                return;
                            case 1:
                                this.tabList[3].value = item.orderNumber;
                                return;
                            default:
                                return;
                        }
                    })
                }
                this.searchForm.totalCount = data.totalCount;
                this.searchForm.currentPage = data.currentPage;
            }).finally(() => {
                this.$nextTick(() => {
                    this.$refs.mainTable.doLayout()
                })
                this.loading = false;
            });
        },

        //出差单类型
        getEvectionTypeToClass(val) {
            //出差单类型：1.因私，2.因公
            switch (val) {
                case (1):
                    return 'blue'
                case (2):
                    return 'orange'
                default:
                    return ''
            }
        },

        // 切换状态
        handleChangeTab(item) {
            // this.cancelStatus = item.name === '-1' ? null : parseInt(item.name)
            this.cancelStatus = parseInt(item.name)
            this.getOrderList()
        },

        //重置
        onReset() {
            this.searchForm = {
                orderNo: '',   //订单编号
                thirdpartyOrderNo: '',   //供应商单号
                person: '',   //入住人
                isBehalf: '',   //是否代客下单 1-是,0-否
                hotelName: '', //酒店名称
                orderTimeStart: '', //下单时间or开始时间
                orderTimeEnd: '', //下单时间or结束时间
                companyName: '',   //所属企业
                evectionType: 0,   //因公因私
                initialName: '',   //操作客服
                resultFlag: '-2',//订单状态

                currentPage: 1,   //当前页码
                pageSize: 10,   //每页记录条数
                totalCount: 0 //总条数
            };
            this.createTime = []; //申请时间
            this.onSearch();
        },

        //查看详情
        handleViewDetail(row) {
            return {
                name: 'admin-hotel-order-non-rule-cancel-detail',
                query: {
                    orderNo: row.orderNo,
                    reverseOrderId: row.reverseOrderId
                },
            }
        },

        //退订处理
        handleCancel(row) {
            this.$router.push({
                name: 'admin-hotel-order-non-rule-cancel-handle',
                query: {
                    orderNo: row.orderNo,
                    reverseOrderId: row.reverseOrderId
                },
            })
        },

        //线下退订
        handleOfflineRefundClick(row) {
            this.$router.push({
                name: "admin-hotel-order-offline-cancel-handle",
                query: {
                    orderNo: row.orderNo
                }
            })
        },
    },
    activated() {
        if (this.$route.query && this.$route.query.type) {
            this.activeName = `${this.$route.query.type}`;
        } else {
            this.activeName = '-2';
        }
        this.searchForm.resultFlag = this.activeName
        this.getOrderList()
    },
}
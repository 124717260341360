// 获取机票订单日志

const __request = require(`./__request/__request_contentType_json`)
const consumer_log_flightList = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/log/flightList',
    data: data
  }
  return __request(pParameter)
}
export default consumer_log_flightList

export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/publish-notice',
            name: 'admin-publish-notice',
            component: () => import(/* webpackChunkName: "page-admin-notice-publish-notice" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `发布公告`,
                },
                entity_list_for_breadcrumb: [
                    {name: `通知公告`},
                    {name: `发布公告`},
                ],
            },
        },
    ],
};

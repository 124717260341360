// 客户列表
import _ from 'underscore';
import consumer_credit_getCreditFlowList from "@/lib/data-service/haolv-default/consumer_credit_getCreditFlowList";
import moment from "moment";

export default {
    data() {
        return {
            loading: false,
            activeName: 'first',
            form: {
                billId: '', // 账单id
                companyId: '', // 公司id
                transactionStartTime: '',
                closingTime: '',
                flowType: '',
                isBill: '',
                currentPage: 1,
                pageSize: 10,
            },
            registerDate: null,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            list: [],
            pager: {
                pageSizes: [10, 15, 20, 25, 30],
                total: 0,
            },
            flowType: [
                {value: '', msg: '全部'},
                {value: 1, msg: '购物'},
                {value: 2, msg: '还款'},
                {value: 3, msg: '退款'},
            ],
            billType: [
                {value: '', msg: '全部'},
                {value: 0, msg: '未出账'},
                {value: 1, msg: '已出账'}
            ],

        };
    },
    methods: {
        init() {
            const __this = this;
            const _date = new Date();
            // __this.form.closingTime = moment(_date).format('YYYY-MM-DD');
            // __this.form.transactionStartTime = moment(_date.setDate(_date.getDate() -30)).format('YYYY-MM-DD');
            // __this.registerDate = [__this.form.transactionStartTime, __this.form.closingTime];
            __this.form.billId = parseInt(__this.$route.query.billId);
            __this.form.companyId = parseInt(__this.$route.query.companyId);
            __this.req_list();
        },
        req_list() {
            const __this = this;
            if (__this.loading) return;
            __this.loading = true;
            consumer_credit_getCreditFlowList(__this.form)
                .then((res) => {
                    __this.list = res.datas.list;
                    __this.pager.total = res.datas.totalCount;
                    __this.loading = false;
                })
                .catch((err) => {
                    __this.loading = false;
                });
        },
        change_date(val) {
            const __this = this;
            if (val) {
                __this.form.transactionStartTime = val[0];
                __this.form.closingTime = val[1];
            } else {
                __this.form.transactionStartTime = "";
                __this.form.closingTime = "";
            }
        },
        search() {
            this.form.currentPage = 1;
            this.req_list();
        },
        reset() {
            const __this = this;
            __this.registerDate = null;
            __this.change_date();
            __this.$refs.form.resetFields();
            __this.req_list();
        },

        change_pageSize(pageSize) {
            const __this = this;
            __this.form.pageSize = pageSize;
            __this.change_currentPage(1);
        },
        change_currentPage(currentPage) {
            const __this = this;
            __this.form.currentPage = currentPage;
            __this.req_list();
        },
    },
    activated() {
        const __this = this;
        __this.init();
    },
    watch: {},
    filters: {
        format_linkman(val, type) {
            if (val) {
                const linkman = JSON.parse(val);
                if (type === 1) {
                    return linkman.name;
                } else if (type === 2) {
                    return linkman.phone;
                }
            } else {
                return "--";
            }
        },
    },
};

// 【399】admin-代客下单删除客服12306账号
// POST /consumer/trains/account/deleteAccountByAdmin
// 接口ID：50861946
// 接口地址：https://www.apifox.cn/web/project/458418/apis/api-50861946
const __request = require(`./__request/__request_contentType_json`);
const consumer_trains_account_deleteAccountByAdmin = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/account/deleteAccountByAdmin',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_account_deleteAccountByAdmin;
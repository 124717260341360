import _ from 'underscore';
import get_list from '@/lib/data-service/haolv-default/consumer_credit_auditCreditList.js';
export default {
  components: {},
  data() {
    return {
      loading: false,
      list: [],
      form: {
        currentPage: 1,
        pageSize: 10,
        status: '1',
        companyName: "",
        phone: "",
        startDate: "",
        endDate: "",
      },
      statusList: [
        { text: '待审核', label: '1' },
        { text: '审核失败', label: '3' },
      ],
      pager: {
        pageSizes: [10, 15, 20, 25, 30],
        total: 0,
      },
      registerDate: null,
      pickerOptions2: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },

      activeName: '1',
    };
  },
  methods: {
    req_list() {
      this.loading = true;
      get_list(this.form).then((result) => {
        const datas = result.pageResult;
        this.list = datas.pageData;
        this.pager.total = datas.totalCount;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
      });
    },
    handleClick(tab) {
      let status = tab.name;
      if (status === this.form.status) return;
      this.form.status = status;
      this.form.currentPage = 1;
      this.req_list();
    },
    change_date(val) {
      const form = this.form;
      if (val) {
        form.startDate = val[0];
        form.endDate = val[1];
      } else {
        form.startDate = '';
        form.endDate = '';
      }
    },
    chagne_staus() {
      this.search();
    },
    search() {
      this.form.currentPage = 1;
      this.req_list();
    },
    reset() {
      const noresetarr = ['currentPage', 'pageSize'];
      const form = this.form;
      for (const key in form) {
        if (!noresetarr.includes(key)) form[key] = '';
      }
      this.registerDate = null;
      this.search();
    },

    to_detail(companyId) {
      let name = 'admin-open-credit';
      const data_app_for_npm_haolv_platform_v2 = this.$route.meta.data_app_for_npm_haolv_platform_v2;
      if (data_app_for_npm_haolv_platform_v2) {
        const get_route_detail_page = data_app_for_npm_haolv_platform_v2.get_route_detail_page;
        if (_.isFunction(get_route_detail_page)) {
          const route_detail_page = get_route_detail_page();
          if (route_detail_page) {
            const route_name = route_detail_page.route_name;
            if (route_name) {
              name = route_name;
            }
          }
        }
      }
      this.$router.push({
        name,
        query: {
          companyId,
          type: 'detail',
        }
      })
    },
    to_approve(companyId) {
      let name = 'admin-open-credit';
      const data_app_for_npm_haolv_platform_v2 = this.$route.meta.data_app_for_npm_haolv_platform_v2;
      if (data_app_for_npm_haolv_platform_v2) {
        const get_route_detail_page = data_app_for_npm_haolv_platform_v2.get_route_detail_page;
        if (_.isFunction(get_route_detail_page)) {
          const route_detail_page = get_route_detail_page();
          if (route_detail_page) {
            const route_name = route_detail_page.route_name;
            if (route_name) {
              name = route_name;
            }
          }
        }
      }
      this.$router.push({
        name: name,
        query: {
          companyId,
          type: 'approve',
        }
      })
    },
    change_pageSize(pageSize) {
      this.form.pageSize = pageSize;
      this.change_currentPage(1);
    },
    change_currentPage(currentPage) {
      this.form.currentPage = currentPage;
      this.req_list();
    },
  },
  activated() {
    this.activeName = this.$route.query && this.$route.query.activeName ? this.$route.query.activeName : '1';
    this.form.status = this.activeName;
    this.req_list();
  },
  deactivated() { },
  watch: {},
  filters: {},
};

import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.2.1/index.vue';
import DynamicForm from '@/component/dynamicForm/import_latest_version_module'
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.8/index.vue';
import consumer_admin_flight_generate_evectionType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_evectionType'
import consumer_admin_flight_generate_payType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_payType'
import consumer_admin_flight_generate_certificateType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType'
import consumer_admin_flight_generate_getCompanyList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getCompanyList'
import consumer_admin_ship_save from '@/lib/data-service/haolv-default/consumer_admin_ship_save'
import consumer_admin_ship_details from '@/lib/data-service/haolv-default/consumer_admin_ship_details'
import defaultForm from './form';
import isMobile from 'yinzhilv-js-sdk/src/common-service/other/is_mobile';
import tipsObj from './tips';
import DictionarySelect from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';
import NewUserPopup
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-list-for-reserve-platform/1.0.0/component/newUserPopup/index.vue";
import TravelMultipleSelectDialog from "@/component/travel-multiple-select-dialog-for-create-order/index.vue";
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";

export default {
    data() {
        return {
            // 当前所属企业的companyId
            companyIdCurrent: null,
            loading: false,
            travelMultipleSelectContacts: [],   // 已选择联系人
            travelMultipleSelectUser: [],   // 已选择出行人
            type: 'create', // 'create' 新建 'edit' 编辑
            orderNo: '',
            form: JSON.parse(JSON.stringify(defaultForm)),
            rules: {
                'companyInfo.companyId': [
                    { required: true, message: '请选择所属企业', trigger: 'change' },
                ],
                'companyInfo.contactName': [
                    { required: true, message: '请填写联系人', trigger: 'blur' },
                ],
                'companyInfo.contactMobile': [
                    { required: true, message: '请填写联系电话', trigger: 'blur' },
                    {validator: this.checkMobile, trigger: 'blur'}
                ],
                'companyInfo.evectionType': [
                    { required: true, message: '请选择出行类型', trigger: 'change' },
                ],
                'companyInfo.payType': [
                    { required: true, message: '请选择支付方式', trigger: 'change' },
                ],
            },
            orderStatus: '',
            // 票务类型List
            ticketingList: [
                {
                    msg: '船票',
                    code: 0,
                },
                {
                    msg: '门票',
                    code: 1,
                },
            ],
            // 证件类型List
            certificateTypeList: [],
            // 支付方式List
            payTypeList: [],
            // 出行类型List
            evectionTypeList: [],
            // 所属企业List
            companyList: [],
            addNewPassengerLoading: false,
            submitLoading: false,
            selectedIdList: [],
            switchTraveler: '',


            testBox: '',
            psgNameTest: '',
            arriveTime: '',
            textarea2: '',

            activeUserList: [],
        }
    },
    components: {
        TravelMultipleSelectDialog,
        NewUserPopup, TrainCitySelector, DynamicForm, TravelerMultipleSelector, DictionarySelect},
    created() {
    },
    mounted() {

    },
    async activated() {
        await this.init();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        orderStatusToDisabled() {
            if (this.type == 'edit') {
                return true;
            }
            return false;
        },
    },
    filters: {},
    methods: {
        // 选择联系人
        addNewContacts() {
            if (!this.form.companyInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择所属企业'
                });
                return
            }
            this.switchTraveler = 'contacts';
            this.$refs.aTravelMultipleSelectDialog.init({selectType: '001'});
            this.selectedIdList = [];
            this.$refs.aTravelMultipleSelectDialog.show();
            this.activeUserList = this.travelMultipleSelectContacts;
        },
        // 选择出行人
        addNewPassenger() {
            if (!this.form.companyInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择所属企业'
                });
                return
            }
            this.switchTraveler = 'user';
            this.$refs.aTravelMultipleSelectDialog.init({selectType: '000'});
            this.selectedIdList = [];
            this.$refs.aTravelMultipleSelectDialog.show();
            this.activeUserList = this.travelMultipleSelectUser;
        },
        validateNumber(str) {
          // 匹配数字或保留一到两位小数的数字
          const reg = /^\d+(\.\d{1,2})?$/;

          // 如果传入的字符符合规则，则直接返回
          if (reg.test(str)) {
            return str;
          }

          // 如果不符合规则，则进行修正并返回
          let num = parseFloat(str.replace(/[^\d.]/g, ''));
          if (isNaN(num)) {
            num = '';
          } else {
            num = num.toFixed(2);
          }
          console.log(num);
          return num;
        },
        // 限制只能输入数字或小数点
        validateInput(item, index, value) {
            value = value.replace(/[^\d{1,}.\d{1,}|\d{1,}]/g, "");
            value = value.replace(/^(\-)*(\d+)\.(\d{2}).*$/, '$1$2.$3');
            this.form.companyBill[index][item] = value;
        },
        inputMatch(data,text) {
            console.log(data, eval(`${data}.match(${text}) == null ? '' : ${data}.match(${text})[0];`));
            eval(`${data} = ${data}.match(${text}) == null ? '' : ${data}.match(${text})[0];`);
        },
        inputReplace(data,text) {
            eval(`${data} = ${data}.replace(${text},'');`);
        },
        // 计算利润
        companyBillChange(index,item) {
            this.form.companyBill[index].profit = this.form.companyBill[index].serviceFee;
            this.form.companyBill[index].supPrice = this.form.companyBill[index].salesPrice;
        },
        addSuccess(data) {
            //新增成功后触发
            Dictionary.init(this, {
                typeGroupKey: ['tmc'],
                typeKey: [],
                parentId: 0
            });
        },
        getPayTypeList() {
            consumer_admin_flight_generate_payType().then(res => {
                this.payTypeList = res.datas
            })
        },
        getEvectionTypeList() {
            consumer_admin_flight_generate_evectionType().then(res => {
                this.evectionTypeList = res.datas
            })
        },
        async contactNameBlur() {
            await this.getCompanyList();
            if (this.companyList.length == 0) {
                const params = {
                    companyName: '',
                    currentPage: 1,
                    keyword: '',
                    pageSize: 999,
                    userName: '',
                };
                let res = await consumer_admin_flight_generate_getCompanyList(params);
                this.companyList = res.datas.list
            }
        },
        async getCompanyList() {
            const params = {
                companyName: '',
                currentPage: 1,
                keyword: '',
                pageSize: 999,
                userName: this.form.companyInfo.contactName,
            };
            let res = await consumer_admin_flight_generate_getCompanyList(params);
            this.companyList = res.datas.list
        },
        async init() {
            this.loading = true;
            this.form = JSON.parse(JSON.stringify(defaultForm));
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            });
            this.getEvectionTypeList();
            this.getCertificateType();
            this.getPayTypeList();
            await this.getCompanyList();
            const query = this.$route.query;
            this.type = query && query.type ? query.type : 'create';  // create edit
            let ticket = query && query.ticket ? query.ticket : '0';  // create edit
            if (this.type === 'edit' && query.id) {
                let data = {
                    id: query.id,
                    orderNo: null,
                };
                let [err, res] = await awaitWrap(consumer_admin_ship_details(data));
                if(err) {
                    this.loading = false;
                };
                if (res.code == '000000') {
                    this.form.order = res.datas.order;
                    this.form.companyInfo = res.datas.companyInfo;
                    this.form.companyBill = res.datas.companyBill;
                    // 获取乘机人的证件信息
                    let passengerIdList = [];
                    this.form.companyBill.forEach(value=>{
                        passengerIdList.push(value.userId);
                    })
                    this.loading = false;
                    /*let [pErr, pRes] = await awaitWrap(consumer_department_getStaffsAndExCusByUserIds({
                        companyId: this.form.companyInfo.companyId,
                        userIds: passengerIdList,
                        isSelectCustomer: true,
                        pageSize: 30,
                        currentPage: 1,
                    }));*/
                    let activeUserList = [];
                    this.form.companyBill.forEach(value=>{
                        const newUserItem = {
                            birthDay: '',
                            certificateName: value.certTypeText,
                            certificateType: value.certType,
                            certificateValue: value.certNum,
                            certificates: value.certificates,
                            countryCode: '',
                            countryName: null,
                            englishName: '',
                            isLoginUser: null,
                            name: value.userName,
                            phone: '',
                            shareWith: null,
                            tags: [],
                            type: 3,
                            typeValue: "员工",
                            unSensitiveCertificateValue: value.certNum,
                            unSensitivePhone: '',
                            userId: value.userId,
                        };
                        activeUserList.push(newUserItem);
                    })
                    this.activeUserList = activeUserList;
                    // 行程时间回填
                    this.form.order.journeyPeriod = res.datas.order.journeyPeriod.split('-');
                }
            } else {
                this.form.order.type = Number(`${ticket}`);
                this.loading = false;
            }
        },
        getCertificateType() {
            consumer_admin_flight_generate_certificateType().then(res => {
                let certificateTypeList = res.datas;
                this.certificateTypeList = [];
                certificateTypeList.forEach(value => {
                    if (value.msg == '护照' || value.msg == '身份证') {
                        value.value = parseInt(value.code);
                        this.certificateTypeList.push(value);
                    }
                    if (value.msg == '港澳通行证') {
                        value.value = parseInt('7');
                        this.certificateTypeList.push(value);
                    }
                    if (value.msg == '台湾通行证') {
                        value.value = parseInt('8');
                        this.certificateTypeList.push(value);
                    }
                });
            })
        },
        changeCompanyId(val) {
            let activeItem = null;
            this.companyList.forEach(value => {
                if (value.id === val) {
                    activeItem = value
                }
            });
            this.companyIdCurrent = val;
            this.form.companyInfo.companyName = activeItem.companyName;
            if (this.type === 'edit') {
                return;
            }
            // 更新初始化用户选择组件
            this.$refs.aTravelMultipleSelectDialog.setCompany(val);
            this.$refs.aTravelMultipleSelectDialog.init();
        },
        clearCompany() {
            // this.form.companyInfo.contactName = '';
            // this.form.companyInfo.contactMobile = '';
        },
        // 出行人--添加一个
        addNewPassengerRequestItem() {
            if (!this.form.companyInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择公司'
                })
                return;
            }
            this.$refs.aNewUserPopup.init({companyId: this.form.companyInfo.companyId});
            this.$refs.aNewUserPopup.show();
            /*this.addNewPassengerLoading = true;
            const newItem = {
                id: null,
                orderId: null,
                companyId: null,
                userId: null,
                userName: "",
                certId: null,
                companyType: 'cr',
                grade: "",
                status: 0,
                seatNumber: "",   //座位号
                salesPrice: null,   //销售价 == 票价
                supPrice: null,     //采购价 == 票价
                serviceFee: null,
                find: 0,
                findTime: "",
                certNum: "",
                certType: 1,


                profit: null,   //利润
                certificates: [],
            };
            this.form.companyBill.splice(index + 1, 0, newItem);
            this.addNewPassengerLoading = false*/
        },
        getNewUser(newUser) {
            const newUserItem = {
                birthDay: newUser.birthDate,
                certificateName: "",
                certificateType: newUser.certificateType,
                certificateValue: newUser.certificateValue,
                certificates: [
                    {
                        "certificateValue": newUser.certificateType,
                        "certificateText": "",
                        "certificate": newUser.certificateValue,
                        "unSensitiveCertificateValue": newUser.certificateValue,
                        "countryCode": newUser.countryCode,
                        "countryName": "",
                        "issCountryCode": newUser.issCountryCode,
                        "issCountryName": "",
                        "cardExpireDate": newUser.passengerExpireDate
                    }
                ],
                countryCode: null,
                countryName: null,
                englishName: "",
                isLoginUser: null,
                name: newUser.name,
                phone: newUser.phone,
                shareWith: null,
                tags: [],
                type: 3,
                typeValue: "员工",
                unSensitiveCertificateValue: newUser.certificateValue,
                unSensitivePhone: newUser.phone,
                userId: newUser.id,
            };
            const newItem = {
                id: null,
                orderId: null,
                companyId: null,
                userId: null,
                userName: "",
                certId: newUser.id,
                companyType: 'cr',
                grade: "",
                status: 0,
                seatNumber: "",   //座位号
                salesPrice: null,   //销售价 == 票价
                supPrice: null,     //采购价 == 票价
                serviceFee: null,
                find: 0,
                findTime: "",
                certNum: newUser.certificateValue,
                certType: newUser.certificateType,


                profit: null,   //利润
                certificates: [
                    {
                        "certificateValue": newUser.certificateType,
                        "certificateText": "",
                        "certificate": newUser.certificateValue,
                        "unSensitiveCertificateValue": newUser.certificateValue,
                        "countryCode": newUser.countryCode,
                        "countryName": "",
                        "issCountryCode": newUser.issCountryCode,
                        "issCountryName": "",
                        "cardExpireDate": newUser.passengerExpireDate
                    }
                ]
            };
            this.form.companyBill.push(newItem);
            this.activeUserList.push(newUserItem);
        },
        // 删除出行人--一个
        deletePassengerRequestItem(item, index) {
            this.form.companyBill.splice(index, 1);
            this.activeUserList.splice(index, 1);
        },
        payTypeChange() {
            this.$refs.form.clearValidate(['order.paymentType','order.paymentTime','order.paymentNum'])
        },
        // 保存
        onSubmit() {
            let isPass = false;
            let unValidObj = null;
            this.$refs.form.validate((valid, obj) => {
                isPass = valid;
                unValidObj = obj;
            });
            if (isPass === true) {
                // 校验新增出行人信息是否重复
                /*let setUserBoxText = '';
                let userCert = [];
                let userName = [];
                let userBox = [];
                this.form.companyBill.forEach((value, index) => {
                    userCert.push(`${value.certType}-${value.certNum}`);
                    userName.push(value.userName);
                    userBox.push('');
                });
                userCert.forEach((Cvalue, Cindex) => {
                    let num = 0;
                    let testNum = '';
                    this.form.companyBill.forEach((value, index) => {
                        if (`${value.certType}-${value.certNum}` == Cvalue) {
                            num++;
                            if (num >= 2) {
                                testNum = `${testNum}-${index}+${value.userName}`;
                                userBox[Cindex] = testNum;
                            } else {
                                testNum = `${index}+${value.userName}`;
                            }
                        }
                    });
                });

                let setUserBox = Array.from(new Set(userBox));

                setUserBox.forEach((item,index) => {
                    if (item != '') {
                        let textBox = '';
                        item.split('-').forEach((SItem,SIndex) => {
                            if (textBox == '') {
                                textBox = ` 第${Number(SItem.split('+')[0]) + 1}行的${SItem.split('+')[1]}`;
                            } else {
                                textBox = `${textBox} 和 第${Number(SItem.split('+')[0]) + 1}行的${SItem.split('+')[1]}`;
                            }
                        });
                        setUserBoxText += `<p style="color: red;">${textBox} 重复`;
                    }
                });
                if (setUserBoxText != '') {
                    setUserBoxText += '；</p>';
                    this.$alert(setUserBoxText, '客户信息', {
                        confirmButtonText: '确定',
                        dangerouslyUseHTMLString: true,
                    });
                } else {
                    this.addOrder();
                }*/
                this.addOrder();
            } else {
                let tipsStr = '';
                if (!isPass) {
                    const unValidKeys = Object.keys(unValidObj);
                    unValidKeys.forEach((item, index) => {
                        let tmpList = item.split('.');
                        let len = tmpList.length;

                        if (index > 0) {
                            tipsStr += '；</p>';
                        }

                        if (len === 2) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}-${tipsObj[tmpList[1]]}`;
                        }
                        if (len === 3) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}第${Number(tmpList[1]) + 1}行-${tipsObj[tmpList[2]]}`;
                        }
                        if (len === 5) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}第${Number(tmpList[1]) + 1}行-${tipsObj[tmpList[2]]}第${Number(tmpList[3]) + 1}行程-${tipsObj[tmpList[4]]}`;
                        }
                    });
                    tipsStr += '；</p>';
                }
                this.$alert(tipsStr, '以下信息未填写', {
                    confirmButtonText: '确定',
                    dangerouslyUseHTMLString: true,
                });
            }
        },
        addOrder() {
            let dataBox = {
                order: {
                  ...this.form.order,
                  journeyPeriod: `${this.form.order.journeyPeriod[0]}-${this.form.order.journeyPeriod[1]}`,
                },
                companyInfo: this.form.companyInfo,
                companyBill: this.form.companyBill,
            };
            this.submitLoading = true;
            this.loading = true;
            consumer_admin_ship_save(dataBox).then((res) => {
                this.$message({
                    type: "success",
                    message: '保存成功'
                });
                if (this.type == 'edit') {
                    this.$router.replace({
                        name: 'admin-ticket-order-list'
                    })
                } else {
                    // 门票船票确认页面
                    this.$router.push({
                        name: "admin-ticket-create-order-pay",
                        query: {
                            id: res.datas.order.id,
                            orderNo: res.datas.order.orderNo,
                            behalfCompanyId: res.datas.companyInfo.companyId,
                        }
                    })
                }
            }).catch((rej) => {
                this.$message.error(`${rej.msg}`);
            }).finally(() => {
                this.submitLoading = false;
                this.loading = false;
            });
        },
        back() {
            this.$router.go(-1)
        },
        greaterThanZero(rule, value, callback, tip) {
            if (Number(value) <= 0) {
                callback(new Error(`${tip}必须大于 0`));
            }
            callback();
        },
        greaterThanOrEqualToZero(rule, value, callback, tip) {
            if (Number(value) < 0) {
                callback(new Error(`${tip}不能为负数`));
            }
            callback();
        },
        changeEvectionType(val) {
            if (this.form.companyInfo.evectionType == 1) {
                this.form.companyInfo.payType = null;
                this.payTypeList = this.payTypeList.filter(item => item.msg !== '授信支付');
            }
            if (this.form.companyInfo.evectionType == 2 && this.payTypeList.indexOf({code: 1,msg: "授信支付"}) == -1) {
                this.payTypeList = [{code: 1,msg: "授信支付"},...this.payTypeList];
            }
        },
        changeCertificateType(item, index, val) {
            this.certificateTypeList.forEach(value => {
                if (value.value === val) {
                    item.cardTypeText = value.msg
                }
            });
            item.certNum = '';
            item.certificates.forEach((CItem,CIndex) => {
                if (CItem.certificateText == item.cardTypeText) {
                    this.form.companyBill[index].certNum = CItem.certificate;
                }
            });
        },
        cardNoValidator(rule, value, callback, index, item) {
            let cardType = item.cardType;
            const date = `${value.substr(6, 4)}-${value.substr(10, 2)}-${value.substr(12, 2)}`;
            if (cardType === 1) {
                if (this.isChineseIDCardNumber(value) === false) {
                    callback(new Error('请输入正确的身份证号码'));
                    return;
                } else {
                    this.$set(item, 'birthday', date);
                    callback();
                }
            } else {
                callback();
            }
            // this.$set(item, 'birthday', date);
        },
        inputValidator(rule, value, callback, index, item) {
            if (value == 0) {
                callback(new Error('请输入大于0的数'));
                return;
            }
            callback();
        },
        // 身份证号码验证
        isValidDate(year, month, day) {
            const date = new Date(year, month - 1, day);
            return (
                date.getFullYear() === year
                && date.getMonth() + 1 === month
                && date.getDate() === day
                && date.getTime() < new Date().getTime()
            );
        },
        isChineseIDCardNumber(value) {
            const testRegExp = /^[1-9]([0-9]{14}|[0-9]{16}[0-9Xx])$/;
            const areaMap = [11, 12, 13, 14, 15, 21, 22, 23, 31, 32, 33, 34, 35, 36, 37, 41, 42, 43, 44, 45, 46, 50, 51, 52, 53, 54, 61, 62, 63, 64, 65, 71, 81, 82];
            const weightMap = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            const codeMap = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
            const len = value.length;
            if (len !== 18) {
                return false;
            }
            // 模式校验
            if (!testRegExp.test(value)) {
                return false;
            }
            // 地区校验
            if (areaMap.indexOf(+value.substr(0, 2)) === -1) {
                return false;
            }
            // 18 位
            if (!this.isValidDate(+value.substr(6, 4), +value.substr(10, 2), +value.substr(12, 2))) {
                return false;
            }
            // 校验码
            const sum = value.split('').slice(0, 17).reduce((s, num, index) => {
                return s += +num * weightMap[index];
            }, 0);
            return codeMap[sum % 11] === value[17].toUpperCase();
        },
        checkMobile(rule, value, callback) {
            if (!isMobile(value)) {
                callback(new Error('只能输入长度不能大于11的数字'));
                return
            }
            callback();
        },
        getUserList(args) {
            if (this.switchTraveler == 'contacts') {
                this.travelMultipleSelectContacts = this.activeUserList;
            }
            if (this.switchTraveler == 'user') {
                this.travelMultipleSelectUser = this.activeUserList;
            }
            if (this.switchTraveler === 'contacts') {
                args.activeDetailList.forEach(value => {
                    this.form.companyInfo.contactName = value.name;
                    this.form.companyInfo.contactMobile = value.phone;
                    this.$nextTick(() => {
                        this.$refs.form.validateField(['companyInfo.contactName', 'companyInfo.contactMobile'])
                    })
                })
            } else {
                let companyBill = [];
                args.activeDetailList.forEach(value => {
                    // 身份证 - 护照 - 港澳 -  台
                    let certificates = [null,null,null,null];
                    value.certificates.forEach((item,index) => {
                        if (item.certificateValue === 1) {
                            certificates[0] = {...item};
                        }
                        if (item.certificateValue === 2) {
                            certificates[1] = {...item};
                        }
                        if (item.certificateValue === 3) {
                            certificates[2] = {...item};
                            certificates[2].certificateValue = 7;
                        }
                        if (item.certificateValue === 8) {
                            certificates[3] = {...item};
                        }
                    });
                    certificates = certificates.filter(item => item != null);
                    const item = {
                        id: null,
                        orderId: null,
                        companyId: this.form.companyInfo.companyId,
                        userId: value.userId,
                        userName: value.name,
                        certId: null,
                        companyType: 'cr',
                        grade: "",
                        status: 0,
                        seatNumber: "",   //座位号
                        salesPrice: null,   //销售价 == 票价
                        supPrice: null,     //采购价 == 票价
                        serviceFee: null,
                        find: 0,
                        findTime: "",
                        certNum: certificates[0].certificate,
                        certType: certificates[0].certificateValue,

                        profit: null,   //利润
                        certificates: certificates,
                    };
                    companyBill.push(item);
                    this.form.companyBill = companyBill && companyBill.length == 0 ? JSON.parse(JSON.stringify(defaultForm)).companyBill : companyBill;
                })
            }
        },
    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Upload from "../../component/upload/import_latest_version_module";
import consumer_air_ticket_refChaReasonEnum from "@/lib/data-service/haolv-default/consumer_air_ticket_refChaReasonEnum";
// import consumer_air_ticket_getFlightRulesList from '@/lib/data-service/haolv-default/consumer_air_ticket_getFlightRulesList';
export default {
    data() {
        return {
            imgUrl: [],
            rule: {
                code: [
                    { required: true, message: '退票原因不能为空', trigger: 'change' },
                ],
                explainMsg: [
                    { required: true, message: '退票原因不能为空', trigger: 'change' },
                ]
            },
            refReasonEnum: [],
        };
    },
    components: {
        Upload
    },
    props: {
        formData: Object
    },
    methods: {
        checkForm() {
            return new Promise((resolve, reject) => {
                this.$refs['refundForm'].validate((valid) => {
                    resolve(valid)
                })
            })
        },
        getRefReasonEnum() {
            consumer_air_ticket_refChaReasonEnum({'bizType': 3}).then(res=>{
                let refReasonEnum = res.datas;
                refReasonEnum.forEach(value=>{
                    let text = '';
                    if (value.reasonType === 1) {
                        text+= '个人原因：'
                    }
                    if (value.reasonType === 2) {
                        text+= '航司原因：'
                    }
                    text+= value.msg;
                    text+=value.refReason === 1 ? '（自愿退票）' : '（非自愿退票）'
                    console.log(text);
                    value.text = text;
                })
                this.refReasonEnum = refReasonEnum;
            })
        },
        changeRefReason(val) {
            console.log(val, this.segmentList)
            this.refReasonEnum.forEach(value => {
                if (value.code === val) {
                    this.formData.refReason = value.refReason;
                    this.formData.refReasonText = value.refReason === 1 ? '自愿退票' : '非自愿退票';
                    this.formData.code = value.code;
                    this.formData.remarkText = value.msg;
                    this.formData.remarkText1 = value.msg + '（' + this.formData.refReasonText + '）';
                }
            })

            // 计算
            /*let segmentLength = 0;
            let psgNos = [];
            let cabin = '';
            let flightNo = '';
            let depDate = '';
            let depTime = '';
            for(let i in this.segmentList) {
                segmentLength = i + 1;
                this.segmentList[i].forEach(value => {
                    if (psgNos.indexOf(value.psgNo) === -1) {
                        psgNos.push(value.psgNo)
                    }
                    cabin = value.cabin;
                    flightNo = value.flightNo;
                    depDate = value.depDate;
                    depTime = value.depTime;
                });
            }
            if (segmentLength > 0) {
                let params = {
                    cabinCode: cabin,
                    flightNo: flightNo,
                    orderNo: this.$route.query.orderNo,
                    dateTime: `${depDate} ${depTime}`,
                    psgNos,
                    refReason: this.formData.refReason
                };
                consumer_air_ticket_getFlightRulesList(params).then(res => {
                    this.refundAmount = res.datas.refundAmount
                })
            } else {
                this.refundAmount = 0
            }*/
        },
    },
    created() {},
    mounted() {
        this.getRefReasonEnum();
    },
    activated() {

    },
    watch: {
        formData(val) {
            console.log(val);
            this.$emit("update:formData", val);
        },
        imgUrl(val) {
            this.formData.url = val.join(",");
            console.log(this.formData);
            this.$emit("update:formData", this.formData);
        }
    },
    computed: {}
};

import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'

import consumer_admin_offline_car_initCount from '@/lib/data-service/haolv-default/consumer_admin_offline_car_initCount';
import consumer_admin_offline_car_list from '@/lib/data-service/haolv-default/consumer_admin_offline_car_list';
import consumer_admin_offline_car_action from '@/lib/data-service/haolv-default/consumer_admin_offline_car_action';
import consumer_admin_offline_car_cancelBill from '@/lib/data-service/haolv-default/consumer_admin_offline_car_cancelBill';
import consumer_admin_offline_car_details from "@/lib/data-service/haolv-default/consumer_admin_offline_car_details";   // 订单详情
import DictionarySelect
    from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';

import moment from "moment";
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";

export default {
    data() {
        const validateFine = (rule, value, callback) => {
            if (value >= 0) {
                callback();
            } else {
                callback(new Error('不能小于0'));
            }
        };
        return {
            confirmType: true,
            moment: moment,
            loading: false,
            // chooseTypeDialogVisible: false,
            issueDialogVisible: false,
            curOrder: {},
            orderChannelList: [], //采购渠道
            useCarTime: [],   //用车时间
            gmtCreate: [],   //创建时间
            updateTime: [],   //操作时间
            searchForm: {
                orderNo: '',   //订单编号
                companyText: '',//联系人
                companyName: '',//所属企业
                orderSource: '',//采购渠道
                journey: '',//行程
                defCarType: '',//车型
                useCarTimeBegin: '',//用车时间-开始
                useCarTimeEnd: '',//用车时间-结束
                updateTimeBegin: '',//操作时间-开始
                updateTimeEnd: '',//操作时间-结束
            },
            pageBean: {
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            },
            totalCount: 0,   //总条数
            orderSourceList: [],
            journeyDate: [],//行程日期

            activeName: '-1',
            tabList: [],
            tabNumberList: [],
            tableLoading: false,
            orderList: [],

            confirmForm: {},
            progressList: [],
            progressForm: {},
            visaBillList: [],
            confirmRules: {
                orderSource: [
                    {required: true, message: '请选择采购渠道', trigger: 'blur'},
                ],
                /*supOrderNo: [
                    {required: true, message: '请输入交易号', trigger: 'blur'},
                ],*/
                paymentType: [
                    {required: true, message: '请选择付款方式', trigger: 'blur'},
                ],
                paymentTime: [
                    {required: true, message: '请选择付款时间', trigger: 'blur'},
                ],
                paymentNumber: [
                    {required: true, message: '请输入付款流水号', trigger: 'blur'},
                ],

            },
            cancelDialogVisible: false, // 取消用车弹窗开关
            cancelForm: {
                id: '', // 临时存放单个id
                ids: null, //array
                fine: 0,
                refundAmount: 0,
                info: {},
            }, // 取消用车表单
            cancelRules: {
                fine: [
                    {required: true, message: '请填写罚金', trigger: 'blur'},
                    { validator: validateFine, trigger: 'blur' }
                ],
            }, // 取消用车表单规则
            cancelDialogVisible2: false, // 取消用车二次确认窗开关
            cancelLoading: false,
            dialogLoading: false,
        }
    },
    components: {
        SearchBox,
        Pagination,
        DictionarySelect
    },
    created() {
        // this.initDictionarySelect();
    },
    async activated() {
        await this.getTabNum();
        await this.getOrderList();
    },
    filters: {
        filterAmount(val) {
            if (val && val >= 0) {
                return parseFloat(val).toFixed(2);
            } else {
                return '0.00';
            }
        },
    },
    methods: {
        driverJudge(row) {
            let box = false;
            if (row.driverName || row.driverPhone || row.carNumber) {
                box = true;
            }
            return box;
        },
        validatorDriverName(rule, value, callback, index, item) {
            callback(new Error('请输入正确的身份证号码'));
        },

        // 只能输入英文或数字
        handleInput(item, value) {
            // 使用正则表达式匹配字母和数字
            const reg = /^[a-zA-Z0-9]+$/;
            // 如果输入值不符合规定的正则表达式，则将inputValue重置为上一个值
            if (!reg.test(value)) {
                this.confirmForm[item] = this.confirmForm[item].replace(/[^\a-\z\A-\Z0-9]/g, '');
            }
        },
        //初始化字段下拉框组件
        initDictionarySelect() {
            console.log(Dictionary);
            Dictionary.init(this, {
                typeGroupKey: ['tmc'],
                typeKey: [],
                parentId: 0
            });
        },

        //后台生单
        toRecordOrder() {
            // this.chooseTypeDialogVisible = true;
            this.$router.push({
                name: 'admin-offline-car-create-order',
                query: {}
            });
        },

        //选择票务类型
        /*chooseType(type) {
            this.chooseTypeDialogVisible = false;
            this.$router.push({
                name: 'admin-ticket-create-order',
                query: {
                    ticket: type
                }
            });
        },*/

        //获取每个tab的数值
        async getTabNum() {
            this.tabList = [
                /*{
                    name: -1,
                    label: '全部',
                    value: 0
                }*/
            ];
            let res = await consumer_admin_offline_car_initCount();
            this.tabList = [...this.tabList, ...res.datas];
        },

        //获取订单列表
        async getOrderList() {
            try {
                this.tableLoading = true;
                const res = await consumer_admin_offline_car_list({ent: this.searchForm, pageBean: this.pageBean});
                this.orderList = res.datas.list;
                if (this.orderList) {
                    let defCarTypeText = '';
                    let orderSourceText = '';
                    this.orderList.forEach(async (item) => {
                        defCarTypeText = await Dictionary.returnDictionary('tmc', 'defCarType', item.defCarType);
                        this.$set(item, 'defCarTypeText', defCarTypeText.name);

                        orderSourceText = await Dictionary.returnDictionary('tmc', 'carOrderSource', item.orderSource);
                        this.$set(item, 'orderSourceText', orderSourceText.name);
                    })
                }
                this.totalCount = res.datas.totalCount;
                let curTab = {};
                /*if (this.activeName === '-1') {
                    curTab = this.tabList[0];
                    curTab.value = 0;
                    this.tabList.forEach(tab => {
                        curTab.value += tab.value;
                    })
                } else {
                    curTab = this.tabList.forEach(item => {
                        return item.name === this.activeName;
                    });
                    curTab.value = this.totalCount;
                }*/
            } catch (error) {
                this.orderList = [];
                this.totalCount = 0;
                this.$message({
                    type: "error",
                    message: "获取订单列表失败！"
                })
            } finally {
                this.$nextTick(() => {
                    this.$refs.mainTable.doLayout()
                })
                this.tableLoading = false
            }
        },

        //搜索
        onSearch() {
            this.searchForm.status = this.activeName === '-1' ? '' : this.activeName;
            this.pageBean.currentPage = 1
            this.getTabNum();
            this.getOrderList()
        },

        //重置
        onReset() {
            this.searchForm = {
                orderNo: '',   //订单编号
                companyText: '',//联系人
                companyName: '',//所属企业
                orderSource: '',//采购渠道
                journey: '',//行程
                defCarType: '',//车型
                useCarTimeBegin: '',//用车时间-开始
                useCarTimeEnd: '',//用车时间-结束
                updateTimeBegin: '',//操作时间-开始
                updateTimeEnd: '',//操作时间-结束
            };
            this.pageBean = {
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            };
            this.journeyDate = [];  //预计出签时间
            this.gmtCreate = [];  //创建时间
            this.updateTime = [];   //操作时间
            this.onSearch()
        },

        //设置出差单类型样式
        getEvectionTypeToClass(val) {
            //出差单类型：1.因私，2.因公
            switch (val) {
                case (1):
                    return 'grey'
                case (2):
                    return 'orange'
                default:
                    return ''
            }
        },

        //修改用车时间
        changeUseCarTime(val) {
            if (!val) {
                this.searchForm.useCarTimeBegin = '';
                this.searchForm.useCarTimeEnd = '';
            } else {
                this.searchForm.useCarTimeBegin = val[0] + ' 00:00:00';
                this.searchForm.useCarTimeEnd = val[1] + ' 23:59:59';
            }
        },

        //修改操作时间
        changeUpdateTime(val) {
            if (!val) {
                this.searchForm.updateTimeBegin = '';
                this.searchForm.updateTimeEnd = '';
            } else {
                this.searchForm.updateTimeBegin = val[0] + ' 00:00:00';
                this.searchForm.updateTimeEnd = val[1] + ' 23:59:59';
            }
        },

        //查看订单
        handleDetailClick(val) {
            this.$router.push({
                name: 'admin-offline-car-order-detail',
                query: {
                    id: val.id,
                    orderNo: val.orderNo
                }
            });
        },

        //取消订单
        handleCancelClick(val) {
            this.$confirm("确定取消此用车订单？", "确认取消", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.loading = true;
                let params = {
                    id: val.id,
                    orderNo: val.orderNo,
                    status: 3
                };
                consumer_admin_offline_car_action(params).then((res) => {
                    this.$message({
                        type: 'success',
                        message: '取消订单成功！'
                    })
                    this.getTabNum();
                    this.getOrderList();
                }).finally(() => {
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                })
            }).catch(() => {
            })
        },

        //取消用车

        //去支付
        handlePayClick(val) {
            this.$router.push({
                name: "admin-offline-car-create-order-pay",
                query: {
                    id: val.id,
                    orderNo: val.orderNo,
                    behalfCompanyId: val.companyId
                }
            })
        },

        //编辑
        handleEditClick(val) {
            this.$router.push({
                name: "admin-offline-car-create-order",
                query: {
                    type: 'edit',
                    id: val.id
                }
            })
        },

        //出票操作-显示弹框
        async onShowConfirmDialog(row,switchBox) {

            console.log(row);
            // this.confirmForm = JSON.parse(JSON.stringify(row));
            this.issueDialogVisible = true;
            this.dialogLoading = true;
            this.confirmForm = {
                type: '2'
            };
            let [err, res] = await awaitWrap(consumer_admin_offline_car_details({
                id: row.id,
                orderNo: row.orderNo,
            }))
            if (err) {
                this.dialogLoading = false;
            }
            this.dialogLoading = false;
            // let paymentTypeText = await Dictionary.returnDictionary('tmc', 'paymentType', res.datas.order.paymentType);
            this.confirmForm = {
                "id": row.id,
                "orderNo": row.orderNo,
                "status": row.status,
                orderSource: res.datas.order.orderSource,
                "supOrderNo": res.datas.order.supOrderNo,
                "paymentType": res.datas.order.paymentType || 'ALIPAY',
                "paymentTime": res.datas.order.paymentTime || moment().format('yyyy-MM-DD HH:mm:ss'),
                "paymentNum": res.datas.order.paymentNumber,
                "remark": res.datas.order.remark,
                "driverName": res.datas.journeyInfo.driverName,
                "driverPhone": res.datas.journeyInfo.driverPhone,
                "carNumber": res.datas.journeyInfo.carNumber,
                "carType": res.datas.journeyInfo.carType,
                "carColor": res.datas.journeyInfo.carColor,
                type: '4',
                orderMoney: row.orderMoney,
                payType: row.payType,
                paymentTypeText: row.paymentTypeText || '',
            };
            this.confirmType = switchBox;
            if (this.confirmType === false) {
                this.confirmForm.type = '2';
            }
        },

        //出票操作-提交确定操作
        async confirmSubmit() {
            let params = {};
            if (this.confirmForm.type !== '3' ) {
                console.log('2222')
                this.$refs['confirmForm'].validate(async (valid) => {
                    if (valid) {
                        this.loading = true;
                        /*params = {
                            id: this.confirmForm.id,
                            orderNo: this.confirmForm.orderNo,
                            status: parseInt(this.confirmForm.type),
                            supOrderNo: this.confirmForm.supOrderNo,
                            paymentType: this.confirmForm.paymentType || 0,
                            paymentTime: this.confirmForm.paymentTime,
                            paymentNum: this.confirmForm.paymentNumber,
                            remark: this.confirmForm.remark,
                        };*/
                        params = JSON.parse(JSON.stringify(this.confirmForm));
                        params.status = parseInt(this.confirmForm.type);
                        await consumer_admin_offline_car_action(params);
                        this.$message.success("保存成功");
                        this.issueDialogVisible = false;
                        this.getTabNum();
                        await this.getOrderList();
                        this.loading = false;
                    }
                })
            } else {
                this.loading = true;
                params = {
                    id: this.confirmForm.id,
                    orderNo: this.confirmForm.orderNo,
                    status: 3,
                    remark: this.confirmForm.remark,
                };
                await consumer_admin_offline_car_action(params);
                this.$message.success("取消出票成功");
                this.issueDialogVisible = false;
                this.getTabNum();
                await this.getOrderList();
                this.loading = false;
            }
        },

        // 取消用车
        handleCancelCarClick(val) {
            this.cancelForm = {
                orderNo: val.orderNo,
                id: val.id, // 临时存放单个id
                ids: null, //array
                fine: 0,
                refundAmount: 0,
                info: val, // 临时存此项信息
            };
            this.cancelDialogVisible = true;
        },
        // 弹窗二次确认取消用车
        cancelSubmit() {
            this.$refs.cancelForm.validate(async (valid) =>{
                if (!valid) {
                    return;
                }
                this.cancelForm.ids = [this.cancelForm.id];
                this.cancelForm.refundAmount = this.cancelForm.info.salesPrice - this.cancelForm.fine;
                this.cancelDialogVisible2 = true;
            })
        },
        // 最终确认取消用车
        cancelSubmitAgain() {
            this.cancelLoading = true;
            let cancelForm = JSON.parse(JSON.stringify(this.cancelForm));
            delete cancelForm.info;
            consumer_admin_offline_car_cancelBill(cancelForm).then(res=>{
                this.cancelLoading = false;
                this.cancelDialogVisible2 = false;
                this.cancelDialogVisible = false;
                this.$message.success('取消用车成功');
                this.getTabNum();
                this.getOrderList();
            }).catch(e=>{
                this.cancelLoading = false;
            })
        }
    }
}

// consumer_admin_insurance_orderTrainsInsuranceAgain  客服平台-火车票重新投保
//https://www.apifox.cn/apidoc/shared-c91c112c-ac85-4f14-8607-3d32d61d8a80/api-23296765
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_insurance_orderTrainsInsuranceAgain = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/insurance/orderTrainsInsuranceAgain',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};

module.exports = consumer_admin_insurance_orderTrainsInsuranceAgain;
// 企业信息组件
import CompanyInfo from '@/component/company-info/import_latest_version_module';
// 城市组件
import HotelCitySelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-city-selector/1.1.0/index.vue";

// 品牌列表
import consumer_hotel_search_brandList from "@/lib/data-service/haolv-default/consumer_hotel_search_brandList.js";
// 酒店列表
import consumer_admin_hotel_order_hotelList from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_hotelList.js";
// 酒店最低价
import consumer_admin_hotel_order_getHotelMinPrice from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_getHotelMinPrice.js";

// 出差单详情
import consumer_journey_getEvectionDetail from "@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail.js";
import moment from "moment";

export default {
    data() {
        return {
            loading: false,
            query: {},
            toCity: null,
            activeTab: "1",
            sortType: "1",
            detail: {},
            form: {
                pageSize: 10,
                currentPage: 1,
                cityId: "",
                cityName: "",
                checkInDate: "",
                checkOutDate: "",
                keyword: "",
                hotelBrand: "",
                hotelStar: "",
                hotelTheme: "",
                hotelPrice: "",
                productType: 3,
                countryType: 1,
                salesSort: "",
                priceSort: '',
                maxValue: '',
                minValue: '',
                behalfCompanyId: ''
            },
            priceRangeObj: {
                maxValue: '',
                minValue: '',
            },
            total: 0,
            salesSort: "desc",
            priceSort: "asc",
            list: [],
            hotelIds: [],
            priceList: [],
            hotelBrand: [],
            classifyType: [
                {
                    label: "酒店主题",
                    value: "1",
                    isFold: true,
                    navList: [
                        {
                            value: "",
                            label: "不限",
                        },
                        {
                            value: "biz",
                            label: "商务酒店",
                        },
                        {
                            value: "spa",
                            label: "温泉SPA",
                        },
                        {
                            value: "economy",
                            label: "经济型酒店",
                        },
                        {
                            value: "vacation",
                            label: "山水名胜",
                        },
                        {
                            value: "huiyi",
                            label: "会议酒店",
                        },
                        {
                            value: "1hx",
                            label: "冰雪假期",
                        },
                        {
                            value: "2hb",
                            label: "阳光沙滩",
                        },
                        {
                            value: "3dm",
                            label: "大漠绿洲",
                        },
                        {
                            value: "4gz",
                            label: "古镇风情",
                        },
                        {
                            value: "5dj",
                            label: "高尔夫度假",
                        },
                        {
                            value: "6bw",
                            label: "台湾风情",
                        },
                        {
                            value: "7ds",
                            label: "都市购物",
                        },
                        {
                            value: "8hd",
                            label: "海岛休闲",
                        },
                        {
                            value: "9zt",
                            label: "主题乐园",
                        },
                        {
                            value: "10gd",
                            label: "高端奢华",
                        },
                        {
                            value: "11sw",
                            label: "商务精选",
                        },
                        {
                            value: "12bc",
                            label: "博彩娱乐",
                        },
                    ],
                },
                {
                    label: "星级",
                    value: "2",
                    navList: [
                        {
                            value: "",
                            label: "不限",
                        },
                        {
                            value: "2",
                            label: "经济型",
                        },
                        {
                            value: "3",
                            label: "舒适型",
                        },
                        {
                            value: "4",
                            label: "高档型",
                        },
                        {
                            value: "5",
                            label: "豪华型",
                        },
                    ],
                },
                {
                    label: "品牌",
                    value: "3",
                    isFold: true,
                    navList: [],
                },
                {
                    label: "价格",
                    value: "4",
                    navList: [
                        {
                            value: "",
                            label: "不限",
                            minValue: 0,
                            maxValue: 100000,
                        },
                        {
                            value: "1",
                            label: "150以下",
                            minValue: 0,
                            maxValue: 150,
                        },
                        {
                            value: "2",
                            label: "150-300",
                            minValue: 150,
                            maxValue: 300,
                        },
                        {
                            value: "3",
                            label: "300-450",
                            minValue: 300,
                            maxValue: 450,
                        },
                        {
                            value: "4",
                            label: "600以上",
                            minValue: 600,
                            maxValue: 100000,
                        },
                    ],
                },
            ],
            checkInRange: {
                disabledDate: this.disabledCheckInDate
            },
            checkOutRange: {
                disabledDate: this.disabledDate,
            },
            pageCount: 0,
            isNoMore: false,//是否已加载到最后
            isAvailablePrice: true,
            isMorning: false,//当前时间是否为凌晨00:00-06:00
        };
    },
    computed: {
        disCheckIn() {
            const now = this.$moment().format("YYYY-MM-DD");
            const to = this.form.checkInDate;
            if (now === to) {
                return "今天";
            } else {
                const week = this.$moment(to).day();
                switch (week) {
                    case 0:
                        return "周日";
                    case 1:
                        return "周一";
                    case 2:
                        return "周二";
                    case 3:
                        return "周三";
                    case 4:
                        return "周四";
                    case 5:
                        return "周五";
                    case 6:
                        return "周六";
                }
            }
        },
        disCheckOut() {
            const now = this.$moment().format("YYYY-MM-DD");
            const to = this.form.checkOutDate;
            if (now === to) {
                return "今天";
            } else {
                const week = this.$moment(to).day();
                switch (week) {
                    case 0:
                        return "周日";
                    case 1:
                        return "周一";
                    case 2:
                        return "周二";
                    case 3:
                        return "周三";
                    case 4:
                        return "周四";
                    case 5:
                        return "周五";
                    case 6:
                        return "周六";
                }
            }
        },
    },
    components: {
        CompanyInfo,
        HotelCitySelector,
    },
    methods: {
        disabledCheckInDate(time) {
            if (this.isMorning) {
                //如果在凌晨(00:00-06:00)时段进入页面，设置昨天以前的时间不可选
                let yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                let timestamp = yesterday.getTime();
                return time.getTime() < (timestamp - 8.64e7);
            } else {
                //如果在非凌晨(00:00-06:00)时段进入页面，设置当前时间后的时间不可选
                return time.getTime() < (Date.now() - 8.64e7);
            }
        },
        disabledDate(time) {
            const form = this.form;
            const end = moment(time).format("YYYYMMDD");
            if (end <= moment(form.checkInDate).format("YYYYMMDD")) return true;
            if (
                end >
                moment(form.checkInDate)
                    .add(30, "days")
                    .format("YYYYMMDD")
            )
                return true;
        },
        checkIsNoMore() {
            let isNoMore = false;
            if (this.form.currentPage >= this.pageCount || this.list.length >= 1001) {
                isNoMore = true;
            }
            this.isNoMore = isNoMore;
        },
        // 酒店列表
        async req_list(params) {
            if (!this.isNoMore) {
                this.loading = true;
                try {
                    const hotelListResult = await consumer_admin_hotel_order_hotelList(params)
                    const dataMap = hotelListResult.dataMap;
                    const list = dataMap.rows;
                    this.total = dataMap.total;
                    this.pageCount = dataMap.pageCount ? dataMap.pageCount : 0;
                    this.checkIsNoMore();
                    if (list.length) {
                        list.forEach(item => {
                            item.minPriceRender = true;
                        })
                        this.list = this.list.concat(list)

                        this.hotelIds = list.map((item) => item.hotelId);
                        const form = this.form;
                        let params = {};
                        this.hotelIds.forEach(hotelId => {
                            params = {
                                checkInDate: form.checkInDate,
                                checkOutDate: form.checkOutDate,
                                hotelIds: [hotelId],
                                behalfCompanyId: this.$route.query.behalfCompanyId,
                                memberPhone: this.$route.query.memberPhone,
                            };
                            consumer_admin_hotel_order_getHotelMinPrice(params)
                                .then((res) => {
                                    this.priceList = this.priceList.concat(res.minPriceResults);
                                    const minPriceResults = res.minPriceResults;

                                    this.list.forEach(item => {
                                        for (let i = 0; i < minPriceResults.length; i++) {
                                            if (item.hotelId === Number(minPriceResults[i].hotelId)) {
                                                this.$set(item, 'price', minPriceResults[i].minPrice)
                                                this.$set(item, 'minPriceRender', false)
                                                break;
                                            }
                                        }
                                    });

                                    // this.loading = false;
                                })
                                .catch((err) => {
                                    this.loading = false;
                                    return
                                })
                                .finally(() => {
                                    console.log('执行了 finally')
                                });
                        })

                    } else {
                        this.priceList = [];
                    }

                    this.loading = false;
                    if (this.list.length < 20 && !this.isNoMore) {
                        this.form.currentPage++;
                        this.req_list(this.form);
                    }
                } catch (err) {
                    this.loading = false;
                }
            }
        },
        init(pParams) {
            this.query = pParams;
            let query = this.query;
            this.form.behalfCompanyId = this.$route.query.behalfCompanyId
            const form = this.form;

            if (query.checkInDate)
                form.checkInDate = this.$moment(query.checkInDate).format("YYYY-MM-DD");
            if (query.checkOutDate)
                form.checkOutDate = this.$moment(query.checkOutDate).format(
                    "YYYY-MM-DD"
                );
            if (!query.checkInDate)
                form.checkInDate = this.$moment().format("YYYY-MM-DD");
            if (!query.checkOutDate)
                form.checkOutDate = this.$moment()
                    .add(1, "d")
                    .format("YYYY-MM-DD");

            if (query.cityId) form.cityId = query.cityId;
            if (query.cityName) form.cityName = query.cityName;
            if (query.keyword) form.keyword = query.keyword;
            if (query.hotelStar) form.hotelStar = query.hotelStar;

            this.toCity = {
                cityId: query.cityId,
                cityCn: query.cityName,
                cityPinyin: "",
                cityPinyinSimp: "",
            };
            consumer_hotel_search_brandList()
                .then((res) => {
                    let list = res.pageResult.pageData;
                    list.unshift({
                        hotelBrandId: "",
                        hotelBrandName: "不限",
                    });
                    this.classifyType[2].navList = list;
                })
                .catch((err) => { });
            if (!form.cityId && !form.cityName) {
                this.loading = false;
                this.$message.warning("请选择目的城市");
            } else {
                if (!form.cityId && form.cityName) {
                    this.$refs.aHotelCitySelector.set_entity({
                        type: `000`,
                        type_parameter: {
                            '000': {
                                name: form.cityName,
                            },
                        }
                    }).then(res => {
                        this.$nextTick(() => {
                            this.req_list(this.form).then(()=>{
                                window.addEventListener('scroll', this.handleScroll, true);
                            })
                        })
                    })
                } else {
                    this.req_list(this.form).then(()=>{
                        window.addEventListener('scroll', this.handleScroll, true);
                    })
                }

            }

            //判断是否凌晨（00:00-06:00）进入页面
            let curTimeList = this.$moment().toArray();
            this.isMorning = curTimeList[3] < 6;
        },
        // 开始搜素
        search() {
            if (this.loading) return;
            this.list = [];
            this.priceList = [];
            this.form.currentPage = 1;
            this.isNoMore = false;
            this.req_list(this.form);
            /*this.$router.replace({
                name: 'admin-hotel-search',
                query: {
                    cityId: this.form.cityId,
                    cityName: this.form.cityName,
                    checkInDate: this.form.checkInDate,
                    checkOutDate: this.form.checkOutDate,
                    evectionNo: this.$route.query.evectionNo,
                    redirectType: '002',
                    orderNo: this.$route.query.orderNo,
                    evectionType: this.$route.query.evectionType
                }
            })*/
        },
        // 时间选择框获取焦点
        date_focus(key) {
            this.$refs[key].focus();
        },
        change_checkInTiem(val) {
            if (val) {
                const form = this.form;
                const end = moment(form.checkOutDate).format("YYYYMMDD");
                if (moment(val).format("YYYYMMDD") >= end) {
                    form.checkOutDate = moment(val)
                        .add(1, "days")
                        .format("YYYY-MM-DD");
                }

                if (
                    end >
                    moment(form.checkInDate)
                        .add(30, "days")
                        .format("YYYYMMDD")
                ) {
                    form.checkOutDate = moment(val)
                        .add(30, "days")
                        .format("YYYY-MM-DD");
                }

                this.$refs.checkOutDate.focus();
            } else {
            }
        },
        change_checkOutTime(val) {
            if (val) {
                if (
                    moment(val).format("YYYYMMDD") <=
                    moment(this.form.checkInDate).format("YYYYMMDD")
                )
                    this.form.checkOutDate = moment(this.form.checkInDate)
                        .add(1, "days")
                        .format("YYYY-MM-DD");
            }
        },
        // 切换分类
        handleClick() { },
        // 选择赛选条件
        change_nav(value, key, item) {
            const form = this.form;
            if (value === "") {
                form[key] = "";
                if (key === 'hotelPrice') {
                    this.form.minValue = 0;
                    this.form.maxValue = 100000;
                }
                this.search();
                return;
            }
            let val = key === 'hotelBrand' ? item.hotelBrandName : value;
            if (form[key] && key !== 'hotelPrice') {
                if (!form[key].split(",").includes(val)) {
                    form[key] = form[key] + "," + val;
                }
            } else {
                form[key] = val;
            }

            if (key === 'hotelPrice') {
                this.form.minValue = item.minValue? Number(item.minValue) : 0;
                this.form.maxValue = item.maxValue? Number(item.maxValue) : 100000;
            }
            if (this.form.minValue && this.form.maxValue && this.form.minValue > this.form.maxValue) {
                this.isAvailablePrice = false;
                return;
            }
            this.isAvailablePrice = true;

            this.search();
        },
        // 选择排序
        change_sort(index) {
            this.sortType = index;
            const form = this.form;
            if (index === "1") {
                form.salesSort = "";
                form.priceSort = "";
            } else if (index === "2") {
                if (form.salesSort) {
                    if (form.salesSort === "asc") {
                        this.salesSort = "desc";
                        form.salesSort = "desc";
                    } else if (form.salesSort === "desc") {
                        this.salesSort = "asc";
                        form.salesSort = "asc";
                    }
                } else {
                    form.salesSort = this.salesSort;
                }
                form.priceSort = "";
            } else if (index === "3") {
                if (form.priceSort) {
                    if (form.priceSort === "asc") {
                        this.priceSort = "desc";
                        form.priceSort = "desc";
                    } else if (form.priceSort === "desc") {
                        this.priceSort = "asc";
                        form.priceSort = "asc";
                    }
                } else {
                    form.priceSort = this.priceSort;
                }
                form.salesSort = "";
            }
            this.search();
        },
        to_detail(hotel) {
            const form = this.form;
            let query = {
                cityId: form.cityId,
                cityName: form.cityName,
                hotelId: hotel.hotelId,
                hotelName: hotel.hotelNameCn,
                hotelNameEn: hotel.hotelNameEn,
                star: hotel.star,
                hotelAddr: hotel.addressCn,
                bizName: hotel.bizName,
                facilities: hotel.facilities,
                phone: hotel.phone,
                checkInDate: form.checkInDate,
                checkOutDate: form.checkOutDate,
                imageUrl: hotel.imageUrl,
                keyword: form.keyword,
                memberPhone: this.query.memberPhone,
            };
            query.evectionType = this.$route.query.evectionType;
            if (this.$route.query.evectionNo) {
                query.evectionNo = this.$route.query.evectionNo;
            }
            const orderNo = this.$route.query.orderNo;
            if (orderNo) query.orderNo = orderNo;

            this.$router.push({
                name: "admin-hotel-detail",
                query,
            });
        },

        format_array(val, key) {
            const strArr = val.split(",");
            const objArr = this.classifyType[key].navList;
            let arr = [];

            for (let i = 0; i < strArr.length; i++) {
                const str = strArr[i];
                for (let j = 0; j < objArr.length; j++) {
                    const obj = objArr[j];
                    if (key === 2) {
                        if (obj.hotelBrandName === str) {
                            arr.push(obj.hotelBrandName);
                        }
                    } else if(key === 3 && strArr[0] === '-1' && (this.priceRangeObj.minValue || this.priceRangeObj.maxValue)) {
                        let range = '';
                        if(this.priceRangeObj.minValue && this.priceRangeObj.maxValue) {
                            range = this.priceRangeObj.minValue + "-" + this.priceRangeObj.maxValue;
                        } else if(this.priceRangeObj.minValue) {
                            range = this.priceRangeObj.minValue + "以上";
                        } else {
                            range = this.priceRangeObj.maxValue + "以下";
                        }

                        arr = [range];
                    } else {
                        if (obj.value === str) {
                            arr.push(obj.label);
                        }
                    }
                }
            }
            return arr;
        },
        close(val, key, index) {
            let strArr = val.split(",");
            strArr.splice(index, 1);
            this.priceRangeObj = {
                minValue: '',
                maxValue: ''
            }
            this.form.minValue = 0;
            this.form.maxValue = 100000;
            this.isNoMore = false;
            this.isAvailablePrice = true;
            this.form[key] = strArr.join();
            this.form.currentPage = 1;
            this.req_list(this.form)
        },
        handleCurrentChange(page) {
            this.form.currentPage = page;
            this.req_list(this.form);
        },
        handleSizeChange(size) {
            this.form.currentPage = 1;
            this.form.pageSize = size;
            this.req_list(this.form);
        },
        handleScroll() {
            let scrollTop = document.getElementsByClassName("row-router-view")[0].scrollTop;
            //变量windowHeight是可视区的高度
            let windowHeight = document.getElementsByClassName("row-router-view")[0].clientHeight;
            //变量scrollHeight是滚动条的总高度
            let scrollHeight = document.getElementsByClassName("row-router-view")[0].scrollHeight;
            if (scrollTop + windowHeight + 36 >= scrollHeight) {
                this.form.currentPage++;
                this.req_list(this.form);
            }
        }
    },
    activated() {
        this.init(this.$route.query);
    },
    deactivated() {
        window.removeEventListener('scroll', this.handleScroll, true);
    },
    watch: {
        toCity: {
            handler(val) {
                const form = this.form;
                if (val) {
                    form.cityId = val.cityId;
                    form.cityName = val.cityCn;
                } else {
                    form.cityId = "";
                    form.cityName = "";
                }
            },
        },
    },
    filters: {
        format_star(val) {
            switch (Number(val)) {
                case 20:
                    return "经济型";
                case 25:
                    return "经济型";
                case 30:
                    return "舒适型";
                case 35:
                    return "舒适型";
                case 40:
                    return "高档型";
                case 45:
                    return "高档型";
                case 50:
                    return "豪华型";
                case 55:
                    return "豪华型";
            }
        },
    },
};

//
//
//
//
//
//
//

// import upload from '@/api/general';
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client"
import tinymce from '@/lib/other-lib/tinymce/5.3.1/tinymce.min';
import '@/lib/other-lib/tinymce/5.3.1/langs/zh_CN';
import '@/lib/other-lib/tinymce/5.3.1/themes/silver/theme.min';
import '@/lib/other-lib/tinymce/5.3.1/icons/default/icons.min';
import '@/lib/other-lib/tinymce/5.3.1/plugins/image/plugin.min';

export default {
  data() {
    return {
      OssClient: new OssClient(),
      editor: null,
    };
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  mounted() {
    const that = this;
    tinymce.init({
      selector: '#my-editor',
      branding: false,
      plugins: 'image',
      toolbar: 'undo redo | fontsizeselect forecolor fontselect | bold italic | link image | alignleft aligncenter alignright alignjustify',
      language: 'zh_CN',
      setup(editor) {
        that.editor = editor;
        editor.on('input', () => {
          that.$emit('input', editor.getContent());
        });
        editor.on('change', () => {
          that.$emit('input', editor.getContent());
        });
      },
      init_instance_callback(editor) {
        if (that.value) {
          editor.setContent(that.value);
        }
      },
      images_upload_handler(blobInfo, success, failure) {
        that.OssClient.multipartUpload({
            file: blobInfo.blob(),
          })
          .then(function(data) {
            success(data.url)
          })
          .catch((err) => {
            console.log('富文本上传图片失败')
            failure(err)
          });
        // const formData = new FormData();
        // formData.append('image', blobInfo.blob(), blobInfo.filename());
        // upload(formData).then((res) => {
        //   if (res.code === 200) {
        //     success(res.data[0]);
        //   } else {
        //     failure(res.msg);
        //   }
        // }).catch((err) => {
        //   failure(err);
        // });
      },
    });
  },
  activated() {
    const that = this;
    tinymce.init({
      selector: '#my-editor',
      branding: false,
      plugins: 'image',
      toolbar: 'undo redo | fontsizeselect forecolor fontselect | bold italic | link image | alignleft aligncenter alignright alignjustify',
      language: 'zh_CN',
      setup(editor) {
        that.editor = editor;
        editor.on('input', () => {
          that.$emit('input', editor.getContent());
        });
        editor.on('change', () => {
          that.$emit('input', editor.getContent());
        });
      },
      init_instance_callback(editor) {
        if (that.value) {
          editor.setContent(that.value);
        }
      },
      images_upload_handler(blobInfo, success, failure) {
        // const formData = new FormData();
        // formData.append('image', blobInfo.blob(), blobInfo.filename());
        // upload(formData).then((res) => {
        //   if (res.code === 200) {
        //     success(res.data[0]);
        //   } else {
        //     failure(res.msg);
        //   }
        // }).catch((err) => {
        //   failure(err);
        // });
      },
    });
  },
  deactivated() {
    this.editor.destroy();
    this.editor = null;
  },
  beforeDestroy() {
    this.editor.destroy();
    this.editor = null;
  },
};

import Q from "q";
import getUserInfo from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info/index';
import consumer_user_logout from "yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_user_logout";
import _ from 'underscore';

export default {
  data() {
    return {
      user_name: `admin`,
      menu_entity_list: [
        { text: `注销`, code: `logout`, enabled: true, },
        { text: `其他`, code: `other`, enabled: false, },
      ],
    };
  },
  methods: {
    __btn_menu_entity_click(pEntity) {
      const __this = this;

      const code = pEntity.code;
      switch (code) {
        case `logout`:
          Q.when()
              .then(function() {
                return consumer_user_logout();
              })
              .then(function() {
                __this.$router.replace({
                  name: `login`,
                });
              })
              .catch(function(ex) {
                __this.$router.replace({
                  name: `login`,
                });
                console.error(ex);
                console.trace();
                if (ex) {
                  if (ex.stack) {
                    console.error(ex.stack);
                  }
                }
                return Q.reject(ex);
              });
          break;

        case `other`:
        default:
          console.log(pEntity);
          break
      }
    },
  },
  watch: {},
  created() {
    const __this = this;
    getUserInfo({clear_cache: true}).then(res => {
      this.user_name = res.datas.username;
    });
  },
}

import consumer_admin_car_order_carOrderDetails from '@/lib/data-service/haolv-default/consumer_admin_car_order_carOrderDetails'
import OrderLog from "@/component/orderLog/2.0.0/index.vue";
import consumer_log_carList from "@/lib/data-service/haolv-default/consumer_log_carList";
import _ from 'underscore';
import ChangeService from "@/component/change-service/index.vue";
export default {
    components: {ChangeService, OrderLog},
    data() {
        return {
            activeName: 'detail',
            loading: false,
            orderNo: '',
            detail: {}
        }
    },
    activated() {
       this.init();
    },
    methods: {
        init() {
            this.activeName = 'detail'
            this.orderNo = this.$route.query.orderNo
            this.getDetail();
            this.getOrderLog();
        },
        getDetail() {
            this.loading = true
            consumer_admin_car_order_carOrderDetails({orderNo: this.orderNo}).then(res => {
                let detail = res.datas
                this.detail = {
                    orderInfoResult: [detail.orderInfoResult],
                    orderJourneyResult: [detail.orderJourneyResult],
                    orderCostResult: [detail.orderCostResult],
                    orderDriverResult: detail.orderDriverResult,
                }
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    return consumer_log_carList({
                        orderNo: this.orderNo
                    }).then(res => {
                        console.log('res', res);
                        // 组装组件需要的list
                        const list = _.chain(res.datas)
                            .map(function (m) {
                                console.log('m',m);
                                // 替换名字
                                const o = {
                                    operator: m.operator,
                                    date: m.operatorTime,
                                    type: m.logSource,
                                    detail: m.logContents
                                }
                                return o
                            }).value()
                        console.log('list',list)
                        const d = {
                            entityList: list
                        }
                        console.log('d', d);
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },
        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
            switch (val) {
                case (1):
                    return 'type2'
                case (2):
                    return 'type1'
                default: return ''
            }
        },
        back() {
            this.$router.go(-1);
            window.close();
        },
        // 调整服务费
        changeService() {
            console.log(this.detail)
            let params = {
                businessType: 4,
                orderNo: this.orderNo,
                oldService: this.detail.orderCostResult[0].serviceFee,
            };
            this.$refs.changeService.init(params).then(res=>{
                this.getDetail();
            }).catch((e)=>{})
        },
    }
}
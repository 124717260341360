import consumer_admin_flight_offlineRefund_details
    from '@/lib/data-service/haolv-default/consumer_admin_flight_offlineRefund_details'//获取详情
import consumer_admin_flight_offlineRefund_offlineRefundConfirm
    from '@/lib/data-service/haolv-default/consumer_admin_flight_offlineRefund_offlineRefundConfirm'//确认

export default {
    data() {
        return {
            loading: false,
            refOrderNo: '',
            fromMode: '000',
            sourceType: 1, // 1国内2国际
            detail: {
                oldSegList: [],
                refSegList: [],
                // segmentsList: [],//航班信息
                insuranceInfo: [],//保险信息
                refundPassenger: [],//乘客信息
            },
            rules: {
                refundFine: [
                    {required: true, message: '退票罚金', trigger: 'blur'}
                ],
                refRealAmount: [
                    {required: true, message: '退回金额', trigger: 'blur'}
                ],
                supplierRefundOrderNo: [
                    {required: true, message: '供应商退票订单号', trigger: 'blur'},
                ],
                supplierRefRealAmount: [
                    {required: true, message: '供应商退回金额', trigger: 'blur'}
                ],
            },
        }
    },
    activated() {
        let {
            refOrderNo,
            fromMode
        } = this.$route.query
        this.refOrderNo = refOrderNo;
        this.fromMode = fromMode ? fromMode : '000';
        this.getDetail()
    },
    filters: {
        filterMoney(val) {
            if (val || val === 0) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return '0.00'
            }
        }
    },
    methods: {
        //获取详情
        getDetail() {
            this.loading = true
            consumer_admin_flight_offlineRefund_details({
                refOrderNo: this.refOrderNo
            }).then(res => {
                res.detailVO.refundPassenger.forEach((item) => {
                    if (item.sourceType) {
                        this.sourceType = item.sourceType
                    }
                });
                this.detail = res.detailVO;
            }).catch(() => {
                this.$message({
                    type: "error",
                    message: "获取机票退票订单详情失败！"
                })
            }).finally(() => {
                this.loading = false
            })
        },

        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
            switch (val) {
                case (1):
                    return 'type2'
                case (2):
                    return 'type1'
                default: return ''
            }
        },

        //保存
        async submit() {
            this.loading = true
            consumer_admin_flight_offlineRefund_offlineRefundConfirm({
                refOrderNo: this.refOrderNo
            }).then(res => {
                this.loading = false
                this.$message({
                    type: "success",
                    message: '确认成功'
                })
                this.back();
            }, error => {
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },

        //返回
        back() {
            switch (this.fromMode) {
                case '000':
                    this.$router.go(-2);
                    break;
                case '100':
                    this.$router.go(-1);
                    break;
                case '200':
                    this.$router.replace({
                        name: "admin-flight-refund-list"
                    })
                    break;
            }
        },
    }
}

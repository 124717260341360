export default {
  path: '',
  component: () => import(`@/component/admin-layout/index.vue`),
  children: [
    {
      path: '/test',
      name: 'test',
      component: () => import(/* webpackChunkName: "page-admin-test" */ `./index.vue`),
      meta: {
        entity_for_history_tag: {
          name: `客户审核`,
        },
        entity_list_for_breadcrumb: [
          {name: `客户管理`},
          {name: `客户审核列表`},
          {name: `客户审核`},
        ],
        data_for_left_menu: {
            get_active_route_name(pParameter) {
                return `admin-users-audit`;
            },
        },
      },
    },
  ],
};

import Q from 'q';
import moment from 'moment';

// 火车票退款订单列表
import consumer_admin_trains_order_orderRefundTrainsList from '@/lib/data-service/haolv-default/consumer_admin_trains_order_orderRefundTrainsList';

import SearchBox from '@/component/search-box/1.0.1/index.vue'
import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'

export default {
    data() {
        return {
            loading: false,
            params: {
                // 搜索参数
                query: {
                    orderNo: '',
                    orderType: '',
                    startDate: '',
                    trainNo: '',
                    gmtCreate: '',
                    passengerNames: '',
                    operatorName: '',
                    realName: '',
                    companyName: '',
                    behalf: ''
                },

                // 分页参数
                currentPage: 1,
                pageSize: 10,
                totalCount: 0
            },
            tableList: [],
            allocation: {
                orderTypeList: [
                    {value: 1, label: '预定'},
                    {value: 2, label: '抢票'}
                ],
                isBehalfList: [
                    {value: '', label: '全部'},
                    {value: 1, label: '是'},
                    {value: 0, label: '否'},
                ],
                orderStatusList: [
                    {value: '0', label: '全部'},
                    {value: '1', label: '确认中'},
                    {value: '2', label: '待支付'},
                    {value: '3', label: '待出票'},
                    {value: '4', label: '出票成功'},
                    {value: '5', label: '出票失败'},
                    {value: '6', label: '取消'},
                ],
            }
        }
    },
    components: {
        SearchBox,
        RouterLinkButton,
        Pagination
    },
    methods: {
        get_table_list() {
            const __this = this;
            __this.loading = true;
            __this.params.query.orderStatus = parseInt(__this.params.orderStatus) === 0 ? '' : parseInt(__this.params.orderStatus);
            consumer_admin_trains_order_orderRefundTrainsList(__this.params).then(res => {
                __this.tableList = res.datas.records;
                __this.params.totalCount = res.datas.total;
            }).finally(() => {
                __this.loading = false;
            })
        },
        onSearch() {
            const __this = this;
            __this.params.currentPage = 1;
            __this.get_table_list()
        },
        onReset() {
            const __this = this;
            __this.params = {
                query: {
                    orderNo: '',
                    orderType: '',
                    startDate: '',
                    trainNo: '',
                    gmtCreate: '',
                    passengerNames: '',
                    realName: '',
                    companyName: '',
                    behalf: ''
                },

                // 分页参数
                currentPage: 1,
                pageSize: 10,
                totalCount: 0
            };
            __this.onSearch();
        },
        handleSizeChange(size) {
            const __this = this;
            __this.params.currentPage = 1;
            __this.params.pageSize = size;
            __this.get_table_list()
        },
        handleCurrentChange(page) {
            const __this = this;
            __this.params.currentPage = page;
            __this.get_table_list()
        },
        handleClick() {
            const __this = this;
            __this.onSearch();
        },
        to_detail(row) {
            const __this = this;
            __this.$router.push({
                name: 'admin-train-order-detail',
                query: {
                    orderNo: row.orderNo,
                },            
            })
        }
    },
    mounted() {

    },
    activated() {
        this.get_table_list();
    },
    deactivated() {
    },
    watch: {
        
    },
    filters: {
        filter_date(val) {
            return val ? moment(val).format("YYYY-MM-DD HH:mm") : val;
        }
    }
}


// consumer_web_company_checkCompany 验证公司是否有效
// https://www.apifox.cn/apidoc/shared-c91c112c-ac85-4f14-8607-3d32d61d8a80/api-21478260
const __request = require(`./__request/__request_contentType_json`);
const consumer_web_company_checkCompany = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/company/checkCompany',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_company_checkCompany;

export default {
  path: '',
  component: () => import(`@/component/admin-layout/index.vue`),
  children: [
    {
      path: '/admin/open-credit',
      name: 'admin-open-credit',
      component: () => import(/* webpackChunkName: "page-admin-credit-management-open-credit" */ `./1.0.2/index.vue`),
      meta: {
        entity_for_history_tag: {
          name: `开通授信`,
        },
        entity_list_for_breadcrumb: [
          { name: `授信管理` },
          { name: `开通授信` },
        ],
        data_for_left_menu: {
          get_active_route_name(pParameter) {
            return `admin-oppen-credit-list`;
          },
        },
      },
    },
  ],
};

export default {
  path: "",
  component: () => import(`@/component/admin-layout/index.vue`),
  children: [
    {
      path: "/admin/users-detail",
      name: "admin-users-detail",
      component: () => import(/* webpackChunkName: "page-admin-users-management-users-detail" */ `./1.1.2/index.vue`),
      meta: {
        entity_for_history_tag: {
          name: `客户详情`,
        },
        entity_list_for_breadcrumb: [
          { name: `客户管理` },
          { name: `客户管理列表` },
          { name: `客户详情` },
        ],
        data_for_left_menu: {
          get_active_route_name(pParameter) {
            return `admin-users-list`;
          },
        },
      },
    },
  ],
};

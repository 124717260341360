import consumer_admin_flight_order_change_getList from '@/lib/data-service/haolv-default/consumer_admin_flight_order_change_getList'
import consumer_air_ticket_cancelChangePay from '@/lib/data-service/haolv-default/consumer_air_ticket_cancelChangePay'
import Pagination from '@/component/base-pagination/index.vue'
import DomesticTicketAirlineSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airline-selector/2.0.0/index.vue'
import consumer_admin_flight_manage_nuLockOrderChange from '@/lib/data-service/haolv-default/consumer_admin_flight_manage_nuLockOrderChange'
import consumer_admin_flight_manage_orderChange from '@/lib/data-service/haolv-default/consumer_admin_flight_manage_orderChange'
import consumer_admin_flight_generate_orderSource from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_orderSource'
import consumer_admin_flight_change_offlineOrderCancellation
    from '@/lib/data-service/haolv-default/consumer_admin_flight_change_offlineOrderCancellation'
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";
import SearchBox from '@/component/search-box/1.0.1/index.vue'

export default {
    data () {
        return {
            loading: false,
            showMore: false,
            params: {
                currentPage: 1,
                pageSize: 10,
                abbreviation: '',
                agency: '', // 代理
                chaOrderNo: '',
                lastBuyerPId: '',
                orderNo: '',
                orderStatus: '',
                orderTimeStart: '',
                orderTimeEnd: '',
                orderTimeArr: [],
                psgName: '',
                segmentType: '',
                source: '',
                userId: '',
                username: '',
                orderSource: '',
                total: 0,
                flightNo: '',
                flightTyightType: '',
                policyType: 0,
                airCompanyCode: '',
                proposerName: '',
                ticketNumber: '',
                depDate: '',
                operatorName: '',
                sourceType: 0,
            },
            activeName: '-1',
            // 31.处理中,33.需补款,34.待改签,35.改签成功,36.改签失败,37.已取消，-1.全部
            tabList: [
                {
                    value: -1,
                    label: "全部",
                    num: ''
                },
                {
                    value: 31,
                    label: "处理中",
                    num: ''
                },
                {
                    value: 33,
                    label: "需补款",
                    num: ''
                },
                {
                    value: 34,
                    label: "待改签",
                    num: ''
                },
                {
                    value: 35,
                    label: "改签成功",
                    num: ''
                },
                {
                    value: 36,
                    label: "改签失败",
                    num: ''
                },
                {
                    value: 37,
                    label: "取消改签",
                    num: ''
                },
            ],
            list: [],
            isBehalfList: [
                {value: '', label: '全部'},
                {value: 1, label: '是'},
                {value: 0, label: '否'},
            ],
            flightTypeList: [
                {value: '', label: '全部'},
                {value: 1, label: '单程'},
                {value: 2, label: '往返'},
                {value: 3, label: '多程'},
            ],
            policyTypeList: [ //  0=全部，1=自营 2=供应商
                {value: 0, label: '全部'},
                {value: 1, label: '自营'},
                {value: 2, label: '供应商'},
            ],
            sourceTypeList: [
                {value: 0, label: '全部'},
                {value: 1, label: '国内'},
                {value: 2, label: '国际'},
            ],
            canSubmit: true,
            orderSourceList: [],
        }
    },
    components: { Pagination, SearchBox, DomesticTicketAirlineSelector },
    created () {
        const __this = this
        const aWebSocketServerClient = global.aWebSocketServerClient;
        aWebSocketServerClient.on({
            event_name: `haolv-platform-change-order_lockOrder`,
            handler: function (data) {
                __this.getList()
            }
        });
        aWebSocketServerClient.on({
            event_name: `haolv-platform-change-order_unLockOrder`,
            handler: function (data) {
                __this.getList()
            }
        });
    },
    mounted () {
        const __this = this;
        const aSelector = __this.$refs.aSelector;
        aSelector.init({
            insert_the_first_entity: {
                enable: true,
            },
        });
        this.getOrderSource()
    },
    activated () {
        this.reset()
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {

    },
    filters: {},
    methods: {
        getOrderSource() {
            consumer_admin_flight_generate_orderSource().then(res => {
                let orderSourceList = res.datas
                orderSourceList.unshift({
                    msg: '全部',
                    code: ''
                })
                this.orderSourceList = orderSourceList
            })
        },
        showChangeOpa(val) {
            if ((val.orderStatus === '31' || val.orderStatus === '34') && (val.selfPolicy === 1 || val.airlineCode === '3U') && (val.lockState === 0 || (val.lockState === 1 && val.isOperation === 1))) {
                return true
            } else {
                return false
            }
        },
        handleToUnlock(val) {
            val.loading = true
            let params = {
                chaOrderNo: val.chaOrderNo
            }
            consumer_admin_flight_manage_nuLockOrderChange(params).then(res => {
                this.getList()
            }).finally(() => {
                val.loading = false
            })
        },
        search() {
            this.params.currentPage = 1
            // this.params.orderStatus = this.activeName === '0' ? '' : this.activeName
            this.params.orderStatus = parseInt(this.activeName)
            this.getList()
        },
        reset() {
            let params = {
                currentPage: 1,
                pageSize: 10,
                abbreviation: '',
                agency: '', // 代理
                chaOrderNo: '',
                lastBuyerPId: '',
                orderNo: '',
                orderStatus: '',
                orderTimeStart: '',
                orderTimeEnd: '',
                orderTimeArr: [],
                psgName: '',
                segmentType: '',
                source: '',
                userId: '',
                username: '',
                orderSource: '',
                total: 0,
                flightNo: '',
                flightTyightType: '',
                policyType: 0,
                airCompanyCode: '',
                proposerName: '',
                ticketNumber: '',
                depDate: '',
                operatorName: '',
                sourceType: 0,
            }
            // params.orderStatus = this.activeName === '0' ? '' : this.activeName
            params.orderStatus = this.activeName
            this.params = params
            this.search()
        },
        showOrHideForm() {
            const showMore = this.showMore
            this.showMore = !showMore
        },
        handleClick(val) {
            this.search()
        },
        changeOrderTimeArr(val) {
            if (val === '' || val === null) {
                this.params.orderTimeStart = ''
                this.params.orderTimeEnd = ''
            } else {
                this.params.orderTimeStart = val[0]
                this.params.orderTimeEnd = val[1]
            }
        },
        getList() {
            this.loading = true
            this.list = []
            consumer_admin_flight_order_change_getList(this.params).then(res => {
                this.loading = false
                let list = res.pageResult.pageData
                list.forEach(value => {
                    let oriPsgName = value.psgName
                    let psgNameArr = []
                    if (value.psgName) {
                        psgNameArr = value.psgName.split(',')
                    }
                    let psgNameResult = []
                    psgNameArr.forEach(value1 => {
                        if (psgNameResult.indexOf(value1) === -1) {
                            psgNameResult.push(value1)
                        }
                    })
                    value.oriPsgName = oriPsgName
                    value.psgName = psgNameResult.join(',')
                })
                this.list = list
                this.params.total = res.pageResult.totalCount
                let flightOrderNum = res.flightOrderNum
                this.tabList.forEach(value => {
                    value.num = 0
                    flightOrderNum.forEach(value1 => {
                        if (value1.status === value.value) {
                            value.num = value1.orderNum
                        }
                    })
                })
                this.$nextTick(() => {
                    this.$refs.listTable.doLayout();
                })
            }).catch(() => {
                this.loading = false
                this.list = []
                this.params.total = 0
            })
        },
        handleEdit(val){
            this.$router.push({
                name: 'admin-flight-change-offline-edit',
                query: {
                    chaOrderNo: val.chaOrderNo
                }
            })
        },
        // 去确认
        handleSubmit(val){
            this.$router.push({
                name: 'admin-flight-change-offline-confirm',
                query: {
                    chaOrderNo: val.chaOrderNo
                }
            })
        },
        handleToDetail(val) {
            if (val.orderSource == 3 || val.orderSource == 4) {
                this.$router.push({
                    name: 'admin-flight-change-detail-offline',
                    query: {
                        chaOrderNo: val.chaOrderNo,
                        orderNo: val.orderNo
                    }
                })
            } else {
                this.$router.push({
                    name: 'admin-flight-change-detail',
                    query: {
                        chaOrderNo: val.chaOrderNo,
                        orderNo: val.orderNo
                    }
                })
            }
        },
        tipsPopup(val) {
            return new Promise(resolve => {
                if ([80, 217, 239, 307, 328, 397, 399, 419].indexOf(val.companyId) == -1) {
                    resolve(true);
                } else {
                    this.$confirm('该公司不支持客服改签补款，请确认是否继续支付？', '温馨提示', {
                        confirmButtonText: '继续支付',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        resolve(true);
                    }).catch(() => {
                        resolve(false);
                    });
                }
            });
        },
        async handleToPay(val) {
            let result = await this.tipsPopup(val);
            if (result === false) {
                return;
            }
            if (val.orderSource === 3 || val.orderSource === 4) {
                this.$router.push({
                    name: 'admin-flight-change-offline-confirm',
                    query: {
                        chaOrderNo: val.chaOrderNo,
                    }
                })
            } else {
                this.$router.push({
                    // name: 'admin-order-payment',
                    name: 'admin-flight-payment',
                    query: {
                        orderType: 1,
                        pageType: 3,
                        oriOrderNo: val.orderNo,
                        orderNo: val.chaOrderNo,
                        behalfCompanyId: val.companyId,
                        selfPolicy: val.selfPolicy
                    }
                })
            }

        },
        handelToCancelOffline(val){
            this.$confirm("确定要取消订单吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                let data = {
                    chaOrderNo: val.chaOrderNo
                };
                consumer_admin_flight_change_offlineOrderCancellation(data)
                  .catch(() => {
                    this.$message.error('取消失败');
                  })
                  .then(res => {
                    this.$message({
                      message: '取消成功',
                      type: 'success'
                    });
                    this.getList()
                  });
            }).catch(() => {});
        },
        handelToCancel(val) {
            this.$confirm("确定要取消订单吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                let data = {
                    changeNo: val.chaOrderNo
                };
                consumer_air_ticket_cancelChangePay(data)
                  .catch(() => {
                    this.$message.error('取消失败');
                  })
                  .then(res => {
                    this.$message({
                      message: '取消成功',
                      type: 'success'
                    });
                    this.getList()
                  });
            }).catch(() => {});
        },
        async handleToChange(val) {
            if (!this.canSubmit) {
                return
            }
            this.canSubmit = false
            if (val.lockState === 1) { // 如果已锁，且出现“改签操作”按钮，说明是自己锁的单
                this.goChange(val)
                return
            }
            const params = {
                chaOrderNo: val.chaOrderNo
            }
            val.loading = true
            let [err, res] = await awaitWrap(consumer_admin_flight_manage_orderChange(params))
            val.loading = false
            if (err) {
                this.canSubmit = true
                this.getList()
                return
            }
            this.goChange(val)
        },
        goChange(val) {
            this.canSubmit = true
            this.$router.push({
                name: 'admin-flight-change-opa',
                query: {
                    chaOrderNo: val.chaOrderNo,
                    orderNo: val.orderNo
                }
            })
        },
        handleToRefund(val) {
            this.$router.push({
                name: "admin-flight-refund",
                query: {
                    orderNo: val.orderNo,
                    orderStatus: 2
                },
            });
        },
        getPolicyTypeTextToClass(val) {
            /*
             "supplier": "1,2,3,4,12,13,9,OPL9,OPL6,OPL11,OPL18,OPL20,OPL16,60,FD,NFD,VARI,OPL14,SGL1,OPL17",
              "supplierText": "平台优选",
              "bigCustomers": "QCYX",
              "bigCustomersText": "企业协议",
              "lowPriceSelection": "YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL13,OPL34,OPL1,OPL26,OPL30",
              "lowPriceSelectionText": "低价精选",
              "lowPrice": "41",
              "lowPriceText": "低价推荐",
              "website": "21,22,23,24,25,31,6,CXF1,WS,GW311,GW312,OPL17,OPL23",
              "websiteText": "航司官网",
              "platformProprietary": "61",
              "platformProprietaryText": "商旅推荐"
             */
            const btoList = ['1','2','3','4','12','13','9','OPL9','OPL6','OPL11','OPL18','OPL20','OPL16','60','FD','NFD','VARI','OPL14','SGL1','OPL17'];
            const lprList = ['YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL13','OPL34','OPL1','OPL26','OPL30'];
            const owList = ['21','22','23','24','25','31','6','CXF1','WS','GW311','GW312','OPL17','OPL23'];
            const qcList = ['QCYX'];
            const ptList = ['61'];
            if (btoList.indexOf(val) > -1) {
                return 'type1'
            } else if (lprList.indexOf(val) > -1) {
                return 'type3'
            } else if (owList.indexOf(val) > -1) {
                return 'type4'
            } else if (qcList.indexOf(val) > -1) {
                return 'type2'
            } else if (ptList.indexOf(val) > -1) {
                return 'type5'
            } else {
                return 'type6'
            }
        },
        getEvectionTypeToClass(val) {
            //出差单类型：1.因私，2.因公
            switch (val) {
                case (1):
                    return 'blue'
                case (2):
                    return 'orange'
                default: return ''
            }
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/

export default {
    visaOrder: {   // 签证信息
        id: null,
        gmtCreate: "",
        gmtModified: "",
        orderNo: "",
        supOrderNo: "",
        status: 0,
        countryNo: "",    // 国家二字码
        countryName: null,   // 国家名称--需要去掉
        name: "",
        orderSource: null,   // 采购渠道
        paymentType: null,
        paymentTime: "",
        paymentNum: "",
        remark: "",   // 备注信息
        files: "",    // 附件信息  数组转JSON
        createBy: null,
        modifiedBy: null,
        modifiedName: null,
    },
    visaCompanyInfo: {   // 客户基础信息
        id: null,
        gmtCreate: "",
        gmtModified: "",
        orderId: null,
        companyId: null,      // 所属企业
        contactName: "",   // 联系人
        contactMobile: "",   // 联系人手机
        evectionType: 2,
        payType: 1,
    },
    visaCompanyBill: [   // 客户信息
        {
            id: null,
            gmtCreate: "",
            gmtModified: "",
            orderId: null,
            companyId: null,
            userId: null,
            certId: null,
            companyType: 1,
            status: 0,
            salesPrice: null,
            supPrice: null,
            serviceFee: 0,
            expectTime: "",
            userName: "",
            certNum: "",
            certType: 2,

            profit: null,   // 利润--需要去掉
        }
    ],


    // orderNo: '',
    // customerInfo: {
    //     companyId: '',
    //     companyName: '',
    //     contactMobile: '',
    //     contactName: '',
    //     evectionType: 2,
    //     payType: 1,
    //     deptId: '',
    //     deptName: '',
    //     oaEvectionNo: '',
    //     feeAffiliationType: 1,
    // },
    // journeyInfo: [
    //     {
    //         fromStationObj: null,
    //         toStationObj: null,
    //         arriveTime: '',
    //         fromStationCode: '',
    //         fromStationName: '',
    //         journeyNo: '1',
    //         seatName: '',
    //         seatPrice: '', // 0
    //         serviceChargePrice: 0,
    //         trainsReservation: 1,
    //         startTime: '',
    //         toStationCode: '',
    //         toStationName: '',
    //         trainNo: '',
    //     }
    // ],
    // passengerList: [
    //     {
    //         birthday: '',
    //         cardNo: '',
    //         cardType: 1,
    //         cardTypeText: '身份证',
    //         mobile: '',
    //         psgName: '',
    //         psgType: 1,
    //         psgTypeText: '成人',
    //         seatnumber: [
    //             {
    //                 boardingGates: '',
    //                 journeyNo: '1',
    //                 seatInfos: '',
    //             }
    //         ]
    //     }
    // ],
    // remark: '',
    // ticketInfo: {
    //     purchaseCommission: 0,
    //     bookingSms: 1,
    //     initialName: '',
    //     linkmanSms: 1,
    //     orderTime: '',
    //     supplierOrderNo: '',
    //     ticketChannel: '1',
    //     ticketNo: '',
    //     userId: '',
    // }
}

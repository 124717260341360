export default {
  customerInfo: '客户基础信息',
  journeyInfo: '行程信息',
  passengerList: '乘机人信息',
  insuranceInfo: '保险信息',
  ticketInfo: '出票信息',

  companyId: '所属企业',
  contactName: '联系人',
  contactMobile: '联系电话',
  evectionType: '出行类型',
  payType: '支付方式',
  airlineCode: '航司名称',
  flightNo: '航班号',
  depAirportCode: '出发城市',
  arrAirportCode: '到达城市',
  depDateTime: '起飞时间',
  arrDateTime: '到达时间',
  cabinRank: '舱位等级',
  cabin: '舱位代码',
  psgName: '乘机人姓名',
  psgType: '乘客类型',
  cardType: '证件类型',
  cardNo: '证件号码',
  birthday: '出生日期',
  mobile: '手机号码',
  ticketNumber: '票号',
  price: '票面价',
  infrastructure: '机建',
  fuel: '燃油税',
  serviceCharge: '服务费',
  purchasePrice: '采购价（含税）',
  buyerPrice: '销售价（含税）',
  initialName: '操作客服',
  ticketTime: '出票时间',
  orderTime: '预订时间',
  bookingSms: '是否发送短信通知给乘客',
  linkmanSms: '是否发送短信通知给联系人',
  countryCode: '国籍',
  issCountryCode: '发证国家',
  cardExpireDate: '证件有效期',
  familyName: '姓',
  givenName: '名',

  adultPrice: '成人票价',
  childPrice: '儿童票价',
  commission: '返佣',

  productName: '保险名称',
  insuranceOrderId: '保单号',
  premium: '保险销售价',
  insureCost: '保险成本',
  startDate: '生效时间',
  endDate: '失效时间',

  officialTicketChannel: '公务票验证方式',
  officialTicketBank: '公务票验证银行/单位',
}

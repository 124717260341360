import consumer_admin_flight_reimburse_getList from '@/lib/data-service/haolv-default/consumer_admin_flight_reimburse_getList'
import Pagination from '@/component/base-pagination/index.vue'
import SearchBox from '@/component/search-box/1.0.1/index.vue'
import _ from "underscore";

export default {
    data () {
        return {
            loading: false,
            showMore: false,
            params: {
                currentPage: 1,
                pageSize: 10,
                abbreviation: '',
                agency: '', // 代理
                reiOrderNo: '',
                lastBuyerPId: '',
                orderNo: '',
                orderStatus: '',
                orderTimeStart: '',
                orderTimeEnd: '',
                orderTimeArr: [],
                psgName: '',
                segmentType: '',
                source: '',
                userId: '',
                username: '',
                airlineName: '',
                isBehalf: '',
                total: 0,
                flightNo: '',
                supReiStatus: '', // 胤之旅退款状态 51-处理中 52-已退款 53-退款失败
            },
            activeName: 0,
            tabList: [
                // 51-处理中 52-已退款
                {
                    value: 0,
                    label: "全部"
                },
                {
                    value: 51,
                    label: "处理中"
                },
                {
                    value: 52,
                    label: "已退款"
                },
            ],
            list: [],
            isBehalfList: [
                {value: '', label: '全部'},
                {value: 1, label: '是'},
                {value: 0, label: '否'},
            ],
            supReiStatusList: [ // 51-处理中 52-已退款 53-退款失败
                {value: '', label: '全部'},
                {value: 51, label: '处理中'},
                {value: 52, label: '已退款'},
                {value: 53, label: '退款失败'},
            ],
        }
    },
    components: { Pagination, SearchBox },
    created () {},
    mounted () {},
    activated () {
        this.reset()
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        search() {
            this.params.currentPage = 1
            this.params.orderStatus = this.activeName === '0' ? '' : this.activeName
            // this.params.orderStatus = this.activeName
            this.getList()
        },
        reset() {
            let params = {
                currentPage: 1,
                pageSize: 10,
                abbreviation: '',
                agency: '', // 代理
                reiOrderNo: '',
                lastBuyerPId: '',
                orderNo: '',
                orderStatus: '',
                orderTimeStart: '',
                orderTimeEnd: '',
                orderTimeArr: [],
                psgName: '',
                segmentType: '',
                source: '',
                userId: '',
                username: '',
                isBehalf: '',
                total: 0,
                flightNo: '',
                supReiStatus: '', // 胤之旅退款状态 51-处理中 52-已退款 53-退款失败
            }
            params.orderStatus = this.activeName === '0' ? '' : this.activeName
            // params.orderStatus = this.activeName
            this.params = params
            this.search()
        },
        showOrHideForm() {
            const showMore = this.showMore
            this.showMore = !showMore
        },
        handleClick(val) {
            this.search()
        },
        changeOrderTimeArr(val) {
            if (val === '' || val === null) {
                this.params.orderTimeStart = ''
                this.params.orderTimeEnd = ''
            } else {
                this.params.orderTimeStart = val[0]
                this.params.orderTimeEnd = val[1]
            }
        },
        getList() {
            this.loading = true
            this.list = []
            consumer_admin_flight_reimburse_getList(this.params).then(res => {
                this.loading = false

                // _.each(res.pageResult.pageData,function (m) {
                //              m.initialName = `xxx`;
                //            });

                this.list = res.pageResult.pageData
                console.log(res.pageResult.pageData)
                this.params.total = res.pageResult.totalCount
            }).catch(() => {
                this.loading = false
                this.list = []
                this.params.total = 0
            })
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/

//【391】admin-火车票订单状态数量
// POST /consumer/admin/trains/order/orderStatusNumber
// 接口ID：50187571
// 接口地址：https://www.apifox.cn/web/project/458418/apis/api-50187571
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_trains_order_orderStatusNumber = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/trains/order/orderStatusNumber',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_trains_order_orderStatusNumber;
import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'
import consumer_admin_flight_generate_orderSource from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_orderSource'
import DictionarySelect from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import queryUtil from 'yinzhilv-js-sdk/frontend/common-project/lib/util/query_filter_util.js';
import excelUtil from 'yinzhilv-js-sdk/frontend/common-project/lib/util/excel_util.js';

import reportRequest from '@/lib/data-service/report/report_flight_request'

import moment from "moment";
import { awaitWrap } from '@/page/admin/replace-orders/travel-reserve/common/unit';

export default {
    data() {
        return {
            loading: false,
            orderSourceList: [{//订单来源枚举值
                code: 0,
                msg: '全部'
            }],
            equityList: [//是否加享
                {
                    value: null,
                    label: '全部'
                },{
                    value: 1,
                    label: '是'
                },{
                    value: 0,
                    label: '否'
                },
            ],
            ticketTypeList:[
                {value: null, label: '全部'},
                {value: 1, label: '国内'},
                {value: 2, label: '国际'},
            ],
            orderTypeList:[
                {value: null, label: '全部'},
                {value: 1, label: '出票'},
                {value: 2, label: '改签'},
                {value: 3, label: '退票'},
            ],
            searchForm: {
                modifiedTimeStart: null,// 操作日期-开始
                modifiedTimeEnd: null,// 操作日期-结束
                orderNo: null,// 订单号
                ticketType: null,// 机票类型
                platformUserName: null,// 操作客服
                orderType: null,// 订单类型
                equity: null,// 订单来源
                orderSource: null,// 订单来源
                flightNo: null,// 航班号
                ticketNumber: null,// 票号
                companyName: null,// 所属企业
            },
            pageBean: {
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            },
            totalCount: 0,   //总条数
            list: [],
            modifiedTime: this.initModifiedTime()
        }
    },
    components: {
        SearchBox,
        DictionarySelect,
        Pagination
    },
    activated() {
        this.init()
    },
    methods: {
        //初始化
        async init() {
            this.changeModifiedTimeArr(this.modifiedTime);
            await this.getOrderSource();
            await this.getList();
        },
        initModifiedTime() {
            let arr = [];
            arr[0] = moment().subtract(7, 'days').format('YYYY-MM-DD');
            arr[1] = moment().format('YYYY-MM-DD');
            return arr;
        },
        //搜索
        search() {
            this.pageBean.currentPage = 1
            this.getList()
        },
        //重置
        reset() {
            let searchForm = {
                modifiedTimeStart: null,// 操作日期-开始
                modifiedTimeEnd: null,// 操作日期-结束
                orderNo: null,// 订单号
                ticketType: null,// 机票类型
                platformUserName: null,// 操作客服
                orderType: null,// 订单类型
                orderSource: null,// 订单来源
                equity: null,// 订单来源
                flightNo: null,// 航班号
                ticketNumber: null,// 票号
                companyName: null,// 所属企业
            };
            this.searchForm = searchForm;
            this.totalCount = 0;
            this.search()
        },
        getQueryParams() {
            let params = queryUtil.getBaseParams(this.pageBean,null,null,null);
            if (this.searchForm.modifiedTimeStart) {
                params.querys.push(queryUtil.createQueryParams("tb.ticket_time",this.searchForm.modifiedTimeStart,queryUtil.QueryOP.GE))
            }
            if (this.searchForm.modifiedTimeEnd) {
                params.querys.push(queryUtil.createQueryParams("tb.ticket_time",this.searchForm.modifiedTimeEnd,queryUtil.QueryOP.LE))
            }
            if (this.searchForm.orderNo) {
                params.querys.push(queryUtil.createQueryParams("tb.order_no",this.searchForm.orderNo))
            }
            if (this.searchForm.ticketType) {
                params.querys.push(queryUtil.createQueryParams("tb.ticket_type",this.searchForm.ticketType))
            }
            if (this.searchForm.platformUserName) {
                params.querys.push(queryUtil.createQueryParams("tb.platform_user_name",this.searchForm.platformUserName,queryUtil.QueryOP.LK))
            }
            if (this.searchForm.orderType) {
                params.querys.push(queryUtil.createQueryParams("tb.orderType",this.searchForm.orderType))
                params.params.orderType = this.searchForm.orderType;
            }
            if (this.searchForm.orderSource) {
                params.querys.push(queryUtil.createQueryParams("tb.order_source",this.searchForm.orderSource))
            }
            if (this.searchForm.equity) {
                params.querys.push(queryUtil.createQueryParams("tb.equityCode",this.searchForm.equity,queryUtil.QueryOP.NOTNULL))
            }
            if (this.searchForm.equity===0) {
                params.querys.push(queryUtil.createQueryParams("tb.equityCode",this.searchForm.equity,queryUtil.QueryOP.ISNULL))
            }
            if(this.searchForm.ticketNumber){
                params.querys.push(queryUtil.createQueryParams("tb.ticket_number",this.searchForm.ticketNumber))
            }
            if(this.searchForm.flightNo){
                params.querys.push(queryUtil.createQueryParams("tb.flight_no",this.searchForm.flightNo,queryUtil.QueryOP.LK))
            }
            if(this.searchForm.companyName){
                params.querys.push(queryUtil.createQueryParams("tb.company_name",this.searchForm.companyName,queryUtil.QueryOP.LK))
            }
            return params;
        },
        //获取列表
        getList() {
            this.loading = true
            this.list = []

            reportRequest.report_page_list(this.getQueryParams()).then(res => {
                this.loading = false
                this.list = res.datas.list
                this.totalCount = res.datas.totalCount
            }).catch(() => {
                this.loading = false
                this.list = []
            })
        },
        //获取订单来源枚举值
        async getOrderSource() {
            this.orderSourceList = [{
                code: 0,
                msg: '全部'
            }];
            let res = await consumer_admin_flight_generate_orderSource();
            this.orderSourceList = [...this.orderSourceList, ...res.datas];
        },
        //导出账单
        async exportExcel() {
            this.loading = true
            const param = this.getQueryParams();
            param.pageBean.currentPage = -1;
            param.pageBean.pageSize = -1;
            let [err, res] = await awaitWrap(reportRequest.report_page_list(param))
            if (err) {
                this.loading = false
                return
            }
            let arr = [];
            let h = [
                '订单号'
                , '订单类型'
                , '出票渠道'
                , '机票类型'
                , '乘机人姓名'
                , '证件'
                , '航班号'
                , '行程'
                , '航班日期'
                , '航班时间'
                , '票号'
                , '机票状态'
                , '销售价'
                , '服务费'
                , '采购价'
                , '利润'
                , '出票客服'
                , '出票日期'
                , '客户名称'
                , '备注'
                , '票面价'
                , '其他税费'
            ]
            let data = res.datas.list
            arr.push(h);
            if (data && data.length>0) {
                for (const item of data) {
                    arr.push([
                        item.orderNo
                        , item.orderTypeText
                        , item.orderSourceText
                        , item.ticketTypeText
                        , item.psgName
                        , item.psgCardNo
                        , item.flightNo
                        , item.airSeg
                        , item.segDate
                        , item.segTime
                        , item.ticketNumber
                        , item.orderStatus
                        , item.salesPrice
                        , item.serviceFee
                        , item.supPrice
                        , item.profits
                        , item.platformUserName
                        , item.ticketTime
                        , item.companyName
                        , item.remark
                        , item.price
                        , item.otherTax
                    ])
                }
            }
            excelUtil.exportToExcel('客服机票报表', arr);
            this.loading = false
        },
        //搜索出票日期改变时
        changeModifiedTimeArr (val) {
            if (val === '' || val === null) {
                this.searchForm.modifiedTimeStart = null
                this.searchForm.modifiedTimeEnd = null
            } else {
                this.searchForm.modifiedTimeStart = val[0] + ' 00:00:00'
                this.searchForm.modifiedTimeEnd = val[1] + ' 23:59:59'
            }
        }
    }
}
// 【1230】平台航变信息-删除
import consumer_air_ticket_deleteAirChange from '@/lib/data-service/haolv-default/consumer_air_ticket_deleteAirChange'
import consumer_air_ticket_airChangeList from '@/lib/data-service/haolv-default/consumer_air_ticket_airChangeList'
// 【1230】平台航变信息列表确认操作
import consumer_air_ticket_airChangeAffirm from '@/lib/data-service/haolv-default/consumer_air_ticket_airChangeAffirm'
import consumer_admin_flight_generate_orderSource from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_orderSource'
import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'
import CopyReportPopup from '@/component/copyReportPopup/import_latest_version_module'
import SearchBox from '@/component/search-box/1.0.1/index.vue'
import moment from "moment";
export default {
    data() {
        return {
            isAdmin: null,
            falseBox: false,
            trueBox: true,
            loading: false,
            dialogVisible: false,
            dialogVisibleData: [],
            selectionArr: [],
            radioDialog: 1,
            title: '通知模板',

            tagIdList: [
                {code: '', msg: '全部'},
                {code: 1, msg: 'VP'},
            ],
            orderSourceList: [],
            evectionTypeOption: [],
            gmtCreate: '',
            params: {
                airborneTimeEnd: '',
                airborneTimeStart: '',
                changeType: '',
                psgName: '',
                ticketNo: '',
                companyName: '',
                flightNo: '',
                keyword: '',
                notifyStatus: 1,
                notifyType: 0,
                pnr: '',
                tagId: '',
                orderNo: '',
                lastUserName: '',
                orderSource: '',
                gmtCreateEnd: '',
                gmtCreateStart: '',
                pageSize: 10,
                orderBy: '',
                currentPage: 1,
                total: 0,
            },
            typeList: [
                {value: 0, label: '全部'},
                {value: 1, label: '未通知'},
                {value: 2, label: '已通知'}
            ],
            activeName: '1',
            orderList: [],
            copyDialog: false,
            copyTextarea: '',
            copyLoading: false,
        }
    },
    components: {Pagination, CopyReportPopup, RouterLinkButton, SearchBox},
    created() {
    },
    mounted() {
    },
    activated() {
        this.activeName = '1';
        this.gmtCreate = '';
        this.params = {
          airborneTimeEnd: '',
          airborneTimeStart: '',
          changeType: '',
          psgName: '',
          ticketNo: '',
          companyName: '',
          flightNo: '',
          keyword: '',
          notifyStatus: 1,
          notifyType: 0,
          pnr: '',
          tagId: '',
          orderNo: '',
          lastUserName: '',
          orderSource: '',
          gmtCreateEnd: '',
          gmtCreateStart: '',
          pageSize: 10,
          orderBy: '',
          currentPage: 1,
          total: 0,
        };
        this.getOrderList();
        this.getOrderSource();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterTime(val) {
            if (!val) {
                return ''
            } else {
                return moment(val).format('HH:mm:ss')
            }
        },
    },
    methods: {
        handleSelectionChange(selectionArr) {
            this.selectionArr = selectionArr;
        },
        releaseClick() {
            this.$router.push({
                name: 'admin-flight-create-air-change',
            })
        },
        batchClick() {
            if (this.selectionArr.length == 0) {
                this.$message.error('请选择航变记录');
            } else {
                this.radioDialog = 1;
                this.dialogVisible = true;
                this.dialogVisibleData = this.selectionArr;
            }
        },
        checkSelectable(row) {
            return row.notifyStatus == 1 ? true : false;
        },
        dialogVisibleTrue(list) {
            let airborneId = [];
            let switchBox = false;
            list.forEach((item,index) => {
                airborneId.push(item.id);
            });
            this.dialogVisibleData.forEach((DItem,DIndex) => {
                if (DItem.linkNotifyResult == 1) {
                    switchBox = true;
                }
                DItem.passengerList.forEach((PItem,PIndex) => {
                    if (PItem.notifyResult == 1) {
                        switchBox = true;
                    }
                });
            });
            if (switchBox) {
                this.loading = true;
                consumer_air_ticket_airChangeAffirm({airborneIds: airborneId,operation: this.radioDialog}).then(res => {
                    this.$message({
                        message: '确认成功',
                        type: 'success'
                    });
                    this.dialogVisible = false;
                }).finally(() => {
                    this.loading = false;
                    this.getOrderList();
                })
            } else {
                this.$message({
                    message: '所选航变记录没有可通知的人',
                    type: 'warning'
                });
            }
        },
        getOrderSource() {
            consumer_admin_flight_generate_orderSource().then(res => {
                let orderSourceList = res.datas;
                orderSourceList.unshift({
                  msg: '全部',
                  code: ''
                });
                this.orderSourceList = orderSourceList;
            })
        },
        changeCreateTime() {
            this.params.airborneTimeStart = this.gmtCreate[0];
            this.params.airborneTimeEnd = this.gmtCreate[1];
        },
        handleClick() {},
        onSearch() {
            this.params.currentPage = 1;
            this.params.notifyStatus = parseInt(this.activeName);
            this.getOrderList()
        },
        onReset() {
            this.gmtCreate = '';
            this.params = {
                airborneTimeEnd: '',
                airborneTimeStart: '',
                changeType: '',
                psgName: '',
                ticketNo: '',
                companyName: '',
                flightNo: '',
                keyword: '',
                notifyStatus: 1,
                notifyType: 0,
                pnr: '',
                tagId: '',
                orderNo: '',
                lastUserName: '',
                orderSource: '',
                gmtCreateEnd: '',
                gmtCreateStart: '',
                pageSize: 10,
                orderBy: '',
                currentPage: 1,
                total: 0,
            };
            this.onSearch()
        },
        getOrderList() {
            this.loading = true;
            consumer_air_ticket_airChangeList(this.params).then(res => {
                this.loading = false;
                let orderList = res.datas.list;
                this.orderList = orderList;
                this.params.total = res.datas.totalCount;
                this.orderList.forEach((item,index) => {
                    item.passengerListSwitch = false;
                    let passengerListBox = [];
                    item.passengerList.forEach((PItem,PIndex) => {
                        passengerListBox.push(PItem.notifyResult);
                        if (passengerListBox.indexOf(1) == -1) {
                            item.passengerListSwitch = false;
                        } else {
                            item.passengerListSwitch = true;
                        }
                    });
                });
            }).catch(() => {
                this.params.total = 0;
                this.loading = false
            })
        },
        // 确认操作
        clickConfirm(item) {
            this.radioDialog = 1;
            this.dialogVisible = true;
            this.dialogVisibleData = [item];
        },
        // 删除操作
        clickDelete(item) {
            this.$confirm('确定删除该航变通知？', '确认删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 确定
                this.loading = true;
                consumer_air_ticket_deleteAirChange({airborneId: item.id}).then(res => {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                }).finally(() => {
                    this.loading = false;
                    this.getOrderList();
                })
            }).catch(() => {
                // 取消
            });
        },
        toShowCopy(item) {
            this.copyDialog = true;
            let daysNum = 0;
            if (moment(item.oldArriveDate).diff(moment(item.oldBeginDate),'days') > 0) {
                daysNum = moment(item.oldArriveDate).diff(moment(item.oldBeginDate),'days')
            }
            let daysNumNew = 0;
            if (moment(item.changeArriveDate).diff(moment(item.changeBeginDate),'days') > 0) {
                daysNumNew = moment(item.changeArriveDate).diff(moment(item.changeBeginDate),'days')
            }
            // 航班取消--1  航班取消有保护--2  航班变更--3
            this.copyTextarea = '';
            this.copyTextarea = `航变通知：${item.changeTypeText || ''}` + '\n';
            // 日期 航班号 出发城市 (出发机场 航站楼) - 到达城市 (到达机场 航站楼) 出发时间-到达时间
            this.copyTextarea += `原航班:${item.oldBeginDate || ''} ${item.flightNo || ''} ${item.oldBeginCityName || ''}（${item.oldBeginAirportName || ''}${item.oldBeginAirportTerminal || ''}）-${item.oldArriveCityName || ''}（${item.oldArriveAirportName || ''}${item.oldArriveAirportTerminal || ''}） ${item.oldBeginTime || ''}起飞-${item.oldArriveTime || ''}${daysNum == 0 ? '' : `+${daysNum || ''}`}到达` + '\n';
            if (item.changeType != 1) {
                this.copyTextarea += `${item.changeType == 2 ? '保护至:' : '变更至:'}${item.changeBeginDate || ''} ${item.newFlightNo || ''} ${item.changeBeginCityName || ''}（${item.changeBeginAirportName || ''}${item.changeBeginAirportTerminal || ''}）-${item.changeArriveCityName || ''}（${item.changeArriveAirportName || ''}${item.changeArriveAirportTerminal || ''}） ${item.changeBeginTime || ''}起飞-${item.changeArriveTime || ''}${daysNum == 0 ? '' : `+${daysNumNew || ''}`}到达` + '\n';
            }
            item.passengerList.forEach((PItem,PIndex) => {
                this.copyTextarea += `乘机人：${PItem.name || ''}` + '\n';
                this.copyTextarea += `票号：${PItem.ticketNo || ''}` + '\n';
            });
        },
    }
}

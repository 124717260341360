// consumer_admin_journey_getEvectionDetail 根据出差单号获取详情 http://api-alpha.haolvtrip.com/doc.html#/yinzhilv-tmc/%E8%A1%8C%E7%A8%8B%E9%A2%84%E8%AE%A2/getEvectionDetailUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_journey_getEvectionDetail = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/journey/getEvectionDetail',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_journey_getEvectionDetail;
export default {
    data() {
        return {}
    },
    props: {
        index: {
            type: Number
        },// 每一行的索引
        source: {
            type: Object,
            default () {
                return {}
            }
        },// 每一行的内容
        choose: {
            type: Function,
        },
        mouseIndex: {
            type: Number
        },
        setMouseIndex: {
            type: Function
        },
        active: {
            type: String,
            default() {
                return '';
            }
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        _choose(val) {
            console.log('23444');
            this.choose(val)
        }
    }
}
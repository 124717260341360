// consumer_car_order_getCarPolicyType 获取用车差标类型枚举 http://yapi.yinzhitravel.com/project/52/interface/api/15708
const __request = require(`./__request/__request_contentType_json`);
const consumer_car_order_getCarPolicyType = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/car/order/getCarPolicyType',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_car_order_getCarPolicyType;
const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 获取国际航司：http://219.128.52.2:18765/doc.html#/haolv-biz/%E6%9C%BA%E7%A5%A8%20-%20%E6%9F%A5%E8%AF%A2/airlinesUsingPOST
const consumer_flight_search_InternaTionalAirlines = (pParameter) => {
    if (!pParameter) pParameter = {};
    const name = pParameter.name || ``;

    const p = {
        method: 'post',
        urlSuffix: '/consumer/flight/search/InternaTionalAirlines',
        data: pParameter,
    };
    return Q.when()
        .then(function () {
            return __request(p);
        })
        .then(function (data) {
            return Q.resolve(data);
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};
module.exports = consumer_flight_search_InternaTionalAirlines;


import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'
import RouterLinkButton
    from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'
import consumer_admin_hotel_order_CancelOrderList
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_CancelOrderList";// 平台酒店退订订单列表
import consumer_admin_hotel_order_queryHotelOrderListByIfInvoice
    from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_queryHotelOrderListByIfInvoice'// 开票方式类型枚举接口
import consumer_admin_hotel_order_getOrderSourceEnum
    from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_getOrderSourceEnum'// 订单来源枚举接口
import consumer_admin_hotel_order_getOrderChannelEnum
    from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_getOrderChannelEnum'// 订单渠道枚举接口

export default {
    data() {
        return {
            searchForm: {
                orderNo: '',   //订单编号
                cancelStatus: -1,
                thirdpartyOrderNo: '',   //胤之旅订单编号
                evectionUserName: '',   //旅客姓名/出行人名字
                certificatesNumber: '',   //证件信息
                orderSource: 0,//订单来源
                orderChannel: 0,//订单渠道
                ifInvoice: 0,//开票方式
                isBehalf: '',   //是否代客下单 1-是,0-否
                orderTimeStart: '',   //下单开始日期
                orderTimeEnd: '',   //下单结束日期
                checkInDate: '',//入住时间
                companyName: '',   //公司id
                evectionType: 0,   //预定方式：0：全部 1：因私，2：因公
                hotelName: '',   //酒店名称
                initialName: '',   //操作客服

                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            },
            orderSourceList: [],//订单来源
            orderChannelList: [
                {
                    code: 0,
                    msg: '全部'

                },
                {
                    code: 1,
                    msg: '农行api'
                }
            ],//订单渠道
            evectionTypeOption: [
                {
                    value: 0,
                    label: '全部'
                },
                {
                    value: 2,
                    label: '因公'
                },
                {
                    value: 1,
                    label: '因私'
                }
            ],//因公因私
            ifInvoiceList: [],//开票方式
            activeName: '-1',
            tabList: [
                {
                    name: -1,
                    label: '全部订单',
                    value: 0
                },
                {
                    name: 7,
                    label: '退订待确认',
                    value: 0
                },
                {
                    name: 1,
                    label: '退订成功',
                    value: 0
                },
                {
                    name: 3,
                    label: '退订失败',
                    value: 0
                }
            ],

            tableData: [],
            currentPage: 1,
            totalCount: 0,
            pageSize: 10,
            orderNo: '',
            createTime: '',
            checkInDate: '',//入住时间
            companyName: '',
            realName: '',
            cancelStatusObj: {
                0: '退订申请',
                1: '退订成功',
                2: '待平台确认',
                3: '退订失败',
                4: '未退订 待审批',
            },
            isBehalf: '',
            hotelName: '',
            initialName: '',
            evectionUserName: '',
            options: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: 0,
                    label: '否',
                },
                {
                    value: 1,
                    label: '是',
                },
            ],
            loading: true,
        };
    },
    components: {
        SearchBox,
        Pagination,
        RouterLinkButton
    },
    methods: {
        //获取订单渠道枚举
        getOrderChannelEnum() {
            consumer_admin_hotel_order_getOrderChannelEnum().then(res => {
                this.orderChannelList = res.datas;
            });
        },

        //获取订单来源枚举
        getOrderSourceEnum() {
            // 订单来源
            consumer_admin_hotel_order_getOrderSourceEnum().then(res => {
                this.orderSourceList = res.datas;
            });
        },

        //获取开票方式
        getByIfInvoice() {
            // 开票方式
            consumer_admin_hotel_order_queryHotelOrderListByIfInvoice().then(res => {
                this.ifInvoiceList = res.datas;
            });
        },

        //搜索
        onSearch() {
            this.searchForm.currentPage = 1;
            this.searchForm.cancelStatus = parseInt(this.activeName)
            this.getOrderList();
        },

        //获取列表
        async getOrderList() {
            this.loading = true;
            try {
                const res = await consumer_admin_hotel_order_CancelOrderList(this.searchForm);
                let data = res.pageResult;
                this.tableData = data.pageData;
                this.tabNumberList = res.hotelOrderNumberResults;
                this.tabList.forEach(tab => {
                    this.tabNumberList.forEach(tabNumber => {
                        if (tab.name === tabNumber.cancelStatus) {
                            tab.value = tabNumber.orderNumber
                        }
                    })
                });
                this.totalCount = data.totalCount;
            } catch (error) {
                this.orderList = [];
                this.totalCount = 0;
                this.$message({
                    type: "error",
                    message: "获取酒店订单列表失败！"
                })
            } finally {
                this.$nextTick(() => {
                    this.$refs.mainTable.doLayout()
                })
                this.loading = false;
            }
        },

        //切换状态
        handleChangeTab(item) {
            this.searchForm.cancelStatus = parseInt(item.name)
            this.getOrderList()
        },

        //修改下单时间筛选
        changeCreateTime(val) {
            if (!val) {
                this.searchForm.orderTimeStart = 0;
                this.searchForm.orderTimeEnd = 0;
            } else {
                this.searchForm.orderTimeStart = val[0] + ' 00:00:00';
                this.searchForm.orderTimeEnd = val[1] + ' 23:59:59';
            }
        },

        //重置
        onReset() {
            this.searchForm = {
                orderNo: '',   //订单编号
                cancelStatus: -1,
                thirdpartyOrderNo: '',   //胤之旅订单编号
                evectionUserName: '',   //旅客姓名/出行人名字
                certificatesNumber: '',   //证件信息
                orderSource: 0,//订单来源
                orderChannel: 0,//订单渠道
                ifInvoice: 0,//开票方式
                isBehalf: '',   //是否代客下单 1-是,0-否
                orderTimeStart: '',   //下单开始日期
                orderTimeEnd: '',   //下单结束日期
                checkInDate: '',//入住时间
                companyName: '',   //公司id
                evectionType: 0,   //预定方式：0：全部 1：因私，2：因公
                hotelName: '',   //酒店名称
                initialName: '',   //操作客服

                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            };
            this.createTime = [];   //下单日期
            this.onSearch();
        },

        //查看详情
        handleViewDetail(id) {
            return {
                name: 'admin-hotel-order-cancel-detail',
                query: {
                    id
                }
            }
        },

        //线下退订
        handleOfflineRefundClick(row) {
            this.$router.push({
                name: "admin-hotel-order-offline-cancel-handle",
                query: {
                    orderNo: row.orderNo
                }
            })
        },
    },
    created() {
        this.getOrderList();
        this.getByIfInvoice();
        this.getOrderSourceEnum();
    },
}
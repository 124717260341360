import SearchBox from "@/component/search-box/1.0.1/index.vue";
import Pagination from '@/component/base-pagination/index.vue'
import queryUtil from 'yinzhilv-js-sdk/frontend/common-project/lib/util/query_filter_util.js';
import moment from "moment";
import baseUrl from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix";
import report_tRpTmcFlightItinerary_v1_query from '@/lib/data-service/report/report_tRpTmcFlightItinerary_v1_query';
import report_tRpTmcFlightItinerary_v1_export from '@/lib/data-service/report/report_tRpTmcFlightItinerary_v1_export';
import report_tRpTmcFlightItinerary_v1_upd from '@/lib/data-service/report/report_tRpTmcFlightItinerary_v1_upd';
import report_tRpTmcFlightItinerary_v1_importUpd from '@/lib/data-service/report/report_tRpTmcFlightItinerary_v1_importUpd';
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";
import consumer_admin_flight_generate_getCompanyList from "@/lib/data-service/haolv-default/consumer_admin_flight_generate_getCompanyList";
export default {
    data() {
        return {
            companyList: [],
            searchForm: {
                ticketTimeArr: null,
                ticketTimeStart: null,
                ticketTimeEnd: null,
                segDateArr: null,
                segDateStart: null,
                segDateEnd: null,
                isSegDate: false,
                selfPolicyStatus: '',
                isRefund: '',
                ticketStatus: 0,
                ticketNumber: '',
                psgName: '',
                itineraryNo: '',
                pingDateArr: null,
                pingDateStart: null,
                pingDateEnd: null,
            },
            pageBean: {
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            },
            totalCount: 0, //总条数
            activeName: '-1',
            tabList: [{value: -1, label: '全部'}, {value: 0, label: '未打印'},{value: 1, label: '正常'},{value: 2, label: '作废'},{value: 3, label: '无法打印'},],
            tableLoading: false,
            orderList: [],

            selfPolicyStatusOption: [{value: '', label: '全部'}, {value: 1, label: '是'}, {value: 0, label: '否'}], // 是否自营 0：否 1：是
            ticketStatusOption: [{value: 0, label: '全部'}, {value: 1, label: 'OPEN'}],
            isRefundOption: [{value: '', label: '全部'}, {value: 1, label: '是'}, {value: 0, label: '否'}],

            exportBtnLog: false,
            fileList: [],
            dialogVisible: false,
            uploadLoading: false,
            downloadModelUrl: '',
            successMsg: '',
            resultType: 1, // 1成功，2失败
            failFileUrl: '',
            // ossClient: new OssClient(),
            dialogVisible2: false,

            editDialogVisible: false,
            form: {
                id: '',
                ticketNumber: '',
                psgName: '',
                status: '',
                itineraryNo: '',
                pingDate: '',
                ticketStatus: 0,
                price: '',
                remark: '',
            },
            statusOption: [{value: 1, label: '正常'},{value: 2, label: '作废'},{value: 3, label: '无法打印'}],
            rule: {
                status: [
                    { required: true, message: '请选择行程单状态', trigger: 'change' },
                ],
            },
            editLoading: false,
        }
    },
    props: {},
    components: {SearchBox, Pagination},
    created() {
    },
    mounted() {
        const ticketTimeStart = moment().subtract(15,'days').format('YYYY-MM-DD');
        const ticketTimeEnd = moment().format('YYYY-MM-DD');
        this.searchForm.ticketTimeArr = [ticketTimeStart, ticketTimeEnd];
        this.searchForm.ticketTimeStart = ticketTimeStart;
        this.searchForm.ticketTimeEnd = ticketTimeEnd;
        this.getCompanyList();
    },
    activated() {
        this.getOrderList()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        async getCompanyList() {
            const params = {
                companyName: '',
                currentPage: 1,
                keyword: '',
                pageSize: 999,
            }
            let res = await consumer_admin_flight_generate_getCompanyList(params)
            this.companyList = res.datas.list
        },
        onSearch() {
            this.pageBean.currentPage = 1;
            this.getOrderList()
        },
        onReset() {
            const ticketTimeStart = moment().subtract(15,'days').format('YYYY-MM-DD');
            const ticketTimeEnd = moment().format('YYYY-MM-DD');
            this.searchForm = {
                ticketTimeArr: [ticketTimeStart, ticketTimeEnd],
                ticketTimeStart: ticketTimeStart,
                ticketTimeEnd: ticketTimeEnd,
                segDateArr: null,
                segDateStart: null,
                segDateEnd: null,
                isSegDate: false,
                selfPolicyStatus: '',
                isRefund: '',
                ticketStatus: 0,
                ticketNumber: '',
                psgName: '',
                itineraryNo: '',
                pingDateArr: null,
                pingDateStart: null,
                pingDateEnd: null,
            }
            this.pageBean = {
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            };
            this.getOrderList()
        },
        changeCreateTime(val) {
            if(!val) {
                this.searchForm.ticketTimeStart = null;
                this.searchForm.ticketTimeEnd = null;
            } else {
                this.searchForm.ticketTimeStart = val[0] + ' 00:00:00';
                this.searchForm.ticketTimeEnd = val[1] + ' 23:59:59';
            }
        },
        changeFlightTime(val) {
            if(!val) {
                this.searchForm.segDateStart = null;
                this.searchForm.segDateEnd = null;
            } else {
                this.searchForm.segDateStart = val[0] + ' 00:00:00';
                this.searchForm.segDateEnd = val[1] + ' 23:59:59';
            }
        },
        changePingDate(val) {
            if(!val) {
                this.searchForm.pingDateStart = null;
                this.searchForm.pingDateEnd = null;
            } else {
                this.searchForm.pingDateStart = val[0] + ' 00:00:00';
                this.searchForm.pingDateEnd = val[1] + ' 23:59:59';
            }
        },
        getQueryParams() {
            let params = queryUtil.getBaseParams(this.pageBean,null,null,[
                {
                    "property": "companyName",
                    "direction": "ASC"
                },
                /*{
                    "property": "sort",
                    "direction": "ASC"
                },*/
                {
                    "property": "tb.type",
                    "direction": "ASC"
                }
            ]);
            if (this.searchForm.ticketTimeStart) {
                params.querys.push(queryUtil.createQueryParams("ticketTime",this.searchForm.ticketTimeStart,queryUtil.QueryOP.GE))
            }
            if (this.searchForm.ticketTimeEnd) {
                params.querys.push(queryUtil.createQueryParams("ticketTime",this.searchForm.ticketTimeEnd,queryUtil.QueryOP.LE))
            }
            if (this.searchForm.companyName) {
                params.querys.push(queryUtil.createQueryParams("companyName",this.searchForm.companyName, queryUtil.QueryOP.LK))
            }
            if (this.searchForm.segDateStart) {
                params.querys.push(queryUtil.createQueryParams("segDate",this.searchForm.segDateStart,queryUtil.QueryOP.GE))
            }
            if (this.searchForm.segDateEnd) {
                params.querys.push(queryUtil.createQueryParams("segDate",this.searchForm.segDateEnd,queryUtil.QueryOP.LE))
            }
            if (this.searchForm.isSegDate) {
                params.querys.push(queryUtil.createQueryParams("segDate", moment().format('yyyy-MM-DD'), queryUtil.QueryOP.EIC))
            }
            if (this.searchForm.selfPolicyStatus) {
                params.querys.push(queryUtil.createQueryParams("selfPolicyStatus",this.searchForm.selfPolicyStatus, queryUtil.QueryOP.EIC))
            }
            if (this.searchForm.isRefund) {
                params.querys.push(queryUtil.createQueryParams("refStatus",this.searchForm.isRefund))
            }
            if (this.searchForm.ticketStatus) {
                params.querys.push(queryUtil.createQueryParams("ticketStatus",this.searchForm.ticketStatus, queryUtil.QueryOP.EIC))
            }
            if (this.searchForm.ticketNumber) {
                params.querys.push(queryUtil.createQueryParams("ticketNumber",this.searchForm.ticketNumber, queryUtil.QueryOP.LK))
            }
            if (this.searchForm.psgName) {
                params.querys.push(queryUtil.createQueryParams("psgName",this.searchForm.psgName, queryUtil.QueryOP.LK))
            }
            if (this.searchForm.itineraryNo) {
                params.querys.push(queryUtil.createQueryParams("itineraryNo",this.searchForm.itineraryNo, queryUtil.QueryOP.LK))
            }
            if (this.searchForm.pingDateStart) {
                params.querys.push(queryUtil.createQueryParams("pingDate",this.searchForm.pingDateStart,queryUtil.QueryOP.GE))
            }
            if (this.searchForm.pingDateEnd) {
                params.querys.push(queryUtil.createQueryParams("pingDate",this.searchForm.pingDateEnd,queryUtil.QueryOP.LE))
            }
            if (this.activeName !== '-1') {
                params.querys.push(queryUtil.createQueryParams("status",parseInt(this.activeName), queryUtil.QueryOP.IN))
            } else {
                params.querys.push(queryUtil.createQueryParams("status",[0,1,2,3], queryUtil.QueryOP.IN))
            }
            return params;
        },
        getOrderList() {
            this.tableLoading = true
            this.orderList = []
            try {
                report_tRpTmcFlightItinerary_v1_query(this.getQueryParams()).then(res => {
                    this.tableLoading = false
                    this.orderList = res.datas.list
                    this.totalCount = res.datas.totalCount
                }).catch(() => {
                    this.tableLoading = false
                    this.orderList = []
                })
            } catch (e) {
                this.tableLoading = false
                this.orderList = []
            }
        },
        //导出行程单
        async exportBills() {
            this.exportBtnLog = true;
            try {
                let res = await report_tRpTmcFlightItinerary_v1_export(this.getQueryParams());
                window.open('https://' + res.datas, '_blank');
                this.exportBtnLog = false;
            }catch (e) {
                this.exportBtnLog = false;
            }
        },
        setReport() {
            this.fileList = [];
            this.dialogVisible = true;
            this.$nextTick(()=>{
                this.$refs.upload.clearFiles();
            })
        },
        /*downloadModel() {
            window.open(this.downloadModelUrl, '_blank');
        },*/
        detectionRemove(file){
            const reg2 = /^(\s|\S)+(xls|XLS|xlsx|XLSX)+$/;
            if (!reg2.test(file.name)) {
                this.$message({ type: "warning", message: "格式上传错误！" });
                return false;
            }
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        handleRequest(file) {
            this.fileList.push(file.file);
            console.log(this.fileList);
        },
        handleRemove() {
            this.fileList = [];
        },
        upload() {
            if (this.fileList.length === 0) {
                this.$message.warning('请先选择文件');
                return;
            }
            this.uploadLoading = true;
            let fd = new FormData()
            fd.append('file', this.fileList[0])
            report_tRpTmcFlightItinerary_v1_importUpd(fd).then(res=>{
                this.uploadLoading = false;
                this.dialogVisible = false;
                this.resultType = 1;
                this.successMsg = res.msg;
                this.dialogVisible2 = true;
            }, error => {
                this.uploadLoading = false;
                this.dialogVisible = false;
                this.resultType = 2;
                this.failFileUrl = error.data.msg;
                this.dialogVisible2 = true;
            }).catch(e=>{
                this.uploadLoading = false;
                this.dialogVisible = false;
                this.resultType = 2;
                this.failFileUrl = e.data.datas;
                this.dialogVisible2 = true;
            })
        },
        getFailUrlName(url) {
            let urlList = url.split('/');
            return urlList[urlList.length - 1];
        },
        downloadFailUrl() {
            const environment = global.appConfig.environment;
            const urlPrefix = baseUrl({
                environment: environment,
                appKey: "yinzhilv-report-default"
            });
            window.open(this.failFileUrl, '_blank');
        },
        closeResult() {
            this.dialogVisible2 = false;
            if (this.resultType === 1) {
                this.search();
            }
        },
        edit(row) {
            this.form = {
                id: row.id,
                ticketNumber: row.ticketNumber,
                psgName: row.psgName,
                status: row.status === 0 ? 1 : row.status,
                itineraryNo: row.itineraryNo,
                pingDate: row.pingDate,
                ticketStatus: row.ticketStatus || 0,
                price: row.price,
                remark: '',
            };
            this.editDialogVisible = true;
            this.$nextTick(()=>{
                this.$refs.editForm.clearValidate();
            })
        },
        checkForm() {
            return new Promise(resolve => {
                this.$refs.editForm.validate(valid=>{
                    resolve(valid);
                })
            })
        },
        async submitEdit() {
            const valid = await this.checkForm();
            if (!valid) {
                return;
            }
            this.editLoading = true;
            let [err, res] = await awaitWrap(report_tRpTmcFlightItinerary_v1_upd(this.form));
            if (err) {
                this.editLoading = false;
                return;
            }
            this.editLoading = false;
            this.editDialogVisible = false;
            this.$message.success('修改成功');
            this.getOrderList();
        },
    }
}

export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/kefu/door-report',
            name: 'admin-kefu-door-report',
            component: () => import(/* webpackChunkName: "page-admin-kefu-report-door-report" */ `./1.0.0/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `客服报表`,
                },
                entity_list_for_breadcrumb: [
                    {name: `客服报表`},
                    {name: `门票报表`},
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        return `admin-kefu-door-report`;
                    },
                },
            },
        },
    ],
};

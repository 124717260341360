//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Upload from "../../component/upload/import_latest_version_module";
import FlightClass from "@/page/admin/order-management/flight/component/flightClass/index.vue";
// import FlightSearch from "@/page/admin/replace-orders/flight-search/1.0.0/index.vue";
import ChangeFlightSearch from "@/page/admin/order-management/flight/component/change-flight-search";
import consumer_air_ticket_refChaReasonEnum from "@/lib/data-service/haolv-default/consumer_air_ticket_refChaReasonEnum";
export default {
    data() {
        var validateUrl = (rule, value, callback) => {
            console.log(value);
            if (value.length === 0) {
                callback(new Error('延误/病退证明不能为空'));
            } else {
                callback();
            }
        };
        return {
            changeForm: {},
            changeIndex: 0,
            oldSegmentList: [],
            changeSegmentList: [],
            startPickerOptions: {
                disabledDate: time => {
                    return time.getTime() < Date.now() - 1000 * 3600 * 24;
                }
            },
            dialogVisible: false,
            newTravelUser: [],

            refReasonEnum: [],
            rule: {
                realReason: [
                    { required: true, message: '改签原因不能为空', trigger: 'change' },
                ],
                url: [
                    { required: true, message: '延误/病退证明不能为空', trigger: 'change' },
                    {  validator: validateUrl, trigger: 'change' },
                ]
            },
        };
    },
    props: {
        formData: Object,
        evectionNo: String,
        evectionType: Number,
        orderNo: String,
        supplierOrderNo: String,
    },
    components: {
        // FlightSearch,
        Upload,
        FlightClass,
        ChangeFlightSearch
    },
    methods: {
        checkForm() {
            return new Promise((resolve, reject) => {
                this.$refs['changeForm'].validate((valid, res) => {
                    console.log(valid, res);
                    const unValidKeys = Object.keys(res);
                    console.log(unValidKeys);
                    unValidKeys.forEach(value => {
                        if (value === 'url') {
                            this.$message.warning('请上传延误/病退证明')
                        }
                    })
                    resolve(valid)
                })
            })
        },
        // 修改改签原因时触发
        changeFormDataType(val) {
            for (let item in this.formData.changeSegmentList) {
                this.formData.changeSegmentList[item].forEach(value => {
                    value.changeDate = value.beginDate
                })
            }
            this.changeSegmentList = []
        },
        /**选择航班传参 */
        getFlight(segment, airIndex) {
            this.airIndex = airIndex
            let obj = {
                cabinRank: segment.cabinRank,
                depDate: segment.changeDate,
                airlineName: segment.airlineName,
                airLineCode: segment.airlineCode, // 缺少二字码
                segmentTypeText: segment.segmentTypeText,
                depCityPort: {
                    name: segment.sCityName,
                    code: segment.scityCode || segment.sAirportCode
                },
                arrCityPort: {
                    name: segment.eCityName,
                    code: segment.ecityCode || segment.eAirportCode
                },
                flightNo: segment.flightNo,
                segmentNo: segment.segmentNo,
            };
            /*if (this.formData.changeType === 2) {
              obj.flightNo = segment.flightNo
            }*/
            this.oldSegmentList[airIndex] = segment.segmentNo;
            this.changeIndex = airIndex;
            this.changeForm = obj;
            this.newTravelUser = segment.userIdArr
            this.dialogVisible = true;
        },
        /**获取新航程 */
        getSegment(val) {
            let userInfoArr = this.formData.needChangeSegmentList[this.changeIndex].userInfoArr
            let violationResults = val.violationResults
            let ticketNumberList = []
            let passengerNoList = []
            if (violationResults.length > 0) {
                violationResults.forEach(value => {
                    userInfoArr.forEach(value1 => {
                        if (value.userId === value1.id) {
                            ticketNumberList.push(value1.ticketNo)
                            passengerNoList.push(value1.psgNo)
                        }
                    })
                })
            }
            let res = val.params.rqData.segmentList[0];
            let segment = {
                arrDate: res.arrDate,
                arrTime: res.arrTime,
                cabinName: res.cabinName,
                cabinCode: res.cabinCode,
                depDate: res.depDate,
                depTime: res.depTime,
                flightNo: res.flightNo,
                overproofApplyList: val.violationResults,
                segmentNo: this.oldSegmentList[this.changeIndex],
                ticketNumberList: ticketNumberList,
                passengerNoList: passengerNoList,
                changePrice: res.settlement,
                airportTax: res.airportTax,
                fuelTax: res.fuelTax,
                policyId: res.policyId,
                price: res.price,
                changeFace: res.price,
                flightKey: res.flightKey,
                businessExt: res.businessExt,
                discount: res.discount,
                depAirportName: res.depAirportName,
                depAirportCode: res.depAirportCode,
                arrAirportName: res.arrAirportName,
                arrAirportCode: res.arrAirportCode,
                depTerminal: res.depTerminal,
                arrTerminal: res.arrTerminal,
                cabinRank: res.cabinRank,
            };
            this.dialogVisible = false;
            this.$set(this.changeSegmentList, this.changeIndex, [res]);
            this.$set(this.formData.segmentList, this.changeIndex, segment);
            this.formData.changeStatu = true;
        },
        clearData() {
            this.changeSegmentList = []
            this.changeIndex = 0
        },
        getRefReasonEnum() {
            consumer_air_ticket_refChaReasonEnum({'bizType': 2}).then(res=>{
                let refReasonEnum = res.datas;
                refReasonEnum.forEach(value=>{
                    let text = '';
                    if (value.reasonType === 1) {
                        text+= '个人原因：'
                    }
                    if (value.reasonType === 2) {
                        text+= '航司原因：'
                    }
                    text+= value.msg;
                    text+=value.chaReason === 1 ? '（自愿改签）' : '（非自愿改签）'
                    console.log(text);
                    value.text = text;
                })
                this.refReasonEnum = refReasonEnum;
            })
        },
        changeReason(val) {
            console.log(val);
            this.refReasonEnum.forEach(value=>{
                if (value.code === val) {
                    this.formData.chaReason = value.chaReason;
                    this.formData.chaReasonText = value.chaReason === 1 ? '自愿改签' : '非自愿改签';
                    // this.formData.realReason = value.code
                    this.formData.realReasonText = value.msg
                    // this.form.realReasonText1 = value.msg + '（'+ (value.chaReason === 1 ? '自愿改签' : '非自愿改签') +'）';
                }
            })
        },
    },
    mounted() {
    },
    activated() {
        this.clearData();
        this.getRefReasonEnum();
    },
    watch: {
        formData(val) {
            this.$emit("update:formData", val);
        }
    },
    computed: {}
};

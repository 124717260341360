import Calendar from '@/component/calendar/index.vue';
import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.1.0/index.vue';
import consumer_admin_trains_order_checkTheRemainingTrainTickets from '@/lib/data-service/haolv-default/consumer_admin_trains_order_checkTheRemainingTrainTickets';
import consumer_trains_common_queryRailwayTimetable from '@/lib/data-service/haolv-default/consumer_trains_common_queryRailwayTimetable';
import consumer_trains_order_scheduledToCheck from '@/lib/data-service/haolv-default/consumer_trains_order_scheduledToCheck';
import consumer_admin_trains_order_addJourney from '@/lib/data-service/haolv-default/consumer_admin_trains_order_addJourney';
import CompanyInfo from '@/component/company-info/index.vue'
// import CheckTravel from '@/component/checkTravel/index.vue';

// import trainList from "./api";
export default {
    data () {
        return {
            loading: false,
            redirectType: '',
            evectionNo: '', // 出差预定的id
            evectionType: 1, // 1为个人出差， 2为出差单出差
            orderNo: '',
            initStatus: 0,
            pickerOptions: {
                disabledDate: this.disabledDate
            },
            fromStation: {},
            toStation: {},
            oriFromStation: {},
            oriToStation: {},
            params: {
                fromStationCode: '',
                toStationCode: '',
                trainDate: '',
                behalfCompanyId: ''
            },
            pickerTrainDate: '',
            oriTrainDate: '',
            isAllTrain: true, // 是否搜索全部类型
            onlySearchTrain: false, // 是否只搜高铁列车
            checkTrainList: [],
            showLoading: false, // loading时是否显示10分钟刷新的提示
            trainList: [],
            daySpacing: '',
            isHasTicket: false, // 是否只显示有票
            reloadTicketFun: '',
            reloadNum: 0,
            loadingNum: 0,

            currentTrainInfo: {}, // 当前选定订票的火车票信息

            fromStationNum: 0,
            toStationNum: 0,
        }
    },
    components: {
        Calendar,
        TrainCitySelector,
        CompanyInfo,
        // CheckTravel
    },
    created () {},
    mounted () {
        this.$refs.formTrainCitySelector.init({
            placeholder_text: `请选择出发城市`,
        })
    },
    activated () {
        this.params.behalfCompanyId = Number(this.$route.query.behalfCompanyId);
        this.redirectType = this.$route.query.redirectType;
        this.evectionNo = this.$route.query.evectionNo ? this.$route.query.evectionNo : '';
        if (this.redirectType === '002') {
            this.orderNo = this.$route.query.orderNo
        }
        if (this.$route.query.fromStationCode) {
            this.fromStation = {
                stationCode: this.$route.query.fromStationCode
            }
        } else {
            this.fromStation = {}
        }
        if (this.$route.query.toStationCode) {
            this.toStation = {
                stationCode: this.$route.query.toStationCode
            }
        } else {
            this.toStation = {}
        }
        this.evectionType = parseInt(this.$route.query.evectionType);
        this.params.fromStationCode = this.$route.query.fromStationCode ? this.$route.query.fromStationCode : '';
        this.params.toStationCode = this.$route.query.toStationCode ? this.$route.query.toStationCode : '';
        this.params.trainDate = this.$route.query.time ? this.$route.query.time : this.$moment().locale('zh-cn').format('YYYY-MM-DD');
        this.oriFromStation = Object.assign({}, this.fromStation);
        this.oriToStation = Object.assign({}, this.toStation);
        this.oriTrainDate = this.params.trainDate;
        this.pickerTrainDate = this.$route.query.time ? this.$route.query.time : this.$moment().locale('zh-cn').format('YYYY-MM-DD');
        let onlySearch = parseInt(this.$route.query.onlySearch); // 是否只搜动车
        if (onlySearch === 1) {
            this.isAllTrain = false;
            this.onlySearchTrain = true;
            this.checkTrainList = [1, 2]; // 传入动车的数值
        }
        if ((!this.$route.query.fromStationCode && this.$route.query.fromStationName) || (!this.$route.query.toStationCode && this.$route.query.toStationName)) {
            let formTrainCitySelector = this.$route.query.fromStationName ? this.$refs.formTrainCitySelector.set_entity({
                type: `000`,
                type_parameter: {
                    '000': {
                        name: this.$route.query.fromStationName,
                    },
                }
            }) : ''
            let toTrainCitySelector = this.$route.query.toStationName ? this.$refs.toTrainCitySelector.set_entity({
                type: `000`,
                type_parameter: {
                    '000': {
                        name: this.$route.query.toStationName,
                    },
                }
            }) : ''
            Promise.all([formTrainCitySelector, toTrainCitySelector]).then((res) => {
                console.log(res)
                this.$nextTick(() => {
                    this.getTrainList()
                })
            })
        } else {
            this.getTrainList();
        }
        this.reloadTicket();

        // this.initCheckTravelBox();
    },
    deactivated() {
        clearInterval(this.reloadTicketFun)
    },
    destroyed () {
        clearInterval(this.reloadTicketFun)
    },
    watch: {
        fromStation(newVal, oldVal) {
            if (newVal === null) {
                this.params.fromStationCode = ''
            } else {
                this.params.fromStationCode = newVal.stationCode
            }
            if (this.fromStationNum === 0 && newVal.stationCode !== undefined && newVal.stationName !== undefined) {
                this.oriFromStation = Object.assign({}, newVal);
                this.fromStationNum++;
            }
        },
        toStation(newVal, oldVal) {
            if (newVal === null) {
                this.params.toStationCode = ''
            } else {
                this.params.toStationCode = newVal.stationCode
            }
            if (this.toStationNum === 0 && newVal.stationCode !== undefined && newVal.stationName !== undefined) {
                this.oriToStation = Object.assign({}, newVal);
                this.toStationNum++;
            }
        },
    },
    computed: {},
    filters: {},
    methods: {
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            const day = this.$moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        // 个人出差预定
        setOrder() {
            let loading;
            let buyTicketsType = 0;
            if (this.currentTrainInfo.isSale === '1' && parseInt(this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatInventory) > 0) {
                buyTicketsType = 0
            } else if (this.currentTrainInfo.isSale === '1' && parseInt(this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatInventory) <= 0) {
                buyTicketsType = 4
            } else {
                buyTicketsType = 5
            }
            let [...robTicketNote] = this.currentTrainInfo.trainSeatVoList;
            let params = {
                companyId: this.params.behalfCompanyId,
                acceptNoSeat: 0, // 是否接受无座: 1：接受 0：不接受
                fromStationCode: this.currentTrainInfo.fromStationCode,
                fromStationName: this.currentTrainInfo.fromStationName,
                fromTime: this.currentTrainInfo.fromTime,
                seatCode: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatCode,
                seatName: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatName,
                seatPrice: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatPrice,
                canChooseSeat: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].canChooseSeat === true ? 1 : 2,
                numberOfSeats: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].numberOfSeats,
                startDate: this.$moment(this.currentTrainInfo.trainStartDate).format('YYYY-MM-DD'),
                toStationCode: this.currentTrainInfo.toStationCode,
                toStationName: this.currentTrainInfo.toStationName,
                trainCode: this.currentTrainInfo.trainCode,
                arriveDate: this.$moment(this.currentTrainInfo.arriveDate).format('YYYY-MM-DD'),
                buyTicketsType: buyTicketsType, // 购票方式 0:自取票 1：送票上门2：12306 购票 4：抢票 5：预约抢票
                toTime: this.currentTrainInfo.toTime,
                trainNo: this.currentTrainInfo.trainNo,
                evectionNo: this.evectionNo,
                overproofApplyList: [],
                robTicketNote: JSON.stringify(robTicketNote),
                orderNo: this.orderNo
            };
            loading = this.$loading({
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            consumer_admin_trains_order_addJourney(params).then(res => {
                loading.close();
                this.$router.push({
                    name: 'admin-train-travel-reserve',
                    query: {
                        evectionNo: res.datas.evectionNo,
                        evectionType: this.evectionType,
                        redirectType: this.redirectType,
                    }
                })
            }).catch(() => {
                loading.close()
            })
        },
        disabledDate(time) {
            let month = this.$moment().month();
            let day = 0;
            if (month === 0 || month === 1) {
                day = 61
            } else if (month === 6) {
                day = 64
            } else {
                day = 63
            }
            return (time.getTime() < Date.now() - 8.64e7) || (time.getTime() > (Date.now() - 8.64e7 + day * 24 * 60 * 60 * 1000))
        },
        setToday() {
            this.pickerTrainDate = this.$moment().locale('zh-cn').format('YYYY-MM-DD')
        },
        changeOnlySearchTrain(val) {
            if (val) {
                this.isAllTrain = false;
                this.checkTrainList = [1, 2] // 传入动车的数值
            } else {
                this.isAllTrain = true;
                this.checkTrainList = []
            }
            // 开始过滤
            this.trainList = this.trainFilter(this.oriTrainList)
        },
        getTrainList() {
            if (this.params.fromStationCode === '' || this.params.fromStationCode === undefined) {
                this.$message({
                    message: '请先选择出发地',
                    type: 'warning'
                });
                return
            }
            if (this.params.toStationCode === '' || this.params.toStationCode === undefined) {
                this.$message({
                    message: '请先选择目的地',
                    type: 'warning'
                });
                return
            }

            if (this.pickerTrainDate === '' || this.pickerTrainDate === null) {
                this.$message({
                    message: '请先选择日期',
                    type: 'warning'
                });
                return
            }
            this.params.trainDate = this.pickerTrainDate
            if (this.params.trainDate === '' || this.params.trainDate === null) {
                this.$message({
                    message: '请先选择日期',
                    type: 'warning'
                });
                return
            }
            let loading;
            loading = this.$loading({
                target: this.$refs.trainTable.$el || '',
                lock: true,
                text: this.showLoading ? '页面停留超过10分钟，正在为您重新查询' : '',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            consumer_admin_trains_order_checkTheRemainingTrainTickets(this.params).then(res => {
                this.showLoading = false;

                this.oriTrainDate = this.params.trainDate;
                let resultList = res.resultList;
                this.oriTrainList = resultList;
                resultList.forEach(value => {
                    value.timeTable = [];
                    value.arriveDateText = this.$moment(value.arriveDate).format('YYYY-MM-DD hh:mm:ss')
                });
                this.trainList = this.trainFilter(this.oriTrainList)
                this.oriFromStation = Object.assign({}, this.fromStation);
                this.oriToStation = Object.assign({}, this.toStation);

                loading.close();
                this.loadingNum++
            }).catch(() => {
                loading.close();
                this.showLoading = false;
                this.loadingNum++
            })
        },
        trainFilter(list) {
            let trainList = list;
            // 根据时间筛选 如果发车日期在一个月内，则不显示'预约抢'的车次，如果发车日期在一个月外，则不显示'预约和'抢票'
            /*let startDay = this.$moment()
            let searchDay = this.$moment(this.params.trainDate)
            this.daySpacing = searchDay.diff(startDay, 'days')
            let dayTrainItemList = []
            trainList.forEach(value => {
                if ((this.daySpacing + 1) === 29 || ((this.daySpacing + 1) <= 30 && value.isSale === '1') || ((this.daySpacing + 1) >= 30 && value.isSale === '0')) { // 预售期内且开售了 || 预售期外且没有开售
                    dayTrainItemList.push(value)
                }
            })
            trainList = dayTrainItemList*/

            // 筛选列车类型
            if (!this.isAllTrain && this.checkTrainList.length > 0) {
                let trainItemList = [];
                this.checkTrainList.forEach(value => {
                    trainList.forEach(val => {
                        if (value === val.motorcycleType) {
                            trainItemList.push(val)
                        }
                    })
                });
                trainList = trainItemList
            }

            // 筛选是否显示有票的
            if ((this.daySpacing + 1 <= 30) && this.isHasTicket) {
                let trainItemList = [];
                trainList.forEach(value => {
                    let hasTicket = false;
                    value.trainSeatVoList.forEach(val => {
                        if (val.seatInventory > 0) {
                            hasTicket = true
                        }
                    });
                    if (hasTicket) {
                        trainItemList.push(value)
                    }
                });
                trainList = trainItemList
            }
            return trainList
        },
        showThisStationList(index, val) {
            if (val.timeTable.length > 0) {
                return
            }
            let params = {
                fromStationCode: val.fromStationCode,
                toStationCode: val.toStationCode,
                trainCode: val.trainCode,
                trainDate: this.$moment(val.trainStartDate).format('YYYY-MM-DD')
            };
            consumer_trains_common_queryRailwayTimetable(params).then(res => {
                let result = res.results;
                let listType = 0;
                for (let i = 0, l = result.length; i < l; i++) {
                    if (result[i].stationName === val.fromStationName) {
                        listType = 1;
                        result[i].listType = 2;
                        continue
                    }
                    if (result[i].stationName === val.toStationName) {
                        listType = 0;
                        result[i].listType = 2;
                        continue
                    }
                    result[i].listType = listType
                }
                this.trainList[index].timeTable = result
            })
        },
        tableRowClassName({row, rowIndex}) {
            if (row.listType === 0) {
                return 'grey-line'
            } else if (row.listType === 1) {
                return 'default-line'
            } else {
                return 'active-line'
            }
        },
        changeFormAndTo() {
            let fromStation = this.toStation;
            let toStation = this.fromStation;
            let fromStationCode = this.params.toStationCode;
            let toStationCode = this.params.fromStationCode;
            this.fromStation = fromStation;
            this.toStation = toStation;
            this.params.fromStationCode = fromStationCode;
            this.params.toStationCode = toStationCode
        },
        changeHasTicket(val) {
            if (this.oriTrainList.length === 0) {
                return
            }
            this.trainList = this.trainFilter(this.oriTrainList)
        },
        async reserve(val, index, sIndex) {
            this.currentTrainInfo = val;
            this.currentTrainInfo.sIndex = sIndex;

            console.log(this.$refs.checkTravelBox);
            this.setOrder()
        },
        copyText(row, seatIndex) { // 复制报价
            const __this = this;
            const trainStartDate = row.trainStartDate ? this.$moment(row.trainStartDate).format('YYYY-MM-DD') : row.trainStartDate;
            const week = __this.filterWeek(row.trainStartDate);
            const totalPrice = Number(row.trainSeatVoList[seatIndex].seatPrice);
            const quoted_price =
                '请确认列车信息：\n' +
                trainStartDate + '（' + week + '）\n' +
                row.trainCode + '次' + row.fromStationName + '（' + row.fromTime + '开）— ' + row.toStationName + '（' + row.toTime + '到）\n' +
                '成人票 ' + row.trainSeatVoList[seatIndex].seatName + '\n' +
                '铨成结算价：'+ row.trainSeatVoList[seatIndex].seatPrice +'元+' + '0元服务费共' + totalPrice + '元';

            const _textarea = document.createElement("textarea");   // 直接构建文本框
            _textarea.value = quoted_price;  // 设置内容
            document.body.appendChild(_textarea);    // 添加临时实例
            _textarea.select();   // 选择实例内容
            document.execCommand("Copy");   // 执行复制
            __this.$message.success('复制成功！');
            document.body.removeChild(_textarea); // 删除临时实例



        },


        ticketGrabbing() {},
        clickDate(val) {
            this.params.trainDate = val;
            this.pickerTrainDate = val;
            this.getTrainList()
        },
        reloadTicket() {
            this.reloadTicketFun = setInterval(() => {
                this.reloadNum++;
                if (this.reloadNum === 10 * 60) {
                    this.showLoading = true;
                    this.getTrainList();
                    // 页面停留超过10分钟，正在为您重新查询
                    this.reloadNum = 0
                }
            }, 1000)
        },

        changeSort() {},
    }
}
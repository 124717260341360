// 订单数量 http://api-beta.haolvtrip.com/doc.html#/tmc-biz/admin-%E9%A6%96%E9%A1%B5/adminPagePaymentCountUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_page_paymentCount = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/page/paymentCount',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_page_paymentCount;
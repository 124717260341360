const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 获取生编类型枚举：http://yapi.yinzhitravel.com/project/52/interface/api/15340
const consumer_web_company_getPnrSetType = (pParameter) => {
  if (!pParameter) pParameter = {};

  const p = {
    method: 'post',
    urlSuffix: '/consumer/web/company/getPnrSetType',
    data: pParameter,
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
};
module.exports = consumer_web_company_getPnrSetType;

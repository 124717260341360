// 获取酒店订单明细

const __request = require(`./__request/__request_contentType_json`)
const consumer_admin_hotel_order_forHotelOrderDetails = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/admin/hotel/order/forHotelOrderDetails',
    data: data
  }
  return __request(pParameter)
}
export default consumer_admin_hotel_order_forHotelOrderDetails

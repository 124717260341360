/*平台酒店规则外部分退订处理操作
  POST /consumer/admin/hotel/order/updateHotelOrderPartialCancel
  接口ID：59364483
  接口地址：https://www.apifox.cn/link/project/458418/apis/api-59364483
  */
const __request = require(`./__request/__request_contentType_json`)
const consumer_admin_hotel_order_updateHotelOrderPartialCancel = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/admin/hotel/order/updateHotelOrderPartialCancel',
    data: data
  }
  return __request(pParameter)
}
export default consumer_admin_hotel_order_updateHotelOrderPartialCancel
const __request = require(`./__request/__request_contentType_json`)
const consumer_intention_order_intentionList = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/intention/order/intentionList',
    data: data
  }
  return __request(pParameter)
}
export default consumer_intention_order_intentionList

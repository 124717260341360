import OrderLog from '@/component/orderLog/2.0.0/index.vue'
import moment from "moment";
import consumer_admin_trains_order_orderDetail from "@/lib/data-service/haolv-default/consumer_admin_trains_order_orderDetail";
import consumer_admin_insurance_refundInsuranceOrder from "@/lib/data-service/haolv-default/consumer_admin_insurance_refundInsuranceOrder";//退保
import consumer_admin_insurance_checkAllowRefundInsurance from "@/lib/data-service/haolv-default/consumer_admin_insurance_checkAllowRefundInsurance";//保单退款
import consumer_admin_insurance_tradeRefundInsuranceOrder from "@/lib/data-service/haolv-default/consumer_admin_insurance_tradeRefundInsuranceOrder";//重新投保
import consumer_admin_insurance_orderTrainsInsuranceAgain from "@/lib/data-service/haolv-default/consumer_admin_insurance_orderTrainsInsuranceAgain";//保单详情
import consumer_admin_insurance_cancelInsuranceOrder from '@/lib/data-service/haolv-default/consumer_admin_insurance_cancelInsuranceOrder'
import consumer_insurance_getEInsuranceOrderUrl from "@/lib/data-service/haolv-default/consumer_insurance_getEInsuranceOrderUrl"
import consumer_admin_trains_order_remarkSaveOrUpdate from '@/lib/data-service/haolv-default/consumer_admin_trains_order_remarkSaveOrUpdate'
import consumer_admin_insurance_orderInsuranceAgain from '@/lib/data-service/haolv-default/consumer_admin_insurance_orderInsuranceAgain'
import consumer_log_trainsList from '@/lib/data-service/haolv-default/consumer_log_trainsList'
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";
import _ from 'underscore';
import ChangeService from "@/component/change-service/index.vue";
export default {
    components: {ChangeService, OrderLog},
    data() {
        return {
            loading: true,
            activeName: 'detail',
            details: {},
            refundVisible: false,
            changeVisible: false,
            // dialog显示关闭控制
            dialogVisible: false,
            // dialog展示保险数据
            dialogVisibleData: {},
            // dialog顶部展示文字
            dialogVisibleText: '确认退保',
            // dialog--退款操作所需数据
            dialogVisibleRefund: {
                orderNo: null,
                productCode: null,
                userId: null,
            },
            // dialog--重新投保操作所需数据
            dialogVisibleAgain: {
                orderNo: null,
                productCode: null,
                userId: null,
            },
            form: {
                orderNo: '',
                remark: '',
                businessType: 1,
            },
        };
    },
    methods: {
        checkInsuranceDetail(val) {
            this.loading = true
            const params = {
                insuranceOrderId: val.insuranceOrderId
            }
            consumer_insurance_getEInsuranceOrderUrl(params).then(res => {
                const url = res.datas.epolicyUrl
                if (url) {
                  window.open(url)
                }
            }).finally(() => {
                this.loading = false
            })
        },
        refundMoney(val) {
            let companyIdBox = '';
            this.details.passengers.forEach((item,index) => {
                if (val.userId == item.userId) {
                    companyIdBox = item.companyId;
                }
            });
            const h = this.$createElement;
            this.$msgbox({
                title: '确认退款',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                const params = {
                    orderNo: this.details.orderNo,
                    productCode: val.productCode,
                    userId: val.userId,
                    companyId: companyIdBox,
                };
                this.loading = true;
                consumer_admin_insurance_tradeRefundInsuranceOrder(params).then(res => {
                    this.loading = false;
                    this.$message({
                        type: "success",
                        message: '退款成功'
                    });
                    this.get_order_detail()
                }).finally(() => {
                    this.loading = false;
                })
            }).catch(() => {
                this.loading = false
            });
        },
        reactInsurance(val) {
            const h = this.$createElement;
            this.$msgbox({
                title: '确认投保',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                const params = {
                    businessType: 2,   // 业务类型
                    userId: val.userId,
                    productCode: val.productCode,
                    orderNo: this.$route.query.orderNo,
                    chaOrderNo: '',
                    segmentNo: '',
                };
                this.loading = true;
                consumer_admin_insurance_orderInsuranceAgain(params).then(res => {
                    this.loading = false;
                    this.$message({
                        type: "success",
                        message: '投保成功'
                    });
                    this.get_order_detail()
                }).finally(() => {
                    this.loading = false;
                });
            }).catch(() => {
                this.loading = false
            });
        },
        async refundInsurance(val) {
            const params = {
                insuranceOrderId: val.insuranceOrderId
            }
            let [err, res] = await awaitWrap(consumer_admin_insurance_checkAllowRefundInsurance(params))
            if (err) {
                this.$message({
                    type: "warning",
                    message: '保险已生效,无法退保'
                })
                return
            }
            const h = this.$createElement;
            this.$msgbox({
                title: '确认退保',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                this.loading = true;
                let data = {
                    insuranceOrderId: params.insuranceOrderId,
                    orderNo: this.orderNo,
                };
                consumer_admin_insurance_refundInsuranceOrder(data).then(res => {
                    this.loading = false
                    this.$message({
                        type: "success",
                        message: '退保成功'
                    })
                    this.get_order_detail()
                })
            }).catch(() => {
                this.loading = false
            });
        },
        cancelInsurance(val) {
            const params = {
                orderNo: this.$route.query.orderNo,
                userId: val.userId,
            }
            const h = this.$createElement;
            this.$msgbox({
                title: '确认取消投保',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                this.loading = true
                consumer_admin_insurance_cancelInsuranceOrder(params).then(res => {
                    this.loading = false
                    this.$message({
                        type: "success",
                        message: '取消投保成功'
                    })
                    this.get_order_detail()
                })
            }).catch(() => {
                this.loading = false
            });
        },
        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    // this.loading = true
                    // 调用获取订单日志的接口
                    return consumer_log_trainsList({
                        orderNo: this.$route.query.orderNo
                    }).then(res => {
                        // 组装组件需要的list
                        const list = _.chain(res.datas)
                            .map(function (m) {
                                // 替换名字
                                const o = {
                                    operator: m.operator,
                                    date: m.operatorTime,
                                    type: m.logSource,
                                    detail: m.logContents
                                }
                                return o
                            }).value();
                        const d = {
                            entityList: list
                        }
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        // this.loading = false
                    })
                }
            })
        },
        clickInsurance(val){
            if (val.insuranceOrderId) {
                let data = {
                    insuranceOrderId: val.insuranceOrderId,
                };
                consumer_insurance_getEInsuranceOrderUrl(data).then(res => {
                    const __this = this;
                    const eleLink = document.createElement('a');
                    eleLink.href = res.datas.epolicyUrl;
                    eleLink.target = '_blank';
                    document.body.appendChild(eleLink);
                    eleLink.click();
                    document.body.removeChild(eleLink);
                })
            } else {
                this.$message.error('正在生成保单信息，请稍后查询...');
            }

        },
        //重新投保--弹窗
        clickAgainDialog(val){
            //可以重新投保--展示Dialog
            this.dialogVisible = true;
            this.dialogVisibleText = '确认投保';
            this.dialogVisibleData = {
                insuranceType: val.insuranceType,
                insured: val.insured,
                insuranceOrderId: val.insuranceOrderId,
            };
            this.dialogVisibleAgain = {
                orderNo: this.$route.query.orderNo,
                productCode: val.productCode,
                userId: val.userId,
            }
        },
        //重新投保--操作
        clickAgain(val){
            let data = {
                orderNo: val.orderNo,
                productCode: val.productCode,
                userId: val.userId,
            };
            this.loading = true;
            consumer_admin_insurance_orderTrainsInsuranceAgain(data)
                .then(res => {
                    this.dialogVisible = false;
                    this.$message({
                        message: '重新投保成功',
                        type: 'success'
                    })
                    this.get_order_detail();
                    this.getOrderLog();
                    this.loading = false;
                })
                .catch(res => {
                    this.$message.error('重新投保失败');
                    this.loading = false;
                })
        },
        //退款--弹窗
        clickRefundDialog(val){
            //可以退款--展示Dialog
            this.dialogVisible = true;
            this.dialogVisibleText = '确认退款';
            this.dialogVisibleData = {
                insuranceType: val.insuranceType,
                insured: val.insured,
                insuranceOrderId: val.insuranceOrderId,
            };
            this.dialogVisibleRefund = {
                orderNo: this.$route.query.orderNo,
                productCode: val.productCode,
                userId: val.userId,
            }
        },
        //退款--操作
        clickRefund(val){
            let data = {
                orderNo: val.orderNo,
                productCode: val.productCode,
                userId: val.userId,
            };
            this.loading = true;
            consumer_admin_insurance_tradeRefundInsuranceOrder(data)
                .then(res => {
                    this.dialogVisible = false;
                    this.$message({
                        message: '退款成功',
                        type: 'success'
                    })
                    this.get_order_detail();
                    this.getOrderLog();
                    this.loading = false;
                })
                .catch(res => {
                    this.$message.error('退款失败');
                    this.loading = false;
                })
        },
        clickDefine(val){
            let data = {
                insuranceOrderId: val.insuranceOrderId,
                orderNo: this.$route.query.orderNo
            };
            this.loading = true;
            consumer_admin_insurance_refundInsuranceOrder(data)
                .then(res => {
                    this.dialogVisible = false;
                    this.$message({
                        message: '退保成功',
                        type: 'success'
                    });
                    this.get_order_detail();
                    this.getOrderLog();
                    this.loading = false;
                })
                .catch(res => {
                    this.$message.error('退保失败');
                    this.loading = false;
                })
        },
        clickSurrender(val) {
            let data = {
                insuranceOrderId: val.insuranceOrderId
            };
            consumer_admin_insurance_checkAllowRefundInsurance(data)
                .then(res => {
                    //可以退保--展示Dialog
                    this.dialogVisible = true;
                    this.dialogVisibleText = '确认退保';
                    this.dialogVisibleData = {
                        insuranceType: val.insuranceType,
                        insured: val.insured,
                        insuranceOrderId: val.insuranceOrderId,
                    };
                })
                .catch(() => {
                    //无法退保
                    this.$message.error('保险已生效 无法退保');
                });
        },
        get_order_detail() {
            const __this = this;
            const params = {
                orderNo: __this.$route.query.orderNo
            };
            __this.loading = true;
            consumer_admin_trains_order_orderDetail(params).then((res) => {
                __this.details = res.datas;
                __this.form.remark = res.datas.remark;
            }).finally(() => {
                setTimeout(() => {
                  __this.loading = false;
                },1000)
            })
        },
        submit() {
            if (this.form.remark === '') {
                this.$message({
                    type: "warning",
                    message: '请输入备注'
                });
                return
            }
            let form = JSON.parse(JSON.stringify(this.form));
            form = {...form,proxyPrintServicePrice: this.details.proxyPrintServicePrice};
            this.loading = true;
            consumer_admin_trains_order_remarkSaveOrUpdate(form).then(res => {
                this.loading = false;
                this.$message({
                    type: "success",
                    message: '提交成功'
                });
                this.get_order_detail();
                this.getOrderLog()
            }, error => {
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        back() {
            this.$router.push({
                name: 'admin-train-order-list'
            })
        },

        toChange() {
            const __this = this;
            // 采购渠道 1-12306 2-携程 3-高铁管家 4.线上订单 5.代客下单
            if ([4,5].indexOf(this.details.ticketChannel) === -1){
                __this.$router.push({
                    name: 'admin-train-change-offline',
                    query: {
                        orderNo: this.details.orderNo,
                    },
                })
            } else {
                __this.$router.push({
                    name: 'admin-train-change',
                    query: {
                        orderNo: this.details.orderNo,
                    },
                })
            }
        },
        toReturn() {
            // 采购渠道 1-12306 2-携程 3-高铁管家 4.线上订单 5.代客下单
            const __this = this;

            if ([4,5].indexOf(this.details.ticketChannel) === -1){
                __this.$router.push({
                    name: 'admin-train-refund-handle',
                    query: {
                        orderNo: this.details.orderNo,
                        type: 'handle'
                    },
                })
            } else {
                __this.$router.push({
                    name: 'admin-train-return',
                    query: {
                        orderNo: this.details.orderNo,
                    },
                })
            }
        },
        reactForm() {
            this.form = {
                orderNo: '',
                remark: '',
                businessType: 1,
            };
            this.form.orderNo = this.$route.query.orderNo;
        },
        changeService() {
            let params = {
                businessType: 21,
                orderNo: this.details.orderNo,
                oldService: this.details.serviceChargeAmount,
                oldProxyPrintServiceFee: this.details.proxyPrintServicePrice,
            };
            this.$refs.changeService.init(params).then(res=>{
                this.get_order_detail();
            }).catch((e)=>{})
        },
    },
    created() {

    },
    activated() {
        const __this = this;
        __this.reactForm();
        __this.get_order_detail();
        __this.getOrderLog();
    },
    filters: {
        formatDate(val) {
            return val ? moment(val).format('YYYY-MM-DD HH:mm') : '';
        },
        formatDateTime(val) {
            return val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : '';
        }
    }
}
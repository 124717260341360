export default {
    data() {
        return {
            showMore: false,
        }
    },
    props: {
        moreConditionSwitch: {
            type: Boolean,
            default: true,
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        onSearch() {
            this.$emit('onSearch')
        },
        onReset() {
            this.$emit('onReset')
        },
        showSearchMore() {
           this.showMore = !this.showMore
        },
    }
}

export default {
    path: "",
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: "/admin/credit-detail",
            name: "admin-credit-detail",
            component: () => import(/* webpackChunkName: "page-admin-credit-management-credit-detail" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `授信详情`,
                },
                entity_list_for_breadcrumb: [
                    { name: `客户管理` },
                    { name: `授信审核列表` },
                    { name: `授信详情` },
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        return `admin-credit-users-list`;
                    },
                },
            },
        },
    ],
};

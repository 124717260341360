// 用车订单授信退款 http://yapi.yinzhitravel.com/project/52/interface/api/15740
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_car_order_toRefundCarOrder = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/car/order/toRefundCarOrder',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_car_order_toRefundCarOrder;

import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'
import {awaitWrap} from "@/page/admin/organizational-structure/common/unit";

import consumer_intention_order_orderList
    from "@/lib/data-service/haolv-default/consumer_intention_order_orderList"


export default {
    data() {
        return {
            loading: false,
            createDate: [],//下单日期
            searchForm: {
                orderNo: '',//意向单编号
                productName: '',//产品名称
                customerServiceName: '',//操作客服
                callPerson: '',//联系人
                callPersonPhone: '',//联系电话
                startDateTime: '',//下单日期-开始
                endDateTime: '',//下单日期-结束
                status: 0,//状态

                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            },
            //意向单状态 0-全部 1-处理中 2-已完成 3-已取消 4-已退款
            tabList: [
                {
                    status: 0,
                    statusText: '全部',
                    number: 0
                },
                {
                    status: 1,
                    statusText: '处理中',
                    number: 0
                },
                {
                    status: 2,
                    statusText: '已完成',
                    number: 0
                },
                {
                    status: 3,
                    statusText: '已取消',
                    number: 0
                },
                {
                    status: 4,
                    statusText: '已退款',
                    number: 0
                }
            ],
            list: [],//授权客户列表
            activeName: '0',
            totalCount: 0,   //总条数
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    activated() {
        this.init();
    },
    methods: {
        // 初始化
        async init() {
            await this.getList();
        },

        //获取列表
        getList() {
            try {
                this.loading = true;
                return consumer_intention_order_orderList(this.searchForm).then(res => {
                    if (res.datas) {
                        this.tabList = res.datas.orderStatusList;
                        if (res.datas.intentionOrderList) {
                            this.list = res.datas.intentionOrderList.list;
                            this.totalCount = res.datas.intentionOrderList.totalCount;
                        }
                    }
                })
            } catch (error) {
                this.list = [];
                this.totalCount = 0;
                this.loading = false
                this.$message({
                    type: "error",
                    message: "获取意向单列表失败！"
                })
            } finally {
                this.loading = false
            }
        },

        //搜索
        onSearch() {
            this.searchForm.currentPage = 1
            this.searchForm.status = this.activeName === '0' ? 0 : parseInt(this.activeName)
            this.getList()
        },

        //重置
        onReset() {
            this.searchForm = {
                orderNo: '',//意向单编号
                productName: '',//产品名称
                customerServiceName: '',//操作客服
                callPerson: '',//联系人
                callPersonPhone: '',//联系电话
                startDateTime: '',//下单日期-开始
                endDateTime: '',//下单日期-结束
                status: 0,//状态

                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            };
            this.createDate = [];
            this.searchForm.status = this.activeName === '0' ? 0 : parseInt(this.activeName)
            this.onSearch()
        },

        //改变下单日期范围
        changeCreateDate(val) {
            if (val) {
                this.searchForm.startDateTime = val[0] + ' 00:00:00';
                this.searchForm.endDateTime = val[1] + ' 23:59:59';
            } else {
                this.searchForm.startDateTime = '';
                this.searchForm.endDateTime = '';
            }
            this.onSearch();
        },

        //查看详情
        toDetails(orderNo) {
            this.$router.push({
                name: "admin-intention-detail",
                query: {
                    orderNo
                }
            })
        },

        //去处理
        toHandle(orderNo) {
            this.$router.push({
                name: "admin-intention-handle",
                query: {
                    orderNo
                }
            })
        }
    }
}

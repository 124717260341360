import ChangeClause from '@/page/admin/replace-orders/component/changeClause/1.0.0/index.vue'
export default {
    data() {
        return {}
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        ruleText: {
            type: Object,
            default: () => {}
        },
        showProductRule: {
            type: Boolean,
            default: true
        }
    },
    components: {ChangeClause},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        ruleShow: {
            get () {
                return this.show
            },
            set (val) {
                this.$emit('update:show', val)
            }
        },
        ruleLoading: {
            get () {
                return this.loading
            },
            set (val) {
                this.$emit('update:loading', val)
            }
        }
    },
    filters: {},
    methods: {
        closeRuleShow() {
            this.ruleShow = false
        },
        opened() {},
    }
}
export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/flight-change-notice',
            name: 'admin-flight-change-notice',
            component: () => import(/* webpackChunkName: "page-admin-order-management-flight-flight-change-detail-notice" */ `@/page/admin/order-management/flight/flight-order-detail-notice/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `打印通知单`,
                },
                entity_list_for_breadcrumb: [
                    {name: `机票订单管理`},
                    {name: `打印通知单`},
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        return `admin-flight-change-list`;
                    },
                },
            },
        },
    ],
};

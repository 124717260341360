import Q from 'q';
// 企业信息组件
import CompanyInfo from '@/component/company-info/import_latest_version_module'
import NewChangeClause from "@/page/admin/replace-orders/flight-search/component/newChangeClause/index.vue";
import PaymentMethodSelector000 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.10/payment-method-selector-000/index.vue';
import PaymentMethodSelector010 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.11/payment-method-selector-010/index.vue';
import consumer_journey_getEvectionDetail from "@/lib/data-service/haolv-default/consumer_admin_journey_getEvectionDetail";
//import consumer_admin_flight_order_details from '@/lib/data-service/haolv-default/consumer_admin_flight_order_details'
import consumer_flight_order_flightsStop from '@/lib/data-service/haolv-default/consumer_flight_order_flightsStop'
import consumer_air_ticket_flightRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules";
import consumer_admin_flight_checkFlightPay from '@/lib/data-service/haolv-default/consumer_admin_flight_checkFlightPay'
import consumer_admin_flight_order_cancel from '@/lib/data-service/haolv-default/consumer_admin_flight_order_cancel'
import consumer_admin_behalfCustomer_certificates from '@/lib/data-service/haolv-default/consumer_admin_behalfCustomer_certificates'
import consumer_trains_common_queryRailwayTimetable from '@/lib/data-service/haolv-default/consumer_trains_common_queryRailwayTimetable';
import consumer_admin_trains_order_applyForCancel from '@/lib/data-service/haolv-default/consumer_admin_trains_order_applyForCancel';
//获取订单id
import consumer_trains_order_behalfTrainOrder from '@/lib/data-service/haolv-default/consumer_trains_order_behalfTrainOrder'
//剩余时间接口
import consumer_admin_flight_milliseconds from '@/lib/data-service/haolv-default/consumer_admin_flight_milliseconds'
import moment from "moment";

import {awaitWrap} from "../../common/unit";
import consumer_admin_user_getCompanyPayType from '@/lib/data-service/haolv-default/consumer_admin_user_getCompanyPayType'

export default {
    data() {
        return {
            intervalFun: null,
            milliseconds: null,
            payType: 1, // 1,企业支付，2个人支付
            evectionType: 2,
            evectionNo: '',
            orderNo: '',
            supplierOrderNo: '',
            //orderType: '', // 为空字符是整个出差单，1是机票订单，2是火车票订单，3是酒店订单
            pageType: '', // 1是创建订单页，2是支付页，3是改签页
            showDetail: true,
            detail: {
                ticketFeeAmount: null,
                serviceFeeAmount: null,
                robTicketFeeAmount: null,
                orderAmount: null,
                waitForPayAmount: null,
                trainFeeDetail:{
                    ticketFeeAmount: null,
                    serviceFeeAmount: null,
                    robTicketFeeAmount: null,
                    orderAmount: null,
                    waitForPayAmount: null,
                },
            },
            tableData: {},
            segmentList: [],
            travellerNameList: [],
            flightOrderList: [],
            travellerList: [],
            list: [],
            trainListAll: [], // 火车票行程时刻表

            errorText: '',
            dialogError2Visible: false,
            successVisible: false,

            papersTypeList: [],
            fullscreenLoading:false,
        }
    },
    components: {
        CompanyInfo,
        NewChangeClause,
        PaymentMethodSelector000,
        PaymentMethodSelector010
    },
    created() {

    },
    beforeDestroy(){
      debugger
    },
    mounted() {
    },
    activated() {
        this.fullscreenLoading = true;
        this.getCertificatesList();
        this.init();
    },
    deactivated() {
        clearInterval(this.intervalFun);
    },
    destroyed() {

    },
    watch: {},
    computed: {},
    filters: {
        filterIntervalTime(val) {
            if (val) {
                let m = moment(val*1000).format('mm分ss秒');
                return m
            } else {
                return '00分00秒'
            }
        },
    },
    methods: {
        showThisStationList(val) {
            if (this.trainListAll.length != 0) {
                return
            }
            let params = {
                fromStationCode: val.fromStationCode,
                toStationCode: val.toStationCode,
                trainCode: val.trainNo,
                trainDate: this.$moment(val.startDate).format('YYYY-MM-DD')
            };
            consumer_trains_common_queryRailwayTimetable(params).then(res => {
                let result = res.results;
                let listType = 0;
                for (let i = 0, l = result.length; i < l; i++) {
                    if (result[i].stationName === val.fromStationName) {
                        listType = 1;
                        result[i].listType = 2;
                        continue
                    }
                    if (result[i].stationName === val.toStationName) {
                        listType = 0;
                        result[i].listType = 2;
                        continue
                    }
                    result[i].listType = listType
                }
                this.trainListAll = result;
            })
        },
        showMore() {
            this.showDetail = !this.showDetail
        },
        async init() {
            //
            window.history.oldName = 'admin-train-payment';
            const query = this.$route.query;
            //出差单号
            this.evectionNo = query.evectionNo;
            //改签单号
            this.changeNo = query.changeNo;
            //订单号
            this.orderNo = query.orderNo;
            this.pageType = Number(query.pageType) || 2;
            await this.getCompanyPayType();

            if (this.evectionNo == undefined) {
                this.reqDetail({
                    orderNo: this.orderNo,
                    pageType: this.pageType,
                    personType: 2,
                });
            } else {
                this.reqDetail({
                    evectionNo: this.evectionNo,
                    orderNo: this.changeNo,
                    pageType: this.pageType,
                    personType: 2,
                });
            }
        },
        async getCompanyPayType() {
            // companyId
            const params = {
                companyId: this.$route.query.behalfCompanyId,
            }
            let [err, res] =  await awaitWrap(consumer_admin_user_getCompanyPayType(params));
            if (err) {
                return;
            }
            this.payType = res.datas.payType;
        },
        reqDetail(params) {
            consumer_journey_getEvectionDetail(params).then((res) => {
                let segmentList = [];
                let travellerNameList = [];
                const datas = res.datas;
                this.evectionType = datas.evectionType;
                if (this.pageType === 3) { // 2是支付页，3是改签页

                } else {

                }
                let singleSettlement = 0; // 单人结算价
                let singleAirportTax = 0; // 单人机建
                let singleFuelTax = 0; // 单人燃油费
                let singleServiceFee = 0; // 单人服务费
                if (datas.flightDetail) {
                    datas.flightDetail.orderList.forEach((value, index) => {

                        datas.orderNo = value.orderNo;
                        datas.orderStatus = value.orderStatus;
                        datas.orderStatusText = value.orderStatusText;
                        this.supplierOrderNo = value.supplierOrderNo;
                        value.segmentList.forEach(value1 => {
                            if (datas.todFlightPolicy) {
                                value1.todFlightPolicy = datas.todFlightPolicy[index];
                            } else {
                                value1.todFlightPolicy = {};
                            }
                            segmentList.push(value1);
                            singleSettlement += parseFloat(value1.settlement);
                            singleAirportTax += parseFloat(value1.airportTax);
                            singleFuelTax += parseFloat(value1.fuelTax);
                        });
                        value.passengerList.forEach((value1, index1) => {
                            singleServiceFee += parseFloat(value1.serviceCharge);
                            datas.travellerList[index1] = Object.assign({}, value1, datas.travellerList[index1]);
                        });
                    });
                    this.flightOrderList = datas.flightDetail.orderList;
                }
                datas.travellerList.forEach(value => {
                    travellerNameList.push(value.staffName);
                    value.singleSettlement = singleSettlement;
                    value.singleAirportTax = singleAirportTax;
                    value.singleFuelTax = singleFuelTax;
                    value.singleServiceFee = singleServiceFee;
                });

                this.segmentList = segmentList;
                this.travellerNameList = travellerNameList;
                this.travellerList = datas.ticketBookingPageDetailsResponseList[0].passengerList;
                this.detail = datas;
                this.tableData = datas.ticketBookingPageDetailsResponseList[0];
                this.initPayment();
                this.fullscreenLoading = false;
            })
        },
        setPayInterval() {
            this.intervalFun = setInterval(() => {
                this.milliseconds--;
                if (this.milliseconds <= 0) {
                    clearInterval(this.intervalFun);
                    this.cancelOrder()
                }
            }, 1000)
        },
        cancelOrder() {
            this.$message({
                message: '超时未支付，订单已自动取消',
                type: 'warning'
            });
            this.$router.push({
                name: 'admin-train-order-list',
            })
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        judgeIdType(num) {
            switch (num) {
                case 1:
                    return "身份证";
                case 2:
                    return "护照";
                case 3:
                    return "港澳通行证";
                case 4:
                    return "回乡证";
                case 5:
                    return "台胞证";
                case 6:
                    return "军官证";
                case 7:
                    return "香港身份证";
                case 8:
                    return "台湾通行证";
                case 9:
                    return "户口本";
                case 10:
                    return "其他";
                case 11:
                    return "澳门身份证";
            }
        },
        initPayment() {
            let _this = this;
            let time = 60;
            let data = {
                orderNo: _this.tableData.orderNo
            };
            consumer_admin_flight_milliseconds(data).then(res => {
                this.milliseconds = res.datas ? res.datas : 0;
                clearInterval(this.intervalFun);
                if (this.milliseconds > 0) {
                    this.setPayInterval()
                } else {
                    this.cancelOrder()
                }
                let aPaymentMethodSelector = null;
                if (this.evectionType === 2 && this.payType == 1) {
                    aPaymentMethodSelector = _this.$refs.aPaymentMethodSelector010
                } else {
                    aPaymentMethodSelector = _this.$refs.aPaymentMethodSelector000
                }
                aPaymentMethodSelector.init({
                    // count_down: {
                    //     pay_remaining_seconds: 30 * 60 * 1 + res.datas,//支付剩余时间，以秒为单位
                    // },
                    //在这里传入订单详情的相关参数
                    get_params() {
                        // const p = {
                        //     cacheKey: _this.detail.cacheKey,
                        //     evenctionNo: _this.evenctionNo,//出差单号
                        //     orderNo: _this.tableData.orderNo,//订单编号
                        // };
                        const p = {
                            "orderForPayVos": [
                                {
                                    "orderNo": _this.tableData.orderNo,
                                    "businessContent": ""
                                },
                            ],
                        };
                        return Q.resolve(p);
                    },
                    //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                    before_pay_event_handler() {
                        // alert(`不能够支付！原因：XXX`);
                        // return Q.reject();
                        // 验价  改签不需要验价
                        console.log(`before_pay_event_handler`);
                        return Q.resolve();
                    },
                    //支付成功事件处理方法
                    pay_successful_event_handler() {
                        console.log(`pay_successful_event_handler`);
                        _this.successVisible = true;
                    },
                    //支付失败事件处理方法
                    pay_failed_event_handler() {
                        console.log(`pay_failed_event_handler`);
                    },
                    //取消订单按钮单击事件处理方法
                    btn_cancel_order_click_event_handler(args) {
                        const show_loading = args.show_loading;
                        const hide_loading = args.hide_loading;
                        const stop_count_down = args.stop_count_down;
                        const enable_btn_pay = args.enable_btn_pay;
                        const enable_btn_cancel_order = args.enable_btn_cancel_order;
                        _this.$confirm('确定取消此订单', '取消提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            Q.when()
                                .then(function () {
                                    show_loading();
                                    return consumer_admin_trains_order_applyForCancel({
                                        orderNo: _this.tableData.orderNo,
                                    });
                                })
                                .then(function () {
                                    console.log(`btn_cancel_order_click_event_handler`);
                                    hide_loading();
                                    stop_count_down();
                                    enable_btn_pay({
                                        enable: false,
                                    });
                                    enable_btn_cancel_order({
                                        enable: false,
                                    });
                                    _this.$message({
                                        type: 'success',
                                        message: '取消成功!'
                                    });
                                    _this.$router.replace({
                                        name: 'admin-travel-book-home',
                                        query: {
                                            activeName: 'third'
                                        }
                                    });
                                })
                                .catch(function (ex) {
                                    if (ex) {
                                        const enable_log_error = !ex.disable_log_error;
                                        if (enable_log_error) {
                                            console.error(ex);
                                            console.trace();
                                            if (ex.stack) {
                                                console.error(ex.stack);
                                            }
                                        }
                                    }
                                    return Q.reject(ex);
                                });
                        }).catch(() => {
                        });
                    },
                });
            });
        },
        getStopMsg(val, index, segmentIndex) {
            let params = {
                "rqData": {
                    "date": val.depDate,
                    "flightNo": val.flightNo
                }
            };
            this.stopLoad = true;
            let flightsList = JSON.parse(JSON.stringify(this.flightList));
            consumer_flight_order_flightsStop(params).then(res => {
                flightsList[index].segmentList[segmentIndex].stopData = res.datas;
                this.stopLoad = false;
                this.flightList = flightsList
            }).catch(() => {
                this.stopLoad = false;
                this.flightList = flightsList
            });
        },
        showThisRuleList(fIndex, index, val) {
            let orangeFlightList = JSON.parse(JSON.stringify(this.flightOrderList));
            orangeFlightList[fIndex].segmentList[index].loading = true;
            this.flightList = orangeFlightList;
            let params = {
                cabinCode: val.cabinCode,
                flightNo: val.flightNo
            };
            consumer_air_ticket_flightRules(params).then(res => {
                let ruleText = [];
                let luggage = '';
                if (res.datas.rsData) {
                    let refRule = res.datas.rsData.refRule;
                    let chaRule = res.datas.rsData.chaRule;
                    let refRuleTitArr = [];
                    let chaRuleTitArr = [];
                    let refRuleArr = [];
                    let chaRuleArr = [];
                    if (refRule && chaRule) {
                        refRule = refRule.rules;
                        chaRule = chaRule.rules;
                        refRule.forEach(value => {
                            value.type = "refRule";
                            if (refRuleTitArr.indexOf(value.ruleTime) === -1) {
                                refRuleTitArr.push(value.ruleTime);
                                refRuleArr.push(value)
                            }
                        });
                        chaRule.forEach(value => {
                            value.type = "chaRule";
                            if (chaRuleTitArr.indexOf(value.ruleTime) === -1) {
                                chaRuleTitArr.push(value.ruleTime);
                                chaRuleArr.push(value)
                            }
                        });
                        refRuleArr.sort((a, b) => {
                            return a.serial - b.serial
                        });
                        chaRuleArr.sort((a, b) => {
                            return a.serial - b.serial
                        });
                        // refRule.map(item => (item.type = "refRule"));
                        // chaRule.map(item => (item.type = "chaRule"));
                        ruleText = [refRuleArr, chaRuleArr];
                    }
                    luggage = res.datas.rsData.luggage
                } else {
                    ruleText = [];
                    luggage = ''
                }
                let flightList = JSON.parse(JSON.stringify(this.flightList));
                flightList[fIndex].segmentList[index].luggage = luggage;
                flightList[fIndex].segmentList[index].ruleText = ruleText;
                flightList[fIndex].segmentList[index].loading = false;
                this.flightOrderList = flightList;
            })
        },
        to_detail() {
            this.successVisible = false;
            const pageType = this.pageType;
            const orderNo = this.tableData.orderNo;

            if (pageType === 2) {
                this.$router.replace({
                    name: "admin-train-order-detail",
                    query: {
                        orderNo,
                    },
                });
            } else if (pageType === 3) {
                this.$router.replace({
                    name: "admin-train-change-detail",
                    query: {
                        changeNo: orderNo,
                        orderNo: this.$route.query.oriOrderNo
                    },
                });
            }
        },
        againInit() {
            this.dialogError2Visible = false;
            this.init()
        },
        setTypeLabelColor(val) {
            //"supplier": "1,2,3,4,12,13,9,OPL9,SGL1,OPL6,OPL11,OPL18,OPL17,OPL20,OPL16,OPL26,OPL23,OPL30,60,FD,NFD,VARI",
            //"supplierText": "其他渠道(供应商)【平台优选】",
            // "bigCustomers": "QCYX",
            //"bigCustomersText": "企业协议(大客户)",
            //"lowPrice": "41,42,43,44,45,26,YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL1,NSTD,62",
            //"lowPriceText": "低价推荐",
            // "website": "21,22,23,24,25,6,CXF1,WS",
            //"websiteText": "航司官网(官网)",
            //"platformProprietary": "61",
            //"platformProprietaryText": "商旅优选(自营)【商旅推荐】"
            const btoList = ['1','2','3','4','12','13', '9', 'OPL9', 'SGL1','OPL6','OPL11','OPL18','OPL17','OPL20','OPL16','OPL26','OPL23','OPL30','60','FD','NFD','VARI']
            const lprList = ['41','42','43','44','45','26','YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL1','NSTD','62']
            const owList = ['21','22','23','24','25','6','CXF1','WS']
            const qcList = ['QCYX']
            const ptList = ['61']
            if (btoList.indexOf(val) > -1) {
                return 'type1'
            } else if (lprList.indexOf(val) > -1) {
                return 'type2'
            } else if (owList.indexOf(val) > -1) {
                return 'type3'
            } else if (qcList.indexOf(val) > -1) {
                return 'type4'
            } else if (ptList.indexOf(val) > -1) {
                return 'type5'
            } else {
                return 'type6'
            }
        },
        cardTypeFormatter(val) {
            let cardTypeText = '';
            if (val.cardType) {
                this.papersTypeList.forEach(value => {
                    if (value.code === val.cardType) {
                        cardTypeText = value.msg
                    }
                })
            }
            return cardTypeText
        },
        getCertificatesList() {
            consumer_admin_behalfCustomer_certificates().then(res => {
                this.papersTypeList = res.datas.items
            })
        },
    }
}

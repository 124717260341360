const __request = require(`./__request/__request_contentType_json`)

// http://192.168.43.143:8765/swagger-ui.html#/t-us-notice-controller/listUsingPOST_3
const consumer_sys_app_publishList = (params) => {
    if (!params) params = {}

    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/sys/app/publishList',
        data: params
    }
    return __request(pParameter).then(data => {
        if (data.code === '000000') {
            return data.pageResult
        }
    }).catch((err) => {
        console.log('接口 consumer_sys_app_publishList 异常')
        throw(err)
    })
}
export default consumer_sys_app_publishList
import consumer_trains_product_getOrderInsuranceInfo from '@/lib/data-service/haolv-default/consumer_trains_product_getOrderInsuranceInfo' // 获取保险列表
import consumer_admin_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_admin_journey_getEvectionDetail' // 获取出差信息
import consumer_trains_product_getAlternativeTrainNumber from '@/lib/data-service/haolv-default/consumer_trains_product_getAlternativeTrainNumber' // 获取备选车次
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg' // 保存信息
import consumer_trains_order_delOrder from '@/lib/data-service/haolv-default/consumer_trains_order_delOrder' // 删除火车订单
import consumer_flight_order_deleteOrder from '@/lib/data-service/haolv-default/consumer_flight_order_deleteOrder' // 删除机票订单
import consumer_tOdHotelOrderInterim_del from '@/lib/data-service/haolv-default/consumer_tOdHotelOrderInterim_del' // 删除酒店订单
import consumer_trains_order_orderScheduledCheck from '@/lib/data-service/haolv-default/consumer_trains_order_orderScheduledCheck' // 校验超标
import consumer_journey_addOrUpdateOverproof from '@/lib/data-service/haolv-default/consumer_journey_addOrUpdateOverproof' // 创建超标订单
import consumer_tOdHotelOrderInterim_updateCheckInPerson from '@/lib/data-service/haolv-default/consumer_tOdHotelOrderInterim_updateCheckInPerson' // 重新安排酒店房间入住
import consumer_air_ticket_flightRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules";
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import consumer_frequent_contacts_getList from '@/lib/data-service/haolv-default/consumer_frequent_contacts_getList' // 获取常用联系人
import Transfer from '@/page/admin/replace-orders/travel-reserve/component/transfer/1.0.0/index.vue'
import CostAttributionList from '@/page/admin/replace-orders/travel-reserve/component/costAttributionList/index.vue'
import CheckTravel from '@/component/checkTravel/index.vue'
import ReservePopupBox from '@/page/admin/replace-orders/travel-reserve/component/reserve-popup-box/index.vue'
import { awaitWrap } from '@/page/admin/replace-orders/travel-reserve/common/unit'
import moment from 'moment'
import consumer_web_city_areaLinkage from '@/lib/data-service/haolv-default/consumer_web_city_areaLinkage' // 获取国家地区
import consumer_air_ticket_fligthLuggage from '@/lib/data-service/haolv-default/consumer_air_ticket_fligthLuggage'
import CompanyInfo from '@/component/company-info/index.vue'
import consumer_admin_behalfCustomer_getServiceAmount from '@/lib/data-service/haolv-default/consumer_admin_behalfCustomer_getServiceAmount'
import consumer_admin_behalfCustomer_getTravelerList from '@/lib/data-service/haolv-default/consumer_admin_behalfCustomer_getTravelerList' // 获取所有出行人
import consumer_admin_behalfCustomer_addTraveler from '@/lib/data-service/haolv-default/consumer_admin_behalfCustomer_addTraveler'
import consumer_behalfOrder from '@/lib/data-service/haolv-default/consumer_behalfOrder'
import consumer_admin_journey_calculatePrice from '@/lib/data-service/haolv-default/consumer_admin_journey_calculatePrice'
import consumer_trains_order_orderBookingPolling from '@/lib/data-service/haolv-default/consumer_trains_order_orderBookingPolling'

import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.5/index.vue';
import consumer_flight_order_flightsStop from '@/lib/data-service/haolv-default/consumer_flight_order_flightsStop'

import Q from 'q';
export default {
    data() {
        const validatePhone = (rule, value, callback) => {
            const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/
            if (regExp.test(value)) {
                callback()
            } else {
                callback(new Error('不符合手机号码格式'))
            }
        };
        const validateTravelUserList = (rule, value, callback) => {
            if (value.length === 0) {
                callback(new Error('请选择出行人'))
            } else {
                callback()
            }
        };
        const validateFeeAffiliationId = (rule, value, callback) => {
            if (value === '' || value === null) {
                callback(new Error('请选择费用归属'))
            } else {
                callback()
            }
        };
        const validateTravelCurrent = (rule, value, callback) => {
            if (value === null) {
                callback(new Error('请选择出差事由'))
            } else {
                callback()
            }
        };
        const validateHotelDateArr = (rule, value, callback) => {
            if (value === null || value === '' || value.length === 0) {
                callback(new Error('请选择入住日期'))
            } else {
                callback()
            }
        };
        return {
            loading: false,
            userInfo: {}, // 个人信息
            evectionNo: '',
            evectionType: 2,
            evectionInfo: {}, // 出差信息合集
            canShowSendMsg: 1, // 是否显示发送短信的勾选 1是显示， 0是不显示

            evectionParams: {
                evectionNo: '',
                evectionType: 2,
                explainDesc: '',
                feeAffiliationId: '',
                feeAffiliationName: '', // 费用归属名称
                feeAffiliationType: 1,
                linkman: '',
                linkmanPhone: '',
                reason: '',
                travelUserList: [],
                smsType: 1, // 是否发送短信， 1为是， 0为否
                linkmanSms: 1, // 是否给联系人发送短信 1为是， 0为否

            },
            rule: {
                travelUserList: [
                    { validator: validateTravelUserList, trigger: 'blur' }
                ],
                linkman: [
                    { required: true, message: '请输入联系人名称', trigger: 'blur' },
                ],
                linkmanPhone: [
                    { required: true, message: '请输入联系手机号码', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' }
                ],
                feeAffiliationId: [
                    { validator: validateFeeAffiliationId, trigger: 'change' }
                ],
                reason: [
                    { required: true, message: '请输入出差事由', trigger: 'blur' },
                ],
            },
            redirectType: '', // 跳转类型
            currentBusinessTravelList: [], // 当前的出差预定信息
            travelUserList: [], // 出行人合集
            canSelectTravelUserList: [], // 有出差单号情况下一共可选出行人列表
            popSelectTravelUserList: [], // 有出差单号情况下弹窗时可选的出行人列表
            cascadeList: [], // 没有出差单号情况下全部员工列表
            currentActiveList: [], // 没有出差单号情况下选中的员工id合集
            dialogVisible: false, // 有出差预定到选择出差人弹窗
            notTravelDialogVisible: false, // 无出差预定的选择出差人弹窗
            travelUserCheckList: [], // 有出差单号时弹窗中勾选中的出差人合集
            newTravelUser: [],
            pendingTravelUserList: [], // 待定的出行人列表

            trainList: [], // 火车票行程列表
            flightList: [], // 飞机票行程列表
            hotelList: [], // 酒店行程列表

            haveTrainEvection: false,
            haveFlightEvection: false,
            haveHotelEvection: false,

            ticketGrabbingNumber: 0, // 抢票类型的数量

            timeOptionRange: null,

            insuranceProId: '', // 选中的保险
            insuranceList: [], // 保险列表
            insuranceDesc: '', // 保险解释

            businessSeatArr: [
                [{ label: 'A', value: '1A', active: false }, { label: 'C', value: '1C', active: false }, { label: 'F', value: '1F', active: false }],
                [{ label: 'A', value: '2A', active: false }, { label: 'C', value: '2C', active: false }, { label: 'F', value: '2F', active: false }]
            ], // 特等座选位
            firstSeatArr: [
                [{ label: 'A', value: '1A', active: false }, { label: 'C', value: '1C', active: false }, { label: 'D', value: '1D', active: false }, { label: 'F', value: '1F', active: false }],
                [{ label: 'A', value: '2A', active: false }, { label: 'C', value: '2C', active: false }, { label: 'D', value: '2D', active: false }, { label: 'F', value: '2F', active: false }]
            ], // 一等座选位
            secondSeatArr: [
                [{ label: 'A', value: '1A', active: false }, { label: 'B', value: '1B', active: false }, { label: 'C', value: '1C', active: false }, { label: 'D', value: '1D', active: false }, { label: 'F', value: '1F', active: false }],
                [{ label: 'A', value: '2A', active: false }, { label: 'B', value: '2B', active: false }, { label: 'C', value: '2C', active: false }, { label: 'D', value: '2D', active: false }, { label: 'F', value: '2F', active: false }]
            ], // 二等座选位

            productListShow: false, // 备选车次弹窗
            alternativeTrainPopupList: [], // 弹窗显示的内旋车次
            currentTrainIndex: 0, // 当前备选火车票弹窗所属的火车票序号

            dialogNewTrainOrderVisible: false,
            // travelCurrent: null,
            onlySearch: 0,
            dialogNewFlightOrderVisible: false,
            dialogNewHotelOrderVisible: false,
            newTravelParams: {
                travelCurrent: null,
                hotelDateArr: [],
                hotelKeyword: '',
                hotelCheckInDate: '',
                hotelCheckOutDate: ''
            },
            newRule: {
                travelCurrent: [
                    { validator: validateTravelCurrent, trigger: 'change' }
                ],
                hotelDateArr: [
                    { validator: validateHotelDateArr, trigger: 'change' }
                ]
            },
            hotelPickerOptions: {
                disabledDate: this.hotelDisabledDate,
                onPick: this.hotelOnPick
            },

            priceResult: {
                flightCalculates: []
            },

            dialogCreateOrderVisible: false,

            dialogKnowVisible: false,

            resultMsg: '',
            isHaveOverproof: false,

            ruleList: [],

            hotelUserVisible: false,
            hotelForm: {
                roomNum: 0,
                checkInPerson: []
            },
            hotelRules: {},
            hotelUserList: [],

            dialogToCreateVisible: false, // 出差单出差，是否确定创建订单弹窗

            frequentPassenger: [], // 常用旅客列表
            activeFrequentPassenger: [], // 选中的常用旅客列表
            storageActiveFrequentPassenger: [], // 暂存-选中的常用旅客列表
            passengerDialogVisible: false, // 因私出差-常用旅客选择弹窗
            papersTypeList: [
                // 乘客证件类型：1.身份证，2.护照，3.港澳通行证，4.回乡证，5.台胞证，6.军官证，7.香港身份证，8.台湾通行证，9.户口本，10.其它,11澳門身份證
                { value: 1, label: '身份证' },
                { value: 2, label: '护照' },
                { value: 3, label: '港澳通行证' },
                { value: 4, label: '回乡证' },
                { value: 5, label: '台胞证' },
                { value: 6, label: '军官证' },
                { value: 7, label: '香港身份证' },
                { value: 8, label: '台湾通行证' },
                { value: 9, label: '户口本' },
                { value: 10, label: '其它' },
                { value: 11, label: '澳門身份證' },
                /*{value: '1', label: '身份证'},
                {value: '2', label: '护照'},
                {value: '3', label: '港澳通行证'},
                {value: '4', label: '回乡证'},
                {value: '5', label: '台胞证'},
                {value: '6', label: '军官证'},
                {value: '7', label: '香港身份证'},
                {value: '8', label: '台湾通行证'},
                {value: '9', label: '户口本'},
                {value: '10', label: '其它'},
                {value: '11', label: '澳門身份證'},*/
            ],
            genderList: [
                { value: 1, label: '男性' },
                { value: 2, label: '女性' },
                /*{value: '0', label: '男性'},
                {value: '1', label: '女性'},*/
            ],
            contactDialogVisible: false, // 联系人弹窗
            contactId: '', // 选中的联系人

            maxUser: 9,

            nationalityList: [],

            copyDialog: false,
            copyTextarea: '',
            copyLoading: false,

            companyId: '',

            dialogError2Visible: false,
            errorText: '',

            dialogNewUserVisible: false,
            newUserParams: {
                staffName: '', // 中文名
                phone: '', // 电话号码
                certificates: [
                    {
                        certificateType: 1,
                        certificateValue: ''
                    }
                ],
                behalfCompanyId: '',
                isOutCustomer: 0,
                staffSex: 1,
            },
            newUserRule: {
                staffName: [
                    {required: true, message: '请输入出行人名称', trigger: 'blur'},
                ],
                certificateType: [
                    {required: true, message: '请选择证件类型', trigger: 'change'},
                ],
                certificateValue: [
                    {required: true, message: '请输入证件号码', trigger: 'blur'},
                    {validator: this.validateNewUerCard, trigger: 'blur'}
                ],
                phone: [
                    {required: true, message: '请输入手机号码', trigger: 'blur'},
                    {validator: this.validatePhone, trigger: 'blur'}
                ],
                staffSex: [
                    {required: true, message: '请选择性别', trigger: 'change'},
                ]
            },
            newUserLoading: false,

            contactIndex: -1,
            travelToContactDialogVisible: false,
            contactList: [],

            keepMinute: '', // 循环计时3分钟方法
            nowMinute: 0, // 累计分钟
            showContinueBtn: false, //
            orderPopShow: false,
            loadingNum: 0,

            orderBookingPolling: '',
            // 最大可订购房间的数量
            maxRoomNum: 9,


            selected_id_list: [],

            stopLoad: false
        }
    },
    components: {
        Transfer,
        CostAttributionList,
        CheckTravel,
        ReservePopupBox,
        CompanyInfo,

        TravelerMultipleSelector
    },
    created() {

    },
    mounted() {

    },
    async activated() {
        // 获取代客的公司id
        this.companyId = this.$route.query.behalfCompanyId

        this.redirectType = this.$route.query.redirectType
        this.evectionNo = this.$route.query.evectionNo
        this.evectionType = parseInt(this.$route.query.evectionType)

        if (this.$route.query.canShowSendMsg) {
            this.canShowSendMsg = parseInt(this.$route.query.canShowSendMsg)
        } else {
            this.canShowSendMsg = 1
        }
        this.$refs.aTravelerMultipleSelector.init_parameter.get_data_source_parameter = () => {
            const d = {
                companyId: this.companyId,
                isSelectCustomer: true,
            };
            return Q.resolve(d);
        };
        this.$refs.aTravelerMultipleSelector.init()

        // await this.getUserInfo() // 获取个人资料
        await this.getInsuranceInfo() // 获取保险信息列表
        await this.getEvectionInfo()

        this.getTravelInfoAndUserList() // 获取当前出差单信息和可选择的出行人列表
        // this.initCheckTravelBox() // 初始化校验弹框
        this.getAllPrice() // 获取价格信息


        // this.getUsuallyUser() // 获取常用旅客

        this.getAreaLine() // 获取国家
    },
    deactivated() { },
    destroyed () {
        this.orderBookingPolling = ''
        clearInterval(this.keepMinute)
    },
    watch: {
        currentActiveList(newVal) {
            this.$nextTick(() => {
                const selected_entity_list = this.$refs.aTravelerMultipleSelector.get_selected_entity_list()
                selected_entity_list.forEach(value => {
                    value.certificateList = value.certificates
                    //value.certificateType = value.certificates[0].certificateType
                    //value.certificateTypeText =  value.certificates[0].certificateTypeText
                    //value.certificateValue = value.certificates[0].certificateValue
                    value.userId = value.id
                    value.username = value.name
                    value.staffId = value.id
                    value.staffName = value.name
                })
                this.evectionParams.travelUserList = JSON.parse(JSON.stringify(selected_entity_list))

                // 计算价格

                if (selected_entity_list.length > 0) {
                    this.$refs['orderForm'].clearValidate('travelUserList')
                }
                this.saveAndReactPrice()
                if (this.evectionParams.travelUserList.length > 0) {
                    this.reactHotelUser()
                }
            })
        }
    },
    computed: {},
    filters: {
        filterDate(val) {
            return moment(val).format('YYYY年MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
    },
    methods: {
        validateCard(rule, value, callback, index) {
            if (this.evectionParams.travelUser[index].certificateType === 1) {
                const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (!regIdCard.test(value)) {
                    callback(new Error('身份证号填写有误'))
                } else {
                    let birthday = '';
                    if (value.length === 15) {
                        birthday = "19" + value.substr(6, 6);
                    } else if (value.length === 18) {
                        birthday = value.substr(6, 8);
                    }
                    birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
                    let birthdayArr = birthday.split('-');
                    let yearSubmit = parseInt(birthdayArr[0]) !== NaN && parseInt(birthdayArr[0]) >= 1900;
                    let monthSubmit = parseInt(birthdayArr[1]) !== NaN && parseInt(birthdayArr[1]) >= 1 && parseInt(birthdayArr[1]) <= 12;
                    let daySubmit = parseInt(birthdayArr[2]) !== NaN && parseInt(birthdayArr[2]) >= 1 && parseInt(birthdayArr[2]) <= 31;
                    if (!yearSubmit || !monthSubmit || !daySubmit) {
                        callback(new Error("身份证号填写有误"));
                    } else {
                        let isHaveRepeat = false;
                        this.evectionParams.travelUser.forEach((value1, index1) => {
                            if (value1.certificateType === 1 && index !== index1 && value1.certificateValue === value) {
                                isHaveRepeat = true
                            }
                        });
                        if (isHaveRepeat) {
                            callback(new Error('有重复身份证号'))
                        } else {
                            callback()
                        }
                    }
                }
            } else {
                callback()
            }
        },
        validateNewUerCard(rule, value, callback) {
            if (this.newUserParams.certificates[0].certificateType === 1) {
                const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (!regIdCard.test(value)) {
                    callback(new Error('身份证号填写有误'))
                } else {
                    let birthday = '';
                    if (value.length === 15) {
                        birthday = "19" + value.substr(6, 6);
                    } else if (value.length === 18) {
                        birthday = value.substr(6, 8);
                    }
                    birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
                    let birthdayArr = birthday.split('-');
                    let yearSubmit = parseInt(birthdayArr[0]) !== NaN && parseInt(birthdayArr[0]) >= 1900;
                    let monthSubmit = parseInt(birthdayArr[1]) !== NaN && parseInt(birthdayArr[1]) >= 1 && parseInt(birthdayArr[1]) <= 12;
                    let daySubmit = parseInt(birthdayArr[2]) !== NaN && parseInt(birthdayArr[2]) >= 1 && parseInt(birthdayArr[2]) <= 31;
                    if (!yearSubmit || !monthSubmit || !daySubmit) {
                        callback(new Error("身份证号填写有误"));
                    } else {
                        callback()
                    }
                }
            } else {
                callback()
            }
        },
        validatePhone(rule, value, callback) {
            const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/;
            if (regExp.test(value) && value.toString().length === 11) {
                callback()
            } else {
                callback(new Error('不符合手机号码格式'))
            }
        },
        validteCheckIn(rule, value, callback) {
            if (
                value === "" ||
                value === null ||
                value === undefined
            ) {
                callback(new Error("请选择入住人"));
            } else {
                callback();
            }
        },
        getBirthday(index, val) {
            if (this.evectionParams.travelUser[index].certificateType === 1) {
                const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (!regIdCard.test(val)) {
                    return
                }

                let birthday = '';
                if (val.length === 15) {
                    birthday = "19" + val.substr(6, 6);
                } else if (val.length === 18) {
                    birthday = val.substr(6, 8);
                }
                birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
                let birthdayArr = birthday.split('-');
                let yearSubmit = parseInt(birthdayArr[0]) !== NaN && parseInt(birthdayArr[0]) >= 1900;
                let monthSubmit = parseInt(birthdayArr[1]) !== NaN && parseInt(birthdayArr[1]) >= 1 && parseInt(birthdayArr[1]) <= 12;
                let daySubmit = parseInt(birthdayArr[2]) !== NaN && parseInt(birthdayArr[2]) >= 1 && parseInt(birthdayArr[2]) <= 31;
                if (!yearSubmit || !monthSubmit || !daySubmit) {
                    return;
                }
                this.evectionParams.travelUser[index].birthday = birthday
            }
        },
        hotelDisabledDate(time) {
            // return (time.getTime() < Date.now() - 8.64e7)
            // 获取选中时间
            let timeOptionRange = this.timeOptionRange;
            // 获取时间范围(30天的毫秒数)
            let secondNum = 30 * 24 * 60 * 60 * 1000;
            if (timeOptionRange) {
                //如果有选中时间 设置超过选中时间后的30天||超过选中前的30天||大于当前时间 不可选
                return time.getTime() > timeOptionRange.getTime() + secondNum || time.getTime() <= timeOptionRange.getTime();
            } else {
                //如果没有选中时间（初始化状态） 设置当前时间后的时间不可选
                return time.getTime() < (Date.now() - 8.64e7);
            }
        },
        hotelOnPick({ maxDate, minDate }) {
            // 当选中了第一个日期还没选第二个
            // 只选中一个的时候自动赋值给minDate，当选中第二个后组件自动匹配，将大小日期分别赋值给maxDate、minDate
            if (minDate && !maxDate) {
                this.timeOptionRange = minDate;
            }
            // 如果有maxDate属性，表示2个日期都选择了，则重置timeOptionRange
            if (maxDate) {
                this.timeOptionRange = null;
            }
        },
        async getUserInfo() {
            this.loading = true;
            let [err, res] = await awaitWrap(get_user_info());
            this.loading = false;
            this.userInfo = res ? res.datas : {};
        },
        // 获取出差信息
        async getEvectionInfo() {
            this.loading = true;
            let [err, res] = await awaitWrap(consumer_admin_journey_getEvectionDetail({ evectionNo: this.evectionNo, pageType: 1, personType: 2 }));
            this.loading = false;
            if (err) {
                return
            }
            this.evectionInfo = res.datas;
            let travellerList = res.datas.travellerList ? res.datas.travellerList : []
            travellerList.forEach(value => {
                if (!value) {
                    return
                }
                value.certificateType = value.certificateList.length > 0 ? value.certificateList[0].certificateType : ''
                value.certificateTypeText = value.certificateList.length > 0 ? this.filterCardType(value.certificateList[0].certificateType) : ''
                value.certificateValue = value.certificateList.length > 0 ? value.certificateList[0].certificateValue : ''
                value.id = value.userId
            })
            this.evectionParams = {
                evectionNo: res.datas.evectionNo,
                evectionType: res.datas.evectionType,
                explainDesc: res.datas.explainDesc,
                feeAffiliationId: res.datas.feeAffiliationId,
                feeAffiliationName: res.datas.feeAffiliationName, // 费用归属名称
                feeAffiliationType: res.datas.feeAffiliationType ? res.datas.feeAffiliationType : 1,
                linkman: res.datas.linkman ? res.datas.linkman : this.userInfo.realName,
                linkmanPhone: res.datas.linkmanPhone ? res.datas.linkmanPhone : this.userInfo.phone,
                reason: res.datas.reason,
                travelUserList: travellerList,
                smsType: 1, // 是否发送短信， 1为是， 0为否
                linkmanSms: (res.datas.linkmanSms !== undefined && res.datas.linkmanSms !== null && res.datas.linkmanSms !== '') ? res.datas.linkmanSms : 1

            };
            this.travelUserList = res.datas.travellerList ? res.datas.travellerList : [];
            // 获取火车票行程
            let trainList = res.datas.ticketBookingPageDetailsResponseList;
            let getAlternativeTrainList = [];// 需要获取
            trainList.forEach(value => {
                let seatArr = [];
                if (value.canChooseSeat === 1 && value.numberOfSeats === 5) {
                    [...seatArr] = this.secondSeatArr
                } else if (value.canChooseSeat === 1 && value.numberOfSeats === 4) {
                    [...seatArr] = this.firstSeatArr
                } else if (value.canChooseSeat === 1 && value.numberOfSeats === 3) {
                    [...seatArr] = this.businessSeatArr
                }
                value.seatArr = seatArr;
                value.chooseSeatArr = [];
                if (value.canChooseSeat === 1 && value.chooseSeat !== '' && value.chooseSeat !== null) {
                    let chooseSeatArr = [];
                    if (value.chooseSeat) {
                        for (let i = 0, l = value.chooseSeat.length; i < l; i += 2) {
                            let arr = value.chooseSeat.slice(i, i + 2);
                            chooseSeatArr.push(arr)
                        }
                    }
                    value.chooseSeatArr = chooseSeatArr;
                    value.seatArr.forEach(val => {
                        val.forEach(val1 => {
                            if (value.chooseSeatArr.indexOf(val1.value) > -1) {
                                val1.active = true
                            }
                        })
                    })
                }
                // 保险信息
                value.insuranceProId = value.insuranceProId ? parseInt(value.insuranceProId) : value.insuranceProId
                value.insuranceDesc = '';
                if (value.insuranceProId !== null && value.insuranceProId !== '') {
                    for (let i = 0, l = this.insuranceList.length; i < l; i++) {
                        if (value.insuranceProId === this.insuranceList[i].id) {
                            value.insuranceDesc = this.insuranceList[i].insuranceDesc
                            value.insurancePrice = this.insuranceList[i].insurancePrice // 单价
                            value.insuranceName = this.insuranceList[i].insuranceClass.name // 名字
                        }
                    }
                }

                value.finalPrice = value.seatPrice // 次行程的最终单价，因为有抢票的情况
                // 获取当前车次的坐席列表
                value.trainSeatList = JSON.parse(value.robTicketNote);
                value.seatList = [];


                // 抢票的情况需要获取备选车次列表
                if (value.orderType === 2) {
                    this.ticketGrabbingNumber++

                    value.acceptSeatCodesArr = value.acceptSeatCodes ? value.acceptSeatCodes.split(',') : []

                    let trainParams = {
                        fromStationCode: value.fromStationCode,
                        toStationCode: value.toStationCode,
                        trainCode: value.trainNo,
                        trainDate: value.startDate
                    }
                    let trainItem = consumer_trains_product_getAlternativeTrainNumber(trainParams)
                    getAlternativeTrainList.push(trainItem)
                }
                // consumer_trains_product_getAlternativeTrainNumber
            })
            this.trainList = trainList

            Promise.all(getAlternativeTrainList).then(data => {
                let index = 0
                let [...finalTrainList] = this.trainList
                finalTrainList.forEach(value => {
                    if (value.orderType === 2) {
                        let acceptTrainCodesArr = value.acceptTrainCodes ? value.acceptTrainCodes.split(',') : []
                        let alternativeTrainNumberList = data[index].resultList
                        alternativeTrainNumberList.forEach(value1 => {
                            if (acceptTrainCodesArr.indexOf(value1.trainCode) > -1) {
                                value1.active = true
                            } else {
                                value1.active = false
                            }
                        })
                        value.alternativeTrainNumberList = alternativeTrainNumberList
                        index++
                    }
                })
                this.trainList = finalTrainList
                this.trainList.forEach((value, index) => {
                    if (value.orderType === 2) {
                        this.filterSeatList(index)
                    }
                })
            })


            // 获取机票行程
            let flightList = res.datas.flightDetail ? res.datas.flightDetail.orderList : []
            flightList.forEach(value => {
                value.segmentList.forEach(value1 => {
                    value1.loading = false
                })
            })
            this.flightList = flightList

            let hotelList = res.datas.todHotelOrderInterimList ? res.datas.todHotelOrderInterimList : []
            this.hotelList = hotelList
        },
        // 更改抢票价格后执行的
        async reactEvectionInfo() {
            let [err, res] = await awaitWrap(consumer_admin_journey_getEvectionDetail({ evectionNo: this.evectionNo, pageType: 1, personType: 2 }));
            if (err) {
                return
            }
            this.evectionInfo = res.datas;
            let travellerList = res.datas.travellerList ? res.datas.travellerList : []
            travellerList.forEach(value => {
                if (!value) {
                    return
                }
                value.certificateType = value.certificateList[0].certificateType
                value.certificateTypeText = this.filterCardType(value.certificateList[0].certificateType)
                value.certificateValue = value.certificateList[0].certificateValue
                value.id = value.userId
            })
            this.evectionParams = {
                evectionNo: res.datas.evectionNo,
                evectionType: res.datas.evectionType,
                explainDesc: res.datas.explainDesc,
                feeAffiliationId: res.datas.feeAffiliationId,
                feeAffiliationName: res.datas.feeAffiliationName, // 费用归属名称
                feeAffiliationType: res.datas.feeAffiliationType ? res.datas.feeAffiliationType : 1,
                linkman: res.datas.linkman ? res.datas.linkman : this.userInfo.realName,
                linkmanPhone: res.datas.linkmanPhone ? res.datas.linkmanPhone : this.userInfo.phone,
                reason: res.datas.reason,
                travelUserList: travellerList,
                smsType: 1, // 是否发送短信， 1为是， 0为否
                linkmanSms: (res.datas.linkmanSms !== undefined && res.datas.linkmanSms !== null && res.datas.linkmanSms !== '') ? res.datas.linkmanSms : 1,

            };
            // 获取火车票行程
            let trainList = res.datas.ticketBookingPageDetailsResponseList;
            let oriTrainList = JSON.parse(JSON.stringify(this.trainList))
            trainList.forEach(value => {
                oriTrainList.forEach(value2 => {
                    if (value.trainNo === value2.trainNo && value.startDate === value2.startDate && value.seatName === value2.seatName) {
                        value2.seatPrice = value.seatPrice
                    }
                })

            })
            this.trainList = oriTrainList
        },
        filterCardType(val) {
            let certificateTypeText = ''
            if (val) {
                this.papersTypeList.forEach(value => {
                    if (value.value === val) {
                        certificateTypeText = value.label
                    }
                })
            }
            return certificateTypeText
        },
        initCheckTravelBox() {
            this.$refs.checkTravelBox.init({
                checkFun: () => {
                    let flightDate = []
                    if (this.flightList.length > 0) {
                        this.flightList.forEach(value => {
                            let flightItem = {
                                bottomPrice: '',
                                cabinRank: value.cabinRank,
                                depDate: value.segmentList[0].depDate,
                                discount: value.segmentList[0].discount,
                                price: value.segmentList[0].price,
                                evectionNo: this.evectionNo,
                                depTime: value.segmentList[0].depTime,
                                rqData: value.segmentList[0]
                                /*rqData: {
                                    depCityPortCode: '', // 出发三字码
                                    arrCityPortCode: '', // 到达三字码
                                    depDate: '', // 出发日期
                                }*/
                            }
                            flightDate.push(flightItem)
                        })
                    }
                    let params = {
                        evectionNo: this.evectionNo,
                        userIds: this.newTravelUser,
                        excessRequest: flightDate
                    }
                    this.loading = true
                    return consumer_trains_order_orderScheduledCheck(params).then(res => {
                        this.loading = false
                        return res.datas.violationResults || [] // 因无超标人时返回的空数组现在变成null，所以需自传空数组
                    }).catch(() => {
                        this.loading = false
                        return []
                    })
                },
                reserveSubmitFun: (warningList) => {
                    let overproofApplyList = []
                    if (warningList.length === 0) {
                        return new Promise(function (resolve) {
                            resolve([])
                        })
                    }
                    warningList.forEach(value => {
                        let item = {
                            businessType: value.businessType,
                            controlRule: value.controlRules,
                            orderNo: value.orderNo,
                            reason: value.reason,
                            reserveContent: value.violationOfReason,
                            standard: value.standard,
                            standardContent: value.seatName,
                            userId: value.userId,
                            userName: value.userRealName
                        }
                        overproofApplyList.push(item)
                    })

                    let params = {
                        evectionNo: this.evectionNo,
                        overproofApplyList
                    }
                    this.loading = true
                    return consumer_journey_addOrUpdateOverproof(params).then(res => {
                        this.loading = false
                        return {}
                    }).catch(() => {
                        this.loading = false
                    })
                },
                reserveResultFun: (result) => {
                    if (!result) {
                        return
                    }
                    this.evectionParams.travelUserList = this.pendingTravelUserList
                    // 清除报错提示
                    this.$refs['orderForm'].clearValidate('travelUserList')
                    this.saveAndReactPrice()

                    // 如果有酒店订单
                    if (this.evectionParams.travelUserList.length > 0) {
                        this.reactHotelUser()
                    }
                }
            })
        },
        // 获取订单所有价格
        async getAllPrice(seatPrice, orderNo) {
            let params = {
                companyId: this.$route.query.behalfCompanyId,
                evectionNo: this.evectionNo,
                headCounts: this.evectionParams.travelUserList.length,
                behalfCompanyId: this.companyId,
                seatPrice,
                orderNo
            }
            let res = await consumer_admin_journey_calculatePrice(params)
            this.priceResult = res.datas
        },
        // 获取当前出差单信息和可选择的出行人列表
        getTravelInfoAndUserList() {
            this.getDepartmentUser()

        },
        // 获取公司所有员工
        async getDepartmentUser() {
            let result = await consumer_admin_behalfCustomer_getTravelerList({ companyId: this.companyId })
            let cascadeList = result.datas.travelers
            let deptIdArr = []
            let deptList = []
            cascadeList.forEach(value => {
                value.id = value.uid
                value.userId = value.uid
                value.name = value.username
                let currentIndex = deptIdArr.indexOf(value.deptId)
                if (currentIndex === -1) {
                    deptIdArr.push(value.deptId)
                    let params = {
                        deptId: value.deptId,
                        deptName: value.deptName,
                        userList: [],
                        children: [],
                    }
                    params.userList.push(value)
                    deptList.push(params)
                } else {
                    deptList[currentIndex].userList.push(value)
                }
            })
            this.cascadeList = deptList
            console.log(this.cascadeList)
        },
        // 显示选择出行人弹窗
        showSelectUserPopup() {
            let currentActiveList = []
            this.evectionParams.travelUserList.forEach(value => {
                if (value && value.id) {
                    currentActiveList.push(value.id)
                }
            })
            this.currentActiveList = currentActiveList
            // this.notTravelDialogVisible = true
            this.$refs.aTravelerMultipleSelector.show()
        },
        // 有出差预定到选择出差人弹窗确认
        submitSelectTravelUser() {
            this.dialogVisible = false
            if (this.popSelectTravelUserList.length === 0) {
                return
            }
            let travelUserList = []
            for (let i = 0, l = this.popSelectTravelUserList.length; i < l; i++) {
                if (this.travelUserCheckList.indexOf(this.popSelectTravelUserList[i].staffId) > -1) {
                    travelUserList.push(this.popSelectTravelUserList[i])
                }
            }
            // 获取此次勾选的新出行人
            let newTravelUserList = []
            travelUserList.forEach(value => {
                newTravelUserList.push(value.userId)
            })
            /*travelUserList.forEach(value => {
                let isHave = false
                this.evectionParams.travelUserList.forEach(value1 => {
                    if (value.staffId === value1.staffId) {
                        isHave = true
                    }
                })
                if (!isHave) {
                    newTravelUserList.push(value.userId)
                }
            })*/
            this.newTravelUser = newTravelUserList
            this.pendingTravelUserList = [...this.evectionParams.travelUserList, ...travelUserList]
            // 提交校验
            this.$refs.checkTravelBox.run()
        },
        // 无出差预定的选择出差人弹窗确认
        submitTransferTravelUser() {
            let travelUserList = []
            let newArr = JSON.parse(JSON.stringify(this.cascadeList))
            // 将多维数组转成一维数组
            function jsonToArray(nodes) {
                let r = [];
                if (Array.isArray(nodes)) {
                    for (let i = 0, l = nodes.length; i < l; i++) {
                        r.push(nodes[i]); // 取每项数据放入一个新数组
                        if (Array.isArray(nodes[i]["children"]) && nodes[i]["children"].length > 0)
                            // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
                            r = r.concat(jsonToArray(nodes[i]["children"]));
                        delete nodes[i]["children"]
                    }
                }
                return r;
            }
            let afterArr = jsonToArray(newArr)
            afterArr.forEach(value => {
                value.userList.forEach(value1 => {
                    console.log(value.userList, this.currentActiveList)
                    if (this.currentActiveList.indexOf(value1.id) > -1) {
                        value1.staffId = value1.id
                        value1.staffName = value1.username
                        value1.userId = value1.id
                        travelUserList.push(value1)
                    }
                })
            })
            // 剔除本来存在的用户
            /*let newTravelUser = []
            travelUserList.forEach(value => {
                let isHave = false
                this.evectionParams.travelUserList.forEach(value1 => {
                    if (value1.staffId === value.staffId) {
                        isHave = true
                    }
                })
                if (!isHave) {
                    newTravelUser.push(value.userId)
                }
            })
            this.newTravelUser = newTravelUser*/
            // this.pendingTravelUserList = travelUserList
            // this.notTravelDialogVisible = false
            // 提交校验
            // this.$refs.checkTravelBox.run()
            this.evectionParams.travelUserList = travelUserList
            this.notTravelDialogVisible = false
            // 清除报错提示
            if (travelUserList.length > 0) {
                this.$refs['orderForm'].clearValidate('travelUserList')
            }
            this.saveAndReactPrice()

            // 如果有酒店订单
            if (travelUserList.length > 0) {
                this.reactHotelUser()
            }
        },
        reactHotelUser() {
            if (this.hotelList.length > 0) {
                this.hotelForm.roomNum = this.hotelList[0].roomNum
                // this.hotelForm.roomNum = 2
                let checkInPerson = []
                for (let i = 0, l = this.hotelForm.roomNum; i < l; i++) {
                    checkInPerson.push('')
                }
                this.hotelForm.checkInPerson = checkInPerson

                // this.hotelUserList = [...this.evectionParams.travelUserList]
                this.hotelUserList = this.evectionParams.travelUserList.slice()
                this.hotelUserList.forEach(value => {
                    value.disabled = false
                })

                this.hotelUserVisible = true
            }
        },
        changeHotelUser(index, val) {
            if (val !== '') {
                this.hotelUserList.forEach(value => {
                    if (value.userId === val || this.hotelForm.checkInPerson.indexOf(value.userId) > -1) {
                        value.disabled = true
                    } else {
                        value.disabled = false
                    }
                })
                this.$refs.reserveForm.clearValidate(`checkInPerson.${index}`)
            } else {
                this.hotelUserList.forEach(value => {
                    if (value.disabled && this.hotelForm.checkInPerson.indexOf(value.userId) === -1) {
                        value.disabled = false
                    }
                })
            }
        },

        change_roomNum(newLength) {
            const form = this.hotelForm;
            const oldLength = form.checkInPerson.length;

            if (newLength) {
                if (oldLength) {
                    if (newLength > oldLength) {
                        for (let i = 0; i < newLength - oldLength; i++) {
                            form.checkInPerson.push([]);
                            //this.checkInPersonList.push({ visible: false });
                        }
                    } else {
                        form.checkInPerson.splice(newLength, oldLength - newLength);
                        //this.checkInPersonList.splice(newLength, oldLength - newLength);
                    }
                } else {
                    for (let i = 0; i < newLength; i++) {
                        form.checkInPerson.push([]);
                        //this.checkInPersonList.push({ visible: false });
                    }
                }
            } else {
                form.checkInPerson = [];
                //this.checkInPersonList = [];
            }
        },

        async commitHotelReserve() {
            let valid = await this.$refs.reserveForm.validate()
            if (!valid) {
                return
            }
            let params = {
                checkInPerson: this.hotelForm.checkInPerson,
                orderNo: this.hotelList[0].orderNo,
                roomNum: this.hotelForm.roomNum
            }
            let [err, res] = await awaitWrap(consumer_tOdHotelOrderInterim_updateCheckInPerson(params))
            if (err) {
                return
            }
            this.$message({
                type: 'success',
                message: '入住安排保存成功'
            })
            this.hotelUserVisible = false
            // 获取最新的订单详情
            await this.getEvectionInfo()
            this.getAllPrice() // 获取价格信息
            /*this.$refs.reserveForm.validate((valid) => {
                if (valid) {
                    let params = {
                        checkInPerson: this.hotelForm.checkInPerson,
                        orderNo: this.hotelList[0].orderNo,
                        roomNum: this.hotelForm.roomNum
                    }
                    // params.orderNo = this.hotelList[0].orderNo
                    consumer_tOdHotelOrderInterim_updateCheckInPerson(params).then(res => {
                        this.$message({
                            type: 'success',
                            message: '入住安排保存成功'
                        })
                        this.hotelUserVisible = false
                    }).catch(() => {

                    })
                }
            })*/
        },
        async chooseSeat(trainIndex, val) {
            // val值不实时
            let acceptSeatCodesArr = this.trainList[trainIndex].acceptSeatCodesArr
            let [...trainList] = this.trainList
            this.trainList = trainList
            // 比较最大价格
            let price = 0
            let [...seatList] = this.trainList[trainIndex].seatList
            if (acceptSeatCodesArr !== undefined && val.length > 0) {
                // 有选中情况
                for (let i = 0, l = acceptSeatCodesArr.length; i < l; i++) {
                    for (let m = 0, n = seatList.length; m < n; m++) {
                        if (acceptSeatCodesArr[i] === seatList[m].seatCode && seatList[m].seatPrice > price) {
                            price = parseFloat(seatList[m].seatPrice)
                            break
                        }
                    }
                }
            }
            let seatPrice = price
            this.trainList[trainIndex].finalPrice = seatPrice > this.trainList[trainIndex].seatPrice ? seatPrice : this.trainList[trainIndex].seatPrice // 最终价格
            this.trainList[trainIndex].acceptSeatCodes = this.trainList[trainIndex].acceptSeatCodesArr.join(',')
            console.log('ff', this.trainList[trainIndex].finalPrice)

            // 保存
            await this.saveAndReactPrice()

        },
        async chooseCheckSeat(trainIndex, val) {
            // val值不实时
            let acceptSeatCodesArr = this.trainList[trainIndex].acceptSeatCodesArr
            console.log(acceptSeatCodesArr, this.trainList[trainIndex].seatList)
            let [...trainList] = this.trainList
            this.trainList = trainList
            // 比较最大价格
            let price = 0
            let [...seatList] = this.trainList[trainIndex].seatList
            console.log(seatList)
            if (acceptSeatCodesArr !== undefined && acceptSeatCodesArr.length > 0) {
                // 有选中情况
                for (let i = 0, l = acceptSeatCodesArr.length; i < l; i++) {
                    for (let m = 0, n = seatList.length; m < n; m++) {
                        console.log(acceptSeatCodesArr[i], seatList[m].seatCode, seatList[m].seatPrice, price)
                        if ((acceptSeatCodesArr[i] === seatList[m].seatCode) && (seatList[m].seatPrice > price)) {
                            price = parseFloat(seatList[m].seatPrice)
                            break
                        }
                    }
                }
            }
            let seatPrice = price
            // 获取原始价格
            let trainSeatArr = JSON.parse(this.trainList[trainIndex].robTicketNote)
            let baseTrainSeatPrice = 0
            trainSeatArr.forEach(value => {
                if (value.seatName === this.trainList[trainIndex].seatName) {
                    baseTrainSeatPrice = parseInt(value.seatPrice)
                }
            })

            this.trainList[trainIndex].finalPrice = seatPrice > baseTrainSeatPrice ? seatPrice : baseTrainSeatPrice // 最终价格
            this.trainList[trainIndex].acceptSeatCodes = this.trainList[trainIndex].acceptSeatCodesArr.join(',')
            console.log('ff', this.trainList[trainIndex].finalPrice)

            // 保存
            await this.saveAndReactPrice(this.trainList[trainIndex].finalPrice, this.trainList[trainIndex].orderNo)

            await this.reactEvectionInfo()

        },
        // 获取保险信息
        async getInsuranceInfo() {
            this.loading = true
            let [err, res] = await awaitWrap(consumer_trains_product_getOrderInsuranceInfo())
            this.loading = false
            if (err) {
                return
            }
            let insuranceList = res.data
            insuranceList.forEach((value, index) => {
                value.label = `${value.insurancePrice}元/份 ${value.insuranceRemark}`
                /*if (index === 0) {
                    this.insuranceProId = value.id
                    this.insuranceDesc = value.insuranceDesc
                }*/
            })
            this.insuranceList = insuranceList
        },
        changeInsuranceDesc(val, index) {
            this.trainList[index].insuracneFlag = val === '' ? 0 : 1
            if (val === '') {
                this.trainList[index].insuranceDesc = null
                this.trainList[index].insurancePrice = null // 单价
                this.trainList[index].insuranceName = null // 名字
            } else {
                for (let i = 0, l = this.insuranceList.length; i < l; i++) {
                    if (val === this.insuranceList[i].id) {
                        this.trainList[index].insuranceDesc = this.insuranceList[i].insuranceDesc
                        this.trainList[index].insurancePrice = this.insuranceList[i].insurancePrice // 单价
                        this.trainList[index].insuranceName = this.insuranceList[i].insuranceClass.name // 名字
                        break
                    }
                }
            }
            this.saveAndReactPrice()
        },
        delUser(item, index) {
            this.evectionParams.travelUserList.splice(index, 1)
            console.log(this.evectionParams.travelUserList)
            this.saveAndReactPrice()

            this.trainList.forEach(value => {
                if (value.orderType === 1 && value.canChooseSeat === 1 && value.chooseSeatArr.length > 0) {
                    let item = value.chooseSeatArr[value.chooseSeatArr.length - 1]
                    value.chooseSeatArr.splice(value.chooseSeatArr.length - 1, 1)
                    value.chooseSeat = value.chooseSeatArr.join('')

                    value.seatArr.forEach(val => {
                        val.forEach(val1 => {
                            if (val1.value === item) {
                                val1.active = false
                            }
                        })
                    })
                }
            })

            // 如果有酒店订单
            if (this.evectionParams.travelUserList.length > 0) {
                this.reactHotelUser()
            }
        },

        chooseTrain(val, index, trainIndex) {
            let [...trainList] = this.trainList
            let [...alternativeTrainNumberList] = this.trainList[trainIndex].alternativeTrainNumberList
            let active = alternativeTrainNumberList[index].active
            let activeNum = 0
            for (let i = 0, l = alternativeTrainNumberList.length; i < l; i++) {
                if (alternativeTrainNumberList[i].active) {
                    activeNum++
                }
            }
            if (activeNum >= 4 && !active) {
                this.$message('最多可以选择4个备选车次');
                return
            }
            alternativeTrainNumberList[index].active = !active
            trainList[trainIndex].alternativeTrainNumberList = alternativeTrainNumberList
            this.trainList = trainList
            this.setAcceptTrainCodes(trainIndex)

            this.filterSeatList(trainIndex)

        },
        //
        setAcceptTrainCodes(trainIndex) {
            let [...trainList] = this.trainList
            let acceptTrainCodes = []
            let alternativeTrainNumberList = trainList[trainIndex].alternativeTrainNumberList
            alternativeTrainNumberList.forEach(value => {
                if (value.active) {
                    acceptTrainCodes.push(value.trainCode)
                }
            })
            trainList[trainIndex].acceptTrainCodes = acceptTrainCodes.join(',')
            this.trainList = trainList
        },
        // 选中座位
        filterSeatList(trainIndex) {
            let [...trainList] = this.trainList
            let [...seatList] = trainList[trainIndex].trainSeatList
            let seatResult = []

            trainList[trainIndex].alternativeTrainNumberList.forEach(value => {
                if (value.active) {
                    value.trainSeatVoList.forEach(val => {
                        seatList.push(val)
                    })
                }
            })
            // 获取备选车座列表
            for (let i = 0, l = seatList.length; i < l; i++) {
                let hasItem = false
                for (let m = 0, n = seatResult.length; m < n; m++) {
                    if (seatResult[m].seatCode === seatList[i].seatCode) {
                        hasItem = true
                        // 比较价格
                        if (parseFloat(seatResult[m].seatPrice) < parseFloat(seatList[i].seatPrice)) {
                            seatResult[m].seatPrice = seatList[i].seatPrice
                        }
                        break
                    }
                }
                if (!hasItem) {
                    seatResult.push(seatList[i])
                }
            }
            trainList[trainIndex].seatList = seatResult
            this.trainList = trainList

            // 再对比选中的坐席的价格
            this.chooseSeat(trainIndex, trainList[trainIndex].acceptSeatCodesArr)
        },
        // 打开备选车次弹窗
        handleShowMore(trainItem, trainIndex) {
            this.currentTrainIndex = trainIndex
            let [...alternativeTrainNumberList] = trainItem.alternativeTrainNumberList
            this.alternativeTrainPopupList = alternativeTrainNumberList
            this.productListShow = true
            // 遍历一次勾选状态
            this.$nextTick(() => {
                this.alternativeTrainPopupList.forEach((value, index) => {
                    if (index > 4) {
                        this.$refs.multipleTable.toggleRowSelection(value, value.active)
                    }
                })
            })
        },
        delRow({ row, rowIndex }) {
            if (rowIndex < 5) {
                return 'display-none'
            } else {
                return ''
            }
        },
        selectTr(selection, row) {
            // 获取当前已选择数
            let alternativeTrainPopupList = this.alternativeTrainPopupList
            let activeNum = 0
            let index = 0
            for (let i = 0, l = alternativeTrainPopupList.length; i < l; i++) {
                if (alternativeTrainPopupList[i].active) {
                    activeNum++
                }
                if (alternativeTrainPopupList[i].trainCode === row.trainCode) {
                    index = i
                }
            }
            if (activeNum >= 4 && !row.active) {
                this.$refs.multipleTable.toggleRowSelection(row)
                this.$message('最多可以选择4个备选车次');
                return
            }
            alternativeTrainPopupList[index].active = !row.active
            this.alternativeTrainPopupList = alternativeTrainPopupList
        },
        cancelChoose() {
            this.productListShow = false
        },
        submitChoose() {
            this.trainList[this.currentTrainIndex].alternativeTrainNumberList = this.alternativeTrainPopupList
            this.productListShow = false
            this.setAcceptTrainCodes(this.currentTrainIndex)
        },

        handleChooseSeat(index, sIndex, tIndex, val) {
            if (val.active) { // 取消
                this.trainList[tIndex].seatArr[index][sIndex].active = false
                let seatIndex = this.trainList[tIndex].chooseSeatArr.indexOf(val.value)
                this.trainList[tIndex].chooseSeatArr.splice(seatIndex, 1)
                this.trainList[tIndex].chooseSeat = this.trainList[tIndex].chooseSeatArr.join(',')
            } else { // 选中
                if (this.evectionParams.travelUserList.length === 0) {
                    this.$message({
                        type: 'warning',
                        message: '请先添加出行人'
                    })
                }
                if (this.trainList[tIndex].chooseSeatArr.length >= this.evectionParams.travelUserList.length) {
                    return
                }
                this.trainList[tIndex].seatArr[index][sIndex].active = true
                this.trainList[tIndex].chooseSeatArr.push(val.value)
                this.trainList[tIndex].chooseSeat = this.trainList[tIndex].chooseSeatArr.join('')
            }

        },
        // 改车次
        async changeTrain(val, index) {
            await this.saveInfo()
            this.$router.push({
                name: 'admin-train-search',
                query: {
                    fromStationCode: val.fromStationCode,
                    toStationCode: val.toStationCode,
                    time: val.startDate,
                    evectionNo: this.evectionNo,
                    orderNo: val.orderNo,
                    redirectType: '002',
                    evectionType: this.evectionParams.evectionType
                }
            })
        },
        // 改航班
        async changeFlight(segment, segmentIndex, flight, fIndex) {
            await this.saveInfo()
            this.$router.push({
                name: 'admin-flight-search',
                query: {
                    fromStationCode: segment.depCityCode,
                    fromStationName: segment.depCityName,
                    toStationCode: segment.arrCityCode,
                    toStationName: segment.arrCityName,
                    startDate: segment.depDate,
                    flightType: 1,
                    evectionNo: this.evectionNo,
                    redirectType: '002',
                    orderNo: flight.journeyNo,
                    evectionType: this.evectionParams.evectionType
                }
            })
        },
        // 该酒店
        async changeHotel(val, index) {
            await this.saveInfo()
            this.$router.push({
                name: 'admin-hotel-search',
                query: {
                    cityId: val.cityId,
                    cityName: val.cityName,
                    checkInDate: val.checkInDate,
                    checkOutDate: val.checkOutDate,
                    // keyword: '',
                    // hotelStar: '',
                    evectionNo: this.evectionNo,
                    redirectType: '002',
                    orderNo: val.orderNo,
                    evectionType: this.evectionParams.evectionType
                }
            })
        },
        // 复制火车信息
        copyTrain(row, seatIndex) {
            const __this = this;
            const trainStartDate = row.startDate ? this.$moment(row.startDate).format('YYYY-MM-DD') : row.startDate;
            const week = __this.filterWeek(row.startDate);
            const totalPrice = parseInt(row.seatPrice)  + parseInt(row.serviceChargeAmount);
            const quoted_price = '报价：\n' +
                '请确认列车信息：\n' +
                trainStartDate + '（' + week + '）' + row.trainNo + '次' + row.fromStationName + '（' + row.startTime + '开）— ' + row.toStationName + '（' + row.arriveTime + '到）\n' +
                '成人票' + row.seatName + '\n' +
                '结算价：'+ row.seatPrice +'元+' + row.serviceChargeAmount + '元服务费共' + totalPrice + '元\n' + '是否符合差标请自行确认';
            const _textarea = document.createElement("textarea");   // 直接构建文本框
            _textarea.value = quoted_price;  // 设置内容
            document.body.appendChild(_textarea);    // 添加临时实例
            _textarea.select();   // 选择实例内容
            document.execCommand("Copy");   // 执行复制
            __this.$message.success('复制成功！');
            document.body.removeChild(_textarea); // 删除临时实例
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            const day = this.$moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        // 复制机票信息
        copyFlight(segment, segmentIndex, flight, fIndex) {
            this.copyDialog = true
            this.copyLoading = true
            this.copyTextarea = ''
            let data = {
                rqData: {
                    "arrCityPortCode": segment.arrCityCode,
                    "cabinCode": segment.cabinCode,
                    "depCityPortCode": segment.depCityCode,
                    "depDate": segment.depDate,
                    "flightNo": segment.flightNo,
                    "flightProductType": segment.flightProductType,
                    "flightType": flight.flightType,
                    "psgType": flight.psgType
                }
            };
            let priceParams = {
                businessType: 3,
                companyId: this.companyId,
                sum: segment.settlement
            }

            Promise.all([consumer_air_ticket_fligthLuggage(data), consumer_admin_behalfCustomer_getServiceAmount(priceParams)]).then(res => {
                let sumServicePrice = parseInt(res[1].datas.sumServicePrice)
                let luggageInfo = ''
                if (res[1].datas && res[0].datas.rsData) {
                    luggageInfo = res[0].datas.rsData
                }
                this.setCopyText(segment, sumServicePrice, luggageInfo)
            }).catch(() => {
                this.setCopyText(segment, '', '')
            }).finally(() => {
                this.copyLoading = false
            })
        },
        setCopyText(segment, servicePrice, luggageInfo) {
            let message = `报价：
目前帮您查询到这趟航班最新的价格，价格随时浮动，请以实时查询为准
${segment.depDate} ${segment.flightNo} ${segment.depCityName}-${segment.arrCityName} ${segment.depTime}-${segment.arrTime}
舱位：${segment.cabinName}
结算价：${segment.settlement}/人 （${segment.airportTax > 0 ? '' : '不'}含税）
服务费：${servicePrice || '--'}/人 （${segment.airportTax > 0 ? '' : '不'}含税）
携程价：--/人 （${segment.airportTax > 0 ? '' : '不'}含税）
行李规定：${luggageInfo.luggage || '--'}
是否符合差标请自行确认`
            this.copyTextarea = message
        },
        handleCopyDialogClose() {
            this.copyDialog = false
        },
        submitCopy() {
            this.$copyText(this.copyTextarea).then((e) => {
                this.$message({
                    message: '提示：已复制',
                    type: 'success'
                });
            }, (e) => {
                this.$message.error('提示：复制失败');
            })
            this.copyDialog = false
        },
        // 复制酒店信息
        copyHotel(val, index) {
            let message = `报价：
                            请确认预定信息：
                            酒店：${val.hotelName}
                            地址：${val.hotelAddr}
                            入住：${val.checkInDate}
                            离店：${val.checkOutDate}
                            房型：${val.hotelRoomTypeName}
                            结算价：${val.price}元/间/晚
                            携程价：--元/间/晚
                            价格随时变动,以实时下单为准
                            预付酒店预订成功不退不改
                            是否符合差标请自行确认`;
            this.$copyText(message).then((e) => {
                this.$message({
                    message: '提示：已复制',
                    type: 'success'
                });
            }, (e) => {
                this.$message.error('提示：复制失败');
            })
        },
        // 删除火车票订单
        delTrainOrder(val, index) {
            this.$confirm('此操作将删除该行程, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                consumer_trains_order_delOrder({ orderNo: val.orderNo }).then(res => {
                    this.trainList.splice(index, 1)
                    this.saveAndReactPrice()
                })
            }).catch(() => {

            })
        },
        // 删除机票订单
        delFlightOrder(segment, segmentIndex, flight, fIndex) {
            this.$confirm('此操作将删除该行程, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                consumer_flight_order_deleteOrder({ journeyNo: flight.journeyNo }).then(res => {
                    this.flightList.splice(fIndex, 1)
                    this.saveAndReactPrice()
                })
            }).catch(() => {

            })
        },
        // 删除酒店订单
        delHotelOrder(val, index) {
            this.$confirm('此操作将删除该行程, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                consumer_tOdHotelOrderInterim_del({ id: val.id }).then(res => {
                    if (res.datas) {
                        this.hotelList.splice(index, 1)
                        this.saveAndReactPrice()
                    } else {
                        this.$message({
                            type: 'warning',
                            message: res.msg
                        })
                    }
                })
            }).catch(() => {

            })
        },
        // 保存信息
        async saveInfo() {
            let params = Object.assign({}, this.evectionParams)
            let userIdList = []
            console.log(this.evectionParams.travelUserList)
            this.evectionParams.travelUserList.forEach(value => {
                userIdList.push(value.id || value.userId)
            })
            params.userIdList = userIdList
            params.ticketRequests = this.trainList
            // 拼装orderNo合集
            /*let order = []
            this.flightList.forEach(value => {
                value.segmentList.forEach(value2 => {
                    const paramsText = `1,${value2.orderNo}`
                    order.push(paramsText)
                })
            })
            this.trainList.forEach(value => {
                const paramsText = `2,${value.orderNo}`
                order.push(paramsText)
            })
            this.hotelList.forEach(value => {
                const paramsText = `3,${value.orderNo}`
                order.push(paramsText)
            })
            params.order = order*/
            /*params.travelUser.forEach(value => {
                value.evectionNo = params.evectionNo
                value.smsType = this.evectionParams.smsType
                if (value.certificateType === '1' && value.nationality === '') { // 如果是身份证且没有选国籍，自动补上70007
                    value.nationality = 70007
                }
            })*/
            //params.contactsVO = params.travelUser
            let [err, res] = await awaitWrap(consumer_journey_saveOrderMsg(params))
            if (err) {
                return false
            }
            return res
        },
        // 新增火车票
        async toSearchTrain() {
            let continueToTrain = await this.saveInfo()
            if (!continueToTrain) {
                return
            }
            let fromStationCode = ''
            let fromStationName = ''
            let toStationCode = ''
            let toStationName = ''
            let startDate = ''
            if (this.trainList.length > 0) {
                fromStationCode = this.trainList[0].fromStationCode
                toStationCode = this.trainList[0].toStationCode
                startDate: this.trainList[0].startDate
            } else if (this.flightList.length > 0) {
                let flightList = this.flightList[0].segmentList
                fromStationName = flightList[0].depCityName
                toStationName = flightList[0].arrCityName
                startDate: flightList[0].depDate
            } else if (this.hotelList.length > 0) {
                toStationName = this.hotelList[0].cityName
                startDate: this.hotelList[0].checkInDate
            }
            this.$router.push({
                name: 'admin-train-search',
                query: {
                    fromStationCode: fromStationCode,
                    fromStationName: fromStationName,
                    toStationCode: toStationCode,
                    toStationName: toStationName,
                    time: startDate,
                    redirectType: '001',
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionParams.evectionType,
                }
            })
        },
        // 新增机票
        async toSearchFlight() {
            let continueToFlight = await this.saveInfo()
            if (!continueToFlight) {
                return
            }

            let fromStationCode = ''
            let fromStationName = ''
            let toStationCode = ''
            let toStationName = ''
            let startDate = ''
            let endDate = ''
            // 如果存在航班行程
            if (this.flightList.length > 0) {
                let flightList = this.flightList[0].segmentList
                fromStationCode = flightList[0].depCityCode
                fromStationName = flightList[0].depCityName
                toStationCode = flightList[0].arrCityCode
                toStationName = flightList[0].arrCityName
                startDate = flightList[0].depDate
            } else if (this.trainList.length > 0) {
                // 如果存在火车票 因为火车票的出发城市到达城市中文显示的是站名，既可能是广州东，所以不传入
                // fromStationCode = ''
                // fromStationName = this.trainList[0].depCityName
                // toStationCode = ''
                // toStationName = this.trainList[0].arrCityName
                startDate = this.trainList[0].depDate
            } else if (this.hotelList.length > 0) {
                toStationName = this.hotelList[0].cityName
                startDate = this.hotelList[0].checkInDate
            }
            this.$router.push({
                name: 'admin-flight-search',
                query: {
                    fromStationCode: fromStationCode,
                    fromStationName: fromStationName,
                    toStationCode: toStationCode,
                    toStationName: toStationName,
                    startDate: startDate,
                    endDate: endDate,
                    flightType: 1,
                    redirectType: '001',
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionParams.evectionType,
                }
            })
        },
        // 新增酒店
        async toSearchHotel() {
            // 只能预订一间酒店
            if (this.hotelList.length > 0) {
                return
            }
            let continueToHotel = await this.saveInfo()
            if (!continueToHotel) {
                return
            }
            // 个人预定
            let cityId = ''
            let cityName = ''
            let checkInDate = ''
            let checkOutDate = ''
            let hotelName = ''
            if (this.hotelList.length > 0) {
                cityId = this.hotelList[0].cityId
                cityName = this.hotelList[0].cityName
                checkInDate = this.hotelList[0].checkInDate
                checkOutDate = this.hotelList[0].checkOutDate
                hotelName = this.hotelList[0].hotelName
            } else if (this.flightList.length > 0) {
                let flightList = this.flightList[0].segmentList
                cityName = flightList[0].arrCityName
                checkInDate = flightList[0].depDate
            } else if (this.trainList.length > 0) {
                checkInDate = this.trainList[0].depDate
            }


            this.$router.push({
                name: 'admin-hotel-search',
                query: {
                    cityId: cityId,
                    cityName: cityName,
                    checkInDate: checkInDate,
                    checkOutDate: checkOutDate,
                    keyword: hotelName,
                    hotelStar: '',
                    redirectType: '001',
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionParams.evectionType,
                }
            })
        },
        submitSearchNewTravelToTrain() {
            /*if (this.newTravelParams.travelCurrent === null) {
                this.$message({
                    type: 'warning',
                    message: '请先选择出差事由'
                })
                return
            }*/
            this.$refs['trainForm'].validate((valid) => {
                if (valid) {
                    this.dialogNewTrainOrderVisible = false;
                    this.$router.push({
                        name: 'admin-train-search',
                        query: {
                            redirectType: '001',
                            time: this.newTravelParams.travelCurrent.active.departTime,
                            fromStationCode: this.newTravelParams.travelCurrent.active.departCityId,
                            toStationCode: this.newTravelParams.travelCurrent.active.toCityId,
                            onlySearch: this.onlySearch,
                            evectionNo: this.evectionNo,
                            evectionType: this.evectionParams.evectionType,
                        }
                    })
                }
            })

        },
        submitSearchNewTravelToFlight() {
            /*if (this.newTravelParams.travelCurrent === null) {
                this.$message({
                    type: 'warning',
                    message: '请先选择出差事由'
                })
                return
            }*/
            this.$refs['flightForm'].validate((valid) => {
                if (valid) {
                    this.dialogNewFlightOrderVisible = false
                    this.$router.push({
                        name: 'admin-flight-search',
                        query: {
                            redirectType: '001',
                            startDate: this.newTravelParams.travelCurrent.active.departTime,
                            endDate: this.newTravelParams.travelCurrent.active.returnTime,
                            fromStationCode: this.newTravelParams.travelCurrent.active.departCityId,
                            fromStationName: this.newTravelParams.travelCurrent.active.departCityName,
                            toStationCode: this.newTravelParams.travelCurrent.active.toCityId,
                            toStationName: this.newTravelParams.travelCurrent.active.toCityName,
                            flightType: 1,
                            evectionNo: this.evectionNo,
                            evectionType: this.evectionParams.evectionType,
                        }
                    })
                }
            })
        },
        submitSearchNewTravelToHotel() {
            this.$refs['hotelForm'].validate((valid) => {
                if (valid) {
                    this.dialogNewHotelOrderVisible = false;
                    this.$router.push({
                        name: 'admin-hotel-search',
                        query: {
                            redirectType: '001',
                            cityId: this.newTravelParams.travelCurrent.active.departCityId,
                            cityName: this.newTravelParams.travelCurrent.active.departCityName,
                            checkInDate: this.newTravelParams.hotelCheckInDate,
                            checkOutDate: this.newTravelParams.hotelCheckOutDate,
                            keyword: this.newTravelParams.hotelKeyword,
                            hotelStar: '',
                            evectionNo: this.evectionNo,
                            evectionType: this.evectionParams.evectionType,
                        }
                    })
                }
            })
        },
        changeHotelDate(val) {
            if (val === '' || val === null || val.length === 0) {
                this.newTravelParams.hotelCheckInDate = ''
                this.newTravelParams.hotelCheckOutDate = ''
            } else {
                this.newTravelParams.hotelCheckInDate = val[0]
                this.newTravelParams.hotelCheckOutDate = val[1]
            }
        },
        blurHotelDate() {
            this.timeOptionRange = null
        },
        // 最终创建订单
        async createOrder() {
            if (this.flightList.length === 0 && this.trainList.length === 0 && this.hotelList.length === 0) {
                this.$message({
                    type: 'warning',
                    message: '请先添加行程'
                })
                return
            }
            let loading
            loading = this.$loading({
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            let valid = await this.checkForm();
            if (!valid) {
                loading.close();
                return
            }
            let saveResult = await this.saveInfo();
            if (!saveResult) {
                loading.close();
                return
            }

            let [err, result] = await awaitWrap(consumer_behalfOrder({ evectionNo: this.evectionNo, pageType: 1 }))
            if (err) {
                loading.close();
                //let canSubmit = true
                // 不予许再点
                let errorCode = err.data.code;
                let orderResultList = err.data.datas.orderResultList
                this.errorText = err.data.msg;
                this.dialogError2Visible = true
                if (!orderResultList) {
                    return
                }
                orderResultList.forEach(value => {
                    if (!value.isSuccess) {
                        let [...trainList] = this.trainList
                        let [...flightList] = this.flightList
                        let [...hotelList] = this.hotelList
                        trainList.forEach(value1 => {
                            if (value1.orderNo === value.orderNo) {
                                value1.showError = true
                                value1.errorReason = value.desc
                            }
                        })
                        flightList.forEach(value1 => {
                            if (value1.journeyNo === value.orderNo) {
                                value1.showError = true
                                value1.errorReason = value.desc
                            }
                        })
                        hotelList.forEach(value1 => {
                            if (value1.orderNo === value.orderNo) {
                                value1.showError = true
                                value1.errorReason = value.desc
                            }
                        })
                        this.trainList = trainList
                        this.flightList = flightList
                        this.hotelList = hotelList
                    }
                })
                return
            }
            let orderResult = result.datas
            let canSubmit = true
            orderResult.orderResultList.forEach(value => {
                if (!value.isSuccess) {
                    canSubmit = false
                    let [...trainList] = this.trainList
                    let [...flightList] = this.flightList
                    let [...hotelList] = this.hotelList
                    trainList.forEach(value1 => {
                        if (value1.orderNo === value.orderNo) {
                            value1.showError = true
                            value1.errorReason = value.desc
                        }
                    })
                    flightList.forEach(value1 => {
                        if (value1.journeyNo === value.orderNo) {
                            value1.showError = true
                            value1.errorReason = value.desc
                        }
                    })
                    hotelList.forEach(value1 => {
                        if (value1.orderNo === value.orderNo) {
                            value1.showError = true
                            value1.errorReason = value.desc
                        }
                    })
                    this.trainList = trainList
                    this.flightList = flightList
                    this.hotelList = hotelList
                }
            })
            // loading.close()
            if (canSubmit) {
                // 火车票轮询
                let _this = this
                this.orderBookingPolling = function (trainOrderList) {
                    consumer_trains_order_orderBookingPolling({orderNos: trainOrderList}).then(result => {
                        if (this.loadingNum < 96) {
                            this.loadingNum++
                        }
                        if (this.nowMinute >= 3 * 60) {
                            this.showContinueBtn = true
                            clearInterval(this.keepMinute)
                            return
                        }
                        let resultSuccessfulList = []
                        result.orderBookingPollingResults.forEach(value => {
                            resultSuccessfulList.push(value.successful)
                        })
                        if (resultSuccessfulList.indexOf(0) >= 0) {
                            // 继续轮询
                            setTimeout(() => {
                                if (this.orderBookingPolling === '') {
                                    return
                                }
                                this.orderBookingPolling(trainOrderList)
                            }, 500)
                        } else {
                            // 成功
                            loading.close()
                            this.loadingNum = 100
                            setTimeout(() => {
                                this.orderPopShow = false
                                this.$router.push({
                                    name: 'admin-order-payment',
                                    query: {
                                        evectionNo: this.evectionNo,
                                    }
                                })
                            }, 500)
                        }
                    }, error => {
                        // 失败
                        loading.close()
                        this.orderPopShow = false
                        clearInterval(this.keepMinute)
                        let errorCode = error.data.code;
                        let orderResultList = error.data.datas.orderBookingPollingResults
                        let [...trainList] = this.trainList
                        orderResultList.forEach(value => {
                            trainList.forEach(value1 => {
                                if (value1.orderNo === value.orderNo && value.successful === 2) {
                                    value1.showError = true
                                    value1.errorReason = value.errorMessage
                                }
                            })
                        })
                        this.trainList = trainList
                        this.errorText = error.data.msg;
                        this.dialogError2Visible = true
                    }).catch(() => {

                    })
                }
                // 如果有火车票单
                if (this.trainList.length > 0) {
                    loading.close()
                    loading = this.$loading({
                        lock: true,
                        spinner: 'el-icon-loading',
                        text: '火车票正在占座中，请耐心等待几分钟...',
                        background: 'rgba(255, 255, 255, 0.7)'
                    });
                    this.loadingNum = 0
                    this.orderPopShow = true
                    let trainOrderList = []
                    this.trainList.forEach(value => {
                        trainOrderList.push(value.orderNo)
                    })
                    this.keepMinute = setInterval(() => {
                        this.nowMinute++
                    }, 1000)
                    this.orderBookingPolling(trainOrderList)
                } else {
                    loading.close()
                    this.$router.push({
                        name: 'admin-order-payment',
                        query: {
                            evectionNo: this.evectionNo,
                        }
                    })
                }
            } else {
                loading.close();
                this.$message({
                    type: 'warning',
                    message: '订票失败，请重新选择行程'
                })
            }
        },
        // 检查表单
        checkForm() {
            return new Promise(resolve => {
                this.$refs['orderForm'].validate((valid) => {
                    resolve(valid)
                })
            })
        },
        // 保存并重新获取价格
        async saveAndReactPrice(seatPrice, orderNo) {
            await this.saveInfo();
            await this.getAllPrice(seatPrice, orderNo)
        },
        submitEnd() {
            this.dialogCreateOrderVisible = false;
            this.$router.push({
                name: 'admin-flight-order-list'
            })
        },
        async submitCreateOrder() {
            let [err, result] = await awaitWrap(consumer_behalfOrder({ evectionNo: this.evectionNo }));
            if (err) {
                return
            }
            let orderResult = result.datas;
            let canSubmit = true;
            orderResult.forEach(value => {
                if (!value.isSuccess) {
                    canSubmit = false;
                    let [...trainList] = this.trainList;
                    let [...flightList] = this.flightList;
                    let [...hotelList] = this.hotelList;
                    trainList.forEach(value1 => {
                        if (value1.orderNo === value.orderNo) {
                            value1.showError = true;
                            value1.errorReason = value.desc
                        }
                    });
                    flightList.forEach(value1 => {
                        if (value1.journeyNo === value.orderNo) {
                            value1.showError = true;
                            value1.errorReason = value.desc
                        }
                    });
                    hotelList.forEach(value1 => {
                        if (value1.orderNo === value.orderNo) {
                            value1.showError = true;
                            value1.errorReason = value.desc
                        }
                    });
                    this.trainList = trainList;
                    this.flightList = flightList;
                    this.hotelList = hotelList
                }
            });
            if (canSubmit) {
                this.$router.push({
                    name: 'admin-my-order-flight-orderList'
                })
            } else {
                this.$message({
                    type: 'warning',
                    message: '订票失败，请重新选择行程'
                })
            }
        },

        showThisRuleList(fIndex, index, val) {
            let orangeFlightList = JSON.parse(JSON.stringify(this.flightList))
            orangeFlightList[fIndex].segmentList[index].loading = true
            this.flightList = orangeFlightList
            let params = {
                cabinCode: val.cabinCode,
                flightNo: val.flightNo
            };
            consumer_air_ticket_flightRules(params).then(res => {
                let result = res.datas.rsData;
                // 退票规则
                let ruleList = [];
                let ruleItem = {};
                let ruleTitList = []; // 表格title
                if (result.refRule === null) {
                    ruleTitList = [
                        ' ',
                        '统一起飞前/后'
                    ];
                    ruleList = [
                        {
                            tips0: '退',
                            tips1: '依据产品规则执行'
                        },
                        {
                            tips0: '改',
                            tips1: '依据产品规则执行'
                        }
                    ]
                } else {
                    let refRule = result.refRule.rules
                    let chaRule = result.chaRule.rules
                    let refRuleTitArr = []
                    let chaRuleTitArr = []
                    let refRuleArr = []
                    let chaRuleArr = []
                    refRule.forEach(value => {
                        value.type = "refRule"
                        if (refRuleTitArr.indexOf(value.ruleTime) === -1) {
                            refRuleTitArr.push(value.ruleTime)
                            refRuleArr.push(value)
                        }
                    })
                    chaRule.forEach(value => {
                        value.type = "chaRule"
                        if (chaRuleTitArr.indexOf(value.ruleTime) === -1) {
                            chaRuleTitArr.push(value.ruleTime)
                            chaRuleArr.push(value)
                        }
                    })
                    refRuleArr.sort((a, b) => {
                        return a.serial - b.serial
                    })
                    chaRuleArr.sort((a, b) => {
                        return a.serial - b.serial
                    })
                    refRuleArr.forEach((val, index) => {
                        if (index === 0) {
                            ruleTitList.push(' ');
                            ruleItem = {
                                tips0: '退票'
                            }
                        }
                        ruleItem[`tips${index + 1}`] = val.rateText;
                        if (val.timeUnit === 1 && val.ruleTime.indexOf('>') >= 0) {
                            let result = val.ruleTime.substr(1);
                            let tips = `起飞前${parseInt(result) / 24}天（含）外`;
                            ruleTitList.push(tips)
                        }
                        if (val.timeUnit === 1 && val.ruleTime.indexOf('-') >= 0) {
                            let resultArr = val.ruleTime.split('-');
                            let tips = `起飞前${parseInt(resultArr[0]) > 48 ? (parseInt(resultArr[0]) / 24) : parseInt(resultArr[0])}${parseInt(resultArr[0]) > 48 ? '天' : '小时'}（含）外至${parseInt(resultArr[1]) > 48 ? (parseInt(resultArr[1]) / 24) : parseInt(resultArr[1])}${parseInt(resultArr[1]) > 48 ? '天' : '小时'}（不含）外`
                            ruleTitList.push(tips)
                        }
                        if (val.ruleTime.indexOf('-') < 0 && val.ruleTime.indexOf('>') < 0) {
                            ruleTitList.push(val.ruleTime)
                        }
                    });
                    ruleList.push(ruleItem);
                    chaRuleArr.forEach((val, index) => {
                        if (index === 0) {
                            ruleItem = {
                                tips0: '改签'
                            }
                        }
                        ruleItem[`tips${index + 1}`] = val.rateText
                    });
                    ruleList.push(ruleItem)
                }
                let flightList = JSON.parse(JSON.stringify(this.flightList))
                flightList[fIndex].segmentList[index].ruleList = ruleList;
                flightList[fIndex].segmentList[index].ruleTitList = ruleTitList;
                // this.flightList[fIndex].segmentList[index].luggage = result.luggage
                flightList[fIndex].segmentList[index].loading = false
                this.flightList = flightList
            })
        },

        showKnowPopup() {
            this.dialogKnowVisible = true
        },
        // 获取常用旅客
        getUsuallyUser() {
            consumer_frequent_contacts_getList({ currentPage: 1, pageSize: 100 }).then(res => {
                let frequentPassenger = res.pageResult.pageData;
                this.frequentPassenger = frequentPassenger
            })
        },
        // 确定勾选中的常用旅客
        submitPassenger() {
            let activeUser = [];
            this.evectionParams.travelUser.forEach((value, index) => {
                if (value.id && value.id !== '') {
                    if (this.storageActiveFrequentPassenger.indexOf(value.id) === -1) { // 勾选中去掉了
                        // 删除该项
                        this.evectionParams.travelUser.splice(index, 1)
                    } else {
                        activeUser.push(value.id)
                    }
                }
            });
            let travelUser = [];
            this.storageActiveFrequentPassenger.forEach(value => {
                this.frequentPassenger.forEach(value1 => {
                    if (value === value1.id && activeUser.indexOf(value) === -1) {
                        let isPushed = false;
                        for (let i = 0, l = this.evectionParams.travelUser.length; i < l; i++) {
                            let isHaveStation = this.isBlankStation(this.evectionParams.travelUser[i]);
                            if (isHaveStation) {
                                isPushed = true;
                                this.evectionParams.travelUser.splice(i, 1, {
                                    id: value1.id,
                                    username: value1.username,
                                    phone: value1.phone,
                                    certificateType: value1.certificateType,
                                    certificateValue: value1.certificateValue,

                                    birthday: value1.birthday, // 出生日期
                                    passengerExpireDate: value1.passengerExpireDate, // 证件有效期
                                    englishSurnames: value1.englishSurnames, // 姓氏
                                    englishName: value1.englishName, // 名字（和姓氏配合）
                                    staffSex: value1.staffSex, // 性别

                                    passengerType: value1.passengerType,
                                    nationality: value1.nationality, // 国籍

                                    choice: 0, //是否保存
                                    smsType: value1.smsType,

                                    uid: value1.uid,
                                    cid: value1.cid,
                                    ofcId: value1.ofcId,
                                });
                                break
                            }
                        }
                        if (!isPushed) {
                            this.evectionParams.travelUser.push({
                                id: value1.id,
                                username: value1.username,
                                phone: value1.phone,
                                certificateType: value1.certificateType,
                                certificateValue: value1.certificateValue,

                                birthday: value1.birthday, // 出生日期
                                passengerExpireDate: value1.passengerExpireDate, // 证件有效期
                                englishSurnames: value1.englishSurnames, // 姓氏
                                englishName: value1.englishName, // 名字（和姓氏配合）
                                staffSex: value1.staffSex, // 性别

                                passengerType: value1.passengerType,
                                nationality: value1.nationality, // 国籍

                                choice: 0, //是否保存
                                smsType: 1,

                                uid: value1.uid,
                                cid: value1.cid,
                                ofcId: value1.ofcId,
                            })
                        }
                    }
                })
            });
            // this.evectionParams.travelUser = travelUser.concat(this.evectionParams.travelUser)
            this.passengerDialogVisible = false;
            this.$refs['orderForm'].clearValidate();
            this.getAllPrice()
        },
        // 是否是一个空白的占位
        isBlankStation(val) {
            if (val.username === '') {
                return true
            }
            if (val.phone === '') {
                return true
            }
            if (val.certificateValue === '') {
                return true
            }
            if (val.certificateType !== 1 && val.passengerExpireDate === '') {
                return true
            }
            if ([1, 2].indexOf(val.certificateType) === -1 && val.birthday === '') {
                return true
            }
            if ([1, 2].indexOf(val.certificateType) === -1 && val.englishSurnames === '') {
                return true
            }
            if ([1, 2].indexOf(val.certificateType) === -1 && val.englishName === '') {
                return true
            }
            if ([1, 2].indexOf(val.certificateType) === -1 && val.staffSex === '') {
                return true
            }
            return false
        },
        cancelPassenger() {
            this.passengerDialogVisible = false
        },

        // 新增用户
        addNewTravelUser() {
            if (this.evectionParams.travelUser.length >= 9) {
                return
            }
            this.evectionParams.travelUser.push({
                id: '',
                username: '',
                phone: '',
                certificateType: 1,
                certificateValue: '',

                birthday: '', // 出生日期
                passengerExpireDate: '', // 证件有效期
                englishSurnames: '', // 姓氏
                englishName: '', // 名字（和姓氏配合）
                staffSex: '', // 性别

                passengerType: '1',
                nationality: '', // 国籍

                choice: 0, //是否保存
                smsType: 1,

                uid: '',
                cid: '',
                ofcId: '',
            });
            this.getAllPrice()
        },
        // 展示常用旅客弹窗
        showChooseUsuallyUser() {

            let activeUser = [];
            this.evectionParams.travelUser.forEach(value => {
                if (value.id && value.id !== '') {
                    activeUser.push(value.id)
                }
            });
            let travelUserNum = 0;
            this.evectionParams.travelUser.forEach(value => {
                let isBlankStation = this.isBlankStation(value);
                if (!isBlankStation) {
                    travelUserNum++
                }
            });
            this.maxUser = 9 - travelUserNum + activeUser.length;
            this.storageActiveFrequentPassenger = activeUser;
            this.passengerDialogVisible = true
        },
        //
        delTravelUser(index) {
            this.evectionParams.travelUser.splice(index, 1);
            this.getAllPrice()
        },
        // 确认联系人
        submitContact() {
            if (this.contactId !== '') {
                this.frequentPassenger.forEach(value => {
                    if (value.id === this.contactId) {
                        this.evectionParams.linkman = value.username;
                        this.evectionParams.linkmanPhone = value.phone
                    }
                })
            }
            this.contactDialogVisible = false
        },
        // 取消联系人
        cancelContact() {
            this.contactDialogVisible = false
        },
        // 打开联系人选择弹窗
        showContactDialog() {
            this.contactId = '';
            this.contactDialogVisible = true
        },
        getAreaLine() {
            consumer_web_city_areaLinkage({ levelType: 1 }).then(res => {
                this.nationalityList = res.datas
            })
        },


        showNewUserPopup() {
            this.newUserParams = {
                staffName: '', // 中文名
                phone: '', // 电话号码
                certificates: [
                    {
                        certificateType: 1,
                        certificateValue: ''
                    }
                ],
                behalfCompanyId: '',
                isOutCustomer: 0,
                staffSex: 1,
            }
            this.dialogNewUserVisible = true
            this.$nextTick(() => {
                this.$refs['newUserForm'].clearValidate()
            })
        },
        submitAddNewUser() {
            this.$refs['newUserForm'].validate((valid) => {
                if (valid) {
                    this.newUserLoading = true
                    this.loading = true
                    this.newUserParams.behalfCompanyId = this.companyId
                    consumer_admin_behalfCustomer_addTraveler(this.newUserParams).then(res => {
                        this.newUserLoading = false
                        this.loading = false
                        let newUser = {
                            staffName: this.newUserParams.staffName,
                            name: this.newUserParams.staffName,
                            userId: res.datas,
                            id: res.datas,
                            certificateList: [
                                {
                                    certificateType: this.newUserParams.certificates[0].certificateType,
                                    certificateValue: this.newUserParams.certificates[0].certificateValue
                                }
                            ],
                            certificateType: this.newUserParams.certificates[0].certificateType,
                            certificateValue: this.newUserParams.certificates[0].certificateValue,
                            phone: this.newUserParams.phone,
                            staffSex: this.newUserParams.staffSex
                        }
                        this.evectionParams.travelUserList.push(newUser)
                        this.saveAndReactPrice()
                        this.getTravelInfoAndUserList()
                        this.dialogNewUserVisible = false

                        // 如果有酒店订单
                        this.reactHotelUser()
                    }).catch(() => {
                        this.newUserLoading = false
                        this.loading = false
                    })
                }
            })
        },

        // 复制出行人到联系人
        saveUserToContact() {
            if (this.evectionParams.travelUserList.length === 0) {
                return
            }
            this.contactIndex++

            let travelUserListLength = this.evectionParams.travelUserList.length
            if (this.contactIndex === travelUserListLength) {
                this.contactIndex = 0
            }
            let linkmanInfo = this.evectionParams.travelUserList[this.contactIndex]
            this.evectionParams.linkman = linkmanInfo.name || linkmanInfo.staffName
            this.evectionParams.linkmanPhone = linkmanInfo.phone
        },

        chooseOnUser() {
            this.contactList = []
            this.travelToContactDialogVisible = true
        },
        submitContactTravelUser() {
            console.log(this.contactList)
            this.travelToContactDialogVisible = false
            let newArr = JSON.parse(JSON.stringify(this.cascadeList))
            // 将多维数组转成一维数组
            function jsonToArray(nodes) {
                let r = [];
                if (Array.isArray(nodes)) {
                    for (let i = 0, l = nodes.length; i < l; i++) {
                        r.push(nodes[i]); // 取每项数据放入一个新数组
                        if (Array.isArray(nodes[i]["children"]) && nodes[i]["children"].length > 0)
                        // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
                            r = r.concat(jsonToArray(nodes[i]["children"]));
                        delete nodes[i]["children"]
                    }
                }
                return r;
            }
            let afterArr = jsonToArray(newArr)
            afterArr.forEach(value => {
                value.userList.forEach(value1 => {
                    if (this.contactList.indexOf(value1.id) > -1) {

                        this.evectionParams.linkman = value1.name || value1.staffName
                        this.evectionParams.linkmanPhone = value1.phone
                    }
                })
            })
        },
        getStopMsg(val, index, segmentIndex) {
            console.log(val, index, segmentIndex)
            let params = {
                "rqData": {
                    "date": val.depDate,
                    "flightNo": val.flightNo
                }
            }
            // val.stopLoad = true
            // this.flightList[index].segmentList[segmentIndex].stopLoad = true
            this.stopLoad = true
            let flightsList = JSON.parse(JSON.stringify(this.flightList))
            consumer_flight_order_flightsStop(params).then(res => {
                flightsList[index].segmentList[segmentIndex].stopData = res.datas
                // flightsList[index].segmentList[segmentIndex].stopLoad = false
                this.stopLoad = false
                this.flightList = flightsList
            }).catch(() => {
                // flightsList[index].segmentList[segmentIndex].stopLoad = false
                this.stopLoad = false
                this.flightList = flightsList
            })
        },
    }
}

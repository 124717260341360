import Q from 'q';
import moment from 'moment';
import CompanyInfo from '@/component/company-info/import_latest_version_module'
// import PaymentMethodSelector010 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.10/payment-method-selector-010/index.vue';
import consumer_journey_getEvectionDetail
    from "@/lib/data-service/haolv-default/consumer_admin_journey_getEvectionDetail";
import req_cancel_order from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_cancelOrder"   //取消订单-酒店
import consumer_admin_behalfCustomer_certificates
    from '@/lib/data-service/haolv-default/consumer_admin_behalfCustomer_certificates'
import consumer_admin_flight_milliseconds from '@/lib/data-service/haolv-default/consumer_admin_flight_milliseconds'
import consumer_admin_user_getCompanyPayType from '@/lib/data-service/haolv-default/consumer_admin_user_getCompanyPayType'
import {awaitWrap} from "../../common/unit";
// import PaymentMethodSelector000 from "yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.10/payment-method-selector-000";

import PaymentMethodSelector000 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.10/payment-method-selector-000/index.vue';
import PaymentMethodSelector010 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.11/payment-method-selector-010/index.vue';
export default {
    data() {
        return {
            loading: false,
            payType: 0, // 1,企业支付，2个人支付
            evectionNo: '',
            evectionType: 2,
            orderNo: '',
            pageType: '', // 1是创建订单页，2是支付页，3是改签页
            showDetail: false,
            detail: {
                costInfoDetail: {
                    actualPayment: 0
                }
            },
            travellerNameList: [],
            travellerList: [],

            errorText: '',
            dialogError2Visible: false,
            successVisible: false,

            papersTypeList: [],
            cacheKey: '',
            milliseconds: 1800
        }
    },
    components: {
        CompanyInfo,
        PaymentMethodSelector010,
        PaymentMethodSelector000,
    },
    mounted() {
        this.getCertificatesList()
        this.init();
    },
    methods: {
        showMore() {
            this.showDetail = !this.showDetail
        },
        async init() {
            this.loading = true;
            const query = this.$route.query;
            this.evectionNo = query.evectionNo;
            this.orderNo = query.orderNo;
            this.pageType = Number(query.pageType) || 2;
            await this.getCompanyPayType();
            await this.reqDetail({
                evectionNo: this.evectionNo,
                orderNo: this.orderNo,
                pageType: this.pageType,
                personType: 2,
            });
            this.loading = false;
        },
        async getCompanyPayType() {
            // companyId
            const params = {
                companyId: this.$route.query.behalfCompanyId,
            }
            let [err, res] =  await awaitWrap(consumer_admin_user_getCompanyPayType(params));
            if (err) {
                return;
            }
            this.payType = res.datas.payType;
        },
        async reqDetail(params) {
            console.log('22222');
            await this.getEvectionDetail(params);
            await this.getMilliseconds();
            await this.initPayment();
        },
        async getEvectionDetail(params) {
            let res = await consumer_journey_getEvectionDetail(params);
            this.cacheKey = res.datas.cacheKey;
            this.evectionType = res.datas.evectionType;
            this.detail = res.datas.todHotelOrderInterimList[0];
            this.detail.gmtCreate = this.$moment(res.datas.todHotelOrderInterimList[0].gmtCreate).format('YYYY-MM-DD hh:mm:ss');
            this.travellerList = res.datas.todHotelOrderInterimList[0].newOrderPersonList;
            this.travellerList.forEach(item => {
                this.travellerNameList.push(item.name)
            })
        },
        async getMilliseconds() {
            let res = await consumer_admin_flight_milliseconds({
                evectionNo: this.evectionNo,
                orderNo: this.detail.orderNo
            });
            this.milliseconds = res.datas
        },
        initPayment() {
            let _this = this
            let orderForPayVos = []
            let params = {
                orderNo: _this.detail.orderNo,
                businessContent: '',
            }
            orderForPayVos.push(params)
            let aPaymentMethodSelector = null;
            if (this.evectionType === 2 && this.payType == 1) {

                aPaymentMethodSelector = _this.$refs.aPaymentMethodSelector010
            } else {
                aPaymentMethodSelector = _this.$refs.aPaymentMethodSelector000
            }
            aPaymentMethodSelector.init({
                page_this: _this,
                // count_down: {
                //     pay_remaining_seconds: _this.milliseconds,//支付剩余时间，以秒为单位
                // },

                //在这里传入订单详情的相关参数
                get_params() {
                    const p = {
                        "orderForPayVos": orderForPayVos,
                        "payType":  1
                    };

                    return Q.resolve(p);
                },

                //在这里传入订单详情的相关参数
                // get_params() {
                //     const p = {
                //         cacheKey: _this.cacheKey,
                //         evenctionNo: _this.evectionNo,//出差单号
                //         orderNo: _this.detail.orderNo,//订单编号
                //     };
                //     return Q.resolve(p);
                // },

                //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                before_pay_event_handler() {
                    return Q.resolve();
                },

                //支付成功事件处理方法
                pay_successful_event_handler() {
                    console.log(`pay_successful_event_handler`);
                    _this.successVisible = true;
                },

                //支付失败事件处理方法
                pay_failed_event_handler() {
                    console.log(`pay_failed_event_handler`);
                },

                //取消订单按钮单击事件处理方法
                btn_cancel_order_click_event_handler(args) {
                    const show_loading = args.show_loading;
                    const hide_loading = args.hide_loading;
                    const stop_count_down = args.stop_count_down;
                    const enable_btn_pay = args.enable_btn_pay;
                    const enable_btn_cancel_order = args.enable_btn_cancel_order;
                    _this.$confirm('确定取消此订单', '取消提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        Q.when()
                            .then(function () {
                                show_loading();
                                return req_cancel_order({
                                    orderNo: _this.detail.orderNo,
                                    cancelRemark: ""
                                });
                            })
                            .then(function () {
                                console.log(`btn_cancel_order_click_event_handler`);
                                hide_loading();
                                stop_count_down();
                                enable_btn_pay({
                                    enable: false,
                                });
                                enable_btn_cancel_order({
                                    enable: false,
                                });
                                _this.$message({
                                    type: 'success',
                                    message: '取消成功!'
                                });
                                _this.$router.replace({
                                    name: 'admin-travel-book-home',
                                    query: {
                                        activeName: 'second'
                                    }
                                })
                            })
                            .catch(function (ex) {
                                if (ex) {
                                    const enable_log_error = !ex.disable_log_error;
                                    if (enable_log_error) {
                                        console.error(ex);
                                        console.trace();
                                        if (ex.stack) {
                                            console.error(ex.stack);
                                        }
                                    }
                                }
                                return Q.reject(ex);
                            });
                    }).catch(() => {
                    });
                },
                //获取支付方式接口，返回code=300052时，弹窗的确定按钮单击处理处理方法
                dialog_code_300052_btn_ok_click_event_handler() {
                    console.log(`dialog_code_300052_btn_ok_click_event_handler`);
                    _this.$router.replace({
                        name: 'admin-hotel-order-sold',
                    });
                    return Q.resolve(true);
                },
            });

        },
        to_detail() {
            this.successVisible = false;
            const orderNo = this.detail.orderNo;
            this.$router.replace({
                name: "admin-hotel-order-sold-detail",
                query: {
                    id: orderNo
                },
            });
        },
        againInit() {
            this.dialogError2Visible = false
            this.init()
        },
        cardTypeFormatter(val) {
            let cardTypeText = ''
            if (val.cardType) {
                this.papersTypeList.forEach(value => {
                    if (value.code === val.cardType) {
                        cardTypeText = value.msg
                    }
                })
            }
            return cardTypeText
        },
        getCertificatesList() {
            consumer_admin_behalfCustomer_certificates().then(res => {
                this.papersTypeList = res.datas.items
            })
        },
    }
}

import _ from 'underscore'
import moment from 'moment'
import history_tag_api from '@/component/admin-layout/component/history-tag/api'
import OrderLog from '@/component/orderLog/1.0.0/index.vue'

import consumer_admin_hotel_order_queryHotelOrderDetails from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_queryHotelOrderDetails"   // 平台酒店订单详情
import consumer_admin_hotel_order_forHotelOrderDetails from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_forHotelOrderDetails"   // 获取酒店订单明细
import consumer_log_hotelList from "@/lib/data-service/haolv-default/consumer_log_hotelList"   // 获取酒店订单日志
import consumer_admin_hotel_order_partialCancelDetail
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_partialCancelDetail"   // 平台酒店订单详情
import consumer_admin_hotel_order_hotelSaveOrderRemark from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_hotelSaveOrderRemark"   // 保存备注

export default {
    data() {
        return {
            detailLoading: false,
            loading: false,
            activeName: 'detail',
            orderDetail: {},
            cancelDetail: {},
            orderPersonList: [],
            costDetail: {},
            refundRule: [],
            formData: {},
            form: {
                orderNo: '',
                hotelRemark: ''
            },

            moment: moment,

            refundRuleTypeObj: {
                1: '不可退',
                2: '限时取消',
            },
            deductTypeObj: {
                0: '扣首晚房费',
                1: '扣全额',
            },
            rules: {
                refundFine: [
                    {required: true, message: '退订罚金', trigger: 'blur'}
                ],
            }
        }
    },
    components: {
        OrderLog
    },
    activated() {
        this.form.orderNo = this.$route.query.orderNo;
        this.getHotelOrderDetails()
        this.getOrderLog()
    },
    filters: {
        filterMoney(val) {
            if (val || val === 0) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return '0.00'
            }
        }
    },
    methods: {
        // 平台酒店订单详情
        async getHotelOrderDetails() {
            try {
                this.detailLoading = true
                const res = await consumer_admin_hotel_order_queryHotelOrderDetails({
                    orderNo: this.$route.query.orderNo
                })
                this.orderDetail = res.orderInterimResult
                this.orderPersonList = res.hotelOrderPersonList
                this.costDetail = res.hotelCostInfoResponse
                this.orderDetail.hotelGroup = res.hotelGroup;
                this.orderDetail.ifAgreement = res.ifAgreement;
                this.orderDetail.cardNumber = res.cardNumber;
                this.orderDetail.agreementHotelText = res.agreementHotelText;
                this.refundRule = [res.orderInterimResult.refund]

                let res1 = await consumer_admin_hotel_order_partialCancelDetail({
                    reverseOrderId: this.$route.query.reverseOrderId
                })
                this.cancelDetail = res1.partialCancelDetailResult;
                this.formData.supplierReturnedPrice = this.cancelDetail.supplierReturnedPrice;
                this.formData.supplierRefundFine = this.cancelDetail.supplierRefundFine;
                this.formData.refundPrice = this.cancelDetail.refundPrice;
                this.formData.refundFine = this.cancelDetail.refundFine;
                this.formData.resultFlag = this.cancelDetail.resultFlag;
                this.formData.failedMsg = this.cancelDetail.failedMsg;
                this.form.hotelRemark = this.orderDetail.hotelRemark;
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "获取酒店订单详情失败！"
                })
            } finally {
                this.detailLoading = false
            }
        },
        // 获取酒店订单明细
        async getSupplierDetail() {
            try {
                this.detailLoading = true
                const res = await consumer_admin_hotel_order_forHotelOrderDetails({
                    orderNo: this.orderDetail.thirdpartyOrderNo
                })
                this.orderDetail.orderFromTypeText = res.datas.supplierName
                this.orderDetail.supplierOrderNo = res.datas.supplierOrder
                this.orderDetail.supplierPrice = res.datas.supplierPrice
                this.orderDetail.settlementText = res.datas.settlement
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "获取酒店订单明细失败！"
                })
            } finally {
                this.detailLoading = false
            }
        },
        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    return consumer_log_hotelList({
                        orderNo: this.$route.query.orderNo
                    }).then(res => {
                        // 组装组件需要的list
                        const list = _.chain(res.datas)
                            .map(function (m) {
                                // 替换名字
                                const o = {
                                    operator: m.operator,
                                    date: m.operatorTime,
                                    type: m.logSource,
                                    detail: m.logContents
                                }
                                return o
                            }).value()
                        const d = {
                            entityList: list
                        }
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },
        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
            switch (val) {
                case (1):
                    return 'type2'
                case (2):
                    return 'type1'
                default:
                    return ''
            }
        },
        // 订单状态
        getOrderStatusToClass(val) {
            switch (val) {
                case (23):
                    return 'status2'
                case (22):
                    return 'status1'
                default:
                    return 'status3'
            }
        },

        //保存备注
        async saveRemark() {
            if (this.form.hotelRemark === '') {
                this.$message({
                    type: "warning",
                    message: '请输入备注'
                })
                return
            }
            this.detailLoading = true;
            consumer_admin_hotel_order_hotelSaveOrderRemark(this.form).then(res => {
                this.detailLoading = false
                this.$message({
                    type: "success",
                    message: '提交成功'
                })
                this.form.hotelRemark = ''
                this.getHotelOrderDetails()
                this.getOrderLog()
            }, error => {
                this.detailLoading = false
            }).catch(() => {
                this.detailLoading = false
            })
        },

        // 返回
        handleBackClick() {
            history_tag_api.remove_tag_by_route({
                route: this.$route
            })
            this.$router.replace({
                name: "admin-hotel-order-non-rule-cancel-list"
            })
        },
    }
}

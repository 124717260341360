// consumer_intention_order_orderlist 分页查询意向订单列表
const __request = require(`./__request/__request_contentType_json`);
const consumer_intention_order_orderList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/intention/order/orderList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_intention_order_orderList;
/*
*admin-新免支付接口  http://api-beta.wholeok.com/doc.html#/yinzhilv-tmc/admin/payUsingPOST
*/
const __request = require(`./__request/__request_contentType_json`)
const consumer_admin_payment_behalfPay = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/admin/payment/behalfPay',
        data: data,
        enable_error_alert: false,
    }
    return __request(pParameter)
}
export default consumer_admin_payment_behalfPay
// 封装异步组件
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";
// 客户经理列表
import consumer_web_staff_staffAll from "@/lib/data-service/haolv-default/consumer_web_staff_staffAll.js";
// 服务商枚举
import consumer_sys_inform_getEnum from '@/lib/data-service/haolv-default/consumer_sys_inform_getEnum.js';
// 客户审核详情
import consumer_web_company_detail from "@/lib/data-service/haolv-default/consumer_web_company_detail.js";
// 客户审核
import consumer_web_company_clientAudit from "@/lib/data-service/haolv-default/consumer_web_company_clientAudit.js";
// 获取特别关注
import consumer_admin_company_query from "@/lib/data-service/haolv-default/consumer_admin_company_query";
// 云
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client/index";
// loading
import {Loading} from 'element-ui';

export default {
  components: {

  },
  data() {
    const validatorCauseOfFailure = function (rule, value, callback) {
      if (value) {
        callback();
      } else {
        callback(new Error("请输入失败原因"));
      }
    };
    return {
      radio: 3,

      loading: false,
      loadingInstance: null,
      detail: {
        // 公司名称
        companyName: "",
        // 公司简称
        abbreviation: "",
        // 行业
        industryId: "",
        // 规模
        scale: "",
        // 省份
        areaProvinceName: "",
        // 城市
        areaCityName: "",
        // 区县
        areaRegionName: "",
        // 简介
        companyIntroduce: "",
        // logo
        companyLogo: "",
        // 营业执照号
        businessLicenseNum: "",
        // 营业执照url
        businessLicensePic: "",
        hotelTogether: false,
        specialFocus: false,
        // 预定联系人
        reserveLinkman: {
          name: "",
          phone: "",
        },
        // 合同联系人
        contractLinkman: {
          name: "",
          phone: "",
        },
        // 财务联系人
        financeLinkman: {
          name: "",
          phone: "",
        },
        // 紧急联系人
        urgencyLinkman: {
          name: "",
          phone: "",
        },
      },
      allocation: {
        // 客户经理--选项
        manager: [],
        // 进位规则--选项
        carryRules: [
          {label: "0", text: "进位到元"},
          {label: "1", text: "进位到分"},
        ],
        // 服务商列表--选项
        settlementList: [],
        audit: [
          {label: true, text: "开通"},
          {label: false, text: "不开通"},
        ],
      },
      form: {
        // 公司id
        companyId: "",
        // 附件
        accessoryUrl: "",
        // 状态
        companyStatus: 2,
        // 客户经理ID
        customerManagerId: "",
        // 失败原因
        causeOfFailure: "",
        // 进位规则
        carryRules: "0",
        // 开通办公
        audit: true,
        // 服务商
        settlement: '',
        // 服务费设置
        pdProductSet: [
          // 机票
          {
            businessType: 1,// 类型
            serviceAmountSet: "0",// 是否收取
            serviceAmountType: "1",// 计算方式
            serviceAmount: "",// 收取金额、百分比
            robTicket: "",// 抢票费
            airPriceSet: '',// 价格配置 1：票面价结算 2：结算价结算
            pnrSet: 1, //生编设置 1：支付前生编 2：支付后生编 默认：2
            enterpriseConfigurationSet: 0, // 0.不加价，1.加价
            enterpriseConfigurationType: '', // 1.百分比,2.定额'
            enterpriseConfigurationAmount: '',
            officialPolicyShowSet: 1, // 授权航司是否展示航司官网设置 0：不展示 1：展示 默认：1
            internationalServiceAmountSet: 0, // 亚洲外服务费设置：0.不收取，1.收取，默认0
            internationalServiceAmountType: 1, // 亚洲外计算方式：0. 无，1.百分比，2.定额
            internationalServiceAmount: '', // 亚洲外服务费
            asiaServiceAmountSet: 0, // 亚洲服务费设置：0.不收取，1.收取，默认0
            asiaServiceAmountType: 1, // 亚洲计算方式：0. 无，1.百分比，2.定额
            asiaServiceAmount: '', // 亚洲服务费
            lowPriceType: 0,
            lowPriceAmount: '',
          },
          // 酒店
          {
            businessType: 3,// 类型
            serviceAmountSet: "0",// 是否收取
            serviceAmountType: "1",// 计算方式
            serviceAmount: "",// 收取金额、百分比
            robTicket: "",// 抢票费
            invoiceType: 0,//发票类型
            invoiceTaxAmount: null,//收取金额(百分比)
          },
          // 火车票
          {
            businessType: 2,// 类型
            serviceAmountSet: "0",// 是否收取
            serviceAmountType: "1",// 计算方式
            serviceAmount: "",// 收取金额、百分比
            robTicket: "",// 抢票费
          },
          // 用车
          {
            businessType: 4, // 类型
            serviceAmountSet: "0", // 是否收取
            serviceAmountType: "2", // 计算方式
            serviceAmount: "", // 收取金额
          },
        ],
      },
      // 附件
      accessoryUrl: [
        {
          name: "",
          url: "",
        },
      ],
      // 图片最大
      imgMaxSize: 512,
      // 附件最大数量
      attachedMax: 5,
      // 当前要上传文件的index
      uploadIndex: null,
      // 公司logo、营业执照--备用图案
      src: "//yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2020/08/17/haolv_platform_up_load_bkgimg.png?x-oss-process=style/yinzhilv-default",
      rules: {
        causeOfFailure:[
          {validator: validatorCauseOfFailure, trigger: 'blur' }
        ],
      },


    };
  },
  methods: {
    select_manager(id) {
      const __this = this;
      __this.allocation.manager.forEach((item) => {
        if (id === item.userId) {
          __this.form.customerManagerName = item.staffName;
        }
      });
    },
    settlementName(settlement){
      let text = '';
      if (this.allocation.settlementList.length > 0) {
        this.allocation.settlementList.forEach((item,index) => {
          if (item.code == settlement) {
            text = item.msg;
          }
        });
      }
      return text
    },
    managerName(id){
      let text ='';
      this.allocation.manager.forEach((item,index) => {
        if (id == item.userId) {
          text = item.staffName;
        }
      });
      return text
    },
    // 重置方法
    clickReset() {
      this.form = {
        // 公司id
        companyId: "",
        // 附件
        accessoryUrl: "",
        // 状态
        companyStatus: "2",
        // 客户经理ID
        customerManagerId: "",
        // 失败原因
        causeOfFailure: "",
        // 进位规则
        carryRules: "0",
        // 开通办公
        audit: true,
        // 服务商
        settlement: '',
        // 服务费设置
        pdProductSet: [
          // 机票
          {
            businessType: 1,// 类型
            serviceAmountSet: "0",// 是否收取
            serviceAmountType: "1",// 计算方式
            serviceAmount: "",// 收取金额、百分比
            robTicket: "",// 抢票费
            airPriceSet: '',// 价格配置 1：票面价结算 2：结算价结算
            pnrSet: 1, //生编设置 1：支付前生编 2：支付后生编 默认：2
            enterpriseConfigurationSet: 0, // 0.不加价，1.加价
            enterpriseConfigurationType: '', // 1.百分比,2.定额'
            enterpriseConfigurationAmount: '',
            officialPolicyShowSet: 1, // 授权航司是否展示航司官网设置 0：不展示 1：展示 默认：1
            internationalServiceAmountSet: 0, // 亚洲外服务费设置：0.不收取，1.收取，默认0
            internationalServiceAmountType: 1, // 亚洲外计算方式：0. 无，1.百分比，2.定额
            internationalServiceAmount: '', // 亚洲外服务费
            asiaServiceAmountSet: 0, // 亚洲服务费设置：0.不收取，1.收取，默认0
            asiaServiceAmountType: 1, // 亚洲计算方式：0. 无，1.百分比，2.定额
            asiaServiceAmount: '', // 亚洲服务费
            lowPriceType: 0,
            lowPriceAmount: '',
          },
          // 酒店
          {
            businessType: 3,// 类型
            serviceAmountSet: "0",// 是否收取
            serviceAmountType: "1",// 计算方式
            serviceAmount: "",// 收取金额、百分比
            robTicket: "",// 抢票费
            invoiceType: 0,//发票类型
            invoiceTaxAmount: null,//收取金额(百分比)
          },
          // 火车票
          {
            businessType: 2,// 类型
            serviceAmountSet: "0",// 是否收取
            serviceAmountType: "1",// 计算方式
            serviceAmount: "",// 收取金额、百分比
            robTicket: "",// 抢票费
          },
          // 用车
          {
            businessType: 4, // 类型
            serviceAmountSet: "0", // 是否收取
            serviceAmountType: "2", // 计算方式
            serviceAmount: "", // 收取金额
          },
        ],
      };
    },
    async req_detail(params) {
      this.loading = true;
      let [err, res] = await awaitWrap(consumer_web_company_detail(params))
      if (err) {
        this.loading = false;
        return
      }
      const detail = res.datas;

      if (detail.reserveLinkman) this.detail.reserveLinkman = JSON.parse(detail.reserveLinkman);
      if (detail.contractLinkman) this.detail.contractLinkman = JSON.parse(detail.contractLinkman);
      if (detail.financeLinkman) this.detail.financeLinkman = JSON.parse(detail.financeLinkman);
      if (detail.urgencyLinkman) this.detail.urgencyLinkman = JSON.parse(detail.urgencyLinkman);
      this.form.companyId = detail.id;
      this.detail.companyName = detail.companyName;
      this.detail.abbreviation = detail.abbreviation;
      this.detail.industryName = detail.industryName;
      this.detail.areaProvinceName = detail.areaProvinceName;
      this.detail.areaCityName = detail.areaCityName;
      this.detail.areaRegionName = detail.areaRegionName;
      this.detail.companyIntroduce = detail.companyIntroduce;
      this.detail.companyLogo = detail.companyLogo;
      this.detail.businessLicenseNum = detail.businessLicenseNum;
      this.detail.businessLicensePic = detail.businessLicensePic;
      this.detail.userName = detail.userName;
      this.detail.realName = detail.realName;
      this.detail.phone = detail.phone;
      this.form.customerManagerId = detail.customerManagerId;
      this.form.carryRules = detail.carryRules;
      this.form.settlement = detail.settlement;
      this.form.pdProductSet[1].invoiceType = detail.pdProductSetList[0].invoiceType;
      this.form.pdProductSet[1].invoiceTaxAmount = detail.pdProductSetList[0].invoiceTaxAmount;
      this.form.audit = detail.audit;
      this.form.allowUseCar = detail.allowUseCar;
      this.form.openMemberMt = detail.openMemberMt;
      this.form.pdProductSet = detail.pdProductSetList;
      this.form.pdProductSet = [this.form.pdProductSet[0],this.form.pdProductSet[1],this.form.pdProductSet[2],this.form.pdProductSet[3]] = [this.form.pdProductSet[0],this.form.pdProductSet[1],this.form.pdProductSet[3],this.form.pdProductSet[2]];

      consumer_admin_company_query({companyId: Number(this.$route.query.id), bizType: 7}).then(res => {
        this.detail.specialFocus = res.datas && res.datas.companyExpendVo && res.datas.companyExpendVo.specialFocus ? res.datas.companyExpendVo.specialFocus : false;
        this.detail.hotelTogether = res.datas && res.datas.companyExpendVo && res.datas.companyExpendVo.hotelTogether ? res.datas.companyExpendVo.hotelTogether : false;
      });

      this.loading = false;
    },
    req_commit(params) {
      let form = JSON.parse(JSON.stringify(params));
      this.loadingInstance = Loading.service({target: 'body', text: '请求中...'});
      console.log(form);
      debugger
      consumer_web_company_clientAudit(form).then((res) => {
        this.loadingInstance.close();
        if (Number(params.companyStatus) === 2) {
          this.$message.success("审核通过成功");
        } else if (Number(params.companyStatus) === 3) {
          this.$message.success("审核不通过成功");
        }
        this.$router.back(-1);
      }).catch((err) => {
        this.loadingInstance.close();
      });
    },
    async init() {
      const __this = this;
      consumer_web_staff_staffAll().then((res) => {
        __this.allocation.manager = res.datas;
      });
      consumer_sys_inform_getEnum(["settlement"]).then((res) => {
        __this.allocation.settlementList = res.datas.settlement;
      });
      await __this.req_detail({id: __this.$route.query.id});
    },
    // 上次文件--点击上传
    change_upload_index(index) {
      this.uploadIndex = index;
    },
    change_attached(file) {
      const size = file.size / 1024;
      if (size > 10240) {
        this.$message.warning("附件大小不能超过 10MB!");
      } else {
        const myUpLoad = new OssClient();
        const index = this.uploadIndex;
        this.accessoryUrl[index].name = file.name;
        myUpLoad
          .multipartUpload({
            file: file.raw,
            progress: function* (p) {
            },
          })
          .then((data) => {
            this.accessoryUrl[index].url = data.url;
            this.form.accessoryUrl = this.accessoryUrl
              .map((item) => item.url)
              .join();
          });
      }
    },
    // 删除附件
    add_attached() {
      if (this.accessoryUrl.length < 5)
        this.accessoryUrl.push({name: "", url: ""});
    },
    delete_item(index) {
      if (this.accessoryUrl.length > 1) {
        this.accessoryUrl.splice(index, 1);
        this.form.accessoryUrl = this.accessoryUrl
          .map((item) => item.url)
          .join();
      } else {
        this.accessoryUrl[0].name = '';
        this.accessoryUrl[0].url = '';
      }
    },
    read_attached(item) {
      const eleLink = document.createElement('a');
      eleLink.href = item.url;
      eleLink.download = item.name;
      document.body.appendChild(eleLink);
      eleLink.click();
      document.body.removeChild(eleLink);
    },
    async commit() {
      const __this = this;

      this.$refs.form.validate((valid) => {
        if (valid) {
          __this.req_commit(__this.form);
        } else {
          this.locateToErr();
          return false;
        }
      });
    },
    locateToErr() {
      setTimeout(() => {
        const errorDiv = document.getElementsByClassName('is-error');
        errorDiv[0].scrollIntoView({behavior: "smooth"})
      }, 0)
    },
  },
  activated() {

  },
  async mounted() {
    await this.init();
  },
  deactivated() {

  },
  watch: {

  },
  filters: {
    format_type(val) {
      switch (Number(val)) {
        case 1:
          return "机票";
          break;
        case 2:
          return "火车票";
          break;
        case 3:
          return "酒店";
          break;
        case 4:
          return "用车";
          break;
      }
    },
  },
};

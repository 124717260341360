// 平台添加员工
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_web_staff_addOrUpdate = (pParameter) => {
    console.log('pParameter', pParameter);
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/web/staff/addOrUpdate',
        data: pParameter,
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_web_staff_addOrUpdate;
import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import store from '@/lib/store';

const set_entity_list_by_entity_list = function (pParameter) {
    if (!pParameter) pParameter = {};
    const entity_list = pParameter.entity_list;
    if (!_.isArray(entity_list)) {
        const msg = 'set_entity_list_by_entity_list parameter error, code=001';
        console.error(msg);
        console.trace();
        return Q.reject({msg: msg});
    }

    store.state.data_for_breadcrumb.entity_list = entity_list;
};

const set_entity_list_by_get_entity_list = function (pParameter) {
    if (!pParameter) pParameter = {};
    const get_entity_list = pParameter.get_entity_list;
    if (!_.isFunction(get_entity_list)) {
        const msg = 'set_entity_list_by_get_entity_list parameter error, code=001';
        console.error(msg);
        console.trace();
        return Q.reject({msg: msg});
    }

    const original_entity_list = _.chain(store.state.data_for_breadcrumb.entity_list)
        .map(function (m) {
            return extend({}, m);
        })
        .value()
    ;

    const entity_list = get_entity_list({
        original_entity_list,
    });
    if (!_.isArray(entity_list)) {
        const msg = 'set_entity_list_by_get_entity_list parameter error, code=002';
        console.error(msg);
        console.trace();
        return Q.reject({msg: msg});
    }

    store.state.data_for_breadcrumb.entity_list = entity_list;
};

export default {
    set_entity_list_by_entity_list,
    set_entity_list_by_get_entity_list,
};

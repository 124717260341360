import consumer_admin_flight_refund_newdetails
    from '@/lib/data-service/haolv-default/consumer_admin_flight_refund_newdetails'
import consumer_air_ticket_fligthRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules"
import consumer_admin_flight_refund_selfSupportRefundOrderRemark from "@/lib/data-service/haolv-default/consumer_admin_flight_refund_selfSupportRefundOrderRemark"
import NewChangeClause from "@/page/admin/order-management/flight/component/newChangeClause/index.vue"
import NewLuggageClause from "@/page/admin/order-management/flight/component/newLuggageClause/index.vue"
import ChangeClausePopup from '@/page/admin/replace-orders/component/changeClausePopup/1.0.0/index.vue'
import consumer_log_flightRefundList from "@/lib/data-service/haolv-default/consumer_log_flightRefundList"
import consumer_admin_flight_equity_getOrderProductInfo from '@/lib/data-service/haolv-default/consumer_admin_flight_equity_getOrderProductInfo'
import OrderLog from '@/component/orderLog/2.0.0/index.vue'

export default {
    data() {
        return {
            orderProductInfo: {},
            dialogEnjoyMore: false,
            activeName: 'detail',
            detailLoading: false,
            loading: false,
            refOrderNo: '',
            orderNo: '',
            detail: {
                refundDetails: [],
                segmentsList: [],
                refundPassenger: [],
                refundContent: []
            },
            remark: '',//备注

            ruleText: {},
            ruleLoading: false,
            ruleShow: false, // 退改签说明弹窗
        }
    },
    components: {OrderLog, NewChangeClause, NewLuggageClause, ChangeClausePopup},
    created() {
    },
    mounted() {
    },
    activated() {
        this.refOrderNo = this.$route.query.refOrderNo
        this.orderNo = this.$route.query.orderNo
        this.getDetail()
        this.getOrderLog()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        refOrderStatus(val) {
            // 41.申请中 42.待确认 43.已确认 44.处理中 45.退票成功 46.退票失败 47.已取消
            if (val === '41') {
                return '申请中'
            } else if (val === '42') {
                return '待确认'
            } else if (val === '43') {
                return '已确认'
            } else if (val === '44') {
                return '处理中'
            } else if (val === '45') {
                return '退票成功'
            } else if (val === '46') {
                return '退票失败'
            } else if (val === '47') {
                return '已取消'
            } else {
                return ''
            }
        },
        getImgNameFilter(val) {
            let filename;
            if (val === undefined || val === '') {
                return ''
            }
            if (val.indexOf('/') > 0) { //如果包含有"/"号 从最后一个"/"号+1的位置开始截取字符串
                filename = val.substring(val.lastIndexOf('/') + 1, val.length);
            } else {
                filename = val;
            }
            return filename
        },
    },
    methods: {
        clickEnjoyMore() {
            consumer_admin_flight_equity_getOrderProductInfo({orderNo: this.orderNo}).then((res) => {
                this.orderProductInfo = res.datas;
            });
            this.dialogEnjoyMore = true;
        },
        getDetail() {
            this.detailLoading = true
            consumer_admin_flight_refund_newdetails({refOrderNo: this.refOrderNo}).then(res => {
                let detail = res.detailVO;
                detail.refundContent.forEach(value => {
                    value.urlList = [];
                    value.urlList = value.url.split(',');
                })
                this.detail = detail
                this.remark = detail.remarks;
                this.detailLoading = false
            }).catch(() => {
                this.detailLoading = false
            })
        },
        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    return consumer_log_flightRefundList({
                        refOrderNo: this.refOrderNo
                    }).then(res => {
                        // 组装组件需要的list
                        const list = res.datas.map(m => {
                            // 替换名字
                            const o = {
                                operator: m.operator,
                                date: m.operatorTime,
                                type: m.logSource,
                                detail: m.logContents
                            }
                            return o
                        })
                        const d = {
                            entityList: list
                        }
                        this.loading = false //临时使用
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },
        // 退票退改规则
        showRefundChangeClause(val) {
            this.ruleText = {}
            this.ruleShow = true
            let data = {
                segmentNo: val.segmentNo
            }
            this.ruleLoading = true
            consumer_air_ticket_fligthRules(data).then(res => {
                this.ruleLoading = false
                this.ruleText = res.datas.rsData
            }).catch(() => {
                this.ruleLoading = false
            })
        },
        async onSubmit() {
            let res = await consumer_admin_flight_refund_selfSupportRefundOrderRemark({
                refOrderNo: this.refOrderNo,
                remark: this.remark
            });
            if(res.code === '000000') {
                this.$message({
                    type: 'success',
                    message: '保存成功！'
                });
                //this.$router.back();
                this.remark = '';
                this.getDetail();
                this.getOrderLog();
            }
        },
        onBack() {
            this.$router.back();
        }
    }
}

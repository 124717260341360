export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/agricultural/invoicing-reconciliation',
            name: 'admin-agricultural-invoicing-reconciliation',
            component: () => import(/* webpackChunkName: "page-admin-agricultural-bank-reconciliation-agricultural-invoicing-reconciliation" */ `./1.0.1/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `开票对账列表`,
                },
                entity_list_for_breadcrumb: [
                    {name: `线下对账和开票`},
                    {name: `开票对账列表`},
                ],
            },
        },
    ],
};

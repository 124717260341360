import _ from 'underscore'
import moment from 'moment'
import history_tag_api from '@/component/admin-layout/component/history-tag/api'
import OrderLog from '@/component/orderLog/2.0.0/index.vue'
import consumer_admin_visa_details
    from "@/lib/data-service/haolv-default/consumer_admin_visa_details";   // 签证订单详情
import consumer_admin_visa_billDetails from '@/lib/data-service/haolv-default/consumer_admin_visa_billDetails';
import consumer_admin_visa_billAction from '@/lib/data-service/haolv-default/consumer_admin_visa_billAction';
import consumer_admin_visa_action from '@/lib/data-service/haolv-default/consumer_admin_visa_action'

import consumer_logManage_bizlog_pageList from "@/lib/data-service/haolv-default/consumer_logManage_bizlog_pageList"   // 获取订单日志
import consumer_admin_visa_cancelBill from "@/lib/data-service/haolv-default/consumer_admin_visa_cancelBill"   // 取消订单-酒店
import consumer_admin_visa_save from "@/lib/data-service/haolv-default/consumer_admin_visa_save"

import DictionarySelect
    from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';

export default {
    data() {
        const validateCancelRules = (rule, value, callback) => {
            if (this.salesPriceMin < this.cancelForm.fine) {
                callback(new Error('罚金(单人)不能大于销售价'));
            } else {
                callback()
            }
        };
        return {
            salesPriceMin: 0,
            detailLoading: false,
            progressDialogVisible: false,
            cancelS1DialogVisible: false,
            cancelS2DialogVisible: false,
            confirmDialogVisible: false,
            loading: false,
            remark: '',
            activeName: 'detail',
            orderSource: '1',
            orderDetail: {},
            orderPersonList: [],
            costDetail: {},
            refundRule: [],
            reportConfigurationValueList: [],//动态表单内容
            form: {
                orderNo: '',
                visaRemark: '',
            },

            moment: moment,

            refundRuleTypeObj: {
                1: '不可退',
                2: '限时取消',
            },
            deductTypeObj: {
                0: '扣首晚房费',
                1: '扣全额',
            },

            userList: [],
            progressList: [],
            progressForm: {},
            cancelForm: {
                userName: '',
                fine: 0,
            },
            cancelRules: {
                fine: [
                  {required: true, message: '请输入罚金(单人)', trigger: ['blur', 'change']},
                  { validator: validateCancelRules, trigger: 'blur' }
                ]
            },

            visaOrder: {},
            visaCompanyInfo: {},
            confirmForm: {},
            visaCompanyBill: [],
            files: [],
            selectedUserList: [],
            visaBillList: [],
            visaBillIdList: [],
            progressStatusList: [//0 办理中 1 已出签 2拒签 3 取消
                {
                    code: 0,
                    msg: '办理中'
                },
                {
                    code: 1,
                    msg: '已出签'
                },
                {
                    code: 2,
                    msg: '已拒签'
                },
                /*{
                    code: 3,
                    msg: '已取消'
                },*/
            ],
            confirmRules: {
                orderSource: [
                    {required: true, message: '请选择采购渠道', trigger: 'blur'},
                ],
                paymentType: [
                    {required: true, message: '请选择付款方式', trigger: 'blur'},
                ],
                paymentTime: [
                    {required: true, message: '请选择付款时间', trigger: 'blur'},
                ],
                paymentNumber: [
                    {required: true, message: '请输入付款流水号', trigger: 'blur'},
                ],

            },
        }
    },
    components: {
        OrderLog,
        DictionarySelect
    },
    activated() {
        this.init();
    },
    filters: {
        filterMoney(val) {
            if (val && val >= 0) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return '0.00'
            }
        }
    },
    methods: {
        inputMatch(data,text) {
            eval(`${data} = ${data}.match(${text}) == null ? '' : ${data}.match(${text})[0];`);
        },
        paymentNumberInput() {
            this.$forceUpdate();
        },
        //初始化
        init() {
            this.activeName = 'detail';
            // this.initDictionarySelect();
            this.getVisaOrderDetails();
            this.getOrderLog();
        },

        //初始化字典组件
        initDictionarySelect() {
        },

        // 获取签证订单详情
        async getVisaOrderDetails() {
            try {
                this.detailLoading = true
                const res = await consumer_admin_visa_details({
                    id: this.$route.query.id,
                    orderNo: this.$route.query.orderNo
                })
                this.detailLoading = false
                this.orderDetail = res.datas;
                this.orderDetail.visaOrder.orderNo = this.$route.query.orderNo;
                this.visaOrder = this.orderDetail.visaOrder;
                this.visaCompanyInfo = this.orderDetail.visaCompanyInfo;
                this.visaCompanyBill = this.orderDetail.visaCompanyBill;
                if (this.visaCompanyBill.length != 0) {
                    this.salesPriceMin = this.visaCompanyBill[0].salesPrice;
                }
                this.visaCompanyBill.forEach((item,index) => {
                    this.salesPriceMin = Number(`${item.salesPrice}`) < this.salesPriceMin ? Number(`${item.salesPrice}`) : this.salesPriceMin;
                });
                this.remark = this.visaOrder.remark;
                let orderSourceText = await Dictionary.returnDictionary('tmc', 'visaOrderSource', this.orderDetail.visaOrder.orderSource);
                this.orderDetail.visaOrder.orderSourceText = orderSourceText.name;

                let paymentTypeText = await Dictionary.returnDictionary('tmc', 'paymentType', this.orderDetail.visaOrder.paymentType);
                this.orderDetail.visaOrder.paymentTypeText = paymentTypeText.name;

                this.files = [];
                if (this.visaOrder.files) {
                    this.files = JSON.parse(this.visaOrder.files);
                }


            } catch (error) {
                this.detailLoading = false
                this.$message({
                    type: "error",
                    message: "获取签证订单详情失败！"
                })
            } finally {
                console.log('日志报错的时候没有进来这里')
                this.detailLoading = false
            }
        },

        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    let params = {
                        "pageBean": {
                            "currentPage": "-1",
                            "pageSize": "-1"
                        },
                        "params": {
                            "bizKey": "visa"
                        },
                        "querys": [
                            {
                                "property": "bizId",
                                "operation": "EQUAL",
                                "value": this.$route.query.id,
                                "relation": "AND",
                                "group": "group1"
                            }
                        ]
                    }
                    return consumer_logManage_bizlog_pageList(params).then(res => {
                        // 组装组件需要的list
                        const list = _.chain(res.datas.list)
                            .map(function (m) {
                                // 替换名字
                                const o = {
                                    operator: m.operator,
                                    date: m.reqTime,
                                    type: m.bizType,
                                    detail: m.logDescribe
                                }
                                return o
                            }).value()
                        const d = {
                            entityList: list
                        }
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },

        // 返回
        handleBackClick() {
            history_tag_api.remove_tag_by_route({
                route: this.$route
            })
            this.$router.replace({
                name: "admin-visa-order-list"
            })
        },

        //跳转至编辑页
        handleEditClick() {
            this.$router.push({
                name: "admin-visa-create-order",
                query: {
                    type: 'edit',
                    id: this.$route.query.id
                }
            })
        },

        // 跳转至确认支付页
        handlePayClick() {
            this.$router.push({
                name: "admin-visa-create-order-pay",
                query: {
                    id: this.$route.query.id,
                    orderNo: this.$route.query.orderNo,
                    behalfCompanyId: this.orderDetail.companyId
                }
            })
        },

        //取消订单
        handleCancelClick() {
            this.$confirm("确定取消此签证订单？", "取消提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.loading = true;
                let params = {
                    id: this.$route.query.id,
                    orderNo: this.$route.query.orderNo,
                    status: 4
                };
                consumer_admin_visa_action(params).then((res) => {
                    this.$message({
                        type: 'success',
                        message: '取消订单成功！'
                    })
                    this.handleBackClick()
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                })
            }).catch(() => {
            })
        },

        //选中需要取消签证的客户
        userSelectionChange(list) {
            this.selectedUserList = list;
        },

        //取消签证
        onShowCancelDialog() {
            if (this.selectedUserList.length === 0) {
                this.$message({
                    type: "error",
                    message: "请先选择需要取消签证的客户"
                });
                return;
            }
            this.cancelForm.fine = 0;
            this.cancelForm.userName = '';
            this.selectedUserList.forEach((user, index) => {
                this.cancelForm.userName += user.userName;
                if (index < this.selectedUserList.length - 1) {
                    this.cancelForm.userName += '、'
                }
            })
            this.cancelS1DialogVisible = true;
        },

        //确认操作-显示弹框
        onShowConfirmDialog() {
            let confirmForm = {...this.visaOrder};
            confirmForm.paymentNumber = this.visaOrder.paymentNum;
            confirmForm.paymentType = confirmForm.paymentType || 'ALIPAY';
            confirmForm.paymentTime = confirmForm.paymentTime || moment().format('yyyy-MM-DD HH:mm:ss');
            this.confirmForm = confirmForm;
            console.log(this.confirmForm);
            this.confirmDialogVisible = true;
        },

        // 只能输入英文或数字
        handleNumInput(item, value) {
            // 使用正则表达式匹配字母和数字
            const reg = /^[a-zA-Z0-9]+$/;
            // 如果输入值不符合规定的正则表达式，则将inputValue重置为上一个值
            if (!reg.test(value)) {
                this.confirmForm[item] = this.confirmForm[item].replace(/[^\a-\z\A-\Z0-9]/g, '');
            }
        },

        //提交确定操作
        async confirmSubmit() {
            console.log(this.confirmForm);
            this.$refs['confirmForm'].validate(async (valid) => {
                if(valid) {
                    let params = {
                        id: this.$route.query.id,
                        orderNo: this.$route.query.orderNo,
                        status: 2,
                        supOrderNo: this.confirmForm.supOrderNo,
                        paymentType: this.confirmForm.paymentType || 0,
                        paymentTime: this.confirmForm.paymentTime,
                        paymentNum: this.confirmForm.paymentNumber,
                        remark: this.confirmForm.remark,
                    };
                    await consumer_admin_visa_action(params);
                    this.$message.success("保存成功");
                    this.confirmDialogVisible = false;
                    await this.init();
                }
            })
        },

        //跟进-判断该客户是否可选--不可变
        onProgressSelectableImmutable(val) {
            return val.selection;
        },


        //判断该客户是否可选
        onProgressSelectable(val) {
            if (val.status === 0) {
                return true;
            }
        },

        // 更新状态
        changeStatus(val) {
            this.visaBillList.forEach((item,index) => {
                if (val.id == item.id) {
                    item.status = val.status;
                }
            });
        },

        //跟进-显示弹框
        async onShowProgressDialog() {
            let res = await consumer_admin_visa_billDetails({id: this.$route.query.id});
            this.progressList = res.datas;
            this.progressList.forEach((item,index) => {
                this.$set(item, 'selection', item.status == 0);
            });
            this.progressDialogVisible = true;
        },

        //跟进-选择调整办理进度的客人
        progressSelectionChange(list) {
            this.visaBillList = [];
            this.visaBillIdList = [];
            if (list) {
                list.forEach(item => {
                    this.visaBillIdList.push(item.id);
                    this.visaBillList.push({
                        id: item.id,
                        status: item.status,
                        expectTime: item.expectTime
                    })
                })
            }
        },

        //跟进-保存跟进信息
        async progressSubmit() {
            try {
                if (this.visaBillList.length === 0) {
                    this.$message.error("请先选择需要跟进的客户");
                    return;
                }
                let params = {
                    orderId: this.$route.query.id,
                    remark: this.progressForm.remark || '',
                    visaBillList: this.visaBillList
                };
                await consumer_admin_visa_billAction(params);
                this.$message.success("保存成功");
                this.progressDialogVisible = false;
                await this.init();
            } catch (e) {
                this.progressDialogVisible = false;
            }
        },

        //取消签证s1-提交
        cancelS1Submit() {
            let salesPriceTotal = 0;
            this.cancelForm.ids = [];
            this.selectedUserList.forEach(user => {
                salesPriceTotal += user.salesPrice;
                this.cancelForm.ids.push(user.id);
            });
            if (this.cancelForm.fine === '' || this.cancelForm.fine === null) {
                this.$message.error('请输入罚金');
            } else {
                this.cancelForm.refundAmount = salesPriceTotal - (this.cancelForm.fine * this.selectedUserList.length);
                this.cancelS1DialogVisible = false;
                this.cancelS2DialogVisible = true;
            }
        },

        //取消签证s2-提交
        async cancelS2Submit() {
            try {
                let params = {
                    ids: this.cancelForm.ids,
                    fine: this.cancelForm.fine,
                    refundAmount: this.cancelForm.refundAmount
                }
                await consumer_admin_visa_cancelBill(params);
                this.$message({
                    type: "success",
                    message: '取消签证成功'
                })
                this.cancelS2DialogVisible = false;
                this.getVisaOrderDetails();
            } catch (e) {
                this.cancelS2DialogVisible = false;
                this.getVisaOrderDetails();
            }
        },

        //保存备注
        async saveRemark() {
            if (this.remark === '') {
                this.$message({
                    type: "warning",
                    message: '请输入备注'
                })
                return
            }
            this.detailLoading = true;
            this.orderDetail.visaOrder.remark = JSON.parse(JSON.stringify(this.remark));
            consumer_admin_visa_save(this.orderDetail).then(res => {
                this.detailLoading = false
                this.$message({
                    type: "success",
                    message: '提交成功'
                })
                this.remark = '';
                this.init()
            }, error => {
                this.detailLoading = false
            }).catch(() => {
                this.detailLoading = false
            })
        },
    }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ElOptionNode',
    props: {
        // 每一行的索引
        index: {
            type: Number,
            default: 0
        },
        // 每一行的内容
        source: {
            type: Object,
            default() {
                return {}
            }
        },
        // 需要显示的名称
        label: {
            type: String,
            default: ''
        },
        // 绑定的值
        value: {
            type: String,
            default: ''
        },
        // 是否拼接label | value
        isConcat: {
            type: Boolean,
            default: false
        },
        // 拼接label、value符号
        concatSymbol: {
            type: String,
            default: ' | '
        },
        // 右侧是否显示绑定的值
        isRight: {
            type: Boolean,
            default() {
                return false
            }
        },
        selectOptionWidth: {
            type: String,
            default: '350px'
        }
    },
    methods: {
        concatString(a, b) {
            a = a || ''
            b = b || ''
            if (this.isConcat) {
                return a + ((a && b) ? this.concatSymbol : '') + b
            }
            return a
        }
    }
}


/**
 *  arr转义字符
 */
function ArrayTransferred(arr = [], formObj = {}) {
  let _arr = arr, _formObj = formObj
  if (_arr.length > 0) {
    _arr.forEach(name => {
      if (Array.isArray(_formObj[name])) {
        _formObj[name] = _formObj[name].join(',')
      } else {
        console.error('非数组')
      }
    })
  }
}
/**
 *  字符转arr
 */
function StringTransferred(arr = [], formObj = {}) {
  let _arr = arr, _formObj = formObj
  if (_arr.length > 0) {
    _arr.forEach(name => {
      if (_formObj[name]) {
        if (_formObj[name].indexOf(',') === -1) {
          _formObj[name] = [_formObj[name]]
        } else {
          _formObj[name] = _formObj[name].split(',')
        }
      }
      else {
        _formObj[name] = []
      }
    })
  }
}
/**
 * 
 * @param {转换日期} val 
 */
function ChangeDateFormat(val) {
  if (val != null) {
    var datetime = new Date(
      // parseInt(val.replace("/Date(", "").replace(")/", ""), 10)
      val
    );
    //月份为0-11，所以+1，月份小于10时补个0
    var year = datetime.getFullYear();
    var month = datetime.getMonth() + 1; //js从0开始取
    var date = datetime.getDate();
    var hour = datetime.getHours();
    var minutes = datetime.getMinutes();
    var second = datetime.getSeconds();

    if (month < 10) {
      month = "0" + month;
    }
    if (date < 10) {
      date = "0" + date;
    }
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (second < 10) {
      second = "0" + second;
    }
    var time =
      year +
      "-" +
      month +
      "-" +
      date +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      second; //2015-11-4 14:11:41

    return time;
  }
  return "";
}

function getWeekDay(time) {
  try {
    var weekDay = ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    let day = new Date(Date.parse(time)).getDay()
    return weekDay[day]
  } catch{
    throw 'date error!'
  }

}
export {
  ArrayTransferred,
  StringTransferred,
  ChangeDateFormat,
  getWeekDay
}
import _ from 'underscore'
import moment from 'moment'
import history_tag_api from '@/component/admin-layout/component/history-tag/api'
import OrderLog from '@/component/orderLog/2.0.0/index.vue'

import consumer_admin_hotel_order_queryHotelOrderDetails
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_queryHotelOrderDetails"   // 平台酒店订单详情
import consumer_admin_hotel_order_orderDetailsForAdmin from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_orderDetailsForAdmin';
import consumer_admin_hotel_order_forHotelOrderDetails
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_forHotelOrderDetails"   // 获取酒店订单明细
import consumer_log_hotelList from "@/lib/data-service/haolv-default/consumer_log_hotelList"   // 获取酒店订单日志
import req_cancel_order from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_cancelOrder"   // 取消订单-酒店
import consumer_admin_hotel_order_hotelSaveOrderRemark
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_hotelSaveOrderRemark"
import ChangeService from "@/component/change-service/index.vue";   // 保存备注

export default {
    data() {
        return {
            detailLoading: false,
            loading: false,
            activeName: 'detail',
            orderSource: '1',
            orderDetail: {},
            orderPersonList: [],
            costDetail: {},
            refundRule: [],
            reportConfigurationValueList: [],//动态表单内容
            form: {
                orderNo: '',
                hotelRemark: ''
            },

            moment: moment,

            refundRuleTypeObj: {
                1: '不可退',
                2: '限时取消',
            },
            deductTypeObj: {
                0: '扣首晚房费',
                1: '扣全额',
            }
        }
    },
    components: {
        ChangeService,
        OrderLog
    },
    activated() {
        this.form.orderNo = this.$route.query.id;
        this.orderSource = this.$route.query.orderSource ? this.$route.query.orderSource : '1';
        this.getHotelOrderDetails()
        this.getOrderLog()
    },
    filters: {
        filterMoney(val) {
            if (val || val === 0) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return '0.00'
            }
        }
    },
    methods: {
        // 平台酒店订单详情
        async getHotelOrderDetails() {
            try {
                this.detailLoading = true
                const res = await consumer_admin_hotel_order_orderDetailsForAdmin({
                    orderNo: this.$route.query.id
                })

                this.orderDetail = res.orderInterimResult
                this.orderPersonList = res.hotelOrderPersonList
                this.costDetail = res.hotelCostInfoResponse
                this.orderDetail.hotelGroup = res.hotelGroup;
                this.orderDetail.ifAgreement = res.ifAgreement;
                this.orderDetail.cardNumber = res.cardNumber;
                this.orderDetail.agreementHotelText = res.agreementHotelText;
                this.orderDetail.hotelPhone = this.orderDetail.hotelPhone ? this.orderDetail.hotelPhone.replaceAll(',', ',<br/>') : '';
                this.refundRule = res.orderInterimResult.refund ? [res.orderInterimResult.refund] : [];
                this.form.hotelRemark = this.orderDetail.hotelRemark;
                this.reportConfigurationValueList = this.orderDetail.reportConfigurationValueList?this.orderDetail.reportConfigurationValueList:[];
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "获取酒店订单详情失败！"
                })
            } finally {
                this.detailLoading = false
            }
        },
        // 获取酒店订单明细
        async getSupplierDetail() {
            try {
                this.detailLoading = true
                const res = await consumer_admin_hotel_order_forHotelOrderDetails({
                    orderNo: this.orderDetail.thirdpartyOrderNo
                })
                this.orderDetail.orderFromTypeText = res.datas.supplierName
                this.orderDetail.supplierOrderNo = res.datas.supplierOrder
                this.orderDetail.supplierPrice = res.datas.supplierPrice
                this.orderDetail.settlementText = res.datas.settlement
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "获取酒店订单明细失败！"
                })
            } finally {
                this.detailLoading = false
            }
        },
        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    return consumer_log_hotelList({
                        orderNo: this.$route.query.id
                    }).then(res => {
                        // 组装组件需要的list
                        const list = _.chain(res.datas)
                            .map(function (m) {
                                // 替换名字
                                const o = {
                                    operator: m.operator,
                                    date: m.operatorTime,
                                    type: m.logSource,
                                    detail: m.logContents
                                }
                                return o
                            }).value()
                        const d = {
                            entityList: list
                        }
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },
        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
            switch (val) {
                case (1):
                    return 'type2'
                case (2):
                    return 'type1'
                default:
                    return ''
            }
        },
        // 订单状态
        getOrderStatusToClass(val) {
            switch (val) {
                case (23):
                    return 'status2'
                case (22):
                    return 'status1'
                default:
                    return 'status3'
            }
        },
        // 去支付
        handlePayClick(val) {
            // 判断是线上还是线下
            if (val.orderType !== 3) {
                this.$router.push({
                    name: "admin-hotel-payment",
                    query: {
                        orderNo: val.orderNo,
                        orderType: 3,
                        pageType: 2
                    }
                })
            } else {
                // 线下单支付
                this.$router.push({
                    name: "admin-hotel-create-order-pay",
                    query: {
                        orderNo: val.orderNo,
                        behalfCompanyId: val.companyId
                    }
                })
            }
        },
        // 取消订单
        handleCancelClick(val) {
            this.$confirm("是否确认取消订单?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                req_cancel_order({
                    orderNo: val.orderNo,
                    cancelRemark: ""
                }).then((res) => {
                    this.$message({
                        type: 'success',
                        message: '取消订单成功！'
                    })
                    this.handleBackClick()
                }).catch(() => {
                })

            }).catch(() => {
            })
        },
        // 返回
        handleBackClick() {
            history_tag_api.remove_tag_by_route({
                route: this.$route
            })
            this.$router.replace({
                name: "admin-hotel-order-sold"
            })
        },
        // 查看取消订单详情
        handleCancelDetailClick(val) {
            this.$router.push({
                name: "admin-hotel-order-cancel-detail",
                query: {
                    id: val.orderNo
                }
            })
        },
        //退订
        handleRefundClick(val) {
            this.$router.push({
                name: "admin-hotel-order-refund",
                query: {
                    orderNo: val.orderNo
                }
            })
        },
        //线下退订
        handleOfflineRefundClick(val) {
            this.$router.push({
                name: "admin-hotel-order-offline-cancel-handle",
                query: {
                    orderNo: val.orderNo
                }
            })
        },
        // 判断表格是否合并
        isColspanTable(val) {
            let colspan = 0;
            let same = true;
            let platformPrice = 0;
            val.forEach((value, index)=>{
                if (index == 0) {
                    platformPrice = value.platformPrice;
                }
                if (platformPrice !== value.platformPrice) {
                    same = false;
                }
            })
            colspan = same ? 1 : val.length;
            return colspan;
        },

        //保存备注
        async saveRemark() {
            if (this.form.hotelRemark === '') {
                this.$message({
                    type: "warning",
                    message: '请输入备注'
                })
                return
            }
            this.detailLoading = true;
            consumer_admin_hotel_order_hotelSaveOrderRemark(this.form).then(res => {
                this.detailLoading = false
                this.$message({
                    type: "success",
                    message: '提交成功'
                })
                this.form.hotelRemark = ''
                this.getHotelOrderDetails()
                this.getOrderLog()
            }, error => {
                this.detailLoading = false
            }).catch(() => {
                this.detailLoading = false
            })
        },
        // 调整服务费
        changeService() {
            let params = {
                businessType: 3,
                orderNo: this.$route.query.id,
                oldService: this.costDetail.serviceFee,
            };
            this.$refs.changeService.init(params).then(res=>{
                this.getHotelOrderDetails();
            }).catch((e)=>{})
        },
    }
}

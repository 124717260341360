export default {
    components: {},
    data() {
        return {};
    },
    methods: {
        __run_mocha() {
            const __this = this;
            const mocha = global.mocha;
            const expect = global.expect;

            document.querySelector(`#mocha`).innerHTML = ``;
            mocha.setup({
                ui: 'bdd',
                timeout: 10000,
            });
            //需要在执行完mocha.setup后，才有这个全局对象
            const describe = global.describe;

            //可以多次mocha.run
            //参考：https://github.com/mochajs/mocha/pull/4234
            mocha._cleanReferencesAfterRun = false;

            if (mocha.suite.suites.length <= 0) {
                describe(`sample test case`, function () {
                    it('1+1=2', function (done) {
                        const actual_value = 1 + 1;
                        const expect_value = 2;
                        expect(actual_value).to.eql(expect_value);
                        done();
                    });
                });
            }

            mocha.run();
        },
    },
    created() {
        // const __this = this;
    },
    activated() {
        const __this = this;
        __this.__run_mocha();
    },
    deactivated() {
        // const __this = this;
    },
}

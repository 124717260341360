export default {
    order: '订购业务信息',
    companyInfo: '客户基础信息',
    companyBills: '客户信息',
    flightInfos: '行程信息',

    contactName: '联系人',
    contactMobile: '联系电话',
    companyId: '所属企业',
    evectionType: '出行类型',
    payType: '支付方式',
    bizType: '业务类型',
    bizName: '产品名称',
    orderSource: '采购渠道',
    paymentType: '付款方式',
    paymentTime: '付款时间',
    paymentNum: '付款流水号',
    airlineName: '航司名称',
    flightNumber: '航班号',
    depAirportCityData: '出发城市',
    depTerminal: '出发航站楼',
    arrAirportCityData: '到达城市',
    arrTerminal: '到达航站楼',
    depTime: '起飞时间',
    arrTime: '到达时间',
    airlineGrade: '舱位等级',
    userName: '客户中文姓名',
    englishName: '客户英文姓名',
    companyType: '乘客类型',
    certType: '证件类型',
    certNum: '证件号',
    ticketNumber: '票号',
    seatNumber: '座位号/确认号',
    salesPrice: '销售价',
    supPrice: '采购价',
    serviceFee: '服务费',
    profit: '利润',
}
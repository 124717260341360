export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/component-custom-slot',
            name: 'admin-component-custom-slot',
            component: () => import(/* webpackChunkName: "page-admin-default-component-custom-slot" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `主页`,
                },
                entity_list_for_breadcrumb: [
                    {name: `示例功能`},
                    {name: `主页`},
                ],
            },
        },
    ],
};

import Q from 'q';
import _ from 'underscore';
import HotelCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-city-selector/1.1.0/index.vue';

export default {
    components: {
        HotelCitySelector,
    },
    data() {
        return {
            selected_entity_aHotelCitySelector: null,
            // selected_entity_aHotelCitySelector: {
            //     "cityId": "70008",
            //     "cityCn": "中山",
            //     "cityPinyin": "Zhongshan",
            //     "cityPinyinSimp": "ZS"
            // },
        };
    },
    methods: {
        __btn_get_value_click() {
            const __this = this;
            console.log(__this.selected_entity_aHotelCitySelector);
        },

        __btn_set_value_click() {
            const __this = this;
            //这样赋值是不可以的，因为监听的是对象！
            // __this.selected_entity_aHotelCitySelector.cityId = `70008`;
            //这样赋值才可以！
            __this.selected_entity_aHotelCitySelector = {
                "cityId": "70008",
                // "cityCn": "中山",
                // "cityPinyin": "Zhongshan",
                // "cityPinyinSimp": "ZS"
            };
        },

        __btn_reset_value_click() {
            const __this = this;
            //这样赋值是不可以的，因为监听的是对象！
            // __this.selected_entity_aHotelCitySelector.cityId = ``;
            //这样赋值才可以！
            __this.selected_entity_aHotelCitySelector = {
                'cityId': '',
            };
            // __this.selected_entity_aHotelCitySelector = null;
        },

        __btn_set_entity_click() {
            const __this = this;
            __this.$refs.aHotelCitySelector.set_entity({
                type: `000`,
                type_parameter: {
                    '000': {
                        name: `深圳`,
                    },
                }
            });
        },
    },
    watch: {
        selected_entity_aHotelCitySelector(pNewValue, pOldValue) {
            const __this = this;
            // console.log(`pNewValue=`);
            // console.log(pNewValue);
            // console.log(`pOldValue=`);
            // console.log(pOldValue);
        },
    },
    created() {
        const __this = this;
    },
    mounted() {
        const __this = this;
        // 在created这样赋值，不会进入到BaseEntitySelector的watch value处理方法中，
        // 在mounted这样赋值，可以进入到BaseEntitySelector的watch value处理方法中
        // __this.selected_entity_aHotelCitySelector = {
        //     "cityId": "70008",
        //     "cityCn": "中山",
        //     "cityPinyin": "Zhongshan",
        //     "cityPinyinSimp": "ZS"
        // };
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}

import Q from 'q';
import {awaitWrap} from "@/page/admin/organizational-structure/common/unit";
import Pagination from "@/page/admin/organizational-structure/components/pagination/index.vue";
import AccountProcessStep0101
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/12306-account-process-step/12306-account-process-step-0101/1.1.0/index.vue';
import AccountProcessStep0102
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/12306-account-process-step/12306-account-process-step-0102/1.1.0/index.vue'
import consumer_trains_account_getServiceTrainsAccount
    from "@/lib/data-service/haolv-default/consumer_trains_account_getServiceTrainsAccount";//获取12306账号列表
import consumer_trains_account_deleteAccountByAdmin
    from "@/lib/data-service/haolv-default/consumer_trains_account_deleteAccountByAdmin";//删除12306账号
import TrainTravelerSelectorForPlatform
  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-traveler-selector-for-platform/1.0.0/index.vue'

export default {
    data() {
        return {
            loading: false,
            params: {
                account: '',
                name: '',
                total: 0,
                pageSize: 10,
                currentPage: 1
            },
            accountList: [],
        };
    },
    components: {
        Pagination,
        AccountProcessStep0101,
        AccountProcessStep0102,
        TrainTravelerSelectorForPlatform,
    },
    async activated() {
        this.init();
    },
    methods: {
        //初始化
        async init() {
            await this.getList();
        },

        // 搜索
        search() {
            this.params.currentPage = 1;
            this.getList();
        },

        //获取列表
        async getList() {
            this.loading = true;
            let [err, res] = await awaitWrap(consumer_trains_account_getServiceTrainsAccount(this.params))
            if (err) {
                this.loading = false;
                return
            }
            this.accountList = res.datas.list;
            this.params.currentPage = res.datas.currPage;
            this.params.total = res.datas.totalCount;
            this.loading = false;
        },

        //新增账号
        addAccount() {
            this.initAccountProcessStep0101();
            this.$refs.aAccountProcessStep0101.show();
        },

        //新增账号组件回调函数
        async loginResult(val) {
            if (val.accountStatus == 1) {
                this.getList();
            } else if (val.accountStatus == 2) {
                this.initAccountProcessStep0102(val);
                this.$refs.aAccountProcessStep0102.show()
            } else if (val.accountStatus == 3) {
                this.$message({
                    type: 'warning',
                    message: val.accountStatusText
                })
                this.$refs.aAccountProcessStep0101.show()
            }
        },

        //重新授权
        redelegation(account) {
            this.initAccountProcessStep0101(account);
            this.$refs.aAccountProcessStep0101.show();
        },

        //重新授权组件回调函数
        checkResult(val) {
            if (val.datas.code == '000000') {
                this.getList();
            } else {
                this.$message({
                    type: 'warning',
                    message: '验证码错误'
                })
            }
        },

        //初始化组件AccountProcessStep0101
        initAccountProcessStep0101(account) {
            this.$refs.aAccountProcessStep0101.init({
                situation: '100',
                getParams() {
                    const params = {
                        form: {
                            account: account ? account.account : '',//账号
                            password: '',//密码
                            accountId: account ? account.accountId : '',//id
                            accountType: account ? account.status : '',//类型
                        }
                    }
                    return Q.resolve(params);
                },
            });
        },

        //初始化组件AccountProcessStep0102
        initAccountProcessStep0102(account) {
            let accountInfo = account;
            this.$set(accountInfo, 'mobileNo', account.phone)
            this.$refs.aAccountProcessStep0102.init({
                situation: '100',
                getParams() {
                    const params = {
                        id: account.accountId,

                    }
                    return Q.resolve(params);
                },
                accountInfo: accountInfo, // 如果是平台端，则需要传accountInfo
            })
        },

        // 乘车人管理
        passengersClick(item) {
            this.$refs.aTrainTravelerSelectorForPlatform.accountInfo = item;
            this.$refs.aTrainTravelerSelectorForPlatform.typeBox = 'manage';
            this.$refs.aTrainTravelerSelectorForPlatform.params.mock_data = {
                enable: false,
                test_case: '001'
            };
            this.$refs.aTrainTravelerSelectorForPlatform._submitAccount();
        },

        //删除账号
        delAccount(account) {
            this.$confirm("是否确认删除" + account.name + "这个账号?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                consumer_trains_account_deleteAccountByAdmin({accountId: account.accountId}).then(res => {
                    this.$alert('删除成功', '提示', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.getList();
                        }
                    });
                })
            })
        }
    },
};

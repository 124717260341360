export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/hotel-order/non-rule-cancel-list',
            name: 'admin-hotel-order-non-rule-cancel-list',
            component: () => import(/* webpackChunkName: "page-admin-order-management-hotel-non-rule-cancel-list" */ `./1.0.1/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `线下退订申请列表`,
                },
                entity_list_for_breadcrumb: [
                    { name: `酒店订单` },
                    { name: `线下退订申请列表` },
                ],
            },
        },
    ],
};

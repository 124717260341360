import consumer_admin_flight_change_newDetails from '@/lib/data-service/haolv-default/consumer_admin_flight_change_newDetails'
import consumer_admin_flight_change_remarkChange from '@/lib/data-service/haolv-default/consumer_admin_flight_change_remarkChange'
import consumer_air_ticket_fligthRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules"
import consumer_admin_flight_chang_logContents from "@/lib/data-service/haolv-default/consumer_admin_flight_chang_logContents"
import consumer_admin_insurance_refundInsuranceOrder from '@/lib/data-service/haolv-default/consumer_admin_insurance_refundInsuranceOrder'
import consumer_admin_insurance_orderInsuranceAgain from '@/lib/data-service/haolv-default/consumer_admin_insurance_orderInsuranceAgain'
import consumer_admin_insurance_tradeRefundInsuranceOrder from '@/lib/data-service/haolv-default/consumer_admin_insurance_tradeRefundInsuranceOrder'
import consumer_admin_insurance_checkAllowRefundInsurance from '@/lib/data-service/haolv-default/consumer_admin_insurance_checkAllowRefundInsurance'
import consumer_insurance_getEInsuranceOrderUrl from '@/lib/data-service/haolv-default/consumer_insurance_getEInsuranceOrderUrl'
import consumer_admin_flight_equity_getOrderProductInfo from '@/lib/data-service/haolv-default/consumer_admin_flight_equity_getOrderProductInfo'
import ChangeClausePopup from '@/page/admin/replace-orders/component/changeClausePopup/1.0.0/index.vue'
import OrderLog from '@/component/orderLog/2.0.0/index.vue'
export default {
    data() {
        return {
            orderProductInfo: {},
            dialogEnjoyMore: false,
            activeName: 'detail',
            sourceType: 1, // 1国内2国际
            detailLoading: false,
            loading: false,
            chaOrderNo: '',
            orderNo: '',
            detail: {},
            form: {
                remark: ''
            },
            ruleText: {},
            ruleLoading: false,
            ruleShow: false, // 退改签说明弹窗
        }
    },
    components: {OrderLog, ChangeClausePopup},
    created() {
    },
    mounted() {
    },
    activated() {
        this.activeName = 'detail'
        this.chaOrderNo = this.$route.query.chaOrderNo
        this.orderNo = this.$route.query.orderNo
        this.getDetail()
        this.getOrderLog()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterCabinRank(val) {
            if (val === 1) {
                return '头等舱'
            } else if (val === 2) {
                return '商务舱'
            } else if (val === 3) {
                return '经济舱'
            } else {
                return ''
            }
        },
        filterMoney(val) {
            if (val) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return ''
            }
        },
    },
    methods: {
        clickEnjoyMore() {
            consumer_admin_flight_equity_getOrderProductInfo({orderNo: this.orderNo}).then((res) => {
                this.orderProductInfo = res.datas;
            });
            this.dialogEnjoyMore = true;
        },
        openUrl(val) {
            window.open(val.url);
        },
        getDetail() {
            this.detailLoading = true
            consumer_admin_flight_change_newDetails({chaOrderNo: this.chaOrderNo}).then(res => {
                let detail = res.orderDetail
                detail.originalPayment.forEach(value => {
                    if (value.sourceType) {
                        this.sourceType = value.sourceType
                    }
                })
                let urlList = detail.url.split(',');
                let urlDettailList = [];
                urlList.forEach(value=>{
                    const nameArr = value.split('/');
                    const name = nameArr[nameArr.length - 1];
                    urlDettailList.push({
                        name: name,
                        url: value,
                    })
                })
                detail.urlDettailList = urlDettailList;
                this.detail = detail
                this.form.remark = detail.remarks
                this.detailLoading = false
            }).catch(() => {
                this.detailLoading = false
            })
        },
        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    //调用获取订单日志的接口
                    return consumer_admin_flight_chang_logContents({
                        changeOrderNo: this.chaOrderNo
                    }).then(res => {
                    // 组装组件需要的list
                    const list = res.datas.map(m => {
                        // 替换名字
                        const o = {
                            operator: m.operator,
                            date: m.operatorTime,
                            type: m.logSource,
                            detail: m.logContents
                        }
                        return o
                    })
                    const d = {
                        entityList: list
                    }
                    this.loading = false //临时使用
                    return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },
        // 退票退改规则
        showRefundChangeClause(val) {
            this.ruleText = {}
            this.ruleShow = true
            let data = {
                segmentNo: val.segmentNo
            }
            this.ruleLoading = true
            consumer_air_ticket_fligthRules(data).then(res => {
                this.ruleLoading = false
                this.ruleText = res.datas.rsData
            }).catch(() => {
                this.ruleLoading = false
            })
        },
        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
            switch (val) {
                case (1):
                    return 'type2'
                case (2):
                    return 'type1'
                default: return ''
            }
        },
        submit() {
            if (this.form.remark === '') {
                this.$message({
                    type: "warning",
                    message: '请输入备注'
                })
                return
            }
            let form = JSON.parse(JSON.stringify(this.form))
            form.chaOrderNo = this.chaOrderNo
            form.orderNo = this.orderNo
            this.detailLoading = true
            consumer_admin_flight_change_remarkChange(form).then(res => {
                this.detailLoading = false
                this.$message({
                    type: "success",
                    message: '提交成功'
                })
                this.form.remark = ''
                this.getDetail();
                this.getOrderLog();
            }, error => {
                this.detailLoading = false
            }).catch(() => {
                this.detailLoading = false
            })
        },
        back() {
            //this.$router.go(-1)
            this.$router.push({
                name: 'admin-flight-change-list'
            })
        },
        async refundInsurance(val) {
            const params = {
                insuranceOrderId: val.insuranceOrderId
            }
            let [err, res] = await awaitWrap(consumer_admin_insurance_checkAllowRefundInsurance(params))
            if (err) {
                this.$message({
                    type: "warning",
                    message: '保险已生效,无法退保'
                })
                return
            }
            this.$msgbox({
                title: '确认退保',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                this.detailLoading = true;
                let data = {
                    insuranceOrderId: params.insuranceOrderId,
                    orderNo: this.orderNo,
                };
                consumer_admin_insurance_refundInsuranceOrder(data).then(res => {
                    this.$message({
                        type: "success",
                        message: '退保成功'
                    })
                    this.getDetail()
                })
            }).finally(() => {
                this.detailLoading = false
            });
        },
        reactInsurance(val) {
            const h = this.$createElement;
            this.$msgbox({
                title: '确认投保',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                const params = {
                    businessType: 1,   // 业务类型
                    userId: val.userId,
                    productCode: val.productCode,
                    orderNo: this.orderNo,
                    chaOrderNo: this.chaOrderNo,
                    segmentNo: val.segmentNo,
                };
                this.detailLoading = true;
                consumer_admin_insurance_orderInsuranceAgain(params).then(res => {
                    this.$message({
                        type: "success",
                        message: '投保成功'
                    });
                    this.getDetail()
                })
            }).finally(() => {
                this.detailLoading = false
            });
        },
        refundMoney(val) {
            const h = this.$createElement;
            this.$msgbox({
                title: '确认退款',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                const params = {
                    orderNo: this.orderNo,
                    productCode: val.productCode,
                    userId: val.userId
                }
                this.detailLoading = true
                consumer_admin_insurance_tradeRefundInsuranceOrder(params).then(res => {
                    this.$message({
                        type: "success",
                        message: '退款成功'
                    })
                    this.getDetail()
                })
            }).finally(() => {
                this.detailLoading = false
            });
        },
        checkInsuranceDetail(val) {
            this.detailLoading = true
            const params = {
                insuranceOrderId: val.insuranceOrderId
            }
            consumer_insurance_getEInsuranceOrderUrl(params).then(res => {
                const url = res.datas.epolicyUrl
                if (url) {
                    window.open(url)
                }
            }).finally(() => {
                this.detailLoading = false
            })
        },
    }
}

import _ from 'underscore'
import moment from 'moment'
import OrderLog from '@/component/orderLog/2.0.0/index.vue'

import consumer_admin_flight_generate_psgType
    from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_psgType'
import consumer_admin_flight_generate_certificateType
    from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType'
import consumer_intention_order_orderDetail from "@/lib/data-service/haolv-default/consumer_intention_order_orderDetail"   // 获取意向单详情
import consumer_intention_order_intentionList
    from "@/lib/data-service/haolv-default/consumer_intention_order_intentionList"// 获取意向单日志
import consumer_intention_order_orderSave from "@/lib/data-service/haolv-default/consumer_intention_order_orderSave"
import consumer_intention_order_saveOrderRemark
    from "@/lib/data-service/haolv-default/consumer_intention_order_saveOrderRemark"
import consumer_intention_order_deleteOrder
    from "@/lib/data-service/haolv-default/consumer_intention_order_deleteOrder";

export default {
    data() {
        return {
            detailLoading: false,
            loading: false,
            isShowCancelDialog: false,//是否显示取消弹框
            isDisabled: false,
            activeName: 'detail',
            orderDetail: {
                touristList: [{
                    touristName: '',
                }],
                intentionMsgList: [],
            },
            form: {
                amountPaid: '',//已付金额
                refundedAmount: '',
                touristList: [{
                    touristName: '',
                }],
                intentionMsgList: [],
                procurementChannel: 1
            },
            remarkForm: {
                orderNo: '',
                remark: ''
            },
            cancelForm: {
                orderNo: '',
                cancellationReason: '',//取消原因
            },

            moment: moment,

            travelerTypeList: [],//游客类型list
            certificateTypeList: [],
            ticketTypeList: [
                {
                    code: 1,
                    msg: '成人票'
                },
                {
                    code: 2,
                    msg: '儿童票'
                },
                {
                    code: 3,
                    msg: '学生票'
                },
                {
                    code: 4,
                    msg: '长者票'
                },
            ],//门票类型list
            procurementChannelList: [],
            rules: {
                touristName: [
                    {required: true, message: '请输入游客姓名', trigger: 'blur'}
                ],
                touristTypeText: [
                    {required: true, message: '请选择乘客类型', trigger: 'change'}
                ],
                cardType: [
                    {required: true, message: '请选择证件类型', trigger: 'change'}
                ],
                procurePrice: [
                    {required: true, message: '请输入采购单价', trigger: 'blur'}
                ],
                salePrice: [
                    {required: true, message: '请输入销售单价', trigger: 'blur'}
                ],
                intentionNum: [
                    {required: true, message: '请输入数量', trigger: 'blur'}
                ],
                amountPaid: [
                    {required: true, message: '请输入已付金额', trigger: 'blur'}
                ],
                // invoiceValue: [
                //     {required: true, message: '请输入开票金额', trigger: 'blur'}
                // ],
                refundedAmount: [
                    {required: true, message: '请输入退款金额', trigger: 'blur'}
                ],
                cancellationReason: [
                    {required: true, message: '请输入取消原因', trigger: 'blur'}
                ]
            }
        }
    },
    computed: {
        totalPrice() {
            let total = 0;
            if (this.form.intentionMsgList && this.form.intentionMsgList.length > 0) {
                this.form.intentionMsgList.forEach(ticket => {
                    total += ticket.salePrice * ticket.intentionNum;
                })
            }
            this.form.amountPaid = total === 0 ? '' : total;
            return total;
        }
    },
    components: {
        OrderLog
    },
    activated() {
        this.init();
    },
    methods: {
        //初始化
        async init() {
            this.orderNo = this.$route.query.orderNo;
            this.form.orderNo = this.orderNo;
            this.remarkForm.orderNo = this.orderNo;
            this.cancelForm.orderNo = this.orderNo;
            await this.getTravelerType();
            await this.getCertificateType();
            await this.getDetails()
            await this.getOrderLog()
        },

        // 获取详情
        async getDetails() {
            try {
                this.detailLoading = true
                const res = await consumer_intention_order_orderDetail({
                    orderNo: this.orderNo
                })
                this.orderDetail = res.datas;
                let {
                    productId,
                    totalPrice,//总价
                    amountPaid,//已付金额
                    refundedAmount,
                    touristList,
                    intentionMsgList,
                    status,
                } = this.orderDetail;
                this.totalPrice = totalPrice ? totalPrice : 0;
                this.form.amountPaid = amountPaid ? amountPaid : '';
                this.form.refundedAmount = refundedAmount ? refundedAmount : '';
                this.form.touristList = touristList;
                this.form.touristList.forEach(item => {
                    if (Number(item.touristType) !== 1 && Number(item.touristType) !== 2) {
                        item.touristType = 1;
                        item.touristTypeText = '成人';
                    }
                })
                intentionMsgList.forEach(item => {
                    if(!item.procurePrice) {
                        item.procurePrice = 0;
                    }
                    if(!item.salePrice) {
                        item.salePrice = 0;
                    }
                });
                this.form.intentionMsgList = intentionMsgList;
                this.isDisabled = status === 1 ? false : true;
                let defChannelText = Number(productId) === 1 ? '北京中盛汇达文化旅游有限公司（环球乐享）' : '珠海骏通国际旅行社有限公司';
                this.procurementChannelList = [{
                    code: 1,
                    msg: defChannelText
                }]
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "获取意向单详情失败！"
                })
            } finally {
                this.detailLoading = false
            }
        },

        // 获取日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    return consumer_intention_order_intentionList({
                        orderNo: this.orderNo
                    }).then(res => {
                        // 组装组件需要的list
                        const list = _.chain(res.datas)
                            .map(function (m) {
                                // 替换名字
                                const o = {
                                    operator: m.operator,
                                    date: m.operatorTime,
                                    type: m.logSource,
                                    detail: m.logContents
                                }
                                return o
                            }).value()
                        const d = {
                            entityList: list
                        }
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },

        //获取游客类型
        getTravelerType() {
            consumer_admin_flight_generate_psgType().then(res => {
                this.travelerTypeList = res.datas
            })
        },

        //获取证件类型list
        getCertificateType() {
            consumer_admin_flight_generate_certificateType().then(res => {
                this.certificateTypeList = res.datas
            })
        },

        // 订单状态
        getOrderStatusToClass(val) {
            switch (val) {
                case (1):
                    return 'yellow'
                case (2):
                    return 'green'
                default:
                    return 'grey'
            }
        },

        //新增游客
        addTraveler() {
            let traveler = {
                touristName: '',
                touristType: 1,
                touristTypeText: '成人',
                cardType: 1,
                cardNo: '',
            };
            this.form.touristList.push(traveler);
        },

        //新增门票
        addTicket() {
            let ticket = {
                admissionTicketType: 1,
                procurePrice: 0,
                salePrice: 0,
                intentionNum: 1
            };
            this.form.intentionMsgList.push(ticket);
        },

        //删除游客
        delTraveler(index) {
            this.form.touristList.splice(index, 1);
        },

        //删除门票信息
        delTicket(index) {
            this.form.intentionMsgList.splice(index, 1);
        },

        //修改游客类型
        changeTravelerType(item, index, val) {
            this.travelerTypeList.forEach(value => {
                if (value.value === val) {
                    item.touristTypeText = value.msg
                }
            })
        },

        //修改游客证件类型
        changeCertificateType(item, index, val) {
            this.certificateTypeList.forEach(value => {
                if (value.value === val) {
                    item.cardTypeText = value.msg
                }
            })
        },

        //修改门票类型
        changeTicketType(item) {
            switch (item.admissionTicketType) {
                case 1:
                    item.admissionTicketTypeText = '成人';
                    break;
                case 2:
                    item.admissionTicketTypeText = '儿童';
                    break;
                case 3:
                    item.admissionTicketTypeText = '学生';
                    break;
                case 4:
                    item.admissionTicketTypeText = '长者';
                    break;
            }
        },

        //检验证件号格式
        cardNoValidator(rule, value, callback, index, item) {
            let cardType = item.cardType;
            const date = `${value.substr(6, 4)}-${value.substr(10, 2)}-${value.substr(12, 2)}`;
            if (cardType === 1) {
                if (this.isChineseIDCardNumber(value) === false) {
                    callback(new Error('请输入正确的身份证号码'));
                    return;
                } else {
                    this.$set(item, 'birthday', date);
                    callback();
                }
            } else {
                callback();
            }
        },

        //身份证格式是否正确
        isChineseIDCardNumber(value) {
            const testRegExp = /^[1-9]([0-9]{14}|[0-9]{16}[0-9Xx])$/;
            const areaMap = [11, 12, 13, 14, 15, 21, 22, 23, 31, 32, 33, 34, 35, 36, 37, 41, 42, 43, 44, 45, 46, 50, 51, 52, 53, 54, 61, 62, 63, 64, 65, 71, 81, 82];
            const weightMap = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            const codeMap = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
            const len = value.length;
            if (len !== 18) {
                return false;
            }
            // 模式校验
            if (!testRegExp.test(value)) {
                return false;
            }
            // 地区校验
            if (areaMap.indexOf(+value.substr(0, 2)) === -1) {
                return false;
            }
            // 18 位
            if (!this.isValidDate(+value.substr(6, 4), +value.substr(10, 2), +value.substr(12, 2))) {
                return false;
            }
            // 校验码
            const sum = value.split('').slice(0, 17).reduce((s, num, index) => {
                return s += +num * weightMap[index];
            }, 0);
            return codeMap[sum % 11] === value[17].toUpperCase();
        },

        // 身份证号码验证
        isValidDate(year, month, day) {
            const date = new Date(year, month - 1, day);
            return (
                date.getFullYear() === year
                && date.getMonth() + 1 === month
                && date.getDate() === day
                && date.getTime() < new Date().getTime()
            );
        },

        //修改已付金额时，默认开票金额与已付金额一致
        // changeAmountPaid() {
        //     this.form.invoiceValue = this.form.amountPaid;
        // },

        //取消订单
        onCancel() {
            consumer_intention_order_deleteOrder(this.cancelForm)
                .then(res => {
                    this.$message({
                        type: "success",
                        message: '取消成功'
                    })
                    this.isShowCancelDialog = false;
                    this.onBack();
                }).catch(() => {
                this.isShowCancelDialog = false;
            })
        },

        //保存信息
        async onSave() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if(this.form.touristList.length === 0) {
                        this.$message({
                            type: "warning",
                            message: '请填写游客信息'
                        })
                        return;
                    }

                    const types = this.form.intentionMsgList.map(value => value.admissionTicketType);
                    // 通过Set去除门票数组重复项
                    const typesSet = new Set(types);
                    // 比较两个数组的长度来判断是否存在重复项
                    if ([...typesSet].length !== types.length) {
                        this.$message({
                            type: "warning",
                            message: '门票类型不能重复'
                        })
                        return;
                    }


                    let emptyTicketInfo = this.form.intentionMsgList.find(ticket => {
                        if (ticket.procurePrice === 0 || ticket.salesPrice === 0 || ticket.intentionNum === 0) {
                            return ticket;
                        }
                    })
                    if (emptyTicketInfo) {
                        this.$message({
                            type: "warning",
                            message: '请完善门票信息'
                        })
                        return;
                    }

                    // let isOk = Number(this.form.amountPaid) === 0 ? false : true;
                    // if (!isOk) {
                    //     this.$message({
                    //         type: "warning",
                    //         message: '请填写已付金额'
                    //     })
                    //     return;
                    // }

                    if (Number(this.form.amountPaid) === 0) {
                        this.form.amountPaid = '';
                    }

                    this.detailLoading = true;
                    consumer_intention_order_orderSave(this.form)
                        .then(res => {
                            if (this.remarkForm.remark) {
                                consumer_intention_order_saveOrderRemark(this.remarkForm)
                            }
                            this.detailLoading = false
                            this.$message({
                                type: "success",
                                message: '提交成功'
                            })
                            this.onBack();
                        }, error => {
                            this.detailLoading = false
                        }).catch(() => {
                        this.detailLoading = false
                    })
                }
            })
        },

        //保存备注
        saveRemark() {
            consumer_intention_order_saveOrderRemark(this.remarkForm)
                .then(res => {
                    this.$message({
                        type: "success",
                        message: '保存备注成功！'
                    });
                    this.getOrderLog();
                })
        },

        //返回
        onBack() {
            this.$router.push({
                name: 'admin-intention-list'
            })
        }
    }
}

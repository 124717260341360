import _ from 'underscore';
import Q from 'q';

import moment from "moment";

import consumer_admin_flight_change_offlineOrderConfirmation
    from '@/lib/data-service/haolv-default/consumer_admin_flight_change_offlineOrderConfirmation'
import consumer_admin_flight_change_offlineOrderCancellation
    from '@/lib/data-service/haolv-default/consumer_admin_flight_change_offlineOrderCancellation'
import consumer_admin_flight_change_newDetails from '@/lib/data-service/haolv-default/consumer_admin_flight_change_newDetails'

import PaymentMethodSelector010 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.11/payment-method-selector-010/index.vue';

import consumer_payment_getPayChannelV2ForAdmin
    from '@/lib/data-service/haolv-default/consumer_payment_getPayChannelV2ForAdmin'



export default {
    data() {
        return {
            payType: '1', // 1支付中 2 无需支付 3 待支付
            detail: {},
            chaOrderNo: '',
            changeRepair: false,
            sourceType: 1, // 1国内2国际
        }
    },
    components: {
        PaymentMethodSelector010,
    },
    created() {
    },
    mounted() {
      this.chaOrderNo = this.$route.query.chaOrderNo;
      this.changeRepair = this.$route.query.changeRepair ? true : false;
    },
    activated() {
        let meta = this.$route.meta;
        meta.data_for_left_menu = {
            get_active_route_name(pParameter) {
                return `admin-flight-change-list`;
            },
        }
        this.getData();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {

        //取消按钮
        backToList(){
            this.$confirm("确定要取消改签吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                let data = {
                    chaOrderNo: this.chaOrderNo
                };
                consumer_admin_flight_change_offlineOrderCancellation(data)
                    .catch(() => {
                        this.$message.error('取消失败');
                    })
                    .then(res => {
                        this.$message({
                            message: '取消成功',
                            type: 'success'
                        });
                        this.$router.go(-1);
                    });
            }).catch(() => {});

        },
        //返回按钮
        back() {
            this.$router.back();
        },
        //确认按钮
        submit(){
            let data = {
                chaOrderNo: this.$route.query.chaOrderNo,
            };
            consumer_admin_flight_change_offlineOrderConfirmation(data).then(res => {
                this.$message({
                    message: '确认成功',
                    type: 'success'
                });
                this.$router.push({
                    name: 'admin-flight-change-list'
                });

            })
        },
        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
          switch (val) {
            case (1):
              return 'type2'
            case (2):
              return 'type1'
            default: return ''
          }
        },
        //请求数据
        getData() {
            let data = {
                chaOrderNo: this.$route.query.chaOrderNo,
            };
            consumer_admin_flight_change_newDetails(data).then(res => {
                let detail = res.orderDetail;
                detail.originalPayment.forEach(value => {
                    if (value.sourceType) {
                        this.sourceType = value.sourceType
                    }
                })
              this.detail = detail;

              // 区分订单状态--不为33(需补款)、31(处理中)显示文字
              if (this.detail.chaOrderStatus !== 33 && this.detail.chaOrderStatus !== 31) {
                this.payType = '1';
              } else {
                //为33(需补款)--区分支付方式(payTypeText--现结支付、线下转账)
                if (this.detail.chaOrderStatus === 33 && this.detail.payTypeText === '线下转账') {
                  this.payType = '2';
                }
                //为33(需补款)--区分支付方式(payTypeText--授信支付)
                if (this.detail.chaOrderStatus === 33 && (this.detail.payTypeText === '授信支付' || this.detail.payTypeText === '现结支付')) {
                  this.payType = '3';
                  this.$nextTick(() => {
                    this.initPayment()
                  });
                }
                //为31(处理中)
                if (this.detail.chaOrderStatus === 31) {
                  this.payType = '2';
                }
              }

            });
        },
        initPayment() {
            let _this = this;
            let orderForPayVos = [{
                orderNo: _this.$route.query.chaOrderNo,
                businessContent: '',
            }];
            _this.$refs.aPaymentMethodSelector010.init({
                page_this: _this,
                // count_down: {
                //     enable: _this.pageType !== 3 ? true : false,
                //     pay_remaining_seconds: _this.milliseconds,//支付剩余时间，以秒为单位
                // },

                //在这里传入订单详情的相关参数
                get_params() {
                    const p = {
                        "orderForPayVos": orderForPayVos,
                        "payType":  2
                    };

                    return Q.resolve(p);
                },
                //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                before_pay_event_handler() {
                    // 重复调用consumer_payment_getPayChannelV2ForAdmin了，所以隐藏， 后端说orderNo传改签单号
                    /*consumer_payment_getPayChannelV2ForAdmin(
                        {
                            channelType: 1,
                            orderForPayVos: {
                                orderNo: _this.detail.orderNo
                            },
                            payType: 2
                        }
                    ).then((res) => {
                        return Q.resolve();
                    });*/

                },
                //支付成功事件处理方法
                pay_successful_event_handler() {
                    console.log(`pay_successful_event_handler`);
                    _this.$message({
                        type: 'success',
                        message: '支付成功!'
                    });
                    _this.$router.replace({
                        name: 'admin-flight-change-list'
                    })
                },
                //支付失败事件处理方法
                pay_failed_event_handler() {
                    console.log(`pay_failed_event_handler`);
                },
                //取消订单按钮单击事件处理方法
                btn_cancel_order_click_event_handler(args) {
                    const show_loading = args.show_loading;
                    const hide_loading = args.hide_loading;
                    const stop_count_down = args.stop_count_down;
                    const enable_btn_pay = args.enable_btn_pay;
                    const enable_btn_cancel_order = args.enable_btn_cancel_order;
                    this.$confirm('是否取消该改签单?', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        Q.when().then(function () {
                            show_loading();
                            return consumer_admin_flight_change_offlineOrderCancellation({
                                chaOrderNo: _this.chaOrderNo,
                            });
                        }).then(function () {
                            console.log(`btn_cancel_order_click_event_handler`);
                            hide_loading();
                            stop_count_down();
                            enable_btn_pay({
                                enable: false,
                            });
                            enable_btn_cancel_order({
                                enable: false,
                            });
                            _this.$message({
                                type: 'success',
                                message: '取消成功!'
                            });
                            _this.$router.replace({
                                name: 'admin-flight-order-list'
                            })
                        }).catch(function (ex) {
                            if (ex) {
                                const enable_log_error = !ex.disable_log_error;
                                if (enable_log_error) {
                                    console.error(ex);
                                    console.trace();
                                    if (ex.stack) {
                                        console.error(ex.stack);
                                    }
                                }
                            }
                            return Q.reject(ex);
                        });
                        hide_loading();
                        stop_count_down();
                        enable_btn_pay({
                            enable: false,
                        });
                        enable_btn_cancel_order({
                            enable: false,
                        });
                        _this.$router.replace({
                            name: 'admin-flight-order-list'
                        })
                    }).catch(() => {
                    });
                },
                //获取支付方式接口，返回code=300052时，弹窗的确定按钮单击处理处理方法
                dialog_code_300052_btn_ok_click_event_handler() {
                    console.log(`dialog_code_300052_btn_ok_click_event_handler`);
                    _this.$router.replace({
                        name: 'admin-flight-order-list',
                    });
                    return Q.resolve(true);
                    //return true;
                },
            });
        },
        clickGoBack() {
            this.$router.go(-1);
        },
    }
}

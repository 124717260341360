import consumer_car_order_getCarType from '@/lib/data-service/haolv-default/consumer_car_order_getCarType'
import consumer_admin_car_order_carOrderList
    from '@/lib/data-service/haolv-default/consumer_admin_car_order_carOrderList'
import SearchBox from '@/component/search-box/1.0.1/index.vue'
import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'

export default {
    data() {
        return {
            loading: false,
            carTypeList: [],//运力类型的选项
            orderStatusList: [],//订单状态的选项
            searchForm: {
                orderNo: '',//订单号
                callPerson: '',//叫车人
                belongCompany: '',//所属企业
                carType: '',//运力类型
                startDate: '',//开始日期
                endDate: '',//结束日期
                orderTimeArr: '',//开始-结束日期
                orderStatus: '',//订单状态-查询 0:全部 1：已完成 2：待付款 3：未完成
                currentPage: 1,//查询页码
                pageSize: 10,//查询条数
                total: 0,
            },
            activeName: 0,
            tabList: [
                // 1：已完成 2：待付款 3：未完成
                {
                    name: '0',
                    label: "全部",
                    value: 0
                },
                {
                    name: '1',
                    label: "已完成",
                    value: 0
                },
                {
                    name: '2',
                    label: "待付款",
                    value: 0
                },
                {
                    name: '3',
                    label: "未完成",
                    value: 0
                }
            ],
            tabNumberList: [],
            list: []
        }
    },
    components: {
        SearchBox,
        Pagination,
        RouterLinkButton
    },
    activated() {
        this.reset()
    },
    methods: {
        //搜索
        search() {
            this.searchForm.currentPage = 1
            this.searchForm.orderStatus = this.activeName === '0' ? '' : this.activeName
            this.getList()
        },
        //重置
        reset() {
            let searchForm = {
                orderNo: '',//订单号
                callPerson: '',//叫车人
                belongCompany: '',//所属企业
                carType: '',//运力类型
                startDate: '',//开始日期
                endDate: '',//结束日期
                orderTimeArr: '',//开始-结束日期
                orderStatus: '',//订单状态-查询 0:全部 1：已完成 2：待付款 3：未完成
                currentPage: 1,//查询页码
                pageSize: 10,//查询条数
                total: 0,
            }
            searchForm.orderStatus = this.activeName === '0' ? '' : this.activeName
            this.searchForm = searchForm
            this.getCarType();
            this.search();
        },
        //改变搜索条件
        handleClick(val) {
            this.search()
        },
        //搜索日期改变时
        changeOrderTimeArr(val) {
            if (val === '' || val === null) {
                this.searchForm.startDate = '';
                this.searchForm.endDate = '';
            } else {
                this.searchForm.startDate = `${val[0]} 00:00:00`;
                this.searchForm.endDate = `${val[1]} 23:59:59`;
            }
        },
        //获取列表
        getList() {
            this.loading = true
            this.list = []
            consumer_admin_car_order_carOrderList(this.searchForm).then(res => {
                this.loading = false
                this.list = res.datas.carOrderList.list
                this.searchForm.total = res.datas.carOrderList.totalCount
                this.tabNumberList = res.datas.orderStatusResults;
                this.tabList.forEach(tab => {
                    this.tabNumberList.forEach(tabNumber => {
                        if (Number(tab.name) === tabNumber.status) {
                            tab.value = tabNumber.orderNumber ? tabNumber.orderNumber : 0
                        }
                    })
                })
            }).catch(() => {
                this.loading = false
                this.list = []
                this.searchForm.total = 0
            })
        },
        //获取运力类型枚举值
        async getCarType() {
            let res = await consumer_car_order_getCarType();
            this.carTypeList = res.datas.result;
        },
        //进入详情页
        handleToDetail(val) {
            return {
                name: 'admin-car-order-detail',
                query: {
                    orderNo: val.orderNo
                }
            }
        },
    }
}
import Q from 'q';
import moment from 'moment';

// 火车票订单列表
import consumer_admin_trains_order_orderChangeList
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_orderChangeList';
// 火车票订单数量
import consumer_admin_trains_order_orderChangeStatusNumber
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_orderChangeStatusNumber';
import consumer_trains_order_cancelChangeOrder
  from '@/lib/data-service/haolv-default/consumer_trains_order_cancelChangeOrder'
import consumer_admin_trains_order_trainsOrdersource
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsOrdersource'

import SearchBox from '@/component/search-box/1.0.1/index.vue'
import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'

// 消息模板
import consumer_admin_trains_change_copyMessageTemplate from '@/lib/data-service/haolv-default/consumer_admin_trains_change_copyMessageTemplate'
import CopyReportPopup from '@/component/copyReportPopup/import_latest_version_module'

export default {
  data() {
    return {
      loading: false,
      copyDialog: false,
      copyLoading: false,
      copyTextarea: '',
      //待支付--订单数量
      orderQuantity: [
        {
          orderQuantity: '0'
        },
        {
          orderQuantity: '0'
        },
      ],
      params: {
        // 搜索参数
        query: {
          orderNo: '',
          changeNo: '',
          fromStationName: '',
          toStationName: '',
          changeStartDate: '',
          trainNo: '',
          seatName: '',
          passengerNames: '',
          operatorName: '',
          buyerContactName: '',
          realName: '',
          companyName: '',
          orderStatus: '',
          ticketChannel: '',
        },

        // 分页参数
        currentPage: 1,
        pageSize: 10,
        totalCount: 0
      },
      tableList: [],
      allocation: {
        orderTypeList: [
          {value: 1, label: '预定'},
          {value: 2, label: '抢票'}
        ],
        orderSourceList: [],
        isBehalfList: [
          {value: '', label: '全部'},
          {value: 1, label: '是'},
          {value: 0, label: '否'},
        ],
        orderStatusList: [
          {value: '0', label: '所有订单'},
          {value: '1', label: '待支付'},
          {value: '2', label: '改签中'},
          {value: '3', label: '改签成功'},
          {value: '4', label: '改签失败'},
          {value: '5', label: '取消'},
        ],
      }
    }
  },
  components: {
    SearchBox,
    RouterLinkButton,
    Pagination,
    CopyReportPopup
  },
  methods: {
    getTrainsOrdersource() {
      consumer_admin_trains_order_trainsOrdersource().then(res => {
        let orderSourceList = res.datas
        orderSourceList.unshift({code: '', msg: '全部'},);
        this.allocation.orderSourceList = orderSourceList
      })
    },
    get_table_list() {
      const __this = this;
      __this.loading = true;
      __this.params.query.orderStatus = parseInt(__this.params.orderStatus) === 0 ? '' : parseInt(__this.params.orderStatus);
      consumer_admin_trains_order_orderChangeList(__this.params).then(res => {
        __this.tableList = res.datas.records;
        __this.params.totalCount = res.datas.total;
        this.getTrainsOrdersource();
      }).finally(() => {
        __this.loading = false;
      });
      consumer_admin_trains_order_orderChangeStatusNumber(__this.params).then(res => {
        this.orderQuantity = res.datas.orderQuantity;
      })
    },
    onSearch() {
      const __this = this;
      __this.params.currentPage = 1;
      __this.get_table_list()
    },
    onReset() {
      const __this = this;
      __this.params = {
        query: {
          orderNo: '',
          changeNo: '',
          fromStationName: '',
          toStationName: '',
          changeStartDate: '',
          trainNo: '',
          seatName: '',
          passengerNames: '',
          operatorName: '',
          buyerContactName: '',
          realName: '',
          companyName: '',
          orderStatus: '',
          ticketChannel: '',
        },

        // 分页参数
        currentPage: 1,
        pageSize: 10,
        totalCount: 0
      };
      __this.onSearch();
    },
    handleSizeChange(size) {
      const __this = this;
      __this.params.currentPage = 1;
      __this.params.pageSize = size;
      __this.get_table_list()
    },
    handleCurrentChange(page) {
      const __this = this;
      __this.params.currentPage = page;
      __this.get_table_list()
    },
    handleClick() {
      const __this = this;
      __this.onSearch();
    },
    handle_edit(row) {
      return {
        name: 'admin-train-change-offline-edit',
        query: {
          orderNo: row.orderNo,
          changeNo: row.changeNo,
        },
      }
    },
    to_detail(row) {
      if (row.ticketChannel == 3 || row.ticketChannel == 2 || row.ticketChannel == 1) {
        return {
          name: 'admin-train-change-detail-offline',
          query: {
            orderNo: row.orderNo,
            changeNo: row.changeNo,
          },
        }
      } else {
        return {
          name: 'admin-train-change-detail',
          query: {
            orderNo: row.orderNo,
            changeNo: row.changeNo,
          },
        }
      }
    },
    cancel_order(row) {
      const __this = this;
      __this.$confirm('此操作将取消该订单的改签, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = __this.$loading({
          lock: true,
          text: '',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        consumer_trains_order_cancelChangeOrder({changeNo: row.changeNo}).then(res => {
          loading.close();
          let ifIsSuccessful = res.datas.ifIsSuccessful;
          if (ifIsSuccessful === 1) {
            __this.$message({
              type: 'success',
              message: '取消成功'
            });
            __this.onSearch()
          } else {
            __this.$message({
              type: 'warning',
              message: '取消改签失败，请稍后再试'
            })
          }
        }).catch(() => {
          loading.close()
        })
      }).catch(() => {

      })
    },
    to_pay(row) {
      if (row.ticketChannel == 3 || row.ticketChannel == 2 || row.ticketChannel == 1) {
        return {
          name: 'admin-train-change-offline-confirm',
          query: {
            changeNo: row.changeNo,
            orderNo: row.orderNo,
          },
        }
      } else {
        return {
          name: 'admin-train-payment-change',
          query: {
            orderType: 2,
            pageType: 3,
            changeNo: row.changeNo,
            orderNo: row.orderNo,
          }
        }
      }
    },
    to_change(row) {
      return {
        name: 'admin-train-change',
        query: {
          orderNo: row.orderNo,
        },
      }
    },
    to_return(row) {
      if (row.ticketChannel == 3 || row.ticketChannel == 2 || row.ticketChannel == 1) {
        return {
          name: 'admin-train-refund-handle',
          query: {
            orderNo: row.orderNo,
            changeNo: row.changeNo,
            type: 'handle'
          },
        }
      } else {
        return {
          name: 'admin-train-return',
          query: {
            changeNo: row.changeNo
          }
        }
      }
    },
    //消息模板
    handleMessage(val) {
      this.copyDialog = true;
      this.copyLoading = true;
      this.copyTextarea = '';
      // 调接口
      consumer_admin_trains_change_copyMessageTemplate({orderNo: val.changeNo}).then(res => {
        const datas = res.datas;
        let message = '';
        message += `列车改签成功：\n`;
        let oldTransDay = 1 === datas.oldTransDay ? '次日' : '';
        message = message + datas.oldStartDate + ' (' + datas.oldStartWeek + ') ' + datas.oldTrainNo + '次' + datas.oldFromStationName + ' (' + datas.oldStartTime + '开) —' + datas.oldToStationName + ' (' + oldTransDay + datas.oldArriveTime + '到)' + '\n';
        let transDay = 1 === datas.transDay ? '次日' : '';
        message = message + datas.startDate + ' (' + datas.startWeek + ') ' + datas.trainNo + '次' + datas.fromStationName + ' (' + datas.startTime + '开) —' + datas.toStationName + ' (' + transDay + datas.arriveTime + '到)' + '\n';
        // datas.psgList.forEach((value, index) => {
        //   message += `${value.psgType} ${datas.psgList.length > 1 ? '乘车人' + (index + 1) + '：' : '乘车人：'} ${value.psgName} ${value.seatName} ${value.seatInfos}\n`;
        // });
        if ( 0 < datas.adultPsgList.length ) {
          message += '成人票 ' + datas.seatName + '\n';
          datas.adultPsgList.forEach((value, index) => {
            message += `${'乘车人' + (index + 1) + '：'} ${value.psgName} ${value.seatInfos}\n`;
          });
        }
        if ( 0 < datas.childPsgList.length ) {
          message += '儿童票 ' + datas.seatName + '\n';
          datas.childPsgList.forEach((value, index) => {
            message += `${'乘车人' + (index + 1) + '：'} ${value.psgName} ${value.seatInfos}\n`;
          });
        }
        message += `温馨提示,请乘坐人持购票时所使用的有效证件原件检票入站。\n`;
        message += `如需报销票凭证可凭本人证件到乘车站点领取。\n`;
        this.copyTextarea = message
      }).finally(() => {
        this.copyLoading = false;
      })
    }
  },
  mounted() {

  },
  activated() {
    this.get_table_list();
  },
  deactivated() {
  },
  watch: {}
}

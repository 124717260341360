// 异常退款订单列表 http://yapi.yinzhitravel.com/project/52/interface/api/15748
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_car_order_toRefundOrderList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/car/order/toRefundOrderList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_car_order_toRefundOrderList;

/*
*改签申请
*/
const __request = require(`./__request/__request_contentType_json`)
const consumer_flight_order_orderChangeCustomer = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/flight/order/orderChangeCustomer',
        data: data
    }
    return __request(pParameter)
}
export default consumer_flight_order_orderChangeCustomer
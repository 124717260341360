/*
* 平台酒店规则外部分退订订单列表
* POST /consumer/admin/hotel/order/partialCancelList
* 接口ID：59364033
* 接口地址：https://www.apifox.cn/link/project/458418/apis/api-59364033
*/
const __request = require(`./__request/__request_contentType_json`)
const consumer_admin_hotel_order_partialCancelList = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/admin/hotel/order/partialCancelList',
    data: data
  }
  return __request(pParameter)
}
export default consumer_admin_hotel_order_partialCancelList
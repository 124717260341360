// consumer_admin_flight_admin_itinerary_print_detail 行程单打印查询
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_flight_admin_itinerary_print_detail = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/flight/admin/itinerary/print/detail',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_flight_admin_itinerary_print_detail;
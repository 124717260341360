export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/train-search',
            name: 'admin-train-search',
            component: () => import(/* webpackChunkName: "page-admin-replace-orders-train-search" */ `./1.1.0/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `火车票搜索`,
                },
                entity_list_for_breadcrumb: [
                    {name: `代客下单`},
                    {name: `火车票搜索`},
                ],
            },
        },
    ],
};

import Q from 'q';
import moment from 'moment';

// 确认出票
import consumer_admin_trains_order_trainsConfirm from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsConfirm'
// 火车票订单列表
import consumer_admin_trains_order_getOrderList from '@/lib/data-service/haolv-default/consumer_admin_trains_order_getOrderList';
// 取消订单
import consumer_trains_order_applyForCancel from '@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel';
import consumer_admin_trains_order_trainsOrdersource from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsOrdersource'
import consumer_admin_trains_order_orderStatusNumber from '@/lib/data-service/haolv-default/consumer_admin_trains_order_orderStatusNumber';
// 消息模板
import consumer_admin_trains_copyMessageTemplate from '@/lib/data-service/haolv-default/consumer_admin_trains_copyMessageTemplate'
import CopyReportPopup from '@/component/copyReportPopup/import_latest_version_module'

import _ from "underscore";

import SearchBox from '@/component/search-box/1.0.1/index.vue'
import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'

export default {
  data() {
    return {
      loading: false,
      copyDialog: false,
      copyLoading: false,
      copyTextarea: '',
      params: {
        // 搜索参数
        query: {
          orderNo: '',
          orderType: '',
          startDate: '',
          trainNo: '',
          gmtCreate: '',
          gmtCreateStart: '',
          gmtCreateEnd: '',
          passengerNames: '',
          operatorName: '',
          buyerContactName: '',
          realName: '',
          companyName: '',
          orderStatus: '',
          behalf: '',
          ticketChannel: '',
        },

        // 分页参数
        currentPage: 1,
        pageSize: 10,
        totalCount: 0
      },
      orderTimeArr: [], //起飞日期--合集
      tableList: [],
      allocation: {
        orderTypeList: [
          {value: '', label: '全部'},
          {value: 1, label: '预定'},
          {value: 2, label: '抢票'}
        ],
        isBehalfList: [
          {value: '', label: '全部'},
          {value: 1, label: '是'},
          {value: 0, label: '否'},
        ],
        orderStatusList: [
          {value: '0', label: '全部'},
          {value: '1', label: '确认中', num: ''},
          {value: '2', label: '待支付', num: ''},
          {value: '3', label: '待出票', num: ''},
          {value: '4', label: '出票成功'},
          {value: '5', label: '出票失败'},
          {value: '6', label: '取消'},
        ],
        orderSourceList: [
          // 1 线下订单 4 线上订单 5 代客下单
          /*{value: '', label: '全部'},
          {value: 1, label: '线下订单'},
          {value: 4, label: '线上订单'},
          {value: 5, label: '代客下单'},*/
        ]
      }
    }
  },
  components: {
    SearchBox,
    RouterLinkButton,
    Pagination,
    CopyReportPopup
  },
  methods: {
    getTrainsOrdersource() {
      consumer_admin_trains_order_trainsOrdersource().then(res => {
        let orderSourceList = res.datas
        orderSourceList.unshift({code: '', msg: '全部'},);
        this.allocation.orderSourceList = orderSourceList
      })
    },
    getOrderNum() {
      consumer_admin_trains_order_orderStatusNumber(this.params).then(res => {
        let orderQuantity = res.datas.orderQuantity;
        orderQuantity.forEach(value => {
          this.allocation.orderStatusList.forEach(value1 => {
            if (value.status + '' === value1.value) {
              value1.num = value.orderQuantity
            }
          })
        })
      })
    },
    get_table_list() {
      const __this = this;
      __this.loading = true;
      __this.params.query.orderStatus = parseInt(__this.params.orderStatus) === 0 ? '' : parseInt(__this.params.orderStatus);
      consumer_admin_trains_order_getOrderList(__this.params).then(res => {

        // _.each(res.datas.records,function (m) {
        //     m.initialName = `xxx`;
        // });

        __this.tableList = res.datas.records;
        __this.tableList = __this.tableList.map(row => {
          return {
            ...row,
            showMoreActions: false, // 初始化为 false
          }; });
        __this.params.totalCount = res.datas.total;
      }).finally(() => {
        __this.loading = false;
      })
    },
    onSearch() {
      const __this = this;
      __this.params.currentPage = 1;
      __this.get_table_list();
      __this.getOrderNum()
    },
    onReset() {
      const __this = this;
      __this.orderTimeArr= [], //下单日期--合集
      __this.params = {
        query: {
          orderNo: '',
          orderType: '',
          startDate: '',
          trainNo: '',
          gmtCreate: '',
          gmtCreateStart: '',
          gmtCreateEnd: '',
          passengerNames: '',
          operatorName: '',
          buyerContactName: '',
          realName: '',
          companyName: '',
          orderStatus: '',
          behalf: '',
          ticketChannel: '',
        },

        currentPage: 1,
        pageSize: 10,
        totalCount: 0
      };
      __this.onSearch();
    },
    handleSizeChange(size) {
      const __this = this;
      __this.params.currentPage = 1;
      __this.params.pageSize = size;
      __this.get_table_list();
      __this.getOrderNum()
    },
    handleCurrentChange(page) {
      const __this = this;
      __this.params.currentPage = page;
      __this.get_table_list();
      __this.getOrderNum()
    },
    handleClick() {
      const __this = this;
      __this.onSearch();
    },
    to_detail(row) {
      this.$router.push({
        name: 'admin-train-order-detail',
        query: {
          orderNo: row.orderNo,
        }
      });
    },
    to_change(row) {
      let name = 'admin-train-change-offline';
      if (row.ticketChannel == 3 || row.ticketChannel == 2 || row.ticketChannel == 1 || row.ticketChannel == 6) {
        name = 'admin-train-change-offline';
      } else {
        name = 'admin-train-change';
      }
      this.$router.push({
        name,
        query: {
          orderNo: row.orderNo,
        }
      });
    },
    to_return(row) {
      const __this = this;
      if (row.ticketChannel == 3 || row.ticketChannel == 2 || row.ticketChannel == 1 || row.ticketChannel == 6) {
        this.$router.push({
          name: 'admin-train-refund-handle',
          query: {
            orderNo: row.orderNo,
            type: 'handle'
          }
        });
      } else {
        this.$router.push({
          name: 'admin-train-return',
          query: {
            orderNo: row.orderNo,
          }
        });
      }
    },
    to_pay(row) {
      if (row.ticketChannel == 3 || row.ticketChannel == 2 || row.ticketChannel == 1 || row.ticketChannel == 6) {
        this.$router.push({
          name: 'admin-train-create-order-pay',
          query: {
            orderNo: row.orderNo,
            behalfCompanyId: row.companyId,
          }
        });
      } else {
        this.$router.push({
          name: 'admin-train-payment',
          query: {
            orderType: '2',
            pageType: '2',
            orderNo: row.orderNo,
            behalfCompanyId: row.companyId
          }
        });
      }
    },
    cancel_order(row) {
      const __this = this;
      __this.$confirm(`是否取消该${row.orderNo}订单?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = __this.$loading({
          lock: true,
          text: '',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        consumer_trains_order_applyForCancel({orderNo: row.orderNo}).then(res => {
          loading.close();
          __this.$alert('你已成功取消预订', '取消预订提示', {
            confirmButtonText: '知道了',
            callback: action => {
              __this.onSearch()
            }
          })
        }).catch(() => {
          loading.close()
        })
      }).catch(() => {});
    },
    toCreate() {
      this.$router.push({
        name: 'admin-train-create-order'
      })
    },
    handleEditClick(val) {
      this.$router.push({
        name: "admin-train-edit-order",
        query: {
          orderNo: val.orderNo,
          type: 'edit',
        }
      });
    },
    //确认出票
    clickConfirmTicket(val) {
      const __this = this;
      __this.$confirm('是否继续出票', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 确认
        const loading = __this.$loading({
          lock: true,
          text: '',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        const params = {
          orderNo: val.orderNo
        };
        consumer_admin_trains_order_trainsConfirm(params).then((res) => {
          this.$message({
            type: 'success',
            message: '出票成功!'
          });
          __this.get_table_list();
        }).finally(() => {
          loading.close();
        })
      }).catch(() => {
        // 取消
      });
    },
    //消息模板
    handleMessage(val) {
      this.copyDialog = true;
      this.copyLoading = true;
      this.copyTextarea = '';
      // 调接口
      consumer_admin_trains_copyMessageTemplate({orderNo: val.orderNo}).then(res => {
        const datas = res.datas;
        let message = '';
        message += `预定成功：\n`;
        let transDay = 1 == datas.transDay ? '次日' : '';
        message = message + datas.startDate + ' (' + datas.startWeek + ') ' + datas.trainNo + '次' + datas.fromStationName + ' (' + datas.startTime + '开) —' + datas.toStationName + ' (' + transDay + datas.arriveTime + '到)' + '\n';
        if ( 0 < datas.adultPsgList.length ) {
          message += '成人票 ' + datas.seatName + '\n';
          datas.adultPsgList.forEach((value, index) => {
            message += `${'乘车人' + (index + 1) + '：'} ${value.psgName} ${value.seatInfos}\n`;
          });
        }
        if ( 0 < datas.childPsgList.length ) {
          message += '儿童票 ' + datas.seatName + '\n';
          datas.childPsgList.forEach((value, index) => {
            message += `${'乘车人' + (index + 1) + '：'} ${value.psgName} ${value.seatInfos}\n`;
          });
        }
        message += `温馨提示,请乘坐人持购票时所使用的有效证件原件检票入站。\n`;
        message += `如需报销票凭证可凭本人证件到乘车站点领取。\n`;
        this.copyTextarea = message
      }).finally(() => {
        this.copyLoading = false;
      })
    },
    showPay(item) {
      // 线下单情况
      let check1 = (item.orderStatus === 1 || item.orderStatus === 2)  && (item.ticketChannel == 3 || item.ticketChannel == 2 || item.ticketChannel == 1 || item.ticketChannel == 6)
      // 线上单情况
      let check2 = item.orderStatus === 2  && item.ticketChannel === 5
      return check1 || check2
    },
    showCancel(item) {
      let check1 = (item.orderStatus === 1 || item.orderStatus === 2 || (item.orderType === 2 && item.orderStatus === 3)) && (item.ticketChannel == 3 || item.ticketChannel == 2 || item.ticketChannel == 1 || item.ticketChannel == 6)
      let check2 = item.orderStatus === 2 && item.ticketChannel === 5
      return check1 || check2
    },
    changeCreateTime(val) {
      if(!val) {
        this.params.query.gmtCreateStart = 0;
        this.params.query.gmtCreateEnd = 0;
      } else {
        this.params.query.gmtCreateStart = val[0] + ' 00:00:00';
        this.params.query.gmtCreateEnd = val[1] + ' 23:59:59';
      }
    },
    handleCopy(val) {
      this.$router.push({
        name: "admin-train-edit-order",
        query: {
          orderNo: val.orderNo,
          type: 'copy',
        }
      });
    },
  },
  created () {

  },
  mounted() {
    this.getTrainsOrdersource();
  },
  activated() {
    this.get_table_list();
    this.getOrderNum()
  },
  deactivated() {
  },
  watch: {

  },
  filters: {
    filter_date(val) {
      return val ? moment(val).format("YYYY-MM-DD HH:mm") : val;
    }
  }
}

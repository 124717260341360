import history_tag_api from '@/component/admin-layout/component/history-tag/api'

import consumer_admin_hotel_order_queryHotelOrderDetails from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_queryHotelOrderDetails"   // 平台酒店订单详情
import applyCancelOrder from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_applyCancelOrder"   // 客户申请退订审核-酒店

export default {
  data() {
    return {
      loading: false,
      orderDetail: {},
      orderPersonList: []
    }
  },
  filters: {
    filterMoney(val) {
      if (val || val === 0) {
        let money = parseFloat(val).toFixed(2)
        return money
      } else {
        return '0.00'
      }
    },
    format_week(val, moment) {
      switch (moment(val).day()) {
        case 0:
          return "周日"
        case 1:
          return "周一"
        case 2:
          return "周二"
        case 3:
          return "周三"
        case 4:
          return "周四"
        case 5:
          return "周五"
        case 6:
          return "周六"
      }
    }
  },
  created() {
    this.getHotelOrderDetails()
  },
  methods: {
    // 平台酒店订单详情
    async getHotelOrderDetails() {
      try {
        this.loading = true
        const res = await consumer_admin_hotel_order_queryHotelOrderDetails({
          orderNo: this.$route.query.orderNo
        })
        this.orderDetail = res.orderInterimResult
        this.orderPersonList = res.hotelOrderPersonList
        if (res.orderInterimResult.refundRule) {
          this.orderDetail.refundRuleType = JSON.parse(res.orderInterimResult.refundRule).refundRuleType
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: "获取酒店订单详情失败！"
        })
      } finally {
        this.loading = false
      }
    },
    // 确认取消
    handleRefundClick() {
      this.$prompt("请输入退订原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputErrorMessage: "请输入原因",
        inputPattern: /\S/
      }).then(({ value }) => {
        applyCancelOrder({
          orderNo: this.orderDetail.orderNo,
          evectionNo: this.orderDetail.evectionNo,
          cancelRemark: value
        }).then((res) => {
          this.$message.success('提交成功')
          this.handleBackClick();
        })
      })
    },
    // 返回
    handleBackClick() {
      history_tag_api.remove_tag_by_route({
        route: this.$route
      })
      this.$router.replace({
        name: "admin-hotel-order-sold"
      })
    },
    // 出差单类型：1.因私，2.因公
    getEvectionTypeToClass(val) {
      switch (val) {
        case (1):
          return 'type2'
        case (2):
          return 'type1'
        default:
          return ''
      }
    }
  }
}

export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/flight-edit-order',
            name: 'admin-flight-edit-order',
            component: () => import(/* webpackChunkName: "page-admin-order-management-flight-edit-order" */ `@/page/admin/order-management/flight/create-order/1.0.8/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `编辑`,
                },
                entity_list_for_breadcrumb: [
                    {name: `机票订单管理`},
                    {name: `编辑`},
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        return `admin-flight-order-list`;
                    },
                },
            },
        },
    ],
};

import _ from 'underscore';
import Q from 'q';
import OrderLog from '@/component/orderLog/2.0.0/index.vue'
import moment from "moment";


// 火车票线下单改签详情
import consumer_admin_trains_order_trainsOfflineOrderDetails
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsOfflineOrderDetails'
// 火车票坐席枚举
import consumer_admin_trains_order_seatingLevel
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_seatingLevel'
// 火车票线下单保存或编辑
import consumer_admin_trains_order_trainsOfflineChangeOrUpdate
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsOfflineChangeOrUpdate'
// 当前登陆人--操作客服
import consumer_admin_flight_generate_userName
  from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_userName'
// 线下单改签确认
import consumer_admin_trains_order_trainsChangeConfirm
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsChangeConfirm'
// 取消订单
import consumer_trains_order_cancelChangeOrder from '@/lib/data-service/haolv-default/consumer_trains_order_cancelChangeOrder'

// 支付组件
import PaymentMethodSelector010
  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.11/payment-method-selector-010/index.vue';

export default {
  data() {
    return {
      options: [
        {
          value: '1',
          label: '改签票价大于原票价'
        },
        {
          value: '2',
          label: '改签票价小于原票价'
        },
        {
          value: '3',
          label: '改签票价等于原票价'
        },
      ],
      payType: '2', // 1支付中 2 无需支付 3 待支付
      loading: false,
      // 当前登陆人
      userName: null,
      // 订单号
      orderNo: '',
      // 改签单号
      changeNo: '',
      // 当前正在更改的改签乘客信息
      ruleSegmentsListCardNo: null,
      // 当前正在更改的改签车次信息--行程
      changejourneyInfoBox: null,
      remark: '', //备注
      // 改签信息
      changeInformation: {},
      //改签车次信息
      changejourneyInfo: [],
      //改签车次信息--checkbox开关暂存
      checkboxjourneyInfo: [],
      //原车次信息
      journeyInfo: [],
      //订单信息
      trainsOrderDetails: {},
      //座席list
      seatNameList: [],

    }
  },
  components: {
    OrderLog,
    PaymentMethodSelector010,
  },
  created() {
  },
  mounted() {

  },
  activated() {
    this.getData();
    // this.getReset();
  },
  deactivated() {
  },
  destroyed() {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    //重置
    getReset() {

    },
    initPayment() {
      let _this = this;
      // orderNo: _this.detail.orderNo,
      let orderForPayVos = [{
        orderNo: this.changeNo,
        businessContent: '',
      }];
      _this.$refs.aPaymentMethodSelector010.init({
        page_this: _this,
        // count_down: {
        //     enable: _this.pageType !== 3 ? true : false,
        //     pay_remaining_seconds: _this.milliseconds,//支付剩余时间，以秒为单位
        // },

        //在这里传入订单详情的相关参数
        get_params() {
          const p = {
            "orderForPayVos": orderForPayVos,
            "payType": 2
          };

          return Q.resolve(p);
        },
        //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
        before_pay_event_handler() {
          return Q.resolve();
        },
        //支付成功事件处理方法
        pay_successful_event_handler() {
          console.log(`pay_successful_event_handler`);
          _this.$message({
            type: 'success',
            message: '支付成功!'
          });
          _this.$router.replace({
            name: 'admin-train-change-order-list'
          })
        },
        //支付失败事件处理方法
        pay_failed_event_handler() {
          console.log(`pay_failed_event_handler`);
        },
        //取消订单按钮单击事件处理方法
        btn_cancel_order_click_event_handler(args) {
          _this.$confirm('此操作将取消该订单的改签, 是否继续?', '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            const show_loading = args.show_loading;
            const hide_loading = args.hide_loading;
            const stop_count_down = args.stop_count_down;
            const enable_btn_pay = args.enable_btn_pay;
            const enable_btn_cancel_order = args.enable_btn_cancel_order;

            Q.when().then(function () {
              show_loading();
              return consumer_trains_order_cancelChangeOrder({
                changeNo: _this.changeNo,
              });
            }).then(function () {
              console.log(`btn_cancel_order_click_event_handler`);
              hide_loading();
              stop_count_down();
              enable_btn_pay({
                enable: false,
              });
              enable_btn_cancel_order({
                enable: false,
              });
              _this.$message({
                type: 'success',
                message: '取消成功!'
              });
              _this.$router.replace({
                name: 'admin-train-order-list'
              })
            }).catch(function (ex) {
              if (ex) {
                const enable_log_error = !ex.disable_log_error;
                if (enable_log_error) {
                  console.error(ex);
                  console.trace();
                  if (ex.stack) {
                    console.error(ex.stack);
                  }
                }
              }
              return Q.reject(ex);
            });
            hide_loading();
            stop_count_down();
            enable_btn_pay({
              enable: false,
            });
            enable_btn_cancel_order({
              enable: false,
            });
            _this.$router.replace({
              name: 'admin-train-order-list'
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
        },
        //获取支付方式接口，返回code=300052时，弹窗的确定按钮单击处理处理方法
        dialog_code_300052_btn_ok_click_event_handler() {
          console.log(`dialog_code_300052_btn_ok_click_event_handler`);
          _this.$router.replace({
            name: 'admin-train-order-list',
          });
          return Q.resolve(true);
          //return true;
        },
      });
    },
    //请求数据
    getData() {
      this.loading = true;
      this.changeNo = this.$route.query.changeNo;
      this.orderNo = this.$route.query.orderNo;
      let dataOrderNo = {
        orderNo: this.orderNo,
        changeNo: this.changeNo,
      };
      consumer_admin_trains_order_trainsOfflineOrderDetails(dataOrderNo).then(res => {
        this.loading = false;
        // 订单信息
        this.trainsOrderDetails = res.datas.trainsOrderDetails;
        // 原车次信息
        this.journeyInfo = res.datas.journeyInfo;
        // 改签车次信息
        this.changejourneyInfo = res.datas.changejourneyInfo;
        // 判断是否需要补款
        let supplementary = 0;
        this.changejourneyInfo.forEach((CItem, CIndex) => {
          // CItem.startTime = this.$moment(CItem.startTime).format("yyyy-MM-dd HH:mm");
          // CItem.arriveTime = this.$moment(CItem.arriveTime).format("yyyy-MM-dd HH:mm");
          CItem.passengerList.forEach((PItem, PIndex) => {
            supplementary = supplementary + Number(PItem.changeAmount);
            PItem.paymentFlag = `${PItem.paymentFlag}`
          });
        });

        // 订单状态不为3、出票中和supplementary(补款金额大于0)和支付方式(trainsOrderDetails--现结支付、线下转账)
        if (supplementary > 0 && (this.trainsOrderDetails.paymentChannelText == '线下转账')) {
          this.payType = '2';
        }
        // 订单状态不为3、出票中和supplementary(补款金额大于0)和支付方式(trainsOrderDetails--授信支付)
        if (supplementary > 0 && (this.trainsOrderDetails.paymentChannelText == '授信支付' || this.trainsOrderDetails.paymentChannelText == '现结支付')) {
          this.payType = '3';
          this.$nextTick(() => {
            this.initPayment();
          });
        }
        // 订单状态不为3、出票中和supplementary(补款金额为0)
        if (supplementary == 0) {
          this.payType = '2';
        }

        let changeInformation = res.datas.changeInformation;
        consumer_admin_flight_generate_userName().then(res => {
          this.userName = res.datas;
          this.changeInformation = {
            changeTime: null,
            initialName: this.userName[0].initialName,
            supplierchangeOrderNo: '',
            userId: this.userName[0].userId,
            paymentTypeMsg: changeInformation.paymentTypeMsg || '--'
          };
        });

        this.remark = '';
      });
      consumer_admin_trains_order_seatingLevel().then(res => {
        this.seatNameList = res.datas;
      });

    },
    //取消按钮
    backToList() {
      this.$router.go(-1);
    },
    //确认按钮
    submit() {
      let data = {
        chaOrderNo: this.changeNo,
      };
      consumer_admin_trains_order_trainsChangeConfirm(data).then(res => {
        this.$message({
          message: '确认成功',
          type: 'success'
        });
        this.$router.push({
          name: 'admin-train-change-order-list'
        });
      })
    },
  }
}
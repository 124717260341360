export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/hotel-order/cancel/handle',
            name: 'admin-hotel-order-cancel-handle',
            component: () => import(/* webpackChunkName: "page-admin-order-management-hotel-cancel-handle" */ `./1.0.0/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `退订处理`,
                },
                entity_list_for_breadcrumb: [
                    { name: `酒店订单` },
                    { name: `退订处理` }
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        return `admin-hotel-order-cancel-pending-list`;
                    },
                },
            },
        },
    ],
};

import CompanyInfo from '@/component/company-info/import_latest_version_module'
import NewChangeClause from "@/page/admin/replace-orders/flight-search/component/newChangeClause/index.vue";
import CopyReportPopup from '@/component/copyReportPopup/import_latest_version_module'
//选择出行人组件
import ChooseTraveler from '@/component/chooseTraveler/1.0.0/index.vue';
import TravelerListForReserve from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-list-for-reserve/1.1.0/index.vue'//选择出行人组件
// import TravelerListForReservePlatform from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-list-for-reserve-platform/1.0.0/index.vue'//选择出行人组件
import TravelerListForReservePlatform from '@/component/traveler-list-for-reserve-platform/1.0.0/index.vue';
// import DynamicForm from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/dynamic-form/import_latest_version_module.js'
import DynamicForm from '@/component/dynamicForm/import_latest_version_module'
import InsureChoice from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/insure-choice/1.0.2/index.vue'
import PolicyTypeLabel from '@/page/admin/replace-orders/component/policyTypeLabel/index.vue'

import {awaitWrap} from '@/page/admin/replace-orders/travel-reserve/common/unit'
import moment from "moment";

import consumer_admin_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_admin_journey_getEvectionDetail' // 获取出差信息
import consumer_flight_search_getEquityProductList from '@/lib/data-service/haolv-default/consumer_flight_search_getEquityProductList' // 获取加享信息
import consumer_air_ticket_fligthLuggage from '@/lib/data-service/haolv-default/consumer_air_ticket_fligthLuggage'
import consumer_admin_behalfCustomer_getServiceAmount from '@/lib/data-service/haolv-default/consumer_admin_behalfCustomer_getServiceAmount'
import consumer_admin_journey_calculatePrice from '@/lib/data-service/haolv-default/consumer_admin_journey_calculatePrice'
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg' // 保存信息
import consumer_admin_flight_createOrder from '@/lib/data-service/haolv-default/consumer_admin_flight_createOrder'

import consumer_flight_order_flightsStop from '@/lib/common-service/consumer_flight_order_flightsStop'
import consumer_flight_search_stipulates from '@/lib/common-service/consumer_flight_search_stipulates'
import consumer_flight_search_stipulatesRule from '@/lib/common-service/consumer_flight_search_stipulatesRule'
import consumer_flight_search_queryMeals from "@/lib/data-service/haolv-default/consumer_flight_search_queryMeals";
import consumer_flight_search_queryPlaneNameByCodes from "@/lib/data-service/haolv-default/consumer_flight_search_queryPlaneNameByCodes";
import consumer_department_getStaffsAndExCusByUserIds from '@/lib/data-service/haolv-default/consumer_department_getStaffsAndExCusByUserIds'

export default {
  data() {
    return {
      equityProductId: null,
      // 加享产品
      equityList: [],
      equityListBackups: [],
      equityData: {},
      limitationTip: {
        numberLimit: '',
        ageLimit: '',
        CertificateLimit: '',
        timeLimitQueue: '',
      },
      loading: false,
      companyId: '',
      evectionNo: '',
      orderNo: '',
      evectionType: 2,
      evectionInfo: {}, // 出差信息合集
      evectionParams: {
        evectionNo: '',
        evectionType: 2,
        explainDesc: '',
        feeAffiliationId: '',
        feeAffiliationName: '', // 费用归属名称
        feeAffiliationType: 1,
        linkman: '',
        linkmanPhone: '',
        reason: '',
        // travelUserList: [],
        smsType: 1, // 是否发送短信， 1为是， 0为否
        linkmanSms: 1, // 是否给联系人发送短信 1为是， 0为否
        userList: []
      },
      flightList: [],
      flightSegmentList: [],
      copyDialog: false,
      copyTextarea: '',
      copyLoading: false,
      luggage:'',//行李额
      stopLoad: false,

      priceResult: {
        flightCalculates: []
      },

      dialogError2Visible: false,
      errorText: '',

      insuranceIdArr: [],

      hasAgreementPricePro: false, // 是否含有协议价产品
      dialogError3Visible: false,

      tmcSnailValuations: [],
    }
  },
  components: {
    CompanyInfo,
    NewChangeClause,
    CopyReportPopup,
    ChooseTraveler,
    TravelerListForReserve,
    InsureChoice,
    PolicyTypeLabel,
    DynamicForm,
    TravelerListForReservePlatform,
  },
  created() {
  },
  mounted() {
  },
  async activated() {
    // 获取代客的公司id
    this.companyId = this.$route.query.behalfCompanyId;
    this.redirectType = this.$route.query.redirectType;
    this.evectionNo = this.$route.query.evectionNo;
    this.orderNo = this.$route.query.orderNo;
    this.evectionType = parseInt(this.$route.query.evectionType);
    await this.getEvectionInfo();
    this.getAllPrice(); // 获取价格信息
    this.initInsure();

    consumer_flight_search_getEquityProductList({
      openChannelParam: 1,
      orderNo: this.orderNo,
    }).then(res => {
        this.equityList = res.datas;
        this.equityListBackups = this.equityList;
    });

    this.$nextTick(() => {
      this.$refs['chooseTraveler'].init({
        companyId: Number(this.companyId),
        businessType: 1,
        startTime: this.flightList[0].segmentList[0].depDate,
        flightTmcSnailValuations: this.tmcSnailValuations,
      });
      setTimeout(()=> {
        this.$refs.aDynamicForm.init({
          companyId: Number(this.companyId),
          // 业务类型：1机票、2火车票、3酒店、4汽车
          businessType: 1,
          showAttribution: true,
          // 是否使用默认数据
          mock_data: {
            enable: false,
          },
        });
      }, 500)
    })
  },
  deactivated() {
  },
  destroyed() {
  },
  watch: {},
  computed: {},
  filters: {
    filterWeek(val) {
      if (val === '') {
        return ''
      }
      let day = moment(val).day();
      if (day === 0) {
        return '周日'
      } else if (day === 1) {
        return '周一'
      } else if (day === 2) {
        return '周二'
      } else if (day === 3) {
        return '周三'
      } else if (day === 4) {
        return '周四'
      } else if (day === 5) {
        return '周五'
      } else if (day === 6) {
        return '周六'
      } else {
        return ''
      }
    },
  },
  methods: {
    changeEquity(val) {
      this.equityData = null;
      this.equityList.forEach(item => {
        if (item.productId == val) {
          this.equityData = item;
        }
      });
      this.getAllPrice();
    },
    blurEquity () {
      this.equityList = this.equityListBackups;
    },
    filterMethodEquity(val) {
      if (val) {
        let boxText = [];
        this.equityListBackups.forEach(item => {
          if (item.productFareBasis.indexOf(val) != -1 || item.productName.indexOf(val) != -1) {
            boxText.push(item);
          };
        });
        this.equityList = boxText;
      } else {
        this.equityList = this.equityListBackups;
      }
    },
    handleSelectCallback(value){
      let dataBox = {
        companyId: this.companyId,
        currentPage: 1,
        isSelectCustomer: true,
        key: '',
        pageSize: 10,
        userIds: [value.userId],
      };
      consumer_department_getStaffsAndExCusByUserIds(dataBox).then((res) => {
        this.$refs.chooseTraveler.setUserInfo(res.datas.list);
        this.$set(res.datas.list[0], 'trainAccountList', []);
        this.$set(res.datas.list[0], 'recommendAccount', {});
        let travelerInfo = this.$refs['chooseTraveler'].getTravelerInfo()
        this.evectionParams.linkman = travelerInfo.linkman
        this.evectionParams.linkmanPhone = travelerInfo.linkmanPhone
        this.evectionParams.smsType = travelerInfo.smsType
        this.evectionParams.linkmanSms = travelerInfo.linkmanSms
        this.evectionParams.userList = travelerInfo.userList
        let hasChild = false;
        this.evectionParams.userList.forEach(item=>{
          if (item.age < 13) {
            hasChild = true;

          }
        })
        if (hasChild) {
          this.$message({
            type: 'warning',
            message: '暂不支持在线预订儿童票'
          })
          return;
        }
        this.getAllPrice()
      })
    },
    handleSelectDelete(){
      this.$refs.chooseTraveler.setUserInfo([]);
    },
    getTravelerInfo(param) {
      console.log('travelerInfo', param);
      // if (param.userList.length > 9) {
      //     this.$message({
      //       type: "warning",
      //       message: '最多只能选9个出行人'
      //     });
      // }
      this.evectionParams.userList = param.userList;
      this.getAllPrice()
      // this.saveAndReactPrice()
    },
    // 保存并重新获取价格
    async saveAndReactPrice() {
      await this.saveInfo();
      await this.getAllPrice()
    },
    // 获取出差信息
    async getEvectionInfo() {
      this.loading = true;
      let [err, res] = await awaitWrap(consumer_admin_journey_getEvectionDetail({
        evectionNo: this.evectionNo,
        companyId: this.companyId,
        pageType: 1,
        personType: 2
      }));
      this.loading = false;
      if (err) {
        return
      }
      this.evectionInfo = res.datas;

      let userIdList = []
      this.evectionInfo.travellerList.forEach(value => {
        if (value) {
          userIdList.push(value.userId)
        }
      })

      this.evectionParams = {
        evectionNo: res.datas.evectionNo,
        evectionType: res.datas.evectionType,
        explainDesc: res.datas.explainDesc,
        feeAffiliationId: res.datas.feeAffiliationId,
        feeAffiliationName: res.datas.feeAffiliationName, // 费用归属名称
        feeAffiliationType: res.datas.feeAffiliationType ? res.datas.feeAffiliationType : 1,
        linkman: res.datas.linkman ? res.datas.linkman : '',
        linkmanPhone: res.datas.linkmanPhone ? res.datas.linkmanPhone : '',
        reason: res.datas.reason,
        // travelUserList: [],
        smsType: 1, // 是否发送短信， 1为是， 0为否
        linkmanSms: (res.datas.linkmanSms !== undefined && res.datas.linkmanSms !== null && res.datas.linkmanSms !== '') ? res.datas.linkmanSms : 1,
        userList: userIdList,
      };
      // 获取机票行程
      let flightList = res.datas.flightDetail ? res.datas.flightDetail.orderList : []
      let flightSegmentList = [];
      let index = 0;
      let tmcSnailValuations = [];
      flightList.forEach((value, index) => {
        value.segmentList.forEach(value1 => {
          if (value1.policyType === 'QCYX') {
            this.hasAgreementPricePro = true;
          }
          value1.loading = false
          if (res.datas.todFlightPolicy) {
            value1.todFlightPolicy = res.datas.todFlightPolicy[index]
          } else {
            value1.todFlightPolicy = {}
          }
          if (index === 0) {
              value1.label = '去程';
          } else {
            value1.label = '回程';
          }
          flightSegmentList.push(value1);
          index++;

          if (value1.tmcSnailValuations) {
            tmcSnailValuations = [...tmcSnailValuations, ...value1.tmcSnailValuations];
          }
        })
      })

      this.tmcSnailValuations = tmcSnailValuations;

      this.flightList = flightList;
      if (this.flightList.length > 0) {
        // this.stipulatesRule();
        this.showThisRuleList(0,0,this.flightList[0].segmentList[0],this.flightList[0]);
      }
      this.flightSegmentList = flightSegmentList;

      // = flightList[0].segmentList[0].luggage;
      console.log('luggage',this.luggage);
      //餐食
      this.queryMeals();
      //机型描述
      this.queryPlaneNames();

    },
    getStopMsg(val, index, segmentIndex) {
      let params = {
        "rqData": {
          "date": val.depDate,
          "flightNo": val.isShare == 1 ? val.shareFlightNo : val.flightNo,
          'depCityCode': val.depCityCode,
          'arrCityCode': val.arrCityCode,
          'arrTime': val.arrTime,
          'deptTime': val.depTime,
          'depAirCode': val.depCityPortCode,
          'arrAirCode': val.arrCityPortCode,
        }
      }
      this.stopLoad = true
      let flightsList = JSON.parse(JSON.stringify(this.flightList))
      consumer_flight_order_flightsStop(params).then(res => {
        flightsList[index].segmentList[segmentIndex].stopData = res.datas
        this.stopLoad = false
        this.flightList = flightsList
      }).catch(() => {
        this.stopLoad = false
        this.flightList = flightsList
      })
    },
    stipulatesRule(){
      if (this.flightList[0].segmentList != null&& this.flightList[0].segmentList.length != 0) {
        let data = {
          rqData: {
            "cabinCode": this.flightList[0].segmentList[0].cabinCode,
            "flightKey": this.flightList[0].segmentList[0].flightKey,
            "flightNo": this.flightList[0].segmentList[0].flightNo,
            "policyId": this.flightList[0].segmentList[0].policyId,
          }
        };
        consumer_flight_search_stipulatesRule(data).then(res => {
          // console.log(res);
          // debugger
        });
      }
    },
    showThisRuleList(fIndex, index, val, flight) {
      console.log(val)
      let orangeFlightList = JSON.parse(JSON.stringify(this.flightList))
      orangeFlightList[fIndex].segmentList[index].loading = true
      this.flightList = orangeFlightList
      let data = {
        rqData: {
          "arrCityPortCode": val.arrCityPortCode,
          "cabinCode": val.cabinCode,
          "depCityPortCode": val.depCityPortCode,
          "depDate": val.depDate,
          "flightNo": val.flightNo,
          "flightProductType": val.flightProductType,
          "flightType": flight.flightType,
          "psgType": flight.psgType,
          //extData: policyItem.extData,
          policyId: val.policyId,
          businessExt: this.evectionInfo.businessExt,
          companyId: this.companyId,
          segmentNo: val.segmentNo,
        }
      };
      consumer_flight_search_stipulates(data).then(res => {
        let ruleText = []
        let luggage = ''
        if (res.datas.rsData) {
          let refRule = res.datas.rsData.refRule;
          let chaRule = res.datas.rsData.chaRule;
          let refRuleTitArr = []
          let chaRuleTitArr = []
          let refRuleArr = []
          let chaRuleArr = []
          if (refRule && chaRule) {
            refRule = refRule.rules
            chaRule = chaRule.rules
            refRule.forEach(value => {
              value.type = "refRule"
              if (refRuleTitArr.indexOf(value.ruleTime) === -1) {
                refRuleTitArr.push(value.ruleTime)
                refRuleArr.push(value)
              }
            })
            chaRule.forEach(value => {
              value.type = "chaRule"
              if (chaRuleTitArr.indexOf(value.ruleTime) === -1) {
                chaRuleTitArr.push(value.ruleTime)
                chaRuleArr.push(value)
              }
            })
            refRuleArr.sort((a, b) => {
              return a.serial - b.serial
            })
            chaRuleArr.sort((a, b) => {
              return a.serial - b.serial
            })
            // refRule.map(item => (item.type = "refRule"));
            // chaRule.map(item => (item.type = "chaRule"));
            ruleText = [refRuleArr, chaRuleArr];
          }
          luggage = res.datas.rsData.luggage
        } else {
          ruleText = [];
          luggage = ''
        }
        console.log(luggage, ruleText)

        let flightList = JSON.parse(JSON.stringify(this.flightList))
        flightList[fIndex].segmentList[index].luggage = luggage;
        flightList[fIndex].segmentList[index].ruleText = ruleText;
        flightList[fIndex].segmentList[index].loading = false
        this.flightList = flightList;
        this.limitationTip = {
          numberLimit: res.datas.rsData.numberLimit,
          ageLimit: res.datas.rsData.ageLimit,
          CertificateLimit: res.datas.rsData.CertificateLimit,
          timeLimitQueue: res.datas.rsData.timeLimitQueue,
        };
      })
    },
    copyReport() {
      this.copyDialog = true
      this.copyLoading = true
      this.copyTextarea = ''

      let segmentList = []
      let promiseList = []
      this.flightList.forEach(value => {
        value.segmentList.forEach(value1 => {
          value1.flightType = value.flightType
          value1.psgType = value.psgType
          segmentList.push(value1)
          let data = {
            rqData: {
              "arrCityPortCode": value1.arrCityPortCode,
              "cabinCode": value1.cabinCode,
              "depCityPortCode": value1.depCityPortCode,
              "depDate": value1.depDate,
              "flightNo": value1.flightNo,
              "flightProductType": value1.flightProductType,
              "flightType": value.flightType,
              "psgType": value.psgType,
              businessExt: this.evectionInfo.businessExt,
              policyId: value1.policyId,
              companyId: this.companyId
            }
          };
          let priceParams = {
            businessType: 1,
            companyId: this.companyId,
            sum: value1.settlement
          }

          let stopParams = {
            "rqData": {
              "date": value1.depDate,
              "flightNo": value1.hasShare ? value1.sharedFlightNo : value1.flightNo,
              'depCityCode': value1.depCityCode,
              'arrCityCode': value1.arrCityCode,
              'arrTime': value1.arrTime,
              'deptTime': value1.depTime,
              'depAirCode': value1.depCityPortCode,
              'arrAirCode': value1.arrCityPortCode,
            }
          }
          promiseList.push(consumer_air_ticket_fligthLuggage(data))
          promiseList.push(consumer_admin_behalfCustomer_getServiceAmount(priceParams))
          promiseList.push(consumer_flight_order_flightsStop(stopParams))
        })
      })

      Promise.all(promiseList).then(res => {
        let arr = []
        for (let i = 0, l = res.length; i < l; i += 2) {
          arr.push(res.slice(i, i + 2))
        }
        let message = ``
        segmentList.forEach((value, index) => {
          let sumServicePrice = parseInt(arr[index][1].datas.sumServicePrice)
          const includeService = arr[index][0].datas.rsData.includedService
          const displayedService = arr[index][0].datas.rsData.displayedService
          const comparison = res[0].datas.rsData.comparison
          let luggageInfo = ''
          if (arr[index][0].datas && arr[index][0].datas.rsData) {
            luggageInfo = arr[index][0].datas.rsData
          }
          let stopCityInfo = ''
          if (res[2].datas && res[2].datas.stopInfos && 3 === res[2].datas.stopInfos.length) {
            stopCityInfo = res[2].datas.stopInfos[1]
          }
          message += this.setCopyText(value, sumServicePrice, luggageInfo, stopCityInfo, includeService,displayedService, comparison)
        })
        this.copyTextarea = message
      }).catch(() => {
        let message = ``
        segmentList.forEach((value, index) => {
          message += this.setCopyText(value, '', '', '', '','', 0)
        })
        this.copyTextarea = message
      }).finally(() => {
        this.copyLoading = false
      })
    },
    setCopyText(segment, servicePrice, luggageInfo, stopCityInfo, includeService = 0,displayedService = 0, comparison = 0) {
      let luggageInfoText = '';
      if (luggageInfo.luggage) {
        luggageInfoText = luggageInfo.luggage.replace(luggageInfo.rests, '');
      }
      let stopInfoText = '';
      console.log('stopInfo', stopCityInfo)
      if (stopCityInfo) {
        let minutes = moment(this.$moment(segment.depDate + ' ' + stopCityInfo.depTime + ':00')).diff(moment(this.$moment(segment.depDate + ' ' + stopCityInfo.arrTime + ':00')),'minutes' )
        stopInfoText += '(经停' + stopCityInfo.cityName + minutes + 'min)';
      }
      let settlementPriceTaxes = 0;
      settlementPriceTaxes = settlementPriceTaxes + (segment.settlement || 0) + (segment.airportTax || 0) + (segment.fuelTax || 0);
      if (includeService !== 1) {
        settlementPriceTaxes = settlementPriceTaxes + (segment.serviceFee || 0);
      }
      let message = '';
      message += `${segment.depDate} ${segment.airCompanyName} ${segment.flightNo}  ${segment.depCityName}（${segment.depAirportName}${segment.depTerminal || ''}）-${segment.arrCityName}（${segment.arrAirportName}${segment.arrTerminal || ''}） ${segment.depTime}-${segment.arrTime} ${stopInfoText}\n`
      message += `铨成结算价：￥${segment.settlement}/人 机建：￥${segment.airportTax}/人 燃油税：￥${segment.fuelTax}/人${displayedService == 1 ? ` 服务费：￥${segment.serviceFee}/人` : ''}\n`;
      // message += `总计：￥${segment.settlementPriceTaxes}/人 ${includeService === 1 ? '（不含服务费）' : ''}\n`;
      message += `总计：￥${settlementPriceTaxes}/人 ${includeService === 1 ? '（不含服务费）' : ''}\n`;
      if (comparison === 1) {
        message += `携程对比价：￥\n`;
      }
      const behalfCompanyId = parseInt(this.$route.query.behalfCompanyId);
      console.log(segment);
      if ([250, 357, 217, 274, 275, 276, 277, 278, 279, 280, 281, 282, 370, 394].indexOf(behalfCompanyId) > -1) {
        message += `舱位：${segment.discount}折${segment.cabinName}\n`;
      } else {
        message += `舱位：${segment.cabinName}\n`;
      }

      message += `行李规定：${luggageInfoText || '--'}\n`;
      if (this.companyId != '81') {
        message += '是否符合差标请自行确认\n';
      }
      message += `价格实时浮动，如需出票请尽快确认\n`;
      if (this.companyId == '81') {
        message += '如需预定请确认行程报价是否与贵司差标相符'
      }
      return message
    },
    // 获取订单所有价格
    async getAllPrice(seatPrice, orderNo) {
      let params = {
        companyId: this.companyId,
        behalfCompanyId: this.companyId,
        evectionNo: this.evectionNo,
        headCounts: this.evectionParams.userList.length,
        seatPrice,
        orderNo,
        packageCodes: this.insuranceIdArr,
        productIds: this.equityData == null ? [] : [this.equityData.productId],
      };
      let res = await consumer_admin_journey_calculatePrice(params);
      let priceResult = res.datas;
      let flightRebatePrice = 0;
      let flightAirportTaxAndFuelTax = 0;
      let flightSubtotal = 0;
      priceResult.flightCalculates.forEach(value => {
        flightRebatePrice += parseFloat(value.rebatePrice);
        flightAirportTaxAndFuelTax = flightAirportTaxAndFuelTax + parseFloat(value.airportTax) + parseFloat(value.fuelTax)
        flightSubtotal += parseFloat(value.subtotal)
      })
      priceResult.flightRebatePrice = flightRebatePrice;
      priceResult.flightAirportTaxAndFuelTax = flightAirportTaxAndFuelTax
      priceResult.flightSubtotal = flightSubtotal;
      this.priceResult = priceResult
    },
    timeLongTips(time) {
      return new Promise((resolve, reject) => {
        if (time && time >= 20) {
          this.$confirm('该订单支付后预计30分钟内出票，是否继续预订？', '温馨提示',{
            confirmButtonText: '继续预订',
            cancelButtonText: '取消',
          })
              .then(() => {
                // on confirm
                resolve(true);
              })
              .catch(() => {
                // on cancel
                resolve(false);
              });
        } else {
          resolve(true)
        }
      })
    },
    toCabinList() {
      this.dialogError3Visible = false;
      this.$router.go(-1);
    },
    async createOrder(lowPriceSwitch) {
      //无自营价格只能出飞常准携程运价，且该运价出票时限大于20分钟，点击创建订单时，弹出提示
      let timeTipsRes = await this.timeLongTips(this.$route.query.ticketLimitTime);
      let switchLprList = false;
      if (!timeTipsRes) {
        return;
      }
      let travelerInfo = this.$refs['chooseTraveler'].getTravelerInfo()
      this.evectionParams.linkman = travelerInfo.linkman
      this.evectionParams.linkmanPhone = travelerInfo.linkmanPhone
      this.evectionParams.smsType = travelerInfo.smsType
      this.evectionParams.linkmanSms = travelerInfo.linkmanSms
      this.evectionParams.userList = travelerInfo.userList
      let hasChild = false;
      let hasDifferentAge = false;
      let hasDifferentAgeNameList = [];

      let hasUserCardErr = false;
      let userErrCardTips = '';
      let userCardErrName = [];
      let hasUserAgeErr = false;
      let userErrAgeTips = '';
      let userAgeErrName = [];
      let hasUserLengthErr = false;
      let userLengthErrTips = '';
      if (!this.evectionParams.linkman) {
        this.$message({
          type: 'warning',
          message: '请填写联系人'
        })
        return;
      }
      if (!this.evectionParams.linkmanPhone) {
        this.$message({
          type: 'warning',
          message: '请填写联系人电话'
        })
        return;
      }
      this.evectionParams.userList.forEach(item=>{
        if (item.age < 12) {
          hasChild = true;
        }
        if (item.age < 22 || item.age > 65) {
          hasDifferentAge = true;
          hasDifferentAgeNameList.push(item.frequentPassengerName || item.userName);
        }
        if (item.cardErrShow) {
          hasUserCardErr = true;
          userErrCardTips = item.cardErrTips;
          userCardErrName.push(item.name);
        }
        if (item.ageErrShow) {
          hasUserAgeErr = true;
          userErrAgeTips = item.ageErrTips;
          userAgeErrName.push(item.name);
        }
        this.tmcSnailValuations.forEach(value => {
          if (value.passengerNum !== '1,9') {
            let passengerNum = value.passengerNum.split(',');
            const userLength = this.evectionParams.userList.length;
            if (passengerNum[0] > userLength || passengerNum[1] < userLength) {
              hasUserLengthErr = true;
              userLengthErrTips = `该产品仅限${passengerNum[0]}-${passengerNum[1]}人预订，当前出行人数不符合条件`
            }
          }
        })
      })
      if (hasChild) {
        this.$message({
          type: 'warning',
          message: '暂不支持在线预订儿童票'
        })
        return;
      }
      if (this.hasAgreementPricePro && hasDifferentAge) {
        this.errorText = `协议价仅限乘客年龄22至65周岁预订，乘客${hasDifferentAgeNameList.join('，')}年龄不符合要求，请选择其他非协议价预订`;
        this.dialogError3Visible = true;
        return;
      }
      if (this.flightList.length === 0) {
        this.$message({
          type: 'warning',
          message: '请先添加行程'
        })
        return
      }
      if (this.evectionParams.userList.length === 0) {
        this.$message({
          type: 'warning',
          message: '请先添加出行人'
        })
        return
      }
      if (this.evectionParams.userList.length > 9) {
        this.$message({
          type: 'warning',
          message: '最多只能添加9位出行人'
        })
        return
      }
      if (this.evectionParams.linkman === '' || this.evectionParams.linkmanPhone === '') {
        this.$message({
          type: 'warning',
          message: '请先填写联系人'
        })
        return
      }

      let errText = ``;
      if (hasUserLengthErr) {
        errText += `${userLengthErrTips}\/n`;
      }
      if (hasUserAgeErr) {
        errText += `${userErrAgeTips},当前出行人${userAgeErrName.join(',')}不符合要求\/n`;
      }
      if (hasUserCardErr) {
        errText += `${userErrCardTips},当前出行人${userCardErrName.join(',')}不符合要求\/n`;
      }
      if (errText) {
        this.$alert(errText, '温馨提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
        return
      }

      // 动态表单
      let formInfo = this.$refs.aDynamicForm.getData();
      if (!formInfo.checkForm) {
        const listLength = formInfo.list.length;
        for (let i = 0; i < listLength; i++) {
          if (!formInfo.list[i].parameterForm) {
            const text = `请填写${formInfo.list[i].parameterText}`;
            this.$message({
              type: "warning",
              message: text
            });
            break
          }
        }
        return
      }

      // 低价精选--提醒
      const lprList = ['YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL13','OPL14','OPL34','OPL1','SGL1','OPL26','OPL30'];
      this.flightList.forEach((item,index) => {
        item.segmentList.forEach((SItem,SIndex) => {
          if (lprList.indexOf(SItem.policyType) != -1 && lowPriceSwitch) {
            switchLprList = true;
            this.$confirm(
              this.limitationTip.CertificateLimit  ?
                `<p>本产品为低价精选产品，最晚于起飞前${this.limitationTip.timeLimitQueue}出票，报销凭证提供行程单+差额发票。</p><p>证件限制:${this.limitationTip.CertificateLimit}</p>` :
                `<p>本产品为低价精选产品，最晚于起飞前${this.limitationTip.timeLimitQueue}出票，报销凭证提供行程单+差额发票。</p><p></p>`,
              '温馨提示', {
                dangerouslyUseHTMLString: true,
                distinguishCancelAndClose: true,
                confirmButtonText: '继续预订',
                cancelButtonText: '重新选择价格',
                callback: action => {
                  if (action == 'confirm') {
                    this.createOrder(false);
                  }
                }
              });
            return;
          };
        });
      });

      if (switchLprList) {
        return;
      }

      let loading
      loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      let saveResult = await this.saveInfo();
      if (!saveResult) {
        loading.close();
        return
      }
      let [err, result] = await awaitWrap(consumer_admin_flight_createOrder({
        evectionNo: this.evectionNo,
        orderNo: this.orderNo,
        reportConfiguration: formInfo.list,
        equityProductId: this.equityData == null ? null : this.equityData.productId,
        equityProductPrice: this.equityData == null ? null : this.equityData.farePat,
      }));
      if (err) {
        loading.close()
        let errorCode = err.data.code;
        let orderResultList = null
        if (err.data.datas) {
          let orderResultList = err.data.datas.orderResultList
        }
        orderResultList = err.data.datas.orderResultList
        this.errorText = err.data.msg;
        this.dialogError2Visible = true
        if (!orderResultList) {
          return
        }
        orderResultList.forEach(value => {
          if (!value.isSuccess) {
            let [...flightList] = this.flightList
            flightList.forEach(value1 => {
              if (value1.journeyNo === value.orderNo) {
                value1.showError = true
                value1.errorReason = value.desc
              }
            })
            this.flightList = flightList
          }
        })
        return
      }
      let orderResult = result.datas
      let canSubmit = true
      let desc = ''
      let paymentPage = 0
      orderResult.orderResultList.forEach((value, index) => {
        if (index === 0) {
          desc = value.desc
          paymentPage = value.paymentPage
        }
        if (!value.isSuccess) {
          canSubmit = false
          let [...flightList] = this.flightList
          flightList.forEach(value1 => {
            if (value1.journeyNo === value.orderNo) {
              value1.showError = true
              value1.errorReason = value.desc
            }
          })
          this.flightList = flightList
        }
      })
      if (canSubmit) {
        // 保存所选保险
        const params = {
          companyId: this.companyId
        }
        this.$refs.aInsureChoice.submit(params).then(res => {
          loading.close()
          if (paymentPage) {
            this.$alert(desc, '温馨提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.$router.push({
                  name: 'admin-flight-payment',
                  query: {
                    evectionNo: this.evectionNo,
                    orderNo: this.orderNo,
                    companyId: this.companyId,
                  }
                })
              }
            })
            return
          }
          this.$router.push({
            name: 'admin-flight-payment',
            query: {
              evectionNo: this.evectionNo,
              orderNo: this.orderNo,
              companyId: this.companyId,
            }
          })
        }).catch(() => {
          loading.close()
        });

      } else {
        loading.close()
        if (paymentPage) {
          this.$alert(desc, '温馨提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          })
        } else {
          this.$message({
            type: 'warning',
            message: '订票失败，请重新选择行程'
          })
        }

      }
    },
    // 保存信息
    async saveInfo() {
      let formMessage = this.$refs.aDynamicForm.getData();
      let projectCode = '';
      let projectName = '';
      formMessage.list.forEach(value => {
        if (value.parameter == 'GuiShuXiangMu') {
          projectCode = value.parameterValue;
          projectName = value.parameterText;
        }
      });

      let params = Object.assign({}, this.evectionParams)
      // 归属项目
      params.projectCode = projectCode;
      params.projectName = projectName;

      let [err, res] = await awaitWrap(consumer_journey_saveOrderMsg(params))
      if (err) {
        return false
      }
      return res
    },
    setTypeLabelColor(val) {
      //"supplier": "1,2,3,4,12,13,9,OPL9,SGL1,OPL6,OPL11,OPL18,OPL17,OPL20,OPL16,OPL26,OPL23,OPL30,60,FD,NFD,VARI",
      //"supplierText": "其他渠道(供应商)【平台优选】",
      // "bigCustomers": "QCYX",
      //"bigCustomersText": "企业协议(大客户)",
      //"lowPrice": "41,42,43,44,45,26,YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL1,NSTD,62",
      //"lowPriceText": "低价推荐",
      // "website": "21,22,23,24,25,6,CXF1,WS",
      //"websiteText": "航司官网(官网)",
      //"platformProprietary": "61",
      //"platformProprietaryText": "商旅优选(自营)【商旅推荐】"
      const btoList = ['1', '2', '3', '4', '12', '13', '9', 'OPL9', 'SGL1', 'OPL6', 'OPL11', 'OPL18', 'OPL17', 'OPL20', 'OPL16', 'OPL26', 'OPL23', 'OPL30', '60', 'FD', 'NFD', 'VARI']
      const lprList = ['41', '42', '43', '44', '45', '26', 'YPL1', 'FPL1', 'BPL1', 'OPL4', 'OPL3', 'OPL5', 'OPL25', 'SPL1', 'OPL21', 'OPL15', 'OPL1', 'NSTD', '62']
      const owList = ['21', '22', '23', '24', '25', '6', 'CXF1', 'WS']
      const qcList = ['QCYX']
      const ptList = ['61']
      if (btoList.indexOf(val) > -1) {
        return 'type1'
      } else if (lprList.indexOf(val) > -1) {
        return 'type2'
      } else if (owList.indexOf(val) > -1) {
        return 'type3'
      } else if (qcList.indexOf(val) > -1) {
        return 'type4'
      } else if (ptList.indexOf(val) > -1) {
        return 'type5'
      } else {
        return 'type6'
      }
    },
    initInsure() {
      this.$refs.aInsureChoice.init({
        mock_data: {
          //是否使用随机数据--true/false
          enable: false,
        },
        //业务类型--1：机票/2：火车票
        businessType: 1,

        tickType: 0,

        //订单号
        orderNo: this.orderNo,
        flightType: this.flightList[0].flightType,
      });
    },
    changeInsure(data) {
      console.log('选中保险', data)
      this.insuranceIdArr = data.id_list
      this.getAllPrice()
    },
    showInsurance(val) {
      let isShow = false
    },
    showPackage(val) {
      let show = false
      if (val && val.length > 0) {
        val.forEach(value => {
          if (value.bookingInsuranceInfos && value.bookingInsuranceInfos.length > 0) {
            show = true
          }
        })
      }
      return show
    },
    showEquity(val) {
      let show = false
      if (val && val.length > 0) {
        val.forEach(value => {
          if (value.bookingEquityProductInfos && value.bookingEquityProductInfos.length > 0) {
            show = true
          }
        })
      }
      return show
    },
    queryMeals() {
      let segmentList =  this.flightList[0].segmentList
      let promises = [];
      for (let i = 0; i < segmentList.length; i++) {
        let val = this.flightList[0].segmentList[i];
        let param = {
          flightNo: val.flightNo,
          deptTime: val.depTime,
          arrTime: val.arrTime
        };
        promises.push(consumer_flight_search_queryMeals(param));
      }
      if(promises.length>0){
        Promise.all(promises).then( (posts) =>{
          for (let i = 0; i < segmentList.length; i++) {
            let meals = posts[i].datas;
            this.flightList[0].segmentList[i].hasMeals = meals ? '有餐食':'无餐食';
          }
          // this.detail = detail;
          this.$forceUpdate();
        }).catch(function(reason){
          console.log('出错了',reason)
        });
      }
    },
    queryPlaneNames(){
        const planeTypes = this.flightList[0].segmentList.map(e => {
            return e.planeType
        })
        consumer_flight_search_queryPlaneNameByCodes(planeTypes).then(res=>{
            console.log("planeTypes",res);
            let planeMap = new Map();
            if(res.datas){
                res.datas.forEach(i=>{
                    planeMap.set(i.planeType,i.planeName);
                })
            }
            this.flightList[0].segmentList.forEach(i=>{
                let planeName = planeMap.get(i.planeType);
                i.planeName = planeName? planeName:i.planeType;
            })
            this.$forceUpdate();

        }).catch(err=>{
            console.log('机型查询出错了',err);
        })
    },
    showLuggage(fIndex, index){
      //行李额
      this.luggage = this.flightList[fIndex].segmentList[index].luggage;
      console.log("luggage",this.luggage)
    }

  }
}

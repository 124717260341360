// 省份市级数据
import consumer_web_city_provinceCity from "@/lib/data-service/haolv-default/consumer_web_city_provinceCity.js";
// 区数据
import consumer_web_city_areaLinkage from "@/lib/data-service/haolv-default/consumer_web_city_areaLinkage.js";

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    layout: {
      type: String,
      required: false,
      defult: "province, city, area",
    },
    // 传入在父级的验证规则
    rules: {
      type: Object,
      required: false,
    },
  },
  computed: {
    myLayout() {
      if (this.layout) {
        return this.layout.replace(/\s/g, "").split(",");
      } else {
        return ["province", "city", "area"];
      }
    },
  },
  data() {
    return {
      form: {
        areaProvince: "",
        areaProvinceName: "",
        areaCity: "",
        areaCityName: "",
        areaRegion: "",
        areaRegionName: "",
      },
      allocation: {
        provinceList: [],
        cityList: [],
        areaList: [],
      },
    };
  },
  methods: {
    req_province_city_data(type, pParams) {
      consumer_web_city_provinceCity(pParams).then((res) => {
        const allocation = this.allocation;
        const list = res.datas;
        if (type === 1) {
          list.forEach((item) => {
            item.name = item.name.replace(/[a-zA-Z]/, "");
          });
          allocation.provinceList = list;
        } else if (type === 2) {
          list[0].childList.forEach((item) => {
            item.name = item.name.replace(/[a-zA-Z]/, "");
          });
          allocation.cityList = list[0].childList;
        }
      });
    },
    req_area_data(params) {
      consumer_web_city_areaLinkage(params).then((res) => {
        res.datas.forEach((item) => {
          item.name = item.name.replace(/[a-zA-Z]/, "");
        });
        this.allocation.areaList = res.datas;
      });
    },
    init(params) {
      const areaProvince = params.areaProvince;
      const areaCity = params.areaCity;
      const areaRegion = params.areaRegion;
      const form = this.form;
      form.areaProvince = areaProvince;
      form.areaCity = areaCity;
      form.areaRegion = areaRegion;
      if (form.areaProvince)
        this.req_province_city_data(2, { provinceId: form.areaProvince });
      if (form.areaCity)
        this.req_area_data({
          areaType: 1,
          levelType: 4,
          parentId: form.areaCity,
        });
    },
    change_province(val) {
      const form = this.form;
      const allocation = this.allocation;
      if (this.myLayout.includes("city")) {
        allocation.cityList = [];
        allocation.areaList = [];
        form.areaCity = "";
        form.areaCityName = "";
        form.areaRegion = "";
        form.areaRegionName = "";
        this.req_province_city_data(2, { provinceId: val });
      }
      const list = this.allocation.provinceList;
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (val === item.id) return (this.form.areaProvinceName = item.name);
      }
    },
    change_city(val) {
      const form = this.form;
      const allocation = this.allocation;
      if (this.myLayout.includes("area")) {
        allocation.areaList = [];
        form.areaRegion = "";
        form.areaRegionName = "";
        this.req_area_data({ areaType: 1, levelType: 4, parentId: val });
      }
      const list = this.allocation.cityList;
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (val === item.id) return (this.form.areaCityName = item.name);
      }
    },
    change_area(val) {
      const list = this.allocation.areaList;
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (val === item.id) return (this.form.areaRegionName = item.name);
      }
    },
  },
  created() {
    this.req_province_city_data(1, {});
  },
  mounted() {},
  activated() {},
  deactivated() {},
  watch: {
    form: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  filters: {},
};

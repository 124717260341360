// 酒店创建预订单 http://219.128.52.2:18765/doc.html#/haolv-consumer/t-od-hotel-order-interim-controller/addOrUpdUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_hotel_order_addOrUpd = (pParams) => {

  const p = {
    method: 'post',
    urlSuffix: '/consumer/admin/hotel/order/addOrUpd',
    data: pParams,
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_admin_hotel_order_addOrUpd;
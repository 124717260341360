export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/hotel-detail',
            name: 'admin-hotel-detail',
            component: () => import(/* webpackChunkName: "page-admin-replace-orders-hotel-detail" */ `./1.1.2/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `酒店详情`,
                },
                entity_list_for_breadcrumb: [
                    { name: `代客下单` },
                    { name: `酒店搜索` },
                    { name: `酒店详情` },
                ],
            },
        },
    ],
};

export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/train-list',
            name: 'admin-train-order-list',
            component: () => import(/* webpackChunkName: "page-admin-order-management-train-train-list" */ `./1.0.4/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `已销售的火车票`,
                },
                entity_list_for_breadcrumb: [
                    { name: `火车票订单` },
                    { name: `已销售的火车票` },
                ],
            },
        },
    ],
};

//  consumer_log_hotelList 代客下单-获取酒店订单日志
const __request = require(`./__request/__request_contentType_json`);
const consumer_log_hotelList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/log/hotelList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_log_hotelList;

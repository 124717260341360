import Q from 'q';
import CompanyInfo from '@/component/company-info/import_latest_version_module'
import PaymentMethodSelector010
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.11/payment-method-selector-010';
import consumer_admin_extra_details from "@/lib/data-service/haolv-default/consumer_admin_extra_details";   // 订单详情
import consumer_admin_flight_generate_certificateType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType';
import consumer_admin_flight_generate_cabinRank from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_cabinRank'
import consumer_admin_extra_action from '@/lib/data-service/haolv-default/consumer_admin_extra_action'
import consumer_flight_search_citys from "@/lib/data-service/haolv-default/consumer_flight_search_citys";
import DictionarySelect
    from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';
import moment from "moment";
export default {
    data() {
        return {
            loading: false,
            id: '',
            orderNo: '',
            payType: '1', // 1支付中 2 无需支付 3 待支付
            payTypeForChannel: 1,
            detail: {},
            orderDetail:{},
            order: {},
            companyInfo: {},
            companyBills: {},
            // journeyInfo: {},
            flightInfo: {},
            totalAmount: 0,
            airlineCodeList: [],
            moment: moment,
            companyTypeList: [
                {label: '成人', value: 1},
                {label: '儿童', value: 2},
                {label: '婴儿', value: 3},
                {label: '老人', value: 4},
            ], // 客户类型 1成人 2儿童 3婴儿 4 老人
            certificateTypeList: [],
            // 舱位等级List
            cabinRankList: [],
        }
    },
    components: {CompanyInfo, DictionarySelect, PaymentMethodSelector010},
    activated() {
        this.init();
    },
    methods: {
        //初始化
        async init() {
            const query = this.$route.query;
            this.id = query.id;
            this.orderNo = query.orderNo;
            // this.initDictionarySelect();
            await this.getCabinRank();
            this.getCertificateType();
            this.initCity();
            await this.getDetail();

        },

        //初始化字典组件
        initDictionarySelect() {
            Dictionary.init(this, {
                typeGroupKey: ['tmc'],
                typeKey: [],
                parentId: 0
            });
        },

        getCabinRank() {
            return consumer_admin_flight_generate_cabinRank().then(res => {
                this.cabinRankList = res.datas
            })
        },

        setCabinRank(val) {
            let box = {};
            this.cabinRankList.forEach((item,index) => {
                if (item.code == val) {
                    box = item;
                }
            });
            return box.msg;
        },

        getCertificateType() {
            consumer_admin_flight_generate_certificateType().then(res => {
                let certificateTypeList = res.datas;
                this.certificateTypeList = [];
                certificateTypeList.forEach(value => {
                    if (value.msg == '护照' || value.msg == '身份证') {
                        value.value = parseInt(value.code);
                        this.certificateTypeList.push(value);
                    }
                });
            })
        },

        // 获取城市机场列表
        initCity() {
            consumer_flight_search_citys({
                currentPage: 1,
                pageSize: 999,
            }).then(res => {
                res.citys.forEach((item,index) => {
                    if (item.airports) {
                        this.airlineCodeList.push(item);
                    }
                });
            });
        },

        //获取详情
        async getDetail() {
            try {
                this.loading = true
                const res = await consumer_admin_extra_details({
                    id: this.$route.query.id,
                    orderNo: this.$route.query.orderNo
                })
                this.orderDetail = res.datas;
                this.orderDetail.order.orderNo = this.$route.query.orderNo;
                this.order = this.orderDetail.order;
                this.companyInfo = this.orderDetail.companyInfo;
                this.companyBills = this.orderDetail.companyBills;
                // this.journeyInfo = this.orderDetail.journeyInfo;
                this.flightInfo = this.orderDetail.flightInfos;
                this.remark = this.orderDetail.order.remark;

                let orderSourceText = await Dictionary.returnDictionary('tmc', 'extraOrderSource', this.orderDetail.order.orderSource);
                this.orderDetail.order.orderSourceText = orderSourceText.name;

                /*let paymentTypeText = await Dictionary.returnDictionary('tmc', 'paymentType', this.orderDetail.order.paymentType);
                this.orderDetail.order.paymentTypeText = paymentTypeText.name;

                let carUseTypeText = await Dictionary.returnDictionary('tmc', 'carUseType', this.orderDetail.journeyInfo.carUseType);
                this.orderDetail.journeyInfo.carUseTypeText = carUseTypeText.name;

                let defCarTypeText = await Dictionary.returnDictionary('tmc', 'defCarType', this.orderDetail.journeyInfo.defCarType);
                this.orderDetail.journeyInfo.defCarTypeText = defCarTypeText.name;*/

                //visaCompanyInfo.payType  1：授信支付 2：现结支付 3：线下转账
                if (this.companyInfo.payType === 2) {//现结支付
                    this.payType = '3'
                    this.$nextTick(() => {
                        this.initPayment()
                    })
                } else {
                    if (this.order.status === 0) {//待支付
                        this.payType = '2'
                    }
                }
            } catch (error) {
                console.log(error);
                this.$message({
                    type: "error",
                    message: "获取线下用车订单详情失败！"
                })
            } finally {
                this.loading = false
            }
        },

        //初始化支付组件
        initPayment() {
            let _this = this;
            let orderForPayVos = [{
                orderNo: _this.orderNo,
                businessContent: '',
                businessType: 11
            }];
            _this.$refs.aPaymentMethodSelector010.init({
                page_this: _this,

                //在这里传入订单详情的相关参数
                get_params() {
                    const p = {
                        "orderForPayVos": orderForPayVos,
                        "payType": _this.payTypeForChannel,
                    };

                    return Q.resolve(p);
                },
                //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                before_pay_event_handler() {
                    return Q.resolve();
                },
                //支付成功事件处理方法
                pay_successful_event_handler() {
                    console.log(`pay_successful_event_handler`);
                    _this.$message({
                        type: 'success',
                        message: '支付成功!'
                    });
                    _this.$router.replace({
                        name: 'admin-extra-order-list'
                    })
                },
                //支付失败事件处理方法
                pay_failed_event_handler() {
                    console.log(`pay_failed_event_handler`);
                },
                //取消订单按钮单击事件处理方法
                btn_cancel_order_click_event_handler(args) {
                    const show_loading = args.show_loading;
                    const hide_loading = args.hide_loading;
                    const stop_count_down = args.stop_count_down;
                    const enable_btn_pay = args.enable_btn_pay;
                    const enable_btn_cancel_order = args.enable_btn_cancel_order;

                    _this.$confirm("确定取消此附加业务订单？", "取消提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        Q.when().then(function () {
                            show_loading();
                            let params = {
                                id: _this.$route.query.id,
                                orderNo: _this.orderNo,
                                status: 3
                            };
                            return consumer_admin_extra_action(params);
                        }).then(function () {
                            console.log(`btn_cancel_order_click_event_handler`);
                            hide_loading();
                            stop_count_down();
                            enable_btn_pay({
                                enable: false,
                            });
                            enable_btn_cancel_order({
                                enable: false,
                            });
                            _this.$message({
                                type: 'success',
                                message: '取消成功!'
                            });
                            _this.$router.replace({
                                name: 'admin-extra-order-list',
                                query: {
                                    behalfCompanyId: _this.$route.query.behalfCompanyId,
                                },
                            })
                        }).catch(function (ex) {
                            if (ex) {
                                const enable_log_error = !ex.disable_log_error;
                                if (enable_log_error) {
                                    console.error(ex);
                                    console.trace();
                                    if (ex.stack) {
                                        console.error(ex.stack);
                                    }
                                }
                            }
                            return Q.reject(ex);
                        });
                    }).catch(() => {
                    })


                },
                //获取支付方式接口，返回code=300052时，弹窗的确定按钮单击处理处理方法
                dialog_code_300052_btn_ok_click_event_handler() {
                    console.log(`dialog_code_300052_btn_ok_click_event_handler`);
                    _this.$router.replace({
                        name: 'admin-extra-order-list',
                    });
                    return Q.resolve(true);
                    //return true;
                },
            });
        },

        //提交
        submit() {
            this.loading = true;
            let params = {
                id: this.$route.query.id,
                orderNo: this.$route.query.orderNo,
                status: 2
            };
            consumer_admin_extra_action(params).then((res) => {
                this.$message({
                    type: 'success',
                    message: '确认成功!'
                });
                this.$router.replace({
                    name: 'admin-extra-order-list'
                })
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        },

        //返回
        backToList() {
            this.$router.replace({
                name: 'admin-extra-order-list'
            })
        },

        onCancelOrder() {
            this.$confirm("确定取消此附加业务订单？", "取消提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.loading = true;
                let params = {
                    id: this.id,
                    orderNo: this.orderNo,
                    status: 3
                };
                consumer_admin_extra_action(params).then((res) => {
                    this.$message({
                        type: 'success',
                        message: '取消订单成功！'
                    })
                    this.backToList()
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                })
            }).catch(() => {
            })
        },
    }
}

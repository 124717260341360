export default {
  certNum: '证件号',
  expectTime: '预计出签时间',
  salesPrice: '销售价',
  supPrice: '采购价',
  serviceFee: '服务费',
  userName: '客户姓名',
  companyId: '所属企业',
  contactMobile: '联系电话',
  contactName: '联系人',
  countryName: '签证国家',
  name: '签证名称',
  orderSource: '采购渠道',
  paymentNum: '付款流水号',
  paymentTime: '付款时间',
  paymentType: '付款方式',

  visaCompanyInfo: '客户基础信息',
  visaOrder: '签证信息',
  visaCompanyBill: '客户信息',
}
import _ from 'underscore'
import extend from 'extend'
import Q from 'q'
import Vue from 'vue'
import Router from 'vue-router'
import to_boolean from 'nllib/src/convert/to_boolean'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

Vue.use(Router);

// console.log(JSON.stringify(process.env, null, 4));

const get_options = function () {
    const VUE_APP_vue_router_history_enable = to_boolean(process.env.VUE_APP_vue_router_history_enable, false);
    const o = {
        routes: [],
    };
    if (VUE_APP_vue_router_history_enable) {
        o.mode = `history`;
        o.base = process.env.BASE_URL;
    }
    return o;
};
const options = get_options();

const aRouter = new Router(options);

export default aRouter;


import web_company_getAllCompanys from '@/lib/data-service/haolv-default/web_company_getAllCompanys.js';

export default {
    name: 'releaseScopeBox',
    data () {
        return {
            showPop: false,
            resultList: [],
            resultIdList: [],
            checkAll: false,
            isIndeterminate: true,
            checkList: []
        }
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        currentItem: {
            type: String,
            default: ''
        }
    },
    methods: {
        getAllCompany() {
            web_company_getAllCompanys().then(res => {
                this.resultList = res.datas;
                this.resultList.forEach(res => {
                    this.resultIdList.push(res.id)
                })
            })
        },
        handleCheckAllChange(val) {
            this.checkList = val ? this.resultIdList : [];
            this.isIndeterminate = false;
            this.filterStr();
        },
        handleCheckGroupChange(val) {
            if (val.length !== this.resultIdList.length) {
                this.isIndeterminate = true;
            } else {
                this.isIndeterminate = false;
            }
            this.filterStr();
        },
        filterStr() {
            const currentList = [];
            this.checkList.forEach(res => {
                this.resultList.forEach(item => {
                    if (res === item.id) {
                        currentList.push(item);
                    }
                })
            });
            this.currentList = this.checkList;
            this.current = currentList;
        },
        toggleShowPop() {
            if (this.disable) {
                return
            }
            this.showPop = !this.showPop
        },
        clickReserveWrapper(e) {
            if (!!this.$refs.releaseScopeBox.contains(e.target)) return;
            this.showPop = false
        },
        // del() {
        //     this.current = null;
        //     this.checkList = [];
        // }
    },
    activated() {
        this.getAllCompany();
    },
    mounted () {
        document.addEventListener('click', this.clickReserveWrapper);
    },
    destroyed () {
        document.removeEventListener('click', this.clickReserveWrapper);
    },
    computed: {
        currentList : {
            get () {
                return this.list;
            },
            set (val) {
                this.$emit('update:list', val);
            }
        },
        current: {
            get () {
                return this.currentItem;
            },
            set (val) {
                const list = [];
                let item = '';
                val.forEach(res => {
                    list.push(res.abbreviation)
                });
                item = list.toString();
                this.$emit('update:currentItem', item);
            }
        }
    },
}
const __request = require(`./__request/__request_contentType_json`);
// 【391】线下单退票确认
// POST /consumer/admin/trains/order/trainsRefundConfirm
// 接口ID：49728366
// 接口地址：https://www.apifox.cn/web/project/458418/apis/api-49728366
const consumer_admin_trains_order_trainsRefundConfirm = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/trains/order/trainsRefundConfirm',
        data: pParameter
    };
    return new Promise(function (resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_trains_order_trainsRefundConfirm;
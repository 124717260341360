export default {
    path: "",
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: "/admin/credit-flow-list",
            name: "admin-credit-flow-list",
            component: () => import(/* webpackChunkName: "page-admin-credit-management-credit-flow-list" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `授信账单详情`,
                },
                entity_list_for_breadcrumb: [
                    { name: `授信管理` },
                    { name: `授信商户详情` },
                    { name: `授信账单详情` },
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        return `admin-credit-users-detail`;
                    },
                },
            },
        },
    ],
};

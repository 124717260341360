import consumer_admin_flight_refund_newdetails
    from '@/lib/data-service/haolv-default/consumer_admin_flight_refund_newdetails'
import consumer_air_ticket_fligthRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules"
import consumer_admin_flight_refund_selfSupportRefundOrder
    from '@/lib/data-service/haolv-default/consumer_admin_flight_refund_selfSupportRefundOrder'
import NewChangeClause from "@/page/admin/order-management/flight/component/newChangeClause/index.vue"
import NewLuggageClause from "@/page/admin/order-management/flight/component/newLuggageClause/index.vue"
import ChangeClausePopup from '@/page/admin/replace-orders/component/changeClausePopup/1.0.0/index.vue'
import OrderLog from '@/component/orderLog/2.0.0/index.vue'
import Decimal from "decimal.js"
export default {
    data() {
        return {
            activeName: 'detail',
            detailLoading: false,
            loading: false,
            refOrderNo: '',
            orderNo: '',
            detail: {
                refundDetails: [],
                segmentsList: [],
                refundPassenger: [],
                refundContent: []
            },
            remark: '',//备注

            ruleText: {},
            ruleLoading: false,
            ruleShow: false, // 退改签说明弹窗

            refStatus: [
                {
                    value: '1',
                    label: '确认退票'
                },
                {
                    value: '2',
                    label: '无法退票'
                }
            ],
            disableAmount: false,
            orderRefund: {
                refOrderNo: '',//退票单号
                refStatus: '1',//退票状态
                refundFine: '',//退票罚金
                refRealAmount: '',//退回金额
                remark: '',//备注信息
                list: []//乘机人的退票发件和退回金额
            },
            isOkSubmit: true,
            rules: {}
        }
    },
    components: {OrderLog, NewChangeClause, NewLuggageClause, ChangeClausePopup},
    created() {
    },
    mounted() {
    },
    activated() {
        this.orderNo = this.$route.query.orderNo;
        this.refOrderNo = this.$route.query.refOrderNo;
        this.orderRefund.refOrderNo = this.$route.query.refOrderNo;
        this.getDetail()
        this.getOrderLog()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        refOrderStatus(val) {
            // 41.申请中 42.待确认 43.已确认 44.处理中 45.退票成功 46.退票失败 47.已取消
            if (val === '41') {
                return '申请中'
            } else if (val === '42') {
                return '待确认'
            } else if (val === '43') {
                return '已确认'
            } else if (val === '44') {
                return '处理中'
            } else if (val === '45') {
                return '退票成功'
            } else if (val === '46') {
                return '退票失败'
            } else if (val === '47') {
                return '已取消'
            } else {
                return ''
            }
        }
    },
    methods: {
        filterMoney(val) {
            if (val) {
                if (val.indexOf('.') > -1) {
                    //有小数点
                    return parseInt(val).toFixed(2);
                } else {
                    // 无小数点
                    return val;
                }
            } else {
                return '';
            }
        },
        getDetail() {
            this.detailLoading = true
            consumer_admin_flight_refund_newdetails({refOrderNo: this.refOrderNo}).then(res => {
                let detail = res.detailVO

                this.detailLoading = false
                let list = [];
                detail.refundPassenger.forEach(item => {
                    if (!item.refundFine) {
                        item.refundFine = 0
                    }
                    if (!item.refRealAmount) {
                        item.refRealAmount = 0
                    }
                    if (!item.platformRefund) {
                        item.platformRefund = 0
                    }
                    if (!item.supplierRefundOrderNo) {
                        item.supplierRefundOrderNo = ''
                    }
                    if (!item.returnDifference) {
                        item.returnDifference = 0;
                    }
                    if (!item.supplierRefRealAmount) {
                        item.supplierRefRealAmount = 0;
                    }
                    item.isRefundToGuest = 0;
                    list.push({
                        psgNo: item.psgNo,
                        refundFine: 0,//退票罚金
                        refRealAmount: 0,//退回金额
                        platformRefund: 0,
                        supplierRefundOrderNo: '',
                        returnDifference: 0,
                        supplierRefRealAmount: 0,
                        isRefundToGuest: 0,
                    })
                })
                this.detail = detail;
                this.orderRefund.list = list;
            }).catch(() => {
                this.detailLoading = false
            })
        },
        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    // return consumer_log_flightList({
                    //     orderNo: this.orderNo
                    // }).then(res => {
                    let res = {
                        datas: [{
                            "operator": "个人测试",
                            "operatorTime": "2021-12-06T15:54:09",
                            "logSource": "1",
                            "logContents": null
                        }, {
                            "operator": "个人测试",
                            "operatorTime": "2021-12-06T15:55:01",
                            "logSource": "1",
                            "logContents": null
                        }, {
                            "operator": "个人测试",
                            "operatorTime": "2021-12-06T15:55:57",
                            "logSource": "1",
                            "logContents": "创建订单成功，订单号：13877698977300"
                        }, {
                            "operator": "个人测试",
                            "operatorTime": "2021-12-06T15:56:08",
                            "logSource": "1",
                            "logContents": "创建订单成功，订单号：13877698977300"
                        }, {
                            "operator": "个人测试",
                            "operatorTime": "2021-12-06T16:02:58",
                            "logSource": "1",
                            "logContents": "调用胤之旅接口，取消订单成功"
                        }]
                    }
                    // 组装组件需要的list
                    const list = res.datas.map(m => {
                        // 替换名字
                        const o = {
                            operator: m.operator,
                            date: m.operatorTime,
                            type: m.logSource,
                            detail: m.logContents
                        }
                        return o
                    })
                    const d = {
                        entityList: list
                    }
                    this.loading = false //临时使用
                    return d || {}
                    // }).catch(() => {
                    //     return []
                    // }).finally(() => {
                    //     this.loading = false
                    // })
                }
            })
        },
        // 退票退改规则
        showRefundChangeClause(val) {
            this.ruleText = {}
            this.ruleShow = true
            let data = {
                segmentNo: val.segmentNo
            }
            this.ruleLoading = true
            consumer_air_ticket_fligthRules(data).then(res => {
                this.ruleLoading = false
                this.ruleText = res.datas.rsData
            }).catch(() => {
                this.ruleLoading = false
            })
        },
        onChangeRefStatus(val) {
            this.disableAmount = false;
            if (val === '2') {
                this.disableAmount = true;
                this.orderRefund.list.forEach(item => {
                    item.refundFine = '';
                    item.refRealAmount = '';
                })
            }
        },
        onCheckForm() {
            let isOk = true;
            if (this.orderRefund.refStatus === '1') {
                let nonAmount = null;
                let nonRefundFine = null;
                let nonPlatformRefund = null;
                let nonRefRealAmount = null;
                let nonSupplierRefundOrderNo = null;
                //let nonSupplierRefRealAmount = null;
                this.detail.refundPassenger.forEach((item, index) => {
                    this.orderRefund.list[index].refundFine = item.refundFine;
                    this.orderRefund.list[index].platformRefund = item.platformRefund;
                    this.orderRefund.list[index].refRealAmount = item.refRealAmount;
                    this.orderRefund.list[index].supplierRefRealAmount = item.supplierRefRealAmount;
                    this.orderRefund.list[index].supplierRefundOrderNo = item.supplierRefundOrderNo;
                })
                nonRefundFine = this.orderRefund.list.find(item => {
                    return item.refundFine === '' || item.refundFine === null
                })
                nonPlatformRefund = this.orderRefund.list.find(item => {
                    return item.platformRefund === '' || item.platformRefund === null
                })
                nonRefRealAmount = this.orderRefund.list.find(item => {
                    return item.refRealAmount === '' || item.refRealAmount === null
                })
                nonSupplierRefundOrderNo = this.orderRefund.list.find(item => {
                    return item.supplierRefundOrderNo === '' || item.supplierRefundOrderNo === null
                })
                /*nonSupplierRefRealAmount = this.orderRefund.list.find(item => {
                    return item.supplierRefRealAmount === '' || item.supplierRefRealAmount === null
                })*/
                if (nonRefundFine) {
                    this.$message({
                        type: 'warning',
                        message: '请输入BSP退票罚金'
                    });
                } else if (nonPlatformRefund) {
                    this.$message({
                        type: 'warning',
                        message: '请输入平台退票罚金'
                    });
                } else if (nonRefRealAmount) {
                    this.$message({
                        type: 'warning',
                        message: '请输入退回金额'
                    });
                }
                // else if (nonSupplierRefundOrderNo) {
                //     this.$message({
                //         type: 'warning',
                //         message: '请输入供应商退票订单号'
                //     });
                // }
                nonAmount = nonRefundFine || nonPlatformRefund || nonRefRealAmount;
                if (nonAmount) {
                    isOk = false;
                }
            }
            return isOk;
        },
        onSubmit() {
            let loading = {};
            if (!this.onCheckForm()) {
                return;
            }
            let msg = '';
            if (this.orderRefund.refStatus === '1') {
                msg = '是否确认退票？';
            } else {
                msg = '是否确认无法退票？';
            }
            this.$confirm(msg, '', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                return consumer_admin_flight_refund_selfSupportRefundOrder({orderRefund: this.orderRefund});
            }).then((res) => {
                loading.close();
                if (res.code === '000000') {
                    this.$message({
                        type: 'success',
                        message: '保存成功！'
                    })
                    this.$router.replace({
                        name: "admin-flight-refund-list"
                    })
                }
            }).catch(() => {
                loading.close();
            })
        },
        onBack() {
            this.$router.back();
        },
        countRefund(item) {
            // 退回金额=销售价-退票罚金-平台退票罚金+退回舱差
            let refRealAmount = 0;
            refRealAmount = Decimal(item.priceTaxes).sub(item.refundFine).sub(item.platformRefund).toString();
            if (item.isRefundToGuest) {
                refRealAmount = Decimal(refRealAmount).add(item.returnDifference).toString();
            }
            // 供应商退回金额=采购价-退票罚金+退回舱差
            let supplierRefRealAmount = 0;
            supplierRefRealAmount = Decimal(item.purchaseprice).sub(item.refundFine).add(item.returnDifference).toString();
            /*if (item.isRefundToGuest) {
                supplierRefRealAmount = Decimal(supplierRefRealAmount).add(item.returnDifference).toString();
            }*/
            item.refRealAmount = refRealAmount;
            item.supplierRefRealAmount = supplierRefRealAmount;



            //let end = this.$math.chain(item.priceTaxes).subtract(parseFloat(item.refundFine)).subtract(item.platformRefund).subtract(item.returnDifference).done();
            //item.refRealAmount = Math.round(end *100)/100
        },

        checkReturnDifference(rule, value, callback, item) {
            const regExp = /^(0|[1-9]\d*)(\.\d{1,2})?$/
            if (regExp.test(value)) {
                if (value < 0) {
                    callback(new Error('请输入不小于0的数字'))
                } else {
                    if (value > (item.differencePrice || 0)) {
                        callback(new Error('金额不能超过改签支付的金额'))
                    } else  {
                        callback();
                    }
                }
            } else {
                callback(new Error('请输入合法数字'))
            }
        },
    }
}

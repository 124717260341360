export default {
    name: "selectCertificate",
    props: ["user"],
    methods: {
        addCertificate() {
            this.$emit('addCertificate', this.user);
        },
        changeCertificate(certificate) {
            let param = {
                certificate: certificate,
                userId: this.user.userId
            }
            this.$emit('changeCertificate', param);
        }
    }
}

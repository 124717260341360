import consumer_wallet_withdrawApplyList from '@/lib/data-service/haolv-default/consumer_wallet_withdrawApplyList' // 列表
import consumer_wallet_affirmWithdraw from '@/lib/data-service/haolv-default/consumer_wallet_affirmWithdraw' // 确认提款
import Pagination from '@/component/base-pagination/index.vue'
export default {
    data () {
        return {
            params: {
                bankName: '', // 银行名称
                companyName: '', //申请公司
                endTime: '', // 结束时间
                startTime: '', // 开始时间
                timeArr: [],
                withdrawStatus: '', // 提款状态：0.待提款，1.提款完成

                currentPage: 1,
                pageSize: 10,
                total: 0
            },
            loading: false,
            list: [],
            options: [{value: '', label: '全部'}, {value: 0, label: '待提款'}, {value: 1, label: '提款完成'}],

            dialogVisible: false,
            form: {
                id: '',
                tradeNo: ''
            },
            rule: {
                tradeNo: [
                    { required: true, message: '请输入交易流水号', trigger: 'blur' },
                ]
            },
            canSubmit: true,
            dialogType: 'edit', // edit 确认提款  info 查看
        }
    },
    components: {
        Pagination
    },
    created () {},
    mounted () {},
    activated () {
        this.getList()
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        search() {
            this.params.currentPage = 1;
            this.getList()
        },
        reset() {
            this.params = {
                bankName: '', // 银行名称
                companyName: '', //申请公司
                endTime: '', // 结束时间
                startTime: '', // 开始时间
                timeArr: [],
                withdrawStatus: '', // 提款状态：0.待提款，1.提款完成

                currentPage: 1,
                pageSize: 10,
                total: 0
            }
            this.list = []
            this.search()
        },
        changeTime(val) {
            if (val === '' || val === null) {
                this.params.startTime = ''
                this.params.endTime = ''
            } else {
                this.params.startTime = val[0]
                this.params.endTime = val[1]
            }
        },
        getList() {
            this.loading = true;
            this.list = [];
            consumer_wallet_withdrawApplyList(this.form).then((res) => {
                this.list = res.datas.list;
                this.params.total = res.datas.totalCount;
                this.loading = false;
            }).catch((err) => {
                this.loading = false;
                this.params.total = 0
            })
        },
        openSubmitDialog(val) {
            this.dialogType = 'edit'
            this.form = {
                id: val.id,
                tradeNo: ''
            }
            // this.form.id = val.id
            this.dialogVisible = true
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid && this.canSubmit) {
                    this.canSubmit = false
                    this.loading = true
                    consumer_wallet_affirmWithdraw(this.form).then(res => {
                        this.loading = false
                        this.dialogVisible = false
                        this.$message({
                            message: '提款成功',
                            type: 'success'
                        });
                        this.canSubmit = true
                        this.getList()
                    }).catch(() => {
                        this.canSubmit = true
                        this.loading = false
                    })
                }
            })
        },
        showSubmitDialog(val) {
            this.dialogType = 'info'
            this.form = {
                id: val.id,
                tradeNo: val.tradeNo
            }
            this.dialogVisible = true
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        doCopy(val) {
            this.$copyText(val).then(res => {
                this.$message({
                    message: '复制成功',
                    type: 'success'
                });
            }, error => {
                this.$message({
                    message: '复制失败',
                    type: 'warning'
                });
            })
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
const __request = require(`./__request/__request_contentType_json`)
let controllerUrl = "/tmc/hotel/";
const report_page_list = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: controllerUrl + 'query',
        data: data
    };
    return __request(pParameter)
};

export default {report_page_list}

/*
*我的差标  http://219.128.52.2:18765/doc.html#/haolv-biz/t-us-user-controller/myTravelPolicyUsingPOST
*/
const __request = require(`./__request/__request_contentType_json`)
const consumer_web_policyConfig_myTravelPolicy = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/web/policyConfig/myTravelPolicy',
    }
    return __request(pParameter)
}
export default consumer_web_policyConfig_myTravelPolicy;
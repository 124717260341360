import Q from 'q';
import _ from 'underscore';
import wait_some_seconds from 'nllib/src/common/wait_some_seconds';

import {Loading} from "element-ui";
// 企业信息组件
import CompanyInfo from "@/component/company-info/index.vue";
// 出差单详情
import consumer_journey_getEvectionDetail from "@/lib/data-service/haolv-default/consumer_admin_journey_getEvectionDetail.js";
//代客下单申请火车票取消
import consumer_admin_trains_order_applyForCancel from "@/lib/data-service/haolv-default/consumer_admin_trains_order_applyForCancel.js";
// 支付
import consumer_admin_payment_behalfPay from "@/lib/data-service/haolv-default/consumer_admin_payment_behalfPay.js";
import consumer_admin_flight_checkFlightPay from '@/lib/data-service/haolv-default/consumer_admin_flight_checkFlightPay'
import moment from "moment";
import PaymentMethodSelector010 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.11/payment-method-selector-010/index.vue';


export default {
    components: {
        CompanyInfo,
        PaymentMethodSelector010
    },
    data() {
        return {
            num:0,
            tableData:[],
            // tableData: [
            //     {
            //         name: '张胜男',
            //         type: '成人',
            //         idType: '身份证',
            //         idNum: '440661198507101212',
            //         dateBirth: '1985-07-10 ',
            //         gender: '男',
            //         telephone: '16676011062',
            //         ticketPrice: '669',
            //         serviceMon: '0',
            //         ticketMon: '10',
            //     },
            //     {
            //         name: '张胜律',
            //         type: '成人',
            //         idType: '身份证',
            //         idNum: '440661198507101212',
            //         dateBirth: '1985-07-10 ',
            //         gender: '男',
            //         telephone: '16676011062',
            //         ticketPrice: '669',
            //         serviceMon: '0',
            //         ticketMon: '10',
            //     },
            //     {
            //         name: '律化娜',
            //         type: '成人',
            //         idType: '身份证',
            //         idNum: '440661198507101212',
            //         dateBirth: '1985-07-10 ',
            //         gender: '男',
            //         telephone: '16676011062',
            //         ticketPrice: '669',
            //         serviceMon: '0',
            //         ticketMon: '10',
            //     },
            //     {
            //         name: '布兰妮y',
            //         type: '成人',
            //         idType: '身份证',
            //         idNum: '440661198507101212',
            //         dateBirth: '1985-07-10 ',
            //         gender: '男',
            //         telephone: '16676011062',
            //         ticketPrice: '669',
            //         serviceMon: '0',
            //         ticketMon: '10',
            //     }
            // ],
            trainDataTop:{
                //开始日期
                // startDate:null,
                startDate: '2021-09-27',
                //星期几
                // whatDay:null,
                whatDay: 1,
                //车次
                // trainNo:null,
                trainNo: 'K599',
                //出发站
                // fromStationName:null,
                fromStationName: '北京西',
                //到达站
                // toStationName:null,
                toStationName: '广州',
                //发车时间
                // startTime:null,
                startTime: '05:14',
                //到达时间
                // arriveTime:null,
                arriveTime: '10:56',
                //跨越时间
                spanTime: '29时42分',
                //座位类型
                seatName: '硬座',
            },
            cardAllBox:null,
            cardBox:true,
            btnLoading: false,
            loading: false,
            loadingInstance: null,
            // 为空字符是整个出差单，1是机票订单，2是火车票订单，3是酒店订单
            orderType: "",
            // 1是创建订单页，2是支付页，3是改签页
            pageType: "",
            detail: {},
            params: {
                evectionNo: "",
                userIds: "",
            },
            detectionDetail: [],
            zhif_rule: "",
            value: "",
            successVisible: false,
            showDetail: false,
            radio: "1",
            paymentForm: {
                pwd: "",
            },
            paymentRules: {
                pwd: [
                    {required: true, message: "请输入支付密码", trigger: "blur"},
                    {
                        pattern: /[0-9a-zA-Z]{6}/,
                        message: "支付密码格式不正确",
                        trigger: "blur",
                    },
                ],
            },
            passwordVisible: false,
            showWrongPassword: false,

            flightOrderList: [],

            dialogError2Visible: false,
            errorText: '',
            allData:{
                orderNo:null,
                sum:null,
            },
            travellerAll:[],
        };
    },
    computed: {},
    methods: {
        __init(){
            let _this = this;
            _this.$refs.aPaymentMethodSelector010.init({
                count_down: {
                    pay_remaining_seconds: 30 * 60 * 1,//支付剩余时间，以秒为单位
                },
                //在这里传入订单详情的相关参数
                get_params() {
                    console.log(_this);
                    // const p = {
                    //     cacheKey: _this.allData.cacheKey,
                    //     evectionNo: _this.allData.evectionNo,//出差单号
                    //     orderNo: _this.allData.orderNo,//订单编号
                    // };
                    const p = {
                        cacheKey: "bdc8c9e31bea431e9f25383213986a88",
                        evectionNo: "13296973328600262",//出差单号
                        orderNo: null,//订单编号
                    };
                    console.log(p);
                    return Q.resolve(p);
                },
                //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                before_pay_event_handler() {
                    // alert(`不能够支付！原因：XXX`);
                    // return Q.reject();

                    console.log(`before_pay_event_handler`);
                    return Q.resolve();
                },
                //支付成功事件处理方法
                pay_successful_event_handler() {
                    console.log(`pay_successful_event_handler`);
                },
                //支付失败事件处理方法
                pay_failed_event_handler() {
                    console.log(`pay_failed_event_handler`);
                },
                //取消订单按钮单击事件处理方法
                btn_cancel_order_click_event_handler(args) {
                    const show_loading = args.show_loading;
                    const hide_loading = args.hide_loading;
                    const stop_count_down = args.stop_count_down;
                    const enable_btn_pay = args.enable_btn_pay;
                    const enable_btn_cancel_order = args.enable_btn_cancel_order;

                    Q.when()
                        .then(function () {
                            show_loading();
                            //---------------------------------------------------
                            return consumer_admin_trains_order_applyForCancel({
                                orderNo: _this.orderNo,
                                supplierOrderNo: _this.supplierOrderNo
                            });
                        })
                        .then(function () {
                            console.log(`btn_cancel_order_click_event_handler`);
                            hide_loading();
                            stop_count_down();
                            enable_btn_pay({
                                enable: false,
                            });
                            enable_btn_cancel_order({
                                enable: false,
                            });
                            _this.$message({
                                type: 'success',
                                message: '取消成功!'
                            });
                            _this.$router.replace({
                                //---------------------------------------------------
                                name: 'admin-travel-book-home'
                            })
                        })
                        .catch(function (ex) {
                            if (ex) {
                                const enable_log_error = !ex.disable_log_error;
                                if (enable_log_error) {
                                    console.error(ex);
                                    console.trace();
                                    if (ex.stack) {
                                        console.error(ex.stack);
                                    }
                                }
                            }
                            return Q.reject(ex);
                        });
                },
            });
        },
        ClickCardBox(){
            this.cardBox = !this.cardBox;
        },
        judgeIdType(num) {
            switch (num) {
                case 1:
                    return "身份证";
                case 2:
                    return "护照";
                case 3:
                    return "港澳通行证";
                case 4:
                    return "回乡证";
                case 5:
                    return "台胞证";
                case 6:
                    return "军官证";
                case 7:
                    return "香港身份证";
                case 8:
                    return "台湾通行证";
                case 9:
                    return "户口本";
                case 10:
                    return "其他";
                case 11:
                    return "澳门身份证";
            }
        },
        req_detail(params) {
            const __this = this;
            consumer_journey_getEvectionDetail(params)
                .then((res) => {
                    let flightOrderList = [];
                    const datas = res.datas;
                    const changeDetailResponse = res.datas.changeDetailResponse;
                    this.allData = res.datas;
                    //出行人集合
                    this.allData.travellerList.forEach((item,index) => {
                        this.travellerAll.push(item.staffName);
                        this.tableData.push({
                            name: item.staffName,
                            idType: this.judgeIdType(item.certificateList[0].certificateType),
                            idNum: item.certificateList[0].certificateValue,
                            dateBirth: item.birthDate,
                            gender: item.staffSex,
                            telephone: item.phone,
                            ticketPrice: '456',
                            serviceMon: '123',
                            ticketMon:'321',
                        });
                    });
                    this.trainDataTop = res.datas.ticketBookingPageDetailsResponseList[0];
                    if (this.pageType === 3) {
                        if (this.orderType === 1) {
                            const flightDetail = datas.flightDetail;
                            let detail = {
                                cacheKey: datas.cacheKey,
                                reason: datas.reason,
                                travellerList: flightDetail.orderList[0].passengerList.map(
                                    (item) => {
                                        return {staffName: item.psgName};
                                    }
                                ),
                                linkman: datas.linkman,
                                linkmanPhone: datas.linkmanPhone,
                                feeAffiliationName: datas.feeAffiliationName,
                                businessContentVo: datas.businessContentVo,
                                flightDetail: flightDetail,
                                serviceFee: datas.serviceFee,
                                sum: flightDetail.orderList[0].chaAmount,
                                evectionType: datas.evectionType,
                            };
                            this.detail = detail;
                            flightDetail.orderList.forEach(value => {
                                flightOrderList.push(value.orderNo)
                            })
                            this.flightOrderList = flightOrderList
                        } else if (this.orderType === 2) {
                            let train = {
                                trainNo: changeDetailResponse.changeToStationCode,
                                seatName: changeDetailResponse.changeSeatName,
                                fromStationName: changeDetailResponse.changeFromStationName,
                                spanTime: "",
                                startTime: changeDetailResponse.changeStartTime,
                                startDate: changeDetailResponse.changeStartDate,
                                arriveDate: changeDetailResponse.changeArriveDate,
                                toStationName: changeDetailResponse.changeToStationName,
                                arriveTime: changeDetailResponse.changeArriveTime,
                                seatPrice: changeDetailResponse.changeSeatPrice,
                                evectionType: changeDetailResponse.evectionType,
                                orderNo: changeDetailResponse.changeNo,
                                headCounts: changeDetailResponse.passengers.length,
                                passengerNames: changeDetailResponse.passengerNames,
                                buyerPaymentTotalAmount: changeDetailResponse.changeTotalAmount,
                            };
                            let detail = {
                                cacheKey: datas.cacheKey,
                                reason: changeDetailResponse.reason,
                                travellerList: changeDetailResponse.passengers.map((item) => {
                                    return {staffName: item.passengerName};
                                }),
                                linkman: res.datas.linkman,
                                linkmanPhone: res.datas.linkmanPhone,
                                feeAffiliationName: res.datas.feeAffiliationName,
                                sum: changeDetailResponse.changeTotalAmount,
                                businessContentVo: res.datas.businessContentVo,
                                ticketBookingPageDetailsResponseList: [train],
                                evectionType: changeDetailResponse.evectionType,
                            };
                            this.detail = detail;
                        }
                    } else {
                        this.detail = res.datas;
                        if (this.detail.flightDetail && this.detail.flightDetail.orderList && this.detail.flightDetail.orderList.length > 0) {
                            this.detail.flightDetail.orderList.forEach(value => {
                                flightOrderList.push(value.orderNo)
                            })
                        }
                        this.flightOrderList = flightOrderList
                    }

                    this.paymentForm.cacheKey = this.detail.cacheKey;
                    this.loading = false;

                    let trainNum = document.querySelector('.card_box_train_num');
                    let trainPeople = document.querySelector('.card_box_train_people');
                    let trainPassenger = document.querySelector('.card_box_train_passenger');
                    this.cardAllBox = trainNum.offsetHeight + trainPeople.offsetHeight + trainPassenger.offsetHeight + 80;

                    // this.__init();

                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        init() {
            const query = this.$route.query;
            const paymentForm = this.paymentForm;
            this.orderType = Number(query.orderType) || "";
            this.pageType = Number(query.pageType) || 2;
            this.loading = true;
            
            if (this.orderType) {
                const orderNo = query.orderNo;
                paymentForm.orderNo = orderNo;
                this.req_detail({
                    orderNo: orderNo,
                    pageType: this.pageType,
                    personType: 2,
                });
            } else {
                const evectionNo = query.evectionNo;
                paymentForm.evectionNo = evectionNo;
                this.req_detail({
                    evectionNo: query.evectionNo,
                    pageType: this.pageType,
                    personType: 2,
                });
            }

        },
        to_detail() {
            this.successVisible = false;
            const type = Number(this.orderType);
            const pageType = this.pageType;
            if (type) {
                const orderNo = this.$route.query.orderNo;
                if (type === 1) {
                    if (pageType === 2) {
                        this.$router.replace({
                            name: "admin-flight-order-detail",
                            query: {
                                orderNo,
                            },
                        });
                    } else if (pageType === 3) {
                        this.$router.replace({
                            name: "admin-flight-change-detail",
                            query: {
                                chaOrderNo: orderNo,
                                orderNo: this.$route.query.oriOrderNo
                            },
                        });
                    }
                } else if (type === 2) {
                    if (pageType === 2) {
                        this.$router.replace({
                            name: "admin-train-order-detail",
                            query: {
                                orderNo,
                            },
                        });
                    } else if (pageType === 3) {
                        this.$router.replace({
                            name: "admin-train-change-detail",
                            query: {
                                changeNo: orderNo,
                                orderNo: this.$route.query.oriOrderNo
                            },
                        });
                    }
                } else if (type === 3) {
                    this.$router.replace({
                        name: "admin-hotel-order-sold-detail",
                        query: {
                            id: orderNo,
                        },
                    });
                }
            } else {
                this.$router.replace({
                    name: "admin-replace-orders",
                });
            }
        },
        format_coseList(val) {
            return val.split("|").reduce((acc, item) => (acc += Number(item)), 0);
        },

        to_order_detail() {
            this.showDetail = !this.showDetail;
        },

        show_input_password() {
            // 验价  改签不需要验价
            this.btnLoading = true
            if (this.flightOrderList.length > 0 && this.pageType !== 3) {
                // 如果有机票订单，则验价
                consumer_admin_flight_checkFlightPay({orderNo: this.flightOrderList}).then(res => {
                    this.passwordVisible = true
                }, error => {
                    this.errorText = error.data.msg
                    this.dialogError2Visible = true
                    this.btnLoading = false
                })
            } else {
                this.passwordVisible = true;
            }

        },

        handleClose() {
            this.passwordVisible = false;
            this.btnLoading = false
        },

        pass_payment() {
            const __this = this;
            __this.$refs.form.validate((valid) => {
                if (valid) {
                    __this.loadingInstance = Loading.service({
                        text: "支付中...",
                        spinner: "el-icon-loading",
                    });
                    consumer_admin_payment_behalfPay(__this.paymentForm)
                        .then((result) => {
                            __this.btnLoading = false
                            __this.loadingInstance.close();
                            __this.successVisible = true;
                        }, error => {
                            __this.btnLoading = false
                            __this.loadingInstance.close();
                            const msg = error.data.msg;
                            if (msg === '密码错误') {
                                __this.$refs.form.resetFields();
                                __this.passwordVisible = false;
                                __this.showWrongPassword = true
                            }
                            ;
                        })
                        .catch((err) => {
                            __this.btnLoading = false
                            __this.loadingInstance.close();
                            const msg = err.data.msg;
                            if (msg === '密码错误') {
                                __this.$refs.form.resetFields();
                                __this.passwordVisible = false;
                                __this.showWrongPassword = true
                            }
                            ;
                        });
                } else {
                    __this.btnLoading = false
                    return false;
                }
            });
        },

        close_showWrongPassword() {
            this.showWrongPassword = false;
        },

        again_payment() {
            this.close_showWrongPassword();
            this.show_input_password();
        },

        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        allInit (){
            this.init();
            this.__init();
        }
    },
    created() {
    },
    activated(){
        this.allInit();
    },
    mounted() {

    },
    filters: {
        format_train_departDate(val) {
            return moment(val).format("YYYY年MM月DD日");
        },
        format_segmentType(val) {
            switch (val) {
                case 1:
                    return "去";

                case 2:
                    return "返";
            }
        },
        format_duration(val) {
            return val.replace("h", "小时").replace("m", "分钟");
        },

    },
};

import Q from 'q';
import CompanyInfo from '@/component/company-info/import_latest_version_module'
import PaymentMethodSelector010 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.11/payment-method-selector-010';
import consumer_admin_hotel_order_generate_generateOrderDetail
    from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_generate_generateOrderDetail'
import consumer_admin_flight_order_cancel from '@/lib/data-service/haolv-default/consumer_admin_flight_order_cancel'
import consumer_t_od_hotel_order_interim_cancelOrder from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_cancelOrder'
import consumer_admin_hotel_order_generate_generateOrderConfirm
    from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_generate_generateOrderConfirm'

export default {
    data() {
        return {
            loading: false,
            orderNo: '',
            payType: '1', // 1支付中 2 无需支付 3 待支付
            payTypeForChannel: 1,
            detail: {},
            hotelInfo: {},
            travelerList: [],
            priceInfo: {},
            refundRule: {},//退改规则
            reportConfiguration: [],
        }
    },
    components: {CompanyInfo, PaymentMethodSelector010},
    activated() {
        const query = this.$route.query;
        this.orderNo = query.orderNo;
        this.payTypeForChannel = query.payType;
        this.getDetail();
    },
    methods: {
        //获取详情
        getDetail() {
            this.loading = true;
            const params = {
                orderNo: this.orderNo
            };
            consumer_admin_hotel_order_generate_generateOrderDetail(params).then(res => {
                let datas = res.datas;
                this.detail = {...datas, ...datas.hotelGenerateOrderHotelMsg, ...datas.customerInfo, ...datas.hotelGenerateOrderPrice};
                this.hotelInfo = datas.hotelGenerateOrderHotelMsg;
                this.travelerList = datas.hotelGenerateOrderPeoples;
                this.priceInfo = datas.hotelGenerateOrderPrice;
                this.refundRule = datas.refundRule;
                this.reportConfiguration = datas.reportConfiguration?datas.reportConfiguration:[];
                //this.detail.status: 0：待支付 1,支付中 ，2,已支付（待出房）6:已取消、7、取消中,8:取消失败:9预下单:10创建订单失败
                // ;11已出房;12已退房;13退房失败;14出房失败:-1：已删除
                //this.detail.payType: 1：授信支付 2：现结支付 3：线下转账"
                //this.payType: '1', // 1支付中 2 无需支付 3 待支付
                // === 3 即补收
                if (this.payTypeForChannel === 3) {
                    this.payType = '3'
                    this.$nextTick(() => {
                        this.initPayment()
                    })
                } else {
                    if (this.detail.status === 1) {
                        this.payType = '1'
                    } else if (this.detail.status === 0 && this.detail.payType === 3) {
                        this.payType = '2'
                    } else {
                        this.payType = '3'
                        this.$nextTick(() => {
                            this.initPayment()
                        })
                    }
                }
            }).finally(() => {
                this.loading = false
            })
        },

        //初始化支付组件
        initPayment() {
            let _this = this;
            let orderForPayVos = [{
                orderNo: _this.orderNo,
                businessContent: '',
            }];
            _this.$refs.aPaymentMethodSelector010.init({
                page_this: _this,

                //在这里传入订单详情的相关参数
                get_params() {
                    const p = {
                        "orderForPayVos": orderForPayVos,
                        "payType": _this.payTypeForChannel,
                    };

                    return Q.resolve(p);
                },
                //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                before_pay_event_handler() {
                    return Q.resolve();
                },
                //支付成功事件处理方法
                pay_successful_event_handler() {
                    console.log(`pay_successful_event_handler`);
                    _this.$message({
                        type: 'success',
                        message: '支付成功!'
                    });
                    _this.$router.replace({
                        name: 'admin-hotel-order-sold'
                    })
                },
                //支付失败事件处理方法
                pay_failed_event_handler() {
                    console.log(`pay_failed_event_handler`);
                },
                //取消订单按钮单击事件处理方法
                btn_cancel_order_click_event_handler(args) {
                    const show_loading = args.show_loading;
                    const hide_loading = args.hide_loading;
                    const stop_count_down = args.stop_count_down;
                    const enable_btn_pay = args.enable_btn_pay;
                    const enable_btn_cancel_order = args.enable_btn_cancel_order;

                    _this.$confirm('确定取消此订单', '取消提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        Q.when().then(function () {
                            show_loading();
                            return consumer_t_od_hotel_order_interim_cancelOrder({
                                orderNo: _this.orderNo,
                                supplierOrderNo: _this.detail.supplierOrderNo,
                                cancelRemark: ""
                            });
                        }).then(function () {
                            console.log(`btn_cancel_order_click_event_handler`);
                            hide_loading();
                            stop_count_down();
                            enable_btn_pay({
                                enable: false,
                            });
                            enable_btn_cancel_order({
                                enable: false,
                            });
                            _this.$message({
                                type: 'success',
                                message: '取消成功!'
                            });
                            _this.$router.replace({
                                name: 'admin-hotel-order-sold'
                            })
                        }).catch(function (ex) {
                            if (ex) {
                                const enable_log_error = !ex.disable_log_error;
                                if (enable_log_error) {
                                    console.error(ex);
                                    console.trace();
                                    if (ex.stack) {
                                        console.error(ex.stack);
                                    }
                                }
                            }
                            return Q.reject(ex);
                        });
                    }).catch(()=>{})


                },
                //获取支付方式接口，返回code=300052时，弹窗的确定按钮单击处理处理方法
                dialog_code_300052_btn_ok_click_event_handler() {
                    console.log(`dialog_code_300052_btn_ok_click_event_handler`);
                    _this.$router.replace({
                        name: 'admin-hotel-order-sold',
                    });
                    return Q.resolve(true);
                    //return true;
                },
            });
        },

        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
            switch (val) {
                case (1):
                    return 'type2'
                case (2):
                    return 'type1'
                default:
                    return ''
            }
        },

        //提交
        submit() {
            const params = {
                orderNo: this.orderNo
            };
            this.loading = true
            consumer_admin_hotel_order_generate_generateOrderConfirm(params).then(res => {
                this.$message({
                    type: 'success',
                    message: '确认成功!'
                });
                this.$router.replace({
                    name: 'admin-hotel-order-sold'
                })
            }).finally(() => {
                this.loading = false
            })
        },

        //返回
        backToList() {
            this.$router.replace({
                name: 'admin-hotel-order-sold'
            })
        },
    }
}

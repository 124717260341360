export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/train-create-order-pay',
            name: 'admin-train-create-order-pay',
            component: () => import(/* webpackChunkName: "page-admin-order-management-train-create-order-pay" */ `./1.0.0/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `后台生单`,
                },
                entity_list_for_breadcrumb: [
                    { name: `火车票订单` },
                    { name: `后台生单` },
                ],
            },
        },
    ],
};

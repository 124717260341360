import moment from "moment";
import consumer_admin_trains_order_trainsOfflineRefundOrderDetails
    from "@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsOfflineRefundOrderDetails";//获取火车票线下单退订单详情
import consumer_admin_flight_generate_userName
    from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_userName'// 当前登陆人
import consumer_admin_trains_order_trainsOfflineRefundOrUpdate
    from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsOfflineRefundOrUpdate'//保存退票信息

export default {
    data() {
        const validateServiceCharge = (rule, value, callback) => {
            const regExp = /^(0|[1-9]\d*)(\.\d{1,2})?$/
            if (regExp.test(value)) {
                if (value >= 0) {
                    callback()
                } else {
                    callback(new Error('请输入大于0的数字'))
                }
            } else {
                callback(new Error('请输入合法数字'))
            }
        }
        const validateRefundAmount = (rule, value, callback) => {
            const regExp = /^(0|[1-9]\d*)(\.\d{1,2})?$/
            if (regExp.test(value)) {
                if (value >= 0) {
                    callback()
                } else {
                    callback(new Error('请输入大于0的数字'))
                }
            } else {
                callback(new Error('请输入合法数字'))
            }
        }
        return {
            loading: false,
            dialogVisible: false,
            details: {},//详情
            trainsOrderDetails: {},
            type: 'handle',
            orderNo: '',//订单号
            changeNo: '',//改签订单号
            refundNo: '',//退票订单号
            journeyInfo: [{
                segmentNo: '',//行程编号
                trainsRefundPassenger: []//选择退票的乘客
            }],
            refundInformation: {
                appTime: '',//退票申请时间
                supplierRefundOrderNo: '',//供应商退票订单号
                initialName: '',//操作客服名称
                userId: '',//操作客服id
            },
            remark: '',//备注
            curJourney: {},
            curPassengerIndex: -1,
            curPassenger: {},
            rules: {
                serviceCharge: [
                    {required: true, message: '退票手续费', trigger: 'blur'},
                    {required: true, validator: validateServiceCharge, trigger: 'blur'}
                ],
                refundAmount: [
                    {required: true, message: '退款金额', trigger: 'blur'},
                    {required: true, validator: validateRefundAmount, trigger: 'blur'}
                ],
                appTime: [
                    {required: true, message: '请选择申请退票时间', trigger: 'blur'},
                ],
                supplierRefundOrderNo: [
                    {required: true, message: '供应商退票订单号', trigger: 'blur'},
                ],
                initialName: [
                    {required: true, message: '操作客服', trigger: 'blur'},
                ],
            }
        };
    },
    methods: {
        //初始化
        async init() {
            let {
                type,
                orderNo,
                changeNo,
                refundNo,
            } = this.$route.query;
            this.type = type;
            this.orderNo = orderNo;
            this.changeNo = changeNo || '';
            this.refundNo = this.type === 'edit' ? refundNo : '';
            this.remark = '';
            await this.getDetail();
            await this.getCurrentUser();
        },

        //获取当前登录人
        getCurrentUser() {
            consumer_admin_flight_generate_userName().then(res => {
                let currentUser = res.datas;
                this.refundInformation.initialName = currentUser[0].initialName;
                this.refundInformation.userId = currentUser[0].userId;
            });
        },

        //获取详情
        async getDetail() {
            this.loading = true;
            let params = {
                orderNo: this.orderNo,//订单号
                changeNo: this.changeNo,//改签订单号
                refundNo: this.refundNo,//退票订单号
            }
            let res = await consumer_admin_trains_order_trainsOfflineRefundOrderDetails(params);
            this.details = res.datas;
            this.trainsOrderDetails = this.details.trainsOrderDetails;
            if (this.type === 'edit') {
                this.refundInformation = this.details.refundInformation;
            }

            let hasPassenger = false;
            this.details.journeyInfo.forEach(journey => {
                journey.trainsRefundPassenger.forEach((passenger, index) => {
                    //判断该乘客是否可选
                    this.$set(passenger, 'isDisabled', true);
                    if (passenger.ticketStatus === 3 || passenger.ticketStatus === 11) {
                        this.$set(passenger, 'isDisabled', false);
                        hasPassenger = true;
                    }

                    //判断该乘客是否被选中
                    this.$set(passenger, 'isSelected', false);
                    if (passenger.refundAmount && passenger.serviceCharge) {
                        this.$set(passenger, 'isSelected', true);
                        this.curPassengerIndex = index;
                        this.curPassenger = passenger;
                        this.curJourney = journey;
                    }
                });
            })


            //如果没有可选的乘客则返回上一页
            if (this.type === 'handle' && !hasPassenger) {
                this.dialogVisible = true;
            }

            this.loading = false;
        },

        //选择退票乘客
        changePassenger(curJourney, passenger, index) {
            this.journeyInfo = [];
            this.curJourney = {};
            this.curPassengerIndex = -1;
            this.curPassenger = {};
            this.curJourney = curJourney;
            this.details.journeyInfo.forEach(journey => {
                journey.trainsRefundPassenger.forEach((pItem, idx) => {
                    if (idx !== index) {
                        this.$set(pItem, 'isSelected', false);
                    }
                })
            })
            if (passenger.isSelected) {
                this.curPassengerIndex = index;
                this.curPassenger = passenger;
            }

            this.$nextTick(() => {
                this.$refs.ruleSegmentsForm.forEach((item, idx) => {
                    item.clearValidate();
                })
            })
        },

        //修改退票手续费时，自动计算退款金额
        changeCharge(curJourney, passenger) {
            passenger.refundAmount = curJourney.seatPrice - passenger.serviceCharge;
            this.$refs.ruleSegmentsForm[this.curPassengerIndex].validate()
        },

        //检查是否可保存
        checkForm() {
            let isOk = false;
            let isRuleSegmentsFormOk = false;
            let isRefundInfoFormOk = false;
            if (this.curPassengerIndex === -1) {
                this.$message({
                    type: "warning",
                    message: '请选择退票乘客！'
                })
                return isOk;
            }
            this.$refs.ruleSegmentsForm[this.curPassengerIndex].validate((valid) => {
                isRuleSegmentsFormOk = valid;
            })
            this.$refs.refundInfoForm.validate((valid) => {
                isRefundInfoFormOk = valid
            })
            isOk = isRuleSegmentsFormOk && isRefundInfoFormOk;
            if (!isOk) {
                this.$message({
                    type: "warning",
                    message: '请完善信息！'
                })
            }
            return isOk;
        },

        //保存备注
        submit() {
            let isOk = this.checkForm();
            if (isOk) {
                this.journeyInfo = [{
                    segmentNo: this.curJourney.segmentNo,
                    trainsRefundPassenger: [
                        {
                            psgNo: this.curPassenger.psgNo,
                            refAmount: this.curPassenger.refundAmount,
                            serviceCharge: this.curPassenger.serviceCharge,
                        }
                    ]
                }]
                let params = {
                    orderNo: this.orderNo,
                    changeNo: this.changeNo,
                    refundNo: this.refundNo,
                    journeyInfo: this.journeyInfo,
                    refundInformation: this.refundInformation,
                    remark: this.remark
                }
                consumer_admin_trains_order_trainsOfflineRefundOrUpdate(params).then(res => {
                    this.$message({
                        type: 'success',
                        message: '保存成功！'
                    });
                    this.$router.push({
                        name: 'admin-train-refund-confirm',
                        query: {
                            orderNo: this.orderNo,
                            refundNo: res.datas.refundNo,
                        },
                    })
                })
            }
        },

        //关闭无可选乘客的弹框
        closeNoRefundDialog() {
            this.dialogVisible = false;
            this.back();
        },

        //返回
        back() {
            this.$router.go(-1);
        },
    },
    activated() {
        this.init();
    },
    filters: {
        formatDate(val) {
            return val ? moment(val).format('YYYY-MM-DD HH:mm') : '';
        }
    }
}
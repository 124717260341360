import CitySelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.1.0/index.vue";
import NewCalendar from '@/page/admin/replace-orders/flight-search/component/newCalendar/index.vue'
import DomesticTicketAirlineSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.1.0/index.vue';
import moment from "moment";
import consumer_flight_search_airlines from '@/lib/data-service/haolv-default/consumer_flight_search_airlines';
import NewLuggageClause from "@/page/admin/replace-orders/flight-search/component/newLuggageClause/index.vue";
import consumer_air_ticket_fligthLuggage from '@/lib/data-service/haolv-default/consumer_air_ticket_fligthLuggage'
import NewChangeClause from "@/page/admin/replace-orders/flight-search/component/newChangeClause/index.vue";
import consumer_flight_search_stipulates from '@/lib/data-service/haolv-default/consumer_flight_search_stipulates'
import consumer_trains_order_orderScheduledCheck from '@/lib/data-service/haolv-default/consumer_trains_order_orderScheduledCheck' // 校验超标
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import { awaitWrap } from '@/page/admin/replace-orders/travel-reserve/common/unit'
import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail' // 获取出差信息
import consumer_web_policyConfig_travelPolicyTips from '@/lib/data-service/haolv-default/consumer_web_policyConfig_travelPolicyTips' // 差标提示
import consumer_apply_myApplyList from '@/lib/data-service/haolv-default/consumer_apply_myApplyList' // 我的出差
import consumer_journey_addOrUpdateOverproof from '@/lib/data-service/haolv-default/consumer_journey_addOrUpdateOverproof' // 创建超标订单
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg' // 保存信息
import consumer_tOdHotelOrderInterim_updateCheckInPerson from '@/lib/data-service/haolv-default/consumer_tOdHotelOrderInterim_updateCheckInPerson' // 重新安排酒店房间入住
import consumer_web_policyConfig_myTravelPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_myTravelPolicy'
import consumer_flight_order_flightsStop from '@/lib/data-service/haolv-default/consumer_flight_order_flightsStop'
import FlightListSkeleton from '@/component/skeleton/flight-list-skeleton/index.vue'
import consumer_admin_flight_budgetOrder from '@/lib/data-service/haolv-default/consumer_admin_flight_budgetOrder'
import consumer_admin_behalfCustomer_getServiceAmount from '@/lib/data-service/haolv-default/consumer_admin_behalfCustomer_getServiceAmount'
import CompanyInfo from '@/component/company-info/import_latest_version_module'
import consumer_flight_search_flightInfo from '@/lib/data-service/haolv-default/consumer_flight_search_flightInfo'
import consumer_flight_search_flightPrice from '@/lib/data-service/haolv-default/consumer_flight_search_flightPrice'

import CopyReportPopup from '@/component/copyReportPopup/import_latest_version_module'
import PolicyTypeLabel from '@/page/admin/replace-orders/component/policyTypeLabel/index.vue'
import Sticky from 'vant/lib/sticky'
import 'vant/lib/sticky/style'

// import consumer_flight_search_stipulates from '@/lib/common-service/consumer_flight_search_stipulates'
// import consumer_air_ticket_fligthLuggage from '@/lib/common-service/consumer_air_ticket_fligthLuggage'
// import consumer_flight_order_flightsStop from '@/lib/common-service/consumer_flight_order_flightsStop'
import consumer_flight_search_queryPlaneNameByCodes
    from "@/lib/data-service/haolv-default/consumer_flight_search_queryPlaneNameByCodes";
import consumer_flight_search_queryMeals from "@/lib/data-service/haolv-default/consumer_flight_search_queryMeals";
export default {
    data () {
        return {
            evectionNo: '',
            evectionType: '',
            userInfo: {}, // 个人信息
            evectionParams: {},
            orderField: '', // 排序字段，depTime-起飞时间 arrTime-到达时间。basePrice-价格排序 默认按起飞时间
            orderType: '', // 排序类型，asc-从小到大/从早到晚/从低到高 desc-从大到小/从晚到早/从高到低。默认asc
            formData: {
                flightType: 1,
                depDate: '',
                comeBackDate: '',
                depCityPort: {},
                arrCityPort: {},

            },
            searchFormData: {
                flightType: 1,
                depDate: '',
                comeBackDate: '',
                depCityPort: {},
                arrCityPort: {},
            },
            ticketAirlineSelector: null,
            params: {},//预定航班信息

            directFlight: 0, // 是否直飞 0=不限 1=是 2=其它
            hideShareFlight: 0, // 隐藏共享航班， 1=隐藏 0=不隐藏
            psgType: 1, //  乘机人类型

            // saveWeek: '', //日历active
            startPickerOptions: {
                disabledDate: time => {
                    return time.getTime() < Date.now() - 1000 * 3600 * 24;
                }
            },
            endPickerOptions: {
                disabledDate: time => {
                    const today = time.getTime();
                    if (this.searchFormData.depDate) {
                        const depDate = new Date(this.searchFormData.depDate + ' 00:00:00').valueOf();
                        return today < depDate;
                    } else {
                        return time.getTime() < Date.now() - 1000 * 3600 * 24;
                    }
                }
            },
            airlinesList: [],
            airlinesParams: '',
            classTypeList: [
                //  舱位列表
                /*{
                    value: 0,
                    label: "不限"
                },*/
                {
                    value: '1,2',
                    label: "头等/商务舱"
                },
                /*{
                    value: 2,
                    label: "公务舱"
                },*/
                {
                    value: '3',
                    label: "经济舱"
                }
            ],
            cabinParams: '',
            takeOffTime: [
                /*{ value: "", label: "全部" },*/
                { value: "00:00-05:59", label: "00:00-05:59" },
                { value: "06:00-11:59", label: "06:00-11:59" },
                { value: "12:00-17:59", label: "12:00-17:59" },
                { value: "18:00-23:59", label: "18:00-23:59" }
            ],
            takeOffTimeParams: '',
            planeSizeList: [
                {value: 1, label: '大型机'},
                {value: 2, label: '中型机'},
                {value: 3, label: '其他型机'},
            ],
            planeSizeParams: '',
            depAirportList: [],
            depAirportParams: '', // 出发城市机场搜索关键词
            arrAirportList: [],
            arrAirportParams: '',


            airCompanyVisible: false,

            oriFlightsList: [],
            filterFlightList: [], // 中间存储列表
            flightsList: [],


            flightReturnList: [], // 返程航班
            flightReturnIndex: 0, // 往返的当前选择index
            currentPolicyItem: {}, // 暂存选中的policyItem信息
            currentItem: {}, // 暂存选中的航班信息
            returnCheckIndex: 0, // 往返审核的时候的顺序

            loadingShow: true,
            percent: 0,
            percentSetInterval: '',

            luggageParams: {},
            luggage: "",
            ruleText: [],
            ruleLoading: false,
            luggageLoading: false,

            canJourney: true,
            journeyNo: '',

            // 出差信息
            evectionInfo: {},
            trainList: [],
            flightList: [],
            hotelList: [],
            travelUserList: [],

            travelPolicyTips: [],
            popoverLoading: false,
            myTravelPolicy: {},



            dialogVisible: false,
            canSelectTravelUserList: [], // 可以选的出行人（出差单里的）
            // popSelectTravelUserList: [],
            travelUserCheckList: [],
            newTravelUser: [],
            pendingTravelUserList: [], // 待定的出行人列表

            hotelUserVisible: false,
            hotelForm: {
                roomNum: 0,
                checkInPerson: []
            },
            hotelRules: {},
            hotelUserList: [],
            loades: 10,

            copyDialog: false,
            copyTextarea: '',
            copyLoading: false,

            companyId: '',

            dialogErrorVisible: false,
            dialogError2Visible: false,
            errorText: '',
            returnSecond: 3,
            returnSetInterval: '',

            container: null,
            containerMarginLeft: 0,
            containerMarginRight: 0,
        }
    },
    components: {
        CitySelector,
        NewCalendar,
        DomesticTicketAirlineSelector,
        NewLuggageClause,
        NewChangeClause,
        //CheckTravel,
        FlightListSkeleton,
        CompanyInfo,
        Sticky,
        CopyReportPopup,
        PolicyTypeLabel
    },
    created () {},
    mounted() {
        this.container = this.$refs.container;
    },
    async activated () {
        this.$refs.depCityPort.init({
            placeholder_text: `请选择出发城市`,
        })
        //搜索航班
        let query = this.$route.query
        this.companyId = query.behalfCompanyId
        this.evectionNo = query.evectionNo
        this.evectionType = parseInt(query.evectionType)
        let obj = {
            flightType: Number(query.flightType) || 1,//航程类型
            depCityPort: {
                name: query.fromStationName,
                code: query.fromStationCode
            },
            arrCityPort: {
                name: query.toStationName,
                code: query.toStationCode
            },
            depDate: query.startDate || moment(new Date()).format('YYYY-MM-DD'),//去程
            comeBackDate: query.endDate || '',//返程
        }
        this.searchFormData = Object.assign(this.searchFormData, obj)
        this.params = {};
        this.canJourney = true;
        this.currentPolicyItem  = {};
        this.currentItem = {};
        this.flightReturnIndex = 0;
        // this.saveWeek = obj.depDate
        if ((query.fromStationCode === '' && query.fromStationName !== '') || (query.toStationCode === '' && query.toStationName !== '')) {
            let setDepCityPort = query.fromStationName ? this.$refs.depCityPort.set_entity({
                type: `000`,
                type_parameter: {
                    '000': {
                        name: query.fromStationName,
                    },
                }
            }) : ''
            let setArrCityPort = query.toStationName ? this.$refs.arrCityPort.set_entity({
                type: `000`,
                type_parameter: {
                    '000': {
                        name: query.toStationName,
                    },
                }
            }) : ''
            Promise.all([setDepCityPort, setArrCityPort]).then((res) => {
                console.log(res)
                this.reactSearch()
            })
        } else {
            this.reactSearch()
        }
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {
        filterPercent(val) {
            if (val < 16) {
                return '航班信息准备中...'
            } else if (val >= 16 && val < 36) {
                return '机票舱位准备中...'
            } else if (val >= 36 && val < 56) {
                return '机票坐席准备中...'
            } else if (val >= 56 && val < 80) {
                return '机票折扣准备中...'
            } else if (val >= 80 && val < 100) {
                return '低价机票准备中...'
            } else {
                return ''
            }
        },
        filterPlaneSize(val) {
            if (val === 1) {
                return '大型机'
            } else if (val === 2) {
                return '中型机'
            } else if (val === 3) {
                return '小型机'
            } else {
                return ''
            }
        },
        filterDate(val) {
            return moment(val).format('YYYY年MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
    },
    methods: {
        changeSticky(val) {
            if (val) {
                let containerMarginLeft = this.$refs.container.offsetLeft
                this.containerMarginLeft = containerMarginLeft
                this.containerMarginRight = 20
            } else {
                this.containerMarginLeft = 0
                this.containerMarginRight = 0
            }
        },
        async getUserInfo() {
            this.loading = true
            let [err, res] = await awaitWrap(get_user_info())
            this.loading = false
            this.userInfo = res.datas

            console.log(this.userInfo)
        },
        // 获取出差信息
        async getEvectionInfo() {
            let [err, res] = await awaitWrap(consumer_journey_getEvectionDetail({evectionNo: this.evectionNo, pageType: 1, personType: 2}))
            if (err) {
                return
            }
            this.evectionInfo = res.datas
            this.evectionParams = {
                evectionNo: res.datas.evectionNo,
                evectionType: res.datas.evectionType,
                explainDesc: res.datas.explainDesc,
                feeAffiliationId: res.datas.feeAffiliationId,
                feeAffiliationName: res.datas.feeAffiliationName, // 费用归属名称
                feeAffiliationType: res.datas.feeAffiliationType ? res.datas.feeAffiliationType : 1,
                linkman: res.datas.linkman ? res.datas.linkman : this.userInfo.realName,
                linkmanPhone: res.datas.linkmanPhone ? res.datas.linkmanPhone : this.userInfo.phone,
                reason: res.datas.reason,
                travelUserList: res.datas.travellerList ? res.datas.travellerList : [],
            }
            this.travelUserList = res.datas.travellerList ? res.datas.travellerList : []
            // 获取火车票行程
            let trainList = res.datas.ticketBookingPageDetailsResponseList
            this.trainList = trainList
            // 获取机票行程
            let flightList = res.datas.flightDetail ? res.datas.flightDetail.orderList : []
            this.flightList = flightList
            // 获取酒店行程
            let hotelList = res.datas.todHotelOrderInterimList ? res.datas.todHotelOrderInterimList : []
            this.hotelList = hotelList
        },
        // 获取整体的差标提示
        getTravelPolicyTips() {
            let params = {
                businessType: 1,
                evectionNo: this.evectionNo
            }
            consumer_web_policyConfig_travelPolicyTips(params).then(res => {
                this.travelPolicyTips = res.datas.flightMsg
            })
        },
        // 获取当前出差单信息和可选择的出行人列表
        getTravelInfoAndUserList() {
            let params = {
                evectionNo: this.evectionNo,
                approveType: 1,
            }
            consumer_apply_myApplyList(params).then(res => {
                this.canSelectTravelUserList = res.datas.list[res.datas.list.length - 1].staffList
            })
        },
        getAirlines() {
            consumer_flight_search_airlines({
                currentPage: 1,
                pageSize: 9999,
            }).then(res => {
                let airlinesList = res.pageResult.pageData
                airlinesList.forEach(value => {
                    value.label = value.airCompanyShortName
                    value.value = value.airlineCode
                })
                this.airlinesList = airlinesList
            })
        },
        handleCommandAirport(val) {
            this.airlinesParams = val
            // this.search()
            this.trainFilter()
        },
        handleCloseAirlines() {
            this.airlinesParams = ''
            //this.search()
            this.trainFilter()
        },
        handleCommandCabin(val) {
            this.cabinParams = val
            // this.search()
            this.trainFilter()
        },
        handleCloseCabin() {
            this.cabinParams = ''
            // this.search()
            this.trainFilter()
        },
        handleCommandTakeOffTime(val) {
            this.takeOffTimeParams = val
            // this.search()
            this.trainFilter()
        },
        handleCloseTakeOffTime() {
            this.takeOffTimeParams = ''
            // this.search()
            this.trainFilter()
        },
        handleCommandPlaneSize(val) {
            this.planeSizeParams = val
            // this.search()
            this.trainFilter()
        },
        handleClosePlaneSize() {
            this.planeSizeParams = ''
            // this.search()
            this.trainFilter()
        },
        handleCommandDepAirport(val) {
            if (val === '') {
                return
            }
            this.depAirportParams = val
            // this.search()
            this.trainFilter()
        },
        handleCloseDepAirport() {
            this.depAirportParams = ''
            // this.search()
            this.trainFilter()
        },
        handleCommandArrAirport(val) {
            if (val === '') {
                return
            }
            this.arrAirportParams = val
            // this.search()
            this.trainFilter()
        },
        handleCloseArrAirport() {
            this.arrAirportParams = ''
            // this.search()
            this.trainFilter()
        },
        clearAllCommandToSearch() {
            this.airlinesParams = ''
            this.cabinParams = ''
            this.takeOffTimeParams = ''
            this.planeSizeParams = ''
            this.depAirportParams = ''
            this.arrAirportParams = ''
            // this.search()
            this.trainFilter()
        },
        clearAllCommand() {
            this.airlinesParams = ''
            this.cabinParams = ''
            this.takeOffTimeParams = ''
            this.planeSizeParams = ''
            this.depAirportParams = ''
            this.arrAirportParams = ''
        },
        setFlightReturnList() {
            this.flightReturnList = []
            if (this.formData.flightType === 2) {
                let deptJourney = {
                    depCityName: this.formData.depCityPort.name,
                    depCityCode: this.formData.depCityPort.code,
                    arrCityName: this.formData.arrCityPort.name,
                    arrCityCode: this.formData.arrCityPort.code,
                    depDate: this.formData.depDate
                }
                let arrJourney = {
                    depCityName: this.formData.arrCityPort.name,
                    depCityCode: this.formData.arrCityPort.code,
                    arrCityName: this.formData.depCityPort.name,
                    arrCityCode: this.formData.depCityPort.code,
                    depDate: this.formData.comeBackDate
                }
                this.flightReturnList.push(deptJourney)
                this.flightReturnList.push(arrJourney)
            }
        },
        clickDate(val) {
            this.flightReturnIndex === 0 ? this.formData.depDate = val : this.formData.comeBackDate = val
            this.search();
        },
        changeDirectFlight(val) {
            this.search()
        },
        changeHideFlight() {
            this.trainFilter();
        },
        changeFormAndTo() {
            let depCityPort = JSON.parse(JSON.stringify(this.searchFormData.depCityPort))
            let arrCityPort = JSON.parse(JSON.stringify(this.searchFormData.arrCityPort))
            // this.formData.depCityPort = arrCityPort
            // this.formData.arrCityPort = depCityPort
            this.$set(this.searchFormData, 'depCityPort', arrCityPort)
            this.$set(this.searchFormData, 'arrCityPort', depCityPort)
            // this.reactSearch()
        },
        changeFlight() {
            // 清空所有搜索条件
            this.clearAllCommand()
            this.flightReturnIndex = 0
            this.currentItem = {};

            this.search()

        },
        sortSwitch(orderField) {
            if (this.orderField === orderField) {
                // 如果本来就是起飞时间排序
                let sortArr = ['asc', 'desc', '']
                let sortIndex = sortArr.indexOf(this.orderType)
                sortIndex === 2 ? sortIndex = 0 : sortIndex++
                this.orderType = sortArr[sortIndex]
            } else {
                // 如果不是起飞时间排序
                this.orderField = orderField
                this.orderType = 'asc'
            }
            this.sortList()
        },
        reactSearch() {
            this.dialogErrorVisible = false
            this.dialogError2Visible = false
            // 清空所有搜索条件
            this.clearAllCommand()
            // 重构往返订单的结果列表
            this.formData = JSON.parse(JSON.stringify(this.searchFormData));
            this.search()
        },
        handleReactSearch() {
            this.dialogErrorVisible = false
            this.dialogError2Visible = false
            // 清空所有搜索条件
            this.clearAllCommand()
            // 重构往返订单的结果列表
            let cityHasChange = false;
            let startTimeHasChange = false;
            // let endTimeHasChange = false;
            if (this.searchFormData.depCityPort.code !== this.formData.depCityPort.code || this.searchFormData.arrCityPort.code !== this.formData.arrCityPort.code) {
                cityHasChange = true;
            }
            if (this.searchFormData.depDate !== this.formData.depDate) {
                startTimeHasChange = true;
            }
            if (this.searchFormData.flightType === 2 && (cityHasChange || startTimeHasChange)) {
                // 返回搜索第一程
                this.currentItem = {};
                this.currentPolicyItem = {};
                this.flightReturnIndex = 0;
            }
            this.formData = JSON.parse(JSON.stringify(this.searchFormData));
            this.search()
        },
        search() {
            let formData = this.formData

            let detection = (obj, rule) => {
                let state = {
                    rule: rule || ['depDate'],
                    inputRule: ['depCityPort', 'arrCityPort'],
                    status: false
                }
                state.status = state.rule.some((name, index) => {
                    if (!obj[state.inputRule[index]]) {
                        if (this.$refs[state.inputRule[index]]) {
                            this.$refs[state.inputRule[index]].$refs.aBaseEntitySelector.$refs.aInput.$refs.input.click()
                        }
                        return true
                    }
                    if (!obj[name]) {
                        this.$refs[name] ? this.$refs[name].focus() : ''
                        return true
                    }
                })
                return state.status
            }

            if (this.formData.flightType === 1) {
                if (detection(formData)) return
            } else {
                let arr = ['depDate', 'comeBackDate']
                if (detection(formData, arr)) return
            }

            // this.flightReturnIndex === 0 ? this.saveWeek = this.formData.depDate : this.saveWeek = this.formData.comeBackDate

            // 获取出发城市机场的三字码
            let depCityPortCode = ''
            if (this.depAirportParams!== '') { // 如果有搜索关键词
                depCityPortCode = this.depAirportParams.value
            } else {
                // 没有搜索关键词
                depCityPortCode = this.flightReturnIndex === 0 ? this.formData.depCityPort.code : this.formData.arrCityPort.code
            }
            // 获取到达地城市机场的三字码
            let arrCityPortCode = ''
            if (this.arrAirportParams !== '') {
                arrCityPortCode = this.arrAirportParams.value
            } else {
                arrCityPortCode = this.flightReturnIndex === 0 ? this.formData.arrCityPort.code : this.formData.depCityPort.code
            }

            let params = {
                // 航程类型
                flightType: formData.flightType,
                needPnr: 0,
                // 出发城市三字码。
                depCityPortCode: depCityPortCode,
                // 到达城市三字码。
                arrCityPortCode: arrCityPortCode,
                // 出发日期
                depDate: this.flightReturnIndex === 0 ? this.formData.depDate : this.formData.comeBackDate,
                // 仓位等级 0=不限 1=头等舱 2=公务舱 3=经济舱。
                cabinRank: this.cabinParams ? this.cabinParams.value : '',
                // 航空公司二字码
                airLineCode: this.airlinesParams ? this.airlinesParams.value : '',
                // psgType
                psgType: this.psgType,
                //  是否直飞
                directFlight: this.directFlight,
                // 折扣
                discount: 0,
                orderField: this.orderField,
                orderType: this.orderType,
                takeOffInterval: this.takeOffTimeParams ? this.takeOffTimeParams.value : '',
                flightNo: formData.flightNo ? formData.flightNo : '',
                planeSize: this.planeSizeParams ? this.planeSizeParams.value : '',

                keyCompanyId: this.companyId
            };

            let data = {
                companyCode: '',
                rqData: Object.assign({}, params),
                sign: '',
                timeStamp: ''
            }
            this.getFlights(data);
        },
        // 获取航班列表
        getFlights(params) {
            this.percent = 0
            this.loadingShow = true
            this.flightsList = [];
            consumer_flight_search_flightInfo(params).then(data => {
                if (!data.rsData) {
                    this.loadingShow = false
                    return
                }
                let flightsList = data.rsData
                let airlinesCodeList = [] // 航司Code合集
                let airlinesList = [] // 航司合集
                let depAirportCodeList = [] // 起飞机场的code合集
                let depAirportList = [] // 起飞机场的合集
                let arrAirportCodeList = [] // 降落机场的code合集
                let arrAirportList = [] // 降落机场的合集
                flightsList.forEach((value, index) => {
                    if (!value.policys) {
                        value.policys = []
                    }
                    value.show = 0
                    value.flightsShow = []
                    value.price = 0
                    value.discount = 0
                    value.cabin = ''
                    value.hasMeals = value.isHasMeal ? '有餐食':'无餐食'
                    //let productTypeTit = [] // 收入tit
                    let productList = []
                    value.policys.forEach((value1, index1) => {
                        if (index1 === 0) {
                            value.price = value1.priceDatas[0].settlement
                            value.discount = value1.cabin[0].discount
                            value.cabin = value1.cabin[0].cabinName
                        } else {
                            if (value.price > value1.priceDatas[0].settlement) {
                                value.price = value1.priceDatas[0].settlement
                                value.discount = value1.cabin[0].discount
                                value.cabin = value1.cabin[0].cabinName
                            }
                        }
                    })
                    value.productList = productList

                    if (airlinesCodeList.indexOf(value.airCompanyCode) === -1) {
                        airlinesCodeList.push(value.airCompanyCode)
                        airlinesList.push({
                            value: value.airCompanyCode,
                            label: value.airCompanyName
                        })
                    }
                    if (depAirportCodeList.indexOf(value.depAirportCode) === -1) {
                        depAirportCodeList.push(value.depAirportCode)
                        depAirportList.push({
                            value: value.depAirportCode,
                            label: value.depAirportName
                        })
                    }
                    if (arrAirportCodeList.indexOf(value.arrAirportCode) === -1) {
                        arrAirportCodeList.push(value.arrAirportCode)
                        arrAirportList.push({
                            value: value.arrAirportCode,
                            label: value.arrAirportName
                        })
                    }
                })
                this.airlinesList = airlinesList
                this.depAirportList = depAirportList
                this.arrAirportList = arrAirportList
                this.oriFlightsList = flightsList
                console.log(this.flightsList)
                this.trainFilter()
            }).catch(err => {
                this.loadingShow = false
                clearInterval(this.percentSetInterval)
            });
        },
        trainFilter() {
            let oriFlightsList = JSON.parse(JSON.stringify(this.oriFlightsList))

            if (this.airlinesParams !== '') {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.airCompanyCode === this.airlinesParams.value) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.hideShareFlight === 1) {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.hasShare !== true) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.cabinParams !== '') {
                let flightItemList = []
                let cabinParamsArr = this.cabinParams.value.split(',')
                oriFlightsList.forEach(value => {
                    let policys = []
                    value.productList.forEach(value1 => {
                        let product = []
                        value1.productList.forEach(value2 => {
                            if (cabinParamsArr.indexOf(value2.cabin[0].cabinRank.toString()) >= 0) {
                                product.push(value2)
                            }
                        })
                        value1.productList = product
                        if (product.length > 0) {
                            policys.push(value1)
                        }
                    })
                    value.productList = policys
                    if (policys.length > 0) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.takeOffTimeParams !== '') {
                let flightItemList = []
                let takeOffTimeArr = this.takeOffTimeParams.value.split('-')
                let leftTime = this.timeToMinute(takeOffTimeArr[0])
                let rightTime = this.timeToMinute(takeOffTimeArr[1])
                oriFlightsList.forEach(value => {
                    let depTime = this.timeToMinute(value.depTime)
                    if (depTime >= leftTime && depTime <= rightTime) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.planeSizeParams !== '') {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.planeSize === this.planeSizeParams.value) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.depAirportParams !== '') {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.depAirportCode === this.depAirportParams.value) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.arrAirportParams !== '') {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.arrAirportCode === this.arrAirportParams.value) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            this.filterFlightList = oriFlightsList
            this.sortList()
        },
        timeToMinute(val) {
            let timeVal = val.split(':')
            let hour = parseInt(timeVal[0]) * 60
            let minute = parseInt(timeVal[1])
            return hour + minute
        },
        sortList() {
            let flightList = JSON.parse(JSON.stringify(this.filterFlightList))

            let timeToMinute = function (val) {
                let timeVal = val.split(':')
                let hour = parseInt(timeVal[0]) * 60
                let minute = parseInt(timeVal[1])
                return hour + minute
            }
            // 按出发时间正序排列
            let depTimeCompare = function (x, y) {
                let xFromTime = timeToMinute(x.depTime)
                let yFromTime = timeToMinute(y.depTime)
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按出发时间反序排列
            let depTimeReverseCompare = function (x, y) {
                let xFromTime = timeToMinute(x.depTime)
                let yFromTime = timeToMinute(y.depTime)
                if (xFromTime < yFromTime) {
                    return 1;
                } else if (xFromTime > yFromTime) {
                    return -1;
                } else {
                    return 0;
                }
            }
            // 按到达时间正序排列
            let arrTimeCompare = function (x, y) {
                let xFromTime = timeToMinute(x.arrTime)
                let yFromTime = timeToMinute(y.arrTime)
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按到达时间反序排列
            let arrTimeReverseCompare = function (x, y) {
                let xFromTime = timeToMinute(x.arrTime)
                let yFromTime = timeToMinute(y.arrTime)
                if (xFromTime < yFromTime) {
                    return 1;
                } else if (xFromTime > yFromTime) {
                    return -1;
                } else {
                    return 0;
                }
            }
            // 按价格正序排列
            let priceCompare = function (x, y) {
                let xPrice = x.policys[0].priceDatas[0].settlement
                let yPrice = y.policys[0].priceDatas[0].settlement
                if (xPrice < yPrice) {
                    return -1;
                } else if (xPrice > yPrice) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按价格反序排序
            let priceReverseCompare = function (x, y) {
                let xPrice = x.policys[0].priceDatas[0].settlement
                let yPrice = y.policys[0].priceDatas[0].settlement
                if (xPrice < yPrice) {
                    return 1;
                } else if (xPrice > yPrice) {
                    return -1;
                } else {
                    return 0;
                }
            }

            if (this.orderField === 'depTime') {
                if (this.orderType === 'asc') {
                    flightList.sort(depTimeCompare)
                } else if (this.formData.orderType === 'desc') {
                    flightList.sort(depTimeReverseCompare)
                }
            } else if (this.orderField === 'arrTime') {
                if (this.orderType === 'asc') {
                    flightList.sort(arrTimeCompare)
                } else if (this.orderType === 'desc') {
                    flightList.sort(arrTimeReverseCompare)
                }
            } else if (this.orderField === 'basePrice') {
                if (this.orderType === 'asc') {
                    flightList.sort(priceCompare)
                } else if (this.orderType === 'desc') {
                    flightList.sort(priceReverseCompare)
                }
            }
            this.loadingShow = false
            this.flightsList = flightList
            console.log(this.flightsList)
            //机型查询
            this.queryPlaneNames();
            //餐食
            // this.queryMeals();
        },
        toSearchAgain(item, index) {
            // 先清除搜索关键字
        },
        getBottomPrice(val){
            let num = 0;
            if (val.length != 0) {
                let box = val[0].concat(val[1] ? val[1] : []);
                num = box[0].priceDatas[0].settlement;
                for (let i = 0;i < box.length; i++) {
                    if (num > box[i].priceDatas[0].settlement) {
                        num = box[i].priceDatas[0].settlement;
                    }
                }
            }
            return num;
        },
        getPrice(val) {
            if (val) {
                return val.toFixed(2);
            } else {
                return 0;
            }
        },
        showChangeClause(policyItem, item) {
            this.ruleText = [];
            let data = {
                rqData: {
                    "arrCityPortCode": item.arrCityCode,
                    "cabinCode": policyItem.cabin[0].cabinCode,
                    "depCityPortCode": item.depCityCode,
                    "depDate": item.depDate,
                    "flightNo": item.flightNo,
                    "flightProductType": policyItem.flightProductType,
                    "flightType": this.formData.flightType,
                    "psgType": this.psgType,
                    extData: policyItem.extData,
                    policyId: policyItem.policyId,
                    businessExt: policyItem.businessExt,
                    companyId: this.companyId
                }
            };
            this.ruleLoading = true
            // consumer_flight_search_stipulates
            consumer_air_ticket_fligthLuggage(data).then(res => {
                this.ruleLoading = false
                if (res.datas.rsData) {
                    let refRule = res.datas.rsData.refRule;
                    let chaRule = res.datas.rsData.chaRule;
                    let refRuleTitArr = []
                    let chaRuleTitArr = []
                    let refRuleArr = []
                    let chaRuleArr = []
                    if (refRule && chaRule) {
                        refRule = refRule.rules
                        chaRule = chaRule.rules
                        refRule.forEach(value => {
                            value.type = "refRule"
                            if (refRuleTitArr.indexOf(value.ruleTime) === -1) {
                                refRuleTitArr.push(value.ruleTime)
                                refRuleArr.push(value)
                            }
                        })
                        chaRule.forEach(value => {
                            value.type = "chaRule"
                            if (chaRuleTitArr.indexOf(value.ruleTime) === -1) {
                                chaRuleTitArr.push(value.ruleTime)
                                chaRuleArr.push(value)
                            }
                        })
                        refRuleArr.sort((a, b) => {
                            return a.serial - b.serial
                        })
                        chaRuleArr.sort((a, b) => {
                            return a.serial - b.serial
                        })
                        // refRule.map(item => (item.type = "refRule"));
                        // chaRule.map(item => (item.type = "chaRule"));
                        this.ruleText = [refRuleArr, chaRuleArr];
                    }
                    this.luggage = res.datas.rsData.luggage
                } else {
                    this.ruleText = [];
                    this.luggage = ''
                }

            }).catch(() => {
                this.ruleLoading = false
            });
        },
        showLuggageClause(policyItem, item) {
            console.log(policyItem, item)
            this.luggageParams = {}
            let data = {
                rqData: {
                    "arrCityPortCode": item.arrAirportCode,
                    "cabinCode": policyItem.cabin[0].cabinCode,
                    "depCityPortCode": item.depAirportCode,
                    "depDate": item.depDate,
                    "flightNo": item.flightNo,
                    "flightProductType": policyItem.flightProductType,
                    "flightType": this.formData.flightType,
                    "psgType": this.psgType,
                    businessExt: policyItem.businessExt,
                    policyId: policyItem.policyId,
                    companyId: this.companyId
                }
            };
            this.luggageLoading = true
            consumer_air_ticket_fligthLuggage(data).then(res => {
                this.luggageLoading = false
                if (res.datas && res.datas.rsData) {
                    let rsData = res.datas.rsData
                    this.luggageParams = {
                        flyFree: rsData.flyFree,
                        freeWithYou: rsData.freeWithYou,
                        rests: rsData.rests,
                        luggage: rsData.luggage
                    }
                }
            }).catch(() => {
                this.luggageLoading = false
            })
        },
        async journeyType(policyItem, item) {
            if (!this.canJourney) {
                return
            }
            this.canJourney = false
            let cabinRank = this.cabinParams.value
            let formData = {
                rqData: {
                    "evectionNo": this.evectionNo,
                    "journeyNo": this.$route.query.orderNo || this.journeyNo,
                    cabinRank: cabinRank == 0 ? 1 : cabinRank == 3 ? 3 : 2,
                    directFlight: 0,
                    flightType: this.formData.flightType,
                    psgType: this.psgType,
                    segmentList: []
                },
                companyId: this.companyId,
                // linkmanSms: true,
            };
            let segment = {
                'ticketLimitTime': policyItem.cabin[0].ticketLimitTime,
                "airportTax": policyItem.priceDatas[0].airportTax,
                "billingFee": policyItem.priceDatas[0].billingFee,
                "cabinName": policyItem.cabin[0].cabinName,
                "cabinCode": policyItem.cabin[0].cabinCode,
                "cabinRank": policyItem.cabin[0].cabinRank,
                "chdCabinCode": policyItem.cabin[0].chdCabinCode,
                "comment": policyItem.comment,
                "crewType": policyItem.priceDatas[0].crewType,
                "discount": policyItem.cabin[0].discount,
                "fuelTax": policyItem.priceDatas[0].fuelTax,
                "policyId": policyItem.policyId,
                "price": policyItem.priceDatas[0].price,
                "priceTaxes": policyItem.priceDatas[0].priceTaxes,
                "productType": policyItem.productType,
                "segmentType": 1,//去返程
                "settlement": policyItem.priceDatas[0].settlement,
                "settlementPriceTaxes": policyItem.priceDatas[0].settlementPriceTaxes,
                "vtWorkTime": policyItem.vtWorkTime,
                "workTime": policyItem.workTime,
                "depTime": item.depTime,
                "flightProductType": policyItem.flightProductType,
                businessExt: policyItem.businessExt,
                airlineQuota: policyItem.priceDatas[0].airlineQuota,
                priceType: policyItem.priceDatas[0].priceType,
                priceSource: policyItem.priceDatas[0].priceType,
                "productTag": policyItem.productTag,

                "airCompanyCode": item.airCompanyCode,
                "airCompanyName": item.airCompanyName,
                "arrAirportCode": item.arrAirportCode,
                "arrAirportName": item.arrAirportName,
                "arrCityCode": item.arrCityCode,
                "arrCityName": item.arrCityName,
                "arrDate": item.arrDate,
                "arrTerminal": item.arrTerminal,
                "arrTime": item.arrTime,
                "chaRule": item.chaRule,
                "depAirportCode": item.depAirportCode,
                "depAirportName": item.depAirportName,
                "depCityCode": item.depCityCode,
                "depCityName": item.depCityName,
                "depDate": item.depDate,
                "depTerminal": item.depTerminal,
                "directFlight": item.directFlight,
                "duration": item.duration,
                "flightNo": item.flightNo,
                "planeType": item.planeType,
                "refRule": item.refRule,
                "stopNum": item.stopNum,

                // policyType: policyItem.policyType,
                hasShare: item.hasShare,
                sharedFlightNo: item.sharedFlightNo,
                sharedAirCompanyCode: item.sharedAirCompanyCode,
                isShareText: `实际乘坐航班：${item.sharedAirCompanyName}${item.sharedFlightNo}请按实际乘坐航班办理值机。`,
                infrastructure: '',
                pasgScopTag: policyItem.teenOldInfo.pasgScopTag,
                cardTypeScopTag: policyItem.teenOldInfo.cardTypeScopTag,
                ageScopTag: policyItem.teenOldInfo.ageScopTag,
                timeLimitQueue: policyItem.cabin[0].timeLimitQueue,
                tagProperty: policyItem.tagProperty,
            }
            /**单程 */
            segment.segmentType = 1
            formData.rqData.segmentList.push(Object.assign({}, segment))
            this.params = formData
            console.log(this.params)
            this.params.rqData.overproofApplyList = []
            this.reserveOrder()
        },
        // 往返预定
        returnJourney(policyItem, item) {
            if (!this.canJourney) {
                return
            }
            this.canJourney = false
            this.currentPolicyItem  = policyItem
            this.currentItem = item
            // this.flightReturnList[this.flightReturnIndex].flight = JSON.parse(JSON.stringify(this.currentItem))
            let cabinRank = this.cabinParams.value
            let formData = {
                rqData: {
                    "evectionNo": this.evectionNo,
                    "journeyNo": this.$route.query.orderNo || this.journeyNo,
                    cabinRank: cabinRank == 0 ? 1 : cabinRank == 3 ? 3 : 2,
                    directFlight: 0,
                    flightType: this.formData.flightType,
                    psgType: this.psgType,
                    segmentList: []
                },
                companyId: this.companyId,
                // linkmanSms: true
            };
            let segment = {
                'ticketLimitTime': policyItem.cabin[0].ticketLimitTime,
                "airportTax": policyItem.priceDatas[0].airportTax,
                "billingFee": policyItem.priceDatas[0].billingFee,
                "cabinName": policyItem.cabin[0].cabinName,
                "cabinCode": policyItem.cabin[0].cabinCode,
                "cabinRank": policyItem.cabin[0].cabinRank,
                "chdCabinCode": policyItem.cabin[0].chdCabinCode,
                "comment": policyItem.comment,
                "crewType": policyItem.priceDatas[0].crewType,
                "discount": policyItem.cabin[0].discount,
                "fuelTax": policyItem.priceDatas[0].fuelTax,
                "policyId": policyItem.policyId,
                "price": policyItem.priceDatas[0].price,
                "priceTaxes": policyItem.priceDatas[0].priceTaxes,
                "productType": policyItem.productType,
                "segmentType": 1,//去返程
                "settlement": policyItem.priceDatas[0].settlement,
                "settlementPriceTaxes": policyItem.priceDatas[0].settlementPriceTaxes,
                "vtWorkTime": policyItem.vtWorkTime,
                "workTime": policyItem.workTime,
                "depTime": item.depTime,
                "flightProductType": policyItem.flightProductType,
                businessExt: policyItem.businessExt,
                airlineQuota: policyItem.priceDatas[0].airlineQuota,
                priceType: policyItem.priceDatas[0].priceType,
                priceSource: policyItem.priceDatas[0].priceType,
                flightKey: item.flightKey,
                "productTag": policyItem.productTag,

                "airCompanyCode": item.airCompanyCode,
                "airCompanyName": item.airCompanyName,
                "arrAirportCode": item.arrAirportCode,
                "arrAirportName": item.arrAirportName,
                "arrCityCode": item.arrCityCode,
                "arrCityName": item.arrCityName,
                "arrDate": item.arrDate,
                "arrTerminal": item.arrTerminal,
                "arrTime": item.arrTime,
                "chaRule": item.chaRule,
                "depAirportCode": item.depAirportCode,
                "depAirportName": item.depAirportName,
                "depCityCode": item.depCityCode,
                "depCityName": item.depCityName,
                "depDate": item.depDate,
                "depTerminal": item.depTerminal,
                "directFlight": item.directFlight,
                "duration": item.duration,
                "flightNo": item.flightNo,
                "planeType": item.planeType,
                "refRule": item.refRule,
                "stopNum": item.stopNum,

                policyType: policyItem.policyType,
                hasShare: item.hasShare,
                isShare: item.hasShare ? 1 : 0, // 是否共享航班 1-是 0-否"
                sharedFlightNo: item.sharedFlightNo,
                sharedAirCompanyCode: item.sharedAirCompanyCode,
                sharedAirCompanyName: item.sharedAirCompanyName,
                isShareText: `实际乘坐航班：${item.sharedAirCompanyName}${item.sharedFlightNo}请按实际乘坐航班办理值机。`,
            }
            // 判断如果只选了一程
            if (this.flightReturnIndex === 1) {
                // 第二程
                segment.segmentType = 2;
                formData = JSON.parse(JSON.stringify(this.params));
                formData.rqData.segmentList.push(Object.assign({}, segment))
                formData.rqData.journeyNo = ''
                this.params = formData;
                //this.flightReturnList[this.flightReturnIndex].params = formData

                this.toBudgetOrder(0)
            } else {
                // 只选了一程
                formData.rqData.segmentList.push(Object.assign({}, segment))
                this.params = formData
                //this.flightReturnList[this.flightReturnIndex].params = formData
                this.clearAllCommand();
                this.addFlightBack()
            }
            this.canJourney = true
        },
        toBudgetOrder(index) {
            let loading
            loading = this.$loading({
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            consumer_admin_flight_budgetOrder(this.params).then(res => {
                loading.close()
                this.canJourney = true
                this.evectionNo = res.datas.rsData.evectionNo
                this.journeyNo = res.datas.rsData.orderNo
                /*if (index === 0) {
                    this.flightReturnList.forEach((value, index1) => {
                        if (index1 === 0) {
                            value.params.rqData.evectionNo = res.datas.rsData.evectionNo
                            value.params.rqData.journeyNo = res.datas.rsData.orderNo
                        } else {
                            value.params.rqData.evectionNo = res.datas.rsData.evectionNo
                        }
                    })
                } else {
                    this.flightReturnList.forEach((value, index1) => {
                        if (index1 !== 0) {
                            value.params.rqData.evectionNo = res.datas.rsData.evectionNo
                            value.params.rqData.journeyNo = res.datas.rsData.orderNo
                        } else {
                            value.params.rqData.evectionNo = res.datas.rsData.evectionNo
                        }
                    })
                }*/

                this.$router.push({
                    name: 'admin-flight-reserve',
                    query: {
                        orderNo: res.datas.rsData.orderNo || '',
                        evectionNo: this.$route.query.evectionNo || res.datas.rsData.evectionNo,
                        redirectType: '001',
                    }
                })

            }, error => {
                // 根据code返回弹窗信息
                loading.close()
                this.setErrorDialog(error.data)
            }).catch(() => {
                loading.close()
                this.canJourney = true
            })
        },
        // 下一程
        addFlightBack() {
            this.canJourney = true
            // this.reserveType = "选为回程";

            // 转换下一程的index
            this.flightReturnIndex = this.flightReturnIndex === 0 ? 1 : 0
            //this.flightReturnPrice = policys

            let obj = {
                arrCityPortCode: this.flightReturnIndex === 1 ? this.formData.depCityPort.code : this.formData.arrCityPort.code,
                depCityPortCode: this.flightReturnIndex === 1 ? this.formData.arrCityPort.code : this.formData.depCityPort.code,
                depDate: this.flightReturnIndex === 1 ? this.formData.comeBackDate : this.formData.depDate,
                // 仓位等级 0=不限 1=头等舱 2=公务舱 3=经济舱。
                cabinRank: this.formData.cabinRank,
                // 航空公司二字码
                airLineCode: this.formData.airLineCode,
                // psgType
                psgType: this.psgType,
                //  是否直飞
                directFlight: 0,
                // 折扣
                discount: 0,
                orderField: this.orderField,
                orderType: this.orderType,

                takeOffInterval: this.formData.takeOffInterval,

                'tripDepDate': this.flightReturnIndex === 1 ? this.currentItem['depDate'] : '',
                'tripDepTime': this.flightReturnIndex === 1 ? this.currentItem['depTime'] : '',
                keyCompanyId: this.companyId,
            }
            let params = JSON.parse(JSON.stringify(this.formData))
            let data = Object.assign(params, obj)
            // this.saveWeek =  this.flightReturnIndex === 1 ? this.formData.comeBackDate : this.formData.depDate
            //this.initStatu++
            this.getFlights({ rqData: data });
        },

        reserveOrder() {
            let loading
            loading = this.$loading({
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            consumer_admin_flight_budgetOrder(this.params).then(res => {
                loading.close()
                //this.$message({ type: 'success', message: '预定成功！' })
                this.$router.push({
                    name: 'admin-flight-reserve',
                    query: {
                        orderNo: res.datas.rsData.orderNo || '',
                        evectionNo: this.$route.query.evectionNo || res.datas.rsData.evectionNo,
                        redirectType: '001',
                        ticketLimitTime: res.datas.rsData.ticketLimitTime,
                    }
                })
            }, error => {
                // 根据code返回弹窗信息
                loading.close()
                this.setErrorDialog(error.data)
            }).catch((error) => {
                loading.close()
                this.setErrorDialog(error.data)
            }).finally(() => {
                this.canJourney = true
            })
        },
        // 根据错误code返回弹窗信息
        setErrorDialog(error) {
            this.errorText = error.msg
            if (error.code === '200028') {
                this.dialogErrorVisible = true;
                this.returnSecond = 3;
                this.returnSetInterval = setInterval(() => {
                    this.returnSecond--;
                    if (this.returnSecond <= 0) {
                        this.search()
                        clearInterval(this.returnSetInterval)
                        this.dialogErrorVisible = false
                    }
                }, 1000)
            } else {
                this.dialogError2Visible = true
            }
        },

        goReservePage() {
            this.$router.push({
                name: 'admin-travel-reserve',
                query: {
                    evectionNo: this.$route.query.evectionNo,
                    redirectType: '001'
                }
            })
        },
        submitSelectTravelUser() {
            this.dialogVisible = false

            let travelUserList = []
            for (let i = 0, l = this.canSelectTravelUserList.length; i < l; i++) {
                if (this.travelUserCheckList.indexOf(this.canSelectTravelUserList[i].userId) > -1) {
                    travelUserList.push(this.canSelectTravelUserList[i])
                }
            }
            // 获取此次勾选的新出行人
            let newTravelUserList = []
            travelUserList.forEach(value => {
                newTravelUserList.push(value.userId)
            })

            this.newTravelUser = newTravelUserList
            this.pendingTravelUserList = travelUserList
            // 提交校验
            if (this.trainList.length !== 0 || this.flightList.length !== 0 || this.hotelList.length !== 0) {
                this.$refs.checkChangeTravelBox.run()
            }
        },
        editTravelUser() {
            let travelUserCheckList = []
            this.travelUserList.forEach(value => {
                travelUserCheckList.push(value.userId || value.staffId)
            })
            this.travelUserCheckList = travelUserCheckList
            this.dialogVisible = true
        },
        initCheckChangeTravelBox() {
            this.$refs.checkChangeTravelBox.init({
                checkFun: () => {
                    let flightDate = []
                    if (this.flightList.length > 0) {
                        this.flightList.forEach(value => {
                            let flightItem = {
                                bottomPrice: '',
                                cabinRank: value.cabinRank,
                                depDate: value.segmentList[0].depDate,
                                discount: value.segmentList[0].discount,
                                price: value.segmentList[0].price,
                                evectionNo: this.evectionNo,
                                depTime: value.segmentList[0].depTime,
                                rqData: value.segmentList[0]
                                /*rqData: {
                                    depCityPortCode: '', // 出发三字码
                                    arrCityPortCode: '', // 到达三字码
                                    depDate: '', // 出发日期
                                }*/
                            }
                            flightDate.push(flightItem)
                        })
                    }
                    let params = {
                        evectionNo: this.evectionNo,
                        userIds: this.newTravelUser,
                        excessRequest: flightDate
                    }
                    this.loading = true
                    return consumer_trains_order_orderScheduledCheck(params).then(res => {
                        this.loading = false
                        return res.datas.violationResults || [] // 因无超标人时返回的空数组现在变成null，所以需自传空数组
                    }).catch(() => {
                        this.loading = false
                        return []
                    })
                },
                reserveSubmitFun: (warningList) => {
                    let overproofApplyList = []
                    if (warningList.length === 0) {
                        return new Promise(function (resolve) {
                            resolve([])
                        })
                    }
                    warningList.forEach(value => {
                        let item = {
                            businessType: value.businessType,
                            controlRule: value.controlRules,
                            orderNo: value.orderNo,
                            reason: value.reason,
                            reserveContent: value.violationOfReason,
                            standard: value.standard,
                            standardContent: value.seatName,
                            userId: value.userId,
                            userName: value.userRealName
                        }
                        overproofApplyList.push(item)
                    })

                    let params ={
                        evectionNo: this.evectionNo,
                        overproofApplyList
                    }
                    this.loading = true
                    return consumer_journey_addOrUpdateOverproof(params).then(res => {
                        this.loading = false
                        return {}
                    }).catch(() => {
                        this.loading = false
                    })
                },
                reserveResultFun: (result) => {
                    if (!result) {
                        return
                    }
                    this.travelUserList = JSON.parse(JSON.stringify(this.pendingTravelUserList))
                    this.evectionParams.travelUserList = JSON.parse(JSON.stringify(this.pendingTravelUserList))
                    // 清除报错提示
                    this.$refs['orderForm'].clearValidate('travelUserList')
                    this.saveInfo()

                    // 如果有酒店订单
                    if (this.evectionParams.travelUserList.length > 0) {
                        this.reactHotelUser()
                    }
                }
            })
        },
        // 保存信息
        async saveInfo() {
            let params = Object.assign({}, this.evectionParams)
            let userIdList = []
            this.evectionParams.travelUserList.forEach(value => {
                userIdList.push(value.userId)
            })
            params.userIdList = userIdList
            params.ticketRequests = this.trainList
            let [err, res] = await awaitWrap(consumer_journey_saveOrderMsg(params))
            if (err) {
                return false
            }
            return res
        },
        reactHotelUser() {
            if (this.hotelList.length > 0) {
                this.hotelForm.roomNum = this.hotelList[0].roomNum
                // this.hotelForm.roomNum = 2
                let checkInPerson = []
                for (let i = 0, l = this.hotelForm.roomNum; i < l; i++) {
                    checkInPerson.push('')
                }
                this.hotelForm.checkInPerson = checkInPerson

                // this.hotelUserList = [...this.evectionParams.travelUserList]
                this.hotelUserList = this.evectionParams.travelUserList.slice()
                this.hotelUserList.forEach(value => {
                    value.disabled = false
                })
                console.log('h', this.hotelUserList)

                this.hotelUserVisible = true
            }
        },
        validteCheckIn(rule, value, callback) {
            if (
                value === "" ||
                value === null ||
                value === undefined
            ) {
                callback(new Error("请选择入住人"));
            } else {
                callback();
            }
        },
        changeHotelUser(index, val) {
            console.log(index, val)
            if (val !== '') {
                this.hotelUserList.forEach(value => {
                    if (value.userId === val || this.hotelForm.checkInPerson.indexOf(value.userId) > -1) {
                        value.disabled = true
                    } else {
                        value.disabled = false
                    }
                })
                this.$refs.reserveForm.clearValidate(`checkInPerson.${index}`)
            } else {
                console.log(this.hotelForm.checkInPerson)
                this.hotelUserList.forEach(value => {
                    if (value.disabled && this.hotelForm.checkInPerson.indexOf(value.userId) === -1) {
                        value.disabled = false
                    }
                })
            }
        },
        commitHotelReserve() {
            this.$refs.reserveForm.validate((valid) => {
                if (valid) {
                    let params = {
                        checkInPerson: this.hotelForm.checkInPerson,
                        orderNo: this.hotelList[0].orderNo
                    }
                    // params.orderNo = this.hotelList[0].orderNo
                    consumer_tOdHotelOrderInterim_updateCheckInPerson(params).then(res => {
                        this.$message({
                            type: 'success',
                            message: '入住安排保存成功'
                        })
                        this.hotelUserVisible = false
                    }).catch(() => {

                    })
                }
            })
        },

        showTravelPolicyPopover(val) {
            this.popoverLoading = true
            let params = {
                user: {
                    id: val.userId
                }
            }
            consumer_web_policyConfig_myTravelPolicy(params).then(res => {
                this.popoverLoading = false
                this.myTravelPolicy = res.datas
            }).catch(() => {
                this.popoverLoading = false
                this.myTravelPolicy = {}
            })
        },

        getStopMsg(val, index) {
            let params = {
                "rqData": {
                    "date": val.depDate,
                    "flightNo": val.hasShare ? val.sharedFlightNo : val.flightNo,
                    'depCityCode': val.depCityCode,
                    'arrCityCode': val.arrCityCode,
                    'arrTime': val.arrTime,
                    'deptTime': val.depTime,
                    'depAirCode': val.depAirportCode,
                    'arrAirCode': val.arrAirportCode,
                }
            }
            // val.stopLoad = true
            this.flightsList[index].stopLoad = true
            consumer_flight_order_flightsStop(params).then(res => {
                let flightItem = this.flightsList[index]
                flightItem.stopResponseData = res.datas
                flightItem.stopLoad = false
                this.$set(this.flightsList, index, flightItem)
            }).catch(() => {
                this.flightsList[index].stopLoad = false
            })
        },
        copyReport(val, child) {
            this.copyDialog = true
            this.copyLoading = true
            this.copyTextarea = ''
            let data = {
                rqData: {
                    "arrCityPortCode": val.arrAirportCode,
                    "cabinCode": child.cabin[0].cabinCode,
                    "depCityPortCode": val.depAirportCode,
                    "depDate": val.depDate,
                    "flightNo": val.flightNo,
                    "flightProductType": child.flightProductType,
                    "flightType": this.formData.flightType,
                    "psgType": this.psgType,
                    extData: child.extData,
                    policyId: child.policyId,
                    businessExt: child.businessExt,
                    companyId: this.companyId,
                }
            };
            let cabinName = child.cabin[0].cabinName
            // "业务类型：1.机票，2.火车票，3.酒店，4.汽车"
            let priceParams = {
                businessType: 1,
                companyId: this.companyId,
                sum: child.priceDatas[0].settlement
            }
            let stopParams = {
                "rqData": {
                    "date": val.depDate,
                    "flightNo": val.hasShare ? val.sharedFlightNo : val.flightNo,
                    'depCityCode': val.depCityCode,
                    'arrCityCode': val.arrCityCode,
                    'arrTime': val.arrTime,
                    'deptTime': val.depTime,
                    'depAirCode': val.depAirportCode,
                    'arrAirCode': val.arrAirportCode,
                }
            }

            Promise.all([consumer_air_ticket_fligthLuggage(data), consumer_admin_behalfCustomer_getServiceAmount(priceParams), consumer_flight_order_flightsStop(stopParams)]).then(res => {

                let sumServicePrice = parseInt(res[1].datas.sumServicePrice)
                const includeService = res[0].datas.rsData.includedService
                const displayedService = res[0].datas.rsData.displayedService
                const comparison = res[0].datas.rsData.comparison

                let luggageInfo = ''
                if (res[0].datas && res[0].datas.rsData) {
                    luggageInfo = res[0].datas.rsData
                }
                let stopCityInfo = ''
                if (res[2].datas && res[2].datas.stopInfos && 3 === res[2].datas.stopInfos.length) {
                    stopCityInfo = res[2].datas.stopInfos[1]
                }
                this.setCopyText(val, child, cabinName, sumServicePrice, luggageInfo, stopCityInfo, includeService,displayedService, comparison)
            }).catch(() => {
                this.setCopyText(val, child, cabinName, '', '', '','', 0)
            }).finally(() => {
                this.copyLoading = false
            })
        },
        setCopyText(segment, child, cabinName,servicePrice, luggageInfo, stopCityInfo, includeService = 0,displayedService = 0, comparison = 0) {

            let luggageInfoText = '';
            if (luggageInfo.luggage) {
                luggageInfoText = luggageInfo.luggage.replace(luggageInfo.rests,'');
            }
            let stopInfoText = '';
            console.log('stopInfo', stopCityInfo)
            if (stopCityInfo) {
                let minutes = moment(this.$moment(segment.depDate + ' ' + stopCityInfo.depTime + ':00')).diff(moment(this.$moment(segment.depDate + ' ' + stopCityInfo.arrTime + ':00')),'minutes' )
                stopInfoText += '(经停' + stopCityInfo.cityName + minutes + 'min)';
            }
            let settlementPriceTaxes = 0;
            settlementPriceTaxes = settlementPriceTaxes + (child.priceDatas[0].settlement || 0) + (child.priceDatas[0].airportTax || 0) + (child.priceDatas[0].fuelTax || 0);
            if (includeService !== 1) {
                settlementPriceTaxes = settlementPriceTaxes + (child.priceDatas[0].serviceAmount || 0);
            }
            let message = '';
            message += `${segment.depDate} ${segment.airCompanyName} ${segment.flightNo}  ${segment.depCityName}（${segment.depAirportName}${segment.depTerminal || ''}）-${segment.arrCityName}（${segment.arrAirportName}${segment.arrTerminal || ''}） ${segment.depTime}-${segment.arrTime} ${stopInfoText}\n`
            message += `铨成结算价：￥${child.priceDatas[0].settlement}/人 机建：￥${child.priceDatas[0].airportTax}/人 燃油税：￥${child.priceDatas[0].fuelTax}/人${displayedService == 1 ? ` 服务费：￥${child.priceDatas[0].serviceAmount}/人` : ''}\n`;
            // message += `总计：￥${child.priceDatas[0].settlementPriceTaxes}/人 ${includeService === 1 ? '（不含服务费）' : ''}\n`;
            message += `总计：￥${settlementPriceTaxes}/人 ${includeService === 1 ? '（不含服务费）' : ''}\n`;
            if (comparison === 1) {
                message += `携程对比价：￥\n`;
            }
            const behalfCompanyId = parseInt(this.$route.query.behalfCompanyId);
            if ([250, 357, 217, 274, 275, 276, 277, 278, 279, 280, 281, 282, 370, 394].indexOf(behalfCompanyId) > -1) {
                message += `舱位：${child.cabin[0].discount}折${cabinName}\n`;
            } else {
                message += `舱位：${cabinName}\n`;
            }

            message += `行李规定：${luggageInfoText || '--'}\n`;
            if (this.companyId != '81') {
                message += '是否符合差标请自行确认\n';
            }
            message += `价格实时浮动，如需出票请尽快确认\n`;
            if (this.companyId == '81') {
                message += '如需预定请确认行程报价是否与贵司差标相符'
            }
            this.copyTextarea = message
        },
        handleCopyDialogClose() {
            this.copyDialog = false
        },
        submitCopy() {
            this.$copyText(this.copyTextarea).then((e) => {
                this.$message({
                    message: '提示：已复制',
                    type: 'success'
                });
            }, (e) => {
                this.$message.error('提示：复制失败');
            })
            this.copyDialog = false
        },
        reloadItem(item, index) {
            item.show === 0 ? item.show = 1 : item.show = 0

            if (item.policys && item.policys.length > 0) {
                return;
            }
            item.loading = true
            let params = {
                "rqData": {
                    "arrAirportCode": item.arrAirportCode,
                    "businessExt": item.businessExt,
                    "deptAirportCode": item.depAirportCode,
                    "deptDate":item.depDate,
                    "deptTime": item.depTime,
                    "flightNo": item.flightNo,
                    "psgType": this.psgType,
                    "shareFlightNo": item.sharedFlightNo,
                    keyCompanyId: this.companyId,
                    supplierType: item.supplierType,
                },
                depCityPortCode: item.depCityCode,
                arrCityPortCode: item.arrCityCode,
                basePrice: item.basePrice,
                switches: false,
            }
            consumer_flight_search_flightPrice(params).then(res => {
                let productList = []
                let data = res.rsData
                // let list = res.rsData.policys
                if (res.rsData.settlement !== null && res.rsData.settlement !== undefined) {
                    item.basePrice = res.rsData.settlement
                }
                if (res.rsData.accord !== null && res.rsData.accord !== undefined) {
                    item.accord = res.rsData.accord
                }
                if (data.flightPolicyList && data.flightPolicyList.length > 0) {
                    productList.push(data.flightPolicyList)
                }
                if (data.policys && data.policys.length > 0) {
                    productList.push(data.policys)
                }
                item.loading = false
                item.policys = [...data.flightPolicyList, ...data.policys]
                item.productList = productList;

            }, error => {
                item.loading = false
                let errorCode = error.data.rsCode;
                this.errorText = error.data.rsMessage ? error.data.rsMessage : error.data.msg;
                if (errorCode === '090002') {
                    this.dialogErrorVisible = true;
                    this.returnSecond = 3;
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.reactSearch()
                            clearInterval(this.returnSetInterval)
                        }
                    }, 1000)
                } else {
                    this.dialogError2Visible = true
                }
            }).catch(() => {
                item.loading = false
            })
        },
        queryPlaneNames(){
            if (this.flightsList.length <= 0) {
                return;
            }
            const planeTypes = this.flightsList.map(e => {
                return e.planeType
            })
            consumer_flight_search_queryPlaneNameByCodes(planeTypes).then(res=>{
                let planeMap = new Map();
                if(res.datas){
                    res.datas.forEach(i=>{
                        planeMap.set(i.planeType,i.planeName);
                    })
                }
                this.flightsList.forEach(i=>{
                    let planeName = planeMap.get(i.planeType);
                    i.planeName = planeName? planeName:i.planeType;
                })
                this.$forceUpdate();

            }).catch(err=>{
                console.log('机型查询出错了',err);
            })
        },
        queryMeals() {

            if (this.flightsList.length <= 0) {
                return;
            }
            let promises = [];
            for (let i = 0; i < this.flightsList.length; i++) {
                let val = this.flightsList[i];
                let param = {
                    flightNo: val.flightNo,
                    deptTime: val.depTime,
                    arrTime: val.arrTime
                };
                promises.push(consumer_flight_search_queryMeals(param));
            }
            if(promises.length>0){
                Promise.all(promises).then( (posts) =>{
                    for (let i = 0; i < this.flightsList.length; i++) {
                        let meals = posts[i].datas;
                        this.flightsList[i].hasMeals = meals ? '有餐食':'无餐食';
                    }
                    // this.detail = detail;
                    this.$forceUpdate();
                }).catch(function(reason){
                    console.log('出错了',reason)
                });
            }
        },
        changeFirstTime(val) {
            console.log(val);
            let startDate = moment(val + ' 00:00:00').valueOf();
            if (this.formData.flightType === 2 ) {
                let endDate = moment(this.searchFormData.comeBackDate + ' 00:00:00').valueOf();
                if (startDate > endDate) {
                    // 打开
                    this.$refs.comeBackDate.focus();
                }
            }
        },
        blurEndDate() {
            let startDate = moment(this.searchFormData.depDate + ' 00:00:00').valueOf();
            let endDate = moment(this.searchFormData.comeBackDate + ' 00:00:00').valueOf();
            if (startDate > endDate) {
                this.searchFormData.depDate = this.formData.depDate
            }
        }
        /*
        * reloadItem(item, index) {
            item.show === 0 ? item.show = 1 : item.show = 0

            if (item.policys && item.policys.length > 0) {
                return;
            }
            item.loading = true
            let params = {
                "rqData": {
                    "arrAirportCode": item.arrAirportCode,
                    "businessExt": item.businessExt,
                    "deptAirportCode": item.depAirportCode,
                    "deptDate":item.depDate,
                    "deptTime": item.depTime,
                    "flightNo": item.flightNo,
                    "psgType": this.psgType,
                    "shareFlightNo": item.sharedFlightNo,
                    keyCompanyId: this.companyId,
                },
                depCityPortCode: item.depCityCode,
                arrCityPortCode: item.arrCityCode,
                basePrice: item.basePrice
            }
            consumer_flight_search_flightPrice(params).then(res => {
                let productTypeTit = [] // 收入tit
                let productList = []
                let list = res.rsData.policys
                if (res.rsData.settlement !== null) {
                    item.basePrice = res.rsData.settlement
                }
                if (res.rsData.accord !== null) {
                    item.accord = res.rsData.accord
                }
                let cabinLevel1 = []
                let cabinLevel2 = []
                let cabinLevel3 = []
                // 分类好座舱级别
                list.forEach((value1, index1) => {
                    if (value1.cabin[0].cabinRank === 1) {
                        cabinLevel1.push(value1)
                    }
                    if (value1.cabin[0].cabinRank === 2) {
                        cabinLevel2.push(value1)
                    }
                    if (value1.cabin[0].cabinRank === 3) {
                        cabinLevel3.push(value1)
                    }
                })
                // 各座舱排序
                cabinLevel1.sort((a, b) => {
                    return a.priceDatas[0].settlement - b.priceDatas[0].settlement
                })
                cabinLevel2.sort((a, b) => {
                    return a.priceDatas[0].settlement - b.priceDatas[0].settlement
                })
                cabinLevel3.sort((a, b) => {
                    return a.priceDatas[0].settlement - b.priceDatas[0].settlement
                })
                let firstList = []
                let secondList = []
                cabinLevel3.forEach((value1, index1) => {
                    if (index1 === 0 || index1 === 1 || (index1 + 1 === cabinLevel3.length && index1 > 1)) {
                        firstList.push(value1)
                    } else {
                        secondList.push(value1)
                    }
                })
                cabinLevel2.forEach((value1, index1) => {
                    if (index1 === 0) {
                        firstList.push(value1)
                    } else {
                        secondList.push(value1)
                    }
                })
                cabinLevel1.forEach((value1, index1) => {
                    if (index1 === 0) {
                        firstList.push(value1)
                    } else {
                        secondList.push(value1)
                    }
                })
                productList.push(firstList)
                if (secondList.length > 0) {
                    productList.push(secondList)
                }
                item.loading = false
                item.policys = list
                item.productList = productList

            }, error => {
                item.loading = false
                let errorCode = error.data.rsCode;
                this.errorText = error.data.rsMessage ? error.data.rsMessage : error.data.msg;
                if (errorCode === '090002') {
                    this.dialogErrorVisible = true;
                    this.returnSecond = 3;
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.reactSearch()
                            clearInterval(this.returnSetInterval)
                        }
                    }, 1000)
                } else {
                    this.dialogError2Visible = true
                }
            }).catch(() => {
                item.loading = false
            })
        },*/
        /*setTypeLabelColor(val) {
            //"supplier": "1,2,3,4,12,13,9,OPL9,SGL1,OPL6,OPL11,OPL18,OPL17,OPL20,OPL16,OPL26,OPL23,OPL30,60,FD,NFD,VARI",
            //"supplierText": "其他渠道(供应商)【平台优选】",
            // "bigCustomers": "QCYX",
            //"bigCustomersText": "企业协议(大客户)",
            //"lowPrice": "41,42,43,44,45,26,YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL1,NSTD,62",
            //"lowPriceText": "低价推荐",
            // "website": "21,22,23,24,25,6,CXF1,WS",
            //"websiteText": "航司官网(官网)",
            //"platformProprietary": "61",
            //"platformProprietaryText": "商旅优选(自营)【商旅推荐】"
            const btoList = ['1','2','3','4','12','13', '9', 'OPL9', 'SGL1','OPL6','OPL11','OPL18','OPL17','OPL20','OPL16','OPL26','OPL23','OPL30','60','FD','NFD','VARI']
            const lprList = ['41','42','43','44','45','26','YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL1','NSTD','62']
            const owList = ['21','22','23','24','25','6','CXF1','WS']
            const qcList = ['QCYX']
            const ptList = ['61']
            if (btoList.indexOf(val) > -1) {
                return 'type1'
            } else if (lprList.indexOf(val) > -1) {
                return 'type2'
            } else if (owList.indexOf(val) > -1) {
                return 'type3'
            } else if (qcList.indexOf(val) > -1) {
                return 'type4'
            } else if (ptList.indexOf(val) > -1) {
                return 'type5'
            } else {
                return 'type6'
            }
        },*/
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/

import consumer_admin_behalfCustomer_addcertificatetype
    from 'yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_admin_behalfCustomer_addcertificatetype'
// import consumer_admin_behalfCustomer_certificates from 'yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_admin_behalfCustomer_certificates'
import consumer_country_queryCountryTwoWord from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/consumer_country_queryCountryTwoWord'
import consumer_admin_flight_generate_certificateType from 'yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType'
import moment from "moment";

export default {
    data() {
        const validateBirthday = (rule, value, callback) => {
            if (value === '') {
                callback()
                return
            }
            let birthday = value
            let birthdayArr = birthday.split('-');
            let yearSubmit = parseInt(birthdayArr[0]) !== NaN && parseInt(birthdayArr[0]) >= 1900;
            let monthSubmit = parseInt(birthdayArr[1]) !== NaN && parseInt(birthdayArr[1]) >= 1 && parseInt(birthdayArr[1]) <= 12;
            let daySubmit = parseInt(birthdayArr[2]) !== NaN && parseInt(birthdayArr[2]) >= 1 && parseInt(birthdayArr[2]) <= 31;
            if (!yearSubmit || !monthSubmit || !daySubmit) {
                callback(new Error("出生年月填写有误"));
            } else {
                callback()
            }
        }
        return {
            dialogNewCertificatesVisible: false,
            userId: '',
            userInfo: {},
            newCertificatesParams: {
                birthDay: '',
                certificateType: 1,
                certificateValue: '',
                userId: '',
                issCountryCode: 'CN',
                cardExpireDate: '',
                countryCode: 'CN',
                familyName: '',
                givenName: '',
                englishSurnames: '',
                englishName: '',
            },
            newCertificatesRule: {
                certificateType: [
                    {required: true, message: '请选择证件类型', trigger: 'change'},
                ],
                familyName: [
                    {required: true, message: '请填写中文姓', trigger: 'blur'},
                    { validator: this.validateFamilyName, trigger: 'blur'}
                ],
                givenName: [
                    {required: true, message: '请填写中文名', trigger: 'blur'},
                    { validator: this.validateStaffName, trigger: 'blur'}
                ],
                englishSurnames: [
                    {required: true, message: '请填写英文姓', trigger: 'blur'},
                ],
                englishName: [
                    {required: true, message: '请填写英文名', trigger: 'blur'},
                ],
                birthDay: [
                    {required: true, message: '请填写出生年月', trigger: 'blur'},
                    {validator: validateBirthday, trigger: 'blur'}
                ]
            },
            loading: false,
            papersTypeList: [
                // 乘客证件类型：1.身份证，2.护照，3.港澳通行证，4.回乡证，5.台胞证，6.军官证，7.香港身份证，8.台湾通行证，9.户口本，10.其它,11澳門身份證
                /*{ value: 1, label: '身份证' },
                { value: 2, label: '护照' },
                { value: 3, label: '港澳通行证' },
                { value: 4, label: '回乡证' },
                { value: 5, label: '台胞证' },
                { value: 6, label: '军官证' },
                { value: 7, label: '香港身份证' },
                { value: 8, label: '台湾通行证' },
                { value: 9, label: '户口本' },
                { value: 10, label: '其它' },
                { value: 11, label: '澳門身份證' },*/
            ],
            countryList: [],
        }
    },
    components: {},
    created() {
    },
    mounted() {
        this.queryCountryTwoWord();
        this.getCertificatesList()
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        /*
        * {"certificateValue":1,"certificateText":"身份证","certificate":"441424199501296097"}],"phone":"13026618645","type":3,"typeValue":"员工","certificateName":"身份证","certificateValue":"441424199501296097"}]}
        * */
        init(val) { // 传入对象
            this.userId = val.userId
            this.userInfo = val
            if (!this.userId) {
                const msg = 'init parameter error, code=001';
                console.error(msg);
                return
            }
            this.reactForm()
            this.newCertificatesParams.userId = this.userId
            let certificatesList = []
            if (this.userInfo.certificates) {
                this.userInfo.certificates.forEach(value => {
                    certificatesList.push(value.certificateValue)
                })
            }
            let papersTypeList = JSON.parse(JSON.stringify(this.papersTypeList))
            papersTypeList.forEach(value => {
                if (certificatesList.indexOf(value.value) > -1) {
                    value.disabled = true
                } else {
                    value.disabled = false
                }
            })
            this.papersTypeList = papersTypeList
            for (let i = 0, l = this.papersTypeList.length; i < l; i++) {
                if (!this.papersTypeList[i].disabled) {
                    this.newCertificatesParams.certificateType = this.papersTypeList[i].value
                    break
                }
            }
            this.dialogNewCertificatesVisible = true
            this.$nextTick(() => {
                this.$refs.newCertificatesForm.clearValidate()
            })
        },
        reactForm() {
            this.newCertificatesParams = {
                birthDay: '',
                certificateType: 1,
                certificateValue: '',
                userId: '',
                issCountryCode: 'CN',
                cardExpireDate: '',
                countryCode: 'CN',
                familyName: '',
                givenName: '',
                englishSurnames: '',
                englishName: '',
            }
        },
        submitAddNewCertificates() {
            this.$refs['newCertificatesForm'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    let newCertificatesParams = JSON.parse(JSON.stringify(this.newCertificatesParams))
                    if (this.userInfo.certificates) {
                        let certificatesList = []
                        this.userInfo.certificates.forEach(value => {
                            certificatesList.push(value.certificateValue)
                        })
                        if (certificatesList.indexOf(newCertificatesParams.certificateType) > -1) {
                            this.loading = false
                            this.$message({
                                type: "warning",
                                message: '已存在相同证件类型'
                            })
                            return
                        }
                    }
                    consumer_admin_behalfCustomer_addcertificatetype(newCertificatesParams).then(res => {
                        this.loading = false
                        this.$message({
                            type: "success",
                            message: '添加证件成功'
                        })
                        this.dialogNewCertificatesVisible = false
                        let certificateTypeName = ''
                        this.papersTypeList.forEach(value => {
                            if (value.code === newCertificatesParams.certificateType) {
                                certificateTypeName = value.msg
                            }
                        })
                        newCertificatesParams.certificateTypeName = certificateTypeName
                        this.$emit('getNewCertificates', newCertificatesParams)
                    }, error => {
                        this.loading = false
                    })
                }
            })
        },
        validateNewUerCard(rule, value, callback) {
            if (this.newCertificatesParams.certificateType === 1) {
                const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (!regIdCard.test(value)) {
                    callback(new Error('身份证号填写有误'))
                } else {
                    let birthday = '';
                    if (value.length === 15) {
                        birthday = "19" + value.substr(6, 6);
                    } else if (value.length === 18) {
                        birthday = value.substr(6, 8);
                    }
                    birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
                    let birthdayArr = birthday.split('-');
                    let yearSubmit = parseInt(birthdayArr[0]) !== NaN && parseInt(birthdayArr[0]) >= 1900;
                    let monthSubmit = parseInt(birthdayArr[1]) !== NaN && parseInt(birthdayArr[1]) >= 1 && parseInt(birthdayArr[1]) <= 12;
                    let daySubmit = parseInt(birthdayArr[2]) !== NaN && parseInt(birthdayArr[2]) >= 1 && parseInt(birthdayArr[2]) <= 31;
                    if (!yearSubmit || !monthSubmit || !daySubmit) {
                        callback(new Error("身份证号填写有误"));
                    } else {
                        callback()
                    }
                }
            } else {
                callback()
            }
        },
        getBirthdayForCertificate() {
            let value = this.newCertificatesParams.certificateValue
            if (this.newCertificatesParams.certificateType === 1) {
                const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (regIdCard.test(value)) {
                    let birthday = '';
                    if (value.length === 15) {
                        birthday = "19" + value.substr(6, 6);
                    } else if (value.length === 18) {
                        birthday = value.substr(6, 8);
                    }
                    birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
                    let birthdayArr = birthday.split('-');
                    let yearSubmit = parseInt(birthdayArr[0]) !== NaN && parseInt(birthdayArr[0]) >= 1900;
                    let monthSubmit = parseInt(birthdayArr[1]) !== NaN && parseInt(birthdayArr[1]) >= 1 && parseInt(birthdayArr[1]) <= 12;
                    let daySubmit = parseInt(birthdayArr[2]) !== NaN && parseInt(birthdayArr[2]) >= 1 && parseInt(birthdayArr[2]) <= 31;
                    if (yearSubmit && monthSubmit && daySubmit) {
                        this.newCertificatesParams.birthDay = birthday
                    }
                }
            }
        },
        getCertificatesList() {
            consumer_admin_flight_generate_certificateType().then(res => {
                let papersTypeList = res.datas;
                let certificatesList = []
                if (this.userInfo.certificates) {
                    this.userInfo.certificates.forEach(value => {
                        certificatesList.push(value.certificateValue)
                    })
                }
                papersTypeList.forEach(value => {
                    value.value = value.code
                    value.label = value.msg
                    if (certificatesList.indexOf(value.label) > -1) {
                        value.disabled = true
                    } else {
                        value.disabled = false
                    }
                })
                this.papersTypeList = papersTypeList
            })
        },
        cardExpireDateDisabledDate(val) {
            const now = moment(val).format('YYYY-MM-DD')
            const nowTime = moment(now + ' 00:00:00').valueOf()
            const today = moment().format('YYYY-MM-DD')
            const todayTime = moment(today + ' 00:00:00').valueOf();
            return nowTime < todayTime
        },
        queryCountryTwoWord() {
            consumer_country_queryCountryTwoWord().then(res => {
                this.countryList = res.datas
            })
        },
        // 检验中文名
        validateStaffName(rule, value, callback) {
            let hasMustChinaCertificate = false;
            if ([1, 12].indexOf(this.newCertificatesParams.certificateType) > -1) {
                hasMustChinaCertificate = true;
            }

            const regExp = hasMustChinaCertificate ? /^[\u4e00-\u9fa5]+$/ : /^[\u0391-\uFFE5A-Za-z]+$/
            if (regExp.test(value)) {
                callback()
            } else {
                callback(hasMustChinaCertificate ? '只能输入中文' : '只能输入中文、英文')
            }
        },
        validateFamilyName(rule, value, callback) {
            let hasMustChinaCertificate = false;
            if ([1, 12].indexOf(this.newCertificatesParams.certificateType) > -1) {
                hasMustChinaCertificate = true;
            }

            const regExp = hasMustChinaCertificate ? /^[\u4e00-\u9fa5]+$/ : /^[\u0391-\uFFE5A-Za-z]+$/
            if (regExp.test(value)) {
                callback()
            } else {
                callback(hasMustChinaCertificate ? '只能输入中文' : '只能输入中文、英文')
            }
        }
    }
}

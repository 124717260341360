// consumer_t_od_trains_order_change_adminGetChangePayDetail 获取火车票改签单数据
//http://yapi.yinzhitravel.com/project/52/interface/api/13411
const __request = require(`./__request/__request_contentType_json`);
const consumer_t_od_trains_order_change_adminGetChangePayDetail = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-trains-order-change/adminGetChangePayDetail',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_od_trains_order_change_adminGetChangePayDetail;
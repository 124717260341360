import Vue from 'vue'
import ElementUI from 'element-ui';
import App from './App.vue';
import aRouter from './router';
import init_router from './router/init_router';
import store from '@/lib/store';
import WebSocketServerClient from './lib/common-service/websocket-server-client/1.0.1';
import './lib/utils/directives.js'

import VueClipboard from 'vue-clipboard2' // 复制到粘贴板用

import 'element-ui/lib/theme-chalk/index.css'; //默认主题
// import './common-style/theme/custom-theme-001/theme/index.css'; //自定义主题
import '@/common-style/common.less'; //全局样式入口
import moment from 'moment';
Vue.prototype.$moment = moment;

import * as math from 'mathjs';
Vue.prototype.$math = math;

import ECharts from 'vue-echarts';// 手动引入 ECharts 各模块来减小打包体积
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'

Vue.component('v-chart', ECharts)

// import OverwriteElButton from '@/component/overwrite-element-ui/el-button';

import vue_plugin_global_method from './lib/vue-plugin/global-method'

Vue.config.productionTip = false;

// Vue.use(ElementUI);
Vue.use(ElementUI, {size: 'small'});

// Vue.use(OverwriteElButton);

Vue.use(vue_plugin_global_method);

Vue.use(VueClipboard);

//加快webpack热更新（潘磊找的方法）
//第二步：调用init_router方法
init_router();

WebSocketServerClient.init();

// 在Vue应用中注册自定义指令
Vue.directive('numeric', {
    bind(el, binding, vnode) {
        let oldValue = '';
        console.log(binding, vnode);
        let input = el.tagName === 'INPUT' ? el : vnode.elm.children[0]
        el.oninput = function(e) {
            console.log(e.isTrusted)
            if (!e.isTrusted) return
            // 匹配最多5位整数和可选的小数点及其后的数字
            const value = e.target.value
            console.log(value);
            const valueArr = value.split('.');

            if (valueArr[0].length > 5) {
                // 如果不匹配，设置为上次有效的值
                input.value = oldValue;
                //input.dispatchEvent(new Event('input'))
            } else {
                oldValue = value;
            }
        }
    }
})

new Vue({
    router: aRouter,
    store,
    render: h => h(App)
}).$mount('#app');


// consumer_product_getServiceAmount
const __request = require(`./__request/__request_contentType_json`)
const consumer_product_getServiceAmount = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/product/getServiceAmount',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_product_getServiceAmount;
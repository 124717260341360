// consumer_wallet_withdrawApplyList 提款申请列表
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-beta.haolvtrip.com/doc.html#/haolv-consumer/t-us-company-account-controller/withdrawApplyListUsingPOST
const consumer_wallet_withdrawApplyList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/wallet/withdrawApplyList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_wallet_withdrawApplyList;
// 平台订单销售数据
import consumer_admin_page_order from "@/lib/data-service/haolv-default/consumer_admin_page_order";
// 平台首页各订单销售数据
import consumer_admin_page_getOrderData from "@/lib/data-service/haolv-default/consumer_admin_page_getOrderData";
// 订单数量
import consumer_admin_page_paymentCount from "@/lib/data-service/haolv-default/consumer_admin_page_paymentCount";
// 订单销售额
import consumer_admin_page_paymentSum from "@/lib/data-service/haolv-default/consumer_admin_page_paymentSum";

import moment from "moment";

export default {
  components: {},
  data() {
    return {
      loading: false,
      salesData: {},
      orderData: {},
      orderOptions: null,
      salesOptions: null,

      // 订单时间
      orderDate: [moment().format("YYYY-MM-DD 00:00:00"), moment().format("YYYY-MM-DD 23:59:59")],
      // 销量时间
      salesDate: [moment().format("YYYY-MM-DD 00:00:00"), moment().format("YYYY-MM-DD 23:59:59")],
      orderIndex: 2,
      salesIndex: 2,
      orderForm: {
        start: moment().add(`week`,-1).format("YYYY-MM-DD 00:00:00"),
        end: moment().format("YYYY-MM-DD 23:59:59"),
      },
      salesForm: {
        start: moment().add(`week`,-1).format("YYYY-MM-DD 00:00:00"),
        end: moment().format("YYYY-MM-DD 23:59:59"),
      },

      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },

      pickerOptions2: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  filters: {
    format_flight_status(val) {
      switch (val) {
        case 10:
          return "预定";
          break;
        case 11:
          return "待支付";
          break;
        case 12:
          return "已支付";
          break;
        case 21:
          return "待出票";
          break;
        case 22:
          return "出票成功";
          break;
        case 23:
          return "出票失败";
          break;
        case 24:
          return "订单作废";
          break;
        case 90:
          return "已取消";
          break;
      }
    },
    format_hotel_status(val) {
      switch (val) {
        case 0:
          return "待支付";
          break;
        case 1:
          return "支付中";
          break;
        case 2:
          return "已支付（待出房）";
          break;
        case 6:
          return "已取消";
          break;
        case 7:
          return "取消中";
          break;
        case 8:
          return "取消失败";
          break;
        case 9:
          return "预下单";
          break;
        case 10:
          return "创建订单失败";
          break;
        case 11:
          return "已出房";
          break;
        case 12:
          return "已退房(退款中)";
          break;
        case 13:
          return "退房失败";
          break;
        case 14:
          return "出房失败";
          break;
        case 15:
          return "退房中";
          break;
        case 16:
          return "已退房(退款成功)";
          break;
        case 17:
          return "已退房(退款失败)";
          break;
        case -1:
          return "已删除";
          break;
      }
    },
    format_train_status(val) {
      switch (val) {
        case -1:
          return "草稿箱";
          break;
        case 0:
          return "全部";
          break;
        case 1:
          return "确认中";
          break;
        case 2:
          return "待支付";
          break;
        case 3:
          return "待出票";
          break;
        case 4:
          return "出票成功";
          break;
        case 5:
          return "出票失败";
          break;
        case 6:
          return "取消";
          break;
      }
    },
  },
  methods: {
    init() {
      this.loading = true;
      Promise.all([consumer_admin_page_order(), consumer_admin_page_getOrderData()]).then((results) => {
        this.salesData = results[0].datas;
        this.orderData = results[1].datas;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
      });
      this.req_order_data();
      this.req_sales_data();
    },

    req_order_data() {
      const __this = this;

      consumer_admin_page_paymentCount(this.orderForm)
        .then((result) => {
          let dateList = [];
          const datas = result.datas;
          datas.forEach((item) => {
            if (!dateList.includes(item.date)) dateList.push(item.date);
          });
          let flightArr = datas.filter((item) => item.type === '机票订单');
          let hotelArr = datas.filter((item) => item.type === '酒店订单');
          let trainArr = datas.filter((item) => item.type === '火车订单');


          let flightData = [];
          let hotelData = [];
          let trainData = [];

          dateList.forEach((item) => {
            const flight = flightArr.filter((n) => n.date === item);
            const hotel = hotelArr.filter((n) => n.date === item);
            const train = trainArr.filter((n) => n.date === item);

            if (flight.length) {
              flightData.push(flight[0].count);
            } else {
              flightData.push(0);
            }

            if (hotel.length) {
              hotelData.push(hotel[0].count);
            } else {
              hotelData.push(0);
            }

            if (train.length) {
              trainData.push(train[0].count);
            } else {
              trainData.push(0);
            }

          });



          this.orderOptions = {
            color: ['#FF9100', '#FF0000', '#007AFF'],
            grid: {
                top:36,
                bottom: 59,
                right: 60,
                left: 60
            },
            tooltip: {
              trigger: "axis",
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: dateList,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                name: "机票订单",
                type: "line",
                data: flightData,
              },
              {
                name: "酒店订单",
                type: "line",
                data: hotelData,
              },
              {
                name: "火车票订单",
                type: "line",
                data: trainData,
              }
            ],
          };
        })
        .catch((err) => {});
    },

    req_sales_data() {
      consumer_admin_page_paymentSum(this.salesForm)
        .then((result) => {
          let dateList = [];
          const datas = result.datas;
          datas.forEach((item) => {
            if (!dateList.includes(item.date)) dateList.push(item.date);
          });
          let flightArr = datas.filter((item) => item.type === '机票订单');
          let hotelArr = datas.filter((item) => item.type === '酒店订单');
          let trainArr = datas.filter((item) => item.type === '火车订单');


          let flightData = [];
          let hotelData = [];
          let trainData = [];

          dateList.forEach((item) => {
            const flight = flightArr.filter((n) => n.date === item);
            const hotel = hotelArr.filter((n) => n.date === item);
            const train = trainArr.filter((n) => n.date === item);

            if (flight.length) {
              flightData.push(flight[0].sum);
            } else {
              flightData.push(0);
            }

            if (hotel.length) {
              hotelData.push(hotel[0].sum);
            } else {
              hotelData.push(0);
            }

            if (train.length) {
              trainData.push(train[0].sum);
            } else {
              trainData.push(0);
            }

          });



          this.salesOptions = {
            color: ['#FF9100', '#FF0000', '#007AFF'],
            grid: {
                top:36,
                bottom: 59,
                right: 60,
                left: 60
            },
            tooltip: {
              trigger: "axis",
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: dateList,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                name: "机票订单",
                type: "line",
                data: flightData,
              },
              {
                name: "酒店订单",
                type: "line",
                data: hotelData,
              },
              {
                name: "火车票订单",
                type: "line",
                data: trainData,
              }
            ],
          };
        })
        .catch((err) => {});
    },

    to_add_user() {
      this.$router.push({
        name: "admin-add-users",
      });
    },

    to_authorization() {
      this.$router.push({
        name: "admin-credit-users-list",
      });
    },

    to_drawings() {
      this.$router.push({
        name: "admin-business-drawing-list",
      });
    },

    to_user_audit() {
      this.$router.push({
        name: "admin-users-audit-list",
      });
    },

    change_date(key, value) {
      const form = this[key + "Form"];
      form.start = value[0] + " 00:00:00";
      form.end = value[1] + " 23:59:59";
      switch (key) {
        case "order":
          this.req_order_data();
          break;
        case "sales":
          this.req_sales_data();
          break;
      }
    },
    change_index(key, index) {
      this[key + "Index"] = index;
      let start = "";
      let end = moment().format("YYYY-MM-DD");
      switch (index) {
        case 1:
          start = moment().format("YYYY-MM-DD");
          break;
        case 2:
          start = moment()
            .startOf("week")
            .add(1, "days")
            .format("YYYY-MM-DD");
          break;
        case 3:
          start = moment().format("YYYY-MM-01");
          break;
      }
      this.change_date(key, [start, end]);
      this[key + "Date"] = [start, end];
    },
  },
  created() {
    this.init();
  },
  activated() {},
  deactivated() {},
};


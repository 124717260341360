import HelloWorld from '@/component/hello-world/index.vue'

export default {
    components: {
        HelloWorld
    },
    data() {
        return {
            fullscreenLoading: false,
        };
    },
    methods: {
        open() {
            this.$alert('这是一段内容', '标题名称', {
                confirmButtonText: '确定',
                callback: action => {
                    this.$message({
                        type: 'info',
                        message: `action: ${action}`
                    });
                }
            });
        },

        openFullScreen1() {
            this.fullscreenLoading = true;
            setTimeout(() => {
                this.fullscreenLoading = false;
            }, 2000);
        },
        openFullScreen2() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                loading.close();
            }, 2000);
        },
    },
    created() {
        // const __this = this;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}

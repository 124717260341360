import Q from 'q';
import consumer_department_getStaffsAndExCusByUserIds
    from "yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_department_getStaffsAndExCusByUserIds";//通过userIds获取人员信息
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project//component/haolv/traveler-multiple-selector/2.2.8/index.vue';
import TravelerMultipleSelectorLabel from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector-label/1.0.0/index.vue';
import TravelMultipleSelectDialog from "@/component/travel-multiple-select-dialog/index.vue";
import SelectCertificate from './component/selectCertificate/index.vue'
import SelectTrainAccount from './component/selectTrainAccount/index.vue'
import NewUserPopup from './component/newUserPopup/index.vue'
import NewCertificatesPopup from './component/newCertificatesPopup/index.vue'
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info'// 获取个人信息
import consumer_trains_account_returnServiceTrainsAccount
    from "yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_trains_account_returnServiceTrainsAccount"; //通过userId获取用户12306账户信息
import {getCurrentAgeByBirthDate} from 'yinzhilv-js-sdk/frontend/common-project/lib/util/index';

export default {
    data() {
        return {
            isShowTrainAccount: false,
            params: {
                companyId: 0,
                situation: '000',//'000':平台端；'100'：pc端
                businessType: '',//1:机票；2：火车票；3：酒店
                userIdList: [],
                selectModeForTraveler: 'multiple',
                startTime: '',
                flightTmcSnailValuations: [], // 机票限制
            },
            curUser: {},
            contactIndex: 0,
            form: {
                userList: [],//出行人
                userIdList: [],//出行人userIdList
                linkman: '',//联系人
                linkmanPhone: '',//联系人电话
                smsType: 1,//发送行程信息到出行人手机。不发送：0；发送：1
                linkmanSms: 1//发送行程信息到联系人手机。不发送：0；发送：1
            },
            contact: [],
            contactObj: {},
        }
    },
    components: {
        SelectCertificate,
        SelectTrainAccount,
        TravelerMultipleSelector,
        NewUserPopup,
        NewCertificatesPopup,
        TravelerMultipleSelectorLabel,
        TravelMultipleSelectDialog,
    },
    computed: {
        showUserLengthErr() {
            let userLength = this.form.userList.length;
            if (!this.params.flightTmcSnailValuations) {
                return false;
            }
            let hasUserLengthErr = false;
            this.params.flightTmcSnailValuations.forEach(value => {
                if (value.passengerNum !== '1,9') {
                    let passengerNum = value.passengerNum.split(',');
                    if (passengerNum[0] > userLength || passengerNum[1] < userLength) {
                        hasUserLengthErr = true;
                        // userLengthErrTips = `该产品仅限${passengerNum[0]}-${passengerNum[1]}人预订，当前出行人数不符合条件`
                    }
                }
            })
            return hasUserLengthErr;
        },
        showUserLengthErrTips() {
            let userLength = this.form.userList.length;
            if (!this.params.flightTmcSnailValuations) {
                return '';
            }
            let userLengthErrTips = '';
            this.params.flightTmcSnailValuations.forEach(value => {
                if (value.passengerNum !== '1,9') {
                    let passengerNum = value.passengerNum.split(',');
                    if (passengerNum[0] > userLength || passengerNum[1] < userLength) {
                        userLengthErrTips = `该产品仅限${passengerNum[0]}-${passengerNum[1]}人预订，当前出行人数不符合条件`
                    }
                }
            })
            return userLengthErrTips;
        },
    },
    watch: {
        //userIdList变化时，更新userList，并发布userIdList和userList给父组件
        'form.userIdList'(pNewValue) {
            /*let _this = this;
            this.$refs.aTravelMultipleSelectDialog.getSelectedListPromise({
                idList: pNewValue,
            }).then(({activeDetailList}) =>{
                _this.form.userList = activeDetailList;
                _this.$set(_this.form.userList, 'changeCardvisible', false);
                _this.$set(_this.form.userList, 'changeTrainAccountvisible', false);
                _this.form.userList.forEach(item => {
                    if (item.birthDay) {
                        const age = getCurrentAgeByBirthDate(item.birthDay, _this.params.startTime);
                        item.age = age;
                    }
                    item.certificates.forEach((item, index) => {
                        if (index === 0) {
                            _this.$set(item, 'isSelected', true);
                        } else {
                            _this.$set(item, 'isSelected', false);
                        }
                    })
                    _this.$set(item, 'trainAccountList', []);
                    _this.$set(item, 'recommendAccount', {});
                })

                if (_this.params.situation === '000' && _this.params.businessType === 2) {
                    _this.getTrainAccount(_this.form.userList[0].userId);
                }

                let travelerInfoObj = {
                    userList: _this.form.userList,
                    userIdList: _this.form.userIdList
                }
                _this.$emit('selected-user-list-change', travelerInfoObj)
            });*/


            /*this.$refs.aTravelerMultipleSelector.get_selected_entity_list_promise({
                id_list: pNewValue,
            }).then(function ({entity_list}) {
                _this.form.userList = entity_list;
                _this.$set(_this.form.userList, 'changeCardvisible', false);
                _this.$set(_this.form.userList, 'changeTrainAccountvisible', false);
                _this.form.userList.forEach(item => {
                    if (item.birthDay) {
                        const age = getCurrentAgeByBirthDate(item.birthDay, _this.params.startTime);
                        item.age = age;
                    }
                    item.certificates.forEach((item, index) => {
                        if (index === 0) {
                            _this.$set(item, 'isSelected', true);
                        } else {
                            _this.$set(item, 'isSelected', false);
                        }
                    })
                    _this.$set(item, 'trainAccountList', []);
                    _this.$set(item, 'recommendAccount', {});
                })

                if (_this.params.situation === '000' && _this.params.businessType === 2) {
                    _this.getTrainAccount(_this.form.userList[0].userId);
                }

                let travelerInfoObj = {
                    userList: _this.form.userList,
                    userIdList: _this.form.userIdList
                }
                _this.$emit('selected-user-list-change', travelerInfoObj)
            });*/
        },
        //从员工中选择联系人时，
        contact(newVal) {
            this.contactObj = this.$refs.aContactSingleSelector.get_selected_entity();
            this.form.linkman = this.contactObj ? this.contactObj.name : '';
            this.form.linkmanPhone = this.contactObj ? this.contactObj.phone : '';
        }
    },
    methods: {
        //初始化
        async init(params) {
            this.setInitDataFromParent(params);
            await this.initSelectorData();
            if (this.params.situation === '100') {
                await this.getCurUser();
                this.setDefaultVal();
                this.form.userIdList = this.params.userIdList;
            }
        },
        //接收父组件传来的值
        setInitDataFromParent(params) {
            this.params = {...this.params, ...params}
        },
        //初始化选人弹框数据
        initSelectorData() {
            this.getTravelerMultipleSelectorData();
            this.getContactSingleSelectorData();
        },
        //初始化选择出行人弹框
        getTravelerMultipleSelectorData() {
            let _this = this;
            if (this.params.situation === '000') {
                this.$refs.aTravelMultipleSelectDialog.setCompany(_this.params.companyId);
            }
            this.$refs.aTravelMultipleSelectDialog.init();
        },
        //初始化选择联系人弹框
        getContactSingleSelectorData() {
            let _this = this;
            let situation = this.params.situation === '000' ? '000' : '101';
            if (situation === '000') {
                this.$refs.aContactSingleSelector.init_parameter.get_pager_data_parameter = function () {
                    const d = {
                        companyId: _this.params.companyId,
                    };
                    return Q.resolve(d);
                };
            }
            this.$refs.aContactSingleSelector.init({
                dialog: {
                    title: `请选择联系人`,
                    width: '1100px'
                },
                select_mode: "single",
                situation: situation,
                businessType: this.params.businessType
            })
        },
        // 获取当前登陆用户信息
        async getCurUser() {
            let res = await get_user_info();
            this.curUser = res.datas;
        },
        //获取12306账号信息
        async getTrainAccount(userId) {
            let res = await consumer_trains_account_returnServiceTrainsAccount({
                userId: userId
            });
            let trainAccountList = res.datas;
            this.$set(this.form.userList[0], 'trainAccountList', trainAccountList);
            trainAccountList.forEach(item => {
                this.$set(item, 'isSelected', false);
                if (item.ifRecommend) {
                    this.$set(item, 'isSelected', true);
                    this.$set(this.form.userList[0], 'recommendAccount', item);
                }
            })

            this.isShowTrainAccount = false;
            if (this.params.situation === '000' && this.params.businessType === 2 && this.form.userList[0].recommendAccount !== {}) {
                this.isShowTrainAccount = true;
            }
        },
        setDefaultVal() {
            this.form.linkman = this.curUser.realName;
            this.form.linkmanPhone = this.curUser.phone;
        },
        //新增出行人
        addTraveler() {
            this.$refs.aNewUserPopup.init({companyId: this.params.companyId});
            this.$refs.aNewUserPopup.show();
        },
        //获取新增的出行人信息
        getNewUser(newUser) {
            let userList = this.form.userList;
            let userIdList = this.form.userIdList;
            const age = getCurrentAgeByBirthDate(newUser.birthDate, this.params.startTime);
            newUser.age = age;
            if (this.params.selectModeForTraveler === 'multiple') {
                userList.push(newUser);
                userIdList.push(newUser.userId);
            } else {
                userList = [newUser];
                userIdList = [newUser.userId];
            }

            this.$set(this.form, 'userList', userList)
            this.$set(this.form, 'userIdList', userIdList)

            let travelerInfoObj = {
                userList: this.form.userList,
                userIdList: this.form.userIdList
            }
            this.$emit('selected-user-list-change', travelerInfoObj)
        },
        //选择出行人
        chooseTraveler() {
            console.log('1222');
            this.$refs.aTravelMultipleSelectDialog.show(false);
            this.$refs.aTravelMultipleSelectDialog.setActiveList(this.form.userList);
        },
        //选择联系人
        chooseContact() {
            this.$refs.aContactSingleSelector.show();
        },
        //删除出行人
        delTraveler(userId) {
            this.form.userIdList.find((item, index) => {
                if (item === userId) {
                    this.form.userIdList.splice(index, 1);
                    this.form.userList.splice(index, 1);
                    return;
                }
            })
        },
        //添加证件
        addCertificate(user) {
            this.$refs.aNewCertificatesPopup.init(user);
            this.changeCardvisible = false;
        },
        //获取新增的证件
        async getNewCertificates(param) {
            let res = await consumer_department_getStaffsAndExCusByUserIds({userIds: [param.userId]})
            this.form.userList.find((item, index) => {
                if (item.userId === param.userId) {
                    item.certificates = res.datas.list[0].certificates;
                    item.certificates.forEach(certificate => {
                        certificate.isSelected = false;
                        if (certificate.certificateValue === param.certificateType) {
                            certificate.isSelected = true;
                            item.certificateType = param.certificateType;
                        }
                    })
                    item.certificateName = param.certificateTypeName;
                    item.certificateValue = param.certificateValue;
                    item.changeCardvisible = false;
                }
            })
        },
        //更改证件
        changeCertificate(param) {
            this.form.userList.find((item, index) => {
                if (item.userId === param.userId) {
                    item.certificates.forEach(certificate => {
                        certificate.isSelected = false;
                        if (certificate.certificateValue === param.certificate.certificateValue) {
                            certificate.isSelected = true;
                            item.certificateType = param.certificate.certificateValue;
                        }
                    })
                    item.certificateName = param.certificate.certificateText;
                    item.certificateValue = param.certificate.certificate;
                    item.unSensitiveCertificateValue = param.certificate.unSensitiveCertificateValue;
                    item.changeCardvisible = false;
                }
            })
        },
        //更改12306账号
        changeTrainAccount(param) {
            this.form.userList.find((item, index) => {
                if (item.userId === param.userId) {
                    item.trainAccountList.forEach(trainAccount => {
                        trainAccount.isSelected = false;
                        if (trainAccount.account === param.trainAccount.account) {
                            trainAccount.isSelected = true;
                            item.recommendAccount = param.trainAccount;
                        }
                    })
                    item.changeTrainAccountvisible = false;
                }
            })
        },
        // 与出行人一致
        sameToTraveler() {
            if (this.form.userList === null || this.form.userList.length === 0) {
                this.$message.warning('请选择出行人');
                return;
            }
            this.contactIndex++

            let travelUserListLength = this.form.userList.length
            if (this.contactIndex === travelUserListLength) {
                this.contactIndex = 0
            }
            let linkmanInfo = this.form.userList[this.contactIndex]
            this.form.linkman = linkmanInfo.name || linkmanInfo.staffName
            this.form.linkmanPhone = linkmanInfo.phone
        },
        //发布整个组件的信息
        getTravelerInfo() {
            let user = {};
            let userList = [];
            let resultForm = {};
            this.form.userList.forEach(item => {
                user = {
                    certificateType: item.certificateType,
                    userId: item.userId,
                    userName: item.name,
                    operator: item.operator,
                    trainAccountId: item.recommendAccount ? item.recommendAccount.id : '',
                    trainAccountName: item.recommendAccount ? item.recommendAccount.name : '',
                    tags: item.tags,
                    age: item.age,
                }
                userList.push(user);
            });
            resultForm = {
                userList: userList,//出行人
                userIdList: this.form.userIdList,//出行人userIdList
                linkman: this.form.linkman,//联系人
                linkmanPhone: this.form.linkmanPhone,//联系人电话
                smsType: this.form.smsType,//发送行程信息到出行人手机。不发送：0；发送：1
                linkmanSms: this.form.linkmanSms//发送行程信息到联系人手机。不发送：0；发送：1
            }
            return resultForm;
        },
        // 传入用户信息
        setUserInfo(val) {
            this.form.userList = val;
        },
        getUserList(val) {
            let _this = this;
            this.form.userIdList = val.activeIdList;
            this.form.userList = val.activeDetailList;
            _this.$set(_this.form.userList, 'changeCardvisible', false);
            _this.$set(_this.form.userList, 'changeTrainAccountvisible', false);
            _this.form.userList.forEach(item => {
                if (item.birthDay) {
                    const age = getCurrentAgeByBirthDate(item.birthDay, _this.params.startTime);
                    item.age = age;
                }
                item.certificates.forEach((item, index) => {
                    if (index === 0) {
                        _this.$set(item, 'isSelected', true);
                    } else {
                        _this.$set(item, 'isSelected', false);
                    }
                })
                _this.$set(item, 'trainAccountList', []);
                _this.$set(item, 'recommendAccount', {});

                _this._setUserError(item);
            })

            if (_this.params.situation === '000' && _this.params.businessType === 2) {
                _this.getTrainAccount(_this.form.userList[0].userId);
            }

            let travelerInfoObj = {
                userList: _this.form.userList,
                userIdList: _this.form.userIdList
            }
            _this.$emit('selected-user-list-change', travelerInfoObj)
        },

        _setUserError(item) {
            console.log(item);
            if (this.params.businessType !== 1) {
                item.cardErrShow = false;
                item.cardErrTips = '';
                item.ageErrShow = false;
                item.ageErrTips = '';
                item.showTips = ''
                return;
            }
            if (!this.params.flightTmcSnailValuations || this.params.flightTmcSnailValuations.length === 0) {
                item.cardErrShow = false;
                item.cardErrTips = '';
                item.ageErrShow = false;
                item.ageErrTips = '';
                item.showTips = ''
                return
            }
            let cardErrShow = false;
            let cardErrTips = '';
            let ageErrShow = false;
            let ageErrTips = '';
            let showTips = '该产品';
            let showNum = 0;
            this.params.flightTmcSnailValuations.forEach(value => {
                if (value.cardType === 'NI'){
                    // 仅支持身份证
                    if (item.certificateType !== 1) {
                        cardErrShow = true;
                        cardErrTips = '仅限身份证预订';
                        showTips += '仅限身份证预订';
                        showNum++;
                    }
                }
                if (value.cardType === 'PP') {
                    // 仅支持身份证
                    if (item.certificateType !== 2) {
                        cardErrShow = true;
                        cardErrTips = '仅限护照预订';
                        showTips += '仅限护照预订';
                        showNum++;
                    }
                }
                if (value.ageScope !== '0,100') {
                    let ageScope = value.ageScope.split(',');
                    if (ageScope[0] > item.age || ageScope[1] < item.age) {
                        ageErrShow = true;
                        if (showNum > 0) {
                            showTips += ', ';
                        }
                        ageErrTips = `仅限年龄在${ageScope[0]}岁至${ageScope[1]}岁之间`;
                        showTips += `仅限年龄在${ageScope[0]}岁至${ageScope[1]}岁之间`;
                    }
                }


            })
            item.cardErrShow = cardErrShow;
            item.cardErrTips = cardErrTips;
            item.ageErrShow = ageErrShow;
            item.ageErrTips = ageErrTips;
            item.showTips = showTips
        },
    }
}

export default {
  path: '',
  component: () => import(`@/component/admin-layout/index.vue`),
  children: [
    {
      path: '/admin/users-management-list',
      name: 'admin-users-management-list',
      component: () => import(/* webpackChunkName: "page-admin-users-management-user-management-list" */ `./1.0.2/index.vue`),
      meta: {
        entity_for_history_tag: {
          name: `客户管理列表`,
        },
        entity_list_for_breadcrumb: [
          {name: `客户管理`},
          {name: `客户管理列表`},
        ],
      },
    },
  ],
};

import _ from 'underscore';

import OrderLog from '@/component/orderLog/2.0.0/index.vue'
//---------------
import consumer_admin_flight_order_saveOrderRemark
    from '@/lib/data-service/haolv-default/consumer_admin_flight_order_saveOrderRemark'
//---------------
import moment from "moment";

import consumer_admin_flight_change_offlineChangeOrderDetails
    from '@/lib/data-service/haolv-default/consumer_admin_flight_change_offlineChangeOrderDetails'
import consumer_admin_flight_change_offlineSaveEdit
    from '@/lib/data-service/haolv-default/consumer_admin_flight_change_offlineSaveEdit'
import consumer_admin_flight_generate_cabinRank
    from "@/lib/data-service/haolv-default/consumer_admin_flight_generate_cabinRank";



export default {
    data() {
        return {
            changeInfoOld: 0,
            form: {},
            loading: false,
            startTimeOptions: {
                disabledDate: (time) => {
                    return time.getTime() <= new Date() - 8.64e7;
                }
            },

            ruleSegments: {
                changFlightNo: [{required: true, validator: this.flightNoRule, trigger: 'blur'}],
                depDateBox: [{required: true, validator: this.depDateBoxRule, trigger: 'blur'}],
                arrDateBox: [{required: true, validator: this.arrDateBoxRule, trigger: 'blur'}],
                cabin: [{required: true, validator: this.cabinCodeRule, trigger: 'blur'}],
                cabinRank: [{required: true, validator: this.cabinRankRule, trigger: 'blur'}],
                chaReason: [{required: true, validator: this.chaReasonRule, trigger: 'blur'}],
                appTime: [{required: true, validator: this.applicationDateBoxRule, trigger: 'blur'}],
                successTime: [{required: true, validator: this.successTimeBoxRule, trigger: 'blur'}],
                supplementary: [{required: true, validator: this.supplementaryRule, trigger: 'blur'}],
                changeprice: [{required: true, validator: this.changepriceRule, trigger: 'blur'}],
                changeFeePrice: [{required: true, validator: this.changeFeePriceRule, trigger: 'blur'}],
                newTicketNo: [{required: true, validator: this.newTicketNoRule, trigger: 'blur'}],
                newPnr: [{required: true, validator: this.newPnrRule, trigger: 'blur'}],
                supplierOrderNo: [{required: true, validator: this.supplierOrderNoRule, trigger: 'blur'}],
                supplierChangeFee: [{required: true, validator: this.supplierChangeFeeRule, trigger: 'blur'}],
            },
            //订单信息
            OrderData: {},
            chaPsgCheckbox: [],
            comment: '', //备注

            formRule: {
                newTicketNo: [
                    {required: true, message: '请输入新票号', trigger: 'blur'}
                ],
                newPnr: [
                    // {required: true, message: '请输入新PNR', trigger: 'blur'}
                ]
            },
            cabinRankList: [],
            changeInfo: {
                chaReason: 1,
                supplementary: 1,
                changeprice: 0,
                changeFeePrice: 0,
                supplierOrderNo: '',
                supplierChangeFee: 0,
                appTime: '',
                successTime: '',
            },
        }
    },
    components: {
        OrderLog,
    },
    created() {
    },
    mounted() {
        this.getCabinRank();
    },
    activated() {
        this.getData();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        // changeCheckbox(val, index) {
        //     this.chaPsgCheckbox[index].switch = !this.chaPsgCheckbox[index].switch;
        // },
        flightNoRule(rule, value, callback) {
            if (value == '') {
                callback(new Error('请填写改签航班号'))
            }
            callback()
        },
        depDateBoxRule(rule, value, callback) {
            if (value == '' || value == null) {
                callback(new Error('请选择改签起飞时间'))
            }
            callback()
        },
        arrDateBoxRule(rule, value, callback) {
            if (value == '' || value == null) {
                callback(new Error('请选择改签到达时间'))
            }
            callback()
        },
        cabinRankRule(rule, value, callback) {
            if (value == '') {
                callback(new Error('请填写改签舱位等级'))
            }
            callback()
        },
        cabinCodeRule(rule, value, callback) {
            if (value == '') {
                callback(new Error('请填写改签舱位'))
            }
            callback()
        },
        chaReasonRule(rule, value, callback) {
            if (value == '') {
                callback(new Error('请选择改签类型'))
            }
            callback()
        },
        applicationDateBoxRule(rule, value, callback) {
            if (value == '') {
                callback(new Error('请选择申请改签时间'))
            }
            callback()
        },
        successTimeBoxRule(rule, value, callback) {
            if (this.successTimeBox == '') {
                callback(new Error('请选择改签完成时间'))
            }
            callback()
        },
        supplementaryRule(rule, value, callback) {
            if (!value) {
                callback(new Error('请选择改签是否需补款'))
            }
            callback()
        },
        changepriceRule(rule, value, callback) {
            if (this.changeInfo.supplementary == 2) {
                return callback();
            }
            if (value == '' || value == null || value == undefined) {
                callback(new Error('请填写改签差价'))
            }
            callback()
        },
        changeFeePriceRule(rule, value, callback) {
            if (this.changeInfo.supplementary == 2) {
                return callback();
            }
            if (value == '' || value == null || value == undefined) {
                callback(new Error('请填写改签手续费'))
            }
            if (value < 0) {
                callback(new Error('改签手续费不能小于0'))
            }
            callback()
        },
        newTicketNoRule(rule, value, callback) {
            if (!value) {
                callback(new Error('请填写新票号'))
            }
            callback()
        },
        newPnrRule(rule, value, callback) {
            if (!value) {
                callback(new Error('请填写新PNR'))
            }
            callback()
        },
        supplierOrderNoRule(rule, value, callback) {
            if (!value) {
                callback(new Error('请填写供应商改签订单号'))
            }
            callback()
        },
        supplierChangeFeeRule(rule, value, callback) {
            if (!value) {
                callback(new Error('请填写供应商改签费'))
            }
            callback()
        },
        //改签起飞日期时间
        changeDepDateBox(item, index, val) {
            let time = moment(val).format('HH:mm');
            let date = moment(val).format('yyyy-MM-DD');
            item.depDate = date;
            item.depTime = time;
        },
        //改签到达时间
        changeArrDateBox(item, index, val) {
            let time = moment(val).format('HH:mm');
            let date = moment(val).format('yyyy-MM-DD');
            item.arrDate = date;
            item.arrTime = time;
        },
        //申请改签时间
        // changeApplicationDateBox(val) {
        //     let DateTime = moment(val).format('yyyy-MM-DD HH:mm:ss');
        //     this.refundsegmentsList.applicationDate = DateTime;
        // },
        //改签完成时间
        // changeSuccessTimeBox(val) {
        //     let DateTime = moment(val).format('yyyy-MM-DD HH:mm:ss');
        //     this.refundsegmentsList.successTime = DateTime;
        // },
        //改签是否需要补款选项操作
        changeSelect(item, index) {
            if (item.supplementary == 2) {
                item.changeprice = '0';
                item.changeFeePrice = '0';
                item.supplierChangeFee = '0';
            } else {
                item.changeprice = '';
                item.changeFeePrice = '';
                item.supplierChangeFee = '';
            }
            this.$refs.ruleForm[index].validateField('supplementary');
        },
        getCabinRank() {
            consumer_admin_flight_generate_cabinRank().then(res => {
                this.cabinRankList = res.datas
            })
        },
        supplementaryChange() {
            this.changeInfo.changeprice = '';
            this.changeInfo.changeFeePrice = '';
        },
        //请求数据
        getData() {
            let data = {
                chaOrderNo: this.$route.query.chaOrderNo,
            };
            consumer_admin_flight_change_offlineChangeOrderDetails(data).then(res => {
                let form = res.datas;
                form.refundsegmentsList.forEach(value => {
                    value.depDateBox = `${value.depDate} ${value.depTime}`;
                    value.arrDateBox = `${value.arrDate} ${value.arrTime}`;
                });
                this.changeInfo = form.signingFee;
                this.changeInfoOld = Number(this.changeInfo.changeprice) + Number(this.changeInfo.changeFeePrice);

                this.totalAmount = form.signingFee.changeprice + form.signingFee.changeFeePrice;

                form.refundsegmentsList = form.refundsegmentsList.sort((a, b) => a.segmentType - b.segmentType);
                form.segmentsList = form.segmentsList.sort((a, b) => a.segmentType - b.segmentType);
                this.form = form;


                this.OrderData = {
                    //订单号
                    orderNo: res.datas.orderNo,
                    //供应商订单号
                    supplierOrderNo: res.datas.supplierOrderNo,
                    //订单来源
                    orderSource: res.datas.orderSource,
                    // 订单金额
                    payAmount: res.datas.payAmount,
                    // 供应商采购价
                    supTotalPay: res.datas.supTotalPay,
                    // 订单状态
                    orderStatusText: res.datas.orderStatusText,
                    // 支付方式
                    payTypeText: res.datas.payTypeText,
                    // 出行类型
                    businessTypeText: res.datas.businessTypeText,
                    // 航程类型
                    flightTypeText: res.datas.flightTypeText,
                    // 客户名称
                    companyName: res.datas.companyName,
                    // 下单客服
                    platformUserName: res.datas.platformUserName,
                    // 下单时间
                    orderTime: res.datas.orderTime,
                    // 联系人
                    contactName: res.datas.contactName,
                    // 联系电话
                    contactMobile: res.datas.contactMobile,
                    // 出差单号
                    evectionNo: res.datas.evectionNo,
                };
            });

        },
        checkPsgForm() {
            return new Promise(resolve => {
                this.$refs.psgForm.validate((valid) => {
                    resolve(valid)
                })
            })
        },
        checkSegmentForm() {
            return new Promise(resolve => {
                this.$refs.segmentForm.validate((valid) => {
                    resolve(valid)
                })
            })
        },
        checkChangeForm() {
            return new Promise(resolve => {
                this.$refs.ruleForm.validate((valid) => {
                    resolve(valid)
                })
            })
        },
        // 改签起飞时间
        disabledDateDepDateBox(item, time) {
            if (item.arrDateBox) {
                let oldTime = `${moment(item.arrDateBox).format('yyyy-MM-DD')} 00:00:00`;
                return time.getTime() > new Date(oldTime).getTime();
            }
        },
        // 改签到达时间
        disabledDateArrDateBox(item, time) {
            if (item.depDateBox) {
                let oldTime = `${moment(item.depDateBox).format('yyyy-MM-DD')} 00:00:00`;
                return time.getTime() < new Date(oldTime).getTime();
            }
        },
        // 旧值与新值比较
        differenceChange() {
            if (this.changeInfo.changeFeePrice == null || this.changeInfo.changeFeePrice == '') {
                return;
            }
            if (this.changeInfo.changeprice == null || this.changeInfo.changeprice == '') {
                return;
            }
            if (this.OrderData.orderStatusText != '改签成功') {
                return;
            }
            let changeInfoNew = Number(this.changeInfo.changeprice) + Number(this.changeInfo.changeFeePrice);

            if (changeInfoNew > this.changeInfoOld) {

                // 补款
                this.$confirm(`本次编辑产生${changeInfoNew - this.changeInfoOld}元差价需补收，是否继续？`, '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    this.clickKeep();
                }).catch(() => {
                });
            }
            if (changeInfoNew < this.changeInfoOld) {
                // 退款
                this.$confirm(`本次编辑产生${this.changeInfoOld - changeInfoNew}元差价需退款，是否继续？`, '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    this.clickKeep(true);
                }).catch(() => {
                });
            }
        },
        clickGoBack(){
            this.$router.go(-1);
        },
        //保存按钮
        async clickKeep(type) {
            let checkPsgFormRes = await this.checkPsgForm();
            if (!checkPsgFormRes) {
                return
            }
            let checkSegmentFormRes = await this.checkSegmentForm();
            if (!checkSegmentFormRes) {
                return
            }
            let checkChangeFormRes = await this.checkChangeForm();
            if (!checkChangeFormRes) {
                return
            }
            let form = {
                comment: this.comment,
                chaOrderNo: this.$route.query.chaOrderNo,
                passengerNoList: this.form.passengerNoList,
                refundsegmentsList: this.form.refundsegmentsList,
                signingFee: JSON.parse(JSON.stringify(this.changeInfo)),
            };

            form.refundsegmentsList.forEach((item, index) => {
                let box = this.cabinRankList.filter((i) => i.msg == item.cabinRank ? i.code : '');
                if (box.length == 1) {
                    item.cabinRank = box[0].code;
                }
            });

            // 计算差价

            let changeInfoNew = Number(this.changeInfo.changeprice) + Number(this.changeInfo.changeFeePrice);
            let difference =  changeInfoNew - this.changeInfoOld;
            let differen =this.changeInfoOld - changeInfoNew

            // 弹出提示框，询问用户是否继续
            let confirmMessage = '';
            if (difference > 0) {
                confirmMessage = "本次编辑产生" + difference + "元差价需补收，是否继续？";
            } else if (differen > 0) {
                confirmMessage = "本次编辑产生" + difference + "元差价需补退，是否继续？";
            }

            if (difference != 0) {
                //let userChoice = confirm(confirmMessage);
                if (difference != 0 ) {
                    if (difference > 0) {
                        // 补收差价
                        // 进入收银台进行补收操作，可以参考改签补款的逻辑
                        console.log("进入收银台进行补收操作");
                    } else {
                        // 补退差价
                        // 直接退款操作
                        console.log("进行退款操作");
                    }
                    this.loading = true;
                    consumer_admin_flight_change_offlineSaveEdit(form).then(res => {
                        if (res.code == '000000') {
                            this.loading = false;
                            this.$message({
                                message: '编辑成功',
                                type: 'success'
                            });
                            setTimeout(() => {
                                this.$router.push({
                                    name: 'admin-flight-change-offline-confirm',
                                    query: {
                                        chaOrderNo: this.$route.query.chaOrderNo,
                                    },
                                })
                            }, 1000)
                        } else {
                            this.$message.error('编辑失败');
                        }

                    });
                } else {
                    console.log("用户选择取消编辑");
                }
            } else {
                this.loading = true;
                consumer_admin_flight_change_offlineSaveEdit(form).then(res => {
                    if (res.code == '000000') {
                        this.loading = false;
                        this.$message({
                            message: '编辑成功',
                            type: 'success'
                        });
                        setTimeout(() => {
                            this.$router.push({
                                name: 'admin-flight-change-offline-confirm',
                                query: {
                                    chaOrderNo: this.$route.query.chaOrderNo,
                                },
                            })
                        }, 1000)
                    } else {
                        this.$message.error('编辑失败');
                    }

                });

            };

            let clickGoBack = () => {
                this.$router.go(-1);
            }
        },

    }
}

import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.2.1/index.vue';
import DynamicForm from '@/component/dynamicForm/import_latest_version_module'
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.8/index.vue';
import consumer_admin_flight_generate_evectionType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_evectionType'
import consumer_admin_flight_generate_psgType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_psgType'
import consumer_admin_flight_generate_payType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_payType'
import consumer_admin_extra_save from '@/lib/data-service/haolv-default/consumer_admin_extra_save'
import consumer_admin_trains_order_passengerCardType from '@/lib/data-service/haolv-default/consumer_admin_trains_order_passengerCardType'
import consumer_admin_flight_generate_getCompanyList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getCompanyList'
import consumer_admin_flight_generate_cabinRank from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_cabinRank'
import consumer_flight_search_airlines from '@/lib/data-service/haolv-default/consumer_flight_search_airlines'
import consumer_flight_search_internationalCitys from '@/lib/data-service/haolv-default/consumer_flight_search_internationalCitys'
import consumer_admin_extra_details from "@/lib/data-service/haolv-default/consumer_admin_extra_details";   // 订单详情
import consumer_admin_extra_ticketNumber from "@/lib/data-service/haolv-default/consumer_admin_extra_ticketNumber";   // 订单详情
import consumer_flight_search_InternaTionalAirlines from '@/lib/data-service/haolv-default/consumer_flight_search_InternaTionalAirlines'
import consumer_department_getStaffsAndExCusByUserIds from '@/lib/data-service/haolv-default/consumer_department_getStaffsAndExCusByUserIds'
import defaultForm from './form';
import isMobile from 'yinzhilv-js-sdk/src/common-service/other/is_mobile';
import Q from 'q';
import tipsObj from './tips';

import DictionarySelect from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';
import NewUserPopup
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-list-for-reserve-platform/1.0.0/component/newUserPopup/index.vue";
import TravelMultipleSelectDialog from "@/component/travel-multiple-select-dialog-for-create-order/index.vue";
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";

export default {
    data() {
        return {
            contactNameBlurLoading: false,
            getCompanyListLoading: false,
            // 当前所属企业的companyId
            companyIdCurrent: null,
            trainsReservationBox: [
                {msg: '12306登录预订',value: 1},
                {msg: '免登录预订',value: 2}
            ],
            loading: false,
            travelMultipleSelectContacts: [],   // 已选择联系人
            travelMultipleSelectTravelers: [],   // 已选择出行人
            type: 'create', // 'create' 新建 'edit' 编辑
            orderNo: '',
            form: JSON.parse(JSON.stringify(defaultForm)),
            rules: {
                'companyInfo.companyId': [
                    { required: true, message: '请选择所属企业', trigger: 'change' },
                ],
                'companyInfo.contactName': [
                    { required: true, message: '请填写联系人', trigger: 'blur' },
                ],
                'companyInfo.contactMobile': [
                    { required: true, message: '请填写联系电话', trigger: 'blur' },
                    {validator: this.checkMobile, trigger: 'blur'}
                ],
                'companyInfo.evectionType': [
                    { required: true, message: '请选择出行类型', trigger: 'change' },
                ],
                'companyInfo.payType': [
                    { required: true, message: '请选择支付方式', trigger: 'change' },
                ],
            },
            orderStatus: '',
            // 票务类型List
            ticketingList: [
                {
                    msg: '选座',
                    code: '1',
                },
                {
                    msg: '贵宾服务',
                    code: '2',
                },
                {
                    msg: '行李额',
                    code: '3',
                }
            ],
            // 证件类型List
            certificateTypeList: [],
            // 支付方式List
            payTypeList: [],
            // 出现类型List
            evectionTypeList: [],
            // 所属企业List
            companyList: [],
            // 乘客类型List
            psgTypeList: [],
            addNewPassengerLoading: false,
            submitLoading: false,
            selectedIdList: [],
            // 航司
            airlineCodeList: [],
            // 国内机场
            allCityList: [],
            // 出发机场
            startCityList: [],
            // 到达机场
            endCityList: [],
            // 航站楼List
            depTerminalList: [{code: 'T1', msg: 'T1'},{code: 'T2', msg: 'T2'},{code: 'T3', msg: 'T3'},{code: 'T4', msg: 'T4'}],
            // 舱位等级List
            cabinRankList: [],
            // 选择人组件当前在那种形态
            switchTraveler: '',


            testBox: null,
            psgNameTest: '',
            arriveTime: '',
            textarea2: '',

            activeUserList: [],
        }
    },
    components: {
        TravelMultipleSelectDialog,
        NewUserPopup, TrainCitySelector, DynamicForm, TravelerMultipleSelector, DictionarySelect},
    created() {
    },
    mounted() {
    },
    async activated() {
        await this.init();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        orderStatusToDisabled() {
            if (this.type == 'edit') {
                return true;
            }
            return false;
        },
    },
    filters: {},
    methods: {
        inputMatch(data,text) {
            eval(`${data} = ${data}.match(${text}) == null ? '' : ${data}.match(${text})[0];`);
        },
        extractingClick () {
            if (this.form.order.flightNumber) {
                let dataBox = {
                    flightNo: '',
                    ticketNumber: this.form.order.flightNumber,
                    pnr: '',
                };
                this.loading = true;
                consumer_admin_extra_ticketNumber(dataBox).then(res => {
                    if (res.datas) {
                        this.$message({
                            message: '提取成功',
                            type: 'success'
                        });
                        this.form.companyInfo.companyId = res.datas.companyBill.companyId;
                        // 更新初始化用户选择组件
                        this.$refs.aTravelMultipleSelectDialog.setCompany(this.form.companyInfo.companyId);
                        const item = {
                            id: 0,
                            orderId: 0,
                            companyId: this.form.companyInfo.companyId,
                            userId: res.datas.companyBill.userId,
                            userName: res.datas.companyBill.userName,
                            englishName: res.datas.companyBill.englishName,
                            certId: 0,
                            companyType: res.datas.companyBill.companyType,
                            ticketNumber: res.datas.companyBill.ticketNumber,
                            seatNumber: "",
                            status: 0,
                            salesPrice: null,
                            supPrice: null,
                            serviceFee: null,
                            fine: 0,
                            fineTime: "",
                            certNum: res.datas.companyBill.certNum,
                            certType: res.datas.companyBill.certType == 2 ? 5 : res.datas.companyBill.certType,

                            profit: 0,
                            certificates: [],
                        };
                        this.form.companyBills = [item];
                        this.form.flightInfos = [];
                        res.datas.segmentList.forEach((item,index) => {
                            if (index < 9) {
                                let dataBox = res.datas.segmentList[index];
                                this.form.flightInfos.push({
                                    id: 0,
                                    orderId: 0,
                                    airlineName: dataBox.airlineName,
                                    airlineNameNo: dataBox.airlineCode,
                                    flightNumber: dataBox.flightNo,
                                    depAirport: dataBox.depAirportName,
                                    depAirportNo: dataBox.depAirportCode,
                                    depCity: dataBox.depCityName,
                                    depCityNo: dataBox.depCityCode,
                                    depTerminal: dataBox.depTerminal,
                                    arrAirport: dataBox.arrAirportName,
                                    arrAirportNo: dataBox.arrAirportCode,
                                    arrCity: dataBox.arrCityName,
                                    arrCityNo: dataBox.arrCityCode,
                                    arrTerminal: dataBox.arrTerminal,
                                    airlineGrade: dataBox.cabinRank,
                                    depTime: `${dataBox.depDate} ${dataBox.depTime}`,
                                    arrTime: `${dataBox.arrDate} ${dataBox.arrTime}`,

                                    segmentType: index + 1,
                                    voyage: `第${index + 1}程`,
                                    depAirportCityData: `${dataBox.depAirportCode}-${dataBox.depAirportName}-${dataBox.depCityCode}-${dataBox.depCityName}-${index}`,
                                    arrAirportCityData: `${dataBox.arrAirportCode}-${dataBox.arrAirportName}-${dataBox.arrCityCode}-${dataBox.arrCityName}-${index}`,
                                });
                            }
                        });
                    } else {
                        this.$alert('查询不到该票号', '温馨提示', {
                            confirmButtonText: '确定',
                            callback: action => {}
                        });
                    }
                }).catch((rej) => {
                    if (rej.msg) {
                        this.$message.error(`${rej.msg}`);
                    }
                }).finally(() => {
                    this.loading = false;
                });
            } else {
                this.$message.error(`请输入票号信息`);
            }
        },
        // 计算利润
        priceInputChange(index) {
            let salesPrice = Number(this.form.companyBills[index].salesPrice);   // 销售价
            let supPrice = Number(this.form.companyBills[index].supPrice);       // 采购价
            let serviceFee = Number(this.form.companyBills[index].serviceFee);   // 服务费
            this.form.companyBills[index].profit = Number(`${(salesPrice - supPrice + serviceFee).toFixed(2)}`);
            this.$forceUpdate();
        },
        // 限制只能输入数字或小数点
        validateInput(item, index, value) {
            value = value.replace(/[^\d{1,}.\d{1,}|\d{1,}]/g, "");
            value = value.replace(/^(-)*(\d+).(\d\d).*$/, "$1$2.$3");
            this.form.companyBills[index][item] = value;
        },
        addSuccess(data) {
            //新增成功后触发
            Dictionary.init(this, {
                typeGroupKey: ['tmc'],
                typeKey: [],
                parentId: 0
            });
        },
        // 到达机场--失焦校验
        blurArrAirportCode(journeyIndex){
            this.$refs.form.validateField([`flightInfos.${journeyIndex}.arrAirportCode`]);
        },
        // 出发机场删除功能
        clearDepAirport(index) {
            eval(`this.form.flightInfos[${index}].depAirport = "";`);
            eval(`this.form.flightInfos[${index}].depAirportNo = "";`);
            eval(`this.form.flightInfos[${index}].depCity = "";`);
            eval(`this.form.flightInfos[${index}].depCityNo = "";`);
        },
        // 出发机场选择功能
        changeDepAirport(item) {
            if (item) {
                let dataBox = item.split('-');
                eval(`this.form.flightInfos[${dataBox[4]}].depAirport = "${dataBox[1]}";`);
                eval(`this.form.flightInfos[${dataBox[4]}].depAirportNo = "${dataBox[0]}";`);
                eval(`this.form.flightInfos[${dataBox[4]}].depCity = "${dataBox[3]}";`);
                eval(`this.form.flightInfos[${dataBox[4]}].depCityNo = "${dataBox[2]}";`);
            } else {

            }
        },
        // 到达机场删除功能
        clearArrAirport(index) {
            eval(`this.form.flightInfos[${index}].arrAirport = "";`);
            eval(`this.form.flightInfos[${index}].arrAirportNo = "";`);
            eval(`this.form.flightInfos[${index}].arrCity = "";`);
            eval(`this.form.flightInfos[${index}].arrCityNo = "";`);
        },
        // 到达机场选择功能
        changeArrAirport(item) {
            if (item) {
                let dataBox = item.split('-');
                eval(`this.form.flightInfos[${dataBox[4]}].arrAirport = "${dataBox[1]}";`);
                eval(`this.form.flightInfos[${dataBox[4]}].arrAirportNo = "${dataBox[0]}";`);
                eval(`this.form.flightInfos[${dataBox[4]}].arrCity = "${dataBox[3]}";`);
                eval(`this.form.flightInfos[${dataBox[4]}].arrCityNo = "${dataBox[2]}";`);
            } else {

            }
        },
        // 出发机场--失焦校验
        blurDepAirportCode(journeyIndex){
            this.$refs.form.validateField([`flightInfos.${journeyIndex}.depAirportCityData`]);
        },
        // 添加行程
        addNewTripRequestItem(item,index){
            if (this.form.flightInfos.length >= 9) {
                this.$message({
                    type: "warning",
                    message: '最多只能增加9个行程'
                });
                return
            }
            const newItem = {
                id: 0,
                orderId: 0,
                airlineName: "",
                airlineNameNo: "",
                flightNumber: "",
                depAirport: "",
                depAirportNo: "",
                depCity: "",
                depCityNo: "",
                depTerminal: "",
                arrAirport: "",
                arrAirportNo: "",
                arrCity: "",
                arrCityNo: "",
                arrTerminal: "",
                airlineGrade: "",
                depTime: "",
                arrTime: "",

                voyage: '第1程',
                depAirportCityData: '',
                arrAirportCityData: '',
            };
            this.form.flightInfos.splice(index + 1, 0, newItem);
            this.form.flightInfos.forEach((item,index) => {
                item.segmentType = index + 1;
                item.voyage = `第${index + 1}程`;
            });
        },
        // 删除行程
        deleteTripRequestItem(item,index){
            this.form.flightInfos.splice(index, 1);
            this.form.flightInfos.forEach((item,index) => {
                item.segmentType = index + 1;
                item.voyage = `第${index + 1}程`;
            });
        },
        // 航司名称--联动
        changeAirlineCode(val,index) {
            let name = null;
            let code = val;
            this.airlineCodeList.forEach(value => {
                if (value.airCompanyName === val) {
                    this.form.flightInfos[index].airlineNameNo = val.airlineCode;
                    name = value.airCompanyName;
                }
            });
        },
        // 航司名称--失焦校验
        blurAirlineCode(journeyIndex){
            this.$refs.form.validateField([`flightInfos.${journeyIndex}.airlineName`]);
        },
        // 键盘回车按键
        toNextInput(name,index) {
            setTimeout(() => {
                if (this.$refs[name][index].focus) {
                    this.$refs[name][index].focus();
                }
            }, 300)
        },
        getPsgType() {
            consumer_admin_flight_generate_psgType().then(res => {
                this.psgTypeList = res.datas
            })
        },
        getPayTypeList() {
            consumer_admin_flight_generate_payType().then(res => {
                this.payTypeList = res.datas
            })
        },
        getEvectionTypeList() {
            consumer_admin_flight_generate_evectionType().then(res => {
                this.evectionTypeList = res.datas
            })
        },
        async contactNameBlur() {
            await this.getCompanyList();
            if (this.companyList.length == 0) {
                this.contactNameBlurLoading = true;
                const params = {
                    companyName: '',
                    currentPage: 1,
                    keyword: '',
                    pageSize: 999,
                    userName: '',
                };
                let res = await consumer_admin_flight_generate_getCompanyList(params);
                this.companyList = res.datas.list;
                this.contactNameBlurLoading = false;
            }
        },
        async getCompanyList() {
            this.getCompanyListLoading = true;
            const params = {
                companyName: '',
                currentPage: 1,
                keyword: '',
                pageSize: 999,
                userName: this.form.companyInfo.contactName,
            };
            let res = await consumer_admin_flight_generate_getCompanyList(params);
            this.companyList = res.datas.list
            this.getCompanyListLoading = false;
        },
        getAirlines() {
            const params = {
                currentPage: 1,
                pageSize: 999,
            };
            consumer_flight_search_InternaTionalAirlines(params).then(res => {
                this.airlineCodeList = res.tbdAirlineTwoWords;
            })
        },
        getCity() {
            consumer_flight_search_internationalCitys().then(res => {
                let cityList = [];
                res.citys.forEach(value => {
                    if (value.airports) {
                        cityList.push(value)
                    }
                });
                this.allCityList = cityList;
                this.startCityList = JSON.parse(JSON.stringify(this.allCityList));
                this.endCityList = JSON.parse(JSON.stringify(this.allCityList));
            })
        },
        getCabinRank() {
            consumer_admin_flight_generate_cabinRank().then(res => {
                this.cabinRankList = res.datas
            })
        },
        async init() {
            this.loading = true;
            this.form = JSON.parse(JSON.stringify(defaultForm));
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            });
            this.getAirlines();
            this.getEvectionTypeList();
            this.getCertificateType();
            this.getPayTypeList();
            this.getPsgType();
            this.getCabinRank();
            this.getCity();
            await this.getCompanyList();
            this.loading = false;
            const query = this.$route.query;
            this.type = query && query.type ? query.type : 'create';  // create edit
            if (this.type === 'edit' && query.id) {
                let dataBox = {
                    id: query.id,
                    orderNo: '',
                };
                let [err, res] = await awaitWrap(consumer_admin_extra_details(dataBox));
                if (err) {
                    return;
                }
                this.form.flightInfos = res.datas.flightInfos;
                this.form.companyBills = res.datas.companyBills;
                this.form.companyInfo = res.datas.companyInfo;
                this.form.order = res.datas.order;

                this.form.companyBills.forEach((item,index) => {
                    item.userName = item.userName.match(/[\u4e00-\u9fa5]+/g)[0];
                });
                this.form.flightInfos.forEach((item,index) => {
                    item.airlineGrade = Number(item.airlineGrade);
                    item.depAirportCityData = `${item.depAirportNo}-${item.depAirport}-${item.depCityNo}-${item.depCity}-${index}`;
                    item.arrAirportCityData = `${item.arrAirportNo}-${item.arrAirport}-${item.arrCityNo}-${item.arrCity}-${index}`;
                });

                // 获取乘机人的证件信息
                let passengerIdList = [];
                this.form.companyBills.forEach(value=>{
                    passengerIdList.push(value.userId);
                })
                let [pErr, pRes] = await awaitWrap(consumer_department_getStaffsAndExCusByUserIds({
                    companyId: this.form.companyInfo.companyId,
                    userIds: passengerIdList,
                    isSelectCustomer: true,
                    pageSize: 30,
                    currentPage: 1,
                }));
                let activeUserList = [];
                this.form.companyBills.forEach(value=>{
                    const newUserItem = {
                        birthDay: '',
                        certificateName: value.certTypeText,
                        certificateType: value.certType,
                        certificateValue: value.certNum,
                        certificates: value.certificates,
                        countryCode: '',
                        countryName: null,
                        englishName: '',
                        isLoginUser: null,
                        name: value.userName,
                        phone: '',
                        shareWith: null,
                        tags: [],
                        type: 3,
                        typeValue: "员工",
                        unSensitiveCertificateValue: value.certNum,
                        unSensitivePhone: '',
                        userId: value.userId,
                    };
                    activeUserList.push(newUserItem);
                })
                this.activeUserList = activeUserList;
            } else {

            }
        },
        getCertificateType() {
            consumer_admin_trains_order_passengerCardType().then(res => {
                let certificateTypeList = res.datas;
                this.certificateTypeList = [];
                certificateTypeList.forEach(value => {
                    if (value.msg == '护照' || value.msg == '身份证') {
                      value.value = parseInt(value.code);
                      this.certificateTypeList.push(value);
                    }
                });
            })
        },
        changeCompanyId(val) {
            let activeItem = null;
            this.companyList.forEach(value => {
                if (value.id === val) {
                    activeItem = value
                }
            });
            this.companyIdCurrent = val;
            this.form.companyInfo.companyName = activeItem.companyName;
            if (this.type === 'edit') {
                return;
            }
            // 更新初始化用户选择组件
            this.$refs.aTravelMultipleSelectDialog.setCompany(val);
            this.$refs.aTravelMultipleSelectDialog.init();
        },
        clearCompany() {
            // this.form.customerInfo.contactName = '';
            // this.form.customerInfo.contactMobile = '';
        },
        // 选择联系人
        addNewContacts() {
            if (!this.form.companyInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择所属企业'
                });
                return
            }
            this.switchTraveler = 'Contacts';
            this.$refs.aTravelMultipleSelectDialog.init({selectType: '001'});
            this.selectedIdList = [];
            this.$refs.aTravelMultipleSelectDialog.show();
            this.activeUserList = this.travelMultipleSelectContacts;
        },
        // 选择出行人
        addNewPassenger() {
            if (!this.form.companyInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择所属企业'
                });
                return
            }
            this.switchTraveler = 'Passenger';
            this.$refs.aTravelMultipleSelectDialog.init({selectType: '000'});
            this.selectedIdList = [];
            this.$refs.aTravelMultipleSelectDialog.show();
            this.activeUserList = this.travelMultipleSelectTravelers;
        },
        // 出行人--添加一个
        addNewPassengerRequestItem() {
            if (!this.form.companyInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择公司'
                })
                return;
            }
            this.$refs.aNewUserPopup.init({companyId: this.form.companyInfo.companyId});
            this.$refs.aNewUserPopup.show();
            /*this.addNewPassengerLoading = true;
            const newItem = {
                id: null,
                orderId: null,
                companyId: null,
                userId: null,
                userName: "",
                englishName: "",
                certId: null,
                companyType: 1,
                ticketNumber: "",
                seatNumber: "",
                status: 0,
                salesPrice: null,
                supPrice: null,
                serviceFee: null,
                fine: 0,
                fineTime: "",
                certNum: "",
                certType: 1,

                profit: null,
                certificates: [],
            };
            this.form.companyBills.splice(index + 1, 0, newItem);
            this.addNewPassengerLoading = false*/
        },
        getNewUser(newUser) {
            const newUserItem = {
                birthDay: newUser.birthDate,
                certificateName: "",
                certificateType: newUser.certificateType,
                certificateValue: newUser.certificateValue,
                certificates: [
                    {
                        "certificateValue": newUser.certificateType,
                        "certificateText": "",
                        "certificate": newUser.certificateValue,
                        "unSensitiveCertificateValue": newUser.certificateValue,
                        "countryCode": newUser.countryCode,
                        "countryName": "",
                        "issCountryCode": newUser.issCountryCode,
                        "issCountryName": "",
                        "cardExpireDate": newUser.passengerExpireDate
                    }
                ],
                countryCode: null,
                countryName: null,
                englishName: "",
                isLoginUser: null,
                name: newUser.name,
                phone: newUser.phone,
                shareWith: null,
                tags: [],
                type: 3,
                typeValue: "员工",
                unSensitiveCertificateValue: newUser.certificateValue,
                unSensitivePhone: newUser.phone,
                userId: newUser.id,
            };
            const newItem = {
                id: null,
                orderId: null,
                companyId: null,
                userId: newUser.id,
                userName: newUser.name,
                englishName: newUser.englishName,
                certId: null,
                companyType: 1,
                ticketNumber: "",
                seatNumber: "",
                status: 0,
                salesPrice: null,
                supPrice: null,
                serviceFee: null,
                fine: 0,
                fineTime: "",
                certNum: newUser.certificateValue,
                certType: newUser.certificateType,

                profit: null,
                certificates: [
                    {
                        "certificateValue": newUser.certificateType,
                        "certificateText": "",
                        "certificate": newUser.certificateValue,
                        "unSensitiveCertificateValue": newUser.certificateValue,
                        "countryCode": newUser.countryCode,
                        "countryName": "",
                        "issCountryCode": newUser.issCountryCode,
                        "issCountryName": "",
                        "cardExpireDate": newUser.passengerExpireDate
                    }
                ]
            };
            this.form.companyBills.push(newItem);
            this.activeUserList.push(newUserItem);
        },
        // 删除出行人--一个
        deletePassengerRequestItem(item, index) {
            this.form.companyBills.splice(index, 1);
            this.activeUserList.splice(index, 1);
        },
        // 保存
        onSubmit() {
            let isPass = false;
            let unValidObj = null;
            this.$refs.form.validate((valid, obj) => {
                isPass = valid;
                unValidObj = obj;
            });
            if (isPass === true) {
                // 校验新增出行人信息是否重复
                let userCert = [];
                let userName = [];
                let userBox = [];
                this.form.companyBills.forEach((value, index) => {
                    userCert.push(`${value.certType}-${value.certNum}`);
                    userName.push(value.userName);
                    userBox.push('');
                });
                userCert.forEach((Cvalue, Cindex) => {
                    let num = 0;
                    let testNum = '';
                    this.form.companyBills.forEach((value, index) => {
                        if (`${value.certType}-${value.certNum}` == Cvalue) {
                            num++;
                            if (num >= 2) {
                                testNum = `${testNum}-${index}+${value.userName}`;
                                userBox[Cindex] = testNum;
                            } else {
                                testNum = `${index}+${value.userName}`;
                            }
                        }
                    });
                });

                let setUserBox = Array.from(new Set(userBox));
                let setUserBoxText = '';
                setUserBox.forEach((item,index) => {
                    if (item != '') {
                        let textBox = '';
                        item.split('-').forEach((SItem,SIndex) => {
                            if (textBox == '') {
                                textBox = ` 第${Number(SItem.split('+')[0]) + 1}行的${SItem.split('+')[1]}`;
                            } else {
                                textBox = `${textBox} 和 第${Number(SItem.split('+')[0]) + 1}行的${SItem.split('+')[1]}`;
                            }
                        });
                        setUserBoxText += `<p style="color: red;">${textBox} 重复`;
                    }
                });
                if (setUserBoxText != '') {
                    setUserBoxText += '；</p>';
                    this.$alert(setUserBoxText, '客户信息', {
                        confirmButtonText: '确定',
                        dangerouslyUseHTMLString: true,
                    });
                } else {
                    this.addOrder();
                }
            } else {
                let tipsStr = '';
                if (!isPass) {
                    const unValidKeys = Object.keys(unValidObj);
                    unValidKeys.forEach((item, index) => {
                        let tmpList = item.split('.');
                        let len = tmpList.length;
                        if (index > 0) {
                            tipsStr += '；</p>';
                        }

                        if (len === 2) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}-${tipsObj[tmpList[1]]}`;
                        }
                        if (len === 3) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}第${Number(tmpList[1]) + 1}行-${tipsObj[tmpList[2]]}`;
                        }
                        if (len === 5) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}第${Number(tmpList[1]) + 1}行-${tipsObj[tmpList[2]]}第${Number(tmpList[3]) + 1}行程-${tipsObj[tmpList[4]]}`;
                        }
                    });
                    tipsStr += '；</p>';
                }
                this.$alert(tipsStr, '以下信息未填写', {
                    confirmButtonText: '确定',
                    dangerouslyUseHTMLString: true,
                });
            }
        },
        addOrder() {
            let data = {
                order: {...this.form.order},
                companyInfo: this.form.companyInfo,
                companyBills: this.form.companyBills,
                flightInfos: [],
                orderMoney: null,
                supPrice: null,
            };

            data.order.paymentTime = data.order.paymentTime.length == 16 ? `${data.order.paymentTime}:00` : data.order.paymentTime;
            let flightInfosBox = [];
            let hasFlight = false;
            this.form.flightInfos.forEach((item,index) => {
                let {segmentType,depAirportCityData,arrAirportCityData,...newObj} = item;
                newObj.depTime = newObj.depTime.length == 16 ? `${newObj.depTime}:00` : newObj.depTime;
                newObj.arrTime = newObj.arrTime.length == 16 ? `${newObj.arrTime}:00` : newObj.arrTime;
                flightInfosBox.push(newObj);
                if (item.airlineName !== '') {
                    // 当是其他类型时，有填一个值就当时有航班
                    hasFlight = true;
                }
            });
            if (this.form.order.bizType !== 'qita' || this.form.order.bizType === 'qita' && hasFlight) {
                data.flightInfos = flightInfosBox;
            }

            this.submitLoading = true;
            this.loading = true;
            consumer_admin_extra_save(data).then(res => {
                this.$message({
                    type: "success",
                    message: '保存成功'
                });
                if (this.type == 'edit') {
                    this.$router.push({
                        name: 'admin-extra-order-list',
                    })
                } else {
                    this.$router.push({
                        name: "admin-extra-create-order-pay",
                        query: {
                            id: res.datas.order.id,
                            orderNo: res.datas.order.orderNo,
                            behalfCompanyId: res.datas.companyInfo.companyId
                        }
                    })
                }
            }).catch((rej) => {
                this.$message.error(`${rej.msg}`);
            }).finally(() => {
                this.submitLoading = false;
                this.loading = false;
            });
        },
        back() {
            this.$router.go(-1)
        },
        greaterThanZero(rule, value, callback, tip) {
            if (Number(value) <= 0) {
                callback(new Error(`${tip}必须大于 0`));
            }
            callback();
        },
        greaterThanOrEqualToZero(rule, value, callback, tip) {
            if (Number(value) < 0) {
                callback(new Error(`${tip}不能为负数`));
            }
            callback();
        },
        changeEvectionType(val) {
            if (this.form.companyInfo.evectionType == 1) {
              this.form.companyInfo.payType = null;
              this.payTypeList = this.payTypeList.filter(item => item.msg !== '授信支付');
            }
            if (this.form.companyInfo.evectionType == 2 && this.payTypeList.indexOf({code: 1,msg: "授信支付"}) == -1) {
              this.payTypeList = [{code: 1,msg: "授信支付"},...this.payTypeList];
            }
        },
        changeCertificateType(item, index, val) {
            this.certificateTypeList.forEach(value => {
                if (value.value === val) {
                    item.cardTypeText = value.msg
                }
            });
            item.certNum = '';
            item.certificates.forEach((CItem,CIndex) => {
                if (CItem.certificateText == item.cardTypeText) {
                    this.form.companyBills[index].certNum = CItem.certificate;
                }
            });
            this.$refs.form.clearValidate([`passengerList.${index}.psgEnglishName`])
        },
        cardNoValidator(rule, value, callback, index, item) {
            let cardType = item.cardType;
            const date = `${value.substr(6, 4)}-${value.substr(10, 2)}-${value.substr(12, 2)}`;
            if (cardType === 1) {
                if (this.isChineseIDCardNumber(value) === false) {
                    callback(new Error('请输入正确的身份证号码'));
                    return;
                } else {
                    this.$set(item, 'birthday', date);
                    callback();
                }
            } else {
                callback();
            }
            // this.$set(item, 'birthday', date);
        },
        inputValidator(rule, value, callback, index, item) {
            if (value == 0) {
                callback(new Error('请输入大于0的数'));
                return;
            }
            callback();
        },
        // 身份证号码验证
        isValidDate(year, month, day) {
            const date = new Date(year, month - 1, day);
            return (
                date.getFullYear() === year
                && date.getMonth() + 1 === month
                && date.getDate() === day
                && date.getTime() < new Date().getTime()
            );
        },
        isChineseIDCardNumber(value) {
            const testRegExp = /^[1-9]([0-9]{14}|[0-9]{16}[0-9Xx])$/;
            const areaMap = [11, 12, 13, 14, 15, 21, 22, 23, 31, 32, 33, 34, 35, 36, 37, 41, 42, 43, 44, 45, 46, 50, 51, 52, 53, 54, 61, 62, 63, 64, 65, 71, 81, 82];
            const weightMap = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            const codeMap = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
            const len = value.length;
            if (len !== 18) {
                return false;
            }
            // 模式校验
            if (!testRegExp.test(value)) {
                return false;
            }
            // 地区校验
            if (areaMap.indexOf(+value.substr(0, 2)) === -1) {
                return false;
            }
            // 18 位
            if (!this.isValidDate(+value.substr(6, 4), +value.substr(10, 2), +value.substr(12, 2))) {
                return false;
            }
            // 校验码
            const sum = value.split('').slice(0, 17).reduce((s, num, index) => {
                return s += +num * weightMap[index];
            }, 0);
            return codeMap[sum % 11] === value[17].toUpperCase();
        },
        checkMobile(rule, value, callback) {
            if (!isMobile(value)) {
                callback(new Error('只能输入长度不能大于11的数字'));
                return
            }
            callback();
        },
        getUserList(args) {
            if (this.switchTraveler == 'Contacts') {
                this.travelMultipleSelectContacts = this.activeUserList;
            }
            if (this.switchTraveler == 'Passenger') {
                this.travelMultipleSelectTravelers = this.activeUserList;
            }
            let companyBills = [];
            args.activeDetailList.forEach((value, index) => {
                console.log(value);
                if (this.switchTraveler == 'Contacts') {
                    // 联系人数据
                    this.form.companyInfo.contactName = value.name;
                    this.form.companyInfo.contactMobile = value.phone;
                }
                if (this.switchTraveler == 'Passenger') {
                    // 身份证 - 护照
                    let certificates = [null,null];
                    value.certificates.forEach((item,index) => {
                        if (item.certificateValue == 1) {
                            certificates[0] = {...item};
                        }
                        if (item.certificateValue == 2) {
                            certificates[1] = {...item};
                            certificates[1].certificateValue = 5;
                        }
                    });
                    certificates = certificates.filter(item => item != null);
                    const item = {
                        id: 0,
                        orderId: 0,
                        companyId: this.form.companyInfo.companyId,
                        userId: value.userId,
                        userName: value.name,
                        englishName: value.englishName,
                        certId: 0,
                        companyType: 1,  // 成人--1
                        ticketNumber: "",
                        seatNumber: "",
                        status: 0,
                        salesPrice: null,
                        supPrice: null,
                        serviceFee: null,
                        fine: 0,
                        fineTime: "",
                        certNum: certificates[0].certificate,
                        certType: certificates[0].certificateValue,

                        profit: null,
                        certificates: certificates,
                    };
                    companyBills.push(item);
                    this.form.companyBills = companyBills && companyBills.length == 0 ? JSON.parse(JSON.stringify(defaultForm)).companyBills : companyBills;
                }
            })
        },
    }
}

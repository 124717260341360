const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 获取系统枚举 http://219.128.52.2:18765/doc.html?tdsourcetag=s_pctim_aiomsg#/tmc-biz/%E7%B3%BB%E7%BB%9F%E9%80%9A%E7%9F%A5/getEnumUsingPOST_1
const consumer_sys_inform_getEnum = (pParameter) => {
    if (!pParameter) pParameter = {};

    const p = {
        method: 'post',
        urlSuffix: '/consumer/sys/inform/getEnum',
        data: pParameter,
    };
    return Q.when()
        .then(function () {
            return __request(p);
        })
        .then(function (data) {
            return Q.resolve(data);
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};
module.exports = consumer_sys_inform_getEnum;

// consumer_admin_car_order_toPayCreditForCarOrder 授信支付用车订单 http://yapi.yinzhitravel.com/project/52/interface/api/15692
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_car_order_toPayCreditForCarOrder = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/car/order/toPayCreditForCarOrder',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_car_order_toPayCreditForCarOrder;
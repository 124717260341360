import _ from 'underscore'
import moment from 'moment'
import history_tag_api from '@/component/admin-layout/component/history-tag/api'
import OrderLog from '@/component/orderLog/2.0.0/index.vue'

import consumer_intention_order_orderDetail
    from "@/lib/data-service/haolv-default/consumer_intention_order_orderDetail"   // 获取意向单详情
import consumer_intention_order_intentionList from "@/lib/data-service/haolv-default/consumer_intention_order_intentionList"// 获取意向单日志
import consumer_intention_order_saveOrderRemark
    from "@/lib/data-service/haolv-default/consumer_intention_order_saveOrderRemark"   // 保存备注

export default {
    data() {
        return {
            detailLoading: false,
            loading: false,
            activeName: 'detail',
            orderNo: '',
            orderDetail: {},
            form: {
                orderNo: '',
                remark: ''
            },
            moment: moment
        }
    },
    components: {
        OrderLog
    },
    activated() {
        this.init();
    },
    filters: {
        filterMoney(val) {
            if (val || val === 0) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return '0.00'
            }
        }
    },
    methods: {
        //初始化
        init() {
            this.orderNo = this.$route.query.orderNo;
            this.form.orderNo = this.orderNo;
            this.getDetails()
            this.getOrderLog()
        },

        // 获取详情
        async getDetails() {
            try {
                this.detailLoading = true
                const res = await consumer_intention_order_orderDetail({
                    orderNo: this.orderNo
                })
                this.orderDetail = res.datas;
                this.orderDetail.defChannelText = Number(this.orderDetail.productId) === 1 ? '北京中盛汇达文化旅游有限公司（环球乐享）' : '珠海骏通国际旅行社有限公司';
                this.form.remark = this.orderDetail.remark;
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "获取意向单详情失败！"
                })
            } finally {
                this.detailLoading = false
            }
        },

        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    return consumer_intention_order_intentionList({
                        orderNo: this.orderNo
                    }).then(res => {
                        // 组装组件需要的list
                        const list = _.chain(res.datas)
                            .map(function (m) {
                                // 替换名字
                                const o = {
                                    operator: m.operator,
                                    date: m.operatorTime,
                                    type: m.logSource,
                                    detail: m.logContents
                                }
                                return o
                            }).value()
                        const d = {
                            entityList: list
                        }
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },

        // 订单状态
        getOrderStatusToClass(val) {
            switch (val) {
                case (1):
                    return 'yellow'
                case (2):
                    return 'green'
                default:
                    return 'grey'
            }
        },

        // 返回
        onBack() {
            history_tag_api.remove_tag_by_route({
                route: this.$route
            })
            this.$router.replace({
                name: "admin-intention-list"
            })
        },

        //保存备注
        async saveRemark() {
            if (this.form.hotelRemark === '') {
                this.$message({
                    type: "warning",
                    message: '请输入备注'
                })
                return
            }
            this.detailLoading = true;
            consumer_intention_order_saveOrderRemark(this.form).then(res => {
                this.detailLoading = false
                this.$message({
                    type: "success",
                    message: '提交成功'
                })
                this.form.hotelRemark = ''
                this.getHotelOrderDetails()
                this.getOrderLog()
            }, error => {
                this.detailLoading = false
            }).catch(() => {
                this.detailLoading = false
            })
        },
    }
}

import req_list_datas from "@/lib/data-service/haolv-default/consumer_web_company_pageList.js";// 客户列表
import req_company_updateStatus from "@/lib/data-service/haolv-default/consumer_web_company_updateStatus.js";// 修改状态
import req_company_deleteCompany from "@/lib/data-service/haolv-default/consumer_web_company_deleteCompany.js";// 删除客户
import consumer_web_company_updateClientType from '@/lib/data-service/haolv-default/consumer_web_company_updateClientType';
import SearchBox from '@/component/search-box/1.0.1/index.vue'
import { Loading } from 'element-ui';
import Pagination from '@/component/base-pagination/index.vue'
import RouterLinkButton from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1";
export default {
  data() {
    return {
      loading: false,
      tableLoading: false,
      loadingInstance: null,
      form: {
        currentPage: 1,
        pageSize: 10,
        companyName: "",
        phone: "",
        startDate: "",
        endDate: "",
        clientType: 0,
        serviceCheckStatus: '',
        // airPattern: "",
        // hotelPattern: "",
        // trainPattern: "",
        status: 2,
      },
      registerDate: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      list: [],
      allocation: {
        showType: [
          {
            label: '2',
            text: "用户列表",
          },
          {
            label: '-1',
            text: "草稿箱",
          },
        ],
        // patternType: [
        //   { label: "全部", value: "" },
        //   { label: "通用", value: "1" },
        //   { label: "定制", value: "2" },
        // ],
        carryType: [
          {
            label: 1,
            text: "进位到元",
          },
          {
            label: 2,
            text: "进位到分",
          },
        ],
        serviceType: [
          {
            label: 1,
            text: "收取",
          },
          {
            label: 2,
            text: "不收取",
          },
        ],
        chargeType: [
          {
            label: 1,
            text: "百分比",
          },
          {
            label: 2,
            text: "定额",
          },
        ],
      },
      showType: '2',
      pager: {
        pageSizes: [10, 20, 30, 40, 50],
        total: 0,
      },

      clientTypeOptions: [
        {
          label: 0,
          text: "全部",
        },
        {
          label: 1,
          text: "临时",
        },
        {
          label: 2,
          text: "正式",
        },
      ], // 客户类型 1：临时 2：正式 0：全部
    };
  },
  components: {
    SearchBox,
    Pagination
  },
  methods: {
    req_list() {
      this.loading = true;
      this.list = [];
      let params = {};
      const form = this.form;
      const showType = this.showType;

      if (showType === '2') {
        params = { ...form };
      } else if (showType === '-1') {
        const { currentPage, pageSize, companyName, phone, status } = form;
        params = { currentPage, pageSize, companyName, phone, status };
      }

      req_list_datas(params)
        .then((res) => {
          this.list = res.datas.res.list;
          this.pager.total = res.datas.res.totalCount;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    req_update_status(id, companyStatus) {
      let message = "";
      if (companyStatus === 2) {
        message = "启";
      } else if (companyStatus === 4) {
        message = "停";
      }

      this.$confirm(`是否确认${message}用此账号?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loadingInstance = Loading.service({ target: 'body', text: '请求中...' });
          req_company_updateStatus({ id, companyStatus })
            .then((res) => {
              this.$message.success(`设置${message}用成功`);
              this.req_list();
              this.loadingInstance.close();
            })
            .catch((err) => {
              this.loadingInstance.close();
            });
        })
        .catch(() => { });
    },
    req_delet(id) {
      this.$confirm(`是否确认删除此账号?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loadingInstance = Loading.service({ target: 'body', text: '请求中...' });
          req_company_deleteCompany({ id, companyStatus: 5 })
            .then((res) => {
              this.$message.success(`删除成功`);
              this.req_list();
              this.loadingInstance.close();
            })
            .catch((err) => {
              this.loadingInstance.close();
            });
        })
        .catch(() => { });
    },
    search() {
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    reset() {
      this.registerDate = null;
      const form = this.form;
      form.companyName = '';
      form.phone = '';
      form.currentPage = 1;
      form.startDate = "";
      form.endDate = "";
      form.clientType = 0;
      // form.serviceCheckStatus = '';
      this.showType = this.$route.query.showType ? this.$route.query.showType : '2';
      form.status = this.showType;
      this.req_list();
    },
    change_table() {
      const form = this.form;
      form.currentPage = 1;
      form.pageSize = 10;
      form.status = this.showType;
      this.pager.total = 0;
      this.req_list();
    },
    to_detail(row) {
      if (this.showType === '-1') {
        // 草稿
        this.$router.push({
          name: "admin-user-draft-detail",
          query: {
            id: row.id,
          },
        });
      } else {
        // 正常用户
        this.$router.push({
          name: "admin-edit-users",
          query: {
            id: row.id,
          },
        });
      }
    },
    to_edit_user(row, type) {
      this.$router.push({
        name: "admin-edit-users",
        query: {
          id: row.id,
          type
        },
      });
    },
    to_edit(row) {
      this.$router.push({
        name: "admin-edit-draft-users",
        query: {
          id: row.id,
          type: 'edit'
        },
      });
    },

    change_pageSize(pageSize) {
      this.form.pageSize = pageSize;
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    change_currentPage(currentPage) {
      this.form.currentPage = currentPage;
      this.req_list(this.form);
    },
    updateClientType(val) {
      this.$confirm(`是否将临时客户${val.companyName}转为正式客户?`, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true;
        let params = {
          clientType: 2,
          companyId: val.id,
        };
        consumer_web_company_updateClientType(params).then(res=>{
          this.loading = false;
          this.$message({
            type: 'success',
            message: '操作成功!'
          });
          this.req_list();
        }).catch(err=>{
          this.loading = false;
        })

      }).catch(() => {
      });
    },
  },
  activated() {
    this.reset();


    let test = [
      {
        name: '1',
        value: 'one',
      },
      {
        name: '2',
        value: 'two',
      },
      {
        name: '3',
        value: 'three',
      }
    ]


    console.log(test.indexOf('2'))
  },
  deactivated() {
    if (this.loadingInstance) {
      this.loadingInstance.close();
    }

  },
  watch: {
    registerDate(val) {
      const form = this.form;
      if (val) {
        form.startDate = val[0];
        form.endDate = val[1];
      } else {
        form.startDate = "";
        form.endDate = "";
      }
    },
  },
  filters: {
    format_status(val) {
      switch (val) {
        case 0:
          return "草稿箱";
        case 1:
          return "待审核";
        case 2:
          return "启用";
        case 4:
          return "停用";
      }
    },
    format_businessType(val) {
      switch (Number(val)) {
        case 1:
          return "火车票";
        case 2:
          return "酒店";
        case 3:
          return "机票";
        case 4:
          return "汽车";
      }
    },
  },
};

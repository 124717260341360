import consumer_air_ticket_creatAirChangeByPnrTxt from '@/lib/data-service/haolv-default/consumer_air_ticket_creatAirChangeByPnrTxt';
import VirtualSelect from "@/component/virtual-select/index.vue";
import consumer_flight_search_internationalCitys from "@/lib/data-service/haolv-default/consumer_flight_search_internationalCitys";
import consumer_flight_search_citys from "@/lib/data-service/haolv-default/consumer_flight_search_citys";
import consumer_air_ticket_confirmAirChange from '@/lib/data-service/haolv-default/consumer_air_ticket_confirmAirChange';
export default {
    data() {
        return {
            loading: false,
            activeName: '1',
            textarea: '',
            showTips: true,

            searchParams: {
                flightNo: '',
                time: ''
            },
            form: {
                type: 1,
            },

            submitForm: {
                "contactName": "",
                "names": [
                    ""
                ],
                "oldSegment": {
                    "arrDate": "",
                    "arrTerminal": "",
                    "arrTime": "",
                    "cabin": "",
                    "deptCode": "",
                    "deptDate": "",
                    "deptTerminal": "",
                    "deptTime": "",
                    "destCode": "",
                    "flightNo": "",
                    "index": "",
                    "stats": "",
                    deptDateTime: '',
                    arrDateTime: '',
                },
                "pnrCode": "",
                "segment": {
                    "arrDate": "",
                    "arrTerminal": "",
                    "arrTime": "",
                    "cabin": "",
                    "deptCode": "",
                    "deptDate": "",
                    "deptTerminal": "",
                    "deptTime": "",
                    "destCode": "",
                    "flightNo": "",
                    "index": "",
                    "stats": "",
                    deptDateTime: '',
                    arrDateTime: '',
                },
                "type": 0,
                "typeText": ""
            },
            rule: {

            },
            submitLoading: false,
            dialogVisible: false,

            allCityList: [],// 国内机场
            allCityListInternational: [],// 国际机场

        }
    },
    props: {},
    components: {VirtualSelect},
    created() {
    },
    mounted() {
        this.getCityList();
    },
    activated() {
      this.textarea = '';
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        disabledForType() {
            return [1].indexOf(this.submitForm.type) > -1;
        }
    },
    filters: {},
    methods: {
        handleTabClick() {

        },
        hideTips() {
            this.showTips = false;
            this.$refs.textarea.focus();
        },
        focusToHideTips() {
            this.showTips = false;
        },
        blurTextarea() {
            this.showTips = true;
        },
        clearPnr() {
            this.textarea = '';
        },
        submitPnr() {
            if (this.textarea === '') {
                this.$message.warning('请输入pnr');
                return;
            }
            this.loading = true;
            consumer_air_ticket_creatAirChangeByPnrTxt({pnrTxt: this.textarea}).then(res=>{
                this.loading = false;
                let submitForm = res.datas;
                submitForm.oldSegment.deptDateTime = `${submitForm.oldSegment.deptDate} ${submitForm.oldSegment.deptTime}`;
                submitForm.oldSegment.arrDateTime = `${submitForm.oldSegment.arrDate} ${submitForm.oldSegment.arrTime}`;
                if (submitForm.segment) {
                    submitForm.segment.deptDateTime = `${submitForm.segment.deptDate} ${submitForm.segment.deptTime}`
                    submitForm.segment.arrDateTime = `${submitForm.segment.arrDate} ${submitForm.segment.arrTime}`
                } else {
                    submitForm.segment = {
                        "arrDate": "",
                        "arrTerminal": "",
                        "arrTime": "",
                        "cabin": "",
                        "deptCode": "",
                        "deptDate": "",
                        "deptTerminal": "",
                        "deptTime": "",
                        "destCode": "",
                        "flightNo": "",
                        "index": "",
                        "stats": "",
                        deptDateTime: '',
                        arrDateTime: '',
                    }
                }
                this.submitForm = submitForm;
                this.dialogVisible = true;
                /*this.$confirm('发布成功', '温馨提示', {
                    confirmButtonText: '继续发布',
                    cancelButtonText: '返回列表',
                }).then(() => {
                    this.textarea = '';
                }).catch(() => {
                    // 返回
                    this.back();
                });*/
            }).catch(err=>{
                this.loading = false;
                this.$alert(err.data.msg, '温馨提示', {
                    confirmButtonText: '确定',
                    callback: action => {}
                });
            })
        },
        back() {
            this.$router.go(-1);
        },

        react() {},
        search() {},

        getCityList() {
            consumer_flight_search_citys().then(res => {
                let cityList = [];
                res.citys.forEach(value => {
                    if (value.airports) {
                        value.label = `${value.code} ${value.cityName}${value.name}`
                        cityList.push(value)
                    }
                });
                this.allCityList = cityList;
            })
            consumer_flight_search_internationalCitys().then(res => {
                let cityList = [];
                res.citys.forEach(value => {
                    if (value.airports) {
                        value.label = `${value.code} ${value.cityName}${value.name}`
                        cityList.push(value)
                    }
                });
                this.allCityListInternational = cityList;
            })
        },
        changeAirport(val) {},
        changeNewFlightNo() {
            if (this.submitForm.type === 3) {
                this.submitForm.segment.flightNo = this.submitForm.oldSegment.flightNo;
            }
        },
        changeOldFlightNo() {
            if (this.submitForm.type === 3) {
                this.submitForm.oldSegment.flightNo = this.submitForm.segment.flightNo;
            }
        },
        submitAgain() {
            this.$refs.submitForm.validate((valid) => {
                if (!valid) {
                    return;
                }
                this.submitLoading = true;
                let submitForm = JSON.parse(JSON.stringify(this.submitForm));
                let deptDateTime = submitForm.oldSegment.deptDateTime.split(' ');
                submitForm.oldSegment.deptDate = deptDateTime[0];
                submitForm.oldSegment.deptTime = deptDateTime[1];
                let arrDateTime = submitForm.oldSegment.arrDateTime.split(' ');
                submitForm.oldSegment.arrDate = arrDateTime[0];
                submitForm.oldSegment.arrTime = arrDateTime[1];
                if ([1].indexOf(submitForm.type) > -1) {
                    // 当航班是取消时，新航班内容不用传
                    submitForm.segment = {
                        "arrDate": "",
                        "arrTerminal": "",
                        "arrTime": "",
                        "cabin": "",
                        "deptCode": "",
                        "deptDate": "",
                        "deptTerminal": "",
                        "deptTime": "",
                        "destCode": "",
                        "flightNo": "",
                        "index": "",
                        "stats": "",
                        deptDateTime: '',
                        arrDateTime: '',
                    }
                } else {
                    let newDeptDateTime = submitForm.segment.deptDateTime.split(' ');
                    submitForm.segment.deptDate = newDeptDateTime[0];
                    submitForm.segment.deptTime = newDeptDateTime[1];
                    let newArrDateTime = submitForm.segment.arrDateTime.split(' ');
                    submitForm.segment.arrDate = newArrDateTime[0];
                    submitForm.segment.arrTime = newArrDateTime[1];
                }
                consumer_air_ticket_confirmAirChange(submitForm).then(res=>{
                    this.submitLoading = false;
                    this.dialogVisible = false;
                    this.$confirm('发布成功', '温馨提示', {
                        confirmButtonText: '继续发布',
                        cancelButtonText: '返回列表',
                    }).then(() => {
                        this.textarea = '';
                    }).catch(() => {
                        // 返回
                        this.back();
                    });
                }).catch(e=>{
                    this.dialogVisible = false;
                })
            });
        },
    }
}
import SearchBoxPromise from '@/component/search-box-promise/1.0.0/index.vue'
import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'
import consumer_admin_flight_order_getExceptionList
    from '@/lib/data-service/haolv-default/consumer_admin_flight_order_getExceptionList'
//解锁异常订单
import consumer_admin_flight_manage_nuLockOrder
    from '@/lib/data-service/haolv-default/consumer_admin_flight_manage_nuLockOrder'
//锁异常订单
import consumer_admin_flight_manage_lockOrder
    from '@/lib/data-service/haolv-default/consumer_admin_flight_manage_lockOrder'
import Pagination from '@/component/base-pagination/index.vue'

export default {
    data() {
        return {
            totalCount: 10,   //总条数
            orderList: [],
            tableLoading: false,
            orderTimeArr: [], //起飞日期--合集
            orderTime: [],   //预定日期--合集
            searchForm: {
                orderNo: '',   //订单号
                ticketNumber: '',   //票号
                psgName: '',   //乘机人
                orderTimeStart: '',   //预定日期--开始
                orderTimeEnd: '',   //预定日期--结束
                companyName: '',   //企业名称
                evectionType: '',   //出行类型：1.全部，2.因公，3.因私
                segmentType: '',   //航程类型：0全部 1单程 2往返
                deptTimeStart: '', //起飞日期--开始
                deptTimeEnd: '', //起飞日期--结束
                username: '', //下单人
                pnr: '', //pnr
                airline: '', //出票航司
                flightNo: '', //航班号

                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            },
            evectionTypeOption: [
                {
                    value: 1,
                    label: '全部'
                },
                {
                    value: 2,
                    label: '因公'
                },
                {
                    value: 3,
                    label: '因私'
                },
            ],
            segmentTypeOption: [
                {
                    value: '',
                    label: '全部'
                },
                {
                    value: 1,
                    label: '单程'
                },
                {
                    value: 2,
                    label: '往返'
                },
            ],
        }
    },
    components: {
        SearchBoxPromise,
        Pagination,
        RouterLinkButton
    },
    created() {
    },
    mounted() {
        // this.getOrderList();
    },
    activated() {
        this.getOrderList();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        clickIssueUnlock(data) {
            let orderNo = data.orderNo;
            consumer_admin_flight_manage_nuLockOrder({orderNo})
                .then(res => {
                    if (res.code == '000000') {
                        this.$message({
                            message: '解锁成功',
                            type: 'success'
                        });
                    } else {
                        this.$message.error('解锁失败');
                    }
                    this.onSearch();
                })
                .catch(ref => {
                    this.$message.error('解锁失败');
                    this.onSearch();
                })
        },
        //拆分预定日期
        orderTimeChange(data) {
            if (data == null) {
                this.searchForm.orderTimeStart = '';
                this.searchForm.orderTimeEnd = '';
            } else {
                this.searchForm.orderTimeStart = data[0];
                this.searchForm.orderTimeEnd = data[1];
            }
        },
        //拆分起飞日期
        orderTimeArrChange(data) {
            if (data == null) {
                this.searchForm.deptTimeStart = '';
                this.searchForm.deptTimeEnd = '';
            } else {
                this.searchForm.deptTimeStart = data[0];
                this.searchForm.deptTimeEnd = data[1];
            }
        },
        //搜索--Promise
        onSearchPromise() {
            this.searchForm.currentPage = 1;
            return consumer_admin_flight_order_getExceptionList(this.searchForm).then(res => {
                this.orderList = res.pageResult.pageData;
                this.totalCount = res.pageResult.pageData.length;
            })
        },
        //重置
        onResetPromise() {
            this.orderTimeArr = [];//起飞日期--合集
            this.orderTime = [];  //预定日期--合集
            this.searchForm = {
                orderNo: '',   //订单号
                ticketNumber: '',   //票号
                psgName: '',   //乘机人
                orderTimeStart: '',   //预定日期--开始
                orderTimeEnd: '',   //预定日期--结束
                companyName: '',   //企业名称
                evectionType: '',   //出差单类型：1.个人预定，2.因公出差申请，3.因公-个人出差申请
                segmentType: '',   //航程类型：0全部 1单程 2往返
                deptTimeStart: '', //起飞日期--开始
                deptTimeEnd: '', //起飞日期--结束
                username: '', //下单人
                pnr: '', //pnr
                airline: '', //出票航司
                flightNo: '', //航班号

                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            };
            return consumer_admin_flight_order_getExceptionList(this.searchForm).then(res => {
                this.orderList = res.pageResult.pageData;
                this.totalCount = res.pageResult.pageData.length;
            })
        },
        //搜索
        onSearch() {
            this.searchForm.currentPage = 1;
            this.getOrderList();
        },
        //重置
        onReset() {
            this.orderTimeArr = [];//起飞日期--合集
            this.orderTime = [];  //预定日期--合集
            this.searchForm = {
                orderNo: '',   //订单号
                ticketNumber: '',   //票号
                psgName: '',   //乘机人
                orderTimeStart: '',   //预定日期--开始
                orderTimeEnd: '',   //预定日期--结束
                companyName: '',   //企业名称
                evectionType: '',   //出差单类型：1.个人预定，2.因公出差申请，3.因公-个人出差申请
                segmentType: '',   //航程类型：0全部 1单程 2往返
                deptTimeStart: '', //起飞日期--开始
                deptTimeEnd: '', //起飞日期--结束
                username: '', //下单人
                pnr: '', //pnr
                airline: '', //出票航司
                flightNo: '', //航班号

                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            };
            this.onSearch()
        },
        //详情
        clickDetails(data) {
            let orderNo = data.orderNo;
            this.$router.push({
                name: 'admin-flight-abnormal-list-details',
                query: {
                    orderNo
                }
            })
        },
        clickIssueTicketIng(){
            this.$message({
                message: '该订单已被锁',
                type: 'warning'
            });
        },
        clickIssueTicket(data) {
            let orderNo = data.orderNo;
            consumer_admin_flight_manage_lockOrder({orderNo}).then(res => {
                this.$router.push({
                    name: 'admin-flight-abnormal-list-issue',
                    query: {
                        orderNo
                    }
                })
            });
        },
        //请求接口
        getOrderList() {
            consumer_admin_flight_order_getExceptionList(this.searchForm).then(res => {
                this.orderList = res.pageResult.pageData;
                this.totalCount = res.pageResult.pageData.length;
            })
        },
    }
}

//预订
export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/train-travel-reserve',
            name: 'admin-train-travel-reserve',
            component: () => import(/* webpackChunkName: "page-admin-replace-orders-train-travel-reserve" */ `./1.1.3/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `预订`,
                },
                entity_list_for_breadcrumb: [
                    {name: `代客下单`},
                    {name: `预订`},
                ],
            },
        },
    ],
};

import Q from 'q';
import _ from 'underscore';

import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.1.0/index.vue';

export default {
    components: {
        TrainCitySelector,
    },
    data() {
        return {
            selected_entity_aTrainCitySelector: null,
            // selected_entity_aTrainCitySelector: {
            //   // 'firstLetter': 'B',
            //   // 'isHotCity': 1,
            //   // 'jianpin': 'bj',
            //   // 'sn': 131,
            //   'stationCode': 'BJP',
            //   // 'stationLevel': 1,
            //   // 'stationName': '北京',
            //   // 'stationPinYin': 'beijing',
            // },
        };
    },
    methods: {
        __btn_get_value_click() {
            const __this = this;
            console.log(__this.selected_entity_aTrainCitySelector);
        },

        __btn_set_value_click() {
            const __this = this;
            //这样赋值是不可以的，因为监听的是对象！
            // __this.selected_entity_aTrainCitySelector.stationCode = `GZQ`;
            //这样赋值才可以！
            __this.selected_entity_aTrainCitySelector = {
                // 'firstLetter': 'G',
                // 'isHotCity': 1,
                // 'jianpin': 'gz',
                // 'sn': 704,
                'stationCode': 'GZQ',
                // 'stationLevel': 1,
                // 'stationName': '广州',
                // 'stationPinYin': 'guangzhou'
            };
        },

        __btn_reset_value_click() {
            const __this = this;
            //这样赋值是不可以的，因为监听的是对象！
            // __this.selected_entity_aTrainCitySelector.stationCode = ``;
            //这样赋值才可以！
            __this.selected_entity_aTrainCitySelector = {
                'stationCode': '',
            };
            // __this.selected_entity_aTrainCitySelector = null;
        },

        __btn_set_entity_click() {
            const __this = this;
            __this.$refs.aTrainCitySelector.set_entity({
                type: `000`,
                type_parameter: {
                    '000': {
                        name: `深圳`,
                    },
                }
            });
        },

    },
    watch: {
        selected_entity_aTrainCitySelector(pNewValue, pOldValue) {
            const __this = this;
            // console.log(`pNewValue=`);
            // console.log(pNewValue);
            // console.log(`pOldValue=`);
            // console.log(pOldValue);
        },
    },
    created() {
        const __this = this;
    },
    mounted() {
        const __this = this;
        // 在created这样赋值，不会进入到BaseEntitySelector的watch value处理方法中，
        // 在mounted这样赋值，可以进入到BaseEntitySelector的watch value处理方法中
        // __this.selected_entity_aTrainCitySelector = {
        //     // 'firstLetter': 'G',
        //     // 'isHotCity': 1,
        //     // 'jianpin': 'gz',
        //     // 'sn': 704,
        //     'stationCode': 'GZQ',
        //     // 'stationLevel': 1,
        //     // 'stationName': '广州',
        //     // 'stationPinYin': 'guangzhou'
        // };
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}

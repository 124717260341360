// consumer_admin_ship_action
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_extra_details = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/extra/details',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_extra_details;
import Q from 'q';
import _ from 'underscore';
// 客户审核详情
import consumer_credit_platformCreditDetail from "@/lib/data-service/haolv-default/consumer_credit_platformCreditDetail";
// 取授信流水列表
import consumer_credit_billList from "@/lib/data-service/haolv-default/consumer_credit_billList";
// 调整授信额度
import consumer_credit_creditAmountUpdate from "@/lib/data-service/haolv-default/consumer_credit_creditAmountUpdate.js";
// 授信停用启用
import req_creditStatusUpdate from "@/lib/data-service/haolv-default/consumer_credit_creditStatusUpdate";
import {Loading} from "element-ui";
import consumer_web_company_getPnrSetType from "@/lib/data-service/haolv-default/consumer_web_company_getPnrSetType";
import consumerweb_company_queryCompanyInvoiceType from "@/lib/data-service/haolv-default/consumerweb_company_queryCompanyInvoiceType";
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";
import consumer_admin_company_query from "@/lib/data-service/haolv-default/consumer_admin_company_query";

export default {
    data () {
        const validateRegularQuota = (rule, value, callback) => {
          if (this.QuotaBox.regularQuota) {
            callback();
          } else {
            callback(new Error("请输入新的固定额度"))
          }
        };
        const validateTemporaryQuota = (rule, value, callback) => {
          if (this.QuotaBox.temporaryQuota) {
            callback();
          } else {
            callback(new Error("请输入新的临时额度"))
          }
        };
        const validateFrozenQuota = (rule, value, callback) => {
          if (this.QuotaBox.frozenQuota) {
            if (this.detail.creditDetailResponse.creditUsableAmount >= this.QuotaBox.frozenQuota) {
              callback();
            } else {
              callback(new Error("冻结额度不能大于可用额度"))
            }
          } else {
            callback(new Error("请输入冻结额度"))
          }
        };
        const validateThawQuota = (rule, value, callback) => {
          if (this.QuotaBox.thawQuota) {
            if (this.detail.creditDetailResponse.creditFreezeAmount >= this.QuotaBox.thawQuota) {
              callback();
            } else {
              callback(new Error("解冻额度不能大于冻结额度"))
            }
          } else {
            callback(new Error("请输入解冻额度"))
          }
        };
        return {
            QuotaBox: {
              regularQuota: '',
              temporaryQuota: '',
              frozenQuota: '',
              thawQuota: '',
            },
            dialogTitle: '提示',
            dialogType: 1,
            rules: {
              regularQuota: [
                {required: true, validator: validateRegularQuota, trigger: ['blur', 'change']},
                { pattern: /^[0-9|^\\.]/, message: '数值不能为负数' }
              ],
              temporaryQuota: [
                {required: true, validator: validateTemporaryQuota, trigger: ['blur', 'change']},
                { pattern: /^[0-9|^\\.]/, message: '数值不能为负数' }
              ],
              frozenQuota: [
                {required: true, validator: validateFrozenQuota, trigger: ['blur', 'change']},
                { pattern: /^[0-9|^\\.]/, message: '数值不能为负数' }
              ],
              thawQuota: [
                {required: true, validator: validateThawQuota, trigger: ['blur', 'change']},
                { pattern: /^[0-9|^\\.]/, message: '数值不能为负数' }
              ],
            },

            dialogVisible: false,
            dialogVisibleLoading: false,
            detail: null,
            form: {
                companyId: '',
                currentPage: 1,
                pageSize: 10
            },
            list: [],
            pager: {
                pageSizes: [10, 20, 30, 40, 50],
                total: 0,
            },
            companyInfoMore: false,
            serviceInfoMore: false,

            pnrSetOptions:[],
            invoiceType: [],

            // 结算设置
            configInfoMore: false,
            matchSetDetail: {
                "companyName": "",
                "companyId": "",
                "hotelSplit": true,
                "settlingDay": '',
                "invoiceType": {
                    "flight": "行程单",
                    "itinerary": "增值稅普票",
                    "flightRef": "增值稅普票",
                    "train": "火车票票根",
                    "hotel": "增值税普票",
                    "car": "增值稅普票",
                    "ship": "自取船票票根",
                    "door": "增值税普票",
                    "visa": "增值稅普票",
                    "extra": "增值税普票",
                    "serviceFee": "增值稅普票"
                }
            },
        }
    },
    methods: {
        toggleShow() {
            this.companyInfoMore = !this.companyInfoMore;
        },
        toggleServiceShow() {
            this.serviceInfoMore = !this.serviceInfoMore;
        },
        toggleConfigShow() {
            this.configInfoMore = !this.configInfoMore;
        },
        clickDialogPrimary(dialogType){
          this.$refs['ruleForm'].validate((valid) => {
            if (valid) {
              this.dialogVisibleLoading = true;
              let dataBox = {};
              if (dialogType == 1) {
                // 调整授信额度
                dataBox = {
                  companyId: Number(this.$route.query.id),
                  creditAmount: Number(this.QuotaBox.regularQuota),
                  creditAmountType: dialogType,
                };
              } else if (dialogType == 2) {
                dataBox = {
                  companyId: Number(this.$route.query.id),
                  creditAmount: Number(this.QuotaBox.temporaryQuota),
                  creditAmountType: dialogType,
                };
              } else if (dialogType == 3) {
                dataBox = {
                  companyId: Number(this.$route.query.id),
                  creditAmount: Number(this.QuotaBox.frozenQuota),
                  creditAmountType: dialogType,
                };
              } else if (dialogType == 4) {
                dataBox = {
                  companyId: Number(this.$route.query.id),
                  creditAmount: Number(this.QuotaBox.thawQuota),
                  creditAmountType: dialogType,
                };
              }
              consumer_credit_creditAmountUpdate(dataBox)
                .then(res => {
                  this.dialogVisibleLoading = false;
                  this.$message({
                    message: '调整成功',
                    type: 'success'
                  });
                  this.req_detail();
                  this.dialogVisible = false;
                })
                .catch(rej => {
                  this.dialogVisibleLoading = false;
                  this.dialogVisible = false;
                });
            } else {

              return false;
            }
          });
        },
        // 调整固定额度\提升临时额度\冻结额度
        clickQuota(type){
          if (type == 1){
            // this.QuotaBox.regularQuota = this.detail.creditDetailResponse.creditAmount;
            this.QuotaBox.regularQuota = '';
            this.dialogTitle = '调整固定额度';
            this.dialogType = 1;
          } else if (type == 2) {
            // this.temporaryQuota = this.detail.creditDetailResponse.creditUsableAmount;
            this.QuotaBox.temporaryQuota = '';
            this.dialogTitle = '提升临时额度';
            this.dialogType = 2;
          } else if (type == 3) {
            // this.QuotaBox.frozenQuota = this.detail.creditDetailResponse.creditFreezeAmount;
            this.QuotaBox.frozenQuota = '';
            this.dialogTitle = '冻结额度';
            this.dialogType = 3;
          } else {
            // this.QuotaBox.thawQuota = this.detail.creditDetailResponse.creditFreezeAmount;
            this.QuotaBox.thawQuota = '';
            this.dialogTitle = '解冻额度';
            this.dialogType = 4;
          }

          this.dialogVisible = true;
          this.$nextTick(() => {
            if (this.$refs.ruleForm) {
              this.$refs.ruleForm.clearValidate();
            }
          })
        },
        init() {
            const __this = this;
            __this.req_detail();
            __this.req_list();
        },
        async req_detail () {
            const __this = this;
            const loading = this.$loading({
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            const params = {
                companyId: Number(__this.$route.query.id)
            };
            let [err, res] = await awaitWrap(consumer_credit_platformCreditDetail(params));
            if (err) {
                loading.close();
                return;
            }
            let detail = res.datas;
            detail.pdProductSetList = detail.companyDetailVo.pdProductSetList;
            if (detail.pdProductSetList) {
                detail.pdProductSetList.forEach(value=>{
                    if (detail.companyDetailVo.pdProductSetTempList) {
                        detail.companyDetailVo.pdProductSetTempList.forEach(value1 => {
                            if (value.businessType === value1.businessType) {
                                value.setTemp = value1;
                            }
                        })
                    }
                })
            }

            __this.detail = detail;
            console.log(__this.detail);
            __this.percentage = Number(__this.detail.usableAmount) / Number(__this.detail.creditAmount) * 100;


            let [configErr, configRes] = await awaitWrap(consumer_admin_company_query({companyId: Number(__this.$route.query.id), bizType: 1}));
            if (configErr) {
                loading.close();
                return;
            }
            let matchSetDetail = configRes.datas ? configRes.datas.companyBillConfigVo : {
                //"companyName": this.detail.companyName,
                //"companyId": this.detail.id,
                "hotelSplit": true,
                "settlingDay": '',
                "invoiceType": null
            };
            matchSetDetail.companyName = __this.detail.companyName;
            matchSetDetail.companyId = __this.detail.id;
            if (!matchSetDetail.invoiceType) {
                matchSetDetail.invoiceType = {
                    "flight": "行程单",
                    "itinerary": "增值稅普票",
                    "flightRef": "增值稅普票",
                    "train": "火车票票根",
                    "hotel": "增值税普票",
                    "car": "增值稅普票",
                    "ship": "自取船票票根",
                    "door": "增值税普票",
                    "visa": "增值稅普票",
                    "extra": "增值税普票",
                    "serviceFee": "增值稅普票"
                };
            }
            matchSetDetail.invoiceType.flight = matchSetDetail.invoiceType.flight || "行程单";
            matchSetDetail.invoiceType.itinerary = matchSetDetail.invoiceType.itinerary || "增值稅普票";
            matchSetDetail.invoiceType.flightRef = matchSetDetail.invoiceType.flightRef || "增值稅普票";
            matchSetDetail.invoiceType.train = matchSetDetail.invoiceType.train || "火车票票根";
            matchSetDetail.invoiceType.hotel = matchSetDetail.invoiceType.hotel || "增值税普票";
            matchSetDetail.invoiceType.car = matchSetDetail.invoiceType.car || "增值稅普票";
            matchSetDetail.invoiceType.ship = matchSetDetail.invoiceType.ship || "自取船票票根";
            matchSetDetail.invoiceType.door = matchSetDetail.invoiceType.door || "增值税普票";
            matchSetDetail.invoiceType.visa = matchSetDetail.invoiceType.visa || "增值稅普票";
            matchSetDetail.invoiceType.extra = matchSetDetail.invoiceType.extra || "增值税普票";
            matchSetDetail.invoiceType.serviceFee = matchSetDetail.invoiceType.serviceFee || "增值税普票";
            __this.matchSetDetail = matchSetDetail;
            loading.close();
        },
        req_list() {
            const __this = this;
            __this.loading = true;
            __this.form.companyId = Number(__this.$route.query.id);
            consumer_credit_billList(this.form).then((res) => {
                __this.list = res.datas.list;
                __this.pager.total = res.datas.totalCount;
                __this.loading = false;
            }).catch((err) => {
                __this.loading = false;
            });
        },
        change_pageSize (pageSize) {
            this.form.pageSize = pageSize;
            this.form.currentPage = 1;
            this.req_list(this.form);
        },
        change_currentPage (currentPage) {
            this.form.currentPage = currentPage;
            this.req_list(this.form);
        },
        to_detail(row) {
            let name = 'admin-credit-flow-list';
            const data_app_for_npm_haolv_platform_v2 = this.$route.meta.data_app_for_npm_haolv_platform_v2;
            if (data_app_for_npm_haolv_platform_v2) {
                const get_route_list_page = data_app_for_npm_haolv_platform_v2.get_route_flow_list_page;
                if (_.isFunction(get_route_list_page)) {
                    const route_list_page = get_route_list_page();
                    if (route_list_page) {
                        const route_name = route_list_page.route_name;
                        if (route_name) {
                            name = route_name;
                        }
                    }
                }
            }
            this.$router.push({
                name,
                query: {
                    billId: row.id,
                    companyId: row.companyId
                }
            });
        },
        req_update_status(companyId, creditStatus) {
            const __this = this;
            let message = '';
            let loading = null;
            if (creditStatus === 1) {
                message = '启用';
            } else if (creditStatus === 2) {
                message = '停用';
            }
            __this.$confirm(`是否确认${message}此帐号?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    loading = Loading.service({target: 'body', text: '请求中...'});
                    req_creditStatusUpdate({companyId, creditStatus}).then((res) => {
                        loading.close();
                        __this.$message({
                            message: `${message}成功`,
                            type: 'success',
                            onClose: function() {
                                let name = 'admin-credit-users-list';
                                const data_app_for_npm_haolv_platform_v2 = this.$route.meta.data_app_for_npm_haolv_platform_v2;
                                if (data_app_for_npm_haolv_platform_v2) {
                                    const get_route_list_page = data_app_for_npm_haolv_platform_v2.get_route_list_page;
                                    if (_.isFunction(get_route_list_page)) {
                                        const route_list_page = get_route_list_page();
                                        if (route_list_page) {
                                            const route_name = route_list_page.route_name;
                                            if (route_name) {
                                                name = route_name;
                                            }
                                        }
                                    }
                                }
                                __this.$router.push({
                                    name
                                })
                            }
                        });
                    });
                })
                .catch(() => {
                    loading.close();
                });
        },
        formatInvoiceType(val) {
            let text = '';
            this.invoiceType.forEach(value => {
                if (value.code === val) {
                    text = value.msg
                }
            })
            return text
        },
        formatPnrSet(val) {
            let text = '';
            this.pnrSetOptions.forEach(value => {
                if (value.value === val) {
                    text = value.text
                }
            });
            return text
        },
    },
    mounted() {
        consumer_web_company_getPnrSetType().then(res => {
            this.pnrSetOptions = res.datas.result;
        });
        consumerweb_company_queryCompanyInvoiceType().then((res) => {
            this.invoiceType = res.datas;
        });
    },
    activated () {
        this.init();
    },
    watch: {

    },
    filters: {
        format_type(val) {
            // "业务类型：1.机票，2.火车票，3.酒店，4.汽车"
            switch (Number(val)) {
                case 4:
                    return "用车";
                    break;
                case 2:
                    return "火车票";
                    break;
                case 3:
                    return "酒店";
                    break;
                case 1:
                    return "机票";
                    break;
            }
        },
        format_scale(val) {
            if (val === 1) {
                return '0~20人'
            } else if (val === 2) {
                return '20~50人'
            } else if (val === 3) {
                return '50~100人'
            } else if (val === 4) {
                return '100~300人'
            } else if (val === 5) {
                return '300~1000人'
            } else if (val === 6) {
                return '1000人以上'
            } else {
                return ''
            }
        },
        format_carryRules(val) {
            switch (Number(val)) {
                case 0:
                    return "进位到元";
                    break;
                case 1:
                    return "进位到分";
                    break;
            }
        },
        format_pass(val) {
            switch (val) {
                case 1:
                    return "是";
                    break;
                case 0:
                    return "否";
                    break;
            }
        },

    }
}

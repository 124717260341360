import get_sample_data_list from '@/lib/data-service/default/get_sample_data_list';
import history_tag_api from '@/component/admin-layout/component/history-tag/api';

export default {
  components: {},
  data() {
    return {
      data_get_sample_data_list: {
        list: [],
      },
    }
  },

  methods: {
    __btn_view_click(pEntity) {
      const __this = this;
      history_tag_api.remove_tag_by_route({
        route: __this.$route,
      });
      __this.$router.push({
        name: `admin-sample-data-detail`,
        query: {
          id: pEntity.id,
        },
      });
    },
  },
  created() {
  },
  activated() {
    const __this = this;
    get_sample_data_list().then(function (data) {
      __this.data_get_sample_data_list = data;
    });
  },
  deactivated() {
    // const __this = this;
  },
}

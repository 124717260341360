import _ from 'underscore'
import moment from 'moment'
import history_tag_api from '@/component/admin-layout/component/history-tag/api'
import OrderLog from '@/component/orderLog/1.0.0/index.vue'

import consumer_admin_hotel_order_queryHotelOrderDetails
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_queryHotelOrderDetails"   // 平台酒店订单详情
import consumer_admin_hotel_order_forHotelOrderDetails
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_forHotelOrderDetails"   // 获取酒店订单明细
import consumer_log_hotelList from "@/lib/data-service/haolv-default/consumer_log_hotelList"   // 获取酒店订单日志
import req_cancel_order from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_cancelOrder"   // 取消订单-酒店
import consumer_admin_hotel_order_partialCancelDetail
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_partialCancelDetail"   // 平台酒店订单详情
import consumer_admin_hotel_order_updateHotelOrderPartialCancel
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_updateHotelOrderPartialCancel";

export default {
    data() {
        const validateSupplierReturnedPrice = (rule, value, callback) => {
            if (value == '' || value == null || value < 0) {
                callback(new Error('供应商退回金额'))
            } else {
                callback()
            }
        }
        const validateSupplierYzlReturnedPrice = (rule, value, callback) => {
            if (value == '' || value == null || value < 0) {
                callback(new Error('胤之旅退回金额'))
            } else {
                callback()
            }
        }
        const validateRefundPrice = (rule, value, callback) => {
            if (value == '' || value == null || value < 0) {
                callback(new Error('退回金额'))
            } else {
                callback()
            }
        }
        return {
            detailLoading: false,
            loading: false,
            activeName: 'detail',
            orderDetail: {},
            cancelDetail: {},
            orderPersonList: [],
            costDetail: {},
            refundRule: [],
            formData: {
                reverseOrderId: '',
                resultFlag: 0,
                refundFine: 0,
                supplierReturnedPrice: 0,
                supplierYzlReturnedPrice: 0,
                refundPrice: 0,
            },
            moment: moment,
            rules: {
                refundFine: [
                    {required: true, validator: this.validateRefundFine, trigger: 'blur'}
                ],
                supplierReturnedPrice: [
                    {required: true, validator: validateSupplierReturnedPrice, trigger: 'blur'}
                ],
                supplierYzlReturnedPrice: [
                    {required: true, validator: validateSupplierYzlReturnedPrice, trigger: 'blur'}
                ],
                refundPrice: [
                    {required: true, validator: validateRefundPrice, trigger: 'blur'}
                ],
            }
        }
    },
    components: {
        OrderLog
    },
    activated() {
        this.formData.reverseOrderId = this.$route.query.reverseOrderId;
        this.getHotelOrderDetails()
        this.getOrderLog()
    },
    filters: {
        filterMoney(val) {
            if (val || val === 0) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return '0.00'
            }
        }
    },
    methods: {
        validateRefundFine(rule, value, callback) {

            if (value == '' || value == null || value < 0) {
                callback(new Error('退订罚金'))
            } else if (value >= this.formData.supplierReturnedPrice) {
                callback(new Error('退订罚金需少于供应商退回金额'))
            } else {
                callback()
            }
        },
        // 平台酒店订单详情
        async getHotelOrderDetails() {
            try {
                this.detailLoading = true
                const res = await consumer_admin_hotel_order_queryHotelOrderDetails({
                    orderNo: this.$route.query.orderNo
                })
                this.orderDetail = res.orderInterimResult
                this.orderPersonList = res.hotelOrderPersonList
                this.costDetail = res.hotelCostInfoResponse
                this.orderDetail.hotelGroup = res.hotelGroup;
                this.orderDetail.ifAgreement = res.ifAgreement;
                this.orderDetail.cardNumber = res.cardNumber;
                this.orderDetail.agreementHotelText = res.agreementHotelText;
                this.refundRule = [res.orderInterimResult.refund]

                let res1 = await consumer_admin_hotel_order_partialCancelDetail({
                    reverseOrderId: this.$route.query.reverseOrderId
                })
                this.cancelDetail = res1.partialCancelDetailResult;

                let supplierReturnedPrice = 0;
                let supplierYzlReturnedPrice = 0;
                let refundPrice = 0;
                let checkDateList = []; // 先获取间夜数据
                let checkInDate = this.cancelDetail.cancelCheckinDate;
                let checkOutDate = this.cancelDetail.cancelCheckoutDate;
                let checkRoomNo = this.cancelDetail.roomNo.split(',');
                while (this.$moment(checkOutDate).diff(this.$moment(checkInDate)) > 0) {
                    checkDateList.push(checkInDate);
                    // 自增
                    checkInDate = this.$moment(new Date(checkInDate)).add(1, 'day').format('YYYY-MM-DD')
                }
                this.costDetail.roomViewList.forEach((value, index)=> {
                    value.roomPriceList.forEach((value1, index1) => {
                        // 需要在时间范围内 且 房间序号对
                        if (checkDateList.indexOf(value.date) > -1 && checkRoomNo.indexOf((index1 + 1).toString()) > -1) {
                            supplierReturnedPrice = supplierReturnedPrice + value1.supplierPrice;
                            supplierYzlReturnedPrice = supplierYzlReturnedPrice + (value1.supplierYzlReturnedPrice || 0);
                            refundPrice = refundPrice + value1.platformPrice;
                        }
                    })


                })
                this.formData.supplierReturnedPrice = supplierReturnedPrice;
                this.formData.supplierYzlReturnedPrice = supplierYzlReturnedPrice;
                this.formData.refundPrice = refundPrice;
                this.formData.oldSupplierReturnedPrice = supplierReturnedPrice;
                this.formData.oldSupplierYzlReturnedPrice = supplierYzlReturnedPrice;
                this.formData.oldRefundPrice = refundPrice;
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "获取酒店订单详情失败！"
                })
            } finally {
                this.detailLoading = false
            }
        },
        changeRefundFine(val) {
            let supplierReturnedPrice = this.formData.oldSupplierReturnedPrice - Number(val);
            let supplierYzlReturnedPrice = 0;
            if(this.formData.oldSupplierYzlReturnedPrice > 0) {
                supplierYzlReturnedPrice = this.formData.oldSupplierYzlReturnedPrice - Number(val);
            }
            let refundPrice = this.formData.oldRefundPrice - Number(val);
            this.formData.supplierReturnedPrice = supplierReturnedPrice;
            this.formData.supplierYzlReturnedPrice = supplierYzlReturnedPrice;
            this.formData.refundPrice = refundPrice;
        },
        // 获取酒店订单明细
        async getSupplierDetail() {
            try {
                this.detailLoading = true
                const res = await consumer_admin_hotel_order_forHotelOrderDetails({
                    orderNo: this.orderDetail.thirdpartyOrderNo
                })
                this.orderDetail.orderFromTypeText = res.datas.supplierName
                this.orderDetail.supplierOrderNo = res.datas.supplierOrder
                this.orderDetail.supplierPrice = res.datas.supplierPrice
                this.orderDetail.settlementText = res.datas.settlement
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "获取酒店订单明细失败！"
                })
            } finally {
                this.detailLoading = false
            }
        },
        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    return consumer_log_hotelList({
                        orderNo: this.$route.query.orderNo
                    }).then(res => {
                        // 组装组件需要的list
                        const list = _.chain(res.datas)
                            .map(function (m) {
                                // 替换名字
                                const o = {
                                    operator: m.operator,
                                    date: m.operatorTime,
                                    type: m.logSource,
                                    detail: m.logContents
                                }
                                return o
                            }).value()
                        const d = {
                            entityList: list
                        }
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },
        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
            switch (val) {
                case (1):
                    return 'grey'
                case (2):
                    return 'orange'
                default:
                    return ''
            }
        },
        //改变退改处理结果
        changeResultFlag() {
            if (this.formData.resultFlag === 1) {
                this.$refs.form.clearValidate();
            }
        },
        // 订单状态
        getOrderStatusToClass(val) {
            switch (val) {
                case (23):
                    return 'status2'
                case (22):
                    return 'status1'
                default:
                    return 'status3'
            }
        },
        //保存
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.detailLoading = true
                    consumer_admin_hotel_order_updateHotelOrderPartialCancel(this.formData).then(res => {
                        this.detailLoading = false
                        this.$message({
                            type: "success",
                            message: '提交成功'
                        })
                        this.handleBackClick();
                    }, error => {
                        this.detailLoading = false
                    }).catch(() => {
                        this.detailLoading = false
                    })
                }
            });
        },
        // 返回
        handleBackClick() {
            this.$refs.form.clearValidate();
            history_tag_api.remove_tag_by_route({
                route: this.$route
            })
            this.$router.go(-1);
        }
    }
}

import _ from 'underscore'
import moment from 'moment'
import history_tag_api from '@/component/admin-layout/component/history-tag/api'
import OrderLog from '@/component/orderLog/2.0.0/index.vue'

import consumer_admin_offline_car_details
    from "@/lib/data-service/haolv-default/consumer_admin_offline_car_details";   // 订单详情
import consumer_admin_offline_car_action from '@/lib/data-service/haolv-default/consumer_admin_offline_car_action'
import consumer_logManage_bizlog_pageList from "@/lib/data-service/haolv-default/consumer_logManage_bizlog_pageList"   // 获取订单日志
import consumer_admin_offline_car_cancelBill from "@/lib/data-service/haolv-default/consumer_admin_offline_car_cancelBill"
import consumer_admin_offline_car_save from '@/lib/data-service/haolv-default/consumer_admin_offline_car_save'

import DictionarySelect
    from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';
import ChangeService from "@/component/change-service/index.vue";

export default {
    data() {
        const validateFine = (rule, value, callback) => {
            if (value >= 0) {
                callback();
            } else {
                callback(new Error('不能小于0'));
            }
        };
        return {
            confirmType: true,
            detailLoading: false,
            loading: false,
            remark: '',
            activeName: 'detail',
            orderSource: '1',
            orderDetail: {},
            form: {
                orderNo: '',
                visaRemark: '',
            },

            moment: moment,

            order: {},
            companyInfo: {},
            companyBills: {},
            journeyInfo: {},
            flightInfo: {},
            confirmRules: {
                orderSource: [
                    {required: true, message: '请选择采购渠道', trigger: 'blur'},
                ],
                /*supOrderNo: [
                    {required: true, message: '请填写交易号', trigger: 'blur'},
                ],*/
                paymentType: [
                    {required: true, message: '请选择付款方式', trigger: 'blur'},
                ],
                paymentTime: [
                    {required: true, message: '请选择付款时间', trigger: 'blur'},
                ],
                paymentNumber: [
                    {required: true, message: '请输入付款流水号', trigger: 'blur'},
                ],
            },

            issueDialogVisible: false,
            confirmForm: {},

            cancelDialogVisible: false, // 取消用车弹窗开关
            cancelForm: {
                id: '', // 临时存放单个id
                ids: null, //array
                fine: 0,
                refundAmount: 0,
                info: {},
            }, // 取消用车表单
            cancelRules: {
                fine: [
                    {required: true, message: '请填写罚金', trigger: 'blur'},
                    { validator: validateFine, trigger: 'blur' }
                ],
            }, // 取消用车表单规则
            cancelDialogVisible2: false, // 取消用车二次确认窗开关
            cancelLoading: false,
        }
    },
    computed: {
    },
    components: {
        ChangeService,
        OrderLog,
        DictionarySelect
    },
    activated() {
        this.init();
    },
    filters: {
        filterMoney(val) {
            if (val || val === 0) {
                return parseFloat(val).toFixed(2)
            } else {
                return '0.00'
            }
        },
        filterAmount(val) {
            if (val && val >= 0) {
                return parseFloat(val).toFixed(2);
            } else {
                return '0.00';
            }
        },
    },
    methods: {
        // 只能输入英文或数字
        handleInput(item, value) {
            // 使用正则表达式匹配字母和数字
            const reg = /^[a-zA-Z0-9]+$/;
            // 如果输入值不符合规定的正则表达式，则将inputValue重置为上一个值
            if (!reg.test(value)) {
                this.confirmForm[item] = this.confirmForm[item].replace(/[^\a-\z\A-\Z0-9]/g, '');
            }
        },
        //初始化
        init() {
            this.activeName = 'detail';
            // this.initDictionarySelect();
            this.getOfflineCarOrderDetails();
            this.getOrderLog()
        },

        //初始化字典组件
        initDictionarySelect() {
            Dictionary.init(this, {
                typeGroupKey: ['tmc'],
                typeKey: [],
                parentId: 0
            });
        },

        // 获取签证订单详情
        async getOfflineCarOrderDetails() {
            try {
                this.detailLoading = true
                const res = await consumer_admin_offline_car_details({
                    id: this.$route.query.id,
                    orderNo: this.$route.query.orderNo
                });
                this.detailLoading = false
                this.orderDetail = res.datas;
                this.orderDetail.order.orderNo = this.$route.query.orderNo;
                this.order = this.orderDetail.order;
                this.companyInfo = this.orderDetail.companyInfo;
                // this.companyBills = this.orderDetail.companyBills;
                this.journeyInfo = this.orderDetail.journeyInfo;
                this.flightInfo =this.orderDetail.flightInfo;
                this.remark = this.orderDetail.order.remark;

                let orderSourceText = await Dictionary.returnDictionary('tmc', 'carOrderSource', this.orderDetail.order.orderSource);
                this.orderDetail.order.orderSourceText = orderSourceText.name;
                this.order.orderSourceText = orderSourceText.name;

                let paymentTypeText = await Dictionary.returnDictionary('tmc', 'paymentType', this.orderDetail.order.paymentType);
                this.orderDetail.order.paymentTypeText = paymentTypeText.name;
                this.order.paymentTypeText = paymentTypeText.name;

                let carUseTypeText = await Dictionary.returnDictionary('tmc', 'carUseType', this.orderDetail.journeyInfo.carUseType);
                this.orderDetail.journeyInfo.carUseTypeText = carUseTypeText.name;
                this.journeyInfo.carUseTypeText = carUseTypeText.name;

                let defCarTypeText = await Dictionary.returnDictionary('tmc', 'defCarType', this.orderDetail.journeyInfo.defCarType);
                this.orderDetail.journeyInfo.defCarTypeText = defCarTypeText.name;
                this.journeyInfo.defCarTypeText = defCarTypeText.name;

            } catch (error) {
                this.detailLoading = false
                this.$message({
                    type: "error",
                    message: "获取签证订单详情失败！"
                })
            } finally {
                console.log('日志报错的时候没有进来这里')
                this.detailLoading = false
            }
        },

        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    let params = {
                        "pageBean": {
                            "currentPage": "-1",
                            "pageSize": "-1"
                        },
                        "params": {
                            "bizKey": "car"
                        },
                        "querys": [
                            {
                                "property": "bizId",
                                "operation": "EQUAL",
                                "value": this.$route.query.id,
                                "relation": "AND",
                                "group": "group1"
                            }
                        ]
                    }
                    return consumer_logManage_bizlog_pageList(params).then(res => {
                        // 组装组件需要的list
                        const list = _.chain(res.datas.list)
                            .map(function (m) {
                                // 替换名字
                                const o = {
                                    operator: m.operator,
                                    date: m.reqTime,
                                    type: m.bizType,
                                    detail: m.logDescribe
                                }
                                return o
                            }).value()
                        const d = {
                            entityList: list
                        }
                        return d || {}
                    }).catch(() => {
                        console.log('555555');
                        return {
                            entityList: []
                        }
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },

        // 返回
        handleBackClick() {
            history_tag_api.remove_tag_by_route({
                route: this.$route
            })
            this.$router.replace({
                name: "admin-offline-car-order-list"
            })
        },

        //跳转至编辑页
        handleEditClick() {
            this.$router.push({
                name: "admin-offline-car-create-order",
                query: {
                    type: 'edit',
                    id: this.$route.query.id
                }
            })
        },

        // 跳转至确认支付页
        handlePayClick() {
            this.$router.push({
                name: "admin-offline-car-create-order-pay",
                query: {
                    id: this.$route.query.id,
                    orderNo: this.$route.query.orderNo,
                    behalfCompanyId: this.orderDetail.companyId
                }
            })
        },

        //取消订单
        handleCancelCarClick() {
            this.cancelForm = {
                orderNo: this.order.orderNo,
                id: this.$route.query.id, // 临时存放单个id
                ids: null, //array
                fine: 0,
                refundAmount: 0, // 临时存此项信息
            };
            this.cancelDialogVisible = true;
        },
        // 弹窗二次确认取消用车
        cancelSubmit() {
            this.$refs.cancelForm.validate(async (valid) =>{
                if (!valid) {
                    return;
                }
                this.cancelForm.ids = [this.cancelForm.id];
                this.cancelForm.refundAmount = this.order.salesPrice - this.cancelForm.fine;
                this.cancelDialogVisible2 = true;
            })
        },
        // 最终确认取消用车
        cancelSubmitAgain() {
            this.cancelLoading = true;
            let cancelForm = JSON.parse(JSON.stringify(this.cancelForm));
            consumer_admin_offline_car_cancelBill(cancelForm).then(res=>{
                this.cancelLoading = false;
                this.cancelDialogVisible2 = false;
                this.cancelDialogVisible = false;
                this.$message.success('取消用车成功');
                this.getOfflineCarOrderDetails();
            }).catch(e=>{
                this.cancelLoading = false;
            })
        },

        handleCancelClick() {
            this.$confirm("确定取消此用车订单？", "确认取消", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.loading = true;
                let params = {
                    id: this.$route.query.id,
                    orderNo: this.$route.query.orderNo,
                    status: 3
                };
                consumer_admin_offline_car_action(params).then((res) => {
                    this.$message({
                        type: 'success',
                        message: '取消订单成功！'
                    })
                    this.getOfflineCarOrderDetails();
                }).finally(() => {
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                })
            }).catch(() => {
            })
        },

        //确认操作-显示弹框
        onShowConfirmDialog(switchBox) {
            // this.confirmForm = {};
            this.confirmForm = {
                "id": this.$route.query.id,
                "orderNo": this.order.orderNo,
                "status": 2,
                "supOrderNo": this.order.supOrderNo,
                orderSource: this.order.orderSource,
                "paymentType": this.order.paymentType || 'ALIPAY',
                "paymentTime": this.order.paymentTime || moment().format('yyyy-MM-DD HH:mm:ss'),
                "paymentNum": this.order.paymentNumber,
                "remark": this.order.remark,
                "driverName": this.journeyInfo.driverName,
                "driverPhone": this.journeyInfo.driverPhone,
                "carNumber": this.journeyInfo.carNumber,
                "carType": this.journeyInfo.carType,
                "carColor": this.journeyInfo.carColor,
                type: '4',
                orderMoney: this.orderDetail.orderMoney,
                payType: this.companyInfo.payType,
            };
            this.confirmType = switchBox;
            if (this.confirmType === false) {
                this.confirmForm.type = '2';
            }
            // this.confirmForm.type = '1';
            this.issueDialogVisible = true;
        },

        //出票操作-提交确定操作
        async confirmSubmit() {
            let params = {};
            if (this.confirmForm.type !== '3') {
                this.$refs['confirmForm'].validate(async (valid) => {
                    if (valid) {
                        this.loading = true;
                        /*params = {
                            id: this.confirmForm.id,
                            orderNo: this.confirmForm.orderNo,
                            status: parseInt(this.confirmForm.type),
                            supOrderNo: this.confirmForm.supOrderNo,
                            paymentType: this.confirmForm.paymentType || 0,
                            paymentTime: this.confirmForm.paymentTime,
                            paymentNum: this.confirmForm.paymentNumber,
                            remark: this.confirmForm.remark,
                        };*/
                        params = JSON.parse(JSON.stringify(this.confirmForm));
                        params.status = parseInt(this.confirmForm.type);
                        await consumer_admin_offline_car_action(params);
                        this.$message.success("保存成功");
                        this.issueDialogVisible = false;
                        await this.getOfflineCarOrderDetails();
                        this.loading = false;
                    }
                })
            } else {
                this.loading = true;
                params = {
                    id: this.confirmForm.id,
                    orderNo: this.confirmForm.orderNo,
                    status: 3,
                    remark: this.confirmForm.remark,
                };
                await consumer_admin_offline_car_action(params);
                this.$message.success("取消出票成功");
                this.issueDialogVisible = false;
                await this.getOfflineCarOrderDetails();
                this.loading = false;
            }

        },

        //保存备注
        async saveRemark() {
            if (this.remark === '') {
                this.$message({
                    type: "warning",
                    message: '请输入备注'
                });
                return
            }
            this.detailLoading = true;
            this.orderDetail.order.remark = JSON.parse(JSON.stringify(this.remark));
            consumer_admin_offline_car_save(this.orderDetail).then(res => {
                this.detailLoading = false;
                this.$message({
                    type: "success",
                    message: '提交成功'
                });
                this.remark = '';
                this.init()
            }, error => {
                this.detailLoading = false
            }).catch(() => {
                this.detailLoading = false
            })
        },

        handleDetailClick() {},

        // 调整服务费
        changeService() {
            let params = {
                businessType: 4,
                orderNo: this.$route.query.orderNo,
                oldService: this.order.serviceFee,
            };
            this.$refs.changeService.init(params).then(res=>{
                this.getOfflineCarOrderDetails();
            }).catch((e)=>{})
        },
    }
}


const __request = require(`./__request/__request_contentType_json`)
const consumer_hotelInit_listMergeRecord = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/hotelInit/handleMergeRecord',
    data: data,
    headers: {token:'TEST2020B'}
  }
  return __request(pParameter)
}
export default consumer_hotelInit_listMergeRecord
export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/kefu/hotel-report',
            name: 'admin-kefu-hotel-report',
            component: () => import(/* webpackChunkName: "page-admin-kefu-report-hotel-report" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `酒店对账报表`,
                },
                entity_list_for_breadcrumb: [
                    { name: `客服报表` },
                    { name: `酒店对账报表` },
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        return `admin-kefu-hotel-report`;
                    },
                },
            },
        },
    ],
};

import Q from "q";
// 封装异步组件
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";
// 历史
import history_tag_api from '@/component/admin-layout/component/history-tag/api';
// 省份市级数据
import consumer_web_city_provinceCity from "@/lib/data-service/haolv-default/consumer_web_city_provinceCity.js";
// 银行数据
import consumer_web_bank_getBankList from "@/lib/data-service/haolv-default/consumer_web_bank_getBankList";
// 上传文件组件
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client/index";
// 行业
import consumer_web_industry_selectIndustry
    from '@/lib/data-service/haolv-default/consumer_web_industry_selectIndustry.js';
// 客户审核详情
import consumer_web_company_detail from "@/lib/data-service/haolv-default/consumer_web_company_detail.js";
// 客户经理列表
import consumer_web_staff_staffAll from "@/lib/data-service/haolv-default/consumer_web_staff_staffAll.js";
// 添加客户
import consumer_web_company_addCustomer from '@/lib/data-service/haolv-default/consumer_web_company_addCustomer.js';
// 编辑客户
import consumer_web_company_updateCustomer from '@/lib/data-service/haolv-default/consumer_web_company_updateCustomer.js'
// 保存草稿
import consumer_web_company_addCustomerDrafts
    from '@/lib/data-service/haolv-default/consumer_web_company_addCustomerDrafts.js';
// 城市组件
import InlandProvinceCityRegion from "@/component/inland-province-city-region/index.vue";
// 手机号码验重
import consumer_web_company_verifyPhone from '@/lib/data-service/haolv-default/consumer_web_company_verifyPhone.js';
// 服务商枚举
import consumer_sys_inform_getEnum from '@/lib/data-service/haolv-default/consumer_sys_inform_getEnum.js';
//公司发票类型枚举
import consumerweb_company_queryCompanyInvoiceType
    from "@/lib/data-service/haolv-default/consumerweb_company_queryCompanyInvoiceType.js";
//获取协议卡等级数据集合
import consumer_web_company_getPnrSetType from "@/lib/data-service/haolv-default/consumer_web_company_getPnrSetType.js";
//获取生编类型枚举
import consumer_web_company_getDCCardLevel
    from "@/lib/data-service/haolv-default/consumer_web_company_getDCCardLevel.js";
// 活动价枚举
import consumer_hotel_search_getDCRateplanList
    from '@/lib/data-service/haolv-default/consumer_hotel_search_getDCRateplanList'
//下拉框选择组件
import EmunSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/emun-selector/1.0.0/index.vue';
import consumer_web_company_getInsuranceProductList from '@/lib/data-service/haolv-default/consumer_web_company_getInsuranceProductList';
import consumer_web_company_updateCompanyInsurance from '@/lib/data-service/haolv-default/consumer_web_company_updateCompanyInsurance';
import consumer_web_company_getCompanyInsurance from '@/lib/data-service/haolv-default/consumer_web_company_getCompanyInsurance';
import consumer_admin_company_save from "@/lib/data-service/haolv-default/consumer_admin_company_save";
import consumer_admin_company_query from "@/lib/data-service/haolv-default/consumer_admin_company_query";
export default {
    components: {
        InlandProvinceCityRegion,
        EmunSelector,
    },
    data() {
        const checkBusinessLicense = (rule, value, callback) => {
            const reg = /^([159Y]{1})([1239]{1})([0-9ABCDEFGHJKLMNPQRTUWXY]{6})([0-9ABCDEFGHJKLMNPQRTUWXY]{9})([0-9ABCDEFGHJKLMNPQRTUWXY])$/;
            if (!value) {
                return callback(new Error("请输入营业执照"));
            } else if (!reg.test(value)) {
                callback(new Error("请输入正确的营业执照"));
            } else {
                callback();
            }
        };
        return {
            type: 'add',
            valueTest: '',
            tipsRadioOff: true,
            loading: false,
            isEdit: false,
            detail: {},
            // 城市组件数据
            locationCity: {},
            fullscreenLoading: false,
            requiredServiceAmountTypeBox:[
                { label: 1, text: "比例" },
                { label: 2, text: "定额" },
            ],
            trainInvoiceTypeBox:[
                { label: 1, text: "代打" },
                { label: 2, text: "自取" },
            ],
            RateData: [
                {
                    RateName: '门市价',
                    RackValue: 'RackRate'
                },
                {
                    RateName: '普卡价',
                    RackValue: 'YbRate'
                },
                {
                    RateName: '银卡价',
                    RackValue: 'ZsRate'
                },
                {
                    RateName: '金卡价',
                    RackValue: 'ZzRate'
                },
                {
                    RateName: '黑金卡价',
                    RackValue: 'HjRate'
                }
            ],
            serviceChargeData: true,
            form: {
                userId: '', // 编辑进来所需的账户id
                clientType: '', // 客户类型 1：临时 2：正式
                username: '', // 账号
                phone: '',  // 手机
                realName: '', //真实姓名
                companyName: '', // 公司名称
                abbreviation: '', // 简称
                industryId: '', // 行业
                scale: '', // 企业规模
                areaProvince: '', // 省
                areaProvinceName: '',
                areaCity: '', // 市
                areaCityName: '',
                areaRegion: '', // 区
                areaRegionName: '',
                companyIntroduce: '', // 简介
                companyLogo: '', // logo
                businessLicenseNum: '', // 营业执照号
                businessLicensePic: '', // 营业执照图片
                reserveLinkman: '', // 预订联系人
                reserveLinkmanObj: { // 预订联系人
                    name: '',
                    phone: ''
                },
                contractLinkman: '', // 合同联系人
                contractLinkmanObj: { // 合同联系人
                    name: '',
                    phone: ''
                },

                financeLinkman: '', // 财务联系人
                financeLinkmanObj: { // 财务联系人
                    name: '',
                    phone: ''
                },

                urgencyLinkman: '', // 紧急联系人
                urgencyLinkmanObj: { // 紧急联系人
                    name: '',
                    phone: ''
                },

                accessoryUrl: '', // 附件
                customerManagerId: '', // 经理id
                customerManagerName: '', // 客户经理真实姓名
                carryRules: 0, // 进位规则
                settlement: '', // 服务商
                specialFocus: false,
                hotelTogether: false,
                accountStatus: 0,
                creditAmount: '', // 授信额度
                settlementInterval: '', // 结算周期
                creditPaymentDays: '', // 账单日
                dueDate: '', // 还款日
                overdueDays: '', // 可逾期天数
                bankList: [
                    {
                        bankUserName: '', // 对公账号 -- 用户名
                        bankAccount: '', // 银行账号
                        openingBank: '', // 开户行
                        openingBankName: '', // 开户行
                        provinceId: '',
                        provinceName: '',
                        cityId: '',
                        cityName: '',
                        openingBankBranch: '', // 开户网点
                    }
                ],

                // 服务费设置
                pdProductSetList: [
                    // 机票
                    {
                        businessType: 1, // 类型
                        showServiceFee: 0, // 是否展示服务费

                        serviceAmountSet: 0, // 线上服务费设置：0.不收取，1.收取，默认0
                        serviceAmountType: 1, // 线上服务费-计算方式：0. 无，1.百分比，2.定额
                        serviceAmount: "", // 线上服务费--收取金额、百分比

                        offlineServiceAmountSet: 0, // 线下服务费：0.不收取，1.收取，默认0
                        offlineServiceAmountType: 1, // 线下服务费-计算方式：0. 无，1.百分比，2.定额
                        offlineServiceAmount: "", // 线下服务费--收取金额、百分比

                        asiaServiceAmountSet: 0, // 亚洲服务费设置：0.不收取，1.收取，默认0
                        asiaServiceAmountType: 1, // 亚洲计算方式：0. 无，1.百分比，2.定额
                        asiaServiceAmount: "", // 亚洲服务费

                        internationalServiceAmountSet: 0, // 亚洲外服务费设置：0.不收取，1.收取，默认0
                        internationalServiceAmountType: 1, // 亚洲外计算方式：0. 无，1.百分比，2.定额
                        internationalServiceAmount: "", //亚洲外服务费

                        robTicket: "", // 抢票费
                        invoiceType: 0, // 发票类型
                        airPriceSet: 1, // 价格配置 1：票面价结算 2：结算价结算
                        pnrSet: 1, //生编设置 1：支付前生编 2：支付后生编 默认：2
                        enterpriseConfigurationSet: 0, // 0.不加价，1.加价
                        enterpriseConfigurationType: 1, // 1.百分比,2.定额'
                        enterpriseConfigurationAmount: '',
                        officialPolicyShowSet: 1, // 授权航司是否展示航司官网设置 0：不展示 1：展示 默认：1
                        lowPriceSet: 0,  //低价精选设置：0.关闭，1.开启，默认0
                        lowPriceType: 1, // 低价精选方式：0. 无，1.百分比，2.定额, 默认0"
                        lowPriceAmount: '0', // 授权航司是否展示航司官网设置 0：不展示 1：展示 默认：1
                        //结算收单
                    },
                    // 火车票
                    {
                        businessType: 2, // 类型
                        showServiceFee: 0, // 是否展示

                        serviceAmountSet: 0, // 线上服务费设置：0.不收取，1.收取，默认0
                        serviceAmountType: 2, // 线上服务费-计算方式：0. 无，1.百分比，2.定额
                        serviceAmount: "", // 线上服务费--收取金额、百分比

                        offlineServiceAmountSet: 0, // 线下服务费：0.不收取，1.收取，默认0
                        offlineServiceAmountType: 2, // 线下服务费-计算方式：0. 无，1.百分比，2.定额
                        offlineServiceAmount: "", // 线下服务费--收取金额、百分比

                        changeServiceAmountSet: 0,  // 线上改签服务费设置：0.不收取，1.收取，默认0
                        changeServiceAmountType: 2, // 线上改签服务费计算方式：0. 无，1.百分比，2.定额
                        changeServiceAmount: "",  // 线上改签服务费

                        offlineChangeServiceAmountSet: 0,  // 线下改签服务费设置：0.不收取，1.收取，默认0
                        offlineChangeServiceAmountType: 2, // 线下改签服务费计算方式：0. 无，1.百分比，2.定额
                        offlineChangeServiceAmount: "",  // 线下改签服务费

                        refundServiceAmountSet: 0, // 线上退票服务费设置：0.不收取，1.收取，默认0
                        refundServiceAmountType: 2, // 线上退票服务费计算方式：0. 无，1.百分比，2.定额
                        refundServiceAmount: "",  // 线上退票服务费

                        offlineRefundServiceAmountSet: 0, // 线下退票服务费设置：0.不收取，1.收取，默认0
                        offlineRefundServiceAmountType: 2, // 线下退票服务费计算方式：0. 无，1.百分比，2.定额
                        offlineRefundServiceAmount: "",  // 线下退票服务费

                        robTicket: "", // 抢票费
                        invoiceType: 0, // 发票类型
                        requiredServiceAmountSet: 0, // 免登录服务费设置：0.不收取，1.收取，默认0
                        requiredServiceAmountType: 2, // 免登录计算方式：0. 无，1.百分比，2.定额
                        requiredServiceAmount: "", // 免登录服务费

                        trainInvoiceType: 2, // 火车票发票设置 0：无 1：代订 2：自取 默认：0 无
                        proxyPrintServiceAmountType: 2,  // 代打服务费计算方式：0. 无，1.百分比，2.定额
                        proxyPrintServiceAmount: "",  // 代打服务费
                    },
                    // 用车
                    {
                        businessType: 4, // 类型
                        serviceAmountSet: 0, // 是否收取
                        showServiceFee: 0, // 是否展示
                        serviceAmountType: 1, // 计算方式
                        serviceAmount: "", // 收取金额
                    },
                    // 酒店
                    {
                        businessType: 3, // 类型
                        showServiceFee: 0, // 是否展示

                        serviceAmountSet: 0, // 服务费设置：0.不收取，1.收取，默认0
                        serviceAmountType: 1, // 计算方式：0. 无，1.百分比，2.定额
                        serviceAmount: "", // 服务费

                        agreementServiceAmountSet: 0, // 酒店线上服务费设置：0.不收取，1.收取，默认0
                        agreementServiceAmountType: 1, // 酒店线上服务费计算方式：0. 无，1.百分比，2.定额
                        agreementServiceAmount: '', // 酒店线上服务费
                        agreementServiceAmountLimit: '', // 酒店线上服务费上限

                        offlineServiceAmountSet: 0, // 线下服务费设置：0.不收取，1.收取，默认0
                        offlineServiceAmountType: 1, // 线下服务费计算方式：0. 无，1.百分比，2.定额
                        offlineServiceAmount: '', // 线下服务费
                        offlineServiceAmountLimit: '', // 线下服务费上限（仅酒店）

                        robTicket: "", // 抢票费
                        invoiceType: 0, // 发票类型
                        invoiceTaxAmount: null, // 收取金额(百分比)
                        //运营加价
                    },
                ],
                audit: true, //是否开通办公
                openCar: 0, //是否开通办公
                openMemberMt: 0, //是否开通展示美团会员酒店产品
                agreement: {
                    hzCompanyArgee: {
                        enable: 0,
                        cardNumber: '',
                        groupType: 6,
                        groupTypeText: '华住',
                        discount: undefined,
                    },// 华住
                    dcCompanyArgee: {
                        enable: 0,
                        cardNumber: '',
                        groupType: 9,
                        groupTypeText: '东呈',
                        rateplanList: [],
                        rateplanActiveList: [], // 选中的
                        discount: undefined,
                    }, // 东呈
                    ydCompanyArgee: {
                        enable: 0,
                        cardNumber: '',
                        discount: undefined,
                        ydCardNumberInfoList: [
                            {
                                ydCardNumber: '',
                                ydCardNumberName: ''
                            }
                        ],
                    }, // 亚朵
                    jjCompanyArgee: {
                        enable: 0,
                        cardNumber: '',
                        jjSignKey: '',
                        discount: undefined,
                    }, // 锦江
                    agreeInfoList: [],
                    serviceAmountSet: 0,
                    serviceAmountType: 1,
                    serviceAmount: '',
                    limitAmount: '',
                },

                // "companyName": "",
                "companyId": "",
                'templatePath': '',
                'exportLevel': 1,
                "hotelSplit": true,
                "settlingDay": '',
                "invoiceType": {
                    "flight": "行程单",
                    "itinerary": "增值稅普票",
                    "flightRef": "增值稅普票",
                    "train": "火车票票根",
                    "hotel": "增值税普票",
                    "car": "增值稅普票",
                    "ship": "自取船票票根",
                    "door": "增值税普票",
                    "visa": "增值稅普票",
                    "extra": "增值税普票",
                    "serviceFee": "增值稅普票"
                }
            },
            rateplanOptions: [], // 活动价枚举
            // 附件
            accessoryUrl: [
                {
                    name: "",
                    url: "",
                },
            ],
            formRules: {
                username: [{required: true, message: '请输入账号', trigger: 'blur'}],
                phone: [
                    {required: true, message: '请输入注册手机号码', trigger: 'blur'},
                    {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur'}
                ],

                realName: [{required: true, message: '请输入真实姓名', trigger: 'blur'}],

                clientType: [{required: true, message: '请选择客户类型', trigger: 'change'}],
                companyName: [{required: true, message: '请输入公司名称', trigger: 'blur'}],
                abbreviation: [{required: true, message: '请输入公司简称', trigger: 'blur'}],
                industryId: [{required: true, message: '请选择所属行业'}],
                scale: [{required: true, message: '请选择企业规模'}],
                // companyIntroduce: [{required: true, message: '请输入公司简介'}],
                companyLogo: [{required: true, message: '请上传公司logo'}],
                businessLicenseNum: [
                    {required: true, message: '请输入营业执照号', trigger: 'blur'},
                    // {validator: checkBusinessLicense, trigger: 'blur' }
                ],
                businessLicensePic: [{required: true, message: '请上传营业执照'}],
                customerManagerId: [{required: true, message: '请选择客户经理'}],
                carryRules: [{required: true, message: '请选择进位规则'}],
                settlement: [{required: true, message: '请选择服务商'}],
                status: [{required: true, message: ''}],
                creditAmount: [{required: true, message: '请输入授信额度'}],
                settlementInterval: [{required: true, message: '请选择结算周期'}],
                creditPaymentDays: [{required: true, message: '请选择账单日'}],
                dueDate: [{required: true, message: '请选择还款日'}],
                overdueDays: [{required: true, message: '请选择可逾期天数'}],
                serviceAmountSet: [{required: true, message: '请选择计算方式'}],
                showServiceFee: [{required: true, message: '请选择是否展示服务费'}],
                invoiceType: [{required: true, message: '请选择发票类型'}],
                invoiceTaxAmount: [{required: true, message: '请填写收取金额(百分比)'}],
                robTicket: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value == '') {
                                callback(new Error("请输入抢票费"));
                            }
                            callback();
                        },
                        trigger: "blur"
                    }
                ],
                trainInvoiceType: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (this.form.pdProductSetList[1].trainInvoiceType == 0) {
                                callback(new Error("请选择发票设置类型"));
                            }
                            if (this.form.pdProductSetList[1].trainInvoiceType == 1 && (this.form.pdProductSetList[1].proxyPrintServiceAmount == '' || this.form.pdProductSetList[1].proxyPrintServiceAmount == null)) {
                                this.form.pdProductSetList[1].proxyPrintServiceAmountType = 2;
                                callback(new Error("请输入代打服务费"));
                            }
                            callback();
                        },
                        trigger: "blur"
                    }
                ],
                serviceAmount: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value == '') {
                                callback(new Error("请输入收取金额"));
                            }
                            callback();
                        },
                        trigger: "blur"
                    }
                ],
                serviceAmountProp: [
                    {
                        validator: (rule, value, callback) => {
                            if (
                                this.form.agreement.serviceAmountSet == 1 &&
                                (this.form.agreement.serviceAmount == null ||
                                    this.form.agreement.serviceAmount == '')
                            ) {
                                callback(new Error("请输入收起金额"));
                            }
                            callback();
                        },
                        trigger: "blur"
                    },
                ],
                agreementServiceAmountLimit: [
                    {
                        validator: (rule, value, callback) => {
                            if (
                                this.form.pdProductSetList[3].agreementServiceAmountSet === 1 &&
                                this.form.pdProductSetList[3].agreementServiceAmountType == 1 &&
                                (this.form.pdProductSetList[3].agreementServiceAmountLimit == null ||
                                  this.form.pdProductSetList[3].agreementServiceAmountLimit == '')
                            ) {
                                callback(new Error("请输入上限金额"));
                            }
                            callback();
                        }, trigger: "blur"
                    },
                ],
                offlineServiceAmountLimit: [
                    {
                        validator: (rule, value, callback) => {
                            if (
                                this.form.pdProductSetList[3].offlineServiceAmountSet === 1 &&
                                this.form.pdProductSetList[3].offlineServiceAmountType == 1 &&
                                (this.form.pdProductSetList[3].offlineServiceAmountLimit == null ||
                                  this.form.pdProductSetList[3].offlineServiceAmountLimit == '')
                            ) {
                                callback(new Error("请输入上限金额"));
                            }
                            callback();
                        }, trigger: "blur"
                    },
                ],
                airPriceSet: [
                    {required: true, message: '请选择价格配置', trigger: 'change'},
                ],
                pnrSet: [
                    {required: true, message: '请选择生编', trigger: 'change'},
                ],
                officialPolicyShowSet: [
                    {required: true, message: '请选择是否展示航司官网产品', trigger: 'change'},
                ],
                hzCardNumber: [
                    {required: true, message: '请填写华住协议卡号', trigger: 'blur'},
                ],
                hzCardNumberDiscount: [
                    {required: true, message: '请填写折扣', trigger: 'blur'},
                    {
                        validator: (rule, value, callback) => {
                            let data = this.form.agreement.hzCompanyArgee.discount;
                            let ruleTextTwo = /^-?\d+$/;

                            if (!ruleTextTwo.test(data)) {
                                callback(new Error("请填写正整数"));
                            }

                            callback();
                        }, trigger: "blur"
                    },
                ],
                dcCardNumber: [
                    {required: true, message: '请填写东呈协议卡号', trigger: 'blur'},
                ],
                dcCardNumberDiscount: [
                    {required: true, message: '请填写折扣', trigger: 'blur'},
                    {
                        validator: (rule, value, callback) => {
                            let data = this.form.agreement.dcCompanyArgee.discount;
                            let ruleTextTwo = /^-?\d+$/;

                            if (!ruleTextTwo.test(data)) {
                                callback(new Error("请填写正整数"));
                            }

                            callback();
                        }, trigger: "blur"
                    },
                ],
                cardGrade: [
                    {required: true, message: '请选择东呈协议卡等级', trigger: 'change'},
                ],
                ydMainCardNumber: [
                    {required: true, message: '请填写亚朵协议卡号', trigger: 'blur'},
                ],
                ydMainCardNumberDiscount: [
                    {required: true, message: '请填写折扣', trigger: 'blur'},
                    {
                        validator: (rule, value, callback) => {
                            let data = this.form.agreement.ydCompanyArgee.discount;
                            let ruleTextTwo = /^-?\d+$/;

                            if (!ruleTextTwo.test(data)) {
                                callback(new Error("请填写正整数"));
                            }

                            callback();
                        }, trigger: "blur"
                    },
                ],
                ydCardNumber: [
                    {required: true, message: '请填写亚朵副协议卡号', trigger: 'blur'},
                ],
                ydCardNumberName: [
                    {required: true, message: '请填写亚朵折扣', trigger: 'blur'},
                ],
                jjCardNumber: [
                    {required: true, message: '请填写锦江账号Appid', trigger: 'blur'},
                ],
                jjCardNumberDiscount: [
                    {required: true, message: '请填写折扣', trigger: 'blur'},
                    {
                        validator: (rule, value, callback) => {
                            let data = this.form.agreement.jjCompanyArgee.discount;
                            let ruleTextTwo = /^-?\d+$/;

                            if (!ruleTextTwo.test(data)) {
                                callback(new Error("请填写正整数"));
                            }

                            callback();
                        }, trigger: "blur"
                    },
                ],
                jjSignKey: [
                    {required: true, message: '请填写密钥', trigger: 'blur'},
                ],
                enterpriseConfigurationSet: [
                    {required: true, message: '请选择是否加价', trigger: 'change'},
                ],
                enterpriseConfigurationType: [
                    {required: true, message: '请选择价格配置', trigger: 'change'},
                ],
                enterpriseConfigurationAmount: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value === undefined || value === null || value === '') {
                                this.form.pdProductSetList.forEach((item) => {
                                    if (item.airPriceSet && item.airPriceSet == 2) {
                                        callback(new Error("请输入收取金额"));
                                        return;
                                    }
                                });
                            }
                            callback();
                        },
                        trigger: 'blur'
                    },
                ],
                lowPriceAmount: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value === undefined || value === null || value === '') {
                                this.form.pdProductSetList.forEach((item) => {
                                    if (item.lowPriceSet && item.lowPriceSet == 1) {
                                        callback(new Error("请输入收取金额"));
                                        return;
                                    }
                                });
                            }
                            callback();
                        },
                        trigger: 'blur'
                    },
                ],
                // bankUserName: [{required: true, message: '请输入用户名', trigger: ['blur', 'change']}],
                // bankAccount: [{required: true, message: '请输入银行账号', trigger: ['blur', 'change']}],
                // openingBank: [{required: true, message: '请选择开户行', trigger: ['blur', 'change']}],
                // areaRegion: [{required: true, message: '请选择所在城市', trigger: ['blur', 'change']}],
                // openingBankBranch: [{required: true, message: "请输入", trigger: ['blur', 'change']}],
            },
            cityRules: {
                form: {
                    areaProvince: 'areaProvince',
                    areaCity: 'areaCity',
                    areaRegion: 'areaRegion',
                },
                rules: {
                    areaProvince: [{required: true, message: '请选择省份', trigger: 'change'}],
                    areaCity: [{required: true, message: '请选择城市', trigger: 'change'}],
                    areaRegion: [{required: false, message: '请选择区', trigger: 'change'}],
                },
                required: true
            },
            businessLicenseList: [], // 营业执照图片

            airPriceSetOptions: [
                {value: 1, label: '票面价结算'},
                {value: 2, label: '结算价结算'},
            ],

            pnrSetOptions: [],
            officialPolicyShowSetOptions: [{value: 1, text: '展示'}, {value: 0, text: '不展示'}],

            allocation: {
                provinceList: [], // 省级列表
                cityList: [], // 市级列表
                bankList: [], // 银行列表
                settlementList: [], // 服务商
                industryType: [],
                enterpriseScale: [
                    {value: 1, label: "0~20人"},
                    {value: 2, label: "20~50人"},
                    {value: 3, label: "50~100人"},
                    {value: 4, label: "100~300人"},
                    {value: 5, label: "300~1000人"},
                    {value: 6, label: "1000人以上"},
                ],
                serviceAmountSet: [
                    {label: 0, text: "不收取"},
                    {label: 1, text: "收取"},
                ],
                serviceAmountType: [
                    {label: 1, text: "比例"},
                    {label: 2, text: "定额"},
                ],
                serviceAmountTypeCar: [
                    {label: 2, text: "定额"},
                ],
                invoiceType: [
                    {code: 0, msg: "专票"},
                    {code: 1, msg: "普票"},
                    {code: 2, msg: "均可"},
                ],
                manager: [],
                carryRules: [
                    {label: 0, text: "元"},
                    {label: 1, text: "分"},
                ],
                audit: [
                    {label: true, text: "开通"},
                    {label: false, text: "不开通"},
                ],
                openCar: [
                    {label: 1, text: "开通"},
                    {label: 0, text: "不开通"},
                ],
                openMemberMt: [
                    {label: 1, text: "开通"},
                    {label: 0, text: "不开通"},
                ],
                setServiceAmountType: [
                    {label: 0, text: "不加价"},
                    {label: 1, text: "加价"},
                ],
                settlementInterval: 3,
                creditPaymentDays: 30,
                dueDate: 30,
                overdueDays: 16,
            },
            selectService: [false, false, false],
            headOnScroll: null,

            logoLoading: false,
            businessLicenseNumLoading: false,

            companyArgeeActiveName: '1',

            isShowCustomerInfo: true,
            isShowCompanyQua: true,
            isShowPlatformSettings: true,
            isShowAppendix: true,
            isShowPriceSettings: true,
            // 保险用
            isShowInsuranceSettings: true,
            insuranceForm: {
                flight: [
                    {
                        businessType: 1,
                        productCode: '',
                        insuranceInvoiceType: 0,
                        freeProduct: 0,
                        zoneType: 0, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                    },
                    {
                        businessType: 1,
                        productCode: '',
                        insuranceInvoiceType: 0,
                        freeProduct: 0,
                        zoneType: 1, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                    },
                    {
                        businessType: 1,
                        productCode: '',
                        insuranceInvoiceType: 0,
                        freeProduct: 0,
                        zoneType: 2, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                    },
                ],
                train: [
                    {
                        businessType: 2,
                        productCode: '',
                        insuranceInvoiceType: 0,
                        freeProduct: 0,
                        zoneType: 0, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                    },
                ]
            },
            insuranceOptions: [],
            trainInsuranceOptions: [],

            //结算设置
            isShowConfigSettings: true,
            /*matchSetForm: {
                "companyName": "",
                "companyId": "",
                "hotelSplit": true,
                "settlingDay": '',
                "invoiceType": {
                    "flight": "",
                    "itinerary": "",
                    "flightRef": "",
                    "train": "",
                    "hotel": "",
                    "car": "",
                    "ship": "",
                    "door": "",
                    "visa": "",
                    "extra": "",
                    "serviceFee": ""
                }
            },
            matchSetFormRules: {},*/
            dayOptions: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
            flightOptions: ['行程单'],
            flightMoneyOptions: ['增值税普票'],
            flightRefundOptions: ['增值税专票', '增值税普票'],
            trainOptions: ['火车票票根'],
            hotelOptions: ['增值税专票', '增值税普票'],
            carOptions: ['增值税专票', '增值税普票'],
            boatOptions: ['船票票根'],
            doorOptions: ['增值税普票'],
            visaOptions: ['增值税普票'],
            otherOptions: ['增值税专票', '增值税普票'],
            serviceOptions: ['增值税专票', '增值税普票'],
        };
    },
    methods: {
        inputMatch(data,text) {
            console.log(data, eval(`${data}.match(${text}) == null ? '' : ${data}.match(${text})[0];`));
            eval(`${data} = ${data}.match(${text}) == null ? '' : ${data}.match(${text})[0];`);
        },
        changeLowPriceSet(){
            this.$refs['form'].validate();
        },
        // 重置方法
        clickReset() {
            this.locationCity = {};
            this.accessoryUrl = [];

            this.form = {
                userId: '', // 编辑进来所需的账户id
                username: '', // 账号
                phone: '',  // 手机
                realName: '', //真实姓名
                companyName: '', // 公司名称
                abbreviation: '', // 简称
                industryId: '', // 行业
                scale: '', // 企业规模
                areaProvince: '', // 省
                areaProvinceName: '',
                areaCity: '', // 市
                areaCityName: '',
                areaRegion: '', // 区
                areaRegionName: '',
                companyIntroduce: '', // 简介
                companyLogo: '', // logo
                businessLicenseNum: '', // 营业执照号
                businessLicensePic: '', // 营业执照图片
                reserveLinkman: '', // 预订联系人
                reserveLinkmanObj: { // 预订联系人
                    name: '',
                    phone: ''
                },
                contractLinkman: '', // 合同联系人
                contractLinkmanObj: { // 合同联系人
                    name: '',
                    phone: ''
                },

                financeLinkman: '', // 财务联系人
                financeLinkmanObj: { // 财务联系人
                    name: '',
                    phone: ''
                },

                urgencyLinkman: '', // 紧急联系人
                urgencyLinkmanObj: { // 紧急联系人
                    name: '',
                    phone: ''
                },

                accessoryUrl: '', // 附件
                customerManagerId: '', // 经理id
                customerManagerName: '', // 客户经理真实姓名
                carryRules: 0, // 进位规则
                settlement: '', // 服务商
                specialFocus: false,
                hotelTogether: false,
                accountStatus: 0,
                creditAmount: '', // 授信额度
                settlementInterval: '', // 结算周期
                creditPaymentDays: '', // 账单日
                dueDate: '', // 还款日
                overdueDays: '', // 可逾期天数
                bankList: [
                    {
                        bankUserName: '', // 对公账号 -- 用户名
                        bankAccount: '', // 银行账号
                        openingBank: '', // 开户行
                        openingBankName: '', // 开户行
                        provinceId: '',
                        provinceName: '',
                        cityId: '',
                        cityName: '',
                        openingBankBranch: '', // 开户网点
                    }
                ],

                // 服务费设置
                pdProductSetList: [
                    // 机票
                    {
                        businessType: 1, // 类型
                        showServiceFee: 0, // 是否展示服务费

                        serviceAmountSet: 0, // 线上服务费设置：0.不收取，1.收取，默认0
                        serviceAmountType: 1, // 线上服务费-计算方式：0. 无，1.百分比，2.定额
                        serviceAmount: "", // 线上服务费--收取金额、百分比

                        offlineServiceAmountSet: 0, // 线下服务费：0.不收取，1.收取，默认0
                        offlineServiceAmountType: 1, // 线下服务费-计算方式：0. 无，1.百分比，2.定额
                        offlineServiceAmount: "", // 线下服务费--收取金额、百分比

                        asiaServiceAmountSet: 0, // 亚洲服务费设置：0.不收取，1.收取，默认0
                        asiaServiceAmountType: 1, // 亚洲计算方式：0. 无，1.百分比，2.定额
                        asiaServiceAmount: "", // 亚洲服务费

                        internationalServiceAmountSet: 0, // 亚洲外服务费设置：0.不收取，1.收取，默认0
                        internationalServiceAmountType: 1, // 亚洲外计算方式：0. 无，1.百分比，2.定额
                        internationalServiceAmount: "", //亚洲外服务费

                        robTicket: "", // 抢票费
                        invoiceType: 0, // 发票类型
                        airPriceSet: 1, // 价格配置 1：票面价结算 2：结算价结算
                        pnrSet: 1, //生编设置 1：支付前生编 2：支付后生编 默认：2
                        enterpriseConfigurationSet: 0, // 0.不加价，1.加价
                        enterpriseConfigurationType: 1, // 1.比例,2.定额'
                        enterpriseConfigurationAmount: '',
                        officialPolicyShowSet: 1, // 授权航司是否展示航司官网设置 0：不展示 1：展示 默认：1
                        lowPriceSet: 0,  //低价精选设置：0.关闭，1.开启，默认0
                        lowPriceType: 1, // 低价精选方式：0. 无，1.百分比，2.定额, 默认0"
                        lowPriceAmount: '0', // 授权航司是否展示航司官网设置 0：不展示 1：展示 默认：1
                    },
                    // 火车票
                    {
                        businessType: 2, // 类型
                        showServiceFee: 0, // 是否展示

                        serviceAmountSet: 0, // 线上服务费设置：0.不收取，1.收取，默认0
                        serviceAmountType: 2, // 线上服务费-计算方式：0. 无，1.百分比，2.定额
                        serviceAmount: "", // 线上服务费--收取金额、百分比

                        offlineServiceAmountSet: 0, // 线下服务费：0.不收取，1.收取，默认0
                        offlineServiceAmountType: 2, // 线下服务费-计算方式：0. 无，1.百分比，2.定额
                        offlineServiceAmount: "", // 线下服务费--收取金额、百分比

                        changeServiceAmountSet: 0,  // 线上改签服务费设置：0.不收取，1.收取，默认0
                        changeServiceAmountType: 2, // 线上改签服务费计算方式：0. 无，1.百分比，2.定额
                        changeServiceAmount: "",  // 线上改签服务费

                        offlineChangeServiceAmountSet: 0,  // 线下改签服务费设置：0.不收取，1.收取，默认0
                        offlineChangeServiceAmountType: 2, // 线下改签服务费计算方式：0. 无，1.百分比，2.定额
                        offlineChangeServiceAmount: "",  // 线下改签服务费

                        refundServiceAmountSet: 0, // 线上退票服务费设置：0.不收取，1.收取，默认0
                        refundServiceAmountType: 2, // 线上退票服务费计算方式：0. 无，1.百分比，2.定额
                        refundServiceAmount: "",  // 线上退票服务费

                        offlineRefundServiceAmountSet: 0, // 线下退票服务费设置：0.不收取，1.收取，默认0
                        offlineRefundServiceAmountType: 2, // 线下退票服务费计算方式：0. 无，1.百分比，2.定额
                        offlineRefundServiceAmount: "",  // 线下退票服务费

                        robTicket: "", // 抢票费
                        invoiceType: 0, // 发票类型
                        requiredServiceAmountSet: 0, // 免登录服务费设置：0.不收取，1.收取，默认0
                        requiredServiceAmountType: 2, // 免登录计算方式：0. 无，1.百分比，2.定额
                        requiredServiceAmount: "", // 免登录服务费

                        trainInvoiceType: 2, // 火车票发票设置 0：无 1：代订 2：自取 默认：0 无
                        proxyPrintServiceAmountType: 2,  // 代打服务费计算方式：0. 无，1.百分比，2.定额
                        proxyPrintServiceAmount: "",  // 代打服务费
                    },
                    // 用车
                    {
                        businessType: 4, // 类型
                        serviceAmountSet: 0, // 是否收取
                        showServiceFee: 0, // 是否展示
                        serviceAmountType: 1, // 计算方式
                        serviceAmount: "", // 收取金额
                    },
                    // 酒店
                    {
                        businessType: 3, // 类型
                        showServiceFee: 0, // 是否展示

                        serviceAmountSet: 0, // 是否收取
                        serviceAmountType: 1, // 计算方式
                        serviceAmount: "", // 收取金额、百分比

                        agreementServiceAmountSet: 0, // 酒店线上服务费设置：0.不收取，1.收取，默认0
                        agreementServiceAmountType: 1, // 酒店线上服务费计算方式：0. 无，1.百分比，2.定额
                        agreementServiceAmount: '', // 酒店线上服务费
                        agreementServiceAmountLimit: '', // 酒店线上服务费上限

                        offlineServiceAmountSet: 0, // 线下服务费设置：0.不收取，1.收取，默认0
                        offlineServiceAmountType: 1, // 线下服务费计算方式：0. 无，1.百分比，2.定额
                        offlineServiceAmount: '', // 线下服务费
                        offlineServiceAmountLimit: '', // 线下服务费上限（仅酒店）

                        robTicket: "", // 抢票费
                        invoiceType: 0, // 发票类型
                        invoiceTaxAmount: null, // 收取金额(百分比)
                    },
                ],
                audit: true, //是否开通办公
                openCar: 0, //是否开通办公
                openMemberMt: 0, //是否开通展示美团会员酒店产品
                agreement: {
                    hzCompanyArgee: {
                        enable: 0,
                        cardNumber: '',
                        groupType: 6,
                        groupTypeText: '华住',
                        discount: undefined,
                    },// 华住
                    dcCompanyArgee: {
                        enable: 0,
                        cardNumber: '',
                        groupType: 9,
                        groupTypeText: '东呈',
                        rateplanList: [],
                        rateplanActiveList: [], // 选中的
                        discount: undefined,
                    }, // 东呈
                    ydCompanyArgee: {
                        enable: 0,
                        cardNumber: '',
                        discount: undefined,
                        ydCardNumberInfoList: [
                            {
                                ydCardNumber: '',
                                ydCardNumberName: ''
                            }
                        ],
                    }, // 亚朵
                    jjCompanyArgee: {
                        enable: 0,
                        cardNumber: '',
                        jjSignKey: '',
                        discount: undefined,
                    }, // 锦江
                    agreeInfoList: [],
                    serviceAmountSet: 0,
                    serviceAmountType: 1,
                    serviceAmount: '',
                    limitAmount: '',
                },
                "hotelSplit": true,
                templatePath: '',
                exportLevel: 1,
                "settlingDay": '',
                "invoiceType": {
                    "flight": "行程单",
                    "itinerary": "增值稅普票",
                    "flightRef": "增值稅普票",
                    "train": "火车票票根",
                    "hotel": "增值税普票",
                    "car": "增值稅普票",
                    "ship": "自取船票票根",
                    "door": "增值税普票",
                    "visa": "增值稅普票",
                    "extra": "增值税普票",
                    "serviceFee": "增值稅普票"
                },
            };

            this.insuranceForm = {
                flight: [
                    {
                        businessType: 1,
                        productCode: '',
                        insuranceInvoiceType: 0,
                        freeProduct: 0,
                        zoneType: 0, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                    },
                    {
                        businessType: 1,
                        productCode: '',
                        insuranceInvoiceType: 0,
                        freeProduct: 0,
                        zoneType: 1, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                    },
                    {
                        businessType: 1,
                        productCode: '',
                        insuranceInvoiceType: 0,
                        freeProduct: 0,
                        zoneType: 2, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                    },
                ],
                    train: [
                    {
                        businessType: 2,
                        productCode: '',
                        insuranceInvoiceType: 0,
                        freeProduct: 0,
                        zoneType: 0, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                    },
                ]
            };

            this.$refs['form'].resetFields();
        },
        inputBlur(index) {
            return
            let textBox = null;
            eval(`
                textBox = this.$refs.cardTextRed${index}
            `);
            let name = ''
            if (index === 0) {
                name = 'hzCompanyArgee'
            } else {
                name = 'dcCompanyArgee'
            }
            if (
                (this.form.agreement[name].cardNumber === '' ||
                    this.form.agreement[name].cardNumber === null)
                &&
                this.form.agreement[name].enable === 1
            ) {
                textBox.innerHTML = '请输入协议卡号';
            } else {
                textBox.innerHTML = null;
            }
        },
        serviceCharge(val, type) {
            let hzCompanyArgeeEnable = this.form.agreement.hzCompanyArgee.enable
            let dcCompanyArgeeEnable = this.form.agreement.dcCompanyArgee.enable
            let ydCompanyArgeeEnable = this.form.agreement.ydCompanyArgee.enable
            let jjCompanyArgeeEnable = this.form.agreement.jjCompanyArgee.enable
            if (hzCompanyArgeeEnable || dcCompanyArgeeEnable || ydCompanyArgeeEnable || jjCompanyArgeeEnable) {
                this.serviceChargeData = true;
            } else {
                this.serviceChargeData = false;
            }
            if (type === 'hz') {
                this.$refs.form.clearValidate(['agreement.hzCompanyArgee.cardNumber'])
            } else if (type === 'dc') {
                this.$refs.form.clearValidate(['agreement.dcCompanyArgee.cardNumber', 'agreement.dcCompanyArgee.cardGrade'])
            } else if (type === 'yd') {
                let validateArr = ['agreement.ydCompanyArgee.cardNumber']
                this.form.agreement.ydCompanyArgee.ydCardNumberInfoList.forEach((value, index) => {
                    validateArr.push('agreement.ydCompanyArgee.ydCardNumberInfoList.' + index + '.ydCardNumber')
                    validateArr.push('agreement.ydCompanyArgee.ydCardNumberInfoList.' + index + '.ydCardNumberName')
                })
                this.$refs.form.clearValidate(validateArr)
            } else if (type === 'jj') {
                let validateArr = ['agreement.jjCompanyArgee.cardNumber', 'agreement.jjCompanyArgee.jjSignKey']
                this.$refs.form.clearValidate(validateArr)
            }
        },
        ClickButton(index) {
            let name = ''
            if (index === 0) {
                name = 'hzCompanyArgee'
            } else {
                name = 'dcCompanyArgee'
            }
            if (this.form.agreement[name].enable === 1) {
                this.form.agreement[name].enable = 0
                this.inputBlur(index)
            } else {
                this.form.agreement[name].enable = 1
            }
            this.serviceCharge(index);
        },
        req_province_city_data(type, pParams) {
            consumer_web_city_provinceCity(pParams).then((res) => {
                const allocation = this.allocation;
                const list = res.datas;
                if (type === 1) {
                    list.forEach((item) => {
                        item.name = item.name.replace(/[a-zA-Z]/, "");
                    });
                    allocation.provinceList = list;
                } else if (type === 2) {
                    list[0].childList.forEach((item) => {
                        item.name = item.name.replace(/[a-zA-Z]/, "");
                    });
                    allocation.cityList = list[0].childList;
                }
            });
        },
        select_province(val, index) {
            const form = this.form;
            const allocation = this.allocation;
            if (this.myLayout.includes("city")) {
                allocation.cityList = [];
                allocation.areaList = [];
                form.bankList[index].cityId = "";
                form.bankList[index].cityName = "";
                this.req_province_city_data(2, {provinceId: val});
            }
            const list = this.allocation.provinceList;
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                if (val === item.id) return (this.form.bankList[index].provinceName = item.name);
            }
        },
        select_city(val, index) {
            const list = this.allocation.cityList;
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                if (val === item.id) return (this.form.bankList[index].cityName = item.name);
            }
        },

        init() {
            const __this = this;
            consumer_web_bank_getBankList().then((res) => {
                res.datas.forEach((item) => {
                    item.bankCode = Number(item.bankCode)
                });
                __this.allocation.bankList = res.datas;
            });
            consumer_web_industry_selectIndustry().then((res) => {
                __this.allocation.industryType = res.datas;
            });
            consumer_web_staff_staffAll().then((res) => {
                __this.allocation.manager = res.datas;
            });
            consumer_sys_inform_getEnum(["settlement"]).then((res) => {
                __this.allocation.settlementList = res.datas.settlement;
            });
            consumerweb_company_queryCompanyInvoiceType().then((res) => {
                this.allocation.invoiceType = res.datas;
            });
            __this.$refs.locationCity.init({
                areaProvince: '',
                areaProvinceName: '',
                areaCity: '',
                areaCityName: '',
                areaRegion: '',
                areaRegionName: ''
            });
        },
        async getDetails() {
            this.loading = true;
            let __this = this;
            let [err, res] = await awaitWrap(consumer_web_company_detail({id: __this.$route.query.id}))
            if (err) {
                this.loading = false;
                return
            }
            let [configErr, configRes] = await awaitWrap(consumer_admin_company_query({companyId: __this.$route.query.id, bizType: 1}));
            if (configErr) {
                return;
            }
            this.loading = false
            let detail = res.datas;
            let companyDetail = configRes.datas ? configRes.datas.companyBillConfigVo : {
                "hotelSplit": true,
                templatePath: '',
                exportLevel: 1,
                "settlingDay": '',
                "invoiceType": {
                    "flight": "行程单",
                    "itinerary": "增值稅普票",
                    "flightRef": "增值稅普票",
                    "train": "火车票票根",
                    "hotel": "增值税普票",
                    "car": "增值稅普票",
                    "ship": "自取船票票根",
                    "door": "增值税普票",
                    "visa": "增值稅普票",
                    "extra": "增值税普票",
                    "serviceFee": "增值稅普票"
                },
            }
            detail = {...detail, ...companyDetail};

            const linkman = {name: '', phone: ''};
            detail.reserveLinkmanObj = detail.reserveLinkman ? JSON.parse(detail.reserveLinkman) : linkman;
            detail.contractLinkmanObj = detail.reserveLinkman ? JSON.parse(detail.contractLinkman) : linkman;
            detail.financeLinkmanObj = detail.reserveLinkman ? JSON.parse(detail.financeLinkman) : linkman;
            detail.urgencyLinkmanObj = detail.reserveLinkman ? JSON.parse(detail.urgencyLinkman) : linkman;
            if (!detail.agreement.hzCompanyArgee) {
                detail.agreement.hzCompanyArgee = {
                    enable: 0,
                    cardNumber: '',
                    groupType: 6,
                    groupTypeText: '华住',
                }
            }
            if (!detail.agreement.dcCompanyArgee) {
                detail.agreement.dcCompanyArgee = {
                    enable: 0,
                    cardNumber: '',
                    groupType: 9,
                    groupTypeText: '东呈',
                    rateplanList: [],
                    rateplanActiveList: [], // 选中的
                }
            } else {
                let rateplanActiveList = []
                if (detail.agreement.dcCompanyArgee.rateplanList) {
                    detail.agreement.dcCompanyArgee.rateplanList.forEach(value => {
                        let params = `${value.rateplanCode}-${value.rateplanLevel}`
                        rateplanActiveList.push(params)
                    })
                    detail.agreement.dcCompanyArgee.rateplanActiveList = rateplanActiveList
                } else {
                    detail.agreement.dcCompanyArgee.rateplanList = []
                    detail.agreement.dcCompanyArgee.rateplanActiveList = []
                }
            }
            if (!detail.agreement.ydCompanyArgee) {
                detail.agreement.ydCompanyArgee = {
                    enable: 0,
                    cardNumber: '',
                    ydCardNumberInfoList: [
                        {
                            ydCardNumber: '',
                            ydCardNumberName: ''
                        }
                    ],
                }
            } else {
                if (!detail.agreement.ydCompanyArgee.ydCardNumberInfoList) {
                    detail.agreement.ydCompanyArgee.ydCardNumberInfoList = [
                        {
                            ydCardNumber: '',
                            ydCardNumberName: ''
                        }
                    ]
                }
            }
            if (!detail.agreement.jjCompanyArgee) {
                detail.agreement.jjCompanyArgee = {
                    enable: 0,
                    cardNumber: '',
                    jjSignKey: ''
                }
            }

            //BUG--10589 【差旅后台】客户管理列表，已有客户开启协议配置，“折扣”默认保存为1，应该默认存为空
            if (detail.agreement.dcCompanyArgee != null && detail.agreement.dcCompanyArgee.discount == null) {
                detail.agreement.dcCompanyArgee.discount = undefined;
            }
            if (detail.agreement.hzCompanyArgee != null && detail.agreement.hzCompanyArgee.discount == null) {
                detail.agreement.hzCompanyArgee.discount = undefined;
            }
            if (detail.agreement.jjCompanyArgee != null && detail.agreement.jjCompanyArgee.discount == null) {
                detail.agreement.jjCompanyArgee.discount = undefined;
            }
            if (detail.agreement.ydCompanyArgee != null && detail.agreement.ydCompanyArgee.discount == null) {
                detail.agreement.ydCompanyArgee.discount = undefined;
            }

            try {
                let hzCompanyArgeeEnable = detail.agreement.hzCompanyArgee.enable
                let dcCompanyArgeeEnable = detail.agreement.dcCompanyArgee.enable
                let ydCompanyArgeeEnable = detail.agreement.ydCompanyArgee.enable
                let jjCompanyArgeeEnable = detail.agreement.jjCompanyArgee.enable
                if (hzCompanyArgeeEnable || dcCompanyArgeeEnable || ydCompanyArgeeEnable || jjCompanyArgeeEnable) {
                    this.serviceChargeData = true;
                    throw new Error("EndIterative");
                } else {
                    this.serviceChargeData = false;
                }
            }catch (e) {}

            detail.companyId = detail.id;
            // 初始化行业
            if (detail.industryId) {
                __this.allocation.industryType.forEach((item, index) => {
                    if (detail.industryId === item.id) {
                        detail.industryName = item.industryName;
                    }
                });
            }

            // 初始化城市
            __this.$refs.locationCity.init({
                areaProvince: Number(detail.areaProvince),
                areaProvinceName: detail.areaProvinceName,
                areaCity: Number(detail.areaCity),
                areaCityName: detail.areaCityName,
                areaRegion: Number(detail.areaRegion),
                areaRegionName: detail.areaRegionName
            });
            // 初始化城市 end

            // 服务费设置
            detail.pdProductSetList.forEach((item, index) => {
                detail.pdProductSetList[index].serviceAmount = item.serviceAmount ? item.serviceAmount + '' : '0';
                detail.pdProductSetList[index].robTicket = item.robTicket ? item.robTicket + '' : '0';
            });

            //价格运营设置，调整业务顺序以适应样式需求
            let hotel = detail.pdProductSetList.find(item  => {
                return item.businessType === 3
            });
            detail.pdProductSetList.forEach((value,index,array)=>{
                if(value.businessType === 3){
                    array.splice(index,1)
                }
            })
            detail.pdProductSetList.push(hotel);

            // 服务费设置 end
            /*if(__this.form.agreement.agreeInfoList[1].cardGrade == null){
                __this.form.agreement.agreeInfoList[1].cardGrade = 'RackRate';
            }*/

            //附件
            detail.accessoryUrl = detail.accessoryUrl ? JSON.parse(detail.accessoryUrl) : [{name:'',url:''}];
            this.accessoryUrl = detail.accessoryUrl

            __this.form = detail;

            // 获取赠送保险信息
            let [iErr, iRes] = await awaitWrap(consumer_web_company_getCompanyInsurance());
            if (iErr) {
                return;
            }
            let insuranceForm = {
                flight: [],
                train: [],
                hotel:[],
                car: [],
            }
            const insuranceList = iRes.datas.insuranceList;
            insuranceList.forEach(value=>{
                switch (value.businessType) {
                    case 1:
                        insuranceForm.flight.push(value);
                        break
                    case 2:
                        insuranceForm.train.push(value);
                        break
                    case 3:
                        insuranceForm.hotel.push(value);
                        break
                    case 4:
                        insuranceForm.car.push(value);
                        break
                }
            })
            this.insuranceForm = insuranceForm;
        },
        req_add_user(params) {
            const __this = this;
            __this.$refs.form.validate((valid) => {
                if (valid) {
                    __this.req_commit(__this.form);
                } else {
                    return false;
                }
            });
        },
        checkPhone(val) { // 验证手机号是否注册
            const __this = this;
            __this.$refs.form.validateField('phone', (msg) => {
                if (!msg) {
                    consumer_web_company_verifyPhone({phone: __this.form.phone}).then((res) => {
                        if (!res.datas) {
                            __this.$message({
                                type: 'warning',
                                message: '该手机号已注册',
                                onClose: function () {
                                    __this.form.phone = ''
                                }
                            });
                        }
                    });
                }
            });
        },
        selectIndustry(val) { // 选择所属行业 name 赋值
            const __this = this;
            __this.allocation.industryType.forEach((item, index) => {
                if (val === item.id) {
                    __this.form.industryName = item.industryName;
                }
                console.log(__this.form.industryName)
            });
        },
        selectBank(val, index) { // 选择开户网点
            const __this = this;
            __this.allocation.bankList.forEach((item, i) => {
                if (val == item.bankCode) {
                    __this.form.bankList[index].openingBankName = item.bankName;
                }
            });
        },

        format_number(str) {
            return str
                .split("")
                .map((item) => {
                    if (/[0-9\.]/.test(item)) {
                        return item;
                    } else {
                        return "";
                    }
                }).join().replace(/\,/g, "");
        },
        format_number2(str) {
            return str
                .split("")
                .map((item) => {
                    if (/[-0-9\.]/.test(item)) {
                        return item;
                    } else {
                        return "";
                    }
                }).join().replace(/\,/g, "");
        },
        input_number(val, key) {
            const __this = this;
            const form = __this.form;
            const keyArr = key.split(".");
            if (keyArr.length === 2) {
                form[keyArr[0]][keyArr[1]] = __this.format_number(val);
            } else if (keyArr.length === 3) {
                form[keyArr[0]][keyArr[1]][keyArr[2]] = __this.format_number(val);
            }
        },
        input_number2(val, key) {
            const __this = this;
            const form = __this.form;
            const keyArr = key.split(".");
            if (keyArr.length === 2) {
                form[keyArr[0]][keyArr[1]] = __this.format_number2(val);
            } else if (keyArr.length === 3) {
                form[keyArr[0]][keyArr[1]][keyArr[2]] = __this.format_number2(val);
            }
        },
        // 上传公司logo
        change_upload(file) {
            const __this = this;
            __this.$refs.uploadLogo.clearFiles();
            const myUpLoad = new OssClient();
            const isJPG_PNG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/jpg' || file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 < 10240;
            if (!isJPG_PNG) {
                __this.$message.error('上传图片格式有误!');
                return false;
            }
            if (!isLt10M) {
                __this.$message.error('公司LOGO大小不能超过 10MB!');
                return false;
            }
            __this.logoLoading = true;
            myUpLoad.multipartUpload({
                file: file.raw,
                progress: function* (p) {
                },
            }).then((data) => {
                __this.logoLoading = false;
                if (isJPG_PNG && isLt10M) {
                    __this.form.companyLogo = data.url;
                }
            }).catch((error) => {
                __this.logoLoading = false;
                __this.$message.error("上传失败");
            });
        },
        deleteLogo() {
            const __this = this;
            __this.form.companyLogo = '';
            __this.$refs.uploadLogo.clearFiles();
        },
        // 上传公司 end

        // 上传营业执照
        business_license_upload(file) {
            const __this = this;
            __this.$refs.uploadBusinessLicense.clearFiles();
            const myUpLoad = new OssClient();
            const isJPG_PNG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/jpg' || file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 < 10240;
            if (!isJPG_PNG) {
                __this.$message.error('上传图片格式有误!');
                return false;
            }
            if (!isLt10M) {
                __this.$message.error('营业执照大小不能超过 10MB!');
                return false;
            }
            __this.businessLicenseNumLoading = true;
            myUpLoad.multipartUpload({
                file: file.raw,
                progress: function* (p) {
                },
            }).then((data) => {
                __this.businessLicenseNumLoading = false;
                if (isJPG_PNG && isLt10M) {
                    __this.form.businessLicensePic = data.url;
                }
            }).catch((error) => {
                __this.businessLicenseNumLoading = false;
                __this.$message.error("上传失败");
            });
        },
        deletePic() {
            const __this = this;
            __this.form.businessLicensePic = '';
            __this.$refs.uploadBusinessLicense.clearFiles();
        },
        // 上传营业执照 end

        // 上传附件
        change_upload_index(index) {
            this.uploadIndex = index;
        },
        change_attached(file) {
            const size = file.size / 1024;
            if (size > 10240) {
                this.$message.warning("附件大小不能超过 10MB!");
                this.$refs['upload' + this.uploadIndex][0].clearFiles();
            } else {
                const myUpLoad = new OssClient();
                const index = this.uploadIndex;
                this.accessoryUrl[index].name = file.name;
                myUpLoad.multipartUpload({
                    file: file.raw,
                    progress: function* (p) {
                    },
                })
                    .then((data) => {
                        this.accessoryUrl[index].name = file.name;
                        this.accessoryUrl[index].url = data.url;
                    }).catch(() => {
                    this.$message.error("上传失败");
                    this.accessoryUrl[index].name = '';
                    this.accessoryUrl[index].url = '';
                });
            }
        },
        add_attached() {
            if (this.accessoryUrl.length < 5)
                this.accessoryUrl.push({name: "", url: ""});
        },
        delete_attached(index) {
            this.accessoryUrl[index].name = "";
            this.accessoryUrl[index].url = "";
            this.form.accessoryUrl = this.accessoryUrl.map((item) => item.url).join();

        },
        delete_item(index) {
            if (this.accessoryUrl.length > 1) {
                this.accessoryUrl.splice(index, 1);
                this.form.accessoryUrl = this.accessoryUrl
                    .map((item) => item.url)
                    .join();
            } else {
                this.accessoryUrl[0].name = '';
                this.accessoryUrl[0].url = '';
            }
        },
        downAccessory(item) { // 点击附件下载
            const eleLink = document.createElement('a');
            eleLink.href = item.url;
            eleLink.download = item.name;
            document.body.appendChild(eleLink);
            eleLink.click();
            document.body.removeChild(eleLink);
        },
        // 上传附件 end

        select_manager(id) {
            const __this = this;
            __this.allocation.manager.forEach((item) => {
                if (id === item.userId) {
                    __this.form.customerManagerName = item.staffName;
                }
            });
        },
        add_bank_user() {
            const user_obj = {
                bankUserName: '', // 对公账号 -- 用户名
                bankAccount: '', // 银行账号
                openingBank: '', // 开户行
                openingBankName: '', // 开户行
                provinceId: '',
                provinceName: '',
                cityId: '',
                cityName: '',
                openingBankBranch: '', // 开户网点
            };
            this.form.bankList.push(user_obj);
        },
        remove_bank_user(index) {
            this.form.bankList.splice(index, 1);
        },
        async commit() {
            const __this = this;

            // __this.form.userId = __this.$route.query.id;
            // 处理附件
            __this.form.accessoryUrl = JSON.stringify(__this.accessoryUrl);

            if (!__this.form.companyLogo) {
                __this.$message.warning('请上传公司LOGO');
                return;
            }
            if (!__this.form.businessLicensePic) {
                __this.$message.warning('请上传营业执照');
                return;
            }

            //协议卡号判断
            /*let itemData = false;
            const agreement = __this.form.agreement
            if ((agreement.hzCompanyArgee.cardNumber === '' || agreement.hzCompanyArgee.cardNumber === null) && agreement.hzCompanyArgee.enable === 1) {
                this.inputBlur(0);
                itemData = true;
            }
            if ((agreement.dcCompanyArgee.cardNumber === '' || agreement.dcCompanyArgee.cardNumber === null) && agreement.dcCompanyArgee.enable === 1) {
                this.inputBlur(1);
                itemData = true;
            }
            if (itemData) {
                return
            }*/

            const params = __this.form;
            this.validateCompany();
            let res = await Promise.all([__this._checkForm(), __this._checkForm2()]);
            if (res[0] && res[1]) {
                __this.req_commit(params);
            } else {
                __this.locateToErr();
            }


            /*__this.$refs.form.validate((valid) => {
                if (valid) {
                    __this.req_commit(params);
                } else {
                    this.locateToErr();
                    return false;
                }
            });*/
        },
        _checkForm() {
            return new Promise(resolve => {
                this.$refs.form.validate((valid)=>{
                    resolve(valid)
                })
            })
        },
        _checkForm2() {
            return new Promise(resolve => {
                this.$refs.form2.validate((valid)=>{
                    resolve(valid)
                })
            })
        },
        validateCompany() {
            this.$refs.form.validateField(["agreement.jjCompanyArgee.cardNumber"], (valid, vv) => {
                if (valid) {
                    this.companyArgeeActiveName = '4'
                } else {
                    return
                }
            });
            this.$refs.form.validateField(["agreement.jjCompanyArgee.jjSignKey"], (valid, vv) => {
                if (valid) {
                    this.companyArgeeActiveName = '4'
                } else {
                    return
                }
            });
            this.$refs.form.validateField(["agreement.ydCompanyArgee.cardNumber"], (valid, vv) => {
                if (valid) {
                    this.companyArgeeActiveName = '3'
                } else {
                    return
                }
            });
            this.$refs.form.validateField(["agreement.dcCompanyArgee.cardNumber"], (valid, vv) => {
                if (valid) {
                    this.companyArgeeActiveName = '2'
                } else {
                    return
                }
            });
            this.$refs.form.validateField(["agreement.dcCompanyArgee.cardGrade"], (valid, vv) => {
                if (valid) {
                    this.companyArgeeActiveName = '2'
                } else {
                    return
                }
            });
            this.$refs.form.validateField(["agreement.hzCompanyArgee.cardNumber"], (valid, vv) => {
                if (valid) {
                    this.companyArgeeActiveName = '1'
                } else {
                    return
                }
            });
        },
        locateToErr() {
            setTimeout(() => {
                const errorDiv = document.getElementsByClassName('is-error');
                errorDiv[0].scrollIntoView({behavior: "smooth"})
            }, 0)
        },
        inputData(data, off) {
            if (off == 1) {
                if (data == '' || Number(data) <= 0) {
                    this.form.agreement.serviceAmount = '';
                }
            } else if (off == 2) {
                if (Number(this.form.pdProductSetList[3].agreementServiceAmountLimit) <= 0) {
                    this.form.pdProductSetList[3].agreementServiceAmountLimit = '';
                }
                if (Number(this.form.pdProductSetList[3].offlineServiceAmountLimit) <= 0) {
                    this.form.pdProductSetList[3].offlineServiceAmountLimit = '';
                }
            }
        },
        //提交
        async req_commit(params) {
            const __this = this;
            this.fullscreenLoading = true;
            // 联系人
            const contractLinkman = __this.form.contractLinkmanObj;
            const financeLinkman = __this.form.financeLinkmanObj;
            const reserveLinkman = __this.form.reserveLinkmanObj;
            const urgencyLinkman = __this.form.urgencyLinkmanObj;
            __this.form.contractLinkman = JSON.stringify(contractLinkman);
            __this.form.financeLinkman = JSON.stringify(financeLinkman);
            __this.form.reserveLinkman = JSON.stringify(reserveLinkman);
            __this.form.urgencyLinkman = JSON.stringify(urgencyLinkman);
            let form = JSON.parse(JSON.stringify(params))
            let ydCardNumberInfoList = []
            form.agreement.ydCompanyArgee.ydCardNumberInfoList.forEach(value => {
                if (value.ydCardNumber || value.ydCardNumberName) {
                    ydCardNumberInfoList.push(value)
                }
            })
            form.agreement.ydCompanyArgee.ydCardNumberInfoList = ydCardNumberInfoList
            form.pdProductSetList.forEach((item,index) => {
                if (item.businessType == 1) {
                    item.enterpriseConfigurationSet = item.airPriceSet === 2 ? 0 : 1;
                }
            });

            let saveApi = this.type === 'edit' ? consumer_web_company_updateCustomer(form) : consumer_web_company_addCustomer(form);
            let [err, res] = await awaitWrap(saveApi);
            if (err) {
                this.fullscreenLoading = false;
                __this.form.contractLinkman = contractLinkman;
                __this.form.financeLinkman = financeLinkman;
                __this.form.reserveLinkman = reserveLinkman;
                __this.form.urgencyLinkman = urgencyLinkman;
                return;
            }
            let insuranceResult = await this.saveInsurance(res.datas);
            this.fullscreenLoading = false;
            if (!insuranceResult) {
                __this.form.contractLinkman = contractLinkman;
                __this.form.financeLinkman = financeLinkman;
                __this.form.reserveLinkman = reserveLinkman;
                __this.form.urgencyLinkman = urgencyLinkman;
                return
            }
            // 提交结算设置
            let matchSetForm = {
                "companyName": __this.form.companyName || '',
                "companyId": __this.form.companyId || res.datas,
                companyBillConfigVo: {
                    'templatePath': __this.form.templatePath,
                    'exportLevel': __this.form.exportLevel,
                    "hotelSplit": __this.form.hotelSplit,
                    "settlingDay": __this.form.settlingDay,
                    "invoiceType": __this.form.invoiceType,
                },
                companyExpendVo: {
                    'specialFocus': __this.form.specialFocus,   // 是否开通特别关注功能", notes = "true-开通，false-关闭
                    'hotelTogether': __this.form.hotelTogether,   // 是否开通酒店合作功能", notes = "true-开通，false-关闭
                },
            };
            let [configErr, configRes] = await awaitWrap(consumer_admin_company_save(matchSetForm));
            if (configErr) {
                return;
            }


            __this.$message({
                message: '添加成功',
                type: 'success',
                onClose: function () {
                    // 重置表单
                    __this.$refs['form'].resetFields();
                    __this.$refs.locationCity.init({
                        areaProvince: '',
                        areaProvinceName: '',
                        areaCity: '',
                        areaCityName: '',
                        areaRegion: '',
                        areaRegionName: ''
                    });
                    history_tag_api.remove_tag_by_route({
                        route: __this.$route,
                    });
                    __this.$router.push({
                        name: 'admin-users-management-list'
                    })
                }
            });
        },

        //保存到草稿箱
        async save_draft() {
            const __this = this;
            // 联系人
            const contractLinkman = __this.form.contractLinkmanObj;
            const financeLinkman = __this.form.financeLinkmanObj;
            const reserveLinkman = __this.form.reserveLinkmanObj;
            const urgencyLinkman = __this.form.urgencyLinkmanObj;
            __this.form.contractLinkman = JSON.stringify(contractLinkman);
            __this.form.financeLinkman = JSON.stringify(financeLinkman);
            __this.form.reserveLinkman = JSON.stringify(reserveLinkman);
            __this.form.urgencyLinkman = JSON.stringify(urgencyLinkman);
            // 处理附件
            __this.form.accessoryUrl = JSON.stringify(__this.accessoryUrl);

            let form = JSON.parse(JSON.stringify(__this.form))
            let ydCardNumberInfoList = []
            form.agreement.ydCompanyArgee.ydCardNumberInfoList.forEach(value => {
                if (value.ydCardNumber || value.ydCardNumberName) {
                    ydCardNumberInfoList.push(value)
                }
            })
            form.agreement.ydCompanyArgee.ydCardNumberInfoList = ydCardNumberInfoList
            this.loading = true;
            let [err, res] = await awaitWrap(consumer_web_company_addCustomerDrafts(form));
            if (err) {
                this.loading = false;
                return
            }
            let insuranceResult = await this.saveInsurance(res.datas);
            this.loading = false;
            if (!insuranceResult) {
                return
            }
            // 提交结算设置
            let matchSetForm = {
                "companyName": form.companyName || '',
                "companyId": form.companyId || '',
                companyBillConfigVo: {
                    'templatePath': form.templatePath,
                    'exportLevel': form.exportLevel,
                    "hotelSplit": form.hotelSplit,
                    "settlingDay": form.settlingDay,
                    "invoiceType": form.invoiceType,
                },
                companyExpendVo: {
                    'specialFocus': form.specialFocus,   // 是否开通特别关注功能", notes = "true-开通，false-关闭
                    'hotelTogether': form.hotelTogether,   // 是否开通酒店合作功能", notes = "true-开通，false-关闭
                },
            };
            let [configErr, configRes] = await awaitWrap(consumer_admin_company_save(matchSetForm));
            if (configErr) {
                return;
            }
            __this.$message({
                message: '保存成功',
                type: 'success',
                onClose:  () => {
                    // 重置表单
                    __this.$refs['form'].resetFields();
                    __this.$refs.locationCity.init({
                        areaProvince: '',
                        areaProvinceName: '',
                        areaCity: '',
                        areaCityName: '',
                        areaRegion: '',
                        areaRegionName: ''
                    });
                    __this.loading = false;
                    __this.$router.push({
                        name: 'admin-users-management-list',
                        query: {
                            showType: '-1'
                        }
                    })
                }
            })
        },

        async saveInsurance(companyId) {
            console.log('companyId:', companyId);
            // 制作入参
            let params = {
                companyId,
                insurances: []
            }
            for (let key in this.insuranceForm) {
                this.insuranceForm[key].forEach(value=>{
                    params.insurances.push(value)
                })
            }
            return new Promise(resolve => {
                consumer_web_company_updateCompanyInsurance(params).then(res=>{
                    resolve(true)
                }).catch(e=>{
                    resolve(false)
                })
            })
        },

        onBack() {
            this.$router.push({
                name: 'admin-users-management-list',
                query: {
                    showType: '-1'
                }
            })
        },

        cardNumber(rule, value, callback) {
            callback();
        },
        changeRrateplanList(val) {
            console.log(val)
            let rateplanList = []
            let rateplanOptions = this.rateplanOptions
            rateplanOptions.forEach(value => {
                value.disabled = false
            })
            val.forEach(value => {
                rateplanOptions.forEach(value1 => {
                    let valueArr = value.split('-')
                    if (valueArr[0] === value1.code && valueArr[1] !== value1.level) {
                        value1.disabled = true
                    }
                    if (value1.value === value) {
                        let params = {
                            rateplanCode: value1.code,
                            rateplanLevel: value1.level,
                            rateplanName: value1.rateplanName
                        }
                        rateplanList.push(params)
                    }
                })
            })
            this.rateplanOptions = rateplanOptions
            this.form.agreement.dcCompanyArgee.rateplanList = rateplanList
        },
        addYdCardNumberInfo() {
            this.form.agreement.ydCompanyArgee.ydCardNumberInfoList.push({
                ydCardNumber: '',
                ydCardNumberName: ''
            })
        },
        delYdCardNumberInfo(index) {
            this.form.agreement.ydCompanyArgee.ydCardNumberInfoList.splice(index, 1)
        },
        changePayConfigurationSet(index, val) {
            this.form.pdProductSetList[index].enterpriseConfigurationType = ''
            this.form.pdProductSetList[index].enterpriseConfigurationAmount = ''
            this.$nextTick(() => {
                this.$refs['form'].clearValidate('pdProductSetList.' + index + '.enterpriseConfigurationType')
                this.$refs['form'].clearValidate('pdProductSetList.' + index + '.enterpriseConfigurationAmount')
            })
        },
        changeConfigurationType(index, val) {
            this.$refs['form'].validateField('pdProductSetList.' + index + '.enterpriseConfigurationType')
        },
        changePaySet(index, val) {
            this.$refs['form'].validateField('pdProductSetList.' + index + '.enterpriseConfigurationAmount')
        },

        setFlightInsuranceLabel(val) {
            let text = '';
            switch (val.zoneType) {
                case 0:
                    text = '国内保险产品'
                    break
                case 1:
                    text = '国际亚洲地区内保险产品'
                    break
                case 2:
                    text = '国际亚洲地区外保险产品'
                    break
            }
            return text;
        },
        getInsuranceProList() {
            let insuranceOptions = [];
            consumer_web_company_getInsuranceProductList({businessType: 1}).then(res=>{
                insuranceOptions = res.datas.insuranceProductVoList;
                insuranceOptions.forEach(value=>{
                    value.label = `${value.insuredSupplierName} ${value.packageName}  ¥${value.insuredFee}`
                })
                /*insuranceOptions.unshift({
                    label: '不赠送',
                    productCode: 0,
                })*/
                this.insuranceOptions = insuranceOptions;
            }).catch(e=>{
                /*insuranceOptions.unshift({
                    label: '不赠送',
                    productCode: 0,
                })*/
                this.insuranceOptions = insuranceOptions;
            })
            let trainInsuranceOptions = [];
            consumer_web_company_getInsuranceProductList({businessType: 2}).then(res=>{
                trainInsuranceOptions = res.datas.insuranceProductVoList;
                trainInsuranceOptions.forEach(value=>{
                    value.label = `${value.insuredSupplierName} ${value.packageName}  ¥${value.insuredFee}`
                })
                /*insuranceOptions.unshift({
                    label: '不赠送',
                    productCode: 0,
                })*/
                this.trainInsuranceOptions = trainInsuranceOptions;
            }).catch(e=>{
                /*insuranceOptions.unshift({
                    label: '不赠送',
                    productCode: 0,
                })*/
                this.trainInsuranceOptions = trainInsuranceOptions;
            })
        },
        clearInsurance(businessType, item, index) {
            item.insuranceInvoiceType = 0;
            item.freeProduct = 0;
            switch (businessType) {
                case 1:
                    this.$refs.form2.clearValidate('flight.' + index + '.productCode');
                    break
                case 2:
                    this.$refs.form2.clearValidate('train.' + index + '.productCode');
                    break
            }
        },
        changeFreeProduct(businessType, item, index, val) {
            if (val === 0) {
                switch (businessType) {
                    case 1:
                        this.$refs.form2.clearValidate('flight.' + index + '.productCode');
                        break
                    case 2:
                        this.$refs.form2.clearValidate('train.' + index + '.productCode');
                        break
                }

            }
        },
    },
    async activated() {
        this.getInsuranceProList();
        this.req_province_city_data(1, {});
        this.init();

        // consumer_web_company_getPnrSetType().then(res => {
        //     this.pnrSetOptions = res.datas.result;
        // });

        let [err, res] = await awaitWrap(Promise.all([consumer_hotel_search_getDCRateplanList(), consumer_web_company_getDCCardLevel()]))
        if (err) {
            return
        }

        const DCCardLevel = res[1].datas.result;
        const DCRateplanList = res[0].datas ? res[0].datas.rateplanList : [];
        let rateplanOptions = []
        DCRateplanList.forEach(value => {
            DCCardLevel.forEach(value1 => {
                let params = {
                    value: `${value.rateplanCode}-${value1.value}`,
                    level: value1.value,
                    code: value.rateplanCode,
                    label: `${value.rateplanName}-${value1.text}`,
                    rateplanName: value.rateplanName,
                }
                rateplanOptions.push(params)
            })
        })

        this.rateplanOptions = rateplanOptions
        this.companyArgeeActiveName = '1';
        this.clickReset();

        this.type = this.$route.query.type ? this.$route.query.type : 'add';
        if (this.type === 'edit') {
            await this.getDetails();
        }
    },
    watch: {
        locationCity: {
            handler(val) {
                const form = this.form;
                form.areaProvince = val.areaProvince;
                form.areaProvinceName = val.areaProvinceName;
                form.areaCity = val.areaCity;
                form.areaCityName = val.areaCityName;
                form.areaRegion = val.areaRegion;
                form.areaRegionName = val.areaRegionName;
            },
            deep: true,
        },
    },
    computed: {
        myLayout() {
            if (this.layout) {
                return this.layout.replace(/\s/g, "").split(",");
            } else {
                return ["province", "city", "area"];
            }
        },
    },
    filters: {
        format_type(val) {
            switch (Number(val)) {
                case 2: // 1
                    return "火车票";
                    break;
                case 3: // 2
                    return "酒店";
                    break;
                case 1: // 3
                    return "机票";
                    break;
                case 4: // 4
                    return "用车";
                    break;
            }
        },
    }
};

import Q from 'q';
import CostAttributionList from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/cost-attribution-list-for-platform/index'
import BelongToProjectList from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/belong-to-project-list-for-platform/index'
import consumer_evection_queryOaInfo from '@/lib/data-service/haolv-default/consumer_evection_queryOaInfo'
import consumer_admin_flight_reportConfiguration from '@/lib/data-service/haolv-default/consumer_admin_flight_reportConfiguration'
import {reportConfigurationsData} from './lib/mockData/index'

export default {
    props: {},
    data() {
        return {
            centerCodeBox: '',
            projectName: '',
            projectCode: '',
            companyIdBox: 0,
            companyIdData: 0,
            businessTypeData: 1,
            belongToSwitch: false,  // 用于控制归属项目是否展示
            autocompleteChange: false,  // 标识状态--选择检索后的OA单号标为true,第一次修改或清空OA单号框时标为false


            AttributionSwitch: true,
            immutableSwitch: false,  // 控制除OA单号外的disabled
            autocompleteDisabled: false,  // 控制OA单号的disabled
            // 组件类型--回显、编辑
            typeBox: null,
            // 回显数据
            displayType: [],
            dataBox: null,
            loading: false,
            reportConfigurations: [],
            ruleForm: {
                name: '',
            },

            hideFieldNameBox: [],
            OAOrderList: [],
        }
    },
    components: {CostAttributionList,BelongToProjectList},
    methods: {
        handleInput() {
            this.immutableSwitch = false;
            if (this.autocompleteChange) {
                this.autocompleteChange = false;
                this.centerCodeBox = '';
                this.belongToSwitch = false;
                this.reportConfigurations.forEach((item) => {
                    if (item.type != '5') {
                        item.parameterValue = '';
                        if (item.type == '3' || item.type == '4') {
                            item.deptName = '';
                            item.feeAffiliationType = null;
                        }
                    }
                });
                this.$emit('handleSelectDelete');
            }
            this.$refs.ruleForm.validate();
        },
        // 选择检索出来的OA单号信息，回填+只读其他选项
        handleSelect(value) {
            this.immutableSwitch = true;
            this.autocompleteChange = true;
            this.reportConfigurations.forEach((item,index) => {
                if (item.type != '5') {
                    value.reportConfigurations.forEach((Iitem,Iindex) => {
                        if (Iitem.parameter == item.parameter) {
                            item.parameterValue = Iitem.parameterValue;
                            if (item.type == '3' || item.type == '4') {
                                item.deptName = Iitem.parameterValue;
                                item.parameterValue = Iitem.parameterId;
                                this.centerCodeBox = Iitem.parameterCode;
                                if (Iitem.parameterCode.indexOf('E') != -1) {
                                    this.belongToSwitch = true;
                                    this.$nextTick(() => {
                                        this.$refs.BelongToProjectList.init({companyId: this.companyIdData});
                                    })
                                }
                                value.reportConfigurations.forEach((VItem,VIndex) => {
                                    if (VItem.parameter == 'attributionType') {
                                        item.feeAffiliationType = VItem.feeAffiliationType;
                                    }
                                });
                            }
                        }
                    });
                } else {
                    item.parameterValue = value.partnerOaNo;
                }
            });
            this.$emit('handleSelectCallback', {name: value.initialName,userId :value.initialUserId});
            this.$refs.ruleForm.validate();
        },
        // 检索OA单号信息
        async querySearch(queryString, cb) {
            if (queryString == '') {
                cb([]);
            } else {
                let dataBox = {
                    businessType: this.businessTypeData,
                    callChannel: 2,
                    companyId: this.companyIdData,
                    oaNo: queryString,
                    startDate: '',
                    userId: '',
                };
                let res = await consumer_evection_queryOaInfo(dataBox);
                let restaurants = res.datas;
                restaurants.forEach((item,index) => {
                    item.value = item.oaDesc;
                    item.address = item.partnerOaNo;
                });
                this.OAOrderList = restaurants;
                cb(restaurants);
            }
        },
        formHidden(val){
            let test = false;
            if (val.type == '3' || val.type == '4') {
                if (this.AttributionSwitch) {
                    test = false;
                } else {
                    test = true;
                }
            }

            return test
        },
        hideAttribution(){
            this.AttributionSwitch = false;
        },
        showAttribution(){
            this.AttributionSwitch = true;
        },
        getData(){
            this.toSelectType({},true);
            this.toSelectTypeBelongTo();
            let data = {
                checkForm: null,
                list: []
            };
            let checkFormAll = [];
            let feeAffiliationTypeBox = null;
            let reportConfigurationsBox = this.reportConfigurations;
            if (this.AttributionSwitch == false) {
                reportConfigurationsBox.forEach((item,index) => {
                    if (item.parameter == "attributionType" || item.parameter == "feeAffiliationId") {
                        item.switchForm = false;
                    }
                });
            }

            reportConfigurationsBox.forEach((item,index) => {
                checkFormAll.push(item.switchForm);
                if (item.parameter == "feeAffiliationId") {
                    feeAffiliationTypeBox = item.feeAffiliationType;
                }
                if (item.parameter == "attributionType") {
                    checkFormAll[index] = false;
                }
                data.list.push({
                    parameter: item.parameter,
                    parameterValue: item.parameterValue,
                    parameterForm: !item.switchForm,
                    parameterText: item.parameterText,
                });
            });
            data.list.forEach((item,index) => {
                if (item.parameter == "attributionType") {
                    item.parameterValue = feeAffiliationTypeBox == 1 ? 2 : 1;
                }
            });

            // 增加归属项目数据
            if (this.belongToSwitch) {
                if (this.projectName) {
                    checkFormAll.push(false);
                    data.list.push(
                        {
                            parameter: "GuiShuXiangMu",
                            parameterForm: true,
                            parameterText: this.projectName,
                            parameterValue :this.projectCode,
                        }
                    );
                } else {
                    checkFormAll.push(true);
                    data.list.push(
                        {
                            parameter: "GuiShuXiangMu",
                            parameterForm: false,
                            parameterText: this.projectName,
                            parameterValue :this.projectCode,
                        }
                    );
                }
            }


            if (checkFormAll.indexOf(true) == -1) {
                // 核验通过
                data.checkForm = true;
            } else {
                // 核验不通过
                data.checkForm = false;
            }

            return data
        },
        changeSelect(){
            this.reportConfigurations.forEach((item,index) => {
                if (item.isMust == 0) {
                    item.switchForm = false;
                } else {
                    if (item.parameterValue == null || item.parameterValue == '' || item.parameterValue.length == 0) {
                        item.switchForm = true;
                    } else {
                        item.switchForm = false;
                    }
                }
            });
        },
        toSelectTypeBelongTo(item){
            if (this.belongToSwitch) {
                this.$refs.ruleForm.validateField('projectName', (msg) => {

                });
            }
        },
        toSelectType(data,type){
            if (type == true || this.immutableSwitch == true) {

            } else {
                if (data && data.centerCode && data.centerCode.indexOf('E') != -1) {
                    this.centerCodeBox = data.centerCode;
                    this.belongToSwitch = true;
                    setTimeout(() => {
                        this.$refs.BelongToProjectList.init({companyId: this.companyIdData});
                    },100);
                } else {
                    this.belongToSwitch = false;
                    this.centerCodeBox = '';
                }
            }

            this.reportConfigurations.forEach((item,index) => {
                if (item.isMust == 0) {
                    item.switchForm = false;
                } else {
                    if (item.parameterValue == null || item.parameterValue == '' || item.parameterValue.length == 0) {
                        item.switchForm = true;
                    } else {
                        item.switchForm = false;
                    }
                }
            });
        },
        validatorBelongToProjectList(rule, value, callback) {
            if (this.belongToSwitch == false) {
                callback();
                return;
            }
            if (this.projectName) {
                callback();
            } else {
                callback(new Error("请选择归属项目"));
            }
        },
        validateNewUerCard(rule, value, callback) {
            this.reportConfigurations.forEach((item,index) => {
                if (item.isMust == 0) {
                    item.switchForm = false;
                } else {
                    if (item.parameterValue == null || item.parameterValue == '' || item.parameterValue.length == 0) {
                        item.switchForm = true;
                    } else {
                        item.switchForm = false;
                    }
                }
            });
            callback();
        },
        init_CostAttributionList(companyId, type){
            const params = {
                companyId: companyId,
                showDept: type == 3
            };
            this.$refs.CostAttributionList.forEach((item,index) => {
                console.log(item);
                item.init(params);
            })
        },
        init (val) {
            this.loading = true;
            this.immutableSwitch = val.immutableSwitch ? val.immutableSwitch : false;
            this.autocompleteDisabled = val.immutableSwitch ? val.immutableSwitch : false;
            let mock_data = val.mock_data ? val.mock_data : null;
            let display_list = val.displayList ? val.displayList : [];
            let show_attribution = val.showAttribution ? val.showAttribution : false;
            let type = val.type ? val.type : null;
            this.centerCodeBox = '';
            this.belongToSwitch = false;
            this.companyIdData = val.companyId;
            this.businessTypeData = val.businessType;
            let data = {
                companyId: val.companyId,
                // 业务类型：1机票、2火车票、3酒店、4汽车
                businessType: val.businessType,
            };
            let SwitchCostAttributionList = true;
            let mockFun = function() {
                return new Promise((resolve, reject) => {
                    resolve(reportConfigurationsData)
                })
            }
            let getDynamicFormFun = mock_data.enable ? mockFun : consumer_admin_flight_reportConfiguration;
            return new Promise(resolve => {
                getDynamicFormFun(data).then(res => {
                    Q.when().then(() => {
                        res.datas.reportConfigurations.sort((a,b) => {
                            return (a.serial || 2) - (b.serial || 2);
                        });
                        this.hideFieldNameBox = [];
                        res.datas.reportConfigurations.forEach((item,index) => {
                            item.isMust = item.isMust != 0 && item.isMust != 1 ? 1 : item.isMust;
                            item.deptName = '';
                            item.parameterValue = '';
                            item.feeAffiliationType = 0;
                            item.switchForm = false;

                            if (item.isNotShown == 1){
                                this.hideFieldNameBox.push(item.shownName);
                            }
                        });
                        return Q.resolve();
                    })
                        .then(() => {
                            this.reportConfigurations = res.datas.reportConfigurations;
                            let dataBoxOne = null;
                            if (type == 'immutable') {
                                this.immutableSwitch = true;
                                this.autocompleteDisabled = true;
                            }
                            if (type == 'display' || type == 'immutable') {
                                this.reportConfigurations.forEach((RItem,TIndex) => {
                                    display_list.forEach((DItem,DIndex) => {
                                        if (RItem.parameter == DItem.parameter) {
                                            RItem.parameterValue = DItem.parameterValue;
                                            // companyCoster要转成数字
                                            if (DItem.parameter == 'companyCoster') {
                                                RItem.parameterValue = parseInt(DItem.parameterValue);
                                            }
                                            if (DItem.parameter == "attributionType") {
                                                dataBoxOne = parseInt(DItem.parameterValue);
                                            }
                                            if (DItem.parameter == 'feeAffiliationId') {
                                                if (DItem.parameterValue == '-1') {
                                                    RItem.deptName = '各自所在的部门';
                                                    RItem.parameterValue = -1;
                                                } else {
                                                    RItem.deptName = DItem.parameterValue;
                                                    RItem.parameterValue = DItem.parameterId;
                                                }

                                            }
                                        }
                                    });
                                });

                                this.reportConfigurations.forEach((item,index) => {
                                    if (item.parameter == "feeAffiliationId") {
                                        item.feeAffiliationType = dataBoxOne == 1 ? 2 : 1;
                                    }
                                });
                            }
                            if (show_attribution) {
                                this.showAttribution();
                            } else {
                                this.hideAttribution();
                            }
                            this.loading = false;
                            return Q.resolve();
                        })
                        .then(() => {
                            this.reportConfigurations.forEach((item,index) => {
                                if (item.type == '3' || item.type == '4') {
                                    if (SwitchCostAttributionList) {
                                        this.$nextTick(() => {
                                            this.init_CostAttributionList(val.companyId, item.type);
                                        });
                                        SwitchCostAttributionList = false;
                                    }
                                }
                            });
                            this.loading = false;

                            resolve(this.hideFieldNameBox);
                        })
                });
            })


            /*if (mock_data.enable) {
                Q.when().then(() => {
                    let reportConfigurationsData = [
                        {
                            parameterText: '出差单号',
                            parameter: 'ChuChaiDanHao',
                            type:'1',
                            opetions: null,
                        },
                        {
                            parameterText: '业务类型',
                            parameter: 'YeWuLeiXing',
                            type:'2',
                            opetions: [
                                {
                                    code: '0',
                                    text: '因公',
                                },
                                {
                                    code: '1',
                                    text: '因私',
                                }
                            ],
                        },
                        {
                            parameterText: '成本中心',
                            parameter: 'ChengBenZhongXin',
                            type:'3',
                            opetions: null,
                        }
                    ];
                    reportConfigurationsData.forEach((item,index) => {
                        item.deptName = '';
                        item.parameterValue = '';
                        item.feeAffiliationType = 0;
                        item.switchForm = false;
                    });
                    return Q.resolve(reportConfigurationsData);
                })
                  .then((reportConfigurationsData) => {
                      this.reportConfigurations = reportConfigurationsData;
                      return Q.resolve();
                  })
                  .then(() => {
                      if (type == 'immutable') {
                          this.immutableSwitch = true;
                      }
                      this.reportConfigurations.forEach((item,index) => {
                          if (item.type == '3' || item.type == '4') {
                              if (SwitchCostAttributionList) {
                                  this.$nextTick(() => {
                                      this.init_CostAttributionList(val.companyId, item.type);
                                  });
                                  SwitchCostAttributionList = false;
                              }
                          }
                      });
                      this.loading = false;
                  })
            } else {

            }*/
        },
        clearOa() {
            this.reportConfigurations.forEach(value => {
                if (value.type == '5') {
                    value.parameterValue = '';
                }
            })
        },
    },
    watch: {},
    created() {

    },
    mounted() {

    },
    activated() {

    },
    deactivated() {

    },
}

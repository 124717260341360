import Q from 'q';
import _ from 'underscore';

import TravelerMultipleSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/1.0.0/index.vue';

export default {
    components: {
        TravelerMultipleSelector,
    },
    data() {
        return {
            // 使用模拟数据时，将enableAutoInit设置为false
            // enableAutoInit: false,
            // 使用接口返回数据时，将enableAutoInit设置为true
            enableAutoInit: true,

            selected_id_list: [],
            // selected_id_list: [
            //     `S479`,
            //     `S488`,
            //     `C66`,
            // ],
        };
    },
    methods: {
        __btn_show_click() {
            const __this = this;
            __this.$refs.aTravelerMultipleSelector.show();
        },

        __btn_get_value_click() {
            const __this = this;
            console.log(__this.selected_id_list);
        },

        __btn_set_value_click() {
            const __this = this;
            __this.selected_id_list = [
                `S479`,
                `S488`,
                `C66`,
            ];

            const staffId_list = [
                479,//陈宇枫
                488,//韦一笑
                66,//几位人气
            ];
            __this.$refs.aTravelerMultipleSelector.get_all_entity_list().then(function ({all_entity_list}) {
                const entity_list = _.chain(all_entity_list)
                    .filter(function (m) {
                        const staffId = m.staffId;
                        return _.contains(staffId_list, staffId);
                    })
                    .value()
                ;
                const id_list = _.chain(entity_list)
                    .map(function (m) {
                        const id = m.id;
                        return id;
                    })
                    .value()
                ;
                __this.selected_id_list = id_list;

            });
        },

        __btn_reset_value_click() {
            const __this = this;
            __this.selected_id_list = [];
        },

        __btn_get_selected_entity_list_click() {
            const __this = this;
            const selected_entity_list = __this.$refs.aTravelerMultipleSelector.get_selected_entity_list();
            console.log(selected_entity_list);
        },
    },
    watch: {
        selected_id_list(pNewValue, pOldValue) {
            const __this = this;
            // console.log(`pNewValue=`);
            // console.log(pNewValue);
            // console.log(`pOldValue=`);
            // console.log(pOldValue);
        },
    },
    created() {
        const __this = this;
        // __this.selected_id_list = [
        //     9,
        //     10,
        // ];
    },
    mounted() {
        const __this = this;
        // __this.selected_id_list = [
        //     `S479`,
        //     `S488`,
        //     `C66`,
        // ];
        // __this.$refs.aTravelerMultipleSelector.show();

        __this.$refs.aTravelerMultipleSelector.init_parameter.btn_ok_click_event_handler = function (args) {
            console.log(`btn_ok_click_event_handler`);
            console.log(args);
        };

        __this.$refs.aTravelerMultipleSelector.init_parameter.btn_cancel_click_event_handler = function () {
            console.log(`btn_cancel_click_event_handler`);
        };

        if (!__this.enableAutoInit) {

            __this.$refs.aTravelerMultipleSelector.init_parameter.get_data_source_parameter = function () {
                const d = {
                    companyId: 153,
                    isSelectCustomer: true,
                };
                return Q.resolve(d);
            };

            __this.$refs.aTravelerMultipleSelector.init_parameter.get_data_source = function () {
                const data = {
                    "datas": [
                        {
                            "id": "D102",
                            "staffId": 102,
                            "name": "默认部门",
                            "parentId": "0",
                            "index": ""
                        },
                        {
                            "id": "D111",
                            "staffId": 111,
                            "name": "超标部门",
                            "parentId": "0",
                            "index": ""
                        },
                        {
                            "id": "D171",
                            "staffId": 171,
                            "name": "人事部门",
                            "parentId": "0",
                            "index": ""
                        },
                        {
                            "id": "D173",
                            "staffId": 173,
                            "name": "默认子部门",
                            "parentId": "D102",
                            "index": ""
                        },
                        {
                            "id": "S479",
                            "staffId": 479,
                            "name": "陈宇枫",
                            "parentId": "D102",
                            "index": ""
                        },
                        {
                            "id": "S486",
                            "staffId": 486,
                            "name": "韦小宝",
                            "parentId": "D102",
                            "index": ""
                        },
                        {
                            "id": "S488",
                            "staffId": 488,
                            "name": "韦一笑",
                            "parentId": "D173",
                            "index": ""
                        },
                        {
                            "id": "C66",
                            "staffId": 66,
                            "name": "几位人气",
                            "parentId": "C-1",
                            "index": ""
                        },
                        {
                            "id": "C-1",
                            "staffId": -1,
                            "name": "外部客户",
                            "parentId": "0",
                            "index": ""
                        }
                    ],
                    "code": "000000",
                    "msg": "成功"
                };

                let datas = data.datas;

                const all_entity_list = datas;
                const d = {
                    type: `000`,
                    all_entity_list,
                };
                return Q.resolve(d);
            };

            __this.$refs.aTravelerMultipleSelector.init();
        }

    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}

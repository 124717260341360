// consumer_trains_order_orderBookingPolling
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://yapi.yinzhitravel.com/project/52/interface/api/13191 【221】一键核验平台端12306账号结果接口
const consumer_trains_account_admin_checkAccounts = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/account/admin/checkAccounts',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_account_admin_checkAccounts;
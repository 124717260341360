import consumer_trains_order_getTrainsOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetail'
import consumer_trains_order_applyForRefund from '@/lib/data-service/haolv-default/consumer_trains_order_applyForRefund'
import consumer_admin_trains_order_applyForRefund from '@/lib/data-service/haolv-default/consumer_admin_trains_order_applyForRefund'
import consumer_trains_order_getTrainsChangeOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsChangeOrderDetail'
import consumer_admin_trains_order_applyForRefundCost from '@/lib/data-service/haolv-default/consumer_admin_trains_order_applyForRefundCost'

import moment from "moment";
export default {
    data () {
        return {
            checkList: [],
            reasonRadio: '',
            orderNo: '',
            changeNo: '',
            orderInfo: {},
            checkSn: '',

            canSubmit: true,
            titleOffOreder: false,
            titleOffRefund: true,
            passengerSnsData:[], //存储选择乘客
            changeConfirmationLoading: false,
            changeConfirmation: false,
            changeSubmitInfo: {
                passengers: [],
            },
        }
    },
    activated () {
        this.orderNo = this.$route.query.orderNo;
        this.changeNo = this.$route.query.changeNo;
        if (this.orderNo) {
            this.getDetail()
        } else {
            this.getChangeDetail()
        }
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {
        gmtChangeStartFilter(val) {
            if (val == null) {
                return null
            } else {
                return moment(val).format('YYYY-MM-DD HH:mm')
            }
        },
        filterPassengerType(val) {
            // 1:成人 2：儿童 3：学生票 4：残军票
            if (val === 1) {
                return '成人'
            } else if (val === 2) {
                return '儿童'
            } else if (val === 3) {
                return '学生票'
            } else if (val === 4) {
                return '残军票'
            } else {
                return ''
            }
        },
        filterPassengerCardType(val) {
            // 1：身份证2：儿童无证件3：港澳通行证4：台 湾通行证5：护照
            if (val === '1') {
                return '身份证'
            } else if (val === '2') {
                return '儿童无证件'
            } else if (val === '3') {
                return '港澳通行证'
            } else if (val === '4') {
                return '台湾通行证'
            } else if (val === '5') {
                return '护照'
            } else {
                return ''
            }
        },
        filterOrderStatus(val) {
            // 1:-确认中 2- 待支付 3-待出票 4 -出票成功 5-出票失败 6-取消
            if (val === 1) {
                return '确认中'
            } else if (val === 2) {
                return '待支付'
            } else if (val === 3) {
                return '待出票'
            } else if (val === 4) {
                return '出票成功'
            } else if (val === 5) {
                return '出票失败'
            } else if (val === 6) {
                return '取消'
            } else {
                return ''
            }
        },
        filterChangeOderStatus(val) {
            // 0：申请改签 1：待支付 2：改签中 3：改签成功 4：改签失败 5：已取消
            if (val === 0) {
                return '申请改签'
            } else if (val === 1) {
                return '待支付'
            } else if (val === 2) {
                return '改签中'
            } else if (val === 3) {
                return '改签成功'
            } else if (val === 4) {
                return '改签失败'
            } else if (val === 5) {
                return '已取消'
            } else {
                return ''
            }
        },
        filterTicketStatus(val) {
            // 0：未出票，1：待出票，2：出票中，3：出票完成，4：出票失败,5:退票已申请,6:退票处理中,7:退票完成，8: 退票失败，9：改签已申请，10：改签中， 11：改签完成，12：改签失败，13：改签已取消
            if (val === 0) {
                return '未出票'
            } else if (val === 1) {
                return '待出票'
            } else if (val === 2) {
                return '出票中'
            } else if (val === 3) {
                return '出票完成'
            } else if (val === 4) {
                return '出票失败'
            } else if (val === 5) {
                return '退票已申请'
            } else if (val === 6) {
                return '退票处理中'
            } else if (val === 7) {
                return '退票完成'
            } else if (val === 8) {
                return '退票失败'
            } else if (val === 9) {
                return '改签已申请'
            } else if (val === 10) {
                return '改签中'
            } else if (val === 11) {
                return '改签完成'
            } else if (val === 12) {
                return '改签失败'
            } else if (val === 13) {
                return '改签已取消'
            } else {
                return ''
            }
        },
        filterWhatDay(val) {
            if (val === 1) {
                return '周一'
            } else if (val === 2) {
                return '周二'
            } else if (val === 3) {
                return '周三'
            } else if (val === 4) {
                return '周四'
            } else if (val === 5) {
                return '周五'
            } else if (val === 6) {
                return '周六'
            } else if (val === 7) {
                return '周日'
            } else {
                return ''
            }
        },
        filterDate(val) {
            if (val) {
                return moment(val).format('YYYY-MM-DD');
            } else {
                return ''
            }
        },
        filterTime(val) {
            if (val) {
                return moment(val).format('HH:mm');
            } else {
                return ''
            }
        },
        filterGetWeek(val) {
            if (val) {
                let week = moment(val).day();
                if (week === 1) {
                    return '周一'
                } else if (week === 2) {
                    return '周二'
                } else if (week === 3) {
                    return '周三'
                } else if (week === 4) {
                    return '周四'
                } else if (week === 5) {
                    return '周五'
                } else if (week === 6) {
                    return '周六'
                } else if (week === 7) {
                    return '周日'
                } else {
                    return ''
                }
            } else {
                return ''
            }
        }
    },
    methods: {
        selectablePassenger(val,index){
            if (val.ticketStatus == 3 || val.ticketStatus == 11) {
                return true
            } else {
                return false
            }
        },
        handleSelectionChange(val){
            this.passengerSnsData[0] = val[val.length-1];
            this.$refs.multipleTable.clearSelection();
            this.$refs.multipleTable.toggleRowSelection(val[val.length-1],true);
        },
        back() {
            this.$router.push({
                name:'admin-train-order-list'
            })
        },
        titleClickRefund(){
            this.titleOffRefund = !this.titleOffRefund;
        },
        titleClickOrder(){
            this.titleOffOreder = !this.titleOffOreder;
        },
        gmtStartFormatter(val) {
            return this.$moment(val.gmtStart).format('YYYY-MM-DD HH:mm')
        },
        gmtArriveFormatter(val) {
            return this.$moment(val.gmtArrive).format('YYYY-MM-DD HH:mm')
        },
        getDetail() {
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            consumer_trains_order_getTrainsOrderDetail({orderNo: this.orderNo}).then(res => {
                let orderInfo = res.datas
                let trainItemInfo = {
                    trainNo: orderInfo.changeTrainCode, // 车次
                    fromStation: orderInfo.changeFromStationName, // 出发站
                    toStation: orderInfo.changeToStationName, // 到达站
                    seatName: orderInfo.changeSeatName, // 坐席
                    startTime: orderInfo.gmtChangeStart, //出发时间
                    arriveTime: orderInfo.gmtChangeArrive, // 到达时间
                    ticket: orderInfo.electronicTicketNumber
                };
                orderInfo.orderTrainArr = [trainItemInfo];
                let passengersList = [];
                orderInfo.passengers.forEach(value => {
                    passengersList.push(value.passengerName)
                });
                orderInfo.passengersList = passengersList.join(',');
                this.orderInfo = orderInfo;

                loading.close()
            }).catch(() => {
                loading.close()
            })
        },
        getChangeDetail() {
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            consumer_trains_order_getTrainsChangeOrderDetail({orderNo: this.changeNo}).then(res => {
                loading.close();
                let orderInfo = res.datas;
                let trainItemInfo = {
                    trainNo: orderInfo.changeTrainCode, // 车次
                    fromStation: orderInfo.changeFromStationName, // 出发站
                    toStation: orderInfo.changeToStationName, // 到达站
                    seatName: orderInfo.changeSeatName, // 坐席
                    startTime: orderInfo.gmtChangeStart, //出发时间
                    arriveTime: orderInfo.gmtChangeArrive, // 到达时间
                    ticket: orderInfo.electronicTicketNumber
                };
                orderInfo.orderTrainArr = [trainItemInfo];
                let passengersList = [];
                orderInfo.passengers.forEach(value => {
                    passengersList.push(value.passengerName)
                });
                orderInfo.passengersList = passengersList.join(',');
                this.orderInfo = orderInfo;
            }).catch(() => {
                loading.close()
            })




        },
        checkEvection() {
            this.$router.push({
                name: 'admin-evection-detail',
                query: {
                    evectionNo: this.orderInfo.evectionNo,
                    fromType: 5
                }
            })
        },
        clickSubmit() {
            if (this.passengerSnsData[0] == undefined) {
                this.$message({
                    type: 'warning',
                    message: '请先选择需要退票的乘客'
                });
                return
            }
            // let checkSn = this.passengerSnsData[0].sn;
            if (this.reasonRadio === '') {
                this.$message({
                    type: 'warning',
                    message: '请先选择原因'
                });
                return
            }
            if (!this.canSubmit) {
                return
            }
            // 判断是否勾选了乘客
            let passengerSns = [];
            let changeHeadCounts = 0;
            let changeAdultCounts = 0;
            let changeChildrenCounts = 0;
            let userIds = [];
            this.passengerSnsData.forEach(value => {
                passengerSns.push(value.sn);
                userIds.push(value.userId);
                changeHeadCounts++;
                if (value.passengerType === 2) {
                    changeChildrenCounts++
                } else {
                    changeAdultCounts++
                }
            });
            this.changeConfirmation = true;
            this.changeConfirmationLoading = true;
            //调用接口获取信息
            const params = {
                list: passengerSns,
                orderNo: this.orderNo || this.orderInfo.orderNo,
            };
            consumer_admin_trains_order_applyForRefundCost(params).then(res => {
                this.changeSubmitInfo = res.datas
            }).finally(() => {
                this.changeConfirmationLoading = false;
            })
        },
        submit() {
            if (this.passengerSnsData[0] == undefined) {
                this.$message({
                    type: 'warning',
                    message: '请先选择需要退票的乘客'
                });
                return
            }
            let checkSn = this.passengerSnsData[0].sn;
            if (this.reasonRadio === '') {
                this.$message({
                    type: 'warning',
                    message: '请先选择原因'
                });
                return
            }
            if (!this.canSubmit) {
                return
            }
            this.canSubmit = false;
            let params = {
                orderNo: this.orderNo || this.orderInfo.orderNo,
                passengerSn: checkSn,
                // passengerSn: this.checkList,
                reason: this.reasonRadio
            };

            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            consumer_admin_trains_order_applyForRefund(params).then(res => {
                this.canSubmit = true;
                loading.close();
                this.$message({
                    type: 'success',
                    message: '提交成功'
                });
                this.$router.go(-1)
            }).catch(() => {
                this.canSubmit = true;
                loading.close()
            })
        },
        confirmRefund() {
            this.changeConfirmation = false;
            this.submit();
        },
        clickCancel() {
            this.changeConfirmation = false;
        },
    }
}
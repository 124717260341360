import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.2.1/index.vue';
import DynamicForm from '@/component/dynamicForm/import_latest_version_module'
// import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.8/index.vue';
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector-label/1.0.0/index.vue';
import consumer_admin_visa_save from '@/lib/data-service/haolv-default/consumer_admin_visa_save'
import consumer_admin_visa_details from '@/lib/data-service/haolv-default/consumer_admin_visa_details'
import consumer_admin_flight_generate_evectionType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_evectionType'
import consumer_admin_flight_generate_psgType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_psgType'
import consumer_admin_flight_generate_payType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_payType'
import consumer_country_queryCountryTwoWord from '@/lib/data-service/haolv-default/consumer_country_queryCountryTwoWord';
import consumer_admin_flight_generate_certificateType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType'
import consumer_admin_flight_generate_getCompanyList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getCompanyList'
import consumer_department_getStaffsAndExCusByUserIds from '@/lib/data-service/haolv-default/consumer_department_getStaffsAndExCusByUserIds'
import defaultForm from './form';
import isMobile from 'yinzhilv-js-sdk/src/common-service/other/is_mobile';
import Q from 'q';
import tipsObj from './tips';
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
import DictionarySelect from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';
import NewUserPopup
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-list-for-reserve-platform/1.0.0/component/newUserPopup/index.vue";
import TravelMultipleSelectDialog from "@/component/travel-multiple-select-dialog-for-create-order/index.vue";
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";


export default {
    data() {
        return {
            // 当前所属企业的companyId
            companyIdCurrent: null,
            trainsReservationBox: [
                {msg: '12306登录预订',value: 1},
                {msg: '免登录预订',value: 2}
            ],
            loading: false,
            travelMultipleSelectContacts: [],   // 已选择联系人
            travelMultipleSelectTravelers: [],   // 已选择出行人
            type: 'create', // 'create' 新建 'edit' 编辑
            orderNo: '',
            form: JSON.parse(JSON.stringify(defaultForm)),
            rules: {
                'visaCompanyInfo.companyId': [
                    { required: true, message: '请选择所属企业', trigger: 'change' },
                ],
                'visaCompanyInfo.contactName': [
                    { required: true, message: '请填写联系人', trigger: 'blur' },
                ],
                // { validator: this.checkMobile, trigger: 'blur'}
                'visaCompanyInfo.contactMobile': [
                    { required: false, message: '请填写联系电话', trigger: 'blur' },

                ],
                'visaCompanyInfo.evectionType': [
                    { required: true, message: '请选择出行类型', trigger: 'change' },
                ],
                'visaCompanyInfo.payType': [
                    { required: true, message: '请选择支付方式', trigger: 'change' },
                ],
            },
            orderStatus: '',
            // 签证国家List
            visaCountryList: [
                {
                    msg: '',
                    code: '',
                }
            ],
            // 证件类型List
            certificateTypeList: [],
            // 支付方式List
            payTypeList: [],
            // 出现类型List
            evectionTypeList: [],
            // 所属企业List
            companyList: [],
            // 乘客类型List
            psgTypeList: [],
            // 办理进度类型
            statusTypeList: [
                {msg: '办理中', value: 0}
            ],
            addNewPassengerLoading: false,
            submitLoading: false,
            selectedIdList: [],
            // 选择人组件当前在那种形态
            switchTraveler: '',
            // 附件
            accessoryUrl: [],
            uploadIndex: 0,
            orderSourceSelect: '',  // 字典组件--采购渠道--code
            paymentTypeSelect: '',  // 字典组件--付款方式--code

            activeUserList: [],
        }
    },
    components: {
        TravelMultipleSelectDialog,
        NewUserPopup,
        TrainCitySelector,
        DynamicForm,
        TravelerMultipleSelector,
        DictionarySelect
    },
    created() {
    },
    async mounted() {
    },
    async activated() {
        this.accessoryUrl = [];
        this.orderSourceSelect = '';
        this.paymentTypeSelect = '';
        await this.init();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        orderStatusToDisabled() {
            if (this.type == 'edit') {
                return true;
            }
            return false;
        },
    },
    filters: {},
    methods: {
        // 限制只能输入数字或小数点
        validateInput(item, index, value) {
            value = value.replace(/[^\d{1,}.\d{1,}|\d{1,}]/g, "");
            value = value.replace(/^(\-)*(\d+)\.(\d{2}).*$/, '$1$2.$3');
            this.form.visaCompanyBill[index][item] = value;
        },
        inputMatch(data,text) {
            eval(`${data} = ${data}.match(${text}) == null ? '' : ${data}.match(${text})[0];`);
        },
        inputReplace(data,text) {
            eval(`${data} = ${data}.replace(${text},'');`);
        },
        visaOrderPaymentType(rule, value, callback) {
            this.form.visaOrder.paymentType = this.paymentTypeSelect;
            if (this.form.visaOrder.paymentType || this.form.visaCompanyInfo.payType == 2) {
                callback();
                return;
            }
            callback(new Error("请选择付款方式"));
        },
        visaOrderOrderSource(rule, value, callback) {
            this.form.visaOrder.orderSource = this.orderSourceSelect;
            if (this.form.visaOrder.orderSource) {
                callback();
                return;
            }
            callback(new Error("请选择采购渠道"));
        },
        addSuccess(data) {
            //新增成功后触发
            Dictionary.init(this, {
                typeGroupKey: ['tmc'],
                typeKey: [],
                parentId: 0
            });
        },
        // 计算利润
        priceInputChange(index) {
            let salesPrice = Number(this.form.visaCompanyBill[index].salesPrice);   // 销售价
            let supPrice = Number(this.form.visaCompanyBill[index].supPrice);       // 采购价
            let serviceFee = Number(this.form.visaCompanyBill[index].serviceFee);   // 服务费
            this.form.visaCompanyBill[index].profit = Number(`${(salesPrice - supPrice + serviceFee).toFixed(2)}`);
            this.$forceUpdate();
        },
        // 上传附件
        change_upload_index() {
            // this.uploadIndex = index;
        },
        // 删除上传附件
        remove_attached(file) {
            this.accessoryUrl.forEach((item,index) => {
                if (item.uid == file.uid) {
                    this.accessoryUrl.splice(index,1);
                }
            });
        },
        change_attached(file) {
            const size = file.size / 1024;
            if (this.accessoryUrl.length == 10) {
                this.$message.warning("只能上传10个附件");
            } else if (size > 10240) {
                this.$message.warning("附件大小不能超过 10MB!");
                this.$refs['upload' + this.uploadIndex][0].clearFiles();
            } else {
                const myUpLoad = new OssClient();
                myUpLoad.multipartUpload({
                    file: file.raw,
                    progress: function* (p) {},
                }).then((data) => {
                    this.accessoryUrl.push({
                        name: file.name,
                        url: data.url,
                    });
                    this.visaOrder.files = JSON.stringify(this.accessoryUrl);
                    this.uploadIndex++;
                }).catch(() => {
                    // this.$message.error("上传失败");
                });
            }
        },
        delete_item(index) {

        },
        // 附件下载
        downAccessory(item) {

        },
        getPsgType() {
            consumer_admin_flight_generate_psgType().then(res => {
                this.psgTypeList = res.datas
            })
        },
        getPayTypeList() {
            consumer_admin_flight_generate_payType().then(res => {
                this.payTypeList = res.datas
            })
        },
        getEvectionTypeList() {
            consumer_admin_flight_generate_evectionType().then(res => {
                this.evectionTypeList = res.datas
            })
        },
        queryCountryTwoWord() {
            consumer_country_queryCountryTwoWord().then(res => {
                this.visaCountryList = res.datas
            })
        },
        async contactNameBlur() {
            await this.getCompanyList();
            if (this.companyList.length == 0) {
                const params = {
                    companyName: '',
                    currentPage: 1,
                    keyword: '',
                    pageSize: 999,
                    userName: '',
                };
                let res = await consumer_admin_flight_generate_getCompanyList(params);
                this.companyList = res.datas.list;
            }
        },
        async getCompanyList() {
            const params = {
                companyName: '',
                currentPage: 1,
                keyword: '',
                pageSize: 999,
                userName: this.form.visaCompanyInfo.contactName,
            };
            let res = await consumer_admin_flight_generate_getCompanyList(params);
            this.companyList = res.datas.list;
        },
        async init() {
            this.loading = true;
            this.form = JSON.parse(JSON.stringify(defaultForm));
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            });
            this.getEvectionTypeList();
            this.getCertificateType();
            this.getPayTypeList();
            this.getPsgType();
            this.queryCountryTwoWord();
            await this.getCompanyList();
            this.loading = false;
            const query = this.$route.query;
            this.type = query && query.type ? query.type : 'create';  // create edit
            if (this.type === 'edit' && query.id) {
                let data = {
                    id: query.id,
                    orderNo: null,
                };
                if (this.statusTypeList.length == 1 || this.statusTypeList.length == 0) {
                    this.statusTypeList.push({msg: '已出签', value: 1});
                    this.statusTypeList.push({msg: '已拒签', value: 2});
                    this.statusTypeList.push({msg: '已取消', value: 3});
                }
                let [err, res] = await awaitWrap(consumer_admin_visa_details(data));
                if (res.code == '000000') {
                    this.form.visaOrder = res.datas.visaOrder;
                    this.form.visaCompanyInfo = res.datas.visaCompanyInfo;
                    this.form.visaCompanyBill = res.datas.visaCompanyBill;
                    // 回填信息
                    this.form.visaOrder.countryName = res.datas.visaOrder.countryNo;
                    this.orderSourceSelect = res.datas.visaOrder.orderSource;
                    this.paymentTypeSelect = res.datas.visaOrder.paymentType;
                    /*this.form.visaCompanyBill.forEach((item,index) => {
                        item.profit = item.salesPrice - item.supPrice + item.serviceFee;
                    });*/

                    // 获取乘机人的证件信息
                    let passengerIdList = [];
                    this.form.visaCompanyBill.forEach(value=>{
                        passengerIdList.push(value.userId);
                    })
                    let [pErr, pRes] = await awaitWrap(consumer_department_getStaffsAndExCusByUserIds({
                        companyId: this.form.visaCompanyInfo.companyId,
                        userIds: passengerIdList,
                        isSelectCustomer: true,
                        pageSize: 30,
                        currentPage: 1,
                    }));
                    let activeUserList = [];
                    this.form.visaCompanyBill.forEach(value =>{
                        const newUserItem = {
                            birthDay: value.birthday,
                            certificateName: value.cardTypeText,
                            certificateType: value.cardType,
                            certificateValue: value.cardNo,
                            certificates: value.certificates,
                            countryCode: value.countryCode,
                            countryName: null,
                            englishName: value.psgEnglishName,
                            isLoginUser: null,
                            name: value.psgName,
                            phone: value.phone,
                            shareWith: null,
                            tags: [],
                            type: 3,
                            typeValue: "员工",
                            unSensitiveCertificateValue: value.cardNo,
                            unSensitivePhone: value.phone,
                            userId: value.id,
                        };
                        activeUserList.push(newUserItem);
                    });
                    this.activeUserList = activeUserList;

                    this.accessoryUrl = JSON.parse(res.datas.visaOrder.files);
                    this.$refs.aTravelMultipleSelectDialog.setCompany(this.form.visaCompanyInfo.companyId);
                }
            }
        },
        getCertificateType() {
            consumer_admin_flight_generate_certificateType().then(res => {
                let certificateTypeList = res.datas;
                this.certificateTypeList = [];
                certificateTypeList.forEach(value => {
                    if (value.msg == '护照') {
                        value.value = parseInt(value.code);
                        this.certificateTypeList.push(value);
                    }
                });
            });
            // let certificateTypeList = [{msg: "护照", code: 5}];
            // certificateTypeList.forEach(value => {
            //   value.value = parseInt(value.code)
            // })
            // this.certificateTypeList = certificateTypeList
        },
        changeCompanyId(val) {
            let activeItem = null;
            this.companyList.forEach(value => {
                if (value.id === val) {
                    activeItem = value
                }
            });
            this.companyIdCurrent = val;
            this.form.visaCompanyInfo.companyName = activeItem.companyName;
            if (this.type === 'edit') {
                return;
            }
            // 更新初始化用户选择组件
            this.$refs.aTravelMultipleSelectDialog.setCompany(val);
            this.$refs.aTravelMultipleSelectDialog.init();
        },
        clearCompany() {
            // this.form.visaCompanyInfo.contactName = '';
            // this.form.visaCompanyInfo.contactMobile = '';
        },
        // 选择联系人
        addNewContacts() {
            if (!this.form.visaCompanyInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择所属企业'
                });
                return
            }
            this.switchTraveler = 'Contacts';
            this.$refs.aTravelMultipleSelectDialog.init({selectType: '001'});
            this.selectedIdList = [];
            this.$refs.aTravelMultipleSelectDialog.show();
            this.activeUserList = this.travelMultipleSelectContacts;
        },
        // 选择出行人
        addNewPassenger() {
            if (!this.form.visaCompanyInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择所属企业'
                });
                return
            }
            this.switchTraveler = 'Passenger';
            this.$refs.aTravelMultipleSelectDialog.init({selectType: '000'});
            this.selectedIdList = [];
            this.$refs.aTravelMultipleSelectDialog.show();
            this.activeUserList = this.travelMultipleSelectTravelers;
        },
        // 出行人--添加一个
        addNewPassengerRequestItem() {
            if(!this.form.visaCompanyInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择公司'
                })
                return;
            }
            this.$refs.aNewUserPopup.init({companyId: this.form.visaCompanyInfo.companyId});
            this.$refs.aNewUserPopup.show();

            /*this.addNewPassengerLoading = true;
            const newItem = {
                id: null,
                gmtCreate: "",
                gmtModified: "",
                orderId: null,
                companyId: null,
                userId: null,
                certId: null,
                companyType: 1,
                status: 0,
                salesPrice: null,
                supPrice: null,
                serviceFee: 0,
                expectTime: "",
                userName: "",
                certNum: "",
                certType: 2,

                profit: null,   // 利润--需要去掉
            };
            this.form.visaCompanyBill.splice(index + 1, 0, newItem);
            this.addNewPassengerLoading = false*/
        },
        // 删除出行人--一个
        deletePassengerRequestItem(item, index) {
            this.form.visaCompanyBill.splice(index, 1);
            this.activeUserList.splice(index, 1);
        },
        // 复制出行人
        copyItem(item, index) {
            // pnr,票面价,机建,燃油,采购价,销售价
            let newItem = {
                id: item.id,
                gmtCreate:  item.gmtCreate,
                gmtModified:  item.gmtModified,
                orderId: item.orderId,
                companyId: item.companyId,
                userId: item.userId,
                certId: item.certId,
                companyType: item.companyType,
                status: item.status,
                salesPrice: item.salesPrice,
                supPrice: item.supPrice,
                serviceFee: item.serviceFee,
                expectTime:  item.expectTime,
                userName:  item.userName,
                certNum:  item.certNum,
                certType: item.certType,

                profit:  item.profit,
            };
            this.form.visaCompanyBill.splice(index, 0, newItem);
        },
        payTypeChange() {
          this.$refs.form.clearValidate(['visaOrder.paymentType','visaOrder.paymentTime','visaOrder.paymentNum'])
        },
        // 保存
        onSubmit() {
            let isPass = false;
            let unValidObj = null;
            this.$refs.form.validate((valid, obj) => {
                isPass = valid;
                unValidObj = obj;
            });
            if (isPass === true) {
                // 校验新增出行人信息是否重复
                let userCert = [];
                let userName = [];
                let userBox = [];
                this.form.visaCompanyBill.forEach((value, index) => {
                    userCert.push(`${value.certType}-${value.certNum}`);
                    userName.push(value.userName);
                    userBox.push('');
                });
                userCert.forEach((Cvalue, Cindex) => {
                    let num = 0;
                    let testNum = '';
                    this.form.visaCompanyBill.forEach((value, index) => {
                        if (`${value.certType}-${value.certNum}` == Cvalue) {
                            num++;
                            if (num >= 2) {
                                testNum = `${testNum}-${index}+${value.userName}`;
                                userBox[Cindex] = testNum;
                            } else {
                                testNum = `${index}+${value.userName}`;
                            }
                        }
                    });
                });

                let setUserBox = Array.from(new Set(userBox));
                let setUserBoxText = '';
                setUserBox.forEach((item,index) => {
                    if (item != '') {
                        let textBox = '';
                        item.split('-').forEach((SItem,SIndex) => {
                            if (textBox == '') {
                                textBox = ` 第${Number(SItem.split('+')[0]) + 1}行的${SItem.split('+')[1]}`;
                            } else {
                                textBox = `${textBox} 和 第${Number(SItem.split('+')[0]) + 1}行的${SItem.split('+')[1]}`;
                            }
                        });
                        setUserBoxText += `<p style="color: red;">${textBox} 重复`;
                    }
                });
                if (setUserBoxText != '') {
                    setUserBoxText += '；</p>';
                    this.$alert(setUserBoxText, '客户信息', {
                        confirmButtonText: '确定',
                        dangerouslyUseHTMLString: true,
                    });
                } else {
                    this.addOrder();
                }
            } else {
                let tipsStr = '';
                if (!isPass) {
                    const unValidKeys = Object.keys(unValidObj);
                    unValidKeys.forEach((item, index) => {
                        let tmpList = item.split('.');
                        let len = tmpList.length;
                        if (index > 0) {
                            tipsStr += '；</p>';
                        }

                        if (len === 2) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}-${tipsObj[tmpList[1]]}`;
                        }
                        if (len === 3) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}第${Number(tmpList[1]) + 1}行-${tipsObj[tmpList[2]]}`;
                        }
                        if (len === 5) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}第${Number(tmpList[1]) + 1}行-${tipsObj[tmpList[2]]}第${Number(tmpList[3]) + 1}行程-${tipsObj[tmpList[4]]}`;
                        }
                    });
                    tipsStr += '；</p>';
                }
                this.$alert(tipsStr, '以下信息未填写', {
                    confirmButtonText: '确定',
                    dangerouslyUseHTMLString: true,
                });
            }
        },
        addOrder() {
            let data = {
                visaOrder: this.form.visaOrder,
                visaCompanyInfo: this.form.visaCompanyInfo,
                visaCompanyBill: this.form.visaCompanyBill,
            };
            data.visaOrder.countryNo = data.visaOrder.countryName;
            data.visaOrder.files = JSON.stringify(this.accessoryUrl);
            // delete data.visaOrder.countryName;
            this.submitLoading = true;
            this.loading = true;
            consumer_admin_visa_save(data).then((res) => {
                this.$message({
                    type: "success",
                    message: '保存成功'
                });
                if (this.type == 'edit') {
                    this.$router.push({
                        name: 'admin-visa-order-list',
                        query: {
                            behalfCompanyId: this.$route.query.behalfCompanyId,
                        },
                    })
                } else {
                    this.$router.push({
                        name: "admin-visa-create-order-pay",
                        query: {
                            id: res.datas.visaOrder.id,
                            orderNo: res.datas.visaOrder.orderNo,
                            behalfCompanyId: res.datas.visaCompanyInfo.companyId
                        }
                    })
                }
            }).catch((rej) => {
                this.$message.error(`${rej.msg}`);
            }).finally(() => {
                this.submitLoading = false;
                this.loading = false;
            });
        },
        back() {
            this.$router.go(-1)
        },
        arrDateTimeDisableDate(time) {
            let value = this.form.visaOrder.paymentTime;
            let m = this.$moment(value);
            const second = m.second();
            const minute = m.minute();
            const hour = m.hour();
            const millisecond = (hour * 60 * 60 + minute * 60 + second) * 1000;
            if (value) {
                return time.getTime() < new Date(value).getTime() - millisecond;
            } else {
                return false;
            }
        },
        greaterThanZero(rule, value, callback, tip) {
            if (Number(value) <= 0) {
                callback(new Error(`${tip}必须大于 0`));
            }
            callback();
        },
        greaterThanOrEqualToZero(rule, value, callback, tip) {
            if (Number(value) < 0) {
                callback(new Error(`${tip}不能为负数`));
            }
            callback();
        },
        changeEvectionType(val) {
            if (this.form.visaCompanyInfo.evectionType == 1) {
                this.form.visaCompanyInfo.payType = null;
                this.payTypeList = this.payTypeList.filter(item => item.msg !== '授信支付');
            }
            if (this.form.visaCompanyInfo.evectionType == 2 && this.payTypeList.indexOf({code: 1,msg: "授信支付"}) == -1) {
                this.payTypeList = [{code: 1,msg: "授信支付"},...this.payTypeList];
            }
        },
        changeCertificateType(item, index, val) {
            this.certificateTypeList.forEach(value => {
                if (value.value === val) {
                    item.cardTypeText = value.msg
                }
            })
        },
        cardNoValidator(rule, value, callback, index, item) {
            let cardType = item.cardType;
            const date = `${value.substr(6, 4)}-${value.substr(10, 2)}-${value.substr(12, 2)}`;
            if (cardType === 1) {
                if (this.isChineseIDCardNumber(value) === false) {
                    callback(new Error('请输入正确的身份证号码'));
                    return;
                } else {
                    this.$set(item, 'birthday', date);
                    callback();
                }
            } else {
                callback();
            }
            // this.$set(item, 'birthday', date);
        },
        inputValidator(rule, value, callback, index, item) {
            if (value == 0) {
                callback(new Error('请输入大于0的数'));
                return;
            }
            callback();
        },
        // 身份证号码验证
        isValidDate(year, month, day) {
            const date = new Date(year, month - 1, day);
            return (
                date.getFullYear() === year
                && date.getMonth() + 1 === month
                && date.getDate() === day
                && date.getTime() < new Date().getTime()
            );
        },
        isChineseIDCardNumber(value) {
            const testRegExp = /^[1-9]([0-9]{14}|[0-9]{16}[0-9Xx])$/;
            const areaMap = [11, 12, 13, 14, 15, 21, 22, 23, 31, 32, 33, 34, 35, 36, 37, 41, 42, 43, 44, 45, 46, 50, 51, 52, 53, 54, 61, 62, 63, 64, 65, 71, 81, 82];
            const weightMap = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            const codeMap = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
            const len = value.length;
            if (len !== 18) {
                return false;
            }
            // 模式校验
            if (!testRegExp.test(value)) {
                return false;
            }
            // 地区校验
            if (areaMap.indexOf(+value.substr(0, 2)) === -1) {
                return false;
            }
            // 18 位
            if (!this.isValidDate(+value.substr(6, 4), +value.substr(10, 2), +value.substr(12, 2))) {
                return false;
            }
            // 校验码
            const sum = value.split('').slice(0, 17).reduce((s, num, index) => {
                return s += +num * weightMap[index];
            }, 0);
            return codeMap[sum % 11] === value[17].toUpperCase();
        },
        checkMobile(rule, value, callback) {
            if (!isMobile(value)) {
                callback(new Error('只能输入长度不能大于11的数字'));
                return
            }
            callback();
        },
        getNewUser(newUser) {
            const newUserItem = {
                birthDay: newUser.birthDate,
                certificateName: "",
                certificateType: newUser.certificateType,
                certificateValue: newUser.certificateValue,
                certificates: [
                    {
                        "certificateValue": newUser.certificateType,
                        "certificateText": "",
                        "certificate": newUser.certificateValue,
                        "unSensitiveCertificateValue": newUser.certificateValue,
                        "countryCode": newUser.countryCode,
                        "countryName": "",
                        "issCountryCode": newUser.issCountryCode,
                        "issCountryName": "",
                        "cardExpireDate": newUser.passengerExpireDate
                    }
                ],
                countryCode: null,
                countryName: null,
                englishName: "",
                isLoginUser: null,
                name: newUser.name,
                phone: newUser.phone,
                shareWith: null,
                tags: [],
                type: 3,
                typeValue: "员工",
                unSensitiveCertificateValue: newUser.certificateValue,
                unSensitivePhone: newUser.phone,
                userId: newUser.id,
            };
            const newItem = {
                id: null,
                gmtCreate: "",
                gmtModified: "",
                orderId: null,
                companyId: null,
                userId: newUser.id,
                certId: null,
                companyType: 1,
                status: 0,
                salesPrice: null,
                supPrice: null,
                serviceFee: 0,
                expectTime: "",
                userName: newUser.name,
                certNum: newUser.certificateType === 2 ? newUser.certificateValue : '',
                certType: 2,
                profit: null,   // 利润--需要去掉
            };
            this.form.visaCompanyBill.push(newItem);
            this.activeUserList.push(newUserItem);
        },
        getUserList(args) {
            if (this.switchTraveler == 'Contacts') {
                this.travelMultipleSelectContacts = this.activeUserList;
            }
            if (this.switchTraveler == 'Passenger') {
                this.travelMultipleSelectTravelers = this.activeUserList;
            }
            let visaCompanyBill = [];
            args.activeDetailList.forEach(value => {
                if (this.switchTraveler === 'Contacts') {
                    // 联系人数据
                    this.form.visaCompanyInfo.contactName = value.name;
                    this.form.visaCompanyInfo.contactMobile = value.phone;
                }
                if (this.switchTraveler === 'Passenger') {
                    let certificate = {};
                    value.certificates.forEach((item,index) => {
                        if (item.certificateText === '护照') {
                            certificate = item;
                        }
                    });
                    const item = {
                        id: null,
                        gmtCreate: "",
                        gmtModified: "",
                        orderId: null,
                        companyId: this.form.visaCompanyInfo.companyId,
                        userId: value.userId,
                        certId: null,
                        companyType: 1,  // 成人--1
                        status: 0,
                        salesPrice: null,
                        supPrice: null,
                        serviceFee: 0,
                        expectTime: "",
                        userName: value.name,
                        certNum: certificate == {} ? '' : certificate.certificate,
                        certType: 2,

                        profit: null,   // 利润--需要去掉
                    };
                    visaCompanyBill.push(item);
                    this.form.visaCompanyBill = visaCompanyBill && visaCompanyBill.length == 0 ? JSON.parse(JSON.stringify(defaultForm)).visaCompanyBill : visaCompanyBill;
                }
                this.$nextTick(() => {
                    this.$refs.form.validateField(['customerInfo.contactName', 'customerInfo.contactMobile'])
                })
            })
        },
    }
}

const reportConfigurationsData = {
    datas: {
        reportConfigurations: [
            {
                parameterText: '出差单号',
                parameter: 'ChuChaiDanHao',
                type:'1',
                opetions: null,
            },
            {
                parameterText: '业务类型',
                parameter: 'YeWuLeiXing',
                type:'2',
                opetions: [
                    {
                        code: '0',
                        text: '因公',
                    },
                    {
                        code: '1',
                        text: '因私',
                    }
                ],
            },
            {
                parameterText: '成本中心',
                parameter: 'ChengBenZhongXin',
                type:'3',
                opetions: null,
            }
        ]
    },
    code: "000000",
    msg: "成功",
}

export {reportConfigurationsData}

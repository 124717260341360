const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_hotel_order_generate_generateOrderConfirm = (pParams) => {

  const p = {
    method: 'post',
    urlSuffix: '/consumer/admin/hotel/order/generate/generateOrderConfirm',
    data: pParams,
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_admin_hotel_order_generate_generateOrderConfirm;

// 酒店退款规则 http://219.128.52.2:18765/doc.html#/haolv-consumer/hotel-search-controller/getRatePlanUsingPOST
const __request = require(`./__request/__request_contentType_json`)
const consumer_hotel_search_getRatePlan = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/admin/hotel/order/getRatePlan',
        data: data
    }
    return __request(pParameter)
}
export default consumer_hotel_search_getRatePlan

export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/hotel-order/offline-cancel-handle',
            name: 'admin-hotel-order-offline-cancel-handle',
            component: () => import(/* webpackChunkName: "page-admin-order-management-hotel-offline-cancel-handle" */ `./1.0.1/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `线下退订处理`,
                },
                entity_list_for_breadcrumb: [
                    { name: `酒店订单` },
                    { name: `已销售的酒店订单` },
                    { name: `线下退订处理` },
                ],
                data_for_left_menu: {
                    get_active_route_name() {
                        return `admin-hotel-order-sold`;
                    },
                },
            },
        },
    ],
};

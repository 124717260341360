// 出差单详情
import consumer_admin_behalfCustomer_pageList from "@/lib/data-service/haolv-default/consumer_admin_behalfCustomer_pageList";
// 验证公司是否有效
import consumer_web_company_checkCompany from "@/lib/data-service/haolv-default/consumer_web_company_checkCompany";
import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'
export default {
  data () {
    return {
      loading: false,
      form: {
        currentPage: 1,
        pageSize: 10,
        companyName: '',
        phone: '',
      },
      memberID: '',
      pager: {
        pageSizes: [10, 15, 20, 25, 30],
        total: 0,
      },
      list: [],
    };
  },
  components: {RouterLinkButton},
  methods: {

    memberIDClick(){
      if (this.memberID == null || this.memberID == '') {
        this.$message({
          message: '请输入会员id',
          type: 'warning'
        });
      } else {
        consumer_web_company_checkCompany({companyId:this.memberID}).then(res => {
          if (res.datas.result) {
            const eleLink = document.createElement('a');
            eleLink.href = `/admin/travel-book-home?behalfCompanyId=${this.memberID}`;
            eleLink.target = '_blank';
            eleLink.click();
          } else {
            this.$message({
              message: '该会员id无法下单，请检查会员id是否正确',
              type: 'warning'
            });
          }
        });
      }
    },
    req_list () {
      this.loading = true;
      consumer_admin_behalfCustomer_pageList(this.form).then((result) => {
        const {list, totalCount} = result.datas.res;
        this.list = list;
        this.pager.total = totalCount;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
      });
    },
    search () {
      this.change_currentPage(1);
    },
    reset () {
      console.log(this.$refs.form)
      this.$refs.form.resetFields();
      this.search();
    },
    change_currentPage (currentPage) {
      this.form.currentPage = currentPage;
      this.req_list();
    },
    change_pageSize (pageSize) {
      this.form.pageSize = pageSize;
      this.change_currentPage(1);
    },
    to_travel_book (companyId) {
      this.$router.push({
        name: 'admin-travel-book-home',
        query: {
          behalfCompanyId: companyId,
        }
      })
    },
  },
  created () {
    this.req_list();
  },
};

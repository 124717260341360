import LayoutHeader from './components/layout-header/index.vue';

export default {
  components: {
    LayoutHeader
  },
  data() {
    return {};
  },
}

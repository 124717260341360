export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/meituan-member-account',
            name: 'admin-meituan-member-account',
            component: () => import(/* webpackChunkName: "page-admin-organizational-structure-meituan-member-account" */ `./1.0.0/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `美团会员账号`,
                },
                entity_list_for_breadcrumb: [
                    {name: `组织架构`},
                    {name: `美团会员账号`},
                ],
            },
        },
    ],
};

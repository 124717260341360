// consumer_wallet_withdrawApplyList 提款申请列表
const __request = require(`./__request/__request_contentType_json`);
// 刪除草稿箱客戶接口文档地址：http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-company-controller/deleteCompanyUsingPOST
const consumer_web_company_deleteCompany = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/company/deleteCompany',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_company_deleteCompany;
export default {
    orderNo: '',
    customerInfo: {
        companyId: '',
        companyName: '',
        contactMobile: '',
        contactName: '',
        evectionType: 2,
        payType: 1,
        deptId: '',
        deptName: '',
        oaEvectionNo: '',
        feeAffiliationType: 1,
    },
    journeyInfo: [
        {
            fromStationObj: null,
            toStationObj: null,
            arriveTime: '',
            fromStationCode: '',
            fromStationName: '',
            journeyNo: '1',
            seatName: '',
            seatPrice: '', // 0
            serviceChargePrice: 0,
            trainsReservation: 1,
            startTime: '',
            toStationCode: '',
            toStationName: '',
            trainNo: '',
        }
    ],
    passengerList: [
        {
            birthday: '',
            cardNo: '',
            cardType: 1,
            cardTypeText: '身份证',
            mobile: '',
            psgName: '',
            psgType: 1,
            psgTypeText: '成人',
            insuranceInfo: {
                isInsure: 1, // 是否购买保险
                insuranceSource: 1,
                productCodes: '',
                insuranceOrderId: '', // 保单号
                productName: '', // 保险名称
                premium: '', // 保险销售价
                insureCost: '', // 保险成本
                startDate: '', // 生效时间
                endDate: '', // 失效时间
                channel: 2, // 保险渠道 1：华泰 2：德付通 3：旅游卫士 4：惠择
                payType: 3, // 支付方式
                packageName: '',
            },
            seatnumber: [
                {
                    boardingGates: '',
                    journeyNo: '1',
                    seatInfos: '',
                }
            ]
        }
    ],
    remark: '',
    ticketInfo: {
        purchaseCommission: 0,
        bookingSms: 1,
        initialName: '',
        linkmanSms: 1,
        orderTime: '',
        supplierOrderNo: '',
        ticketChannel: '1',
        ticketNo: '',
        userId: '',
        account: '',
    }
}

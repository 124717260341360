export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/visa-order/list',
            name: 'admin-visa-order-list',
            component: () => import(/* webpackChunkName: "page-admin-order-management-visa-order-list" */ `./1.0.0/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `签证管理`,
                },
                entity_list_for_breadcrumb: [
                    { name: `签证管理` },
                    { name: `签证订单` },
                ],
            },
        },
    ],
};

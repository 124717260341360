export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/car-master-bills',
            name: 'admin-car-master-bills',
            component: () => import(/* webpackChunkName: "page-admin-reconciliation-statement-car-car-master-bills" */ `./1.0.0/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `用车总账单报表`,
                },
                entity_list_for_breadcrumb: [
                    { name: `用车总账单报表` }
                ],
            },
        },
    ],
};

// consumer_admin_flight_offlineRefund_editDetails
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_flight_offlineRefund_editDetails = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/admin/flight/offlineRefund/editDetails',
    data: pParameter
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_admin_flight_offlineRefund_editDetails;

import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'

import consumer_country_queryCountryTwoWord
    from '@/lib/data-service/haolv-default/consumer_country_queryCountryTwoWord';
import consumer_admin_visa_initCount from '@/lib/data-service/haolv-default/consumer_admin_visa_initCount';
import consumer_admin_visa_list from '@/lib/data-service/haolv-default/consumer_admin_visa_list';
import consumer_admin_visa_billDetails from '@/lib/data-service/haolv-default/consumer_admin_visa_billDetails';
import consumer_admin_visa_billAction from '@/lib/data-service/haolv-default/consumer_admin_visa_billAction';
import consumer_admin_visa_action from '@/lib/data-service/haolv-default/consumer_admin_visa_action';

import DictionarySelect
    from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';
import moment from "moment/moment";

export default {
    data() {
        return {
            loading: false,
            confirmDialogVisible: false,
            progressDialogVisible: false,
            curOrder: {},
            countryList: [], //签证国家
            orderChannelList: [], //采购渠道
            expectTime: [],   //预计出签时间
            gmtCreate: [],   //创建时间
            updateTime: [],   //操作时间
            searchForm: {
                orderNo: '',   //订单编号
                companyText: '',//客人名称
                countryNo: '',   //签证国家
                name: '',//签证名称
                supOrderNo: '',//供应商订单号
                status: null,//办理进度
                orderStatus: null,//办理进度
                orderSource:'',//采购渠道
                companyId: '',//所属企业
                updateUser: '',//操作客服
                expectTimeBegin: '',//预计出签时间-开始
                expectTimeEnd: '',//预计出签时间-结束
                gmtCreateBegin: '',//创建时间-开始
                gmtCreateEnd: '',//创建时间-结束
                updateTimeBegin: '',//操作时间-开始
                updateTimeEnd: '',//操作时间-结束
            },
            pageBean: {
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            },
            totalCount: 0,   //总条数
            orderSourceList: [],

            activeName: '-1',
            tabList: [
                {
                    name: -1,
                    label: '全部',
                    value: 0
                },
                {
                    name: 0,
                    label: '待支付',
                    value: 0
                },
                {
                    name: 1,
                    label: '待确认',
                    value: 0
                },
                {
                    name: 2,
                    label: '办理中',
                    value: 0
                },
                {
                    name: 3,
                    label: '已办理',
                    value: 0
                },
                {
                    name: 4,
                    label: '已取消',
                    value: 0
                }
            ],
            tabNumberList: [],
            tableLoading: false,
            orderList: [],

            confirmForm: {},
            progressList: [],
            progressForm: {
                remark: '',
            },
            visaBillList: [],
            visaBillIdList: [],
            confirmRules: {
                channel: [
                    {required: true, validator: this.validatorChannel, trigger: 'blur'},
                ],
                paymentType: [
                    {required: true, message: '请选择付款方式', trigger: 'blur'},
                ],
                paymentTime: [
                    {required: true, message: '请选择付款时间', trigger: 'blur'},
                ],
                paymentNum: [
                    {required: true, validator: this.validatorPaymentNumber, trigger: 'blur'},
                ],

            },
            statusList: [//0 办理中 1 已出签 2拒签 3 取消
                {
                    code: null,
                    msg: '全部'
                },
                {
                    code: 0,
                    msg: '办理中'
                },
                {
                    code: 1,
                    msg: '已出签'
                },
                {
                    code: 2,
                    msg: '已拒签'
                },
                {
                    code: 3,
                    msg: '已取消'
                },
            ],
            progressStatusList: [//0 办理中 1 已出签 2拒签 3 取消
                {
                    code: 0,
                    msg: '办理中'
                },
                {
                    code: 1,
                    msg: '已出签'
                },
                {
                    code: 2,
                    msg: '已拒签'
                },
                /*{
                    code: 3,
                    msg: '已取消'
                },*/
            ]
        }
    },
    components: {
        SearchBox,
        Pagination,
        DictionarySelect
    },
    created() {
        // this.initDictionarySelect();
    },
    async activated() {
        this.queryCountryTwoWord();
        await this.getTabNum();
        await this.getOrderList();
    },
    methods: {
        inputReplace(data,text) {
            eval(`${data} = ${data}.replace(${text},'');`);
            this.$forceUpdate();
        },
        validatorChannel(rule, value, callback) {
            if (this.confirmForm.orderSource) {
                callback();
                return;
            }
            callback(new Error('请选择采购渠道'))
        },
        validatorPaymentNumber(rule, value, callback) {
            if (this.confirmForm.paymentNum) {
                callback();
                return;
            }
            callback(new Error('请输入付款流水号'))
        },

        //初始化字段下拉框组件
        initDictionarySelect() {
            console.log(Dictionary);
        },

        //后台生单
        toRecordOrder() {
            this.$router.push({
                name: "admin-visa-create-order",
                query: {
                    type: 'create'
                }
            })
        },

        //获取每个tab的数值
        async getTabNum() {
            let res = await consumer_admin_visa_initCount();
            this.tabList = res.datas;
        },

        //获取签证订单列表
        async getOrderList() {
            this.tableLoading = true;
            consumer_admin_visa_list({ent: this.searchForm, pageBean: this.pageBean}).then(res => {
                this.orderList = res.datas.list;
                if(this.orderList) {
                    let orderSourceText = '';
                    this.orderList.forEach(async (item) => {
                        orderSourceText = await Dictionary.returnDictionary('tmc', 'visaOrderSource', item.orderSource);
                        this.$set(item, 'orderSourceText', orderSourceText.name);
                    })
                }
                this.totalCount = res.datas.totalCount;
            }).catch(rej => {
                this.orderList = [];
                this.totalCount = 0;
                this.$message({
                    type: "error",
                    message: "获取签证订单列表失败！"
                })
            }).finally(() => {
                this.$nextTick(() => {
                    this.$refs.mainTable.doLayout()
                });
                this.tableLoading = false
            })
        },

        //搜索tabList
        onSearch(val) {
            this.searchForm.orderStatus = this.activeName == -1 ? '' : this.activeName;
            this.pageBean.currentPage = 1;
            this.getOrderList()
        },

        //重置
        onReset() {
            this.searchForm = {
                orderNo: '',   //订单编号
                    companyText: '',//客人名称
                    countryNo: '',   //签证国家
                    name: '',//签证名称
                    supOrderNo: '',//供应商订单号
                    status: null,//办理进度
                    orderSource:'',//采购渠道
                    companyId: '',//所属企业
                    updateUser: '',//操作客服
                    expectTimeBegin: '',//预计出签时间-开始
                    expectTimeEnd: '',//预计出签时间-结束
                    gmtCreateBegin: '',//创建时间-开始
                    gmtCreateEnd: '',//创建时间-结束
                    updateTimeBegin: '',//操作时间-开始
                    updateTimeEnd: '',//操作时间-结束
            };
            this.pageBean = {
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            };
            this.expectTime = [];  //预计出签时间
            this.gmtCreate = [];  //创建时间
            this.updateTime = [];   //操作时间
            this.onSearch()
        },

        //修改预计出签时间
        changeExpectTime(val) {
            if (!val) {
                this.searchForm.expectTimeBegin = '';
                this.searchForm.expectTimeEnd = '';
            } else {
                this.searchForm.expectTimeBegin = val[0] + ' 00:00:00';
                this.searchForm.expectTimeEnd = val[1] + ' 23:59:59';
            }
        },

        //修改创建时间
        changeGmtCreate(val) {
            if (!val) {
                this.searchForm.gmtCreateBegin = '';
                this.searchForm.gmtCreateEnd = '';
            } else {
                this.searchForm.gmtCreateBegin = val[0] + ' 00:00:00';
                this.searchForm.gmtCreateEnd = val[1] + ' 23:59:59';
            }
        },

        //修改操作时间
        changeUpdateTime(val) {
            if (!val) {
                this.searchForm.updateTimeBegin = '';
                this.searchForm.updateTimeEnd = '';
            } else {
                this.searchForm.updateTimeBegin = val[0] + ' 00:00:00';
                this.searchForm.updateTimeEnd = val[1] + ' 23:59:59';
            }
        },

        //设置出差单类型样式
        getEvectionTypeToClass(val) {
            //出差单类型：1.因私，2.因公
            switch (val) {
                case (1):
                    return 'grey'
                case (2):
                    return 'orange'
                default:
                    return ''
            }
        },

        //查看订单
        handleDetailClick(val) {
            this.$router.push({
                name: 'admin-visa-order-detail',
                query: {
                    id: val.id,
                    orderNo: val.orderNo
                }
            });
        },

        //取消订单
        handleCancelClick(val) {
            this.$confirm("确定取消此签证订单？", "取消提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.loading = true;
                let params = {
                    id: val.id,
                    orderNo: val.orderNo,
                    status: 4
                };
                consumer_admin_visa_action(params).then((res) => {
                    this.$message({
                        type: 'success',
                        message: '取消订单成功！'
                    })
                    this.getOrderList()
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                })
            }).catch(() => {
            })
        },

        //去支付
        handlePayClick(val) {
            this.$router.push({
                name: "admin-visa-create-order-pay",
                query: {
                    id: val.id,
                    orderNo: val.orderNo,
                    behalfCompanyId: val.companyId
                }
            })
        },

        //编辑
        handleEditClick(val) {
            this.$router.push({
                name: "admin-visa-create-order",
                query: {
                    type: 'edit',
                    id: val.id
                }
            })
        },

        //获取签证国家枚举值
        queryCountryTwoWord() {
            consumer_country_queryCountryTwoWord().then(res => {
                this.countryList = res.datas
            })
        },

        //确认操作-显示弹框
        onShowConfirmDialog(row) {
            this.confirmForm = {...row};
            console.log(this.confirmForm);
            this.confirmForm.paymentNum = row.paymentNum;
            this.confirmForm.paymentType = this.confirmForm.paymentType || 'ALIPAY';
            this.confirmForm.paymentTime = this.confirmForm.paymentTime || moment().format('yyyy-MM-DD HH:mm:ss');
            this.confirmDialogVisible = true;
        },

        // 只能输入英文或数字
        handleNumInput(item, value) {
            // 使用正则表达式匹配字母和数字
            const reg = /^[a-zA-Z0-9]+$/;
            // 如果输入值不符合规定的正则表达式，则将inputValue重置为上一个值
            if (!reg.test(value)) {
                this.confirmForm[item] = this.confirmForm[item].replace(/[^\a-\z\A-\Z0-9]/g, '');
            }
        },

        //提交确定操作
        async confirmSubmit() {
            this.$refs['confirmForm'].validate(async (valid) => {
                if(valid) {
                    let params = {
                        id: this.confirmForm.id,
                        orderNo: this.confirmForm.orderNo,
                        status: 2,
                        supOrderNo: this.confirmForm.supOrderNo,
                        paymentType: this.confirmForm.paymentType || 0,
                        paymentTime: this.confirmForm.paymentTime,
                        paymentNum: this.confirmForm.paymentNum,
                        remark: this.confirmForm.remark,
                    };
                    await consumer_admin_visa_action(params);
                    this.$message.success("保存成功");
                    this.confirmDialogVisible = false;
                    await this.getOrderList();
                }
            })
        },

        //跟进-显示弹框
        async onShowProgressDialog(row) {
            this.progressForm = {remark: '',};
            this.curOrder = JSON.parse(JSON.stringify(row));
            let res = await consumer_admin_visa_billDetails({id: row.id});
            this.progressList = res.datas;
            this.progressList.forEach((item,index) => {
                this.$set(item, 'selection', item.status == 0);
            });
            this.progressDialogVisible = true;
        },

        //跟进-判断该客户是否可选--不可变
        onProgressSelectableImmutable(val) {
            return val.selection;
        },

        //跟进-判断该客户是否可选
        onProgressSelectable(val) {
            if (val.status === 0) {
              return true;
            }
        },

        //跟进-选择调整办理进度的客人
        progressSelectionChange(list) {
            this.visaBillList = [];
            this.visaBillIdList = [];
            if (list) {
                list.forEach(item => {
                    this.visaBillIdList.push(item.id);
                    this.visaBillList.push({
                        id: item.id,
                        status: item.status,
                        expectTime: item.expectTime
                    })
                })
            }
        },

        // 更新状态
        changeStatus(val) {
            this.visaBillList.forEach((item,index) => {
                if (val.id === item.id) {
                  item.status = val.status;
                }
            });
        },

        // 更改签证时间
        changeTime(row, val) {
            this.visaBillList.forEach((item,index) => {
                if (row.id === item.id) {
                    item.expectTime = val;
                }
            });
        },

        //保存跟进信息
        async progressSubmit() {
            let params = {
                orderId: this.curOrder.id,
                remark: this.progressForm.remark || '',
                visaBillList: this.visaBillList
            };
            await consumer_admin_visa_billAction(params);
            this.$message.success("保存成功");
            this.progressDialogVisible = false;
            await this.getOrderList();
        },

        handleInput(event) {
            console.log(event);
            // 使用正则表达式匹配数字和英文字符
            const regex = /^[0-9a-zA-Z]+$/;
            // 如果输入的值不符合正则表达式，则将其设置为上一个值
            if (!regex.test(event)) {
                console.log('1');
                this.confirmForm.paymentNum = event.replace(/[^0-9a-zA-Z]/g, '');
                this.$set(this.confirmForm, 'paymentNum', event.replace(/[^0-9a-zA-Z]/g, ''))
            } else {
                console.log('2')
                this.confirmForm.paymentNum = event;
                // this.$set(this.confirmForm, 'paymentNum', event)
            }
        }
    }
}

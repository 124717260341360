// 机票退票订单查看操作日志

const __request = require(`./__request/__request_contentType_json`)
const consumer_log_flightRefundList = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/log/flightRefundList',
    data: data
  }
  return __request(pParameter)
}
export default consumer_log_flightRefundList

import consumer_department_getDepartmentList from "@/lib/data-service/haolv-default/consumer_department_getDepartmentList";
import consumer_department_getDepartments from "@/lib/data-service/haolv-default/consumer_department_getDepartments";
import consumer_department_addDepartment from "@/lib/data-service/haolv-default/consumer_department_addDepartment"; // 添加部门
import consumer_department_updateDepartment from "@/lib/data-service/haolv-default/consumer_department_updateDepartment"; // 更新部门
import consumer_department_moveDepartments from "@/lib/data-service/haolv-default/consumer_department_moveDepartments"; // 转移员工到其它部门
import consumer_department_deleteDepartment from "@/lib/data-service/haolv-default/consumer_department_deleteDepartment"; // 删除部门
import consumer_department_getEmployeeList from "@/lib/data-service/haolv-default/consumer_department_getEmployeeList"; // 获取员工名单
import getCompanyInfo from "@/lib/data-service/haolv-default/consumer_web_company_getCompanyInfo"; // 获取公司信息
import delDialog from "@/page/admin/organizational-structure/components/del-dialog/index.vue";
import { awaitWrap } from "@/page/admin/organizational-structure/common/unit";
export default {
  data() {
    return {
      loading: "",
      companyInfo: {},
      navList: [],
      oneDimensionalArray: [], // 部门列表-一维数组
      treeDataSource: [],
      expandIdList: [], // 已展开的项的合集
      dialogVisible: false,
      dialogChangeVisible: false,
      dialogDelVisible: false,
      deptParams: {
        companyId: "",
        deptLevel: "",
        name: "",
        parentId: "",
        userId: "",
        userName: "",
        deptDescribe: "",
        deptId: "",
        parentName: "",
      }, // 新增部门用
      moveDeptUserList: [],
      dialogType: "add", // add = 新建 edit = 编辑
      dialogStatus: {
        add: "新增部门",
        edit: "编辑部门",
      },
      moveParams: {
        companyId: "",
        deptId: "",
        targetDeptId: "",
      }, // 转移部门员工用
      moveRule: {
        targetDeptId: [
          { required: true, message: "请选择部门", trigger: "change" },
        ],
      },
      moveDeptList: [],
      delParams: {
        deptId: "",
        strength: 0,
      },
    };
  },
  components: {
    treeTable: () => import("@/page/admin/organizational-structure/components/tree-table/index.vue"),
    delDialog,
  },
  created() {},
  mounted() {},
  async activated() {
    await this.getDepartmentList();
  },
  deactivated() {},
  destroyed() {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
    async getDepartmentList() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
      });
      let [error, result] = await awaitWrap(getCompanyInfo());
      if (error) {
        this.loading.close();
        return;
      }
      if (!result.datas) {
        this.loading.close();
        return;
      }
      this.companyInfo = result.datas ? result.datas : {};
      let allList = [
        {
          deptName: this.companyInfo.companyName,
          companyId: this.companyInfo.id,
          deptLevel: 0,
          deptId: 0,
          strength: 0,
          childrenList: [],
        },
      ];
      let [err, res] = await awaitWrap(consumer_department_getDepartmentList());
      if (err) {
        this.loading.close();
      }
      if (res) {
        {
          allList[0].strength = res.datas.strength;
          allList[0].childrenList = res.datas.departmentListResults;
          // this.navList = res.datas.departmentListResults
        }
      }
      this.navList = allList;

      this.initTreeData();
      this.loading.close();
    },
    initTreeData() {
      // 这里一定要转化，要不然他们的值监听不到变化
      let tempData = JSON.parse(JSON.stringify(this.navList));
      let reduceDataFunc = (data, level) => {
        data.map((m, i) => {
          if (level === 0) {
            m.isExpand = true; // 默认一级展开
          } else {
            m.isExpand = false; // 是否展开
          }

          if (this.expandIdList.indexOf(m.deptId) > -1) {
            m.isExpand = true;
          }
          // m.children = m.children || []
          m.level = level;
          m.bLeft = level === 0 || level === 1 ? 29 : (level - 1) * 16 + 29;
          if (m.childrenList && m.childrenList.length > 0) {
            reduceDataFunc(m.childrenList, level + 1);
          }
        });
      };
      reduceDataFunc(tempData, 0);
      this.treeDataSource = tempData;
    },
    addItem(val) {
      this.reactDeptParams();
      this.dialogType = "add";
      this.dialogVisible = true;
      this.deptParams.companyId = val.companyId;
      this.deptParams.deptLevel = val.deptLevel + 1;
      this.deptParams.parentName = val.deptName;
      this.deptParams.parentId = val.deptId;
      this.$nextTick(() => {
        this.$refs["addForm"].clearValidate();
      });
      // 获取员工
      consumer_department_getEmployeeList({ companyId: val.companyId }).then(
        (res) => {
          this.moveDeptUserList = res.datas;
        }
      );
    },
    editItem(val) {
      console.log(val);
      this.reactDeptParams();
      this.deptParams.companyId = val.companyId;
      this.deptParams.deptLevel = val.deptLevel;
      this.deptParams.parentId = val.parentId;
      this.deptParams.deptId = val.deptId;
      this.deptParams.name = val.deptName;
      this.deptParams.userId = val.userId;
      this.deptParams.userName = val.userName;
      this.dialogType = "edit";
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["addForm"].clearValidate();
      });
      consumer_department_getDepartments({ companyId: val.companyId }).then(
        (res) => {
          this.oneDimensionalArray = res.datas.departmentVos;
          for (let i = 0, l = this.oneDimensionalArray.length; i < l; i++) {
            if (this.oneDimensionalArray[i].id === val.parentId) {
              this.deptParams.parentName = this.oneDimensionalArray[i].name;
              break;
            }
          }
        }
      );
      // 获取员工
      consumer_department_getEmployeeList({ companyId: val.companyId }).then(
        (res) => {
          this.moveDeptUserList = res.datas;
        }
      );
    },
    submitAdd() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
      });
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          consumer_department_addDepartment(this.deptParams)
            .then((res) => {
              this.loading.close();
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.getDepartmentList();
              this.dialogVisible = false;
            })
            .catch(() => {
              this.loading.close();
            });
        }
      });
    },
    submitEdit() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
      });
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          consumer_department_updateDepartment(this.deptParams)
            .then((res) => {
              this.loading.close();
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.getDepartmentList();
              this.dialogVisible = false;
            })
            .catch(() => {
              this.loading.close();
            });
        }
      });
    },
    changeItem(val) {
      // 判断如果当前部门没有员工就不能迁移
      if (val.strength === 0) {
        this.$message({
          message: "当前部门没有员工",
          type: "warning",
        });
        return;
      }
      let params = {
        companyId: val.companyId,
        deptId: val.deptId,
      };
      consumer_department_getDepartments(params).then((res) => {
        this.moveDeptList = res.datas.departmentVos;
      });
      this.reactMoveParams();
      this.moveParams.companyId = val.companyId;
      this.moveParams.deptId = val.deptId;
      this.dialogChangeVisible = true;
      this.$nextTick(() => {
        this.$refs["moveForm"].clearValidate();
      });
    },
    submitMove() {
      this.$refs["moveForm"].validate((valid) => {
        if (valid) {
          this.loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
          });
          consumer_department_moveDepartments(this.moveParams)
            .then((res) => {
              this.loading.close();
              this.$message({
                message: "迁移成功",
                type: "success",
              });
              this.dialogChangeVisible = false;
              this.getDepartmentList();
            })
            .catch(() => {
              this.loading.close();
            });
        }
      });
    },
    delItem(val) {
      if (val.childrenList && val.childrenList.length > 0) {
        this.$message({
          message: "请先删除此部门包含的子部门",
          type: "warning",
        });
        return;
      }
      this.reactDelParams();
      this.delParams = {
        deptId: val.deptId,
        strength: val.strength,
      };
      this.dialogDelVisible = true;
    },
    submitDel() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
      });
      consumer_department_deleteDepartment(this.delParams)
        .then((res) => {
          this.loading.close();
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.dialogDelVisible = false;
          this.getDepartmentList();
        })
        .catch(() => {
          this.loading.close();
        });
    },
    handlerExpand(val) {
      if (val.isExpand) {
        // 将要收起
        let index = this.expandIdList.indexOf(val.deptId);
        this.expandIdList.splice(index, 1);
      } else {
        // 将要展开
        this.expandIdList.push(val.deptId);
      }
      val.isExpand = !val.isExpand;
    },
    reactDeptParams() {
      this.deptParams = {
        companyId: "",
        deptLevel: "",
        name: "",
        parentId: "",
        userId: "",
        userName: "",
        deptDescribe: "",
        deptId: "",
        parentName: "",
      };
    },
    reactMoveParams() {
      this.moveParams = {
        companyId: "",
        deptId: "",
        targetDeptId: "",
      };
    },
    reactDelParams() {
      this.delParams = {
        deptId: "",
        strength: 0,
      };
    },
    changeDeptUser(val) {
      for (let i = 0, l = this.moveDeptUserList.length; i < l; i++) {
        if (this.moveDeptUserList[i].id === val) {
          this.deptParams.userName = this.moveDeptUserList[i].staffName;
        }
      }
    },
  },
};

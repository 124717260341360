const __request = require(`./__request/__request_contentType_json`);
// 火车票行程添加： http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/admin/addJourneyUsingPOST

const consumer_admin_trains_order_addJourney = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/trains/order/addJourney',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_trains_order_addJourney;
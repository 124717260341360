export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/train-change-search',
            name: 'admin-train-change-search',
            component: () => import(/* webpackChunkName: "page-admin-replace-orders-train-change-search" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `火车票搜索`,
                },
                entity_list_for_breadcrumb: [
                    {name: `火车票订单`},
                    {name: `火车票搜索`},
                ],
            },
        },
    ],
};

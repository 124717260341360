import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.2.1/index.vue';
import DynamicForm from '@/component/dynamicForm/import_latest_version_module'
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.8/index.vue';
import consumer_admin_flight_generate_evectionType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_evectionType'
import consumer_admin_flight_generate_psgType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_psgType'
import consumer_admin_flight_generate_payType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_payType'
import consumer_country_queryCountryTwoWord from '@/lib/data-service/haolv-default/consumer_country_queryCountryTwoWord';
import consumer_department_getStaffsAndExCusByUserIds from '@/lib/data-service/haolv-default/consumer_department_getStaffsAndExCusByUserIds'
import consumer_admin_trains_order_passengerCardType from '@/lib/data-service/haolv-default/consumer_admin_trains_order_passengerCardType'
import consumer_admin_flight_generate_getCompanyList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getCompanyList'
import consumer_flight_search_internationalCitys from '@/lib/data-service/haolv-default/consumer_flight_search_internationalCitys'
import defaultForm from './form';
import isMobile from 'yinzhilv-js-sdk/src/common-service/other/is_mobile';
import Q from 'q';
import tipsObj from './tips';
import DictionarySelect from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';
import consumer_admin_offline_car_save from '@/lib/data-service/haolv-default/consumer_admin_offline_car_save'
import consumer_admin_offline_car_details from "@/lib/data-service/haolv-default/consumer_admin_offline_car_details";
import consumer_admin_ship_details from "@/lib/data-service/haolv-default/consumer_admin_ship_details";   // 订单详情
export default {
    data() {
        return {
            // 当前所属企业的companyId
            companyIdCurrent: null,
            trainsReservationBox: [
                {msg: '12306登录预订',value: 1},
                {msg: '免登录预订',value: 2}
            ],
            loading: false,
            type: 'create', // 'create' 新建 'edit' 编辑
            orderNo: '',
            form: JSON.parse(JSON.stringify(defaultForm)),
            rules: {
                'companyInfo.companyId': [
                    { required: true, message: '请选择所属企业', trigger: 'change' },
                ],
                'companyInfo.contactName': [
                    { required: true, message: '请填写联系人', trigger: 'blur' },
                ],
                'companyInfo.contactMobile': [
                    { required: true, message: '请填写联系电话', trigger: 'blur' },
                    {validator: this.checkMobile, trigger: 'blur'}
                ],
                'companyInfo.evectionType': [
                    { required: true, message: '请选择出行类型', trigger: 'change' },
                ],
                'companyInfo.payType': [
                    { required: true, message: '请选择支付方式', trigger: 'change' },
                ],
            },
            orderStatus: '',
            // 签证国家List
            visaCountryList: [
                {
                    msg: '',
                    code: '',
                }
            ],
            // 航司
            airlineCodeList: [],
            // 证件类型List
            certificateTypeList: [],
            // 支付方式List
            payTypeList: [],
            // 出现类型List
            evectionTypeList: [],
            // 所属企业List
            companyList: [],
            // 乘客类型List
            psgTypeList: [],
            addNewPassengerLoading: false,
            submitLoading: false,
            selectedIdList: [],
            // 选择人组件当前在那种形态
            switchTraveler: '',
            // 附件
            accessoryUrl: [
                {
                    name: "",
                    url: "",
                },
            ],
            uploadIndex: 0,
            setOutAirlineCode: '',
            reachAirlineCode: '',
            switchThen: true,

            testBox: null,
            psgNameTest: '',
            arriveTime: '',
            textarea2: '',
        }
    },
    components: { TrainCitySelector, DynamicForm, TravelerMultipleSelector, DictionarySelect},
    created() {
    },
    mounted() {
        this.$refs.aTravelerMultipleSelector.init_parameter.btn_ok_click_event_handler = (args) => {
            args.selected_entity_list.forEach(value => {
                this.form.companyInfo.contactName = value.name;
                this.form.companyInfo.contactMobile = value.phone;
                this.$nextTick(() => {
                    this.$refs.form.validateField(['companyInfo.contactName', 'companyInfo.contactMobile'])
                })
            })
        };
    },
    async activated() {
        await this.init();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        'form.journeyInfo.carUseType'(newVal) {
            if (this.switchThen) {
                this.form.journeyInfo.setOut = '';
                this.form.journeyInfo.reach = '';
                this.setOutAirlineCode = '';
                this.reachAirlineCode = '';


                this.form.flightInfo.flightNumber = '';
                this.form.flightInfo.depAirportNo = '';
                this.form.flightInfo.depTerminal = '';
                this.form.flightInfo.arrAirportNo = '';
                this.form.flightInfo.arrTerminal = '';
                this.form.flightInfo.depTime = '';
                this.form.flightInfo.arrTime = '';
            } else {
                this.switchThen = true;
            }
        },
    },
    computed: {
        orderStatusToDisabled() {
            if (this.type == 'edit') {
                return true;
            }
            return false;
        },
        payToOrderStatusToDisabled() {
            // 线下用车编辑页面，当支付方式为授信支付或线下转账时，允许修改销售价
            if (this.type == 'edit' && this.form.companyInfo.payType == 2) {
                return true;
            }
            return false;
        },
    },
    filters: {},
    methods: {
        validatorDriverName(rule, value, callback) {
            let box = false;
            if (this.form.journeyInfo.driverName || this.form.journeyInfo.driverPhone || this.form.journeyInfo.carNumber) {
                box = true;
            } else {
                this.$refs.form.clearValidate()
            }
            if (box && !this.form.journeyInfo.driverName) {
                callback(new Error('请输入司机姓名'));
            }
            callback();
        },
        validatorDriverPhone(rule, value, callback) {
            let box = false;
            if (this.form.journeyInfo.driverName || this.form.journeyInfo.driverPhone || this.form.journeyInfo.carNumber) {
                box = true;
            } else {
                this.$refs.form.clearValidate()
            }
            if (box && !this.form.journeyInfo.driverPhone) {
                callback(new Error('请输入司机电话'));
            }
            callback();
        },
        validatorCarNumber(rule, value, callback) {
            let box = false;
            if (this.form.journeyInfo.driverName || this.form.journeyInfo.driverPhone || this.form.journeyInfo.carNumber) {
                box = true;
            } else {
                this.$refs.form.clearValidate()
            }
            if (box && !this.form.journeyInfo.carNumber) {
                callback(new Error('请输入车牌号'));
            }
            callback();
        },

        driverJudge(row) {
            let box = false;
            if (row.driverName || row.driverPhone || row.carNumber) {
                box = true;
            }
            return box;
        },
        // 只能输入英文或数字
        handleInput(item, value) {
            // 使用正则表达式匹配字母和数字
            const reg = /^[a-zA-Z0-9]+$/;
            // 如果输入值不符合规定的正则表达式，则将inputValue重置为上一个值
            if (!reg.test(value)) {
                this.form.order[item] = this.form.order[item].replace(/[^\a-\z\A-\Z0-9]/g, '');
            }
        },
        //只允许输入数字
        handleKeydown(e) {
            console.log(e.keyCode);
            // 允许输入数字、Backspace、Delete、左箭头、右箭头
            if (!((e.keyCode >= 48 && e.keyCode <= 57) || // 数字
                (e.keyCode >= 96 && e.keyCode <= 105) ||
                e.keyCode === 8 || e.keyCode === 46 || // Backspace/Delete
                (e.keyCode >= 37 && e.keyCode <= 40))) { // 方向键
                e.preventDefault();
            }
        },
        // 限制只能输入数字或小数点
        validateInput(item, value) {
            console.log(item, value)
            value = value.replace(/[^\d{1,}.\d{1,}|\d{1,}]/g, "");
            value = value.replace(/^(\-)*(\d+)\.(\d{2}).*$/, '$1$2.$3');
            this.form.order[item] = value;
        },
        companyBillChange() {
            let salesPriceBox = Number(this.form.order.salesPrice);
            let supPriceBox = Number(this.form.order.supPrice);
            let serviceFeeBox = Number(this.form.order.serviceFee);
            this.form.order.profit = (salesPriceBox - supPriceBox + serviceFeeBox).toFixed(2);
            this.$forceUpdate();
        },
        addSuccess(data) {
            //新增成功后触发
            Dictionary.init(this, {
                typeGroupKey: ['tmc'],
                typeKey: [],
                parentId: 0
            });
        },
        getPsgType() {
            consumer_admin_flight_generate_psgType().then(res => {
                this.psgTypeList = res.datas
            })
        },
        getPayTypeList() {
            consumer_admin_flight_generate_payType().then(res => {
                this.payTypeList = res.datas
            })
        },
        getEvectionTypeList() {
            consumer_admin_flight_generate_evectionType().then(res => {
                this.evectionTypeList = res.datas
            })
        },
        queryCountryTwoWord() {
            consumer_country_queryCountryTwoWord().then(res => {
                this.visaCountryList = res.datas
            })
        },
        async contactNameBlur() {
            await this.getCompanyList();
            if (this.companyList.length == 0) {
                const params = {
                    companyName: '',
                    currentPage: 1,
                    keyword: '',
                    pageSize: 999,
                    userName: '',
                };
                let res = await consumer_admin_flight_generate_getCompanyList(params);
                this.companyList = res.datas.list
            }
        },
        async getCompanyList() {
            const params = {
                companyName: '',
                currentPage: 1,
                keyword: '',
                pageSize: 999,
                userName: this.form.companyInfo.contactName,
            }
            let res = await consumer_admin_flight_generate_getCompanyList(params)
            this.companyList = res.datas.list
        },
        async init() {
            this.loading = true;
            this.form = JSON.parse(JSON.stringify(defaultForm));
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            });
            this.getEvectionTypeList();
            this.getCertificateType();
            this.getPayTypeList();
            this.getPsgType();
            this.queryCountryTwoWord();
            await this.getCompanyList();
            this.loading = false;
            const query = this.$route.query;
            this.type = query && query.type ? query.type : 'create';  // create edit
            let airportsBox = [];
            consumer_flight_search_internationalCitys().then(res => {
                res.citys.forEach((item,index) => {
                    if (item.airports && airportsBox.indexOf(item.name) == -1) {
                        airportsBox.push(item.name);
                        this.airlineCodeList.push(item);
                    }
                });
            });
            if (this.type == 'edit') {
                // 编辑
                let data = {
                    id: query.id,
                    orderNo: null,
                };
                consumer_admin_offline_car_details(data).then(res => {
                    if (res.code === '000000') {
                        this.form.order = res.datas.order;
                        this.form.order.profit = res.datas.profit;
                        this.form.companyInfo = res.datas.companyInfo;
                        this.form.journeyInfo = res.datas.journeyInfo;
                        this.form.flightInfo = res.datas.flightInfo ? res.datas.flightInfo : {
                            journeyId: null,
                            orderId: null,      //订单id
                            flightNumber: '',      //航班号
                            depAirport: '',      //起飞机场
                            depAirportNo: '',      //起飞机场--三字码
                            depTerminal: '',      //起飞航站楼
                            arrAirport: '',      //到达机场
                            arrAirportNo: '',      //到达机场--三字码
                            arrTerminal: '',      //到达航站楼
                            depTime: '',      //起飞时间
                            arrTime: '',      //到达时间
                        };
                        this.switchThen = false;
                        // this.form.companyBill = res.datas.companyBill;
                        // 行程时间回填
                        // this.form.order.journeyPeriod = res.datas.order.journeyPeriod.split('-');
                        if (this.form.journeyInfo.carUseType == 'sj') {
                            this.reachAirlineCode = this.form.flightInfo.depAirportNo;
                        }
                        if (this.form.journeyInfo.carUseType == 'jj') {
                            this.setOutAirlineCode = this.form.flightInfo.arrAirportNo;
                        }
                    }
                }).finally(() => {
                    this.loading = false
                });
            } else {

            }
        },
        // 出发--起飞
        changeDepAirportSetOut(val) {
            if (val) {
                this.airlineCodeList.forEach((item,index) => {
                    if (item.code == val) {
                        this.depAirportData = item;
                        if (this.form.journeyInfo.carUseType == 'jj') {
                            this.form.journeyInfo.setOut = `${this.depAirportData.cityName}(${this.depAirportData.cityCode}) ${this.depAirportData.name}`;
                            this.form.flightInfo.arrAirportNo = item.code;
                        }
                        this.form.flightInfo.arrAirport = `${this.depAirportData.cityName} ${this.depAirportData.name}`;
                    }
                });
            } else {
                this.form.flightInfo.arrAirport = '';
                this.form.flightInfo.arrAirportNo = '';
                this.form.journeyInfo.setOut = '';
            }
        },
        // 到达--到达
        changeDepAirportReach(val) {
            if (val) {
                this.airlineCodeList.forEach((item,index) => {
                    if (item.code == val) {
                        this.arrAirportData = item;
                        if (this.form.journeyInfo.carUseType == 'sj') {
                            this.form.journeyInfo.reach = `${this.arrAirportData.cityName}(${this.arrAirportData.cityCode}) ${this.arrAirportData.name}`;
                            this.form.flightInfo.depAirportNo = item.code;
                        }
                        this.form.flightInfo.depAirport = `${this.arrAirportData.cityName} ${this.arrAirportData.name}`;
                    }
                });
            } else {
                this.form.flightInfo.depAirport = '';
                this.form.flightInfo.depAirportNo = '';
                this.form.journeyInfo.reach = '';
            }
        },
        // 起飞
        changeDepAirport(val) {
            // 城市(城市码) 机场
            if (val) {
                this.airlineCodeList.forEach((item,index) => {
                    if (item.code == val) {
                        this.depAirportData = item;
                        if (this.form.journeyInfo.carUseType == 'sj') {
                            this.form.journeyInfo.reach = `${this.depAirportData.cityName}(${this.depAirportData.cityCode}) ${this.depAirportData.name}`;
                            this.reachAirlineCode = item.code;
                        }
                        this.form.flightInfo.depAirport = `${this.depAirportData.cityName} ${this.depAirportData.name}`;
                    }
                });
            } else if (this.form.journeyInfo.carUseType == 'sj') {
                this.form.flightInfo.depAirport = '';
                this.reachAirlineCode = '';
                this.form.journeyInfo.reach = '';
            }
        },
        // 到达
        changeArrAirport(val) {
            if (val) {
                this.airlineCodeList.forEach((item,index) => {
                    if (item.code == val) {
                        this.arrAirportData = item;
                        if (this.form.journeyInfo.carUseType == 'jj') {
                            this.form.journeyInfo.setOut = `${this.arrAirportData.cityName}(${this.arrAirportData.cityCode}) ${this.arrAirportData.name}`;
                            this.setOutAirlineCode = item.code;
                        }
                        this.form.flightInfo.arrAirport = `${this.arrAirportData.cityName} ${this.arrAirportData.name}`;
                    }
                });
            } else if (this.form.journeyInfo.carUseType == 'jj')  {
                this.form.flightInfo.arrAirport = '';
                this.setOutAirlineCode = '';
                this.form.journeyInfo.setOut = '';
            }
        },
        getCertificateType() {
            consumer_admin_trains_order_passengerCardType().then(res => {
                let certificateTypeList = res.datas
                certificateTypeList.forEach(value => {
                    value.value = parseInt(value.code)
                })
                this.certificateTypeList = certificateTypeList
            })
        },
        changeCompanyId(val) {
            let activeItem = null;
            this.companyList.forEach(value => {
                if (value.id === val) {
                    activeItem = value
                }
            });
            this.companyIdCurrent = val;
            this.form.companyInfo.companyName = activeItem.companyName;
            if (this.type === 'edit') {
                return;
            }
            // 更新初始化用户选择组件
            this.$refs.aTravelerMultipleSelector.init_parameter.get_pager_data_parameter = () => {
                const d = {
                    companyId: val,
                };
                return Q.resolve(d);
            };
            this.$refs.aTravelerMultipleSelector.init({
                dialog: {
                    title: `请选择出行人员`,
                    width: '1100px'
                },
                // select_mode: `single`,
                select_mode: `multiple`,
                // 场景
                // 000:代客下单,
                // 100:行程预订首页，选择出行人（1.有差标管控;2.能选择外部客户;）
                // 101:出差申请页面，选择出行人（1.没有差标管控;2.能选择外部客户;）
                // 102:出差申请页面，选择审批人员（1.没有差标管控;2.不能选择外部客户;）
                situation: `000`,
                // situation: `100`,
                // situation: `101`,
                // situation: `102`,
                businessType: 2,
            });
        },
        clearCompany() {

        },
        // 选择联系人
        addNewContacts() {
            if (!this.form.companyInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择所属企业'
                });
                return
            }
            this.$refs.aTravelerMultipleSelector.init({
                dialog: {
                    title: `请选择联系人`,
                    width: '1100px'
                },
                select_mode: `single`,
                situation: `000`,
                businessType: 2,
                pageIndex: 1,
            });
            this.selectedIdList = [];
            this.$refs.aTravelerMultipleSelector.show();
            this.switchTraveler = 'Contacts';
        },
        // 选择出行人
        addNewPassenger() {
            if (!this.form.companyInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择所属企业'
                });
                return
            }
            this.$refs.aTravelerMultipleSelector.init({
                dialog: {
                    title: `请选择出行人`,
                    width: '1100px'
                },
                select_mode: `multiple`,
                situation: `000`,
                businessType: 2,
                pageIndex: 1,
            });
            this.selectedIdList = [];
            this.$refs.aTravelerMultipleSelector.show();
            this.switchTraveler = 'Passenger';
        },
        // 保存
        onSubmit() {
            let isPass = false;
            let unValidObj = null;
            this.$refs.form.validate((valid, obj) => {
                isPass = valid;
                unValidObj = obj;
            });

            if (isPass === true) {
                this.addOrder();
            } else {
                let tipsStr = '';
                if (!isPass) {
                    const unValidKeys = Object.keys(unValidObj);
                    console.log(unValidKeys)
                    unValidKeys.forEach((item, index) => {
                        let tmpList = item.split('.');
                        let len = tmpList.length;

                        if (index > 0) {
                            tipsStr += '；</p>';
                        }
                        if (len === 1) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}`;
                        }
                        if (len === 2) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[1]]}`;
                        }
                        if (len === 3) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[1]].split('-')[0]}第${Number(tmpList[1]) + 1}行-${tipsObj[tmpList[1]].split('-')[1]}`;
                        }
                    });
                    tipsStr += '；</p>';
                }
                console.log(tipsStr)
                this.$alert(tipsStr, '以下信息未填写', {
                    confirmButtonText: '确定',
                    dangerouslyUseHTMLString: true,
                });
            }
        },
        addOrder() {
            let dataBox = {};
            if (this.form.journeyInfo.carUseType == 'sj' || this.form.journeyInfo.carUseType == 'jj') {
                dataBox = {
                    order: this.form.order,
                    companyInfo: this.form.companyInfo,
                    journeyInfo: this.form.journeyInfo,
                    flightInfo: this.form.flightInfo,
                };
            } else {
                dataBox = {
                    order: this.form.order,
                    companyInfo: this.form.companyInfo,
                    journeyInfo: this.form.journeyInfo,
                };
            }
            dataBox.order.paymentTime = dataBox.order.paymentTime.length == 16 ? `${dataBox.order.paymentTime}:00` : dataBox.order.paymentTime;
            dataBox.journeyInfo.useCarTime = dataBox.journeyInfo.useCarTime.length == 16 ? `${dataBox.journeyInfo.useCarTime}:00` : dataBox.journeyInfo.useCarTime;
            if (this.form.journeyInfo.carUseType == 'jj' || this.form.journeyInfo.carUseType == 'sj') {
                dataBox.flightInfo.depTime = dataBox.flightInfo.depTime.length == 16 ? `${dataBox.flightInfo.depTime}:00` : dataBox.flightInfo.depTime;
                dataBox.flightInfo.arrTime = dataBox.flightInfo.arrTime.length == 16 ? `${dataBox.flightInfo.arrTime}:00` : dataBox.flightInfo.arrTime;
            }

            this.submitLoading = true;
            this.loading = true;
            consumer_admin_offline_car_save(dataBox).then((res) => {
                this.$message({
                    type: "success",
                    message: '保存成功'
                });
                if (this.type == 'edit') {
                    this.$router.replace({
                        name: 'admin-offline-car-order-list'
                    })
                } else {
                    this.$router.push({
                        name: "admin-offline-car-create-order-pay",
                        query: {
                            id: res.datas.order.id,
                            orderNo: res.datas.order.orderNo,
                            behalfCompanyId: res.datas.companyInfo.companyId
                        }
                    })
                }
            }).catch((rej) => {
                this.$message.error(`${rej.msg}`);
            }).finally(() => {
                this.submitLoading = false;
                this.loading = false;
            });
        },
        back() {
            this.$router.go(-1)
        },
        greaterThanZero(rule, value, callback, tip) {
            if (Number(value) <= 0) {
                callback(new Error(`${tip}必须大于 0`));
            }
            callback();
        },
        greaterThanOrEqualToZero(rule, value, callback, tip) {
            if (Number(value) < 0) {
                callback(new Error(`${tip}不能为负数`));
            }
            callback();
        },
        changeEvectionType(val) {
            if (this.form.companyInfo.evectionType == 1) {
                this.form.companyInfo.payType = null;
                this.payTypeList = this.payTypeList.filter(item => item.msg !== '授信支付');
            }
            if (this.form.companyInfo.evectionType == 2 && this.payTypeList.indexOf({code: 1,msg: "授信支付"}) == -1) {
                this.payTypeList = [{code: 1,msg: "授信支付"},...this.payTypeList];
            }
        },
        changeCertificateType(item, index, val) {
            this.certificateTypeList.forEach(value => {
                if (value.value === val) {
                    item.cardTypeText = value.msg
                }
            })
        },
        cardNoValidator(rule, value, callback, index, item) {
            let cardType = item.cardType;
            const date = `${value.substr(6, 4)}-${value.substr(10, 2)}-${value.substr(12, 2)}`;
            if (cardType === 1) {
                if (this.isChineseIDCardNumber(value) === false) {
                    callback(new Error('请输入正确的身份证号码'));
                    return;
                } else {
                    this.$set(item, 'birthday', date);
                    callback();
                }
            } else {
                callback();
            }
            // this.$set(item, 'birthday', date);
        },
        inputValidator(rule, value, callback, index, item) {
            if (value == 0) {
                callback(new Error('请输入大于0的数'));
                return;
            }
            callback();
        },
        // 身份证号码验证
        isValidDate(year, month, day) {
            const date = new Date(year, month - 1, day);
            return (
                date.getFullYear() === year
                && date.getMonth() + 1 === month
                && date.getDate() === day
                && date.getTime() < new Date().getTime()
            );
        },
        isChineseIDCardNumber(value) {
            const testRegExp = /^[1-9]([0-9]{14}|[0-9]{16}[0-9Xx])$/;
            const areaMap = [11, 12, 13, 14, 15, 21, 22, 23, 31, 32, 33, 34, 35, 36, 37, 41, 42, 43, 44, 45, 46, 50, 51, 52, 53, 54, 61, 62, 63, 64, 65, 71, 81, 82];
            const weightMap = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            const codeMap = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
            const len = value.length;
            if (len !== 18) {
                return false;
            }
            // 模式校验
            if (!testRegExp.test(value)) {
                return false;
            }
            // 地区校验
            if (areaMap.indexOf(+value.substr(0, 2)) === -1) {
                return false;
            }
            // 18 位
            if (!this.isValidDate(+value.substr(6, 4), +value.substr(10, 2), +value.substr(12, 2))) {
                return false;
            }
            // 校验码
            const sum = value.split('').slice(0, 17).reduce((s, num, index) => {
                return s += +num * weightMap[index];
            }, 0);
            return codeMap[sum % 11] === value[17].toUpperCase();
        },
        inputCardNo() {},
        checkMobile(rule, value, callback) {
            if (!isMobile(value)) {
                callback(new Error('只能输入长度不能大于11的数字'));
                return
            }
            callback();
        },
    }
}

import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'
import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'

import consumer_admin_hotel_order_partialCancelList from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_partialCancelList";
// 开票方式类型枚举接口
import consumer_admin_hotel_order_queryHotelOrderListByIfInvoice from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_queryHotelOrderListByIfInvoice'
import consumer_admin_hotel_order_getHotelBusinessClassEnum
  from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_getHotelBusinessClassEnum'
// 订单来源枚举接口
import consumer_admin_hotel_order_getOrderSourceEnum from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_getOrderSourceEnum'
// 订单渠道枚举接口
import consumer_admin_hotel_order_getOrderChannelEnum from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_getOrderChannelEnum'
// 平台酒店订单列表
import consumer_admin_hotel_order_orderList from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_orderList"
// 获取规则外退订数量
import consumer_admin_hotel_order_partialCancelPending from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_partialCancelPending"
// 取消订单-酒店
import req_cancel_order from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_cancelOrder"
// 导出
import consumer_admin_hotel_order_hotelListReportExcel from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_hotelListReportExcel'
// 消息模板
import consumer_admin_hotel_copyMessageTemplate from '@/lib/data-service/haolv-default/consumer_admin_hotel_copyMessageTemplate'
import CopyReportPopup from '@/component/copyReportPopup/import_latest_version_module'

export default {
  data() {
    return {
      outsideRulesNum: 0,
      fileUrl: '',
      loading: false,
      createTime: [],   //下单日期
      searchForm: {
        orderNo: '',   //订单编号
        supplierOrderNo: '',   //供应商订单号
        evectionUserName: '',   //旅客姓名/出行人名字
        certificatesNumber: '',   //证件信息
        orderSource: 0,//订单来源
        orderChannel: 0,//订单渠道
        ifInvoice: 0,//开票方式
        businessClassCode: -1,//业务类别
        businessClass: '',//业务类别
        isBehalf: '',   //是否代客下单 1-是,0-否
        orderTimeStart: '',   //下单开始日期
        orderTimeEnd: '',   //下单结束日期
        checkInDate: '',//入住时间
        companyName: '',   //公司id
        evectionType: 0,   //预定方式：0：全部 1：因私，2：因公
        status: null,   //订单状态
        hotelName: '',   //酒店名称
        initialName: '',   //操作客服
        realName: '',
        tagId: '',//标签
        searchPartialCancel: 0,    // 是否查询规则外退订单 查传：1"

        currentPage: 1,   //当前页码
        pageSize: 10   //每页记录条数
      },
      totalCount: 0,   //总条数
      orderSourceList: [],

      behalfOption: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '是'
        },
        {
          value: 0,
          label: '否'
        }
      ],
      evectionTypeOption: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 2,
          label: '因公'
        },
        {
          value: 1,
          label: '因私'
        }
      ],

      activeName: '-1',
      tabList: [
        // 全部订单/超标审批中/待支付/待确认/已出房/出房失败/退订中/退订成功/退订失败/已取消
        {
          name: -1,
          label: '全部订单',
          value: 0
        },
        {
          name: 18,
          label: '超标审批中',
          value: 0
        },
        {
          name: 0,
          label: '待支付',
          value: 0
        },
        {
          name: 2,
          label: '待确认',
          value: 0
        },
        {
          name: 11,
          label: '已出房',
          value: 0
        },
        {
          name: 14,
          label: '出房失败',
          value: 0
        },
        {
          name: 15,
          label: '退订中',
          value: 0
        },
        {
          name: 16,
          label: '退订成功',
          value: 0
        },
        {
          name: 13,
          label: '退订失败',
          value: 0
        },
        {
          name: 6,
          label: '已取消',
          value: 0
        },
        // {
        //   name: -1,
        //   label: '全部订单',
        //   value: 0
        // },
        // {
        //   name: 0,
        //   label: '待支付',
        //   value: 0
        // },
        // {
        //   name: 2,
        //   label: '已支付，待确认',
        //   value: 0
        // },
        // {
        //   name: 11,
        //   label: '已确认',
        //   value: 0
        // },
        // {
        //   name: 14,
        //   label: '已拒单',
        //   value: 0
        // },
        // {
        //   name: 21,
        //   label: '线下退订处理中',
        //   value: 0
        // },
        // {
        //   name: 22,
        //   label: '线下部分退款',
        //   value: 0
        // },
        // {
        //   name: 23,
        //   label: '线下全部退款',
        //   value: 0
        // },
      ],
      tabNumberList: [],
      tableLoading: false,
      orderList: [],
      orderChannelList: [
        {
          code: 0,
          msg: '全部'

        },
        {
          code: 1,
          msg: '农行api'
        }
      ],
      ifInvoiceList: [],
      businessClassList: [],
      tagList: [
        {
          tagId: '',
          tagName: '全部'
        },
        {
          tagId: 1,
          tagName: 'VP'
        }
      ],
      copyDialog: false,
      copyLoading: false,
      copyTextarea: '',
    }
  },
  components: {
    SearchBox,
    Pagination,
    RouterLinkButton,
    CopyReportPopup
  },
  activated() {
    this.getOrderList();
    this.getByIfInvoice();
    this.getBusinessClassList();
    this.getOrderSourceEnum();
    // this.getOrderChannelEnum();
  },
  methods: {
    clickOutsideCancel() {
      this.loading = true;
      consumer_admin_hotel_order_partialCancelList({
        companyName: "",
        currentPage: 1,
        evectionType: 0,
        hotelName: "",
        initialName: "",
        isBehalf: "",
        orderNo: "",
        orderTimeEnd: "",
        orderTimeStart: "",
        pageSize: 1,
        person: "",
        resultFlag: "-2",
        thirdpartyOrderNo: "",
        totalCount: 0
      }).then(res => {
        this.$router.push({
          name: 'admin-hotel-order-non-rule-cancel-list',
          query: {
            type: -1,
          }
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    // 导出
    toPreview(){
      this.loading = true;
      const _http = document.location.protocol + '//';
      consumer_admin_hotel_order_hotelListReportExcel(this.searchForm).then(res => {
        this.fileUrl = _http + res.datas;
        this.$nextTick(() => {
          this.$refs.downloadBtn.click()
        })
        this.loading = false;
      });
    },
    //后台生单
    toRecordOrder() {
      this.$router.push({
        name: "admin-hotel-create-order",
        query: {
          type: 'create'
        }
      })
    },
    //平台酒店订单列表
    async getOrderList() {
      try {
        this.tableLoading = true;

        consumer_admin_hotel_order_partialCancelPending({}).then(res => {
          this.outsideRulesNum = res.datas;
        });

        const res = await consumer_admin_hotel_order_orderList(this.searchForm);
        this.tabNumberList = res.hotelOrderNumberResults;
        if(this.tabNumberList) {
          this.tabList.forEach(tab => {
            this.tabNumberList.forEach(tabNumber => {
              if (tab.name === tabNumber.status) {
                tab.value = tabNumber.orderNumber
              }
            })
          });
        }

        this.orderList = res.pageResult.pageData;
        this.totalCount = res.pageResult.totalCount;

        if(this.orderList) {
          this.orderList.forEach(item => {
            if (item.orderContent) {
              let str1 = item.orderContent.replace(' br ', '<br/>');
              let str2 = str1.replace(' br ', ' ');
              item.orderContent = str2;
            }
          })
        }
      } catch (error) {
        this.orderList = [];
        this.totalCount = 0;
        this.$message({
          type: "error",
          message: "获取酒店订单列表失败！"
        })
      } finally {
        this.$nextTick(() => {
          this.$refs.mainTable.doLayout()
        })
        this.tableLoading = false
      }
    },
    //搜索
    onSearch() {
      this.searchForm.currentPage = 1
      this.searchForm.status = this.activeName === '-1' ? null : parseInt(this.activeName)
      this.getOrderList()
    },
    //重置
    onReset() {
      this.searchForm = {
        orderNo: '',   //订单编号
        supplierOrderNo: '',   //供应商订单号
        evectionUserName: '',   //旅客姓名/出行人名字
        certificatesNumber: '',   //证件信息
        orderSource: 0,//订单来源
        orderChannel: 0,//订单渠道
        ifInvoice: 0,//开票方式
        businessClassCode: -1,//业务类别
        businessClass: '',//业务类别
        isBehalf: '',   //是否代客下单 1-是,0-否
        orderTimeStart: '',   //下单开始日期
        orderTimeEnd: '',   //下单结束日期
        checkInDate: '',//入住时间
        companyName: '',   //公司id
        evectionType: 0,   //预定方式：0：全部 1：因私，2：因公
        status: null,   //订单状态
        hotelName: '',   //酒店名称
        initialName: '',   //操作客服
        tagId: '',//标签
        realName: '',
        searchPartialCancel: 0,  // 是否查询规则外退订单

        currentPage: 1,   //当前页码
        pageSize: 10   //每页记录条数
      };
      this.createTime = [];   //下单日期
      this.onSearch()
    },
    getOrderChannelEnum(){
      consumer_admin_hotel_order_getOrderChannelEnum().then(res => {
        this.orderChannelList = res.datas;
      });
    },
    getOrderSourceEnum() {
     // 订单来源
      consumer_admin_hotel_order_getOrderSourceEnum().then(res => {
        this.orderSourceList = res.datas;
      });
    },
    getByIfInvoice() {
      // 开票方式
      consumer_admin_hotel_order_queryHotelOrderListByIfInvoice().then(res => {
        this.ifInvoiceList = res.datas;
      });
    },

    //获取业务类别枚举值
    async getBusinessClassList() {
      let res = await consumer_admin_hotel_order_getHotelBusinessClassEnum();
      let list = res.datas;
      let category = {};
      let businessClassList = [
        {
          code: -1,
          msg: '全部'
        }
      ];
      list.forEach((item, index) => {
        category = {
          code: index,
          msg: item
        }
        businessClassList.push(category)
      });
      this.businessClassList = businessClassList;
    },

    //改变业务类别code时
    changeBusinessClassCode() {
      let msg = this.businessClassList.find(item => {
        return item.code === this.searchForm.businessClassCode;
      }).msg;
      this.searchForm.businessClass = msg === '全部' ? '' : msg;
    },

    getEvectionTypeToClass(val) {
      //出差单类型：1.因私，2.因公
      switch (val) {
        case (1):
          return 'grey'
        case (2):
          return 'orange'
        default: return ''
      }
    },
    changeCreateTime(val) {
      if(!val) {
        this.searchForm.orderTimeStart = 0;
        this.searchForm.orderTimeEnd = 0;
      } else {
        this.searchForm.orderTimeStart = val[0] + ' 00:00:00';
        this.searchForm.orderTimeEnd = val[1] + ' 23:59:59';
      }
    },
    //查看订单
    handleDetailClick(val) {
      return {
        name: 'admin-hotel-order-sold-detail',
        query: {
          id: val.orderNo,
          orderSource: val.orderSource
        }
      }
    },
    //取消订单
    handleCancelClick(val) {
      this.$confirm("是否确认取消订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.loading = true;
        req_cancel_order({
          orderNo: val.orderNo,
          cancelRemark: ""
        }).then((res) => {
          this.$message({
            type: 'success',
            message: '取消订单成功！'
          })
          this.getOrderList()
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })

      }).catch(() => { })
    },
    //去支付
    handlePayClick(val) {
      // 判断是线上还是线下
      if (val.orderType !== 3) {
        this.$router.push({
          name: "admin-hotel-payment",
          query: {
            orderNo: val.orderNo,
            orderType: 3,
            pageType: 2
          }
        })
      } else {
        // 线下单支付
        this.$router.push({
          name: "admin-hotel-create-order-pay",
          query: {
            orderNo: val.orderNo,
            behalfCompanyId: val.companyId
          }
        })
      }
    },
    //退订
    handleRefundClick(val) {
      this.$router.push({
        name: "admin-hotel-order-refund",
        query: {
          orderNo: val.orderNo
        }
      })
    },
    //线下退订
    handleOfflineRefundClick(val) {
      this.$router.push({
        name: "admin-hotel-order-offline-cancel-handle",
        query: {
          orderNo: val.orderNo
        }
      })
    },
    //编辑线下单
    handleEditClick(val) {
      this.$router.push({
        name: "admin-hotel-create-order",
        query: {
          type: 'edit',
          orderNo: val.orderNo,
          orderSource: val.orderSource,
        }
      })
    },
    //确认出房
    handleConfirmRoomClick(val) {
      this.$router.push({
        name: "admin-hotel-confirm-room",
        query: {
          type: 'edit',
          orderNo: val.orderNo
        }
      })
    },
    //消息模板
    handleMessage(val) {
      this.copyDialog = true;
      this.copyLoading = true;
      this.copyTextarea = '';
      // 调接口
      consumer_admin_hotel_copyMessageTemplate({orderNo: val.orderNo}).then(res => {
        const datas = res.datas;
        let message = '';
        message += `预定成功：\n`;
        datas.hotelPsgList.forEach((value, index) => {
          message = message + '入住人' + (index+1) + '：' + value + '\n';
        })
        message = message + '入住：' + datas.checkInDate + '\n';
        message = message + '离店：' + datas.checkOutDate + '  共' + datas.roomNum + '间' + datas.stayNights + '晚' + '\n';
        message = message + '酒店：' + datas.hotelName + '\n';
        message = message + '房型：' + datas.hotelRoomTypeName + '\n';
        message = message + '酒店地址：' + datas.hotelAddress + '  ' + '酒店电话：' + datas.hotelPhone + '\n';
        message += `请入住人持证件到酒店前台登记入住。\n`;
        this.copyTextarea = message
      }).finally(() => {
        this.copyLoading = false;
      })
    },
    handleCopy(val) {
      this.$router.push({
        name: "admin-hotel-create-order",
        query: {
          type: 'copy',
          orderNo: val.orderNo,
          orderSource: val.orderSource,
        }
      })
    },
  }
}

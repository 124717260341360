export default {
  path: '',
  component: () => import(`@/component/admin-layout/index.vue`),
  children: [
    {
      path: '/admin/test',
      name: 'admin-test',
      component: () => import(/* webpackChunkName: "page-admin-default-test" */ `./index.vue`),
      meta: {
        entity_for_history_tag: {
          name: ``,
        },
        entity_list_for_breadcrumb: [
          {name: ``},
          {name: ``},
        ],
      },
    },
  ],
};

import _ from 'underscore';

import OrderLog from '@/component/orderLog/2.0.0/index.vue'
import moment from "moment";

import consumer_admin_flight_equity_getOrderProductInfo from '@/lib/data-service/haolv-default/consumer_admin_flight_equity_getOrderProductInfo'
import consumer_admin_flight_change_offlineOrderDetails from '@/lib/data-service/haolv-default/consumer_admin_flight_change_offlineOrderDetails'
import consumer_admin_flight_change_offlineSubmission from '@/lib/data-service/haolv-default/consumer_admin_flight_change_offlineSubmission'
import consumer_admin_flight_generate_cabinRank from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_cabinRank'
import consumer_admin_flight_changeOfflineSegment from '@/lib/data-service/haolv-default/consumer_admin_flight_changeOfflineSegment'
import consumer_flight_report_order_offlineOutPayType from '@/lib/data-service/haolv-default/consumer_flight_report_order_offlineOutPayType'
import consumer_air_ticket_refChaReasonEnum from "@/lib/data-service/haolv-default/consumer_air_ticket_refChaReasonEnum";
import Decimal from "decimal.js";



export default {
    data() {
        return {
            orderProductInfo: {},
            dialogEnjoyMore: false,
            loading: false,
            startTimeOptions: {
                disabledDate: (time) => {
                    return time.getTime() <= new Date()- 8.64e7;
                }
            },
            segmentsList: [],
            paymentTypeList: [
                {
                    code:0,
                    msg:'支付宝',
                },
                {
                    code:1,
                    msg:'网银',
                }
            ],//支付方式枚举值
            ruleSegments: {
                changFlightNo: [{required: true, validator: this.changFlightNoRule, trigger: 'blur'}],
                depDateBox: [{required: true, validator: this.depDateBoxRule, trigger: 'blur'}],
                arrDateBox: [{required: true, validator: this.arrDateBoxRule, trigger: 'blur'}],
                cabinCode: [{required: true, validator: this.cabinCodeRule, trigger: 'blur'}],
                cabinRank: [{required: true, validator: this.cabinRankRule, trigger: 'change'}],

                realReason: [{required: true, validator: this.chaReasonRule, trigger: 'blur'}],
                appTime: [{required: true, validator: this.applicationDateBoxRule, trigger: 'blur'}],
                successTime: [{required: true, validator: this.successTimeBoxRule, trigger: 'blur'}],

                supplementary: [{required: true, validator: this.supplementaryRule, trigger: 'blur'}],
                changeprice: [{required: true, validator: this.changepriceRule, trigger: 'blur'}],
                changeFeePrice: [{required: true, validator: this.changeFeePriceRule, trigger: 'blur'}],
                newTicketNo: [{required: true, validator: this.newTicketNoRule, trigger: 'blur'}],
                newPnr: [{required: true, validator: this.newPnrRule, trigger: 'blur'}],
                supplierOrderNo: [{required: true, validator: this.supplierOrderNoRule, trigger: 'blur'}],
                supplierChangeFee: [{required: true, validator: this.supplierChangeFeeRule, trigger: 'blur'}],

            },
            //订单信息
            OrderData: {},
            chaPsgCheckbox: [],
            comment: '', //备注

            cabinRankList: [],

            form: {
                sourceType: 1
            },
            formRule: {
                newTicketNo: [
                    {required: true, message: '请输入新票号', trigger: 'blur'}
                ],
                newPnr: [
                    {required: true, message: '请输入新PNR', trigger: 'blur'}
                ]
            },
            changeInfo: {
                realReason: '',
                realReasonText: '',
                chaReasonText: '',
                chaReason: 1,
                supplementary: 1,
                changeprice: 0,
                changeFeePrice: 0,
                supplierOrderNo: '',
                supplierChangeFee: 0,
                appTime: '',
                successTime: '',
                total: '',
            },
            activePsgIdList: [], // 选中的人的id
            activeSegmentNoList: [], // 选中的行程id
            refReasonEnum: [],
        }
    },
    components: {
        OrderLog,
    },
    created() {
    },
    mounted() {
        this.getRefReasonEnum();
        this.getCabinRank();
    },
    activated() {
        this.getReset();
        this.getData();
        this.getPaymentTypeList();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        clickEnjoyMore() {
            consumer_admin_flight_equity_getOrderProductInfo({orderNo: this.$route.query.orderNo}).then((res) => {
                this.orderProductInfo = res.datas;
            });
            this.dialogEnjoyMore = true;
        },
        getRefReasonEnum() {
            consumer_air_ticket_refChaReasonEnum({'bizType': 2}).then(res=>{
                let refReasonEnum = res.datas;
                refReasonEnum.forEach(value=>{
                    let text = '';
                    if (value.reasonType === 1) {
                        text+= '个人原因：'
                    }
                    if (value.reasonType === 2) {
                        text+= '航司原因：'
                    }
                    text+= value.msg;
                    text+=value.chaReason === 1 ? '（自愿改签）' : '（非自愿改签）'
                    console.log(text);
                    value.text = text;
                })
                this.refReasonEnum = refReasonEnum;
            })
        },
        changeReason(val) {
            console.log(val)
            this.refReasonEnum.forEach(value=>{
                if (value.code === val) {
                    this.changeInfo.chaReason = value.chaReason;
                    this.changeInfo.chaReasonText = value.chaReason === 1 ? '自愿改签' : '非自愿改签';
                    this.changeInfo.realReasonText = value.msg
                }
            })
        },
        depDateBoxDisableDate(time,val){
          // if (val.change.arrDateBox) {
          //   let oldTime = val.change.arrDateBox;
          //   return time.getTime() > new Date(oldTime).getTime();
          // }
            return false
        },
        arrDateBoxDisableDate(time,val){
          if (val.change.depDateBox) {
            let oldTime = val.change.depDateBox;
            return time.getTime() < new Date(oldTime).getTime() - 8.64e7;
          }
        },
        changeSupplementary(){
          this.changeInfo.changeprice = this.form.sourceType == 2 ? '0' : '';
          this.changeInfo.changeFeePrice = '';
          this.changeInfo.supplierChangeFee = '0';
          this.$refs.ruleForm.clearValidate();
        },
        getCabinRank() {
            consumer_admin_flight_generate_cabinRank().then(res => {
                this.cabinRankList = res.datas
            })
        },
        //重置
        getReset(){
            this.form = {
                sourceType: 1
            };
            this.changeInfo = {
                realReason: '',
                realReasonText: '',
                chaReasonText: '',
                chaReason: 1,
                supplementary: 1,
                changeprice: 0,
                changeFeePrice: 0,
                supplierOrderNo: '',
                supplierChangeFee: 0,
                appTime: '',
                successTime: '',
                total: '',
            };
            this.activePsgIdList = [];
            this.activeSegmentNoList = [];

        },

        //获取支付方式枚举值
        async getPaymentTypeList() {
            let res = await consumer_flight_report_order_offlineOutPayType();
            this.paymentTypeList = res.datas;
        },

        changFlightNoRule(rule, value, callback) {
            if (!value) {
                callback(new Error('请填写改签航班号'))
            }
            callback()
        },
        depDateBoxRule(rule, value, callback) {
            if (value == '') {
                callback(new Error('请选择改签起飞时间'))
            }
            callback()
        },
        arrDateBoxRule(rule, value, callback) {
            if (value == '') {
                callback(new Error('请选择改签到达时间'))
            }
            callback()
        },
        cabinRankRule(rule, value, callback) {
            console.log('111', value)
            if (value == '' || value == null) {
                callback(new Error('请选择舱位等级'))
            }
            callback()
        },
        cabinCodeRule(rule, value, callback) {
            if (!value) {
                callback(new Error('请填写改签舱位'))
            }
            callback()
        },
        chaReasonRule(rule, value, callback) {
            if (!value) {
                callback(new Error('请选择改签原因'))
            }
            callback()
        },
        applicationDateBoxRule(rule, value, callback) {
            if (value == '') {
                callback(new Error('请选择申请改签时间'))
            }
            callback()
        },
        successTimeBoxRule(rule, value, callback) {
            if (value == '') {
                callback(new Error('请选择改签完成时间'))
            }
            callback()
        },
        supplementaryRule(rule, value, callback) {
            if (!value) {
                callback(new Error('请选择改签是否需补款'))
            }
            callback()
        },
        changepriceRule(rule, value, callback) {
            if (value === '' && this.changeInfo.supplementary == 1 && this.form.sourceType !== 2) {
                callback(new Error('请填写改签差价'))
            }
            callback()
        },
        changeFeePriceRule(rule, value, callback) {
            if (this.changeInfo.supplementary == 2) {
              return callback();
            }
            if (value == '' || value == null || value == undefined) {
                callback(new Error('请填写改签手续费'))
            }
            if (value < 0) {
                callback(new Error('改签手续费不能小于0'))
            }
            callback()
        },
        newTicketNoRule(rule, value, callback) {
            if (!value) {
                callback(new Error('请填写新票号'))
            }
            callback()
        },
        newPnrRule(rule, value, callback) {
            if (!value) {
                callback(new Error('请填写新PNR'))
            }
            callback()
        },
        supplierOrderNoRule(rule, value, callback) {
            if (!value) {
                callback(new Error('请填写供应商改签订单号'))
            }
            callback()
        },
        supplierChangeFeeRule(rule, value, callback) {
            if (!value) {
                callback(new Error('请填写供应商改签费'))
            }
            callback()
        },
        //改签起飞日期时间
        changeDepDateBox(item, index, val) {
            let time = moment(val).format('HH:mm');
            let date = moment(val).format('yyyy-MM-DD');
            item.change.depDate = date;
            item.change.depTime = time;

            if (val === null) {
                item.change.arrDateBox = '';
                item.change.arrDate = '';
                item.change.arrTime = '';
            }
        },
        //改签到达时间
        changeArrDateBox(item, index, val) {
            let time = moment(val).format('HH:mm');
            let date = moment(val).format('yyyy-MM-DD');
            item.change.arrDate = date;
            item.change.arrTime = time;
        },
        //申请改签时间
        // changeApplicationDateBox(val) {
        //     let DateTime = moment(val).format('yyyy-MM-DD HH:mm:ss');
        //     console.log(DateTime)
        //     this.changeInfo.applicationDate = DateTime;
        // },
        //改签完成时间
        // changeSuccessTimeBox(val) {
        //     let DateTime = moment(val).format('yyyy-MM-DD HH:mm:ss');
        //     this.changeInfo.successTime = DateTime;
        // },
        changeSelectCabin(val){
            console.log(val);
            // this.$refs.ruleSegmentsList.validateField('cabinRank');
        },

        //请求数据
        async getData() {
            this.loading = true;
            let data = {
                orderNo: this.$route.query.orderNo,
            };
            consumer_admin_flight_change_offlineOrderDetails(data).then(res => {
                this.segmentsList = res.datas.segmentsList;
                let form = res.datas;
                form.chaPsgList.forEach(value => {
                    value.newTicketNo = '';
                    value.newPnr = '';
                });
                form.refundsegmentsList = [];
                this.form = form;

                // 机票改签-国际机票发起改签页面的供应商改签费默认为空，不要显示0
                this.changeInfo.supplierChangeFee = this.form.sourceType === 1 ? 0 : '';

                this.loading = false;
                if (this.form.chaPsgList.length != 0) {
                    // 无操作
                } else {
                    this.$alert('当前无可改签乘客', '提示', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.$router.go(-1);
                        }
                    });
                }

                this.OrderData = {
                    //订单号
                    orderNo: res.datas.orderNo,
                    //供应商订单号
                    supplierOrderNo: res.datas.supplierOrderNo,
                    //订单来源
                    orderSource: res.datas.orderSource,
                    // 订单金额
                    payAmount: res.datas.payAmount,
                    // 供应商采购价
                    supTotalPay: res.datas.supTotalPay,
                    // 订单状态
                    orderStatusText: res.datas.orderStatusText,
                    // 支付方式
                    paymentChannel: res.datas.paymentChannel,
                    // 出行类型
                    evectionTypeText: res.datas.evectionTypeText,
                    // 航程类型
                    flightTypeText: res.datas.flightTypeText,
                    // 客户名称
                    companyName: res.datas.companyName,
                    // 下单客服
                    platformUserName: res.datas.platformUserName,
                    // 下单时间
                    orderTime: res.datas.orderTime,
                    // 联系人
                    contactName: res.datas.contactName,
                    // 联系电话
                    contactMobile: res.datas.contactMobile,
                    // 出差单号
                    evectionNo: res.datas.evectionNo,
                    // 加享产品
                    productInfo: res.datas.productInfo,
                };

                this.$nextTick(() => {
                    this.$refs.psgForm.resetFields();
                    // this.$refs.segmentForm.resetFields();
                    this.$refs.ruleForm.resetFields();
                })
            });

        },
        checkPsgForm() {
            return new Promise(resolve => {
                this.$refs.psgForm.validate((valid, obj) => {
                    resolve({valid, obj})
                })
            })
        },
        checkSegmentForm() {
            return new Promise(resolve => {
                this.$refs.segmentForm.validate((valid, obj) => {
                    resolve({valid, obj})
                })
            })
        },
        checkChangeForm() {
            return new Promise(resolve => {
                this.$refs.ruleForm.validate((valid, obj) => {
                    resolve({valid, obj})
                })
            })
        },
        //保存按钮
        async clickKeep() {
            // 判断选人了没
            if (this.activePsgIdList.length === 0) {
                this.$message({
                    type: "warning",
                    message: '请选择改签的乘机人'
                })
                return;
            }
            // 判断选了行程没
            if (this.activeSegmentNoList.length === 0) {
                this.$message({
                    type: "warning",
                    message: '请选择改签的航班'
                })
                return;
            }
            let checkPsgFormRes = await this.checkPsgForm();
            if (!checkPsgFormRes.valid) {
                let unValidObj = checkPsgFormRes.obj;
                document.getElementById(Object.keys(unValidObj)[0]).scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block:    "start"  // 上边框与视窗顶部平齐。默认值
                });
                // this.$message.error('请输入改签乘客信息');
                document.getElementsByClassName("row-router-view")[0].scrollTop = 100;
                return
            }
            let checkSegmentFormRes = await this.checkSegmentForm();
            if (!checkSegmentFormRes.valid) {
                let unValidObj = checkSegmentFormRes.obj;
                document.getElementById(Object.keys(unValidObj)[0]).scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block:    "start"  // 上边框与视窗顶部平齐。默认值
                });
                return
            }
            let checkChangeFormRes = await this.checkChangeForm();
            if (!checkChangeFormRes.valid) {
                let unValidObj = checkChangeFormRes.obj;
                document.getElementById(Object.keys(unValidObj)[0]).scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block:    "start"  // 上边框与视窗顶部平齐。默认值
                });
                return
            }
            let form = {
                comment: this.comment,
                orderNo: this.$route.query.orderNo,
                passengerNoList: [],
                refundsegmentsList: [],
                signingFee: JSON.parse(JSON.stringify(this.changeInfo)),
            }
            this.form.chaPsgList.forEach(value => {
                if (this.activePsgIdList.indexOf(value.psgNo) > -1) {
                    let item = {
                        //changeFeePrice: this.changeInfo.changeFeePrice,
                        //changeprice: this.changeInfo.changeprice,
                        newPnr: value.newPnr,
                        newTicketNo: value.newTicketNo,
                        psgNo: value.psgNo,
                        //supplementary: this.changeInfo.supplementary,
                        //supplierChangeFee: this.changeInfo.supplierChangeFee,
                        //supplierOrderNo: this.changeInfo.supplierOrderNo
                    }
                    form.passengerNoList.push(item)
                }
            })
            this.form.refundsegmentsList.forEach(value => {
                if (this.activeSegmentNoList.indexOf(value.segmentNo) > -1) {
                    let item = {
                        applicationDate: this.changeInfo.applicationDate,
                        arrDate: value.change.arrDate,
                        arrTime: value.change.arrTime,
                        cabinCode: value.change.cabinCode,
                        cabinRank: value.change.cabinRank,
                        changFlightNo: value.change.changFlightNo,
                        depDate: value.change.depDate,
                        depTime: value.change.depTime,
                        oldOrderNo: value.oldOrderNo,
                        oldSegmentNo: value.segmentNo,
                        successTime: this.changeInfo.successTime,
                        realReason: this.changeInfo.realReason,
                        realReasonText: this.changeInfo.realReasonText,
                        chaReasonText: this.changeInfo.chaReasonText,
                        chaReason: this.changeInfo.chaReason,
                    }
                    form.refundsegmentsList.push(item);
                }
            })
            this.loading = true;
            consumer_admin_flight_change_offlineSubmission(form).then(res => {
                if (res.code == '000000') {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.loading = false;
                        this.$router.push({
                            name: 'admin-flight-change-offline-confirm',
                            query: {
                                chaOrderNo: res.datas.chaOrderNo,
                            },
                        })
                    },1000)
                } else {
                    this.$message.error('保存失败');
                }
            }).catch(() => {
                this.loading = false;
            });

        },
        clickGoBack() {
            this.$router.go(-1);
        },


        psgSelectable(row, index) {
            return row.isChange === 1
        },
        handleSelectionChange(val) {
            let activePsgIdList = [];
            if (val && val.length > 0) {
                val.forEach(value => {
                    activePsgIdList.push(value.psgNo);
                })
            }
            this.activePsgIdList = activePsgIdList;
            this.$nextTick(() => {
                // 清空输入框错误

                // 判断是否有选中人
                if (!this.activePsgIdList || this.activePsgIdList.length === 0) {
                    this.form.refundsegmentsList = []
                    return
                }
                let params = {
                    operationType: 1,
                    orderNo: this.$route.query.orderNo,
                    psgNos: this.activePsgIdList
                };
                consumer_admin_flight_changeOfflineSegment(params)
                  .then(res => {
                    if (res.datas == null) {
                        this.form.refundsegmentsList = []
                        return
                    }
                    let newChangeSegment = res.datas.segmentsList || [];
                    const newSegmentsItem = {
                      applicationDate: '',
                      arrDate: '',
                      arrTime: '',
                      cabinRank: '',
                      cabinCode: '',
                      chaReason: 1,
                      changFlightNo: '',
                      depDate: '',
                      depTime: '',
                      successTime: '',
                      depDateBox: '',
                      arrDateBox: ''
                    };
                    newChangeSegment.forEach(value => {
                      value.change = JSON.parse(JSON.stringify(newSegmentsItem));
                    });
                    this.form.refundsegmentsList = newChangeSegment;
                  })
                  .catch(err => {
                    this.$alert(err.data.msg, '提示', {
                      confirmButtonText: '确定',
                      callback: action => {

                      }
                    });
                  });

            })
        },
        setPsgInputDisable(val) {
            const psgNo = val.psgNo;
            return this.activePsgIdList.indexOf(psgNo) === -1
        },
        formRuleForNewTicketNo(val) {
            if (!val) return false
            const psgNo = val.psgNo;
            if (this.activePsgIdList.indexOf(psgNo) === -1) {
                return false
            } else {
                return true
            }
        },
        segmentSelectable(row, index) {
            return true
        },
        handleSegmentSelectionChange(val) {
            let activeSegmentNoList = [];
            if (val && val.length > 0) {
                val.forEach(value => {
                    activeSegmentNoList.push(value.segmentNo);
                })
            }
            this.activeSegmentNoList = activeSegmentNoList
        },
        setSegmentInputDisable(val) {
            const segmentNo = val.segmentNo;
            return this.activeSegmentNoList.indexOf(segmentNo) === -1
        },
        setChangeInfoTotal() {
            // 改期总费用=舱位差价+改期手续费
            let total = Decimal(this.changeInfo.changeprice).add(Decimal(this.changeInfo.changeFeePrice)).toNumber();
            this.changeInfo.total = total;
        },
    }
}

import consumer_admin_user_getCredit from '@/lib/data-service/haolv-default/consumer_admin_user_getCredit'
export default {
    data() {
        return {
            companyId: '',
            companyDetail: {

            }
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
        this.getCompanyInfo()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        getCompanyInfo() {
            this.companyId = this.$route.query.behalfCompanyId
            consumer_admin_user_getCredit({companyId: this.companyId}).then(res => {
                this.companyDetail = res.datas || {}
            })
        },
        back() {
            this.$router.go(-1)
        }
    }
}
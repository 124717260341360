export default {
    // 订票信息
    order: {
        id: null,
        gmtCreate: "",
        gmtModified: "",
        type: 0,
        orderNo: "",
        journey: "",
        journeyDate: "",
        journeyPeriod: ['00:00','23:59'],
        shift: "",
        orderSource: "",
        supOrderNo: "",  // 交易号 == 供应商订单号
        paymentType: "",
        paymentTime: "",
        paymentNum: "",
        seatNumber: "",
        refund: 0,
        status: 0,
        remark: "",
        createBy: 0,
        modifiedBy: 0,
        modifiedName: "",
    },
    // 客户基础信息
    companyInfo: {
        id: null,
        orderId: 0,
        companyId: null,
        contactName: "",
        contactMobile: "",
        evectionType: 2,
        payType: 1,
    },
    // 客户信息
    companyBill: [
        {
            id: null,
            orderId: null,
            companyId: null,
            userId: null,
            userName: "",
            certId: null,
            companyType: 'cr',
            grade: "",
            status: 0,
            seatNumber: "",   //座位号
            salesPrice: null,   //销售价 == 票价
            supPrice: null,     //采购价 == 票价
            serviceFee: null,
            find: 0,
            findTime: "",
            certNum: "",
            certType: 1,


            profit: null,   //利润
            certificates: [],
        },
    ],
}

import consumer_trains_common_queryOverweightBecause from '@/lib/data-service/haolv-default/consumer_trains_common_queryOverweightBecause'
export default {
    name: 'CheckTravel',
    data () {
        return {
            dialogVisible: false,
            checkFun: null, // 检查的方法
            submitFun: null, // 提交的方法
            resultFun: null, // 结果返回的方法
            warningTableForm: [],
            warningList: [],
            reasonList: [{value: 1, label: '余票不足'}, {value: 2, label: '出发或到达时间不合适'}, {value: 3, label: '出发到达车站选择'}],
            flightResultList: [],
            hotelResultList: [],
            trainsResultList:[],
            reasonRule: {
                reason: [
                    { required: true, message: '请选择超标原因', trigger: 'change' }
                ]
            },
            canReserve: true,
        }
    },
    components: {},
    created () {},
    mounted () {
        this._getReasonList()
    },
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        init(params) {
            if (!params) params = {};
            // 判断各入参是否符合标准
            let defaultParams = {
                checkFun: null,
                reserveSubmitFun: null,
                reserveResultFun: null
            };
            const parameter = Object.assign({}, defaultParams, params);
            if (!this._isFunction(parameter.checkFun)) {
                const msg = 'init parameter error, code=001';
                console.error(msg);
                return Promise.reject({msg})
            }
            if (!this._isFunction(parameter.reserveSubmitFun)) {
                const msg = 'init parameter error, code=002';
                console.error(msg);
                return Promise.reject({msg})
            }
            if (!this._isFunction(parameter.reserveResultFun)) {
                const msg = 'init parameter error, code=003';
                console.error(msg);
                return Promise.reject({msg})
            }
            // 赋值
            this.checkFun = params.checkFun;
            this.submitFun = params.reserveSubmitFun;
            this.resultFun = params.reserveResultFun
        },
        run() {
            this._check()
        },
        _isFunction(obj){
            return Object.prototype.toString.call(obj)==='[object Function]'
        },
        async _check() {
            if (!this.checkFun) return;
            let res = await this.checkFun();
            if (res === null) {
                return
            }
            let violationResults = res;
            let form = [];
            let result = [];
            let errorResult = [];
            violationResults.forEach(value => {
                if (value.controlRules === 1 || value.controlRules === 2) {
                    result.push(value)
                } else if (value.controlRules === 3) {
                    errorResult.push(value)
                }
            });
            if (errorResult.length > 0) {
                this.canReserve = false;
                this.warningList = errorResult;
                this.dialogVisible = true;
                return
            }
            this.canReserve = true;
            result.forEach((value, index) => {
                if (value.controlRules === 2) {
                    value.reason = '';
                    form.push(`form-${index}`)
                }

            });
            this.warningTableForm = form;
            this.warningList = result;

            if (result.length === 0) {
                // 通过
                if (!this.submitFun) return;
                let result = await this.submitFun(violationResults);
                if (!this.resultFun) return;
                this.resultFun(result)
            } else {
                // 需要审核提交
                this.dialogVisible = true
            }
        },
        _checkErrorTableForm() {
            let warningTableFormStatus = [];
            if (this.warningTableForm.length === 0) {
                if (!this.submitFun) return;
                this.submitFun(this.warningList).then(res => {
                    this.dialogVisible = false;
                    if (!this.resultFun) return;
                    this.resultFun(res)
                });
                return
            }
            this.warningTableForm.forEach(value => {
                warningTableFormStatus.push(-1)
            });
            this.warningTableForm.map((value, index) => {
                this.$refs[value].validate((valid) => {
                    valid ? warningTableFormStatus[index] = 1 : warningTableFormStatus[index] = -1;
                    if (warningTableFormStatus.indexOf(-1) === -1) {
                        if (!this.submitFun) return;
                        this.submitFun(this.warningList).then(res => {
                            this.dialogVisible = false;
                            if (!this.resultFun) return;
                            this.resultFun(res)
                        })
                    }
                })
            })
        },
        _getReasonList() {
            consumer_trains_common_queryOverweightBecause().then(res => {
                this.flightResultList = res.datas.flightResult;
                this.hotelResultList = res.datas.hotelResult;
                this.trainsResultList = res.datas.trainsResult
            })
        },
        delRow({row, rowIndex}) {
            if (row.controlRules === 4) {
                return 'display-none'
            } else {
                return ''
            }
        },
    }
}
import consumer_admin_flight_offlineRefund_createDetails
    from '@/lib/data-service/haolv-default/consumer_admin_flight_offlineRefund_createDetails'//获取详情
import consumer_admin_flight_offlineRefund_offlineRefundOperation
    from '@/lib/data-service/haolv-default/consumer_admin_flight_offlineRefund_offlineRefundOperation'//保存
import consumer_air_ticket_refChaReasonEnum from "@/lib/data-service/haolv-default/consumer_air_ticket_refChaReasonEnum";
import consumer_admin_flight_equity_getOrderProductInfo from '@/lib/data-service/haolv-default/consumer_admin_flight_equity_getOrderProductInfo'
import Decimal from "decimal.js"
export default {
    data() {
        const validateNumber = (rule, value, callback) => {
            if (!value) {
                callback();
            }
            const regExp = /^(0|[1-9]\d*)(\.\d{1,2})?$/
            if (regExp.test(value)) {
                if (value >= 0) {
                    callback()
                } else {
                    callback(new Error('请输入不小于0的数字'))
                }
            } else {
                callback(new Error('请输入合法数字'))
            }
        }
        return {
            orderProductInfo: {},
            dialogEnjoyMore: false,
            loading: false,
            fromMode: '200',
            sourceType: 1, // 1国内2国际
            message: '',
            detail: {
                segmentsList: [],//航班信息
                insuranceInfo: [],//保险信息
                refundPassenger: [],//乘客信息
            },
            refundOptions: [
                {
                    value: 1,
                    label: "自愿退票"
                },
                {
                    value: 2,
                    label: "非自愿退票"
                },
            ],
            form: {
                orderNo: '',
                remark: '',
                code: '',
                refReason: 1,
                refReasonText: '',
                userRemark: '',
                remarkText1: '',
                explainMsg: '',
                applyRefundTime: '',
                psgList: [],
            },
            rules: {
                refundFine: [
                    {required: true, message: '退票罚金', trigger: 'blur'},
                    {required: true, validator: validateNumber, trigger: 'blur'}
                ],
                platformRefund: [
                    {required: true, message: '平台退票罚金', trigger: 'blur'},
                    {required: true, validator: validateNumber, trigger: 'blur'}
                ],
                refRealAmount: [
                    {required: true, message: '退回金额', trigger: 'blur'},
                    {required: true, validator: validateNumber, trigger: 'blur'}
                ],
                supplierRefRealAmount: [
                    {validator: validateNumber, trigger: 'blur'}
                ],
                code: [
                    {required: true, message: '退票原因', trigger: 'blur'},
                ],
                explainMsg: [
                    {required: true, message: '具体说明', trigger: 'blur'},
                ],
                applyRefundTime: [
                    {required: true, message: '申请退票时间', trigger: 'blur'},
                ],
            },
            refReasonEnum: [],
        }
    },
    mounted() {
        this.getRefReasonEnum();
    },
    activated() {
        this.react();
        this.form.orderNo = this.$route.query.orderNo;
        this.getDetail()
    },
    filters: {
        filterMoney(val) {
            if (val || val === 0) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return '0.00'
            }
        }
    },
    methods: {
        clickEnjoyMore() {
            consumer_admin_flight_equity_getOrderProductInfo({orderNo: this.form.orderNo}).then((res) => {
                this.orderProductInfo = res.datas;
            });
            this.dialogEnjoyMore = true;
        },
        react() {
            this.form = {
                orderNo: '',
                remark: '',
                code: '',
                refReason: 1,
                refReasonText: '',
                userRemark: '',
                remarkText1: '',
                explainMsg: '',
                applyRefundTime: '',
                psgList: [],
            };
        },
        getRefReasonEnum() {
            consumer_air_ticket_refChaReasonEnum({'bizType': 3}).then(res=>{
                let refReasonEnum = res.datas;
                refReasonEnum.forEach(value=>{
                    let text = '';
                    if (value.reasonType === 1) {
                        text+= '个人原因：'
                    }
                    if (value.reasonType === 2) {
                        text+= '航司原因：'
                    }
                    text+= value.msg;
                    text+=value.refReason === 1 ? '（自愿退票）' : '（非自愿退票）'
                    console.log(text);
                    value.text = text;
                })
                this.refReasonEnum = refReasonEnum;
            })
        },
        changeRefReason(val) {
            this.refReasonEnum.forEach(value => {
                if (value.code === val) {
                    this.form.refReason = value.refReason;
                    this.form.refReasonText = value.refReason === 1 ? '自愿退票' : '非自愿退票';
                    this.form.code = value.code;
                    this.form.userRemark = value.msg;
                    this.form.remarkText1 = value.msg + '（' + this.form.refReasonText + '）';
                }
            })
        },
        // 选择航班
        handleSelectionChange(item, index, val) {
            console.log(item, val);
            let activeSegmentList = [];
            val.forEach(value => {
                activeSegmentList.push(value.segmentNo)
            })
            console.log(activeSegmentList);
          // item.activeSegmentList = activeSegmentList
            this.detail.refundPassenger[index].activeSegmentList = activeSegmentList;
            this.$forceUpdate();

            // 清空输入框情况
            this.$refs.amountForm[index].clearValidate();

            // 判断所选是否统一
            let firstSegment = [];
            let hasDifference = false;
            this.detail.refundPassenger.forEach((value, rIndex) => {
                // 排除空的
                if (!value.activeSegmentList || value.activeSegmentList.length === 0) {
                    return
                }
                // 选出第一个有值的
                if (firstSegment.length === 0) {
                    firstSegment = value.activeSegmentList
                }
                // 先判断两者的值的数量是否一致
                if (firstSegment.length !== value.activeSegmentList.length) {
                    hasDifference = true
                }
                // 再比较里面的值
                value.activeSegmentList.forEach(value1 => {
                    if (firstSegment.indexOf(value1) === -1) {
                        hasDifference = true
                    }
                })
            })
            if (hasDifference) {
                this.message = this.$message({
                    type: "warning",
                    message: '乘客所选航班不一致'
                })
            } else {
                if (this.message) {
                    this.message.close()
                }
            }
        },
        //获取详情
        getDetail() {
            this.loading = true
            consumer_admin_flight_offlineRefund_createDetails({
                orderNo: this.form.orderNo
            }).then(res => {
                res.detailVO.refundPassenger.forEach((item) => {
                  item.refundFine = '';
                  item.platformRefund = 0;
                  item.returnDifference = 0;
                  item.isRefundToGuest = 0;
                  item.refRealAmount = '';
                  item.supplierRefundOrderNo = '';
                  item.supplierRefRealAmount = '';

                    if (item.sourceType) {
                        this.sourceType = item.sourceType
                    }
                });
                this.detail = res.detailVO;
                this.detail.refundPassenger.forEach(item => {
                    this.$set(item, 'isPass', true);
                    this.$set(item, 'isSelected', false);
                    this.$set(item, 'isDisable', false);
                    if (item.psgEdit === 2) {
                        item.isDisable = true
                    }
                    item.activeSegmentList = [];
                })
                let canSelectedPassenger = this.detail.refundPassenger.find(item => {
                    return item.isDisable === false;
                });
                if (!canSelectedPassenger) {
                    // this.$alert(`该订单无可退票乘客!`, "提示", {
                    //     confirmButtonText: "确定",
                    //     type: "warning",
                    //     callback: action => {
                    //         this.$router.go(-1);
                    //     }
                    // })
                }
            }).catch(() => {
                this.$message({
                    type: "error",
                    message: "获取机票订单详情失败！"
                })
            }).finally(() => {
                this.loading = false
            })
        },

        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
            switch (val) {
                case (1):
                    return 'type2'
                case (2):
                    return 'type1'
                default: return ''
            }
        },

        // 校验表单
        checkForm() {
            const _this = this;
            return new Promise(resolve => {
                let checkRes = [];
                let unValidObjArr = [];
                let pass = false;
                const formLength = _this.$refs.amountForm.length;
                this.$refs.amountForm.forEach((value, index) => {
                    value.validate((valid, obj) => {
                        console.log(valid, obj);
                        checkRes.push(valid)
                        unValidObjArr.push(obj);

                        if (index + 1 !== formLength) {
                            return
                        }
                        if (checkRes.indexOf(false) === -1) {
                            pass = true
                        } else {
                            pass = false
                        }
                        resolve({pass, unValidObjArr})
                    })
                })

            })
        },
        //保存
        async submit() {
            // 判断所选是否统一
            let firstSegment = [];
            let hasDifference = false;
            console.log(this.detail.refundPassenger)
            this.detail.refundPassenger.forEach((value, rIndex) => {
                // 排除空的
                if (!value.activeSegmentList || value.activeSegmentList.length === 0) {
                    return
                }
                // 选出第一个有值的
                if (firstSegment.length === 0) {
                    firstSegment = value.activeSegmentList
                }
                // 先判断两者的值的数量是否一致
                if (firstSegment.length !== value.activeSegmentList.length) {
                    hasDifference = true
                }
                // 再比较里面的值
                value.activeSegmentList.forEach(value1 => {
                    if (firstSegment.indexOf(value1) === -1) {
                        hasDifference = true
                    }
                })
            })
            if (hasDifference) {
                this.$message({
                    type: "warning",
                    message: '乘客所选航班不一致'
                })
                return
            }
            let isOk = await this.checkForm();
            if(!isOk.pass) {
                this.$message({
                    type: "warning",
                    message: '请完善已选退票乘客的退票信息'
                })
                console.log(isOk.unValidObjArr)
                for (let i = 0, l = isOk.unValidObjArr.length; i < l; i++) {
                    if (JSON.stringify(isOk.unValidObjArr[i]) === '{}') {
                        continue
                    }

                    let idName = Object.keys(isOk.unValidObjArr[i])[0];
                    document.getElementById(idName + i).scrollIntoView({
                        behavior: "smooth",  // 平滑过渡
                        block:    "start"  // 上边框与视窗顶部平齐。默认值
                    });
                    break
                }
                return;
            }

            let form = this.form;
            form.psgList = [];
            this.detail.refundPassenger.forEach(psgItem => {
                if (psgItem.activeSegmentList.length > 0) {
                    let segList = [];
                    psgItem.activeSegmentList.forEach(value => {
                        let segItem = {
                            segNo: value
                        }
                        segList.push(segItem)
                    })
                    let item = {
                        psgNo: psgItem.psgNo,
                        refRealAmount: psgItem.refRealAmount,
                        refundFine: psgItem.refundFine,
                        platformRefund: psgItem.platformRefund,
                        supplierRefRealAmount: psgItem.supplierRefRealAmount,
                        supplierRefundOrderNo: psgItem.supplierRefundOrderNo,
                        returnDifference: psgItem.returnDifference,
                        isRefundToGuest: psgItem.isRefundToGuest,
                        segList
                    }
                    form.psgList.push(item);
                }
            })

            if (form.psgList.length === 0) {
                this.$message({
                    type: "warning",
                    message: '请选择退票乘客/航班'
                })
                return
            }
            if (!form.applyRefundTime) {
                this.$message({
                    type: "warning",
                    message: '请输入申请退票时间'
                })
                return
            }

            if (form.psgList.length === 0 && form.remark === '') {
                this.$message({
                    type: "warning",
                    message: '请输入备注'
                })
                return
            }
            form.refundTime = form.applyRefundTime;
            this.loading = true
            consumer_admin_flight_offlineRefund_offlineRefundOperation(form)
              .then(res => {
                this.loading = false
                this.refOrderNo = res.msg;
                this.$message({
                    type: "success",
                    message: '提交成功'
                })
                this.form.remark = ''
                this.toConfirm()
              })
              .catch(err => {
                this.$message({
                  showClose: true,
                  message: err.data.msg,
                  type: 'error'
                });
                this.loading = false
              })
        },

        //跳转至确认页
        toConfirm() {
            this.$router.push({
                name: 'admin-flight-offline-refund-confirm',
                query: {
                    refOrderNo: this.refOrderNo,
                    fromMode: '200'
                }
            })
        },

        //返回
        back() {
            this.$router.go(-1)
        },

        // 改变退票罚金触发
        changeRefundFine(index, item, val) {
            // 退回金额=销售价-退票罚金-平台退票罚金+退回舱差
            let refRealAmount = 0;
            refRealAmount = Decimal(item.personSalePrice).sub(item.refundFine).sub(item.platformRefund).toString();
            if (item.isRefundToGuest) {
                refRealAmount = Decimal(refRealAmount).add(item.returnDifference).toString();
            }
            // 供应商退回金额=采购价-退票罚金+退回舱差
            let supplierRefRealAmount = 0;
            supplierRefRealAmount = Decimal(item.personSupPrice).sub(item.refundFine).add(item.returnDifference).toString();
            /*if (item.isRefundToGuest) {
                supplierRefRealAmount = Decimal(supplierRefRealAmount).add(item.returnDifference).toString();
            }*/
            item.refRealAmount = refRealAmount;
            item.supplierRefRealAmount = supplierRefRealAmount;
        },
        // 改变平台退票罚金触发
        changePlatformRefundFine(index, item, val) {
            // 退回金额=销售价-退票罚金-平台退票罚金
            // 要勾选所有的航班，才去算
            console.log(index, item, val)
            if (item.activeSegmentList.length !== item.segmentsList.length) {
                return;
            }
            item.refRealAmount = Decimal(item.personSalePrice).sub(Decimal(item.refundFine || 0)).sub(Decimal(val)).toNumber();
        },

        checkReturnDifference(rule, value, callback, item) {
            const regExp = /^(0|[1-9]\d*)(\.\d{1,2})?$/
            if (regExp.test(value)) {
                if (value < 0) {
                    callback(new Error('请输入不小于0的数字'))
                } else {
                    if (value > item.differencePrice) {
                        callback(new Error('金额不能超过改签支付的金额'))
                    } else  {
                        callback();
                    }
                }
            } else {
                callback(new Error('请输入合法数字'))
            }
        },
    }
}

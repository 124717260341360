var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"refund-detail"},[_c('div',{staticClass:"detail-tit-wrapper flex flex-pack-justify"},[_c('div',{staticClass:"detail-tit"},[_vm._v("订单详情")]),_c('div',[_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.handleRefundClick()}}},[_vm._v("确认取消")]),_c('el-button',{on:{"click":function($event){return _vm.handleBackClick()}}},[_vm._v("返回")])],1)]),_c('div',{staticClass:"detail-cont-wrapper mb-24"},[_c('div',{staticClass:"detail-default-table-wrapper"},[_c('el-table',{attrs:{"data":[_vm.orderDetail],"header-row-class-name":"travelers-header","cell-class-name":"travelers-cell","tooltip-effect":"dark","size":"small"}},[_c('el-table-column',{attrs:{"label":"订单号","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-center"},[_c('div',[_vm._v(_vm._s(row.orderNo))]),_c('div',{staticClass:"behalf"},[_vm._v(_vm._s(row.soureName))])])]}}])}),_c('el-table-column',{attrs:{"label":"下单时间","prop":"supplierOrderNo","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$moment(row.gmtCreate).format('YYYY-MM-DD HH:mm:ss'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"订单状态","prop":"statusText","align":"center"}}),_c('el-table-column',{attrs:{"label":"已付金额","prop":"deptName","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" ￥"+_vm._s(_vm._f("filterMoney")(row.actualPayment))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"订单来源","prop":"evectionTypeText","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-center"},[(row.evectionType)?_c('el-button',{class:['travel-type', _vm.getEvectionTypeToClass(row.evectionType)]},[_vm._v(_vm._s(row.evectionTypeText))]):_c('span',[_vm._v("--")])],1)]}}])}),_c('el-table-column',{attrs:{"label":"出差单号","prop":"evectionNo","align":"center"}}),_c('el-table-column',{attrs:{"label":"联系人","prop":"orderFromTypeText","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.linkman)+" "+_vm._s(row.linkmanPhone))])]}}])}),_c('el-table-column',{attrs:{"label":"所属企业","prop":"companyName","align":"center"}})],1)],1)]),_vm._m(0),_c('div',{staticClass:"detail-cont-wrapper mb-24"},[_c('div',{staticClass:"detail-default-table-wrapper"},[_c('el-table',{attrs:{"data":[_vm.orderDetail],"header-row-class-name":"travelers-header","cell-class-name":"travelers-cell","tooltip-effect":"dark","size":"small"}},[_c('el-table-column',{attrs:{"label":"所在城市","prop":"cityName","align":"center"}}),_c('el-table-column',{attrs:{"label":"酒店名称","prop":"hotelName","align":"center"}}),_c('el-table-column',{attrs:{"label":"房型","prop":"hotelRoomTypeName","align":"center"}}),_c('el-table-column',{attrs:{"label":"间夜","prop":"deptName","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.roomNum)+"间"+_vm._s(row.stayNights)+"晚 ")]}}])}),_c('el-table-column',{attrs:{"label":"入离日期","prop":"evectionTypeText","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-center"},[_vm._v(" "+_vm._s(_vm.$moment(_vm.orderDetail.checkInDate).format('MM月DD日'))+" 至 "+_vm._s(_vm.$moment(_vm.orderDetail.checkOutDate).format('MM月DD日'))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"最晚到店时间","prop":"latestArrivalTime","align":"center"}})],1),_c('div',{staticClass:"hotel-address"},[_vm._v(" 地址： "+_vm._s(_vm.orderDetail.hotelAddr)+" ")])],1)]),_vm._m(1),_c('div',{staticClass:"detail-cont-wrapper mb-24"},[_c('div',{staticClass:"detail-default-table-wrapper"},[_c('el-table',{attrs:{"data":_vm.orderPersonList,"header-row-class-name":"travelers-header","cell-class-name":"travelers-cell","tooltip-effect":"dark","size":"small"}},[_c('el-table-column',{attrs:{"label":"姓名","prop":"name","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.name)+" "),(scope.row.shareWith===1)?_c('span',[_vm._v("(合住)")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"类型","prop":"type","align":"center"}}),_c('el-table-column',{attrs:{"label":"证件类型","prop":"certificatesType","align":"center"}}),_c('el-table-column',{attrs:{"label":"证件号","prop":"certificatesNumber","align":"center"}})],1)],1)]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"btns-wrapper"},[_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.handleRefundClick()}}},[_vm._v("确认取消")]),_c('el-button',{on:{"click":function($event){return _vm.handleBackClick()}}},[_vm._v("返回")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-tit-wrapper flex flex-pack-justify"},[_c('div',{staticClass:"detail-tit"},[_vm._v("酒店信息")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-tit-wrapper flex flex-pack-justify"},[_c('div',{staticClass:"detail-tit"},[_vm._v("入住人信息")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-tit-wrapper flex flex-pack-justify"},[_c('div',{staticClass:"detail-tit"},[_vm._v("取消须知")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-cont-wrapper mb-24"},[_c('div',{staticClass:"detail-default-table-wrapper rule-wrapper"},[_vm._v("此订单一经提交不得取消修改。若未入住或取消修改订单，我们将扣除您一定金额。")])])}]

export { render, staticRenderFns }
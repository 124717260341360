import _ from 'underscore';
import consumer_credit_open_list from "@/lib/data-service/haolv-default/consumer_credit_open_list.js";

export default {
  data() {
    return {
      query: {
        companyName: '',
        phone: '',
        currentPage: 1,
        pageSize: 10,
      },
      resetQuery: null,
      date: [],
      list: [],
      total: 0,

      loading: false,
    };
  },
  methods: {
    formatQuery() {
      let query = { ...this.query };
      if (this.date.length) {
        query.timeState = this.date[0];
        query.timeEnd = this.date[1];
      }
      return query;
    },
    setList() {
      const query = this.formatQuery();

      this.loading = true;
      consumer_credit_open_list(query)
        .then(res => {
          const pageResult = res.pageResult;
          const { pageData, totalCount } = pageResult;
          this.list = pageData;
          this.total = totalCount;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onReset() {
      this.query = { ...this.resetQuery };
      this.date = [];
      this.setList();
    },
    onOpen(row) {
      let name = 'admin-open-credit';
      const data_app_for_npm_haolv_platform_v2 = this.$route.meta.data_app_for_npm_haolv_platform_v2;
      if (data_app_for_npm_haolv_platform_v2) {
        const get_route_detail_page = data_app_for_npm_haolv_platform_v2.get_route_detail_page;
        if (_.isFunction(get_route_detail_page)) {
          const route_detail_page = get_route_detail_page();
          if (route_detail_page) {
            const route_name = route_detail_page.route_name;
            if (route_name) {
              name = route_name;
            }
          }
        }
      }
      this.$router.push({
        name: name,
        query: {
          companyId: row.companyId,
          type: 'open',
        },
      });
    },
    handleCurrentChange(page) {
      this.query.currentPage = page;
      this.setList();
    },
    handleSizeChange(size) {
      this.query.currentPage = 1;
      this.query.pageSize = size;
      this.setList();
    },
  },
  created() {
    this.resetQuery = { ...this.query };
  },
  activated() {
    this.setList();
  },
};

import _ from 'underscore';
import moment from 'moment';
export default {
    data() {
        return {
            getDataSource: null,
            dataSource: {
                entityList: []
            },
            moment: moment,
        }
    },
    methods: {
        //初始化
        async init(pParams) {
            if (!pParams) pParams = {}
            // 判断入参是否符合标准
            let defaultParams = {
                getDataSource: null
            }
            const parameter = Object.assign({}, defaultParams, pParams)
            if (!_.isFunction(parameter.getDataSource)) {
                const msg = 'init pParams error, code=001';
                console.error(msg);
                return Promise.reject({msg})
            }
            // 赋值
            this.getDataSource = pParams.getDataSource;
            this.dataSource = await this.getDataSource();
            this.checkEntity();
        },
        //检查返回值是否符合标准
        checkEntity() {
            if (!_.isObject(this.dataSource)) {
                const msg = 'getDataSource dataSource error, code=002';
                console.error(msg);
                return Promise.reject({msg})
            }
            if (!_.isArray(this.dataSource.entityList)) {
                const msg = 'entityList error, code=003';
                console.error(msg);
                return Promise.reject({msg})
            }
            if (this.dataSource.entityList.length > 0) {
                let isValidate = this.dataSource.entityList.some(item => {
                    return !_.has(item, 'operator') || !_.has(item, 'date') || !_.has(item, 'type') || !_.has(item, 'detail')
                });
                if (isValidate) {
                    const msg = 'entityList data error, code=004';
                    console.error(msg);
                    return Promise.reject({msg})
                }
            }
        }
    }
}

// 【338】admin-线下单退票订单详情（确认页）
// POST /consumer/admin/flight/offlineRefund/details
// 接口ID：33720245
// 接口地址：https://www.apifox.cn/web/project/458418/apis/api-33720245
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_flight_offlineRefund_details = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/flight/offlineRefund/details',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_flight_offlineRefund_details;
// 酒店最低价 http://192.168.0.19:8765/doc.html#/haolv-consumer/hotel-search-controller/getHotelMinPriceUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_hotel_order_getHotelMinPrice = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/admin/hotel/order/getHotelMinPrice',
    data: {
      behalfCompanyId: pParameter.behalfCompanyId,
      params: pParameter
    }
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_admin_hotel_order_getHotelMinPrice;
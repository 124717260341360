import Q from 'q';
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info';

export default {
    data() {
        return {
            data_get_user_info: {
                datas: {
                    username: ``,
                },
            },
        };
    },
    methods: {
        to_home() {
            this.$router.push({
                name: 'admin-replace-orders',
            })
        },
        to_personal_center() {
        },
        logout() {
            const __this = this;
            __this.$router.replace({
                name: 'login',
            });
        },
    },
    watch: {},
    created() {
        const __this = this;
        Q.when()
            .then(function () {
                return get_user_info();
            })
            .then(function (data) {
                __this.data_get_user_info = data;
            })
            .catch(function (ex) {
                if (ex) {
                    const enable_log_error = !ex.disable_log_error;
                    if (enable_log_error) {
                        console.error(ex);
                        console.trace();
                        if (ex.stack) {
                            console.error(ex.stack);
                        }
                    }
                }
                return Q.reject(ex);
            })
        ;

    },
};

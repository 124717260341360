// consumer_admin_behalfCustomer_getTravelerList 获取出行人集合 http://api-alpha.haolvtrip.com/doc.html#/yinzhilv-tmc/admin/getTravelerListUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_behalfCustomer_getTravelerList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/behalfCustomer/getTravelerList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_behalfCustomer_getTravelerList;
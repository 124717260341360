import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'
import DictionarySelect from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';
import queryUtil from 'yinzhilv-js-sdk/frontend/common-project/lib/util/query_filter_util.js';
import excelUtil from 'yinzhilv-js-sdk/frontend/common-project/lib/util/excel_util.js';

import reportRequest from '@/lib/data-service/report/report_extra_request';

import moment from "moment";
import { awaitWrap } from '@/page/admin/replace-orders/travel-reserve/common/unit';

export default {
    data() {
        return {
            loading: false,
            statusList: [
                {value: null, label: '全部'},
                {value: 0, label: '待预订'},
                {value: 1, label: '已预订'},
                {value: 2, label: '已退订'},
                {value: 3, label: '已取消'},
            ],
            searchForm: {
                modifiedTimeStart: null,// 操作日期-开始
                modifiedTimeEnd: null,// 操作日期-结束
                orderNo: null,// 订单号
                supOrderNo: null,// 订单号
                companyName: null,// 企业
                status: null,// 办理进度
                userName: null,// 客户名
                orderSource: null,// 订单来源
                bizType: null,// 业务类型
                bizName: null,// 产品名称
            },
            pageBean: {
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            },
            totalCount: 0,   //总条数
            list: [],
            modifiedTime: this.initModifiedTime()
        }
    },
    components: {
        SearchBox,
        DictionarySelect,
        Pagination
    },
    activated() {
        this.init()
    },
    methods: {
        //初始化
        async init() {
            this.changeModifiedTimeArr(this.modifiedTime);
            await this.getList();
        },
        initModifiedTime() {
            let arr = [];
            arr[0] = moment().subtract(7, 'days').format('YYYY-MM-DD');
            arr[1] = moment().format('YYYY-MM-DD');
            return arr;
        },
        //搜索
        search() {
            this.pageBean.currentPage = 1
            this.getList()
        },
        //重置
        reset() {
            let searchForm = {
                modifiedTimeStart: null,// 操作日期-开始
                modifiedTimeEnd: null,// 操作日期-结束
                orderNo: null,// 订单号
                supOrderNo: null,// 订单号
                companyName: null,// 企业
                status: null,// 办理进度
                userName: null,// 客户名
                orderSource: null,// 订单来源
                bizType: null,// 业务类型
                bizName: null,// 产品名称
            };
            this.searchForm = searchForm;
            this.totalCount = 0;
            this.search()
        },
        getQueryParams() {
            let params = {
                "pageBean": this.pageBean,
                "params": {},
                "querys": [],
                "groupRelation": "AND",
                "sorter": [
                    {
                        "property": "gmtModified",
                        "direction": "DESC"
                    }
                ],
            }
            if (this.searchForm.modifiedTimeStart) {
                params.querys.push(queryUtil.createQueryParams("gmtModified",this.searchForm.modifiedTimeStart,queryUtil.QueryOP.GE))
            }
            if (this.searchForm.modifiedTimeEnd) {
                params.querys.push(queryUtil.createQueryParams("gmtModified",this.searchForm.modifiedTimeEnd,queryUtil.QueryOP.LE))
            }
            if (this.searchForm.orderNo) {
                params.querys.push(queryUtil.createQueryParams("orderNo",this.searchForm.orderNo))
            }
            if (this.searchForm.supOrderNo) {
                params.querys.push(queryUtil.createQueryParams("supOrderNo",this.searchForm.supOrderNo))
            }
            if (this.searchForm.companyName) {
                params.querys.push(queryUtil.createQueryParams("companyName",this.searchForm.companyName,queryUtil.QueryOP.LK))
            }
            if (this.searchForm.status!=null && this.searchForm.status>=0) {
                params.querys.push(queryUtil.createQueryParams("status",this.searchForm.status))
            }
            if (this.searchForm.userName) {
                params.querys.push(queryUtil.createQueryParams("userName",this.searchForm.userName,queryUtil.QueryOP.LK,"OR","group2"))
                params.querys.push(queryUtil.createQueryParams("englishName",this.searchForm.userName,queryUtil.QueryOP.LK,"OR","group2"))
            }
            if (this.searchForm.orderSource) {
                params.querys.push(queryUtil.createQueryParams("orderSource",this.searchForm.orderSource))
            }
            if (this.searchForm.bizType) {
                params.querys.push(queryUtil.createQueryParams("bizType",this.searchForm.bizType))
            }
            if (this.searchForm.bizName) {
                params.querys.push(queryUtil.createQueryParams("bizName",this.searchForm.bizName,queryUtil.QueryOP.LK))
            }
            return params;
        },
        //获取列表
        getList() {
            this.loading = true
            this.list = []

            reportRequest.report_page_list(this.getQueryParams()).then(res => {
                this.loading = false
                this.list = res.datas.list
                if (this.list) {
                    let text = '';
                    this.list.forEach(async (item) => {
                        text = await Dictionary.returnDictionary('tmc', 'extraOrderSource', item.orderSource);
                        this.$set(item, 'orderSourceText', text.name);
                        text = await Dictionary.returnDictionary('tmc', 'paymentType', item.paymentType);
                        this.$set(item, 'paymentTypeText', text.name);
                        text = await Dictionary.returnDictionary('tmc', 'extraBizType', item.bizType);
                        this.$set(item, 'bizTypeText', text.name);
                    })
                }
                this.totalCount = res.datas.totalCount
            }).catch(() => {
                this.loading = false
                this.list = []
            })
        },

        //导出账单
        async exportExcel() {
            this.loading = true
            const param = this.getQueryParams();
            param.pageBean.currentPage = -1;
            param.pageBean.pageSize = -1;
            let [err, res] = await awaitWrap(reportRequest.report_page_list(param))
            if (err) {
                this.loading = false
                return
            }
            let arr = [];
            let h = [
                '订单号'
                , '客户名称'
                , '客人中文姓名'
                , '客人英文姓名'
                , '票号'
                , '业务类型'
                , '产品名称'
                , '预订状态'
                , '销售价(￥)'
                , '采购价(￥)'
                , '服务费(￥)'
                , '利润(￥)'
                , '收款方式'
                , '采购渠道'
                , '付款方式'
                , '下单人'
                , '操作客服'
                , '操作日期'
                , '创建日期'
                , '关联订单号'
                , '备注'
            ]
            let data = res.datas.list
            arr.push(h);
            if (data && data.length>0) {
                for (const item of data) {
                    let orderSourceText = await Dictionary.returnDictionary('tmc', 'extraOrderSource', item.orderSource);
                    let paymentTypeText = await Dictionary.returnDictionary('tmc', 'paymentType', item.paymentType);
                    let bizTypeText = await Dictionary.returnDictionary('tmc', 'extraBizType', item.bizType);
                    arr.push([
                        item.orderNo
                        , item.companyName
                        , item.userName
                        , item.englishName
                        , item.supOrderNo
                        , bizTypeText.name
                        , item.bizName
                        , item.statusText
                        , item.minus ? -item.salesPrice : item.salesPrice
                        , item.minus ? -item.supPrice : item.supPrice
                        , item.minus ? -item.serviceFee : item.serviceFee
                        , item.profit
                        , item.payTypeText
                        , orderSourceText.name
                        , paymentTypeText.name
                        , item.createName
                        , item.gmtModifiedName
                        , item.gmtModified
                        , item.gmtCreate
                        , item.bizOrderNo
                        , item.remark
                    ])
                }
            }
            excelUtil.exportToExcel('附加业务报表', arr);
            this.loading = false
        },
        //搜索出票日期改变时
        changeModifiedTimeArr (val) {
            if (val === '' || val === null) {
                this.searchForm.modifiedTimeStart = null
                this.searchForm.modifiedTimeEnd = null
            } else {
                this.searchForm.modifiedTimeStart = val[0] + ' 00:00:00'
                this.searchForm.modifiedTimeEnd = val[1] + ' 23:59:59'
            }
        }
    }
}
import {Howl} from 'howler';
import join from 'nllib/src/uri/join';
import get_uuid from 'nllib/src/common/get_uuid';
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';
import store from './lib/store';

export default {
    components: {},
    data() {
        return {
            sound: '',
            notifyList: [],
        }
    },
    methods: {},
    created() {
    },
    mounted() {
        const __this = this;

        const init_store_state_app_config = function () {
            const appConfig = global.appConfig;
            if (!appConfig) return;

            store.commit(`init_app_config`, appConfig);
        };

        const init_favicon = function () {
            const appConfig = global.appConfig;
            if (!appConfig) return;
            const environment = appConfig.environment;
            if (environment !== `local`) {
                const VUE_APP_vue_router_history_public_path = process.env.VUE_APP_vue_router_history_public_path;
                const uuid = get_uuid();
                const href = join(VUE_APP_vue_router_history_public_path, `/favicon.ico?uuid=${uuid}`);

                const link = document.createElement(`link`);
                link.rel = `icon`;
                link.href = href;
                document.querySelector(`head`).appendChild(link);
            }
        };

        const init_head_title = function () {
            const appConfig = global.appConfig;
            if (!appConfig) return;
            const name = appConfig.name;
            document.querySelector(`head title`).innerHTML = name;
        };

        const init_yu_yin_bo_bao = function () {
            const aWebSocketServerClient = global.aWebSocketServerClient;
            // 【异常订单】有新的异常订单
            aWebSocketServerClient.on({
                event_name: `haolv-platform-exception_order`,
                handler: function (data) {
                    console.log('异常订单通知', data)
                    let fullPath = __this.$router.history.current.fullPath
                    if (fullPath !== '/' && fullPath !== '/login') {
                        let randomNum = Math.ceil(Math.random()*10000)
                        let notify = __this.$notify({
                            title: '异常订单通知',
                            dangerouslyUseHTMLString: true,
                            message: `<div class="cont-box cont-box-${randomNum}">
<div>有新的异常订单，请及时处理<br/>[<span class="order-no" data-info="${data.biz_order_no}">${data.biz_order_no}</span>]</div>
<div class="order-btn-box"><div class="order-btn" data-info="${data.biz_order_no}">确定</div></div>
</div>`,
                            duration: 0,
                            customClass: 'order-notify-wrapper abnormal-order-notify-wrapper'
                        });
                        function orderRouterJump(e){
                            notify.close()
                            console.log(e, e.srcElement.dataset)
                            const $router = __this.$router;
                            const resolved = $router.resolve({
                                name: 'admin-flight-abnormal-list-issue',
                                query: {orderNo: e.srcElement.dataset.info},
                            });
                            const href = resolved.href;
                            console.log(href);
                            window.open(href);
                        }
                        document.querySelector(`.cont-box-${randomNum} .order-btn`).addEventListener('click', (e) => {
                            notify.close()
                        })
                        document.querySelector(`.cont-box-${randomNum} .order-no`).addEventListener('click', (e) => {
                            orderRouterJump(e)
                        })
                        __this.notifyList.push(notify)
                        __this.sound = new Howl({
                            src: [data.voice_url]
                        })
                        __this.sound.play()
                    }
                }
            });
            // 【改签订单】有新的待处理或已补款改签订单
            aWebSocketServerClient.on({
                event_name: `haolv-platform-flight-change_order`,
                handler: function (data) {
                    console.log('新手工改签单', data)
                    let fullPath = __this.$router.history.current.fullPath
                    if (fullPath !== '/' && fullPath !== '/login') {
                        let randomNum = Math.ceil(Math.random()*10000)
                        let notify = __this.$notify({
                            title: '改签订单通知',
                            dangerouslyUseHTMLString: true,
                            message: `<div class="cont-box cont-box-${randomNum}">
<div>有新的改签订单，请及时处理<br/>[<span class="order-no" data-info="${data.biz_order_no}">${data.biz_order_no}</span>]</div>
<div class="order-btn-box"><div class="order-btn" data-info="${data.biz_order_no}">确定</div></div>
</div>`,
                            duration: 0,
                            customClass: 'order-notify-wrapper change-order-notify-wrapper'
                        });
                        function orderRouterJump(e){
                          console.log(e, e.srcElement.dataset)
                          notify.close()
                          const $router = __this.$router;
                          const resolved = $router.resolve({
                                name: 'admin-flight-change-opa',
                                query: {chaOrderNo: e.srcElement.dataset.info},
                            });
                            const href = resolved.href;
                            console.log(href);
                            window.open(href);
                        }
                        document.querySelector(`.cont-box-${randomNum} .order-btn`).addEventListener('click', (e) => {
                            notify.close()
                        })
                        document.querySelector(`.cont-box-${randomNum} .order-no`).addEventListener('click', (e) => {
                            orderRouterJump(e)
                        })
                        __this.notifyList.push(notify)
                        __this.sound = new Howl({
                            src: [data.voice_url]
                        })
                        __this.sound.play()
                    }
                }
            });
            // 【退票订单】有新的待处理退票订单
            aWebSocketServerClient.on({
                event_name: `haolv-platform-flight-refund_order`,
                handler: function (data) {
                    console.log('新手工退票单', data)
                    let fullPath = __this.$router.history.current.fullPath
                    if (fullPath !== '/' && fullPath !== '/login') {
                        let randomNum = Math.ceil(Math.random()*10000)
                        let notify = __this.$notify({
                            title: '退票订单通知',
                            dangerouslyUseHTMLString: true,
                            message: `<div class="cont-box cont-box-${randomNum}">
<div>有新的退票订单，请及时处理<br/>[<span class="order-no" data-info="${data.biz_order_no}">${data.biz_order_no}</span>]</div>
<div class="status">状态：${data.pnr_status_text}</div>
<div class="order-btn-box"><div class="order-btn" data-info="${data.biz_order_no}">确定</div></div>
</div>`,
                            duration: 0,
                            customClass: 'order-notify-wrapper refund-order-notify-wrapper'
                        });
                        function orderRouterJump(e){
                            console.log(e, e.srcElement.dataset)
                            notify.close()
                            const $router = __this.$router;
                            const resolved = $router.resolve({
                                name: 'admin-flight-refund-application',
                                query: {refOrderNo: e.srcElement.dataset.info},
                            });
                            const href = resolved.href;
                            console.log(href);
                            window.open(href);
                        }
                        document.querySelector(`.cont-box-${randomNum} .order-btn`).addEventListener('click', (e) => {
                            notify.close()
                        })
                        document.querySelector(`.cont-box-${randomNum} .order-no`).addEventListener('click', (e) => {
                            orderRouterJump(e)
                        })
                        __this.notifyList.push(notify)
                        __this.sound = new Howl({
                            src: [data.voice_url]
                        })
                        __this.sound.play()
                    }
                }
            });
            // 【长时间未出票订单通知】有长时间未出票订单
            aWebSocketServerClient.on({
                event_name: `haolv-platform-flight-pending_order`,
                handler: function (data) {
                    console.log('长时间未出票单', data)
                    let fullPath = __this.$router.history.current.fullPath
                    if (fullPath !== '/' && fullPath !== '/login') {
                        let randomNum = Math.ceil(Math.random()*10000)
                        let notify = __this.$notify({
                            title: '未处理订单通知',
                            dangerouslyUseHTMLString: true,
                            message: `<div class="cont-box cont-box-${randomNum}">
<div>有一张长时间未出票的订单，请及时处理<br/>[<span class="order-no" data-info="${data.biz_order_no}" data-source="${data.order_source}">${data.biz_order_no}</span>]</div>
<div class="order-btn-box"><div class="order-btn" data-info="${data.biz_order_no}">确定</div></div>
</div>`,
                            duration: 0,
                            customClass: 'order-notify-wrapper refund-order-notify-wrapper'
                        });
                        function orderRouterJump(e){
                            console.log(e, e.srcElement.dataset)
                            notify.close()
                            const $router = __this.$router;
                            const resolved = $router.resolve({
                                name: 'admin-flight-order-detail',
                                query: {
                                    orderNo: e.srcElement.dataset.info,
                                    orderSource: e.srcElement.dataset.source,
                                },
                            });
                            const href = resolved.href;
                            console.log(href);
                            window.open(href);
                        }
                        document.querySelector(`.cont-box-${randomNum} .order-btn`).addEventListener('click', (e) => {
                            notify.close()
                        })
                        document.querySelector(`.cont-box-${randomNum} .order-no`).addEventListener('click', (e) => {
                            orderRouterJump(e)
                        })
                        __this.notifyList.push(notify)
                        __this.sound = new Howl({
                            src: [data.voice_url]
                        })
                        __this.sound.play()
                    }
                }
            });
            // 【酒店后台生单现结支付订单待确认弹窗通知】有待确认订单
            aWebSocketServerClient.on({
                event_name: `haolv-platform-hotel-create-order-pay_order`,
                handler: function (data) {
                    console.log('酒店后台生单现结支付订单待确认单', data)
                    let fullPath = __this.$router.history.current.fullPath
                    if (fullPath !== '/' && fullPath !== '/login') {
                        let randomNum = Math.ceil(Math.random()*10000)
                        let notify = __this.$notify({
                            title: '待确认订单通知',
                            dangerouslyUseHTMLString: true,
                            message: `<div class="cont-box cont-box-${randomNum}">
<div>有一张酒店后台生单现结支付订单待确认订单，请及时处理<br/>[<span class="order-no" data-info="${data.biz_order_no}" data-source="${data.order_source}">${data.biz_order_no}</span>]</div>
<div class="order-btn-box"><div class="order-btn" data-info="${data.biz_order_no}">确定</div></div>
</div>`,
                            duration: 0,
                            customClass: 'order-notify-wrapper refund-order-notify-wrapper'
                        });
                        function orderRouterJump(e){
                            console.log(e, e.srcElement.dataset)
                            notify.close()
                            const $router = __this.$router;
                            const resolved = $router.resolve({
                                name: 'admin-hotel-confirm-room',
                                query: {
                                    orderNo: e.srcElement.dataset.info,
                                    behalfCompanyId: e.srcElement.dataset.behalfCompanyId,
                                    payType: e.srcElement.dataset.info,
                                    type: 'edit'
                                },
                            });
                            const href = resolved.href;
                            console.log(href);
                            window.open(href);
                        }
                        document.querySelector(`.cont-box-${randomNum} .order-btn`).addEventListener('click', (e) => {
                            notify.close()
                        })
                        document.querySelector(`.cont-box-${randomNum} .order-no`).addEventListener('click', (e) => {
                            orderRouterJump(e)
                        })
                        __this.notifyList.push(notify)
                        __this.sound = new Howl({
                            src: [data.voice_url]
                        })
                        __this.sound.play()
                    }
                }
            });
            // 机票自营订单，获取采购价失败，需要提醒客服进行人工回填
            /*aWebSocketServerClient.on({
                event_name: `haolv-platform-flight-order-get-price-fail`,
                handler: function (data) {
                    console.log('获取采购价失败', data)
                    let fullPath = __this.$router.history.current.fullPath
                    if (fullPath !== '/' && fullPath !== '/login') {
                        let randomNum = Math.ceil(Math.random()*10000)
                        let notify = __this.$notify({
                            title: '获取采购价失败通知',
                            dangerouslyUseHTMLString: true,
                            message: `<div class="cont-box cont-box-${randomNum}">
<div>有一张获取采购价失败的订单，请及时处理<br/>[<span class="order-no" data-info="${data.biz_order_no}" data-source="${data.order_source}">${data.biz_order_no}</span>]</div>
<div class="order-btn-box"><div class="order-btn" data-info="${data.biz_order_no}">确定</div></div>
</div>`,
                            duration: 0,
                            customClass: 'order-notify-wrapper refund-order-notify-wrapper'
                        });
                        function orderRouterJump(e){
                            console.log(e, e.srcElement.dataset)
                            notify.close()
                            const $router = __this.$router;
                            const resolved = $router.resolve({
                                name: 'admin-flight-order-detail',
                                query: {
                                    orderNo: e.srcElement.dataset.info,
                                    orderSource: e.srcElement.dataset.source,
                                },
                            });
                            const href = resolved.href;
                            console.log(href);
                            window.open(href);
                        }
                        document.querySelector(`.cont-box-${randomNum} .order-btn`).addEventListener('click', (e) => {
                            notify.close()
                        })
                        document.querySelector(`.cont-box-${randomNum} .order-no`).addEventListener('click', (e) => {
                            orderRouterJump(e)
                        })
                        __this.notifyList.push(notify)
                        __this.sound = new Howl({
                            src: [data.voice_url]
                        })
                        __this.sound.play()
                    }
                }
            });*/
            // 【意向单通知】有新的意向单
            aWebSocketServerClient.on({
                event_name: `haolv-platform-intention-order`,
                handler: function (data) {
                    console.log('有新的意向单', data)
                    let fullPath = __this.$router.history.current.fullPath
                    if (fullPath !== '/' && fullPath !== '/login') {
                        let randomNum = Math.ceil(Math.random()*10000)
                        let notify = __this.$notify({
                            title: '意向单通知',
                            dangerouslyUseHTMLString: true,
                            message: `<div class="cont-box cont-box-${randomNum}">
<div>有一张新的意向单，请及时处理<br/>[<span class="order-no" data-info="${data.biz_order_no}" data-source="${data.order_source}">${data.biz_order_no}</span>]</div>
<div class="order-btn-box"><div class="order-btn" data-info="${data.biz_order_no}">确定</div></div>
</div>`,
                            duration: 0,
                            customClass: 'order-notify-wrapper refund-order-notify-wrapper'
                        });
                        function orderRouterJump(e){
                            console.log(e, e.srcElement.dataset)
                            notify.close()
                            const $router = __this.$router;
                            const resolved = $router.resolve({
                                name: 'admin-intention-handle',
                                query: {
                                    orderNo: e.srcElement.dataset.info
                                },
                            });
                            const href = resolved.href;
                            console.log(href);
                            window.open(href);
                        }
                        document.querySelector(`.cont-box-${randomNum} .order-btn`).addEventListener('click', (e) => {
                            notify.close()
                        })
                        document.querySelector(`.cont-box-${randomNum} .order-no`).addEventListener('click', (e) => {
                            orderRouterJump(e)
                        })
                        __this.notifyList.push(notify)
                        __this.sound = new Howl({
                            src: [data.voice_url]
                        })
                        __this.sound.play()
                    }
                }
            });
            // 【签证订单】【门票/船票订单】【线下用车订单】有需处理的订单
            aWebSocketServerClient.on({
                event_name: `tempMsg`,
                handler: function (data) {
                    console.log('【签证/门票订单】有需处理的订单', data)
                    let fullPath = __this.$router.history.current.fullPath
                    if (fullPath !== '/' && fullPath !== '/login') {
                        let randomNum = Math.ceil(Math.random()*10000)
                        let dataInfo = {
                            orderNo: data.orderNo,
                            key: data.key,
                            id: data.bizId,
                        };
                        let notify = __this.$notify({
                            title: `${data.title}`,
                            dangerouslyUseHTMLString: true,
                            message: `<div class="cont-box cont-box-${randomNum}">
<div>[<span class="order-no" data-info="${data.orderNo}">${data.orderNo}</span>]${data.msg}</div>
<div class="order-btn-box"><div class="order-btn" data-info="${JSON.stringify(dataInfo)}">确定</div></div>
</div>`,
                            duration: 0,
                            customClass: 'order-notify-wrapper refund-order-notify-wrapper'
                        });
                        function orderRouterJump(e, datas){
                            console.log('orderRouterJump',e, e.srcElement.dataset, datas)
                            // let p = JSON.parse(e.srcElement.dataset.info);
                            console.log('1', datas.key);
                            let routerName = '';
                            switch (datas.key) {
                                case 'visa':
                                    routerName = 'admin-visa-order-detail';
                                    break;
                                case 'ticket':
                                    routerName = 'admin-ticket-order-detail';
                                    break;
                                case 'ship':
                                    routerName = 'admin-ticket-order-detail';
                                    break;
                                case 'car':
                                    routerName = 'admin-offline-car-order-detail';
                                    break;
                                case 'extra':
                                    routerName = 'admin-extra-order-detail';
                                    break;
                                case 'payMsg':
                                    routerName = 'admin-pay-msg-detail';
                                    break;
                                default:
                                    routerName = '';
                            }
                            notify.close()
                            const $router = __this.$router;
                            const resolved = $router.resolve({
                                name: routerName,
                                query: {orderNo: datas.orderNo, id: datas.bizId},
                            });
                            const href = resolved.href;
                            console.log(href);
                            window.open(href);
                        }
                        document.querySelector(`.cont-box-${randomNum} .order-btn`).addEventListener('click', (e) => {
                            notify.close()
                        })
                        document.querySelector(`.cont-box-${randomNum} .order-no`).addEventListener('click', (e) => {
                            orderRouterJump(e, data)
                        })
                        __this.notifyList.push(notify)
                        __this.sound = new Howl({
                            src: [data.voice_url]
                        })
                        __this.sound.play()
                    }
                }
            });
            // 【火车订单】新增待出票
            aWebSocketServerClient.on({
                event_name: `haolv-platform-train-pending_order`,
                handler: function (data) {
                    console.log('火车票待出票订单', data)
                    let fullPath = __this.$router.history.current.fullPath
                    if (fullPath !== '/' && fullPath !== '/login') {
                        let randomNum = Math.ceil(Math.random()*10000)
                        let notify = __this.$notify({
                            title: '火车票待出票订单通知',
                            dangerouslyUseHTMLString: true,
                            message: `<div class="cont-box cont-box-${randomNum}">
            <div>有新的火车票待出票订单，请及时处理<br/>[<span class="order-no" data-info="${data.biz_order_no}">${data.biz_order_no}</span>]</div>
            <div class="order-btn-box"><div class="order-btn" data-info="${data.biz_order_no}">确定</div></div>
            </div>`,
                            duration: 0,
                            customClass: 'order-notify-wrapper refund-order-notify-wrapper'
                        });
                      function orderRouterJump(e){
                          console.log(e, e.srcElement.dataset)
                          notify.close()
                          const $router = __this.$router;
                          const resolved = $router.resolve({
                              name: 'admin-train-order-detail',
                              query: {orderNo: e.srcElement.dataset.info},
                          });
                          const href = resolved.href;
                          console.log(href);
                          window.open(href);
                      }
                      document.querySelector(`.cont-box-${randomNum} .order-btn`).addEventListener('click', (e) => {
                          notify.close()
                      })
                      document.querySelector(`.cont-box-${randomNum} .order-no`).addEventListener('click', (e) => {
                          orderRouterJump(e)
                      })
                      __this.notifyList.push(notify)
                      __this.sound = new Howl({
                          src: [data.voice_url]
                      })
                      __this.sound.play()
                    }
                }
            });

            // 机票后台生单现结支付订单待出票
            aWebSocketServerClient.on({
                event_name: `haolv-platform-flight-createOrder-cashPay-pendingOrder`,
                handler: function (data) {
                    console.log('机票后台生单现结支付订单待出票', data)
                    let fullPath = __this.$router.history.current.fullPath
                    if (fullPath !== '/' && fullPath !== '/login') {
                        let randomNum = Math.ceil(Math.random()*10000)
                        let notify = __this.$notify({
                            title: '现结支付待出票订单通知',
                            dangerouslyUseHTMLString: true,
                            message: `<div class="cont-box cont-box-${randomNum}">
            <div>有新的机票后台生单现结支付待出票订单，请及时处理<br/>[<span class="order-no" data-info="${data.biz_order_no}">${data.biz_order_no}</span>]</div>
            <div class="order-btn-box"><div class="order-btn" data-info="${data.biz_order_no}">确定</div></div>
            </div>`,
                            duration: 0,
                            customClass: 'order-notify-wrapper refund-order-notify-wrapper'
                        });
                        function orderRouterJump(e){
                            console.log(e, e.srcElement.dataset)
                            notify.close()
                            const $router = __this.$router;
                            const resolved = $router.resolve({
                                name: 'admin-flight-order-detail',
                                query: {orderNo: e.srcElement.dataset.info},
                            });
                            const href = resolved.href;
                            console.log(href);
                            window.open(href);
                        }
                        document.querySelector(`.cont-box-${randomNum} .order-btn`).addEventListener('click', (e) => {
                            notify.close()
                        })
                        document.querySelector(`.cont-box-${randomNum} .order-no`).addEventListener('click', (e) => {
                            orderRouterJump(e)
                        })
                        __this.notifyList.push(notify)
                        __this.sound = new Howl({
                            src: [data.voice_url]
                        })
                        __this.sound.play()
                    }
                }
            });

            // 机票改签已补款（非客服操作确认补款）订单
            aWebSocketServerClient.on({
                event_name: `haolv-platform-flight-changeAndPayOrder`,
                handler: function (data) {
                    console.log('机票改签已补款（非客服操作确认补款）订单', data)
                    let fullPath = __this.$router.history.current.fullPath
                    if (fullPath !== '/' && fullPath !== '/login') {
                        let randomNum = Math.ceil(Math.random()*10000)
                        let notify = __this.$notify({
                            title: '改签已补款订单通知',
                            dangerouslyUseHTMLString: true,
                            message: `<div class="cont-box cont-box-${randomNum}">
            <div>有新的改签已补款订单，请及时处理<br/>[<span class="order-no" data-order-info="${data.order_no}" data-info="${data.biz_order_no}">${data.biz_order_no}</span>]</div>
            <div class="order-btn-box"><div class="order-btn" data-order-info="${data.order_no}" data-info="${data.biz_order_no}">确定</div></div>
            </div>`,
                            duration: 0,
                            customClass: 'order-notify-wrapper refund-order-notify-wrapper'
                        });
                        function orderRouterJump(e){
                            console.log(e, e.srcElement.dataset)
                            notify.close()
                            const $router = __this.$router;
                            const resolved = $router.resolve({
                                name: 'admin-flight-change-detail',
                                query: {orderNo: e.srcElement.dataset.orderInfo, chaOrderNo: e.srcElement.dataset.info},
                            });
                            const href = resolved.href;
                            console.log(href);
                            window.open(href);
                        }
                        document.querySelector(`.cont-box-${randomNum} .order-btn`).addEventListener('click', (e) => {
                            notify.close()
                        })
                        document.querySelector(`.cont-box-${randomNum} .order-no`).addEventListener('click', (e) => {
                            orderRouterJump(e)
                        })
                        __this.notifyList.push(notify)
                        __this.sound = new Howl({
                            src: [data.voice_url]
                        })
                        __this.sound.play()
                    }
                }
            });

            // 机票代客下单出票成功弹窗
            aWebSocketServerClient.on({
                event_name: `haolv-platform-flight-replace-order-ticket`,
                handler: function (data) {
                    console.log('机票代客下单出票成功提醒', data)
                    let fullPath = __this.$router.history.current.fullPath
                    if (fullPath !== '/' && fullPath !== '/login') {
                        let randomNum = Math.ceil(Math.random()*10000)
                        let notify = __this.$notify({
                            title: '出票订单通知',
                            dangerouslyUseHTMLString: true,
                            message: `<div class="cont-box cont-box-${randomNum}">
            <div>订单出票成功，请检查是否需要购买保险<br/>[<span class="order-no" data-info="${data.biz_order_no}">${data.biz_order_no}</span>]</div>
            <div class="order-btn-box"><div class="order-btn" data-info="${data.biz_order_no}">确定</div></div>
            </div>`,
                            duration: 0,
                            customClass: 'order-notify-wrapper refund-order-notify-wrapper'
                        });
                        function orderRouterJump(e){
                            console.log(e, e.srcElement.dataset)
                            notify.close()
                            const $router = __this.$router;
                            const resolved = $router.resolve({
                                name: 'admin-flight-order-detail',
                                query: {orderNo: e.srcElement.dataset.info},
                            });
                            const href = resolved.href;
                            console.log(href);
                            window.open(href);
                        }
                        document.querySelector(`.cont-box-${randomNum} .order-btn`).addEventListener('click', (e) => {
                            notify.close()
                        })
                        document.querySelector(`.cont-box-${randomNum} .order-no`).addEventListener('click', (e) => {
                            orderRouterJump(e)
                        })
                        __this.notifyList.push(notify)
                        __this.sound = new Howl({
                            src: [data.voice_url]
                        })
                        __this.sound.play()
                    }
                }
            });

            // 有订单需要联系酒店支付入住押金
            aWebSocketServerClient.on({
                event_name: `haolv-platform-deposit-hotel-order`,
                handler: function (data) {
                    console.log('有订单需要联系酒店支付入住押金', data)
                    let fullPath = __this.$router.history.current.fullPath
                    if (fullPath !== '/' && fullPath !== '/login') {
                        let randomNum = Math.ceil(Math.random()*10000)
                        let notify = __this.$notify({
                            title: '重要订单通知',
                            dangerouslyUseHTMLString: true,
                            message: `<div class="cont-box cont-box-${randomNum}">
            <div>订单号[<span class="order-no" data-info="${data.biz_order_no}">${data.biz_order_no}</span>]<br/>入住人${data.psg_names}<br/>入住酒店${data.hotel_name}<br/>入住日期${data.check_in_date}<br/>请联系酒店支付入住押金</div>
            <div class="order-btn-box"><div class="order-btn" data-info="${data.biz_order_no}">确定</div></div>
            </div>`,
                            duration: 0,
                            customClass: 'order-notify-wrapper refund-order-notify-wrapper'
                        });
                        function orderRouterJump(e){
                            console.log(e, e.srcElement.dataset)
                            notify.close()
                            const $router = __this.$router;
                            const resolved = $router.resolve({
                                name: 'admin-hotel-order-sold-detail',
                                query: {id: e.srcElement.dataset.info},
                            });
                            const href = resolved.href;
                            console.log(href);
                            window.open(href);
                        }
                        document.querySelector(`.cont-box-${randomNum} .order-btn`).addEventListener('click', (e) => {
                            notify.close()
                        })
                        document.querySelector(`.cont-box-${randomNum} .order-no`).addEventListener('click', (e) => {
                            orderRouterJump(e)
                        })
                        __this.notifyList.push(notify)
                        __this.sound = new Howl({
                            src: [data.voice_url]
                        })
                        __this.sound.play()
                    }
                }
            });

        };

        // 需要鉴权，所以移到admin-layout
        const init_dictionary = function () {
            Dictionary.init(__this, {
                typeGroupKey: ['tmc'],
                typeKey: [],
                parentId: 0
            });
        };

        init_store_state_app_config();
        init_favicon();
        init_head_title();
        init_yu_yin_bo_bao();
        // init_dictionary();
    },
}

import consumer_admin_flight_change_newDetails from '@/lib/data-service/haolv-default/consumer_admin_flight_change_newDetails'
import consumer_admin_flight_change_remarkChange from '@/lib/data-service/haolv-default/consumer_admin_flight_change_remarkChange'
import consumer_air_ticket_fligthRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules"
import consumer_admin_flight_chang_logContents from "@/lib/data-service/haolv-default/consumer_admin_flight_chang_logContents"
import consumer_admin_insurance_refundInsuranceOrder from '@/lib/data-service/haolv-default/consumer_admin_insurance_refundInsuranceOrder'
import consumer_admin_insurance_orderInsuranceAgain from '@/lib/data-service/haolv-default/consumer_admin_insurance_orderInsuranceAgain'
import consumer_admin_insurance_tradeRefundInsuranceOrder from '@/lib/data-service/haolv-default/consumer_admin_insurance_tradeRefundInsuranceOrder'
import consumer_admin_insurance_checkAllowRefundInsurance from '@/lib/data-service/haolv-default/consumer_admin_insurance_checkAllowRefundInsurance'
import consumer_insurance_getEInsuranceOrderUrl from '@/lib/data-service/haolv-default/consumer_insurance_getEInsuranceOrderUrl'
import consumer_admin_insurance_cancelInsuranceOrder from '@/lib/data-service/haolv-default/consumer_admin_insurance_cancelInsuranceOrder'
import ChangeClausePopup from '@/page/admin/replace-orders/component/changeClausePopup/1.0.0/index.vue'
import OrderLog from '@/component/orderLog/2.0.0/index.vue'



// 火车票改签订单详情
import consumer_trains_order_getTrainsChangeOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsChangeOrderDetail'
// 火车票订单保存备注操作
import consumer_admin_trains_order_remarkSaveOrUpdate from '@/lib/data-service/haolv-default/consumer_admin_trains_order_remarkSaveOrUpdate'
// 订单日志
import consumer_log_trainsList from '@/lib/data-service/haolv-default/consumer_log_trainsList'


import moment from "moment";
import ChangeService from "@/component/change-service/index.vue";

export default {
    data() {
        return {
            // 改签单号
            changeNo: '',
            // 订单号
            orderNo: '',
            // 改签信息
            changeInformation: null,
            //改签车次信息
            changejourneyInfo: null,
            //原车次信息
            journeyInfo: null,
            //备注
            remark: null,
            //订单信息
            trainsOrderDetails: null,
            detailData: null,
            trainNumber: [{
                trainNo: '',
            }],


            activeName: 'detail',
            detailLoading: false,
            loading: false,
            chaOrderNo: '',
            detail: {
                remark: '',
                trainsChangepassengerList: [
                    {
                        psgTypeText: null,
                    }
                ],
                changeTrainsJourneyInfo:{
                    trainNo: ''
                },
                ticketFeeDetail:{
                    payAmount: '',
                    refundAmount: '',
                },
                order: {
                    orderStatus: null,
                    scheduledWay: null,
                },
            },
            form: {
                remark: ''
            },
            ruleText: {},
            ruleLoading: false,
            ruleShow: false, // 退改签说明弹窗
        }
    },
    components: {ChangeService, OrderLog, ChangeClausePopup},
    created() {
    },
    mounted() {
    },
    activated() {
        this.activeName = 'detail';
        this.chaOrderNo = this.$route.query.changeNo;
        this.orderNo = this.$route.query.orderNo;
        this.getDetail();
        this.getOrderLog();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterTime(val) {
            return val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : '--';
        },
        filterChangeOderStatus(val) {
            // 0：申请改签 1：待支付 2：改签中 3：改签成功 4：改签失败 5：已取消
            if (val === 0) {
                return '申请改签'
            } else if (val === 1) {
                return '待支付'
            } else if (val === 2) {
                return '改签中'
            } else if (val === 3) {
                return '改签成功'
            } else if (val === 4) {
                return '改签失败'
            } else if (val === 5) {
                return '已取消'
            } else {
                return ''
            }
        },
        filterCabinRank(val) {
            if (val === 1) {
                return '头等舱'
            } else if (val === 2) {
                return '商务舱'
            } else if (val === 3) {
                return '经济舱'
            } else {
                return ''
            }
        },
        filterMoney(val) {
            if (val) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return ''
            }
        },
    },
    methods: {
        cancelInsurance(val) {
            const params = {
                orderNo: this.orderNo,
                userId: val.userId,
            };
            const h = this.$createElement;
            this.$msgbox({
                title: '确认取消投保',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                this.detailLoading = true;
                consumer_admin_insurance_cancelInsuranceOrder(params).then(res => {
                    this.detailLoading = false;
                    this.$message({
                        type: "success",
                        message: '取消投保成功'
                    });
                    this.getDetail()
                })
            }).catch(() => {
                this.detailLoading = false
            });
        },
        getDetail() {
            this.detailLoading = true;
            this.changeNo = this.$route.query.changeNo;
            this.orderNo = this.$route.query.orderNo;

            let data = {
                orderNo: this.changeNo
            };

            consumer_trains_order_getTrainsChangeOrderDetail(data).then(res => {
                this.detail = res.datas;
                // this.changeInformation = res.datas.changeInformation;
                // this.changejourneyInfo = res.datas.changejourneyInfo;
                // this.journeyInfo = res.datas.journeyInfo;
                // this.remark = res.datas.remark;
                // this.trainsOrderDetails = res.datas.trainsOrderDetails;

                this.trainNumber = [
                    {
                        trainNo: this.detail.originalTrainCode, // 车次
                        fromStation: this.detail.originalChangeFromStationName, // 出发站
                        toStation: this.detail.originalChangeToStationName, // 到达站
                        seatName: this.detail.originalSeatName, // 坐席
                        startTime: this.detail.originalChangeStartDate, //出发时间
                        arriveTime: this.detail.originalChangeToDate, // 到达时间
                        ticket: this.detail.order.seatPrice,
                    }
                ];


                this.detailLoading = false
            }).catch(() => {
                this.detailLoading = false
            })
        },
        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true;
                    //调用获取订单日志的接口
                    return consumer_log_trainsList({
                        orderNo: this.orderNo
                    }).then(res => {
                    // 组装组件需要的list
                    const list = res.datas.map(m => {
                        // 替换名字
                        const o = {
                            operator: m.operator,
                            date: m.operatorTime,
                            type: m.logSource,
                            detail: m.logContents
                        }
                        return o
                    })
                    const d = {
                        entityList: list
                    }
                    this.loading = false //临时使用
                    return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },
        // 退票退改规则
        showRefundChangeClause(val) {
            this.ruleText = {}
            this.ruleShow = true
            let data = {
                segmentNo: val.segmentNo
            }
            this.ruleLoading = true
            consumer_air_ticket_fligthRules(data).then(res => {
                this.ruleLoading = false
                this.ruleText = res.datas.rsData
            }).catch(() => {
                this.ruleLoading = false
            })
        },
        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
            switch (val) {
                case (1):
                    return 'type2'
                case (2):
                    return 'type1'
                default: return ''
            }
        },
        submit() {
            if (this.detail.remark === '') {
                this.$message({
                    type: "warning",
                    message: '请输入备注'
                });
                return
            }
            let dataBox = {
                businessType: 2,
                orderNo: this.changeNo,
                remark: this.detail.remark
            };
            this.detailLoading = true;
            consumer_admin_trains_order_remarkSaveOrUpdate(dataBox).then(res => {
                this.detailLoading = false;
                this.$message({
                    type: "success",
                    message: '提交成功'
                });
                this.getDetail();
            }, error => {
                this.detailLoading = false;

            }).catch(() => {
                this.detailLoading = false;
            })
        },
        back() {
            this.$router.push({
                name: 'admin-train-change-order-list'
            })
        },
        async refundInsurance(val) {
            const params = {
                insuranceOrderId: val.insuranceOrderId
            };
            let [err, res] = await awaitWrap(consumer_admin_insurance_checkAllowRefundInsurance(params));
            if (err) {
                this.$message({
                    type: "warning",
                    message: '保险已生效,无法退保'
                });
                return
            }
            this.$msgbox({
                title: '确认退保',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                this.detailLoading = true;
                let data = {
                    insuranceOrderId: params.insuranceOrderId,
                    orderNo: this.orderNo,
                };
                consumer_admin_insurance_refundInsuranceOrder(data).then(res => {
                    this.$message({
                        type: "success",
                        message: '退保成功'
                    });
                    this.getDetail()
                })
            }).finally(() => {
                this.detailLoading = false
            });
        },
        reactInsurance(val) {
            const h = this.$createElement;
            this.$msgbox({
                title: '确认投保',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                const params = {
                    businessType: 2,   // 业务类型
                    userId: val.userId,
                    productCode: val.productCode,
                    orderNo: this.orderNo,
                    chaOrderNo: this.chaOrderNo,
                    segmentNo: '',
                };
                this.detailLoading = true;
                consumer_admin_insurance_orderInsuranceAgain(params).then(res => {
                    this.$message({
                        type: "success",
                        message: '投保成功'
                    });
                    this.getDetail()
                });
            }).finally(() => {
                this.detailLoading = false
            });
        },
        refundMoney(val) {
            const h = this.$createElement;
            this.$msgbox({
                title: '确认退款',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                const params = {
                    orderNo: this.orderNo,
                    productCode: val.productCode,
                    userId: val.userId
                }
                this.detailLoading = true
                consumer_admin_insurance_tradeRefundInsuranceOrder(params).then(res => {
                    this.$message({
                        type: "success",
                        message: '退款成功'
                    })
                    this.getDetail()
                })
            }).finally(() => {
                this.detailLoading = false
            });
        },
        checkInsuranceDetail(val) {
            this.detailLoading = true
            const params = {
                insuranceOrderId: val.insuranceOrderId
            }
            consumer_insurance_getEInsuranceOrderUrl(params).then(res => {
                const url = res.datas.epolicyUrl
                if (url) {
                    window.open(url)
                }
            }).finally(() => {
                this.detailLoading = false
            })
        },
        // 调整服务费
        changeService() {
            let params = {
                businessType: 22,
                orderNo: this.changeNo,
                oldService: this.detail.serviceAmount,
            };
            this.$refs.changeService.init(params).then(res=>{
                this.getDetail();
            }).catch((e)=>{})
        },
    }
}
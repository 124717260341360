// consumer_admin_flight_manage_lockOrder 锁异常订单
// http://yapi.yinzhitravel.com/project/52/interface/api/15604
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_flight_manage_lockOrder = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/flight/manage/lockOrder',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_flight_manage_lockOrder;

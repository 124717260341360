export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/hotel-search',
            name: 'admin-hotel-search',
            component: () => import(/* webpackChunkName: "page-admin-replace-orders-hotel-search" */ `./1.0.6/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `酒店搜索`,
                },
                entity_list_for_breadcrumb: [
                    { name: `代客下单` },
                    { name: `酒店搜索` },
                ],
            },
        },
    ],
};

import consumer_admin_trains_order_trainsOfflineRefundOrderDetails
    from "@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsOfflineRefundOrderDetails";//获取火车票线下单退订单详情
import consumer_admin_flight_generate_userName
    from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_userName'// 当前登陆人
import consumer_admin_trains_order_trainsRefundConfirm
    from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsRefundConfirm'//保存退票信息

export default {
    data() {
        return {
            loading: false,
            orderNo: '',//订单号
            refundNo: '',//退票订单号
            details: {},//详情
            trainsOrderDetails: {},
            trainsRefundPassenger: {},
            refundInformation: {
                appTime: '',//退票申请时间
                supplierRefundOrderNo: '',//供应商退票订单号
                initialName: '',//操作客服名称
                userId: '',//操作客服id
            }
        };
    },
    methods: {
        //初始化
        async init() {
            let {
                orderNo,
                refundNo,
            } = this.$route.query;
            this.orderNo = orderNo;
            this.refundNo = refundNo;
            await this.getDetail();
            await this.getCurrentUser();
        },

        //获取当前登录人
        getCurrentUser() {
            consumer_admin_flight_generate_userName().then(res => {
                let currentUser = res.datas;
                this.refundInformation.initialName = currentUser[0].initialName;
                this.refundInformation.userId = currentUser[0].userId;
            });
        },

        //获取详情
        async getDetail() {
            this.loading = true;
            let params = {
                orderNo: this.orderNo,//订单号
                refundNo: this.refundNo,//退票订单号
            }
            let res = await consumer_admin_trains_order_trainsOfflineRefundOrderDetails(params);
            this.details = res.datas;
            this.trainsOrderDetails = this.details.trainsOrderDetails;
            this.refundInformation = this.details.refundInformation;
            this.trainsRefundPassenger = this.details.journeyInfo[0].trainsRefundPassenger[0];
            this.loading = false;
        },

        //保存备注
        submit() {
            consumer_admin_trains_order_trainsRefundConfirm({refundNo: this.refundNo}).then(res => {
                this.$message({
                    type: 'success',
                    message: '保存成功！'
                });
                this.$router.replace({
                    name: 'admin-train-refund-list'
                })
            })
        },

        //返回
        back() {
            this.$router.push({
                name: 'admin-train-refund-list'
            })
        },
    },
    activated() {
        this.init();
    }
}
import consumer_department_getStaffsAndExCus from '@/lib/data-service/haolv-default/consumer_department_getStaffsAndExCus';
import consumer_department_getStaffsAndExCusByUserIds from '@/lib/data-service/haolv-default/consumer_department_getStaffsAndExCusByUserIds';
import SelectCertificate from "@/component/travel-multiple-select-dialog/component/selectCertificate/index.vue";
import NewCertificatesPopup from "@/component/travel-multiple-select-dialog/component/newCertificatesPopup/index.vue";
export default {
    data() {
        return {
            selectType: '000',
            dialogVisible: false,
            companyId: '',
            keyword: '',
            loading: false,
            params: {
                currentPage: 1,
                pageSizes: [10, 20, 50, 100],
                pageSize: 10,
                layout: `total, sizes, prev, pager, next, jumper`,
                total: 0,
                isSelectCustomer: true,
            },
            list: [],
            //activeDetailList: [], // 选中的用户详情
            //activeIdList: [], // 选中的用户id

            activeKey: '',
        }
    },
    props: {
        activeUserList: {
            type: Array,
            default: ()=> [],
        }
    },
    components: {
        SelectCertificate,
        NewCertificatesPopup,
    },
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        activeDetailList:{
            get () {
                return this.activeUserList;
            },
            set (val) {
                this.$emit('update:activeUserList', val);
            }
        },
        activeIdList() {
            let idList = [];
            let detailList = this.activeUserList;
            detailList.forEach(value => {
                idList.push(value.userId);
            })
            return idList;
        },
    },
    filters: {},
    methods: {
        /**
         * 初始化
         * 入参：
         * params: obj {
         *     companyId: String, 公司id,
         *     selectType: '000'：多选； ‘001’： ‘单选’
         * }
         * **/
        init(params) {
            if (params) {
                if (params.companyId) {
                    this.companyId = params.companyId;
                }
                let selectType = '000';
                if (params.selectType) {
                    selectType = params.selectType;
                }
                this.selectType = selectType;
            }
            this.activeDetailList = [];
        },
        /**
         * 设置companyId
         * 入参：
         * companyId： string
         * */
        setCompany(companyId, clear = true) {
            this.companyId = companyId;
        },
        show(clear = true) {
            this.dialogVisible = true;
        },
        /*getSelectedListPromise(params) {
            return new Promise(resolve => {
                let idList = params.idList || [];
                let detailList = [];
                console.log(idList, this.activeDetailList);
                this.activeDetailList.forEach(value => {
                    if (idList.indexOf(value.userId) > -1) {
                        detailList.push(value);
                    }
                })
                this.activeIdList = idList;
                this.activeDetailList = detailList;
                resolve({
                    activeIdList: idList,
                    activeDetailList: detailList,
                })
            });
        },*/
        getSelectedDetailList() {
            return this.activeDetailList;
        },
        _handleClose() {
            this.dialogVisible = false;
        },
        _search() {
            this.params.currentPage = 1;
            this._getUserList();
        },
        _clear() {
            this._search();
        },
        _opened() {
            this.keyword = '';
            this.activeKey = '';
            this._search();
        },
        _getUserList() {
            this.loading = true;
            let params = JSON.parse(JSON.stringify(this.params));
            params.key = this.keyword;
            params.companyId = this.companyId;
            consumer_department_getStaffsAndExCus(params).then(res=>{
                this.list = res.datas.list;
                this.params.total = res.datas.totalCount;
            }).finally(()=>{
                this.loading = false;
            })
        },
        _choose(val) {
            let index = this.activeIdList.indexOf(val.userId);
            if (this.selectType === '000') {
                if (index === -1) {
                    this.activeDetailList.push(val);
                } else {
                    this.activeDetailList.splice(index, 1);
                }
            }
            if (this.selectType === '001') {
                if (index === -1) {
                    this.activeDetailList = [val];
                } else {
                    this.activeDetailList = [];
                }
            }
        },
        _sizeChange(val) {
            console.log(val);
            this.params.pageSize = val;
            this._search();
        },
        _currentChange(val) {
            this.params.currentPage = val;
            this._getUserList();
        },

        _searchActive() {},
        _remove(item, index) {
            this.activeDetailList.splice(index, 1);
        },
        _setRowClass({row, rowIndex}) {
            let t = '';
            let index = this.activeIdList.indexOf(row.userId);
            if (index > -1) {
                t += ' row-entity-selected'
            }
            return t;
        },
        _submit() {
            this._handleClose();
            this.$emit('getUserList', {
                activeIdList: JSON.parse(JSON.stringify(this.activeIdList)),
                activeDetailList: JSON.parse(JSON.stringify(this.activeDetailList)),
            })
        },

        _changeCertificate(val, item) {
            let certificate = val.certificate;
            item.cardExpireDate = certificate.cardExpireDate;
            item.certificateValue = certificate.certificate;
            item.certificateName = certificate.certificateText;
            item.certificateType = certificate.certificateValue;
            item.countryCode = certificate.countryCode;
            item.countryName = certificate.countryName;
            item.issCountryCode = certificate.issCountryCode;
            item.issCountryName = certificate.issCountryName;
            item.unSensitiveCertificateValue = certificate.unSensitiveCertificateValue;
            item.cardVisible = false;
            // 选中或者替换当前选中
            let index = this.activeIdList.indexOf(item.userId);
            if (index === -1) {
                this.activeDetailList.push(item);
            } else {
                this.activeDetailList[index] = item;
            }
        },
        _addCertificate(user, item) {
            this.$refs.aNewCertificatesPopup.init(user);
        },
        async _getNewCertificates(params) {
            let res = await consumer_department_getStaffsAndExCusByUserIds({userIds: [params.userId]})
            this.list.forEach((item, index) => {
                if (item.userId === params.userId) {
                    item.certificates = res.datas.list[0].certificates;
                    item.certificateName = params.certificateTypeName;
                    item.certificateValue = params.certificateValue;
                    item.certificateType = params.certificateType;
                    // 选中或者修改
                    // 选中或者替换当前选中
                    let index = this.activeIdList.indexOf(item.userId);
                    if (index === -1) {
                        this.activeDetailList.push(item);
                    } else {
                        this.activeDetailList[index] = item;
                    }
                }
            })
        },
    }
}

import URI from 'urijs'
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
export default {
    name: "Upload",
    data() {
        return {
            ossClient: new OssClient(),
            picVisible: false,
            currentPic: ''
        };
    },
    props: {
        list: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    methods: {
        uploadFile(e) {
            let file = e.target.files;
            // if (file.length === 0) return;
            if (this.list.length >= 3) {
                return
            }
            Object.values(file).map(item => {
                this.uploadChange(item);
            });
        },
        uploadChange(file) {
            this.ossClient
                .multipartUpload({
                    file: file
                })
                .then(res => {
                    if (this.list.length >= 3) {
                        return
                    }
                    this.list.push(res.url);
                    this.updateList();
                    this.$refs.fileInput.value = "";
                });
        },
        deleteList(index) {
            this.list.splice(index, 1);
            this.updateList();
        },
        updateList() {
            this.$emit("update:list", this.list);
        },
        detectionImg(file) {
            const reg2 = /^(\s|\S)+(jpg|png|jpeg)+$/;
            const isLt2M = file.size / 1024 < 500;
            if (!reg2.test(file.name)) {
                this.$message({ type: "warning", message: "图片格式上传错误！" });
                return false;
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 500K!");
                return false;
            }
        },
        showFullPic(item) {
            this.picVisible = true
            this.currentPic = item
        },
    },
    created() {},
    activated() {},
    watch: {},
    computed: {
        showList() {
            let resultList = [];
            if(this.list && this.list.length > 0) {
                this.list.forEach(item => {
                    resultList.push({
                        name: URI(item).filename(),
                        url: item
                    })
                })
            }
            return resultList
        }
    }
};

export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/replace-orders',
            name: 'admin-replace-orders',
            component: () => import(/* webpackChunkName: "page-admin-replace-orders-replace-orders" */ `./1.0.1/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `代客下单`,
                },
                entity_list_for_breadcrumb: [
                    {name: `代客下单`},
                    {name: `代客下单`},
                ],
            },
        },
    ],
};

export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/hotel-order/refund',
            name: 'admin-hotel-order-refund',
            component: () => import(/* webpackChunkName: "page-admin-order-management-hotel-sold-refund" */ `./1.0.5/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `已销售的酒店订单-退订`,
                },
                entity_list_for_breadcrumb: [
                    { name: `酒店订单` },
                    { name: `已销售的酒店订单` },
                    { name: `已销售的酒店订单-退订` },
                ],
            },
        },
    ],
};

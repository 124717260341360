export default {
  path: '',
  component: () => import(`@/component/admin-layout/index.vue`),
  children: [
    {
      path: '/admin/user-draft-detail',
      name: 'admin-user-draft-detail',
      component: () => import(/* webpackChunkName: "page-admin-users-management-user-draft-detail" */ `./index.vue`),
      meta: {
        entity_for_history_tag: {
          name: `草稿箱详情`,
        },
        entity_list_for_breadcrumb: [
          {name: `客户管理`},
          {name: `草稿箱详情`},
        ],
        data_for_left_menu: {
          get_active_route_name(pParameter) {
            return `admin-users-management-list`;
          },
        },
      },
    },
  ],
};

import Q from 'q';

// 客户审核列表
import consumer_web_company_getAuditCompanyList from '@/lib/data-service/haolv-default/consumer_web_company_getAuditCompanyList.js';
import SearchBox from "@/component/search-box/1.0.1/index.vue";

export default {
    components: {SearchBox},
	data() {
		return {
			form: {
				currentPage: 1,
				pageSize: 10,
				companyName: '',
				phone: '',
				startDate: '',
				endDate: '',
				clientType: 0,
				status: 1,
			},

			registerDate: null,
			list: [],
			allocation: {
				status: [
					{
						label: 1,
						text: '待审核'
					},
					{
						label: 3,
						text: '审核失败'
					},
				]
			},
			pager: {
				pageSizes: [10, 15, 20, 25, 30],
				total: 0,
			},
			loading: false,
			clientTypeOptions: [
				{
					label: 0,
					text: "全部",
				},
				{
					label: 1,
					text: "临时",
				},
				{
					label: 2,
					text: "正式",
				},
			],
		}
	},
	methods: {
		req_list(params) {
			this.loading = true;
			this.list = [];
			consumer_web_company_getAuditCompanyList(params).then((res) => {
				this.list = res.datas.list;
				this.pager.total = res.datas.totalCount;
				this.loading = false;
			}).catch((err) => {
				this.loading = false;
			})
		},
		search() {
			this.form.currentPage = 1;
			this.req_list(this.form);
		},
		reset() {
			this.registerDate = null;
			const pageSize = this.form.pageSize;
			const status = this.form.status;
			this.form = {
				currentPage: 1,
				pageSize,
				companyName: '',
				phone: '',
				startDate: '',
				endDate: '',
				status,
				clientType: 0,
			};
			this.req_list(this.form);
		},
		chagne_staus(val) {
			this.form.currentPage = 1;
			this.req_list(this.form);
		},
		change_currentPage(currentPage) {
			this.form.currentPage = currentPage;
			this.req_list(this.form);
		},
		change_pageSize(pageSize) {
			this.form.pageSize = pageSize;
			this.form.currentPage = 1;
			this.req_list(this.form);
		},
		to_audit(id) {
			this.$router.push({
				name: 'admin-users-audit',
				query: { id },
			})
		},
	},
	activated() {
		this.reset();
	},
	watch: {
		registerDate(val) {
			const form = this.form;
			if (val) {
				form.startDate = val[0];
				form.endDate = val[1];
			} else {
				form.startDate = '';
				form.endDate = '';
			}
		}
	},
}
import consumer_credit_open_detail from "@/lib/data-service/haolv-default/consumer_credit_open_detail.js";
import consumer_credit_creditAdjust from "@/lib/data-service/haolv-default/consumer_credit_creditAdjust.js";
import consumer_credit_creditDetail from "@/lib/data-service/haolv-default/consumer_credit_creditDetail.js";
import apply_approve from "@/lib/data-service/haolv-default/consumer_credit_auditCredit.js";// 审批
import number2text from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/number_to_text/index'
import history_tag_api from '@/component/admin-layout/component/history-tag/api';
import {Loading} from "element-ui";
import _ from 'underscore';

export default {
  data() {
    const validateEstimateConsumption = (rule, value, callback) => {
      const re = /^([1-9][\d]*|0)(\.[\d]+)?$/;
      if (value === '') {
        callback(new Error('请输入预计月消费量'));
      } else if (re.test(value) === false) {
        callback(new Error('请输入正确的数字'));
      } else {
        callback();
      }
    };
    const validateCreditAmount = (rule, value, callback) => {
      const re = /^([1-9][\d]*|0)(\.[\d]+)?$/;
      if (value === '') {
        callback(new Error('请输入授信额度'));
      } else if (re.test(value) === false) {
        callback(new Error('请输入正确的数字'));
      }else {
        callback();
      }
    };

    return {
      loading: true,
      submitLoading: false,

      type: 'open',//'open':开通授信；'approve'：审核开通申请
      allocation: {
        settlementInterval: 3,
        creditPaymentDays: 30,
        dueDate: 30,
        overdueDays: 16
      },

      form: {
        companyId: '',
        status: 2,//2：开通；3：关闭
        settlementInterval: '1',
        creditPaymentDays: '1',
        dueDate: '15',
        overdueDays: '7',
        creditAmount: '',
        rejectReason:''//拒绝理由
      },
      rules: {
        status: [
          { required: true, message: '请选择是否开通', trigger: 'blur' }
        ],
        settlementInterval: [
          { required: true, message: '请选择结算周期', trigger: 'change' }
        ],
        creditPaymentDays: [{required: true, message: '请选择账单日'}],
        dueDate: [{required: true, message: '请选择还款日'}],
        overdueDays: [{required: true, message: '请选择可逾期天数'}],
        creditAmount: [
          { validator: validateCreditAmount, trigger: 'blur', required: true },
        ],
        rejectReason: [
          { required: true, message: '请填写拒绝理由', trigger: 'blur' }
        ],
      },
      detail: {},

      periodList: [
        { label: '标准自然月', value: '1' },
        { label: '每月16日至次月15日', value: '2' },
        { label: '每月26日至次月25日', value: '3' },
        { label: '每月6日至次月5日', value: '4' },
        { label: '每月11日至次月10日', value: '5' },
      ],
      overdueDayList: [
        { label: '1', text: '1' },
        { label: '2', text: '2' },
        { label: '3', text: '3' },
      ],
    };
  },
  methods: {
    //获取详情
    setDetail() {
      const companyId = this.$route.query.companyId;
      this.loading = true;
      consumer_credit_open_detail({ companyId })
        .then(res => {
          let detail = res.datas;

          detail.orderCount = {};
          detail.passengerCount = {};
          detail.flowing = {};
          detail.transactionList.forEach(item => {
            if (item.type === '机票') {
              detail.orderCount.plane = item.orderCount || 0;
              detail.passengerCount.plane = item.passengerCount || 0;
              detail.flowing.plane = item.flowing || 0;
            } else if (item.type === '酒店') {
              detail.orderCount.hotel = item.orderCount || 0;
              detail.passengerCount.hotel = item.passengerCount || 0;
              detail.flowing.hotel = item.flowing || 0;
            } else {
              detail.orderCount.train = item.orderCount || 0;
              detail.passengerCount.train = item.passengerCount || 0;
              detail.flowing.train = item.flowing || 0;
            }
          });

          detail.orderCountSum = detail.orderCount.plane + detail.orderCount.hotel + detail.orderCount.train;
          detail.passengerCountSum = detail.passengerCount.plane + detail.passengerCount.hotel + detail.passengerCount.train;
          detail.flowingSum = detail.flowing.plane + detail.flowing.hotel + detail.flowing.train;

          this.detail = detail;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    //获取开通授信详情
    getApproveDetail() {
      const companyId = this.$route.query.companyId;
      this.loading = true;
      consumer_credit_creditDetail({companyId}).then(res => {
        this.loading = false;
        let {
          creditAmount,
          settlementInterval,
          creditPaymentDays,
          dueDate,
          overdueDays
        } = res.datas;
        this.form.creditAmount = creditAmount;
        this.form.settlementInterval = settlementInterval.toString();
        this.form.creditPaymentDays = creditPaymentDays;
        this.form.dueDate = dueDate;
        this.form.overdueDays = overdueDays;
      }).finally(() => {
        this.loading = false;
      });
    },

    //提交开通申请
    onSubmitOpeningApplication() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let query = {
            ...this.form,
            companyId: this.$route.query.companyId,
          };
          this.submitLoading = true;
          consumer_credit_creditAdjust(query)
              .then(() => {
                this.$message.success('提交成功');
                this.toApproveList();
              })
              .finally(() => {
                this.submitLoading = false;
              });
        } else {
          this.$message.error('请检查表单是否填写正确');
        }
      });
    },

    //提交审核结果前的检查
    onApproved(status) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.onSubmitAuditResult(status);
        } else {
          return;
        }
      });
    },

    //提交审核结果
    onSubmitAuditResult(status) {
      this.submitLoading = Loading.service({text: "请求中..."});
      apply_approve({...this.form, status})
          .then((result) => {
            this.submitLoading.close();
            if (status === 2) {
              this.$message.success("设置审批通过成功");
              let name = 'admin-credit-users-list';
              const data_app_for_npm_haolv_platform_v2 = this.$route.meta.data_app_for_npm_haolv_platform_v2;
              if (data_app_for_npm_haolv_platform_v2) {
                const get_route_list_page = data_app_for_npm_haolv_platform_v2.get_route_user_list_page;
                if (_.isFunction(get_route_list_page)) {
                  const route_list_page = get_route_list_page();
                  if (route_list_page) {
                    const route_name = route_list_page.route_name;
                    if (route_name) {
                      name = route_name;
                    }
                  }
                }
              }
              this.$router.replace({
                name,
              });
            } else if (status === 3) {
              this.$message.success("设置审批拒绝成功");
              let name = 'admin-credit-approve-list';
              const data_app_for_npm_haolv_platform_v2 = this.$route.meta.data_app_for_npm_haolv_platform_v2;
              if (data_app_for_npm_haolv_platform_v2) {
                const get_route_list_page = data_app_for_npm_haolv_platform_v2.get_route_list_page;
                if (_.isFunction(get_route_list_page)) {
                  const route_list_page = get_route_list_page();
                  if (route_list_page) {
                    const route_name = route_list_page.route_name;
                    if (route_name) {
                      name = route_name;
                    }
                  }
                }
              }
              this.$router.replace({
                name,
                query: {
                  activeName: '3'
                }
              });
            }
          })
          .catch((err) => {
            this.submitLoading.close();
          });
    },

    //提交开通申请
    onSubmit() {
      if (this.type === 'open') {
        this.onSubmitOpeningApplication();
      } else {
        this.onApproved(this.form.status);
      }
    },

    //跳转至审核列表
    toApproveList() {
      history_tag_api.remove_tag_by_route({
        route: this.$route,
      });
      let name = 'admin-credit-approve-list';
      const data_app_for_npm_haolv_platform_v2 = this.$route.meta.data_app_for_npm_haolv_platform_v2;
      if (data_app_for_npm_haolv_platform_v2) {
        const get_route_list_page = data_app_for_npm_haolv_platform_v2.get_route_list_page;
        if (_.isFunction(get_route_list_page)) {
          const route_list_page = get_route_list_page();
          if (route_list_page) {
            const route_name = route_list_page.route_name;
            if (route_name) {
              name = route_name;
            }
          }
        }
      }
      this.$router.replace({
        name
      });
    },

    //返回授信开通列表
    onBack() {
      history_tag_api.remove_tag_by_route({
        route: this.$route,
      });
      let openName = 'admin-oppen-credit-list'
      const data_app_for_npm_haolv_platform_v2 = this.$route.meta.data_app_for_npm_haolv_platform_v2;
      if (data_app_for_npm_haolv_platform_v2) {
        const get_route_list_page = data_app_for_npm_haolv_platform_v2.get_route_open_list_page;
        if (_.isFunction(get_route_list_page)) {
          const route_list_page = get_route_list_page();
          if (route_list_page) {
            const route_name = route_list_page.route_name;
            if (route_name) {
              openName = route_name;
            }
          }
        }
      }
      let name = 'admin-credit-approve-list';
      if (data_app_for_npm_haolv_platform_v2) {
        const get_route_list_page = data_app_for_npm_haolv_platform_v2.get_route_list_page;
        if (_.isFunction(get_route_list_page)) {
          const route_list_page = get_route_list_page();
          if (route_list_page) {
            const route_name = route_list_page.route_name;
            if (route_name) {
              name = route_name;
            }
          }
        }
      }
      let href = this.type === 'open' ? openName : name
      this.$router.replace({
        name: href
      });
    },
  },
  filters: {
    formatNumber(value) {
      return value.toLocaleString();
    },
    //授信额度转换为大写中文
    numberToChinese(val) {
      let t = '';
      if (val) {
        t = number2text(val*10000).replace('整', '');
      }
      return t;
    },
  },
  activated() {
    this.form.companyId = this.$route.query.companyId;
    this.type = this.$route.query.type;
    this.setDetail();
    if (this.type === 'approve') {
      this.getApproveDetail();
    }
  },
};

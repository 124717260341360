import moment from 'moment'
export default {
    sourceType: 1,
    orderNo: '',
    customerInfo: {
        companyId: '',
        companyName: '',
        contactMobile: '',
        contactName: '',
        evectionType: 2,
        payType: 1,
        deptId: '',
        deptName: '',
        oaEvectionNo: '',
        feeAffiliationType: 1,
    },
    journeyInfo: [
      {
        sourceType: 1,
        airlineName: '',
        airlineCode: '',
        flightNo: '',
        depCityObj: null,
        arrCityObj: null,
        depAirportName: '',
        depAirportCode: '',
        depCityName: '',
        depCityCode: '',
        arrAirportName: '',
        arrAirportCode: '',
        arrCityName: '',
        arrCityCode: '',
        depDateTime: '',
        arrDateTime: '',
        depDate: '',
        depTime: '',
        arrDate: '',
        arrTime: '',
        cabin: '',
        cabinRank: 3,
        cabinRankText: '经济舱',
        segmentNo: '',
        segmentType: 1,
        segmentTypeText: '第1程',
        depTerminal: 'T1',
        arrTerminal: 'T1',
        // keyAccountCode: '', // 大客户号
      },
    ],
    passengerList: [
        {
            userId: '',
            segmentType: 1,
            segmentTypeText: '单程',
            psgName: '',
            psgType: 1,
            psgTypeText: '成人',
            cardType: 1,
            cardTypeText: '身份证',
            cardNo: '',
            birthday: '',
            mobile: '',
            ticketNumber: '',
            ticketNumberList: [''],
            pnr: '',
            price: 0,
            infrastructure: 0,
            fuel: 0,
            commission: 0,
            serviceCharge: 0,
            purchasePrice: 0,
            buyerPrice: 0,
            countryCode: 'CN',
            issCountryCode: 'CN',
            cardExpireDate: '',
            familyName: '',
            givenName: '',
            tagNames: [], // 标签数组
        }
    ],
    insuranceInfo: [
      {
        insuranceSource: 2,
        productCodes: [],
        otherProductCodes: [],
        isInsure: 1,
        isInsureChecked: true,
        productName: '', // 保险名称
        channel: 2, // 保险渠道 1：华泰 2：德付通 3：旅游卫士 4：惠择
        insuranceOrderId: '', // 保单号
        premium: '', // 保险销售价
        insureCost: '', // 保险成本
        startDate: '', // 生效时间
        endDate: '', // 失效时间
        payType: 3, // 支付方式
        validity: '',
      }
    ],
    ticketInfo: {
        orderSource: 4,
        ticketChannel: 6,
        ticketChannelText: 'BSP',
        supplierOrderNo: '',
        initialName: '',
        ticketTime: '',
        orderTime: '',
        bookingSms: 1,
        linkmanSms: 1,
        officialTicketChannel: 1,
        officialTicketBank: '',
        keyAccountCode: '', // 大客户号
    },
    adultPrice: {
        psgType: 1,
        price: 0,
        infrastructure: 50,
        fuel: 0,
        otherTax: 0,
        serviceCharge: 0,
        purchasePrice: 0,
        buyerPrice: 0,
        commission: 0,
        returnLater: 0,
    },
    childPrice: {
        psgType: 2,
        price: 0,
        infrastructure: 0,
        fuel: 0,
        otherTax: 0,
        serviceCharge: 0,
        purchasePrice: 0,
        buyerPrice: 0,
        commission: 0,
        returnLater: 0,
    },
    babyPrice: {
        psgType: 3,
        price: 0,
        infrastructure: 0,
        fuel: 0,
        otherTax: 0,
        serviceCharge: 0,
        purchasePrice: 0,
        buyerPrice: 0,
        commission: 0,
        returnLater: 0,
    },
    remark: ''
}

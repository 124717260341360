import VoyageInfo from "@/page/admin/order-management/flight/component/voyageInfo.vue";
import FormTabel from "./formTabel";
import NullData from "../../component/nullData";
import refundOrder from "@/lib/data-service/haolv-default/consumer_admin_flight_refund_refundOrder";
import { ChangeDateFormat } from "@/lib/utils/common";
import saveRefundOrder from "@/lib/data-service/haolv-default/consumer_air_ticket_saveRefundOrder";
import consumer_admin_flight_equity_getOrderProductInfo from '@/lib/data-service/haolv-default/consumer_admin_flight_equity_getOrderProductInfo'

export default {
    name: "FlightRefund",
    data() {
        return {
            orderProductInfo: {},
            dialogEnjoyMore: false,
            detail: {},
            noPassenger: true,
            segmentList: {},
            ChangeDateFormat: ChangeDateFormat,
            formData: {
                code: '',
                refReason: '',
                remark: '',
                remarkText: '',
                url: "",
                orderStatus: 1,
                otherRefundReason: '',
                explainMsg: '',
            },
            loading: false,
            canSubmit: true,

            dialogVisible:  false,
        };
    },
    components: {
        VoyageInfo,
        FormTabel,
        NullData
    },
    methods: {
        clickEnjoyMore() {
            consumer_admin_flight_equity_getOrderProductInfo({orderNo: this.detail.orderNo}).then((res) => {
                this.orderProductInfo = res.datas;
            });
            this.dialogEnjoyMore = true;
        },
        checkboxInit(row) {
            if (row.refundEnable === 1) {
                return true;
            } else {
                return false;
            }
        },
        async submit() {
            if (!this.submitDisabled) {
                return
            }
            let formResult = await this.$refs['formTable'].checkForm()
            if (!formResult) {
                return
            }
            let segment = Object.values(this.segmentList);
            let formData = JSON.parse(JSON.stringify(this.formData));
            if (segment.length === 0) {
                return this.$message({ type: "warning", message: "请选择航程" });
            }
            // 15838 【差旅平台端】6814 自营订单提交退票申请，希望附件为非必传
            let mustFile = true;
            for (let value in this.segmentList) {
                if (this.segmentList[value]) {
                    this.segmentList[value].forEach(value1=>{
                        if (value1.flightProductType === '7') {
                            mustFile = false;
                        }
                    })
                }
            }
            if (formData.refReason !== '' && formData.refReason !== 1 && !formData.url && mustFile) {
                return this.$message({ type: "warning", message: "请上传证明" });
            }

            /*if (formData.refReason === 5 && formData.otherRefundReason === '') {
                return this.$message({ type: "warning", message: "请选择退票理由" });
            }*/
            let segmentTypeList = [];
            for (let key in this.segmentList) {
                this.segmentList[key].forEach(value=>{
                    if (segmentTypeList.indexOf(value.segmentType) === -1) {
                        segmentTypeList.push(value.segmentType)
                    }
                })
            }
            if (segmentTypeList.length > 1) {
                return this.$message({ type: "warning", message: "去程和回程不能同时选择" });
            }
            /**params */
            if (!this.canSubmit) {
                return
            }
            this.canSubmit = false
            formData.psgNo = segment.map(item => {
                return item.map(items => items.psgNo).join("");
            });
            formData.flightNo = segment.map(item => {
                return item.map(items => items.flightNo).join("");
            });
            formData.segmentNo = segment.map(item => {
                return item.map(items => items.segmentNo).join("");
            });

            let obj = {
                orderNo: this.detail.orderNo,
                evectionNo: this.detail.evectionNo,
                evectionType: this.detail.evectionType === '个人预定' ? 1 : 2
            };
            data = Object.assign(formData, obj);
            let data = {
                applet: data
            };
            console.log(data);
            saveRefundOrder(data).then(res => {
                this.canSubmit = true
                this.$message({ type: "success", message: "提交成功" });
                this.$router.push({ name: "admin-flight-refund-list" });
            }, err => {
                this.canSubmit = true
                this.$alert(err.data.msg, '温馨提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.$router.go(-1)
                    }
                });
            }).catch((err) => {
                this.canSubmit = true
                this.$alert(err.data.msg, '温馨提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.$router.go(-1)
                    }
                });
            });
        },
        getSegment(val, index) {
            if (val.length > 0) {
                this.segmentList[index] = val;
            } else {
                delete(this.segmentList[index])
            }
        },
        getDetail() {
            let orderNo = this.$route.query.orderNo;
            let orderStatus = this.$route.query.orderStatus
            this.loading = true;
            refundOrder({ orderNo, orderStatus }).then(res => {
                this.loading = false
                let noPassenger = true
                let detail = res.orderRefund
                if (detail.airplanePassengerList) {
                    detail.airplanePassengerList.forEach(value => {
                        if (value.theFlightList && value.theFlightList.length > 0) {
                            value.theFlightList.forEach(val => {
                                val.psgNo = value.psgNo
                                val.ticketNumber = value.ticketNumber
                            })
                            noPassenger = false
                        }
                    })
                }
                this.detail = detail
                this.noPassenger = noPassenger
                if (this.noPassenger) {
                    this.dialogVisible = true
                    return
                }
                this.$nextTick(() => {
                    let refundTable = this.$refs['refundTable']
                    if (refundTable instanceof Array) {
                        refundTable.forEach(value => {
                            value.clearSelection()
                        })
                    } else {
                        refundTable.clearSelection()
                    }
                })
            }).catch(() => {
                this.loading = false;
            });
        },
        goBack() {
            this.dialogVisible = false
            this.$router.go(-1)
        },
    },
    created() {

    },
    activated() {
        this.formData.orderStatus = this.$route.query.orderStatus
        this.getDetail();
    },
    watch: {},
    computed: {
        submitDisabled() {
            let isHasUser = false
            let detail = this.detail
            if (detail) {
                detail.airplanePassengerList.forEach(value => {
                    if (value.theFlightList && value.theFlightList.length > 0) {
                        value.theFlightList.forEach(value1 => {
                            if (value1.refundEnable === 1) {
                                isHasUser = true
                            }
                        })
                    }
                })
            }
            return isHasUser
        }
    }
};
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/

export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/haolv/demo-TrainCitySelector',
            name: 'admin-haolv-demo-TrainCitySelector',
            component: () => import(/* webpackChunkName: "page-admin-haolv-demo-TrainCitySelector" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `demo-TrainCitySelector`,
                },
                entity_list_for_breadcrumb: [
                    {name: `demo`},
                    {name: `demo-TrainCitySelector`},
                ],
            },
        },
    ],
};

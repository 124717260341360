// 列表数据
import consumer_flight_report_order_addExpressRecordList from '@/lib/data-service/haolv-default/consumer_flight_report_order_addExpressRecordList'
// 邮寄信息详情
import consumer_flight_report_order_mailingDetail from '@/lib/data-service/haolv-default/consumer_flight_report_order_mailingDetail'


import SearchBox from '@/component/search-box/import_latest_version_module.js'
import Pagination from '@/component/base-pagination/index.vue'
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
const moment = require('moment');




export default {
  data() {
    const validatorTrackingNumber = (rule, value, callback) => {
      if (this.FailCheck.trackingNumber) {
        callback();
      } else {
        callback(new Error('请输入快递单号'));
        return
      }
      callback();
    };
    const validatorExpressName = (rule, value, callback) => {
      if (this.FailCheck.expressName) {
        callback();
      } else {
        callback(new Error('请选择快递公司'));
        return
      }
      callback();
    };
    const validatorPecipient = (rule, value, callback) => {
      if (this.FailCheck.pecipient) {
        callback();
      } else {
        callback(new Error('请输入收件人'));
        return
      }
      callback();
    };
    const validatorPhone = (rule, value, callback) => {
      if (this.FailCheck.phone) {
        callback();
      } else {
        callback(new Error('请输入收件人手机号'));
        return
      }
      callback();
    };
    const validatorMailingTime = (rule, value, callback) => {
      if (this.FailCheck.mailingTime) {
        callback();
      } else {
        callback(new Error('请输入邮寄时间'));
        return
      }
      callback();
    };

    return {
      timeGmtCreate: [], //申请时间
      timeGmtModified: [], //核对时间
      timeMailingTime: [], //邮寄时间
      viewMailDialog: false,
      fileList: [],
      ossClient: new OssClient(),
      expressNameList: [
        {
          value: '顺丰快递',
          label: '顺丰快递',
        }
      ],
      FailCheck: {
        expressName: '顺丰快递',  //快递公司
        mailingTime: '',  // 邮寄时间
        trackingNumber: '', // 快递单号
        pecipient: '',  // 收件人
        phone: '',   // 收件人手机号
        attachmentAddress: '', // 附件
        notes: '', // 备注
        Rules: {
          trackingNumber: [{required: true, validator: validatorTrackingNumber, trigger: 'blur'}],
          expressName: [{required: true, validator: validatorExpressName, trigger: 'blur'}],
          pecipient: [{required: true, validator: validatorPecipient, trigger: 'blur'}],
          phone: [{required: true, validator: validatorPhone, trigger: 'blur'}],
          mailingTime: [{required: true, validator: validatorMailingTime, trigger: 'blur'}],
        },
      },

      loading: false,
      multipleSelection: [],
      params: {
        batchId: '',   //开票批次id
        endGmtCreate: '', //结束申请时间
        endGmtModified: '', //结束核对时间
        endMailingTime: '', //结束邮寄时间
        invoiceTitle: '', //发票抬头
        mailingStatus: '-1',// 邮寄状态 -1-全部 0-待邮寄，1-已邮寄
        orderNo: '', //订单号
        reimburseApplicationId: '', //报账单号
        staffName: '', //乘机人
        startGmtCreate: '',//开始申请时间
        startGmtModified: '',//开始核对时间
        startMailingTime: '',//开始邮寄时间
        tfn: '',//税号
        trackingNumber: '',//快递单号
        pageSize: 10,    //每页记录条数
        currentPage: 1,  // 当前页码
        total: 0,    // 总条数
      },
      tabList: [
        {
          name: '-1',
          label: "全部",
          value: 0
        },
        {
          name: '0',
          label: "待邮寄",
          value: 0
        },
        {
          name: '1',
          label: "已邮寄",
          value: 0
        }
      ],
      list: [],
    }
  },
  components: {
    SearchBox,
    Pagination
  },
  mounted() {
    this.search();
  },
  methods: {
    // 查看邮寄信息--确定按钮
    clickDetermine () {
      this.viewMailDialog = false;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleRequest(file){
      this.ossClient.multipartUpload({
        file: file.file,
      }).then((res) => {
        this.fileList.push({ url: res.res.requestUrls[0], name: file.file.name, uid: file.file.uid });
        this.getUrl(this.fileList);
      }).catch(() => {
      }).finally(() => {
      });
    },
    detectionRemove(file){
      const reg2 = /^(\s|\S)+(png|jpg|JPG|PNG)+$/;
      const isLt2M = file.size / 1024 < 5000;
      if (!reg2.test(file.name)) {
        this.$message({ type: "warning", message: "格式上传错误！" });
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5M!');
        return false
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList = [];
    },
    // 核对日期
    timeGmtModifiedTimeArr(data) {
      if (data != null) {
        this.params.startGmtModified = data[0];
        this.params.endGmtModified = data[1];
      } else {
        this.params.startGmtModified = '';
        this.params.endGmtModified = '';
      }
    },
    // 申请日期
    timeGmtCreateTimeArr(data) {
      if (data != null) {
        this.params.startGmtCreate = data[0];
        this.params.endGmtCreate = data[1];
      } else {
        this.params.startGmtCreate = '';
        this.params.endGmtCreate = '';
      }
    },
    // 邮寄日期
    timeMailingTimeTimeArr(data) {
      this.params.startMailingTime = data[0];
      this.params.endMailingTime = data[1];
    },
    momentTime(time, text) {
      return moment(time).format(text);
    },
    // 查看邮寄信息
    viewMailClick(data){
      this.viewMailDialog = true;
      let box = {
        trackingNumber: data.trackingNumber,
      };
      this.FailCheck.expressName = '顺丰快递';
      this.FailCheck.mailingTime = '';
      this.FailCheck.trackingNumber = '';
      this.FailCheck.pecipient = '';
      this.FailCheck.phone = '';
      this.FailCheck.attachmentAddress = '';
      this.FailCheck.notes = '';
      this.fileList = [];
      consumer_flight_report_order_mailingDetail(box)
        .then((res) => {
          this.FailCheck.expressName = res.datas.expressName;
          this.FailCheck.mailingTime = res.datas.mailingTime;
          this.FailCheck.trackingNumber = res.datas.trackingNumber;
          this.FailCheck.pecipient = res.datas.pecipient;
          this.FailCheck.phone = res.datas.phone;
          this.FailCheck.attachmentAddress = res.datas.attachmentAddress;
          this.FailCheck.notes = res.datas.notes;
          if (res.datas.attachmentAddress == null) {
            this.fileList = [];
          } else {
            let attachmentAddressBox = res.datas.attachmentAddress.split('/');
            this.fileList = [
              {
                "url": res.datas.attachmentAddress,
                "name": `${attachmentAddressBox[attachmentAddressBox.length - 1]}`,
              }
            ];
          }

        })
        .finally(() => {

        })
    },
    search() {
      this.params.currentPage = 1;
      this.getList()
    },
    reset() {
      this.params.batchId = '';
      this.params.endGmtCreate = '';
      this.params.endGmtModified = '';
      this.params.endMailingTime = '';
      this.params.invoiceTitle = '';
      this.params.orderNo = '';
      this.params.reimburseApplicationId = '';
      this.params.staffName = '';
      this.params.startGmtCreate = '';
      this.params.startGmtModified = '';
      this.params.startMailingTime = '';
      this.params.tfn = '';
      this.params.trackingNumber = '';
      this.params.pageSize = 10;
      this.params.currentPage = 1;
      this.params.total = 0;
      this.search()
    },
    handleClick(val) {
      this.search()
    },
    getList() {
      this.loading = true;
      this.list = [];
      consumer_flight_report_order_addExpressRecordList(this.params)
        .catch(() => {
          this.loading = false;
          this.list = [];
          this.params.total = 0;
        })
        .then(res => {
          this.loading = false;
          this.list = res.datas.pageData;
          this.params.total = res.datas.totalCount;
        });
    },
    handleSelectionChange(selectedList) {
      this.multipleSelection = [];
      selectedList.forEach(item => {
        this.multipleSelection.push(item)
      })
    },
  }
}
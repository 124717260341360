import _ from 'underscore';

import get_sample_data_entity from '@/lib/data-service/default/get_sample_data_entity';
import history_tag_api from '@/component/admin-layout/component/history-tag/api';
import breadcrumb_api from '@/component/admin-layout/component/breadcrumb/api';

export default {
    components: {},
    data() {
        return {
            entity: {
                id: ``,
            }
        }
    },
    methods: {
        __btn_go_back_click() {
            const __this = this;

            history_tag_api.remove_tag_by_route({
                route: __this.$route,
            });

            // __this.$router.back(-1);//用这个容易有问题
            __this.$router.push({
                name: `admin-sample-data-list`,
            });
        },
    },
    created() {
        // const __this = this;
    },
    activated() {
        const __this = this;
        const id = __this.$route.query.id;
        get_sample_data_entity({id}).then(function (data) {
            __this.entity = data.entity;

            const tag_name = `示例数据详情(id=${id})`;
            history_tag_api.modify_tag_name_by_route({
                route: __this.$route,
                tag_name: tag_name,
            });
            breadcrumb_api.set_entity_list_by_get_entity_list({
                get_entity_list(p) {
                    const original_entity_list = p.original_entity_list;
                    if (original_entity_list.length <= 0) return original_entity_list;
                    const last = _.last(original_entity_list);
                    last.name = tag_name;
                    return original_entity_list;
                },
            });
        });
    },
    deactivated() {
        // const __this = this;
    },
}

import Q from 'q';
import consumer_admin_flight_equity_getOrderProductInfo from '@/lib/data-service/haolv-default/consumer_admin_flight_equity_getOrderProductInfo'
import CompanyInfo from '@/component/company-info/import_latest_version_module'
import NewChangeClause from "@/page/admin/replace-orders/flight-search/component/newChangeClause/index.vue";
import PaymentMethodSelector000 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.10/payment-method-selector-000/index.vue';
import PaymentMethodSelector010 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.11/payment-method-selector-010/index.vue';
import consumer_journey_getEvectionDetail from "@/lib/data-service/haolv-default/consumer_admin_journey_getEvectionDetail";
import ChangeClausePopup from '@/page/admin/replace-orders/component/changeClausePopup/1.0.0/index.vue'
//import consumer_admin_flight_order_details from '@/lib/data-service/haolv-default/consumer_admin_flight_order_details'
//import consumer_flight_order_flightsStop from '@/lib/data-service/haolv-default/consumer_flight_order_flightsStop'
//import consumer_air_ticket_flightRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules";
import consumer_admin_flight_checkFlightPay from '@/lib/data-service/haolv-default/consumer_admin_flight_checkFlightPay'
import consumer_admin_flight_order_cancel from '@/lib/data-service/haolv-default/consumer_admin_flight_order_cancel'
import consumer_admin_flight_milliseconds from '@/lib/data-service/haolv-default/consumer_admin_flight_milliseconds'
import consumer_admin_behalfCustomer_certificates from '@/lib/data-service/haolv-default/consumer_admin_behalfCustomer_certificates'
import consumer_admin_flight_change_details from '@/lib/data-service/haolv-default/consumer_admin_flight_change_details'
import consumer_air_ticket_cancelChangePay from '@/lib/data-service/haolv-default/consumer_air_ticket_cancelChangePay'
import consumer_air_ticket_fligthRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules"
import consumer_admin_user_getCompanyPayType from '@/lib/data-service/haolv-default/consumer_admin_user_getCompanyPayType'
import consumer_flight_search_stipulates from '@/lib/common-service/consumer_flight_search_stipulates'
import moment from "moment";

import consumer_flight_order_flightsStop from '@/lib/common-service/consumer_flight_order_flightsStop'
import consumer_air_ticket_flightRules from '@/lib/common-service/consumer_air_ticket_flightRules'
import PolicyTypeLabel from '@/page/admin/replace-orders/component/policyTypeLabel/index.vue'
import consumer_flight_search_queryMeals from "@/lib/data-service/haolv-default/consumer_flight_search_queryMeals";
import consumer_flight_search_queryPlaneNameByCodes
    from "@/lib/data-service/haolv-default/consumer_flight_search_queryPlaneNameByCodes";
import {awaitWrap} from "../../common/unit";
export default {
    data() {
        return {
            stipulatesData: {
              timeLimitQueue: '',
              tagProperty: '',
            },
            loading: false,
            payType: 1, // 1,企业支付，2个人支付
            companyId: '',
            evectionNo: '',
            evectionType: 2,
            orderNo: '', // 普通情况，此为订单号，改签情况下此为改签订单号
            oriOrderNo: '', // 改签单时，此为原订单号
            supplierOrderNo: '',
            //orderType: '', // 为空字符是整个出差单，1是机票订单，2是火车票订单，3是酒店订单
            pageType: '', // 1是创建订单页，2是支付页，3是改签页
            showDetail: true,
            selfPolicy: 0, // 是否是自营：0.不是自营 ，1.是自营
            // paymentForm: {
            //     pwd: "",
            // },
            detail: {},
            changeDetail: {},
            segmentList: [],
            travellerNameList: [],
            flightOrderList: [],
            travellerList: [],
            list: [],

            orderList: [],

            errorCode: '',
            errorText: '',
            dialogError2Visible: false,
            successVisible: false,

            papersTypeList: [],
            milliseconds: 1800,

            ruleText: {},
            ruleLoading: false,
            ruleShow: false, // 退改签说明弹窗

            stopLoad: false,
            productInfo: {},
        }
    },
    components: {
        CompanyInfo,
        NewChangeClause,
        PaymentMethodSelector000,
        PaymentMethodSelector010,
        ChangeClausePopup,
        PolicyTypeLabel
    },
    created() {
    },
    mounted() {
        this.getCertificatesList()
        this.init();
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
    },
    methods: {
        showMore() {
            this.showDetail = !this.showDetail
        },
        async init() {
            const query = this.$route.query;
            this.companyId = query.companyId
            this.evectionNo = query.evectionNo
            this.orderNo = query.orderNo
            this.oriOrderNo = query.oriOrderNo || ''
            this.orderType = Number(query.orderType) || "";
            this.pageType = Number(query.pageType) || 2;
            this.selfPolicy = Number(query.selfPolicy) || 0
            //this.paymentForm.orderNo = this.orderNo;
            //this.paymentForm.evectionNo = this.evectionNo;
            let params = {}
            if (this.orderType) {
                params = {
                    orderNo: this.orderNo,
                    pageType: this.pageType,
                    personType: 2,
                }
            } else {
                params = {
                    evectionNo: this.evectionNo,
                    pageType: this.pageType,
                    personType: 2,
                }
            }
            await this.getCompanyPayType();
            this.reqDetail(params, this.orderNo);
        },
        async getCompanyPayType() {
            // companyId
            const params = {
                companyId: this.$route.query.behalfCompanyId,
            }
            let [err, res] =  await awaitWrap(consumer_admin_user_getCompanyPayType(params));
            if (err) {
                return;
            }
            this.payType = res.datas.payType;
        },
        reqDetail(params, orderNo) {
            let getEvectionDetail = consumer_journey_getEvectionDetail(params)
            let getMilliseconds = consumer_admin_flight_milliseconds({evectionNo: params.evectionNo, orderNo})
            let getChangeDetail = Q.resolve();
            if (this.pageType === 3) { // 2是支付页，3是改签页
                getChangeDetail = consumer_admin_flight_change_details({chaOrderNo: this.orderNo, orderNo: this.oriOrderNo})
            }
            let promiseArr = []
            promiseArr.push(getEvectionDetail)
            promiseArr.push(getMilliseconds)
            if (this.pageType === 3) { // 2是支付页，3是改签页
                promiseArr.push(getChangeDetail)
            }
            this.loading = true;
            // 获取选购加享产品
            consumer_admin_flight_equity_getOrderProductInfo({orderNo: this.pageType == 2 ? orderNo : this.oriOrderNo}).then((res) => {
                this.productInfo = res.datas;
            });
            Promise.all(promiseArr).then(res => {
                this.loading = false
                let segmentList = []
                let travellerNameList = []
                const datas = res[0].datas;
                let dataBox = {
                    rqData: {
                        "arrCityPortCode": datas.flightDetail.orderList[0].segmentList[0].arrCityPortCode,
                        "cabinCode": datas.flightDetail.orderList[0].segmentList[0].cabinCode,
                        "depCityPortCode": datas.flightDetail.orderList[0].segmentList[0].depCityPortCode,
                        "depDate": datas.flightDetail.orderList[0].segmentList[0].depDate,
                        "flightNo": datas.flightDetail.orderList[0].segmentList[0].flightNo,
                        "flightProductType": datas.flightDetail.orderList[0].segmentList[0].flightProductType,
                        "flightType": datas.flightDetail.orderList[0].flightType,
                        "psgType": datas.flightDetail.orderList[0].psgType,
                        policyId: datas.flightDetail.orderList[0].segmentList[0].policyId,
                        businessExt: datas.businessExt,
                        companyId: this.companyId,
                        segmentNo: datas.flightDetail.orderList[0].segmentList[0].segmentNo,
                    }
                };
                consumer_flight_search_stipulates(dataBox).then(res => {
                  this.stipulatesData = res.datas.rsData;
                });

                this.evectionType = datas.evectionType;
                if (this.pageType === 3) { // 2是支付页，3是改签页
                    let changeDetail = res[2].orderDetail
                    this.changeDetail = changeDetail

                    this.orderList = [orderNo]
                }
                let travellerList = []
                let singlePrice = 0 // 单人票面价
                let singleSettlement = 0 // 单人结算价
                let singleAirportTax = 0 // 单人机建
                let singleFuelTax = 0 // 单人燃油费
                //let singleServiceFee = 0 // 单人服务费
                if (datas.flightDetail) {
                    datas.flightDetail.orderList.forEach((value, index) => {
                        if (this.pageType !== 3) {
                            this.orderList.push(value.orderNo)
                        }
                        datas.orderNo = value.orderNo
                        datas.orderStatus = value.orderStatus
                        datas.orderStatusText = value.orderStatusText
                        this.supplierOrderNo = value.supplierOrderNo

                        value.segmentList.forEach(value1 => {
                            if (datas.todFlightPolicy) {
                                value1.todFlightPolicy = datas.todFlightPolicy[index]
                            } else {
                                value1.todFlightPolicy = {}
                            }
                            segmentList.push(value1)

                            singleSettlement += parseFloat(value1.settlement)
                            singleAirportTax += parseFloat(value1.airportTax)
                            singleFuelTax += parseFloat(value1.fuelTax)
                            singlePrice += parseFloat(value1.price)
                        })

                        value.passengerList.forEach((value1, index1) => {
                            datas.travellerList.forEach((value2, index2) => {
                                if (value2.phone === value1.mobile) {
                                    datas.travellerList[index2] = Object.assign({}, value1, value2)
                                }
                            })
                        })

                        travellerList = value.passengerList
                    })

                    this.flightOrderList = datas.flightDetail.orderList

                } else {
                    console.error('接口返回的flightDetail为空')
                }
                travellerList.forEach(value => {
                    travellerNameList.push(value.psgName)
                    value.singlePrice = singlePrice
                    value.singleSettlement = singleSettlement
                    value.singleAirportTax = singleAirportTax
                    value.singleFuelTax = singleFuelTax
                    //value.singleServiceFee = singleServiceFee
                })
                this.segmentList = segmentList
                this.travellerNameList = travellerNameList
                this.travellerList = travellerList
                this.detail = datas
                this.milliseconds = res[1].datas
                this.initPayment()
                //餐食
                this.queryMeals();
                //
                this.queryPlaneNames();
            }).catch(err => {
                this.loading = false
            })
            /*consumer_journey_getEvectionDetail(params).then((res) => {
                let segmentList = []
                let travellerNameList = []
                const datas = res.datas;
                if (this.pageType === 3) { // 2是支付页，3是改签页

                } else {

                }
                let singleSettlement = 0 // 单人结算价
                let singleAirportTax = 0 // 单人机建
                let singleFuelTax = 0 // 单人燃油费
                let singleServiceFee = 0 // 单人服务费
                if (datas.flightDetail) {
                    datas.flightDetail.orderList.forEach((value, index) => {
                        datas.orderNo = value.orderNo
                        datas.orderStatus = value.orderStatus
                        datas.orderStatusText = value.orderStatusText
                        this.supplierOrderNo = value.supplierOrderNo

                        value.segmentList.forEach(value1 => {
                            if (datas.todFlightPolicy) {
                                value1.todFlightPolicy = datas.todFlightPolicy[index]
                            } else {
                                value1.todFlightPolicy = {}
                            }
                            segmentList.push(value1)

                            singleSettlement += parseFloat(value1.settlement)
                            singleAirportTax += parseFloat(value1.airportTax)
                            singleFuelTax += parseFloat(value1.fuelTax)
                        })

                        value.passengerList.forEach((value1, index1) => {
                            singleServiceFee += parseFloat(value1.serviceCharge)
                            datas.travellerList[index1] = Object.assign({}, value1, datas.travellerList[index1])
                        })
                    })

                    this.flightOrderList = datas.flightDetail.orderList
                }
                datas.travellerList.forEach(value => {
                    travellerNameList.push(value.staffName)

                    value.singleSettlement = singleSettlement
                    value.singleAirportTax = singleAirportTax
                    value.singleFuelTax = singleFuelTax
                    value.singleServiceFee = singleServiceFee
                })
                this.segmentList = segmentList
                this.travellerNameList = travellerNameList
                this.travellerList = datas.travellerList
                this.detail = datas

                this.initPayment()
            })*/
        },
        initPayment() {
            let _this = this
            let orderForPayVos = []
            _this.orderList.forEach(value => {
                const params = {
                    orderNo: value,
                    businessContent: '',
                }
                orderForPayVos.push(params)
            })

            this.$nextTick(() => {
                let aPaymentMethodSelector = null;
                if (this.evectionType === 2 && this.payType == 1) {
                    console.log('进1');
                    aPaymentMethodSelector = _this.$refs.aPaymentMethodSelector010
                } else {
                    console.log('进2');
                    aPaymentMethodSelector = _this.$refs.aPaymentMethodSelector000
                }

                aPaymentMethodSelector.init({
                    page_this: _this,
                    // count_down: {
                    //     enable: _this.pageType !== 3 ? true : false,
                    //     pay_remaining_seconds: _this.milliseconds,//支付剩余时间，以秒为单位
                    // },

                    //在这里传入订单详情的相关参数
                    get_params() {
                        const p = {
                            "orderForPayVos": orderForPayVos,
                            "payType":  _this.pageType !== 3 ? 1 : 2
                        };

                        return Q.resolve(p);
                    },
                    // get_params() {
                    //     const p = {
                    //         cacheKey: _this.detail.cacheKey,
                    //         evenctionNo: _this.evenctionNo,//出差单号
                    //         orderNo: _this.orderNo,//订单编号
                    //     };
                    //     return Q.resolve(p);
                    // },

                    //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                    before_pay_event_handler() {
                        // alert(`不能够支付！原因：XXX`);
                        // return Q.reject();
                        // 验价  改签不需要验价
                        if (_this.pageType !== 3) {
                            // 如果有机票订单，则验价
                            return consumer_admin_flight_checkFlightPay({orderNo: [_this.orderNo]}).then(res => {
                                return Q.resolve();
                            }, error => {
                                _this.errorCode = error.data.code
                                _this.errorText = error.data.msg;
                                _this.dialogError2Visible = true;
                                return Q.reject();
                            })
                        } else {
                            return Q.resolve();
                        }
                        // console.log(`before_pay_event_handler`);
                        // return Q.resolve();
                    },

                    //支付成功事件处理方法
                    pay_successful_event_handler() {
                        console.log(`pay_successful_event_handler`);
                        _this.successVisible = true;
                    },

                    //支付失败事件处理方法
                    pay_failed_event_handler() {
                        console.log(`pay_failed_event_handler`);
                    },

                    //取消订单按钮单击事件处理方法
                    btn_cancel_order_click_event_handler(args) {
                        const show_loading = args.show_loading;
                        const hide_loading = args.hide_loading;
                        const stop_count_down = args.stop_count_down;
                        const enable_btn_pay = args.enable_btn_pay;
                        const enable_btn_cancel_order = args.enable_btn_cancel_order;
                        _this.$confirm('确定取消此订单', '取消提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            if (_this.pageType === 3) {
                                // 改签情况
                                Q.when().then(function () {
                                    show_loading();
                                    return consumer_air_ticket_cancelChangePay({
                                        changeNo: _this.orderNo,
                                    });
                                }).then(function () {
                                    console.log(`btn_cancel_order_click_event_handler`);
                                    hide_loading();
                                    stop_count_down();
                                    enable_btn_pay({
                                        enable: false,
                                    });
                                    enable_btn_cancel_order({
                                        enable: false,
                                    });
                                    _this.$message({
                                        type: 'success',
                                        message: '取消成功!'
                                    });
                                    _this.$router.replace({
                                        name: 'admin-flight-change-list',
                                    })
                                }).catch(function (ex) {
                                    if (ex) {
                                        const enable_log_error = !ex.disable_log_error;
                                        if (enable_log_error) {
                                            console.error(ex);
                                            console.trace();
                                            if (ex.stack) {
                                                console.error(ex.stack);
                                            }
                                        }
                                    }
                                    return Q.reject(ex);
                                });
                            } else {
                                // 正常支付
                                Q.when().then(function () {
                                    show_loading();
                                    return consumer_admin_flight_order_cancel({
                                        orderNo: _this.orderNo,
                                        supplierOrderNo: _this.supplierOrderNo
                                    });
                                }).then(function () {
                                    console.log(`btn_cancel_order_click_event_handler`);
                                    hide_loading();
                                    stop_count_down();
                                    enable_btn_pay({
                                        enable: false,
                                    });
                                    enable_btn_cancel_order({
                                        enable: false,
                                    });
                                    _this.$message({
                                        type: 'success',
                                        message: '取消成功!'
                                    });
                                    _this.$router.replace({
                                        name: 'admin-travel-book-home'
                                    })
                                }).catch(function (ex) {
                                    if (ex) {
                                        const enable_log_error = !ex.disable_log_error;
                                        if (enable_log_error) {
                                            console.error(ex);
                                            console.trace();
                                            if (ex.stack) {
                                                console.error(ex.stack);
                                            }
                                        }
                                    }
                                    return Q.reject(ex);
                                });
                            }
                        }).catch(() => {
                        });
                    },
                    //获取支付方式接口，返回code=300052时，弹窗的确定按钮单击处理处理方法
                    dialog_code_300052_btn_ok_click_event_handler() {
                        console.log(`dialog_code_300052_btn_ok_click_event_handler`);
                        _this.$router.replace({
                            name: 'admin-flight-order-list',
                        });
                        return Q.resolve(true);
                        //return true;
                    },
                });
            })

        },
        getStopMsg(val, index, segmentIndex) {
            let params = {
                "rqData": {
                    "date": val.depDate,
                    "flightNo": val.isShare  == 1 ? val.shareFlightNo : val.flightNo,
                    'depCityCode': val.depCityCode,
                    'arrCityCode': val.arrCityCode,
                    'arrTime': val.arrTime,
                    'deptTime': val.depTime,
                    'depAirCode': val.depCityPortCode,
                    'arrAirCode': val.arrCityPortCode,
                }
            }
            this.stopLoad = true
            let flightsList = JSON.parse(JSON.stringify(this.flightOrderList))
            consumer_flight_order_flightsStop(params).then(res => {
                flightsList[index].segmentList[segmentIndex].stopData = res.datas
                this.stopLoad = false
                this.flightOrderList = flightsList
            }).catch(() => {
                this.stopLoad = false
                this.flightOrderList = flightsList
            })
        },
        showThisRuleList(fIndex, index, val) {
            this.ruleText = {}
            this.ruleShow = true
            let data = {
                segmentNo: val.segmentNo
            }
            this.ruleLoading = true
            consumer_air_ticket_fligthRules(data).then(res => {
                this.ruleLoading = false
                this.ruleText = res.datas.rsData
            }).catch(() => {
                this.ruleLoading = false
            })
        },
        to_detail() {
            this.successVisible = false;
            // const type = Number(this.orderType);
            const pageType = this.pageType;
            const orderNo = this.$route.query.orderNo;
            if (pageType === 2) {
                this.$router.replace({
                    name: "admin-flight-order-detail",
                    query: {
                        orderNo,
                    },
                });
            } else if (pageType === 3) {
                if (this.selfPolicy === 1) {
                    this.$router.replace({
                        name: "admin-flight-change-opa",
                        query: {
                            chaOrderNo: orderNo,
                            orderNo: this.$route.query.oriOrderNo
                        },
                    });
                } else {
                    this.$router.replace({
                        name: "admin-flight-change-detail",
                        query: {
                            chaOrderNo: orderNo,
                            orderNo: this.$route.query.oriOrderNo
                        },
                    });
                }
            }
        },
        againInit() {
            this.dialogError2Visible = false
            if (this.errorCode === '200029') {
                this.$router.push({
                    name: 'admin-travel-book-home',
                })
            } else {
                this.init()
            }

        },
        /*setTypeLabelColor(val) {
            //"supplier": "1,2,3,4,12,13,9,OPL9,SGL1,OPL6,OPL11,OPL18,OPL17,OPL20,OPL16,OPL26,OPL23,OPL30,60,FD,NFD,VARI",
            //"supplierText": "其他渠道(供应商)【平台优选】",
            // "bigCustomers": "QCYX",
            //"bigCustomersText": "企业协议(大客户)",
            //"lowPrice": "41,42,43,44,45,26,YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL1,NSTD,62",
            //"lowPriceText": "低价推荐",
            // "website": "21,22,23,24,25,6,CXF1,WS",
            //"websiteText": "航司官网(官网)",
            //"platformProprietary": "61",
            //"platformProprietaryText": "商旅优选(自营)【商旅推荐】"
            const btoList = ['1','2','3','4','12','13', '9', 'OPL9', 'SGL1','OPL6','OPL11','OPL18','OPL17','OPL20','OPL16','OPL26','OPL23','OPL30','60','FD','NFD','VARI']
            const lprList = ['41','42','43','44','45','26','YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL1','NSTD','62']
            const owList = ['21','22','23','24','25','6','CXF1','WS']
            const qcList = ['QCYX']
            const ptList = ['61']
            if (btoList.indexOf(val) > -1) {
                return 'type1'
            } else if (lprList.indexOf(val) > -1) {
                return 'type2'
            } else if (owList.indexOf(val) > -1) {
                return 'type3'
            } else if (qcList.indexOf(val) > -1) {
                return 'type4'
            } else if (ptList.indexOf(val) > -1) {
                return 'type5'
            } else {
                return 'type6'
            }
        },*/
        cardTypeFormatter(val) {
            let cardTypeText = ''
            if (val.cardType) {
                this.papersTypeList.forEach(value => {
                    if (value.code === val.cardType) {
                        cardTypeText = value.msg
                    }
                })
            }
            return cardTypeText
        },
        getCertificatesList() {
            consumer_admin_behalfCustomer_certificates().then(res => {
                this.papersTypeList = res.datas.items
            })
        },

        queryMeals() {

            let segmentList =  this.flightOrderList[0].segmentList
            let promises = [];
            for (let i = 0; i < segmentList.length; i++) {
                let val = this.flightOrderList[0].segmentList[i];
                let param = {
                    flightNo: val.flightNo,
                    deptTime: val.depTime,
                    arrTime: val.arrTime
                };
                promises.push(consumer_flight_search_queryMeals(param));
            }
            if(promises.length>0){
                Promise.all(promises).then( (posts) =>{
                    for (let i = 0; i < segmentList.length; i++) {
                        let meals = posts[i].datas;
                        this.flightOrderList[0].segmentList[i].hasMeals = meals ? '有餐食':'无餐食';
                    }
                    // this.detail = detail;
                    this.$forceUpdate();
                }).catch(function(reason){
                    console.log('出错了',reason)
                });
            }
        },
        queryPlaneNames(){
            const planeTypes = this.flightOrderList[0].segmentList.map(e => {
                return e.planeType
            })
            consumer_flight_search_queryPlaneNameByCodes(planeTypes).then(res=>{
                console.log("planeTypes",res);
                let planeMap = new Map();
                if(res.datas){
                    res.datas.forEach(i=>{
                        planeMap.set(i.planeType,i.planeName);
                    })
                }
                this.flightOrderList[0].segmentList.forEach(i=>{
                    let planeName = planeMap.get(i.planeType);
                    i.planeName = planeName? planeName:i.planeType;
                })
                this.$forceUpdate();

            }).catch(err=>{
                console.log('机型查询出错了',err);
            })
        },
    }
}

// consumer_admin_flight_generate_officialTicketChannel
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_flight_generate_officialTicketChannel = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/admin/flight/generate/officialTicketChannel',
    data: pParameter
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_admin_flight_generate_officialTicketChannel;

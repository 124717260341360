const get_route_root = function () {
    const r = {
        name: `root`,
        path: '/',
        component: () => import(`@/page/login/index.vue`),
        meta: {},
    };

    return r;
};

export default get_route_root;

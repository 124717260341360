// 改签详情查看操作日志 http://yapi.yinzhitravel.com/project/52/interface/api/15524

const __request = require(`./__request/__request_contentType_json`)
const consumer_admin_flight_chang_logContents = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/admin/flight/change/logContents',
    data: data
  }
  return __request(pParameter)
}
export default consumer_admin_flight_chang_logContents

export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/flight-info',
            name: 'admin-flight-info',
            component: () => import(/* webpackChunkName: "page-admin-info-maintenance-flight-info" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `信息维护`,
                },
                entity_list_for_breadcrumb: [
                    {name: `信息维护`},
                    {name: `航司信息`},
                ],
            },
        },
    ],
};

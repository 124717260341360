import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'

import consumer_admin_ship_initCount from '@/lib/data-service/haolv-default/consumer_admin_ship_initCount';
import consumer_admin_ship_list from '@/lib/data-service/haolv-default/consumer_admin_ship_list';
import consumer_admin_ship_action from '@/lib/data-service/haolv-default/consumer_admin_ship_action';

import DictionarySelect
    from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';
import moment from "moment";
export default {
    data() {
        return {
            loading: false,
            chooseTypeDialogVisible: false,
            issueDialogVisible: false,
            curOrder: {},
            orderChannelList: [], //采购渠道
            expectTime: [],   //预计出签时间
            gmtCreate: [],   //创建时间
            updateTime: [],   //操作时间
            searchForm: {
                orderNo: '',   //订单编号
                companyText: '',//客人名称
                journey: '',//行程
                journeyDateBegin: '',//操作时间-开始
                journeyDateEnd: '',//操作时间-结束
                supOrderNo: '',//交易号
                type: '',   //票务类型
                orderSource: '',//采购渠道
                companyId: '',//所属企业
                updateUser: '',//操作客服
                gmtCreateBegin: '',//创建时间-开始
                gmtCreateEnd: '',//创建时间-结束
                gmtModifiedBegin: '',//操作时间-开始
                gmtModifiedEnd: '',//操作时间-结束
                status: '',//状态
                billStatus: '',
            },
            pageBean: {
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            },
            totalCount: 0,   //总条数
            orderSourceList: [],
            journeyDate: [],//行程日期

            activeName: '-1',
            tabList: [
                {
                    name: -1,
                    label: '全部',
                    value: 0
                },
                {
                    name: 0,
                    label: '待支付',
                    value: 0
                },
                {
                    name: 2,
                    label: '待确认',
                    value: 0
                },
                {
                    name: 11,
                    label: '办理中',
                    value: 0
                },
                {
                    name: 14,
                    label: '已办理',
                    value: 0
                },
                {
                    name: 21,
                    label: '已取消',
                    value: 0
                }
            ],
            tabNumberList: [],
            tableLoading: false,
            orderList: [],

            confirmForm: {},
            progressList: [],
            progressForm: {},
            visaBillList: [],
            confirmRules: {
                orderSource: [
                    {required: true, message: '请选择采购渠道', trigger: 'blur'},
                ],
                supOrderNo: [
                    {required: true, message: '请输入交易号', trigger: 'blur'},
                ],
                paymentType: [
                    {required: true, message: '请选择付款方式', trigger: 'blur'},
                ],
                paymentTime: [
                    {required: true, message: '请选择付款时间', trigger: 'blur'},
                ],
                paymentNumber: [
                    {required: true, message: '请输入付款流水号', trigger: 'blur'},
                ],

            },
            typeList: [
                {
                    code: null,
                    msg: '全部',
                },
                {
                    code: 0,
                    msg: '船票'
                },
                {
                    code: 1,
                    msg: '门票'
                }
            ],
            billStatusList: [
                {
                    code: '',
                    msg: '全部',
                },
                {
                    code: 0,
                    msg: '待出票'
                },
                {
                    code: 1,
                    msg: '已出票'
                },
                {
                    code: 2,
                    msg: '已取消'
                },
                {
                    code: 3,
                    msg: '已退票'
                }
            ],
            progressStatusList: [//0 办理中 1 已出签 2拒签 3 取消
                {
                    code: 0,
                    msg: '办理中'
                },
                {
                    code: 1,
                    msg: '已出签'
                },
                {
                    code: 2,
                    msg: '已拒签'
                },
                {
                    code: 3,
                    msg: '已取消'
                },
            ]
        }
    },
    components: {
        SearchBox,
        Pagination,
        DictionarySelect
    },
    created() {
        // this.initDictionarySelect();
    },
    async activated() {
        await this.getTabNum();
        await this.getOrderList();
    },
    methods: {
        // 只能输入英文或数字
        handleInput(item, value) {
            // 使用正则表达式匹配字母和数字
            const reg = /^[a-zA-Z0-9]+$/;
            // 如果输入值不符合规定的正则表达式，则将inputValue重置为上一个值
            if (!reg.test(value)) {
                this.confirmForm[item] = this.confirmForm[item].replace(/[^\a-\z\A-\Z0-9]/g, '');
            }
        },
        //初始化字段下拉框组件
        initDictionarySelect() {
            console.log(Dictionary);
            Dictionary.init(this, {
                typeGroupKey: ['tmc'],
                typeKey: [],
                parentId: 0
            });
        },

        //后台生单
        toRecordOrder() {
            this.chooseTypeDialogVisible = true;
        },

        //选择票务类型
        chooseType(type) {
            this.chooseTypeDialogVisible = false;
            this.$router.push({
                name: 'admin-ticket-create-order',
                query: {
                    ticket: type
                }
            });
        },

        //获取每个tab的数值
        async getTabNum() {
            let res = await consumer_admin_ship_initCount();
            this.tabList = res.datas;
        },
        aa(val) {
            console.log(val);
        },
        //获取订单列表
        async getOrderList() {
            this.tableLoading = true;
            console.log({ent: this.searchForm, pageBean: this.pageBean})
            consumer_admin_ship_list({ent: this.searchForm, pageBean: this.pageBean}).then(res => {
                this.orderList = res.datas.list;
                if (this.orderList) {
                    let orderSourceText = '';
                    this.orderList.forEach(async (item) => {
                        orderSourceText = await Dictionary.returnDictionary('tmc', 'shipOrderSource', item.orderSource);
                        this.$set(item, 'orderSourceText', orderSourceText.name);
                    })
                }
                this.totalCount = res.datas.totalCount;
            }).catch(rej => {
                this.orderList = [];
                this.totalCount = 0;
                this.$message({
                    type: "error",
                    message: "获取订单列表失败！"
                })
            }).finally(() => {
                this.$nextTick(() => {
                    this.$refs.mainTable.doLayout()
                });
                this.tableLoading = false
            });
        },

        //搜索
        onSearch() {
            this.searchForm.status = this.activeName == -1 ? '' : this.activeName;
            this.pageBean.currentPage = 1;
            this.getTabNum();
            this.getOrderList()
        },

        //重置
        onReset() {
            this.searchForm = {
                orderNo: '',   //订单编号
                companyText: '',//客人名称
                journey: '',//行程
                journeyDateBegin: '',//操作时间-开始
                journeyDateEnd: '',//操作时间-结束
                supOrderNo: '',//交易号
                type: '',   //票务类型
                orderSource: '',//采购渠道
                companyId: '',//所属企业
                updateUser: '',//操作客服
                gmtCreateBegin: '',//创建时间-开始
                gmtCreateEnd: '',//创建时间-结束
                gmtModifiedBegin: '',//操作时间-开始
                gmtModifiedEnd: '',//操作时间-结束
                status: '',//状态
                billStatus: '',
            };
            this.pageBean = {
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            }
            this.journeyDate = [];  //预计出签时间
            this.gmtCreate = [];  //创建时间
            this.updateTime = [];   //操作时间
            this.onSearch()
        },

        //设置出差单类型样式
        getEvectionTypeToClass(val) {
            //出差单类型：1.因私，2.因公
            switch (val) {
                case (1):
                    return 'grey'
                case (2):
                    return 'orange'
                default:
                    return ''
            }
        },

        //修改行程日期
        changeJourneyDate(val) {
            if (!val) {
                this.searchForm.journeyDateBegin = '';
                this.searchForm.journeyDateEnd = '';
            } else {
                this.searchForm.journeyDateBegin = val[0];
                this.searchForm.journeyDateEnd = val[1];
            }
        },

        //修改创建时间
        changeGmtCreate(val) {
            if (!val) {
                this.searchForm.gmtCreateBegin = '';
                this.searchForm.gmtCreateEnd = '';
            } else {
                this.searchForm.gmtCreateBegin = val[0] + ' 00:00:00';
                this.searchForm.gmtCreateEnd = val[1] + ' 23:59:59';
            }
        },

        //修改操作时间
        changeUpdateTime(val) {
            if (!val) {
                this.searchForm.updateTimeBegin = '';
                this.searchForm.updateTimeEnd = '';
            } else {
                this.searchForm.updateTimeBegin = val[0] + ' 00:00:00';
                this.searchForm.updateTimeEnd = val[1] + ' 23:59:59';
            }
        },

        //查看订单
        handleDetailClick(val) {
            this.$router.push({
                name: 'admin-ticket-order-detail',
                query: {
                    id: val.id,
                    orderNo: val.orderNo
                }
            });
        },

        //取消订单
        handleCancelClick(val) {
            this.$confirm("确定取消此订单？", "取消提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.loading = true;
                let params = {
                    id: val.id,
                    orderNo: val.orderNo,
                    status: 3
                };
                consumer_admin_ship_action(params).then((res) => {
                    this.$message({
                        type: 'success',
                        message: '取消订单成功！'
                    })
                    this.getTabNum();
                    this.getOrderList();
                }).finally(() => {
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                })
            }).catch(() => {
            })
        },

        //去支付
        handlePayClick(val) {
            this.$router.push({
                name: "admin-ticket-create-order-pay",
                query: {
                    id: val.id,
                    orderNo: val.orderNo,
                    behalfCompanyId: val.companyId
                }
            })
        },

        //编辑
        handleEditClick(val) {
            this.$router.push({
                name: "admin-ticket-create-order",
                query: {
                    type: 'edit',
                    id: val.id
                }
            })
        },

        //出票操作-显示弹框
        onShowConfirmDialog(row) {
            // this.confirmForm = {};
            row.paymentNumber = row.paymentNum || '';
            this.confirmForm = JSON.parse(JSON.stringify(row));
            console.log(this.confirmForm);
            this.confirmForm.paymentType = this.confirmForm.paymentType || 'ALIPAY';
            this.confirmForm.paymentTime = this.confirmForm.paymentTime || moment().format('yyyy-MM-DD HH:mm:ss');
            // this.confirmForm.paymentNumber = row.paymentNum || '';
            this.confirmForm.type = '2';
            this.issueDialogVisible = true;
        },

        //出票操作-提交确定操作
        async confirmSubmit() {
            this.$refs['confirmForm'].validate(async (valid) => {
                if(valid) {
                    try {
                        this.loading = true;
                        let params = {};
                        if (this.confirmForm.type === '2') {
                            this.$refs['confirmForm'].validate(async (valid) => {
                                if (valid) {
                                    params = {
                                        id: this.confirmForm.id,
                                        orderNo: this.confirmForm.orderNo,
                                        status: 2,
                                        supOrderNo: this.confirmForm.supOrderNo,
                                        paymentType: this.confirmForm.paymentType || 0,
                                        paymentTime: this.confirmForm.paymentTime,
                                        paymentNum: this.confirmForm.paymentNumber,
                                        remark: this.confirmForm.remark,
                                    };
                                    await consumer_admin_ship_action(params);
                                    this.$message.success("保存成功");
                                    this.issueDialogVisible = false;
                                    await this.getTabNum();
                                    await this.getOrderList();
                                    this.loading = false;
                                }
                            })
                        } else {
                            params = {
                                id: this.confirmForm.id,
                                orderNo: this.confirmForm.orderNo,
                                remark: this.confirmForm.remark,
                                refundAmount: this.confirmForm.orderMoney,
                                status: 3,
                            };
                            await consumer_admin_ship_action(params);
                            this.$message.success("取消出票成功");
                            this.issueDialogVisible = false;
                            await this.getTabNum();
                            await this.getOrderList();
                            this.loading = false;
                        }
                    } catch (e) {
                        this.issueDialogVisible = false;
                        this.loading = false;
                    }
                }
            });
        },
    }
}

// 机票对账退票报表 https://www.apifox.cn/web/project/458418

const __request = require(`./__request/__request_contentType_json`)
const consumer_flight_report_flightRefundReportList = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/flight/report/flightRefundReportList',
    data: data
  }
  return __request(pParameter)
}
export default consumer_flight_report_flightRefundReportList

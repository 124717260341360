import Q from 'q';
import _ from 'underscore';
import HaoLvLoginCore from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/login-core/1.1.0/index.vue';

export default {
    components: {
        HaoLvLoginCore,
    },
    data() {
        return {};
    },
    methods: {},
    created() {
        // const __this = this;
    },
    mounted() {
        const __this = this;
        __this.$refs.aHaoLvLoginCore.init({
            type: `100`,
            login_successfully_event_handler(data) {
                const environment = global.appConfig.environment;

                __this.$router.push({
                    name: `admin-my-home`,
                });
            },
        });
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}

import consumer_admin_flight_refund_getList from '@/lib/data-service/haolv-default/consumer_admin_flight_refund_getList'
import consumer_admin_flight_generate_orderSource from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_orderSource'
import consumer_admin_flight_manage_lockOrderRefun
    from '@/lib/data-service/haolv-default/consumer_admin_flight_manage_lockOrderRefun'
import consumer_admin_flight_manage_nuLockOrderRefund
    from '@/lib/data-service/haolv-default/consumer_admin_flight_manage_nuLockOrderRefund'
import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'
import _ from "underscore";
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";

export default {
    data() {
        return {
            loading: false,
            showMore: false,
            params: {
                currentPage: 1,
                pageSize: 10,
                abbreviation: '',
                agency: '', // 代理
                refOrderNo: '',
                lastBuyerPId: '',
                orderNo: '',
                flightNo: '',
                orderStatus: '',
                orderTimeStart: '',
                orderTimeEnd: '',
                orderTimeArr: [],
                psgName: '',
                segmentType: '',
                source: '',
                userId: '',
                username: '',
                orderSource: '',
                total: 0,
                ticketNumber: '',
                policyType: 0,
                proposerName: '',
                depDate: '',
                operatorName: '',
                sourceType: 0,
                flightType: 0,//航程类型
            },
            activeName: 0,
            tabList: [
                // 41.申请中 42.待确认 43.已确认 44.处理中 45.退票成功 46.退票失败 47.已取消
                {
                    name: '0',
                    label: "全部",
                    value: 0
                },
                // {
                //     value: 41,
                //     label: "申请中"
                // },
                // {
                //     value: 42,
                //     label: "待确认"
                // },
                // {
                //     value: 43,
                //     label: "已确认"
                // },
                {
                    name: '44',
                    label: "处理中",
                    value: 0
                },
                {
                    name: '45',
                    label: "退票成功",
                    value: 0
                },
                {
                    name: '46',
                    label: "退票失败",
                    value: 0
                },
                // {
                //     name: '47',
                //     label: "已退款",
                //     value: 0
                // }
            ],
            tabNumberList: [],
            list: [],
            isBehalfList: [
                {value: '', label: '全部'},
                {value: 1, label: '是'},
                {value: 0, label: '否'},
            ],
            canSubmit: true,
            policyTypeList: [
                // 0=全部，1=自营 2=供应商
                {value: 0, label: '全部'},
                {value: 1, label: '自营'},
                {value: 2, label: '供应商'},
            ],
            sourceTypeList: [
                {value: 0, label: '全部'},
                {value: 1, label: '国内'},
                {value: 2, label: '国际'},
            ],
            flightTypeList: [
                // 0=全部，1=自营 2=供应商
                {value: 0, label: '全部'},
                {value: 1, label: '单程'},
                {value: 2, label: '往返'},
                {value: 3, label: '多程'},
            ],
            orderSourceList: [],
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    created() {
    },
    mounted() {
        this.getOrderSource()
    },
    activated() {
        this.reset()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        getPolicyTypeTextToClass(val) {
            /*
             "supplier": "1,2,3,4,12,13,9,OPL9,OPL6,OPL11,OPL18,OPL20,OPL16,60,FD,NFD,VARI,OPL14,SGL1,OPL17",
              "supplierText": "平台优选",
              "bigCustomers": "QCYX",
              "bigCustomersText": "企业协议",
              "lowPriceSelection": "YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL13,OPL34,OPL1,OPL26,OPL30",
              "lowPriceSelectionText": "低价精选",
              "lowPrice": "41",
              "lowPriceText": "低价推荐",
              "website": "21,22,23,24,25,31,6,CXF1,WS,GW311,GW312,OPL17,OPL23",
              "websiteText": "航司官网",
              "platformProprietary": "61",
              "platformProprietaryText": "商旅推荐"
             */
            const btoList = ['1','2','3','4','12','13','9','OPL9','OPL6','OPL11','OPL18','OPL20','OPL16','60','FD','NFD','VARI','OPL14','SGL1','OPL17'];
            const lprList = ['YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL13','OPL34','OPL1','OPL26','OPL30'];
            const owList = ['21','22','23','24','25','31','6','CXF1','WS','GW311','GW312','OPL17','OPL23'];
            const qcList = ['QCYX'];
            const ptList = ['61'];
            if (btoList.indexOf(val) > -1) {
                return 'type1'
            } else if (lprList.indexOf(val) > -1) {
                return 'type3'
            } else if (owList.indexOf(val) > -1) {
                return 'type4'
            } else if (qcList.indexOf(val) > -1) {
                return 'type2'
            } else if (ptList.indexOf(val) > -1) {
                return 'type5'
            } else {
                return 'type6'
            }
        },
        getOrderSource() {
            consumer_admin_flight_generate_orderSource().then(res => {
                let orderSourceList = res.datas
                orderSourceList.unshift({
                    msg: '全部',
                    code: ''
                })
                this.orderSourceList = orderSourceList
            })
        },
        search() {
            this.params.currentPage = 1
            this.params.orderStatus = this.activeName === '0' ? '' : this.activeName
            // this.params.orderStatus = this.activeName
            this.getList()
        },
        reset() {
            let params = {
                currentPage: 1,
                pageSize: 10,
                abbreviation: '',
                agency: '', // 代理
                refOrderNo: '',
                lastBuyerPId: '',
                orderNo: '',
                flightNo: '',
                orderStatus: '',
                orderTimeStart: '',
                orderTimeEnd: '',
                orderTimeArr: [],
                psgName: '',
                segmentType: '',
                source: '',
                userId: '',
                username: '',
                orderSource: '',
                total: 0,
                ticketNumber: '',
                policyType: 0,
                proposerName: '',
                depDate: '',
                operatorName: '',
                sourceType: 0,
                flightType: 0,//航程类型
            }
            params.orderStatus = this.activeName === '0' ? '' : this.activeName
            // params.orderStatus = this.activeName
            this.params = params
            this.search()
        },
        showOrHideForm() {
            const showMore = this.showMore
            this.showMore = !showMore
        },
        handleClick(val) {
            this.search()
        },
        changeOrderTimeArr(val) {
            if (val === '' || val === null) {
                this.params.orderTimeStart = ''
                this.params.orderTimeEnd = ''
            } else {
                this.params.orderTimeStart = val[0]
                this.params.orderTimeEnd = val[1]
            }
        },
        getList() {
            this.loading = true
            this.list = []
            consumer_admin_flight_refund_getList(this.params).then(res => {
                this.loading = false
                this.list = res.pageResult.pageData
                this.params.total = res.pageResult.totalCount
                this.tabNumberList = res.flightOrderNum;
                // _.each(res.pageResult.pageData,function (m) {
                //              m.initialName = `xxx`;
                //            });
                this.tabList.forEach(tab => {
                    this.tabNumberList.forEach(tabNumber => {
                        if (Number(tab.name) === tabNumber.status) {
                            tab.value = tabNumber.orderNum ? tabNumber.orderNum : 0
                        }
                    })
                })
                this.$nextTick(() => {
                    this.$refs.listTable.doLayout();
                })
            }).catch(() => {
                this.loading = false
                this.list = []
                this.params.total = 0
            })
        },
        //解锁订单
        async unlockOrder(refOrderNo) {
            await consumer_admin_flight_manage_nuLockOrderRefund({refOrderNo: refOrderNo})
            this.reset();
        },
        handleToDetail(val) {
            if (val.orderSource === '1' || val.orderSource === '2') {
                this.$router.push({
                    name: 'admin-flight-refund-detail',
                    query: {
                        orderNo: val.orderNo,
                        refOrderNo: val.refOrderNo
                    }
                })
            } else {
                this.$router.push({
                    name: "admin-flight-offline-refund-detail",
                    query: {
                        refOrderNo: val.refOrderNo
                    }
                })
            }
        },
        handleToConfirm(val) {
            this.$router.push({
                name: "admin-flight-offline-refund-confirm",
                query: {
                    refOrderNo: val.refOrderNo,
                    fromMode: '100',
                }
            })
        },
        handleToEdit(val) {
            this.$router.push({
                name: "admin-flight-offline-refund-edit",
                query: {
                    refOrderNo: val.refOrderNo
                }
            })
        },
        async handleCancelClick(val) {
            if (!this.canSubmit) {
                return
            }
            this.canSubmit = false
            if (val.lockState === 1) { // 如果已锁，且出现“退票操作”按钮，说明是自己锁的单
                this.goRefund(val)
                return
            }
            const params = {
                refOrderNo: val.refOrderNo
            }
            val.loading = true
            let [err, res] = await awaitWrap(consumer_admin_flight_manage_lockOrderRefun(params))
            val.loading = false
            if (err) {
                this.canSubmit = true
                this.getList()
                return
            }
            this.goRefund(val)
        },
        //进入退票操作
        goRefund(val) {
            this.canSubmit = true
            this.$router.push({
                name: 'admin-flight-refund-application',
                query: {
                    orderNo: val.orderNo,
                    refOrderNo: val.refOrderNo
                }
            })
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/

import Q from 'q';
import CompanyInfo from '@/component/company-info/import_latest_version_module'
import PaymentMethodSelector010
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.11/payment-method-selector-010';
import consumer_admin_flight_generate_psgType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_psgType'
import consumer_admin_visa_details
    from "@/lib/data-service/haolv-default/consumer_admin_visa_details";   // 签证订单详情
import consumer_country_queryCountryTwoWord
    from '@/lib/data-service/haolv-default/consumer_country_queryCountryTwoWord';
import consumer_admin_visa_action from '@/lib/data-service/haolv-default/consumer_admin_visa_action'
import consumer_admin_flight_generate_certificateType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType'
import DictionarySelect
    from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';

export default {
    data() {
        return {
            loading: false,
            id: '',
            orderNo: '',
            payType: '1', // 1支付中 2 无需支付 3 待支付
            payTypeForChannel: 1,
            detail: {},
            hotelInfo: {},
            travelerList: [],
            priceInfo: {},
            refundRule: {},//退改规则
            reportConfiguration: [],
            orderDetail:{},
            visaOrder: {},
            visaCompanyInfo: {},
            visaCompanyBill: [],
            countryList: [],
            files:[],
            salesPriceList: [],
            serviceFeeList: [],
            totalAmount: 0,

            // 乘客类型List
            psgTypeList: [],
            // 证件类型List
            certificateTypeList: [],
            // 办理进度类型
            statusTypeList: [
                {msg: '办理中', value: 0},
                {msg: '已出签', value: 1},
                {msg: '已拒签', value: 2},
                {msg: '已取消', value: 3}
            ],
        }
    },
    components: {CompanyInfo, DictionarySelect, PaymentMethodSelector010},
    activated() {
        this.init();
    },
    methods: {
        //初始化
        async init() {
            const query = this.$route.query;
            this.id = query.id;
            this.orderNo = query.orderNo;
            // this.initDictionarySelect();
            await this.getDetail();
            await this.queryCountryTwoWord();
            await this.getPsgType();
            await this.getCertificateType();
        },

        //初始化字典组件
        initDictionarySelect() {
            Dictionary.init(this, {
                typeGroupKey: ['tmc'],
                typeKey: [],
                parentId: 0
            });
        },

        //获取签证国家枚举值
        queryCountryTwoWord() {
            consumer_country_queryCountryTwoWord().then(res => {
                this.countryList = res.datas
            })
        },

        //获取乘客类型
        getPsgType() {
            consumer_admin_flight_generate_psgType().then(res => {
                this.psgTypeList = res.datas
            })
        },

        //获取证件类型
        getCertificateType() {
            consumer_admin_flight_generate_certificateType().then(res => {
                let certificateTypeList = res.datas;
                this.certificateTypeList = [];
                certificateTypeList.forEach(value => {
                    if (value.msg == '护照') {
                        value.value = parseInt(value.code);
                        this.certificateTypeList.push(value);
                    }
                });
            });
        },

        //获取详情
        async getDetail() {
            try {
                this.loading = true
                const res = await consumer_admin_visa_details({
                    id: this.$route.query.id,
                    orderNo: this.$route.query.orderNo
                })
                this.orderDetail = res.datas;
                this.orderDetail.visaOrder.orderNo = this.$route.query.orderNo;
                this.visaOrder = this.orderDetail.visaOrder;
                this.visaCompanyInfo = this.orderDetail.visaCompanyInfo;
                this.visaCompanyBill = this.orderDetail.visaCompanyBill;

                const salesPriceGrouped = _.groupBy(this.visaCompanyBill, 'salesPrice');
                this.salesPriceList = _.map(salesPriceGrouped, (group, key) => {
                    return { salesPrice: key, list: group };
                });

                const serviceFeeGrouped = _.groupBy(this.visaCompanyBill, 'serviceFee');
                this.serviceFeeList = _.map(serviceFeeGrouped, (group, key) => {
                    return { serviceFee: key, list: group };
                });

                // totalAmount
                // let totalAmount = 0;
                // this.salesPriceList.forEach(item => {
                //     totalAmount += Number(item.salesPrice)*item.list.length;
                // })
                // this.serviceFeeList.forEach(item => {
                //     totalAmount += Number(item.serviceFee)*item.list.length;
                // })
                // this.totalAmount = totalAmount;

                let orderSourceText = await Dictionary.returnDictionary('tmc', 'visaOrderSource', this.orderDetail.visaOrder.orderSource);
                this.orderDetail.visaOrder.orderSourceText = orderSourceText.name;

                let paymentTypeText = await Dictionary.returnDictionary('tmc', 'paymentType', this.orderDetail.visaOrder.paymentType);
                this.orderDetail.visaOrder.paymentTypeText = paymentTypeText.name;

                this.files = [];
                if (this.visaOrder.files) {
                    this.files = JSON.parse(this.visaOrder.files);
                }

                //visaCompanyInfo.payType  1：授信支付 2：现结支付 3：线下转账
                if (this.visaCompanyInfo.payType === 2) {//现结支付
                    this.payType = '3'
                    this.$nextTick(() => {
                        this.initPayment()
                    })
                } else {
                    if (this.visaOrder.status === 0) {//待支付
                        this.payType = '2'
                    }
                }
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "获取签证订单详情失败！"
                })
            } finally {
                this.loading = false
            }
        },

        //初始化支付组件
        initPayment() {
            let _this = this;
            let orderForPayVos = [{
                orderNo: _this.orderNo,
                businessContent: '',
                businessType: 8
            }];
            _this.$refs.aPaymentMethodSelector010.init({
                page_this: _this,

                //在这里传入订单详情的相关参数
                get_params() {
                    const p = {
                        "orderForPayVos": orderForPayVos,
                        "payType": _this.payTypeForChannel,
                    };

                    return Q.resolve(p);
                },
                //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                before_pay_event_handler() {
                    return Q.resolve();
                },
                //支付成功事件处理方法
                pay_successful_event_handler() {
                    console.log(`pay_successful_event_handler`);
                    _this.$message({
                        type: 'success',
                        message: '支付成功!'
                    });
                    _this.$router.replace({
                        name: 'admin-visa-order-list',
                        query: {
                            behalfCompanyId: _this.$route.query.behalfCompanyId,
                        },
                    })
                },
                //支付失败事件处理方法
                pay_failed_event_handler() {
                    console.log(`pay_failed_event_handler`);
                },
                //取消订单按钮单击事件处理方法
                btn_cancel_order_click_event_handler(args) {
                    const show_loading = args.show_loading;
                    const hide_loading = args.hide_loading;
                    const stop_count_down = args.stop_count_down;
                    const enable_btn_pay = args.enable_btn_pay;
                    const enable_btn_cancel_order = args.enable_btn_cancel_order;

                    _this.$confirm("确定取消此签证订单？", "取消提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        Q.when().then(function () {
                            show_loading();
                            let params = {
                                id: _this.$route.query.id,
                                orderNo: _this.orderNo,
                                status: 4
                            };
                            return consumer_admin_visa_action(params);
                        }).then(function () {
                            console.log(`btn_cancel_order_click_event_handler`);
                            hide_loading();
                            stop_count_down();
                            enable_btn_pay({
                                enable: false,
                            });
                            enable_btn_cancel_order({
                                enable: false,
                            });
                            _this.$message({
                                type: 'success',
                                message: '取消成功!'
                            });
                            _this.$router.replace({
                                name: 'admin-visa-order-list',
                                query: {
                                    behalfCompanyId: _this.$route.query.behalfCompanyId,
                                },
                            })
                        }).catch(function (ex) {
                            if (ex) {
                                const enable_log_error = !ex.disable_log_error;
                                if (enable_log_error) {
                                    console.error(ex);
                                    console.trace();
                                    if (ex.stack) {
                                        console.error(ex.stack);
                                    }
                                }
                            }
                            return Q.reject(ex);
                        });
                    }).catch(() => {
                    })


                },
                //获取支付方式接口，返回code=300052时，弹窗的确定按钮单击处理处理方法
                dialog_code_300052_btn_ok_click_event_handler() {
                    console.log(`dialog_code_300052_btn_ok_click_event_handler`);
                    _this.$router.replace({
                        name: 'admin-visa-order-list',
                        query: {
                            behalfCompanyId: _this.$route.query.behalfCompanyId,
                        },
                    });
                    return Q.resolve(true);
                    //return true;
                },
            });
        },

        //取消订单
        onCancelOrder() {
            this.$confirm("确定取消此签证订单？", "取消提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.loading = true;
                let params = {
                    id: this.id,
                    orderNo: this.orderNo,
                    status: 4
                };
                consumer_admin_visa_action(params).then((res) => {
                    this.$message({
                        type: 'success',
                        message: '取消订单成功！'
                    })
                    this.backToList()
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                })
            }).catch(() => {
            })
        },

        //提交
        submit() {
            this.loading = true;
            let params = {
                id: this.$route.query.id,
                orderNo: this.$route.query.orderNo,
                status: 2
            };
            consumer_admin_visa_action(params).then((res) => {
                this.$message({
                    type: 'success',
                    message: '确认成功!'
                });
                this.$router.replace({
                    name: 'admin-visa-order-list'
                })
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        },

        //返回
        backToList() {
            this.$router.replace({
                name: 'admin-visa-order-list'
            })
        },
    }
}

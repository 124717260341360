// consumer_air_ticket_airChangeAffirm 【1230】平台航变信息列表确认操作
const __request = require(`./__request/__request_contentType_json`);
const consumer_air_ticket_airChangeAffirm = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/air/ticket/airChangeAffirm',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_air_ticket_airChangeAffirm;

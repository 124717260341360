import consumer_admin_behalfCustomer_newAddTraveler
    from 'yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_admin_behalfCustomer_newAddTraveler'
import consumer_country_queryCountryTwoWord from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/consumer_country_queryCountryTwoWord'
import consumer_admin_flight_generate_certificateType from 'yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType'
import moment from 'moment'

export default {
    data() {
        return {
            loading: false,
            companyId: '',
            newUserParams: {
                // staffName: '', // 中文名
                // phone: '', // 电话号码
                // certificates: [
                //     {
                //         certificateType: 1,
                //         certificateValue: ''
                //     }
                // ],
                // behalfCompanyId: '',
                // isOutCustomer: 0,
                // staffSex: 1,
                familyName: '',
                givenName: '',
                isOutCustomer: 1,
                companyId: '',
                customerName: '',
                englishName: '',
                englishSurnames: '',
                birthDate: '',
                email: '',
                phone: '',
                customerSex: 1,
                customerType: '',
                certificates: [
                    {
                        certificateType: 1,
                        certificateValue: '',
                        issCountryCode: 'CN',
                        passengerExpireDate: '',
                        countryCode: 'CN',
                    }
                ],
                id: '',
                nationality: '',
                remark: '',
            },
            newUserRule: {
                customerName: [
                    {required: true, message: '请输入出行人名称', trigger: 'blur'},
                ],
                familyName: [
                    {required: true, message: '请输入出行人姓', trigger: 'blur'},
                    { validator: this.validateFamilyName, trigger: 'blur'}
                ],
                givenName: [
                    {required: true, message: '请输入出行人名', trigger: 'blur'},
                    { validator: this.validateStaffName, trigger: 'blur'}
                ],
                certificateType: [
                    {required: true, message: '请选择证件类型', trigger: 'change'},
                ],
                certificateValue: [
                    {required: true, message: '请输入证件号码', trigger: 'blur'},
                    {validator: this.validateNewUerCard, trigger: 'blur'}
                ],
                phone: [
                    {required: true, message: '请输入手机号码', trigger: 'blur'},
                    {validator: this.validatePhone, trigger: 'blur'}
                ],
                customerSex: [
                    {required: true, message: '请选择性别', trigger: 'change'},
                ],
                birthDate: [
                    {required: true, message: '请输入生日', trigger: 'change'},
                ]
            },
            papersTypeList: [
                // 乘客证件类型：1.身份证，2.护照，3.港澳通行证，4.回乡证，5.台胞证，6.军官证，7.香港身份证，8.台湾通行证，9.户口本，10.其它,11澳門身份證
                /*{ value: 1, label: '身份证' },
                { value: 2, label: '护照' },
                { value: 3, label: '港澳通行证' },
                { value: 4, label: '回乡证' },
                { value: 5, label: '台胞证' },
                { value: 6, label: '军官证' },
                { value: 7, label: '香港身份证' },
                { value: 8, label: '台湾通行证' },
                { value: 9, label: '户口本' },
                { value: 10, label: '其它' },
                { value: 11, label: '澳門身份證' },*/
            ],
            genderList: [
                {value: 1, label: '男性'},
                {value: 2, label: '女性'},
                /*{value: '0', label: '男性'},
                {value: '1', label: '女性'},*/
            ],
            dialogNewUserVisible: false,
            countryList: [],
        }
    },
    components: {},
    created() {
    },
    mounted() {
        this.queryCountryTwoWord();
        this.getCertificatesList()
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        /*
        *
        * {
        *   companyId: '',
        * }
        * */
        init(val) {
            if (val) {
                this.companyId = val.companyId || ''
            } else {
                this.companyId = ''
            }

            this.newUserParams = {
                familyName: '',
                givenName: '',
                isOutCustomer: 1,
                companyId: '',
                customerName: '',
                englishName: '',
                englishSurnames: '',
                birthDate: '',
                email: '',
                phone: '',
                customerSex: 1,
                customerType: '',
                certificates: [
                    {
                        certificateType: 1,
                        certificateValue: '',
                        issCountryCode: 'CN',
                        passengerExpireDate: '',
                        countryCode: 'CN',
                    }
                ],
                id: '',
                nationality: '',
                remark: '',
            }
        },
        show() {
            this.newUserParams = {
                familyName: '',
                givenName: '',
                isOutCustomer: 1,
                companyId: this.companyId,
                customerName: '',
                englishName: '',
                englishSurnames: '',
                birthDate: '',
                email: '',
                phone: '',
                customerSex: 1,
                customerType: '',
                certificates: [
                    {
                        certificateType: 1,
                        certificateValue: '',
                        issCountryCode: 'CN',
                        passengerExpireDate: '',
                        countryCode: 'CN',
                    }
                ],
                id: '',
                nationality: '',
                remark: '',
            }
            this.dialogNewUserVisible = true
            this.$nextTick(() => {
                this.$refs['newUserForm'].clearValidate()
            })
        },
        validateNewUerCard(rule, value, callback) {
            if (this.newUserParams.certificates[0].certificateType === 1) {
                const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (!regIdCard.test(value)) {
                    callback(new Error('身份证号填写有误'))
                } else {
                    let birthday = '';
                    if (value.length === 15) {
                        birthday = "19" + value.substr(6, 6);
                    } else if (value.length === 18) {
                        birthday = value.substr(6, 8);
                    }
                    birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
                    let birthdayArr = birthday.split('-');
                    let yearSubmit = parseInt(birthdayArr[0]) !== NaN && parseInt(birthdayArr[0]) >= 1900;
                    let monthSubmit = parseInt(birthdayArr[1]) !== NaN && parseInt(birthdayArr[1]) >= 1 && parseInt(birthdayArr[1]) <= 12;
                    let daySubmit = parseInt(birthdayArr[2]) !== NaN && parseInt(birthdayArr[2]) >= 1 && parseInt(birthdayArr[2]) <= 31;
                    if (!yearSubmit || !monthSubmit || !daySubmit) {
                        callback(new Error("身份证号填写有误"));
                    } else {
                        callback()
                    }
                }
            } else {
                callback()
            }
        },
        validatePhone(rule, value, callback) {
            const regExp = /^1[3456789]\d{9}$/;
            if (regExp.test(value) && value.toString().length === 11) {
                callback()
            } else {
                callback(new Error('不符合手机号码格式'))
            }
        },
        submitAddNewUser() {
            this.$refs['newUserForm'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.newUserParams.behalfCompanyId = this.companyId
                    this.newUserParams.customerName = this.newUserParams.familyName + this.newUserParams.givenName
                    consumer_admin_behalfCustomer_newAddTraveler(this.newUserParams).then(res => {
                        this.newUserLoading = false
                        this.loading = false
                        let newUser = {
                            birthDate: this.newUserParams.birthDate,
                            familyName: this.newUserParams.familyName,
                            givenName: this.newUserParams.givenName,
                            staffName: this.newUserParams.familyName + this.newUserParams.givenName,
                            name: this.newUserParams.familyName + this.newUserParams.givenName,
                            englishName: this.newUserParams.englishName,
                            englishSurnames: this.newUserParams.englishSurnames,
                            userId: res.datas,
                            id: res.datas,
                            certificateList: [
                                {
                                    certificateType: this.newUserParams.certificates[0].certificateType,
                                    certificateValue: this.newUserParams.certificates[0].certificateValue,
                                    issCountryCode: this.newUserParams.certificates[0].issCountryCode,
                                    passengerExpireDate: this.newUserParams.certificates[0].passengerExpireDate,
                                    countryCode: this.newUserParams.certificates[0].countryCode,
                                }
                            ],
                            certificateName: this.newUserParams.certificates[0].certificateName,
                            certificateType: this.newUserParams.certificates[0].certificateType,
                            certificateValue: this.newUserParams.certificates[0].certificateValue,
                            issCountryCode: this.newUserParams.certificates[0].issCountryCode,
                            passengerExpireDate: this.newUserParams.certificates[0].passengerExpireDate,
                            countryCode: this.newUserParams.certificates[0].countryCode,
                            phone: this.newUserParams.phone,
                            staffSex: this.newUserParams.customerSex
                        }
                        this.dialogNewUserVisible = false
                        this.$emit('getNewUser', newUser)
                    }).catch(() => {
                        this.loading = false
                    })
                }
            })
        },
        getCertificatesList() {
            consumer_admin_flight_generate_certificateType().then(res => {
                let papersTypeList = res.datas
                papersTypeList.forEach(value => {
                    value.value = value.code
                    value.label = value.msg
                })
                this.papersTypeList = papersTypeList
            })
        },
        queryCountryTwoWord() {
            consumer_country_queryCountryTwoWord().then(res => {
                this.countryList = res.datas
            })
        },
        getBirthDate(item, index) {
            console.log(item, index)
            if (item.certificateType !== 1) {
                return
            }

            const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (regIdCard.test(item.certificateValue)) {
                let birthday = '';
                if (item.certificateValue.length === 15) {
                    birthday = "19" + item.certificateValue.substr(6, 6);
                } else if (item.certificateValue.length === 18) {
                    birthday = item.certificateValue.substr(6, 8);
                }
                birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
                let birthdayArr = birthday.split('-');
                let yearSubmit = parseInt(birthdayArr[0]) !== NaN && parseInt(birthdayArr[0]) >= 1900;
                let monthSubmit = parseInt(birthdayArr[1]) !== NaN && parseInt(birthdayArr[1]) >= 1 && parseInt(birthdayArr[1]) <= 12;
                let daySubmit = parseInt(birthdayArr[2]) !== NaN && parseInt(birthdayArr[2]) >= 1 && parseInt(birthdayArr[2]) <= 31;
                if (!yearSubmit || !monthSubmit || !daySubmit) {
                    return
                }

                this.newUserParams.birthDate = birthday

                if (parseInt(item.certificateValue.substr(16, 1)) % 2 === 1) {
                    // 男
                    this.newUserParams.customerSex = 1
                } else {
                    // 女
                    this.newUserParams.customerSex = 2
                }

                console.log(this.newUserParams)
            }
        },
        cardExpireDateDisabledDate(val) {
            const now = moment(val).format('YYYY-MM-DD')
            const nowTime = moment(now + ' 00:00:00').valueOf()
            const today = moment().format('YYYY-MM-DD')
            const todayTime = moment(today + ' 00:00:00').valueOf();
            return nowTime < todayTime
        },
        _changeCardType(item, val) {
            console.log(item, val)
            this.papersTypeList.forEach(value => {
                if (value.code === val) {
                    item.certificateName = value.msg;
                }
            })
        },
        // 检验中文名
        validateStaffName(rule, value, callback) {
            let hasMustChinaCertificate = false;
            this.newUserParams.certificates.forEach(value1 => {
                if ([1, 12].indexOf(value1.certificateType) > -1) {
                    hasMustChinaCertificate = true;
                }
            })

            const regExp = hasMustChinaCertificate ? /^[\u4e00-\u9fa5]+$/ : /^[\u0391-\uFFE5A-Za-z]+$/
            if (regExp.test(value)) {
                callback()
            } else {
                callback(hasMustChinaCertificate ? '只能输入中文' : '只能输入中文、英文')
            }
        },
        validateFamilyName(rule, value, callback) {
            let hasMustChinaCertificate = false;
            this.newUserParams.certificates.forEach(value1 => {
                if ([1, 12].indexOf(value1.certificateType) > -1) {
                    hasMustChinaCertificate = true;
                }
            })

            const regExp = hasMustChinaCertificate ? /^[\u4e00-\u9fa5]+$/ : /^[\u0391-\uFFE5A-Za-z]+$/
            if (regExp.test(value)) {
                callback()
            } else {
                callback(hasMustChinaCertificate ? '只能输入中文' : '只能输入中文、英文')
            }
        }
    }
}

import Q from 'q';
import BaseEntitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/base-entity-selector-0000/1.4.1/index.vue'
import consumer_admin_flight_generate_getCompanyList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getCompanyList'
export default {
    data() {
        return {
            valueModel: null
        }
    },
    props: {
        value: {
            // type: Object,
            required: false,
            default: null,
        },
    },
    components: {BaseEntitySelector},
    created() {
    },
    mounted() {
        const __this = this;
        console.log('1111111111111111111111')
        console.log(__this.$refs.aBaseEntitySelector)
        __this.$refs.aBaseEntitySelector.init({
            entity_define: {
                property_name_for_id: `id`,
                property_name_for_code: `id`,
                property_name_for_name: `companyName`,
                property_name_for_pin_yin: `id`,
                property_name_for_jian_pin: `id`,
                property_name_for_first_letter: `id`,
                property_name_for_extend_001: ``, // 扩展字段
                property_name_for_extend_002: ``, // 扩展字段
            },
            placeholder_text: '请选择公司',
            get_data_source() {
                console.log('33333333333333')
                return Q.when()
                    .then(function () {
                        console.log('222222222222222222222')
                        const params = {
                            companyName: '',
                            currentPage: 1,
                            keyword: '',
                            pageSize: 999,
                        }
                        return consumer_admin_flight_generate_getCompanyList(params);
                    })
                    .then(function (data) {
                        const all_entity_list = data.datas.list;

                        const o = {};
                        o.all_entity_list = all_entity_list;
                        return o;
                    })
                    ;
            },
            panel_select_entity: {
                get_tab_list(pParameter) {
                    console.log('22233333333344444455555555', pParameter)
                    return Q.when()
                        .then(function () {
                            const params = {
                                companyName: '',
                                currentPage: 1,
                                keyword: '',
                                pageSize: 999,
                            }
                            return consumer_admin_flight_generate_getCompanyList(params);
                        })
                        .then(function (data) {
                            const all_entity_list = data.datas.list;

                            const o = {};
                            o.tab_list = all_entity_list;
                            return o;
                        })
                        ;
                },
            },
            panel_filter_entity: {
                title: `名称`,
                is_show_code: false,
            },
            input: {
                event_handler_set_input_text: null,
            },

            event_handler: {
                event_handler_blur: null,
                event_handler_entity_selected: null,
            },
            panel_use_situation: `100`,
            defer_init_completed: Q.defer(),
        });
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        value(pNewValue, pOldValue) {
            const __this = this;
            __this.valueModel = pNewValue;
        },
        valueModel(pNewValue, pOldValue) {
            const __this = this;
            __this.$emit('input', pNewValue);
        },
    },
    computed: {},
    filters: {},
    methods: {}
}
import consumer_admin_journey_updateServiceFee from '@/lib/data-service/haolv-default/consumer_admin_journey_updateServiceFee';
export default {
    data() {
        return {
            commonResolve: null,
            commonReject: null,
            dialogVisible: false,
            loading: false,

            form: {
                serviceFee: '',
                proxyPrintServiceFee: '',
            },
            rule: {
                serviceFee: [
                    { required: true, message: '请输入服务费总价', trigger: 'blur' },
                ],
                proxyPrintServiceFee: [
                    { required: true, message: '请输入代打服务费总价', trigger: 'blur' },
                ],
            },
        }
    },
    props: {},
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        _inputMatch(data,text) {
            console.log(data, eval(`${data}.match(${text}) == null ? '' : ${data}.match(${text})[0];`));
            eval(`${data} = ${data}.match(${text}) == null ? '' : ${data}.match(${text})[0];`);
        },
        _cancel() {
            this.dialogVisible = false;
            this.commonReject();
        },
        _submit() {
            this.$refs.form.validate((valid) => {
                if (!valid) {
                    return
                }
                this.loading = true;
                consumer_admin_journey_updateServiceFee(this.form).then(res=>{
                    this.loading = false;
                    this.dialogVisible = false;
                    this.$message.success('调整成功');
                    this.commonResolve();
                }).catch(e=>{
                    this.loading = false;
                })
            });
        },
        /**
         * 入参
         * params obj
         * {
         *     businessType: number, 业务类型：1机票，21火车票，22火车票改签，23火车票退订，3酒店，4汽车
         *     orderNo: String 订单号码
         *     oldService: number, 旧服务费
         *     oldProxyPrintServiceFee: number, 旧代打服务费总金额， 当businessType = 21才需要传
         * }
         * **/
        init(params) {
            console.log(params)
            params = params || {};
            if (!params.businessType) {
                console.log('请传入businessType');
            }
            if (!params.orderNo) {
                console.log('请传入orderNo');
            }
            if (params.oldService === undefined || params.oldService == null) {
                console.log('请传入oldService');
            }
            if (params.businessType === 21 && (params.oldProxyPrintServiceFee === undefined || params.oldProxyPrintServiceFee === null)) {
                console.log('请传入oldProxyPrintServiceFee');
            }
            this.form = {
                "businessType": params.businessType,
                "orderNo": params.orderNo,
                "oldProxyPrintServiceFee": params.oldProxyPrintServiceFee,
                "oldServiceFee": params.oldService,
                "proxyPrintServiceFee": '',
                "serviceFee": ''
            };
            return new Promise((resolve, reject) => {
                this.commonResolve = resolve;
                this.commonReject = reject;
                this.dialogVisible = true;
            })
        }
    }
}
const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);


const consumer_web_company_getDCCardLevel = (pParameter) => {
  if (!pParameter) pParameter = {};

  const p = {
    method: 'post',
    urlSuffix: '/consumer/web/company/getDCCardLevel',
    data: pParameter,
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
};
module.exports = consumer_web_company_getDCCardLevel;

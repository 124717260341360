// consumer_admin_user_getUserMenu
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/doc.html?tdsourcetag=s_pctim_aiomsg#/tmc-biz/admin-user-controller/getUserMenuUsingPOST
const consumer_admin_user_getUserMenu = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/user/getUserMenu',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_user_getUserMenu;
const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 差旅平台获取公司发票类型枚举接口文档地址：http://http://192.168.0.26:8665/doc.html#/yinzhilv-tmc/%E5%85%AC%E5%8F%B8%E4%BF%A1%E6%81%AF/queryCompanyInvoiceTypeUsingPOST
const consumer_web_company_detail = (pParameter) => {
  if (!pParameter) pParameter = {};

  const p = {
    method: 'post',
    urlSuffix: '/consumer/web/company/queryCompanyInvoiceType',
    data: pParameter,
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
};
module.exports = consumer_web_company_detail;

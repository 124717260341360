// import biz_flight_order_ItineraryDetail  from '@/lib/data-service/b2c/biz_flight_order_ItineraryDetail'
import consumer_admin_flight_admin_itinerary_print_detail from '@/lib/data-service/haolv-default/consumer_admin_flight_admin_itinerary_print_detail' // 获取行程单数据
import consumer_admin_flight_admin_itinerary_save_detail from '@/lib/data-service/haolv-default/consumer_admin_flight_admin_itinerary_save_detail' // 提交
import baseUrl from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix";
export default {
    data () {
        return {
            ticketNumber: '',
            orderNo: '',
            psgNo: '',
            segmentNo: '',
            params: {
                airportTax: 0,
                cardNo: '',
                fillDate: "",
                fillUnit: "",
                fuelTax: 0,
                insureFee: 0,
                otherTax: 0,
                promptMessage: "",
                psgName: "",
                rebatePrice: 0,
                remark: "",
                salesUnitCode: "",
                ticketNumber: 0,
                total: 0,
                verificationCode: "",
                segmentList: [],
            },
            segmentList: [
                {}, {}
            ],
            canSubmit: true,
            fileUrl: '',
        }
    },
    components: {},
    created () {},
    mounted () {},
    activated () {
        this.ticketNumber = this.$route.query.ticketNumber
        this.orderNo = this.$route.query.orderNo
        //this.psgNo = this.$route.query.psgNo
        //this.segmentNo = this.$route.query.segmentNo
        if (this.ticketNumber) {
            this.getDetail()
        } else {
            this.clearItem()
        }

    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        getDetail() {
            consumer_admin_flight_admin_itinerary_print_detail({ticketNumber: this.ticketNumber, orderNo: this.orderNo}).then(res => {
                let datas = res.datas

                datas.segmentList = [datas.segmentGo, datas.segmentBack]
                this.params = Object.assign({}, this.params, datas)
                console.log(this.params)
            })
        },
        filterMoneyFun(val) {
            const regexp = /(?:\.0*|(\.\d+?)0+)$/
            val = (val / 100).toFixed(2)
            val = val.replace(regexp, '$1')
            if (val.indexOf('.') > -1) {
                return val
            } else {
                return val + '.00'
            }
        },
        clearItem() {
            this.params = {
                airportTax: 0,
                cardNo: '',
                fillDate: "",
                fillUnit: "",
                fuelTax: 0,
                insureFee: 0,
                otherTax: 0,
                promptMessage: "",
                psgName: "",
                rebatePrice: 0,
                remark: "",
                salesUnitCode: "",
                ticketNumber: 0,
                total: 0,
                verificationCode: "",
                segmentList: [
                    {
                        airlineName: '',
                        cabin: '',
                        cityName: '',
                        date: '',
                        deadlineDate: '',
                        effectiveDate: '',
                        flightNo: '',
                        luggage: '',
                        segmentSerial: 1,
                        segmentType: 1,
                        ticketType: '',
                        time: '',
                    },
                    {
                        airlineName: '',
                        cabin: '',
                        cityName: '',
                        date: '',
                        deadlineDate: '',
                        effectiveDate: '',
                        flightNo: '',
                        luggage: '',
                        segmentSerial: 2,
                        segmentType: 2,
                        ticketType: '',
                        time: '',
                    }
                ]
            }
        },
        toPreview() {
            let params = JSON.parse(JSON.stringify(this.params))
            params.segmentGo = params.segmentList[0]
            params.segmentBack = params.segmentList[1]

            if (!this.canSubmit) {
                return
            }
            this.canSubmit = false
            consumer_admin_flight_admin_itinerary_save_detail(params).then(res => {
                this.canSubmit = true

                const environment = global.appConfig.environment;
                const urlPrefix = baseUrl({
                    environment: environment,
                    appKey: "haolv-default"
                });
                console.log('urlPrefix', urlPrefix)
                window.open(urlPrefix + res.datas);


                return
                this.fileUrl = res.datas
                this.$nextTick(() => {
                    this.$refs.downloadBtn.click()
                })
            }).catch(() => {
                this.canSubmit = true
            })
        },
        filterMoneyToBranch(val) {
            return parseInt(val) * 100
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
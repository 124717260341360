const wrap_cache = require(`nllib/src/promise-helper/wrap_cache`);
const consumer_admin_user_getUserMenu = require(`../../data-service/haolv-default/consumer_admin_user_getUserMenu`);

const get_consumer_admin_user_getUserMenu = function (pParameter) {
    if (!pParameter) {
        pParameter = {};
    }

    const clear_cache = pParameter.clear_cache;

    const fn = consumer_admin_user_getUserMenu;

    return wrap_cache({
        fn: fn,
        clear_cache: clear_cache
    });
};

module.exports = get_consumer_admin_user_getUserMenu;
import _ from 'underscore';
// 客户列表
import consumer_web_company_pageList from "@/lib/data-service/haolv-default/consumer_web_company_pageList.js";
// 平台获取公司授信信息
import consumer_credit_platformCreditData from "@/lib/data-service/haolv-default/consumer_credit_platformCreditData.js";
// 授信停用启用
import consumer_credit_creditStatusUpdate from "@/lib/data-service/haolv-default/consumer_credit_creditStatusUpdate";


//---------------------------------------------------------------------------------------------------------------------------------------
// 查询服务费配置
import consumer_web_company_serviceSetInfo from '@/lib/data-service/haolv-default/consumer_web_company_serviceSetInfo';
// 确认生效
import consumer_web_company_confirmServiceAdult from '@/lib/data-service/haolv-default/consumer_web_company_confirmServiceAdult';
import submenu from "element-ui/packages/submenu";
//---------------------------------------------------------------------------------------------------------------------------------------

export default {
  computed: {
    submenu() {
      return submenu
    }
  },
  data() {
    return {
      serviceFeeSettingDialog: false,
      formRules: {},
      loadingDialog: false,
      allocation: {
        serviceAmountType: [
          {label: 1, text: "比例"},
          {label: 2, text: "定额"},
        ],
        serviceAmountTypeCar: [
          {label: 2, text: "定额"},
        ],
        trainInvoiceTypeBox:[
          { label: 1, text: "代打" },
          { label: 2, text: "自取" },
        ],
      },
      companyIdBox: '',
      serviceFeeSettingName: '',
      lastAdultTime: '',
      form: {
        // 已生效
        pdProductSetList: [{},{},{},{}],
        // 未生效
        pdProductSetTempList: [
          {
            businessType: 1,

            serviceAmountSet: 0, // 线上服务费设置：0.不收取，1.收取，默认0
            serviceAmountType: 1, // 线上服务费-计算方式：0. 无，1.百分比，2.定额
            serviceAmount: "", // 线上服务费--收取金额、百分比

            offlineServiceAmountSet: 0, // 线下服务费：0.不收取，1.收取，默认0
            offlineServiceAmountType: 1, // 线下服务费-计算方式：0. 无，1.百分比，2.定额
            offlineServiceAmount: "", // 线下服务费--收取金额、百分比

            asiaServiceAmountSet: 0, // 亚洲服务费设置：0.不收取，1.收取，默认0
            asiaServiceAmountType: 1, // 亚洲计算方式：0. 无，1.百分比，2.定额
            asiaServiceAmount: "", // 亚洲服务费

            internationalServiceAmountSet: 0, // 亚洲外服务费设置：0.不收取，1.收取，默认0
            internationalServiceAmountType: 1, // 亚洲外计算方式：0. 无，1.百分比，2.定额
            internationalServiceAmount: "", //亚洲外服务费
          },
          {
            businessType: 3,
            
            agreementServiceAmountSet: 0, // 酒店线上服务费设置：0.不收取，1.收取，默认0
            agreementServiceAmountType: 1, // 酒店线上服务费计算方式：0. 无，1.百分比，2.定额
            agreementServiceAmount: '', // 酒店线上服务费
            agreementServiceAmountLimit: '', // 酒店线上服务费上限

            offlineServiceAmountSet: 0, // 线下服务费设置：0.不收取，1.收取，默认0
            offlineServiceAmountType: 1, // 线下服务费计算方式：0. 无，1.百分比，2.定额
            offlineServiceAmount: '', // 线下服务费
            offlineServiceAmountLimit: '', // 线下服务费上限（仅酒店）
          },
          {
            businessType: 2,

            serviceAmountSet: 0, // 线上服务费设置：0.不收取，1.收取，默认0
            serviceAmountType: 2, // 线上服务费-计算方式：0. 无，1.百分比，2.定额
            serviceAmount: "", // 线上服务费--收取金额、百分比

            offlineServiceAmountSet: 0, // 线下服务费：0.不收取，1.收取，默认0
            offlineServiceAmountType: 2, // 线下服务费-计算方式：0. 无，1.百分比，2.定额
            offlineServiceAmount: "", // 线下服务费--收取金额、百分比

            changeServiceAmountSet: 0,  // 线上改签服务费设置：0.不收取，1.收取，默认0
            changeServiceAmountType: 2, // 线上改签服务费计算方式：0. 无，1.百分比，2.定额
            changeServiceAmount: "",  // 线上改签服务费

            offlineChangeServiceAmountSet: 0,  // 线下改签服务费设置：0.不收取，1.收取，默认0
            offlineChangeServiceAmountType: 2, // 线下改签服务费计算方式：0. 无，1.百分比，2.定额
            offlineChangeServiceAmount: "",  // 线下改签服务费

            refundServiceAmountSet: 0, // 线上退票服务费设置：0.不收取，1.收取，默认0
            refundServiceAmountType: 2, // 线上退票服务费计算方式：0. 无，1.百分比，2.定额
            refundServiceAmount: "",  // 线上退票服务费

            offlineRefundServiceAmountSet: 0, // 线下退票服务费设置：0.不收取，1.收取，默认0
            offlineRefundServiceAmountType: 2, // 线下退票服务费计算方式：0. 无，1.百分比，2.定额
            offlineRefundServiceAmount: "",  // 线下退票服务费

            trainInvoiceType: 0, // 火车票发票设置 0：无 1：代订 2：自取 默认：0 无
            proxyPrintServiceAmountType: 2,  // 代打服务费计算方式：0. 无，1.百分比，2.定额
            proxyPrintServiceAmount: "",  // 代打服务费

            requiredServiceAmountSet: 0, // 免登录服务费设置：0.不收取，1.收取，默认0
            requiredServiceAmountType: 2, // 免登录计算方式：0. 无，1.百分比，2.定额
            requiredServiceAmount: "", // 免登录服务费

            robTicket: "", // 抢票费
          },
          {
            businessType: 4, // 类型
            serviceAmountSet: 0, // 是否收取
            showServiceFee: 0, // 是否展示
            serviceAmountType: 1, // 计算方式
            serviceAmount: "", // 收取金额
          },
        ],
      },
      query: {
        companyName: '',
        phone: '',
        startDate: '',
        endDate: '',
        status: 2,
        creditStatus: 0,
        pageSize: 10,
        currentPage: 1,
      },
      resetQuery: {},
      date: [],
      total: 0,
      list: [],
      activeName: '0',

      info: {
        openCreditAmount: '',
        usedCreditAmount: '',
      },
      waitConfirmCount: 0,
      disableCount: 0,
      enableCount: 0,

      canSubmit: false,
    };
  },
  filters: {
    format_type(val) {
      switch (Number(val)) {
        case 2: // 1
          return "火车票";
          break;
        case 3: // 2
          return "酒店";
          break;
        case 1: // 3
          return "机票";
          break;
        case 4: // 4
          return "用车";
          break;
      }
    },
  },
  methods: {
    clickTakeEffect(){
      // this.serviceFeeSettingDialog = false
      this.loadingDialog = true;
      consumer_web_company_confirmServiceAdult({companyId: this.companyIdBox})
        .then((res) => {
          this.$message({
            message: '已生效',
            type: 'success'
          });
          this.serviceFeeSettingDialog = false;
        })
        .catch((rej) => {
          this.$message.error(`${rej.data.msg}`);
        })
        .finally(() => {
          this.setList();
          this.loadingDialog = false;
        })
    },
    clickServiceFeeDialog(data, canSubmit) {
      this.canSubmit = canSubmit;
      this.serviceFeeSettingName = data.companyName;
      this.loadingDialog = true;
      this.companyIdBox = data.id;
      this.form = null;
      consumer_web_company_serviceSetInfo({companyId: data.id})
        .then(res => {
          this.lastAdultTime = res.datas.lastAdultTime;
          let form = {
            pdProductSetList: [{},{},{},{}],
            pdProductSetTempList: [{},{},{},{}],
          }
          // this.form.pdProductSetList = [{},{},{},{}];
          res.datas.pdProductSetList.forEach((item,index) => {
            if (item.businessType == 1) {
              form.pdProductSetList[0] = item;
              form.pdProductSetTempList[0] = item;
            }
            if (item.businessType == 2) {
              form.pdProductSetList[2] = item;
              form.pdProductSetTempList[2] = item;
            }
            if (item.businessType == 3) {
              form.pdProductSetList[1] = item;
              form.pdProductSetTempList[1] = item;
            }
            if (item.businessType == 4) {
              form.pdProductSetList[3] = item;
              form.pdProductSetTempList[3] = item;
            }
          });

          res.datas.pdProductSetTempList.forEach((item,index) => {
            if (item.businessType == 1) {
              form.pdProductSetTempList[0] = item;
            }
            if (item.businessType == 2) {
              form.pdProductSetTempList[2] = item;
            }
            if (item.businessType == 3) {
              form.pdProductSetTempList[1] = item;
            }
            if (item.businessType == 4) {
              form.pdProductSetTempList[3] = item;
            }
          });
          form.pdProductSetList.forEach((item, index) => {
            item.setTemp = null;
            form.pdProductSetTempList.forEach(value => {
              if (value.businessType === item.businessType) {
                item.setTemp = value;
              }
            })
          });
          this.form = form;
          console.log(this.form);
          this.$forceUpdate();
        })
        .finally(() => {
          this.loadingDialog = false;
        })
      this.serviceFeeSettingDialog = true;
    },
    handleClick(tab) {
      let creditStatus = Number(tab.name);
      if (creditStatus === this.query.creditStatus) return;
      this.query.creditStatus = creditStatus;
      this.query.currentPage = 1;
      this.setList();
    },
    handleCurrentChange(page) {
      this.query.currentPage = page;
      this.setList();
    },
    handleSizeChange(size) {
      this.query.currentPage = 1;
      this.query.pageSize = size;
      this.setList();
    },
    setList() {
      const loading = this.$loading({
        lock: true,
        text: '数据加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let dataBox = false;
      if (this.activeName == 4) {
        dataBox = {
          clientType: 0,
          companyName: this.query.companyName,
          currentPage: this.query.currentPage,
          endDate: this.query.endDate,
          pageSize: this.query.pageSize,
          phone: "",
          serviceCheckStatus: "0",
          startDate: this.query.startDate,
        }
      }
      if (this.activeName == 0) {
        dataBox = {
          clientType: 0,
          companyName: this.query.companyName,
          currentPage: this.query.currentPage,
          endDate: this.query.endDate,
          pageSize: this.query.pageSize,
          phone: "",
          creditStatus: "0",
          serviceCheckStatus: "-1",
          startDate: this.query.startDate,
          status: "2",
        }
      }

      consumer_web_company_pageList(dataBox ? dataBox : this.query)
        .then((res) => {
          this.list = res.datas.res.list;
          this.total = res.datas.res.totalCount;
          this.enableCount = res.datas.enableCount;
          this.disableCount = res.datas.disableCount;
          this.waitConfirmCount = res.datas.waitConfirmCount;
          this.info = {
            openCreditAmount: res.datas.openCreditAmount ? res.datas.openCreditAmount : '',
            usedCreditAmount: res.datas.usedCreditAmount ? res.datas.usedCreditAmount : '',
          };
        })
        .finally(() => {
          loading.close();
        });
    },
    setInfo() {
      // consumer_credit_platformCreditData()
      //   .then((res) => {
      //     const info = res.datas;
      //     if (info) {
      //       this.info = info;
      //     }
      //   });
    },
    onDateChange(value) {
      if (value) {
        this.query.startDate = value[0];
        this.query.endDate = value[1];
      } else {
        this.query.startDate = '';
        this.query.endDate = '';
      }
    },
    onReset() {
      this.query.companyName = '';
      this.query.endDate = '';
      this.query.startDate = '';
      this.date = [];
      this.setList();
    },
    onDetail(row) {
      let name = 'admin-credit-users-detail';
      const data_app_for_npm_haolv_platform_v2 = this.$route.meta.data_app_for_npm_haolv_platform_v2;
      if (data_app_for_npm_haolv_platform_v2) {
        const get_route_detail_page = data_app_for_npm_haolv_platform_v2.get_route_detail_page;
        if (_.isFunction(get_route_detail_page)) {
          const route_detail_page = get_route_detail_page();
          if (route_detail_page) {
            const route_name = route_detail_page.route_name;
            if (route_name) {
              name = route_name;
            }
          }
        }
      }
      this.$router.push({
        name: name,
        query: {
          id: row.id,
        },
      });
    },
    onUpdateStatus(companyId, creditStatus) {
      let message = '';
      if (creditStatus === 1) {
        message = '启用';
      } else if (creditStatus === 2) {
        message = '停用';
      }
      this.$confirm(`是否确认${message}此帐号授权功能?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: '请求中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          consumer_credit_creditStatusUpdate({ companyId, creditStatus })
            .then(() => {
              this.setList();
              this.$message.success(`${message}成功`);
            })
            .finally(() => {
              loading.close();
            });
        })
    },
  },
  created() {
    this.resetQuery = { ...this.query };
    this.setList();
    this.setInfo();
  },
};

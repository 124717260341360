import consumer_admin_flight_change_newDetails from '@/lib/data-service/haolv-default/consumer_admin_flight_change_newDetails'
import consumer_air_ticket_fligthRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules"
import consumer_admin_flight_change_operationChange from '@/lib/data-service/haolv-default/consumer_admin_flight_change_operationChange'
import consumer_admin_flight_manage_nuLockOrderChange from '@/lib/data-service/haolv-default/consumer_admin_flight_manage_nuLockOrderChange'
import ChangeClausePopup from '@/page/admin/replace-orders/component/changeClausePopup/1.0.0/index.vue'
export default {
    data() {
        return {
            detailLoading: false,
            loading: false,
            chaOrderNo: '',
            orderNo: '',
            detail: {},
            form: {
                chaFailReason: '',   // 失败原因
                chaOrderNo: '',
                orderNo: '',
                changeStatus: '',
                //differencePrice: '',
                //commission: '',
                //newTicketNumber: '',
                //newpnr: '',
                remark: ''
            },
            formList: [],
            ruleText: {},
            ruleLoading: false,
            ruleShow: false, // 退改签说明弹窗
            changeStatusList: [
                // 改签状态  1.改签需补款 2.改签完成 3.改签失败
                {value: 1, label: '改签需补款'},
                {value: 2, label: '改签完成'},
                {value: 3, label: '改签失败'},
            ],

            rules: {
                // differencePrice: [
                //     {validator: this.validateDifferencePrice, trigger: 'blur' },
                // ],
                // commission: [
                //     {validator: this.validateDifferencePrice, trigger: 'blur' },
                // ],
                // newTicketNumber: [
                //     {validator: this.validateNewTicketNumber, trigger: 'blur' },
                // ],
                // newpnr: [
                //     {validator: this.validateNewPNR, trigger: 'blur' },
                // ],
            },
            canSubmit: true,
        }
    },
    components: {ChangeClausePopup},
    created() {
    },
    mounted() {
    },
    activated() {
        this.chaOrderNo = this.$route.query.chaOrderNo
        this.orderNo = this.$route.query.orderNo || ''
        this.form.chaOrderNo = this.$route.query.chaOrderNo
        this.form.orderNo = this.$route.query.orderNo
        this.getDetail()
        //this.getOrderLog()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterCabinRank(val) {
            if (val === 1) {
                return '头等舱'
            } else if (val === 2) {
                return '商务舱'
            } else if (val === 3) {
                return '经济舱'
            } else {
                return ''
            }
        },
        filterMoney(val) {
            if (val) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return ''
            }
        },
    },
    methods: {
        validateDifferencePrice(rule, value, callback) {
            if (this.form.changeStatus !== 1) {
                callback()
            } else {
                const regExp = /^(0|[1-9]\d*)(\.\d{1,2})?$/
                if (regExp.test(value)) {
                    if (value >= 0) {
                        callback()
                    } else {
                        callback(new Error('请输入不小于0的数字'))
                    }
                } else {
                    callback(new Error('请输入合法数字'))
                }
            }
        },
        validateCommission(rule, value, callback) {
            if (this.form.changeStatus !== 1) {
                callback()
            } else {
                const regExp = /^(0|[1-9]\d*)(\.\d{1,2})?$/
                if (regExp.test(value)) {
                    // 2022-10-23 微信群客服陈露夕要求改签手续费可填0
                    if (value >= 0) {
                        callback()
                    } else {
                        callback(new Error('请输入不小于0的数字'))
                    }
                } else {
                    callback(new Error('请输入合法数字'))
                }
            }
        },
        validateNewTicketNumber(rule, value, callback) {
            if (this.form.changeStatus !== 2) {
                callback()
            } else {
                if (value !== '') {
                    callback()
                } else {
                    callback(new Error('请输入新票号'))
                }
            }
        },
        validateNewPNR(rule, value, callback) {
            if (this.form.changeStatus !== 2) {
                callback()
            } else {
                if (value !== '') {
                    callback()
                } else {
                    callback(new Error('请输入新PNR'))
                }
            }
        },
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 9) {
                return [1, 2];
            } else if (columnIndex === 10) {
                return [0, 0];
            }
        },
        headerStyle({ row, columnIndex }) {
            row[9].colSpan = 2;
            row[10].colSpan = 0;
        },
        getDetail() {
            this.detailLoading = true
            consumer_admin_flight_change_newDetails({chaOrderNo: this.chaOrderNo}).then(res => {
                let detail = res.orderDetail
                this.form.orderNo = detail.orderNo
                // 如果是已补款进去的，则只有两个选项
                this.form.changeStatus = 2
                this.changeStatusList = [{value: 2, label: '改签完成'}, {value: 3, label: '改签失败'}]
                if (detail.chaOrderStatus === 31) {
                    this.form.changeStatus = 1
                    this.changeStatusList.unshift({value: 1, label: '改签需补款'})
                }
                let formList = []
                detail.changeinformation.forEach((value, index) => {
                    if (value.commission === undefined || value.commission === null) {
                        value.commission = ''
                        value.oldCommission = ''
                    } else {
                        value.oldCommission = value.commission
                    }
                    if (value.differencePrice === undefined || value.differencePrice === null) {
                        value.differencePrice = ''
                        value.oldDifferencePrice = ''
                    } else {
                        value.oldDifferencePrice = value.differencePrice
                    }
                    value.newTicketNumber = ''
                    value.newpnr = ''

                    formList.push(`detailListForm${index}`)
                })
                this.formList = formList
                this.detail = detail
                this.detailLoading = false
                this.$nextTick(() => {
                    this.$refs.detailForm.resetFields()
                })
            }).catch(() => {
                this.detailLoading = false
            })
        },
        // 退票退改规则
        showRefundChangeClause(val) {
            this.ruleText = {}
            this.ruleShow = true
            let data = {
                segmentNo: val.segmentNo
            }
            this.ruleLoading = true
            consumer_air_ticket_fligthRules(data).then(res => {
                this.ruleLoading = false
                this.ruleText = res.datas.rsData
            }).catch(() => {
                this.ruleLoading = false
            })
        },
        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
            switch (val) {
                case (1):
                    return 'type2'
                case (2):
                    return 'type1'
                default: return ''
            }
        },
        changeStatusFun(val) {
            if (this.detail.chaOrderStatus === 31 && val === 2) {
                // 处理中， 改签完成
                this.detail.changeinformation.forEach(value => {
                    value.differencePrice = ''
                    value.commission = ''
                })
            }
            if (val === 1) {
                // 改签需补款
                this.detail.changeinformation.forEach(value => {
                    value.newTicketNumber = ''
                    value.newpnr = ''
                })
            }
            if (val === 3) {
                this.detail.changeinformation.forEach(value => {
                    value.newTicketNumber = ''
                    value.newpnr = ''
                    if (this.detail.chaOrderStatus === 31) {
                        value.differencePrice = ''
                        value.commission = ''
                    }
                })
            }
            this.formList.forEach(value => {
                this.$refs[value].clearValidate()
            })

        },
        submit() {
            if (!this.canSubmit) {
                return
            }
            let list = []
            if (this.form.changeStatus == 3) {
                this.formList.push('detailForm');
            }
            this.formList.forEach(value => {
                let item = new Promise((resolve) => {
                    this.$refs[value].validate((valid) => {
                        resolve(valid ? 1 : -1)
                    })
                })
                list.push(item)
            })
            Promise.all(list).then(resList => {
                if (resList.indexOf(-1) === -1) {
                    let text = ''
                    this.changeStatusList.forEach(value => {
                        if (value.value === this.form.changeStatus) {
                            text = '是否确认' + value.label + '?'
                        }
                    })
                    this.$confirm(text, '温馨提示', {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.canSubmit = false
                        this.loading = true
                        let form = JSON.parse(JSON.stringify(this.form))
                        let changeOperation = []
                        this.detail.changeinformation.forEach(value => {
                            /*const changeOperationItem = {
                                differencePrice: value.differencePrice,
                                commission: value.commission,
                                newTicketNumber: value.newTicketNumber,
                                newpnr: value.newpnr,
                                psgNo: value.psgNo
                            }
                            changeOperation.push(changeOperationItem)*/
                            changeOperation.push(value)
                        })
                        form.changeOperation = changeOperation;
                        consumer_admin_flight_change_operationChange(form).then(res => {
                            this.$message({
                                type: "success",
                                message: '提交成功'
                            })
                            // 解锁
                            // consumer_admin_flight_manage_nuLockOrderChange({chaOrderNo: this.chaOrderNo})
                            if (this.form.changeStatus === 1 && res.datas.agriculturalBank === 0) {
                                // 去支付
                                // agriculturalBank:1为农行api订单,0为正常订单
                                // 农行api单不需要去支付，就算需补款也不需要跳转去支付
                                this.$router.push({
                                    // name: 'admin-order-payment',
                                    name: 'admin-flight-payment',
                                    query: {
                                        orderType: 1,
                                        pageType: 3,
                                        oriOrderNo: this.detail.orderNo,
                                        orderNo: this.detail.chrOrderNo,
                                        behalfCompanyId: this.detail.companyId,
                                        selfPolicy: this.detail.selfPolicy
                                    }
                                })
                            } else {
                                this.back()
                            }
                        }).finally(() => {
                            this.loading = false
                            this.canSubmit = true
                        })
                    }).catch(() => {
                    });
                }
            })
        },
        back() {
            this.$router.push({
                name: 'admin-flight-change-list'
            })
        },
        changeModel(params, name, val) {
            params[name] = val
            params.rescheduling = this.$math.chain(parseFloat(params.oldDifferencePrice || 0)).add(parseFloat(params.oldCommission || 0)).done()
        },
    }
}

export default {
    contactName: '客户基础信息-联系人',
    contactMobile: '客户基础信息-联系电话',
    companyId: '客户基础信息-所属企业',
    evectionType: '客户基础信息-出行类型',
    payType: '客户基础信息-支付方式',

    orderSource: '采购信息-采购渠道',
    supOrderNo: '采购信息-供应商订单号',
    salesPrice: '采购信息-销售价',
    supPrice: '采购信息-采购价',
    serviceFee: '采购信息-服务费',
    profit: '采购信息-利润',
    paymentType: '采购信息-付款方式',
    paymentTime: '采购信息-付款时间',
    paymentNum: '采购信息-付款流水号',


    carUseType: '行程信息-用车类型',
    defCarType: '行程信息-车型',
    setOut: '行程信息-出发',
    reach: '行程信息-到达',
    useCarTime: '行程信息-用车时间',
    useCarPeople: '行程信息-出行人姓名',
    flightNumber: '行程信息-航班号',
    depAirportNo: '行程信息-起飞机场',
    depTerminal: '行程信息-起飞航站楼',
    arrAirportNo: '行程信息-到达机场',
    arrTerminal: '行程信息-到达航站楼',
    depTime: '行程信息-起飞时间',
    arrTime: '行程信息-到达时间',

    driverName: '司机信息-司机名称',
    driverPhone: '司机信息-司机手机',
    carNumber: '司机信息-车牌号'

}
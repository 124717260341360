import consumer_admin_flight_generate_getDepartmentList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getDepartmentList'
import consumer_admin_flight_generate_getCostCenterList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getCostCenterList'
import {awaitWrap, handlePromise} from "@/page/admin/replace-orders/common/unit";
import Q from 'q';
export default {
    data() {
        return {
            costList: [], // 成本列表
            cascadeList: [], // 部门列表
            allCostList: [], // 所有成本列表
            filterCostList: [],
            activeName: '1',
            //listShow: false,
            //filterShow: false,
            showType: '', // '' 不展开。 'tab' 展开tab。 'filter': 搜索过滤
            input: '',
            placeholder: '请选择成本归属',
            deferInitCompleted: Q.defer(),
            selectIndex: 0
        }
    },
    props: {
        feeType: { // 费用归属类型：1.组织架构，2.成本中心
            type: Number,
            default: 1
        },
        feeName: {
            type: String,
            default: ''
        },
        feeId: {
            type: [Number, String],
            default: ''
        },
        centerCode: {
            type: String,
            default: ''
        },
    },
    components: {
        radioItem: () => import('./component/radioItem/index.vue')
    },
    created() {
    },
    mounted() {
        document.addEventListener('click', this._clickReserveWrapper);
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
        document.removeEventListener('click', this._clickReserveWrapper)
    },
    watch: {
        showType(newVal, oldVal) {
            if (newVal === '') {
                this.input = this.currentFeeName;
                this.placeholder = '请选择成本归属'
            } else if (oldVal === '') {
                this.input = '';
                this.placeholder = this.currentFeeName;
            }
        },
        feeId(newValue, oldValue) {
            const _this = this;
            function getList() {
                return Q.when().then(() => {
                    return _this.deferInitCompleted.promise
                })
            };
            getList().then(() => {
                this.input = this.currentFeeName;
                if (this.currentFeeType === 1) {
                    this._toSelectRadio({deptId: newValue, deptName: this.feeName})
                } else {
                    this._getActive({id: newValue, centerName: this.feeName, centerCode: this.currentCenterCode})
                }
            })
        },
        filterShow(newVal) {
            if (newVal) {
                this.selectIndex = 0
            }
        },
    },
    computed: {
        currentFeeType: {
            get () {
                this.activeName = this.feeType.toString();
                return this.feeType
            },
            set (val) {
                this.$emit('update:feeType', val)
            }
        },
        currentFeeName: {
            get () {
                return this.feeName
            },
            set (val) {
                this.$emit('update:feeName', val)
            }
        },
        currentFeeId: {
            get () {
                return this.feeId
            },
            set (val) {
                this.$emit('update:feeId', val)
            }
        },
        currentCenterCode: {
            get () {
                return this.centerCode
            },
            set (val) {
                this.$emit('update:centerCode', val)
            }
        },
        listShow() {
            const showType = this.showType;
            if (showType === 'tab') {
                return true
            } else {
                return false
            }
        },
        filterShow() {
            const showType = this.showType;
            if (showType === 'filter') {
                return true
            } else {
                return false
            }
        },
    },
    filters: {},
    methods: {
        _clickInput() {
            if (this.showType === '') {
                this.showType = 'tab'
            }
        },
        _clickReserveWrapper(e) {
            if (!!this.$refs.costAttributionList.contains(e.target)) return
            this.showType = ''
        },
        _getCostCenterList(params) {
            return new Promise(resolve => {
                let apiList = [];
                apiList.push(consumer_admin_flight_generate_getCostCenterList(params));
                apiList.push(consumer_admin_flight_generate_getDepartmentList(params));
                Promise.all(handlePromise(apiList)).then(res => {
                    if (res[0] && res[0].datas) {
                        let allCostList = res[0].datas;
                        allCostList.forEach(value => {
                            value.check = false;
                            value.type = 2;
                        });
                        this.costList = JSON.parse(JSON.stringify(allCostList));
                        this.allCostList = this.allCostList.concat(allCostList);
                    }
                    if (res[1] && res[1].datas) {
                        let cascadeList = res[1].datas.departmentListResults ? res[1].datas.departmentListResults : [];
                        let item = {
                            childrenList: null,
                            deptId: -1,
                            deptLevel: 1,
                            deptName: "各自所在部门",
                            deptStatus: 1,
                            parentId: 0,
                            strength: 1,
                            userId: null,
                            userName: "",
                        };
                        cascadeList.unshift(item);
                        this.cascadeList = this._initTreeData(cascadeList)
                    }
                    this.deferInitCompleted.resolve()
                    resolve();
                })
            })
        },
        _initTreeData(list) {
            // 这里一定要转化，要不然他们的值监听不到变化
            let tempData = JSON.parse(JSON.stringify(list));
            let reduceDataFunc = (data, level) => {
                data.map((m, i) => {
                    m.isExpand = false; // 是否展开
                    m.check = false;
                    m.indeterminate = false;
                    m.level = level;
                    m.bLeft = (level - 1) * 15;
                    m.label = m.name;
                    m.value = m.deptId;
                    m.type = 1;
                    m.children = m.childrenList;
                    delete m.childrenList;
                    this.allCostList.push(m);
                    if (m.children && m.children.length > 0) {
                        reduceDataFunc(m.children, level + 1)
                    }
                })
            };
            reduceDataFunc(tempData, 1);
            return tempData
        },
        _handlerExpand(val) {
            val.isExpand = !val.isExpand
        },
        _toSelectRadio(val, close) {
            let cascadeList = JSON.parse(JSON.stringify(this.cascadeList))
            let childrenAllChange = function (list, item) {
                list.forEach(value => {
                    if (item.deptId === value.deptId) {
                        value.check = true
                    } else {
                        value.check = false
                    }
                    if (value.children && value.children.length > 0) {
                        childrenAllChange(value.children, item)
                    }
                })
            };
            childrenAllChange(cascadeList, val);
            this.cascadeList = cascadeList;
            this.allCostList.forEach(value => {
                if (value.deptId === val.deptId) {
                    value.check = true
                } else {
                    value.check = false
                }
            });
            this.costList.forEach(value => {
                value.check = false
            });
            this.currentFeeName = val.deptName;
            this.currentCenterCode = val.centerCode;
            this.currentFeeId = val.deptId;
            this.currentFeeType = parseInt(this.activeName);
            this.showType = '';
            this.$nextTick(() => {
                this.$emit('toSelect', val)
            })
        },
        _getActive(item, close) {
            this.costList.forEach(value => {
                if (value.id === item.id) {
                    value.check = true
                } else {
                    value.check = false
                }
            });
            // 清除部门的选中
            let childrenAllClean = function (list) {
                list.forEach(value => {
                    value.check = false;
                    if (value.children && value.children.length > 0) {
                        childrenAllClean(value.children)
                    }
                })
            };
            childrenAllClean(this.cascadeList);
            this.allCostList.forEach(value => {
                if (value.id === item.id) {
                    value.check = true
                } else {
                    value.check = false
                }
            });
            this.currentFeeName = item.centerName;
            this.currentCenterCode = item.centerCode;
            this.currentFeeId = item.id;
            this.currentFeeType = parseInt(this.activeName);
            if (close) {
                this.showType = '';
            }
            this.$nextTick(() => {
                this.$emit('toSelect', item)
            })
        },
        _getFilterActive(item, close = true) {
            this.activeName = item.type.toString();
            if (item.type === 1) {
                this._toSelectRadio(item, close)
            } else {
                this._getActive(item, close)
            }
            // this.currentFeeType = item.type
        },
        _keyupValue(e) {
            if (this.input.length > 0) {
                // 有输入
                this.showType = 'filter';
                // 过滤
                let filterCostList = [];
                this.allCostList.forEach(value => {
                    if (value.deptName && value.deptName.indexOf(this.input) > -1) {
                        filterCostList.push(value)
                    }
                    if (value.centerName && value.centerName.indexOf(this.input) > -1) {
                        filterCostList.push(value)
                    }
                })
                this.filterCostList = filterCostList;
            } else {
                // 没输入
                this.showType = 'tab'
            }
            // 监听操作键
            if ([`ArrowDown`, `ArrowUp`, `ArrowLeft`, `ArrowRight`, `Enter`, `NumpadEnter`, `Tab`].indexOf(e.code) === -1) {
                this.selectIndex = 0;
                return
            }
            if ((e.code === 'ArrowDown' || e.code === 'ArrowRight') && this.filterShow) {
                this.selectIndex++;
                if (this.selectIndex >= this.filterCostList.length) {
                    this.selectIndex = 0
                }
            }
            if ((e.code === 'ArrowUp' || e.code === 'ArrowLeft') && this.filterShow) {
                this.selectIndex--;
                if (this.selectIndex < 0) {
                    this.selectIndex = this.filterCostList.length - 1
                }
            }
            if ((e.code === 'Enter' || e.code === 'NumpadEnter') && this.filterShow) {
                const item = this.filterCostList[this.selectIndex];
                this._getFilterActive(item)
            }
        },
        _mouseenterToGetFilterActive(item, index, e) {
            console.log(item, e);
            // this.selectIndex = index;
            // this._getFilterActive(item, false);
        },
        _blurInput() {

        },
        _clearInput() {
            this.currentFeeName = '';
            this.currentCenterCode = '';
            this.currentFeeId = '';
        },
        /**
         * obj :
         * {
         *     companyId: 公司ID， string
         * }
         * */
        async init(params) {
            return new Promise(resolve => {
                this._getCostCenterList(params).then(()=>{
                    resolve();
                });
            })

        },
    }
}

// consumer_sys_sms_insuranceAccreditSmsImport 批量发送保险授权短信导入
const __request = require(`./__request/__request_contentType_json`);
const consumer_sys_sms_insuranceAccreditSmsImport = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/sys/sms/insuranceAccreditSmsImport',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_sys_sms_insuranceAccreditSmsImport;
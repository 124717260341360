// 【338】admin-线下单退票编辑
// POST /consumer/admin/flight/offlineRefund/offlineRefundEdit
// 接口ID：33720143
// 接口地址：https://www.apifox.cn/web/project/458418/apis/api-33720143
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_flight_offlineRefund_offlineRefundEdit = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/flight/offlineRefund/offlineRefundEdit',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_flight_offlineRefund_offlineRefundEdit;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "VoyageInfo",
  data() {
    return {
      detailList: []
    };
  },
  props: {
    detail: [Object, Array],
    type: {
      type: Number,
      default: 1, // 1是改签， 2是退票
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit("getSegment", val);
    },
    checkboxInit(row) {
      if ((this.type === 1 && row.changeEnable === 1) || (this.type === 2 && row.refundEnable === 1)) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    // console.log(this.detail);
    this.detail instanceof Array
      ? (this.detailList = this.detail)
      : (this.detailList = [this.detail]);
  },
  created() {},
  activated() {
    this.$refs['refundTable'].clearSelection()
  },
  watch: {},
  computed: {}
};

import consumer_admin_car_order_adminCarOrderBill
    from '@/lib/data-service/haolv-default/consumer_admin_car_order_adminCarOrderBill'
import consumer_admin_car_order_adminCarOrderBillExcel
    from '@/lib/data-service/haolv-default/consumer_admin_car_order_adminCarOrderBillExcel'
import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'

export default {
    data() {
        return {
            loading: false,
            searchForm: {
                orderNo: '',//订单号
                belongCompany: '',//所属企业
                startDate: '',//开始日期
                endDate: '',//结束日期
                orderTimeArr: '',//开始-结束日期
                currentPage: 1,//查询页码
                pageSize: 10,//查询条数
                total: 0,
            },
            list: []
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    activated() {
        this.reset()
    },
    methods: {
        //搜索
        search() {
            this.searchForm.currentPage = 1
            this.searchForm.orderStatus = this.activeName === '0' ? '' : this.activeName
            this.getList()
        },
        //重置
        reset() {
            let searchForm = {
                orderNo: '',//订单号
                belongCompany: '',//所属企业
                startDate: '',//开始日期
                endDate: '',//结束日期
                orderTimeArr: '',//开始-结束日期
                currentPage: 1,//查询页码
                pageSize: 10,//查询条数
                total: 0,
            }
            this.searchForm = searchForm
            this.search()
        },
        //改变搜索条件
        handleClick(val) {
            this.search()
        },
        //搜索日期改变时
        changeOrderTimeArr(val) {
            if (val === '' || val === null) {
                this.searchForm.startDate = '';
                this.searchForm.endDate = '';
            } else {
                this.searchForm.startDate = val[0] + ' 00:00:00';
                this.searchForm.endDate = val[1] + ' 24:00:00';
            }
        },
        //获取列表
        getList() {
            this.loading = true
            this.list = []
            consumer_admin_car_order_adminCarOrderBill(this.searchForm).then(res => {
                this.loading = false
                this.list = res.datas.carOrderBillResultList.list
                this.searchForm.total = res.datas.carOrderBillResultList.totalCount
            }).catch(() => {
                this.loading = false
                this.list = []
            })
        },
        //导出账单
        async exportBills() {
            let res = await consumer_admin_car_order_adminCarOrderBillExcel(this.searchForm);
            window.open(res.datas.url);
        }
    }
}
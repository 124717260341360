import moment from 'moment';

import consumer_admin_trains_order_orderRefundList
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_orderRefundList';// 火车票退票订单列表
import consumer_admin_trains_order_trainsOrdersource
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsOrdersource';// 订单来源
import consumer_admin_trains_order_orderRefundStatusNumber
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_orderRefundStatusNumber';// 火车票订单状态数量
import consumer_admin_trains_order_trainsCancelrefund
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsCancelrefund';// 取消退票

import SearchBox from '@/component/search-box/1.0.1/index.vue'
import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'

// 消息模板
import consumer_admin_trains_refund_copyMessageTemplate from '@/lib/data-service/haolv-default/consumer_admin_trains_refund_copyMessageTemplate'
import CopyReportPopup from '@/component/copyReportPopup/import_latest_version_module'

export default {
  data() {
    return {
      loading: false,
      copyDialog: false,
      copyLoading: false,
      copyTextarea: '',
      params: {
        // 搜索参数
        query: {
          orderNo: '',//原订单号
          refundNo: '',//退票单号
          fromStationName: '',//出发站
          toStationName: '',//到达站
          gmtStart: '',//退票开始时间
          gmtEnd: '',//退票结束时间
          trainNo: '',//车次
          passengerNames: '',//乘车人
          companyName: '',//所属企业
          realName: '',//买家ID
          ticketChannel: '',//订单来源
          operatorName: '',//操作客服
          buyerContactName: '',//预订人
          startTime: '',//出行时间
          refundStatus: '',//状态
        },

        // 分页参数
        currentPage: 1,
        pageSize: 10,
        totalCount: 0
      },
      refundStatus: '0',//状态
      refundDate: [],
      tableList: [],
      allocation: {
        ticketChannelList: [],
        orderStatusList: [
          {value: '0', label: '全部'},
          {value: '1', label: '退票处理中'},
          {value: '3', label: '退票成功'},
          {value: '4', label: '退票失败'},
          {value: '5', label: '已取消'}
        ],
      },
      pendingQuantity: 0
    }
  },
  components: {
    SearchBox,
    RouterLinkButton,
    Pagination,
    CopyReportPopup
  },
  methods: {
    //初始化
    async init() {
      this.refundStatus = '0';
      await this.getList();
      await this.getTrainsOrdersource();
      await this.getOrderStatusNumber();
    },

    //获取订单来源枚举值
    async getTrainsOrdersource() {
      let res = await consumer_admin_trains_order_trainsOrdersource();
      let ticketChannelList = res.datas
      ticketChannelList.unshift({code: '', msg: '全部'},);
      this.allocation.ticketChannelList = ticketChannelList;
    },

    //获取不同状态订单的数量
    async getOrderStatusNumber() {
      let res = await consumer_admin_trains_order_orderRefundStatusNumber(this.params);
      this.pendingQuantity = res.datas.orderQuantity[0] ? res.datas.orderQuantity[0].orderQuantity : 0;
    },

    //获取列表数据
    getList() {
      this.loading = true;
      this.params.query.refundStatus = this.refundStatus === '0' ? '' : this.refundStatus;
      consumer_admin_trains_order_orderRefundList(this.params).then(res => {
        this.tableList = res.datas.records;
        this.tableList.forEach(item => {
          item.isOffline = false;
          if (item.ticketChannel === 1 || item.ticketChannel === 2 || item.ticketChannel === 3) {
            item.isOffline = true;
          }
        })
        this.params.totalCount = res.datas.total;
      }).finally(() => {
        this.loading = false;
      })
    },

    //搜索
    async onSearch() {
      this.params.currentPage = 1;
      await this.getList();
      await this.getOrderStatusNumber();
    },

    //重置
    onReset() {
      this.params = {
        // 搜索参数
        query: {
          orderNo: '',//原订单号
          refundNo: '',//退票单号
          fromStationName: '',//出发站
          toStationName: '',//到达站
          gmtStart: '',//退票开始时间
          gmtEnd: '',//退票结束时间
          trainNo: '',//车次
          passengerNames: '',//乘车人
          companyName: '',//所属企业
          realName: '',//买家ID
          ticketChannel: '',//订单来源
          operatorName: '',//操作客服
          buyerContactName: '',//预订人
          startTime: '',//出行时间
          refundStatus: '',//状态
        },

        // 分页参数
        currentPage: 1,
        pageSize: 10,
        totalCount: 0
      };
      this.refundDate = [];
      this.onSearch();
    },

    //改变退票时间
    changeRefundDate(val) {
      this.params.query.gmtStart = val ? val[0] + ' 00:00:00' : '';
      this.params.query.gmtEnd = val ? val[1] + ' 23:59:59' : '';
    },

    //跳转至详情页
    toDetail(order) {
      return {
        name: 'admin-train-refund-detail',
        query: {
          orderNo: order.orderNo,
          refundNo: order.refundNo,
        },
      }
    },

    //跳转至编辑页
    toEdit(order) {
      return {
        name: 'admin-train-refund-handle',
        query: {
          orderNo: order.orderNo,
          refundNo: order.refundNo,
          type: 'edit'
        },
      }
    },

    //取消退票
    toCancelRefund(order) {
      this.$confirm('此操作将取消该订单的退票, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return consumer_admin_trains_order_trainsCancelrefund({refundNo: order.refundNo})
      }).then(() => {
        this.$message.success("取消退票成功");
        this.getList();
      })
    },

    //跳转至去确定
    toConfirm(order) {
      return {
        name: 'admin-train-refund-confirm',
        query: {
          orderNo: order.orderNo,
          refundNo: order.refundNo
        },
      }
    },
    //消息模板
    handleMessage(val) {
      this.copyDialog = true;
      this.copyLoading = true;
      this.copyTextarea = '';
      // 调接口
      consumer_admin_trains_refund_copyMessageTemplate({orderNo: val.refundNo}).then(res => {
        const datas = res.datas;
        let message = '';
        message += `列车退票成功：\n`;
        let transDay = 1 === datas.transDay ? '次日' : '';
        message = message + datas.startDate + ' (' + datas.startWeek + ') ' + datas.trainNo + '次' + datas.fromStationName + ' (' + datas.startTime + '开) —' + datas.toStationName + ' (' + transDay + datas.arriveTime + '到)' + '\n';
        if ( 0 < datas.adultPsgList.length ) {
          message += '成人票 ' + datas.seatName + '\n';
          datas.adultPsgList.forEach((value, index) => {
            message += `${'乘车人' + (index + 1) + '：'} ${value.psgName} ${value.seatInfos}\n`;
          });
        }
        if ( 0 < datas.childPsgList.length ) {
          message += '儿童票 ' + datas.seatName + '\n';
          datas.childPsgList.forEach((value, index) => {
            message += `${'乘车人' + (index + 1) + '：'} ${value.psgName} ${value.seatInfos}\n`;
          });
        }
        message += '实际退票费：' + datas.realRefAmount + '\n';
        message += `如需报销票凭证可凭本人证件到乘车站点领取。\n`;
        this.copyTextarea = message
      }).finally(() => {
        this.copyLoading = false;
      })
    },

    handleSizeChange(size) {
      this.params.currentPage = 1;
      this.params.pageSize = size;
      this.getList()
    },
    handleCurrentChange(page) {
      this.params.currentPage = page;
      this.getList()
    },
    handleClick() {
      this.onSearch();
    },
  },
  activated() {
    this.init();
  },
  filters: {
    filter_date(val) {
      return val ? moment(val).format("YYYY-MM-DD HH:mm") : val;
    }
  }
}

export default {
  customerInfo: '客户基础信息',
  hotelGenerateOrderHotelMsg: '酒店信息',
  hotelGenerateOrderPeoples: '入住人信息',
  hotelGenerateOrderPrice: '采购信息',
  reportConfiguration: '间夜信息',
  refundRule: '退改规则',

  companyId: '所属企业',
  contactName: '联系人',
  contactMobile: '联系电话',
  evectionType: '出行类型',
  payType: '支付方式',
  hotelName: '酒店名称',
  hotelAddr: '酒店地址',
  hotelRoomTypeName: '房型',
  breakfast: '早餐',
  roomNum: '房间数',
  stayDateArr: '入离日期',
  checkInDate: '入住日期',
  checkOutDate: '离店日期',
  hotelPhone: '酒店电话',
  groupName: '协议酒店名称',
  name: '入住人姓名',
  certificatesType: '证件类型',
  certificatesNumber: '证件号',
  countryCode: '国籍',
  phone: '手机号码',
  supplierPrice: '采购总价',
  orderAmount: '销售总价',
  ifInvoice: '发票来源',
  invoiceMode: '发票类型',
  invoicePrice: '开票金额',
  orderFromType: '采购渠道',
  supplierOrderNo: '采购渠道订单号',
  bookingDate: '预定时间',
  ticketTime: '采购间夜价',
  nightlyRateList: '销售间夜价',
  refundDate: '免费取消的截止日期',
  orderFromTypeText: '采购渠道',
}

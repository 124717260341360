import consumer_flight_report_order_ticketChannel from '@/lib/data-service/haolv-default/consumer_flight_report_order_ticketChannel'
import consumer_flight_report_order_airPriceSetType from '@/lib/data-service/haolv-default/consumer_flight_report_order_airPriceSetType'
import consumer_admin_flight_generate_orderSource from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_orderSource'
import consumer_flight_report_order_ticketReport from '@/lib/data-service/haolv-default/consumer_flight_report_order_ticketReport'
import consumer_flight_report_order_ticketReportExcel from '@/lib/data-service/haolv-default/consumer_flight_report_order_ticketReportExcel'
import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'

export default {
    data() {
        return {
            loading: false,
            exportLoading: false,
            ticketChannelList: [],
            evectionTypeList: [
                {
                    value: 0,
                    label: '全部'
                },
                {
                    value: 2,
                    label: '因公出差'
                },
                {
                    value: 1,
                    label: '因私出行'
                },
            ],
            ticketTypeList: [],
            orderSourceList: [{//订单来源枚举值
                code: 0,
                msg: '全部'
            }],
            searchForm: {
                orderNo: '',//订单号
                ticketNumber: '',//票号
                psgName: '',//乘机人
                abbreviation: '',//所属企业
                flightNo: '',//航班号
                orderTimeStart: '',//下单时间or开始时间
                orderTimeEnd: '',//下单时间or结束时间
                ticketTimeStart: '',//出票时间or开始时间
                ticketTimeEnd: '',//出票时间or结束时间
                ticketChannel: '',//出票渠道
                evectionType: 0,//因公/因私
                ticketType: '',//订单类型
                orderSource: 0,//订单来源
                currentPage: 1,//查询页码
                pageSize: 10,//查询条数
                flightTimeStart: '',
                flightTimeEnd: '',
                total: 0,
                tagId: '',
                sourceType: 0,
                airlineCode: '',
            },
            sourceTypeList: [
                // 机票类型：0.全部, 1.国内，2.国际
                {value: 0, label: '全部'},
                {value: 1, label: '国内'},
                {value: 2, label: '国际'},
            ],
            list: [],
            orderTimeArr: [],//下单时间
            ticketTime: [],//出票时间
            flightTimeArr: [],
            tagNamesList: [
                {value: '', label: '全部'},
                {value: 1, label: 'VP'},
            ],
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    activated() {
        this.init()
    },
    methods: {
        //初始化
        async init() {
            await this.getTicketChannelList();
            await this.getAirPriceSetType();
            await this.getOrderSource();
            this.search();
        },
        //搜索
        search() {
            this.searchForm.currentPage = 1
            this.searchForm.orderStatus = this.activeName === '0' ? '' : this.activeName
            this.getList()
        },
        //重置
        reset() {
            let searchForm = {
                orderNo: '',//订单号
                ticketNumber: '',//票号
                psgName: '',//乘机人
                abbreviation: '',//所属企业
                flightNo: '',//航班号
                orderTimeStart: '',//下单时间or开始时间
                orderTimeEnd: '',//下单时间or结束时间
                ticketTimeStart: '',//出票时间or开始时间
                ticketTimeEnd: '',//出票时间or结束时间
                ticketChannel: '',//出票渠道
                evectionType: 0,//因公/因私
                ticketType: '',//订单类型
                orderSource: 0,//订单来源
                currentPage: 1,//查询页码
                pageSize: 10,//查询条数
                flightTimeStart: '',
                flightTimeEnd: '',
                total: 0,
                tagId: '',
                sourceType: 0,
                airlineCode: '',
            };
            this.searchForm = searchForm;
            this.orderTimeArr = [];//下单时间
            this.ticketTime = [];//下单时间
            this.search()
        },
        //改变搜索条件
        handleClick() {
            this.search()
        },
        //搜索下单时间改变时
        changeOrderTimeArr(val) {
            if (val === '' || val === null) {
                this.searchForm.orderTimeStart = ''
                this.searchForm.orderTimeEnd = ''
            } else {
                this.searchForm.orderTimeStart = val[0] + ' 00:00:00'
                this.searchForm.orderTimeEnd = val[1] + ' 23:59:59'
            }
        },
        //搜索出票时间改变时
        changeTicketTimeArr(val) {
            if (val === '' || val === null) {
                this.searchForm.ticketTimeStart = ''
                this.searchForm.ticketTimeEnd = ''
            } else {
                this.searchForm.ticketTimeStart = val[0] + ' 00:00:00'
                this.searchForm.ticketTimeEnd = val[1] + ' 23:59:59'
            }
        },
        //搜索航班时间改变时
        changeFlightTimeArr(val) {
            if (val === '' || val === null) {
                this.searchForm.flightTimeStart = ''
                this.searchForm.flightTimeEnd = ''
            } else {
                this.searchForm.flightTimeStart = val[0] + ' 00:00:00'
                this.searchForm.flightTimeEnd = val[1] + ' 23:59:59'
            }
        },
        //获取列表
        getList() {
            this.loading = true
            this.list = []
            consumer_flight_report_order_ticketReport(this.searchForm).then(res => {
                this.loading = false
                this.list = res.datas.list
                this.searchForm.total = res.datas.totalCount
            }).catch(() => {
                this.loading = false
                this.list = []
            })
        },
        //获取出票渠道枚举值
        async getTicketChannelList() {
            let res = await consumer_flight_report_order_ticketChannel();
            this.ticketChannelList = res.datas;
        },
        //获取订单类型枚举值
        async getAirPriceSetType() {
            let res = await consumer_flight_report_order_airPriceSetType();
            this.ticketTypeList = res.datas;
        },
        //获取订单来源枚举值
        async getOrderSource() {
            this.orderSourceList = [{
                code: 0,
                msg: '全部'
            }];
            let res = await consumer_admin_flight_generate_orderSource();
            this.orderSourceList = [...this.orderSourceList, ...res.datas];
        },
        //导出账单
        async exportBills() {
            this.exportLoading = true;
            let res = await consumer_flight_report_order_ticketReportExcel(this.searchForm);
            this.exportLoading = false;
            window.open(`${res.msg}`, '_blank');
        }
    }
}

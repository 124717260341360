export default {
  path: '',
  component: () => import(`@/component/admin-layout/index.vue`),
  children: [
    {
      path: '/admin/credit-users-list',
      name: 'admin-credit-users-list',
      component: () => import(/* webpackChunkName: "page-admin-credit-management-credit-users-list" */ `./1.0.2/index.vue`),
      meta: {
        entity_for_history_tag: {
          name: `授信账户列表`,
        },
        entity_list_for_breadcrumb: [
          { name: `授信管理` },
          { name: `授信账户列表` },
        ],
      },
    },
  ],
};

import _ from 'underscore'
import moment from 'moment'
import Decimal from "decimal.js"
import history_tag_api from '@/component/admin-layout/component/history-tag/api'
import OrderLog from '@/component/orderLog/1.0.0/index.vue'

import consumer_admin_hotel_order_queryHotelOrderDetails
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_queryHotelOrderDetails"   // 平台酒店订单详情
import consumer_admin_hotel_order_forHotelOrderDetails
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_forHotelOrderDetails"   // 获取酒店订单明细
import consumer_log_hotelList from "@/lib/data-service/haolv-default/consumer_log_hotelList"   // 获取酒店订单日志
import consumer_admin_hotel_order_refundHotelOrderPartialCancel
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_refundHotelOrderPartialCancel";

export default {
    data() {

        const validateAllSupplierReturnedPrice = (rule, value, callback) => {
            //let v = this.refundForm.refundRoomWhole.supplierReturnedPrice
            if (value === '' || value < 0) {
                callback(new Error('请输入供应商退回金额'))
            } else {
                callback()
            }
        }
        const validateRefundFine = (rule, value, callback) => {
            //let v = this.refundForm.refundRoomWhole.supplierReturnedPrice
            if (value === '' || value < 0) {
                callback(new Error('请输入退订罚金'))
            } else {
                callback()
            }
        }
        const validateSupplierYzlReturnedPrice = (rule, value, callback) => {
            //let v = this.refundForm.refundRoomWhole.supplierReturnedPrice
            if (value === '' || value < 0) {
                callback(new Error('请输入胤之旅退回金额'))
            } else {
                callback()
            }
        }
        const validateAllRefundPrice = (rule, value, callback) => {
            //let v = this.refundForm.refundRoomWhole.supplierReturnedPrice
            if (value === '' || value < 0) {
                callback(new Error('请输入客户端退款金额'))
            } else {
                callback()
            }
        }
        const validateSupplierReturnedPrice = (rule, value, callback) => {
            if (value === '' || value < 0) {
                callback(new Error('请输入供应商退回金额'))
            } else {
                callback()
            }
        }
        const validateRefundPrice = (rule, value, callback) => {
            if (value === '' || value < 0) {
                callback(new Error('请输入退回金额'))
            } else {
                callback()
            }
        }
        return {
            detailLoading: false,
            loading: false,
            activeName: 'detail',
            orderDetail: {},
            orderPersonList: [],
            costDetail: {},
            refundRule: [],

            moment: moment,

            refundRuleTypeObj: {
                1: '不可退',
                2: '限时取消',
            },
            deductTypeObj: {
                0: '扣首晚房费',
                1: '扣全额',
            },
            orderRooms: [],
            refundForm: {
                orderNo: '',
                isAllRefund: true,
                refundRoomWhole: {//整单退时的信息
                    refundFine: '',
                    supplierReturnedPrice: '',
                    supplierYzlReturnedPrice: '',
                    refundPrice: '',
                    failedMsg: ''
                },
                refundRooms: []//部分退的信息
            },
            wholeRules: {
                refundDate: [
                    {required: true, message: '请选择退订日期', trigger: 'blur'}
                ],
                refundFine: [
                    {required: true, validator: this.validateRefundFine, trigger: 'blur'}
                ],
                supplierReturnedPrice: [
                    {required: true, validator: validateSupplierReturnedPrice, trigger: 'blur'}
                ],
                refundPrice: [
                    {required: true, validator: validateRefundPrice, trigger: 'blur'}
                ],
                supplierYzlReturnedPrice: [
                    {required: true, validator: validateSupplierYzlReturnedPrice, trigger: 'blur'}
                ],
                allRefundPrice: [
                    {required: true, validator: validateAllRefundPrice, trigger: 'blur'}
                ],
            },
            rules: {
                refundDate: [
                    {required: true, message: '请选择退订日期', trigger: 'blur'}
                ],
                refundFine: [
                    {required: true, validator: validateRefundFine, trigger: 'blur'}
                ],
                supplierReturnedPrice: [
                    {required: true, validator: validateSupplierReturnedPrice, trigger: 'blur'}
                ],
                refundPrice: [
                    {required: true, validator: validateRefundPrice, trigger: 'blur'}
                ],
                supplierYzlReturnedPrice: [
                    {required: true, validator: validateSupplierYzlReturnedPrice, trigger: 'blur'}
                ],
                allRefundPrice: [
                    {required: true, validator: validateAllRefundPrice, trigger: 'blur'}
                ],
            },
            multipleSelection: [],
        }
    },
    components: {
        OrderLog
    },
    activated() {
        this.refundForm.orderNo = this.$route.query.orderNo;
        this.getHotelOrderDetails()
        this.getOrderLog()
    },
    filters: {
        filterMoney(val) {
            if (val || val === 0) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return '0.00'
            }
        }
    },
    methods: {
        // 平台酒店订单详情
        async getHotelOrderDetails() {
            try {
                this.detailLoading = true
                const res = await consumer_admin_hotel_order_queryHotelOrderDetails({
                    orderNo: this.$route.query.orderNo
                })
                this.orderDetail = res.orderInterimResult
                this.orderPersonList = res.hotelOrderPersonList
                this.costDetail = res.hotelCostInfoResponse
                this.orderDetail.hotelGroup = res.hotelGroup;
                this.orderDetail.ifAgreement = res.ifAgreement;
                this.orderDetail.cardNumber = res.cardNumber;
                this.orderDetail.agreementHotelText = res.agreementHotelText;
                this.refundRule = res.orderInterimResult.refund?[res.orderInterimResult.refund]:[];
                let orderRooms = this.orderDetail.orderRooms;
                this.refundForm.isAllRefund = this.orderDetail.partialCancelMsg === 0 ? true : false;

                let supplierReturnedPrice = 0;
                let supplierYzlReturnedPrice = 0;
                let refundPrice = 0;
                this.costDetail.roomViewList.forEach(value=>{
                    value.roomPriceList.forEach(value1 => {
                        supplierReturnedPrice = supplierReturnedPrice + value1.supplierPrice;
                        supplierYzlReturnedPrice = supplierYzlReturnedPrice + (value1.supplierPriceYzl || 0);
                        refundPrice = refundPrice + value1.platformPrice;
                    })
                })
                this.refundForm.refundRoomWhole = {
                    refundFine: '0',
                    supplierReturnedPrice: supplierReturnedPrice,
                    oldSupplierReturnedPrice: supplierReturnedPrice,
                    supplierYzlReturnedPrice: supplierYzlReturnedPrice,
                    oldSupplierYzlReturnedPrice: supplierYzlReturnedPrice,
                    refundPrice: refundPrice,
                    oldRefundPrice: refundPrice,
                    failedMsg: ''
                }

                orderRooms.forEach((item, index) => {
                    item.index = index;
                    item.isChosen = false;
                    item.setDateRange = {
                        disabledDate: (time) => this.refundDisabledDate(time, item),
                        onPick: ({maxDate, minDate}) => this.refundOnPick({maxDate, minDate}, item)
                    }
                    item.timeOptionRange = null;
                    item.refundDate = null;
                    item.refundFine = 0;
                    item.supplierReturnedPrice = 0;
                    item.supplierYzlReturnedPrice = 0;
                    item.refundPrice = 0;
                })
                this.orderRooms = JSON.parse(JSON.stringify(orderRooms));
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "获取酒店订单详情失败！"
                })
            } finally {
                this.detailLoading = false
            }
        },

        returnPickerOption(item) {
          return {
              disabledDate: (time) => this.refundDisabledDate(time, item),
              onPick: ({maxDate, minDate}) => this.refundOnPick({maxDate, minDate}, item)
          }
        },
        // 获取酒店订单明细
        async getSupplierDetail() {
            try {
                this.detailLoading = true
                const res = await consumer_admin_hotel_order_forHotelOrderDetails({
                    orderNo: this.orderDetail.thirdpartyOrderNo
                })
                this.orderDetail.orderFromTypeText = res.datas.supplierName
                this.orderDetail.supplierOrderNo = res.datas.supplierOrder
                this.orderDetail.supplierPrice = res.datas.supplierPrice
                this.orderDetail.settlementText = res.datas.settlement
            } catch (error) {
                this.$message({
                    type: "error",
                    message: "获取酒店订单明细失败！"
                })
            } finally {
                this.detailLoading = false
            }
        },
        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    return consumer_log_hotelList({
                        orderNo: this.$route.query.orderNo
                    }).then(res => {
                        // 组装组件需要的list
                        const list = _.chain(res.datas)
                            .map(function (m) {
                                // 替换名字
                                const o = {
                                    operator: m.operator,
                                    date: m.operatorTime,
                                    type: m.logSource,
                                    detail: m.logContents
                                }
                                return o
                            }).value()
                        const d = {
                            entityList: list
                        }
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },
        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
            switch (val) {
                case (1):
                    return 'type2'
                case (2):
                    return 'type1'
                default:
                    return ''
            }
        },
        // 返回
        handleBackClick() {
            history_tag_api.remove_tag_by_route({
                route: this.$route
            })
            this.$router.replace({
                name: "admin-hotel-order-sold"
            })
        },
        //修改供应商退回金额
        supplierReturnedPriceChange(row) {
            row.supplierReturnedFine = row.index;
            if (this.refundForm.refundRooms) {
                let item = this.refundForm.refundRooms.find(item => {
                    return item.index === row.index
                });
                if (item) {
                    item.supplierReturnedFine = row.index;
                }
            }
        },
        //修改退回金额
        refundPriceChange(row) {
            row.refundFine = row.index;
            if (this.refundForm.refundRooms) {
                let item = this.refundForm.refundRooms.find(item => {
                    return item.index === row.index
                });
                if (item) {
                    item.refundFine = row.index;
                }
            }
        },
        //设置不可选的退订时间
        refundDisabledDate(time, row) {
            // 获取选中时间
            let timeOptionRange = row.timeOptionRange;
            let checkInDate = moment(row.checkInDate).format('X') * 1000;
            let checkOutDate = moment(row.checkOutDate).format('X') * 1000;
            if (timeOptionRange) {
                //如果有选中时间
                return time.getTime() === timeOptionRange.getTime() || time.getTime() < checkInDate || time.getTime() > checkOutDate;
            } else {
                //如果没有选中时间（初始化状态
                return time.getTime() < checkInDate || time.getTime() > checkOutDate;
            }
        },
        //记录退订日期范围是否只选了一个日期
        refundOnPick({maxDate, minDate}, row) {
            // 当选中了第一个日期还没选第二个
            // 只选中一个的时候自动赋值给minDate，当选中第二个后组件自动匹配，将大小日期分别赋值给maxDate、minDate
            if (minDate && !maxDate) {
                row.timeOptionRange = minDate;
            }
            // 如果有maxDate属性，表示2个日期都选择了，则重置timeOptionRange
            if (maxDate) {
                row.timeOptionRange = null;
            }
        },
        //退订日期控件失焦
        blurRefundDate(row) {
            row.timeOptionRange = null
        },
        //改变退订时间
        changeRefundDate(row, index) {
            if (row.refundDate === '' || row.refundDate === null) {
                row.cancelCheckinDate = ''
                row.cancelCheckoutDate = ''
            } else {
                if (row.refundDate[0] === row.checkInDate || row.refundDate[1] === row.checkOutDate) {
                    row.cancelCheckinDate = row.refundDate[0]
                    row.cancelCheckoutDate = row.refundDate[1]
                } else {
                    row.refundDate = []
                    row.cancelCheckinDate = ''
                    row.cancelCheckoutDate = ''
                    this.$alert('退订日期需满足开始日期为入住日期或者结束日期为离店日期。', '温馨提示', {
                        confirmButtonText: '确定'
                    });
                }
            }

            this.changeRefundPrice(row, index);
        },
        changeRefundPrice(row, index) {
            let supplierReturnedPrice = 0;
            // 供应商退回金额=退订间夜供应商采购价（多个间夜则累加）-退订罚金
            let yinzhilvReturnedPrice = 0;
            // 胤之旅退回金额=退订间夜胤之旅采购价（多个间夜则累加）-退订罚金
            let platformReturnedPrice = 0;
            // 客户端退款金额=退订间夜价格（不含服务费税费，多个间夜则累加）-退订罚金
            let checkDateList = []; // 先获取间夜数据
            if (row.refundDate.length > 0) {
                let checkInDate = row.refundDate[0];
                let checkOutDate = row.refundDate[1];
                while (this.$moment(checkOutDate).diff(this.$moment(checkInDate)) > 0) {
                    checkDateList.push(checkInDate);
                    // 自增
                    checkInDate = this.$moment(new Date(checkInDate)).add(1, 'day').format('YYYY-MM-DD')
                }
                console.log(checkDateList);

                this.costDetail.roomViewList.forEach(value=>{
                    if (checkDateList.indexOf(value.date) > -1) {
                        supplierReturnedPrice = supplierReturnedPrice + value.roomPriceList[index].supplierPrice;
                        yinzhilvReturnedPrice = yinzhilvReturnedPrice + (value.roomPriceList[index].supplierPriceYzl || 0);
                        platformReturnedPrice = platformReturnedPrice + value.roomPriceList[index].platformPrice;
                    }
                })
            }

            supplierReturnedPrice = supplierReturnedPrice - Number(row.refundFine);
            if (yinzhilvReturnedPrice > 0) {
                yinzhilvReturnedPrice = yinzhilvReturnedPrice - Number(row.refundFine);
            }
            platformReturnedPrice = platformReturnedPrice - Number(row.refundFine);
            console.log(supplierReturnedPrice);
            row.supplierReturnedPrice = supplierReturnedPrice;
            row.supplierYzlReturnedPrice = yinzhilvReturnedPrice;
            row.refundPrice = platformReturnedPrice;
            this.$set(this.orderRooms, index, row);
            this.$nextTick(()=> {
                this.$refs['refundForm' + index].clearValidate();
            });
        },
        changeAllRefundDate(val) {
            // https://www.cnblogs.com/caihongmin/p/17986466
            let supplierReturnedPrice = Decimal(this.refundForm.refundRoomWhole.oldSupplierReturnedPrice).sub(Decimal(Number(val))).toNumber();
            let supplierYzlReturnedPrice = 0;
            if (this.refundForm.refundRoomWhole.oldSupplierYzlReturnedPrice > 0) {
                supplierYzlReturnedPrice = Decimal(this.refundForm.refundRoomWhole.oldSupplierYzlReturnedPrice).sub(Decimal(Number(val))).toNumber();
            }
            let refundPrice = Decimal(this.refundForm.refundRoomWhole.oldRefundPrice).sub(Decimal(Number(val))).toNumber();
            let refundRoomWhole = this.refundForm.refundRoomWhole;
            refundRoomWhole.supplierReturnedPrice = supplierReturnedPrice;
            refundRoomWhole.supplierYzlReturnedPrice = supplierYzlReturnedPrice;
            refundRoomWhole.refundPrice = refundPrice;
            this.$set(this.refundForm, 'refundRoomWhole', refundRoomWhole);
        },
        // 改变需要退订的房间
        handleSelectionChange(rowList) {
            this.multipleSelection = rowList;
            let indexList = [];
            rowList.forEach(row => {
                indexList.push(row.index);
            })
            this.orderRooms.forEach((item, index) => {
                if (_.indexOf(indexList, index) > -1) {
                    item.isChosen = true;
                } else {
                    item.isChosen = false;
                    this.$refs['refundForm' + index].clearValidate();
                }
                this.$set(this.orderRooms, index, item);
            })

            // this.orderDetail.orderRooms = orderRooms;
            // this.orderRooms = orderRooms;
            this.refundForm.refundRooms = rowList;
            // this.$forceUpdate();
        },
        check(val){
            this.$forceUpdate(); //强制刷新
        },
        // 订单状态
        getOrderStatusToClass(val) {
            switch (val) {
                case (23):
                    return 'status2'
                case (22):
                    return 'status1'
                default:
                    return 'status3'
            }
        },
        //是否整单退
        onChangeIsAllRefund() {
            this.onSelectable();
            if (this.$refs.refundAllForm) {
                this.$refs.refundAllForm.clearValidate();
            }

            this.orderDetail.orderRooms.forEach((item, index) => {

                if (this.$refs[`refundForm${index}`]) {
                    this.$refs[`refundForm${index}`].clearValidate();
                }
            })
        },
        //是否可选房间
        onSelectable() {
            if (!this.refundForm.isAllRefund) {
                return true;
            }
        },
        //回到顶部
        toTop() {
            let box = document.getElementsByClassName('row-router-view');
            box[0].scrollTo({
                top: 0,
                behavior: "smooth",
            })
        },
        validateRefundFine(rule, value, callback) {
            let v = this.refundForm.refundRoomWhole.oldSupplierReturnedPrice
            if (value === '' || value < 0) {
                callback(new Error('请输入退订罚金'))
            } else if (value > v) {
                callback(new Error('退订罚金需少于供应商退回金额'))
            }else {
                callback()
            }
        },
        //退订
        handleOfflineRefundClick() {
            let isPass = true;
            let params = {};
            if (this.refundForm.isAllRefund) {
                this.$refs.refundAllForm.validate(valid => {
                    if (!valid) {
                        isPass = false;
                    } else {
                        let {
                            orderNo,
                            refundRoomWhole
                        } = this.refundForm;
                        params = {
                            orderNo,
                            refundRoomWhole
                        }
                    }
                })
            } else {
                if (!this.refundForm.refundRooms || this.refundForm.refundRooms.length === 0) {
                    isPass = false;
                    this.toTop();
                    this.$alert('请选择需要退订的房间。', '温馨提示', {
                        confirmButtonText: '确定'
                    });
                    return;
                }
                this.orderDetail.orderRooms.forEach((item, index) => {
                    this.$refs['refundForm' + index].validate(valid => {
                        if (!valid) {
                            isPass = false;
                        } else {
                            let {
                                orderNo,
                                refundRooms
                            } = this.refundForm;
                            params = {
                                orderNo,
                                refundRooms
                            }
                        }
                    })
                });
            }
            if (isPass) {
                this.detailLoading = true
                consumer_admin_hotel_order_refundHotelOrderPartialCancel(params).then(res => {
                    this.$message({
                        type: "success",
                        message: '提交成功'
                    })
                    history_tag_api.remove_tag_by_route({
                        route: this.$route
                    })
                    this.$router.replace({
                        name: "admin-hotel-order-non-rule-cancel-list"
                    })
                }, error => {
                    this.detailLoading = false
                }).catch(() => {
                    this.detailLoading = false
                })
            } else {
                this.toTop();
            }
        }
    }
}

import _ from "underscore";
import Q from "q";
import aRouter from "../index";
import all_routes_processor from "./all_routes_processor";

const init_route_onError = function () {
    aRouter.onError(error => {
        if (/loading chunk \d* failed./i.test(error.message)) {
            global.location.reload()
        }
    });
};
export default init_route_onError;

//参考
//https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58

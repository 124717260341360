import Q from 'q';
// 企业信息组件
import CompanyInfo from '@/component/company-info/import_latest_version_module'
import NewChangeClause from "@/page/admin/replace-orders/flight-search/component/newChangeClause/index.vue";
import PaymentMethodSelector010 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.11/payment-method-selector-010/index.vue';

import consumer_t_od_trains_order_change_adminGetChangePayDetail from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_change_adminGetChangePayDetail'
//取消订单
import consumer_trains_order_cancelChangeOrder from '@/lib/data-service/haolv-default/consumer_trains_order_cancelChangeOrder'

//剩余时间接口
import consumer_admin_flight_milliseconds from '@/lib/data-service/haolv-default/consumer_admin_flight_milliseconds'
import moment from "moment";

export default {
    data() {
        return {
            evenctionNo: null,
            changeNo: null,
            orderNo: null,
            successVisible: false,
            //改签单信息
            changeOrderInfo:{
                changeNo:'',
                orderNo:'',
                partnerChangeNo:'',
                partnerOrderNo:'',
                changeOrderStatusText:'',
                changeTime:'',
                changeSeatPrice:'',
                applyer:'',
                companyName:'',
            },
            //原车次信息
            orginalTrainInfo:[
                {
                    fromStationName: '',
                    toStationName: '',
                    gmtStart: '',
                    gmtArrive: '',
                    trainCode: '',
                    seatPrice: '',
                    seatName: '',
                }
            ],
            //改签车次信息
            changeTrainInfo:[
                {
                    changeTrainCode: '',
                    changeFromStationName: '',
                    changeToStationName: '',
                    gmtChangeArrive: '',
                    gmtChangeStart: '',
                    changeSeatName: '',
                    changeSeatPrice: '',
                }
            ],
            //改签乘客信息
            changePassengerInfo:[
                {
                    passengerName:'',
                    passengerTypeText:'',
                    passengerCardTypeText:'',
                    passengerCardNumber:'',
                    passengerPhone:'',
                },
            ],
            //费用信息
            feeInfo:[
                {
                    changeTotalAmount:'',
                    changeAdultSeatPrice:'',
                    changeAdultCounts:'',
                    changeChildrenSeatPrice:'',
                    changeChildrenCounts:'',
                }
            ]
        }
    },
    components: {
        CompanyInfo,
        NewChangeClause,
        PaymentMethodSelector010
    },
    created() {

    },
    mounted() {

    },
    activated() {
        this.evenctionNo = this.$route.query.evenctionNo ? this.$route.query.evenctionNo : '';
        this.changeNo = this.$route.query.changeNo ? this.$route.query.changeNo : '';
        this.orderNo = this.$route.query.orderNo ? this.$route.query.orderNo : '';


        this.postData();
        this.initPayment();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        startTimeFormatter(val) {
            return this.$moment(val.gmtChangeStart).format('YYYY-MM-DD HH:mm')
        },
        arriveTimeFormatter(val) {
            return this.$moment(val.gmtChangeArrive).format('YYYY-MM-DD HH:mm')
        },
        to_detail() {
            this.successVisible = false;
            const orderNo = this.$route.query.orderNo;
            const changeNo = this.changeNo;
            this.$router.replace({
                name: "admin-train-change-detail",
                query: {
                    orderNo,
                    changeNo,
                    // orderNo: this.$route.query.oriOrderNo,
                },
            });
        },
        postData(){
            let data = {
                changeNo:this.changeNo,
            };
            consumer_t_od_trains_order_change_adminGetChangePayDetail(data).then(res => {
                console.log(res);
                this.changeOrderInfo = res.datas.changeOrderInfo;
                this.changePassengerInfo = res.datas.changePassengerInfo;
                this.changeTrainInfo = [res.datas.changeTrainInfo];
                this.feeInfo = [res.datas.feeInfo];
                this.orginalTrainInfo = [res.datas.orginalTrainInfo];

            })
        },
        initPayment(){
            let _this = this;
            let time = 60;
            consumer_admin_flight_milliseconds().then(res => {
                _this.$refs.aPaymentMethodSelector010.init({
                    page_this: _this,
                    //在这里传入订单详情的相关参数
                    get_params() {
                        const p = {
                            orderForPayVos: [
                                {
                                    orderNo: _this.changeNo,
                                    businessContent: ""
                                },
                            ],
                            payType: 2
                        };
                        return Q.resolve(p);
                    },
                    //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                    before_pay_event_handler() {
                        // alert(`不能够支付！原因：XXX`);
                        // return Q.reject();
                        // 验价  改签不需要验价
                        console.log(`before_pay_event_handler`);
                        return Q.resolve();
                    },
                    //支付成功事件处理方法
                    pay_successful_event_handler() {
                        console.log(`pay_successful_event_handler`);

                        //展示支付成功提示框
                        _this.successVisible = true;
                    },
                    //支付失败事件处理方法
                    pay_failed_event_handler() {
                        console.log(`pay_failed_event_handler`);
                    },
                    //取消订单按钮单击事件处理方法
                    btn_cancel_order_click_event_handler(args) {
                        const show_loading = args.show_loading;
                        const hide_loading = args.hide_loading;
                        const stop_count_down = args.stop_count_down;
                        const enable_btn_pay = args.enable_btn_pay;
                        const enable_btn_cancel_order = args.enable_btn_cancel_order;

                        Q.when()
                            .then(function () {
                                show_loading();
                                return consumer_trains_order_cancelChangeOrder({
                                    changeNo: _this.changeNo,
                                });
                            })
                            .then(function (data) {
                                console.log(`btn_cancel_order_click_event_handler`);
                                hide_loading();
                                stop_count_down();
                                enable_btn_pay({
                                    enable: false,
                                });
                                enable_btn_cancel_order({
                                    enable: false,
                                });

                                if (data.datas.ifIsSuccessful == 1) {
                                    _this.$message({
                                        message: '取消订单成功',
                                        type: 'success'
                                    });

                                    setTimeout(() => {
                                        _this.$router.push({
                                            name: 'admin-train-change-order-list',
                                        })
                                    },1000)
                                } else if (data.datas.ifIsSuccessful == 2) {
                                    _this.$message.error('取消订单失败');
                                }
                            })
                            .catch(function (ex) {
                                if (ex) {
                                    const enable_log_error = !ex.disable_log_error;
                                    if (enable_log_error) {
                                        console.error(ex);
                                        console.trace();
                                        if (ex.stack) {
                                            console.error(ex.stack);
                                        }
                                    }
                                }
                                return Q.reject(ex);
                            });
                    },
                })
            })
        },
    }
}

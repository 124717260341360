export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/often-traveller',
            name: 'admin-often-traveller',
            component: () => import(/* webpackChunkName: "page-admin-replace-orders-often-traveller" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `常用旅客`,
                },
                entity_list_for_breadcrumb: [
                    {name: `代客下单`},
                    {name: `常用旅客`},
                ],
            },
        },
    ],
};

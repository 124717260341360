import history_tag_api from '@/component/admin-layout/component/history-tag/api';
import Editor from '@/component/editor/1.0.0/index.vue';
import ReleaseScope from '../component/release-scope/index.vue';
import consumer_notice_add from '@/lib/data-service/haolv-default/consumer_notice_add.js';

export default {
    data() {
        return {
            form: {
                noticeContent: '', // 内容
                noticeTitle: '', // 标题
                scope: '', // 发布范围
            },
            value: '',
            releaseScopeList: [],
            newTravelParams: '',
        };
    },
    components: {
        Editor,
        ReleaseScope
    },
    methods: {
        onSubmit() { // 确定
            this.form.scope = this.releaseScopeList.toString();
            if (this.form.noticeTitle === '') {
                this.$message.warning('请输入公告标题');
                return
            }
            if (this.form.scope === '') {
                this.$message.warning('请输入公告标题');
                return
            }
            if (this.form.noticeContent === '') {
                this.$message.warning('请输入公告内容');
                return
            }
            consumer_notice_add(this.form).then(() => {
                this.$message({
                    type: 'success',
                    message: '发布成功',
                    onClose: () => {
                        history_tag_api.remove_tag_by_route({
                            route: this.$route,
                        });
                        this.$router.push({
                            name: 'admin-notice-list'
                        });
                    }
                });
            })
        },
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
};

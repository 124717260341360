import CascadeRadioBox from "@/page/admin/organizational-structure/components/cascade-radio-box/index.vue";
import Pagination from "@/page/admin/organizational-structure/components/pagination/index.vue";
import delDialog from "@/page/admin/organizational-structure/components/del-dialog/index.vue";
import resetPasswordDialog from "@/page/admin/organizational-structure/components/reset-password-dialog/index.vue";
import consumer_web_staff_pageList from "@/lib/data-service/haolv-default/consumer_web_staff_pageList";
import consumer_department_getDepartmentList from "@/lib/data-service/haolv-default/consumer_department_getDepartmentList"; // 获取部门list
import consumer_role_roleAll from "@/lib/data-service/haolv-default/consumer_role_roleAll"; // 获取角色list
import consumer_web_staff_delete from "@/lib/data-service/haolv-default/consumer_web_staff_delete";
import consumer_web_staff_detail from "@/lib/data-service/haolv-default/consumer_web_staff_detail";
import consumer_admin_web_staff_addOrUpdate from "@/lib/data-service/haolv-default/consumer_admin_web_staff_addOrUpdate";
import getCompanyInfo from "@/lib/data-service/haolv-default/consumer_web_company_getCompanyInfo"; // 获取公司信息
import consumer_admin_web_staff_reSetPassword from "@/lib/data-service/haolv-default/consumer_admin_web_staff_reSetPassword"; // 重置员工密码
import consumer_department_getDepartments from "@/lib/data-service/haolv-default/consumer_department_getDepartments";
import { awaitWrap } from "@/page/admin/organizational-structure/common/unit";
export default {
  data() {
    const validateStaffName = (rule, value, callback) => {
      const regExp = /^[\u0391-\uFFE5A-Za-z]+$/;
      if (regExp.test(value)) {
        callback();
      } else {
        callback("只能输入中文、英文");
      }
    };

    // { pattern: /^[0-9A-Za-z]+$/, message: '账号名只能用字母或数字组合', trigger: 'blur' },
    // { min: 6, message: '密码至少6位', trigger: 'blur' },
    const passwordReg = /[^0-9A-Za-z]+$/;
    const validateVerify1 = (rule, value, callback) => {
      if (value) {
        if (value.length < 6) {
          callback(new Error("密码至少6个字符"));
        } else {
          if (!passwordReg.test(value)) {
            // const affirmPassword = this.newParams.affirmPassword;
            // if (affirmPassword && value !== affirmPassword) {
            //   callback(new Error("两次密码不一致"));
            // }
            callback();
          } else {
            callback(new Error("密码只能用字母或数字组合"));
          }
        }
      } else {
        callback(new Error("请设置密码"));
      }
    };

    const validateVerify2 = (rule, value, callback) => {
      if (value) {
        if (value.length < 6) {
          callback(new Error("密码至少6个字符"));
        } else {
          if (!passwordReg.test(value)) {
            const password = this.newParams.password;
            if (password && value !== password) {
              callback(new Error("两次密码不一致"));
            } else {
              callback();
            }
          } else {
            callback(new Error("密码只能用字母或数字组合"));
          }
        }
      } else {
        callback(new Error("请再次确认密码"));
      }
    };

    return {
      loading: false,
      params: {
        total: 0,
        pageSize: 10,
        currentPage: 1,
        deptIds: null, // 部门id
        //phone: "",
        //staffName: "",
        //searchKey: "",
        keyword: '',
      },
      cascadeList: [],
      treeDataSource: [], // 全部部门list
      activeList: [], // 筛选出来部门list

      tableData: [],
      dialogVisible: false,
      dialogType: "add", // 新增add 编辑edit
      dialogStatus: {
        add: "新增员工",
        edit: "编辑员工",
      },
      newParams: {
        phone: "",
        staffName: "", // 姓名
        deptId: "", // 部门id
        deptArr: [],
        roleIds: [], // 角色id集合
        staffId: "", // 员工id,修改必填
        username: "", //登录帐号
        password: "", //登录密码
        affirmPassword: "", //确认密码
        userId: "",
      },

      rule: {
        staffName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { validator: validateStaffName, trigger: "blur" },
        ],
        phone: [
          { min: 11, message: "请输入正确的11位手机号码", trigger: "blur" },
          {
            pattern: /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19\d|106)-?\d{3,8}$/,
            message: "请输入正确的11位手机号码",
            trigger: "blur",
          },
        ],
        roleIds: [],
        username: [
          { required: true, message: "请设置登录帐号", trigger: "blur" },
          {
            pattern: /[0-9A-Za-z]+$/,
            message: "账号名只能用字母或数字组合",
            trigger: "blur",
          },
          { min: 4, message: "帐号名至少4位", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请设置登录密码", trigger: "blur" },
          { validator: validateVerify1, trigger: "blur" },
          // { pattern: /^[0-9A-Za-z]+$/, message: '账号名只能用字母或数字组合', trigger: 'blur' },
          // { min: 6, message: '密码至少6位', trigger: 'blur' },
        ],
        affirmPassword: [
          { required: true, message: "请再次确认密码", trigger: "blur" },
          { validator: validateVerify2, trigger: "blur" },
          // { pattern: /^[0-9A-Za-z]+$/, message: '账号名只能用字母或数字组合', trigger: 'blur' },
          // { min: 6, message: '密码至少6位', trigger: 'blur' },
        ],
      },
      cardTypeList: [
        { value: 1, label: "身份证", disabled: false },
        { value: 2, label: "护照", disabled: false },
        { value: 4, label: "回乡证", disabled: false },
        { value: 5, label: "台胞证", disabled: false },
        { value: 6, label: "军官证", disabled: false },
        { value: 7, label: "香港身份证", disabled: false },
        { value: 11, label: "澳门身份证", disabled: false },
        { value: 10, label: "其它", disabled: false },
      ],
      delParams: {
        id: "",
        status: 3,
        userId: "",
      },
      dialogDelVisible: false,
      resetPasswordParams: {
        id: "",
      },
      dialogResetVisible: false,
      roleList: [],
      companyInfo: {},
      structureList: [],
    };
  },
  components: {
    CascadeRadioBox,
    Pagination,
    delDialog,
    resetPasswordDialog,
  },
  created() {},
  mounted() {},
  async activated() {
    this.getStaffList();
    await this.getDeptList();
    this.treeDataSource = this.initTreeData(this.cascadeList);

    await this.getCompany();
    this.getRoleList();
    this.getStructureList();
  },
  deactivated() {},
  destroyed() {},
  computed: {},
  filters: {},
  methods: {
    async getDeptList() {
      let [err, res] = await awaitWrap(consumer_department_getDepartmentList());
      if (err) {
        this.cascadeList = [];
        return;
      }
      this.cascadeList = res.datas.departmentListResults
        ? res.datas.departmentListResults
        : [];
    },
    getRoleList() {
      consumer_role_roleAll().then((res) => {
        this.roleList = res.datas;
      });
    },
    async getCompany() {
      let res = await getCompanyInfo();
      this.companyInfo = res.datas ? res.datas : {};
    },
    getStructureList() {
      consumer_department_getDepartments({
        companyId: this.companyInfo.companyId,
      }).then((res) => {
        this.structureList = res.datas.departmentVos;
      });
    },
    initTreeData(list) {
      // 这里一定要转化，要不然他们的值监听不到变化
      let tempData = JSON.parse(JSON.stringify(list));
      let reduceDataFunc = (data, level) => {
        data.map((m, i) => {
          m.isExpand = false; // 是否展开
          m.check = false;
          m.indeterminate = false;
          m.level = level;
          m.bLeft = level * 15;
          m.label = m.name;
          m.value = m.deptId;
          m.children = m.childrenList;
          delete m.childrenList;
          if (m.children && m.children.length > 0) {
            reduceDataFunc(m.children, level + 1);
          }
        });
      };
      reduceDataFunc(tempData, 1);
      return tempData;
    },
    handleAddWorker() {
      this.reactNewParams();
      this.reactCardTypeList();
      // 避免选择相同的证件类型
      this.cardTypeList.forEach((value) => {
        if (value.value === 1) {
          value.disabled = true;
        }
      });
      this.dialogType = "add";
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    toSelect(val) {
      this.search();
    },
    delSelect(val) {
      this.search();
    },
    changeKey(val) {
      this.search();
    },
    submitAdd() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const newParams = this.newParams;
          newParams.deptId = newParams.deptArr[newParams.deptArr.length - 1];
          let params = {};
          params.staffName = newParams.staffName;
          params.phone = newParams.phone;
          params.username = newParams.username;
          params.password = newParams.password;
          params.deptId = newParams.deptId || "";
          params.roleIds = newParams.roleIds;
          consumer_admin_web_staff_addOrUpdate(params)
            .then((res) => {
              this.loading = false;
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.dialogVisible = false;
              this.getStaffList();
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    getStaffList() {
      this.loading = true;
      /*if (this.params.keyword === "") {
        //this.params.phone = "";
        //this.params.staffName = "";
      } else {
        // 如果判断是手机号码就转成phone。否则就是name
        // const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/
        // 如果判断是数字就转成phone。否则就是name
        const regExp = /^[\d|\.]*$/;
        if (regExp.test(this.params.searchKey)) {
          this.params.phone = this.params.searchKey;
        } else {
          this.params.staffName = this.params.searchKey;
        }
      }*/
      consumer_web_staff_pageList(this.params)
        .then((res) => {
          this.loading = false;
          if (res.code === "000000") {
            this.tableData = res.datas.list;
            this.params.total = res.datas.totalCount;
          } else {
            this.tableData = [];
          }
        })
        .catch(() => {
          this.loading = false;
          this.tableData = [];
        });
    },
    search() {
      if (this.activeList.length === 0) {
        this.params.deptIds = null;
      } else {
        let deptIds = [];
        this.activeList.forEach((value) => {
          deptIds.push(value.deptId);
        });
        this.params.deptIds = deptIds;
      }
      this.params.currentPage = 1;
      this.getStaffList();
    },
    handleEdit(val) {
      consumer_web_staff_detail({ id: val.staffId }).then((res) => {
        let userData = res.datas[0];
        const newParams = this.newParams;
        const roleList = userData.roleList;
        newParams.userId = userData.userId;
        newParams.staffId = userData.staffId;
        this.newParams.deptArr = this.getDeptArr(this.newParams.deptId);
        let roleIds = [];
        roleList.forEach((val) => {
          roleIds.push(val.id);
        });
        newParams.staffName = userData.staffName;
        newParams.phone = userData.phone;
        newParams.username = userData.userName;
        newParams.deptId = userData.deptId;
        newParams.deptArr = [userData.deptId];
        newParams.roleIds = roleIds; // 角色列表
        this.reactCardTypeList();
        this.dialogType = "edit";
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs["form"].clearValidate();
        });
      });
    },
    getDeptArr(val) {
      let deptArr = [];
      let _this = this;
      const getDept = (value) => {
        for (let i = 0, l = this.structureList.length; i < l; i++) {
          if (value === this.structureList[i].id) {
            deptArr.unshift(value);
            if (this.structureList[i].parentId !== 0) {
              getDept(this.structureList[i].parentId);
            }
          }
        }
      };
      getDept(val);
      return deptArr;
    },
    submitEdit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const newParams = this.newParams;
          newParams.deptId = newParams.deptArr[newParams.deptArr.length - 1];
          let params = {};
          params.staffName = newParams.staffName;
          params.phone = newParams.phone;
          params.username = newParams.username;
          params.password = newParams.password;
          params.deptId = newParams.deptId || "";
          params.roleIds = newParams.roleIds;
          params.userId = newParams.userId;
          params.staffId = newParams.staffId;
          consumer_admin_web_staff_addOrUpdate(params)
            .then((res) => {
              this.newParams.userId = "";
              this.loading = false;
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.dialogVisible = false;
              this.getStaffList();
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    handleReset(val) {
      // 重置密码
      debugger
      this.resetPasswordParams.id = val.userId;
      this.dialogResetVisible = true;
    },
    submitReset() {
      this.loading = true;
      consumer_admin_web_staff_reSetPassword(this.resetPasswordParams)
        .then((res) => {
          this.loading = false;
          this.$message({
            message: "重置密码成功",
            type: "success",
          });
          this.dialogResetVisible = false;
          if (this.tableData.length <= 1 && this.params.currentPage !== 1) {
            this.params.currentPage--;
          }
          this.getStaffList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleDel(val) {
      this.delParams = {
        id: val.staffId,
        status: 3,
        userId: val.userId,
      };
      this.dialogDelVisible = true;
    },
    submitDel() {
      this.loading = true;
      consumer_web_staff_delete(this.delParams)
        .then((res) => {
          this.loading = false;
          this.$message({
            message: "成功删除",
            type: "success",
          });
          this.dialogDelVisible = false;
          if (this.tableData.length <= 1 && this.params.currentPage !== 1) {
            this.params.currentPage--;
          }
          this.getStaffList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    reactNewParams() {
      this.newParams = {
        phone: "",
        staffName: "", // 姓名
        deptId: "", // 部门id
        deptArr: [],
        englishName: "", // 英文姓名
        roleIds: [], // 角色id集合
        staffId: "", // 员工id,修改必填
        username: "", //登录帐号
        password: "", //登录密码
      };
    },
    reactCardTypeList() {
      this.cardTypeList.forEach((value) => {
        value.disabled = false;
      });
    },
  },
};

// consumer_admin_trains_order_applyForRefund 申请退票New
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
const consumer_admin_trains_order_applyForRefund = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/trains/order/applyForRefund',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_trains_order_applyForRefund;
// consumer_payment_getPayChannelV2ForAdmin admin- 【349】获取支付渠道并生成支付流水号outTradeNo,支付流水号用于发起支付
const __request = require(`./__request/__request_contentType_json`)
const consumer_payment_getPayChannelV2ForAdmin = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/payment/getPayChannelV2ForAdmin',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_payment_getPayChannelV2ForAdmin;

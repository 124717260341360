// 授信申请列表  http://api-beta.haolvtrip.com/doc.html#/tmc-biz/t-us-company-credit-controller/auditCreditListUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_credit_auditCreditList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/credit/audit/creditList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_credit_auditCreditList;
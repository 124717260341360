export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/invoice/mailing-management',
            name: 'admin-invoice-mailing-management',
            component: () => import(/* webpackChunkName: "page-admin-agricultural-bank-reconciliation-invoice-mailing-management" */ `./1.0.0/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `发票邮寄管理`,
                },
                entity_list_for_breadcrumb: [
                    {name: `线下对账和开票`},
                    {name: `发票邮寄管理`},
                ],
            },
        },
    ],
};

import add_routes from './add_routes';
import init_route_guard from './init_route_guard';
import init_route_onError from './init_route_onError';
import all_routes_processor from "./all_routes_processor";

const init_router = function () {
    const routes_level_one = add_routes();
    all_routes_processor.init({
        routes_level_one: routes_level_one,
    });
    init_route_guard();
    init_route_onError();
};
export default init_router;

// 用车订单列表 http://yapi.yinzhitravel.com/project/52/interface/api/15092

const __request = require(`./__request/__request_contentType_json`)
const consumer_admin_car_order_carOrderList = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/admin/car/order/carOrderList',
    data: data
  }
  return __request(pParameter)
}
export default consumer_admin_car_order_carOrderList

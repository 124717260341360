export default {
  path: '',
  component: () => import(`@/component/admin-layout/index.vue`),
  children: [
    {
      path: '/admin/v-model-test',
      name: 'admin-v-model-test',
      component: () => import(/* webpackChunkName: "page-admin-default-v-model-test" */ `./index.vue`),
      meta: {
        entity_for_history_tag: {
          name: `v-model-test`,
        },
        entity_list_for_breadcrumb: [
          {name: `示例功能`},
          {name: `v-model-test`},
        ],
      },
    },
  ],
};

import Vue from 'vue'
import Vuex from 'vuex'
import Q from 'q'
import moment from 'moment'

import moduleDictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-vuex';

const api_system_getCurrentUser = require(`@/lib/data-service/system/api_system_getCurrentUser`);
const api_system_getGlobalData = require(`@/lib/data-service/system/api_system_getGlobalData`);
const api_system_get_server_config = require(`@/lib/data-service/system/api_system_get_server_config`);

let __store;

let promise_init_global_data;
let promise_init_current_user;
let promise_init_server_config;



const init = function () {
    Vue.use(Vuex);

    __store = new Vuex.Store({
        state: {
            last_refresh_time: moment().format(`YYYY-MM-DD HH:mm:ss`),
            app_config: {},
            global_data: {},
            current_user: {},
            data_for_left_menu: {
                is_open: true,
            },
            data_for_history_tag: {
                tag_list: [],
            },
            data_for_breadcrumb: {
                entity_list: [],
            },
        },
        mutations: {
            update_last_refresh_time(state, pData) {
                console.log(pData);
                state.last_refresh_time = pData;
            },

            init_app_config(state, pData) {
                state.app_config = pData;
            },

            init_global_data(state) {
                promise_init_global_data = Q.when()
                    .then(function () {
                        return api_system_getGlobalData();
                    })
                    .then(function (data) {
                        state.global_data = data.entity;
                        global.global_data = data.entity;
                    })
                    .catch(function (ex) {
                        console.error(ex);
                        if (ex) {
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                        return Q.reject(ex);
                    })
                ;

            },

            init_current_user(state) {
                promise_init_current_user = Q.when()
                    .then(function () {
                        return api_system_getCurrentUser();
                    })
                    .then(function (data) {
                        state.current_user = data.entity;
                        global.current_user = data.entity;
                    })
                    .catch(function (ex) {
                        console.error(ex);
                        if (ex) {
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                        return Q.reject(ex);
                    })
                ;

            },

            init_server_config(state) {
                promise_init_server_config = Q.when()
                    .then(function () {
                        return api_system_get_server_config();
                    })
                    .then(function (data) {
                        state.server_config = data.entity;
                        global.server_config = data.entity;
                    })
                    .catch(function (ex) {
                        console.error(ex);
                        if (ex) {
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                        return Q.reject(ex);
                    })
                ;

            },
        },
        modules: {
            moduleDictionary: moduleDictionary
        }
    });

    // __store.commit(`init_app_config`);
    // __store.commit(`init_global_data`);
    // __store.commit(`init_current_user`);
    // __store.commit(`init_server_config`);

    global.__store = __store;

    return __store;
};
init();

const update_last_refresh_time = function (pData) {
    __store.commit(`update_last_refresh_time`, pData);
};

const get_global_data = function () {
    return Q.when()
        .then(function () {
            return promise_init_global_data;
        })
        .then(function () {
            return __store.state.global_data;
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};

const get_current_user = function () {
    return Q.when()
        .then(function () {
            return promise_init_current_user;
        })
        .then(function () {
            return __store.state.current_user;
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};

const get_server_config = function () {
    return Q.when()
        .then(function () {
            return promise_init_server_config;
        })
        .then(function () {
            return __store.state.server_config;
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};

export default __store;

import moment from 'moment'
export default {
    name: 'ReservePopupBox',
    data () {
        return {
            showPop: false,
            resultList: []
        }
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        currentItem: {
            type: Object,
            default: null
        },
        // 筛选显示到类型
        businessType: {
            type: Number,
            default: 0 // 1.机票，2.火车票，3.酒店
        },
        disable: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    created () {},
    mounted () {
        document.addEventListener('click', this.clickReserveWrapper)
        this.setResultList()
    },
    activated () {
    },
    deactivated() {},
    destroyed () {
        document.removeEventListener('click', this.clickReserveWrapper)
    },
    watch: {
        list(newVal) {
            let resultList = []
            newVal.forEach(value => {
                let result = this.isShowItem(value)
                if (result) {
                    resultList.push(value)
                }
            })
            this.resultList = resultList
        },
        disable(newVal) {
            if (newVal) {
                this.current = null
                this.showPop = false
            }
        }
    },
    computed: {
        current: {
            get () {
                return this.currentItem
            },
            set (val) {
                this.$emit('update:currentItem', val)
            }
        }
    },
    filters: {
        changeDepartTime(val) {
            let day = moment(val).format('YYYY年MM月DD日')
            return day
        }
    },
    methods: {
        setResultList() {
            let resultList = []
            this.list.forEach(value => {
                let result = this.isShowItem(value)
                if (result) {
                    resultList.push(value)
                }
            })
            this.resultList = resultList
        },
        toggleShowPop() {
            if (this.disable) {
                return
            }
            this.showPop = !this.showPop
        },
        clickReserveWrapper(e) {
            if (!!this.$refs.reserveChooseBox.contains(e.target)) return
            this.showPop = false
        },
        getCurrentItem(item, journey, index, jIndex) {
            let current = item
            current.active = journey
            this.current = current
            this.toggleShowPop()
        },
        isShowItem(item) {
            let haveStaffList = item.staffList.length > 0 ? true : false
            if (this.businessType === 0) {
                return haveStaffList
            } else {
                let show = false
                item.journeys.forEach(value => {
                    if (value.businessType === this.businessType) {
                        show = true
                    }
                })
                return show && haveStaffList
            }
        },
        del() {
            this.current = null
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
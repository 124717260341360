// 平台获取公司授信信息  http://api-alpha.haolvtrip.com/doc.html#/tmc-biz/t-us-company-credit-controller/platformCreditDataUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_credit_platformCreditData = () => {
  const p = {
    method: 'post',
    urlSuffix: '/consumer/credit/platformCreditData',
    data: {},
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_credit_platformCreditData;

export default {
  components: {
    
  },
  data() {
    return {
      a: false,
      b: false,
      c: false,
    };
  },
  methods: {
    
  },
  mounted () {
    
  },
  activated() {
    
  },
};

// consumer_admin_trains_order_trainsticketChannel
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_trains_order_trainsticketChannel = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/trains/order/trainsticketChannel',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_trains_order_trainsticketChannel;
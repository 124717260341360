import CompanySelector from '@/component/companySelector/index.vue'
import CostAttributionList from '@/component/costAttributionList/index'
import BelongToProjectList from '@/component/belongToProjectList/index'
import DynamicForm from '@/component/dynamicForm/import_latest_version_module'
import DomesticTicketAirportSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/2.0.1/index.vue'
// import DynamicForm from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/dynamic-form/import_latest_version_module'
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.8/index.vue';
import TravelerMultipleSelectorLable from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector-label/1.0.0/index.vue';
import consumer_admin_flight_generate_getCompanyList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getCompanyList'
import consumer_admin_flight_generate_evectionType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_evectionType'
import consumer_admin_flight_generate_payType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_payType'
import consumer_flight_search_airlines from '@/lib/data-service/haolv-default/consumer_flight_search_airlines'
import consumer_flight_search_addInternaTionalAirlines from '@/lib/data-service/haolv-default/consumer_flight_search_addInternaTionalAirlines'
import consumer_flight_search_InternaTionalAirlines from '@/lib/data-service/haolv-default/consumer_flight_search_InternaTionalAirlines'
import consumer_flight_search_internationalCitys from '@/lib/data-service/haolv-default/consumer_flight_search_internationalCitys'
import consumer_admin_flight_generate_getLinkmanList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getLinkmanList'
import consumer_admin_flight_generate_psgType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_psgType'
import consumer_admin_flight_generate_certificateType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType'
import consumer_admin_flight_generate_getInsuranceProductList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getInsuranceProductList'
import consumer_admin_flight_generate_getOtherInsuranceProductList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getOtherInsuranceProductList'
import consumer_admin_flight_generate_purchaseChannel from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_purchaseChannel'
import consumer_admin_flight_generate_saveOrUpdate from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_saveOrUpdate'
import consumer_admin_flight_generate_details from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_details'
import consumer_admin_flight_generate_userName from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_userName'
import consumer_admin_flight_generate_cabinRank from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_cabinRank'
import consumer_admin_flight_generate_configServiceFee from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_configServiceFee'
import consumer_admin_flight_generate_getInsurancePayTypeList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getInsurancePayTypeList'
import consumer_admin_flight_order_saveOrderRemark from '@/lib/data-service/haolv-default/consumer_admin_flight_order_saveOrderRemark'
import consumer_flight_search_citys from "@/lib/data-service/haolv-default/consumer_flight_search_citys";
import consumer_country_queryCountryTwoWord from '@/lib/data-service/haolv-default/consumer_country_queryCountryTwoWord';
import consumer_admin_flight_generate_officialTicketChannel from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_officialTicketChannel';
import consumer_admin_flight_generate_pnrCode from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_pnrCode';
import consumer_admin_flight_generate_configInternationalServiceFee from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_configInternationalServiceFee'
import consumer_admin_flight_generate_checkPsgRepeatOrNot from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_checkPsgRepeatOrNot'
import consumer_department_getStaffsAndExCusByUserIds from '@/lib/data-service/haolv-default/consumer_department_getStaffsAndExCusByUserIds'
import consumer_flight_report_order_offlineOutPayType from '@/lib/data-service/haolv-default/consumer_flight_report_order_offlineOutPayType'
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info';
import isMobile from 'yinzhilv-js-sdk/src/common-service/other/is_mobile';
import tipsObj from './tips';
import defaultForm from './form';
import Q from 'q';
import moment from 'moment';
import IdentityCodeValid from '@/lib/utils/validateIdent.js';
import Vue, {set} from 'vue';
import consumer_admin_flight_generate_confirm
    from "@/lib/data-service/haolv-default/consumer_admin_flight_generate_confirm";

export default {
    data() {
        const validateAirlineCode = (rule, value, callback) => {
          if (!value) {
            callback(new Error('请选择航司名称'));
            return
          }
          callback();
        };
        return {
            depDateTime:'',
            validity:'',
            addAirportLoading: false,
            addAirportRule: {
              airCompanyName: [
                { required: true, message: '请输入航司名称', trigger: 'blur' },
              ],
              airlineCode: [
                { required: true, message: '请输入航司三字码', trigger: 'blur' },
              ],
            },
            addAirportIndex: null,
            addAirportForm: {
              airCompanyName: '',
              airlineCode: ''
            },
            addAirport: false,
            activeName: '1',
            type: 'create', // 'create' 新建 'edit' 编辑
            orderCountryType: 1,
            pnrForm: {
                pnrCode: '',
            },
            orderNo: '',
            orderStatus: '',
            loading: false,
            pnrLoading: false,
            addNewPassengerLoading: false,
            submitLoading: false,
            initialName: '',
            freeProduct: 1, // 是否赠送保险，0：否 1：是
            form: {},
            // oriForm: JSON.parse(JSON.stringify(defaultForm)),
            companyList: [],
            evectionTypeList: [],
            payTypeList: [],
            // 国际航司
            airlineCodeList_international: [],
            // 国内航司
            airlineCodeList_domestic: [],
            // 航司
            airlineCodeList: [],
            psgTypeList: [],
            certificateTypeList: [],
            insuranceSourceList: [{code: 1, msg: '线上投保'}, {code: 2, msg: '线下投保'}], // 保险来源：1.线上投保，2.线下投保  , {code: 2, msg: '线下投保'}
            insuranceProductList: [],
            otherInsuranceProductList: [],
            ticketedMethodList: [{code: 3, msg: '线下外采'}, {code: 4, msg: '黑屏'}], // 出票方式 1-线上订单 2-代客下单 3-线下外采 4-黑屏订单
            purchaseChannelList: [],
            purchaseChannelListResult: [],
            generateUserNameList: [],
            insurancePayTypeList: [],
            cabinRankList: [],
            depTerminalList: [{code: 'T1', msg: 'T1'},{code: 'T2', msg: 'T2'},{code: 'T3', msg: 'T3'},{code: 'T4', msg: 'T4'}],
            // 国内机场
            allCityList: [],
            // 国际机场
            allCityList_international: [],
            // 出发机场
            startCityList: [],
            // 到达机场
            endCityList: [],
            officialTicketChannelList: [],
            paymentTypeList: [
                {
                    code:0,
                    msg:'支付宝',
                },
                {
                    code:1,
                    msg:'网银',
                }
            ],//支付方式枚举值
            rules: {
                'customerInfo.companyId': [
                    { required: true, message: '请选择所属企业', trigger: 'change' },
                ],
                'customerInfo.contactName': [
                    { required: true, message: '请填写联系人', trigger: 'blur' },
                ],
                'customerInfo.contactMobile': [
                    { required: true, message: '请填写联系电话', trigger: 'blur' },
                    {validator: this.checkMobile, trigger: 'blur'}
                ],
                'customerInfo.evectionType': [
                    { required: true, message: '请选择出行类型', trigger: 'change' },
                ],
                'customerInfo.payType': [
                    { required: true, message: '请选择支付方式', trigger: 'change' },
                ],
                airlineCode: [
                    { required: true, validator: validateAirlineCode, trigger: ['blur', 'change'] },
                ],
                flightNo: [
                    { required: true, message: '请填写航班号', trigger: ['blur', 'change'] },
                ],
                depAirportCode: [
                    { required: true, message: '请选择出发城市', trigger: ['blur', 'change'] },
                ],
                arrAirportCode: [
                    { required: true, message: '请选择目的城市', trigger: ['blur', 'change'] },
                ],
                depDateTime: [
                    { required: true, message: '请选择起飞时间', trigger: ['blur', 'change'] },
                ],
                arrDateTime: [
                    { required: true, message: '请选择到达时间', trigger: ['blur', 'change'] },
                ],
                cabinRank: [
                    { required: true, message: '请选择舱位等级', trigger: ['blur', 'change'] },
                ],
                cabin: [
                    { required: true, message: '请填写舱位', trigger: ['blur', 'change'] },
                ],
                'journeyInfo.depTerminal': [
                    { required: true, message: '请填写出发航站楼', trigger: 'blur' },
                ],
                'journeyInfo.arrTerminal': [
                    { required: true, message: '请填写到达航站楼', trigger: 'blur' },
                ],
                'ticketInfo.initialName': [
                    { required: true, message: '请填写操作客服', trigger: 'blur' },
                ],
                'ticketInfo.officialTicketChannel': [
                    { required: true, message: '请选择公务票验证方式', trigger: 'change' },
                ],
                'ticketInfo.officialTicketBank': [
                    { required: true, message: '请填写公务票验证银行/单位', trigger: 'blur' },
                ],
                'ticketInfo.ticketTime': [
                    { required: true, message: '请选择出票时间', trigger: 'change' },
                ],
                'ticketInfo.orderTime': [
                    { required: true, message: '请选择预订时间', trigger: 'change' },
                ],
                'ticketInfo.bookingSms': [
                    { required: true, message: '请选择是否发送短信通知给乘客', trigger: 'change' },
                ],
                'ticketInfo.linkmanSms': [
                    { required: true, message: '请选择是否发送短信通知给联系人', trigger: 'change' },
                ],
                'insuranceInfo.isInsure': [
                    { required: true, message: '请选择是否投保', trigger: 'change' },
                ],
                'insuranceInfo.productName': [
                    { required: true, message: '请填写保险名称', trigger: 'blur' },
                ],
                'insuranceInfo.insuranceOrderId': [
                    { required: true, message: '请填写保单号', trigger: 'blur' },
                ],
                'insuranceInfo.premium': [
                    { required: true, message: '请填写保险销售价', trigger: 'blur' },
                ],
                'insuranceInfo.insureCost': [
                    { required: true, message: '请填写保险成本', trigger: 'blur' },
                ],
                'insuranceInfo.startDate': [
                    { required: true, message: '请选择生效日期时间', trigger: ['blur', 'change'] },
                ],
                'insuranceInfo.endDate': [
                    { required: true, message: '请选择失效日期时间', trigger: ['blur', 'change'] },
                ],
                'insuranceInfo.payType': [
                    { required: true, message: '请选择支付方式', trigger: 'change' },
                ]
            },
            serviceCharge: 0, // 国内服务费
            intServiceCharge: 0, // 国际服务费

            selectedIdList: [],
            selectedIdListB: [],

            adultNum: 1,
            childNum: 0,
            babyNum: 0,

            countryList: [],

            parameterListBox: [],

            centerCodeBox: '',  // 成本中心--centerCode
            projectNameBox: '',  // 归属项目--projectName
            projectCodeBox: '',  // 归属项目--projectCode
            companyIdBox: 0,  // 归属项目--companyId
        }
    },
    components: {CompanySelector, DomesticTicketAirportSelector, CostAttributionList, DynamicForm, TravelerMultipleSelector, TravelerMultipleSelectorLable, BelongToProjectList},
    created() {
    },
    mounted() {
        this.$refs.bTravelerMultipleSelector.init_parameter.btn_ok_click_event_handler = (args) => {
          if (args.selected_entity_list) {
            this.form.customerInfo.contactName = args.selected_entity_list[0].name;
            this.form.customerInfo.contactMobile = args.selected_entity_list[0].phone;
          }
        };
        this.$refs.aTravelerMultipleSelector.init_parameter.btn_ok_click_event_handler = (args) => {
            console.log(`btn_ok_click_event_handler`);
            console.log(args);
            let pnr = this.form.passengerList[0].pnr || '';
            // 先判断是否只剩一个没有输入过的乘机人
            if (this.form.passengerList.length === 1 && !this.form.passengerList[0].psgName && !this.form.passengerList[0].cardNo && !this.form.passengerList[0].mobile) {
                this.form.passengerList = []
            }
            args.selected_entity_list.forEach(value => {
                let birthday = '';
                if (value.certificateType == 1 && value.certificateValue) {
                    birthday = `${value.certificateValue.substr(6, 4)}-${value.certificateValue.substr(10, 2)}-${value.certificateValue.substr(12, 2)}`;
                }
                if (!birthday && value.birthDay) {
                    birthday = value.birthDay;
                }
                if (this.form.sourceType == 2 && value.certificateType != 2) {
                    value.certificateType = 2;
                    value.certificateName = '护照';
                    value.certificateValue = '';
                }
                if(value.certificates) {
                    value.certificates.forEach(value2=>{
                        if (value2.certificateValue == value.certificateType) {
                            value.cardExpireDate = value2.cardExpireDate
                            value.issCountryCode = value2.issCountryCode
                            value.countryCode = value2.countryCode
                        }
                    })
                }
                console.log(value.cardExpireDate);

                let reg = new RegExp("[\\u4E00-\\u9FFF]+","g");
                if (!value.englishName && !reg.test(value.name)) {
                    value.englishName = value.name;
                    value.name = '';
                }
                const item = {
                    userId: value.userId,
                    segmentType: this.form.journeyInfo.length == 1 ? 1 : this.form.journeyInfo.length == 2 ? 2 : 3,
                    segmentTypeText: this.form.journeyInfo.length == 1 ? '单程' : this.form.journeyInfo.length == 2 ? '往返' : '多程',
                    psgName: value.name,
                    psgEnglishName: value.englishName,
                    psgType: 1,
                    psgTypeText: '成人',
                    cardType: value.certificateType,
                    cardTypeText: value.certificateName,
                    cardNo: value.certificateValue,
                    birthday: birthday,
                    mobile: value.phone,
                    ticketNumber: '',
                    pnr: pnr,
                    price: 0,
                    infrastructure: 0,
                    fuel: 0,
                    serviceCharge: this.serviceCharge,
                    purchasePrice: 0,
                    buyerPrice: 0,
                    commission: 0,
                    countryCode: value.countryCode ? value.countryCode : 'CN',
                    issCountryCode: value.issCountryCode ? value.issCountryCode : 'CN',
                    cardExpireDate: value.cardExpireDate ? value.cardExpireDate : '',
                    familyName: value.familyName ? value.familyName : '',
                    givenName: value.givenName ? value.givenName : '',
                    tagNames: value.tags ? value.tags : [],
                    tags: value.tags ? value.tags : [],
                };
                let productCodes = [];
                let otherProductCodes = [];
                this.form.insuranceInfo.forEach((item,index) => {
                    if (item.productCodes != []) {
                        productCodes = item.productCodes;
                    }
                    if (item.otherProductCodes != []) {
                        otherProductCodes = item.otherProductCodes;
                    }
                });
                const newInsure = {
                    insuranceSource: 2,
                    productCodes,
                    otherProductCodes,
                    isInsure: 1,
                    isInsureChecked: true,
                    productName: '', // 保险名称
                    insuranceOrderId: '', // 保单号
                    premium: '', // 保险销售价
                    insureCost: '', // 保险成本
                    startDate: this.depDateTime, // 生效时间
                    endDate: '', // 失效时间
                    payType: 3, // 支付方式
                    validity:'',//有效期
                };
                this.form.insuranceInfo.push(newInsure);
                this.form.passengerList.push(item);
            });
            setTimeout(() => {
                this.form.passengerList.forEach((item,index) => {
                    this.$refs.form.validateField(`passengerList.${index}.psgName`);
                    this.$refs.form.validateField(`passengerList.${index}.psgType`);
                    this.$refs.form.validateField(`passengerList.${index}.birthday`);
                    this.$refs.form.validateField(`passengerList.${index}.cardType`);
                    this.$refs.form.validateField(`passengerList.${index}.cardNo`);
                    this.$refs.form.validateField(`passengerList.${index}.ticketNumber`);
                });
                // this.$refs.form.validate();
            },1000)
        };
    },
    async activated() {
        this.loading = true;
        await this.init();
        this.loading = false;
        //this.$refs.depDomesticTicketAirportSelector.init({ placeholder_text: "请选择出发城市" });
        //this.$refs.arrDomesticTicketAirportSelector.init({ placeholder_text: "请选择到达城市" });
        const query = this.$route.query;
        this.activeName = query && query.activeName ? query.activeName : '1';
        this.type = query && query.type ? query.type : 'create';
        this.orderNo = query && query.orderNo ? query.orderNo : '';
        if (this.type !== 'create') {
            const params = {
                orderNo: this.orderNo
            };
            this.loading = true;
            consumer_admin_flight_generate_details(params).then(res => {
                this.orderStatus = res.details.orderStatus;
                let form = res.details;

                if (!form.customerInfo.feeAffiliationType) {
                    form.customerInfo.feeAffiliationType = 1
                }
                if(form.journeyInfo) {
                    form.journeyInfo.forEach((item,index) => {
                        item.depDateTime = `${item.depDate} ${item.depTime}`;
                        item.arrDateTime = `${item.arrDate} ${item.arrTime}`;

                        item.depCityObj = {
                            id: item.depAirportCode,
                            name: item.depAirportName
                        };
                        item.arrCityObj = {
                            id: item.arrAirportCode,
                            name: item.arrAirportName
                        };
                    })
                };

                let adultNum = 0;
                let childNum = 0;
                let babyNum = 0;
                let insuranceInfoBox = form.insuranceInfo;
                form.insuranceInfo = [];
                form.passengerList.forEach((value,index) => {
                    if (value.psgType === 3) {
                        babyNum++
                        // 婴儿
                        form.babyPrice = {
                          psgType: 3,
                          price: value.price,
                          infrastructure: value.infrastructure,
                          fuel: value.fuel,
                          serviceCharge: value.serviceCharge,
                          purchasePrice: value.purchasePrice,
                          buyerPrice: value.buyerPrice,
                          commission:value.commission,
                        }
                    } else if (value.psgType === 2) {
                        childNum++
                        // 儿童
                        form.childPrice = {
                            psgType: 2,
                            price: value.price,
                            infrastructure: value.infrastructure,
                            fuel: value.fuel,
                            serviceCharge: value.serviceCharge,
                            purchasePrice: value.purchasePrice,
                            buyerPrice: value.buyerPrice,
                            commission:value.commission,
                        }
                    } else if (value.psgType === 1) {
                        adultNum++
                        // 成人
                        form.adultPrice = {
                            psgType: 1,
                            price: value.price,
                            infrastructure: value.infrastructure,
                            fuel: value.fuel,
                            serviceCharge: value.serviceCharge,
                            purchasePrice: value.purchasePrice,
                            buyerPrice: value.buyerPrice,
                            commission:value.commission,
                        }
                    } else {
                        adultNum++
                    }
                    insuranceInfoBox.forEach((InfoItem,InfoIndex) => {
                      if (InfoItem.insured == value.psgName) {
                        form.insuranceInfo.push(InfoItem);
                      }
                    });
                });
                if (form.passengerList.length != form.insuranceInfo.length) {
                  const newInsure = {
                    insuranceSource: 2,
                    productCodes: [],
                    otherProductCodes: [],
                    isInsure: 0,
                    isInsureChecked: false,
                    productName: '', // 保险名称
                    insuranceOrderId: '', // 保单号
                    premium: '', // 保险销售价
                    insureCost: '', // 保险成本
                      startDate: this.depDateTime, // 生效时间
                      endDate: '', // 失效时间
                      payType: 3, // 支付方式
                      validity:'',//有效期
                  };
                  for (let i = 0;i<=10;i++) {
                    if (form.passengerList.length != form.insuranceInfo.length) {
                      form.insuranceInfo.push(newInsure)
                    }
                  }
                }
                form.sourceType = form.journeyInfo[0].sourceType;
                if (form.sourceType == 2) {
                  this.airlineCodeList = this.airlineCodeList_international;
                  this.startCityList = JSON.parse(JSON.stringify(this.allCityList_international));
                  this.endCityList = JSON.parse(JSON.stringify(this.allCityList_international));
                } else {
                  this.airlineCodeList = this.airlineCodeList_domestic;
                  this.startCityList = JSON.parse(JSON.stringify(this.allCityList));
                  this.endCityList = JSON.parse(JSON.stringify(this.allCityList));
                }
                this.adultNum = adultNum;
                this.childNum = childNum;
                this.babyNum = babyNum;
                form.journeyInfo.sort((a, b) => a.segmentType - b.segmentType);
                this.form = form;
                this.form.ticketInfo.paymentType = this.form.ticketInfo.paymentType ? this.form.ticketInfo.paymentType : 0;
                this.form.insuranceInfo.forEach((item,index) => {
                  this.$set(item, 'isInsureChecked', item.isInsure == 1 ? true : false);
                });
                let activeItem = null;
                this.companyList.forEach(value => {
                    if (value.id === this.form.customerInfo.companyId) {
                        activeItem = value
                    }
                });
                this.freeProduct = activeItem.freeProduct;
                const params = {
                    companyId: this.form.customerInfo.companyId
                };
                this.getInsuranceProductList(params);
                this.getOtherInsuranceProductList(params);
                this.$refs.costAttributionList.init(params);
                let purchaseChannelListResult = [];
                this.purchaseChannelList.forEach(value => {
                    if ((this.form.ticketInfo.orderSource === 3 && (value.code !== 6 && value.code !== 7)) || (this.form.ticketInfo.orderSource !== 3 && (value.code === 6 || value.code === 7))) {
                        purchaseChannelListResult.push(value)
                    }
                    this.purchaseChannelListResult = purchaseChannelListResult
                })
                // 更新初始化动态表单
                this.$refs.aDynamicForm.init({
                    showAttribution: true,
                    immutableSwitch: false,
                    type: 'display',
                    displayList: this.form.reportConfiguration,
                    companyId: this.form.customerInfo.companyId,
                    // 业务类型：1机票、2火车票、3酒店、4汽车
                    businessType: 1,
                    // 是否使用默认数据
                    mock_data: {
                        enable: false,
                    },
                }).then(res=>{
                    console.log('re', res);
                    this.parameterListBox = res;
                });
                // 更新初始化用户选择组件
                this.$refs.aTravelerMultipleSelector.init_parameter.get_pager_data_parameter = () => {
                    const d = {
                        companyId: this.form.customerInfo.companyId,
                    };
                    return Q.resolve(d);
                };
                this.$refs.bTravelerMultipleSelector.init_parameter.get_pager_data_parameter = () => {
                    const d = {
                        companyId: this.form.customerInfo.companyId,
                    };
                    return Q.resolve(d);
                };
                this.$refs.aTravelerMultipleSelector.init({
                    dialog: {
                        title: `请选择出行人员`,
                        width: '1100px'
                    },
                    // select_mode: `single`,
                    select_mode: `multiple`,
                    // 场景
                    // 000:代客下单,
                    // 100:行程预订首页，选择出行人（1.有差标管控;2.能选择外部客户;）
                    // 101:出差申请页面，选择出行人（1.没有差标管控;2.能选择外部客户;）
                    // 102:出差申请页面，选择审批人员（1.没有差标管控;2.不能选择外部客户;）
                    situation: `000`,
                    // situation: `100`,
                    // situation: `101`,
                    // situation: `102`,
                    businessType: 1,
                });
                this.$refs.bTravelerMultipleSelector.init({
                  dialog: {
                    title: `请选择出行人员`,
                    width: '1100px'
                  },
                  select_mode: `single`,
                  // select_mode: `multiple`,
                  // 场景
                  // 000:代客下单,
                  // 100:行程预订首页，选择出行人（1.有差标管控;2.能选择外部客户;）
                  // 101:出差申请页面，选择出行人（1.没有差标管控;2.能选择外部客户;）
                  // 102:出差申请页面，选择审批人员（1.没有差标管控;2.不能选择外部客户;）
                  situation: `000`,
                  // situation: `100`,
                  // situation: `101`,
                  // situation: `102`,
                  businessType: 1,
                });
            }).finally(() => {
                this.loading = false
            })
        } else {
            get_user_info().then(res => {
                this.initialName = res.datas.realName;
                this.form.ticketInfo.initialName = res.datas.realName;
            })
        }
        //this.$refs.form.resetFields();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        orderStatusToDisabled() {
            // 订单状态  10=预订，11=待支付，12=已支付，13=超标审批中，21=待出票，22=出票成功，23=出票失败，24=作废，90=已取消
            const orderStatus = this.orderStatus
            if (orderStatus !== '' && orderStatus !== 10 && orderStatus !== 11 && orderStatus !== 13) {
                return true
            } else {
                return false
            }
        },
        orderStatusAndEvectionTypeToDisabled() {
            let hasBusinessInsurance = false;
            const insuranceProductList = this.insuranceProductList
            const evectionType = this.form.customerInfo.evectionType;
            const orderStatus = this.orderStatus
            insuranceProductList.forEach(value => {
                if (value.freeProduct !== 1) {
                    hasBusinessInsurance = true
                }
            });
            if (!hasBusinessInsurance && evectionType !== 1) {
                return true
            } else {
                // 订单状态  10=预订，11=待支付，12=已支付，13=超标审批中，21=待出票，22=出票成功，23=出票失败，24=作废，90=已取消
                if (orderStatus !== '' && orderStatus !== 10 && orderStatus !== 11 && orderStatus !== 13) {
                    return true
                } else {
                    return false
                }
            }
        },
    },
    filters: {},
    methods: {
        handleSelectDelete(){
            this.form.passengerList = [
                {
                    userId: '',
                    segmentType: 1,
                    segmentTypeText: '单程',
                    psgName: '',
                    psgType: 1,
                    psgTypeText: '成人',
                    cardType: 1,
                    cardTypeText: '身份证',
                    cardNo: '',
                    birthday: '',
                    mobile: '',
                    ticketNumber: '',
                    pnr: '',
                    price: 0,
                    infrastructure: 0,
                    fuel: 0,
                    commission: 0,
                    serviceCharge: 0,
                    purchasePrice: 0,
                    buyerPrice: 0,
                    countryCode: 'CN',
                    issCountryCode: 'CN',
                    cardExpireDate: '',
                    familyName: '',
                    givenName: '',
                    tagNames: [], // 标签数组
                }
            ];
        },
        handleSelectCallback(value){
            let dataBox = {
                companyId: this.form.customerInfo.companyId,
                currentPage: 1,
                isSelectCustomer: true,
                key: '',
                pageSize: 10,
                userIds: [value.userId],
            };
            let pnr = this.form.passengerList[0].pnr || '';
            consumer_department_getStaffsAndExCusByUserIds(dataBox).then((res) => {
                res.datas.list.forEach((item) => {
                    if (item.userId == value.userId) {
                        let birthday = '';
                        if (item.certificateType == 1 && item.certificateValue) {
                          birthday = `${item.certificateValue.substr(6, 4)}-${item.certificateValue.substr(10, 2)}-${item.certificateValue.substr(12, 2)}`;
                        }
                        if (!birthday && item.birthDay) {
                          birthday = item.birthDay;
                        }
                        this.form.passengerList = [
                            {
                                userId: item.userId,
                                segmentType: this.form.journeyInfo.length == 1 ? 1 : this.form.journeyInfo.length == 2 ? 2 : 3,
                                segmentTypeText: this.form.journeyInfo.length == 1 ? '单程' : this.form.journeyInfo.length == 2 ? '往返' : '多程',
                                psgName: item.name,
                                psgType: 1,
                                psgTypeText: '成人',
                                cardType: item.certificateType,
                                cardTypeText: item.certificateName,
                                cardNo: item.certificateValue,
                                birthday: birthday,
                                mobile: item.phone,
                                ticketNumber: '',
                                pnr: pnr,
                                price: 0,
                                infrastructure: 0,
                                fuel: 0,
                                serviceCharge: this.serviceCharge,
                                purchasePrice: 0,
                                buyerPrice: 0,
                                commission: 0,
                                countryCode: item.certificates[0].countryCode ? item.certificates[0].countryCode : 'CN',
                                issCountryCode: item.certificates[0].issCountryCode ? item.certificates[0].issCountryCode : 'CN',
                                cardExpireDate: item.certificates[0].cardExpireDate ? item.certificates[0].cardExpireDate : '',
                                familyName: '',
                                givenName: '',
                                tagNames: item.tags ? item.tags : [],
                                tags: item.tags ? item.tags : [],
                            }
                        ];
                    }
                })
            });
        },
        toSelectType(){
            // this.form.customerInfo.companyId
            let params = {
                companyId: this.form.customerInfo.companyId,
            };
            if (this.centerCodeBox != null && this.centerCodeBox != '' && this.centerCodeBox.indexOf('E') != -1) {
                this.$refs.belongToProjectListRef.init(params);
            }
        },
        handleSelectChange(value){
            // 将 startDate 转换为日期对象
            const startDateObj = new Date(value.startDate);
            // 计算有效期（以天数表示）的毫秒数
            const validityDays = value.validity * 24 * 60 * 60 * 1000;
            // 计算 endDate 的日期对象
            const endDateObj = new Date(startDateObj.getTime() + validityDays);
            // 将 endDate 转换回日期字符串，以便存储或显示
            value.endDate = moment(endDateObj).format('YYYY-MM-DD HH:mm:ss');
            // value.endDate = endDateObj.toISOString().split('T')[0]; // 将日期对象转为 'YYYY-MM-DD' 格式的字符串
        },
        addAirportPost(){
          let data = this.addAirportForm;
          this.addAirportLoading = true;
          consumer_flight_search_addInternaTionalAirlines(data)
            .then(async () => {
              {
                this.form.sourceType = 2;
                await this.getAirlinesInternational();
                this.airlineCodeList = this.airlineCodeList_international;
                this.addAirportLoading = false;
                this.addAirport = false;

                this.form.journeyInfo[this.addAirportIndex].airlineName = data.airCompanyName;
                this.form.journeyInfo[this.addAirportIndex].airlineCode = data.airlineCode;

                this.$message({
                  message: '添加航司成功',
                  type: 'success'
                });
              }
            })
            .catch(rej => {
              this.$message.error(rej.data.msg);
              this.addAirportLoading = false;
              this.addAirport = false;
            });
        },
        // 添加航司--弹框
        addAirportClick(index){
          this.addAirportForm.airCompanyName = '';
          this.addAirportForm.airlineCode = '';
          this.addAirport = true;
          this.addAirportIndex = index;
        },
        //  选择联系人
        addNewContact(){
          if (!this.form.customerInfo.companyId) {
            this.$message({
              type: "warning",
              message: '请先选择所属企业'
            });
            return
          }
          this.selectedIdListB = [];
          this.$refs.bTravelerMultipleSelector.show();
        },
        radioSourceType(val){
            console.log(val)
            // 12373 【差旅平台端】机票后台生单--国际票保险信息屏蔽掉“线上投保”
            if (val === 1) {
                this.insuranceSourceList = [{code: 1, msg: '线上投保'}, {code: 2, msg: '线下投保'}];
                // 获取国内服务费
                this.form.adultPrice.serviceCharge = this.serviceCharge;
                this.form.childPrice.serviceCharge = this.serviceCharge;
                this.form.babyPrice.serviceCharge = this.serviceCharge;
                this.$nextTick(()=>{
                    this.$refs.form.validateField(['adultPrice.serviceCharge'])
                })
            } else {
                this.insuranceSourceList = [{code: 2, msg: '线下投保'}];
                this.form.insuranceInfo.forEach(value=>{
                    value.insuranceSource = 2;
                })
                // 获取国际服务费
                const params = {
                    companyId: this.form.customerInfo.companyId,
                    evectionType: this.form.customerInfo.evectionType,
                    businessType: 1,
                };
                this.getInternationalServiceFee(params);
            }
          // 清空机场选项，更换机场List
          let switchMethodTwo = () => {
            this.form.journeyInfo.forEach((item,index) => {
              item.depAirportName = '';
              item.depAirportCode = '';
              item.arrAirportName = '';
              item.arrAirportCode = '';
              item.sourceType = this.form.sourceType;
            });
            if (this.form.sourceType == 2) {
              this.startCityList = JSON.parse(JSON.stringify(this.allCityList_international));
              this.endCityList = JSON.parse(JSON.stringify(this.allCityList_international));
            } else {
              this.startCityList = JSON.parse(JSON.stringify(this.allCityList));
              this.endCityList = JSON.parse(JSON.stringify(this.allCityList));
            }
          };
          // 清空航司选项，更换航司List
          let switchMethod = () => {
            this.form.journeyInfo.forEach((item,index) => {
              item.airlineName = '';
              item.airlineCode = '';
                item.sourceType = this.form.sourceType;
            });
            if (this.form.sourceType == 2) {
              this.airlineCodeList = this.airlineCodeList_international;
            } else {
              this.airlineCodeList = this.airlineCodeList_domestic;
            }
          };
          // 首次请求接口，获取数据
          if (this.airlineCodeList_international.length == 0) {
            const params = {
              currentPage: 1,
              pageSize: 999,
            };

            consumer_flight_search_InternaTionalAirlines(params).then(res => {
              this.airlineCodeList_international = res.tbdAirlineTwoWords;
              switchMethod();
            });
            consumer_flight_search_internationalCitys().then(res => {
              let cityList = [];
              res.citys.forEach(value => {
                if (value.airports) {
                  cityList.push(value)
                }
              });
              this.allCityList_international = cityList;
              switchMethodTwo();
            })
          }
          switchMethod();
          switchMethodTwo();
          // 机建切换为税，使燃油税为0
          this.form.adultPrice.infrastructure = 0;
          this.form.adultPrice.fuel = 0;

          // 由于 国际国内选项 字段跟随了航班信息(journeyInfo)即每个航班信息里面都拥有 国际国内字段,
          // 但是在原型描述中 国际国内选项 独立于航班信息即 国际国内选项 不随着航班信息的增加减少而改变
          // 所以在 国际国内 切换时将外部的 国际国内字段 写入航班信息中的 国际国内 字段----2023-06-02
          this.form.journeyInfo.forEach((item,index) => {
            item.sourceType = this.form.sourceType;
          });

          // 乘机人信息，证件类型转换为护照类型
          if (this.form.sourceType == 2) {
            this.form.passengerList.forEach((item,index) => {
              item.cardType = 2;
              item.cardTypeText = '护照';
              item.cardNo = '';
            });
          }


          // 获取服务费

        },
        clickPnrVerify(){
            if (!document.getElementsByClassName('red-text')[0]) return
            if (this.activeName == 2 && this.pnrForm.pnrCode == '') {
              if (document.getElementsByClassName('red-text')[0].className.indexOf(' red-text-after') == -1) {
                document.getElementsByClassName('red-text')[0].className = `${document.getElementsByClassName('red-text')[0].className} red-text-after`;
              }
            } else {
              document.getElementsByClassName('red-text')[0].className = document.getElementsByClassName('red-text')[0].className.replace(' red-text-after', '');
            }
        },
        isInsureCheckedChange(item,index){
            console.log(item);
          this.form.insuranceInfo[index].insuranceSource =2;
          // this.form.insuranceInfo[index].productCodes = [];
          this.form.insuranceInfo[index].otherProductCodes = [];
          this.form.insuranceInfo[index].isInsure = item ? 1 : 0;
          this.form.insuranceInfo[index].productName = '';
          this.form.insuranceInfo[index].premium = '';
          this.form.insuranceInfo[index].insureCost = '';
          this.form.insuranceInfo[index].startDate = '';
          this.form.insuranceInfo[index].endDate = '';
          this.form.insuranceInfo[index].payType = 3;
          if (item == true) {
              // 勾上
              // 如果返回的只有赠送保险 应该像操作客服那样默认勾选 以及禁用
              let hasBusinessInsurance = false;
              this.insuranceProductList.forEach(value => {
                  if (value.freeProduct !== 1) {
                      hasBusinessInsurance = true
                  }
              });
              if (!hasBusinessInsurance && this.form.customerInfo.evectionType !== 1 && this.insuranceProductList.length > 0) {
                  const item = this.insuranceProductList[0].productCode;
                  this.form.insuranceInfo[index].productCodes = [item];
              }
          } else {
              // 取消
              this.form.insuranceInfo[index].productCodes = [];
          }
        },
        async init() {
            this.form = this.reactForm();
            this.$nextTick(()=>{
                this.$refs.form.clearValidate();
            })
            console.log('-------', this.form);
            this.$forceUpdate();
            this.getEvectionTypeList();
            this.getAirlines();
            this.getAirlinesInternational();
            this.getPayTypeList();
            this.getPsgType();
            this.getCertificateType();
            this.getGenerateUserName();
            this.getCabinRank();
            this.getInsurancePayTypeList();
            this.getCity();
            this.getCityInternational();
            this.queryCountryTwoWord();
            this.getOfficialTicketChannel();
            this.getPaymentTypeList();
            await this.getPurchaseChannel();
            await this.getCompanyList();
        },

        //获取支付方式枚举值
        async getPaymentTypeList() {
            let res = await consumer_flight_report_order_offlineOutPayType();
            this.paymentTypeList = res.datas;
        },

        getOfficialTicketChannel() {
            consumer_admin_flight_generate_officialTicketChannel().then(res => {
                this.officialTicketChannelList = res.datas
            })
        },
        queryCountryTwoWord() {
            consumer_country_queryCountryTwoWord().then(res => {
                this.countryList = res.datas
            })
        },

        async getCompanyList() {
            const params = {
                companyName: '',
                currentPage: 1,
                keyword: '',
                pageSize: 999,
            }
            let res = await consumer_admin_flight_generate_getCompanyList(params)
            this.companyList = res.datas.list
        },
        getEvectionTypeList() {
            consumer_admin_flight_generate_evectionType().then(res => {
                this.evectionTypeList = res.datas
            })
        },
        getPayTypeList() {
            consumer_admin_flight_generate_payType().then(res => {
                this.payTypeList = res.datas
            })
        },
        getAirlinesInternational() {
          const params = {
            currentPage: 1,
            pageSize: 999,
          };
          return consumer_flight_search_InternaTionalAirlines(params).then(res => {
            this.airlineCodeList_international = res.tbdAirlineTwoWords;
          });
        },
        getAirlines() {
            const params = {
                currentPage: 1,
                pageSize: 999,
            };
            consumer_flight_search_airlines(params).then(res => {
                this.airlineCodeList = res.pageResult.pageData;
                this.airlineCodeList_domestic = res.pageResult.pageData;
            })
        },
        getGenerateUserName() {
            consumer_admin_flight_generate_userName().then(res => {
                this.generateUserNameList = res.datas
            })
        },
        getPsgType() {
            consumer_admin_flight_generate_psgType().then(res => {
                this.psgTypeList = res.datas
            })
        },
        getCertificateType() {
            consumer_admin_flight_generate_certificateType().then(res => {
                this.certificateTypeList = res.datas
            })
        },
        getCabinRank() {
            consumer_admin_flight_generate_cabinRank().then(res => {
                this.cabinRankList = res.datas
            })
        },
        getInsuranceProductList(params) {
            consumer_admin_flight_generate_getInsuranceProductList(params).then(res => {
                this.insuranceProductList = res.datas.insuranceProductVoList;
                // if (this.type !== 'create') {
                //     return
                // }
                // 如果返回的只有赠送保险 应该像操作客服那样默认勾选 以及禁用
                let hasBusinessInsurance = false;
                this.insuranceProductList.forEach(value => {
                    if (value.freeProduct !== 1) {
                        hasBusinessInsurance = true
                    }
                });
                if (!hasBusinessInsurance && this.form.customerInfo.evectionType !== 1 && this.insuranceProductList.length > 0) {
                    const item = this.insuranceProductList[0].productCode;
                    this.form.insuranceInfo.forEach(value => {
                      if (value.productCodes == null) {
                        value.productCodes = [];
                      }
                      value.productCodes.push(item)
                    });
                } else if (this.insuranceProductList.length === 1) {
                    // 如果只有一个保险可选, 默认选上
                    const item = this.insuranceProductList[0].productCode;
                    this.form.insuranceInfo.forEach(value => {
                      if (value.productCodes == null) {
                        value.productCodes = [];
                      }
                      value.productCodes.push(item)
                    });
                }
            })
        },
        getOtherInsuranceProductList(params) {
            consumer_admin_flight_generate_getOtherInsuranceProductList(params).then(res => {
                this.otherInsuranceProductList = res.datas.insuranceProductVoList
            })
        },
        getServiceFee(params) {
            consumer_admin_flight_generate_configServiceFee(params).then(res => {
                let serviceFee = res.datas ? res.datas.serviceFee : '';
                if (this.form.customerInfo.companyId == '238' && !serviceFee) {
                    serviceFee = 0;
                }
                this.serviceCharge = serviceFee;
                this.form.passengerList.forEach(value => {
                    value.serviceCharge = serviceFee
                })
                this.form.adultPrice.serviceCharge = serviceFee;
                this.form.childPrice.serviceCharge = serviceFee;
                this.form.babyPrice.serviceCharge = serviceFee;
                this.$nextTick(()=>{
                    this.$refs.form.validateField(['adultPrice.serviceCharge'])
                })
            })
        },
        getInternationalServiceFee(params) {
            let citycds = [];
            this.form.journeyInfo.forEach(value=>{
                if (value.depAirportCode) {
                    citycds.push(value.depAirportCode)
                }
                if (value.arrAirportCode) {
                    citycds.push(value.arrAirportCode)
                }
            })
            if (citycds.length === 0) {
                return
            }
            params.citycds = citycds;
            consumer_admin_flight_generate_configInternationalServiceFee(params).then(res => {
                let serviceFee = res.datas ? res.datas.serviceFee : '';
                if (this.form.customerInfo.companyId == '238' && !serviceFee) {
                    serviceFee = 0;
                }
                this.intServiceCharge = serviceFee;
                this.form.passengerList.forEach(value => {
                    value.serviceCharge = serviceFee
                })
                this.form.adultPrice.serviceCharge = serviceFee;
                this.form.childPrice.serviceCharge = serviceFee;
                this.form.babyPrice.serviceCharge = serviceFee;
                this.$nextTick(()=>{
                    this.$refs.form.validateField(['adultPrice.serviceCharge'])
                })
            })
        },
        async getPurchaseChannel() {
            let res = await consumer_admin_flight_generate_purchaseChannel()
            this.purchaseChannelList = res.datas
            let purchaseChannelListResult = []
            this.purchaseChannelList.forEach(value => {
                if ((this.form.ticketInfo.orderSource === 3 && (value.code !== 6 && value.code !== 7)) || (this.form.ticketInfo.orderSource !== 3 && (value.code === 6 || value.code === 7))) {
                    purchaseChannelListResult.push(value)
                }
                this.purchaseChannelListResult = purchaseChannelListResult
            })
        },
        async getLinkmanList() {
            let params = {
                companyId: this.form.customerInfo.companyId,
                currentPage: 1,
                pageSize: 99,
            }
            let res = await consumer_admin_flight_generate_getLinkmanList(params)
            const linkmanList = res.datas.list
            this.form.customerInfo.contactName = linkmanList[0].userName
            this.form.customerInfo.contactMobile = linkmanList[0].phone
            this.$nextTick(() => {
                this.$refs.form.validateField(['customerInfo.contactName', 'customerInfo.contactMobile'])
            })
        },
        getInsurancePayTypeList() {
            consumer_admin_flight_generate_getInsurancePayTypeList().then(res => {
                this.insurancePayTypeList = res.datas
            })
        },
        getCityInternational() {
          consumer_flight_search_internationalCitys().then(res => {
            let cityList = [];
            res.citys.forEach(value => {
              if (value.airports) {
                cityList.push(value)
              }
            });
            this.allCityList_international = cityList;
          })
        },
        getCity() {
            consumer_flight_search_citys().then(res => {
                let cityList = [];
                res.citys.forEach(value => {
                    if (value.airports) {
                        cityList.push(value)
                    }
                });
                this.allCityList = cityList;
                this.startCityList = JSON.parse(JSON.stringify(this.allCityList));
                this.endCityList = JSON.parse(JSON.stringify(this.allCityList));
            })
        },
        filterMethodToCity(val) {
            let dataList = [];
            if (this.form.sourceType == 2) {
                dataList = this.allCityList_international;
            } else {
                dataList = this.allCityList;
            }
            if (val) {
                this.startCityList = dataList.filter(item => {
                    const reg = new RegExp(`^${val}`, `gi`);
                    let flag = false;
                    const code = item.code || '';
                    const name = item.name || '';
                    const spell = item.spell || '';
                    const cityCode = item.cityCode || '';
                    const cityName = item.cityName || '';
                    flag = flag || (code.match(reg));
                    flag = flag || (name.match(reg));
                    flag = flag || (spell.match(reg));
                    flag = flag || (cityCode.match(reg));
                    flag = flag || (cityName.match(reg));
                    return flag
                })
            } else {
                this.startCityList = JSON.parse(JSON.stringify(dataList))
            }
        },
        filterMethodToEndCity(val) {
            let dataList = [];
            if (this.form.sourceType == 2) {
                dataList = this.allCityList_international;
            } else {
                dataList = this.allCityList;
            }
            if (val) {
                this.endCityList = dataList.filter(item => {
                    const reg = new RegExp(`^${val}`, `gi`);
                    let flag = false;
                    const code = item.code || '';
                    const name = item.name || '';
                    const spell = item.spell || '';
                    const cityCode = item.cityCode || '';
                    const cityName = item.cityName || '';
                    flag = flag || (code.match(reg));
                    flag = flag || (name.match(reg));
                    flag = flag || (spell.match(reg));
                    flag = flag || (cityCode.match(reg));
                    flag = flag || (cityName.match(reg));
                    return flag
                })
            } else {
                this.endCityList = JSON.parse(JSON.stringify(dataList))
            }
        },


        depDateTimeDisableDate(time, journeyIndex) {
          if (this.form.journeyInfo.length === 2 && journeyIndex === 1) {
            let oldTime = `${moment(this.form.journeyInfo[0].arrDateTime).format('yyyy-MM-DD')} 00:00:00`;
            // 后台生单填写国际机票的时候 要把到达时间给放开； 北京起飞是9号，到了美国之后 美国时间是8号
            if (this.form.sourceType === 1) {
                return time.getTime() < new Date(oldTime).getTime();
            } else {
                return false;
            }

          } else {
              return false;
          }
        },
        arrDateTimeDisableDate(time, journeyIndex) {
            let value = this.form.journeyInfo[journeyIndex].depDateTime;
            let m = this.$moment(value);
            const second = m.second();
            const minute = m.minute();
            const hour = m.hour();
            const millisecond = (hour * 60 * 60 + minute * 60 + second) * 1000;
            // 国际票因考虑时差，所以放开时间选择限制
            if (this.form.sourceType === 1) {
                if (value) {
                    return time.getTime() < new Date(value).getTime() - millisecond;
                } else {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            } else {
                return false;
            }

        },
        changeAirport() {
            const params = {
                companyId: this.form.customerInfo.companyId,
                evectionType: this.form.customerInfo.evectionType,
                businessType: 1,
            };
            if (this.form.sourceType === 2) {
                this.getInternationalServiceFee(params);
            }
        },
        changeCompanyId(val) {
            let activeItem = null;
            this.companyList.forEach(value => {
                if (value.id === val) {
                    activeItem = value
                }
            });
            this.freeProduct = activeItem.freeProduct;
            this.form.customerInfo.companyName = activeItem.companyName;
            // 清除联系人
            this.form.customerInfo.contactName = '';
            this.form.customerInfo.contactMobile = '';
            this.centerCodeBox = '';
            this.projectNameBox = '';
            this.projectCodeBox = '';
            this.companyIdBox = 0;
            const params = {
                companyId: val,
                evectionType: this.form.customerInfo.evectionType,
                businessType: 1,
                reservationSource: 3,
            };
            // if (this.activeName ==='1') {
            //     this.getLinkmanList(params);
            // }
            // 清除选中的保险
            this.form.insuranceInfo.forEach((item,index) => {
                item.productCodes = [];
                item.otherProductCodes = [];
            });
            this.serviceCharge = 0;
            this.getInsuranceProductList(params);
            this.getOtherInsuranceProductList(params);
            this.$refs.costAttributionList.init(params);
            // 区分国内还是国际调用不同的服务费接口
            if (this.form.sourceType === 1) {
                this.getServiceFee(params);
            } else {
                this.getInternationalServiceFee(params);
            }
            // 更新初始化动态表单
            this.$refs.aDynamicForm.init({
                showAttribution: true,
                immutableSwitch: false,
                companyId: Number(val),
                // 业务类型：1机票、2火车票、3酒店、4汽车
                businessType: 1,
                // 是否使用默认数据
                mock_data: {
                    enable: false,
                },
            }).then(res=>{
                console.log('re2', res);
                this.parameterListBox = res;
                if (this.parameterListBox.indexOf('成本中心') == -1) {
                    this.$refs.costAttributionList.init(params);
                }
            });
            // 更新初始化用户选择组件
            this.$refs.aTravelerMultipleSelector.init_parameter.get_pager_data_parameter = () => {
                const d = {
                    companyId: val,
                };
                return Q.resolve(d);
            };
            this.$refs.bTravelerMultipleSelector.init_parameter.get_pager_data_parameter = () => {
              const d = {
                companyId: val,
              };
              return Q.resolve(d);
            };
            this.$refs.aTravelerMultipleSelector.init({
                dialog: {
                    title: `请选择出行人员`,
                    width: '1100px'
                },
                // select_mode: `single`,
                select_mode: `multiple`,
                // 场景
                // 000:代客下单,
                // 100:行程预订首页，选择出行人（1.有差标管控;2.能选择外部客户;）
                // 101:出差申请页面，选择出行人（1.没有差标管控;2.能选择外部客户;）
                // 102:出差申请页面，选择审批人员（1.没有差标管控;2.不能选择外部客户;）
                situation: `000`,
                // situation: `100`,
                // situation: `101`,
                // situation: `102`,
                businessType: 1,
            });
            this.$refs.bTravelerMultipleSelector.init({
              dialog: {
                title: `请选择出行人员`,
                width: '1100px'
              },
              select_mode: `single`,
              // select_mode: `multiple`,
              // 场景
              // 000:代客下单,
              // 100:行程预订首页，选择出行人（1.有差标管控;2.能选择外部客户;）
              // 101:出差申请页面，选择出行人（1.没有差标管控;2.能选择外部客户;）
              // 102:出差申请页面，选择审批人员（1.没有差标管控;2.不能选择外部客户;）
              situation: `000`,
              // situation: `100`,
              // situation: `101`,
              // situation: `102`,
              businessType: 1,
            });
        },
        clearCompany() {
            this.form.customerInfo.contactName = '';
            this.form.customerInfo.contactMobile = '';
            this.form.insuranceInfo.forEach((item,index) => {
              item.productCodes = [];
              item.otherProductCodes = [];
            });
            this.parameterListBox = [];
        },
        disabledArrDepAirportCode(index,code){
          if (this.form.journeyInfo[index].arrAirportCode == code) {
            return true
          }
        },
        disabledArrAirportCode(index,code){
          if (this.form.journeyInfo[index].depAirportCode == code) {
            return true
          }
        },
        // 删除行程
        deleteTripRequestItem(item,index){
          this.form.journeyInfo.splice(index, 1);
          this.form.journeyInfo.forEach((item,index) => {
            item.segmentType = index + 1;
            item.segmentTypeText = `第${index + 1}程`;
          });
          this.form.adultPrice.infrastructure = this.form.journeyInfo.length * 50;
          // 往返添加航程时回填信息
          // if (this.form.journeyInfo.length == 2) {
          //   this.form.journeyInfo[1].airlineCode = this.form.journeyInfo[0].airlineCode;
          //   this.form.journeyInfo[1].airlineName = this.form.journeyInfo[0].airlineName;
          //
          //
          //   this.form.journeyInfo[1].arrAirportCode = this.form.journeyInfo[0].depAirportCode;
          //   this.form.journeyInfo[1].arrAirportName = this.form.journeyInfo[0].depAirportName;
          //
          //   this.form.journeyInfo[1].depAirportCode = this.form.journeyInfo[0].arrAirportCode;
          //   this.form.journeyInfo[1].depAirportName = this.form.journeyInfo[0].arrAirportName;
          // };
        },
        // 添加行程
        addNewTripRequestItem(item,index){
          if (this.form.journeyInfo.length > 9) {
            this.$message({
              type: "warning",
              message: '最多只能增加10个行程'
            });
            return
          }

          // 记录 到达机场、出发机场三字码--
          let depAirportCodeBox = '';
          let arrAirportCodeBox = '';
          if (this.form.journeyInfo.length == 1) {
            // 去程返程时联动--记录去程的出发机场到达机场三字码
            depAirportCodeBox = this.form.journeyInfo[0].arrAirportCode;
            arrAirportCodeBox = this.form.journeyInfo[0].depAirportCode;
          }

          const newItem = {
              sourceType: this.form.sourceType,
            airlineName: '',
            airlineCode: '',
            flightNo: '',
            depCityObj: null,
            arrCityObj: null,
            depAirportName: '',
            depAirportCode: depAirportCodeBox,
            depCityName: '',
            depCityCode: '',
            arrAirportName: '',
            arrAirportCode: arrAirportCodeBox,
            arrCityName: '',
            arrCityCode: '',
            depDateTime: '',
            arrDateTime: '',
            depDate: '',
            depTime: '',
            arrDate: '',
            arrTime: '',
            cabin: '',
            cabinRank: 3,
            cabinRankText: '经济舱',
            segmentNo: '',
            segmentType: this.form.journeyInfo.length + 1,
            segmentTypeText: `第${this.form.journeyInfo.length + 1}程`,
            depTerminal: 'T1',
            arrTerminal: 'T1',
            // keyAccountCode: '', // 大客户号
          };
          // 往返添加航程时回填信息
          // 当去程填写了机场信息，返程需要联动信息，但是在实际过程中，去程的三字码可以带到返程的三字码
          // 但是没有触发视图更新，尝试方法：this.$set()、focus()、this.$forceUpdate()、setTimeout
          let switchType = false;
          if (this.form.journeyInfo.length == 1) {
            this.form.journeyInfo.push(newItem);
            this.form.journeyInfo.push(newItem);
            switchType = true;
          } else {
            this.form.journeyInfo.splice(index + 1, 0, newItem);
          }
          this.form.journeyInfo.forEach((item,index) => {
            item.segmentType = index + 1;
            item.segmentTypeText = `第${index + 1}程`;
          });
          // 行程-机建联动
          this.form.adultPrice.infrastructure = this.form.journeyInfo.length * 50;
          if (switchType) {
            // 往返添加航程时回填信息
            this.$nextTick(() => {
              // 获取焦点
              this.$refs['depDomesticTicketAirportSelector'][2].focus();
              this.$refs['arrDomesticTicketAirportSelector'][2].focus();
              //删除
              this.form.journeyInfo.splice(1, 1);
              this.form.journeyInfo[1].segmentTypeText = '第2程';
              this.form.journeyInfo[1].segmentType = 2;
              this.form.journeyInfo[1].airlineCode = this.form.journeyInfo[0].airlineCode;
              this.form.journeyInfo[1].airlineName = this.form.journeyInfo[0].airlineName;
            });
            this.form.adultPrice.infrastructure = 100;
          }

          console.log(this.form);

          // 机票后台生单，行程选多个 的时候，保险来源先把线上投保置灰
            // 所以如果已经选了线上投保，把线上换回线下
            this.form.insuranceInfo.forEach(value=> {
                value.insuranceSource = 2;
            })
        },
        // 添加出行人
        addNewPassengerRequestItem(item, index) {
            if (this.form.passengerList.length > 8) {
                this.$message({
                    type: "warning",
                    message: '最多只能增加9个出行人'
                })
                return
            }
            let productCodes = [];
            let otherProductCodes = [];
            this.form.insuranceInfo.forEach((item,index) => {
              if (item.productCodes != []) {
                productCodes = item.productCodes;
              }
              if (item.otherProductCodes != []) {
                otherProductCodes = item.otherProductCodes;
              }
            });

            this.addNewPassengerLoading = true;
            const newInsure = {
              insuranceSource: 2,
              productCodes,
              otherProductCodes,
              isInsure: 1,
              isInsureChecked: true,
              productName: '', // 保险名称
              insuranceOrderId: '', // 保单号
              premium: '', // 保险销售价
              insureCost: '', // 保险成本
              startDate:  this.depDateTime, // 生效时间
              endDate: '', // 失效时间
              payType: 3, // 支付方式
            };

            let cardTypeBox = 1;
            let cardTypeTextBox = '身份证';
            if (this.form.sourceType == 2) {
              cardTypeBox = 2;
              cardTypeTextBox = '护照';
            }
            const newItem = {
                userId: '',
                segmentType: 1,
                segmentTypeText: '第1程',
                psgName: '',
                psgType: 1,
                psgTypeText: '成人',
                cardType: cardTypeBox,
                cardTypeText: cardTypeTextBox,
                cardNo: '',
                birthday: '',
                mobile: '',
                ticketNumber: '',
                pnr: '',
                price: 0,
                infrastructure: 0,
                fuel: 0,
                commission:0,
                serviceCharge: 0,
                purchasePrice: 0,
                buyerPrice: 0,
                countryCode: 'CN',
                issCountryCode: 'CN',
                cardExpireDate: '',
                familyName: '',
                givenName: '',
            };
            newItem.serviceCharge = this.serviceCharge;
            if (!index) {
                index = this.form.passengerList.length - 1;
            }
            this.form.insuranceInfo.splice(index + 1, 0, newInsure);
            this.form.passengerList.splice(index + 1, 0, newItem);

            this.addNewPassengerLoading = false;
            this.getPassengerNum();
        },
        deletePassengerRequestItem(item, index) {
            this.form.passengerList.splice(index, 1);
            this.form.insuranceInfo.splice(index, 1);
            this.getPassengerNum();
        },
        changePsgType(item, index, val) {
            this.psgTypeList.forEach(value => {
                if (value.value === val) {
                    item.psgTypeText = value.msg
                }
            })
            this.getPassengerNum();
        },
        getPassengerNum() {
            let adultNum = 0;
            let childNum = 0;
            let babyNum = 0;
            this.form.passengerList.forEach(value => {
                if (value.psgType === 3) {
                    babyNum++
                } else if (value.psgType === 2) {
                    childNum++
                } else {
                    adultNum++
                }
            })
            this.adultNum = adultNum;
            this.childNum = childNum;
            this.babyNum = babyNum;
        },
        changeCertificateType(item, index, val) {
            this.certificateTypeList.forEach(value => {
                if (value.value === val) {
                    item.cardTypeText = value.msg
                }
            })
        },
        changeCabinRank(val,journeyIndex) {
            this.cabinRankList.forEach(value => {
                if (val === value.code) {
                    this.form.journeyInfo[journeyIndex].cabinRankText = value.msg
                }
            })
        },
        // 到达机场--失焦校验
        blurArrAirportCode(journeyIndex){
          this.$refs.form.validateField([`journeyInfo.${journeyIndex}.arrAirportCode`]);
        },
        // 出发机场--失焦校验
        blurDepAirportCode(journeyIndex){
          this.$refs.form.validateField([`journeyInfo.${journeyIndex}.depAirportCode`]);
        },
        // 航司名称--失焦校验
        blurAirlineCode(journeyIndex){
          this.$refs.form.validateField([`journeyInfo.${journeyIndex}.airlineCode`]);
        },
        // 航司名称--联动
        changeAirlineCode(val,index) {
            let name = null;
            let code = val;
            this.airlineCodeList.forEach(value => {
                if (value.airlineCode === val) {
                    this.form.journeyInfo[index].airlineName = value.airCompanyName;
                    name = value.airCompanyName;
                }
            });
        },
        changeOrderSource(val) {
            // console.log(val)
            // this.form.ticketInfo.ticketChannel = ''
            if (val === 3) {
                this.form.ticketInfo.ticketChannel = 3;
                this.form.ticketInfo.ticketChannelText = '携程';
            } else {
                this.form.ticketInfo.ticketChannel = 6;
                this.form.ticketInfo.ticketChannelText = 'BSP';
            }
            let purchaseChannelListResult = []
            this.purchaseChannelList.forEach(value => {
                if ((val === 3 && (value.code !== 6 && value.code !== 7)) || (val !== 3 && (value.code === 6 || value.code === 7))) {
                    purchaseChannelListResult.push(value)
                }
                this.purchaseChannelListResult = purchaseChannelListResult
            })
        },
        changeEvectionType(val) {
            if (val === 2) {
                this.form.customerInfo.payType = 1;
            } else {
                this.form.customerInfo.payType = 2;
            }
            this.form.insuranceInfo.forEach((item) => {
              item.productCodes = [];
            });
            const params = {
                companyId: this.form.customerInfo.companyId,
                evectionType: val
            };
            this.getInsuranceProductList(params)
        },


        // check
        checkMobile(rule, value, callback) {
          console.log(this.form.sourceType);
            if (!isMobile(value) && this.form.sourceType == 1) {
                callback(new Error('只能输入长度不能大于11的数字'));
                return
            }
            callback();
        },
        cardNoValidator(rule, value, callback, index, item) {
            let cardType = item.cardType;
            const date = `${value.substr(6, 4)}-${value.substr(10, 2)}-${value.substr(12, 2)}`;
            if (cardType === 1) {
                if (this.isChineseIDCardNumber(value) === false) {
                    callback(new Error('请输入正确的身份证号码'));
                    return;
                } else {
                    this.$set(item, 'birthday', date);
                    callback();
                }
            } else {
                callback();
            }
            // this.$set(item, 'birthday', date);
        },
        // 身份证号码验证
        isValidDate(year, month, day) {
            const date = new Date(year, month - 1, day);
            return (
                date.getFullYear() === year
                && date.getMonth() + 1 === month
                && date.getDate() === day
                && date.getTime() < new Date().getTime()
            );
        },
        isChineseIDCardNumber(value) {
            const testRegExp = /^[1-9]([0-9]{14}|[0-9]{16}[0-9Xx])$/;
            const areaMap = [11, 12, 13, 14, 15, 21, 22, 23, 31, 32, 33, 34, 35, 36, 37, 41, 42, 43, 44, 45, 46, 50, 51, 52, 53, 54, 61, 62, 63, 64, 65, 71, 81, 82];
            const weightMap = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            const codeMap = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
            const len = value.length;
            if (len !== 18) {
                return false;
            }
            // 模式校验
            if (!testRegExp.test(value)) {
                return false;
            }
            // 地区校验
            if (areaMap.indexOf(+value.substr(0, 2)) === -1) {
                return false;
            }
            // 18 位
            if (!this.isValidDate(+value.substr(6, 4), +value.substr(10, 2), +value.substr(12, 2))) {
                return false;
            }
            // 校验码
            const sum = value.split('').slice(0, 17).reduce((s, num, index) => {
                return s += +num * weightMap[index];
            }, 0);
            return codeMap[sum % 11] === value[17].toUpperCase();
        },
        greaterThanZero(rule, value, callback, tip) {
            if (Number(value) <= 0) {
                callback(new Error(`${tip}必须大于 0`));
            }
            callback();
        },
        greaterThanZeroWithPsgType(rule, value, callback, tip, item) {
            if (item.psgType === 1 && Number(value) <= 0) {
                callback(new Error(`${tip}必须大于 0`));
            } else if (Number(value) < 0) {
                callback(new Error(`${tip}不能为负数`))
            } else {
                callback()
            }
        },
        greaterThanOrEqualToZero(rule, value, callback, tip) {
            if (Number(value) < 0) {
                callback(new Error(`${tip}不能为负数`));
            }
            callback();
        },
        toAddMoney(item, index) {
            let money = parseFloat(item.price || '0') + parseFloat(item.infrastructure || '0') + parseFloat(item.fuel || '0') + parseFloat(item.otherTax || '0')
            let purchasePrice = parseFloat(item.price || '0') + parseFloat(item.infrastructure || '0') + parseFloat(item.fuel || '0') + parseFloat(item.otherTax || '0') - parseFloat(item.commission || '0')
            item.buyerPrice = money.toString()
            item.purchasePrice = purchasePrice.toString()
            this.$nextTick(() => {
                this.$refs.form.validateField(`${index === 0 ? 'adultPrice' : item.psgType == 2 ? 'childPrice' : 'babyPrice'}.buyerPrice`)
            })
        },
        priceToNum(item, price) {
            console.log("before", item)
            if (item != '') {
                item = parseFloat(item).toString()
                console.log("after", item)
                if (item == 'NaN') {
                    item = 0;
                    console.log("convert", item)
                }
                this.form.adultPrice[price] = item
            }
        },
        // 是否投保--切换清空校验结果
        isInsureInput(index){
          const text = [`insuranceInfo.${index}.insuranceSource`,`insuranceInfo.${index}.productName`,`insuranceInfo.${index}.insuranceOrderId`,`insuranceInfo.${index}.startDate`,`insuranceInfo.${index}.premium`,`insuranceInfo.${index}.insureCost`,`insuranceInfo.${index}.payType`,`insuranceInfo.${index}.endDate`];
          this.$refs.form.clearValidate(text);
        },
        // submit
        onSubmit() {
            let isPass = false;
            let unValidObj = null;
            let dataList = [];
            if (this.form.sourceType == 2) {
              dataList = this.allCityList_international;
            } else {
              dataList = this.allCityList;
            }
            dataList.forEach(value => {
                this.form.journeyInfo.forEach((item,index) => {
                  if (value.code === item.depAirportCode) {
                    item.depAirportName = value.name;
                    item.depCityCode = value.cityCode;
                    item.depCityName = value.cityName;
                  }
                  if (value.code === item.arrAirportCode) {
                    item.arrAirportName = value.name;
                    item.arrCityCode = value.cityCode;
                    item.arrCityName = value.cityName;
                  }
                });
            });

// 动态表单
            //let formInfo1 = this.$refs.aDynamicForm.getData();
            //console.log(formInfo1)
            //return;

            this.$refs.form.validate((valid, obj) => {
                isPass = valid;
                unValidObj = obj;
            });
            // 动态表单
            let formInfo = this.$refs.aDynamicForm.getData();
            if (this.form.customerInfo.evectionType === 1) {
                formInfo = {
                    checkForm: true,
                    list: [],
                }
            }
            if (!formInfo.checkForm && this.form.customerInfo.evectionType === 2) {
                const listLength = formInfo.list.length;
                for (let i = 0; i < listLength; i++) {
                    if (!formInfo.list[i].parameterForm) {
                        const text = `请填写${formInfo.list[i].parameterText}`;
                        this.$message({
                            type: "warning",
                            message: text
                        });
                        break
                    }
                }
                return
            }

            if (isPass === true && formInfo.checkForm) {
                this.addOrder(formInfo);
            } else {
                let tipsStr = '';
                if (!isPass) {
                    const unValidKeys = Object.keys(unValidObj);
                    unValidKeys.forEach((item, index) => {
                        let tmpList = item.split('.');
                        let len = tmpList.length;

                        if (index > 0) {
                            tipsStr += '；</p>';
                        }

                        if (len === 2) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}-${tipsObj[tmpList[1]]}`;
                        }
                        if (len === 3) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}第${Number(tmpList[1]) + 1}行-${tipsObj[tmpList[2]]}`;
                        }
                    });
                    tipsStr += '；</p>';
                }
                if (!formInfo.checkForm) {
                    const listLength = formInfo.list.length;
                    for (let i = 0; i < listLength; i++) {
                        if (!formInfo.list[i].parameterForm) {
                            tipsStr += `<p style="color: red;">请填写${formInfo.list[i].parameterText}</p>`;
                            break
                        }
                    }
                }

                this.$alert(tipsStr, '以下信息未填写', {
                    confirmButtonText: '确定',
                    dangerouslyUseHTMLString: true,
                });
            }
        },
        addOrder(formInfo) {
            let body = JSON.parse(JSON.stringify(this.form));
            body.projectCode = this.projectNameBox;
            body.projectName = this.projectCodeBox;

            // 如果采购渠道是没有枚举值，是填上去的，就把ticketChannel清空，因为ticketChannel只能填入数字枚举值
            if (typeof body.ticketInfo.ticketChannel !== 'number') {
                body.ticketInfo.ticketChannel = ''
            }
            body.journeyInfo.forEach((item,index) => {
              item.depDate = this.$moment(item.depDateTime).format('YYYY-MM-DD');
              item.depTime = this.$moment(item.depDateTime).format('HH:mm');
              item.arrDate = this.$moment(item.arrDateTime).format('YYYY-MM-DD');
              item.arrTime = this.$moment(item.arrDateTime).format('HH:mm');
            });

            body.reportConfiguration = formInfo.list;
            body.reportConfiguration.forEach((value) => {
                if (value.parameter == 'GuiShuXiangMu') {
                    body.projectCode = value.parameterValue;
                    body.projectName = value.parameterText;
                }
            });
            body.reportConfiguration = body.reportConfiguration.filter(value => {return value.parameter != 'GuiShuXiangMu'});
            body.passengerList.forEach((value,index) => {
                if (value.psgType === 3) {
                    // 婴儿
                    value.price = body.babyPrice.price;
                    value.infrastructure = body.babyPrice.infrastructure;
                    value.fuel = body.babyPrice.fuel;
                    value.otherTax = body.babyPrice.otherTax;
                    value.serviceCharge = body.babyPrice.serviceCharge;
                    value.purchasePrice = body.babyPrice.purchasePrice;
                    value.buyerPrice = body.babyPrice.buyerPrice;
                    value.commission = body.babyPrice.commission;
                } else if (value.psgType === 2) {
                    // 儿童票
                    value.price = body.childPrice.price;
                    value.infrastructure = body.childPrice.infrastructure;
                    value.fuel = body.childPrice.fuel;
                    value.otherTax = body.childPrice.otherTax;
                    value.serviceCharge = body.childPrice.serviceCharge;
                    value.purchasePrice = body.childPrice.purchasePrice;
                    value.buyerPrice = body.childPrice.buyerPrice;
                    value.commission = body.childPrice.commission;
                } else {
                    // 大人
                    value.price = body.adultPrice.price;
                    value.infrastructure = body.adultPrice.infrastructure;
                    value.fuel = body.adultPrice.fuel;
                    value.otherTax = body.adultPrice.otherTax;
                    value.serviceCharge = body.adultPrice.serviceCharge;
                    value.purchasePrice = body.adultPrice.purchasePrice;
                    value.buyerPrice = body.adultPrice.buyerPrice;
                    value.commission = body.adultPrice.commission;
                }
                value.insuranceInfo = null;
                if (this.form.insuranceInfo[index].isInsure == 1) {
                  value.insuranceInfo = this.form.insuranceInfo[index];
                } else {
                  value.insuranceInfo = {
                    insuranceSource: 2,
                    productCodes: [],
                    otherProductCodes: [],
                    isInsure: 0,
                    isInsureChecked: false,
                    productName: '', // 保险名称
                    insuranceOrderId: '', // 保单号
                    premium: '', // 保险销售价
                    insureCost: '', // 保险成本
                    startDate: '', // 生效时间
                    endDate: '', // 失效时间
                    payType: 3, // 支付方式
                  };
                }
                if (value.insuranceInfo.insuranceSource == 1) {
                  value.insuranceInfo.payType = null;
                }


            });
            delete body.insuranceInfo;
            delete body.sourceType;
            if (body.ticketInfo.ticketChannel != 7) {
              body.ticketInfo.officialTicketChannel = null;
            }

            this.form.journeyInfo.forEach((item,index) => {
              item.segmentType = index + 1;
              item.segmentTypeText = `第${index + 1}程`;
            });
            let passengerListSegmentType = 3;
            let passengerListSegmentTypeText = '多程';
            if (this.form.journeyInfo.length == 1) {
              passengerListSegmentType = 1;
              passengerListSegmentTypeText = '单程';
            } else if (this.form.journeyInfo.length == 2) {
              passengerListSegmentType = 2;
              passengerListSegmentTypeText = '往返';
            }
            this.form.passengerList.forEach((item,index) => {
              item.segmentType = passengerListSegmentType;
              item.segmentTypeText = passengerListSegmentTypeText;
            });


            this.submitLoading = true;
            consumer_admin_flight_generate_saveOrUpdate(body).then(res => {
                const orderResult = res.datas.orderResult || {};
                let orderNo = orderResult ? orderResult.orderNo : '';
                // 另外传备注
                if (body.remark) {
                    const remarkParams = {
                        orderNo,
                        remark: body.remark
                    };
                    consumer_admin_flight_order_saveOrderRemark(remarkParams)
                }
                //
                const params = {
                    orderNo: this.orderNo
                };
                consumer_admin_flight_generate_confirm(params).then(res => {
                    this.$message({
                        type: 'success',
                        message: '出票成功!'
                    });
                    this.$router.replace({
                        name: 'admin-flight-order-list'
                    })
                }).finally(() => {
                    this.submitLoading = false
                })
            }).catch(()=>{
                this.submitLoading = false
            })
        },
        back() {
            this.$router.go(-1)
        },
        inputCardNo(item, val) {
            item.cardNo = val.replace(/\s+/g,'')
        },
        changeInsurance(index) {
            // 清空保险的检验结果
            const text = [`insuranceInfo.${index}.insuranceSource`,`insuranceInfo.${index}.productName`,`insuranceInfo.${index}.insuranceOrderId`,`insuranceInfo.${index}.startDate`,`insuranceInfo.${index}.premium`,`insuranceInfo.${index}.insureCost`,`insuranceInfo.${index}.payType`,`insuranceInfo.${index}.endDate`];
            this.$refs.form.clearValidate(text);
        },
        addNewPassenger() {
            if (!this.form.customerInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择所属企业'
                });
                return
            }
            this.selectedIdList = [];
            this.$refs.aTravelerMultipleSelector.show();
        },
        changePnr(index, val) {
            if (index !== 0) {
                return
            }
            this.form.passengerList.forEach(value => {
                value.pnr = val
            })
        },

        depAirportCodeFocus() {
            let dataList = [];
            if (this.form.sourceType == 2) {
              dataList = this.allCityList_international;
            } else {
              dataList = this.allCityList;
            }
            this.startCityList = JSON.parse(JSON.stringify(dataList));
        },
        arrAirportCodeFocus() {
            let dataList = [];
            if (this.form.sourceType == 2) {
              dataList = this.allCityList_international;
            } else {
              dataList = this.allCityList;
            }
            this.endCityList = JSON.parse(JSON.stringify(dataList));
        },

        toNextInput(name,index) {
            this.depDateTime = moment(this.form.journeyInfo[0].depDateTime).format('YYYY-MM-DD HH:mm:ss')
            this.form.insuranceInfo.forEach((value)=>{
                 value.startDate = this.depDateTime;
            })
            setTimeout(() => {
              if (this.$refs[name][index].focus) {
                this.$refs[name][index].focus();
              }
            }, 300)
        },
        cardExpireDateDisabledDate(val) {
            const now = moment(val).format('YYYY-MM-DD')
            const nowTime = moment(now + ' 00:00:00').valueOf()
            const today = moment().format('YYYY-MM-DD')
            const todayTime = moment(today + ' 00:00:00').valueOf();
            return nowTime < todayTime
        },
        handleClick(val) {
            this.pnrForm = {
                pnrCode: '',
            };
            // this.form.sourceType = 1;
            // this.form = JSON.parse(JSON.stringify(defaultForm));
        },
        deepClone(obj) {
            if(obj && typeof obj ==='object' ){
                var newObj= Array.isArray(obj) ? []:{};
                for (var key in obj) {
                    if(obj.hasOwnProperty(key)){
                        if(obj[key] && typeof obj[key]==='object'){
                            newObj[key]=this.deepClone(obj[key] )
                        }else {
                            newObj[key]= obj[key]
                        }
                    }
                }
            }else {
                console.error('输入参数为空或不为对象');
                return {}
            }
            return newObj
        },
        getPnrInfo() {
            this.pnrLoading = true;
            consumer_admin_flight_generate_pnrCode(this.pnrForm).then(res => {
                let form = {};
                let _dForm = this.reactForm();
                // console.log('---form--', defaultForm, _dForm);
                form = Object.assign({}, _dForm, res.datas);
                form.sourceType = this.form.sourceType;
                if (form.ticketInfo.orderSource === null) {
                    form.ticketInfo.orderSource = 4;
                }
                if (form.ticketInfo.ticketChannel === null) {
                    form.ticketInfo.ticketChannel = 6;
                }
                if (form.ticketInfo.initialName === null) {
                    form.ticketInfo.initialName = this.initialName;
                }
                if (form.ticketInfo.ticketTime === null) {
                    form.ticketInfo.ticketTime = moment().format('YYYY-MM-DD HH:mm:ss')
                }
                if (form.ticketInfo.orderTime === null) {
                    form.ticketInfo.orderTime = moment().format('YYYY-MM-DD HH:mm:ss')
                }
                if (form.ticketInfo.bookingSms === null) {
                    form.ticketInfo.bookingSms = 1
                }
                if (form.ticketInfo.linkmanSms === null) {
                    form.ticketInfo.linkmanSms = 1
                }
                if (!form.customerInfo.feeAffiliationType) {
                    form.customerInfo.feeAffiliationType = 1
                }

                if(form.journeyInfo) {
                    form.journeyInfo.forEach((item,index) => {
                        item.sourceType = this.form.sourceType;
                        this.airlineCodeList.forEach(value => {
                            if (item.airlineCode === value.airlineCode) {
                                item.airlineName = value.airCompanyName
                            }
                        });

                        item.depDateTime = `${item.depDate} ${item.depTime}`;
                        item.arrDateTime = `${item.arrDate} ${item.arrTime}`;

                        item.depCityObj = {
                            id: item.depAirportCode,
                            name: item.depAirportName
                        };
                        item.arrCityObj = {
                            id: item.arrAirportCode,
                            name: item.arrAirportName
                        };

                        item.segmentType = index + 1;
                        item.segmentTypeText = `第${index + 1}程`;
                    })
                };

                let adultNum = 0;
                let childNum = 0;
                let babyNum = 0;
                form.insuranceInfo = [];
                let reg = new RegExp("[\\u4E00-\\u9FFF]+","g");
                form.passengerList.forEach(value => {
                    if (!value.psgEnglishName && !reg.test(value.psgName)) {
                        value.psgEnglishName = value.psgName;
                        value.psgName = '';
                    }
                    if (value.psgType === 3) {
                        babyNum++
                        // 婴儿
                        form.babyPrice = {
                          psgType: 3,
                          price: value.price !== null ? value.price : 0,
                          infrastructure: value.infrastructure !== null ? value.infrastructure : 0,
                          fuel: value.fuel !== null ? value.fuel : 0,
                          serviceCharge: this.form.babyPrice.serviceCharge !== null ? this.form.babyPrice.serviceCharge : 0,
                          purchasePrice: value.purchasePrice !== null ? value.purchasePrice : 0,
                          buyerPrice: value.buyerPrice !== null ? value.buyerPrice : 0,
                          commission: value.commission !== null ? value.commission : 0,
                        }
                    } else if (value.psgType === 2) {
                        childNum++
                        // 儿童
                        form.childPrice = {
                            psgType: 2,
                            price: value.price !== null ? value.price : 0,
                            infrastructure: value.infrastructure !== null ? value.infrastructure : 0,
                            fuel: value.fuel !== null ? value.fuel : 0,
                            serviceCharge: this.form.childPrice.serviceCharge !== null ? this.form.childPrice.serviceCharge : 0,
                            purchasePrice: value.purchasePrice !== null ? value.purchasePrice : 0,
                            buyerPrice: value.buyerPrice !== null ? value.buyerPrice : 0,
                            commission: value.commission !== null ? value.commission : 0,
                        }
                    } else if (value.psgType === 1) {
                        adultNum++
                        // 成人
                        form.adultPrice = {
                            psgType: 1,
                            price: value.price !== null ? value.price : 0,
                            infrastructure: value.infrastructure !== null ? value.infrastructure : 50,
                            fuel: value.fuel !== null ? value.fuel : 0,
                            serviceCharge: this.form.adultPrice.serviceCharge !== null ? this.form.adultPrice.serviceCharge : 0,
                            purchasePrice: value.purchasePrice !== null ? value.purchasePrice : 0,
                            buyerPrice: value.buyerPrice !== null ? value.buyerPrice : 0,
                            commission: value.commission !== null ? value.commission : 0,
                        }
                    } else {
                        adultNum++
                    }

                    const newInsure = {
                      insuranceSource: 2,
                      productCodes: [],
                      otherProductCodes: [],
                      isInsure: 1,
                      isInsureChecked: true,
                      productName: '', // 保险名称
                      insuranceOrderId: '', // 保单号
                      premium: '', // 保险销售价
                      insureCost: '', // 保险成本
                      startDate: this.depDateTime, // 生效时间
                      endDate: '', // 失效时间
                      payType: 3, // 支付方式
                    };
                    form.insuranceInfo.push(newInsure);
                });

                this.adultNum = adultNum;
                this.childNum = childNum;
                this.babyNum = babyNum;
              console.log(form)
                this.form = form;

                let activeItem = null;
                this.companyList.forEach(value => {
                    if (value.id === this.form.customerInfo.companyId) {
                        activeItem = value
                    }
                });
                if (activeItem) {
                    this.freeProduct = activeItem.freeProduct;
                }
                const params = {
                    companyId: this.form.customerInfo.companyId
                };
                this.getInsuranceProductList(params);
                this.getOtherInsuranceProductList(params);
                this.$refs.costAttributionList.init(params);
                let purchaseChannelListResult = [];
                this.purchaseChannelList.forEach(value => {
                    if ((this.form.ticketInfo.orderSource === 3 && (value.code !== 6 && value.code !== 7)) || (this.form.ticketInfo.orderSource !== 3 && (value.code === 6 || value.code === 7))) {
                        purchaseChannelListResult.push(value)
                    }
                    this.purchaseChannelListResult = purchaseChannelListResult
                })
                // 更新初始化动态表单
                this.$refs.aDynamicForm.init({
                    showAttribution: true,
                    immutableSwitch: false,
                    type: 'display',
                    displayList: this.form.reportConfiguration,
                    companyId: this.form.customerInfo.companyId,
                    // 业务类型：1机票、2火车票、3酒店、4汽车
                    businessType: 1,
                    // 是否使用默认数据
                    mock_data: {
                        enable: false,
                    },
                }).then(res=>{
                    console.log('re3', res);
                    this.parameterListBox = res;
                });
                // 更新初始化用户选择组件
                this.$refs.aTravelerMultipleSelector.init_parameter.get_pager_data_parameter = () => {
                    const d = {
                        companyId: this.form.customerInfo.companyId,
                    };
                    return Q.resolve(d);
                };
                this.$refs.aTravelerMultipleSelector.init({
                    dialog: {
                        title: `请选择出行人员`,
                        width: '1100px'
                    },
                    // select_mode: `single`,
                    select_mode: `multiple`,
                    // 场景
                    // 000:代客下单,
                    // 100:行程预订首页，选择出行人（1.有差标管控;2.能选择外部客户;）
                    // 101:出差申请页面，选择出行人（1.没有差标管控;2.能选择外部客户;）
                    // 102:出差申请页面，选择审批人员（1.没有差标管控;2.不能选择外部客户;）
                    situation: `000`,
                    // situation: `100`,
                    // situation: `101`,
                    // situation: `102`,
                    businessType: 1,
                });
            }).finally(() => {
                this.pnrLoading = false;
            })
        },
        changeTicketChannel(val) {
            console.log(val);
            if (typeof val === 'number') {
                this.purchaseChannelListResult.forEach(value => {
                    if (value.code === val) {
                        this.form.ticketInfo.ticketChannelText = value.msg;
                    }
                })
            } else {
                this.form.ticketInfo.ticketChannelText = val;
            }
        },
        reactForm() {
            let time = moment().format('YYYY-MM-DD HH:mm:ss');
            console.log(moment().format('YYYY-MM-DD HH:mm:ss'), time)
            let form = {
                sourceType: 1,
                orderNo: '',
                customerInfo: {
                    companyId: '',
                    companyName: '',
                    contactMobile: '',
                    contactName: '',
                    evectionType: 2,
                    payType: 1,
                    deptId: '',
                    deptName: '',
                    oaEvectionNo: '',
                    feeAffiliationType: 1,
                },
                journeyInfo: [
                    {
                        sourceType: 1,
                        airlineName: '',
                        airlineCode: '',
                        flightNo: '',
                        depCityObj: null,
                        arrCityObj: null,
                        depAirportName: '',
                        depAirportCode: '',
                        depCityName: '',
                        depCityCode: '',
                        arrAirportName: '',
                        arrAirportCode: '',
                        arrCityName: '',
                        arrCityCode: '',
                        depDateTime: '',
                        arrDateTime: '',
                        depDate: '',
                        depTime: '',
                        arrDate: '',
                        arrTime: '',
                        cabin: '',
                        cabinRank: 3,
                        cabinRankText: '经济舱',
                        segmentNo: '',
                        segmentType: 1,
                        segmentTypeText: '第1程',
                        depTerminal: 'T1',
                        arrTerminal: 'T1',
                        // keyAccountCode: '', // 大客户号
                    },
                ],
                passengerList: [
                    {
                        userId: '',
                        segmentType: 1,
                        segmentTypeText: '单程',
                        psgName: '',
                        psgEnglishName: '',
                        psgType: 1,
                        psgTypeText: '成人',
                        cardType: 1,
                        cardTypeText: '身份证',
                        cardNo: '',
                        birthday: '',
                        mobile: '',
                        ticketNumber: '',
                        pnr: '',
                        price: 0,
                        infrastructure: 0,
                        fuel: 0,
                        commission: 0,
                        serviceCharge: 0,
                        purchasePrice: 0,
                        buyerPrice: 0,
                        countryCode: 'CN',
                        issCountryCode: 'CN',
                        cardExpireDate: '',
                        familyName: '',
                        givenName: '',
                        tagNames: [], // 标签数组
                    }
                ],
                insuranceInfo: [
                    {
                        insuranceSource: 2,
                        productCodes: [],
                        otherProductCodes: [],
                        isInsure: 1,
                        isInsureChecked: true,
                        productName: '', // 保险名称
                        channel: 2, // 保险渠道 1：华泰 2：德付通 3：旅游卫士 4：惠择
                        insuranceOrderId: '', // 保单号
                        premium: '', // 保险销售价
                        insureCost: '', // 保险成本
                        startDate: '', // 生效时间
                        endDate: '', // 失效时间
                        payType: 3, // 支付方式
                    }
                ],
                ticketInfo: {
                    orderSource: 4,
                    ticketChannel: 6,
                    ticketChannelText: 'BSP',
                    supplierOrderNo: '',
                    initialName: '',
                    ticketTime: time,
                    orderTime: time,
                    bookingSms: 1,
                    linkmanSms: 1,
                    officialTicketChannel: 1,
                    officialTicketBank: '',
                    keyAccountCode: '', // 大客户号
                    paymentType: 0
                },
                adultPrice: {
                    psgType: 1,
                    price: 0,
                    infrastructure: 50,
                    fuel: 0,
                    otherTax: 0,
                    serviceCharge: 0,
                    purchasePrice: 0,
                    buyerPrice: 0,
                    commission: 0,
                },
                childPrice: {
                    psgType: 2,
                    price: 0,
                    infrastructure: 0,
                    fuel: 0,
                    otherTax: 0,
                    serviceCharge: 0,
                    purchasePrice: 0,
                    buyerPrice: 0,
                    commission: 0,
                },
                babyPrice: {
                    psgType: 3,
                    price: 0,
                    infrastructure: 0,
                    fuel: 0,
                    otherTax: 0,
                    serviceCharge: 0,
                    purchasePrice: 0,
                    buyerPrice: 0,
                    commission: 0,
                },
                remark: ''
            };
            return form;
        },
        _changePayType(val) {
            console.log(val);
            if (val === 2) {
                this.form.ticketInfo.bookingSms = 0;
                this.form.ticketInfo.linkmanSms = 0;
            }
        },
    }
}

export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/train-account',
            name: 'admin-train-account',
            component: () => import(/* webpackChunkName: "page-admin-organizational-structure-train-account" */ `./1.0.1/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `12306账号`,
                },
                entity_list_for_breadcrumb: [
                    {name: `组织架构`},
                    {name: `12306账号`},
                ],
            },
        },
    ],
};

export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/hotel-order/cancel/detail',
            name: 'admin-hotel-order-cancel-detail',
            component: () => import(/* webpackChunkName: "page-admin-order-management-hotel-cancel-detail" */ `./1.0.3/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `取消订单详情`,
                },
                entity_list_for_breadcrumb: [
                    { name: `酒店订单` },
                    { name: `取消订单` },
                    { name: `取消订单详情` },
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        // if (!pParameter) pParameter = {};
                        // const all_route_list = pParameter.all_route_list;
                        return `admin-hotel-order-cancel`;
                    },
                },
            },
        },
    ],
};

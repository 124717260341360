export default {
    data() {
        return {
            editNum: 0,
        }
    },
    props: {
        title: {
            type: String,
            default: '报价信息'
        },
        show: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        textarea: {
            type: String,
            default: ''
        },
        needFirstCopy: {
            type: Boolean,
            default: true
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        textarea(newVal) {
            // 当打开复制窗口并第一次传入复制文案时
            if (this.editNum === 0 && newVal !== '') {
                // qq浏览器不予许模拟点击
                if (this.needFirstCopy) {
                    this.submitCopy()
                }
                /*this.$nextTick(() => {
                    console.log(this.$refs.copyBtn)
                    this.$refs.copyBtn.$el.click()
                })*/
                this.editNum++
            }
        },
        show(newVal) {
            if (newVal) {
                this.editNum = 0
            }
        },
    },
    computed: {
        copyDialogShow: {
            get() {
                return this.show
            },
            set(val) {
                this.$emit('update:show', val)
            }
        },
        copyLoading: {
            get() {
                return this.loading
            },
            set(val) {
                this.$emit('update:loading', val)
            }
        },
        copyTextarea: {
            get() {
                return this.textarea
            },
            set(val) {
                this.$emit('update:textarea', val)
            }
        }
    },
    filters: {},
    methods: {
        handleCopyDialogClose() {
            this.copyDialogShow = false
        },
        submitCopy() {
            this.$copyText(this.copyTextarea).then((e) => {
                this.$message({
                    message: '提示：已复制',
                    type: 'success'
                });
            }, (e) => {
                this.$message.error('提示：复制失败');
            })
            this.copyDialog = false
        },
    }
}

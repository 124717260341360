// 出票渠道枚举接口 http://yapi.yinzhitravel.com/project/52/interface/api/15828

const __request = require(`./__request/__request_contentType_json`)
const consumer_flight_report_order_ticketChannel = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/flight/report/order/ticketChannel',
    data: data
  }
  return __request(pParameter)
}
export default consumer_flight_report_order_ticketChannel

// 锁退票订单 http://yapi.yinzhitravel.com/project/52/interface/api/15588

const __request = require(`./__request/__request_contentType_json`)
const consumer_admin_flight_manage_lockOrderRefun = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/admin/flight/manage/lockOrderRefun',
    data: data
  }
  return __request(pParameter)
}
export default consumer_admin_flight_manage_lockOrderRefun

export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/multi-level-menu-test-3-1',
            name: 'admin-multi-level-menu-test-3-1',
            component: () => import(/* webpackChunkName: "page-admin-default-multi-level-menu-test-3-1" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `3.1`,
                },
                entity_list_for_breadcrumb: [
                    {name: `示例功能`},
                    {name: `三级菜单示例`},
                    {name: `3.1`},
                ],
            },
        },
    ],
};

/*平台酒店规则外部分退订处理操作-客服发起申请和退款*/
const __request = require(`./__request/__request_contentType_json`)
const consumer_admin_hotel_order_refundHotelOrderPartialCancel = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/admin/hotel/order/refundHotelOrderPartialCancel',
    data: data
  }
  return __request(pParameter)
}
export default consumer_admin_hotel_order_refundHotelOrderPartialCancel
import virtualList from 'vue-virtual-scroll-list'
import OptionItem from "@/component/airport-select/component/option-item/index.vue";
export default {
    data() {
        return {
            itemComponent: OptionItem,
            val: null, // 选中的对象
            inputKey: '', // 输入框显示的内容
            show: false,
            focus: false, // 焦点是否在
            mouseIndex: 0, // 鼠标或者方向键选中
        }
    },
    props: {
        value:{ //获取父组件的数据value
            type:String,
            default:''
        },
        placeholder: {
            type: String,
            default: '请选择',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        option: {
            type: Array,
            default: [],
        },

    },
    components: {
        'virtual-list': virtualList,

    },
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        inputKey(newVal, oldVal) {
            console.log('in', newVal, oldVal)
        },
        focus(newVal, oldVal) {
          setTimeout(()=>{

              this.show = newVal;

          }, 100)
        },
        value: {
            handler(newVal, oldVal) {
                console.log('value', newVal, oldVal)
                this.val = null;
                this.inputKey = '';
                if(this.option.length > 0) {
                    this.option.forEach(value => {
                        if (value.code === newVal) {
                            value.label=`${value.cityName} ${value.name}`;
                            value.value = value.code
                            this.val = value;
                            this.inputKey = value.label;
                        }
                    })
                }
            },
            // immediate 设为true，进入页面会立即执行handler函数。
            immediate: true
        },
        option: {
            handler(newVal, oldVal) {
                if (this.value && newVal.length > 0 && !this.focus) {
                    console.log('o', newVal, oldVal);
                    newVal.forEach(value => {
                        if (this.value === value.code) {
                            value.label=`${value.cityName} ${value.name}`;
                            value.value = value.code
                            this.val = value;
                            this.inputKey = value.label;
                        }
                    })
                }
            },
            // immediate 设为true，进入页面会立即执行handler函数。
            immediate: true
        },
        show(newVal, oldVal) {
            if (newVal) {
                setTimeout(()=>{
                    console.log('virtualList',  this.$refs.virtualList)
                    this.$refs.virtualList.reset();
                }, 200)
            }
        }
    },
    computed: {
        textPlaceholder() {
            let text = '';
            if (this.focus && this.val) {
                text = this.val.label;
            } else {
                text = this.placeholder;
            }
            return text;
        },
        optionList() {
            let inputKey = this.inputKey;
            let option = JSON.parse(JSON.stringify(this.option));
            let show = this.show;

            let result = [];
            console.log('触发', show, inputKey);
            option.forEach((value, index) => {
                value.key=`${value.cityName || ''} + ${value.name || ''} + ${index}`;
                value.label=`${value.cityName} ${value.name}`;
                value.value = value.code
            });
            if (show && inputKey) {
                let key = inputKey.toLowerCase();

                // 筛选
                option.forEach(value => {
                    let optionValue = value.value.toLowerCase();
                    if (value.label.indexOf(inputKey) > -1 || optionValue.indexOf(key) > -1) {
                        result.push(value)
                    }
                })
            } else {
                result = JSON.parse(JSON.stringify(option));
            }
            return result;
        },
    },
    filters: {},
    methods: {
        _focusHandle() {
            this.focus = true;
            this._inputHandle();
            setTimeout(()=>{
                this.inputKey = '';
                this.$forceUpdate();
                this.$emit('focus');
            }, 100)
        },
        _blurHandle() {
            this.focus = false;
            setTimeout(()=>{
                this.inputKey = this.val ? this.val.label : '';
                this.$emit('blur');
            }, 100)
        },
        _clearHandle() {
            this.val = null;
            this.inputKey = '';
            setTimeout(()=>{
                this.$emit('change', null);
                this.$emit('input', '');
            }, 300)
        },
        _inputHandle() {
            this.mouseIndex = 0;
        },
        _enterHandle() {
            if (this.show) {
                let val = this.optionList[this.mouseIndex];
                this.$refs.selectInput.blur();
                this.choose(val);
            }
        },
        _keyupHandle(event) {
            console.log(event.key)
            switch (event.key) {
                case 'ArrowDown':
                    if (this.mouseIndex + 1 < this.optionList.length) {
                        this.mouseIndex++;
                    }
                    break;
                case 'ArrowUp':
                    if (this.mouseIndex > 0) {
                        this.mouseIndex--;
                    }
                    break;
                case 'ArrowRight':
                    if (this.mouseIndex + 1 < this.optionList.length) {
                        this.mouseIndex++;
                    }
                    break;
                case 'ArrowLeft':
                    if (this.mouseIndex > 0) {
                        this.mouseIndex--;
                    }
                    break;
            }
        },
        choose(val) {
            console.log('c', val)
            this.val = val;
            this.inputKey = this.val.label;
            setTimeout(()=>{
                this.$emit('change', val);
                this.$emit('input', val.value);
            }, 300)
        },
        setMouseIndex(index) {
            this.mouseIndex = index;
        }
    }
}
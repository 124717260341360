import Q from 'q';
import extend from "extend";
import VueVirtualScrollList from 'vue-virtual-scroll-list'
import consumer_web_company_selectOwnerProjects from '@/lib/data-service/haolv-default/consumer_web_company_selectOwnerProjects'

const handlePromise = (list) => {
    return list.map(promise => {
        return promise.then(res => {
            return res
        }).catch(e => {
            return e
        })
    })
};

export default {
    data() {
        return {
            params: {},
            costList: [], // 归属项目列表
            filterCostList: [], // 归属项目搜索后列表
            showType: '', // '' 不展开。 'tab' 展开tab。 'filter': 搜索过滤
            input: '',
            placeholder: '请选择归属项目',
            deferInitCompleted: Q.defer(),
            selectIndex: 0,
            currentComponent: () => import('./component/radioItemForVirtual/index.vue')
        }
    },
    props: {
        projectName: {
            type: String,
            default: ''
        },
        projectCode: {
            type: String,
            default: ''
        },
        companyId: {
            type: Number,
            default: 0
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        VueVirtualScrollList,
        radioItem: () => import('./component/radioItem/index.vue'),
        RadioItemForVirtual: () => import('./component/radioItemForVirtual/index.vue'),
    },
    created() {
    },
    mounted() {
        document.addEventListener('click', this._clickReserveWrapper);
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
        document.removeEventListener('click', this._clickReserveWrapper)
    },
    watch: {
        showType(newVal, oldVal) {
            console.log('currentProjectName', this.currentProjectName)
            if (newVal === '') {
                this.input = this.currentProjectName;
                this.placeholder = '请选择归属项目'
            } else if (oldVal === '') {
                this.input = '';
                this.placeholder = this.currentProjectName;
            }
        },
        projectCode: {
            handler(newValue, oldValue) {
                const _this = this;
                function getList() {
                    return Q.when().then(() => {
                        return _this.deferInitCompleted.promise
                    })
                }
                getList().then(() => {
                    this._getActive({
                        projectCode: newValue,
                        projectName: currentProjectName,
                        companyId: currentCompanyId,
                    })
                })
            },
            immediate: true,
            deep: true
        },
        filterShow(newVal) {
            if (newVal) {
                this.selectIndex = 0
            }
        },
    },
    computed: {
        currentProjectName: {
            get () {
                return this.projectName;
            },
            set (val) {
                this.$emit('update:projectName', val)
            }
        },
        currentProjectCode: {
            get () {
                return this.projectCode;
            },
            set (val) {
                this.$emit('update:projectCode', val)
            }
        },
        currentCompanyId: {
            get () {
                return this.companyId;
            },
            set (val) {
                this.$emit('update:companyId', val)
            }
        },
        listShow() {
            const showType = this.showType;
            if (showType === 'tab') {
                return true
            } else {
                return false
            }
        },
        filterShow() {
            const showType = this.showType;
            if (showType === 'filter') {
                return true
            } else {
                return false
            }
        },
    },
    filters: {},
    methods: {
        _clickInput() {
            if (this.disabled) {
                return
            }
            if (this.showType === '') {
                this.showType = 'tab'
            }
        },
        _clickReserveWrapper(e) {
            if (!!this.$refs.costAttributionList.contains(e.target)) return
            this.showType = ''
        },
        _getCostCenterList(params) {
            let apiList = [];
            consumer_web_company_selectOwnerProjects({companyId: params.companyId}).then((res) => {
                let allCostList = res.datas;
                allCostList.forEach(value => {
                    value.check = false;
                    if (this.currentProjectCode == value.projectCode) {
                        value.check = true;
                    }
                });
                this.costList = JSON.parse(JSON.stringify(allCostList));
            });
        },
        _getActive(item, close) {
            let projectName = '';
            this.costList.forEach(value => {
                if (value.projectCode === item.projectCode) {
                    value.check = true;
                    projectName = value.projectName;
                } else {
                    value.check = false
                }
            });
            this.currentProjectCode = item.projectCode;
            this.currentProjectName = projectName;
            this.currentCompanyId = item.companyId;
            this.input = projectName;
            if (close) {
                this.showType = '';
            }
        },
        _getFilterActive(item, close = true) {
            this._getActive(item, close)
        },
        _keyupValue(e) {
            if (this.input.length > 0) {
                // 有输入
                this.showType = 'filter';
                // 过滤
                let filterCostList = [];
                this.costList.forEach(value => {
                    if (value.projectName && value.projectName.indexOf(this.input) > -1) {
                        filterCostList.push(value)
                    }
                })
                this.filterCostList = filterCostList;
            } else {
                // 没输入
                this.showType = 'tab'
            }
            // 监听操作键
            if ([`ArrowDown`, `ArrowUp`, `ArrowLeft`, `ArrowRight`, `Enter`, `NumpadEnter`, `Tab`].indexOf(e.code) === -1) {
                this.selectIndex = 0;
                return
            }
            if ((e.code === 'ArrowDown' || e.code === 'ArrowRight') && this.filterShow) {
                this.selectIndex++;
                if (this.selectIndex >= this.filterCostList.length) {
                    this.selectIndex = 0
                }
            }
            if ((e.code === 'ArrowUp' || e.code === 'ArrowLeft') && this.filterShow) {
                this.selectIndex--;
                if (this.selectIndex < 0) {
                    this.selectIndex = this.filterCostList.length - 1
                }
            }
            if ((e.code === 'Enter' || e.code === 'NumpadEnter') && this.filterShow) {
                const item = this.filterCostList[this.selectIndex];
                this._getFilterActive(item)
            }
        },
        _mouseenterToGetFilterActive(item, index, e) {
            console.log(item, e);
            // this.selectIndex = index;
            // this._getFilterActive(item, false);
        },
        _blurInput() {

        },
        /**
         * obj :
         * {
         *     type: 使用情景 String 001: 平台使用  002：pc端使用 默认001
         *     companyId: 公司ID， string
         *
         * }
         * */
        async init(params) {
            let initParams = {
                type: '001',
                companyId: '',
            };
            if (!params) params = {};
            // 判断各入参是否符合标准
            extend(initParams, params);
            this.params = JSON.parse(JSON.stringify(initParams));
            this._getCostCenterList(this.params);
        },
    }
}

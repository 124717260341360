import _ from 'underscore';
import Q from 'q';
import {awaitWrap} from '@/page/admin/replace-orders/travel-reserve/common/unit'
import consumer_admin_journey_getEvectionDetail
    from '@/lib/data-service/haolv-default/consumer_admin_journey_getEvectionDetail' // 获取出差信息
import consumer_admin_journey_calculateHotelPrice
    from '@/lib/data-service/haolv-default/consumer_admin_journey_calculateHotelPrice'//计算总价
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg' // 保存信息
import consumer_admin_hotel_order_createHotelOrder
    from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_createHotelOrder' //创建订单

import CompanyInfo from '@/component/company-info/import_latest_version_module'// 企业信息组件
import TravelerMultipleSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector-label/1.0.0/index.vue';//选人列表组件
import DynamicForm from '@/component/dynamicForm/import_latest_version_module'
import NewUserPopup from '@/component/newUserPopup/import_latest_version_module'//新增入住人组件
import CopyReportPopup from '@/component/copyReportPopup/1.0.0/index.vue';//复制报价组件组件
import moment from "moment"
import consumer_TTpRefundChangePolicy_copyPriceWithService from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_copyPriceWithService';
import consumer_product_getServiceAmount from '@/lib/data-service/haolv-default/consumer_product_getServiceAmount';
import Decimal from "decimal.js"
import TravelMultipleSelectDialog from "@/component/travel-multiple-select-dialog/index.vue";
export default {
    data() {
        return {
            loading: false,
            companyId: '',
            evectionNo: '',
            orderNo: '',
            evectionType: 2,
            evectionInfo: {}, // 出差信息合集
            evectionParams: {
                evectionNo: '',
                evectionType: 2,
                explainDesc: '',
                feeAffiliationId: '',
                feeAffiliationName: '', // 费用归属名称
                feeAffiliationType: 1,
                linkman: '',
                linkmanPhone: '',
                reason: '',
                // travelUserList: [],
                smsType: 1, // 是否发送短信， 1为是， 0为否
                linkmanSms: 1, // 是否给联系人发送短信 1为是， 0为否
                userList: []
            },

            copyDialog: false,
            copyTextarea: '',
            copyLoading: false,

            priceResult: 0,

            hotelOrderInfo: [],
            form: {
                arrivalTime: '',
                remarks: '',
                linkman: '',
                linkmanPhone: '',
                smsType: 1,
                linkmanSms: 1,
                userIdList: [],
                userList: [],
                contact: [],
                roomAmount: 0,
                roomList: [{
                    roomId: 1,
                    user: {}
                }]
            },
            pickerOptions: {start: '13:00',step: '01:00',end: '24:00'},

            roomAmountList: [1, 2, 3, 4, 5],
            curRoomId: 1,
            aUserIdList: [],
            contactObj: {},
            contactIndex: 0,
            today: '',
            isTodayMorning: false,
            showNotBookDialog: false,
            isDisabledRoomAmount: false,
            isDisabledTraveler: false,
        }
    },
    components: {
        TravelMultipleSelectDialog,
        CompanyInfo,
        TravelerMultipleSelector,
        NewUserPopup,
        CopyReportPopup,
        DynamicForm
    },
    async activated() {
        this.init();
    },
    watch: {
        //房间数量改变时，改变需要选定入住人数和预定总价
        'form.roomAmount'(newVal) {
            let roomList = JSON.parse(JSON.stringify(this.form.roomList))
            let fullList = roomList.filter(item => {
                return Object.keys(item.user).length > 0;
            });
            let fullListLen = fullList.length;

            if (fullListLen === 0) {
                this.form.roomList = [{
                    roomId: 1,
                    user: [],
                    userIdList: []
                }];
                for (let i = 1; i < newVal; i++) {
                    this.form.roomList.push({
                        roomId: i + 1,
                        user: [],
                        userIdList: []
                    })
                }
            } else {
                if (newVal > fullListLen) {
                    this.form.roomList = fullList;
                    for (let i = 0; i < newVal - fullListLen; i++) {
                        this.form.roomList.push({
                            roomId: fullListLen + i + 1,
                            user: [],
                            userIdList: []
                        })
                    }
                } else {
                    this.form.roomList = [];
                    for (let i = 0; i < newVal; i++) {
                        this.form.roomList.push(fullList[i]);
                    }
                }
            }
            this.getAllPrice();
        },
        //userIdList变化时，更新userList，并发布userIdList和userList给父组件
        'aUserIdList'(newVal) {
            console.log('98',newVal);
            /*this.form.roomList.find(room => {
                if (room.roomId === this.curRoomId) {
                    if (newVal && newVal.length > 0) {
                        let idList = newVal;
                        if(newVal.length > this.hotelOrderInfo.roomMaxSize) {
                            idList = newVal.splice(this.hotelOrderInfo.roomMaxSize-1, newVal.length - this.hotelOrderInfo.roomMaxSize);
                        }
                        console.log(idList);
                        this.$refs.aTravelMultipleSelectDialog.setCompany(this.companyId, false);
                        this.$refs.aTravelMultipleSelectDialog.getSelectedListPromise({
                            idList: idList,
                        }).then(({activeDetailList}) =>{
                            console.log(activeDetailList);
                            room.user = activeDetailList;
                        });
                        room.userIdList = idList;
                    } else {
                        room.user = {};
                        room.userIdList = [];
                    }
                }
            });*/
        },
        //从员工中选择联系人时，
        'form.contact'(newVal) {
            this.contactObj = this.$refs.aContactSingleSelector.get_selected_entity();
            this.form.linkman = this.contactObj.name;
            this.form.linkmanPhone = this.contactObj.phone;
        }
    },
    computed: {},
    methods: {
        //初始化
        async init() {
            this.evectionNo = this.$route.query.evectionNo;
            this.companyId = Number(this.$route.query.behalfCompanyId);
            this.orderNo = this.$route.query.orderNo
            this.evectionType = parseInt(this.$route.query.evectionType)
            setTimeout(()=>{
                this.initDynamicForm();
            }, 500)
            this.initSelectorData();
            await this.getEvectionInfo();
        },
        // 获取出差信息
        async getEvectionInfo() {
            this.loading = true;
            let [err, res] = await awaitWrap(consumer_admin_journey_getEvectionDetail({
                evectionNo: this.evectionNo,
                pageType: 4,
                personType: 2
            }));
            this.loading = false;
            if (err) {
                return
            }
            this.evectionInfo = res.datas;
            this.form.roomAmount = 1;
            this.hotelOrderInfo = this.evectionInfo.todHotelOrderInterimList[0];
            // 判断入住时间
            this.today = this.$moment().format('YYYY-MM-DD');
            let yesterday = this.$moment().add(-1, 'days').format('YYYY-MM-DD');
            this.isTodayMorning = this.hotelOrderInfo.checkInDate === yesterday ? true : false;
            if (this.isTodayMorning) {
                //当天凌晨入住
                let hour = 1;
                if (moment().hour() > 1) {
                    hour = moment().hour() + 1;
                }
                this.form.arrivalTime = `${hour}:00`;
                this.pickerOptions = {start: `${hour}:00`,step: '01:00',end: '06:00'}
            } else {
                if (this.hotelOrderInfo.checkInDate === moment().format('YYYY-MM-DD')) {
                    let hour = 13;
                    if (moment().hour() > 12) {
                        hour = moment().hour() + 1;
                    }
                    this.form.arrivalTime = `${hour}:00`;
                    this.pickerOptions = {start: `${hour}:00`,step: '01:00',end: '24:00'}
                } else {
                    this.form.arrivalTime = '13:00';
                    this.pickerOptions = {start: '13:00',step: '01:00',end: '24:00'}
                }
            }

            this.hotelOrderInfo.newNightlyRateList.forEach(item => {
                let dateText = this.$moment(item.date).format('MM月DD日');
                this.$set(item, 'dateText', dateText);
            })
        },
        //初始化动态表单
        initDynamicForm() {
            this.$refs.aDynamicForm.init({
                companyId: Number(this.companyId),
                // 业务类型：1机票、2火车票、3酒店、4汽车
                businessType: 3,
                showAttribution: true,
                // 是否使用默认数据
                mock_data: {
                    enable: false,
                },
            });
        },
        //初始化选人弹框数据
        initSelectorData() {
            this.getTravelerMultipleSelectorData();
            this.getContactSingleSelectorData();
        },
        //初始化选择出行人弹框
        getTravelerMultipleSelectorData() {
            this.$refs.aTravelMultipleSelectDialog.setCompany(this.companyId, false);
            /*this.$refs.aTravelerMultipleSelector.init_parameter.get_data_source_parameter = () => {
                const d = {
                    companyId: this.companyId,
                    isSelectCustomer: true,
                };
                return Q.resolve(d);
            };*/
            this.$refs.aTravelMultipleSelectDialog.init({selectType: '001'});
        },
        //初始化选择联系人弹框
        getContactSingleSelectorData() {
            this.$refs.aContactSingleSelector.init_parameter.get_data_source_parameter = () => {
                const d = {
                    companyId: this.companyId,
                    isSelectCustomer: true,
                };
                return Q.resolve(d);
            };
            this.$refs.aContactSingleSelector.init({selectType: '001'})
        },
        //新增入住人
        addTraveler(room) {
            this.curRoomId = room.roomId;
            this.$refs.aNewUserPopup.init({companyId: this.companyId});
            this.$refs.aNewUserPopup.show();
        },
        //获取新增入住人
        getNewUser(newUser) {
            this.aUserIdList = [newUser.userId];
        },
        //选择出行人
        chooseTraveler(room) {
            this.aUserIdList = [];
            this.curRoomId = room.roomId;
            if (room.user !== null && room.user !== undefined && room.user !== [] && room.user.length > 0) {
                this.aUserIdList = room.userIdList;
            }
            this.$refs.aTravelMultipleSelectDialog.show(false);
            this.$refs.aTravelMultipleSelectDialog.setActiveList(room.user || []);
        },
        //选择联系人
        chooseContact() {
            this.$refs.aContactSingleSelector.show();
        },
        //删除出行人
        delTraveler(userId) {
            this.form.userIdList.find((item, index) => {
                if (item === userId) {
                    this.form.userIdList.splice(index, 1);
                    return;
                }
            })
        },
        // 与出行人一致
        sameToTraveler() {
            let userList = [];
            this.form.roomList.forEach(room => {
                if (room.user !== null && room.user !== {}) {
                    userList = userList.concat(room.user);
                }
            });
            if (userList.length === 0) {
                this.$message.warning('请选择入住人');
                return;
            }
            this.contactIndex++
            let travelUserListLength = userList.length
            if (this.contactIndex === travelUserListLength) {
                this.contactIndex = 0
            }
            let linkmanInfo = userList[this.contactIndex]
            this.form.linkman = linkmanInfo.name || linkmanInfo.staffName
            this.form.linkmanPhone = linkmanInfo.phone
        },
        // 复制酒店信息
        async copyHotel() {
            console.log(this.hotelOrderInfo)
            this.copyDialog = true;
            this.copyLoading = true;
            const params = {
                companyId: Number(this.$route.query.behalfCompanyId),
                groupType: this.hotelOrderInfo.orderFromType,
            }
            let displayedService = 0; // 是否单独展示服务费 0：否 1：是
            let serviceInfo = {};
            let [err, res] = await awaitWrap(Promise.all([consumer_TTpRefundChangePolicy_copyPriceWithService(params), consumer_product_getServiceAmount(params)]));
            if (res) {
                displayedService = res[0].datas.displayedService;
                serviceInfo = res[1].datas;
            }
            this.copyTextarea = `酒店：${this.hotelOrderInfo.hotelName}
地址：${this.hotelOrderInfo.hotelAddr}
入住：${this.hotelOrderInfo.checkInDate}
离店：${this.hotelOrderInfo.checkOutDate} 共${this.hotelOrderInfo.stayNights}晚
房型：${this.hotelOrderInfo.hotelRoomTypeName}`;
            if (this.hotelOrderInfo.windowName) {
                this.copyTextarea += `(${this.hotelOrderInfo.windowName}, ${this.hotelOrderInfo.breakfastName})
`;
            } else {
                this.copyTextarea += `(${this.hotelOrderInfo.breakfastName})
`;
            }
            this.copyTextarea += `铨成结算${this.hotelOrderInfo.stayNights > 1 ? '均' : ''}价：${this.hotelOrderInfo.averagePrice}元/间/晚
`;
            if (displayedService === 1 && this.hotelOrderInfo.negotiatedPriceText) {
                let service = 0;
                if (serviceInfo.serviceAmountSet === 1) { // 服务费设置：0.不收取 1.收取
                    if (serviceInfo.serviceAmountType === 1) { // 计算方式：1.百分比，2.定额
                        service = Decimal(this.hotelOrderInfo.averagePrice).mul(serviceInfo.serviceAmount).toString();
                        if (serviceInfo.limitAmount != null && service > serviceInfo.limitAmount) { // 如果超过上限金额
                            service = serviceInfo.limitAmount
                        }
                    } else {
                        service = serviceInfo.serviceAmount;
                    }

                }
                this.copyTextarea +=`服务费：${service}元/间/晚
`;
            }
            this.copyTextarea +=`${this.hotelOrderInfo.stayNights}晚总价格：${this.priceResult.hotelSum}元
预付酒店预订成功不退不改`
            if (this.companyId == '81') {
                this.copyTextarea += '如需预定请确认行程报价是否与贵司差标相符'
            } else {
                this.copyTextarea += '是否符合差标请自行确认'
            }
            this.copyLoading = false;
        },
        // 计算订单价格
        async getAllPrice() {
            let params = {
                evectionNo: this.evectionNo,
                headCounts: this.form.roomAmount,
                roomNum: this.form.roomAmount
            }
            let res = await consumer_admin_journey_calculateHotelPrice(params)
            this.priceResult = res.datas;
            this.hotelOrderInfo.roomMaxSize = this.priceResult.todHotelOrderInterims[0].roomMaxSize;
            this.hotelOrderInfo.confirmType = this.priceResult.todHotelOrderInterims[0].confirmType;
        },
        // 保存信息
        async saveInfo() {
            let formInfo = this.$refs.aDynamicForm.getData();
            let projectCode = '';
            let projectName = '';
            formInfo.list.forEach(value => {
                if (value.parameter == 'GuiShuXiangMu') {
                    projectCode = value.parameterValue;
                    projectName = value.parameterText;
                }
            });
            this.evectionParams = {
                evectionNo: this.evectionNo,
                linkman: this.form.linkman,
                linkmanPhone: this.form.linkmanPhone,
                smsType: this.form.smsType,
                linkmanSms: this.form.linkmanSms,
                userList: this.form.userList,
                projectCode,
                projectName
            };
            let [err, res] = await awaitWrap(consumer_journey_saveOrderMsg(this.evectionParams))
            if (err) {
                return false
            }
            return res
        },
        //创建订单
        async createOrder() {
            let formInfo = this.$refs.aDynamicForm.getData();
            let projectCode = '';
            let projectName = '';
            formInfo.list.forEach(value => {
                if (value.parameter == 'GuiShuXiangMu') {
                    projectCode = value.parameterValue;
                    projectName = value.parameterText;
                }
            });
            let params = {
                evectionNo: this.evectionNo,
                roomNum: this.form.roomAmount,
                latestArrivalTime: this.form.arrivalTime,
                hotelRemark: this.form.remarks,
                orderAmount: this.priceResult.sum,
                reportConfiguration: formInfo.list
            };
            // 归属项目
            params.projectCode = projectCode;
            params.projectName = projectName;
            params.reportConfiguration = params.reportConfiguration.filter(value => {return value.parameter != 'GuiShuXiangMu'});
            let [err, result] = await awaitWrap(consumer_admin_hotel_order_createHotelOrder(params));
            if (err) {
                return false
            }
            let orderResult = result.datas;
            let canSubmit = true;
            orderResult.orderResultList.forEach(value => {
                if (!value.isSuccess) {
                    canSubmit = false
                }
            })
            return canSubmit;
        },
        checkForm() {
            let canSubmit = false;

            //必填-到店时间
            if (!this.form.arrivalTime) {
                this.$message({
                    type: 'warning',
                    message: '请选择到店时间'
                })
                return canSubmit;
            }

            //必填-入住人
            let user = {};
            let userIdList = [];
            let isEmptyRoom = false;
            this.form.userList = [];
            this.form.roomList.forEach(item => {
                if (item.user && item.user.length > 0) {
                    item.user.forEach(item => {
                        user = {
                            userId: item.userId,
                            certificateType: item.certificateType,
                            tags: item.tags,
                        };
                        this.form.userList.push(user);
                    })
                    userIdList = userIdList.concat(item.userIdList);
                } else {
                    isEmptyRoom = true;
                }
            })

            if(userIdList.length > 0 && _.uniq(userIdList).length < userIdList.length) {
                this.$message({
                    type: 'warning',
                    message: '不能选择同一入住人'
                })
                return canSubmit;
            }

            if (!this.form.userList || isEmptyRoom) {
                this.$message({
                    type: 'warning',
                    message: '请先添加入住人'
                })
                return canSubmit;
            }

            //必填-联系人
            if (this.form.linkman === '' || this.form.linkmanPhone === '') {
                this.$message({
                    type: 'warning',
                    message: '请先填写联系人'
                })
                return canSubmit;
            }

            canSubmit = true;
            return canSubmit;
        },
        async submit() {
            if (this.isTodayMorning) {
                let curTimeList = this.$moment().toArray();
                if(curTimeList[3] >= 6) {
                    this.showNotBookDialog = true;
                    return;
                }
            }
            // 动态表单
            let formInfo = this.$refs.aDynamicForm.getData();
            if (!formInfo.checkForm) {
                const listLength = formInfo.list.length;
                for (let i = 0; i < listLength; i++) {
                    if (!formInfo.list[i].parameterForm) {
                        const text = `请填写${formInfo.list[i].parameterText}`;
                        this.$message({
                            type: "warning",
                            message: text
                        });
                        break
                    }
                }
                return
            }

            if(this.checkForm()) {
                let loading
                loading = this.$loading({
                    lock: true,
                    spinner: 'el-icon-loading',
                    background: 'rgba(255, 255, 255, 0.7)'
                });

                //保存订单
                let saveResult = await this.saveInfo();
                if (!saveResult) {
                    loading.close();
                    this.$message({
                        type: 'warning',
                        message: '保存订单信息失败，请重新选择'
                    })
                    return
                }

                //创建订单
                let createOrderResult = await this.createOrder();
                if (!createOrderResult) {
                    loading.close();
                    this.$message({
                        type: 'warning',
                        message: '创建订单失败，请重新选择'
                    })
                    return
                }

                loading.close()
                this.$router.push({
                    name: 'admin-hotel-payment',
                    query: {
                        evectionNo: this.evectionNo,
                        orderNo: this.orderNo
                    }
                })
            }
        },
        onCloseDialog() {
            this.showNotBookDialog = false;
            this.$router.back();
        },

        //选中oa单，回显入住人，禁止修改房间数和入住人
        handleSelectCallback(value) {
            //oa单目前只会带出一个入住人, eg.{name: '张磊', userId: 22881}
            this.curRoomId = 1;
            this.aUserIdList = [value.userId];
            this.form.roomAmount = 1;
            this.isDisabledRoomAmount = true;
            this.isDisabledTraveler = true;
            // 要更新出行人
            this.$refs.aTravelMultipleSelectDialog.setCompany(this.companyId, false);
            this.$refs.aTravelMultipleSelectDialog.getSelectedListPromise({
                idList: this.aUserIdList,
            }).then(({activeDetailList}) =>{
                console.log(activeDetailList);
                this.form.roomList[0].user = activeDetailList;
            });
        },

        //删除oa单，清除入住人，可修改房间数和入住人
        handleSelectDelete() {
            this.aUserIdList = [];
            this.isDisabledRoomAmount = false;
            this.isDisabledTraveler = false;
        },
        getUserList(args) {
            console.log(args);
            this.aUserIdList = args.activeIdList;
            this.form.roomList.find(room => {
                if (room.roomId === this.curRoomId) {
                    if (this.aUserIdList && this.aUserIdList.length > 0) {
                        let idList = this.aUserIdList;
                        if(this.aUserIdList.length > this.hotelOrderInfo.roomMaxSize) {
                            idList = this.aUserIdList.splice(this.hotelOrderInfo.roomMaxSize-1, this.aUserIdList.length - this.hotelOrderInfo.roomMaxSize);
                        }
                        console.log(idList);
                        this.$refs.aTravelMultipleSelectDialog.setCompany(this.companyId, false);
                        room.user = args.activeDetailList;
                        room.userIdList = idList;
                    } else {
                        room.user = [];
                        room.userIdList = [];
                    }
                }
            });
        },
    }
}

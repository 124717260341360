export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/hotel-order/non-rule-cancel-detail',
            name: 'admin-hotel-order-non-rule-cancel-detail',
            component: () => import(/* webpackChunkName: "page-admin-order-management-hotel-non-rule-cancel-detail" */ `./1.0.2/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `退订详情`,
                },
                entity_list_for_breadcrumb: [
                    {name: `酒店订单`},
                    {name: `线下退订申请列表`},
                    {name: `线下退订申请详情`},
                ],
                data_for_left_menu: {
                    get_active_route_name() {
                        return `admin-hotel-order-non-rule-cancel-list`;
                    },
                },
            },
        },
    ],
};

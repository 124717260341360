export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/flight-bill-report',
            name: 'admin-flight-bill-report',
            component: () => import(/* webpackChunkName: "page-admin-operation-report-flight-bill-report" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `机票对账报表`,
                },
                entity_list_for_breadcrumb: [
                    {name: `运营报表`},
                    {name: `机票对账报表`},
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        return `admin-flight-bill-report`;
                    },
                },
            },
        },
    ],
};

export default {
    customerInfo: '客户基础信息',
    journeyInfo: '车次信息',
    passengerList: '乘机人信息',
    ticketInfo: '采购信息',

    companyId: '所属企业',
    contactName: '联系人',
    contactMobile: '联系电话',
    evectionType: '出行类型',
    payType: '支付方式',
    fromStationObj: '出发站',
    toStationObj: '到达站',
    trainNo: '车次号',
    startTime: '出发时间',
    arriveTime: '到达时间',
    seatName: '座席等级',
    seatPrice: '票价',
    serviceChargePrice: '服务费',
    psgName: '乘客姓名',
    psgType: '乘客类型',
    cardType: '证件类型',
    cardNo: '证件号',
    birthday: '出生日期',
    mobile: '手机号码',
    seatnumber: '座位信息',
    seatInfos: '座位号',
    productCodes: '选择保险',
    insuranceOrderId: '保单号',
    productName: '保险名称',
    premium: '保险销售价',
    insureCost: '保险成本',
    startDate: '生效时间',
    endDate: '失效时间',
    channel: '保险渠道',
    boardingGates: '检票口',
    ticketChannel: '采购渠道',
    ticketNo: '取票号',
    supplierOrderNo: '供应商订单号',
    orderTime: '预订时间',
    initialName: '操作客服',
    bookingSms: '是否发送短信通知',
    userId: '操作客服',
}
export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/my-home',
            name: 'admin-my-home',
            component: () => import(/* webpackChunkName: "page-admin-home" */ `./1.0.1/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `主页`,
                },
                entity_list_for_breadcrumb: [
                    // {name: `示例功能`},
                    {name: `主页`},
                ],
            },
        },
    ],
};

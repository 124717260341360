export default {
  path: '',
  component: () => import(`@/component/admin-layout/index.vue`),
  children: [
    {
      path: '/admin/users-audit',
      name: 'admin-users-audit',
      component: () => import(/* webpackChunkName: "page-admin-users-management-users-audit" */ `./1.1.3/index.vue`),
      meta: {
        entity_for_history_tag: {
          name: `客户审核`,
        },
        entity_list_for_breadcrumb: [
          {name: `客户管理`},
          {name: `客户审核列表`},
          {name: `客户审核`},
        ],
        data_for_left_menu: {
            get_active_route_name(pParameter) {
                return `admin-users-audit`;
            },
        },

        remove_alive: true,
      },
    },
  ],
};

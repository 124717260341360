const __request = require(`./__request/__request_contentType_json`)

// http://192.168.43.143:8765/swagger-ui.html#/t-us-notice-controller/listUsingPOST_3
const consumer_sys_app_addOrDelete = (params) => {
    if (!params) params = {}

    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/sys/app/addOrDelete',
        data: params
    }
    return new Promise(function(resolve, reject) {
        resolve(__request(pParameter))
    })
}
export default consumer_sys_app_addOrDelete

import SearchBox from '@/component/search-box/1.0.1/index.vue'
import consumer_admin_hotel_order_queryHotelOperationReportOrderList from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_queryHotelOperationReportOrderList'
import consumer_admin_hotel_order_getHotelOperationReportExcel from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_getHotelOperationReportExcel'

export default {
    data() {
        return {
            form: {
                orderNo: '',
                thirdpartyOrderNo: '',
                supplierPaymentTime: '',
                orderTimeStart: '',//预定日期-开始
                orderTimeEnd: '',//预定日期-结束
                paymentTime: '',
                companyName: '',
                checkInDate: '',
                checkOutDate: '',
                productName: '',
                currentPage: 1,
                pageSize: 10,
                sourceType: 0,
            },
            sourceTypeList: [
                // 机票类型：0.全部, 1.国内，2.国际
                {value: 0, label: '全部'},
                {value: 1, label: '国内'},
                {value: 2, label: '国际'},
            ],
            fileUrl: '',
            totalCount: 0,
            tableData: [],
            loading: false,
        }
    },
    components: {
        SearchBox
    },
    created() {
        this.onSearch();
    },
    methods: {
        onSearch() {
            this.loading = true;
            let form = this.formatQueryForm();
            consumer_admin_hotel_order_queryHotelOperationReportOrderList(form)
                .then(data => {
                    this.tableData = data.datas.pageData;
                    this.totalCount = data.datas.totalCount;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        changeSupplierPaymentTime(val) {
            this.form.orderTimeStart = val[0] + ' 00:00:00' || '';
            this.form.orderTimeEnd = val[1] + ' 23:59:59' || '';
        },
        formatQueryForm() {
            let {
                orderNo,
                thirdpartyOrderNo,
                paymentTime,
                companyName,
                checkInDate,
                checkOutDate,
                productName,
                orderTimeStart,
                orderTimeEnd,
                currentPage,
                pageSize,
                sourceType,
            } = this.form;
            paymentTime = paymentTime && this.$moment(paymentTime).format('YYYY-MM-DD');
            checkInDate = checkInDate && this.$moment(checkInDate).format('YYYY-MM-DD');
            checkOutDate = checkOutDate && this.$moment(checkOutDate).format('YYYY-MM-DD');

            let form = {
                orderNo: orderNo || '',
                thirdpartyOrderNo: thirdpartyOrderNo || '',
                paymentTime: paymentTime || '',
                companyName: companyName || '',
                checkInDate: checkInDate || '',
                checkOutDate: checkOutDate || '',
                productName: productName || '',
                orderTimeStart: orderTimeStart || '',
                orderTimeEnd: orderTimeEnd || '',
                currentPage: currentPage || 1,
                pageSize: pageSize || 10,
                sourceType: sourceType || 0
            };
            return form;
        },
        handleSizeChange(size) {
            this.form.pageSize = size;
            this.form.currentPage = 1;
            this.onSearch();
        },
        handleCurrentChange(page) {
            this.form.currentPage = page;
            this.onSearch();
        },
        onReset() {
            this.form = {
                orderNo: '',
                thirdpartyOrderNo: '',
                orderTimeStart: '',//预定日期-开始
                orderTimeEnd: '',//预定日期-结束
                paymentTime: '',
                companyName: '',
                checkInDate: '',
                checkOutDate: '',
                productName: '',
                currentPage: 1,
                pageSize: 10,
                sourceType: 0,
            };
            this.onSearch();
        },
        onExoprt() {
            let form = this.formatQueryForm();
            const _http = document.location.protocol + '//';
            const _req = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
            consumer_admin_hotel_order_getHotelOperationReportExcel(form)
                .then(res => {
                    if (res.datas) {
                        if (_req.test(res.datas)) { // 判断返回链接是否存在http://或https://
                            this.fileUrl = res.datas;
                        } else {
                            this.fileUrl = _http + res.datas;
                        }
                        this.$nextTick(() => {
                            this.$refs.downloadBtn.click()
                        })
                    } else {
                        this.$message.error('导出失败！');
                    }
                });
        },
    }
}
// 列表数据
import consumer_statement_invoiceList from '@/lib/data-service/haolv-default/consumer_statement_invoiceList'
// 开票对账详细信息
import consumer_statement_invoiceDetail from '@/lib/data-service/haolv-default/consumer_statement_invoiceDetail'
// 核对接口
import consumer_statement_updateInvoiceStatus
  from '@/lib/data-service/haolv-default/consumer_statement_updateInvoiceStatus'
// 获取开票信息
import consumer_statement_eleInvoiceDetail
  from '@/lib/data-service/haolv-default/consumer_statement_eleInvoiceDetail'
// 导出
import consumer_statement_invoiceListExcel
  from '@/lib/data-service/haolv-default/consumer_statement_invoiceListExcel'


import SearchBox from '@/component/search-box/import_latest_version_module.js'
import Pagination from '@/component/base-pagination/index.vue'
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";

const moment = require('moment');

export default {
  data() {


    const validatorInvoiceType = (rule, value, callback) => {
      callback();
    };
    const validatorInvoiceDate = (rule, value, callback) => {
      if (!this.Invoicing.data[0].invoiceDate && this.activeName == 'hotel') {
        callback(new Error('请选择开票日期'));
      } else if (!this.Invoicing.data[1].invoiceDate && this.activeName == 'fine') {
        callback(new Error('请选择开票日期'));
      }
      callback();
    };
    const validatorinvoiceNo = (rule, value, callback) => {
      if (!this.Invoicing.data[0].invoiceNo && this.activeName == 'hotel') {
        callback(new Error('请输入发票号码'));
      } else if (!this.Invoicing.data[1].invoiceNo && this.activeName == 'fine') {
        callback(new Error('请输入发票号码'));
      }
      callback();
    };
    const validatorInvoicePrice = (rule, value, callback) => {
      let item = this.Invoicing.data[0].invoicePrice;
      if (!item && this.Invoicing.data[0].invoiceType == 1) {
        return callback(new Error('请输入价额'));
      }
      if (item <= 0 || (item.split('.').length == 2 && item.split('.')[1].length > 2)) {
        return callback(new Error('请输入大于0的数字，可保留两位小数'));
      }
      callback();
    };
    const validatorInvoiceTax = (rule, value, callback) => {
      let item = this.Invoicing.data[0].invoiceTax;
      if (!item && this.Invoicing.data[0].invoiceType == 1) {
        return callback(new Error('请输入税额'));
      }
      if (item <= 0 || (item.split('.').length == 2 && item.split('.')[1].length > 2)) {
        return callback(new Error('请输入大于0的数字，可保留两位小数'));
      }
      callback();
    };
    const validatorPriceAmtSum = (rule, value, callback) => {
      if (this.activeName == 'hotel') {
        let item = this.Invoicing.data[0].priceAmtSum;
        if (!item && this.Invoicing.data[0].invoiceType == 2) {
          callback(new Error('请输入票面价税合计金额'));
        }
        if (item <= 0 || (item.split('.').length == 2 && item.split('.')[1].length > 2)) {
          return callback(new Error('请输入大于0的数字，可保留两位小数'));
        }
        callback();
      } else {
        let item = this.Invoicing.data[1].priceAmtSum;
        if (!item && this.Invoicing.data[1].invoiceType == 2) {
          callback(new Error('请输入票面价税合计金额'));
        }
        if (item <= 0 || (item.split('.').length == 2 && item.split('.')[1].length > 2)) {
          return callback(new Error('请输入大于0的数字，可保留两位小数'));
        }
        callback();
      }
    };
    const validatorInvoiceAmount = (rule, value, callback) => {
      callback();
    };
    const validatorFileList = (rule, value, callback) => {
      if (this.activeName == 'hotel') {
        if (this.fileListHotel.length == 0) {
          callback(new Error('请选择ofd文件'));
        } else {
          this.Invoicing.data[0].url = this.fileListHotel[0].url;
          this.Invoicing.data[0].urlName = this.fileListHotel[0].name;
        }
        callback();
      } else {
        if (this.fileListFine.length == 0) {
          callback(new Error('请选择ofd文件'));
        } else {
          this.Invoicing.data[1].url = this.fileListFine[0].url;
          this.Invoicing.data[1].urlName = this.fileListFine[0].name;
        }
        callback();
      }
    };
    const validatorRemark = (rule, value, callback) => {
      callback();
    };
    return {
      activeName: 'hotel',
      InvoicingDataGrey: '',
      invoicingLoading: false,
      ossClient: new OssClient(),
      fileListHotel: [],
      fileListFine: [],
      Invoicing: {
        Dialog: false,
        type: 0,
        data: [
          {
            invoiceType: 1,
            invoiceDate: '',
            invoiceNo: '',
            invoicePrice: '',
            invoiceTax: '',
            priceAmtSum: '',
            invoiceAmount: '',
            url: '',
            urlName: '',
            remark: '',
            item: null,
          },
          {
            invoiceType: 2,
            invoiceDate: '',
            invoiceNo: '',
            invoicePrice: '',
            invoiceTax: '',
            priceAmtSum: '',
            invoiceAmount: '',
            url: '',
            urlName: '',
            remark: '',
            item: null,
          }
        ],
        Rules: {
          invoiceType: [{required: true, validator: validatorInvoiceType, trigger: 'blur'}],
          invoiceDate: [{required: true, validator: validatorInvoiceDate, trigger: 'blur'}],
          invoiceNo: [{required: true, validator: validatorinvoiceNo, trigger: 'blur'}],
          invoicePrice: [{required: true, validator: validatorInvoicePrice, trigger: 'blur'}],
          invoiceTax: [{required: true, validator: validatorInvoiceTax, trigger: 'blur'}],
          priceAmtSum: [{required: true, validator: validatorPriceAmtSum, trigger: 'blur'}],
          invoiceAmount: [{required: true, validator: validatorInvoiceAmount, trigger: 'blur'}],
          fileList: [{required: true, validator: validatorFileList, trigger: 'blur'}],
          remark: [{required: true, validator: validatorRemark, trigger: 'blur'}],
        }
      },
      // 发票类型
      InvoiceTypeHotel: [
        {
          value: 1,
          label: '专票',
        },
        {
          value: 2,
          label: '普票',
        }
      ],
      InvoiceTypeFine: [
        {
          value: 2,
          label: '普票',
        }
      ],
      applyTime: [],  //筛选条件--申请时间
      handleTime: [],  //筛选条件--操作时间
      barchIdDialog: false,
      barchIdDialogLoading: false,
      barchIdDialogData: [],
      loading: false,
      multipleSelection: [],
      params: {
        invoiceStatus: '-1',// 对账状态 -1-待核对 0-核对失败 1-核对成功
        batchId: '',   //开票批次id
        tfn: '',//税号
        travelName: '', //出行人
        orderNo: '', //订单号
        invoiceNo: '', //发票号码
        reimburseApplicationId: '', //报销单号
        applyTimeStart: '', // 申请日期 开始时间
        applyTimeEnd: '', // 申请日期 结束时间
        handleTimeStart: '', // 操作日期 开始时间
        handleTimeEnd: '', // 操作日期 结束时间
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      tabList: [
        {
          name: '-1',
          label: "待核对",
          value: 0
        },
        {
          name: '0',
          label: "核对失败",
          value: 0
        },
        {
          name: '1',
          label: "核对成功",
          value: 0
        }
      ],
      list: [],
    }
  },
  components: {
    SearchBox,
    Pagination
  },
  mounted() {
    this.search();
  },
  methods: {
    handleRequest(file){
      this.ossClient.multipartUpload({
        file: file.file,
      }).then((res) => {
        if (this.activeName == 'hotel') {
          this.fileListHotel.push({ url: res.url, name: file.file.name, uid: file.file.uid });
          this.getUrl(this.fileListHotel);
        } else {
          this.fileListFine.push({ url: res.url, name: file.file.name, uid: file.file.uid });
          this.getUrl(this.fileListFine);
        }

      }).catch(() => {
      }).finally(() => {
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      if (this.activeName == 'hotel') {
        this.fileListHotel = [];
      } else {
        this.fileListFine = [];
      }
    },
    detectionRemove(file){
      const reg2 = /^(\s|\S)+(ofd|OFD)+$/;
      const reg1 = /[\u4e00-\u9fa5]/g;
      if (reg1.test(file.name.split('.ofd')[0])) {
        this.$message({ type: "warning", message: "文件名称不要包含中文或特殊字符" });
        return false;
      }
      if (!reg2.test(file.name)) {
        this.$message({ type: "warning", message: "格式上传错误！" });
        return false;
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },

    //  申请日期
    applyOrderTimeArr(data) {
      if (data != null) {
        this.params.applyTimeStart = data[0];
        this.params.applyTimeEnd = data[1];
      } else {
        this.params.applyTimeStart = '';
        this.params.applyTimeEnd = '';
      }
    },
    //  操作日期
    handleOrderTimeArr(data) {
      if (data != null) {
        this.params.handleTimeStart = data[0];
        this.params.handleTimeEnd = data[1];
      } else {
        this.params.handleTimeStart = '';
        this.params.handleTimeEnd = '';
      }
    },
    changeInvoiceType(){
      if (this.activeName == 'hotel') {
        this.Invoicing.data[0].invoiceAmount = 0;
        this.Invoicing.data[0].invoiceTax = 0;
        this.Invoicing.data[0].invoicePrice = 0;
        this.Invoicing.data[0].priceAmtSum = 0;
      } else {
        this.Invoicing.data[1].invoiceAmount = 0;
        this.Invoicing.data[1].invoiceTax = 0;
        this.Invoicing.data[1].invoicePrice = 0;
        this.Invoicing.data[1].priceAmtSum = 0;
      }
    },
    //*报销价税合计金额 金额计算
    inputInvoicing(){
      if (this.activeName == 'hotel') {
        if (this.Invoicing.data[0].invoiceType == 1){
          this.Invoicing.data[0].invoiceAmount = Number(this.Invoicing.data[0].invoiceTax) + Number(this.Invoicing.data[0].invoicePrice);
        }
        if (this.Invoicing.data[0].invoiceType == 2){
          this.Invoicing.data[0].invoiceAmount = this.Invoicing.data[0].priceAmtSum;
        }
      } else {
        if (this.Invoicing.data[1].invoiceType == 1){
          this.Invoicing.data[1].invoiceAmount = Number(this.Invoicing.data[1].invoiceTax) + Number(this.Invoicing.data[1].invoicePrice);
        }
        if (this.Invoicing.data[1].invoiceType == 2){
          this.Invoicing.data[1].invoiceAmount = this.Invoicing.data[1].priceAmtSum;
        }
      }
    },
    clickBarchId(batchId) {
      this.barchIdDialog = true;
      this.barchIdDialogLoading = true;
      consumer_statement_invoiceDetail({batchId}).then(res => {
        this.barchIdDialogData = res.datas;
        this.barchIdDialogLoading = false;
      });
    },
    momentTime(time, text) {
      return moment(time).format(text);
    },
    // 导出
    leadingOutClick() {
      let loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      consumer_statement_invoiceListExcel(this.params).then(res => {
        loading.close();
        window.open(res.msg);
      });
    },
    search() {
      this.params.currentPage = 1;
      this.getList()
    },
    reset() {
      this.params.batchId = '';
      this.params.tfn = '';
      this.params.travelName = '';
      this.params.orderNo = '';
      this.params.invoiceNo = '';
      this.params.reimburseApplicationId = '';
      this.params.applyTimeStart = '';
      this.params.applyTimeEnd = '';
      this.params.handleTimeStart = '';
      this.params.handleTimeEnd = '';
      this.params.total = 0;
      this.params.pageSize = 10;
      this.params.currentPage = 1;
      this.applyTime = [];
      this.handleTime = [];
      this.search()
    },
    activeNameClick(val){
      this.$refs['Invoicing'].resetFields();
    },
    handleClick(val) {
      this.search()
    },
    getList() {
      this.loading = true;
      this.list = [];
      consumer_statement_invoiceList(this.params)
        .catch(() => {
          this.loading = false;
          this.list = [];
          this.params.total = 0;
        })
        .then(res => {
          this.loading = false;
          this.list = res.datas.pageData;
          this.params.total = res.datas.totalCount;
        });
    },
    handleSelectionChange(selectedList) {
      this.multipleSelection = [];
      selectedList.forEach(item => {
        this.multipleSelection.push(item)
      })
    },
    // 开票信息取消按钮
    InvoicingCancelClick(){
      this.Invoicing.Dialog = false;
    },
    // 获取开票信息
    InvoicingData(item,switchBox){
      this.Invoicing.type = item.type;
      this.activeName = 'hotel';
      this.Invoicing.Dialog = true;
      this.invoicingLoading = true;
      this.InvoicingDataGrey = switchBox;
      consumer_statement_eleInvoiceDetail({orderNo: item.orderNo})
        .then(res => {
          if (res.datas.length == 2 && res.datas[0].type != null && res.datas[1].type != null) {
            res.datas.forEach(value => {
              if (value.type == 1) {
                this.$set(this.Invoicing.data[0], "invoiceType", Number(value.invoiceType));
                this.$set(this.Invoicing.data[0], "invoiceDate", value.invoiceDate);
                this.$set(this.Invoicing.data[0], "invoiceNo", value.invoiceNo);
                this.$set(this.Invoicing.data[0], "invoicePrice", value.invoicePrice);
                this.$set(this.Invoicing.data[0], "invoiceTax", value.invoiceTax);
                this.$set(this.Invoicing.data[0], "priceAmtSum", value.priceAmtSum);
                this.$set(this.Invoicing.data[0], "invoiceAmount", value.invoiceAmount);
                this.$set(this.Invoicing.data[0], "url", value.url);
                this.$set(this.Invoicing.data[0], "urlName", value.urlName);
                this.$set(this.Invoicing.data[0], "remark", value.remark);
                this.$set(this.Invoicing.data[0], "item", item);
                if (value.url == null) {
                  this.fileListHotel = [];
                } else {
                  this.fileListHotel = [
                    {
                      "url": value.url,
                      "name": value.urlName
                    }
                  ];
                }
              } else if (value.type == 2) {
                this.$set(this.Invoicing.data[1], "invoiceType", Number(value.invoiceType));
                this.$set(this.Invoicing.data[1], "invoiceDate", value.invoiceDate);
                this.$set(this.Invoicing.data[1], "invoiceNo", value.invoiceNo);
                this.$set(this.Invoicing.data[1], "invoicePrice", value.invoicePrice);
                this.$set(this.Invoicing.data[1], "invoiceTax", value.invoiceTax);
                this.$set(this.Invoicing.data[1], "priceAmtSum", value.priceAmtSum);
                this.$set(this.Invoicing.data[1], "invoiceAmount", value.invoiceAmount);
                this.$set(this.Invoicing.data[1], "url", value.url);
                this.$set(this.Invoicing.data[1], "urlName", value.urlName);
                this.$set(this.Invoicing.data[1], "remark", value.remark);
                this.$set(this.Invoicing.data[1], "item", item);
                if (value.url == null) {
                  this.fileListFine = [];
                } else {
                  this.fileListFine = [
                    {
                      "url": value.url,
                      "name": value.urlName
                    }
                  ];
                }
              }
            });
          } else {
            this.$set(this.Invoicing.data[0], "invoiceType", Number(res.datas[0].invoiceType));
            this.$set(this.Invoicing.data[0], "invoiceDate", res.datas[0].invoiceDate);
            this.$set(this.Invoicing.data[0], "invoiceNo", res.datas[0].invoiceNo);
            this.$set(this.Invoicing.data[0], "invoicePrice", res.datas[0].invoicePrice);
            this.$set(this.Invoicing.data[0], "invoiceTax", res.datas[0].invoiceTax);
            this.$set(this.Invoicing.data[0], "priceAmtSum", res.datas[0].priceAmtSum);
            this.$set(this.Invoicing.data[0], "invoiceAmount", res.datas[0].invoiceAmount);
            this.$set(this.Invoicing.data[0], "url", res.datas[0].url);
            this.$set(this.Invoicing.data[0], "urlName", res.datas[0].urlName);
            this.$set(this.Invoicing.data[0], "remark", res.datas[0].remark);
            this.$set(this.Invoicing.data[0], "item", item);
            if (res.datas[0].url == null) {
              this.fileListHotel = [];
            } else {
              this.fileListHotel = [
                {
                  "url": res.datas[0].url,
                  "name": res.datas[0].urlName
                }
              ];
            }
          }

          this.invoicingLoading = false;
        })
        .catch(rej => {
          this.invoicingLoading = false;
        });
    },
    InvoicingRequest(){
      let loading;
      loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let InvoiceStatusRequest = {
        batchId: this.Invoicing.data[0].item.batchId,
        id: this.Invoicing.data[0].item.id,
        invoiceStatus: 1,
        orderNo: this.Invoicing.data[0].item.orderNo,
        reason: '',
        type: this.Invoicing.type,  // 1-机票对账 2-火车票对账 3-酒店对账 4-罚金对账 5-酒店对账，罚金对账
        invoiceInfoList: [],
      };
      InvoiceStatusRequest.invoiceInfoList.push(
        {
          type: this.Invoicing.type == 5 ? 1 : null,
          invoiceAmount: this.Invoicing.data[0].invoiceAmount,
          invoiceDate: moment(this.Invoicing.data[0].invoiceDate).format('YYYY-MM-DD'),
          invoiceNo: this.Invoicing.data[0].invoiceNo,
          invoicePrice: this.Invoicing.data[0].invoicePrice,
          invoiceTax: this.Invoicing.data[0].invoiceTax,
          invoiceType: Number(this.Invoicing.data[0].invoiceType),
          priceAmtSum: this.Invoicing.data[0].priceAmtSum,
          remark: this.Invoicing.data[0].remark,
          url: this.Invoicing.data[0].url,
          urlName: this.Invoicing.data[0].urlName,
        }
      );
      if (InvoiceStatusRequest.type == 5) {
        InvoiceStatusRequest.invoiceInfoList.push(
          {
            type: this.Invoicing.type == 5 ? 2 : null,
            invoiceAmount: this.Invoicing.data[1].invoiceAmount,
            invoiceDate: moment(this.Invoicing.data[1].invoiceDate).format('YYYY-MM-DD'),
            invoiceNo: this.Invoicing.data[1].invoiceNo,
            invoicePrice: this.Invoicing.data[1].invoicePrice,
            invoiceTax: this.Invoicing.data[1].invoiceTax,
            invoiceType: Number(this.Invoicing.data[1].invoiceType),
            priceAmtSum: this.Invoicing.data[1].priceAmtSum,
            remark: this.Invoicing.data[1].remark,
            url: this.Invoicing.data[1].url,
            urlName: this.Invoicing.data[1].urlName,
          }
        );
      }
      consumer_statement_updateInvoiceStatus(InvoiceStatusRequest).then(res => {
        loading.close();
        if (res.code === '000000') {
          this.$message({
            type: 'success',
            message: '核对完成！'
          });
          this.Invoicing.Dialog = false;
          this.reset();
        }
      });
    },
    // 开票信息确定按钮
    InvoicingClick(){
      if (this.InvoicingDataGrey == 'Grey') {
        this.Invoicing.Dialog = false;
        return;
      }
      if (this.Invoicing.type == 5) {
        this.activeName = 'hotel';
        this.$nextTick(() => {
          this.$refs['Invoicing'].validate((valid) => {
            if (valid) {
              this.activeName = 'fine';
              this.$refs['Invoicing'].validate((valid) => {
                if (valid) {
                  this.InvoicingRequest();
                } else {
                  this.$alert('罚金对账开票信息未填写，请填写后再提交', '温馨提示', {
                    confirmButtonText: '确定',
                    callback: action => {

                    }
                  });
                }
              });
            }
          });
        });
      } else {
        this.$refs['Invoicing'].validate((valid) => {
          if (valid) {
            let loading;
            loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            let InvoiceStatusRequest = {
              batchId: this.Invoicing.data[0].item.batchId,
              id: this.Invoicing.data[0].item.id,
              invoiceStatus: 1,
              orderNo: this.Invoicing.data[0].item.orderNo,
              reason: '',
              type: this.Invoicing.type,  // 1-机票对账 2-火车票对账 3-酒店对账 4-罚金对账 5-酒店对账，罚金对账
              invoiceInfoList: [],
            };
            InvoiceStatusRequest.invoiceInfoList.push(
              {
                type: this.Invoicing.type == 5 ? 1 : null,
                invoiceAmount: this.Invoicing.data[0].invoiceAmount,
                invoiceDate: moment(this.Invoicing.data[0].invoiceDate).format('YYYY-MM-DD'),
                invoiceNo: this.Invoicing.data[0].invoiceNo,
                invoicePrice: this.Invoicing.data[0].invoicePrice,
                invoiceTax: this.Invoicing.data[0].invoiceTax,
                invoiceType: Number(this.Invoicing.data[0].invoiceType),
                priceAmtSum: this.Invoicing.data[0].priceAmtSum,
                remark: this.Invoicing.data[0].remark,
                url: this.Invoicing.data[0].url,
                urlName: this.Invoicing.data[0].urlName,
              }
            );
            if (InvoiceStatusRequest.type == 5) {
              InvoiceStatusRequest.invoiceInfoList.push(
                {
                  type: this.Invoicing.type == 5 ? 2 : null,
                  invoiceAmount: this.Invoicing.data[1].invoiceAmount,
                  invoiceDate: moment(this.Invoicing.data[1].invoiceDate).format('YYYY-MM-DD'),
                  invoiceNo: this.Invoicing.data[1].invoiceNo,
                  invoicePrice: this.Invoicing.data[1].invoicePrice,
                  invoiceTax: this.Invoicing.data[1].invoiceTax,
                  invoiceType: Number(this.Invoicing.data[1].invoiceType),
                  priceAmtSum: this.Invoicing.data[1].priceAmtSum,
                  remark: this.Invoicing.data[1].remark,
                  url: this.Invoicing.data[1].url,
                  urlName: this.Invoicing.data[1].urlName,
                }
              );
            }
            consumer_statement_updateInvoiceStatus(InvoiceStatusRequest).then(res => {
              loading.close();
              if (res.code === '000000') {
                this.$message({
                  type: 'success',
                  message: '核对完成！'
                });
                this.Invoicing.Dialog = false;
                this.reset();
              }
            });
          }
        });
      }
    },
  }
}
import Decimal from "decimal.js"
import consumer_admin_flight_offlineRefund_editDetails from '@/lib/data-service/haolv-default/consumer_admin_flight_offlineRefund_editDetails'
import consumer_admin_flight_offlineRefund_offlineRefundEdit
    from '@/lib/data-service/haolv-default/consumer_admin_flight_offlineRefund_offlineRefundEdit'
import consumer_air_ticket_refChaReasonEnum
    from "@/lib/data-service/haolv-default/consumer_air_ticket_refChaReasonEnum";
//保存

export default {
    data() {
        const validateNumber = (rule, value, callback) => {
            if (!value) {
                callback();
            }
            const regExp = /^(0|[1-9]\d*)(\.\d{1,2})?$/
            if (regExp.test(value)) {
                if (value >= 0) {
                    callback()
                } else {
                    callback(new Error('请输入不小于0的数字'))
                }
            } else {
                callback(new Error('请输入合法数字'))
            }
        }
        return {
            loading: false,
            detail: {
                segmentsList: [],//航班信息
                insuranceInfo: [],//保险信息
                refundPassenger: [],//乘客信息
            },
            refundOptions: [
                {
                    value: 1,
                    label: "自愿退票"
                },
                {
                    value: 2,
                    label: "非自愿退票"
                },
            ],
            refReasonEnum: [],
            form: {
                code: 1,
                refundOrderNo: '',
                remark: '',
                refReason: 1,
                refundTime: '',
                psgList: [],
                userRemark: '',
            },
            rules: {
                refundFine: [
                    {required: true, message: '退票罚金', trigger: 'blur'},
                    {required: true, validator: validateNumber, trigger: 'blur'}
                ],
                platformRefund: [
                    {required: true, message: '平台退票罚金', trigger: 'blur'},
                    {required: true, validator: validateNumber, trigger: 'blur'}
                ],
                returnDifference: [
                    {required: true, message: '退回舱差', trigger: 'blur'},
                ],
                refRealAmount: [
                    {required: true, message: '退回金额', trigger: 'blur'},
                    {required: true, validator: validateNumber, trigger: 'blur'}
                ],
                supplierRefRealAmount: [
                    {validator: validateNumber, trigger: 'blur'}
                ],
                refReason: [
                    {required: true, message: '退票类型', trigger: 'blur'},
                ],
                refundTime: [
                    {required: true, message: '申请退票时间', trigger: 'blur'},
                ],
            },
            oldRefRealAmount:'',
        }
    },
    mounted() {

    },
    activated() {
        this.form.refundOrderNo = this.$route.query.refOrderNo;
        this.getRefReasonEnum();
        this.getDetail()
    },
    filters: {
        filterMoney(val) {
            if (val || val === 0) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return '0.00'
            }
        }
    },
    methods: {
        getRefReasonEnum() {
            consumer_air_ticket_refChaReasonEnum({'bizType': 3}).then(res=>{
                let refReasonEnum = res.datas;
                refReasonEnum.forEach(value=>{
                    let text = '';
                    if (value.reasonType === 1) {
                        text+= '个人原因：'
                    }
                    if (value.reasonType === 2) {
                        text+= '航司原因：'
                    }
                    text+= value.msg;
                    text+=value.refReason === 1 ? '（自愿退票）' : '（非自愿退票）'
                    console.log(text);
                    value.text = text;
                })
                this.refReasonEnum = refReasonEnum;

                // 默认第一个
                this.form.code = 1;
                this.form.refReason = 1;
                this.form.userRemark = this.refReasonEnum[0]['msg'];
            })
        },
        changeRefReason(val) {
            this.refReasonEnum.forEach(value => {
                if (value.code === val) {
                    this.form.refReason = value.refReason;
                    this.form.refReasonText = value.refReason === 1 ? '自愿退票' : '非自愿退票';
                    this.form.code = value.code;
                    this.form.userRemark = value.msg;
                    this.form.remarkText1 = value.msg + '（' + this.form.refReasonText + '）';
                }
            })
        },
        //获取详情
        getDetail() {
            this.loading = true
            consumer_admin_flight_offlineRefund_editDetails({
                refOrderNo: this.form.refundOrderNo
            }).then(res => {
                let detail = res.detailVO;
                detail.refundPassenger.forEach(item => {
                    this.$set(item, 'isPass', true);
                    item.returnDifference = 0;
                    item.isRefundToGuest = 0; // 1:退给客户，0:不推给客户
                })
                // differencePrice  最后一次改签金额
                this.detail = detail;
                this.form.refundType = this.detail.refundType;
                this.form.refundTime = this.detail.refundAppTime;
                //this.oldRefRealAmount = this.detail.refundPassenger[0].refRealAmount;
                this.oldRefRealAmount = this.detail.totalRefRealAmount;
            }).catch(() => {
                this.$message({
                    type: "error",
                    message: "获取机票订单详情失败！"
                })
            }).finally(() => {
                this.loading = false
            })
        },

        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
            switch (val) {
                case (1):
                    return 'type2'
                case (2):
                    return 'type1'
                default: return ''
            }
        },

        // 校验表单
        checkForm() {
            const _this = this;
            return new Promise(resolve => {
                let checkRes = [];
                let pass = false;
                const formLength = _this.$refs.amountForm.length;
                this.$refs.amountForm.forEach((value, index) => {
                    value.validate((valid) => {
                        console.log(valid);
                        checkRes.push(valid)

                        if (index + 1 !== formLength) {
                            return
                        }
                        if (checkRes.indexOf(false) === -1) {
                            pass = true
                        } else {
                            pass = false
                        }
                        resolve(pass)
                    })
                })

            })
        },
        //保存
        async submit() {
            let isOk = await this.checkForm();
            if(!isOk) {
                this.$message({
                    type: "warning",
                    message: '请完善已选退票乘客的退票信息'
                })
                return;
            }

            let form = this.form;
            form.psgList = [];
            this.detail.refundPassenger.forEach(psgItem => {
                if (psgItem.segmentsList.length > 0) {
                    let segList = [];
                    psgItem.segmentsList.forEach(value => {
                        let segItem = {
                            segNo: value.segmentNo
                        }
                        segList.push(segItem)
                    })
                    let item = {
                        psgNo: psgItem.psgNo,
                        refRealAmount: psgItem.refRealAmount,
                        platformRefund: psgItem.platformRefund,
                        refundFine: psgItem.refundFine,
                        supplierRefRealAmount: psgItem.supplierRefRealAmount,
                        supplierRefundOrderNo: psgItem.supplierRefundOrderNo,
                        returnDifference: psgItem.returnDifference,
                        isRefundToGuest: psgItem.isRefundToGuest,
                        segList
                    }
                    form.psgList.push(item);
                }
            })

            if (form.psgList.length === 0) {
                this.$message({
                    type: "warning",
                    message: '请选择退票乘客/航班'
                })
                return
            }
            if (!form.refundTime) {
                this.$message({
                    type: "warning",
                    message: '请输入申请退票时间'
                })
                return
            }

            if (form.psgList.length === 0 && form.remark === '') {
                this.$message({
                    type: "warning",
                    message: '请输入备注'
                })
                return
            }


            // 计算差价
            let nowRefRealAmount = 0;
            this.form.psgList.forEach(value => {
                nowRefRealAmount+= parseInt(value.refRealAmount);
            })
            let difference =  this.oldRefRealAmount - nowRefRealAmount;

            // 弹出提示框，询问用户是否继续
            if(difference>0){
                // let confirmMessage = "本次编辑产生" + difference + "元差价需退款，是否继续？";
                // 补款
                this.$confirm(`本次编辑产生${difference}元差价需补收，是否继续？`, '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    this.clickKeep(difference, form);
                }).catch(() => {
                });
                return;
            }
            if(difference<0){
                // let confirmMessage = "本次编辑产生" + Math.abs(difference) + "元差价需退款，是否继续？";
                // 补款
                this.$confirm(`本次编辑产生${Math.abs(difference)}元差价需退款，是否继续？`, '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning'
                }).then(() => {
                    this.clickKeep(difference, form);
                }).catch(() => {
                });
                return;
            }
            this.clickKeep(difference, form);
        },

        async clickKeep(difference, form){
            // let difference =  this.oldRefRealAmount - this.form.psgList[0].refRealAmount;
            if (difference != 0){
                //let userChoice = confirm(confirmMessage);
                debugger
                if (difference != 0 ) {
                    if (difference > 0) {
                        // 补退差价
                        // 直接退款操作
                        console.log("进入收银台进行补退操作");
                    } else {
                        // 补收差价
                        // 进入收银台进行补收操作，可以参考改签补款的逻辑
                        console.log("进行补收操作");
                    }
                    this.loading = true
                    consumer_admin_flight_offlineRefund_offlineRefundEdit(form).then(res => {
                        this.loading = false
                        this.$message({
                            type: "success",
                            message: '提交成功'
                        })
                        this.form.remark = ''
                        this.toConfirm()
                    }, error => {
                        this.loading = false
                    }).catch(() => {
                        this.loading = false
                    })
                }
                else {
                    console.log("用户选择取消编辑");
                }
            }else {
                this.loading = true
                consumer_admin_flight_offlineRefund_offlineRefundEdit(form).then(res => {
                    this.loading = false
                    this.$message({
                        type: "success",
                        message: '提交成功'
                    })
                    this.form.remark = ''
                    this.toConfirm()
                }, error => {
                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            }
        },

        //跳转至确认页
        toConfirm() {
            this.$router.push({
                name: 'admin-flight-offline-refund-confirm',
                query: {
                    refOrderNo: this.form.refundOrderNo
                }
            })
        },

        //返回
        back() {
            this.$router.go(-1)
        },

        // 计算
        changeItemPrice(item, index) {
            // 退回金额=销售价-退票罚金-平台退票罚金+退回舱差
            let refRealAmount = 0;
            refRealAmount = Decimal(item.personSalePrice).sub(item.refundFine).sub(item.platformRefund).toString();
            if (item.isRefundToGuest) {
                refRealAmount = Decimal(refRealAmount).add(item.returnDifference).toString();
            }
            // 供应商退回金额=采购价-退票罚金+退回舱差
            let supplierRefRealAmount = 0;
            supplierRefRealAmount = Decimal(item.personSupPrice).sub(item.refundFine).add(item.returnDifference).toString();
            item.refRealAmount = refRealAmount;
            item.supplierRefRealAmount = supplierRefRealAmount;
        },

        checkReturnDifference(rule, value, callback, item) {
            const regExp = /^(0|[1-9]\d*)(\.\d{1,2})?$/
            if (regExp.test(value)) {
                if (value < 0) {
                    callback(new Error('请输入不小于0的数字'))
                } else {
                    if (value > item.differencePrice) {
                        callback(new Error('金额不能超过改签支付的金额'))
                    } else  {
                        callback();
                    }
                }
            } else {
                callback(new Error('请输入合法数字'))
            }
        }
    }
}

import Q from 'q';
import _ from 'underscore';
import get_query_string_object from 'nllib/src/uri/get_query_string_object';
import set_token from 'nllib/src/model/set_token';

export default {
    components: {},
    data() {
        return {}
    },
    methods: {},
    created() {
        const __this = this;

        const aQueryStringObject = get_query_string_object({
            isLowerCase: true,
        });
        console.log(aQueryStringObject);

        const process_redirectType_000 = function () {
            __this.$router.replace({
                name: `admin-my-home`,
            });
        };

        const process_redirectType_100 = function () {
            const token = aQueryStringObject.token;
            if (!token) {
                const msg = 'redirect::process_redirectType_100 parameter error, code=100001';
                console.error(msg);
                return;
            }

            set_token({
                token: token,
            });

            __this.$router.replace({
                name: `admin-my-home`,
            });
        };

        const redirectType = aQueryStringObject.redirecttype;
        if (!redirectType) return process_redirectType_000();
        if (redirectType === `000`) return process_redirectType_000();
        if (redirectType === `100`) return process_redirectType_100();
    },
    mounted() {
        // const __this = this;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}

import _ from 'underscore';
// 城市组件
import InlandProvinceCityRegion from "@/component/inland-province-city-region/index.vue";
// 封装异步组件
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";
// 历史
import history_tag_api from '@/component/admin-layout/component/history-tag/api';
// 省份市级数据
import consumer_web_city_provinceCity from "@/lib/data-service/haolv-default/consumer_web_city_provinceCity.js";
// 客户审核详情
import consumer_web_company_detail from "@/lib/data-service/haolv-default/consumer_web_company_detail.js";
//公司发票类型枚举
import consumerweb_company_queryCompanyInvoiceType from "@/lib/data-service/haolv-default/consumerweb_company_queryCompanyInvoiceType.js";
//获取协议卡等级数据集合
import consumer_web_company_getPnrSetType from "@/lib/data-service/haolv-default/consumer_web_company_getPnrSetType.js";
// 银行数据
import consumer_web_bank_getBankList from "@/lib/data-service/haolv-default/consumer_web_bank_getBankList";
// 上传文件组件
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client/index";
// 行业
import consumer_web_industry_selectIndustry from '@/lib/data-service/haolv-default/consumer_web_industry_selectIndustry.js';
// 客户经理列表
import consumer_web_staff_staffAll from "@/lib/data-service/haolv-default/consumer_web_staff_staffAll.js";
// 编辑客户
import consumer_web_company_updateCustomer from '@/lib/data-service/haolv-default/consumer_web_company_updateCustomer.js';
// 服务商枚举
import consumer_sys_inform_getEnum from '@/lib/data-service/haolv-default/consumer_sys_inform_getEnum.js';
//获取协议卡等级数据集合
import consumer_web_company_getDCCardLevel from "@/lib/data-service/haolv-default/consumer_web_company_getDCCardLevel.js";
// 活动价枚举
import consumer_hotel_search_getDCRateplanList from '@/lib/data-service/haolv-default/consumer_hotel_search_getDCRateplanList'
// 航司枚举
import consumer_web_company_airlineCodeAll from '@/lib/data-service/haolv-default/consumer_web_company_airlineCodeAll'
// 获取大客户详情
import consumer_web_company_addkeyacDetails from '@/lib/data-service/haolv-default/consumer_web_company_addkeyacDetails'
// 新增大客户
import consumer_web_company_addkeyacCount from '@/lib/data-service/haolv-default/consumer_web_company_addkeyacCount'
// 编辑大客户
import consumer_web_company_addkeyacEdit from '@/lib/data-service/haolv-default/consumer_web_company_addkeyacEdit'
// 舱位枚举
import consumer_web_company_cabinByAirline from '@/lib/data-service/haolv-default/consumer_web_company_cabinByAirline'
// 删除大客户政策
import consumer_web_company_deleteKeyac from '@/lib/data-service/haolv-default/consumer_web_company_deleteKeyac'
import moment from 'moment'
import consumer_flight_search_citys from "@/lib/data-service/haolv-default/consumer_flight_search_citys";
import consumer_web_company_getInsuranceProductList from '@/lib/data-service/haolv-default/consumer_web_company_getInsuranceProductList';
import consumer_web_company_updateCompanyInsurance from '@/lib/data-service/haolv-default/consumer_web_company_updateCompanyInsurance';
import consumer_web_company_getCompanyInsurance from '@/lib/data-service/haolv-default/consumer_web_company_getCompanyInsurance';
import consumer_web_company_serviceSetInfo from '@/lib/data-service/haolv-default/consumer_web_company_serviceSetInfo';
import consumer_web_company_adultServiceSet from '@/lib/data-service/haolv-default/consumer_web_company_adultServiceSet';
import consumer_web_company_updateClientType
    from "@/lib/data-service/haolv-default/consumer_web_company_updateClientType";
import OrderLog from "@/component/orderLog/2.0.0/index.vue";
// import consumer_log_flightList from "@/lib/data-service/haolv-default/consumer_log_flightList";
import consumer_log_companyList
    from "@/lib/data-service/haolv-default/consumer_log_companyList";
import consumer_admin_company_query from "@/lib/data-service/haolv-default/consumer_admin_company_query";
import consumer_admin_company_save from "@/lib/data-service/haolv-default/consumer_admin_company_save";
export default {
    data() {
        return {
            activeName: 'detail',
            requiredServiceAmountTypeBox:[
                { label: 1, text: "比例" },
                { label: 2, text: "定额" },
            ],
            trainInvoiceTypeBox:[
                { label: 1, text: "代打" },
                { label: 2, text: "自取" },
            ],
            loading: false,
            cardInfo: [
                {
                    more: true,
                    edit: false,
                },
                {
                    more: false,
                    edit: false,
                },
                {
                    more: false,
                    edit: false,
                },
                {
                    more: false,
                    edit: false,
                },
                {
                    more: false,
                    edit: [false, false, false, false],
                },
                {
                    more: false,
                    edit: false,
                },
                {
                    more: false,
                    edit: false,
                },
                {
                    more: false,
                    edit: false,
                }, // 保险
                {
                    more: false,
                    edit: false,
                }, // 结算设置
            ],
            detail: {},
            specialFocus: false,
            hotelTogether: false,
            form: {
                companyId: '', // 公司id
                userId: '', // 编辑进来所需的账户id
                username: '', // 账号
                phone: '',  // 手机
                realName: '', //真实姓名
                companyName: '', // 公司名称
                abbreviation: '', // 简称
                industryId: '', // 行业
                scale: '', // 企业规模
                areaProvince: '', // 省
                areaProvinceName: '',
                areaCity: '', // 市
                areaCityName: '',
                areaRegion: '', // 区
                areaRegionName: '',
                companyIntroduce: '', // 简介
                companyLogo: '', // logo
                businessLicenseNum: '', // 营业执照号
                businessLicensePic: '', // 营业执照图片
                reserveLinkman: '', // 预订联系人
                reserveLinkmanObj: { // 预订联系人
                    name: '',
                    phone: '',
                    sendSms: 0,
                },
                contractLinkman: '', // 合同联系人
                contractLinkmanObj: { // 合同联系人
                    name: '',
                    phone: ''
                },

                financeLinkman: '', // 财务联系人
                financeLinkmanObj: { // 财务联系人
                    name: '',
                    phone: ''
                },

                urgencyLinkman: '', // 紧急联系人
                urgencyLinkmanObj: { // 紧急联系人
                    name: '',
                    phone: ''
                },
                accessoryUrl: '', // 附件
                customerManagerId: '', // 经理id
                customerManagerName: '', // 客户经理真实姓名
                carryRules: 0, // 进位规则
                settlement: '', // 服务商
                accountStatus: 0,
                creditAmount: '', // 授信额度
                settlementInterval: '', // 结算周期
                creditPaymentDays: '', // 账单日
                dueDate: '', // 还款日
                overdueDays: '', // 可逾期天数
                bankList: [
                    {
                        bankUserName: '', // 对公账号 -- 用户名
                        bankAccount: '', // 银行账号
                        openingBank: '', // 开户行
                        openingBankName: '', // 开户行
                        provinceId: '',
                        provinceName: '',
                        cityId: '',
                        cityName: '',
                        openingBankBranch: '', // 开户网点
                    }
                ],

                // 服务费设置
                pdProductSetList: [
                    // 机票
                    {
                        businessType: 1, // 类型
                        showServiceFee: 0, // 是否展示

                        serviceAmountSet: 0, // 线上服务费设置：0.不收取，1.收取，默认0
                        serviceAmountType: 1, // 线上服务费-计算方式：0. 无，1.百分比，2.定额
                        serviceAmount: "", // 线上服务费--收取金额、百分比

                        offlineServiceAmountSet: 0, // 线下服务费：0.不收取，1.收取，默认0
                        offlineServiceAmountType: 1, // 线下服务费-计算方式：0. 无，1.百分比，2.定额
                        offlineServiceAmount: "", // 线下服务费--收取金额、百分比

                        asiaServiceAmountSet: 0, // 亚洲服务费设置：0.不收取，1.收取，默认0
                        asiaServiceAmountType: 1, // 亚洲计算方式：0. 无，1.百分比，2.定额
                        asiaServiceAmount: "", // 亚洲服务费

                        internationalServiceAmountSet: 0, // 亚洲外服务费设置：0.不收取，1.收取，默认0
                        internationalServiceAmountType: 1, // 亚洲外计算方式：0. 无，1.百分比，2.定额
                        internationalServiceAmount: "", //亚洲外服务费

                        robTicket: "", // 抢票费
                        airPriceSet: '', // 价格配置 1：票面价结算 2：结算价结算
                        pnrSet: 1, //生编设置 1：支付前生编 2：支付后生编 默认：2
                        enterpriseConfigurationSet: 1, // 0.不加价，1.加价
                        enterpriseConfigurationType: '', // 1.百分比,2.定额'
                        enterpriseConfigurationAmount: '',
                        officialPolicyShowSet: 1, // 授权航司是否展示航司官网设置 0：不展示 1：展示 默认：1
                        lowPriceSet: 0,  //低价精选设置：0.关闭，1.开启，默认0
                        lowPriceType: 1, // 低价精选方式：0. 无，1.百分比，2.定额, 默认0"
                        lowPriceAmount: '0', // 授权航司是否展示航司官网设置 0：不展示 1：展示 默认：1
                    },
                    // 火车票
                    {
                        businessType: 2, // 类型
                        showServiceFee: 0, // 是否展示

                        serviceAmountSet: 0, // 线上服务费设置：0.不收取，1.收取，默认0
                        serviceAmountType: 2, // 线上服务费-计算方式：0. 无，1.百分比，2.定额
                        serviceAmount: "", // 线上服务费--收取金额、百分比

                        offlineServiceAmountSet: 0, // 线下服务费：0.不收取，1.收取，默认0
                        offlineServiceAmountType: 2, // 线下服务费-计算方式：0. 无，1.百分比，2.定额
                        offlineServiceAmount: "", // 线下服务费--收取金额、百分比

                        changeServiceAmountSet: 0,  // 线上改签服务费设置：0.不收取，1.收取，默认0
                        changeServiceAmountType: 2, // 线上改签服务费计算方式：0. 无，1.百分比，2.定额
                        changeServiceAmount: "",  // 线上改签服务费

                        offlineChangeServiceAmountSet: 0,  // 线下改签服务费设置：0.不收取，1.收取，默认0
                        offlineChangeServiceAmountType: 2, // 线下改签服务费计算方式：0. 无，1.百分比，2.定额
                        offlineChangeServiceAmount: "",  // 线下改签服务费

                        refundServiceAmountSet: 0, // 线上退票服务费设置：0.不收取，1.收取，默认0
                        refundServiceAmountType: 2, // 线上退票服务费计算方式：0. 无，1.百分比，2.定额
                        refundServiceAmount: "",  // 线上退票服务费

                        offlineRefundServiceAmountSet: 0, // 线下退票服务费设置：0.不收取，1.收取，默认0
                        offlineRefundServiceAmountType: 2, // 线下退票服务费计算方式：0. 无，1.百分比，2.定额
                        offlineRefundServiceAmount: "",  // 线下退票服务费

                        robTicket: "", // 抢票费
                        invoiceType: 0, // 发票类型
                        requiredServiceAmountSet: 0, // 免登录服务费设置：0.不收取，1.收取，默认0
                        requiredServiceAmountType: 2, // 免登录计算方式：0. 无，1.百分比，2.定额
                        requiredServiceAmount: "", // 免登录服务费

                        trainInvoiceType: 2, // 火车票发票设置 0：无 1：代订 2：自取 默认：0 无
                        proxyPrintServiceAmountType: 2,  // 代打服务费计算方式：0. 无，1.百分比，2.定额
                        proxyPrintServiceAmount: "",  // 代打服务费
                    },
                    // 用车
                    {
                        businessType: 4, // 类型
                        serviceAmountSet: 0, // 是否收取
                        showServiceFee: 0, // 是否展示
                        serviceAmountType: 1, // 计算方式
                        serviceAmount: "", // 收取金额
                    },
                    // 酒店
                    {
                        businessType: 3, // 类型
                        showServiceFee: 0, // 是否展示

                        serviceAmountSet: 0, // 是否收取
                        serviceAmountType: 1, // 计算方式
                        serviceAmount: "", // 收取金额、百分比

                        agreementServiceAmountSet: 0, // 酒店线上服务费设置：0.不收取，1.收取，默认0
                        agreementServiceAmountType: 1, // 酒店线上服务费计算方式：0. 无，1.百分比，2.定额
                        agreementServiceAmount: '', // 酒店线上服务费
                        agreementServiceAmountLimit: '', // 酒店线上服务费上限

                        offlineServiceAmountSet: 0, // 线下服务费设置：0.不收取，1.收取，默认0
                        offlineServiceAmountType: 1, // 线下服务费计算方式：0. 无，1.百分比，2.定额
                        offlineServiceAmount: '', // 线下服务费
                        offlineServiceAmountLimit: '', // 线下服务费上限（仅酒店）

                        robTicket: "", // 抢票费
                        invoiceType: 0, // 发票类型
                        invoiceTaxAmount: null, // 收取金额(百分比)
                    },
                ],

                audit: true, //是否开通办公
                openCar: 0, //是否开通用车
                openMemberMt: 0, //是否开通用车
                agreement: {
                    hzCompanyArgee: {
                        enable: 1,
                        cardNumber: '',
                        groupType: 6,
                        groupTypeText: '华住',
                        discount: undefined,
                    },// 华住
                    dcCompanyArgee: {
                        enable: 1,
                        cardNumber: '',
                        groupType: 9,
                        groupTypeText: '东呈',
                        rateplanList: [],
                        rateplanActiveList: [], // 选中的
                        discount: undefined,
                    }, // 东呈
                    ydCompanyArgee: {
                        enable: 1,
                        cardNumber: '',
                        discount: undefined,
                        ydCardNumberInfoList: [
                            {
                                ydCardNumber: '',
                                ydCardNumberName: ''
                            }
                        ],
                    }, // 亚朵
                    jjCompanyArgee: {
                        enable: 1,
                        cardNumber: '',
                        jjSignKey: '',
                        discount: undefined,
                    }, // 锦江
                    redCompanyAgree: {
                        enable: 1,
                        redPartnerCode: '',
                        redSecureKey: '',
                        discount: undefined,
                    }, // 红色加力
                    qtCompanyAgree: {
                        enable: 1,
                        qtPartnerCode: '',
                    }, // 千淘
                    agreeInfoList: [],
                    serviceAmountSet: 0,
                    serviceAmountType: 1,
                    serviceAmount: '0',
                    limitAmount: '0',
                },
            },
            formRules: {
                username: [{required: true, message: '请输入账号', trigger: 'blur'}],
                phone: [
                    {required: true, message: '请输入注册手机号码', trigger: 'blur'},
                    {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur'}
                ],
                realName: [{required: true, message: '请输入真实姓名', trigger: 'blur'}],
                companyName: [{required: true, message: '请输入公司名称', trigger: 'blur'}],
                abbreviation: [{required: true, message: '请输入公司简称', trigger: 'blur'}],
                industryId: [{required: true, message: '请选择所属行业'}],
                scale: [{required: true, message: '请选择企业规模'}],
                // companyIntroduce: [{required: true, message: '请输入公司简介'}],
                companyLogo: [{required: true, message: '请上传公司logo'}],
                businessLicenseNum: [
                    {required: true, message: '请输入营业执照号', trigger: 'blur'},
                    // {validator: checkBusinessLicense, trigger: 'blur' }
                ],
                businessLicensePic: [{required: true, message: '请上传营业执照'}],
                customerManagerId: [{required: true, message: '请选择客户经理'}],
                carryRules: [{required: true, message: '请选择进位规则'}],
                settlement: [{required: true, message: '请选择服务商'}],
                creditAmount: [{required: true, message: '请输入授信额度'}],
                settlementInterval: [{required: true, message: '请选择结算周期'}],
                creditPaymentDays: [{required: true, message: '请选择账单日'}],
                dueDate: [{required: true, message: '请选择还款日'}],
                overdueDays: [{required: true, message: '请选择可逾期天数'}],
                serviceAmountSet:[{required: true, message: '请选择计算方式'}],
                showServiceFee:[{required: true, message: '请选择是否展示服务费'}],
                invoiceType:[{required: true, message: '请选择发票类型'}],
                invoiceTaxAmount:[{required: true, message: '请填写收取金额(百分比)'}],
                robTicket:[
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value == '') {
                                callback(new Error("请输入抢票费"));
                            }
                            callback();
                        },
                        trigger: "blur"
                    }
                ],
                bankUserName: [{required: true, message: '请输入用户名', trigger: ['blur', 'change']}],
                bankAccount: [{required: true, message: '请输入银行账号', trigger: ['blur', 'change']}],
                openingBank: [{required: true, message: '请选择开户行', trigger: ['blur', 'change']}],
                areaRegion: [{required: true, message: '请选择所在城市', trigger: ['blur', 'change']}],
                openingBankBranch: [{required: true, message: "请输入", trigger: ['blur', 'change']}],
                serviceProp:[
                    { validator: (rule, value, callback) => {
                            if (this.form.agreement.serviceAmountSet == null) {
                                callback(new Error("请选择服务费设置"));
                            }
                            callback();
                        }, trigger: "blur" },
                ],
                serviceTypeProp:[
                    { validator: (rule, value, callback) => {
                            if (
                                this.form.agreement.serviceAmountSet == 1 &&
                                (this.form.agreement.serviceAmountType == null ||
                                    this.form.agreement.serviceAmountType == '')
                            ) {
                                callback(new Error("请选择计算方式"));
                            }
                            callback();
                        }, trigger: "blur" },
                ],
                serviceAmountProp:[
                    { validator: (rule, value, callback) => {
                            if (
                                this.form.agreement.serviceAmountSet == 1 &&
                                (this.form.agreement.serviceAmount == null ||
                                    this.form.agreement.serviceAmount == '')
                            ) {
                                callback(new Error("请输入收起金额"));
                            }
                            callback();
                        }, trigger: "blur" },
                ],
                serviceAmountSetProp:[
                    { validator: (rule, value, callback) => {
                            if (
                                this.form.agreement.serviceAmountSet == 1 &&
                                this.form.agreement.serviceAmountType == 1 &&
                                (this.form.agreement.limitAmount == null || this.form.agreement.limitAmount == '')
                            ) {
                                callback(new Error("请输入上限金额"));
                            }
                            callback();
                        }, trigger: "blur" },
                ],
                trainInvoiceType: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (this.form.pdProductSetList[1].trainInvoiceType == 0) {
                                callback(new Error("请选择发票设置类型"));
                            }
                            if (this.form.pdProductSetList[1].trainInvoiceType == 1 && (this.form.pdProductSetList[1].proxyPrintServiceAmount === '' || this.form.pdProductSetList[1].proxyPrintServiceAmount === null)) {
                                this.form.pdProductSetList[1].proxyPrintServiceAmountType = 2;
                                callback(new Error("请输入代打服务费"));
                            }
                            callback();
                        },
                        trigger: "blur"
                    }
                ],
                airPriceSet: [
                    {required: true, message: '请选择价格配置', trigger: 'change'},
                ],
                pnrSet: [
                    {required: true, message: '请选择生编', trigger: 'change'},
                ],
                officialPolicyShowSet: [
                    {required: true, message: '请选择是否展示航司官网产品', trigger: 'change'},
                ],
                hzCardNumber: [
                    {required: true, message: '请填写华住协议卡号', trigger: 'blur'},
                ],
                hzCardNumberDiscount: [
                    {required: true, message: '请填写折扣', trigger: 'blur'},
                    { validator: (rule, value, callback) => {
                            let data = this.form.agreement.hzCompanyArgee.discount;
                            let ruleTextTwo = /^-?\d+$/;

                            if (!ruleTextTwo.test(data)) {
                                callback(new Error("请填写正整数"));
                            }

                            callback();
                        }, trigger: "blur"
                    },
                ],
                dcCardNumber: [
                    {required: true, message: '请填写东呈协议卡号', trigger: 'blur'},
                ],
                dcCardNumberDiscount: [
                    {required: true, message: '请填写折扣', trigger: 'blur'},
                    { validator: (rule, value, callback) => {
                            let data = this.form.agreement.dcCompanyArgee.discount;
                            let ruleTextTwo = /^-?\d+$/;

                            if (!ruleTextTwo.test(data)) {
                                callback(new Error("请填写正整数"));
                            }

                            callback();
                        }, trigger: "blur"
                    },
                ],
                cardGrade: [
                    {required: true, message: '请选择东呈协议卡等级', trigger: 'change'},
                ],
                ydMainCardNumber: [
                    {required: true, message: '请填写亚朵协议卡号', trigger: 'blur'},
                ],
                ydMainCardNumberDiscount: [
                    {required: true, message: '请填写折扣', trigger: 'blur'},
                    { validator: (rule, value, callback) => {
                            let data = this.form.agreement.ydCompanyArgee.discount;
                            let ruleTextTwo = /^-?\d+$/;

                            if (!ruleTextTwo.test(data)) {
                                callback(new Error("请填写正整数"));
                            }

                            callback();
                        }, trigger: "blur"
                    },
                ],
                ydCardNumber: [
                    {required: true, message: '请填写亚朵副协议卡号', trigger: 'blur'},
                ],
                ydCardNumberName: [
                    {required: true, message: '请填写亚朵折扣', trigger: 'blur'},
                ],
                redPartnerCode: [
                    {required: true, message: '请填写合作商编码', trigger: 'blur'},
                ],
                redSecureKey: [
                    {required: true, message: '请填写密钥', trigger: 'blur'},
                ],
                redCardNumberDiscount: [
                    {required: true, message: '请填写折扣', trigger: 'blur'},
                    { validator: (rule, value, callback) => {
                            let data = this.form.agreement.redCompanyAgree.discount;
                            let ruleTextTwo = /^-?\d+$/;

                            if (!ruleTextTwo.test(data)) {
                                callback(new Error("请填写正整数"));
                            }

                            callback();
                        }, trigger: "blur"
                    },
                ],
                jjCardNumber: [
                    {required: true, message: '请填写锦江账号Appid', trigger: 'blur'},
                ],
                jjCardNumberDiscount: [
                    {required: true, message: '请填写折扣', trigger: 'blur'},
                    { validator: (rule, value, callback) => {
                            let data = this.form.agreement.jjCompanyArgee.discount;
                            let ruleTextTwo = /^-?\d+$/;

                            if (!ruleTextTwo.test(data)) {
                                callback(new Error("请填写正整数"));
                            }

                            callback();
                        }, trigger: "blur"
                    },
                ],
                jjSignKey: [
                    {required: true, message: '请填写密钥', trigger: 'blur'},
                ],
                enterpriseConfigurationSet: [
                    {required: true, message: '请选择是否加价', trigger: 'change'},
                ],
                enterpriseConfigurationType: [
                    {required: true, message: '请选择价格配置', trigger: 'change'},
                ],
                enterpriseConfigurationAmount: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value === undefined || value === null || value === '') {
                                this.form.pdProductSetList.forEach((item) => {
                                    if (item.airPriceSet && item.airPriceSet == 2) {
                                        callback(new Error("请输入收取金额"));
                                        return;
                                    }
                                });
                            }
                            callback();
                        },
                        trigger: 'blur'
                    },
                ],
                lowPriceAmount: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value === undefined || value === null || value === '') {
                                this.form.pdProductSetList.forEach((item) => {
                                    if (item.lowPriceSet && item.lowPriceSet == 1) {
                                        callback(new Error("请输入收取金额"));
                                        return;
                                    }
                                });
                            }
                            callback();
                        },
                        trigger: 'blur'
                    },
                ],

                keyAccountCode: [{required: true, message: '请输入协议号', trigger: ['blur', 'change']}],
                discountRate: [{required: true, message: '请输入优惠率', trigger: ['blur', 'change']}]
            },

            // 城市组件数据
            locationCity: {},
            cityRules: {
                form: {
                    areaProvince: 'areaProvince',
                    areaCity: 'areaCity',
                    areaRegion: 'areaRegion',
                },
                rules: {
                    areaProvince: [{required: true, message: '请选择省份', trigger: 'change'}],
                    areaCity: [{required: true, message: '请选择城市', trigger: 'change'}],
                    areaRegion: [{required: false, message: '请选择区', trigger: 'change'}],
                },
                required: true
            },

            allocation: {
                provinceList: [], // 省级列表
                cityList: [], // 市级列表
                bankList: [], // 银行列表
                settlementList: [], // 服务商
                industryType: [],
                enterpriseScale: [
                    {value: 1, label: "0~20人"},
                    {value: 2, label: "20~50人"},
                    {value: 3, label: "50~100人"},
                    {value: 4, label: "100~300人"},
                    {value: 5, label: "300~1000人"},
                    {value: 6, label: "1000人以上"},
                ],
                serviceAmountSet: [
                    {label: 0, text: "不收取"},
                    {label: 1, text: "收取"},
                ],
                serviceAmountType: [
                    {label: 1, text: "比例"},
                    {label: 2, text: "定额"},
                ],
                serviceAmountTypeCar: [
                    {label: 2, text: "定额"},
                ],
                manager: [],
                carryRules: [
                    {label: 0, text: "进位到元"},
                    {label: 1, text: "进位到分"},
                ],
                audit: [
                    {label: true, text: "开通"},
                    {label: false, text: "不开通"},
                ],
                openCar: [
                    {label: 1, text: "开通"},
                    {label: 0, text: "不开通"},
                ],
                openMemberMt: [
                    {label: 1, text: "开通"},
                    {label: 0, text: "不开通"},
                ],
                setServiceAmountType: [
                    {label: 0, text: "不加价"},
                    {label: 1, text: "加价"},
                ],
                settlementInterval: 5,
                creditPaymentDays: 28,
                dueDate: 30,
                overdueDays: 15,
            },

            logoLoading: false,
            businessLicenseNumLoading: false,

            uploadIndex: 0,
            // 附件
            accessoryUrl: [
                /*{
                    name: "",
                    url: "",
                },*/
            ],

            pnrSetOptions:[],

            flightSetActiveName: '0',
            flightCompanyList: [],
            activeFlightInfo: {

            },
            activeFlightForm: {
                airlineCode: '',
                companyId: '',
                id: '',

                policyStatus: 3, // 2启用 3停用
                keyAccountCode: '',
                realNameSystem: 0,// 1: 实名， 0 非实名
                travelValidityStart: '', // 旅游有效期
                travelValidityEnd: '', // 旅游有效期
                travelValidityArr: [],
                excludeTravelValidity: '', // 旅游除外
                excludeTravelValidityArr: [],
                excludeTravelValidityOption: [], // 排除日期下拉选择
                saleValidityStart: '', // 销售有效期
                saleValidityEnd: '', // 销售有效期
                saleValidityArr: [],
                excludeSaleValidity: '', // 销售排除
                excludeSaleValidityArr: [],
                excludeSaleValidityOption: [], // 销售排除下拉选择

                conditions: [
                    /*{
                        discountRate: '',
                        depAirportType: 1, // 适用起飞机场 1全部 2部分
                        depAirportParam: '',
                        depAirportParamArr: [],
                        depAirportExclude: '',
                        depAirportExcludeArr: [],
                        arrAirportType: 1, // 适用到达机场 1全部 2部分
                        arrAirportParam: '',
                        arrAirportParamArr: [],
                        arrAirportExclude: '',
                        arrAirportExcludeArr: [],
                        shareAirlineType: 1, // 适用共享航班类型 1-全部适用，2-部分适用,3-不适用
                        shareAirlineParam: '',
                        shareAirlineExclude: '',
                        cabinType: 1, // 适用舱位类型1-全部适用，2-部分适用
                        cabinParam: '',
                        cabinExclude: '',
                    }*/
                ],
            },
            activeFlightInfoRules: {
                keyAccountCode: [{required: true, message: '请输入协议号', trigger: ['blur', 'change']}],
                discountRate: [{required: true, message: '请输入优惠率', trigger: ['blur', 'change']}],
            },
            policyInfo: [],
            startAirPortOptions: [
                {value: 1, text: "全部"},
                {value: 2, text: "限定"},
            ],
            shareAirlineTypeOptions: [
                {value: 1, text: "全部"},
                {value: 2, text: "限定"},
                {value: 3, text: "不适用"},
            ],
            airportOptionsArr: [],
            allCityList: [],
            filterCityList: [],

            serviceChargeData: true,

            companyArgeeActiveName: '1',
            rateplanOptions: [], // 活动价枚举
            DCCardLevelList: [],
            officialPolicyShowSetOptions: [{value: 1, text: '展示'}, {value: 0, text: '不展示'}],

            travelPickerOption: {
                disabledDate: this.disabledTravelDate
            },
            salePickerOption: {
                disabledDate: this.disabledSaleDate
            },
            cabinList: [],

            // 保险用
            isShowInsuranceSettings: true,
            insuranceDetail: {
                flight: [
                    {
                        businessType: 1,
                        productCode: '',
                        insuranceInvoiceType: 0,
                        freeProduct: 0,
                        zoneType: 0, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                    },
                    {
                        businessType: 1,
                        productCode: '',
                        insuranceInvoiceType: 0,
                        freeProduct: 0,
                        zoneType: 1, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                    },
                    {
                        businessType: 1,
                        productCode: '',
                        insuranceInvoiceType: 0,
                        freeProduct: 0,
                        zoneType: 2, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                    },
                ],
                train: [
                    {
                        businessType: 2,
                        productCode: '',
                        insuranceInvoiceType: 0,
                        freeProduct: 0,
                        zoneType: 0, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                    },
                ]
            },
            insuranceForm: {},
            insuranceOptions: [],
            trainInsuranceOptions: [],

            pdProductSetTempList: [], // 未生效服务费配置信息

            invoiceType: [],

            // 结算设置
            matchSetForm: {
                "companyName": "",
                "companyId": "",
                "hotelSplit": true,
                "settlingDay": '',
                "invoiceType": {
                    "flight": "",
                    "itinerary": "",
                    "flightRef": "",
                    "train": "",
                    "hotel": "",
                    "car": "",
                    "ship": "",
                    "door": "",
                    "visa": "",
                    "extra": "",
                    "serviceFee": ""
                }
            },
            matchSetDetail: {
                "companyName": "",
                "companyId": "",
                "hotelSplit": true,
                "settlingDay": '',
                "invoiceType": {
                    "flight": "行程单",
                    "itinerary": "增值稅普票",
                    "flightRef": "增值稅普票",
                    "train": "火车票票根",
                    "hotel": "增值税普票",
                    "car": "增值稅普票",
                    "ship": "自取船票票根",
                    "door": "增值税普票",
                    "visa": "增值稅普票",
                    "extra": "增值税普票",
                    "serviceFee": "增值稅普票"
                }
            },
            matchSetFormRules: {},
            dayOptions: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
            flightOptions: ['行程单'],
            flightMoneyOptions: ['增值税普票'],
            flightRefundOptions: ['增值税专票', '增值税普票'],
            trainOptions: ['火车票票根'],
            hotelOptions: ['增值税专票', '增值税普票'],
            carOptions: ['增值税专票', '增值税普票'],
            boatOptions: ['船票票根'],
            doorOptions: ['增值税普票'],
            visaOptions: ['增值税普票'],
            otherOptions: ['增值税专票', '增值税普票'],
            serviceOptions: ['增值税专票', '增值税普票'],
        }
    },
    components: {OrderLog, InlandProvinceCityRegion},
    created() {
        // this.req_province_city_data(1, {});
    },
    mounted() {
        // consumer_web_bank_getBankList().then((res) => {
        //     res.datas.forEach((item) => {
        //         item.bankCode = Number(item.bankCode)
        //     });
        //     this.allocation.bankList = res.datas;
        // });
        this.getInsuranceProList();
        consumer_web_industry_selectIndustry().then((res) => {
            this.allocation.industryType = res.datas;
        });
        consumer_web_staff_staffAll().then((res) => {
            this.allocation.manager = res.datas;
        });
        consumer_sys_inform_getEnum(["settlement"]).then((res) => {
            this.allocation.settlementList = res.datas.settlement;
        });
        // consumer_web_company_getPnrSetType().then(res => {
        //     this.pnrSetOptions = res.datas.result;
        // });
        consumer_flight_search_citys().then(res => {
            let cityList = [];
            let cityCodeList = [];
            res.citys.forEach(value => {
                if (value.airports && cityCodeList.indexOf(value.code) === -1) {
                    cityCodeList.push(value.code)
                    cityList.push(value)
                }
            })
            this.allCityList = cityList
            this.filterCityList = JSON.parse(JSON.stringify(this.allCityList))
            // this.startCityList = JSON.parse(JSON.stringify(this.allCityList))
            // this.endCityList = JSON.parse(JSON.stringify(this.allCityList))
        });
        consumerweb_company_queryCompanyInvoiceType().then((res) => {
            this.invoiceType = res.datas;
        });
    },
    async activated() {
        await this.init();
        this.cardInfo[4].edit = [false, false, false, false];
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        locationCity: {
            handler(val) {
                const form = this.form;
                form.areaProvince = val.areaProvince;
                form.areaProvinceName = val.areaProvinceName ? val.areaProvinceName : form.areaProvinceName;
                form.areaCity = val.areaCity;
                form.areaCityName = val.areaCityName ? val.areaCityName : form.areaCityName;
                form.areaRegion = val.areaRegion;
                form.areaRegionName = val.areaRegionName ? val.areaRegionName : form.areaRegionName;
            },
            deep: true,
        },
    },
    computed: {
    },
    filters: {
        format_carryRules(val) {
            switch (Number(val)) {
                case 0:
                    return "进位到元";
                    break;
                case 1:
                    return "进位到分";
                    break;
            }
        },
        format_amountSet(val) {
            switch (Number(val)) {
                case 0:
                    return "不收取";
                    break;
                case 1:
                    return "收取";
                    break;
            }
        },
        format_amountType(val) {
            switch (Number(val)) {
                case 1:
                    return "百分比";
                    break;
                case 2:
                    return "定额";
                    break;
                default:
                    return ''
            }
        },
        format_scale(val) {
            if (val === 1) {
                return '0~20人'
            } else if (val === 2) {
                return '20~50人'
            } else if (val === 3) {
                return '50~100人'
            } else if (val === 4) {
                return '100~300人'
            } else if (val === 5) {
                return '300~1000人'
            } else if (val === 6) {
                return '1000人以上'
            } else {
                return ''
            }
        },
        format_audit(val) {
            switch (Boolean(val)) {
                case true:
                    return "是";
                    break;
                case false:
                    return "否";
                    break;
            }
        },
        format_allowUseCar(val) {
            switch (val) {
                case 1:
                    return "是";
                    break;
                case 0:
                    return "否";
                    break;
            }
        },
        format_openMemberMt(val) {
            switch (val) {
                case 1:
                    return "是";
                    break;
                case 0:
                    return "否";
                    break;
            }
        },
        format_pass(val) {
            switch (val) {
                case 1:
                    return "是";
                    break;
                case 0:
                    return "否";
                    break;
            }
        },
        format_type(val) {
            // "业务类型：1.机票，2.火车票，3.酒店，4.汽车"
            switch (Number(val)) {
                case 4:
                    return "用车";
                    break;
                case 2:
                    return "火车票";
                    break;
                case 3:
                    return "酒店";
                    break;
                case 1:
                    return "机票";
                    break;
            }
        },
        format_policyStatus(val) {
            switch (Number(val)) {
                case 1:
                    return "申请中";
                    break;
                case 2:
                    return "是"; // 启用
                    break;
                case 3:
                    return "否"; // 停用
                    break;
            }
        },
        format_startAirPortOptions(val) {
            switch (Number(val)) {
                case 1:
                    return "全部";
                    break;
                case 2:
                    return "限定";
                    break;
            }
        },
        format_shareAirlineType(val) {
            switch (Number(val)) {
                case 1:
                    return "全部";
                    break;
                case 2:
                    return "限定";
                    break;
                case 3:
                    return "不适用";
                    break;
            }
        },
    },
    methods: {
        switchChangeTrain(businessType,text) {
            let dataBox = null;
            if (businessType == 3) {
                eval(`dataBox = ${text}`);
                if (dataBox == 0) {
                    eval(`${text} = 2`);
                }
            }
            if (businessType == 2) {
                eval(`${text} = 2`);
            }
        },
        inputMatch(data,text) {
            console.log(data, eval(`${data}.match(${text}) == null ? '' : ${data}.match(${text})[0];`));
            eval(`${data} = ${data}.match(${text}) == null ? '' : ${data}.match(${text})[0];`);
        },
        inputData(data, off) {
            if (off == 1) {
                if (data == '' || Number(data) <= 0) {
                    this.form.agreement.serviceAmount = '';
                }
            } else if (off == 2) {
                if (Number(this.form.pdProductSetList[3].agreementServiceAmountLimit) <= 0) {
                    this.form.pdProductSetList[3].agreementServiceAmountLimit = '';
                }
                if (Number(this.form.pdProductSetList[3].offlineServiceAmountLimit) <= 0) {
                    this.form.pdProductSetList[3].offlineServiceAmountLimit = '';
                }
            }
        },
        getInsuranceProList() {
            let insuranceOptions = [];
            let trainInsuranceOptions = [];
            consumer_web_company_getInsuranceProductList({businessType: 1}).then(res=>{
                insuranceOptions = res.datas.insuranceProductVoList;
                insuranceOptions.forEach(value=>{
                    value.label = `${value.insuredSupplierName} ${value.packageName} ¥${value.insuredFee}`
                })
                /*insuranceOptions.unshift({
                    label: '不赠送',
                    productCode: 0,
                })*/
                this.insuranceOptions = insuranceOptions;
            }).catch(e=>{
                /*insuranceOptions.unshift({
                    label: '不赠送',
                    productCode: 0,
                })*/
                this.insuranceOptions = insuranceOptions;
            })
            consumer_web_company_getInsuranceProductList({businessType: 2}).then(res=>{
                trainInsuranceOptions = res.datas.insuranceProductVoList;
                trainInsuranceOptions.forEach(value=>{
                    value.label = `${value.insuredSupplierName} ${value.packageName} ¥${value.insuredFee}`
                })
                /*insuranceOptions.unshift({
                    label: '不赠送',
                    productCode: 0,
                })*/
                this.trainInsuranceOptions = trainInsuranceOptions;
            }).catch(e=>{
                /*insuranceOptions.unshift({
                    label: '不赠送',
                    productCode: 0,
                })*/
                this.trainInsuranceOptions = trainInsuranceOptions;
            })
        },
        redCompanyAgreeDiscount() {
            this.$refs['form6'].validateField('agreement.redCompanyAgree.discount');
        },
        openOrCloseCard(val) {
            console.log('chufadao');
            this.cardInfo.forEach((value, index) => {
                if (val === index) {
                    value.more = !value.more;
                } else {
                    value.more = false;
                }
            });
        },
        toEdit(val, childVal) {
            const __this = this;
            const bankList = __this.form.bankList;
            const linkman = {name: '', phone: ''};

            this.cardInfo.forEach((value, index) => {
                if (val === index) {
                    value.more = true;
                    if (index === 4) {
                        value.edit.forEach((value1, index1) => {
                            if (index1 === childVal) {
                                value.edit[index1] = true
                            } else {
                                value.edit[index1] = false
                            }
                        })
                    } else {
                        value.edit = true;
                    }
                } else {
                    value.more = false;
                    if (index === 4) {
                        value.edit = [false, false, false, false]
                    } else {
                        value.edit = false;
                    }
                }
            });

            if (val === 5) {
                // 协议机票
                let activeFlightForm = JSON.parse(JSON.stringify(this.activeFlightInfo));
                if (activeFlightForm.conditions) {
                    activeFlightForm.conditions.forEach(value => {
                        console.log(value)
                        if (value.depAirportParams) {
                            value.depAirportParamsText = value.depAirportParams.join(',')
                        } else {
                            value.depAirportParamsText = ''
                        }
                        if (value.depAirportExcludes) {
                            value.depAirportExcludesText = value.depAirportExcludes.join(',')
                        } else {
                            value.depAirportExcludesText = ''
                        }
                        if (value.arrAirportParams) {
                            value.arrAirportParamsText = value.arrAirportParams.join(',')
                        } else {
                            value.arrAirportParamsText = ''
                        }
                        if (value.arrAirportExcludes) {
                            value.arrAirportExcludesText = value.arrAirportExcludes.join(',')
                        } else {
                            value.arrAirportExcludesText = ''
                        }
                        if (value.cabinParams) {
                            value.cabinParamsText = value.cabinParams.join(',')
                        } else {
                            value.cabinParamsText = ''
                        }
                        if (value.cabinExcludes) {
                            value.cabinExcludesText = value.cabinExcludes.join(',')
                        } else {
                            value.cabinExcludesText = ''
                        }
                    })
                }
                this.activeFlightForm = activeFlightForm;
            } else if (val === 7) {
                this.insuranceForm = JSON.parse(JSON.stringify(this.insuranceDetail));
            } else if (val === 8 || val === 2) {
                this.matchSetForm = JSON.parse(JSON.stringify(this.matchSetDetail));
            } else {
                this.form = JSON.parse(JSON.stringify(this.detail));
                if (this.form.agreement.hzCompanyArgee === null) {
                    this.form.agreement.hzCompanyArgee = {
                        enable: 0,
                            cardNumber: '',
                            groupType: 6,
                            groupTypeText: '华住',
                            discount: undefined,
                    }
                };
                if (this.form.agreement.dcCompanyArgee === null) {
                    this.form.agreement.dcCompanyArgee = {
                        enable: 0,
                        cardNumber: '',
                        groupType: 9,
                        groupTypeText: '东呈',
                        rateplanList: [],
                        rateplanActiveList: [], // 选中的
                        discount: undefined,
                    }
                };
                if (this.form.agreement.ydCompanyArgee === null) {
                    this.form.agreement.ydCompanyArgee = {
                        enable: 0,
                        cardNumber: '',
                        discount: undefined,
                        ydCardNumberInfoList: [
                            {
                                ydCardNumber: '',
                                ydCardNumberName: ''
                            }
                        ],
                    }
                };
                if (this.form.agreement.jjCompanyArgee === null) {
                    this.form.agreement.jjCompanyArgee = {
                        enable: 0,
                            cardNumber: '',
                            jjSignKey: '',
                            discount: undefined,
                    }
                };
                if (this.form.agreement.redCompanyAgree === null) {
                    this.form.agreement.redCompanyAgree = {
                        enable: 0,
                        redPartnerCode: '',
                        redSecureKey: '',
                        discount: undefined,
                    }
                };
                if (this.form.agreement.qtCompanyAgree === null) {
                    this.form.agreement.qtCompanyAgree = {
                        enable: 0,
                        qtPartnerCode: '',
                    }
                };
                console.log(this.form);
                this.$nextTick(() => {
                    try {
                        let hzCompanyArgeeEnable = this.form.agreement.hzCompanyArgee.enable
                        let dcCompanyArgeeEnable = this.form.agreement.dcCompanyArgee.enable
                        let ydCompanyArgeeEnable = this.form.agreement.ydCompanyArgee.enable
                        let jjCompanyArgeeEnable = this.form.agreement.jjCompanyArgee.enable
                        let redCompanyArgeeEnable = this.form.agreement.redCompanyAgree.enable
                        let qtCompanyArgeeEnable = this.form.agreement.qtCompanyAgree.enable
                        if (hzCompanyArgeeEnable || dcCompanyArgeeEnable || ydCompanyArgeeEnable || jjCompanyArgeeEnable || redCompanyArgeeEnable || qtCompanyArgeeEnable) {
                            this.serviceChargeData = true;
                            throw new Error("EndIterative");
                        } else {
                            this.serviceChargeData = false;
                        }
                    }catch (e) {}

                    __this.form.companyId = this.detail.id;
                    __this.form.audit = this.detail.audit;
                    __this.form.openCar = this.detail.openCar;
                    __this.form.openMemberMt = this.detail.openMemberMt;

                    // 初始化行业
                    if (__this.form.industryId) {
                        __this.allocation.industryType.forEach((item, index) => {
                            if (__this.form.industryId === item.id) {
                                __this.form.industryName = item.industryName;
                            }
                        });
                    }

                    // 初始化城市
                    if (val === 0) {
                        __this.$refs.locationCity.init({
                            areaProvince: Number(__this.form.areaProvince),
                            areaProvinceName: __this.form.areaProvinceName,
                            areaCity: Number(__this.form.areaCity),
                            areaCityName: __this.form.areaCityName,
                            areaRegion: Number(__this.form.areaRegion),
                            areaRegionName: __this.form.areaRegionName
                        });
                    }
                    // 初始化城市 end

                    // 联系人
                    __this.form.reserveLinkmanObj = __this.form.reserveLinkman ? JSON.parse(__this.form.reserveLinkman) : linkman;
                    __this.form.contractLinkmanObj = __this.form.contractLinkman ? JSON.parse(__this.form.contractLinkman) : linkman;
                    __this.form.financeLinkmanObj = __this.form.financeLinkman ? JSON.parse(__this.form.financeLinkman) : linkman;
                    __this.form.urgencyLinkmanObj = __this.form.urgencyLinkman ? JSON.parse(__this.form.urgencyLinkman) : linkman;
                    // 联系人 end

                    // 服务费设置
                    __this.form.pdProductSetList.forEach((item, index) => {
                        __this.form.pdProductSetList[index].serviceAmount = item.serviceAmount ? item.serviceAmount + '' : '0';
                        __this.form.pdProductSetList[index].robTicket = item.robTicket ? item.robTicket + '' : '0';

                    });
                    // 服务费设置 end

                    /*if(__this.form.agreement.agreeInfoList[1].cardGrade == null){
                        __this.form.agreement.agreeInfoList[1].cardGrade = 'RackRate';
                    }*/

                    // 附件

                    __this.accessoryUrl = this.detail.accessoryUrl ? JSON.parse(this.detail.accessoryUrl) : [{name:'',url:''}];
                    // 附件end
                })
            }
            console.log(this.form)
            this.$nextTick(() => {
                // this.$refs[`form${val}`].resetFields();
            })
        },
        toCloseEdit(val) {
            this.cardInfo.forEach((value, index) => {
                if (val === index) {
                    if (index === 4) {
                        value.edit = [false, false, false, false];
                    } else {
                        value.edit = false;
                    }
                }
            })
            if (val === 5) {
                this.activeFlightForm = JSON.parse(JSON.stringify(this.activeFlightInfo));
                let policyInfo = [];
                this.activeFlightInfo.conditions.forEach(value => {
                    policyInfo.push({
                        more: false,
                        edit: false
                    })
                })
                this.policyInfo = policyInfo
            }
        },
        checkForm(formName) {
            return new Promise(resolve => {
                this.$refs[formName].validate((valid) => {
                    console.log(valid)
                    resolve(valid);
                });
            })
        },
        async toSaveService(val,index) {
            const __this = this;
            if (val === 0 && !__this.form.companyLogo) {
                __this.$message.warning('请上传公司LOGO');
                return;
            }
            if (val === 1 && !__this.form.businessLicensePic) {
                __this.$message.warning('请上传营业执照');
                return;
            }
            if (val === 3) {
                // 处理附件
                __this.form.accessoryUrl = JSON.stringify(__this.accessoryUrl);
            }
            /*if (val === 5 && (!__this.activeFlightForm.conditions || __this.activeFlightForm.conditions.length === 0)) {
                __this.$message.warning('请至少添加一组政策');
                return;
            }*/
            const formName = `form${val}`;
            let valid = await this.checkForm(formName);
            console.log(valid)
            if (!valid) {
                this.locateToErr();
                return
            }
            let params = {
                companyId: Number(this.$route.query.id),
                updateSetList: JSON.parse(JSON.stringify([this.form.pdProductSetList[index]])),
            }

            consumer_web_company_adultServiceSet(params).then(res=>{
                this.loading = false;
                if (this.detail.clientType === 1 || res.msg != '保存成功，待财务确认后生效。') {
                    // 临时
                    this.$message({
                        message: '保存成功',
                        type: 'success',
                        onClose: () => {
                            this.cardInfo[val].edit = [false, false, false, false];
                            this.reqDetail();
                            this.getOrderLog();
                        }
                    });
                } else {
                    this.$alert('保存成功，待财务确认后生效。', '温馨提示', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.cardInfo[val].edit = [false, false, false, false];
                            this.reqDetail();
                            this.getOrderLog();
                        }
                    });
                }

            }).catch(err => {
                this.loading = false
            });
        },
        toSaveThisCard(val) {
            const __this = this;
            if (val === 0 && !__this.form.companyLogo) {
                __this.$message.warning('请上传公司LOGO');
                return;
            }
            if (val === 1 && !__this.form.businessLicensePic) {
                __this.$message.warning('请上传营业执照');
                return;
            }
            if (val === 3) {
                // 处理附件
                __this.form.accessoryUrl = JSON.stringify(__this.accessoryUrl);
            }
            /*if (val === 5 && (!__this.activeFlightForm.conditions || __this.activeFlightForm.conditions.length === 0)) {
                __this.$message.warning('请至少添加一组政策');
                return;
            }*/
            if (val === 6) {
                this.validateCompany();
            }
            const formName = `form${val}`
            console.log(formName, __this.$refs[formName]);
            __this.$refs[formName].validate((valid) => {
                console.log(valid)
                if (!valid) {
                    this.locateToErr();
                    return
                }
                if (val === 5) {
                    __this.reqFlightPolicyCommit(val);
                } else if(val === 7) {
                    __this.saveInsurance(val);
                } else if(val === 8) {
                    // 提交结算
                    __this.reqConfigInfo(val);
                } else {
                    __this.reqCommit(val);
                }
            });
        },
        async saveInsurance(val) {
            // 制作入参
            let params = {
                companyId: this.$route.query.id,
                insurances: []
            }
            for (let key in this.insuranceForm) {
                this.insuranceForm[key].forEach(value=>{
                    params.insurances.push(value)
                })
            }
            this.loading = true;
            let [err, res] = await awaitWrap(consumer_web_company_updateCompanyInsurance(params));
            this.loading = false;
            if (err) {
                return;
            }
            this.$message({
                message: '保存成功',
                type: 'success',
                onClose: () => {
                    this.cardInfo.forEach((value, index) => {
                        if (val === index) {
                            value.edit = false;
                        }
                    });
                    this.insuranceDetail = JSON.parse(JSON.stringify(this.insuranceForm));
                }
            });
        },
        validateCompany(){
            this.$refs.form6.validateField(["agreement.redCompanyAgree.redPartnerCode"], (valid,vv) => {
                if(valid){
                    this.companyArgeeActiveName = '5'
                } else {
                    return
                }
            });
            this.$refs.form6.validateField(["agreement.redCompanyAgree.redSecureKey"], (valid,vv) => {
                if(valid){
                    this.companyArgeeActiveName = '5'
                } else {
                    return
                }
            });
            this.$refs.form6.validateField(["agreement.jjCompanyArgee.cardNumber"], (valid,vv) => {
                if(valid){
                    this.companyArgeeActiveName = '4'
                } else {
                    return
                }
            });
            this.$refs.form6.validateField(["agreement.jjCompanyArgee.jjSignKey"], (valid,vv) => {
                if(valid){
                    this.companyArgeeActiveName = '4'
                } else {
                    return
                }
            });
            this.$refs.form6.validateField(["agreement.ydCompanyArgee.cardNumber"], (valid,vv) => {
                if(valid){
                    this.companyArgeeActiveName = '3'
                } else {
                    return
                }
            });
            this.$refs.form6.validateField(["agreement.dcCompanyArgee.cardNumber"], (valid,vv) => {
                if(valid){
                    this.companyArgeeActiveName = '2'
                } else {
                    return
                }
            });
            this.$refs.form6.validateField(["agreement.dcCompanyArgee.cardGrade"], (valid,vv) => {
                if(valid){
                    this.companyArgeeActiveName = '2'
                } else {
                    return
                }
            });
            this.$refs.form6.validateField(["agreement.hzCompanyArgee.cardNumber"], (valid,vv) => {
                if(valid){
                    this.companyArgeeActiveName = '1'
                } else {
                    return
                }
            });
        },
        reqCommit(val) {
            this.loading = true;
            const __this = this;
            // 联系人
            const contractLinkman = __this.form.contractLinkmanObj;
            const financeLinkman = __this.form.financeLinkmanObj;
            const reserveLinkman = __this.form.reserveLinkmanObj;
            const urgencyLinkman = __this.form.urgencyLinkmanObj;
            __this.form.contractLinkman = JSON.stringify(contractLinkman);
            __this.form.financeLinkman = JSON.stringify(financeLinkman);
            __this.form.reserveLinkman = JSON.stringify(reserveLinkman);
            __this.form.urgencyLinkman = JSON.stringify(urgencyLinkman);
            __this.$refs[`form${val}`].clearValidate();
            let form = JSON.parse(JSON.stringify(__this.form))
            let ydCardNumberInfoList = []
            form.agreement.ydCompanyArgee.ydCardNumberInfoList.forEach(value => {
                if (value.ydCardNumber || value.ydCardNumberName) {
                    ydCardNumberInfoList.push(value)
                }
            })
            if (form.agreement.dcCompanyArgee.cardGradeList.length > 0) {
                form.agreement.dcCompanyArgee.cardGrade = form.agreement.dcCompanyArgee.cardGradeList.join(',')
            }
            form.agreement.ydCompanyArgee.ydCardNumberInfoList = ydCardNumberInfoList;
            this.reqConfigInfo(2, false);
            consumer_web_company_updateCustomer(form).then((res) => {
                // __this.loading = false;
                //__this.form.contractLinkman = contractLinkman;
                //__this.form.financeLinkman = financeLinkman;
                //__this.form.reserveLinkman = reserveLinkman;
                //__this.form.urgencyLinkman = urgencyLinkman;

                __this.$message({
                    message: '保存成功',
                    type: 'success',
                    onClose: () => {
                        if (val === 4) {
                            __this.cardInfo[val].edit = [false, false, false, false];
                        } else {
                            __this.cardInfo[val].edit = false
                        }
                        __this.reqDetail();
                        __this.getOrderLog();
                    }
                });
            }).catch(err => {
                __this.loading = false
            });
        },
        reqConfigInfo(val, runSaveAfter = true) {
            this.loading = true;
            const __this = this;
            let form = {};
            if (val == 2) {
                form = {
                    companyId: Number(this.$route.query.id),
                    companyExpendVo: {
                        specialFocus: this.specialFocus,   // 是否开通特别关注功能", notes = "true-开通，false-关闭
                        hotelTogether: this.hotelTogether,   // 是否开通酒店合作功能", notes = "true-开通，false-关闭
                    },
                };
            } else {
                form = {
                    companyId: Number(this.$route.query.id),
                    companyBillConfigVo: JSON.parse(JSON.stringify(this.matchSetForm)),
                };
            }
            console.log(form);
            consumer_admin_company_save(form).then(res=>{
                if (runSaveAfter) {
                    __this.$message({
                        message: '保存成功',
                        type: 'success',
                        onClose: () => {
                            __this.cardInfo[val].edit = false;
                            __this.reqDetail();
                            __this.getOrderLog();
                        }
                    });
                }

            }).catch(err => {
                __this.loading = false
            });
        },
        reqFlightPolicyCommit(val) {
            this.loading = true;
            const __this = this;
            if (this.activeFlightForm.travelValidityArr && this.activeFlightForm.travelValidityArr.length > 0) {
                this.activeFlightForm.travelValidityStart = this.activeFlightForm.travelValidityArr[0];
                this.activeFlightForm.travelValidityEnd = this.activeFlightForm.travelValidityArr[1];
            } else {
                this.activeFlightForm.travelValidityStart = '';
                this.activeFlightForm.travelValidityEnd = '';
            }
            if (this.activeFlightForm.excludeTravelValidityArr && this.activeFlightForm.excludeTravelValidityArr.length > 0) {
                this.activeFlightForm.excludeTravelValidity = this.activeFlightForm.excludeTravelValidityArr.join(',');
            } else {
                this.activeFlightForm.excludeTravelValidity = ''
            }
            if (this.activeFlightForm.saleValidityArr && this.activeFlightForm.saleValidityArr.length > 0) {
                this.activeFlightForm.saleValidityStart = this.activeFlightForm.saleValidityArr[0];
                this.activeFlightForm.saleValidityEnd = this.activeFlightForm.saleValidityArr[1];
            } else {
                this.activeFlightForm.saleValidityStart = '';
                this.activeFlightForm.saleValidityEnd = '';
            }
            if (this.activeFlightForm.excludeSaleValidityArr && this.activeFlightForm.excludeSaleValidityArr.length > 0) {
                this.activeFlightForm.excludeSaleValidity = this.activeFlightForm.excludeSaleValidityArr.join(',');
            } else {
                this.activeFlightForm.excludeSaleValidity = ''
            }
            if (this.activeFlightForm.conditions && this.activeFlightForm.conditions.length > 0) {
                this.activeFlightForm.conditions.forEach(value => {
                    if (value.arrAirportType == 1) {
                        value.arrAirportParamsText = '';
                        value.arrAirportParams = [];
                        value.arrAirportParam = '';
                    }
                    if (value.depAirportType == 1) {
                        value.depAirportParamsText = '';
                        value.depAirportParams = [];
                        value.depAirportParam = '';
                    }
                    //value.depAirportParam = value.depAirportParamArr.join(',')
                    //value.depAirportExclude = value.depAirportExcludeArr.join(',')
                    //value.arrAirportParam = value.arrAirportParamArr.join(',')
                    //value.arrAirportExclude = value.arrAirportExcludeArr.join(',')
                })
            }
            const submitFun = this.activeFlightForm.id ? consumer_web_company_addkeyacEdit : consumer_web_company_addkeyacCount;
            submitFun(this.activeFlightForm).then(res => {
                // __this.loading = false;
                __this.$message({
                    message: '保存成功',
                    type: 'success',
                    onClose: () => {
                        __this.cardInfo.forEach((value, index) => {
                            if (val === index) {
                                value.edit = false;
                            }
                        });
                        __this.reqFlightPolicyDetail()
                    }
                });
            }).catch(err => {
                this.loading = false;
            })
        },
        locateToErr() {
            setTimeout(() => {
                const errorDiv = document.getElementsByClassName('is-error');
                errorDiv[0].scrollIntoView({ behavior: "smooth" })
            }, 0)
        },
        openOrClosePolicy(item, index) {
            this.policyInfo.forEach((value1, index1) => {
                if (index1 === index) {
                    value1.more = !value1.more
                } else {
                    value1.more = false
                }
            })
        },
        delPolicy(item, index) {
            if (!this.cardInfo[5].edit && this.policyInfo.length === 1) {
                return;
            }
            if (!item.id) {
                this.activeFlightForm.conditions.splice(index, 1);
                this.policyInfo.splice(index, 1);
                return
            }
            if (this.cardInfo[5].edit) {
                // 编辑中
                this.activeFlightForm.conditions.splice(index, 1);
                this.policyInfo.splice(index, 1);
            } else {
                this.$confirm('是否删除该政策?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.activeFlightForm.conditions.splice(index, 1);
                    this.toSaveThisCard(5)
                }).catch(() => {
                });
            }
        },
        toEditPolicy(item, iIndex) {
            // 取消别的组
            this.cardInfo.forEach((value, index) => {
                if (index === 4) {
                    value.edit = [false, false, false, false]
                } else {
                    value.edit = false
                }
            })
            let activeFlightForm = JSON.parse(JSON.stringify(this.activeFlightInfo));
            if (activeFlightForm.conditions) {
                activeFlightForm.conditions.forEach(value => {
                    console.log(value)
                    if (value.depAirportParams) {
                        value.depAirportParamsText = value.depAirportParams.join(',')
                    } else {
                        value.depAirportParamsText = ''
                    }
                    if (value.depAirportExcludes) {
                        value.depAirportExcludesText = value.depAirportExcludes.join(',')
                    } else {
                        value.depAirportExcludesText = ''
                    }
                    if (value.arrAirportParams) {
                        value.arrAirportParamsText = value.arrAirportParams.join(',')
                    } else {
                        value.arrAirportParamsText = ''
                    }
                    if (value.arrAirportExcludes) {
                        value.arrAirportExcludesText = value.arrAirportExcludes.join(',')
                    } else {
                        value.arrAirportExcludesText = ''
                    }
                    if (value.cabinParams) {
                        value.cabinParamsText = value.cabinParams.join(',')
                    } else {
                        value.cabinParamsText = ''
                    }
                    if (value.cabinExcludes) {
                        value.cabinExcludesText = value.cabinExcludes.join(',')
                    } else {
                        value.cabinExcludesText = ''
                    }
                })
            }
            console.log(activeFlightForm);
            this.activeFlightForm = activeFlightForm;
            this.policyInfo.forEach((value, index) => {
                if (iIndex === index) {
                    value.edit = true;
                    value.more = true;
                } else {
                    value.edit = false;
                    value.more = false;
                }
            })
        },
        toCloseEditPolicy(iem, index) {
            this.activeFlightForm = JSON.parse(JSON.stringify(this.activeFlightInfo));
            let policyInfo = JSON.parse(JSON.stringify(this.policyInfo))
            // this.activeFlightInfo.conditions.forEach(value => {
            //     policyInfo.push({
            //         more: false,
            //         edit: false,
            //     })
            // })
            policyInfo.forEach(value => {
                value.edit = false
            })
            this.policyInfo = policyInfo
        },
        addNewPolicy() {
            if (this.cardInfo[5].edit) {
                this.activeFlightForm.conditions.push({
                    discountRate: '',
                    depAirportType: 1, // 适用起飞机场 1全部 2部分
                    depAirportParam: '',
                    depAirportParamArr: [],
                    depAirportExclude: '',
                    depAirportExcludeArr: [],
                    arrAirportType: 1, // 适用到达机场 1全部 2部分
                    arrAirportParam: '',
                    arrAirportParamArr: [],
                    arrAirportExclude: '',
                    arrAirportExcludeArr: [],
                    shareAirlineType: 1, // 适用共享航班类型 1-全部适用，2-部分适用,3-不适用
                    shareAirlineParam: '',
                    shareAirlineExclude: '',
                    cabinType: 1, // 适用舱位类型1-全部适用，2-部分适用
                    cabinParam: '',
                    cabinExclude: '',
                })
                this.policyInfo.forEach((value, index) => {
                    value.edit = false;
                    value.more = false;
                })
                this.policyInfo.push({
                    edit: false,
                    more: true,
                })
            } else {
                // 选把未保存的提交
                let isHasEditNow = false;
                this.policyInfo.forEach(value => {
                    if (value.edit) {
                        isHasEditNow = true
                    }
                });
                if (isHasEditNow) {
                    this.$message({
                        type: "warning",
                        message: '请先保存正在编辑的政策'
                    })
                    return
                }
                // 取消别的组
                this.cardInfo.forEach((value, index) => {
                    if (index === 4) {
                        value.edit = [false, false, false, false]
                    } else {
                        value.edit = false
                    }
                })
                this.activeFlightForm = JSON.parse(JSON.stringify(this.activeFlightInfo));
                this.activeFlightForm.conditions.push({
                    discountRate: '',
                    depAirportType: 1, // 适用起飞机场 1全部 2部分
                    depAirportParam: '',
                    depAirportParamArr: [],
                    depAirportExclude: '',
                    depAirportExcludeArr: [],
                    arrAirportType: 1, // 适用到达机场 1全部 2部分
                    arrAirportParam: '',
                    arrAirportParamArr: [],
                    arrAirportExclude: '',
                    arrAirportExcludeArr: [],
                    shareAirlineType: 1, // 适用共享航班类型 1-全部适用，2-部分适用,3-不适用
                    shareAirlineParam: '',
                    shareAirlineExclude: '',
                    cabinType: 1, // 适用舱位类型1-全部适用，2-部分适用
                    cabinParam: '',
                    cabinExclude: '',
                })
                let policyInfo = [];
                this.activeFlightForm.conditions.forEach((value, index) => {
                    policyInfo.push({
                        edit: (this.activeFlightForm.conditions.length - 1 === index) ? true : false,
                        more: (this.activeFlightForm.conditions.length - 1 === index) ? true : false,
                    })
                });
                this.policyInfo = policyInfo;
            }
        },

        async init() {
            const __this = this;
            this.loading = true;
            let apiList = [
                consumer_hotel_search_getDCRateplanList(),
                consumer_web_company_getDCCardLevel(),
                consumer_web_company_getCompanyInsurance({companyId: Number(this.$route.query.id)}),
                consumer_web_company_serviceSetInfo({companyId: Number(this.$route.query.id)}),
            ];
            let [err, res] = await awaitWrap(Promise.all(apiList.map((promise)=> promise.catch((e)=>{}))))
            if (err) {
                console.log(res);
                return
            }
            console.log(res);

            if (res[1] && res[1].datas && res[0] && res[0].datas) {
                const DCCardLevel = res[1].datas.result;
                const DCRateplanList = res[0].datas.rateplanList;
                let rateplanOptions = []
                DCRateplanList.forEach(value => {
                    DCCardLevel.forEach(value1 => {
                        let params = {
                            value: `${value.rateplanCode}-${value1.value}`,
                            level: value1.value,
                            code: value.rateplanCode,
                            label: `${value.rateplanName}-${value1.text}`,
                            rateplanName: value.rateplanName,
                        }
                        rateplanOptions.push(params)
                    })
                })

                this.rateplanOptions = rateplanOptions;
                this.DCCardLevelList = DCCardLevel;
            }

            if (res[2].datas) {
                let insuranceForm = {
                    flight: [],
                    train: [],
                    hotel:[],
                    car: [],
                }
                let flightZoneTypeList = [];
                const insuranceList = res[2].datas.insuranceList;
                if (insuranceList.length > 0) {
                    insuranceList.forEach(value=>{
                        switch (value.businessType) {
                            case 1:
                                insuranceForm.flight.push(value);
                                flightZoneTypeList.push(value.zoneType);
                                break
                            case 2:
                                insuranceForm.train.push(value);
                                break
                            case 3:
                                insuranceForm.hotel.push(value);
                                break
                            case 4:
                                insuranceForm.car.push(value);
                                break
                        }
                    })

                }
                if (insuranceForm.flight.length < 3) {
                    const flight = [
                        {
                            businessType: 1,
                            productCode: '',
                            insuranceInvoiceType: 0,
                            freeProduct: 0,
                            zoneType: 0, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                        },
                        {
                            businessType: 1,
                            productCode: '',
                            insuranceInvoiceType: 0,
                            freeProduct: 0,
                            zoneType: 1, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                        },
                        {
                            businessType: 1,
                            productCode: '',
                            insuranceInvoiceType: 0,
                            freeProduct: 0,
                            zoneType: 2, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                        },
                    ]
                    flight.forEach(value => {
                        if (flightZoneTypeList.indexOf(value.zoneType) === -1) {
                            insuranceForm.flight.push(value);
                            flightZoneTypeList.push(value.zoneType);
                        }
                    })
                }
                if (insuranceForm.train.length === 0) {
                    insuranceForm.train.push({
                        businessType: 2,
                        productCode: '',
                        insuranceInvoiceType: 0,
                        freeProduct: 0,
                        zoneType: 0, // 保险赠送地区类型 0-国内赠送保险产品 1-国际亚洲地区内赠送保险产品 2-国际亚洲地区外赠送保险产品
                    });
                }
                this.insuranceDetail = insuranceForm;
            }

            if (res[3] && res[3].datas) {
                this.pdProductSetTempList = res[3].datas.pdProductSetTempList || [];
            }



            let [planeErr, planeRes] = await awaitWrap(consumer_web_company_airlineCodeAll());
            if (planeErr) {
                return
            }
            this.flightCompanyList = planeRes.datas
            if (this.flightCompanyList && this.flightCompanyList.length > 0) {
                this.flightSetActiveName = this.flightCompanyList[0].code;
            }
            await this.reqDetail(true);
            await this.reqFlightPolicyDetail(null, true);

            if (this.detail.agreement.dcCompanyArgee.rateplanActiveList.length > 0) {
                this.changeRrateplanList(this.detail.agreement.dcCompanyArgee.rateplanActiveList)
            }

            this.getOrderLog()

            this.$nextTick(() => {
                // 跳转到对应锚点
                const elementName = this.$route.query.type;
                if (!elementName) {
                    return
                }
                switch (elementName) {
                    case 'flight-card':
                        this.openOrCloseCard(5)
                        break
                    case 'hotel-card':
                        this.openOrCloseCard(6)
                        break
                }
                let element = document.getElementById(elementName);
                element.scrollIntoView({
                    behavior: "auto"
                })
            })
        },
        async reqDetail(isInit) {
            const __this = this;
            this.loading = true;
            let [err, res] = await awaitWrap(consumer_web_company_detail({id: __this.$route.query.id}))
            if (err) {
                this.loading = false;
                // loading.close()
                return
            }
            let [configErr, configRes] = await awaitWrap(consumer_admin_company_query({companyId: Number(this.$route.query.id), bizType: 0}));
            if (configErr) {
                this.loading = false;
                return;
            }
            if (configRes.datas && configRes.datas.companyExpendVo) {
                this.specialFocus = configRes.datas.companyExpendVo.specialFocus;
                this.hotelTogether = configRes.datas.companyExpendVo.hotelTogether;
            }
            let matchSetDetail = configRes.datas && configRes.datas.companyBillConfigVo ? configRes.datas.companyBillConfigVo :  {
                //"companyName": res.datas.companyName,
                //"companyId": res.datas.id,
                "hotelSplit": true,
                "settlingDay": '',
                "invoiceType": null,
                templatePath: '',
                exportLevel: 1,
            };
            matchSetDetail.companyName = res.datas.companyName;
            matchSetDetail.companyId = res.datas.id;
            if (!matchSetDetail.invoiceType) {
                matchSetDetail.invoiceType = {
                    "flight": "行程单",
                    "itinerary": "增值稅普票",
                    "flightRef": "增值稅普票",
                    "train": "火车票票根",
                    "hotel": "增值税普票",
                    "car": "增值稅普票",
                    "ship": "自取船票票根",
                    "door": "增值税普票",
                    "visa": "增值稅普票",
                    "extra": "增值税普票",
                    "serviceFee": "增值稅普票"
                };
            }
            matchSetDetail.invoiceType.flight = matchSetDetail.invoiceType.flight || "行程单";
            matchSetDetail.invoiceType.itinerary = matchSetDetail.invoiceType.itinerary || "增值稅普票";
            matchSetDetail.invoiceType.flightRef = matchSetDetail.invoiceType.flightRef || "增值稅普票";
            matchSetDetail.invoiceType.train = matchSetDetail.invoiceType.train || "火车票票根";
            matchSetDetail.invoiceType.hotel = matchSetDetail.invoiceType.hotel || "增值税普票";
            matchSetDetail.invoiceType.car = matchSetDetail.invoiceType.car || "增值稅普票";
            matchSetDetail.invoiceType.ship = matchSetDetail.invoiceType.ship || "自取船票票根";
            matchSetDetail.invoiceType.door = matchSetDetail.invoiceType.door || "增值税普票";
            matchSetDetail.invoiceType.visa = matchSetDetail.invoiceType.visa || "增值稅普票";
            matchSetDetail.invoiceType.extra = matchSetDetail.invoiceType.extra || "增值税普票";
            matchSetDetail.invoiceType.serviceFee = matchSetDetail.invoiceType.serviceFee || "增值税普票";
            this.matchSetDetail = matchSetDetail;

            if (!isInit) {
                this.loading = false;
            }
            let detail = res.datas

            //const bankList = __this.form.bankList;
            const linkman = {name: '', phone: ''};
            detail.reserveLinkmanObj = detail.reserveLinkman ? JSON.parse(detail.reserveLinkman) : linkman;
            if (detail.reserveLinkmanObj.sendSms === undefined || detail.reserveLinkmanObj.sendSms === null) {
                detail.reserveLinkmanObj.sendSms = 0;
            }
            detail.contractLinkmanObj = detail.reserveLinkman ? JSON.parse(detail.contractLinkman) : linkman;
            detail.financeLinkmanObj = detail.reserveLinkman ? JSON.parse(detail.financeLinkman) : linkman;
            detail.urgencyLinkmanObj = detail.reserveLinkman ? JSON.parse(detail.urgencyLinkman) : linkman;
            if (!detail.agreement.hzCompanyArgee) {
                detail.agreement.hzCompanyArgee = {
                    enable: 0,
                    cardNumber: '',
                    groupType: 6,
                    groupTypeText: '华住',
                }
            }
            if (!detail.agreement.dcCompanyArgee) {
                detail.agreement.dcCompanyArgee = {
                    enable: 0,
                    cardNumber: '',
                    groupType: 9,
                    groupTypeText: '东呈',
                    rateplanList: [],
                    rateplanActiveList: [], // 选中的
                    cardGrade: '',
                    cardGradeList: [],
                }
            } else {
                let rateplanActiveList = []
                if (detail.agreement.dcCompanyArgee.rateplanList) {
                    detail.agreement.dcCompanyArgee.rateplanList.forEach(value => {
                        let params = `${value.rateplanCode}-${value.rateplanLevel}`
                        rateplanActiveList.push(params)
                    })
                    detail.agreement.dcCompanyArgee.rateplanActiveList = rateplanActiveList
                } else {
                    detail.agreement.dcCompanyArgee.rateplanList = []
                    detail.agreement.dcCompanyArgee.rateplanActiveList = []
                }

                if (detail.agreement.dcCompanyArgee.cardGrade) {
                    detail.agreement.dcCompanyArgee.cardGradeList = detail.agreement.dcCompanyArgee.cardGrade.split(',')
                } else {
                    detail.agreement.dcCompanyArgee.cardGradeList = []
                }
            }
            if (!detail.agreement.ydCompanyArgee) {
                detail.agreement.ydCompanyArgee = {
                    enable: 0,
                    cardNumber: '',
                    ydCardNumberInfoList: [
                        {
                            ydCardNumber: '',
                            ydCardNumberName: ''
                        }
                    ],
                }
            } else {
                if (!detail.agreement.ydCompanyArgee.ydCardNumberInfoList) {
                    detail.agreement.ydCompanyArgee.ydCardNumberInfoList = [
                        {
                            ydCardNumber: '',
                            ydCardNumberName: ''
                        }
                    ]
                }
            }
            if (!detail.agreement.jjCompanyArgee) {
                detail.agreement.jjCompanyArgee = {
                    enable: 0,
                    cardNumber: '',
                    jjSignKey: ''
                }
            }
            if (!detail.agreement.redCompanyAgree) {
                detail.agreement.redCompanyAgree = {
                    enable: 0,
                    redPartnerCode: '',
                    redSecureKey: '',
                }
            }

            //BUG--10589 【差旅后台】客户管理列表，已有客户开启协议配置，“折扣”默认保存为1，应该默认存为空
            if (detail.agreement.dcCompanyArgee != null && detail.agreement.dcCompanyArgee.discount == null) {
                detail.agreement.dcCompanyArgee.discount = undefined;
            }
            if (detail.agreement.hzCompanyArgee != null && detail.agreement.hzCompanyArgee.discount == null) {
                detail.agreement.hzCompanyArgee.discount = undefined;
            }
            if (detail.agreement.jjCompanyArgee != null && detail.agreement.jjCompanyArgee.discount == null) {
                detail.agreement.jjCompanyArgee.discount = undefined;
            }
            if (detail.agreement.redCompanyAgree != null && detail.agreement.redCompanyAgree.discount == null) {
                detail.agreement.redCompanyAgree.discount = undefined;
            }
            if (detail.agreement.ydCompanyArgee != null && detail.agreement.ydCompanyArgee.discount == null) {
                detail.agreement.ydCompanyArgee.discount = undefined;
            }

            try {
                let hzCompanyArgeeEnable = detail.agreement.hzCompanyArgee.enable;
                let dcCompanyArgeeEnable = detail.agreement.dcCompanyArgee.enable;
                let ydCompanyArgeeEnable = detail.agreement.ydCompanyArgee.enable;
                let jjCompanyArgeeEnable = detail.agreement.jjCompanyArgee.enable;
                let redCompanyArgeeEnable = detail.agreement.redCompanyAgree.enable;
                if (hzCompanyArgeeEnable || dcCompanyArgeeEnable || ydCompanyArgeeEnable || jjCompanyArgeeEnable || redCompanyArgeeEnable) {
                    this.serviceChargeData = true;
                    throw new Error("EndIterative");
                } else {
                    this.serviceChargeData = false;
                }
            }catch (e) {}

            detail.companyId = detail.id;
            // 初始化行业
            if (detail.industryId) {
                __this.allocation.industryType.forEach((item, index) => {
                    if (detail.industryId === item.id) {
                        detail.industryName = item.industryName;
                    }
                });
            }
            // 服务费排序
            const serverArr = [1, 2, 4, 3];
            // 服务费设置
            detail.pdProductSetList.forEach((item, index) => {
                detail.pdProductSetList[index].serviceAmount = item.serviceAmount ? item.serviceAmount + '' : '0';
                detail.pdProductSetList[index].robTicket = item.robTicket ? item.robTicket + '' : '0';
                let box = detail.pdProductSetList[index].requiredServiceAmountType;
                // detail.pdProductSetList[index].requiredServiceAmountType = box == 0 ? 1 : box;
                item.sort = serverArr[index]
                if (item.businessType == 1) {
                    item.enterpriseConfigurationSet = item.airPriceSet === 2 ? 1 : 0;
                    item.lowPriceType = item.lowPriceType == 0 ? 2 : item.lowPriceType;
                    // item.pdProductSetList
                }
                if (item.businessType == 2) {
                    if (item.trainInvoiceType == 0) {
                        item.trainInvoiceType = 2;
                        // item.proxyPrintServiceAmountType = 0;
                    }
                    if (item.trainInvoiceType == 1 || item.trainInvoiceType == 2) {
                        item.proxyPrintServiceAmountType = 2;
                    }
                }
                item.setTemp = null;
                this.pdProductSetTempList = detail.pdProductSetTempList || [];
                this.pdProductSetTempList.forEach(value => {
                    if (value.businessType === item.businessType) {
                        item.setTemp = value;
                    }
                })
            });
            detail.pdProductSetList.sort((e1, e2) => {
                return e1.sort - e2.sort
            })
            // 服务费设置 end
            /*if(__this.form.agreement.agreeInfoList[1].cardGrade == null){
                __this.form.agreement.agreeInfoList[1].cardGrade = 'RackRate';
            }*/

            __this.detail = detail;
            console.log(this.detail)
            const accessoryUrl = this.detail.accessoryUrl ? JSON.parse(this.detail.accessoryUrl) : [];
            let hasUrl = false;
            accessoryUrl.forEach(value => {
                if (value.url) {
                    hasUrl = true
                }
            })
            __this.detail.accessoryUrlArr = hasUrl ? accessoryUrl : [];
            // 附件
            __this.accessoryUrl = this.detail.accessoryUrl ? JSON.parse(this.detail.accessoryUrl) : [{name:'',url:''}];
            // 附件end
            this.form = JSON.parse(JSON.stringify(this.detail));

            // loading.close()
        },
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    // this.loading = true
                    // 调用获取订单日志的接口
                    return consumer_log_companyList({
                        companyId: Number(this.$route.query.id)
                    }).then(res => {
                        // 组装组件需要的list
                        const list = _.chain(res.datas)
                            .map((m) => {
                                // 替换名字
                                const o = {
                                    operator: m.operator,
                                    date: m.operatorTime,
                                    type: m.logSource,
                                    detail: m.logContents
                                }
                                return o
                            }).value()
                        const d = {
                            entityList: list
                        }
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        // this.loading = false
                    })
                }
            })
        },
        async reqFlightPolicyDetail(activeName, isInit, isGetNew) {
            if (!isInit) {
                this.loading = true;
            }
            consumer_web_company_cabinByAirline({airline: activeName ? activeName : this.flightSetActiveName}).then(res => {
                this.cabinList = res.datas ? res.datas.cabins : []
            })
            const params = {
                companyId: parseInt(this.$route.query.id),
                airlineCode: activeName ? activeName : this.flightSetActiveName
            }
            let [err, res] = await awaitWrap(consumer_web_company_addkeyacDetails(params))
            if (err) {
                this.loading = false
                return
            }
            this.loading = false
            let detail = res.datas || {};
            detail.companyId = parseInt(this.$route.query.id)
            detail.airlineCode = this.flightSetActiveName
            if (!detail || !detail.id) {
                // 新的
                this.activeFlightInfo = {
                    airlineCode: this.flightSetActiveName,
                    companyId: parseInt(this.$route.query.id),
                    id: '',

                    policyStatus: 3, // 2启用 3停用
                    keyAccountCode: '',
                    realNameSystem: 0,// 1: 实名， 0 非实名
                    travelValidityStart: '', // 旅游有效期
                    travelValidityEnd: '', // 旅游有效期
                    travelValidityArr: '',
                    excludeTravelValidity: '', // 旅游除外
                    excludeTravelValidityArr: '',
                    excludeTravelValidityOption: [], // 排除日期下拉选择
                    saleValidityStart: '', // 销售有效期
                    saleValidityEnd: '', // 销售有效期
                    saleValidityArr: [],
                    excludeSaleValidity: '', // 销售排除
                    excludeSaleValidityArr: [],
                    excludeSaleValidityOption: [], // 销售排除下拉选择

                    conditions: [
                        /*{
                            discountRate: '',
                            depAirportType: 1, // 适用起飞机场 1全部 2部分
                            depAirportParam: '',
                            depAirportParamArr: [],
                            depAirportExclude: '',
                            depAirportExcludeArr: [],
                            arrAirportType: 1, // 适用到达机场 1全部 2部分
                            arrAirportParam: '',
                            arrAirportParamArr: [],
                            arrAirportExclude: '',
                            arrAirportExcludeArr: [],
                            shareAirlineType: 1, // 适用共享航班类型 1-全部适用，2-部分适用,3-不适用
                            shareAirlineParam: '',
                            shareAirlineExclude: '',
                            cabinType: 1, // 适用舱位类型1-全部适用，2-部分适用
                            cabinParam: '',
                            cabinExclude: '',
                        }*/
                    ],
                }
                this.policyInfo = [
                    /*{
                        more: false,
                        edit: false,
                    }*/
                ]
            } else {
                if (detail.travelValidityStart) {
                    detail.travelValidityArr = [detail.travelValidityStart, detail.travelValidityEnd];
                    // let timeArr = [];
                    // let startTime = moment(detail.travelValidityStart + ' 00:00:00').valueOf();
                    // let endTime = moment(detail.travelValidityEnd + ' 00:00:00').valueOf();
                    // const dayTime = 24 * 60 * 60 * 1000;
                    // for (let i = 0, l = (endTime - startTime)/dayTime; i <= l; i++) {
                    //     const thisTime = moment(startTime + dayTime * i).format('YYYY-MM-DD');
                    //     timeArr.push({
                    //         value: thisTime,
                    //         label: thisTime
                    //     })
                    // }
                    // detail.excludeTravelValidityOption = timeArr
                } else {
                    detail.travelValidityArr = ''
                }
                if (detail.excludeTravelValidity) {
                    detail.excludeTravelValidityArr = detail.excludeTravelValidity.split(',')
                } else {
                    detail.excludeTravelValidityArr = ''
                }
                if (detail.saleValidityStart) {
                    detail.saleValidityArr = [detail.saleValidityStart, detail.saleValidityEnd];
                    // let timeArr = [];
                    // let startTime = moment(detail.saleValidityStart + ' 00:00:00').valueOf();
                    // let endTime = moment(detail.saleValidityEnd + ' 00:00:00').valueOf();
                    // const dayTime = 24 * 60 * 60 * 1000;
                    // for (let i = 0, l = (endTime - startTime)/dayTime; i <= l; i++) {
                    //     const thisTime = moment(startTime + dayTime * i).format('YYYY-MM-DD');
                    //     timeArr.push({
                    //         value: thisTime,
                    //         label: thisTime
                    //     })
                    // }
                    // detail.excludeSaleValidityOption = timeArr
                } else {
                    detail.saleValidityArr = []
                }
                if (detail.excludeSaleValidity) {
                    detail.excludeSaleValidityArr = detail.excludeSaleValidity.split(',')
                }
                if (detail.conditions) {
                    detail.conditions.forEach(value => {
                        /*value.depAirportParam = value.depAirportParams ? value.depAirportParams.join(',') : '';
                        value.depAirportExclude = value.depAirportExcludes ? value.depAirportExcludes.join(',') : '';
                        value.arrAirportParam = value.arrAirportParams ? value.arrAirportParams.join(',') : '';
                        value.arrAirportExclude = value.arrAirportExcludes ? value.arrAirportExcludes.join(',') : '';
                        value.shareAirlineParam = value.shareAirlineParams ? value.shareAirlineParams.join(',') : '';
                        value.shareAirlineExclude = value.shareAirlineExcludes ? value.shareAirlineExcludes.join(',') : '';
                        value.cabinParam = value.cabinParams ? value.cabinParams.join(',') : '';
                        value.cabinExclude = value.cabinExcludes ? value.cabinExcludes.join(',') : '';*/
                    })
                }
                this.activeFlightInfo = detail
            }
            this.activeFlightForm = JSON.parse(JSON.stringify(this.activeFlightInfo))
            let policyInfo = []
            if (isGetNew) {
                this.activeFlightInfo.conditions.forEach(value => {
                    policyInfo.push({
                        more: false,
                        edit: false,
                    })
                })
            } else {
                policyInfo = JSON.parse(JSON.stringify(this.policyInfo));
                policyInfo.forEach(value => {
                    value.edit = false
                })
            }
            this.policyInfo = policyInfo
        },
        req_province_city_data(type, pParams) {
            consumer_web_city_provinceCity(pParams).then((res) => {
                const allocation = this.allocation;
                const list = res.datas;
                if (type === 1) {
                    list.forEach((item) => {
                        item.name = item.name.replace(/[a-zA-Z]/, "");
                    });
                    allocation.provinceList = list;
                } else if (type === 2) {
                    list[0].childList.forEach((item) => {
                        item.name = item.name.replace(/[a-zA-Z]/, "");
                    });
                    allocation.cityList = list[0].childList;
                }
            }).then(() => {
                this.allocation.cityList.forEach((item, index) => {

                })
            });
        },

        selectIndustry(val) { // 选择所属行业 name 赋值
            const __this = this;
            __this.allocation.industryType.forEach((item, index) => {
                if (val === item.id) {
                    __this.form.industryName = item.industryName;
                }
            });
        },
        // 上传公司logo
        change_upload(file) {
            const __this = this;
            __this.$refs.uploadLogo.clearFiles();
            const myUpLoad = new OssClient();
            const isJPG_PNG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/jpg' || file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 < 10240;
            if (!isJPG_PNG) {
                __this.$message.error('上传图片格式有误!');
                return false;
            }
            if (!isLt10M) {
                __this.$message.error('公司LOGO大小不能超过 10MB!');
                return false;
            }
            __this.logoLoading = true;
            myUpLoad.multipartUpload({
                file: file.raw,
                progress: function* (p) {
                },
            }).then((data) => {
                __this.logoLoading = false;
                if(isJPG_PNG && isLt10M) {
                    __this.form.companyLogo = data.url;
                }
            }).catch((error) => {
                __this.logoLoading = false;
                __this.$message.error("上传失败");
            });
        },
        deleteLogo() {
            const __this = this;
            __this.form.companyLogo = '';
            __this.$refs.uploadLogo.clearFiles();
        },
        // 上传公司 end
        // 上传营业执照
        business_license_upload(file) {
            const __this = this;
            __this.$refs.uploadBusinessLicense.clearFiles();
            const myUpLoad = new OssClient();
            const isJPG_PNG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/jpg' || file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 < 10240;
            if (!isJPG_PNG) {
                __this.$message.error('上传图片格式有误!');
                return false;
            }
            if (!isLt10M) {
                __this.$message.error('营业执照大小不能超过 10MB!');
                return false;
            }
            __this.businessLicenseNumLoading = true;
            myUpLoad.multipartUpload({
                file: file.raw,
                progress: function* (p) {},
            }).then((data) => {
                __this.businessLicenseNumLoading = false;
                if(isJPG_PNG && isLt10M) {
                    __this.form.businessLicensePic = data.url;
                }
            }).catch((error) => {
                __this.businessLicenseNumLoading = false;
                __this.$message.error("上传失败");
            });
        },
        deletePic() {
            const __this = this;
            __this.form.businessLicensePic = '';
            __this.$refs.uploadBusinessLicense.clearFiles();
        },
        // 上传营业执照 end
        select_manager(id) {
            const __this = this;
            __this.allocation.manager.forEach((item) => {
                if (id === item.userId) {
                    __this.form.customerManagerName = item.staffName;
                }
            });
        },
        // 上传附件
        change_upload_index() {
            // if (this.accessoryUrl.length < 5) {
            //     this.accessoryUrl.push({ name: "", url: "" });
            //     this.uploadIndex = this.accessoryUrl.length;
            // }
        },
        change_attached(file) {
            if (this.accessoryUrl.length >= 5) {
                return
            }
            const size = file.size / 1024;
            if (size > 10240) {
                this.$message.warning("附件大小不能超过 10MB!");
                this.$refs['upload'].clearFiles();
            } else {
                this.accessoryUrl.push({ name: "", url: "" });
                this.uploadIndex = this.accessoryUrl.length - 1;
                const myUpLoad = new OssClient();
                const index = this.uploadIndex;
                this.accessoryUrl[index].name = file.name;
                myUpLoad.multipartUpload({
                    file: file.raw,
                    progress: function*(p) {},
                })
                    .then((data) => {
                        this.accessoryUrl[index].name = file.name;
                        this.accessoryUrl[index].url = data.url;
                        this.$refs['upload'].clearFiles();
                    }).catch(() => {
                    this.$message.error("上传失败");
                    this.accessoryUrl[index].name = '';
                    this.accessoryUrl[index].url = '';
                });
            }
        },
        add_attached() {
            if (this.accessoryUrl.length < 5)
                this.accessoryUrl.push({ name: "", url: "" });
        },
        delete_attached(index) {
            this.accessoryUrl[index].name = "";
            this.accessoryUrl[index].url = "";
            this.form.accessoryUrl = this.accessoryUrl.map((item) => item.url).join();

        },
        delete_item(index) {
            if (this.accessoryUrl.length > 1) {
                this.accessoryUrl.splice(index, 1);
                this.form.accessoryUrl = this.accessoryUrl
                    .map((item) => item.url)
                    .join();
            } else {
                this.accessoryUrl[0].name = '';
                this.accessoryUrl[0].url = '';
            }
        },
        downAccessory(item) { // 点击附件下载
            const eleLink = document.createElement('a');
            eleLink.href = item.url;
            eleLink.download = item.name;
            document.body.appendChild(eleLink);
            eleLink.click();
            document.body.removeChild(eleLink);
        },
        // 上传附件 end
        read_accessory(item) {
            const eleLink = document.createElement('a');
            eleLink.href = item.url;
            eleLink.download = item.name;
            document.body.appendChild(eleLink);
            eleLink.click();
            document.body.removeChild(eleLink);
        },

        changeTravelTime(val) {
            let timeArr = [];
            if (val) {
                let startTime = moment(val[0] + ' 00:00:00').valueOf();
                let endTime = moment(val[1] + ' 00:00:00').valueOf();
                const dayTime = 24 * 60 * 60 * 1000;
                for (let i = 0, l = (endTime - startTime)/dayTime; i <= l; i++) {
                    const thisTime = moment(startTime + dayTime * i).format('YYYY-MM-DD');
                    timeArr.push({
                        value: thisTime,
                        label: thisTime
                    })
                }
                this.activeFlightForm.excludeTravelValidityOption = timeArr
            } else {
                this.activeFlightForm.excludeTravelValidityOption = timeArr
            }
        },
        changeSaleTime(val) {
            let timeArr = [];
            if (val) {
                let startTime = moment(val[0] + ' 00:00:00').valueOf();
                let endTime = moment(val[1] + ' 00:00:00').valueOf();
                const dayTime = 24 * 60 * 60 * 1000;
                for (let i = 0, l = (endTime - startTime)/dayTime; i <= l; i++) {
                    const thisTime = moment(startTime + dayTime * i).format('YYYY-MM-DD');
                    timeArr.push({
                        value: thisTime,
                        label: thisTime
                    })
                }
                this.activeFlightForm.excludeSaleValidityOption = timeArr
            } else {
                this.activeFlightForm.excludeSaleValidityOption = timeArr
            }
        },

        serviceCharge(val, type){
            let hzCompanyArgeeEnable = this.form.agreement.hzCompanyArgee.enable;
            let dcCompanyArgeeEnable = this.form.agreement.dcCompanyArgee.enable;
            let ydCompanyArgeeEnable = this.form.agreement.ydCompanyArgee.enable;
            let jjCompanyArgeeEnable = this.form.agreement.jjCompanyArgee.enable;
            let redCompanyArgeeEnable = this.form.agreement.redCompanyAgree.enable;
            if (hzCompanyArgeeEnable || dcCompanyArgeeEnable || ydCompanyArgeeEnable || jjCompanyArgeeEnable || redCompanyArgeeEnable) {
                this.serviceChargeData = true;
            } else {
                this.serviceChargeData = false;
            }
            if (type === 'hz') {
                this.$refs.form5.clearValidate(['agreement.hzCompanyArgee.cardNumber'])
            } else if (type === 'dc') {
                this.$refs.form5.clearValidate(['agreement.dcCompanyArgee.cardNumber', 'agreement.dcCompanyArgee.cardGrade'])
            } else if (type === 'yd') {
                let validateArr = ['agreement.ydCompanyArgee.cardNumber']
                this.form.agreement.ydCompanyArgee.ydCardNumberInfoList.forEach((value, index) => {
                    validateArr.push('agreement.ydCompanyArgee.ydCardNumberInfoList.' + index + '.ydCardNumber');
                    validateArr.push('agreement.ydCompanyArgee.ydCardNumberInfoList.' + index + '.ydCardNumberName');
                });
                this.$refs.form5.clearValidate(validateArr);
            }  else if (type === 'jj') {
                let validateArr = ['agreement.jjCompanyArgee.cardNumber', 'agreement.jjCompanyArgee.jjSignKey'];
                this.$refs.form5.clearValidate(validateArr);
            }  else if (type === 'red') {
                let validateArr = ['agreement.redCompanyAgree.redPartnerCode', 'agreement.redCompanyAgree.redSecureKey'];
                this.$refs.form5.clearValidate(validateArr);
            }  else if (type === 'qt') {
                let validateArr = ['agreement.qtCompanyAgree.qtPartnerCode'];
                this.$refs.form5.clearValidate(validateArr);
            }
        },
        inputBlur(index){
            return
            // 找不到this.$refs.cardTextRed${index}
            let textBox = null;
            eval(`
                textBox = this.$refs.cardTextRed${index}
            `);
            let name = ''
            if (index === 0) {
                name = 'hzCompanyArgee'
            } else {
                name = 'dcCompanyArgee'
            }
            if (
                (this.form.agreement[name].cardNumber === '' ||
                    this.form.agreement[name].cardNumber === null)
                &&
                this.form.agreement[name].enable === 1
            ) {
                textBox.innerHTML = '请输入协议卡号';
            } else {
                textBox.innerHTML = null;
            }
        },
        changeCardGradeList(val) {
            console.log(val)
            this.form.agreement.dcCompanyArgee.cardGrade = val.join(',')
        },
        changeRrateplanList(val) {
            let rateplanList = []
            let rateplanOptions = this.rateplanOptions
            rateplanOptions.forEach(value => {
                value.disabled = false
            })
            val.forEach(value => {
                rateplanOptions.forEach(value1 => {
                    let valueArr = value.split('-')
                    if (valueArr[0] === value1.code && valueArr[1] !== value1.level) {
                        value1.disabled = true
                    }
                    if (value1.value === value) {
                        let params = {
                            rateplanCode: value1.code,
                            rateplanLevel: value1.level,
                            rateplanName: value1.rateplanName
                        }
                        rateplanList.push(params)
                    }
                })
            })
            this.rateplanOptions = rateplanOptions
            this.form.agreement.dcCompanyArgee.rateplanList = rateplanList
        },
        delYdCardNumberInfo(index) {
            this.form.agreement.ydCompanyArgee.ydCardNumberInfoList.splice(index, 1)
        },
        addYdCardNumberInfo() {
            this.form.agreement.ydCompanyArgee.ydCardNumberInfoList.push({
                ydCardNumber: '',
                ydCardNumberName: ''
            })
        },
        input_number(val, key) {
            const __this = this;
            const form = __this.form;
            const keyArr = key.split(".");
            if (keyArr.length === 2) {
                form[keyArr[0]][keyArr[1]] = __this.format_number(val);
            } else if (keyArr.length === 3) {
                form[keyArr[0]][keyArr[1]][keyArr[2]] = __this.format_number(val);
            }
        },
        format_number(str) {
            return str
                .split("")
                .map((item) => {
                    if (/[0-9\.]/.test(item)) {
                        return item;
                    } else {
                        return "";
                    }
                })
                .join()
                .replace(/\,/g, "");
        },
        formatPnrSet(val) {
            let text = '';
            this.pnrSetOptions.forEach(value => {
                if (value.value === val) {
                    text = value.text
                }
            });
            return text
        },
        formatDCCardLevelList(val) {
            if (!val) {
                return '--'
            }
            let text = '';
            const valueArr = val.split(',');
            let textArr = [];
            this.DCCardLevelList.forEach(value => {
                valueArr.forEach(value2 => {
                    if (value.value === value2) {
                        textArr.push(value.text)
                    }
                })

            });
            return textArr.join(',')
        },
        formatRateplan(val) {
            let text = '';
            this.rateplanOptions.forEach(value => {
                if (value.value === val) {
                    text = value.label
                }
            });
            return text
        },
        formatInvoiceType(val) {
            let text = '';
            this.invoiceType.forEach(value => {
                if (value.code === val) {
                    text = value.msg
                }
            })
            return text
        },
        formatSettlement(val) {
            let text = '';
            this.allocation.settlementList.forEach(value => {
                if (value.code === val) {
                    text = value.msg
                }
            });
            return text
        },
        filterMethodToCity(val) {
            if (val) {
                this.filterCityList = this.allCityList.filter(item => {
                    const reg = new RegExp(`^${val}`, `gi`);
                    let flag = false;
                    const code = item.code || '';
                    const name = item.name || '';
                    const spell = item.spell || '';
                    const cityCode = item.cityCode || '';
                    const cityName = item.cityName || '';
                    flag = flag || (code.match(reg));
                    flag = flag || (name.match(reg));
                    flag = flag || (spell.match(reg));
                    flag = flag || (cityCode.match(reg));
                    flag = flag || (cityName.match(reg));
                    return flag
                })
            } else {
                this.filterCityList = JSON.parse(JSON.stringify(this.allCityList))
            }
        },
        depAirportCodeFocus() {
            this.filterCityList = JSON.parse(JSON.stringify(this.allCityList))
        },
        beforeLeaveFlight(activeName, oldActiveName) {
            let isHasEditNow = false;
            this.policyInfo.forEach(value => {
                if (value.edit) {
                    isHasEditNow = true
                }
            })
            if (this.cardInfo[5].edit || isHasEditNow) {
                return new Promise((resolve, reject) => {
                    this.$confirm('当前政策还没保存，如果切换，编辑内容将会清除，是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.reqFlightPolicyDetail(activeName);
                        resolve()
                    }).catch(() => {
                        reject()
                    });
                })
            } else {
                this.reqFlightPolicyDetail(activeName);
                return true
            }
        },
        tabClickFlight(val) {
            if (this.cardInfo[5].edit) {
                this.$message('切换到当前选择的航司， 之前编辑未保存的内容已清除')
            }
            this.reqFlightPolicyDetail(val, false, true);
        },
        disabledTravelDate(time) {
            if (this.activeFlightForm.travelValidityArr && this.activeFlightForm.travelValidityArr.length > 0) {
                return time.getTime() < moment(this.activeFlightForm.travelValidityArr[0]).valueOf() || time.getTime() > moment(this.activeFlightForm.travelValidityArr[1]).valueOf()
            } else {
                return true
            }
        },
        disabledSaleDate(time) {
            if (this.activeFlightForm.saleValidityArr && this.activeFlightForm.saleValidityArr.length > 0) {
                return time.getTime() < moment(this.activeFlightForm.saleValidityArr[0]).valueOf() || time.getTime() > moment(this.activeFlightForm.saleValidityArr[1]).valueOf()
            } else {
                return true
            }
        },
        back() {
            this.$router.go(-1)
        },
        returnAirportParamsDisabled(item, policyItem, activeArrName) {
            const code = item.code;
            if (!policyItem[activeArrName]) {
                return false
            } else {
                return policyItem[activeArrName].indexOf(code) > -1
            }
        },
        returnCabinDisabled(item, policyItem, activeArrName) {
            const code = item.cabin;
            if (!policyItem[activeArrName]) {
                return false
            } else {
                return policyItem[activeArrName].indexOf(code) > -1
            }
        },
        returnAirportParams(policyItem, activeArrTextName, activeArrName) {
            let params = policyItem[activeArrTextName].split(',')
            policyItem[activeArrName] = params
        },
        returnAirportParamsText(policyItem, activeArrTextName, activeArrName, val) {
            console.log(policyItem, activeArrTextName, activeArrName, val)
            let params = ''
            if (val) {
                params = val.join(',')
            }
            policyItem[activeArrName] = params
        },
        changeLowPriceSet(){
            this.$refs['form4'].resetFields();
        },
        changeFlightAirPriceSet(item, val) {
            this.$refs['form4'].resetFields();
            // item.enterpriseConfigurationSet = val === 2 ? 1 : 0;
            // item.lowPriceSet = val === 2 ? 0 : 1;
        },
        filterInsuranceProName(val) {
            let text = '--';
            if (val === '') {
                return text;
            }
            this.insuranceOptions.forEach(value => {
                if (val === value.productCode) {
                    text = value.label;
                }
            })
            return text;
        },
        filterTrainInsuranceProName(val) {
            let text = '--';
            if (val === '') {
                return text;
            }
            this.trainInsuranceOptions.forEach(value => {
                if (val === value.productCode) {
                    text = value.label;
                }
            })
            return text;
        },
        clearInsurance(businessType, item, index) {
            item.insuranceInvoiceType = 0;
            item.freeProduct = 0;
            switch (businessType) {
                case 1:
                    this.$refs.form7.clearValidate('flight.' + index + '.productCode');
                    break
                case 2:
                    this.$refs.form7.clearValidate('train.' + index + '.productCode');
                    break
            }
        },
        changeFreeProduct(businessType, item, index, val) {
          if (val === 0) {
              switch (businessType) {
                  case 1:
                      this.$refs.form7.clearValidate('flight.' + index + '.productCode');
                      break
                  case 2:
                      this.$refs.form7.clearValidate('train.' + index + '.productCode');
                      break
              }

          }
        },
        setFlightInsuranceLabel(val) {
            let text = '';
            switch (val.zoneType) {
                case 0:
                    text = '国内保险产品'
                    break
                case 1:
                    text = '国际亚洲地区内保险产品'
                    break
                case 2:
                    text = '国际亚洲地区外保险产品'
                    break
            }
            return text;
        },
        updateClientType(val) {
            this.$confirm(`是否将临时客户${this.detail.companyName}转为正式客户?`, '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true;
                let params = {
                    clientType: 2,
                    companyId: this.detail.id,
                };
                consumer_web_company_updateClientType(params).then(res=>{
                    // this.loading = false;
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    this.reqDetail();
                    this.getOrderLog();
                }).catch(err=>{
                    this.loading = false;
                })

            }).catch(() => {
            });
        },
    }
}

// 【396】根据id删除
// POST /consumer/t-od-hotel-member-phone/remove
// 接口ID：49210326
// 接口地址：https://www.apifox.cn/web/project/458418/apis/api-49210326

const __request = require(`./__request/__request_contentType_json`)
const consumer_t_od_hotel_member_phone_remove = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/t-od-hotel-member-phone/remove',
    data: data
  }
  return __request(pParameter)
}
export default consumer_t_od_hotel_member_phone_remove

import _ from 'underscore'
import moment from 'moment'
import history_tag_api from '@/component/admin-layout/component/history-tag/api'
import OrderLog from '@/component/orderLog/2.0.0/index.vue'

import consumer_admin_extra_details
    from "@/lib/data-service/haolv-default/consumer_admin_extra_details";   // 订单详情
import consumer_admin_extra_action from '@/lib/data-service/haolv-default/consumer_admin_extra_action'
import consumer_logManage_bizlog_pageList from "@/lib/data-service/haolv-default/consumer_logManage_bizlog_pageList"   // 获取订单日志
import consumer_admin_extra_cancelBill from "@/lib/data-service/haolv-default/consumer_admin_extra_cancelBill"
import consumer_admin_extra_save from '@/lib/data-service/haolv-default/consumer_admin_extra_save'
import consumer_admin_extra_billDetails from '@/lib/data-service/haolv-default/consumer_admin_extra_billDetails';
import consumer_admin_flight_generate_cabinRank from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_cabinRank'

import DictionarySelect
    from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';

export default {
    data() {
        const validateFine = (rule, value, callback) => {
            if (this.cancelForm.fine === '' || this.cancelForm.fine === null) {
                callback(new Error('请输入罚金(单人)'));
                return;
            }
            callback();
        }
        return {
            detailLoading: false,
            cancelS1DialogVisible: false,
            cancelS2DialogVisible: false,
            confirmDialogVisible: false,
            confirmDialogRadio: 3,
            loading: false,
            remark: '',
            activeName: 'detail',
            orderSource: '1',
            orderDetail: {},
            tableData: [],
            form: {
                orderNo: '',
                visaRemark: '',
            },

            moment: moment,

            cancelForm: {
                userName: '',
                fine: 0,
            },
            cancelRules: {
                fine: [{required: true, validator: validateFine, trigger: ['blur', 'change']},]
            },

            order: {},
            companyInfo: {},
            confirmForm: {},
            companyBill: [],
            flightInfos: [],
            files: [],
            selectedUserList: [],
            visaBillList: [],
            progressStatusList: [//0待支付 1待出票 2已出票 3已取消
                {
                    code: 0,
                    msg: '待支付'
                },
                {
                    code: 1,
                    msg: '待出票'
                },
                {
                    code: 2,
                    msg: '已出票'
                },
                {
                    code: 3,
                    msg: '已取消'
                },
            ],
            confirmRules: {
                channel: [
                    {required: true, message: '请选择采购渠道', trigger: 'blur'},
                ],
                paymentType: [
                    {required: true, message: '请选择付款方式', trigger: 'blur'},
                ],
                paymentTime: [
                    {required: true, message: '请选择付款时间', trigger: 'blur'},
                ],
                paymentNumber: [
                    {required: true, message: '请输入付款流水号', trigger: 'blur'},
                ],
            },
            // 舱位等级List
            cabinRankList: [],
        }
    },
    computed: {
        refundAmountAll: function(){
            let num = 0;
            this.companyBill.forEach((item,index) => {
                num = num + Number(item.salesPrice);
                if (item.status == 0) {
                  num = num + Number(item.serviceFee);
                }
            });
            return num;
        }
    },
    components: {
        OrderLog,
        DictionarySelect
    },
    activated() {
        this.init();
    },
    filters: {
        filterMoney(val) {
            if (val && val > 0) {
                return parseFloat(val).toFixed(2);
            } else {
                return '0.00'
            }
        }
    },
    methods: {
        //初始化
        async init() {
            this.activeName = 'detail';
            // this.initDictionarySelect();
            await this.getCabinRank();
            this.getExtraOrderDetails()
            this.getOrderLog();
        },

        //初始化字典组件
        initDictionarySelect() {
            Dictionary.init(this, {
                typeGroupKey: ['tmc'],
                typeKey: [],
                parentId: 0
            });
        },

        getCabinRank() {
            return consumer_admin_flight_generate_cabinRank().then(res => {
                this.cabinRankList = res.datas
            })
        },

        setCabinRank(val) {
            let box = {};
            this.cabinRankList.forEach((item,index) => {
                if (item.code == val) {
                    box = item;
                }
            });
            return box.msg;
        },

        // 获取签证订单详情
        async getExtraOrderDetails() {
            try {
                this.detailLoading = true
                const res = await consumer_admin_extra_details({
                    id: this.$route.query.id,
                    orderNo: this.$route.query.orderNo
                });
                this.detailLoading = false
                this.orderDetail = res.datas;
                this.orderDetail.order.orderNo = this.$route.query.orderNo;
                this.order = this.orderDetail.order;
                this.flightInfos = this.orderDetail.flightInfos;
                this.companyInfo = this.orderDetail.companyInfo;
                this.companyBill = this.orderDetail.companyBills;
                this.remark = this.orderDetail.order.remark;

                let bizTypeText = await Dictionary.returnDictionary('tmc', 'extraBizType', this.orderDetail.order.bizType);
                this.orderDetail.order.bizTypeText = bizTypeText.name;

                let orderSourceText = await Dictionary.returnDictionary('tmc', 'extraOrderSource', this.orderDetail.order.orderSource);
                this.orderDetail.order.orderSourceText = orderSourceText.name;

                let paymentTypeText = await Dictionary.returnDictionary('tmc', 'paymentType', this.orderDetail.order.paymentType);
                this.orderDetail.order.paymentTypeText = paymentTypeText.name;
            } catch (error) {
                this.detailLoading = false
                this.$message({
                    type: "error",
                    message: "获取附加业务订单详情失败！"
                })
            } finally {
                console.log('日志报错的时候没有进来这里')
                this.detailLoading = false
            }
        },

        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    let params = {
                        "pageBean": {
                            "currentPage": "-1",
                            "pageSize": "-1"
                        },
                        "params": {
                            "bizKey": "extra"
                        },
                        "querys": [
                            {
                                "property": "bizId",
                                "operation": "EQUAL",
                                "value": this.$route.query.id,
                                "relation": "AND",
                                "group": "group1"
                            }
                        ]
                    }
                    return consumer_logManage_bizlog_pageList(params).then(res => {
                        // 组装组件需要的list
                        const list = _.chain(res.datas.list)
                            .map(function (m) {
                                // 替换名字
                                const o = {
                                    operator: m.operator,
                                    date: m.reqTime,
                                    type: m.bizType,
                                    detail: m.logDescribe
                                }
                                return o
                            }).value()
                        const d = {
                            entityList: list
                        }
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },

        // 返回
        handleBackClick() {
            history_tag_api.remove_tag_by_route({
                route: this.$route
            })
            this.$router.replace({
                name: "admin-extra-order-list"
            })
        },

        //跳转至编辑页
        handleEditClick() {
            this.$router.push({
                name: "admin-extra-create-order",
                query: {
                    type: 'edit',
                    id: this.$route.query.id
                }
            })
        },

        // 跳转至确认支付页
        handlePayClick() {
            this.$router.push({
                name: "admin-extra-create-order-pay",
                query: {
                  id: this.order.id,
                  orderNo: this.order.orderNo,
                  behalfCompanyId: this.companyInfo.companyId
                }
            })
        },

        //取消订单
        handleCancelClick() {
            this.$confirm(`确定取消此订单？`, "取消提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.loading = true;
                let params = {
                    id: this.$route.query.id,
                    orderNo: this.$route.query.orderNo,
                    status: 3
                };
                consumer_admin_extra_action(params).then((res) => {
                    this.$message({
                        type: 'success',
                        message: '取消订单成功！'
                    })
                    this.handleBackClick()
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                })
            }).catch(() => {
            })
        },

        //选中需要退订的客户
        userSelectionChange(list) {
            this.selectedUserList = list;
        },

        //退订
        onShowCancelDialog() {
            if (this.selectedUserList.length === 0) {
                this.$message({
                    type: "error",
                    message: "请先选择需要退订的客户"
                });
                return;
            }
            this.cancelForm.userName = '';
            this.selectedUserList.forEach((user, index) => {
                this.cancelForm.userName += user.userName;
                if (index < this.selectedUserList.length - 1) {
                    this.cancelForm.userName += '、'
                }
            });
            this.cancelS1DialogVisible = true;
            this.cancelForm.fine = 0;
        },

        //确认操作-显示弹框
        async onShowConfirmDialog() {
            let confirmForm2 = JSON.parse(JSON.stringify(this.order));
            confirmForm2.payType = this.companyInfo.payType;
            confirmForm2.orderMoney = this.orderDetail.orderMoney;
            let params = {
                id: this.order.id,
                orderNo: this.order.orderNo
            };
            confirmForm2.tableData = [];
            const res = await consumer_admin_extra_billDetails(params);
            res.datas.forEach((item) => {
                confirmForm2.tableData.push({
                    id: item.id,
                    userName: item.userName,
                    ticketNumber: item.ticketNumber,
                    seatNumber: ''
                });
            });
            confirmForm2.type = '1';
            confirmForm2.paymentType = confirmForm2.paymentType || 'ALIPAY';
            confirmForm2.paymentTime = confirmForm2.paymentTime || moment().format('yyyy-MM-DD HH:mm:ss');
            this.confirmForm = confirmForm2;

            this.confirmDialogVisible = true;
        },

        //提交确定操作
        async confirmSubmit() {
            this.$refs['confirmForm'].validate(async (valid) => {
                if(valid) {
                    try {
                        this.loading = true;
                        let params = {};
                        if (this.confirmForm.type === '1') {
                            this.$refs['confirmForm'].validate(async (valid) => {
                                if (valid) {
                                    let billInfo = [];
                                    this.confirmForm.tableData.forEach((item) => {
                                        billInfo.push({id: item.id, seatNumber: item.seatNumber});
                                    });
                                    params = {
                                        id: this.confirmForm.id,
                                        orderNo: this.confirmForm.orderNo,
                                        status: 2,
                                        supOrderNo: this.confirmForm.supOrderNo,
                                        paymentType: this.confirmForm.paymentType || 0,
                                        paymentTime: this.confirmForm.paymentTime,
                                        paymentNum: this.confirmForm.paymentNumber,
                                        remark: this.confirmForm.remark,
                                        billInfo: billInfo
                                    };
                                    await consumer_admin_extra_action(params);
                                    this.$message.success("保存成功");
                                    this.confirmDialogVisible = false;
                                    await this.getTabNum();
                                    await this.getOrderList();
                                    this.loading = false;
                                }
                            })
                        } else {
                            params = {
                                id: this.confirmForm.id,
                                orderNo: this.confirmForm.orderNo,
                                remark: this.confirmForm.remark,
                                refundAmount: this.confirmForm.orderMoney,
                                status: 3,
                            };
                            await consumer_admin_extra_action(params);
                            this.$message.success("取消预定成功");
                            this.issueDialogVisible = false;
                            await this.getTabNum();
                            await this.getOrderList();
                            this.loading = false;
                        }
                    } catch (e) {
                        this.issueDialogVisible = false;
                        this.loading = false;
                    }
                }
            })
        },

        //判断该客户是否可选
        onProgressSelectable(val) {
            if (val.status === 1) {
                return true;
            }
        },

        //确认退订s1-提交
        cancelS1Submit() {
            let salesPriceTotal = 0;
            this.cancelForm.ids = [];
            this.selectedUserList.forEach(user => {
                salesPriceTotal += user.salesPrice;
                this.cancelForm.ids.push(user.id);
            });
            this.cancelForm.refundAmount = salesPriceTotal - (this.cancelForm.fine * this.selectedUserList.length);
            this.cancelS1DialogVisible = false;
            this.cancelS2DialogVisible = true;
        },

        //确认退订s2-提交
        async cancelS2Submit() {
            try {
                let params = {
                    ids: this.cancelForm.ids,
                    fine: this.cancelForm.fine,
                    refundAmount: this.cancelForm.refundAmount
                };
                await consumer_admin_extra_cancelBill(params);
                this.$message({
                    type: "success",
                    message: '退订成功'
                });
                this.cancelS2DialogVisible = false;
                this.getExtraOrderDetails();
            } catch (e) {
                this.cancelS2DialogVisible = false;
            }
        },

        //保存备注
        async saveRemark() {
            if (this.remark === '') {
                this.$message({
                    type: "warning",
                    message: '请输入备注'
                });
                return
            }
            this.detailLoading = true;
            this.orderDetail.order.remark = JSON.parse(JSON.stringify(this.remark));
            consumer_admin_extra_save(this.orderDetail).then(res => {
                this.detailLoading = false;
                this.$message({
                    type: "success",
                    message: '提交成功'
                });
                this.remark = '';
                this.init()
            }, error => {
                this.detailLoading = false
            }).catch(() => {
                this.detailLoading = false
            })
        },
    }
}

export default {
	data () {
		return {
			
		}
	},
	methods: {
		
	},
	activated () {
		
	},
	watch: {
		
	}
}
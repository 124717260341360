export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/insurance/authorized-customer-list',
            name: 'admin-insurance-authorized-customer-list',
            component: () => import(/* webpackChunkName: "page-admin-insurance-management-authorized-customers-list" */ `./1.0.0/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `授权客户列表`,
                },
                entity_list_for_breadcrumb: [
                    { name: `保险管理` },
                    { name: `授权客户列表` },
                ],
            },
        },
    ],
};

// consumer_admin_user_getCompanyPayType
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-alpha.haolvtrip.com/doc.html#/yinzhilv-tmc/admin/getCreditUsingPOST
const consumer_admin_user_getCompanyPayType = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: 'consumer/admin/user/getCompanyPayType',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_user_getCompanyPayType;

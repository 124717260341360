import Breadcrumb from './component/breadcrumb/index.vue';
import CurrentUserMenu from './component/current-user-menu/index.vue';
import HistoryTag from './component/history-tag/index.vue';
import LeftMenu from './component/left-menu/index.vue';
import Dictionary from "yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary";

export default {
  components: {
    Breadcrumb,
    CurrentUserMenu,
    HistoryTag,
    LeftMenu,
  },
  data() {
    return {};
  },
  mounted() {
    const __this = this;

    const init_dictionary = function () {
      Dictionary.init(__this, {
        typeGroupKey: ['tmc'],
        typeKey: [],
        parentId: 0
      });
    };

    init_dictionary();
  },
}

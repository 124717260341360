import CostAttributionList from '@/component/costAttributionList/index'
import BelongToProjectList from '@/component/belongToProjectList/index'
import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.2.1/index.vue';
import DynamicForm from '@/component/dynamicForm/import_latest_version_module'
// import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.8/index.vue';
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector-label/1.0.0/index.vue';
import consumer_admin_flight_generate_getCompanyList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getCompanyList'
import consumer_admin_flight_generate_evectionType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_evectionType'
import consumer_admin_flight_generate_payType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_payType'
import consumer_admin_flight_generate_getLinkmanList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getLinkmanList'
import consumer_admin_flight_generate_psgType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_psgType'
import consumer_admin_flight_generate_checkPsgRepeatOrNot from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_checkPsgRepeatOrNot'
import consumer_department_getStaffsAndExCusByUserIds from '@/lib/data-service/haolv-default/consumer_department_getStaffsAndExCusByUserIds'
import consumer_admin_flight_generate_getInsuranceProductList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getInsuranceProductList'
// import consumer_admin_flight_generate_certificateType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType'
import consumer_admin_trains_order_passengerCardType from '@/lib/data-service/haolv-default/consumer_admin_trains_order_passengerCardType'
import consumer_admin_flight_generate_userName from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_userName'
import consumer_admin_flight_generate_configServiceFee from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_configServiceFee'
import consumer_admin_trains_order_seatingLevel from '@/lib/data-service/haolv-default/consumer_admin_trains_order_seatingLevel'
import consumer_admin_trains_order_trainsSaveOrUpdate from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsSaveOrUpdate'
import consumer_admin_trains_order_trainsdetails from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsdetails'
import consumer_admin_trains_order_trainsticketChannel from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsticketChannel'
import consumer_admin_trains_order_remarkSaveOrUpdate from '@/lib/data-service/haolv-default/consumer_admin_trains_order_remarkSaveOrUpdate'
import consumer_admin_flight_generate_getInsurancePayTypeList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getInsurancePayTypeList'
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info';
import defaultForm from './form';
import isMobile from 'yinzhilv-js-sdk/src/common-service/other/is_mobile';
import Q from 'q';
import tipsObj from './tips';
import consumer_flight_report_order_offlineOutPayType from '@/lib/data-service/haolv-default/consumer_flight_report_order_offlineOutPayType'
import moment from "moment";
import NewUserPopup
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-list-for-reserve-platform/1.0.0/component/newUserPopup/index.vue";
import TravelMultipleSelectDialog from "@/component/travel-multiple-select-dialog-for-create-order/index.vue";
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";
export default {
    data() {
        const validatePurchaseCommission = (rule, value, callback) => {
            if (!value) {
                callback();
            }
            const regExp =  /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/;
            if (regExp.test(value)) {
                callback();
            } else {
                callback(new Error('输入等于或大于0保留两位小数的正数'))
            }
        };
        return {
            // 当前所属企业的companyId
            companyIdCurrent: null,
            trainsReservationBox: [
                {msg: '12306登录预订',value: 1},
                {msg: '免登录预订',value: 2}
            ],
            loading: false,
            type: 'create', // 'create' 新建 'edit' 编辑
            orderNo: '',
            centerCodeBox: '',  // 成本中心--centerCode
            projectNameBox: '',  // 归属项目--projectName
            projectCodeBox: '',  // 归属项目--projectCode
            companyIdBox: 0,  // 归属项目--companyId
            hasBusinessInsurance: false,
            insuranceProductList: [],
            form: JSON.parse(JSON.stringify(defaultForm)),
            insurancePayTypeList: [],
            paymentTypeList: [
                {
                    code:0,
                    msg:'支付宝',
                },
                {
                    code:1,
                    msg:'网银',
                }
            ],//支付方式枚举值
            rules: {
                'customerInfo.companyId': [
                    { required: true, message: '请选择所属企业', trigger: 'change' },
                ],
                'customerInfo.contactName': [
                    { required: true, message: '请填写联系人', trigger: 'blur' },
                ],
                'customerInfo.contactMobile': [
                    { required: true, message: '请填写联系电话', trigger: 'blur' },
                    {validator: this.checkMobile, trigger: 'blur'}
                ],
                'customerInfo.evectionType': [
                    { required: true, message: '请选择出行类型', trigger: 'change' },
                ],
                'customerInfo.payType': [
                    { required: true, message: '请选择支付方式', trigger: 'change' },
                ],
                'ticketInfo.ticketChannel': [
                    { required: true, message: '请选择采购渠道', trigger: 'change' },
                ],
                'ticketInfo.purchaseCommission': [
                    { required: true, message: '请输入采购手续费', trigger: 'change' },
                    {required: true, validator: validatePurchaseCommission, trigger: 'change'}
                ],
                'ticketInfo.ticketNo': [
                    { required: true, message: '请填写取票号', trigger: 'blur' },
                ],
                'ticketInfo.supplierOrderNo': [
                    { required: true, message: '请填写供应商订单号', trigger: 'blur' },
                ],
                'ticketInfo.orderTime': [
                    { required: true, message: '请选择预订时间', trigger: 'change' },
                ],
                'ticketInfo.userId': [
                    { required: true, message: '请选择操作客服', trigger: 'change' },
                ],
                'ticketInfo.bookingSms': [
                    { required: true, message: '请勾选是否发送短信通知', trigger: 'change' },
                ]
            },
            orderStatus: '',
            companyList: [],
            evectionTypeList: [],
            payTypeList: [],
            seatNameList: [],
            psgTypeList: [],
            certificateTypeList: [],
            purchaseChannelListResult: [],
            generateUserNameList: [],
            addNewPassengerLoading: false,
            submitLoading: false,
            // selectedIdList: [],
            serviceCharge: 0,
            proxyPrintServicePrice: 0,
            parameterListBox:[],
            ticketChannel: '',

            activeUserList: [],
        }
    },
    components: {
        NewUserPopup,
        CostAttributionList, BelongToProjectList, TrainCitySelector, DynamicForm, TravelerMultipleSelector, TravelMultipleSelectDialog},
    created() {
    },
    mounted() {
    },
    async activated() {
        await this.init();
        this.form.ticketInfo.orderTime = `${moment(new Date()).format('YYYY-MM-DD')} 00:00:00`;
        //this.$refs.formTrainCitySelector.init({ placeholder_text: "请选择出发站点" });
        //this.$refs.toTrainCitySelector.init({ placeholder_text: "请选择到达站点" });
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        orderStatusAndEvectionTypeToDisabled() {
            let hasBusinessInsurance = false;
            const insuranceProductList = this.insuranceProductList;
            const evectionType = this.form.customerInfo.evectionType;
            const orderStatus = this.orderStatus;
            insuranceProductList.forEach(value => {
                if (value.freeProduct !== 1) {
                    hasBusinessInsurance = true
                }
            });
            if (!hasBusinessInsurance && evectionType !== 1) {
                return true
            } else {
                // 订单状态  10=预订，11=待支付，12=已支付，13=超标审批中，21=待出票，22=出票成功，23=出票失败，24=作废，90=已取消
                if (orderStatus !== '' && orderStatus !== 10 && orderStatus !== 11 && orderStatus !== 13) {
                    return true
                } else {
                    return false
                }
            }
        },
        orderStatusToDisabled() {
            // 订单状态  1.占座中 2.待支付 3.出票中 4.出票完成 5.出票失败 6.已取消 8.取消中 10.超标审批中
            const orderStatus = this.orderStatus;
            const type = this.type;
            if (orderStatus !== '' && orderStatus !== 1 && orderStatus !== 2 && type === 'edit') {
                return true
            } else {
                return false
            }
        },
        orderTicketChannelToDisabled() {
            if (this.ticketChannel === 5) {
                return true;
            } else {
                return false;
            }
        },
    },
    filters: {},
    methods: {
        changeProduct(val,index) {
            this.insuranceProductList.forEach((item) => {
                if (item.productCode == this.form.passengerList[index].insuranceInfo.productCodes) {
                    this.form.passengerList[index].insuranceInfo.packageName = item.packageName;
                }
            });
        },
        handleSelectCallback(value){
            if (this.type === 'edit') {
                let hasOaUser = false;
                this.form.passengerList.forEach(val=>{
                    if (val.userId === value.userId) {
                        hasOaUser = true;
                    }
                })
                if (!hasOaUser) {
                    this.$message.warning('不允许选择该OA单号');
                    this.$refs.aDynamicForm.clearOa();
                }
                return;
            }
            consumer_department_getStaffsAndExCusByUserIds({userIds: [value.userId]}).then((res) => {
                let birthday = '';
                if (res.datas.list[0].certificateType == 1 && res.datas.list[0].certificateValue) {
                  birthday = `${res.datas.list[0].certificateValue.substr(6, 4)}-${res.datas.list[0].certificateValue.substr(10, 2)}-${res.datas.list[0].certificateValue.substr(12, 2)}`;
                }
                if (!birthday && res.datas.list[0].birthDay) {
                  birthday = res.datas.list[0].birthDay;
                }
                this.form.passengerList = [{
                    "psgName": res.datas.list[0].name,
                    "psgType": 1,
                    "psgTypeText": "成人",
                    "cardType": res.datas.list[0].certificateType,
                    "cardTypeText": res.datas.list[0].certificateName,
                    "cardNo": res.datas.list[0].certificateValue,
                    "birthday": birthday,
                    "mobile": res.datas.list[0].phone,
                    "userId": res.datas.list[0].userId,
                    "insuranceInfo": {
                        "isInsure": 1, // 是否购买保险
                        "insuranceSource": 1,
                        "productCodes": '',
                        "insuranceOrderId": '',
                        "productName": '', // 保险名称
                        "premium": '', // 保险销售价
                        "insureCost": '', // 保险成本
                        "startDate": '', // 生效时间
                        "endDate": '', // 失效时间
                        "channel": 2, // 保险渠道 1：华泰 2：德付通 3：旅游卫士 4：惠择
                        "payType": 3, // 支付方式
                        "packageName": '',
                    },
                    "seatnumber": [{ "boardingGates": "", "journeyNo": "1", "seatInfos": "" }],
                }];
            });
        },
        handleSelectDelete(){
            if (this.type === 'edit') {
                return;
            }
            this.form.passengerList = [{
                "psgName": '',
                "psgType": 1,
                "psgTypeText": "成人",
                "cardType": 1,
                "cardTypeText": '身份证',
                "cardNo": '',
                "birthday": '',
                "mobile": '',
                "insuranceInfo": {
                    "isInsure": 1, // 是否购买保险
                    "insuranceSource": 1,
                    "productCodes": '',
                    "insuranceOrderId": '', // 保单号
                    "productName": '', // 保险名称
                    "premium": '', // 保险销售价
                    "insureCost": '', // 保险成本
                    "startDate": '', // 生效时间
                    "endDate": '', // 失效时间
                    "channel": 2, // 保险渠道 1：华泰 2：德付通 3：旅游卫士 4：惠择
                    "payType": 3, // 支付方式
                    "packageName": '',
                },
                "seatnumber": [{ "boardingGates": "", "journeyNo": "1", "seatInfos": "" }],
            }];
        },
        toSelectType(){
            // this.form.customerInfo.companyId
            let params = {
                companyId: this.form.customerInfo.companyId,
            };
            if (this.centerCodeBox != null && this.centerCodeBox != '' && this.centerCodeBox.indexOf('E') != -1) {
                this.$refs.belongToProjectListRef.init(params);
            }
        },
        // 车次信息--预定方式--切换回调
        changeTrainsReservation(){
            if (!this.form.customerInfo.companyId) return
            const params = {
                companyId: this.form.customerInfo.companyId,
                evectionType: this.form.customerInfo.evectionType,
                businessType: 2,
                trainsReservation: this.form.journeyInfo[0].trainsReservation,
                reservationSource: 3,
            };
            // 获取服务费
            this.getServiceFee(params);
        },
        async init() {
            this.loading = true;
            this.form = JSON.parse(JSON.stringify(defaultForm));
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
            this.getInsurancePayTypeList();
            this.getEvectionTypeList();
            this.getPayTypeList();
            this.getPsgType();
            this.getCertificateType();
            this.getGenerateUserName();
            this.getSeat();
            this.getPaymentTypeList();
            await this.getPurchaseChannel();
            await this.getCompanyList();
            this.loading = false;
            this.$refs.formTrainCitySelector.forEach(value => {
                value.init({ placeholder_text: "请选择出发站点" })
            });
            this.$refs.toTrainCitySelector.forEach(value => {
                value.init({ placeholder_text: "请选择到达站点" })
            });
            const query = this.$route.query;
            this.type = query && query.type ? query.type : 'create';
            this.orderNo = query && query.orderNo ? query.orderNo : '';
            const params = {
                companyId: this.form.customerInfo.companyId
            };
            this.$refs.costAttributionList.init(params);
            if (this.type !== 'create') {
                const params = {
                    orderNo: this.orderNo
                };
                this.loading = true;
                let [err, res] = await awaitWrap( consumer_admin_trains_order_trainsdetails(params));
                if (err) {
                    this.loading = false
                }
                this.loading = false
                this.ticketChannel = res.ticketInfo.ticketChannel;
                this.orderStatus = res.trainsOrderDetails.status;
                let form = res;

                if (!form.customerInfo.feeAffiliationType) {
                    form.customerInfo.feeAffiliationType = 1
                }
                form.journeyInfo.forEach(value => {
                    const fromStationObj = {
                        stationCode: value.fromStationCode,
                        stationName: value.fromStationName
                    };
                    const toStationObj = {
                        stationCode: value.toStationCode,
                        stationName: value.toStationName
                    };
                    value.fromStationObj = fromStationObj;
                    value.toStationObj = toStationObj;
                });
                form.orderNo = this.orderNo;
                if (this.type === 'copy') {
                    form.orderNo = '';
                    let userRes = await get_user_info();
                    form.ticketInfo.userId = userRes.datas.id;
                    form.ticketInfo.initialName = userRes.datas.realName;
                }
                form.ticketInfo.paymentType = form.ticketInfo.paymentType ? form.ticketInfo.paymentType : 0;
                form.ticketInfo.ticketChannel = form.ticketInfo.ticketChannel ? form.ticketInfo.ticketChannel.toString() : '';
                form.ticketInfo.purchaseCommission = form.ticketInfo.purchaseCommission ? form.ticketInfo.purchaseCommission : 0;

                // 获取乘机人的证件信息
                let passengerIdList = [];
                form.passengerList.forEach(value=>{
                    passengerIdList.push(value.userId);
                })
                let [pErr, pRes] = await awaitWrap(consumer_department_getStaffsAndExCusByUserIds({
                    companyId: form.customerInfo.companyId,
                    userIds: passengerIdList,
                    isSelectCustomer: true,
                    pageSize: 30,
                    currentPage: 1,
                }));
                let activeUserList = [];
                let detailPassengerList = pRes ? pRes.datas.list : [];
                form.passengerList.forEach((value,index) => {
                    value.certificates = [];
                    let certificates = [];
                    detailPassengerList.forEach(value1 => {
                        if (value.userId === value1.userId) {
                            value.certificates = value1.certificates;
                        }
                    })

                    const newUserItem = {
                        birthDay: value.birthday,
                        certificateName: value.cardTypeText,
                        certificateType: value.cardType,
                        certificateValue: value.cardNo,
                        certificates: value.certificates,
                        countryCode: value.countryCode,
                        countryName: null,
                        englishName: value.psgEnglishName,
                        isLoginUser: null,
                        name: value.psgName,
                        phone: value.phone,
                        shareWith: null,
                        tags: [],
                        type: 3,
                        typeValue: "员工",
                        unSensitiveCertificateValue: value.cardNo,
                        unSensitivePhone: value.phone,
                        userId: value.id,
                    };
                    activeUserList.push(newUserItem);
                });
                this.activeUserList = activeUserList;


                form.passengerList.forEach((FItem,FIndex) => {
                    if (FItem.insuranceInfo == null) {
                        FItem.insuranceInfo = {
                            isInsure: 0, // 是否购买保险
                            insuranceSource: 1,
                            productCodes: '',
                            packageName: '',
                        };
                    } else {
                        FItem.insuranceInfo.productCodes = FItem.insuranceInfo.productCodes.join('');
                    }
                });
                //this.$refs.formTrainCitySelector.set_value(fromStationObj);
                //this.$refs.toTrainCitySelector.set_value(toStationObj);

                this.form = form;

                let activeItem = null;
                this.companyList.forEach(value => {
                    if (value.id === this.form.customerInfo.companyId) {
                        activeItem = value
                    }
                });
                // 更新初始化动态表单
                this.$refs.aDynamicForm.init({
                    showAttribution: true,
                    immutableSwitch: false,
                    type: 'display',
                    displayList: this.form.reportConfiguration,
                    companyId: this.form.customerInfo.companyId,
                    // 业务类型：1机票、2火车票、3酒店、4汽车
                    businessType: 2,
                    // 是否使用默认数据
                    mock_data: {
                        enable: false,
                    },
                }).then(res => {
                    this.parameterListBox = res;
                });
                // 更新初始化用户选择组件
                this.$refs.aTravelMultipleSelectDialog.setCompany(this.form.customerInfo.companyId);
                this.$refs.aTravelMultipleSelectDialog.init();

                consumer_admin_flight_generate_getInsuranceProductList({
                    companyId: this.form.customerInfo.companyId,
                    evectionType: this.form.customerInfo.evectionType,
                    businessType: 2,
                }).then(res => {
                    this.insuranceProductList = res.datas.insuranceProductVoList;
                    // 如果返回的只有赠送保险 应该像操作客服那样默认勾选 以及禁用
                    let hasBusinessInsurance = false;
                    this.insuranceProductList.forEach(value => {
                        if (value.freeProduct !== 1) {
                            hasBusinessInsurance = true
                        }
                    });
                    if (!hasBusinessInsurance && this.form.customerInfo.evectionType !== 1 && this.insuranceProductList.length > 0) {
                        this.hasBusinessInsurance = true;
                    }
                    this.$forceUpdate();
                });

            } else {
                get_user_info().then(res => {
                    this.form.ticketInfo.userId = res.datas.id;
                    this.form.ticketInfo.initialName = res.datas.realName;
                })
                this.form.ticketInfo.paymentType = 0;
            }
        },
        //获取支付方式枚举值
        async getPaymentTypeList() {
            let res = await consumer_flight_report_order_offlineOutPayType();
            this.paymentTypeList = res.datas;
        },
        getInsurancePayTypeList() {
            consumer_admin_flight_generate_getInsurancePayTypeList().then(res => {
                this.insurancePayTypeList = res.datas
            })
        },
        getEvectionTypeList() {
            consumer_admin_flight_generate_evectionType().then(res => {
                this.evectionTypeList = res.datas
            })
        },
        getPayTypeList() {
            consumer_admin_flight_generate_payType().then(res => {
                this.payTypeList = res.datas
            })
        },
        getPsgType() {
            consumer_admin_flight_generate_psgType().then(res => {
                this.psgTypeList = res.datas
            })
        },
        getCertificateType() {
            consumer_admin_trains_order_passengerCardType().then(res => {
                let certificateTypeList = res.datas
                certificateTypeList.forEach(value => {
                    value.value = parseInt(value.code)
                })
                this.certificateTypeList = certificateTypeList
            })
        },
        getGenerateUserName() {
            consumer_admin_flight_generate_userName().then(res => {
                this.generateUserNameList = res.datas
            })
        },
        getSeat() {
            consumer_admin_trains_order_seatingLevel().then(res => {
                this.seatNameList = res.datas
            })
        },
        async getPurchaseChannel() {
            let res = await consumer_admin_trains_order_trainsticketChannel()
            this.purchaseChannelList = res.datas
            this.purchaseChannelListResult = res.datas
            /*let purchaseChannelListResult = []
            this.purchaseChannelList.forEach(value => {
                if ((this.form.ticketInfo.orderSource === 3 && value.code !== 6) || (this.form.ticketInfo.orderSource !== 3 && value.code === 6)) {
                    purchaseChannelListResult.push(value)
                }
                this.purchaseChannelListResult = purchaseChannelListResult
            })*/
        },
        async getCompanyList() {
            const params = {
                companyName: '',
                currentPage: 1,
                keyword: '',
                pageSize: 999,
            }
            let res = await consumer_admin_flight_generate_getCompanyList(params)
            this.companyList = res.datas.list
        },
        changeCompanyId(val) {
            let activeItem = null;
            this.companyList.forEach(value => {
                if (value.id === val) {
                    activeItem = value
                }
            });
            // 获取保险
            if (activeItem.id) {
                consumer_admin_flight_generate_getInsuranceProductList({
                    companyId: activeItem.id,
                    evectionType: this.form.customerInfo.evectionType,
                    businessType: 2,
                }).then(res => {
                    this.insuranceProductList = res.datas.insuranceProductVoList;
                    // 如果返回的只有赠送保险 应该像操作客服那样默认勾选 以及禁用
                    let hasBusinessInsurance = false;
                    this.insuranceProductList.forEach(value => {
                        if (value.freeProduct !== 1) {
                            hasBusinessInsurance = true
                        }
                    });
                    if (!hasBusinessInsurance && this.form.customerInfo.evectionType !== 1 && this.insuranceProductList.length > 0) {
                        const item = this.insuranceProductList[0].productCode;
                        this.form.passengerList.forEach(passengerListValue => {
                            if (passengerListValue.insuranceInfo.productCodes == null) {
                              passengerListValue.insuranceInfo.productCodes = '';
                            }
                            passengerListValue.insuranceInfo.productCodes = item;
                            passengerListValue.insuranceInfo.packageName = this.insuranceProductList[0].packageName;
                            this.hasBusinessInsurance = true;
                        });
                    } else if (this.insuranceProductList.length === 1) {
                        // 如果只有一个保险可选, 默认选上
                        // const item = this.insuranceProductList[0].productCode;
                        // this.form.passengerList.forEach(passengerListValue => {
                        //     if (passengerListValue.insuranceInfo.productCodes == null) {
                        //         passengerListValue.insuranceInfo.productCodes = [];
                        //     }
                        //     passengerListValue.insuranceInfo.productCodes = [item];
                        //     passengerListValue.insuranceInfo.packageName = this.insuranceProductList[0].packageName;
                        // });
                    }
                });
            }
            this.form.customerInfo.companyName = activeItem.companyName;
            if (this.orderStatusToDisabled) {
                // 编辑时候也要可以改成本中心
                const params = {
                    companyId: val,
                    evectionType: this.form.customerInfo.evectionType,
                    businessType: 2,
                    trainsReservation: this.form.journeyInfo[0].trainsReservation,
                    reservationSource: 3,
                };
                this.$refs.costAttributionList.init(params).then(()=>{
                    this.form.customerInfo.deptName = '各自所在部门';
                    this.form.customerInfo.deptId = -1;
                    this.form.customerInfo.feeAffiliationType = 1;
                });
                return;
            }
            // 清除联系人
            this.form.customerInfo.contactName = '';
            this.form.customerInfo.deptName = '';
            this.form.customerInfo.deptId = '';
            this.form.customerInfo.feeAffiliationType = 0;
            this.centerCodeBox = '';
            this.projectNameBox = '';
            this.projectCodeBox = '';
            this.companyIdBox = 0;
            const params = {
                companyId: val,
                evectionType: this.form.customerInfo.evectionType,
                businessType: 2,
                trainsReservation: this.form.journeyInfo[0].trainsReservation,
                reservationSource: 3,
            };
            this.companyIdCurrent = val;
            this.getLinkmanList(params);
            this.serviceCharge = 0;
            this.getServiceFee(params);
            // 更新初始化动态表单
            this.$refs.aDynamicForm.init({
                showAttribution: true,
                immutableSwitch: false,
                companyId: Number(val),
                // 业务类型：1机票、2火车票、3酒店、4汽车
                businessType: 2,
                // 是否使用默认数据
                mock_data: {
                    enable: false,
                },
            }).then(res => {
                this.parameterListBox = res;
                if (this.parameterListBox.indexOf('成本中心') == -1) {
                    this.$refs.costAttributionList.init(params);
                }
            });
            // 更新初始化用户选择组件
            this.$refs.aTravelMultipleSelectDialog.setCompany(val);
            this.$refs.aTravelMultipleSelectDialog.init();
        },
        changeIsInsure(item,index) {
            if (item.isInsure == 0) {
                this.form.passengerList[index].insuranceInfo.insuranceSource = 1;
                // this.form.passengerList[index].insuranceInfo.productCodes = '';
                // this.form.passengerList[index].insuranceInfo.productName = '';
            }
        },
        clearCompany() {
            if (this.orderStatusToDisabled) {
                return;
            }
            this.insuranceProductList = [];
            this.form.passengerList.forEach((item,index) => {
                item.insuranceInfo.productCodes = '';
                item.insuranceInfo.productName = '';
            });
            this.form.customerInfo.contactName = '';
            this.form.customerInfo.contactMobile = '';
        },
        async getLinkmanList() {
            let params = {
                companyId: this.form.customerInfo.companyId,
                currentPage: 1,
                pageSize: 99,
            }
            let res = await consumer_admin_flight_generate_getLinkmanList(params);
            const linkmanList = res.datas.list;
            if (linkmanList.length != 0) {
                this.form.customerInfo.contactName = linkmanList[0].userName;
                this.form.customerInfo.contactMobile = linkmanList[0].phone;
            }
            this.$nextTick(() => {
                this.$refs.form.validateField(['customerInfo.contactName', 'customerInfo.contactMobile'])
            })
        },
        getServiceFee(params) {
            consumer_admin_flight_generate_configServiceFee(params).then(res => {
                this.serviceCharge = res.datas ? res.datas.serviceFee : 0;
                this.proxyPrintServicePrice = res.datas ? res.datas.proxyPrintServicePrice : 0;
                this.form.journeyInfo.forEach(value => {
                    value.serviceChargePrice = res.datas ? res.datas.serviceFee : 0;
                    if (params.companyId == 238) {
                        value.serviceChargePrice = 0;
                    }
                })
            })
        },
        addNewPassenger() {
            if (!this.form.customerInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择所属企业'
                });
                return
            }
            // this.selectedIdList = [];
            let activeUserList = this.activeUserList;
            activeUserList.forEach(value => {
                this.form.passengerList.forEach(value1=>{
                    if (value.userId === value1.userId) {
                        value.certificateType = value1.cardType;
                        value.certificateName = value1.cardTypeText;
                        value.certificateValue = value1.cardNo;
                    }
                })
            })
            this.activeUserList = JSON.parse(JSON.stringify(activeUserList));
            this.$refs.aTravelMultipleSelectDialog.show();

        },
        addNewPassengerRequestItem() {
            if(!this.form.customerInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择公司'
                })
                return;
            }
            this.$refs.aNewUserPopup.init({companyId: this.form.customerInfo.companyId});
            this.$refs.aNewUserPopup.show();
            /*this.addNewPassengerLoading = true;
            let newItem = {
                birthday: '',
                cardNo: '',
                cardType: 1,
                cardTypeText: '身份证',
                mobile: '',
                psgName: '',
                psgType: 1,
                psgTypeText: '成人',
                insuranceInfo: {
                    isInsure: 1, // 是否购买保险
                    insuranceSource: 1,
                    productCodes: '',
                    insuranceOrderId: '', // 保单号
                    productName: '', // 保险名称
                    premium: '', // 保险销售价
                    insureCost: '', // 保险成本
                    startDate: '', // 生效时间
                    endDate: '', // 失效时间
                    channel: 2, // 保险渠道 1：华泰 2：德付通 3：旅游卫士 4：惠择
                    payType: 3, // 支付方式
                    packageName: '',
                },
                seatnumber: [
                    {
                        boardingGates: '',
                        journeyNo: '1',
                        seatInfos: '',
                    }
                ]
            };
            newItem.insuranceInfo.productCodes = this.form.passengerList[0].insuranceInfo.productCodes;
            newItem.insuranceInfo.packageName = this.form.passengerList[0].insuranceInfo.packageName;
            this.form.passengerList.splice(index + 1, 0, newItem);
            this.addNewPassengerLoading = false*/
        },
        deletePassengerRequestItem(item, index) {
            this.form.passengerList.splice(index, 1);
            this.activeUserList.splice(index, 1);
        },
        copyItem(item, index) {
            // pnr,票面价,机建,燃油,采购价,销售价
            let newItem = {
                birthday: '',
                cardNo: '',
                cardType: 1,
                cardTypeText: '身份证',
                mobile: '',
                psgName: '',
                psgType: 1,
                psgTypeText: '成人',
                insuranceInfo: {
                    isInsure: 1, // 是否购买保险
                    insuranceSource: 1,
                    productCodes: '',
                    insuranceOrderId: '', // 保单号
                    productName: '', // 保险名称
                    premium: '', // 保险销售价
                    insureCost: '', // 保险成本
                    startDate: '', // 生效时间
                    endDate: '', // 失效时间
                    channel: 2, // 保险渠道 1：华泰 2：德付通 3：旅游卫士 4：惠择
                    payType: 3, // 支付方式
                    packageName: '',
                },
                seatnumber: JSON.parse(JSON.stringify(item.seatnumber)),
            };
            this.form.passengerList.splice(index, 0, newItem)
        },
        onSubmit() {
            let isPass = false;
            let unValidObj = null;
            this.form.journeyInfo.forEach(value => {
                const depCityObj = value.fromStationObj;
                value.fromStationCode = depCityObj ? depCityObj.stationCode : null;
                value.fromStationName = depCityObj ? depCityObj.stationName : null;
                const arrCityObj = value.toStationObj;
                value.toStationCode = arrCityObj ? arrCityObj.stationCode : null;
                value.toStationName = arrCityObj ? arrCityObj.stationName : null;
            });
            this.$refs.form.validate((valid, obj) => {
                isPass = valid;
                unValidObj = obj;
            });
            // 动态表单
            let formInfo = this.$refs.aDynamicForm.getData();
            if (this.form.customerInfo.evectionType == 1) {
                formInfo = {
                    checkForm: true,
                    list: [],
                }
            }
            if (!formInfo.checkForm) {
                const listLength = formInfo.list.length;
                for (let i = 0; i < listLength; i++) {
                    if (!formInfo.list[i].parameterForm) {
                        const text = `请填写${formInfo.list[i].parameterText}`;
                        this.$message({
                            type: "warning",
                            message: text
                        });
                        break
                    }
                }
                return
            }
            if (isPass === true && formInfo.checkForm) {
                // 校验新增出行人信息是否重复
                let checkRepeatPsg = [];
                this.form.passengerList.forEach((value, index) => {
                    if (!value.userId) {
                        let psg = {'psgName': value.psgName, 'psgPhone': value.mobile, 'cardType': value.cardType, 'cardNo': value.cardNo};
                        checkRepeatPsg.push(psg);
                    }
                });
                if (0 === checkRepeatPsg.length) {
                    this.addOrder(formInfo);
                } else if (this.type === 'create') {
                    const checkParams = {
                        companyId: this.form.customerInfo.companyId,
                        psgList: checkRepeatPsg
                    };
                    consumer_admin_flight_generate_checkPsgRepeatOrNot(checkParams).then(res => {
                        const returnMsg = res.datas;
                        if (returnMsg && returnMsg != []) {
                            let confirmMsg = ``;
                            res.datas.forEach((value, index) => {
                                confirmMsg = `${confirmMsg}${value}</br>`;
                            });
                            this.$confirm(confirmMsg, '提示', {
                                confirmButtonText: '继续生单',
                                cancelButtonText: '取消',
                                type: 'warning',
                                dangerouslyUseHTMLString: true
                            }).then(() => {
                                this.addOrder(formInfo);
                            }).catch(() => {
                            });
                        } else {
                            this.addOrder(formInfo);
                        }
                    })
                } else {
                    this.addOrder(formInfo);
                }
            } else {
                let tipsStr = '';
                this.form.passengerList.forEach((PItem,PIndex) => {
                    if (PItem.insuranceInfo.isInsure == 1 && PItem.insuranceInfo.productCodes == '') {
                        // PItem.insuranceInfo.isInsure = 0;
                    }
                });
                if (!isPass) {
                    const unValidKeys = Object.keys(unValidObj);
                    console.log(unValidKeys)
                    unValidKeys.forEach((item, index) => {
                        let tmpList = item.split('.');
                        let len = tmpList.length;

                        if (index > 0) {
                            tipsStr += '；</p>';
                        }

                        if (len === 2) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}-${tipsObj[tmpList[1]]}`;
                        }
                        if (len === 3) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}第${Number(tmpList[1]) + 1}行-${tipsObj[tmpList[2]]}`;
                        }
                        if (len === 4) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}第${Number(tmpList[1]) + 1}行-${tipsObj[tmpList[3]]}`;
                        }
                        if (len === 5) {
                            tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}第${Number(tmpList[1]) + 1}行-${tipsObj[tmpList[2]]}第${Number(tmpList[3]) + 1}行程-${tipsObj[tmpList[4]]}`;
                        }
                    });
                    tipsStr += '；</p>';
                }
                if (!formInfo.checkForm) {
                    const listLength = formInfo.list.length;
                    for (let i = 0; i < listLength; i++) {
                        if (!formInfo.list[i].parameterForm) {
                            tipsStr += `<p style="color: red;">请填写${formInfo.list[i].parameterText}</p>`;
                            break
                        }
                    }
                }
                console.log(tipsStr)
                this.$alert(tipsStr, '以下信息未填写', {
                    confirmButtonText: '确定',
                    dangerouslyUseHTMLString: true,
                });
            }
        },
        addOrder(formInfo) {
            let body = JSON.parse(JSON.stringify(this.form));
            body.passengerList.forEach((item,index) => {
                item.insuranceInfo.productCodes = [item.insuranceInfo.productCodes];
            });
            body.reportConfiguration = formInfo.list;
            body.projectCode = this.projectNameBox;
            body.projectName = this.projectCodeBox;
            body.reportConfiguration.forEach((value) => {
                if (value.parameter == 'GuiShuXiangMu') {
                    body.projectCode = value.parameterValue;
                    body.projectName = value.parameterText;
                }
            });
            body.reportConfiguration = body.reportConfiguration.filter(value => {return value.parameter != 'GuiShuXiangMu'});
            this.submitLoading = true;
            body.journeyInfo.forEach((item,index) => {
                body.journeyInfo[index] = {...item,proxyPrintServicePrice: this.proxyPrintServicePrice};
            });
            consumer_admin_trains_order_trainsSaveOrUpdate(body).then(res => {
                const orderResult = res.datas || {};
                let orderNo = orderResult ? orderResult.orderNo : '';
                // 另外传备注
                if (body.remark) {
                    const remarkParams = {
                        businessType: 2,
                        orderNo,
                        remark: body.remark
                    };
                    consumer_admin_trains_order_remarkSaveOrUpdate(remarkParams)
                }
                if (this.type === 'create' || !this.orderStatusToDisabled) {
                    // 跳转支付
                    const behalfCompanyId = body.customerInfo.companyId;
                    let isSuccess = orderResult ? orderResult.isSuccess : false;
                    if (isSuccess) {
                        this.$message({
                            type: "success",
                            message: '保存成功'
                        })
                        this.$router.push({
                            name: 'admin-train-create-order-pay',
                            query: {
                                orderNo,
                                behalfCompanyId
                            }
                        })
                    }
                } else {
                    // 跳转订单列表
                    this.$router.replace({
                        name: 'admin-train-order-list'
                    })
                }
            }).finally(() => {
                this.submitLoading = false;
            })
        },
        back() {
            this.$router.go(-1)
        },
        arrDateTimeDisableDate(time, index) {
            let value = this.form.journeyInfo[index].startTime;
            let m = this.$moment(value);
            const second = m.second();
            const minute = m.minute();
            const hour = m.hour();
            const millisecond = (hour * 60 * 60 + minute * 60 + second) * 1000;
            if (value) {
                return time.getTime() < new Date(value).getTime() - millisecond;
            } else {
                return false;
            }
        },
        greaterThanZero(rule, value, callback, tip) {
            if (Number(value) <= 0) {
                callback(new Error(`${tip}必须大于 0`));
            }
            callback();
        },
        greaterThanOrEqualToZero(rule, value, callback, tip) {
            if (Number(value) < 0) {
                callback(new Error(`${tip}不能为负数`));
            }
            callback();
        },
        changeEvectionType(val) {
            this.form.customerInfo.payType = '';
            const params = {
                companyId: this.form.customerInfo.companyId,
                evectionType: val,
                businessType: 2,
                trainsReservation: this.form.journeyInfo[0].trainsReservation,
                reservationSource: 3,
            };
            this.getServiceFee(params);
        },
        changePsgType(item, index, val) {
            this.psgTypeList.forEach(value => {
                if (value.value === val) {
                    item.psgTypeText = value.msg
                }
            })
        },
        changeCertificateType(item, index, val) {
            this.certificateTypeList.forEach(value => {
                if (value.value === val) {
                    item.cardTypeText = value.msg
                }
            })
            item.cardNo = '';
            if(item.certificates) {
                item.certificates.forEach(value=>{
                    if (value.certificateValue === val) {
                        item.cardNo = value.certificate;
                    }
                })
            }
        },
        cardNoValidator(rule, value, callback, index, item) {
            let cardType = item.cardType;
            const date = `${value.substr(6, 4)}-${value.substr(10, 2)}-${value.substr(12, 2)}`;
            if (cardType === 1) {
                if (this.isChineseIDCardNumber(value) === false) {
                    callback(new Error('请输入正确的身份证号码'));
                    return;
                } else {
                    this.$set(item, 'birthday', date);
                    callback();
                }
            } else {
                callback();
            }
            // this.$set(item, 'birthday', date);
        },
        // 身份证号码验证
        isValidDate(year, month, day) {
            const date = new Date(year, month - 1, day);
            return (
                date.getFullYear() === year
                && date.getMonth() + 1 === month
                && date.getDate() === day
                && date.getTime() < new Date().getTime()
            );
        },
        isChineseIDCardNumber(value) {
            const testRegExp = /^[1-9]([0-9]{14}|[0-9]{16}[0-9Xx])$/;
            const areaMap = [11, 12, 13, 14, 15, 21, 22, 23, 31, 32, 33, 34, 35, 36, 37, 41, 42, 43, 44, 45, 46, 50, 51, 52, 53, 54, 61, 62, 63, 64, 65, 71, 81, 82];
            const weightMap = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            const codeMap = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
            const len = value.length;
            if (len !== 18) {
                return false;
            }
            // 模式校验
            if (!testRegExp.test(value)) {
                return false;
            }
            // 地区校验
            if (areaMap.indexOf(+value.substr(0, 2)) === -1) {
                return false;
            }
            // 18 位
            if (!this.isValidDate(+value.substr(6, 4), +value.substr(10, 2), +value.substr(12, 2))) {
                return false;
            }
            // 校验码
            const sum = value.split('').slice(0, 17).reduce((s, num, index) => {
                return s += +num * weightMap[index];
            }, 0);
            return codeMap[sum % 11] === value[17].toUpperCase();
        },
        inputCardNo() {},
        checkMobile(rule, value, callback) {
            if (!isMobile(value)) {
                callback(new Error('输入长度不能大于11'));
                return
            }
            callback();
        },
        getNewUser(newUser) {
            let newItem = {
                userId: newUser.id,
                birthday: newUser.birthDate,
                cardNo: newUser.certificateValue,
                cardType: newUser.certificateType,
                cardTypeText: '',
                mobile: newUser.phone,
                psgName: '',
                psgType: 1,
                psgTypeText: '成人',
                insuranceInfo: {
                    isInsure: 1, // 是否购买保险
                    insuranceSource: 1,
                    productCodes: '',
                    insuranceOrderId: '', // 保单号
                    productName: '', // 保险名称
                    premium: '', // 保险销售价
                    insureCost: '', // 保险成本
                    startDate: '', // 生效时间
                    endDate: '', // 失效时间
                    channel: 2, // 保险渠道 1：华泰 2：德付通 3：旅游卫士 4：惠择
                    payType: 3, // 支付方式
                    packageName: '',
                },
                seatnumber: [
                    {
                        boardingGates: '',
                        journeyNo: '1',
                        seatInfos: '',
                    }
                ],
                certificates: [
                    {
                        "certificateValue": newUser.certificateType,
                        "certificateText": "",
                        "certificate": newUser.certificateValue,
                        "unSensitiveCertificateValue": newUser.certificateValue,
                        "countryCode": newUser.countryCode,
                        "countryName": "",
                        "issCountryCode": newUser.issCountryCode,
                        "issCountryName": "",
                        "cardExpireDate": newUser.passengerExpireDate
                    }
                ]
            };
            newItem.insuranceInfo.productCodes = this.form.passengerList[0].insuranceInfo.productCodes;
            newItem.insuranceInfo.packageName = this.form.passengerList[0].insuranceInfo.packageName;
            this.form.passengerList.push(newItem);
        },
        getUserList(val) {
            let pnr = this.form.passengerList[0].pnr || '';
            // 先判断是否只剩一个没有输入过的乘机人
            if (this.form.passengerList.length === 1 && !this.form.passengerList[0].psgName && !this.form.passengerList[0].cardNo) {
                this.form.passengerList = []
            }
            // 先删
            let activeIdList = [];
            val.activeDetailList.forEach(value => {
                activeIdList.push(value.userId);
            });
            let passengerList= [];
            this.form.passengerList.forEach((value, index)=>{
                if (activeIdList.indexOf(value.userId) > -1) {
                    passengerList.push(value);
                }
            });

            // 后加
            let passengerIdList = [];
            passengerList.forEach(value1=>{
                passengerIdList.push(value1.userId);
            });

            val.activeDetailList.forEach(value => {
                let _index = passengerIdList.indexOf(value.userId);
                if (_index === -1) {
                    // 新增
                    let birthday = '';
                    if (value.certificateType === 1 && value.certificateValue.length === 18) {
                        // 后续还要校验一下身份证号是否正常
                        birthday = `${value.certificateValue.substr(6, 4)}-${value.certificateValue.substr(10, 2)}-${value.certificateValue.substr(12, 2)}`;
                    }
                    let item = {
                        psgName: value.name,
                        psgType: 1,
                        psgTypeText: '成人',
                        cardType: value.certificateType,
                        cardTypeText: value.certificateName,
                        cardNo: value.certificateValue,
                        birthday: birthday,
                        mobile: value.phone,
                        userId: value.userId,
                        insuranceInfo: {
                            isInsure: 1, // 是否购买保险
                            insuranceSource: 1,
                            productCodes: '',
                            insuranceOrderId: '', // 保单号
                            productName: '', // 保险名称
                            premium: '', // 保险销售价
                            insureCost: '', // 保险成本
                            startDate: '', // 生效时间
                            endDate: '', // 失效时间
                            channel: 2, // 保险渠道 1：华泰 2：德付通 3：旅游卫士 4：惠择
                            payType: 3, // 支付方式
                            packageName: '',
                        },
                        seatnumber: [
                            {
                                boardingGates: '',
                                journeyNo: '1',
                                seatInfos: ''
                            }
                        ],
                        certificates: value.certificates || [],
                    };
                    if (this.form.passengerList.length > 0) {
                        item.insuranceInfo.productCodes = this.form.passengerList[0].insuranceInfo.productCodes;
                        item.insuranceInfo.packageName = this.form.passengerList[0].insuranceInfo.packageName;
                    }
                    if (this.form.passengerList.length === 0 && this.hasBusinessInsurance === true) {
                        item.insuranceInfo.productCodes = this.insuranceProductList[0].productCode;
                        item.insuranceInfo.packageName = this.insuranceProductList[0].packageName;
                    }
                    passengerList.push(item);
                } else {
                    // 匹配现有的
                    passengerList[_index].cardType = value.certificateType;
                    passengerList[_index].cardTypeText = value.certificateName;
                    passengerList[_index].cardNo = value.certificateValue;
                }
            })
            this.form.passengerList = JSON.parse(JSON.stringify(passengerList));

            // 如果没有乘客
            if (this.form.passengerList.length === 0) {
                let user = {
                    birthday: '',
                    cardNo: '',
                    cardType: 1,
                    cardTypeText: '身份证',
                    mobile: '',
                    psgName: '',
                    psgType: 1,
                    psgTypeText: '成人',
                    insuranceInfo: {
                        isInsure: 1, // 是否购买保险
                        insuranceSource: 1,
                        productCodes: '',
                        insuranceOrderId: '', // 保单号
                        productName: '', // 保险名称
                        premium: '', // 保险销售价
                        insureCost: '', // 保险成本
                        startDate: '', // 生效时间
                        endDate: '', // 失效时间
                        channel: 2, // 保险渠道 1：华泰 2：德付通 3：旅游卫士 4：惠择
                        payType: 3, // 支付方式
                        packageName: '',
                    },
                    seatnumber: [
                        {
                            boardingGates: '',
                            journeyNo: '1',
                            seatInfos: '',
                        }
                    ]
                };
                if (this.hasBusinessInsurance === true) {
                    user.insuranceInfo.productCodes = this.insuranceProductList[0].productCode;
                    user.insuranceInfo.packageName = this.insuranceProductList[0].packageName;
                }
                this.form.passengerList = [
                    user
                ]
            }
        },
        /*handleSelectCallback(value){
            let dataBox = {
                companyId: this.form.customerInfo.companyId,
                currentPage: 1,
                isSelectCustomer: true,
                key: '',
                pageSize: 10,
                userIds: [value.userId],
            };
            consumer_department_getStaffsAndExCusByUserIds(dataBox).then((res) => {
                res.datas.list.forEach((item) => {
                    if (item.userId == value.userId) {
                        let birthday = '';
                        if (item.certificateType == 1 && item.certificateValue) {
                            birthday = `${item.certificateValue.substr(6, 4)}-${item.certificateValue.substr(10, 2)}-${item.certificateValue.substr(12, 2)}`;
                        }
                        if (!birthday && item.birthDay) {
                            birthday = item.birthDay;
                        }
                        this.form.passengerList = [
                            {
                                psgName: item.name,
                                psgType: 1,
                                psgTypeText: '成人',
                                cardType: item.certificateType,
                                cardTypeText: item.certificateName,
                                cardNo: item.certificateValue,
                                birthday: birthday,
                                mobile: item.phone,
                                userId: item.userId,
                                seatnumber: [
                                    {
                                        boardingGates: '',
                                        journeyNo: '1',
                                        seatInfos: '',
                                    }
                                ]
                            },
                        ];
                    }
                })
            });
        },
        handleSelectDelete(){
            this.form.passengerList = [
                {
                    birthday: '',
                    cardNo: '',
                    cardType: 1,
                    cardTypeText: '身份证',
                    mobile: '',
                    psgName: '',
                    psgType: 1,
                    psgTypeText: '成人',
                    seatnumber: [
                        {
                            boardingGates: '',
                            journeyNo: '1',
                            seatInfos: '',
                        }
                    ]
                }
            ];
        },*/
    }
}

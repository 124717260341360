import extend from 'extend';
import _ from 'underscore';
import get_menu_data from './get_menu_data';
import get_consumer_admin_user_getUserMenu from '@/lib/common-service/get_consumer_admin_user_getUserMenu';
import all_routes_processor from '@/router/init_router/all_routes_processor';

export default {
    data() {
        return {
            data_get_menu_data: {
                menu_list: [],
                all_menu_list: [],
            },
            menu_item_list: [],
            default_openeds: [],
            default_active: ``,
        };
    },
    computed: {
        is_open() {
            const __this = this;
            return __this.$store.state.data_for_left_menu.is_open;
        }
    },
    watch: {
        $route(pNewValue, pOldValue) {
            const __this = this;
            __this.__init_default_active();
        },
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },

        __init_default_active() {
            const __this = this;

            const all_route_list = all_routes_processor.get_all_route_list();

            const __get_active_route_name = function () {
                let r = ``;

                const meta = __this.$route.meta;
                if (!meta) return r;

                const data_for_left_menu = meta.data_for_left_menu;
                if (!data_for_left_menu) return r;

                const get_active_route_name = data_for_left_menu.get_active_route_name;
                if (!_.isFunction(get_active_route_name)) return r;

                r = get_active_route_name({
                    all_route_list,
                });
                return r;
            };

            const active_route_name = __get_active_route_name();

            const route = _.chain(all_route_list)
                .find(function (m) {
                    return m.name === active_route_name;
                })
                .value()
            ;

            if (route) {
                //非一级页面
                __this.default_active = route.path;
            } else {
                //一级页面
                __this.default_active = __this.$route.path;
            }
        },
        flatten(arr) { // 多维数组转一维数组
            return [].concat(...arr.map(item => [].concat(item, ...this.flatten(item.menus || []))))
        },
        get_menu_data(__all_menu_list, _router) {
            const $router = _router;

            const all_menu_list = _.chain(__all_menu_list).map(function (m) {
                const route_name = m.routeName;
                const r = $router.resolve({
                    name: route_name,
                });

                let route_path = ``;
                let route_fullPath = ``;
                if (r) {
                    if (r.resolved) {
                        if (r.resolved.path) {
                            route_path = r.resolved.path;
                            route_fullPath = r.resolved.fullPath;
                        }
                    }
                }

                const o = extend(true, {}, m);
                o.title = m.name;
                o.route_name = m.routeName;
                o.route_path = route_path;
                o.route_fullPath = route_fullPath;
                o.sub_list = [];
                return o;
            }).value();

            const list_level_1 = _.chain(all_menu_list).filter(function (m) {
                return m.level === 1;
            }).map(function (m1) {
                const sub_list = _.chain(all_menu_list).filter(function (m2) {
                    return 1 === 1 && m2.parentId === m1.id && m2.level === 2;
                }).value();
                m1.sub_list = sub_list;
                return m1;
            }).value();

            const menu_list = list_level_1;

            const o = {
                menu_list,
                all_menu_list,
            };
            return o;
        }
    },
    created() {
        const __this = this;
        get_consumer_admin_user_getUserMenu({clear_cache: true}).then(res => {
            let __menus = __this.flatten(res.datas);
            __this.data_get_menu_data = __this.get_menu_data(__menus, __this.$router);
        }).catch((err) => {
            __this.data_get_menu_data = get_menu_data({
                $router: __this.$router,
            });
        });

        // __this.default_openeds = _.chain(__this.data_get_menu_data.menu_list)
        //     .map(function (m, i) {
        //         return i.toString();
        //     })
        //     .value()
        // ;

        __this.__init_default_active();
    },
}

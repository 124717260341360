export default {
    order: {      // 订购业务信息
        id: null,
        gmtCreate: "",
        gmtModified: "",
        orderNo: "",
        bizType: "",
        flightNumber: "",
        bizName: "",
        orderSource: "",
        paymentType: "",
        paymentTime: "",
        paymentNum: "",
        status: 0,
        remark: "",
        createBy: null,
        modifiedBy: null,
        modifiedName: "",
        insuranceNo: '',
        startTime: '',
        endTime: '',
        bizOrderNo: '',
    },
    companyInfo: {     // 客户基础信息
        id: null,
        orderId: null,
        companyId: null,
        companyName: "",
        contactName: "",
        contactMobile: "",
        evectionType: 2,
        payType: 1,
    },
    companyBills: [     // 客户信息
        {
            id: null,
            orderId: null,
            companyId: null,
            userId: null,
            userName: "",
            englishName: "",
            certId: null,
            companyType: 1,
            ticketNumber: "",
            seatNumber: "",
            status: 0,
            salesPrice: null,
            supPrice: null,
            serviceFee: null,
            fine: 0,
            fineTime: "",
            certNum: "",
            certType: 1,

            profit: null,
            certificates: [],
        }
    ],
    flightInfos: [     // 行程信息
        {
            id: null,
            orderId: null,
            airlineName: "",
            airlineNameNo: "",
            flightNumber: "",
            depAirport: "",
            depAirportNo: "",
            depCity: "",
            depCityNo: "",
            depTerminal: "",
            arrAirport: "",
            arrAirportNo: "",
            arrCity: "",
            arrCityNo: "",
            arrTerminal: "",
            airlineGrade: "",
            depTime: "",
            arrTime: "",

            segmentType: 1,
            voyage: '第1程',
            depAirportCityData: '',
            arrAirportCityData: '',
        }
    ],
}

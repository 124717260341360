import ChangeClausePopup from '@/page/admin/replace-orders/component/changeClausePopup/1.0.0/index.vue'
import consumer_air_ticket_fligthRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules"
import consumer_flight_search_stipulatesRule from '@/lib/common-service/consumer_flight_search_stipulatesRule'
export default {
    name: "FlightClass",
    data() {
        return {
            ruleText: {},
            ruleLoading: false,
            ruleShow: false, // 退改签说明弹窗
        };
    },
    props: {
        detail: Array,
        type: String,
        ticketDetail: Object
    },
    components: {
        ChangeClausePopup
    },
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        //退改规则
        backRule(val, index) {
            console.log('val', val)
            this.ruleText = {}
            this.ruleShow = true
            let params = {
                rqData: {
                    cabinCode: val.cabinCode,
                    flightKey: val.flightKey,
                    flightNo: val.flightNo,
                    policyId: val.policyId
                }
            }
            this.ruleLoading = true
            consumer_flight_search_stipulatesRule(params).then(res => {
                this.ruleLoading = false
                this.ruleText = res.datas.rsData
            }).catch(() => {
                this.ruleLoading = false
            })
        },
    }
}
export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/car-bill-report',
            name: 'admin-car-bill-report',
            component: () => import(/* webpackChunkName: "page-admin-reconciliation-statement-car-car-bill-report" */ `./1.0.1/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `用车对账明细报表`,
                },
                entity_list_for_breadcrumb: [
                    { name: `用车对账明细报表` }
                ],
            },
        },
    ],
};

import OrderLog from '@/component/orderLog/2.0.0/index.vue'

import consumer_admin_flight_offlineRefund_details
    from '@/lib/data-service/haolv-default/consumer_admin_flight_offlineRefund_details'//获取详情
import consumer_log_flightRefundList from "@/lib/data-service/haolv-default/consumer_log_flightRefundList"//获取备注
import consumer_admin_flight_refund_selfSupportRefundOrderRemark from "@/lib/data-service/haolv-default/consumer_admin_flight_refund_selfSupportRefundOrderRemark"//保存备注

export default {
    data() {
        return {
            activeName: 'detail',
            detailLoading: false,
            loading: false,
            refOrderNo: '',
            sourceType: 1, // 1国内2国际
            form: {
                remark: ''
            },
            detail: {
                oldSegList: [],
                segmentsList: [],//航班信息
                insuranceInfo: [],//保险信息
                refundPassenger: [],//乘客信息
                refundContent: [],//退票内容
            },
        }
    },
    components: {OrderLog},
    activated() {
        this.activeName = 'detail'
        this.refOrderNo = this.$route.query.refOrderNo;
        this.getDetail();
        this.getOrderLog();
    },
    filters: {
        filterMoney(val) {
            if (val || val === 0) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return '0.00'
            }
        },
        getImgNameFilter(val) {
            let filename;
            if (val === undefined || val === '') {
                return ''
            }
            if (val.indexOf('/') > 0) { //如果包含有"/"号 从最后一个"/"号+1的位置开始截取字符串
                filename = val.substring(val.lastIndexOf('/') + 1, val.length);
            } else {
                filename = val;
            }
            return filename
        },
    },
    methods: {
        //获取详情
        getDetail() {
            this.loading = true
            consumer_admin_flight_offlineRefund_details({
                refOrderNo: this.refOrderNo
            }).then(res => {
                let detail = res.detailVO;
                detail.refundPassenger.forEach((item) => {
                    if (item.sourceType) {
                        this.sourceType = item.sourceType
                    }
                });
                detail.refundContent.forEach(value => {
                    value.urlList = [];
                    value.urlList = value.url.split(',');
                })
                this.detail = detail;
                this.form.remark = this.detail.remark;
            }).catch(() => {
                this.$message({
                    type: "error",
                    message: "获取机票退票订单详情失败！"
                })
            }).finally(() => {
                this.loading = false
            })
        },

        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    //调用获取订单日志的接口
                    return consumer_log_flightRefundList({
                        refOrderNo: this.refOrderNo
                    }).then(res => {
                        // 组装组件需要的list
                        const list = res.datas.map(m => {
                            // 替换名字
                            const o = {
                                operator: m.operator,
                                date: m.operatorTime,
                                type: m.logSource,
                                detail: m.logContents
                            }
                            return o
                        })
                        const d = {
                            entityList: list
                        }
                        this.loading = false //临时使用
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },

        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
            switch (val) {
                case (1):
                    return 'type2'
                case (2):
                    return 'type1'
                default: return ''
            }
        },

        //保存
        async submit() {
            if (this.form.remark === '') {
                this.$message({
                    type: "warning",
                    message: '请输入备注'
                })
                return
            }
            let form = JSON.parse(JSON.stringify(this.form))
            form.refOrderNo = this.refOrderNo
            this.detailLoading = true
            consumer_admin_flight_refund_selfSupportRefundOrderRemark(form).then(res => {
                this.detailLoading = false
                this.$message({
                    type: "success",
                    message: '提交成功'
                })
                this.form.remark = '';
                this.getDetail();
                this.getOrderLog();
            }, error => {
                this.detailLoading = false
            }).catch(() => {
                this.detailLoading = false
            })
        },

        //返回
        back() {
            this.$router.go(-1)
        },
    }
}

import _ from 'underscore';

import OrderLog from '@/component/orderLog/2.0.0/index.vue'
import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.2.1/index.vue';
import moment from "moment";


// 火车票线下单改签详情
import consumer_admin_trains_order_trainsOfflineOrderDetails
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsOfflineOrderDetails'
// 火车票坐席枚举
import consumer_admin_trains_order_seatingLevel
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_seatingLevel'
// 火车票线下单保存或编辑
import consumer_admin_trains_order_trainsOfflineChangeOrUpdate
  from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsOfflineChangeOrUpdate'
// 当前登陆人--操作客服
import consumer_admin_flight_generate_userName from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_userName'


export default {
  data() {
    return {
      options: [
        {
          value: '1',
          label: '改签票价大于原票价'
        },
        {
          value: '2',
          label: '改签票价小于原票价'
        },
        {
          value: '3',
          label: '改签票价等于原票价'
        },
      ],
      loading: false,
      // 当前登陆人
      userName: null,
      // 订单号
      orderNo: '',
      // 改签单号
      changeNo: '',
      // 当前正在更改的改签乘客信息
      ruleSegmentsListCardNo: null,
      // 当前正在更改的改签车次信息--行程
      changejourneyInfoBox: null,
      remark: '', //备注
      // 改签信息
      changeInformation: {},
      //改签车次信息
      changejourneyInfo: [],
      //改签车次信息--checkbox开关暂存
      checkboxjourneyInfo: [],
      //原车次信息
      journeyInfo: [],
      //订单信息
      trainsOrderDetails: {},
      //座席list
      seatNameList: [],
      changeInFormationRule: {
        changeTime: [
          {
            required: true,
            validator: (rule, value, callback) => {
              this.VerificationTwo(this.changeInformation, 'changeTime', '请选择申请改签时间', callback);
            },
            trigger: 'blur'
          }
        ],
        supplierchangeOrderNo: [
          {
            required: true,
            validator: (rule, value, callback) => {
              this.VerificationTwo(this.changeInformation, 'supplierchangeOrderNo', '请输入供应商改签订单号', callback);
            },
            trigger: 'blur'
          }
        ],
        initialName: [
          {
            required: true,
            validator: (rule, value, callback) => {
              this.VerificationTwo(this.changeInformation, 'initialName', '请输入操作客服', callback);
            },
            trigger: 'blur'
          }
        ],
      },
      rulesJourneyInfo: {
        trainNo: [
          {
            validator: (rule, value, callback) => {
              let dataBox = this.changejourneyInfoBox;
              this.VerificationTwo(dataBox, 'trainNo', '请输入车次', callback);
            }, trigger: ['change', 'blur']
          }
        ],
        fromStationName: [
          {
            validator: (rule, value, callback) => {
              let dataBox = this.changejourneyInfoBox;
              this.VerificationTwo(dataBox, 'fromStationName', '请输入始发站', callback);
            }, trigger: ['change', 'blur']
          }
        ],
        toStationName: [
          {
            validator: (rule, value, callback) => {
              let dataBox = this.changejourneyInfoBox;
              if (dataBox.toStationNameBox == null) {
                callback(new Error(`请输入到达站`));
              } else {
                this.VerificationTwo(dataBox, 'toStationNameBox.stationCode', '请输入到达站', callback);
              }
            }, trigger: ['change', 'blur']
          }
        ],
        startTime: [
          {
            validator: (rule, value, callback) => {
              let dataBox = this.changejourneyInfoBox;
              this.VerificationTwo(dataBox, 'startTime', '请选择出发时间', callback);
            }, trigger: ['change', 'blur']
          }
        ],
        arriveTime: [
          {
            validator: (rule, value, callback) => {
              let dataBox = this.changejourneyInfoBox;
              this.VerificationTwo(dataBox, 'arriveTime', '请选择到达时间', callback);
            }, trigger: ['change', 'blur']
          }
        ],
        seatName: [
          {
            validator: (rule, value, callback) => {
              let dataBox = this.changejourneyInfoBox;
              this.VerificationTwo(dataBox, 'seatName', '请选择座席等级', callback);
            }, trigger: 'blur'
          }
        ],
        seatPrice: [
          {
            validator: (rule, value, callback) => {
              let dataBox = this.changejourneyInfoBox;
              this.VerificationTwo(dataBox, 'seatPrice', '请输入票价', callback);
            }, trigger: ['change', 'blur']
          }
        ],
      },
      ruleSegmentsListRuleFlase: {},
      ruleSegmentsListRule: {
        changFlightNo: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let dataBox = this.ruleSegmentsListCardNo;
              if (dataBox) {
                if (dataBox.paymentFlag != '3' && dataBox.paymentFlag != '2') {
                  this.VerificationTwo(dataBox, 'changeAmount', '请输入改签支付金额', callback);
                }
                if (dataBox.paymentFlag == '3' || dataBox.paymentFlag == '2') {
                  callback();
                }
              }
            },
            trigger: 'blur'
          },
        ],
        depDateBox: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let dataBox = this.ruleSegmentsListCardNo;
              if (dataBox) {
                if (dataBox.paymentFlag != '3') {
                  this.VerificationTwo(dataBox, 'returnamount', '请输入退回金额', callback);
                }
                if (dataBox.paymentFlag == '3') {
                  callback();
                }
              }
            },
            trigger: 'blur'
          },
        ],
        cabinRank: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let dataBox = this.ruleSegmentsListCardNo;
              this.VerificationTwo(dataBox, 'newSeat', '请输入新座位号', callback);
            },
            trigger: 'blur'
          },
        ],
        cabinCode: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let dataBox = this.ruleSegmentsListCardNo;
              this.VerificationTwo(dataBox, 'newElectronicTicketNumber', '请输入新取票号', callback);
            },
            trigger: 'blur'
          },
        ],
      },
    }
  },
  components: {
    OrderLog,
    TrainCitySelector
  },
  created() {
  },
  mounted() {

  },
  activated() {
    this.getData();
    // this.getReset();
  },
  deactivated() {
  },
  destroyed() {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    changeSelect(paymentFlag,passengerList){
      passengerList.forEach((PItem,PIndex) => {
        PItem.paymentFlag = paymentFlag;

        PItem.changeAmount = '';
        PItem.returnamount = '';
        PItem.newSeat = '';
        PItem.newElectronicTicketNumber = '';
      })
    },
    VerificationTwo(dataBox, name, title, callback) {
      let nameBox = null;
      if (dataBox == null) {
        return
      }
      eval(`
        nameBox = dataBox.${name};
      `);
      if (dataBox) {
        if (nameBox) {
          callback();
        } else {
          callback(new Error(`${title}`));
        }
      } else {
        callback();
      }
      callback();
    },
    blurSelectRulesJourneyInfo(item,index) {
      this.$refs.ruleForm[index].validateField('seatName');
      let box = null;
      this.seatNameList.forEach((SItem,SIndex) => {
        if (item.seatName == SItem.msg) {
          box = item;
        }
      });
      if (box) {
        this.changejourneyInfo[index].seatCode = box.code;
      }
    },
    clickRulesJourneyInfo(data) {
      this.changejourneyInfoBox = data;
    },
    checkSwitchBox(index) {
      this.$refs.ruleSegmentsList[index].clearValidate();
    },
    focusRecordName(data,cardNo) {
      data.passengerList.forEach((item,index) => {
        if (item.cardNo == cardNo) {
          this.ruleSegmentsListCardNo = item;
        }
      });
    },
    //重置
    getReset() {

    },
    // 勾选火车票行程checkbox
    handleSelectionChange(data) {
      let box = this.checkboxjourneyInfo[data];
      this.checkboxjourneyInfo[data] = !box;
      this.$refs['ruleForm'][data].clearValidate()
    },
    //请求数据
    getData() {
      this.loading = true;
      this.orderNo = this.$route.query.orderNo;
      this.changeNo = this.$route.query.changeNo;
      let dataOrderNo = {
        orderNo: this.orderNo,
        changeNo: this.changeNo,
      };
      consumer_admin_trains_order_trainsOfflineOrderDetails(dataOrderNo).then(res => {
        this.loading = false;
        // 订单信息
        this.trainsOrderDetails = res.datas.trainsOrderDetails;
        // 原车次信息
        this.journeyInfo = res.datas.journeyInfo;
        // 改签车次信息
        this.changejourneyInfo = res.datas.changejourneyInfo;
        // 改签行程--是否勾选list
        this.checkboxjourneyInfo = [];

        this.changejourneyInfo.forEach((item, index) => {
          this.$set(item, 'switchBox', false);
          this.$set(item, 'toStationNameBox', {stationCode: item.toStationCode});
          item.fromStationName = this.journeyInfo[0].fromStationName;
          this.checkboxjourneyInfo.push(true);
          item.passengerList.forEach((PItem, PIndex) => {
            PItem.paymentFlag = `${PItem.paymentFlag}`;
            this.$set(PItem, 'switchBox', false);
          });
        });
        this.changeInformation = res.datas.changeInformation;
        consumer_admin_flight_generate_userName().then(res => {
          this.userName = res.datas;
          this.changeInformation.initialName = this.userName[0].initialName;
          this.changeInformation.userId = this.userName[0].userId;

          this.$nextTick(() => {
            this.$refs.toTrainCitySelector.forEach(value => {
              value.init({ placeholder_text: "请选择到达站点" })
            });
          })
        });

        this.remark = '';
      });
      consumer_admin_trains_order_seatingLevel().then(res => {
        this.seatNameList = res.datas;
      });

    },
    //保存按钮
    clickKeep() {
      let VerificationCrux = [];
      let passengerListMax = [];
      let checkboxJourneyInfoSwitch = true;
      let passengerListSwitch = true;
      this.checkboxjourneyInfo.forEach((item, index) => {
        this.changejourneyInfo[index].passengerList.forEach((PItem, PIndex) => {
          passengerListMax.push(PItem);
        });
        if (item == false) {
          checkboxJourneyInfoSwitch = false;
          this.changejourneyInfoBox = this.changejourneyInfo[index];
          this.$refs.ruleForm[index].validate();
          // 触发-行程区域-校验
          this.$refs.ruleForm[index].validate();
          let box = [];
          if (this.changejourneyInfoBox.trainNo) {
            box.push(true)
          } else {
            box.push(false)
          }
          if (this.changejourneyInfoBox.fromStationName) {
            box.push(true)
          } else {
            box.push(false)
          }
          if (this.changejourneyInfoBox.toStationNameBox.stationName) {
            box.push(true)
          } else {
            box.push(false)
          }
          if (this.changejourneyInfoBox.startTime) {
            box.push(true)
          } else {
            box.push(false)
          }
          if (this.changejourneyInfoBox.arriveTime) {
            box.push(true)
          } else {
            box.push(false)
          }
          if (this.changejourneyInfoBox.seatName) {
            box.push(true)
          } else {
            box.push(false)
          }
          if (this.changejourneyInfoBox.seatPrice) {
            box.push(true)
          } else {
            box.push(false)
          }

          if (box.indexOf(false) == -1) {
            // VerificationCrux(所有校验信息集合--行程、乘客、信息)--收集校验信息
            VerificationCrux.push(true);
          } else {
            VerificationCrux.push(false);
          }
        }
      });
      passengerListMax.forEach((item,index) => {
        if (item.switchBox) {
          passengerListSwitch = false;
          this.ruleSegmentsListCardNo = item;
          this.$refs['ruleSegmentsList'][index].validate((valid) => {
            if (valid) {
              this.ruleSegmentsListCardNo = null;
              VerificationCrux.push(true);
            } else {
              this.ruleSegmentsListCardNo = null;
              VerificationCrux.push(false);
            }
          });
        }
      });

      this.$refs['changeInFormationList'].validate((valid) => {
        if (valid) {
          VerificationCrux.push(true);
        } else {
          VerificationCrux.push(false);
        }
      });

      if (VerificationCrux.indexOf(false) != -1) {
        this.changejourneyInfo.forEach((item,index) => {
          if (item.switchBox) {
            let boxSwitchBox = [];
            item.passengerList.forEach((PItem,PIndex) => {
              boxSwitchBox.push(PItem.switchBox);
            });
            passengerListSwitch = true;
            if (boxSwitchBox.indexOf(true) != -1) {
              passengerListSwitch = false;
            }
          }
        })
      }

      if (checkboxJourneyInfoSwitch) {
        this.$message({
          message: '请选择改签车次行程',
          type: 'warning'
        });
        return;
      }
      if (passengerListSwitch) {
        this.$message({
          message: '请选择改签乘客',
          type: 'warning'
        });
        return;
      }

      if (VerificationCrux.indexOf(false) == -1 && VerificationCrux.length != 0) {
        this.loading = true;
        let changeInformation = this.changeInformation;
        let changeNo = this.changeNo;
        let changejourneyInfo = this.changejourneyInfo;
        let orderNo = this.orderNo;
        let remark = this.remark;

        changejourneyInfo.forEach((CItem,CIndex) => {
          CItem.startTime = this.$moment(CItem.startTime).format("YYYY-MM-DD HH:mm");
          CItem.arriveTime = this.$moment(CItem.arriveTime).format("YYYY-MM-DD HH:mm");

          CItem.toStationCode = CItem.toStationNameBox.stationCode;
          CItem.toStationName = CItem.toStationNameBox.stationName;

          CItem.passengerList.forEach((PItem,PIndex) => {
            if (PItem.switchBox == true) {
              delete PItem.switchBox
            }
            if (PItem.switchBox == false) {
              delete CItem.passengerList[PIndex]
            }
          });
          delete CItem.switchBox
          delete CItem.toStationNameBox
        });
        changeInformation.changeTime = this.$moment(changeInformation.changeTime).format("YYYY-MM-DD HH:mm:ss");
        let postBox = {
          changeInformation,
          changeNo,
          changejourneyInfo,
          orderNo,
          remark,
        };
        // 去除乘客list中的空元素
        postBox.changejourneyInfo[0].passengerList = postBox.changejourneyInfo[0].passengerList.filter(Boolean);

        consumer_admin_trains_order_trainsOfflineChangeOrUpdate(postBox)
          .then(res => {
            this.loading = true;
            if (res.code == '000000') {
              this.$message({
                message: '保存成功',
                type: 'success'
              });
              setTimeout(() => {
                this.loading = false;
                this.$router.push({
                  name: 'admin-train-change-offline-confirm',
                  query: {
                    orderNo: this.orderNo,
                    changeNo: res.datas.changeNo,
                  },
                })
              },1000)
            } else {
              this.$message.error('保存失败');
            }
          })
          .catch(ref => {
            this.loading = true;
          })
      }

    },
    //返回按钮
    clickGoBack() {
      this.$router.go(-1);
    },
  }
}
export default {
    name: 'Pagination',
    props: {
        total: {
            required: true,
            type: Number
        },
        page: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 20
        },
        pageSizes: {
            type: Array,
            default () {
                return [10, 20, 30, 50]
            }
        },
        layout: {
            type: String,
            default: 'prev, pager, next'
        },
        background: {
            type: Boolean,
            default: true
        },
        autoScroll: {
            type: Boolean,
            default: true
        },
        hidden: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        currentPage: {
            get () {
                return this.page
            },
            set (val) {
                this.$emit('update:page', val)
            }
        },
        pageSize: {
            get () {
                return this.limit
            },
            set (val) {
                this.$emit('update:limit', val)
            }
        }
    },
    methods: {
        handleSizeChange (val) {
            this.$emit('pagination', { page: this.currentPage, limit: val })
            if (this.autoScroll) {
                // scrollTo(0, 800)
                window.scrollTo(0, 0)
            }
        },
        handleCurrentChange (val) {
            this.$emit('pagination', { page: val, limit: this.pageSize })
            if (this.autoScroll) {
                // scrollTo(0, 800)
                window.scrollTo(0, 0)
            }
        }
    }
}
// 未开通授信公司详情  http://api-alpha.wholeok.com/doc.html#/tmc-biz/t-us-company-credit-controller/openCreditDetailUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_credit_open_detail = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/credit/open/detail',
    data: pParameter
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_credit_open_detail;
const __request = require(`./__request/__request_contentType_json`);
// 重置员工密码：http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/API/reSetPasswordUsingPOST
const consumer_admin_web_staff_reSetPassword = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/web/staff/reSetPassword',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_web_staff_reSetPassword;

export default {
    order: '订票信息',
    companyInfo: '客户基础信息',
    companyBill: '客户信息',
    // 备注信息
    remark: '备注信息',

    contactName: '联系人',
    contactMobile: '联系电话',
    companyId: '所属企业',
    evectionType: '出行类型',
    payType: '支付方式',
    type: '票务类型',
    journey: '行程',
    shift: '班次',
    journeyDate: '行程日期',
    journeyPeriod: '行程时间',
    orderSource: '采购渠道',
    supOrderNo: '交易号',
    paymentType: '付款方式',
    paymentTime: '到达时间',
    paymentNum: '付款流水号',
    seatNumber: '确认号',
    refund: '是否可退',
    userName: '客户姓名',
    companyType: '乘客类型',
    certType: '证件类型',
    certNum: '证件号',
    grade: '等级',
    salesPrice: '票价',
    serviceFee: '服务费',
}
const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 获取国际机场
const consumer_flight_search_internationalCitys = (pParameter) => {
    if (!pParameter) pParameter = {};
    const name = pParameter.name || ``;

    const p = {
        method: 'post',
        urlSuffix: '/consumer/flight/search/internationalCitys',
        data: pParameter,
    };
    return Q.when()
        .then(function () {
            return __request(p);
        })
        .then(function (data) {
            return Q.resolve(data);
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};
module.exports = consumer_flight_search_internationalCitys;


export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/train-unsubscribe-list',
            name: 'admin-train-unsubscribe-list',
            component: () => import(/* webpackChunkName: "page-admin-order-management-train-train-unsubscribe-list" */ `./1.0.2/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `退款订单`,
                },
                entity_list_for_breadcrumb: [
                    { name: `火车票订单` },
                    { name: `退款订单` },
                ],
            },
        },
    ],
};



export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    roomType: {
      type: Number,
      required: false,
      default: 1,
    },
    staffs: {
      type: Array,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      checkList: [],
    }
  },
  computed: {
  },
  methods: {
    change_value (val) {
      this.$emit('input', val);
    },
    disabled (userId) {
      const length = this.roomType;
      const list = this.value;

      if (list.length >= length) {
        if (list.includes(userId)) {
          return false;
        } else {
          return true;
        }
      }
    },
  },
  filters: {
    
  },
  created() {},
  mounted() {
    
  },
  activated() {},
  deactivated() {},
  destroyed() {},
  watch: {
    
  },
};

// 火车票对账报表
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/doc.html?tdsourcetag=s_pctim_aiomsg#/tmc-biz/admin-%E7%81%AB%E8%BD%A6%E8%AE%A2%E5%8D%95/queryTrainsOperationReportOrderListUsingPOST
const consumer_admin_trains_order_queryTrainsOperationReportOrderList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/trains/order/queryTrainsOperationReportOrderList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_trains_order_queryTrainsOperationReportOrderList;
import consumer_flight_report_order_ticketChannel from '@/lib/data-service/haolv-default/consumer_flight_report_order_ticketChannel'
import consumer_flight_report_flightRefundReportList from '@/lib/data-service/haolv-default/consumer_flight_report_flightRefundReportList'
import consumer_admin_flight_generate_orderSource from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_orderSource'
import consumer_flight_report_flightRefundReportForExcel from '@/lib/data-service/haolv-default/consumer_flight_report_flightRefundReportForExcel'
import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'

export default {
    data() {
        return {
            loading: false,
            refundTypeList: [
                {
                    id: 0,
                    value: '全部'
                },
                {
                    id: 1,
                    value: '自愿退票'
                },
                {
                    id: 2,
                    value: '非自愿退票'
                }
            ],
            ticketChannelList: [],
            orderTimeArr: [],
            evectionTypeList: [
                {
                    value: 0,
                    label: '全部'
                },
                {
                    value: 2,
                    label: '因公出差'
                },
                {
                    value: 1,
                    label: '因私出行'
                },
            ],
            orderSourceList: [{//订单来源枚举值
                code: 0,
                msg: '全部'
            }],
            searchForm: {
                refOrderNo: '',//退票单号
                refundType: 0,//改签类型
                ticketNumber: '',//票号
                psgName: '',//乘机人
                flightNo: '',//航班号
                orderTimeStart: '',//申请开始时间
                orderTimeEnd: '',//申请结束时间
                abbreviation: '',//所属企业
                ticketChannel: '',//出票渠道
                evectionType: 0,//因公/因私
                orderSource: 0,//订单来源
                currentPage: 1,//查询页码
                pageSize: 10,//查询条数
                total: 0,
                sourceType: 0,
            },
            sourceTypeList: [
                // 机票类型：0.全部, 1.国内，2.国际
                {value: 0, label: '全部'},
                {value: 1, label: '国内'},
                {value: 2, label: '国际'},
            ],
            list: []
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    activated() {
        this.init()
    },
    methods: {
        //初始化
        async init() {
            await this.getTicketChannelList();
            await this.getOrderSource();
            this.search();
        },
        //搜索
        search() {
            this.searchForm.currentPage = 1
            this.searchForm.orderStatus = this.activeName === '0' ? '' : this.activeName
            this.getList()
        },
        //重置
        reset() {
            let searchForm = {
                refOrderNo: '',//退票单号
                refundType: 0,//改签类型
                ticketNumber: '',//票号
                psgName: '',//乘机人
                flightNo: '',//航班号
                orderTimeStart: '',//申请开始时间
                orderTimeEnd: '',//申请结束时间
                abbreviation: '',//所属企业
                ticketChannel: '',//出票渠道
                evectionType: 0,//因公/因私
                orderSource: 0,//订单来源
                currentPage: 1,//查询页码
                pageSize: 10,//查询条数
                total: 0,
                sourceType: 0,
            };
            this.searchForm = searchForm;
            this.orderTimeArr = [];
            this.search()
        },
        //改变搜索条件
        handleClick() {
            this.search()
        },
        //搜索日期改变时
        changeOrderTimeArr(val) {
            if (val === '' || val === null) {
                this.searchForm.orderTimeStart = ''
                this.searchForm.orderTimeEnd = ''
            } else {
                this.searchForm.orderTimeStart = val[0] + ' 00:00:00'
                this.searchForm.orderTimeEnd = val[1] + ' 23:59:59'
            }
        },
        //获取列表
        getList() {
            this.loading = true
            this.list = []
            consumer_flight_report_flightRefundReportList(this.searchForm).then(res => {
                this.loading = false
                this.list = res.datas.list
                this.searchForm.total = res.datas.totalCount
            }).catch(() => {
                this.loading = false
                this.list = []
            })
        },
        //获取出票渠道枚举值
        async getTicketChannelList() {
            let res = await consumer_flight_report_order_ticketChannel();
            this.ticketChannelList = res.datas;
        },
        //获取订单来源枚举值
        async getOrderSource() {
            this.orderSourceList = [{
                code: 0,
                msg: '全部'
            }];
            let res = await consumer_admin_flight_generate_orderSource();
            this.orderSourceList = [...this.orderSourceList, ...res.datas];
        },
        //导出账单
        async exportBills() {
            let res = await consumer_flight_report_flightRefundReportForExcel(this.searchForm);
            window.open(`${res.msg}`, '_blank');
        }
    }
}
import Q from 'q';

// 上传文件组件
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client/index";
// 获取国内航司
import consumer_flight_search_airlines from '@/lib/data-service/haolv-default/consumer_flight_search_airlines';
// 添加编辑航司
import consumer_flight_search_saveOrUpdate from '@/lib/data-service/haolv-default/consumer_flight_search_saveOrUpdate';
// 删除航司
import consumer_flight_search_deleteAirline from '@/lib/data-service/haolv-default/consumer_flight_search_deleteAirline';

export default {
    data () {
        return {
            loading: false,
            params: {
                // 搜索参数
                airLineName: '',
                airLineCode: '',

                // 分页参数
                currentPage: 1,
                pageSize: 10,
                totalCount: 0
            },
            tableList: [],
            dialogFormVisible: false,
            form: {
                airCompanyName: '',
                airCompanyShortName: '',
                airCompanyLogoImg: '',
                airlineCode: '',
            },
            formRules: {
                airCompanyName: [{required: true, message: '请输入航司名称', trigger: 'blur'}],
                airCompanyShortName: [{required: true, message: '请输入航司简称', trigger: 'blur'}],
                airCompanyLogoImg: [{required: true, message: '请上传航司logo'}],
                airlineCode: [{required: true, message: '请输入代码', trigger: 'blur'}],
            },
            logoLoading: false
        }
    },
    methods: {
        get_table_list() {
            const __this = this;
            __this.loading = true;
            consumer_flight_search_airlines(__this.params).then(res => {
                __this.tableList = res.pageResult.pageData;
                __this.params.totalCount = res.pageResult.totalCount;
            }).finally(() => {
                __this.loading = false;
            })
        },
        onSearch() {
            const __this = this;
            __this.params.currentPage = 1;
            __this.get_table_list()
        },
        handleSizeChange(size) {
            const __this = this;
            __this.params.currentPage = 1;
            __this.params.pageSize = size;
            __this.get_table_list()
        },
        handleCurrentChange(page) {
            const __this = this;
            __this.params.currentPage = page;
            __this.get_table_list()
        },
        change_upload(file) {
            const __this = this;
            __this.$refs.uploadLogo.clearFiles();
            const myUpLoad = new OssClient();
            const isJPG_PNG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/jpg' || file.raw.type === 'image/png';
            const isLt10M = file.size / 1024 < 10240;
            if (!isJPG_PNG) {
                __this.$message.error('上传图片格式有误!');
                return false;
            }
            if (!isLt10M) {
                __this.$message.error('航司LOGO大小不能超过 10MB!');
                return false;
            }
            __this.logoLoading = true;
            myUpLoad.multipartUpload({
                file: file.raw,
                progress: function* (p) {
                },
            }).then((data) => {
                __this.logoLoading = false;
                if(isJPG_PNG && isLt10M) {
                    __this.form.airCompanyLogoImg = data.url;
                }
            }).catch((error) => {
                __this.logoLoading = false;
                __this.$message.error("上传失败");
            });
        },
        add_flight() {
            const __this = this;
            __this.form = {
                airCompanyName: '',
                airCompanyShortName: '',
                airCompanyLogoImg: '',
                airlineCode: '',
            };
            __this.$nextTick(() => {
                __this.$refs.form.clearValidate();
            });
            __this.dialogFormVisible = true;
        },
        edit_form(val) {
            const __this = this;
            const vals = JSON.parse(JSON.stringify(val));
            __this.form = vals;
            __this.dialogFormVisible = true;
        },
        add_or_update() {
            const __this = this;
            __this.$refs.form.validate((valid) => {
                if (valid) {
                    __this.loading = true;
                    consumer_flight_search_saveOrUpdate(__this.form).then(res => {
                        __this.$refs.form.resetFields();
                        __this.dialogFormVisible = false;
                        __this.get_table_list();
                    }).finally(() => {
                        __this.loading = false;
                    })
                } else {
                    return false;
                }
            });
        },
        handleClose() {
            const __this = this;
            __this.$nextTick(() => {
                __this.$refs.form.resetFields();
            });
        },
        delete_flight(val) {
            const __this = this;
            __this.$confirm('确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                __this.loading = true;
                consumer_flight_search_deleteAirline({id: val.id}).then(res => {
                    __this.$message.success('删除成功!');
                    __this.get_table_list()
                }).finally(() => {
                    __this.loading = false;
                })
            }).catch(() => {});
        }
    },
    activated() {
        const __this = this;
        __this.get_table_list();
    },
    watch: {
    },
}
// 授信申请审批  http://api-beta.haolvtrip.com/doc.html#/tmc-biz/t-us-company-credit-controller/auditCreditUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_credit_auditCredit = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/credit/auditCredit',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_credit_auditCredit;
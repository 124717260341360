// 用车总账单excel导出 http://yapi.yinzhitravel.com/project/52/interface/api/15420

const __request = require(`./__request/__request_contentType_json`)
const consumer_admin_car_order_adminCarOrderBillExcel = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/admin/car/order/adminCarOrderBillExcel',
    data: data
  }
  return __request(pParameter)
}
export default consumer_admin_car_order_adminCarOrderBillExcel

import Q from 'q';
import consumer_department_getStaffsAndExCusByUserIds
    from "yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_department_getStaffsAndExCusByUserIds";
import TravelerMultipleSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.0.2/index.vue';
import SelectCertificate from '@/component/selectCertificate/import_latest_version_module'
import NewUserPopup from '@/component/newUserPopup/import_latest_version_module'
import NewCertificatesPopup from '@/component/newCertificatesPopup/import_latest_version_module'

export default {
    data() {
        return {
            companyId: '',
            contactIndex: 0,
            form: {
                userList: [],//出行人
                userIdList: [],//出行人userIdList
                linkman: '',//联系人
                linkmanPhone: '',//联系人电话
                smsType: 0,//发送行程信息到出行人手机。不发送：0；发送：1
                linkmanSms: 0//发送行程信息到联系人手机。不发送：0；发送：1
            },
            contact: [],
            contactObj: {},
        }
    },
    components: {
        SelectCertificate,
        TravelerMultipleSelector,
        NewUserPopup,
        NewCertificatesPopup,
    },
    watch: {
        //userIdList变化时，更新userList，并发布userIdList和userList给父组件
        'form.userIdList'(pNewValue) {
            let _this = this;
            this.$refs.aTravelerMultipleSelector.get_selected_entity_list_promise({
                id_list: pNewValue,
            }).then(function ({entity_list}) {
                _this.form.userList = entity_list;
                _this.$set(_this.form.userList, 'changeCardvisible', false);
                _this.form.userList.forEach(item => {
                    _this.$set(item, 'certificateType', 1);
                    item.certificates.forEach(item => {
                        if (item.certificateValue !== 1) {
                            _this.$set(item, 'isSelected', false);
                        } else {
                            _this.$set(item, 'isSelected', true);
                        }
                    })
                })

                let travelerInfoObj = {
                    userList: _this.form.userList,
                    userIdList: _this.form.userIdList
                }
                _this.$emit('selected-user-list-change', travelerInfoObj)
            });
        },
        //从员工中选择联系人时，
        contact(newVal) {
            this.contactObj = this.$refs.aContactSingleSelector.get_selected_entity();
            this.form.linkman = this.contactObj.name;
            this.form.linkmanPhone = this.contactObj.phone;
        }
    },
    methods: {
        //初始化
        init(companyId) {
            this.setInitDataFromParent(companyId);
            this.initSelectorData();
        },
        //接收父组件传来的值
        setInitDataFromParent(companyId) {
            this.companyId = companyId;
        },
        //初始化选人弹框数据
        initSelectorData() {
            this.getTravelerMultipleSelectorData();
            this.getContactSingleSelectorData();
        },
        //初始化选择出行人弹框
        getTravelerMultipleSelectorData() {
            this.$refs.aTravelerMultipleSelector.init_parameter.get_data_source_parameter = () => {
                const d = {
                    companyId: this.companyId,
                    isSelectCustomer: true,
                };
                return Q.resolve(d);
            };
            this.$refs.aTravelerMultipleSelector.init()
        },
        //初始化选择联系人弹框
        getContactSingleSelectorData() {
            this.$refs.aContactSingleSelector.init_parameter.get_data_source_parameter = () => {
                const d = {
                    companyId: this.companyId,
                    isSelectCustomer: true,
                };
                return Q.resolve(d);
            };
            this.$refs.aContactSingleSelector.init()
        },
        //新增出行人
        addTraveler() {
            this.$refs.aNewUserPopup.init({companyId: this.companyId});
            this.$refs.aNewUserPopup.show();
        },
        getNewUser(newUser) {
            let userList = this.form.userList;
            userList.push(newUser);
            this.$set(this.form, 'userList', userList)

            let userIdList = this.form.userIdList;
            userIdList.push(newUser.userId);
            this.$set(this.form, 'userIdList', userIdList)
        },
        //选择出行人
        chooseTraveler() {
            this.$refs.aTravelerMultipleSelector.show();
        },
        //选择联系人
        chooseContact() {
            this.$refs.aContactSingleSelector.show();
        },
        //删除出行人
        delTraveler(userId) {
            this.form.userIdList.find((item, index) => {
                if (item === userId) {
                    this.form.userIdList.splice(index, 1);
                    return;
                }
            })
        },
        //添加证件
        addCertificate(user) {
            this.$refs.aNewCertificatesPopup.init(user);
            this.changeCardvisible = false;
        },
        //获取新增的证件
        async getNewCertificates(param) {
            let res = await consumer_department_getStaffsAndExCusByUserIds({userIds: [param.userId]})
            this.form.userList.find((item, index) => {
                if (item.userId === param.userId) {
                    item.certificates = res.datas.list[0].certificates;
                    item.certificates.forEach(certificate => {
                        certificate.isSelected = false;
                        if (certificate.certificateValue === param.certificateType) {
                            certificate.isSelected = true;
                            item.certificateType = param.certificateType;
                        }
                    })
                    item.certificateName = param.certificateTypeName;
                    item.certificateValue = param.certificateValue;
                    item.changeCardvisible = false;
                }
            })
        },
        //更改证件
        changeCertificate(param) {
            this.form.userList.find((item, index) => {
                if (item.userId === param.userId) {
                    item.certificates.forEach(certificate => {
                        certificate.isSelected = false;
                        if (certificate.certificateValue === param.certificate.certificateValue) {
                            certificate.isSelected = true;
                            item.certificateType = param.certificate.certificateValue;
                        }
                    })
                    item.certificateName = param.certificate.certificateText;
                    item.certificateValue = param.certificate.certificate;
                    item.changeCardvisible = false;
                }
            })
        },
        // 与出行人一致
        sameToTraveler() {
            if (this.form.userList === null || this.form.userList.length === 0) {
                this.$message.warning('请选择出行人');
                return;
            }
            this.contactIndex++

            let travelUserListLength = this.form.userList.length
            if (this.contactIndex === travelUserListLength) {
                this.contactIndex = 0
            }
            let linkmanInfo = this.form.userList[this.contactIndex]
            this.form.linkman = linkmanInfo.name || linkmanInfo.staffName
            this.form.linkmanPhone = linkmanInfo.phone
        },
        //发布整个组件的信息
        getTravelerInfo() {
            let user = {};
            let userList = [];
            let resultForm = {};
            this.form.userList.forEach(item => {
                user = {
                    certificateType: item.certificateType,
                    userId: item.userId
                }
                userList.push(user);
            });
            resultForm = {
                userList: userList,//出行人
                userIdList: this.form.userIdList,//出行人userIdList
                linkman: this.form.linkman,//联系人
                linkmanPhone: this.form.linkmanPhone,//联系人电话
                smsType: this.form.smsType,//发送行程信息到出行人手机。不发送：0；发送：1
                linkmanSms: this.form.linkmanSms//发送行程信息到联系人手机。不发送：0；发送：1
            }
            return resultForm;
        }
    }
}

import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'
import {awaitWrap} from "@/page/admin/organizational-structure/common/unit";
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client"

import consumer_admin_insurance_accreditCusList
    from "@/lib/data-service/haolv-default/consumer_admin_insurance_accreditCusList" //授权客户列表
import consumer_sys_sms_insuranceAccreditSmsImport
    from "@/lib/data-service/haolv-default/consumer_sys_sms_insuranceAccreditSmsImport" //批量发送
import consumer_admin_insurance_checkAccreditSms
    from "@/lib/data-service/haolv-default/consumer_admin_insurance_checkAccreditSms" //查看短信
import consumer_admin_insurance_sendAccreditAgain
    from "@/lib/data-service/haolv-default/consumer_admin_insurance_sendAccreditAgain" //重新发送
import consumer_admin_insurance_updateAccreditInfo
    from "@/lib/data-service/haolv-default/consumer_admin_insurance_updateAccreditInfo" //修改信息


export default {
    data() {
        var checkPhone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号码"))
            } else if (value.length !== 11) {
                callback(new Error("请输入正确的手机号码"))
            } else {
                callback()
            }
        };
        return {
            loading: false,
            fileLoading: false,
            sendDate: [],//发送日期
            searchForm: {
                name: '',//姓名
                certificateValue: '',//证件号码
                phone: '',//联系电话
                sendDateStart: '',//发送日期-开始
                sendDateEnd: '',//发送日期-结束
                accreditStatus: 0, //状态

                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            },
            form: {
                name: '',
                phone: ''
            },
            //授权状态 0-全部 1-未发送 2-发送中 3-发送失败 4-未回复 5-已授权 6-不授权 7-已过期
            tabList: [
                {
                    accreditStatus: 0,
                    accreditStatusText: '全部',
                    number: 0
                },
                {
                    accreditStatus: 1,
                    accreditStatusText: '未发送',
                    number: 0
                },
                {
                    accreditStatus: 2,
                    accreditStatusText: '发送中',
                    number: 0
                },
                {
                    accreditStatus: 3,
                    accreditStatusText: '发送失败',
                    number: 0
                },
                {
                    accreditStatus: 4,
                    accreditStatusText: '未回复',
                    number: 0
                },
                {
                    accreditStatus: 5,
                    accreditStatusText: '已授权',
                    number: 0
                },
                {
                    accreditStatus: 6,
                    accreditStatusText: '不授权',
                    value: 0
                },
                {
                    accreditStatus: 7,
                    accreditStatusText: '已过期',
                    number: 0
                },
            ],
            list: [],//授权客户列表
            totalCount: 0,   //总条数
            activeName: '0',
            changeInfoDialogVisible: false,//是否显示短信内容弹框
            smsDialogVisible: false,//是否显示短信内容弹框
            tipDialogVisible: false,//是否显示提示弹框
            smsList: null,//短信内容
            fileList: [],//导入的文件
            tipList: [],//导入返回的提示信息
            ossClient: new OssClient(),
            rules: {
                name: [
                    {required: true, message: '请填入客户姓名', trigger: 'blur'}
                ],
                phone: [
                    {validator: checkPhone, required: true, trigger: 'blur'},
                ],
            }
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    activated() {
        this.init();
    },
    methods: {
        // 初始化
        async init() {
            await this.getList();
        },

        //获取列表
        getList() {
            try {
                this.loading = true;
                return consumer_admin_insurance_accreditCusList(this.searchForm).then(res => {
                    if (res.datas) {
                        this.tabList = res.datas.countList;
                        if (res.datas.resultList) {
                            this.list = res.datas.resultList.list;
                            this.totalCount = res.datas.resultList.totalCount;
                        }
                    }
                })
            } catch (error) {
                this.list = [];
                this.totalCount = 0;
                this.loading = false
                this.$message({
                    type: "error",
                    message: "获取授权客户列表失败！"
                })
            } finally {
                this.loading = false
            }
        },

        //搜索
        onSearch() {
            this.searchForm.currentPage = 1
            this.searchForm.accreditStatus = this.activeName === '0' ? 0 : parseInt(this.activeName)
            this.getList()
        },

        //修改发送日期
        changeSendDate(val) {
            if (val) {
                this.searchForm.sendDateStart = val[0] + ' 00:00:00';
                this.searchForm.sendDateEnd = val[1] + ' 23:59:59';
            } else {
                this.searchForm.sendDateStart = '';
                this.searchForm.sendDateEnd = '';
            }
            this.onSearch();
        },

        //重置
        onReset() {
            this.searchForm = {
                name: '',//姓名
                certificateValue: '',//证件号码
                phone: '',//联系电话
                sendDateStart: '',//发送日期-开始
                sendDateEnd: '',//发送日期-结束

                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            };
            this.sendDate = [];
            this.searchForm.accreditStatus = this.activeName === '0' ? 0 : parseInt(this.activeName)
            this.onSearch()
        },

        //批量发送
        async onBatchSend(file, fileList) {
            this.fileList = [file];
            if (this.fileList.length > 0) {
                this.fileLoading = true;
                this.ossClient.multipartUpload({file: this.fileList[0].raw}).then(res => {
                    let url = res.url;
                    let fd = new FormData();
                    fd.append('file', this.fileList[0].raw);
                    fd.append('fileUrl', url);
                    fd.append('policyType', this.form.policyType);
                    fd.append('delPolicy', this.form.delPolicy);
                    consumer_sys_sms_insuranceAccreditSmsImport(fd).then((res) => {
                        if (res.msg === '导入失败') {
                            this.$message({
                                type: 'error',
                                message: '导入失败',
                            });
                        }
                        if (res.msg === '成功') {
                            this.$message({
                                type: 'success',
                                message: '导入成功',
                            })
                            this.tipList = res.datas.resultList;
                            if (this.tipList !== [] && this.tipList.length > 0) {
                                this.tipDialogVisible = true;
                            }
                        }
                    }).finally(() => {
                        this.fileList = [];
                        this.fileLoading = false;
                        this.searchForm.currentPage = 1;
                        this.getList()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'error',
                        message: '上传文件失败',
                    });
                    this.fileLoading = false;
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '未添加任何文件',
                })
            }
        },

        //查看短信
        async onCheckSms(accreditId) {
            this.smsList = [];
            let [error, res] = await awaitWrap(consumer_admin_insurance_checkAccreditSms({accreditId: accreditId}));
            if (!error) {
                this.smsList = res.datas;
                this.smsDialogVisible = true;
            }
        },

        //显示修改信息dialog
        showInfoDialog(user) {
            this.form = JSON.parse(JSON.stringify(user));
            this.changeInfoDialogVisible = true;
        },

        //修改信息
        onChangeInfo() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let param = {
                        accreditId: this.form.accreditId,
                        name: this.form.name,
                        phone: this.form.phone
                    }
                    consumer_admin_insurance_updateAccreditInfo(param).then(res => {
                        this.$message.success('修改成功');
                        this.searchForm.currentPage = 1;
                        this.getList()
                        this.changeInfoDialogVisible = false;
                    })
                }
            })
        },

        //重新发送
        async onResend(user) {
            let [error, res] = await awaitWrap(consumer_admin_insurance_sendAccreditAgain({accreditId: user.accreditId}));
            if (!error) {
                this.tabList[2].number += 1;
                let tab = this.tabList.find((item, index) => {
                    return item.accreditStatus === Number(this.activeName)
                })
                tab.number -= 1;
                let curIndex = this.list.findIndex(item => {
                    if (item.accreditId === user.accreditId) {
                        return true;
                    }
                })
                if (this.activeName !== '0') {
                    this.list.splice(curIndex, 1);
                }
                user.accreditStatus = 2;
                user.accreditStatusText = '发送中';
                this.$message.success('发送中');
            }
        }
    }
}

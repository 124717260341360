export default {
    path: '',
    component: () => import(`@/component/replace-orders-layout/index.vue`),
    children: [
        {
            path: '/admin/order-payment',
            name: 'admin-order-payment',
            // component: () => import(/* webpackChunkName: "page-admin-replace-orders-order-payment" */ `./1.0.1/index.vue`),
            component: () => import(`./1.1.0/index.vue`),
            meta: {

                entity_for_history_tag: {
                    name: `代客下单`,
                },
                entity_list_for_breadcrumb: [
                    {name: `代客下单`},
                    {name: `收银台`},
                ],
            },
        },
    ],
};

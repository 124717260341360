export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/flight-change',
            name: 'admin-flight-change',
            component: () => import(/* webpackChunkName: "page-admin-order-management-flight-order-change" */ `./1.0.2/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `机票改签申请`,
                },
                entity_list_for_breadcrumb: [
                    {name: `机票订单管理`},
                    {name: `机票改签申请`},
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        return `admin-flight-order-list`;
                    },
                },
            },
        },
    ],
};

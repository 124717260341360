// 【396】获取会员手机号列表
// POST /consumer/t-od-hotel-member-phone/list
// 接口ID：49210363
// 接口地址：https://www.apifox.cn/web/project/458418/apis/api-49210363

const __request = require(`./__request/__request_contentType_json`)
const consumer_t_od_hotel_member_phone_list = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/t-od-hotel-member-phone/list',
    data: data
  }
  return __request(pParameter)
}
export default consumer_t_od_hotel_member_phone_list

const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 差旅平台获取授权管理商户详情接口文档地址：http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-company-credit-controller/platformCreditDetailUsingPOST
const consumer_credit_platformCreditDetail = (pParameter) => {
    if (!pParameter) pParameter = {};

    const p = {
        method: 'post',
        urlSuffix: '/consumer/credit/platformCreditDetail',
        data: pParameter,
    };
    return Q.when()
        .then(function () {
            return __request(p);
        })
        .then(function (data) {
            return Q.resolve(data);
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};
module.exports = consumer_credit_platformCreditDetail;

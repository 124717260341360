const _ = require(`underscore`);

const __all_menu_list = [
    //<editor-fold desc="示例功能">
    {
        id: `0`,
        parent_id: ``,
        title: `示例功能`,
        route_name: ``,
        level: 1,
    },
    {
        id: `0.1`,
        parent_id: `0`,
        title: `主页`,
        route_name: `admin-home`,
        level: 2,
    },
    {
        id: `0.2`,
        parent_id: `0`,
        title: `桌面`,
        route_name: `admin-desktop`,
        level: 2,
    },
    {
        id: `0.3`,
        parent_id: `0`,
        title: `通讯录`,
        route_name: `admin-contacts`,
        level: 2,
    },
    {
        id: `0.4`,
        parent_id: `0`,
        title: `示例数据`,
        route_name: `admin-sample-data-list`,
        level: 2,
    },
    {
        id: `0.5`,
        parent_id: `0`,
        title: `v-model-test`,
        route_name: `admin-v-model-test`,
        level: 2,
    },
    {
        id: `0.6`,
        parent_id: `0`,
        title: `v-for-test`,
        route_name: `admin-v-for-test`,
        level: 2,
    },
    {
        id: `0.7`,
        parent_id: `0`,
        title: `vuex-test`,
        route_name: `admin-vuex-test`,
        level: 2,
    },
    {
        id: `0.8`,
        parent_id: `0`,
        title: `三级菜单示例`,
        route_name: ``,
        level: 2,
    },
    {
        id: `0.8.1`,
        parent_id: `0.8`,
        title: `3.1`,
        route_name: `admin-multi-level-menu-test-3-1`,
        level: 3,
    },
    {
        id: `0.8.2`,
        parent_id: `0.8`,
        title: `3.2`,
        route_name: `admin-multi-level-menu-test-3-2`,
        level: 3,
    },
    {
        id: `0.9`,
        parent_id: `0`,
        title: `global-query-test`,
        route_name: `admin-global-query-test-page-001`,
        level: 2,
    },
    //</editor-fold>

    {
        id: `11`,
        parent_id: ``,
        title: `首页`,
        route_name: ``,
        level: 1,
    },
    {
        id: `11.1`,
        parent_id: `11`,
        title: `首页`,
        route_name: `admin-my-home`,
        level: 2,
    },

    //<editor-fold desc="客户管理">
    {
        id: `1`,
        parent_id: ``,
        title: `客户管理`,
        route_name: ``,
        level: 1,
    },
    {
        id: `1.1`,
        parent_id: `1`,
        title: `添加客户`,
        route_name: `admin-add-users`,
        level: 2,
    },
    {
        id: `1.2`,
        parent_id: `1`,
        title: `客户审核列表`,
        route_name: `admin-users-audit-list`,
        level: 2,
    },
    {
        id: `1.3`,
        parent_id: `1`,
        title: `客户管理列表`,
        route_name: `admin-users-management-list`,
        level: 2,
    },
    {
        id: `2`,
        parent_id: ``,
        title: `授信管理`,
        route_name: ``,
        level: 1,
    },
    {
        id: `2.1`,
        parent_id: `2`,
        title: `授信商户列表`,
        route_name: `admin-credit-users-list`,
        level: 2,
    },
    {
        id: `2.2`,
        parent_id: `2`,
        title: `商户提款管理`,
        route_name: `admin-business-drawing-list`,
        level: 2,
    },
    {
        id: `2.3`,
        parent_id: `2`,
        title: `授信审核列表`,
        route_name: `admin-credit-approve-list`,
        level: 2,
    },
    {
        id: `2.4`,
        parent_id: `2`,
        title: `开通授信列表`,
        route_name: `admin-oppen-credit-list`,
        level: 2,
    },
    {
        id: `4`,
        parent_id: ``,
        title: `代客下单`,
        route_name: ``,
        level: 1,
    },
    {
        id: `4.1`,
        parent_id: `4`,
        title: `代客下单`,
        route_name: `admin-replace-orders`,
        level: 2,
    },
    {
        id: `4.2`,
        parent_id: `4`,
        title: `常用旅客`,
        route_name: `admin-often-traveller`,
        level: 2,
    },
    {
        id: `3`,
        parent_id: ``,
        title: `信息维护`,
        route_name: ``,
        level: 1,
    },
    {
        id: `3.1`,
        parent_id: `3`,
        title: `航司信息`,
        route_name: `admin-flight-info`,
        level: 2,
    },
    {
        id: `5`,
        parent_id: ``,
        title: `酒店管理`,
        route_name: ``,
        level: 1,
    },
    {
        id: `5.1`,
        parent_id: `5`,
        title: `已销售的酒店订单`,
        route_name: `admin-hotel-order-sold`,
        level: 2,
    },
    {
        id: `5.2`,
        parent_id: `5`,
        title: `退订订单`,
        route_name: `admin-hotel-order-cancel`,
        level: 2,
    },
    {
        id: `6`,
        parent_id: ``,
        title: `机票管理`,
        route_name: ``,
        level: 1,
    },
    {
        id: `6.1`,
        parent_id: `6`,
        title: `已销售的机票订单`,
        route_name: `admin-flight-order-list`,
        level: 2,
    },
    {
        id: `6.2`,
        parent_id: `6`,
        title: `改签订单`,
        route_name: `admin-flight-change-list`,
        level: 2,
    },
    {
        id: `6.3`,
        parent_id: `6`,
        title: `退票订单`,
        route_name: `admin-flight-refund-list`,
        level: 2,
    },
    {
        id: `6.4`,
        parent_id: `6`,
        title: `退款订单`,
        route_name: `admin-flight-refund-form-list`,
        level: 2,
    },
    {
        id: `7`,
        parent_id: ``,
        title: `火车票管理`,
        route_name: ``,
        level: 1,
    },
    {
        id: `7.1`,
        parent_id: `7`,
        title: `已销售的火车票`,
        route_name: `admin-train-order-list`,
        level: 2,
    },
    {
        id: `7.2`,
        parent_id: `7`,
        title: `改签订单`,
        route_name: `admin-train-change-order-list`,
        level: 2,
    },
    {
        id: `7.3`,
        parent_id: `7`,
        title: `退款订单`,
        route_name: `admin-train-unsubscribe-list`,
        level: 2,
    },
    {
        id: `8`,
        parent_id: ``,
        title: `运营报表`,
        route_name: ``,
        level: 1,
    },
    {
        id: `8.1`,
        parent_id: `8`,
        title: `机票对账报表`,
        route_name: `admin-flight-bill-report`,
        level: 2,
    },
    {
        id: `8.2`,
        parent_id: `8`,
        title: `酒店对账报表`,
        route_name: `admin-hotel-bill-report`,
        level: 2,
    },
    {
        id: `8.3`,
        parent_id: `8`,
        title: `火车票对账报表`,
        route_name: `admin-train-bill-report`,
        level: 2,
    },


    {
        id: `9`,
        parent_id: ``,
        title: `组织构架`,
        route_name: ``,
        level: 1,
    },
    {
        id: `9.1`,
        parent_id: `9`,
        title: `部门管理`,
        route_name: `admin-department-management`,
        level: 2,
    },
    {
        id: `9.2`,
        parent_id: `9`,
        title: `员工管理`,
        route_name: `admin-staff-management`,
        level: 2,
    },
    {
        id: `9.3`,
        parent_id: `9`,
        title: `角色权限`,
        route_name: `admin-role-authority`,
        level: 2,
    },

    {
        id: `10`,
        parent_id: ``,
        title: `通知公告`,
        route_name: ``,
        level: 1,
    },
    {
        id: `10.1`,
        parent_id: `10`,
        title: `公告列表`,
        route_name: `admin-notice-list`,
        level: 2,
    },
    {
        id: `11`,
        parent_id: ``,
        title: `版本管理`,
        route_name: ``,
        level: 1,
    },
    {
        id: `11.1`,
        parent_id: `11`,
        title: `版本管理列表`,
        route_name: `admin-versioning-list`,
        level: 2,
    },
    {
        id: `12`,
        parent_id: ``,
        title: `线下对账和开票`,
        route_name: ``,
        level: 1,
    },
    {
        id: `12.1`,
        parent_id: `12`,
        title: `开票对账列表`,
        route_name: `admin-agricultural-invoicing-reconciliation`,
        level: 2,
    },
    {
        id: `12.2`,
        parent_id: `12`,
        title: `发票邮寄管理`,
        route_name: `admin-invoice-mailing-management`,
        level: 2,
    },
    //</editor-fold>
];

const all_menu_list = _.chain(__all_menu_list)
    .filter(function (m) {
        return 1 === 1
            && m.id !== `0`
            && m.parent_id !== `0`
    })
    .value()
    ;

module.exports = all_menu_list;

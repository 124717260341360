
// 【276】查询酒店信息 https://api-beta.wholeok.com/doc.html#/yinzhilv-tmc/API/getHotelStaticInfoResultUsingPOST
const __request = require(`./__request/__request_contentType_json`)
const consumer_hotel_search_getHotelStaticInfoResult = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/hotel/search/getHotelStaticInfoResult',
        data: data
    }
    return __request(pParameter)
}
export default consumer_hotel_search_getHotelStaticInfoResult

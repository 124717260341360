// report_tRpTmcFlightItinerary_v1_importUpd
const __request = require(`./__request/__request_contentType_json`)
const report_tRpTmcFlightItinerary_v1_importUpd = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/tRpTmcFlightItinerary/v1/importUpd',
        //urlSuffix: '/report//tRpTmcFlightItinerary/v1/importUpd',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': '*/*'
        },
        enable_error_alert: false // 临时隐藏
    }
    return __request(pParameter)
}
export default report_tRpTmcFlightItinerary_v1_importUpd
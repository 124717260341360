export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/ticket-order/list',
            name: 'admin-ticket-order-list',
            component: () => import(/* webpackChunkName: "page-admin-order-management-ticket-order-list" */ `./1.0.0/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `门票/船票订单`,
                },
                entity_list_for_breadcrumb: [
                    { name: `门票/船票管理` },
                    { name: `门票/船票订单` },
                ],
            },
        },
    ],
};

import consumer_admin_car_order_toRefundOrderList
    from '@/lib/data-service/haolv-default/consumer_admin_car_order_toRefundOrderList'
import consumer_admin_car_order_toRefundCarOrder
    from '@/lib/data-service/haolv-default/consumer_admin_car_order_toRefundCarOrder'
import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'

export default {
    data() {
        return {
            loading: false,
            searchForm: {
                orderNo: '',//订单号
                belongCompany: '',//所属企业
                startDate: '',//开始日期
                endDate: '',//结束日期
                orderTimeArr: '',//开始-结束日期
                currentPage: 1,//查询页码
                pageSize: 10,//查询条数
                total: 0,
            },
            list: [],
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    activated() {
        this.reset()
    },
    methods: {
        //搜索
        search() {
            this.searchForm.currentPage = 1
            this.searchForm.orderStatus = this.activeName === '0' ? '' : this.activeName
            this.getList()
        },
        //重置
        reset() {
            let searchForm = {
                orderNo: '',//订单号
                belongCompany: '',//所属企业
                startDate: '',//开始日期
                endDate: '',//结束日期
                orderTimeArr: '',//开始-结束日期
                currentPage: 1,//查询页码
                pageSize: 10,//查询条数
                total: 0,
            }
            this.searchForm = searchForm
            this.search()
        },
        //改变搜索条件
        handleClick(val) {
            this.search()
        },
        //搜索日期改变时
        changeOrderTimeArr(val) {
            if (val === '' || val === null) {
                this.searchForm.startDate = ''
                this.searchForm.endDate = ''
            } else {
                this.searchForm.startDate = val[0]
                this.searchForm.endDate = val[1]
            }
        },
        //获取列表
        getList() {
            this.loading = true
            this.list = []
            consumer_admin_car_order_toRefundOrderList(this.searchForm).then(res => {
                this.loading = false
                this.list = res.datas.toRefundOrderList.list
                this.searchForm.total = res.datas.toRefundOrderList.totalCount
            }).catch(() => {
                this.loading = false
                this.list = []
                this.searchForm.total = 0
            })
        },
        //进入详情页
        handleToDetail(val) {
            return {
                name: 'admin-car-order-detail',
                query: {
                    orderNo: val.orderNo
                }
            }
        },
        //退款
        handleToRefund(orderNo) {
            let loading;
            this.$confirm('是否确认对当前订单进行授信退款', '授信退款处理', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                return consumer_admin_car_order_toRefundCarOrder({orderNo: orderNo});
            }).then((res) => {
                loading.close();
                if (res.code === '000000') {
                    this.$message({
                        type: 'success',
                        message: '退款成功！'
                    })
                    this.reset();
                }
            }).catch(() => {
                loading.close();
            })
        }
    }
}
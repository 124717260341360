// 角色列表
import consumer_t_od_hotel_member_phone_list
    from '@/lib/data-service/haolv-default/consumer_t_od_hotel_member_phone_list';
// 删除角色
import consumer_t_od_hotel_member_phone_remove
    from '@/lib/data-service/haolv-default/consumer_t_od_hotel_member_phone_remove';

export default {
    data() {
        return {
            loading: false,
            list: [],
        };
    },
    methods: {
        //获取列表数据
        async getList() {
            this.loading = true;
            let res = await consumer_t_od_hotel_member_phone_list();
            this.list = res.pageResult.pageData;
            this.loading = false;
        },

        //删除账号
        delAccount(account) {
            let _this = this;
            this.$confirm("确定要删除"+account.memberPhone+"吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.loading = true;
                return consumer_t_od_hotel_member_phone_remove({id: account.id})
            }).then(() => {
                _this.getList();
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        }
    },
    activated() {
        this.getList();
    },
};

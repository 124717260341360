import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'
import moment from 'moment'
import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'
import consumer_admin_flight_order_getList from '@/lib/data-service/haolv-default/consumer_admin_flight_order_getList'   //admin-机票订单列表
import consumer_admin_flight_order_cancel from '@/lib/data-service/haolv-default/consumer_admin_flight_order_cancel'   //admin-机票取消订单
import consumer_admin_flight_generate_orderSource from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_orderSource'
import consumer_admin_flight_order_abnormalOrder from '@/lib/data-service/haolv-default/consumer_admin_flight_order_abnormalOrder'
import consumer_admin_flight_copyMessageTemplate from '@/lib/data-service/haolv-default/consumer_admin_flight_copyMessageTemplate'
import CopyReportPopup from '@/component/copyReportPopup/import_latest_version_module'

export default {
  data() {
    return {
      orderTimeArr: [], //下单日期--合集
      searchForm: {
        orderNo: '',   //订单号
        ticketNumber: '',   //票号
        psgName: '',   //乘机人
        cardNo: '',   //证件号码
        supplierOrderNo: '',   //胤之旅订单编号
        orderTime: '',   //下单时间
        orderStartTime: '',   //下单开始时间
        orderEndTime: '',   //下单结束时间
        companyName: '',   //所属企业
        evectionType: '',   //出差单类型：1.个人预定，2.因公出差申请，3.因公-个人出差申请
        segmentType: '',   //航程类型：1单程 2往返 3多程
        orderSource: '',   //订单来源 1、线上订单 2、代客下单 3、线下外采 4、黑屏订单
        orderStatus: 0,   //订单状态
        platformName: '',
        policyType: 0,
        flightNo: '',
        depDate: '',
        operatorName: '',
        tagId: '',
        sourceType: 0, // 国内国际标识 0-全部 1-国内 2-国际
        currentPage: 1,   //当前页码
        pageSize: 10   //每页记录条数
      },
      totalCount: 0,   //总条数
      sourceTypeList: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '国内'
        },
        {
          value: 2,
          label: '国际'
        }
      ],
      evectionTypeOption: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 2,
          label: '因公'
        },
        {
          value: 1,
          label: '因私'
        }
      ],
      segmentTypeOption: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '单程'
        },
        {
          value: 2,
          label: '往返'
        },
        {
          value: 3,
          label: '多程'
        }
      ],
      behalfOption: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '是'
        },
        {
          value: 0,
          label: '否'
        }
      ],

      activeName: '-1',
      tabList: [
        {
          name: -1,
          label: '全部订单',
          value: 0
        },
        {
          name: 11,
          label: '待支付',
          value: 0
        },
        {
          name: 21,
          label: '待出票',
          value: 0
        },
        {
          name: 22,
          label: '已出票',
          value: 0
        },
        {
          name: 24,
          label: '部分出票成功',
          value: 0
        },
        {
          name: 23,
          label: '出票失败',
          value: 0
        },
        {
          name: 90,
          label: '已取消',
          value: 0
        }
      ],
      tabNumberList: [],
      tableLoading: false,
      orderList: [],
      moment: moment,
      policyTypeList: [
        // 0=全部，1=自营 2=供应商
        {value: 0, label: '全部'},
        {value: 1, label: '自营'},
        {value: 2, label: '供应商'},
      ],
      orderSourceList: [],
      tagNamesList: [
        {value: '', label: '全部'},
        {value: 1, label: 'VP'},
      ],

      copyDialog: false,
      copyLoading: false,
      copyTextarea: '',
    }
  },
  components: {
    SearchBox,
    Pagination,
    RouterLinkButton,
    CopyReportPopup
  },
  created() {
    this.getOrderSource()
    //this.getOrderList()
  },
  mounted () {},
  activated () {
    // if (this.$route.query.refresh === true) {
    //   this.getOrderList()
    // }
    this.activeName = this.$route.query && this.$route.query.activeName ? this.$route.query.activeName : '-1';
    this.searchForm.orderStatus = this.activeName === '-1' ? 0 : parseInt(this.activeName)
    this.getOrderList()
  },
  deactivated() {},
  destroyed () {},
  methods: {
    //admin-机票订单列表
    async getOrderList() {
      try {
        this.tableLoading = true
        //this.tabList = []
        const res = await consumer_admin_flight_order_getList(this.searchForm)
        this.tabNumberList = res.flightOrderNum
        this.tabList.forEach(tab => {
          tab.value = 0
          this.tabNumberList.forEach(tabNumber => {
            if (tab.name === tabNumber.status) {
              tab.value = tabNumber.orderNum
            }
          })
        })
        this.orderList =res.pageResult.pageData.map(row => {
          return {
            ...row,
            showMoreActions: false, // 初始化为 false
          }; });
        this.totalCount = res.pageResult.totalCount
        this.$nextTick(() => {
          this.$refs.listTable.doLayout();
        })
      } catch (error) {
        this.orderList = []
        this.totalCount = 0
        // this.$message({
        //   type: "error",
        //   message: "获取机票订单列表失败！"
        // })
      } finally {
        this.tableLoading = false
      }
    },
    //搜索
    onSearch() {
      this.searchForm.currentPage = 1
      this.searchForm.orderStatus = this.activeName === '-1' ? 0 : parseInt(this.activeName)
      this.getOrderList()
    },
    //重置
    onReset() {
      this.orderTimeArr= [], //下单日期--合集
      this.searchForm = {
        orderNo: '',   //订单号
        ticketNumber: '',   //票号
        psgName: '',   //乘机人
        cardNo: '',   //证件号码
        supplierOrderNo: '',   //胤之旅订单编号
        orderTime: '',   //下单时间
        orderStartTime: '',   //下单开始时间
        orderEndTime: '',   //下单结束时间
        companyName: '',   //所属企业
        evectionType: '',   //出差单类型：1.个人预定，2.因公出差申请，3.因公-个人出差申请
        segmentType: '',   //航程类型：1单程 2往返 3多程
        orderSource: '',   //订单来源 1、线上订单 2、代客下单 3、线下外采 4、黑屏订单
        orderStatus: 0,   //订单状态
        platformName: '',
        policyType: 0,
        flightNo: '',
        depDate: '',
        operatorName: '',
        tagId: '',
        sourceType: 0,

        currentPage: 1,   //当前页码
        pageSize: 10   //每页记录条数
      }
      this.onSearch()
    },
    getOrderSource() {
      consumer_admin_flight_generate_orderSource().then(res => {
        let orderSourceList = res.datas
        orderSourceList.unshift({
          msg: '全部',
          code: ''
        })
        this.orderSourceList = orderSourceList
      })
    },
    getPolicyTypeTextToClass(val) {
      //政策类型文本
      // switch (val) {
      //   case ('大客户'):
      //     return 'red'
      //   case ('自营'):
      //     return 'green'
      //   case ('供应商'):
      //     return 'blue'
      //   default: return ''
      // }
      //"supplier": "1,2,3,4,12,13,9,OPL9,SGL1,OPL6,OPL11,OPL18,OPL17,OPL20,OPL16,OPL26,OPL23,OPL30,60,FD,NFD,VARI",
      //"supplierText": "其他渠道(供应商)【平台优选】",
      // "bigCustomers": "QCYX",
      //"bigCustomersText": "企业协议(大客户)",
      //"lowPrice": "41,42,43,44,45,26,YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL1,NSTD,62",
      //"lowPriceText": "低价推荐",
      // "website": "21,22,23,24,25,6,CXF1,WS",
      //"websiteText": "航司官网(官网)",
      //"platformProprietary": "61",
      //"platformProprietaryText": "商旅优选(自营)【商旅推荐】"
      /*const btoList = ['1','2','3','4','12','13','9','OPL9','OPL6','OPL11','OPL18','OPL20','OPL16','60','FD','NFD','VARI'];
      const lprList = ['YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL13','OPL14','OPL34','OPL1','SGL1','OPL26','OPL30'];
      const owList = ['21','22','23','24','25','31','6','CXF1','WS','GW311','GW312','OPL17','OPL23'];
      const qcList = ['QCYX'];
      const ptList = ['61'];
      if (btoList.indexOf(val) > -1) {
        return 'type1'
      } else if (lprList.indexOf(val) > -1) {
        return 'type3'
      } else if (owList.indexOf(val) > -1) {
        return 'type4'
      } else if (qcList.indexOf(val) > -1) {
        return 'type2'
      } else if (ptList.indexOf(val) > -1) {
        return 'type5'
      } else {
        return 'type6'
      }*/
      if (val === 1) {
        return 'type1'
      } else if (val === 2) {
        return 'type4'
      } else if (val === 3) {
        return 'type2'
      } else if (val === 4) {
        return 'type5'
      } else if (val === 5) {
        return 'type8'
      } else {
        return 'type6'
      }
    },
    getEvectionTypeToClass(val) {
      //出差单类型：1.因私，2.因公
      switch (val) {
        case (1):
          return 'blue'
        case (2):
          return 'orange'
        default: return ''
      }
    },
    //查看详情
    handleDetailClick(val) {
      this.$router.push({
        name: "admin-flight-order-detail",
        query: {
          orderNo: val.orderNo
        },
      })
    },
    //去支付
    handlePayClick(val) {
      // 判断是线上还是线下
      if (val.orderSource === '1' || val.orderSource === '2') {
        // 正常支付
        this.$router.push({
          // name: "admin-order-payment",
          name: "admin-flight-payment",
          query: {
            orderNo: val.orderNo,
            orderType: 1,
            pageType: 2,
            behalfCompanyId: val.companyId
          }
        })
      } else {
        // 线下单支付
        this.$router.push({
          name: "admin-flight-create-order-pay",
          query: {
            orderNo: val.orderNo,
            behalfCompanyId: val.companyId
          }
        })
      }
    },
    // 转异常
    handleReturn(val) {
      this.$confirm("是否确认转成异常订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        consumer_admin_flight_order_abnormalOrder({
          orderNo: val.orderNo,
        }).then(() => {
          this.$message({
            type: 'success',
            message: '转异常成功！'
          })
          this.getOrderList()
        }).catch(() => { })

      }).catch(() => { })
    },
    //取消订单
    handleCancelClick(val) {
      this.$confirm("是否确认取消订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        consumer_admin_flight_order_cancel({
          orderNo: val.orderNo,
          supplierOrderNo: val.supplierOrderNo
        }).then(() => {
          this.$message({
            type: 'success',
            message: '取消订单成功！'
          })
          this.getOrderList()
        }).catch(() => { })

      }).catch(() => { })
    },
    //改签
    handleChangeClick(val) {
      //线下订单--3、4
      if (val.orderSource == 3 || val.orderSource == 4) {
        // admin-flight-change-offline
        this.$router.push({
          name: "admin-flight-change-offline",
          query: {
            orderNo: val.orderNo,
          }
        })
      } else {
        this.$router.push({
          name: "admin-flight-change",
          query: {
            orderNo: val.orderNo,
            evectionNo: val.evectionNo,
            evectionType: 1
          }
        })
      }
    },
    //退票
    handleRefundClick(val) {
      if (val.orderSource === '1' || val.orderSource === '2') {
        this.$router.push({
          name: "admin-flight-refund",
          query: {
            orderNo: val.orderNo,
            orderStatus: 1
          }
        })
      } else {
        this.$router.push({
          name: "admin-flight-offline-refund-handle",
          query: {
            orderNo: val.orderNo
          }
        })
      }
    },
    handleEditClick(val) {
      this.$router.push({
        name: "admin-flight-edit-order",
        query: {
          orderNo: val.orderNo,
          type: 'edit',
          orderSource: val.orderSource,
        }
      })
    },
    // 去确认
    handleSubmitClick(val) {
      this.$router.push({
        name: "admin-flight-edit-submit-order",
        query: {
          orderNo: val.orderNo,
          type: 'submit'
        }
      })
    },
    toCreate() {
      this.$router.push({
        name: 'admin-flight-create-order'
      })
    },
    toDetail(val) {
      /*let routeName = ''
      if (val.orderSource === '3' || val.orderSource === '4') {
      } else {
      }*/
      return {name: 'admin-flight-order-detail', query: {orderNo: val.orderNo, orderSource: val.orderSource}}
    },
    handleMessage(val) {
      this.copyDialog = true;
      this.copyLoading = true;
      this.copyTextarea = '';
      // 调接口
      consumer_admin_flight_copyMessageTemplate({orderNo: val.orderNo}).then(res => {
        const datas = res.datas;
        let message = '';
        if (val.flightType === 1 || val.orderSource === '3' || val.orderSource === '4') {
          //单程、线下外采、黑屏订单 - start
          message += `出票成功：\n`;
          datas.segList.forEach((value, index) => {
            message += `${datas.segList.length > 1 ? '第' + (index + 1) + '程：' : ''}${value.depDate} ${value.flightNo}  ${value.depCityName}（${value.depAirportName}${value.depTerminal || ''}）-${value.arrCityName}（${value.arrAirportName}${value.arrTerminal || ''}） ${value.depTime}起飞-${value.arrTime}${value.transDay ? '+1' : ''}到达`;
            if (value.stopInfoCity) {
              message += `(经停${value.stopInfoCity}${value.stopInfoTime}min)\n`;
            } else {
              message += `\n`;
            }
          })
          if (val.flightType === 1 && datas.sourceType === 1 && val.companyId === 328) {
            datas.segList[0].psgList.forEach(value => {
              message += `乘机人：${value.psgName}(证件号码:${value.cardNo})\n`;
              message += `票号：${value.ticketNum}\n`;
            })
            message += `舱位：${datas.segList[0].cabinRank}\n最终结算价：${datas.segList[0].amount}\n温馨提示：请乘机人持有效身份证件，提前 90 分钟登机，收到航班变动信息，请勿随意点击链接，如需帮助，请致电:400-626-8448!`;
          } else {
            datas.psgList.forEach(value => {
              message += `乘机人：${value.psgName}\n`;
              message += `票号：${value.ticketNum}\n`;
            });
            if (datas.sourceType === 2) {
              if (val.companyId === 310) {
                message += `舱位：\n`;
                message += `行李额：\n`;
                message += `退改条款：\n`;
              }
              message += `温馨提示：\n持商务、旅游签、免签的旅客，建议同时购买返程票或第三地机票；\n护照有效期不得少于六个月（由回程日期开始计算）以免无法办理乘机和出境手续；\n建议航班起飞前2~3小时到达机场办理登机手续；\n关注当地出行政策，如收到航班变动信息，请勿随意点击链接，如有疑问请随时联系客服了解。`;
            } else {
              message += `温馨提示：请乘机人持有效身份证件，最少提前2小时到达机场办理登机手续。如收到航班变动信息，请勿随意点击链接，有任何疑问请随时联系客服了解。\n`;
            }
          }
          //单程、线下外采、黑屏订单 - end
        } else {
          //线上往返消息模板-start
          let depValue = datas.segList[0];
          let reValue = datas.segList[1];
          if (val.orderStatus === '22') {
            //出票成功-start
            message += `出票成功：\n`;
            message += `去程：${depValue.depDate} ${depValue.flightNo}  ${depValue.depCityName}（${depValue.depAirportName}${depValue.depTerminal || ''}）- ${depValue.arrCityName}（${depValue.arrAirportName}${depValue.arrTerminal || ''}） ${depValue.depTime}-${depValue.arrTime}${depValue.transDay ? '+1' : ''}`;
            if (depValue.stopInfoCity) {
              message += `(经停${depValue.stopInfoCity}${depValue.stopInfoTime}min)\n`;
            } else {
              message += `\n`;
            }
            depValue.psgList.forEach(value => {
              message += `乘机人：${value.psgName}\n`;
              message += `票号：${value.ticketNum}\n`;
            })
            message += `\n`;
            message += `返程：${reValue.depDate} ${reValue.flightNo}  ${reValue.depCityName}（${reValue.depAirportName}${reValue.depTerminal || ''}）- ${reValue.arrCityName}（${reValue.arrAirportName}${reValue.arrTerminal || ''}） ${reValue.depTime}-${reValue.arrTime}${reValue.transDay ? '+1' : ''}`;
            if (reValue.stopInfoCity) {
              message += `(经停${reValue.stopInfoCity}${reValue.stopInfoTime}min)\n`;
            } else {
              message += `\n`;
            }
            reValue.psgList.forEach(value => {
              message += `乘机人：${value.psgName}\n`;
              message += `票号：${value.ticketNum}\n`;
            })
            if (datas.sourceType === 2) {
              if (val.companyId === 310) {
                message += `舱位：\n`;
                message += `行李额：\n`;
                message += `退改条款：\n`;
              }
              message += `温馨提示：\n持商务、旅游签、免签的旅客，建议同时购买返程票或第三地机票；\n护照有效期不得少于六个月（由回程日期开始计算）以免无法办理乘机和出境手续；\n建议航班起飞前2~3小时到达机场办理登机手续；\n关注当地出行政策，如收到航班变动信息，请勿随意点击链接，如有疑问请随时联系客服了解。`;
            } else {
              message += `温馨提示：请乘机人持有效身份证件，最少提前2小时到达机场办理登机手续。如收到航班变动信息，请勿随意点击链接，有任何疑问请随时联系客服了解。\n`;
            }
            //出票成功-end
          } else if (val.orderStatus === '24') {
            if (datas.segList[0].ticketStatus === 22 && datas.segList[1].ticketStatus === 23) {
              //去程出票成功，返程出票失败 - strat
              message += `去程出票成功，返程出票失败：\n`;
              message += `去程：${depValue.depDate} ${depValue.flightNo}  ${depValue.depCityName}（${depValue.depAirportName}${depValue.depTerminal || ''}）- ${depValue.arrCityName}（${depValue.arrAirportName}${depValue.arrTerminal || ''}） ${depValue.depTime}-${depValue.arrTime}${depValue.transDay ? '+1' : ''}`;
              if (depValue.stopInfoCity) {
                message += `(经停${depValue.stopInfoCity}${depValue.stopInfoTime}min)\n`;
              } else {
                message += `\n`;
              }
              depValue.psgList.forEach(value => {
                message += `乘机人：${value.psgName}\n`;
                message += `票号：${value.ticketNum}\n`;
              })
              message += `\n`;
              message += `返程：${reValue.depDate} ${reValue.flightNo}  ${reValue.depCityName}（${reValue.depAirportName}${reValue.depTerminal || ''}）- ${reValue.arrCityName}（${reValue.arrAirportName}${reValue.arrTerminal || ''}） ${reValue.depTime}-${reValue.arrTime}${reValue.transDay ? '+1' : ''}`;
              if (reValue.stopInfoCity) {
                message += `(经停${reValue.stopInfoCity}${reValue.stopInfoTime}min)\n`;
              } else {
                message += `\n`;
              }
              message += `乘机人：`;
              reValue.psgList.forEach((value, index) => {
                message += `${value.psgName}`;
                if (index < reValue.psgList.length - 1) {
                  message += `、`;
                }
              });
              message += `温馨提示：此往返票为组合产品，如遇其中一程航班调整，您需自行办理另一程退改事宜并承担相应费用。\n`;
              if (datas.sourceType === 2) {
                if (val.companyId === 310) {
                  message += `舱位：\n`;
                  message += `行李额：\n`;
                  message += `退改条款：\n`;
                }
                message += `温馨提示：\n持商务、旅游签、免签的旅客，建议同时购买返程票或第三地机票；\n护照有效期不得少于六个月（由回程日期开始计算）以免无法办理乘机和出境手续；\n建议航班起飞前2~3小时到达机场办理登机手续；\n关注当地出行政策，如收到航班变动信息，请勿随意点击链接，如有疑问请随时联系客服了解。`;
              } else {
                message += `温馨提示：请乘机人持有效身份证件，最少提前2小时到达机场办理登机手续。如收到航班变动信息，请勿随意点击链接，有任何疑问请随时联系客服了解。\n`;
              }
              //去程出票成功，返程出票失败 - end
            } else if (datas.segList[0].ticketStatus === 23 && datas.segList[1].ticketStatus === 22) {
              // //去程出票失败，返程出票成功 - start
              message += `去程出票失败，返程出票成功：\n`;
              message += `去程：${depValue.depDate} ${depValue.flightNo}  ${depValue.depCityName}（${depValue.depAirportName}${depValue.depTerminal || ''}）- ${depValue.arrCityName}（${depValue.arrAirportName}${depValue.arrTerminal || ''}） ${depValue.depTime}-${depValue.arrTime}${depValue.transDay ? '+1' : ''}`;
              if (depValue.stopInfoCity) {
                message += `(经停${depValue.stopInfoCity}${depValue.stopInfoTime}min)\n`;
              } else {
                message += `\n`;
              }
              message += `乘机人：`;
              depValue.psgList.forEach((value, index) => {
                message += `${value.psgName}`;
                if (index < depValue.psgList.length - 1) {
                  message += `、`;
                }
              })
              message += `\n出票失败请重新预订\n\n`;
              message += `返程：${reValue.depDate} ${reValue.flightNo}  ${reValue.depCityName}（${reValue.depAirportName}${reValue.depTerminal || ''}）- ${reValue.arrCityName}（${reValue.arrAirportName}${reValue.arrTerminal || ''}） ${reValue.depTime}-${reValue.arrTime}${reValue.transDay ? '+1' : ''}`;
              if (reValue.stopInfoCity) {
                message += `(经停${reValue.stopInfoCity}${reValue.stopInfoTime}min)\n`;
              } else {
                message += `\n`;
              }
              reValue.psgList.forEach(value => {
                message += `乘机人：${value.psgName}\n`;
                message += `票号：${value.ticketNum}\n`;
              })
              message += `温馨提示：此往返票为组合产品，如遇其中一程航班调整，您需自行办理另一程退改事宜并承担相应费用。\n`;
              if (datas.sourceType === 2) {
                if (val.companyId === 310) {
                  message += `舱位：\n`;
                  message += `行李额：\n`;
                  message += `退改条款：\n`;
                }
                message += `温馨提示：\n持商务、旅游签、免签的旅客，建议同时购买返程票或第三地机票；\n护照有效期不得少于六个月（由回程日期开始计算）以免无法办理乘机和出境手续；\n建议航班起飞前2~3小时到达机场办理登机手续；\n关注当地出行政策，如收到航班变动信息，请勿随意点击链接，如有疑问请随时联系客服了解。`;
              } else {
                message += `温馨提示：请乘机人持有效身份证件，最少提前2小时到达机场办理登机手续。如收到航班变动信息，请勿随意点击链接，有任何疑问请随时联系客服了解。\n`;
              }
              //去程出票失败，返程出票成功 - end
            }
          }
          //线上往返消息模板-end
        }
        this.copyTextarea = message
      }).finally(() => {
        this.copyLoading = false;
      })
    },
    handleCopy(val) {
      this.$router.push({
        name: "admin-flight-edit-order",
        query: {
          orderNo: val.orderNo,
          type: 'copy',
          orderSource: val.orderSource,
        }
      })
    },
    changeCreateTime(val) {
      if(!val) {
        this.searchForm.orderStartTime = 0;
        this.searchForm.orderEndTime = 0;
      } else {
        this.searchForm.orderStartTime = val[0] + ' 00:00:00';
        this.searchForm.orderEndTime = val[1] + ' 23:59:59';
      }
    }
  }
}

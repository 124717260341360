import Q from 'q';
import consumer_notice_list from "@/lib/data-service/haolv-default/consumer_notice_list.js";
import consumer_notice_removeById from "@/lib/data-service/haolv-default/consumer_notice_removeById.js";

export default {
    data() {
        return {
            loading: true,
            pager: {
                pageSizes: [10, 15, 20, 25, 30],
                totalCount: 0,
            },
            list: [],
            form: {
                currentPage: 1,
                pageSize: 10,
            },
            dialogVisible: false,
            dialogInfo: {
                noticeTitle: '',
                noticeContent: '',
            },
        }
    },
    created() {
    },
    activated() {
        this.setList()
    },
    methods: {
        toPublish() {
            this.$router.push({
                name: 'admin-publish-notice'
            })
        },
        // 获取列表
        setList() {
            return consumer_notice_list(this.form).then(data => {
                this.pager.totalCount = data.totalCount;
                this.list = data.list;
                this.loading = false
            })
        },
        handleDelete(row) {
            this.$confirm('是否删除此公告?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                consumer_notice_removeById({ id: row.id }).then(() => {
                    this.setList().then(() => {
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        });
                    })
                })
            })
        },
        // 详情
        showDetail(row) {
            this.dialogInfo = {
                noticeTitle: row.noticeTitle,
                noticeContent: row.noticeContent,
            };
            this.dialogVisible = true;
        },
        // 切换页码
        handleCurrentChange(page) {
            this.form.currentPage = page;
            this.setList();
        },
        // 切换每页数量
        handleSizeChange(size) {
            this.form.pageSize = size;
            this.setList();
        },
    },
}
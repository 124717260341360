var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-select',_vm._g(_vm._b({staticClass:"virtual-select-custom-style",attrs:{"popper-class":"virtualselect","value":_vm.defaultValue,"filterable":"","filter-method":_vm.filterMethod,"default-first-option":"","clearable":"","placeholder":_vm.placeholder,"disabled":_vm.disabled,"multiple":_vm.isMultiple,"allow-create":_vm.allowCreate},on:{"visible-change":_vm.visibleChange,"clear":_vm.clearChange,"focus":_vm.focusChange}},'el-select',_vm.$attrs,false),_vm.$listeners),[_c('virtual-list',{ref:"virtualList",staticClass:"virtualselect-list",attrs:{"data-key":_vm.value,"data-sources":_vm.selectArr,"data-component":_vm.itemComponent,"keeps":_vm.keepsParams,"extra-props":{
      label: _vm.label,
      value: _vm.value,
      isRight: _vm.isRight,
      isConcat: _vm.isConcat,
      concatSymbol: _vm.concatSymbol,
      selectOptionWidth: _vm.selectOptionWidth
    }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
// 用车订单详情 http://yapi.yinzhitravel.com/project/52/interface/api/15084

const __request = require(`./__request/__request_contentType_json`)
const consumer_admin_car_order_carOrderDetails = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/admin/car/order/carOrderDetails',
    data: data
  }
  return __request(pParameter)
}
export default consumer_admin_car_order_carOrderDetails

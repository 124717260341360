export default {
    data() {
        return {
            showMore: false,
            searchLoading: false,
            resetLoading: false,
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        onSearch() {
            this.searchLoading = true;
            let data = this.$parent.onSearchPromise();
            data.then(() => {
                this.searchLoading = false;
            })
        },
        onReset() {
            this.resetLoading = true;
            let data = this.$parent.onResetPromise();
            data.then(() => {
                this.resetLoading = false;
            })
        },
        showSearchMore() {
           this.showMore = !this.showMore
        },
    }
}
export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/business-drawing-list',
            name: 'admin-business-drawing-list',
            component: () => import(/* webpackChunkName: "page-admin-credit-management-business-drawing-list" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `商户提款管理`,
                },
                entity_list_for_breadcrumb: [
                    {name: `授信管理`},
                    {name: `商户提款管理`},
                ],
            },
        },
    ],
};
import _ from 'underscore';

import OrderLog from '@/component/orderLog/2.0.0/index.vue'
import consumer_admin_flight_order_saveOrderRemark from '@/lib/data-service/haolv-default/consumer_admin_flight_order_saveOrderRemark'
import consumer_admin_flight_order_exceptionDetails from '@/lib/data-service/haolv-default/consumer_admin_flight_order_exceptionDetails'
import consumer_air_ticket_fligthRules from '@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules'
import ChangeClausePopup from '@/page/admin/replace-orders/component/changeClausePopup/1.0.0/index.vue'
import consumer_log_flightList from "@/lib/data-service/haolv-default/consumer_log_flightList"

export default {
    data() {
        return {
            ruleLoading: false,
            ruleShow: false,
            ruleText:{},
            remark: '', //备注
            orderDetail:[
                {
                    oaEvectionNo: '',       //外部出差单号
                    feeAffiliationName: '',     //成本部门
                    oaBusinessTypeText: '',         //业务类型
                    abbreviation: '', //所属企业
                    amount: null, //应付金额
                    contactMobile: '', //联系电话
                    contactName: '', //联系人
                    evectionNo: '', //出差单号
                    evectionType: null, //1.因私，2.因公
                    evectionTypeText: '', //因私/因公
                    failureReason: '', //异常原因
                    initialName: '', //操作客服
                    lastPayTime: '', //支付时间
                    orderClient: '', //下单客户端
                    orderNo: '', //订单号
                    orderSource: '', //订单来源
                    orderStatus: '',
                    orderStatusText: '', //订单状态
                    orderTime: '', //预定时间
                    orderType: '', //订单类型
                    payAmount: null, //实付金额
                    paymentChannel: '',
                    paymentChannelText: '', //支付方式
                    ticketTime: '', //出票时间
                    totalPriceAll: null, //订单总价
                    policyTypeText: '', //政策类型
                }
            ],
            passengerList:[
                {
                    segmentTypeText: '', //航班类型
                    psgName: '', //姓名
                    psgTypeText: '', //乘客类型
                    cardTypeText: '', //证件类型
                    cardNo: '', //证件号码
                    birthday: '', //出生日期
                    mobile: '', //联系电话
                }
            ],
            purchasingList:[
                {
                    segmentTypeText: '', //航班类型
                    psgName: '', //姓名
                    psgTypeText: '', //乘客类型
                    ticketChannel: '', //出票渠道
                    totalPrice: null, //票面价
                    airportTax: null, //基建
                    fuelTax: null, //燃油
                    serviceFee: null, //服务费
                    totalAmount: null, //总金额
                    rebate: null, //返佣
                    purchaseprice: '', //采购价
                    ticketNo: '', //票号
                    pnr: '', //PNR
                }
            ],
            segmentList:[
                {
                    segmentTypeText: '', //航班类型
                    keyCustomerCode: '', //大客户编号
                    flightNo: '', //航班号
                    airlineName: '', //航空公司名称
                    depCityName: '', //出发城市名称
                    depCityCode: '', //出发城市三字码
                    depAirportName: '', //出发机场名称
                    depTerminal: '', //出发航站楼
                    arrCityName: '', //到达城市
                    arrCityCode: '', //到达城市三字码
                    arrAirportName: '', //到达机场名称
                    arrTerminal: '', //到达航站楼
                    depTime: '', //出发时间
                    arrTime: '', //到达时间
                    cabinCode: '', //到达时间
                    cabinName: '', //到达时间
                    duration: '', //飞行时长
                    discount: '', //折扣
                    segmentNo: '', //航段号
                }
            ],
            activeName: '1',

            insuranceInfo: [],
        }
    },
    components: {
        OrderLog,
        ChangeClausePopup
    },
    created() {
    },
    mounted() {
    },
    activated() {
        this.getData();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        //退票改签规则按钮
        showRefundChangeClause(val){
            this.ruleText = {};
            this.ruleShow = true;
            let data = {
                segmentNo: val.segmentNo
            };
            this.ruleLoading = true;
            consumer_air_ticket_fligthRules(data).then(res => {
                this.ruleLoading = false;
                this.ruleText = res.datas.rsData;
            }).catch(() => {
                this.ruleLoading = false
            })
        },
        //请求数据
        getData(){
            let data = {
                orderNo: this.$route.query.orderNo,
            };
            consumer_admin_flight_order_exceptionDetails(data).then(res => {
                this.orderDetail = [
                    {
                        oaEvectionNo: res.detail.oaEvectionNo,
                        feeAffiliationName: res.detail.feeAffiliationName,
                        oaBusinessTypeText: res.detail.oaBusinessTypeText,
                        abbreviation: res.detail.abbreviation,
                        amount: res.detail.amount,
                        contactMobile: res.detail.contactMobile,
                        contactName: res.detail.contactName,
                        evectionNo: res.detail.evectionNo,
                        evectionType: res.detail.evectionType,
                        evectionTypeText: res.detail.evectionTypeText,
                        failureReason: res.detail.failureReason,
                        initialName: res.detail.initialName,
                        lastPayTime: res.detail.lastPayTime,
                        orderClient: res.detail.orderClient,
                        orderNo: res.detail.orderNo,
                        orderSource: res.detail.orderSource,
                        orderStatus: res.detail.orderStatus,
                        orderStatusText: res.detail.orderStatusText,
                        orderTime: res.detail.orderTime,
                        orderType: res.detail.orderType,
                        payAmount: res.detail.payAmount,
                        paymentChannel: res.detail.paymentChannel,
                        paymentChannelText: res.detail.paymentChannelText,
                        ticketTime: res.detail.ticketTime,
                        totalPriceAll: res.detail.totalPriceAll,
                        policyTypeText: res.detail.policyTypeText,
                    }
                ];
                this.passengerList = res.detail.passengerList;
                this.purchasingList = res.detail.purchasingList;
                this.segmentList = res.detail.segmentList;
                this.remark = res.detail.remark;
                this.insuranceInfo = res.detail.insuranceInfo
            })
        },
        //保存按钮
        clickKeep(){
            let data = {
                orderNo: this.$route.query.orderNo,
                remark: this.remark
            };
            const random = (n = 1) => {
                const max = Math.pow(10, n) - 1;
                const min = Math.pow(10, n - 1);
                return Math.round(Math.random() * (max - min)) + min;
            };
            consumer_admin_flight_order_saveOrderRemark(data)
                .then(res => {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.$router.push({
                            name: 'admin-flight-abnormal-list-details',
                            query:{
                                orderNo: data.orderNo,
                                rd: `${random(5)}`
                            }
                        });
                    },1000)
                })
                .catch(res => {
                    this.$message.error('保存失败');
                })
        },
        clickGoBack(){
            this.$router.go(-1);
        },
        handleClick(){
            if (this.activeName == 2) {
                this.$refs.orderLog.init({
                    getDataSource: () => {
                        this.loading = true
                        // 调用获取订单日志的接口
                        return consumer_log_flightList({
                            orderNo: this.$route.query.orderNo,
                        }).then(res => {
                            // 组装组件需要的list
                            const list = _.chain(res.datas)
                                .map(function (m) {
                                    // 替换名字
                                    const o = {
                                        operator: m.operator,
                                        date: m.operatorTime,
                                        type: m.logSource,
                                        detail: m.logContents
                                    };
                                    return o
                                }).value();
                            const d = {
                                entityList: list
                            };
                            return d || {}
                        }).catch(() => {
                            return []
                        }).finally(() => {
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
}
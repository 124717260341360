import Q from 'q';
import moment from 'moment';
import CompanySelector from '@/component/companySelector/index.vue'
import CostAttributionList from '@/component/costAttributionList/index'
import BelongToProjectList from '@/component/belongToProjectList/index'
import HotelCitySelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-city-selector/1.1.0/index.vue';
import isMobile from "yinzhilv-js-sdk/src/common-service/other/is_mobile";
import DynamicForm from '@/component/dynamicForm/import_latest_version_module'
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector-label/1.0.0/index.vue';
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info';
import consumer_admin_flight_generate_payType
    from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_payType'
import consumer_admin_flight_generate_certificateType
    from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType'
import consumer_country_queryCountryTwoWord
    from '@/lib/data-service/haolv-default/consumer_country_queryCountryTwoWord';
import consumer_admin_hotel_order_queryHotelOrderListByIfInvoice
    from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_queryHotelOrderListByIfInvoice'
import consumer_admin_flight_generate_getCompanyList
    from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getCompanyList'
import consumer_hotel_common_cityList
    from '@/lib/data-service/haolv-default/consumer_hotel_common_cityList'
import consumer_admin_hotel_order_getOrderFromTypeEnum
    from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_getOrderFromTypeEnum'
import consumer_admin_hotel_order_getHotelBusinessClassEnum
    from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_getHotelBusinessClassEnum'
import consumer_admin_hotel_order_generate_generateOrderDetail
    from '@/lib/data-service/haolv-default/consumer_admin_hotel_order_generate_generateOrderDetail'
import saveOrder from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_generate_saveOrUpdate"
import saveRemark from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_hotelSaveOrderRemark"// 保存备注
import consumer_admin_flight_generate_checkPsgRepeatOrNot from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_checkPsgRepeatOrNot'
import tipsObj from "../../../hotel/create-order/1.0.1/tips";
import consumer_department_getStaffsAndExCusByUserIds from '@/lib/data-service/haolv-default/consumer_department_getStaffsAndExCusByUserIds'
import consumer_flight_report_order_offlineOutPayType from '@/lib/data-service/haolv-default/consumer_flight_report_order_offlineOutPayType'
import NewUserPopup
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-list-for-reserve-platform/1.0.0/component/newUserPopup/index.vue";
import TravelMultipleSelectDialog from "@/component/travel-multiple-select-dialog-for-create-order/index.vue";

export default {
    data() {
        return {
            oldOrderStatus: '',
            oldOrderAmount: 0, // 旧的订单销售总价
            orderDifAmount: 0, // 编辑销售总价差价
            loading: false,
            submitLoading: false,
            addTravelerLoading: false,
            type: 'create',
            orderNo: '',//订单号
            initialName: '',//操作客服
            hotelCitySelector: null,//选中的所在城市对象
            stayDateArr: [],
            form: {
                orderNo: '',//订单号

                //客户基础信息
                customerInfo: {
                    companyId: '',//所属企业id
                    companyName: '',//所属企业名称
                    contactName: '',//联系人
                    contactMobile: '',//联系电话
                    evectionType: 2,//出行类型：1.因私出行，2.因公出差
                    payType: 1,//支付方式 1：授信支付 2：现结支付 3：线下转账
                    deptId: '',//成本部门id
                    deptName: '',//成本部门
                    feeAffiliationType: 1,//成本部门类型：1.组织架构，2.成本中心
                    oaEvectionNo: '', //外部出差单号
                },

                //酒店信息
                hotelGenerateOrderHotelMsg: {
                    cityId: '',//城市id
                    cityName: '',//所在城市
                    hotelName: '',//酒店名称
                    businessClassCode: 0,//业务类别
                    businessClass: '',//业务类别
                    hotelAddr: '',//酒店地址
                    hotelRoomTypeName: '',//房型
                    breakfast: 2,//早餐
                    windowName: '',//窗户
                    roomNum: 1,//房间数
                    roomNumArr: [],
                    stayDateArr: [],//入离日期
                    checkInDate: moment().format("YYYY-MM-DD"),//入住日期
                    checkOutDate: moment().add(1, 'days').format("YYYY-MM-DD"),//离店日期
                    hotelPhone: '',//酒店电话
                    isGroup: 0,//是否协议酒店
                    groupName: '',//酒店所属集团
                    sourceType: 1,//国内/国际 1：国内 2：国际
                    groupTicket: 0, // 是否团体票 0 否 1 是
                },

                //入住人
                hotelGenerateOrderPeoples: [
                    {
                        userId: '',
                        name: '',
                        certificatesType: 1,
                        cardTypeText: '身份证',
                        certificatesNumber: '',
                        nationality: 'CN',//国籍
                        phone: '',//手机号码
                    }
                ],

                //采购信息
                hotelGenerateOrderPrice: {
                    supplierPrice: '0.0',//采购总价
                    orderAmount: '0.0',//销售总价
                    ifInvoice: '',//发票来源
                    invoiceMode: 1,//发票类型
                    invoicePrice: '0.0',//开票金额
                    // orderFromType: '',//采购渠道code
                    orderFromTypeText: '',//采购渠道msg
                    supplierOrderNo: '',//采购订单号
                    bookingDate: moment().format("YYYY-MM-DD"),//预定时间
                    bookingSms: 1,//是否发送短信给入住人
                    linkmanSms: 1,//是否发送短信给联系人
                    nightlyRateList: [],//间夜信息
                    editPriceDifference: '0.0' // 销售总价差价
                },

                //退改规则
                refundRule: {
                    refundRuleType: 1,//是否可退
                    refundDate: moment().format("YYYY-MM-DD") + ' 18:00:00',//该时间前免费退
                },

                reportConfiguration: []
            },
            remark: '',//备注
            companyList: [],//企业列表
            roomNumList: [
                {
                    value: 1,
                },
                {
                    value: 2,
                },
                {
                    value: 3,
                },
                {
                    value: 4,
                },
                {
                    value: 5,
                },
                {
                    value: 6,
                },
                {
                    value: 7,
                },
                {
                    value: 8,
                },
                {
                    value: 9,
                },
                {
                    value: 10,
                },
            ],//房间数量枚举值
            selectedIdList: [],//选中的入住人id
            evectionTypeList: [
                {
                    code: 2,
                    msg: '因公出差'
                },
                {
                    code: 1,
                    msg: '因私出行'
                }
            ],//出行类型
            payTypeList: [],//支付方式list
            cityList: [],//城市列表
            copyCityList: [],//copy城市列表
            breakfastList: [
                {
                    code: 0,
                    msg: '无早'
                },
                {
                    code: 1,
                    msg: '单份早餐'
                },
                {
                    code: 2,
                    msg: '双份早餐'
                },
            ],//早餐类型
            windowList: [
                {
                    id: 1,
                    value: '无窗'
                },
                {
                    id: 2,
                    value: '有窗'
                },
                {
                    id: 3,
                    value: '随机'
                }
            ],//窗户类型
            certificateTypeList: [],//证件类型list
            countryList: [],//国籍list
            ifInvoiceList: [],//发票来源list
            invoiceTypeList: [
                {
                    code: 1,
                    msg: '普票'
                },
                {
                    code: 2,
                    msg: '专票1'
                },
                {
                    code: 3,
                    msg: '专票6'
                },
                {
                    code: 4,
                    msg: '未知'
                }
            ],//发票类型list
            orderFromTypeList: [],//采购渠道list
            averagePurchase: '0.0',//采购平均间夜价
            lastAveragePurchase: '0.0',//采购平均间夜价最后一晚
            averagePurchaseArr: [],
            averageSales: '0.0',//销售平均间夜价
            lastAverageSales: '0.0',//销售平均间夜价最后一晚
            averageSalesArr: [],
            checkOutRange: {
                disabledDate: this.disabledDate,
            },//离店时间限制
            curIndex: 0,//当前正在操作的房间号
            parameterListBox: [],
            centerCodeBox: '',  // 成本中心--centerCode
            projectNameBox: '',  // 归属项目--projectName
            projectCodeBox: '',  // 归属项目--projectCode
            companyIdBox: 0,  // 归属项目--companyId
            businessClassList: [
                {
                    code: 0,
                    msg: '房费'
                },
                {
                    code: 1,
                    msg: '杂费'
                },
                {
                    code: 2,
                    msg: '会议室费'
                },
            ],//业务类别list
            sourceTypeList: [
                {
                    code: 1,
                    msg: '国内'
                },
                {
                    code: 2,
                    msg: '国际'
                },
            ],//国内国际list
            cityInputVal: '',//所在目的城市输入值
            isDisabledRoomAmount: false,//是否可修改房间数
            isDisabledTraveler: false,//是否可修改入住人
            paymentTypeList: [
                {
                    code:0,
                    msg:'支付宝',
                },
                {
                    code:1,
                    msg:'网银',
                }
            ],//支付方式枚举值
            rules: {
                'customerInfo': {
                    'companyId': [
                        {required: true, message: '请选择所属企业', trigger: 'change'},
                    ],
                    'contactName': [
                        {required: true, message: '请填写联系人', trigger: 'blur'}
                    ],
                    'contactMobile': [
                        {required: true, message: '请填写联系电话', trigger: 'blur'},
                        {validator: this.checkMobile, trigger: 'blur'}
                    ],
                    'evectionType': [
                        {required: true, message: '请选择出行类型', trigger: 'blur'}
                    ],
                    'payType': [
                        {required: true, message: '请选择支付方式', trigger: 'blur'}
                    ]
                },

                'hotelGenerateOrderHotelMsg': {
                    'hotelName': [
                        {required: true, message: '请填写酒店名称', trigger: 'blur'}
                    ],
                    'businessClassCode': [
                        {required: true, message: '请填写业务类别', trigger: 'blur'}
                    ],
                    'hotelAddr': [
                        {required: true, message: '请填写酒店地址', trigger: 'blur'}
                    ],
                    'hotelRoomTypeName': [
                        {required: true, message: '请填写房型', trigger: 'blur'}
                    ],
                    'breakfast': [
                        {required: true, message: '请选择早餐', trigger: 'blur'}
                    ],
                    'roomNum': [
                        {required: true, message: '请填写房间数', trigger: 'blur'}
                    ],
                    'stayDateArr': [
                        {required: true, message: '请选择入离日期', trigger: 'blur'},
                    ],
                    'checkInDate': [
                        {required: true, message: '请选择入住日期', trigger: 'blur'},
                    ],
                    'checkOutDate': [
                        {required: true, message: '请选择离店日期', trigger: 'blur'},
                    ],
                    'hotelPhone': [
                        {required: true, message: '请填写酒店电话', trigger: 'blur'},
                    ],
                    'groupName': [
                        {required: true, message: '请填写所属酒店集团', trigger: 'blur'},
                    ],
                },

                'hotelGenerateOrderPrice': {
                    'supplierPrice': [
                        {required: true, message: '请填写采购总价', trigger: 'blur'}
                    ],
                    'orderAmount': [
                        {required: true, message: '请填写销售总价', trigger: 'blur'}
                    ],
                    'ifInvoice': [
                        {required: true, message: '请选择发票来源', trigger: 'change'}
                    ],
                    'invoiceMode': [
                        {required: true, message: '请选择发票类型', trigger: 'change'}
                    ],
                    'invoicePrice': [
                        {required: true, message: '请填写开票金额', trigger: 'blur'}
                    ],
                    'orderFromTypeText': [
                        {required: true, message: '请选择采购渠道', trigger: 'blur'}
                    ],
                    'bookingDate': [
                        {required: true, message: '请填写预定时间', trigger: 'blur'}
                    ],
                },

                'refundRule': {
                    'refundDate': [
                        {required: true, message: '请选择免费取消的截止日期', trigger: 'blur'},
                    ],
                },
                orderSource: '',
            },
            activeUserList: [],
        }
    },
    computed: {
        //是否可编辑
        isDisabled() {
            if (this.type === 'create' || this.type === 'edit') {
                return false
            } else {
                return true
            }
        },

        orderStatusToDisabled() {
            const orderStatus = this.oldOrderStatus;
            const type = this.type;
            if (orderStatus !== '' && orderStatus === 11 && type === 'edit') {
                return true
            } else {
                return false
            }
        },
        orderTypeToDisabled() {
            if (this.orderSource === 2) {
                return true
            } else {
                return false;
            }
        },

        //总间夜数
        dateGap() {
            let gap = null;
            const checkInDate = this.form.hotelGenerateOrderHotelMsg.checkInDate;
            const checkOutDate = this.form.hotelGenerateOrderHotelMsg.checkOutDate;
            if (checkInDate && checkOutDate) {
                gap = moment(checkOutDate).diff(moment(checkInDate), 'days');
            }
            return gap;
        }
    },
    components: {
        TravelMultipleSelectDialog,
        NewUserPopup,
        CompanySelector,
        CostAttributionList,
        DynamicForm,
        HotelCitySelector,
        TravelerMultipleSelector,
        BelongToProjectList
    },
    async activated() {
        await this.init();
    },
    watch: {
        hotelCitySelector(newVal) {
            if (newVal) {
                this.form.hotelGenerateOrderHotelMsg.cityId = newVal.cityId.toString();
                this.form.hotelGenerateOrderHotelMsg.cityName = newVal.cityCn;
            } else {
                this.form.hotelGenerateOrderHotelMsg.cityId = '';
                this.form.hotelGenerateOrderHotelMsg.cityName = '';
            }
            this.$refs.form.validateField('hotelGenerateOrderHotelMsg.cityName');
        },
    },
    methods: {
        //初始化
        async init() {
            this.loading = true;
            this.resetForm();
            this.getCityList();
            this.initQueryData();//获取地址栏传入参数信息
            this.getPaymentTypeList();
            await this.initTravelerMultipleSelector();//初始化选入住人组件
            await this.getCompanyList();//获取所属公司枚举值
            await this.getCertificateType();//获取证件类型枚举值
            await this.queryCountryTwoWord();//获取国籍枚举值
            await this.getByIfInvoice();//获取发票来源枚举值
            await this.getorderFromTypeList();//获取采购渠道枚举值
            await this.getBusinessClassList();
            await this.initData();//通过接口获取信息进行回显
            await this.initCostAttributionList({
                companyId: this.form.customerInfo.companyId
            });

            this.$refs.form.clearValidate();
            this.loading = false;
        },

        //重置数据
        resetForm() {
            this.form = {
                orderNo: '',
                //客户基础信息
                customerInfo: {
                    companyId: '',//所属企业id
                    companyName: '',//所属企业名称
                    contactName: '',//联系人
                    contactMobile: '',//联系电话
                    evectionType: 2,//出行类型：1.因私出行，2.因公出差
                    payType: 1,//支付方式 1：授信支付 2：现结支付 3：线下转账
                    deptId: '',//成本部门id
                    deptName: '',//成本部门
                    feeAffiliationType: 1,//成本部门类型：1.组织架构，2.成本中心
                },

                //酒店信息
                hotelGenerateOrderHotelMsg: {
                    cityId: '',//城市id
                    cityName: '',//所在城市
                    hotelName: '',//酒店名称
                    hotelAddr: '',//酒店地址
                    hotelRoomTypeName: '',//房型
                    breakfast: 2,//早餐
                    windowName: '',//窗户
                    roomNum: 1,//房间数
                    roomNumArr: [],
                    stayDateArr: [],//入离日期
                    checkInDate: moment().format("YYYY-MM-DD"),//入住日期
                    checkOutDate: moment().add(1, 'days').format("YYYY-MM-DD"),//离店日期
                    hotelPhone: '',//酒店电话
                    isGroup: 0,//是否协议酒店
                    groupName: '',//酒店所属集团
                    sourceType: 1,//国内/国际 1：国内 2：国际
                    groupTicket: 0,
                },

                //入住人
                hotelGenerateOrderPeoples: [
                    {
                        userId: '',
                        name: '',
                        certificatesType: 1,
                        cardTypeText: '身份证',
                        certificatesNumber: '',
                        nationality: 'CN',//国籍
                        phone: '',//手机号码
                    }
                ],

                //采购信息
                hotelGenerateOrderPrice: {
                    supplierPrice: '0.0',//采购总价
                    orderAmount: '0.0',//销售总价
                    ifInvoice: '',//发票来源
                    invoiceMode: 1,//发票类型
                    invoicePrice: '0.0',//开票金额
                    // orderFromType: '',//采购渠道code
                    orderFromTypeText: '',//采购渠道msg
                    paymentType: 0,//付款方式 0：支付宝；1：网银
                    supplierOrderNo: '',//采购订单号
                    bookingDate: moment().format("YYYY-MM-DD"),//预定时间
                    bookingSms: 1,//是否发送短信给入住人
                    linkmanSms: 1,//是否发送短信给联系人
                    nightlyRateList: []//间夜信息
                },

                //退改规则
                refundRule: {
                    refundRuleType: 1,//是否可退
                    refundDate: moment().format("YYYY-MM-DD") + ' 18:00:00',//该时间前免费退
                },

                reportConfiguration: []
            };
            this.remark = '';
            this.selectedIdList = [];
            this.averagePurchase = '0.0';
            this.averageSales = '0.0';
            let weekArr = ["日", "一", "二", "三", "四", "五", "六"];
            let nightObj = {
                date: moment().format("YYYY-MM-DD"),
                week: '周' + weekArr[this.$moment(moment().format("YYYY-MM-DD")).weekday()],
                supplierPrice: ['0'],
                platformPrice: ['0'],
                roomPriceList: [{supplierPrice: '0', platformPrice: '0'}],
            }
            this.form.hotelGenerateOrderPrice.nightlyRateList.push(nightObj);
            this.form.hotelGenerateOrderHotelMsg.businessClassCode = 0;
            this.isDisabledRoomAmount = false;//是否可修改房间数
            this.isDisabledTraveler = false;//是否可修改入住人
            if (this.$route.query.orderSource) {
                this.orderSource = parseInt(this.$route.query.orderSource);
            } else {
                this.orderSource = '';
            }

            this.clearCompany();
            this.changeCompanyId();
        },

        //获取支付方式枚举值
        async getPaymentTypeList() {
            let res = await consumer_flight_report_order_offlineOutPayType();
            this.paymentTypeList = res.datas;
        },

        //获取地址栏传入参数信息
        initQueryData() {
            const query = this.$route.query;
            this.type = query && query.type ? query.type : 'create';
            this.orderNo = query && query.orderNo ? query.orderNo : '';
            this.form.orderNo = this.orderNo;
        },

        //通过接口获取信息进行回显
        async initData() {
            if (this.type === 'create') {
                //创建生单信息时，需要获取当前登录人信息
                get_user_info().then(res => {
                    this.initialName = res.datas.realName;
                    this.form.hotelGenerateOrderPrice.initialName = res.datas.realName;
                })
            } else {
                //编辑时，需要获取详情信息用于回显
                await this.getOrderDetail();

                //初始化动态表单
                this.initDynamicForm();
            }
            await this.getPayTypeList();
        },

        //获取企业列表
        async getCompanyList() {
            const params = {
                companyName: '',
                currentPage: 1,
                keyword: '',
                pageSize: 999,
            }
            let res = await consumer_admin_flight_generate_getCompanyList(params)
            this.companyList = res.datas.list;
        },

        //获取支付方式
        getPayTypeList() {
            consumer_admin_flight_generate_payType().then(res => {
                this.payTypeList = res.datas
            })
        },

        //获取证件类型list
        getCertificateType() {
            consumer_admin_flight_generate_certificateType().then(res => {
                this.certificateTypeList = res.datas
            })
        },

        //获取国籍list
        queryCountryTwoWord() {
            consumer_country_queryCountryTwoWord().then(res => {
                this.countryList = res.datas
            })
        },

        //获取发票来源
        getByIfInvoice() {
            let list = [];
            consumer_admin_hotel_order_queryHotelOrderListByIfInvoice().then(res => {
                if (res.datas) {
                    res.datas.forEach(item => {
                        if (item.code !== 0) {
                            list.push(item)
                        }
                    })
                }
                this.ifInvoiceList = list;
                this.form.hotelGenerateOrderPrice.ifInvoice = this.ifInvoiceList[0].code;
            });
        },

        //获取采购渠道枚举值
        async getorderFromTypeList() {
            let res = await consumer_admin_hotel_order_getOrderFromTypeEnum();
            this.orderFromTypeList = res.datas;
        },

        //获取业务类别枚举值
        async getBusinessClassList() {
            let res = await consumer_admin_hotel_order_getHotelBusinessClassEnum();
            let list = res.datas;
            let category = {};
            let businessClassList = [];
            list.forEach((item, index) => {
                category = {
                    code: index,
                    msg: item
                }
                businessClassList.push(category)
            });
            this.businessClassList = businessClassList;
        },

        //获取城市列表
        async getCityList() {
            let res = await consumer_hotel_common_cityList({countryType: 1});
            this.cityList = res.citys;
            this.copyCityList = JSON.parse(JSON.stringify(this.cityList));
        },

        //编辑 - 获取订单详情
        async getOrderDetail() {
            let res = await consumer_admin_hotel_order_generate_generateOrderDetail({orderNo: this.orderNo});
            let form = res.datas;
            //this.form = res.datas;
            if (this.type === 'copy') {
                form.orderNo = '';

            }
            if (form.hotelGenerateOrderHotelMsg.cityId && form.hotelGenerateOrderHotelMsg.cityName) {
                this.hotelCitySelector = {
                    cityId: form.hotelGenerateOrderHotelMsg.cityId.toString(),
                    cityCn: form.hotelGenerateOrderHotelMsg.cityName
                }
                if (Number(this.hotelCitySelector.cityId) === 0) {
                    let item = {
                        cityId: '0',
                        cityCn: this.hotelCitySelector.cityCn,
                        cityName: this.hotelCitySelector.cityCn
                    }
                    this.cityList.push(item);
                    this.copyCityList.push(item);
                }
            }
            let groupName = form.hotelGenerateOrderHotelMsg.groupName;
            let businessClass = this.businessClassList.find(item => {
                console.log(item)
                return item.msg === form.hotelGenerateOrderHotelMsg.businessClass;
            })
            console.log()
            if (businessClass) {
                form.hotelGenerateOrderHotelMsg.businessClassCode = businessClass.code;
            }
            form.hotelGenerateOrderHotelMsg.stayDateArr = [form.hotelGenerateOrderHotelMsg.checkInDate, form.hotelGenerateOrderHotelMsg.checkOutDate];
            let isGroup = !groupName ? 0 : 1;
            form.hotelGenerateOrderHotelMsg.isGroup = isGroup;
            // this.$set(form.hotelGenerateOrderHotelMsg, 'isGroup', isGroup);
            this.oldOrderAmount = form.hotelGenerateOrderPrice.orderAmount;
            this.oldOrderStatus = res.datas.status;
            this.form = form;
            console.log("订单状态：" + this.oldOrderStatus);
        },

        //初始化动态表单
        initDynamicForm() {
            // 更新初始化动态表单
            setTimeout(() => {
                this.$refs.aDynamicForm.init({
                    showAttribution: true,
                    immutableSwitch: false,
                    type: 'display',
                    displayList: this.form.reportConfiguration,
                    companyId: this.form.customerInfo.companyId,
                    // 业务类型：1机票、2火车票、3酒店、4汽车
                    businessType: 3,
                    // 是否使用默认数据
                    mock_data: {
                        enable: false,
                    },
                }).then(res => {
                    this.parameterListBox = res;
                });
            }, 300)
        },

        //初始化归属项目
        toSelectType(){
            let params = {
                companyId: this.form.customerInfo.companyId,
            };
            if (this.centerCodeBox != null && this.centerCodeBox != '' && this.centerCodeBox.indexOf('E') != -1) {
                this.$refs.belongToProjectListRef.init(params);
            }
        },

        //初始化用户选择组件
        initTravelerMultipleSelector() {
            this.initMultiSelectorA();
            this.initMultiSelectorB();
        },

        //初始化多选入住人组件
        initMultiSelectorA() {
            this.$refs.aTravelMultipleSelectDialog.setCompany(this.form.customerInfo.companyId);
            this.$refs.aTravelMultipleSelectDialog.init();
        },

        changeGroupTicket(val) {
            this.$refs.form.clearValidate();
            this.$forceUpdate();
            console.log(val);
            if (val === 1) {
                // this.form.hotelGenerateOrderHotelMsg.roomNumArr = [1];
                this.form.hotelGenerateOrderHotelMsg.roomNum = 1;
                this.changeRoomNum(1);
            } else {
                if (this.form.hotelGenerateOrderPeoples.length > 10) {
                    this.form.hotelGenerateOrderHotelMsg.roomNum = 10;
                    this.changeRoomNum(6);
                }
            }
        },

        //初始化单选入住人组件
        initMultiSelectorB() {
            this.$refs.bTravelMultipleSelectDialog.setCompany(this.form.customerInfo.companyId);
            this.$refs.bTravelMultipleSelectDialog.init({
                selectType: `001`,
            });
        },

        //初始化成本部门组件
        initCostAttributionList(params) {
            if (this.$refs.costAttributionList == undefined) {
                return
            }
            this.$refs.costAttributionList.init(params);
        },

        //改变所属企业
        changeCompanyId(val) {
            let activeItem = null;
            this.companyList.forEach(value => {
                if (value.id === val) {
                    activeItem = value
                }
            });
            this.form.customerInfo.companyName = activeItem ? activeItem.companyName : '';
            if (this.orderStatusToDisabled) {
                // 编辑时候也要更新成本部门
                const params = {
                    companyId: val,
                    evectionType: this.form.customerInfo.evectionType,
                    businessType: 3,
                };
                this.$refs.costAttributionList.init(params).then(()=>{
                    this.form.customerInfo.deptName = '各自所在部门';
                    this.form.customerInfo.deptId = -1;
                    this.form.customerInfo.feeAffiliationType = 1;
                });
                return;
            }
            // 清除联系人
            this.form.customerInfo.contactName = '';
            this.form.customerInfo.contactMobile = '';
            this.centerCodeBox = '';
            this.projectNameBox = '';
            this.projectCodeBox = '';
            this.companyIdBox = 0;
            const params = {
                companyId: val,
                evectionType: this.form.customerInfo.evectionType,
                businessType: 3,
            };
            this.initCostAttributionList(params);
            // 更新初始化动态表单
            if (this.$refs.aDynamicForm) {
                this.$refs.aDynamicForm.init({
                    showAttribution: true,
                    companyId: Number(val),
                    // 业务类型：1机票、2火车票、3酒店、4汽车
                    businessType: 3,
                    // 是否使用默认数据
                    mock_data: {
                        enable: false,
                    },
                }).then(res => {
                    this.parameterListBox = res;
                    if (this.parameterListBox.indexOf('成本中心') == -1) {
                        this.$refs.costAttributionList.init(params);
                    }
                });
            }

            // 更新初始化用户选择组件
            this.initTravelerMultipleSelector();
        },

        //清除已选所属企业
        clearCompany() {
            if (this.orderStatusToDisabled) {
                return;
            }
            this.form.customerInfo.contactName = '';
            this.form.customerInfo.contactMobile = '';
        },

        //改变出行类型
        changeEvectionType(val) {
            if (val === 2) {
                this.form.customerInfo.payType = 1;
            } else {
                this.form.customerInfo.payType = 2;
            }
            const params = {
                companyId: this.form.customerInfo.companyId,
                evectionType: val
            };
        },

        //切换是否协议酒店开关
        changeIsGroup() {
            if (this.form.hotelGenerateOrderHotelMsg.isGroup === 0) {
                this.form.hotelGenerateOrderHotelMsg.groupName = '';
            } else {
                // this.form.hotelGenerateOrderPrice.orderFromType = '';
                this.form.hotelGenerateOrderPrice.orderFromTypeText = '';
            }
        },

        //离店日期不能早于入住日期
        disabledDate(time) {
            const form = this.form.hotelGenerateOrderHotelMsg;
            const end = moment(time).format("YYYYMMDD");
            if (end <= moment(form.checkInDate).format("YYYYMMDD")) return true;
            if (end > moment(form.checkInDate).add(30, "days").format("YYYYMMDD")) return true;
        },

        //检验是否有完全未填信息的入住人
        checkNoInfoTraveler() {
            let isHasNoInfoTraveler = false;
            let noInfoTraveler = this.form.hotelGenerateOrderPeoples.find(traveler => {
                return !traveler.name && !traveler.certificatesNumber && !traveler.phone
            })
            if (noInfoTraveler) {
                isHasNoInfoTraveler = true;
            }
            return isHasNoInfoTraveler;
        },

        //检验是否可选择或增加入住人
        checkTravelerListNum(type) {
            let isOk = true;
            if (type === 'choose' && this.checkNoInfoTraveler()) {
                return isOk;
            }
            let roomNum = this.form.hotelGenerateOrderHotelMsg.roomNum;
            console.log(this.form.hotelGenerateOrderHotelMsg.groupTicket);
            if (type === 'add' && this.form.hotelGenerateOrderPeoples.length >= roomNum && this.form.hotelGenerateOrderHotelMsg.groupTicket === 0) {
                this.$message({
                    type: "warning",
                    message: '最多只能' + roomNum + '个入住人'
                })
                isOk = false;
            }
            return isOk;
        },

        //清空入住人
        clearTraveler(index) {
            let item = {
                userId: '',
                name: '',
                certificatesType: 1,
                cardTypeText: '身份证',
                certificatesNumber: '',
                nationality: 'CN',//国籍
                phone: ''
            };
            this.form.hotelGenerateOrderPeoples.splice(index, 1, item);
        },

        //多选入住人
        chooseMultiTraveler() {
            if (!this.checkTravelerListNum('choose')) {
                return;
            }
            if (!this.form.customerInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择所属企业'
                });
                return
            }
            this.selectedIdList = [];
            this.activeUserList = [];
            this.$refs.aTravelMultipleSelectDialog.init();
            this.$refs.aTravelMultipleSelectDialog.show();
        },

        //单选入住人
        chooseSingleTraveler(index) {
            if (!this.checkTravelerListNum('choose')) {
                return;
            }
            if (!this.form.customerInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择所属企业'
                });
                return
            }
            this.selectedIdList = [];
            this.$refs.bTravelMultipleSelectDialog.show();
            this.curIndex = index;
        },

        //增加入住人
        addTraveler(item, index) {
            if(!this.form.customerInfo.companyId) {
                this.$message({
                    type: "warning",
                    message: '请先选择公司'
                })
                return;
            }
            /*if (!this.checkTravelerListNum('add')) {
                return;
            }*/
            let _index = this.canSetNum();
            if (_index === -1 && this.form.hotelGenerateOrderPeoples.length === this.form.hotelGenerateOrderHotelMsg.roomNum) {
                this.$message({
                    type: "warning",
                    message: '最多只能' + this.form.hotelGenerateOrderHotelMsg.roomNum + '个入住人'
                })
                return;
            }
            this.$refs.aNewUserPopup.init({companyId: this.form.customerInfo.companyId});
            this.$refs.aNewUserPopup.show();
            /*this.addTravelerLoading = true;
            const newItem = {
                userId: '',
                name: '',
                certificatesType: 1,
                cardTypeText: '身份证',
                certificatesNumber: '',
                nationality: 'CN',
                phone: '',
                tags: [],
            };
            if (!index) {
                index = this.form.hotelGenerateOrderPeoples.length - 1;
            }
            this.form.hotelGenerateOrderPeoples.splice(index + 1, 0, newItem);
            this.addTravelerLoading = false;*/
        },

        canSetNum() {
            let index = -1;
            for (let i = 0; i < this.form.hotelGenerateOrderPeoples.length; i++) {
                const item = this.form.hotelGenerateOrderPeoples[i];
                if (item.name === '' && item.certificatesNumber === '') {
                    index = i;
                    break;
                }
            }
            return index;
        },

        //删除入住人
        delTraveler(item, index) {
            this.form.hotelGenerateOrderPeoples.splice(index, 1);
            this.form.hotelGenerateOrderHotelMsg.roomNum = this.form.hotelGenerateOrderPeoples.length;
        },

        //修改证件类型
        changeCertificateType(item, index, val) {
            this.certificateTypeList.forEach(value => {
                if (value.value === val) {
                    item.cardTypeText = value.msg
                }
            })
            item.nationality = 'CN';
            item.certificatesNumber = '';
            if(item.certificates) {
                item.certificates.forEach(value=>{
                    if (value.certificateValue === val) {
                        item.certificatesNumber = value.certificate;
                        item.nationality = value.countryCode;
                    }
                })
            }
        },

        //检验证件号码
        cardNoValidator(rule, value, callback, index, item) {
            let cardType = item.cardType;
            if (cardType === 1 && this.isChineseIDCardNumber(value) === false) {
                callback(new Error('请输入正确的身份证号码'));
                return;
            } else {
                callback();
            }
        },

        //检验身份证号码格式
        isChineseIDCardNumber(value) {
            const testRegExp = /^[1-9]([0-9]{14}|[0-9]{16}[0-9Xx])$/;
            const areaMap = [11, 12, 13, 14, 15, 21, 22, 23, 31, 32, 33, 34, 35, 36, 37, 41, 42, 43, 44, 45, 46, 50, 51, 52, 53, 54, 61, 62, 63, 64, 65, 71, 81, 82];
            const weightMap = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            const codeMap = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
            const len = value.length;
            if (len !== 18) {
                return false;
            }
            // 模式校验
            if (!testRegExp.test(value)) {
                return false;
            }
            // 地区校验
            if (areaMap.indexOf(+value.substr(0, 2)) === -1) {
                return false;
            }
            // 18 位
            if (!this.isValidDate(+value.substr(6, 4), +value.substr(10, 2), +value.substr(12, 2))) {
                return false;
            }
            // 校验码
            const sum = value.split('').slice(0, 17).reduce((s, num, index) => {
                return s += +num * weightMap[index];
            }, 0);
            return codeMap[sum % 11] === value[17].toUpperCase();
        },

        //去除证件号的空格
        inputCardNo(item, val) {
            item.certificatesNumber = val.replace(/\s+/g, '')
        },

        //检验手机号码格式
        checkMobile(rule, value, callback) {
            if (!isMobile(value)) {
                callback(new Error('只能输入长度不能大于11的数字'));
                return
            }
            callback();
        },

        filterMethodToCity(val) {
            let dataList = this.cityList;
            this.form.hotelGenerateOrderHotelMsg.cityId = '';
            if (val) {
                this.copyCityList = dataList.filter(item => {
                    const reg = new RegExp(`^${val}`, `gi`);
                    let flag = false;
                    const cityId = item.cityId || '';
                    const cityCn = item.cityCn || '';
                    flag = flag || (cityId.match(reg));
                    flag = flag || (cityCn.match(reg));
                    return flag
                })
            } else {
                this.copyCityList = JSON.parse(JSON.stringify(dataList))
            }
            this.cityInputVal = val;
        },

        changeCity() {
            let selectedCity = this.cityList.find(item => {
                return item.cityId === this.form.hotelGenerateOrderHotelMsg.cityId;
            });
            let cityName = selectedCity ? selectedCity.cityCn : '';
            if (cityName === '' && this.cityInputVal !== '') {
                cityName = this.cityInputVal;
                let listLength = this.copyCityList.length;
                let addItem = {
                    cityId: '0',
                    cityCn: cityName,
                    cityName: cityName,
                };
                if (this.copyCityList && this.copyCityList.length > 0 && this.copyCityList[listLength - 1].cityId === '0') {
                    this.copyCityList[listLength - 1] = addItem;
                } else {
                    this.copyCityList.push(addItem)
                }
                this.form.hotelGenerateOrderHotelMsg.cityId = '0';
            }
            this.form.hotelGenerateOrderHotelMsg.cityName = cityName;
        },

        FocusCitySelector() {
            this.copyCityList = JSON.parse(JSON.stringify(this.cityList))
        },

        //改变房间数
        changeRoomNum() {
            this.autoCalAverage();
            let roomNum = this.form.hotelGenerateOrderHotelMsg.roomNum;
            let travelerNum = this.form.hotelGenerateOrderPeoples.length;
            if (roomNum <= 0) {
                roomNum = 1;
                this.form.hotelGenerateOrderHotelMsg.roomNum = 1;
            } else if (roomNum > 10) {
                roomNum = 10;
                this.form.hotelGenerateOrderHotelMsg.roomNum = 10;
            }
            if (travelerNum < roomNum) {
                for (let i = travelerNum; i < roomNum; i++) {
                    this.form.hotelGenerateOrderPeoples.push({
                        userId: '',
                        name: '',
                        certificatesType: 1,
                        cardTypeText: '身份证',
                        certificatesNumber: '',
                        nationality: 'CN',//国籍
                        phone: '',//手机号码
                    })
                    //this.addTraveler();
                }
            }
            if (travelerNum > roomNum) {
                for (let i = travelerNum; i > roomNum; i--) {
                    this.form.hotelGenerateOrderPeoples.splice(i - 1, i - roomNum);
                }
            }
            this.initNightlyRateList();
        },
        changeRoomNumForInput() {
            this.initNightlyRateList();
        },

        //搜索窗口类型
        searchWindow(queryString, cb) {
            let windowList = this.windowList;
            let results = queryString ? windowList.filter(this.createFilter(queryString)) : windowList;
            cb(results);
        },

        //搜索采购渠道
        searchOrderFromType(queryString, cb) {
            let orderFromTypeList = [];
            this.orderFromTypeList.forEach(item => {
                let type = {
                    id: item.code,
                    value: item.msg
                }
                orderFromTypeList.push(type);
            });
            let results = queryString ? orderFromTypeList.filter(this.createFilter(queryString)) : orderFromTypeList;
            cb(results);
        },

        //带输入建议input的筛选功能
        createFilter(queryString) {
            return (res) => {
                return (res.value.indexOf(queryString) === 0);
            };
        },

        //改变入离日期
        changeCheckDate(arr) {
            if (arr && arr.length > 0) {
                this.form.hotelGenerateOrderHotelMsg.checkInDate = arr[0];
                this.form.hotelGenerateOrderHotelMsg.checkOutDate = arr[1];
            }
            this.autoCalAverage();
            if (this.dateGap && this.dateGap > 0) {
                this.initNightlyRateList();
                this.form.refundRule.refundDate = this.form.hotelGenerateOrderHotelMsg.checkInDate + ' 18:00:00';
            } else if (this.dateGap && this.dateGap < 0) {
                this.form.hotelGenerateOrderHotelMsg.checkOutDate = '';
                this.form.hotelGenerateOrderHotelMsg.checkInDate = '';
            }
        },

        //改变采购总价时
        changeSupplierPrice() {
            this.autoCalPurchaseAverage();
            this.form.hotelGenerateOrderPrice.invoicePrice = this.form.hotelGenerateOrderPrice.supplierPrice;
        },

        changeOneSupplierPrice() {
            let supplierPrice = 0;
            this.form.hotelGenerateOrderPrice.nightlyRateList.forEach(value => {
                value.roomPriceList.forEach(value1 => {
                    supplierPrice = supplierPrice + Number(value1.supplierPrice);
                })
            })
            this.form.hotelGenerateOrderPrice.supplierPrice = supplierPrice;
            this.form.hotelGenerateOrderPrice.invoicePrice = supplierPrice;
        },

        changeOnePlatformPrice() {
            let platformPrice = 0;
            this.form.hotelGenerateOrderPrice.nightlyRateList.forEach(value => {
                value.roomPriceList.forEach(value1 => {
                    platformPrice = platformPrice + Number(value1.platformPrice);
                })
            })
            this.form.hotelGenerateOrderPrice.orderAmount = platformPrice;
        },

        //自动算平均间夜价
        autoCalAverage() {
            this.autoCalPurchaseAverage();
            this.autoCalSalesAverage();
        },

        //自动算采购平均间夜价
        autoCalPurchaseAverage() {
            const roomNum = this.form.hotelGenerateOrderHotelMsg.roomNum; // 房间数
            const supplierPrice = this.form.hotelGenerateOrderPrice.supplierPrice;
            if (this.dateGap && this.dateGap > 0 && supplierPrice && supplierPrice > 0 && roomNum > 0) {
                let r = supplierPrice % (this.dateGap * roomNum);
                let averagePrice = supplierPrice / (this.dateGap * roomNum);
                if (r > 0) {
                    averagePrice = averagePrice.toFixed(2);
                }
                this.averagePurchase = parseInt(averagePrice);
                const averagePriceRemainder = supplierPrice % (this.dateGap * roomNum);
                if (averagePriceRemainder > 0) {
                    this.lastAveragePurchase = parseInt(averagePrice) + averagePriceRemainder;
                } else {
                    this.lastAveragePurchase = parseInt(averagePrice);
                }
                this.initNightlyRateList();
            }
        },

        //自动算销售平均间夜价
        autoCalSalesAverage() {
            const roomNum = this.form.hotelGenerateOrderHotelMsg.roomNum;
            const orderAmount = this.form.hotelGenerateOrderPrice.orderAmount;
            if (this.dateGap && this.dateGap > 0 && orderAmount && orderAmount > 0 && roomNum > 0) {
                let r = orderAmount % (this.dateGap * roomNum);
                let averagePrice = orderAmount / (this.dateGap * roomNum);
                if (r > 0) {
                    averagePrice = averagePrice.toFixed(2);
                }
                this.averageSales = parseInt(averagePrice);
                const averagePriceRemainder = orderAmount % (this.dateGap * roomNum);
                if (averagePriceRemainder > 0) {
                    this.lastAverageSales = parseInt(averagePrice) + averagePriceRemainder;
                } else {
                    this.lastAverageSales = parseInt(averagePrice);
                }
                this.initNightlyRateList();
            }
        },

        //动态生成间夜list
        initNightlyRateList() {
            let dayArr = [];
            let weekArr = ["日", "一", "二", "三", "四", "五", "六"];
            let checkInDate = JSON.parse(JSON.stringify(this.form.hotelGenerateOrderHotelMsg.checkInDate));
            let checkOutDate = JSON.parse(JSON.stringify(this.form.hotelGenerateOrderHotelMsg.checkOutDate));
            const roomNum = this.form.hotelGenerateOrderHotelMsg.roomNum; // 房间数
            let checkDateList = []; // 先获取间夜数据
            while (this.$moment(checkInDate).isBefore(checkOutDate)) {
                checkDateList.push(checkInDate);
                // 自增
                checkInDate = this.$moment(new Date(checkInDate)).add(1, 'day').format('YYYY-MM-DD')
            }
            checkDateList.forEach((checkInDate, cIndex) => {

                let averagePurchaseArr = []; // 采购价list
                let averagePurchase = Number(this.averagePurchase); // 采购价
                if (this.averagePurchase == this.lastAveragePurchase) {
                    // 整数
                    for (let i = 0; i < roomNum; i++) {
                        averagePurchaseArr.push(averagePurchase);
                    }
                } else {
                    // 有小数
                    // 如果是最后一项的最后一个房间
                    const defaultRoomPrice = parseInt(this.averagePurchase);
                    const lastRoomPrice = Math.ceil(this.averagePurchase);
                    for (let i = 0; i < roomNum; i++) {
                        if (checkDateList.length == cIndex + 1 && roomNum == i + 1) {
                            // 最后一项
                            averagePurchaseArr.push(this.lastAveragePurchase);
                        } else {
                            averagePurchaseArr.push(defaultRoomPrice);
                        }
                    }
                }
                let averageSalesArr = [];
                let averageSales = Number(this.averageSales);
                if (this.averageSales == this.lastAverageSales) {
                    // 整数
                    for (let i = 0; i < roomNum; i++) {
                        averageSalesArr.push(averageSales);
                    }
                } else {
                    // 有小数
                    // 如果是最后一项的最后一个房间
                    const defaultRoomPrice = parseInt(this.averageSales);
                    const lastRoomPrice = Math.ceil(this.averageSales);
                    for (let i = 0; i < roomNum; i++) {
                        if (checkDateList.length == cIndex + 1 && roomNum == i + 1) {
                            // 最后一项
                            averageSalesArr.push(this.lastAverageSales);
                        } else {
                            averageSalesArr.push(defaultRoomPrice);
                        }

                    }
                }
                let roomPriceList = [];
                for (let i = 0; i < roomNum; i++) {
                    roomPriceList.push({
                        supplierPrice: averagePurchaseArr[i],
                        platformPrice: averageSalesArr[i],
                    });
                }

                dayArr.push({
                    date: checkInDate,
                    week: '周' + weekArr[this.$moment(checkInDate).weekday()],
                    supplierPrice: averagePurchaseArr,
                    platformPrice: averageSalesArr,
                    roomPriceList: roomPriceList
                })
            })
            this.form.hotelGenerateOrderPrice.nightlyRateList = dayArr;
            console.log('间夜list：');
            console.log(this.form.hotelGenerateOrderPrice.nightlyRateList);
        },

        //检验间夜价格是否等于总价
        onCheckPrice() {
            let isOk = true;
            let supplierTotalPrice = 0;
            let platformTotalPrice = 0;
            const priceObj = this.form.hotelGenerateOrderPrice;
            const roomNum = this.form.hotelGenerateOrderHotelMsg.roomNum;
            priceObj.nightlyRateList.forEach(item => {
                item.roomPriceList.forEach(value => {
                    supplierTotalPrice += Number(value.supplierPrice);
                    platformTotalPrice += Number(value.platformPrice);
                })

            })
            console.log(supplierTotalPrice, Number(priceObj.supplierPrice))
            if (supplierTotalPrice !== Number(priceObj.supplierPrice)) {
                isOk = false;
                this.$message({
                    type: "warning",
                    message: '采购间夜价总和*房间数需等于采购总价！'
                });
            } else if (platformTotalPrice !== Number(priceObj.orderAmount)) {
                isOk = false;
                this.$message({
                    type: "warning",
                    message: '销售间夜价总和*房间数需等于销售总价！'
                });
            }
            return isOk
        },

        //改变退改规则
        changeRefundRuleType() {
            if (this.form.refundRule.refundRuleType === 2 && !this.form.refundRule.refundDate) {
                this.form.refundRule.refundDate = this.form.hotelGenerateOrderHotelMsg.checkInDate + ' 18:00:00';
            }
        },

        //保存
        async onSubmit() {
            let isPass = false;
            let unValidObj = null;

            // 动态表单
            let formInfo = this.$refs.aDynamicForm.getData();
            if (this.form.customerInfo.evectionType === 1) {
                formInfo = {
                    checkForm: true,
                    list: [],
                }
            }
            if (this.form.customerInfo.evectionType === 2) {
                //检验动态表单
                if (!formInfo.checkForm) {
                    const listLength = formInfo.list.length;
                    for (let i = 0; i < listLength; i++) {
                        if (!formInfo.list[i].parameterForm) {
                            const text = `请填写${formInfo.list[i].parameterText}`;
                            this.$message({
                                type: "warning",
                                message: text
                            });
                            break
                        }
                    }
                    isPass = false
                    return
                }
            }

            //表单检验
            this.$refs.form.validate((valid, obj) => {
                isPass = valid;
                unValidObj = obj;
            });

            //检验间夜价格是否等于总价
            if (!this.onCheckPrice()) {
                return;
            }

            if (isPass) {
                // 校验新增出行人信息是否重复
                let checkRepeatPsg = [];
                this.form.hotelGenerateOrderPeoples.forEach((value, index) => {
                    if (!value.userId && value.certificatesNumber !== '') {
                        let psg = {'psgName': value.name, 'psgPhone': value.phone, 'cardType': value.certificatesType, 'cardNo': value.certificatesNumber};
                        checkRepeatPsg.push(psg);
                    }
                });
                if (0 === checkRepeatPsg.length) {
                    // 计算销售总价差价
                    this.orderDifAmount = this.form.hotelGenerateOrderPrice.orderAmount - this.oldOrderAmount;
                    console.log( "差价:" + this.orderDifAmount);
                    if (this.oldOrderStatus === 11
                        && this.orderDifAmount != 0) {
                        let confirmMsg = '';
                        if (this.orderDifAmount > 0) {
                            // 需进行补收
                            confirmMsg = '本次编辑产生' + this.orderDifAmount + '元差价需补收，是否继续？'
                        } else {
                            // 需进行补退
                            confirmMsg = '本次编辑产生' + -this.orderDifAmount + '元差价需补退，是否继续？'
                        }
                        this.$confirm(confirmMsg, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            // 保存编辑内容并跳转收银台
                            this.form.hotelGenerateOrderPrice.editPriceDifference = this.orderDifAmount;
                            this.form.reportConfiguration = formInfo.list;
                            this.addOrder(3, formInfo);
                        }).catch(() => {
                        });
                    } else if (this.oldOrderStatus === 0 || this.oldOrderStatus === '') {
                        this.form.reportConfiguration = formInfo.list;
                        this.addOrder(1, formInfo);
                    } else {
                        // 已出房且不产生差价，-1不跳转收银台
                        this.addOrder(-1, formInfo);
                    }
                } else {
                    const checkParams = {
                        companyId: this.form.customerInfo.companyId,
                        psgList: checkRepeatPsg
                    };
                    this.loading = true;
                    consumer_admin_flight_generate_checkPsgRepeatOrNot(checkParams).then(res => {
                        this.loading = false;
                        const returnMsg = res.datas;
                        if (returnMsg && returnMsg.length != 0) {
                            let confirmMsg = ``;
                            res.datas.forEach((value, index) => {
                                confirmMsg = `${confirmMsg}${value}</br>`;
                            });
                            this.$confirm(confirmMsg, '提示', {
                                confirmButtonText: '继续生单',
                                cancelButtonText: '取消',
                                type: 'warning',
                                dangerouslyUseHTMLString: true
                            }).then(() => {
                                // 计算销售总价差价
                                this.orderDifAmount = this.form.hotelGenerateOrderPrice.orderAmount - this.oldOrderAmount;
                                console.log( "差价:" + this.orderDifAmount);
                                if (this.oldOrderStatus === 11 && this.orderDifAmount != 0) {
                                    let confirmMsg = '';
                                    if (this.orderDifAmount > 0) {
                                        // 需进行补收
                                        confirmMsg = '本次编辑产生' + this.orderDifAmount + '元差价需补收，是否继续？'
                                    } else {
                                        // 需进行补退
                                        confirmMsg = '本次编辑产生' + -this.orderDifAmount + '元差价需补退，是否继续？'
                                    }
                                    this.$confirm(confirmMsg, '提示', {
                                        confirmButtonText: '确定',
                                        cancelButtonText: '取消',
                                        type: 'warning'
                                    }).then(() => {
                                        // 保存编辑内容并跳转收银台
                                        this.form.hotelGenerateOrderPrice.editPriceDifference = this.orderDifAmount;
                                        this.form.reportConfiguration = formInfo.list;
                                        this.addOrder(3, formInfo);
                                    }).catch(() => {
                                    });
                                } else if (this.oldOrderStatus === 0 || this.oldOrderStatus === '') {
                                    this.form.reportConfiguration = formInfo.list;
                                    this.addOrder(1, formInfo);
                                } else {
                                    // 已出房且不产生差价，-1不跳转收银台
                                    this.addOrder(-1, formInfo);
                                }
                            }).catch(() => {
                            });
                        } else {
                            this.orderDifAmount = this.form.hotelGenerateOrderPrice.orderAmount - this.oldOrderAmount;
                            if (this.oldOrderStatus === 11 && this.orderDifAmount != 0) {
                                let confirmMsg = '';
                                if (this.orderDifAmount > 0) {
                                    // 需进行补收
                                    confirmMsg = '本次编辑产生' + this.orderDifAmount + '元差价需补收，是否继续？'
                                } else {
                                    // 需进行补退
                                    confirmMsg = '本次编辑产生' + -this.orderDifAmount + '元差价需补退，是否继续？'
                                }
                                this.$confirm(confirmMsg, '提示', {
                                    confirmButtonText: '确定',
                                    cancelButtonText: '取消',
                                    type: 'warning'
                                }).then(() => {
                                    // 保存编辑内容并跳转收银台
                                    this.form.hotelGenerateOrderPrice.editPriceDifference = this.orderDifAmount;
                                    this.form.reportConfiguration = formInfo.list;
                                    this.addOrder(3, formInfo);
                                }).catch(() => {
                                });
                            } else if (this.oldOrderStatus === 0 || this.oldOrderStatus === '') {
                                this.form.reportConfiguration = formInfo.list;
                                this.addOrder(1, formInfo);
                            } else {
                                // 已出房且不产生差价，-1不跳转收银台
                                this.addOrder(-1, formInfo);
                            }
                        }
                    }).finally(() => {
                        this.loading = false
                    })
                }

            } else {
                this.onShowErrorMessage(unValidObj);
            }
        },

        //创建订单
        addOrder(payType, formInfo) {
            this.submitLoading = true;
            this.loading = true;
            let orderNo = this.orderNo ? this.orderNo : '';
            const behalfCompanyId = this.form.customerInfo.companyId;
            let businessClass = this.businessClassList.find(item => {
                return item.code === this.form.hotelGenerateOrderHotelMsg.businessClassCode;
            })
            this.form.hotelGenerateOrderHotelMsg.businessClass = businessClass.msg;

            let body = JSON.parse(JSON.stringify(this.form));
            body.reportConfiguration = formInfo.list;
            body.reportConfiguration.forEach((value) => {
                if (value.parameter == 'GuiShuXiangMu') {
                    body.projectCode = value.parameterValue;
                    body.projectName = value.parameterText;
                }
            });
            body.reportConfiguration = body.reportConfiguration.filter(value => {return value.parameter != 'GuiShuXiangMu'});

            saveOrder(body)
                .then(res => {
                    const orderResult = res.datas.orderResult || {};
                    orderNo = orderResult ? orderResult.orderNo : '';
                    let isSuccess = orderResult ? orderResult.isSuccess : false;
                    if (isSuccess) {
                        this.$message({
                            type: "success",
                            message: '保存成功'
                        })
                        let params = {
                            orderNo,
                            hotelRemark: this.remark
                        };
                        return saveRemark(params);
                    }
                })
                .then(() => {
                    if ((payType === 1)
                        || (payType === 3 &&  this.orderDifAmount > 0)) {
                        this.toPay(orderNo, behalfCompanyId, payType);
                    } else if (payType === -1) {
                        // 跳转订单列表
                        this.$router.replace({
                            name: 'admin-hotel-order-sold'
                        })
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.submitLoading = false;
                })
        },

        //表单检验后，提示需要充填的信息
        onShowErrorMessage(unValidObj) {
            let tipsStr = '';
            const unValidKeys = Object.keys(unValidObj);

            unValidKeys.forEach((item, index) => {
                let tmpList = item.split('.');
                let len = tmpList.length;
                if (index > 0) {
                    tipsStr += '；</p>';
                }

                if (len === 2) {
                    tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}-${tipsObj[tmpList[1]]}`;
                }
                if (len === 3) {
                    tipsStr += `<p style="color: red;">${tipsObj[tmpList[0]]}第${Number(tmpList[1]) + 1}行-${tipsObj[tmpList[2]]}`;
                }
            });
            tipsStr += '；</p>';

            this.$alert(tipsStr, '以下信息未填写', {
                confirmButtonText: '确定',
                dangerouslyUseHTMLString: true,
            });
        },

        //跳转至支付页
        toPay(orderNo, behalfCompanyId, payType) {
            this.$router.push({
                name: 'admin-hotel-create-order-pay',
                query: {
                    orderNo,
                    behalfCompanyId,
                    payType,
                }
            })
        },

        //返回
        onBack() {
            this.$router.go(-1);
        },

        //选中oa单，回显入住人，禁止修改房间数和入住人
        handleSelectCallback(value) {
            if (this.type === 'edit') {
                let hasOaUser = false;
                this.form.passengerList.forEach(val=>{
                    if (val.userId === value.userId) {
                        hasOaUser = true;
                    }
                })
                if (!hasOaUser) {
                    this.$message.warning('不允许选择该OA单号');
                    this.$refs.aDynamicForm.clearOa();
                }
                return;
            }
            //oa单目前只会带出一个入住人, eg.{name: '张磊', userId: 22881}
            this.selectedIdList = [value.userId];
            this.form.hotelGenerateOrderHotelMsg.roomNum = 1;
            let user = {};
            let dataBox = {
                companyId: this.form.customerInfo.companyId,
                currentPage: 1,
                isSelectCustomer: true,
                key: '',
                pageSize: 10,
                userIds: [value.userId],
            };
            this.form.hotelGenerateOrderPeoples = [];
            consumer_department_getStaffsAndExCusByUserIds(dataBox).then((res) => {
                res.datas.list.forEach((item) => {
                    if (item.userId == value.userId) {
                        user = {
                            userId: item.userId,
                            name: item.name,
                            certificatesType: item.certificateType,
                            cardTypeText: item.certificateName,
                            certificatesNumber: item.certificateValue,
                            nationality: item.countryCode,//国籍
                            phone: item.phone,
                        };
                        this.form.hotelGenerateOrderPeoples.push(user);
                    }
                })
            });
            this.isDisabledRoomAmount = true;
            this.isDisabledTraveler = true;
        },

        //删除oa单，清除入住人，可修改房间数和入住人
        handleSelectDelete() {
            if (this.type === 'edit') {
                return;
            }
            this.selectedIdList = [];
            this.form.hotelGenerateOrderHotelMsg.roomNum = 1;
            this.form.hotelGenerateOrderPeoples = [{
                userId: '',
                name: '',
                certificatesType: 1,
                cardTypeText: '身份证',
                certificatesNumber: '',
                nationality: 'CN',//国籍
                phone: '',//手机号码
            }];
            this.isDisabledRoomAmount = false;
            this.isDisabledTraveler = false;
        },
        getNewUser(newUser) {
            const newItem = {
                userId: newUser.id,
                name: newUser.name,
                certificatesType: newUser.certificateType,
                cardTypeText: '',
                certificatesNumber: newUser.certificateValue,
                nationality: newUser.countryCode,
                phone: newUser.phone,
                tags: [],
                certificates: [
                    {
                        "certificateValue": newUser.certificateType,
                        "certificateText": "",
                        "certificate": newUser.certificateValue,
                        "unSensitiveCertificateValue": newUser.certificateValue,
                        "countryCode": newUser.countryCode,
                        "countryName": "",
                        "issCountryCode": newUser.issCountryCode,
                        "issCountryName": "",
                        "cardExpireDate": newUser.passengerExpireDate
                    }
                ]
            };
            let _index = this.canSetNum();
            if (_index === -1) {
                this.form.hotelGenerateOrderPeoples.push(newItem);
            } else {
                let hotelGenerateOrderPeoples = JSON.parse(JSON.stringify(this.form.hotelGenerateOrderPeoples));
                hotelGenerateOrderPeoples[_index] = newItem;
                this.form.hotelGenerateOrderPeoples = hotelGenerateOrderPeoples;
            }

        },
        getUserList(args) {
            //当次选中的入住人list
            let selectedList = args.activeDetailList ? JSON.parse(JSON.stringify(args.activeDetailList)) : [];
            if (selectedList && selectedList.length > 0) {
                let travelerItem = {};
                let blankTravelerList = [];//空白房间行list
                let chosenTravelerList = [];//已选入住人list
                const roomNum = this.form.hotelGenerateOrderHotelMsg.roomNum;//总房间数
                let travelerNum = this.form.hotelGenerateOrderPeoples.length;//入住人数量（包括空白行）
                let travelerList = JSON.parse(JSON.stringify(this.form.hotelGenerateOrderPeoples));//入住人list（包括空白行）

                travelerList.forEach((traveler, index) => {
                    if (traveler.name === '' && traveler.certificatesNumber === '' && traveler.phone === '') {
                        //找到现有空白行list
                        travelerItem = {
                            index: index,
                            name: ''
                        }
                        blankTravelerList.push(travelerItem);
                    } else if (traveler.userId !== '') {
                        //找到是从组件中选出的入住人list
                        travelerItem = {
                            index: index,
                            name: ''
                        }
                        chosenTravelerList.push(travelerItem);
                    }
                })

                let item = {};
                //如果此次只选中一位入住人且全部为空白行，则将该入住人填入所有空白行
                if (selectedList.length === 1 && blankTravelerList.length === travelerList.length) {
                    item = {
                        userId: selectedList[0].userId,
                        name: selectedList[0].name,
                        certificatesType: selectedList[0].certificateType,
                        cardTypeText: selectedList[0].certificateName,
                        certificatesNumber: selectedList[0].certificateValue,
                        nationality: selectedList[0].countryCode ? selectedList[0].countryCode : 'CN',//国籍
                        phone: selectedList[0].phone,
                        tags: selectedList[0].tags,
                        certificates: selectedList[0].certificates || [],
                    };
                    for (let i = 0; i < blankTravelerList.length; i++) {
                        travelerList[i] = item;
                    }
                    this.form.hotelGenerateOrderPeoples = [];
                    travelerList.forEach(traveler => {
                        this.form.hotelGenerateOrderPeoples.push(traveler);
                    })
                    return;
                }

                //如果有空白行list,先将选中的入住人填入空白行
                if (blankTravelerList) {
                    blankTravelerList.forEach(bTraveler => {
                        if (selectedList.length > 0) {
                            item = {
                                userId: selectedList[0].userId,
                                name: selectedList[0].name,
                                certificatesType: selectedList[0].certificateType,
                                cardTypeText: selectedList[0].certificateName,
                                certificatesNumber: selectedList[0].certificateValue,
                                nationality: selectedList[0].countryCode ? selectedList[0].countryCode : 'CN',//国籍
                                phone: selectedList[0].phone,
                                tags: selectedList[0].tags,
                                certificates: selectedList[0].certificates || [],
                            };
                            travelerList[bTraveler.index] = item;
                            selectedList.splice(0, 1);
                        }
                    })
                }

                //如果填完空白行，此次还有未填入的入住人
                if (selectedList && selectedList.length > 0) {
                    //如果已有行数小于总房间数，则增加相应房间，并填入入住人
                    //如果填入补加的空行后，还有未填入的入住人，不自动替换之前已选的入住人
                    if (travelerNum < roomNum || this.form.hotelGenerateOrderHotelMsg.groupTicket === 1) {
                        let max = (roomNum - travelerNum) > selectedList.length ? selectedList.length : (roomNum - travelerNum);
                        // 如果是团房
                        if (this.form.hotelGenerateOrderHotelMsg.groupTicket === 1) {
                            max = selectedList.length;
                        }
                        for (let i = 1; i < max + 1; i++) {
                            this.addTraveler();
                            item = {
                                userId: selectedList[0].userId,
                                name: selectedList[0].name,
                                certificatesType: selectedList[0].certificateType,
                                cardTypeText: selectedList[0].certificateName,
                                certificatesNumber: selectedList[0].certificateValue,
                                nationality: selectedList[0].countryCode ? selectedList[0].countryCode : 'CN',//国籍
                                phone: selectedList[0].phone,
                                tags: selectedList[0].tags,
                                certificates: selectedList[0].certificates || [],
                            };
                            travelerList.push(item);
                            selectedList.splice(0, 1);
                        }
                    } else {
                        //如果已有房间数等于总房间数，则从上至下替换掉已选的入住人
                        chosenTravelerList.forEach(bTraveler => {
                            if (selectedList.length > 0) {
                                item = {
                                    userId: selectedList[0].userId,
                                    name: selectedList[0].name,
                                    certificatesType: selectedList[0].certificateType,
                                    cardTypeText: selectedList[0].certificateName,
                                    certificatesNumber: selectedList[0].certificateValue,
                                    nationality: selectedList[0].countryCode ? selectedList[0].countryCode : 'CN',//国籍
                                    phone: selectedList[0].phone,
                                    tags: selectedList[0].tags,
                                    certificates: selectedList[0].certificates || [],
                                };
                                travelerList[bTraveler.index] = item;
                                selectedList.splice(0, 1);
                            }
                        })
                    }
                }

                this.form.hotelGenerateOrderPeoples = [];
                travelerList.forEach(traveler => {
                    this.form.hotelGenerateOrderPeoples.push(traveler);
                })
            }
        },
        getUserListToOne(args) {
            args.activeDetailList.forEach(value => {
                const item = {
                    userId: value.userId,
                    name: value.name,
                    certificatesType: value.certificateType,
                    cardTypeText: value.certificateName,
                    certificatesNumber: value.certificateValue,
                    nationality: value.countryCode ? value.countryCode : 'CN',//国籍
                    phone: value.phone,
                    tags: value.tags
                };
                let travelerList = JSON.parse(JSON.stringify(this.form.hotelGenerateOrderPeoples));
                this.form.hotelGenerateOrderPeoples = [];
                travelerList[this.curIndex] = item;
                travelerList.forEach(traveler => {
                    this.form.hotelGenerateOrderPeoples.push(traveler);
                })
            })
        },
    }
}

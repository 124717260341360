export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/global-query-test/page-001',
            name: 'admin-global-query-test-page-001',
            component: () => import(/* webpackChunkName: "page-admin-default-global-query-parameter-test-page-001" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `page-001`,
                },
                entity_list_for_breadcrumb: [
                    {name: `page-001`},
                    {name: `指定路径自动传入全局设置的query参数`},
                    {name: `主页`},
                ],
            },
        },
    ],
};

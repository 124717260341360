export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/mocha/core',
            name: 'admin-mocha-core',
            component: () => import(/* webpackChunkName: "page-admin-mocha-core" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `core`,
                },
                entity_list_for_breadcrumb: [
                    {name: `mocha`},
                    {name: `core`},
                ],
            },
        },
    ],
};

export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/intention/handle',
            name: 'admin-intention-handle',
            component: () => import(/* webpackChunkName: "page-admin-intention-handle" */ `./1.0.1/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `意向单详情`,
                },
                entity_list_for_breadcrumb: [
                    { name: `意向单管理` },
                    { name: `意向单列表` },
                    { name: `去处理` },
                ],
            },
        },
    ],
};

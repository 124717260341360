export default {
    // 采购渠道
    order: {
        id: null,
        gmtCreate: null,
        gmtModified: null,
        orderNo: '',   //订单号
        orderSource: '',   //采购渠道
        supOrderNo: '',    //供应商订单号
        paymentType: '',    //付款方式
        paymentTime: '',    //付款时间 yyyy-MM-dd HH:mm:ss
        paymentNum: '',     //付款流水号
        status: null,      //订单状态
        salesPrice: null,     //销售价
        supPrice: null,      //采购价
        serviceFee: 0,      //服务费
        fine: 0,           //罚金
        fineTime: '',       //取消时间 yyyy-MM-dd HH:mm:ss
        remark: '',         //备注
        createBy: null,      //创建用户
        modifiedBy: null,     //更新用户
        modifiedName: '',     //更新用户名

        profit: null   //利润
    },
    // 客户基础信息
    companyInfo:  {
        id: null,
        orderId: null,
        companyId: null,      //所属企业
        contactName: '',      //联系人姓名
        contactMobile: '',    //联系人手机
        evectionType: 2,      //出行类型 1.因私出行，2.因公出差
        payType: 1,
    },
    journeyInfo: {
        id: null,
        orderId: null,     //所属订单号id
        companyId: null,   //所属企业
        carUseType: '',      //用车类型 见字典carUseType
        defCarType: '',      //车型 见字典 defCarType
        setOut: '',      //出发
        reach: '',      //到达
        useCarTime: '',      //用车时间
        useCarPeople: '',      //用车人
        driverName: '',      //司机名
        driverPhone: '',      //司机电话
        carNumber: '',      //车牌号
        carType: '',      //车辆类型
        carColor: '',      //车辆颜色
    },
    flightInfo: {
        journeyId: null,
        orderId: null,      //订单id
        flightNumber: '',      //航班号
        depAirport: '',      //起飞机场
        depAirportNo: '',      //起飞机场--三字码
        depTerminal: '',      //起飞航站楼
        arrAirport: '',      //到达机场
        arrAirportNo: '',      //到达机场--三字码
        arrTerminal: '',      //到达航站楼
        depTime: '',      //起飞时间
        arrTime: '',      //到达时间
    },
}

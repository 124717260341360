export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/train-change-offline-edit',
            name: 'admin-train-change-offline-edit',
            component: () => import(/* webpackChunkName: "page-admin-order-management-train-order-change-offline-edit" */ `./1.0.0/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `线下火车票改签申请`,
                },
                entity_list_for_breadcrumb: [
                    {name: `火车票订单管理`},
                    {name: `线下火车票改签编辑`},
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        return `admin-train-change-list`;
                    },
                },
            },
        },
    ],
};

import Pagination from '@/component/base-pagination/index.vue'
import DomesticTicketAirportSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.2.2/index.vue'
import consumer_admin_trains_order_queryTrainsOperationReportOrderList from '@/lib/data-service/haolv-default/consumer_admin_trains_order_queryTrainsOperationReportOrderList'
import consumer_admin_trains_order_getTrainsOperationReportExcel from '@/lib/data-service/haolv-default/consumer_admin_trains_order_getTrainsOperationReportExcel' // 导出

export default {
    data () {
        return {
            gmtPracticalPayment: '',
            params: {
                totalCount: 0,
                currentPage: 1,
                pageSize: 10,
                orderNo: '',
                partnerOrderNo: '',
                companyName: '',
                buyerName: '',
                gmtPracticalPaymentStart: '',
                gmtPracticalPaymentEnd: '',
                startDate: '',
                arriveDate: '',
                fromStationName: '',
                toStationName: '',
            },
            loading: false,
            payList: [
                {value: '', label: '所有'},
                {value: 'ALIPAY', label: '支付宝支付'},
                {value: 'WECHAT', label: '微信支付'},
                {value: 'YEEPAY', label: '易宝支付'},
                {value: 'CREDIT', label: '授信支付'},
                {value: 'UNIONPAY', label: '网银支付'},
                {value: 'B2B_CHINAPAY', label: '企业银联支付'},
                {value: 'B2C_CHINAPAY', label: '个人银联支付'},
                {value: 'PERSON_UNIONPAY', label: '个人网银支付'},
                {value: 'CREDIT_UNIONPAY', label: '个人信用卡网银支付'},
                {value: 'YPAY', label: '胤钱宝支付'},
            ],
            list: [],
            fileUrl: '',
            canSubmit: true,
        }
    },
    components: {
        Pagination,
        DomesticTicketAirportSelector
    },
    created () {},
    mounted () {},
    activated () {
        this.reset()
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        changeTime(val) {
          if (val === '' || val === null) {
            this.params.gmtPracticalPaymentStart = '';
            this.params.gmtPracticalPaymentEnd = '';
          } else {
            this.params.gmtPracticalPaymentStart = val[0] + ' 00:00:00';
            this.params.gmtPracticalPaymentEnd = val[1] + ' 23:59:59';
          }
        },
        search() {
            this.params.currentPage = 1;
            this.getList()
        },
        reset() {
            this.gmtPracticalPayment = '';
            this.params = {
                totalCount: 0,
                currentPage: 1,
                pageSize: 10,
                orderNo: '',
                partnerOrderNo: '',
                companyName: '',
                buyerName: '',
                gmtPracticalPaymentStart: '',
                gmtPracticalPaymentEnd: '',
                startDate: '',
                arriveDate: '',
                fromStationName: '',
                toStationName: '',
            };
            this.search()
        },
        getList() {
            this.loading = true;
            consumer_admin_trains_order_queryTrainsOperationReportOrderList(this.params).then(res => {
                this.loading = false;
                this.list = res.datas.pageData;
                this.params.totalCount = res.datas.totalCount
            }).catch(() => {
                this.loading = false;
                this.list = [];
                this.params.totalCount = 0
            })
        },
        toPreview() {
            if (!this.canSubmit) {
                return
            }
            const _req = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
            const _http = document.location.protocol + '//';
            consumer_admin_trains_order_getTrainsOperationReportExcel(this.params).then(res => {
                this.canSubmit = true;
                if (res.datas) {
                    if (_req.test(res.datas)) { // 判断返回链接是否存在http://或https://
                        this.fileUrl = res.datas;
                    } else {
                        this.fileUrl = _http + res.datas;
                    }
                    this.$nextTick(() => {
                        this.$refs.downloadBtn.click()
                    })
                } else {
                    this.$message.error('导出失败！');
                }
            }).catch(() => {
                this.canSubmit = true
            })
        },
    }
}

export default {
    name: 'resetPasswordDialog',
    data () {
        return {
            dialogResetVisible: this.value
        }
    },
    props: {
        value: {
            type: Boolean
        },
        tipsText: {
            type: String,
            default: '是否确认重置密码，恢复默认密码abc123456？'
        }
    },
    components: {},
    created () {},
    mounted () {},
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {
        value(newVal) {
            this.dialogResetVisible = newVal
        },
        dialogResetVisible(newVal) {
            this.$emit('input', newVal)
        },
    },
    computed: {},
    filters: {},
    methods: {
        submitReset(val) {
            this.$emit('submitReset', val)
        },
    }
}

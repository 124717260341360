// report_tRpTmcFlightItinerary_v1_upd
const __request = require(`./__request/__request_contentType_json`)
const report_tRpTmcFlightItinerary_v1_upd = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/tRpTmcFlightItinerary/v1/upd',
        data: data
    };
    return __request(pParameter)
};

export default report_tRpTmcFlightItinerary_v1_upd
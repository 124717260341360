import OrderLog from '@/component/orderLog/2.0.0/index.vue'
import consumer_admin_flight_order_manualTicket from '@/lib/data-service/haolv-default/consumer_admin_flight_order_manualTicket'
import consumer_admin_flight_order_exceptionDetails from '@/lib/data-service/haolv-default/consumer_admin_flight_order_exceptionDetails'
import consumer_user_info from '@/lib/data-service/haolv-default/consumer_user_info'
import consumer_air_ticket_fligthRules from '@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules'
import ChangeClausePopup from '@/page/admin/replace-orders/component/changeClausePopup/1.0.0/index.vue'

export default {
    data() {
        return {
            canManualTicket: true,
            ruleLoading: false,
            ruleShow: false,
            ruleText:{},
            rules:{
                purchaseprice: [{validator: this.purchasepriceRule,trigger: 'blur'}],
                rebateMoney: [{validator: this.rebateMoneyRule,trigger: 'blur'}],
                ticketNo: [{validator: this.initialTicketNumberRule,trigger: 'blur'}],
                pnr: [{validator: this.pnrRule,trigger: 'blur'}],
                failureReason: [{validator: this.issueTicketReasonRule,trigger: 'blur'}],
            },
            ruleForm:{
                ticketStatus: '1', //出票状态
                ticketOperator: '', //出票员
                ticketOperatorId: '', //出票员id
                failureReason: '' //出票失败原因
            },
            remark: '', //备注
            orderDetail:[
                {
                    abbreviation: '', //所属企业
                    amount: null, //应付金额
                    contactMobile: '', //联系电话
                    contactName: '', //联系人
                    evectionNo: '', //出差单号
                    evectionType: null, //1.因私，2.因公
                    evectionTypeText: '', //因私/因公
                    failureReason: '', //异常原因
                    initialName: '', //操作客服
                    lastPayTime: '', //支付时间
                    orderClient: '', //下单客户端
                    orderNo: '', //订单号
                    orderSource: '', //订单来源
                    orderStatus: '',
                    orderStatusText: '', //订单状态
                    orderTime: '', //预定时间
                    orderType: '', //订单类型
                    payAmount: null, //实付金额
                    paymentChannel: '',
                    paymentChannelText: '', //支付方式
                    ticketTime: '', //出票时间
                    totalPriceAll: null, //订单总价
                }
            ],
            passengerList:[
                {
                    segmentTypeText: '', //航班类型
                    psgName: '', //姓名
                    psgTypeText: '', //乘客类型
                    cardTypeText: '', //证件类型
                    cardNo: '', //证件号码
                    birthday: '', //出生日期
                    mobile: '', //联系电话
                }
            ],
            purchasingList:[
                {
                    segmentTypeText: '', //航班类型
                    psgName: '', //姓名
                    psgTypeText: '', //乘客类型
                    ticketChannel: '', //出票渠道
                    totalPrice: null, //票面价
                    airportTax: null, //基建
                    fuelTax: null, //燃油
                    serviceFee: null, //服务费
                    totalAmount: null, //总金额
                    rebate: null, //返佣
                    rebateMoney: null, //返佣--填写
                    purchaseprice: '', //采购价
                    ticketNo: '', //票号
                    pnr: '', //PNR
                    psgNo: '', //乘机人编号
                    segmentNo: '', //航段号
                },
                {
                    segmentTypeText: '', //航班类型
                    psgName: '', //姓名
                    psgTypeText: '', //乘客类型
                    ticketChannel: '', //出票渠道
                    totalPrice: null, //票面价
                    airportTax: null, //基建
                    fuelTax: null, //燃油
                    serviceFee: null, //服务费
                    totalAmount: null, //总金额
                    rebate: null, //返佣
                    rebateMoney: null, //返佣--填写
                    purchaseprice: '', //采购价
                    ticketNo: '', //票号
                    pnr: '', //PNR
                    psgNo: '', //乘机人编号
                    segmentNo: '', //航段号
                },
            ],
            segmentList:[
                {
                    segmentTypeText: '', //航班类型
                    keyCustomerCode: '', //大客户编号
                    flightNo: '', //航班号
                    airlineName: '', //航空公司名称
                    depCityName: '', //出发城市名称
                    depCityCode: '', //出发城市三字码
                    depAirportName: '', //出发机场名称
                    depTerminal: '', //出发航站楼
                    arrCityName: '', //到达城市
                    arrCityCode: '', //到达城市三字码
                    arrAirportName: '', //到达机场名称
                    arrTerminal: '', //到达航站楼
                    depTime: '', //出发时间
                    arrTime: '', //到达时间
                    cabinCode: '', //到达时间
                    cabinName: '', //到达时间
                    duration: '', //飞行时长
                    discount: '', //折扣
                    segmentNo: '', //航段号
                }
            ],
            activeName: '1'
        }
    },
    components: {
        OrderLog,
        ChangeClausePopup
    },
    created() {
    },
    mounted() {
    },
    activated() {
        this.getData();
        this.getUserData();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        //退票改签规则按钮
        showRefundChangeClause(val){
            this.ruleText = {};
            this.ruleShow = true;
            let data = {
                segmentNo: val.segmentNo
            };
            this.ruleLoading = true;
            consumer_air_ticket_fligthRules(data).then(res => {
                this.ruleLoading = false;
                this.ruleText = res.datas.rsData;
            }).catch(() => {
                this.ruleLoading = false
            })
        },
        getUserData(){
            consumer_user_info().then(res => {
                this.ruleForm.ticketOperator = res.datas.username;
                this.ruleForm.ticketOperatorId = res.datas.staffId;
            })
        },
        //请求数据
        getData(){
            let data = {
                orderNo: this.$route.query.orderNo,
            };
            consumer_admin_flight_order_exceptionDetails(data).then(res => {
                this.orderDetail = [
                    {
                        abbreviation: res.detail.abbreviation,
                        amount: res.detail.amount,
                        contactMobile: res.detail.contactMobile,
                        contactName: res.detail.contactName,
                        evectionNo: res.detail.evectionNo,
                        evectionType: res.detail.evectionType,
                        evectionTypeText: res.detail.evectionTypeText,
                        failureReason: res.detail.failureReason,
                        initialName: res.detail.initialName,
                        lastPayTime: res.detail.lastPayTime,
                        orderClient: res.detail.orderClient,
                        orderNo: res.detail.orderNo,
                        orderSource: res.detail.orderSource,
                        orderStatus: res.detail.orderStatus,
                        orderStatusText: res.detail.orderStatusText,
                        orderTime: res.detail.orderTime,
                        orderType: res.detail.orderType,
                        payAmount: res.detail.payAmount,
                        paymentChannel: res.detail.paymentChannel,
                        paymentChannelText: res.detail.paymentChannelText,
                        ticketTime: res.detail.ticketTime,
                        totalPriceAll: res.detail.totalPriceAll,
                    }
                ];
                this.passengerList = res.detail.passengerList;
                let purchasingListData = res.detail.purchasingList;
                purchasingListData.forEach((item,index) => {
                    item.rebateMoney = item.rebate;
                });
                this.purchasingList = purchasingListData;
                this.segmentList = res.detail.segmentList;
                this.remark = res.detail.remark;
            })
        },
        clickKeep(){
            if (this.ruleForm.ticketStatus == 1) {
                let test = [];
                this.$refs.ruleForm.forEach((item,index) => {
                    item.validate((valid) => {
                        if (valid) {
                            test.push(true);
                        } else {
                            test.push(false);
                            console.log('error submit!!');
                            return false;
                        }
                    });
                });

                if (test.indexOf(false) == -1) {
                    this.keepManualTicket();
                }
            } else {
                this.$refs['ruleFormTwo'].validate((valid) => {
                    if (valid) {
                        this.keepManualTicket();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        },
        //生成n位数字
        generateMixed(n) {
            let chars = ['0','1','2','3','4','5','6','7','8','9'];
            let res = "";
            for(let i = 0; i < n ; i++) {
                let id = Math.floor(Math.random()*10);
                res += chars[id];
            }
            return res;
        },
        //手工出票保存方法
        keepManualTicket(){
            if (!this.canManualTicket) {
                return
            }
            this.canManualTicket = false;
            let ticketOperationInfos = [];
            this.purchasingList.forEach((item,index) => {
                ticketOperationInfos.push({
                    pnr: item.pnr,
                    psgNo: item.psgNo,
                    purchasePrice: item.purchaseprice,
                    segmentNo: item.segmentNo,
                    ticketNo: item.ticketNo,
                    rebateMoney: item.rebateMoney,
                });
            });
            let data = {
                failureReason: this.ruleForm.failureReason,
                orderNo: this.$route.query.orderNo,
                ticketOperationInfos,
                remark: this.remark,
                ticketStatus: this.ruleForm.ticketStatus,
            };
            try {
                consumer_admin_flight_order_manualTicket(data).then(res => {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    let num = this.generateMixed(6);
                    setTimeout(() => {
                        this.$router.push({
                            name: 'admin-flight-order-list',
                            query:{
                                rd: num,
                            }
                        });
                    },1000)
                }).catch(res => {
                    this.$message.error('保存失败');
                }).finally(()=>{
                    this.canManualTicket = true;
                })
            }catch (e) {
                this.canManualTicket = true;
            }
        },
        changeSelect(data){
            this.$refs.ruleForm.forEach((item,index) => {
                item.clearValidate();
            });
            this.$refs.ruleFormTwo.clearValidate();
        },
        issueTicketReasonRule(rule,value,callback){
            if (!value) {
                callback(new Error('请选择出票失败原因'))
            }
            callback()
        },
        rebateMoneyRule(rule,value,callback){
            if (value === '') {
                callback(new Error('请填写返佣'))
            }
            if (value < 0) {
                callback(new Error('返佣不能为负数'))
            }
            callback()
        },
        purchasepriceRule(rule,value,callback){
            if (!value) {
                callback(new Error('请填写采购价'))
            }
            callback()
        },
        initialTicketNumberRule(rule,value,callback){
            if (!value) {
                callback(new Error('请填写票号'))
            }
            callback()
        },
        pnrRule(rule,value,callback){
            if (!value) {
                callback(new Error('请填写PNR'))
            }
            callback()
        },
        clickGoBack(){
            this.$router.go(-1);
        },
        handleClick(){
            if (this.activeName == 2) {
                this.$refs.orderLog.init({
                    getDataSource: () => {
                        this.loading = true
                        // 调用获取订单日志的接口
                        // return consumer_log_flightList({
                        //     orderNo: this.orderNo
                        // }).then(res => {
                        let res = {
                            datas: [{
                                "operator": "个人测试",
                                "operatorTime": "2021-12-06T15:54:09",
                                "logSource": "1",
                                "logContents": null
                            }, {
                                "operator": "个人测试",
                                "operatorTime": "2021-12-06T15:55:01",
                                "logSource": "1",
                                "logContents": null
                            }, {
                                "operator": "个人测试",
                                "operatorTime": "2021-12-06T15:55:57",
                                "logSource": "1",
                                "logContents": "创建订单成功，订单号：13877698977300"
                            }, {
                                "operator": "个人测试",
                                "operatorTime": "2021-12-06T15:56:08",
                                "logSource": "1",
                                "logContents": "创建订单成功，订单号：13877698977300"
                            }, {
                                "operator": "个人测试",
                                "operatorTime": "2021-12-06T16:02:58",
                                "logSource": "1",
                                "logContents": "调用胤之旅接口，取消订单成功"
                            }]
                        }
                        // 组装组件需要的list
                        const list = res.datas.map(m => {
                            // 替换名字
                            const o = {
                                operator: m.operator,
                                date: m.operatorTime,
                                type: m.logSource,
                                detail: m.logContents
                            }
                            return o
                        })
                        const d = {
                            entityList: list
                        }
                        this.loading = false //临时使用
                        return d || {}
                        // }).catch(() => {
                        //     return []
                        // }).finally(() => {
                        //     this.loading = false
                        // })
                    }
                })
            }
        }
    }
}

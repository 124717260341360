import Q from 'q';
// 封装异步组件
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";
// 客户审核详情
import consumer_web_company_detail from "@/lib/data-service/haolv-default/consumer_web_company_detail.js";
//公司发票类型枚举
import consumerweb_company_queryCompanyInvoiceType from "@/lib/data-service/haolv-default/consumerweb_company_queryCompanyInvoiceType.js";
//获取协议卡等级数据集合
import consumer_web_company_getPnrSetType from "@/lib/data-service/haolv-default/consumer_web_company_getPnrSetType.js";
//获取协议卡等级数据集合
import consumer_web_company_getDCCardLevel from "@/lib/data-service/haolv-default/consumer_web_company_getDCCardLevel.js";
// 活动价枚举
import consumer_hotel_search_getDCRateplanList from '@/lib/data-service/haolv-default/consumer_hotel_search_getDCRateplanList'
//下拉框选择组件
import EmunSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/emun-selector/1.0.0/index.vue';
export default {
    components: {
        EmunSelector,
    },
    data() {
        const checkBusinessLicense = (rule, value, callback) => {
            const reg = /^([159Y]{1})([1239]{1})([0-9ABCDEFGHJKLMNPQRTUWXY]{6})([0-9ABCDEFGHJKLMNPQRTUWXY]{9})([0-9ABCDEFGHJKLMNPQRTUWXY])$/;
            if (!value) {
                return callback(new Error("请输入营业执照"));
            } else if (!reg.test(value)) {
                callback(new Error("请输入正确的营业执照"));
            } else {
                callback();
            }
        };
        return {
            serviceChargeData: true,
            detail: null,
            accessoryUrl: [], // 附件
            src: '',
            invoiceType:[],
            rateplanOptions: [], // 活动价枚举

            formRules: {
                username: [{required: true, message: '请输入账号', trigger: 'blur'}],
                phone: [
                    {required: true, message: '请输入注册手机号码', trigger: 'blur'},
                    {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur'}
                ],
                realName: [{required: true, message: '请输入真实姓名', trigger: 'blur'}],
                companyName: [{required: true, message: '请输入公司名称', trigger: 'blur'}],
                abbreviation: [{required: true, message: '请输入公司简称', trigger: 'blur'}],
                industryId: [{required: true, message: '请选择所属行业'}],
                scale: [{required: true, message: '请选择企业规模'}],
                // companyIntroduce: [{required: true, message: '请输入公司简介'}],
                companyLogo: [{required: true, message: '请上传公司logo'}],
                businessLicenseNum: [
                    {required: true, message: '请输入营业执照号', trigger: 'blur'},
                    {validator: checkBusinessLicense, trigger: 'blur' }
                ],
                businessLicensePic: [{required: true, message: '请上传营业执照'}],
                customerManagerId: [{required: true, message: '请选择客户经理'}],
                carryRules: [{required: true, message: '请选择进位规则'}],
                settlement: [{required: true, message: '请选择服务商'}],
                creditAmount: [{required: true, message: '请输入授信额度'}],
                settlementInterval: [{required: true, message: '请选择结算周期'}],
                creditPaymentDays: [{required: true, message: '请选择账单日'}],
                dueDate: [{required: true, message: '请选择还款日'}],
                overdueDays: [{required: true, message: '请选择可逾期天数'}],
                bankUserName: [{required: true, message: '请输入用户名', trigger: ['blur', 'change']}],
                bankAccount: [{required: true, message: '请输入银行账号', trigger: ['blur', 'change']}],
                openingBank: [{required: true, message: '请选择开户行', trigger: ['blur', 'change']}],
                areaRegion: [{required: true, message: '请选择所在城市', trigger: ['blur', 'change']}],
                openingBankBranch: [{required: true, message: "请输入", trigger: ['blur', 'change']}],
            },
            allocation: {
                provinceList: [], // 省级列表
                cityList: [], // 市级列表
                bankList: [], // 银行列表
                settlementList: [], // 服务商
                industryType: [],
                enterpriseScale: [
                    {value: 1, label: "0~20人"},
                    {value: 2, label: "20~50人"},
                    {value: 3, label: "50~100人"},
                    {value: 4, label: "100~300人"},
                    {value: 5, label: "300~1000人"},
                    {value: 6, label: "1000人以上"},
                ],
                serviceAmountSet: [
                    {label: 0, text: "不收取"},
                    {label: 1, text: "收取"},
                ],
                serviceAmountType: [
                    {label: 1, text: "百分比"},
                    {label: 2, text: "定额"},
                ],
                manager: [],
                carryRules: [
                    {label: 0, text: "进位到元"},
                    {label: 1, text: "进位到分"},
                ],
                audit: [
                    {label: true, text: "开通"},
                    {label: false, text: "不开通"},
                ],
                allowUseCar: [
                    {label: 1, text: "开通"},
                    {label: 0, text: "不开通"},
                ],
                openMemberMt: [
                    {label: 1, text: "开通"},
                    {label: 0, text: "不开通"},
                ],
                setServiceAmountType: [
                    {label: 0, text: "不加价"},
                    {label: 1, text: "加价"},
                ],
                settlementInterval: 5,
                creditPaymentDays: 28,
                dueDate: 30,
                overdueDays: 15,
            },
            selectService: [false, false, false],
            airPriceSetOptions: [
                {value: 1, label: '票面价结算'},
                {value: 2, label: '结算价结算'},
            ],
            pnrSetOptions:[],
            officialPolicyShowSetOptions: [{value: 1, text: '展示'}, {value: 0, text: '不展示'}],

            companyArgeeActiveName: '1',
        }
    },
    methods: {
        inputBlur(index){
            let textBox = null;
            eval(`
                textBox = this.$refs.cardTextRed${index}
            `);
            if (
                this.detail.agreement.agreeInfoList[index].cardNumber == '' &&
                this.detail.agreement.agreeInfoList[index].enable == 1
            ) {
                textBox[0].innerHTML = '请输入协议卡号';
            } else {
                textBox[0].innerHTML = null;
            }
        },
        serviceCharge(){
            let hzCompanyArgeeEnable = this.form.agreement.hzCompanyArgee.enable
            let dcCompanyArgeeEnable = this.form.agreement.dcCompanyArgee.enable
            let ydCompanyArgeeEnable = this.form.agreement.ydCompanyArgee.enable
            let jjCompanyArgeeEnable = this.form.agreement.jjCompanyArgee.enable
            if (hzCompanyArgeeEnable || dcCompanyArgeeEnable || ydCompanyArgeeEnable || jjCompanyArgeeEnable) {
                this.serviceChargeData = true;
            } else {
                this.serviceChargeData = false;
            }
            // let text = [];
            // this.detail.agreement.agreeInfoList.forEach((item,index) => {
            //     text.push(item.enable);
            // });
            // if (text.indexOf(1) != -1) {
            //     this.serviceChargeData = true;
            // } else {
            //     this.serviceChargeData = false;
            // }
        },
        ClickButton(index){
            if (this.detail.agreement.agreeInfoList[index].enable == 1) {
                this.detail.agreement.agreeInfoList[index].enable = 0
            } else {
                this.detail.agreement.agreeInfoList[index].enable = 1
            }
            this.serviceCharge();
        },
        req_detail() {
            const __this = this;
            const loading = this.$loading({
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            consumerweb_company_queryCompanyInvoiceType().then((res) => {
                this.invoiceType = res.datas;
            });
            Q.when()
                .then(function () {
                    return consumer_web_company_detail({id: __this.$route.query.id});
                })
                .then(function (res) {
                    if (!res.datas.agreement.hzCompanyArgee) {
                        res.datas.agreement.hzCompanyArgee = {
                            enable: 0,
                            cardNumber: '',
                            groupType: 6,
                            groupTypeText: '华住',
                        }
                    }
                    if (!res.datas.agreement.dcCompanyArgee) {
                        res.datas.agreement.dcCompanyArgee = {
                            enable: 0,
                            cardNumber: '',
                            groupType: 9,
                            groupTypeText: '东呈',
                            rateplanList: [],
                            rateplanActiveList: [], // 选中的
                        }
                    } else {
                        let rateplanActiveList = []
                        if (res.datas.agreement.dcCompanyArgee.rateplanList) {
                            res.datas.agreement.dcCompanyArgee.rateplanList.forEach(value => {
                                let params = `${value.rateplanCode}-${value.rateplanLevel}`
                                rateplanActiveList.push(params)
                            })
                            res.datas.agreement.dcCompanyArgee.rateplanActiveList = rateplanActiveList
                        } else {
                            res.datas.agreement.dcCompanyArgee.rateplanList = []
                            res.datas.agreement.dcCompanyArgee.rateplanActiveList = []
                        }
                    }
                    if (!res.datas.agreement.ydCompanyArgee) {
                        res.datas.agreement.ydCompanyArgee = {
                            enable: 0,
                            cardNumber: '',
                            ydCardNumberInfoList: [
                                {
                                    ydCardNumber: '',
                                    ydCardNumberName: ''
                                }
                            ],
                        }
                    }
                    if (!res.datas.agreement.jjCompanyArgee) {
                        res.datas.agreement.jjCompanyArgee = {
                            enable: 0,
                            cardNumber: '',
                            jjSignKey: ''
                        }
                    }

                    //BUG--10589 【差旅后台】客户管理列表，已有客户开启协议配置，“折扣”默认保存为1，应该默认存为空
                    if (res.datas.agreement.dcCompanyArgee != null && res.datas.agreement.dcCompanyArgee.discount == null) {
                        res.datas.agreement.dcCompanyArgee.discount = undefined;
                    }
                    if (res.datas.agreement.hzCompanyArgee != null && res.datas.agreement.hzCompanyArgee.discount == null) {
                        res.datas.agreement.hzCompanyArgee.discount = undefined;
                    }
                    if (res.datas.agreement.jjCompanyArgee != null && res.datas.agreement.jjCompanyArgee.discount == null) {
                        res.datas.agreement.jjCompanyArgee.discount = undefined;
                    }
                    if (res.datas.agreement.ydCompanyArgee != null && res.datas.agreement.ydCompanyArgee.discount == null) {
                        res.datas.agreement.ydCompanyArgee.discount = undefined;
                    }

                    __this.detail = res.datas;

                    __this.accessoryUrl = __this.detail.accessoryUrl ? JSON.parse(__this.detail.accessoryUrl) : [];
                    loading.close();


                    try {
                        let hzCompanyArgeeEnable = __this.detail.agreement.hzCompanyArgee.enable
                        let dcCompanyArgeeEnable = __this.detail.agreement.dcCompanyArgee.enable
                        let ydCompanyArgeeEnable = __this.detail.agreement.ydCompanyArgee.enable
                        let jjCompanyArgeeEnable = __this.detail.agreement.jjCompanyArgee.enable
                        if (hzCompanyArgeeEnable || dcCompanyArgeeEnable || ydCompanyArgeeEnable || jjCompanyArgeeEnable) {
                            this.serviceChargeData = true;
                            throw new Error("EndIterative");
                        } else {
                            this.serviceChargeData = false;
                        }
                    }catch (e) {}
                }).catch(function () {
                    loading.close();
                })
        },
        read_accessory(item) {
            const eleLink = document.createElement('a');
            eleLink.href = item.url;
            eleLink.download = item.name;
            document.body.appendChild(eleLink);
            eleLink.click();
            document.body.removeChild(eleLink);
        },
        changeRadio(){
            try {
                this.detail.agreement.agreeInfoList.forEach((item,index) => {
                    if (item.enable == '1') {
                        this.serviceChargeData = true;
                        throw new Error("EndIterative");
                    } else {
                        this.serviceChargeData = false;
                    }
                })
            }catch (e) {}
        },
    },
    activated() {
        this.companyArgeeActiveName = '1'
        this.req_detail();
    },
    async mounted(){
        // consumer_web_company_getPnrSetType().then(res => {
        //     this.pnrSetOptions = res.datas.result;
        // });

        let [err, res] = await awaitWrap(Promise.all([consumer_hotel_search_getDCRateplanList(), consumer_web_company_getDCCardLevel()]))
        if (err) {
            return
        }
        const DCCardLevel = res[1].datas.result
        const DCRateplanList = res[0].datas.rateplanList
        let rateplanOptions = []
        DCRateplanList.forEach(value => {
            DCCardLevel.forEach(value1 => {
                let params = {
                    value: `${value.rateplanCode}-${value1.value}`,
                    level: value1.value,
                    code: value.rateplanCode,
                    label: `${value.rateplanName}-${value1.text}`,
                    rateplanName: value.rateplanName,
                }
                rateplanOptions.push(params)
            })
        })

        this.rateplanOptions = rateplanOptions

        this.$refs.aEmunSelector.init({
            type: `100`,
            request_url: null,

            entity_define: {
                property_name_value: 'value',
                property_name_text: 'text',
            },

            get_data_source() {
                const data = {
                    entity_list: [],
                };
                data.entity_list = DCCardLevel;
                return data;
            },

            option_all: {
                is_show: false,
                text: '全部',
                value: '',
            },
        })
    },
    watch: {},
    filters: {
        format_carryRules(val) {
            switch (Number(val)) {
                case 0:
                    return "进位到元";
                    break;
                case 1:
                    return "进位到分";
                    break;
            }
        },
        format_amountSet(val) {
            switch (Number(val)) {
                case 0:
                    return "不收取";
                    break;
                case 1:
                    return "收取";
                    break;
            }
        },
        format_amountType(val) {
            switch (Number(val)) {
                case 1:
                    return "百分比";
                    break;
                case 2:
                    return "定额";
                    break;
                default:
                    return ''
            }
        },
        format_scale(val) {
            if (val === 1) {
                return '0~20人'
            } else if (val === 2) {
                return '20~50人'
            } else if (val === 3) {
                return '50~100人'
            } else if (val === 4) {
                return '100~300人'
            } else if (val === 5) {
                return '300~1000人'
            } else if (val === 6) {
                return '1000人以上'
            } else {
                return ''
            }
        },
        format_audit(val) {
            switch (Boolean(val)) {
                case true:
                    return "开通";
                    break;
                case false:
                    return "不开通";
                    break;
            }
        },
        format_allowUseCar(val) {
            switch (val) {
                case 1:
                    return "开通";
                    break;
                case 0:
                    return "不开通";
                    break;
            }
        },
        format_openMemberMt(val) {
            switch (val) {
                case 1:
                    return "开通";
                    break;
                case 0:
                    return "不开通";
                    break;
            }
        },
        format_type(val) {
            // "业务类型：1.机票，2.火车票，3.酒店，4.汽车"
            switch (Number(val)) {
                case 4:
                    return "用车";
                    break;
                case 2:
                    return "火车票";
                    break;
                case 3:
                    return "酒店";
                    break;
                case 1:
                    return "机票";
                    break;
            }
        },
    }
}

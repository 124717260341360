import Q from 'q';
import CompanyInfo from '@/component/company-info/import_latest_version_module'
import PaymentMethodSelector010 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.11/payment-method-selector-010';
import consumer_admin_flight_generate_getConfirmPaymentInfo from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getConfirmPaymentInfo'
import consumer_admin_flight_order_cancel from '@/lib/data-service/haolv-default/consumer_admin_flight_order_cancel'
import consumer_admin_flight_generate_confirm from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_confirm'
import consumer_admin_insurance_cancelInsuranceOrder from '@/lib/data-service/haolv-default/consumer_admin_insurance_cancelInsuranceOrder'
export default {
    data() {
        return {
            loading: false,
            orderNo: '',
            payTypeForChannel: 1,
            payType: '1', // 1支付中 2 无需支付 3 待支付
            detail: {},
        }
    },
    components: {CompanyInfo, PaymentMethodSelector010},
    created() {
    },
    mounted() {
    },
    activated() {
        const query = this.$route.query;
        this.orderNo = query.orderNo;
        this.payTypeForChannel = query.payType || 1;
        console.log(this.orderNo, this.payTypeForChannel);
        this.getDetail();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        isShowInsurancePrice() {
            let show = false;
            if (this.detail && this.detail.insuranceInfo) {
                this.detail.insuranceInfo.forEach(value => {
                    // 如果有其中一个是正常，就正常
                    // 其实现在就只有一个
                    // 保单状态 1：待投保 2：已取消 3：已投保 6：投保失败 4：待退保 5：已退保 7：退保失败
                    if (value.insuranceStatus === 2) {
                        show = false
                    } else {
                        show = true
                    }
                })
            }
            return show
        },
    },
    filters: {},
    methods: {
        getDetail() {
            this.loading = true;
            const params = {
                orderNo: this.orderNo
            };
            consumer_admin_flight_generate_getConfirmPaymentInfo(params).then(res => {
                this.detail = res.datas
                // 有没有需要支付的保险
                let hasInsuranceNeedPay = false
                if (this.detail.insuranceInfo) {
                    this.detail.insuranceInfo.forEach(value => {
                        if (value.insuranceControlType === 1 || value.insuranceControlType === 2) {
                            hasInsuranceNeedPay = true
                        }
                    })
                }
                // 支付方式 1：授信支付 2：现结支付 3：线下转账
                // 支付方式选为线下转账，保险费无需支付
                if (this.detail.payType === 3) {
                    hasInsuranceNeedPay = false;
                }
                console.log(this.payTypeForChannel)
                if (this.payTypeForChannel == 3) {
                    console.log("进入待支付")
                    this.payType = '3'
                    this.$nextTick(() => {
                        this.initPayment()
                    })
                } else {
                    if (this.detail.orderStatus !== 11 && this.detail.orderStatus !== '11') {
                        this.payType = '1'
                    } else if ((this.detail.payType === 3) && !hasInsuranceNeedPay) {
                        this.payType = '2'
                    } else {
                        this.payType = '3'
                        this.$nextTick(() => {
                            this.initPayment()
                        })
                    }
                }
            }).finally(() => {
                this.loading = false
            })
        },
        initPayment() {
            console.log("initPayment");
            let _this = this;
            let orderForPayVos = [{
                orderNo: _this.orderNo,
                businessContent: '',
            }];
            _this.$refs.aPaymentMethodSelector010.init({
                page_this: _this,
                // count_down: {
                //     enable: _this.pageType !== 3 ? true : false,
                //     pay_remaining_seconds: _this.milliseconds,//支付剩余时间，以秒为单位
                // },

                //在这里传入订单详情的相关参数
                get_params() {
                    const p = {
                        "orderForPayVos": orderForPayVos,
                        "payType":  _this.payTypeForChannel
                    };

                    return Q.resolve(p);
                },
                //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                before_pay_event_handler() {
                    return Q.resolve();
                },
                //支付成功事件处理方法
                pay_successful_event_handler() {
                    console.log(`pay_successful_event_handler`);
                    _this.$message({
                        type: 'success',
                        message: '支付成功!'
                    });
                    _this.$router.replace({
                        name: 'admin-flight-order-list'
                    })
                },
                //支付失败事件处理方法
                pay_failed_event_handler() {
                    console.log(`pay_failed_event_handler`);
                },
                //取消订单按钮单击事件处理方法
                btn_cancel_order_click_event_handler(args) {
                    const show_loading = args.show_loading;
                    const hide_loading = args.hide_loading;
                    const stop_count_down = args.stop_count_down;
                    const enable_btn_pay = args.enable_btn_pay;
                    const enable_btn_cancel_order = args.enable_btn_cancel_order;

                    _this.$confirm('确定取消此订单', '取消提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        Q.when().then(() => {
                            show_loading();
                            return consumer_admin_flight_order_cancel({
                                orderNo: _this.orderNo,
                                supplierOrderNo: _this.detail.supplierOrderNo
                            });
                        }).then(function () {
                            console.log(`btn_cancel_order_click_event_handler`);
                            hide_loading();
                            stop_count_down();
                            enable_btn_pay({
                                enable: false,
                            });
                            enable_btn_cancel_order({
                                enable: false,
                            });
                            _this.$message({
                                type: 'success',
                                message: '取消成功!'
                            });
                            _this.$router.replace({
                                name: 'admin-flight-order-list'
                            })
                        }).catch(function (ex) {
                            if (ex) {
                                const enable_log_error = !ex.disable_log_error;
                                if (enable_log_error) {
                                    console.error(ex);
                                    console.trace();
                                    if (ex.stack) {
                                        console.error(ex.stack);
                                    }
                                }
                            }
                            return Q.reject(ex);
                        });
                    }).catch(() => {
                    });
                },
                //获取支付方式接口，返回code=300052时，弹窗的确定按钮单击处理处理方法
                dialog_code_300052_btn_ok_click_event_handler() {
                    console.log(`dialog_code_300052_btn_ok_click_event_handler`);
                    _this.$router.replace({
                        name: 'admin-flight-order-list',
                    });
                    return Q.resolve(true);
                    //return true;
                },
            });
        },
        submit() {
            const params = {
                orderNo: this.orderNo
            };
            this.loading = true
            consumer_admin_flight_generate_confirm(params).then(res => {
                this.$message({
                    type: 'success',
                    message: '确认成功!'
                });
                this.$router.replace({
                    name: 'admin-flight-order-list'
                })
            }).finally(() => {
                this.loading = false
            })
        },
        backToList() {
            this.$router.replace({
                name: 'admin-flight-order-list'
            })
        },
        cancelInsurance(val) {
            const params = {
                orderNo: this.orderNo,
                userId: val.userId,
            }
            const h = this.$createElement;
            this.$msgbox({
                title: '确认取消投保',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                this.loading = true
                consumer_admin_insurance_cancelInsuranceOrder(params).then(res => {
                    this.loading = false
                    this.$message({
                        type: "success",
                        message: '取消投保成功'
                    })
                    this.getDetail()
                })
            }).catch(() => {
                this.loading = false
            });
        },
    }
}

import virtualList from 'vue-virtual-scroll-list'
import ElOptionNode from './el-option-node.vue'
import {validatenull} from './utils.js'
export default {
    data() {
        return {
            itemComponent: ElOptionNode,
            selectArr: [],
            // defaultValue: null, // 绑定的默认值
            selectOptionWidth: null, // 下拉框宽度
            dataList: [],
        }
    },
    model: {
        // prop: 'bindValue',
        prop: 'defaultValue',
        event: 'change'
    },
    props: {
        // 数组
        list: {
            type: Array,
            default() {
                return []
            }
        },
        // 显示名称
        label: {
            type: String,
            default: ''
        },
        // 标识
        value: {
            type: String,
            default: ''
        },
        // 是否拼接label | value
        isConcat: {
            type: Boolean,
            default: false
        },
        // 拼接label、value符号
        concatSymbol: {
            type: String,
            default: ' | '
        },
        // 显示右边
        isRight: {
            type: Boolean,
            default: false
        },
        // 加载条数
        keepsParams: {
            type: Number,
            default: 10
        },
        // 绑定值 v-model
        // bindValue: {
        defaultValue: {
            type: [String, Array],
            default() {
                // if (typeof this.bindValue === 'string') return ''
                if (typeof this.defaultValue === 'string') return ''
                return []
            }
        },
        // 是否多选
        isMultiple: {
            type: Boolean,
            default: false
        },
        // 输入框占位文本
        placeholder: {
            type: String,
            default: '请选择'
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false
        },
        // 是否允许创建条目
        allowCreate: {
            type: Boolean,
            default: false
        },
    },
    components: {
        'virtual-list': virtualList
    },
    created() {
    },
    mounted() {
        this.init()
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        'list'() {
            this.init()
        },
        // bindValue: {
        defaultValue: {
            handler(val, oldVal) {
                // this.defaultValue = this.bindValue
                if (validatenull(val)) this.clearChange()
                this.init()
            },
            immediate: false,
            deep: true
        }
    },
    computed: {},
    filters: {},
    methods: {
        init() {
            this.list.forEach(item => {
                if (validatenull(item[this.label])) {
                    item[this.label] = item[this.value]
                }
            })
            this.dataList = this.list
            if (!this.defaultValue || this.defaultValue?.length === 0) {
                // this.selectArr = this.list
                this.selectArr = this.dataList
            } else {
                // 回显问题
                // 由于只渲染固定keepsParams（10）条数据,当默认数据处于10条之外,在回显的时候会显示异常
                // 解决方法:遍历所有数据,将对应回显的那一条数据放在第一条即可
                this.selectArr = JSON.parse(JSON.stringify(this.dataList))
                // this.selectArr = JSON.parse(JSON.stringify(this.list))
                let obj = {}
                if (typeof this.defaultValue === 'string' && !this.isMultiple) {
                    if (this.allowCreate) {
                        const arr = this.selectArr.filter(val => {
                            return val[this.value] === this.defaultValue
                        })
                        if (arr.length === 0) {
                            const item = {}
                            // item[this.value] = `Create-${this.defaultValue}`
                            item[this.value] = this.defaultValue
                            item[this.label] = this.defaultValue
                            item.allowCreate = true
                            this.selectArr.push(item)
                            this.$emit('selChange', item)
                        } else {
                            this.$emit('selChange', arr[0])
                        }
                    }

                    // 单选
                    for (let i = 0; i < this.selectArr.length; i++) {
                        const element = this.selectArr[i]
                        if (element[this.value]?.toLowerCase() === this.defaultValue?.toLowerCase()) {
                            obj = element
                            this.selectArr?.splice(i, 1)
                            break
                        }
                    }
                    this.selectArr?.unshift(obj)
                } else if (this.isMultiple) {
                    if (this.allowCreate) {
                        this.defaultValue.map(v => {
                            const arr = this.selectArr.filter(val => {
                                return val[this.value] === v
                            })
                            if (arr?.length === 0) {
                                const item = {}
                                // item[this.value] = `Create-${v}`
                                item[this.value] = v
                                item[this.label] = v
                                item.allowCreate = true
                                this.selectArr.push(item)
                                this.$emit('selChange', item)
                            } else {
                                this.$emit('selChange', arr[0])
                            }
                        })
                    }

                    // 多选
                    for (let i = 0; i < this.selectArr.length; i++) {
                        const element = this.selectArr[i]
                        this.defaultValue?.map(val => {
                            if (element[this.value]?.toLowerCase() === val?.toLowerCase()) {
                                obj = element
                                this.selectArr?.splice(i, 1)
                                this.selectArr?.unshift(obj)
                            }
                        })
                    }
                }
            }
        },
        // 搜索
        filterMethod(query) {
            if (!validatenull(query?.trim())) {
                this.$refs.virtualList.scrollToIndex(0) // 滚动到顶部
                setTimeout(() => {
                    // const orgArr = this.list.filter(item => {
                    const orgArr = this.dataList.filter(item => {
                        return this.isRight || this.isConcat
                            ? (item[this.label].trim()?.toLowerCase()?.indexOf(query?.trim()?.toLowerCase()) > -1 || item[this.value]?.toLowerCase()?.indexOf(query?.trim()?.toLowerCase()) > -1)
                            : item[this.label]?.toLowerCase()?.indexOf(query?.trim()?.toLowerCase()) > -1
                    })
                    this.selectArr = this.handleSort(orgArr) || []
                }, 100)
            } else {
                setTimeout(() => {
                    this.init()
                }, 100)
            }
        },
        // 按拼音字母数字顺序排序
        handleSort(arr) {
            const sortArr = arr.sort((a, b) => {
                const reg = /[a-zA-Z0-9]/
                const x = a[this.label],
                    y = b[this.label]
                if (reg.test(x) || reg.test(y)) {
                    if (x > y) {
                        return 1
                    } else if (x < y) {
                        return -1
                    } else {
                        return 0
                    }
                } else {
                    return x.localeCompare(y)
                }
            })
            return sortArr
        },
        visibleChange(bool) {
            if (!bool) {
                this.$refs.virtualList.reset()
                this.init()
            }
            this.$emit('visible-change', bool)
        },
        clearChange() {
            // if (typeof this.defaultValue === 'string') {
            //   this.defaultValue = ''
            // } else if (this.isMultiple) {
            //   this.defaultValue = []
            // }
            this.visibleChange(false)
        },
        focusChange(event) {
            this.setOptionWidth(event)
        },
        setOptionWidth(event) {
            // 下拉框弹出时，设置弹框的宽度
            this.$nextTick(() => {
                this.selectOptionWidth = event.srcElement.offsetWidth + 'px'
            })
        },
    }
}
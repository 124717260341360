import Q from 'q';
import CompanyInfo from '@/component/company-info/import_latest_version_module'
import PaymentMethodSelector010 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.4.11/payment-method-selector-010';
import consumer_admin_trains_order_trainsdetails from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsdetails'
import consumer_admin_flight_generate_evectionType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_evectionType'
import consumer_admin_flight_generate_payType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_payType'
import consumer_admin_flight_generate_psgType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_psgType'
import consumer_admin_flight_generate_certificateType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType'
import consumer_admin_flight_generate_userName from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_userName'
import consumer_admin_trains_order_seatingLevel from '@/lib/data-service/haolv-default/consumer_admin_trains_order_seatingLevel'
import consumer_admin_trains_order_trainsticketChannel from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsticketChannel'
import consumer_trains_order_applyForCancel from '@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel';
import consumer_admin_trains_order_trainsConfirm from '@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsConfirm'
import consumer_admin_trains_order_passengerCardType from '@/lib/data-service/haolv-default/consumer_admin_trains_order_passengerCardType'
import consumer_flight_report_order_offlineOutPayType from '@/lib/data-service/haolv-default/consumer_flight_report_order_offlineOutPayType'
import consumer_admin_flight_generate_getInsurancePayTypeList from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_getInsurancePayTypeList'
export default {
    data() {
        return {
            loading: false,
            orderNo: '',
            payType: '1', // 1支付中 2 无需支付 3 待支付
            detail: {},
            seatNameList: [],
            psgTypeList: [],
            insurancePayTypeList: [],
            certificateTypeList: [],
            purchaseChannelListResult: [],
            generateUserNameList: [],
            paymentTypeList: [
                {
                    code:0,
                    msg:'支付宝',
                },
                {
                    code:1,
                    msg:'网银',
                }
            ],//支付方式枚举值
        }
    },
    components: {CompanyInfo, PaymentMethodSelector010},
    created() {
    },
    mounted() {
        this.init();
    },
    activated() {
        const query = this.$route.query;
        this.orderNo = query.orderNo;
        this.init();
        this.getDetail();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        init() {
            this.getInsurancePayTypeList();
            this.getEvectionTypeList();
            this.getPayTypeList();
            this.getPsgType();
            this.getCertificateType();
            this.getGenerateUserName();
            this.getSeat();
            this.getPurchaseChannel();
            this.getPaymentTypeList();
        },
        returnPayTypeText(payType) {
            let box = '';
            this.insurancePayTypeList.forEach((item,index) => {
                if (item.code == payType) {
                    box = item.msg;
                }
            });
            return box;
        },
        getInsurancePayTypeList() {
            consumer_admin_flight_generate_getInsurancePayTypeList().then(res => {
              this.insurancePayTypeList = res.datas
            })
        },
        getEvectionTypeList() {
            consumer_admin_flight_generate_evectionType().then(res => {
                this.evectionTypeList = res.datas
            })
        },
        getPayTypeList() {
            consumer_admin_flight_generate_payType().then(res => {
                this.payTypeList = res.datas
            })
        },
        getPsgType() {
            consumer_admin_flight_generate_psgType().then(res => {
                this.psgTypeList = res.datas
            })
        },
        getCertificateType() {
            consumer_admin_trains_order_passengerCardType().then(res => {
                let certificateTypeList = res.datas
                certificateTypeList.forEach(value => {
                    value.value = parseInt(value.code)
                })
                this.certificateTypeList = certificateTypeList
            })
        },
        getGenerateUserName() {
            consumer_admin_flight_generate_userName().then(res => {
                this.generateUserNameList = res.datas
            })
        },
        getSeat() {
            consumer_admin_trains_order_seatingLevel().then(res => {
                this.seatNameList = res.datas
            })
        },
        getPurchaseChannel() {
            consumer_admin_trains_order_trainsticketChannel().then(res => {
                let purchaseChannelListResult = res.datas;
                purchaseChannelListResult.forEach(value => {
                    value.code = parseInt(value.code)
                });
                this.purchaseChannelListResult = purchaseChannelListResult
            })

        },
        getDetail() {
            this.loading = true;
            const params = {
                orderNo: this.orderNo
            };
            consumer_admin_trains_order_trainsdetails(params).then(res => {
                this.detail = res;
                console.log('this.detail', this.detail);
                // 订单状态 0.全部(用于查询) 1.占座中 2.待支付 3.出票中 4.出票完成 5.出票失败 6.已取消 8.取消中 10.超标审批中
                if ([1, 2, '1', '2'].indexOf(this.detail.trainsOrderDetails.status) === -1) {
                    this.payType = '1'
                } else if (this.detail.customerInfo.payType === 3) {
                    this.payType = '2'
                } else {
                    this.payType = '3';
                    this.$nextTick(() => {
                        this.initPayment()
                    })
                }

            }).finally(() => {
                this.loading = false
            })
        },
        //获取支付方式枚举值
        async getPaymentTypeList() {
            let res = await consumer_flight_report_order_offlineOutPayType();
            this.paymentTypeList = res.datas;
        },
        initPayment() {
            let _this = this;
            let orderForPayVos = [{
                orderNo: _this.orderNo,
                businessContent: '',
            }];
            _this.$refs.aPaymentMethodSelector010.init({
                page_this: _this,
                // count_down: {
                //     enable: _this.pageType !== 3 ? true : false,
                //     pay_remaining_seconds: _this.milliseconds,//支付剩余时间，以秒为单位
                // },

                //在这里传入订单详情的相关参数
                get_params() {
                    const p = {
                        "orderForPayVos": orderForPayVos,
                        "payType":  1
                    };

                    return Q.resolve(p);
                },
                //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                before_pay_event_handler() {
                    return Q.resolve();
                },
                //支付成功事件处理方法
                pay_successful_event_handler() {
                    console.log(`pay_successful_event_handler`);
                    _this.$message({
                        type: 'success',
                        message: '支付成功!'
                    });
                    _this.$router.replace({
                        name: 'admin-train-order-list'
                    })
                },
                //支付失败事件处理方法
                pay_failed_event_handler() {
                    console.log(`pay_failed_event_handler`);
                },
                //取消订单按钮单击事件处理方法
                btn_cancel_order_click_event_handler(args) {
                    const show_loading = args.show_loading;
                    const hide_loading = args.hide_loading;
                    const stop_count_down = args.stop_count_down;
                    const enable_btn_pay = args.enable_btn_pay;
                    const enable_btn_cancel_order = args.enable_btn_cancel_order;

                    _this.$confirm('确定取消此订单', '取消提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        Q.when().then(function () {
                            show_loading();
                            return consumer_trains_order_applyForCancel({
                                orderNo: _this.orderNo,
                                supplierOrderNo: _this.detail.supplierOrderNo
                            });
                        }).then(function () {
                            console.log(`btn_cancel_order_click_event_handler`);
                            hide_loading();
                            stop_count_down();
                            enable_btn_pay({
                                enable: false,
                            });
                            enable_btn_cancel_order({
                                enable: false,
                            });
                            _this.$message({
                                type: 'success',
                                message: '取消成功!'
                            });
                            _this.$router.replace({
                                name: 'admin-train-order-list'
                            })
                        }).catch(function (ex) {
                            if (ex) {
                                const enable_log_error = !ex.disable_log_error;
                                if (enable_log_error) {
                                    console.error(ex);
                                    console.trace();
                                    if (ex.stack) {
                                        console.error(ex.stack);
                                    }
                                }
                            }
                            return Q.reject(ex);
                        });
                    }).catch(()=>{})


                    /*hide_loading();
                    stop_count_down();
                    enable_btn_pay({
                        enable: false,
                    });
                    enable_btn_cancel_order({
                        enable: false,
                    });
                    _this.$router.replace({
                        name: 'admin-flight-order-list'
                    })*/
                },
                //获取支付方式接口，返回code=300052时，弹窗的确定按钮单击处理处理方法
                dialog_code_300052_btn_ok_click_event_handler() {
                    console.log(`dialog_code_300052_btn_ok_click_event_handler`);
                    _this.$router.replace({
                        name: 'admin-train-order-list',
                    });
                    return Q.resolve(true);
                    //return true;
                },
            });
        },
        submit() {
            const params = {
                orderNo: this.orderNo
            };
            this.loading = true;
            consumer_admin_trains_order_trainsConfirm(params).then(res => {
                this.$message({
                    type: 'success',
                    message: '出票成功!'
                });
                this.$router.replace({
                    name: 'admin-train-order-list'
                })
            }).finally(() => {
                this.loading = false
            })
        },
        backToList() {
            this.$router.replace({
                name: 'admin-train-order-list'
            })
        },
    }
}

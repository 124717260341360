export default {
    path: '',
    component: () => import(`@/component/admin-layout/index.vue`),
    children: [
        {
            path: '/admin/add-users',
            name: 'admin-add-users',
            component: () => import(/* webpackChunkName: "page-admin-users-management-add-users" */ /* webpackChunkName: "page-admin-users-management-add-users" */ `./1.1.5/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `添加客户`,
                },
                entity_list_for_breadcrumb: [
                    {name: `客户管理`},
                    {name: `添加客户`},
                ]
            },
        },
        {
            path: '/admin/edit-draft-users',
            name: 'admin-edit-draft-users',
            component: () => import(`./1.1.5/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `编辑客户`,
                },
                entity_list_for_breadcrumb: [
                    {name: `客户管理`},
                    {name: `编辑客户`},
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        return `admin-users-management-list`;
                    },
                },
            },
        },
    ],
};

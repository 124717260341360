import _ from "underscore";
import Q from "q";
import extend from "extend";
import to_string from "nllib/src/convert/to_string";

//允许进行全局query参数的路由名称
const route_name_list = [
    // `admin-global-query-test-page-001`,
    // `admin-global-query-test-page-002`,
    // `admin-global-query-test-page-003`,
    `*`,
];

//全局query参数名称
const query_name_list = [
    // `aaa`,
    // `bbb`,
    // 代客下单公司id
    `behalfCompanyId`,
    `evectionType`
];

const handler = function ({to, from, next}) {
    //用例
    //1)有多个参数时，直接刷新页面
    //2)【000-000】 ===> 【001-002】 ，expect:page-002?aaa=111&bbb=222
    //3)【000-000】 ===> 【001-004】 ，expect:page-004
    //4)【000-000】 ===> 【003-004】 ===》 【002-001】，expect:page-001?aaa=666&bbb=1666
    //5)【000-000】 ===> 【003-004】 ===》 【002-001】，expect:page-001?aaa=666&bbb=1666
    //6)【002-004】 ===> 【000-000】 ，expect:page-001?aaa=111&bbb=222&ccc=333

    const get_query_data = function (pRoute) {
        const query_list = _.chain(query_name_list)
            .map(function (m) {
                const name = m;
                const value = pRoute.query[name];

                const o = {};
                o.name = name;
                o.value = value;
                return o;
            })
            .filter(function (m) {
                return m.value;
            })
            .value()
        ;

        const query_object = _.chain(query_list)
            .groupBy(function (m) {
                return m.name;
            })
            .mapObject(function (property_value, property_name) {
                const list = property_value;
                let r = ``;
                if (!_.isArray(list)) return r;
                if (list.length <= 0) return r;
                const first = list[0];
                r = first.value;
                return r;
            })
            .value()
        ;

        //query_name_list下，所有query都有值
        const flag_all_query_has_value = query_list.length === query_name_list.length;

        return {
            query_list,
            query_object,
            flag_all_query_has_value,
        };
    };

    const from_query_data = get_query_data(from);
    const from_query_list = from_query_data.query_list;
    const from_query_object = from_query_data.query_object;

    const to_query_data = get_query_data(to);
    const to_query_list = to_query_data.query_list;
    const to_query_object = to_query_data.query_object;
    // const to_flag_all_query_has_value = to_query_data.flag_all_query_has_value;//query_name_list下，所有query都有值

    const to_name = to.name;

    //query_object_change改变标志
    const flag_query_object_change = JSON.stringify(from_query_object) === JSON.stringify(to_query_object);

    // if (!_.contains(route_name_list, `*`)) {
    // if (!_.contains(route_name_list, to_name)) {
    if (!_.contains(route_name_list, to_name) && !_.contains(route_name_list, `*`)) {
        next();
    } else if (to.params.flag_to_next) {
        next();
    } else if (!flag_query_object_change) {
        const query = extend({}, from_query_object, to.query);
        next({
            name: to_name,
            query: query,
            params: {
                flag_to_next: true,
            },
        });
    } else {
        next();
    }
};

const next = handler;

export default {
    next,
    query_name_list,
}

// 参考
// https://segmentfault.com/a/1190000011042794

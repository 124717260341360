const Q = require(`q`);
const __original_sample_data_list = require(`@/lib/data-service/default/get_sample_data_list/__original_sample_data_list`);

const get_sample_data_list = function (pParameter) {
  if (!pParameter) {
    pParameter = {};
  }

  return Q.resolve({
    list: __original_sample_data_list,
  });
};

module.exports = get_sample_data_list;

// 未开通授信公司列表  http://api-alpha.wholeok.com/doc.html#/tmc-biz/t-us-company-credit-controller/openCreditUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_credit_open_list = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/credit/open/list',
    data: pParameter
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_credit_open_list;
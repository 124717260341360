import { ChangeDateFormat } from "@/lib/utils/common";
import FormTabel from "./formTabel";
import VoyageInfo from "../../component/voyageInfo";
import consumer_flight_order_orderChangeCustomer from "@/lib/data-service/haolv-default/consumer_flight_order_orderChangeCustomer";
import consumer_flight_order_change from "@/lib/data-service/haolv-default/consumer_flight_order_change";
import consumer_journey_addOrUpdateOverproof from '@/lib/data-service/haolv-default/consumer_journey_addOrUpdateOverproof' // 创建超标订单
import consumer_admin_flight_equity_getOrderProductInfo from '@/lib/data-service/haolv-default/consumer_admin_flight_equity_getOrderProductInfo'
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";
import NullData from "../../component/nullData";
export default {
    name: "FlightChange",
    data() {
        return {
            orderProductInfo: {},
            dialogEnjoyMore: false,
            orderNo: '',
            centerDialogVisible: false,
            ChangeDateFormat: ChangeDateFormat,
            detail: {},
            loading: false,
            changeSegmentList: {},
            needChangeSegmentList: [],
            formData: {
                chaReason: '',
                changeType: '',
                chaReasonText: '',
                realReason: '',
                realReasonText: '',
                segmentList: [],
                url: [],
                remark: '',
            },
            dialogVisible: false,
            canSubmit: true,
        };
    },
    methods: {
        clickEnjoyMore() {
            consumer_admin_flight_equity_getOrderProductInfo({orderNo: this.$route.query.orderNo}).then((res) => {
                this.orderProductInfo = res.datas;
            });
            this.dialogEnjoyMore = true;
        },
        lookChange() {
            this.$router.push({ name: "admin-flight-change-detail" });
        },
        goFlightList() {
            this.$router.push({ name: "admin-flight-order-list" });
        },
        async submit() {
            let formResult = await this.$refs['formTable'].checkForm()
            console.log(formResult);
            if (!formResult) {
                return
            }
            if (!this.canSubmit) {
                return
            }
            this.canSubmit = false
            console.log(this.formData);
            if (this.formData.segmentList.length === 0) {
                this.$message({ type: "warning", message: "请选择航班" });
                this.canSubmit = true;
                return;
            }
            if (this.formData.needChangeSegmentList.length > 1) {
                let segmentTypeList = [];
                this.formData.needChangeSegmentList.forEach(value => {
                    if (segmentTypeList.indexOf(value.segmentType) === -1) {
                        segmentTypeList.push(value.segmentType)
                    }
                })
                this.$message({ type: "warning", message: segmentTypeList.length > 1 ? "去程回程不能同时改签" : '航班号或舱位不一致，请分别提交改签' });
                this.canSubmit = true;
                return;
            }
            let overproofApplyList = []
            this.formData.segmentList.forEach(value => {
                let warningList = value.overproofApplyList
                warningList.forEach(value2 => {
                    let item = {
                        businessType: value2.businessType,
                        controlRule: value2.controlRules,
                        orderNo: value2.orderNo,
                        reason: value2.reason,
                        violationOfReason: value2.violationOfReason,
                        reserveContent: value2.violationOfReason,
                        standard: value2.standard,
                        seatName: value2.seatName,
                        standardContent: value2.seatName,
                        userId: value2.userId,
                        userName: value2.userRealName,
                        userRealName: value2.userRealName,
                        overproofType: 2
                    }
                    overproofApplyList.push(item)
                })
            })
            let checkParams ={
                evectionNo: this.$route.query.evectionNo,
                overproofApplyList
            }
            console.log(checkParams);
            let [resultErr, result] = await awaitWrap(consumer_journey_addOrUpdateOverproof(checkParams))
            if (resultErr) {
                this.canSubmit = true
                return
            }
            let changeSegment = Object.values(this.changeSegmentList);
            changeSegment = [].concat(...changeSegment);
            let passengerNoList = []
            changeSegment.forEach(value => {
                if (passengerNoList.indexOf(value.psgNo) === -1) {
                    passengerNoList.push(value.psgNo)
                }
            })
            // 重新检验新选的航班是否对应回needChangeSegmentList
            let newSegmentList = [];
            this.formData.needChangeSegmentList.forEach(value => {
                this.formData.segmentList.forEach(value1 => {
                    if (value1.segmentNo === value.segmentNo) {
                        newSegmentList.push(value1);
                    }
                })
            })
            this.formData.segmentList = newSegmentList;
            let data = {
                ticketNumberList: changeSegment.map(item => item.ticketNumber),
                adultTicketNo: changeSegment
                    .map(item => item.aduTicketNumber)
                    .join(","),
                passengerNoList: passengerNoList, // changeSegment.map(item => item.psgNo)
                orderNo: this.$route.query.orderNo,
                evectionNo: this.$route.query.evectionNo,
                manualStatus: 0,
            };
            this.formData = Object.assign(this.formData, data);
            let params = JSON.parse(JSON.stringify(this.formData));
            if (this.formData.chaReason === 2 && params.url) {
                params.url = params.url.join(',');
            }
            let policyType = '';
            this.formData.needChangeSegmentList.forEach(value => {
                policyType = value.policyType;
            })
            params.policyType = policyType;
            console.log(params);
            let [err, res] = await awaitWrap(consumer_flight_order_change(params))
            this.canSubmit = true
            if (err) {
                return
            }
            this.$message({
                type: "success",
                message: '提交成功'
            })
            this.$router.push({ name: "admin-flight-change-list" });

        },
        getSegment(val, passenger, index) {
            this.setChangeSegment(passenger, val, index);
        },
        setChangeSegment(passenger, segment, index) {
            let passengerName = `${passenger.psgName}/${index}`;
            if (segment.length) {
                /**添加 */
                segment.map(item => {
                    item.name = passengerName.split("/")[0];
                    item.startTime = "";
                });
                this.changeSegmentList[passengerName] = segment;
            } else {
                /**清空 */
                delete this.changeSegmentList[passengerName];
            }
            // 筛选出航程
            let flightNoArr = []
            let needChangeSegmentList = []
            for (let segment in this.changeSegmentList) {
                this.changeSegmentList[segment].forEach(value => {
                    let arrIndex = flightNoArr.indexOf(value.flightNo)
                    if (arrIndex === -1) {
                        flightNoArr.push(value.flightNo)
                        value.userIdArr = [value.userId]
                        value.userInfoArr = [{
                            id: value.userId,
                            ticketNo: value.ticketNumber,
                            psgNo: value.psgNo,
                            segmentNo: value.segmentNo
                        }]
                        needChangeSegmentList.push(value)
                    } else {
                        needChangeSegmentList[arrIndex].userIdArr.push(value.userId)
                        needChangeSegmentList[arrIndex].userInfoArr.push({
                            id: value.userId,
                            ticketNo: value.ticketNumber,
                            psgNo: value.psgNo,
                            segmentNo: value.segmentNo
                        })
                    }
                })
            }
            this.needChangeSegmentList = needChangeSegmentList
            this.$set(
                this.formData,
                "changeSegmentList",
                JSON.parse(JSON.stringify(this.changeSegmentList))
            );
            this.$set(this.formData, 'needChangeSegmentList', JSON.parse(JSON.stringify(this.needChangeSegmentList)))
        },
        getDetail() {
            let orderNo = this.$route.query.orderNo;
            this.loading = true;
            try {
                consumer_flight_order_orderChangeCustomer({ orderNo }).then(res => {
                    this.loading = true;
                    if (res.datas.changeRes) {
                        let detail = res.datas.changeRes
                        detail.creatType = parseInt(detail.creatType)
                        if (detail.passengerList) {
                            detail.passengerList.forEach(value => {
                                value.changeSegmentList.forEach(value1 => {
                                    value1.userId = parseInt(value.userId)
                                    value1.psgName = value.psgName
                                    value1.psgNo = value.psgNo
                                    value1.changeDate = value1.beginDate
                                })
                            })
                        }
                        this.detail = detail;
                    } else {
                        // 弹窗
                        this.dialogVisible = true
                    }
                })
                    .catch(() => {
                        this.loading = true;
                    });
            } catch {
                console.error("改签详情");
            }
        },
        goBack() {
            this.dialogVisible = false
            this.$router.go(-1)
        },
        reactDate() {
            this.centerDialogVisible = false
            this.detail = {}
            this.changeSegmentList = {}
            this.needChangeSegmentList = []
            this.formData = {
                chaReason: '',
                changeType: '',
                realReason: '',
                segmentList: []
            }
            this.dialogVisible = false
        },
    },
    created() {

    },
    activated() {
        this.orderNo = this.$route.query.orderNo
        this.reactDate()
        this.getDetail();
    },
    watch: {},
    components: {
        FormTabel,
        VoyageInfo,
        NullData
    },
    computed: {}
};
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/

import consumer_car_order_getCarPolicyType from '@/lib/data-service/haolv-default/consumer_car_order_getCarPolicyType'
import consumer_car_order_getCarType from '@/lib/data-service/haolv-default/consumer_car_order_getCarType'
import consumer_admin_car_order_adminCarOrderBillDetails
    from '@/lib/data-service/haolv-default/consumer_admin_car_order_adminCarOrderBillDetails'
import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'
import consumer_admin_car_order_adminCarOrderBillDetailsExcel from '@/lib/data-service/haolv-default/consumer_admin_car_order_adminCarOrderBillDetailsExcel'

export default {
    data() {
        return {
            loading: false,
            carPolicyTypeList: [],
            carTypeList: [],
            searchForm: {
                orderNo: '',//订单号
                callPerson: '',//叫车人
                belongCompany: '',//所属企业
                carPolicyType: '',//差标类型
                carType: '',//运力类型
                startDate: '',//开始日期
                endDate: '',//结束日期
                orderTimeArr: '',//开始-结束日期
                currentPage: 1,//查询页码
                pageSize: 10,//查询条数
                total: 0,
            },
            list: []
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    activated() {
        this.init()
    },
    methods: {
        //导出账单
        async exportBills() {
            let res = await consumer_admin_car_order_adminCarOrderBillDetailsExcel(this.searchForm);
            window.open(res.datas.url);
        },
        //初始化
        async init() {
            await this.getCarPolicyType();
            await this.getCarType();
            this.search();
        },
        //搜索
        search() {
            this.searchForm.currentPage = 1;
            this.searchForm.orderStatus = this.activeName === '0' ? '' : this.activeName;
            this.getList()
        },
        //重置
        reset() {
            let searchForm = {
                orderNo: '',//订单号
                callPerson: '',//叫车人
                belongCompany: '',//所属企业
                carPolicyType: '',//差标类型
                carType: '',//运力类型
                startDate: '',//开始日期
                endDate: '',//结束日期
                orderTimeArr: '',//开始-结束日期
                currentPage: 1,//查询页码
                pageSize: 10,//查询条数
                total: 0,
            };
            this.searchForm = searchForm;
            this.search();
        },
        //改变搜索条件
        handleClick() {
            this.search()
        },
        //搜索日期改变时
        changeOrderTimeArr(val) {
            if (val === '' || val === null) {
                this.searchForm.startDate = '';
                this.searchForm.endDate = '';
            } else {
                this.searchForm.startDate = val[0] + ' 00:00:00';
                this.searchForm.endDate = val[1] + ' 23:59:59';
            }
        },
        //获取列表
        getList() {
            this.loading = true;
            this.list = [];
            consumer_admin_car_order_adminCarOrderBillDetails(this.searchForm).then(res => {
                this.loading = false;
                this.list = res.datas.billDetailsResultList.list;
                this.searchForm.total = res.datas.billDetailsResultList.totalCount;
            }).catch(() => {
                this.loading = false;
                this.list = [];
            })
        },
        //获取差标类型枚举值
        async getCarPolicyType() {
            let res = await consumer_car_order_getCarPolicyType();
            this.carPolicyTypeList = res.datas.result;
        },
        //获取运力类型枚举值
        async getCarType() {
            let res = await consumer_car_order_getCarType();
            this.carTypeList = res.datas.result;
        },
    }
}
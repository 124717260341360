import moment from "moment";
// 酒店详情
import consumer_admin_hotel_order_getRoomPackageInfo
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_getRoomPackageInfo.js";
// 出差单员工
import consumer_journey_getEvectionDetail from "@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail.js";
// 酒店创建预订单
import consumer_admin_hotel_order_addOrUpd
    from "@/lib/data-service/haolv-default/consumer_admin_hotel_order_addOrUpd.js";
// 退订规则
import req_refund_rule from "@/lib/data-service/haolv-default/consumer_hotel_search_getRatePlan.js";

import CheckInPerson from "./components/checkIn-person/index.vue";

import history_tag_api from '@/component/admin-layout/component/history-tag/api';
//酒店相册
import consumer_hotel_search_getHotelImageV2
    from "@/lib/data-service/haolv-default/consumer_hotel_search_getHotelImageV2.js";

// 企业信息组件
import CompanyInfo from '@/component/company-info/import_latest_version_module';
//地图
import Map from '@/component/map/1.0.0/index.vue';
//每天报价
import DailyPrice from './components/daily-price/1.0.0/index.vue';
//复制报价组件
import CopyReportPopup from '@/component/copyReportPopup/1.0.0/index.vue';
//合并前原酒店信息
import consumer_hotel_search_getHotelStaticInfoResult
    from "@/lib/data-service/haolv-default/consumer_hotel_search_getHotelStaticInfoResult";
import consumer_TTpRefundChangePolicy_copyPriceWithService from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_copyPriceWithService';
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";
import consumer_product_getServiceAmount from '@/lib/data-service/haolv-default/consumer_product_getServiceAmount';
import Decimal from "decimal.js"
export default {
    components: {
        CheckInPerson,
        CompanyInfo,
        DailyPrice,
        CopyReportPopup,
        Map
    },
    data() {
        var validteCheckIn = (rule, value, callback) => {
            if (
                !value.length ||
                value === "" ||
                value === null ||
                value === undefined
            ) {
                callback(new Error("请选择入住人"));
            } else {
                callback();
            }
        };

        return {
            loading: false,
            copyDialog: false,
            copyLoading: false,
            copyTextarea: '',
            dailyPriceLoading: false,
            hotelStaticInfo: {},
            hotelImgList: [],
            roomType: 1,
            // 请求房型参数
            searchForm: {
                checkInDate: "",
                checkOutDate: "",
                hotelId: "",
            },

            // 预定表单
            reserveForm: {
                cityName: "",
                // 入住时间
                checkInDate: "",
                // 离店时间
                checkOutDate: "",
                // 城市id
                cityId: "",
                // 出差单号
                evectionNo: "",
                // 酒店地址
                hotelAddr: "",
                // 酒店id
                hotelId: "",
                // 酒店名称
                hotelName: "",
                // 房型id
                hotelRoomTypeId: "",
                // 房型名称
                hotelRoomTypeName: "",
                keyId: "",
                hotelStar: "",
                hotelPhone: "",
                hotelPicUrl: "",
                // 订单号
                orderNo: "",
                // 价格
                price: "",

                coseList: "",
                // 房间数
                roomNum: "",
                checkInPerson: [],
                // 入住晚数
                stayNights: "",
                // 间夜信息
                // nightlyRateList: null,
                // 第三方id
                thirdpartyHotelId: "",

                countryName: '中国',//国家名称
                stateName: '',//省份名称
                areaName: '',//区县名称
                windowName: '',//窗户说明
                breakfastName: '',//早餐说明
                nightlyRateList: [],//间夜信息
                // bookingRule: '',
                // refundRule: ''
                roomMaxSize: 1,//每个房间最多可住人数

                groupName: '',//集团酒店
                negotiatedPriceText: '',//协议价标识
                invoiceMode: '',//税费
                orderFromType: '',//1捷旅 2美团 3艺龙 4千淘 5美团商旅
                supplierPrice: '',//供应商采购价
            },

            checkInPersonVisible: [],

            noticeVisible: false,

            noticeDatas: {
                bookingRule: [],
                refundRule: [],
            },

            // 预定表单验证
            reserveRules: {
                roomNum: [{required: true, message: "请选择房间数", trigger: "blur"}]
            },
            // 最大可订购房间的数量
            maxRoomNum: 9,
            // 酒店详情
            detail: {},
            // 房型列表
            roomList: [],
            // 控制没有房型是的显示
            isEmpty: false,
            // 预定弹窗
            reserveVisible: false,
            // 出差单内员工
            travellerList: [],
            // 入驻时间选择
            checkInDate: null,
            // 时间框选择范围
            checkInRange: {
                disabledDate: this.disabledCheckInDate
            },
            exceedVisible: false,

            checkInPersonList: [],
            overproof: {
                evectionNo: "",
                overproofApplyList: [],
            },

            isMergedHotel: false,//是否合并酒店
            originalHotelInfo: {},//合并前原酒店信息
            originalHotelVisible: false,//合并前原酒店信息弹框是否显示

            showNotBookDialog: false,
            today: '',//今天-'YYY-MM-DD'
            yesterday: '',//昨天-'YYY-MM-DD'
            isMorning: false,//当前时间是否为凌晨00:00-06:00
            isTodayMorning: false,//是否今天凌晨入住
        };
    },
    computed: {},
    methods: {
        validteCheckIn(rule, value, callback) {
            if (
                !value.length ||
                value === "" ||
                value === null ||
                value === undefined
            ) {
                callback(new Error("请选择入住人"));
            } else {
                callback();
            }
        },
        disabledCheckInDate(time) {
            //没有选中时间（初始化状态）
            if (this.isMorning) {
                //如果在凌晨(00:00-06:00)时段进入页面，设置昨天以前的时间不可选
                let yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                let timestamp = yesterday.getTime();
                return time.getTime() < (timestamp - 8.64e7);
            } else {
                //如果在非凌晨(00:00-06:00)时段进入页面，设置当前时间后的时间不可选
                return time.getTime() < (Date.now() - 8.64e7);
            }
        },
        //获取是否今日凌晨入住
        getInTodayMorning() {
            let checkInDateStr = this.$moment(this.searchForm.checkInDate).format('YYYY-MM-DD');
            this.isTodayMorning = checkInDateStr === this.yesterday ? true : false;
        },
        // 请求房型
        req_detail(params, type) {
            this.loading = true;
            this.roomList = [];
            this.isEmpty = false;
            const param = {
                behalfCompanyId: this.$route.query.behalfCompanyId,
                params: params
            }
            consumer_admin_hotel_order_getRoomPackageInfo(param)
                .then((res) => {
                    this.detail.hotelDays = res.datas.hotelDays;
                    this.hotelStaticInfo = res.datas.hotelStaticInfo;
                    this.isMergedHotel = this.hotelStaticInfo.enumArrayListFromType && (this.hotelStaticInfo.enumArrayListFromType.length > 1);
                    this.$refs.aMap.init({
                        center: [Number(this.hotelStaticInfo.longitude), Number(this.hotelStaticInfo.latitude)]
                    })
                    const list = res.datas.roomViewList;
                    list.forEach((item) => {
                        item.isFold = true;
                    });
                    this.roomList = list;
                    if (!this.roomList.length) this.isEmpty = true;
                    this.loading = false;
                    if (this.$route.query.evectionNo)
                        this.req_staff({
                            evectionNo: this.reserveForm.evectionNo,
                            pageType: 1,
                            personType: 2,
                        });
                })
                .catch((err) => {
                    if (!this.roomList.length) this.isEmpty = true;
                    this.loading = false;
                });
        },
        // 请求员工
        req_staff(params) {
            this.travellerList = [];
            consumer_journey_getEvectionDetail(params)
                .then((res) => {
                    const detail = res.datas;
                    this.travellerList = detail.travellerList;
                })
                .catch((err) => {
                });
        },
        req_reserve(confirmType) {
            const loading = this.$loading({
                lock: true,
                text: "请求中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let params = {
                companyId: this.$route.query.behalfCompanyId,
                confirmType: confirmType ? confirmType : '',
                ...this.reserveForm
            };
            params.checkInPerson = params.checkInPerson.flat(Infinity);
            consumer_admin_hotel_order_addOrUpd(params)
                .then((res) => {
                    loading.close();
                    this.$router.push({
                        name: "admin-hotel-reserve",
                        query: {
                            evectionNo: res.msg,
                        },
                    });
                })
                .catch((err) => {
                    loading.close();
                    console.error(err);
                });
        },

        to_home() {
            this.$router.push({name: 'admin-travel-book-home'});
        },

        back() {
            this.$router.back();
        },
        // 初始化
        init(query) {
            this.loading = true;
            this.detail = query;

            this.searchForm = {
                hotelId: query.hotelId,
                checkInDate: query.checkInDate,
                checkOutDate: query.checkOutDate,
                memberPhone: query.memberPhone,
            };

            const form = this.reserveForm;

            form.cityId = query.cityId || "";
            form.cityName = query.cityName || "";
            form.evectionNo = query.evectionNo;
            form.hotelAddr = query.hotelAddr;
            form.hotelId = query.hotelId;
            form.hotelName = query.hotelName;
            form.orderNo = query.orderNo || "";
            form.hotelPicUrl = query.imageUrl;
            form.hotelStar = query.star;
            form.hotelPhone = query.phone;

            this.checkInDate = [query.checkInDate, query.checkOutDate];
            this.req_detail(this.searchForm, "init");

            this.getHotelImg(form.hotelId);

            history_tag_api.modify_tag_name_by_route({
                route: this.$route,
                tag_name: query.hotelName,
            });

            let curTimeList = this.$moment().toArray();
            this.isMorning = curTimeList[3] < 6;
            if (this.isMorning) {
                //凌晨时进入预订页面需要做的事情
                this.initDateForMorning();
            }
        },

        //凌晨时进入预订页面需要做的事情
        initDateForMorning() {
            this.today = this.$moment().format('YYYY-MM-DD');
            this.yesterday = this.$moment().add(-1,'days').format('YYYY-MM-DD');
            this.getInTodayMorning();
        },

        async getHotelImg(hotelId) {
            let res = await consumer_hotel_search_getHotelImageV2({hotelId: hotelId});
            this.hotelImgList = res.data;
        },
        close_notes() {
            this.noticeVisible = false;
            this.noticeDatas = {
                bookingRule: [],
                refundRule: [],
            };
        },
        popup_notes(item, index, combo) {
            const detail = this.detail;
            req_refund_rule({
                behalfCompanyId: this.$route.query.behalfCompanyId,
                params: {
                    checkInDate: detail.checkInDate,
                    checkOutDate: detail.checkOutDate,
                    hotelId: combo.hotelId,
                    keyId: item.ratePlanViewList[index].keyId,
                    roomTypeId: item.ratePlanViewList[index].roomTypeId,
                },
            }).then((res) => {
                this.noticeDatas.bookingRule = res.bookingRule;
                this.noticeDatas.refundRule = res.refundRule;
                this.noticeVisible = true;
            });
        },
        // 预定按钮事件
        to_reserve(combo, thirdpartyHotelId, roomTypeId, orderFromType, item) {
            if (this.isTodayMorning) {
                let curTimeList = this.$moment().toArray();
                if(curTimeList[3] >= 6) {
                    this.showNotBookDialog = true;
                    return;
                }
            }

            this.roomType = 1;

            const travellerList = this.travellerList;
            const checkInNum = travellerList.length;

            const canReserve = combo.nightlyRateList.map((item) => moment(item.date).format('YYYY-MM-DD'));
            const length = canReserve.length;
            const form = this.searchForm;
            const start = form.checkInDate;
            const clearDays = moment(form.checkOutDate).diff(moment(start), "days");

            let incomplete = false;

            for (let i = 0; i < clearDays; i++) {
                const need = moment(start)
                    .add(i, "days")
                    .format("YYYY-MM-DD");
                if (!canReserve.includes(need)) incomplete = true;
            }
            let node = "";

            if (length === 0) {
            } else if (length === 1) {
                node =
                    "当前仅“" +
                    `<span style="display: inline-block;">${canReserve[0]}</span>”${length}天可预定！`;
            } else if (length === 2) {
                node =
                    "仅“" +
                    `<span style="display: inline-block;">${canReserve[0]}</span>，<span style="display: inline-block;">${canReserve[1]}</span>”，${length}天可预定!`;
            } else {
                for (let i = 0; i < canReserve.length; i++) {
                    const item = canReserve[i];
                    if (i === 0) {
                        node =
                            "当前仅“" +
                            `<span style="display: inline-block;">${item}，</span>`;
                    } else if (i === length - 1) {
                        node += `<span style="display: inline-block;">${item}，</span>”，${length}天可预定！`;
                    } else {
                        node += `<span style="display: inline-block;">${item}，</span>`;
                    }
                }
            }

            const _this = this;
            if (incomplete) {
                this.$confirm(`${node}`, `提示`, {
                    dangerouslyUseHTMLString: true,
                    showConfirmButton: false,
                    cancelButtonText: "关闭",
                })
                    .then(() => {
                        reserve(_this);
                    })
                    .catch(() => {
                    });
            } else {
                reserve(_this);
            }

            function reserve(_this) {
                const searchForm = _this.searchForm;
                const form = _this.reserveForm;
                form.hotelId = combo.hotelId;
                form.checkInDate = searchForm.checkInDate;
                form.checkOutDate = searchForm.checkOutDate;
                form.hotelRoomTypeId = roomTypeId;
                form.hotelRoomTypeName = combo.name;
                form.stayNights = length;
                form.price = combo.cose / length;
                form.coseList = combo.coseList;
                form.thirdpartyHotelId = thirdpartyHotelId;
                form.keyId = combo.keyId;
                form.breakfast = combo.breakfast;
                form.countryName = '中国';
                form.stateName = _this.hotelStaticInfo.stateCn;
                form.areaName = _this.hotelStaticInfo.areaName;
                form.windowName = combo.windowName;
                form.breakfastName = combo.breakfastName;
                form.nightlyRateList = combo.nightlyRateList
                form.ifInvoice = combo.ifInvoice;
                form.orderFromType = combo.hotelFrom;
                // form.bookingRule = combo.bookingRule;
                // form.refundRule = combo.refundRule;
                form.groupName = combo.groupName;
                form.negotiatedPriceText = combo.negotiatedPriceText;
                form.orderFromType = combo.hotelFrom;
                form.invoiceMode = combo.invoiceMode;
                form.supplierPrice = combo.supplierPrice;
                form.roomMaxSize = item.maximize;
                form['rateTypeId'] = combo['rateTypeId'];

                if (checkInNum === 1) {
                    form.roomNum = 1;
                    form.checkInPerson = [[travellerList[0].userId]];
                } else if (checkInNum === 0) {
                    form.roomNum = 1;
                    form.checkInPerson = [];
                } else {
                    form.roomNum = "";
                    form.checkInPerson = [];
                }
                _this.req_reserve(combo.confirmType);
            }
        },

        // 关闭房间数弹窗
        close_room_visible() {
            this.roomType = 2;
            this.reserveVisible = false;
            this.$refs.reserveForm.resetFields();
            const reserveForm = this.reserveForm;
            reserveForm.checkInDate = "";
            reserveForm.checkOutDate = "";
            reserveForm.hotelRoomTypeId = "";
            reserveForm.hotelRoomTypeName = "";
            reserveForm.price = "";
            reserveForm.coseList = "";
            reserveForm.stayNights = "";
            reserveForm.checkInPerson = [];
            reserveForm.thirdpartyHotelId = "";
            reserveForm.keyId = "";
            reserveForm.breakfast = ''
        },
        commit_reserve() {
            this.$refs.reserveForm.validate((valid) => {
                if (valid) {
                    this.req_reserve();
                } else {
                    return false;
                }
            });
        },

        change_roomNum(newLength) {
            const form = this.reserveForm;
            const oldLength = form.checkInPerson.length;

            if (newLength) {
                if (oldLength) {
                    if (newLength > oldLength) {
                        for (let i = 0; i < newLength - oldLength; i++) {
                            form.checkInPerson.push([]);
                            this.checkInPersonList.push({visible: false});
                        }
                    } else {
                        form.checkInPerson.splice(newLength, oldLength - newLength);
                        this.checkInPersonList.splice(newLength, oldLength - newLength);
                    }
                } else {
                    for (let i = 0; i < newLength; i++) {
                        form.checkInPerson.push([]);
                        this.checkInPersonList.push({visible: false});
                    }
                }
            } else {
                form.checkInPerson = [];
                this.checkInPersonList = [];
            }
        },

        popup_checkIn(index) {
            const list = this.checkInPersonList;
            list.forEach((item, key) => {
                if (index === key) {
                    item.visible = !item.visible;
                } else {
                    item.visible = false;
                }
            });

            window.addEventListener("click", this.estimate_target, true);
        },

        estimate_target(e) {
            let element = e.target;
            let isHave = false;
            let isWindow = false;
            while (!isHave && !isWindow) {
                if (element.classList.length)
                    isHave = element.classList.contains("checkIn_person");
                isWindow = element === document.getElementById("app");
                element = element.parentNode;
            }

            if (isHave) {
            } else {
                const list = this.checkInPersonList;
                list.forEach((item, key) => {
                    item.visible = false;
                });
            }
        },

        close_checkIn_dialog(index, type) {
            this.checkInPersonList[index].visible = false;
            window.removeEventListener("click", this.estimate_target, true);
        },
        change_person(index) {
            this.reserveForm.checkInPerson[index].pop();
        },
        format_weeks(val) {
            if (val) {
                return val
                    .split(",")
                    .map((item) => {
                        switch (Number(item)) {
                            case 1:
                                return "星期一";
                            case 2:
                                return "星期二";
                            case 3:
                                return "星期三";
                            case 4:
                                return "星期四";
                            case 5:
                                return "星期五";
                            case 6:
                                return "星期六";
                            case 7:
                                return "星期天";
                        }
                    })
                    .join();
            }
        },
        // 复制酒店信息
        async copyHotel(val) {
            console.log(val)
            this.copyDialog = true;
            this.copyLoading = true;
            const params = {
                companyId: Number(this.$route.query.behalfCompanyId),
                groupType: val.hotelFrom,
            }
            let displayedService = 0; // 是否单独展示服务费 0：否 1：是
            let serviceInfo = {};
            let [err, res] = await awaitWrap(Promise.all([consumer_TTpRefundChangePolicy_copyPriceWithService(params), consumer_product_getServiceAmount(params)]));
            if (res) {
                displayedService = res[0].datas.displayedService;
                serviceInfo = res[1].datas;
            }
            let platformPrice = val.platformPrice;
            this.copyTextarea = `酒店：${this.detail.hotelName}
地址：${this.detail.hotelAddr}
入住：${this.searchForm.checkInDate}
离店：${this.searchForm.checkOutDate} 共${val.nightlyRateList.length}晚
房型：${val.name}`;
            if (val.windowName) {
                this.copyTextarea += `(${val.windowName}, ${val.breakfastName})
`;
            } else {
                this.copyTextarea += `(${val.breakfastName})
`;
            }
            this.copyTextarea += `铨成结算${val.nightlyRateList.length > 1 ? '均' : ''}价：${val.averagePrice}元/间/晚
`;
if (displayedService === 1 && val.negotiatedPriceText) {
    let service = 0;
    if (serviceInfo.serviceAmountSet === 1) { // 服务费设置：0.不收取 1.收取
        if (serviceInfo.serviceAmountType === 1) { // 计算方式：1.百分比，2.定额
            service = Decimal(val.averagePrice).mul(serviceInfo.serviceAmount).toString();
            if (serviceInfo.limitAmount != null && service > serviceInfo.limitAmount) { // 如果超过上限金额
                service = serviceInfo.limitAmount
            }
        } else {
            service = serviceInfo.serviceAmount;
        }

    }
    this.copyTextarea +=`服务费：${service}元/间/晚
`;
    platformPrice = platformPrice + (service * val.nightlyRateList.length);
}
            this.copyTextarea +=`${val.nightlyRateList.length}晚总价格：${platformPrice}元
预付酒店预订成功不退不改`
            if (this.$route.query.behalfCompanyId == '81') {
                this.copyTextarea += '如需预定请确认行程报价是否与贵司差标相符'
            } else {
                this.copyTextarea += '是否符合差标请自行确认'
            }
            this.copyTextarea += '\n房态和价格最终以酒店确认为准'
            this.copyLoading = false;
        },

        //合并前原酒店信息
        async originalHotelPopup(hotelId) {
            let res = await consumer_hotel_search_getHotelStaticInfoResult({hotelId:hotelId});
            this.originalHotelInfo = res.data;
            this.originalHotelVisible = true;
        },

        //关闭合并前原酒店信息
        handleCloseOriginalHotel() {
            this.originalHotelVisible = false;
        }
    },
    filters: {
        format_checkIn(val, travellerList) {
            for (let i = 0; i < travellerList.length; i++) {
                const item = travellerList[i];
                if (item.userId === val) return item.staffName;
            }
        },
        format_star(val) {
            switch (Number(val)) {
                case 20:
                    return "经济型";
                case 25:
                    return "经济型";
                case 30:
                    return "舒适型";
                case 35:
                    return "舒适型";
                case 40:
                    return "高档型";
                case 45:
                    return "高档型";
                case 50:
                    return "豪华型";
                case 55:
                    return "豪华型";
            }
        },
    },
    activated() {
        this.init(this.$route.query);
    },
    deactivated() {
        this.reserveVisible = false;
    },
    destroyed() {
        this.reserveVisible = false;
    },
    watch: {
        checkInDate(val) {
            const form = this.searchForm;
            if (val) {
                const moment = this.$moment;
                const start = moment(val[0])
                    .add(30, "days")
                    .format("YYYYMMDD");
                const end = moment(val[1])
                    .add(30, "days")
                    .format("YYYYMMDD");

                if (start === end)
                    this.checkInDate = [
                        val[0],
                        moment(val[1])
                            .add(1, "days")
                            .format("YYYY-MM-DD"),
                    ];

                if (start < moment(val[1]).format("YYYYMMDD"))
                    this.checkInDate = [val[0], moment(start).format("YYYY-MM-DD")];
                form.checkInDate = val[0];
                form.checkOutDate = val[1];
            } else {
                form.checkInDate = "";
                form.checkOutDate = "";
            }
            this.getInTodayMorning();
        },
    },
};

// 常用联系人集合
import consumer_frequent_contacts_getList from '@/lib/data-service/haolv-default/consumer_frequent_contacts_getList'
// 删除常用旅客信息
import consumer_frequent_contacts_del from '@/lib/data-service/haolv-default/consumer_frequent_contacts_del'

export default {
    data() {
        return {
            params: {
                // 搜索参数
                choice: 1,

                // 分页参数
                currentPage: 1,
                pageSize: 10,
                totalCount: 0
            },
            loading: false,
            tableList: [],
        };
    },
    computed: {},
    components: {},
    methods: {
        get_table_list() { // 获取订单列表
            const __this = this;
            __this.loading = true;
            consumer_frequent_contacts_getList(__this.params).then(res => {
                __this.tableList = res.pageResult.pageData;
                __this.params.totalCount = res.pageResult.totalCount;
            }).finally(() => {
                __this.loading = false;
            })
        },
        handleSizeChange(size) {
            const __this = this;
            __this.params.currentPage = 1;
            __this.params.pageSize = size;
            __this.get_table_list()
        },
        handleCurrentChange(page) {
            const __this = this;
            __this.params.currentPage = page;
            __this.get_table_list()
        },
        add_traveller() {
            const __this = this;
            __this.$router.push({
                name: 'admin-often-traveller-add'
            })
        },
        edit_traveller(row) {
            const __this = this;
            __this.$router.push({
                name: 'admin-often-traveller-add',
                query: {
                    id: row.id
                }
            })
        },
        delete_traveller(row) {
            const __this = this;
            __this.$confirm('确认删除该出行人?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                __this.loading = true;
                const ids = [row.id];
                consumer_frequent_contacts_del(ids).then(res => {
                    __this.$message.success('删除成功!');
                    __this.get_table_list();
                }).finally(() => {
                    __this.loading = false;
                })

            }).catch(() => {});
        }
    },
    created() {

    },
    activated() {
        const __this = this;
        __this.get_table_list();
    },
    mounted() {

    },
    watch: {},
    filters: {},
};

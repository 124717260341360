/*
* 平台酒店退订订单列表   http://api-alpha.haolvtrip.com/doc.html#/haolv-biz/%E5%B9%B3%E5%8F%B0%E9%85%92%E5%BA%97%E8%AE%A2%E5%8D%95/getHotelListUsingPOST
*/
const __request = require(`./__request/__request_contentType_json`)
const consumer_admin_hotel_order_CancelOrderList = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/admin/hotel/order/CancelOrderList',
    data: data
  }
  return __request(pParameter)
}
export default consumer_admin_hotel_order_CancelOrderList
import { Loading } from "element-ui";

// 枚举
// import consumer_credit_getEnum from "@/lib/data-service/haolv-default/consumer_credit_getEnum.js";
// 详情
import get_detail from "@/lib/data-service/haolv-default/consumer_credit_auditCreditDetail.js";
// 审批
import apply_approve from "@/lib/data-service/haolv-default/consumer_credit_auditCredit.js";

export default {
  data() {
    return {
      loading: false,
      type: "",
      detail: {},
      form: {
        companyId: "",
        estimateConsumption: "", //预计月消费量/万元
        settlementInterval: "", //结算周期
        invoice: "", //发票类型
        creditAmount: "", //授信额度
      },
      rules: {
        estimateConsumption: [
          { required: true, message: "请输入预计月消费量", trigger: "blur" },
        ],
        settlementInterval: [
          { required: true, message: "请输入结算周期", trigger: "blur" },
        ],
        invoice: [
          { required: true, message: "请输入发票类型", trigger: "blur" },
        ],
        creditAmount: [
          { required: true, message: "请输入授信额度", trigger: "blur" },
        ],
      },
      periodList: [
        { label: "标准自然月", value: "1" },
        { label: "每月16日至次月15日", value: "2" },
        { label: "每月26日至次月25日", value: "3" },
        { label: "每月6日至次月5日", value: "4" },
        { label: "每月11日至次月10日", value: "5" },
      ],
      invoiceTypeList: [
        { label: "1", text: "纸质发票" },
        { label: "2", text: "电子发票" },
      ],
      loadingInstance: null,
      rejectVisible: false,
      rejectForm: {
        rejectReason: "", //失败原因
      },
      rejectRules: {
        rejectReason: [
          { required: true, message: "请输入失败原因", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    req_detail() {
      this.loading = true;
      get_detail({ companyId: this.form.companyId })
        .then((result) => {
          let detail = result.datas;
          detail.companyTransactions.forEach((value) => {
            if (value.type === "机票") {
              detail.flightFlowing = value.flowing; // 交易流水
              detail.flightOrderCount = value.orderCount; // 订单量
              detail.flightPassengerCount = value.passengerCount; // 出行人数
            } else if (value.type === "酒店") {
              detail.hotelFlowing = value.flowing;
              detail.hotelOrderCount = value.orderCount;
              detail.hotelPassengerCount = value.passengerCount;
            } else {
              detail.trainFlowing = value.flowing;
              detail.trainOrderCount = value.orderCount;
              detail.trainPassengerCount = value.passengerCount;
            }
          });
          this.detail = detail;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    req_change_status(status) {
      this.loadingInstance = Loading.service({ text: "请求中..." });
      apply_approve({ ...this.form, status })
        .then((result) => {
          this.loadingInstance.close();
          if (status === 2) {
            this.$message.success("设置审批通过成功");
          } else if (status === 3) {
            this.$message.success("设置审批拒绝成功");
          }
          this.$router.replace({
            name: "admin-credit-approve-list",
          });
        })
        .catch((err) => {
          this.loadingInstance.close();
        });
    },
    init() {
      const { type, companyId } = this.$route.query;
      this.type = type;
      this.form.companyId = companyId;
      this.req_detail();
      // if (type === 'approve') consumer_credit_getEnum();
    },
    commit_approve() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.req_change_status(2);
        } else {
          return;
        }
      });
    },
    fail_approve() {
      this.rejectVisible = true;
      // rejectReason
      // this.req_change_status(3);
    },
    close_reject() {
      this.rejectVisible = false;
    },
    commit_fail() {
      this.$refs.rejectForm.validate((valid) => {
        if (valid) {
          this.loadingInstance = Loading.service({ text: "请求中..." });
          apply_approve({ companyId: this.form.companyId, ...this.rejectForm, status: 3 })
            .then((res) => {
              this.close_reject();
              this.loadingInstance.close();
              this.$message.success("设置审批拒绝成功");
              this.$router.replace({
                name: "admin-credit-approve-list",
              });
            })
            .catch((err) => {
              this.loadingInstance.close();
              this.$message.success("设置审批拒绝成功");
            });
        } else {
          return false;
        }
      });
    },

    back() {
      this.$router.back();
    },
  },
  activated() {
    this.init();
  },
  watch: {},
  filters: {
    format_scale(val) {
      switch (val) {
        case 1:
          return "0~20人";
          break;
        case 2:
          return "20~50人";
          break;
        case 3:
          return "50~100人";
          break;
        case 4:
          return "100~300人";
          break;
        case 5:
          return "300~1000人";
          break;
        case 6:
          return "1000人以上";
          break;
      }
    },
    filterFMoney(val) {
      function fmoney(s, n) {
        n = n > 0 && n <= 20 ? n : 2;
        s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
        let l = s
            .split(".")[0]
            .split("")
            .reverse(),
          r = s.split(".")[1];
        let t = "";
        for (let i = 0; i < l.length; i++) {
          t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? "," : "");
        }
        return (
          t
            .split("")
            .reverse()
            .join("") +
          "." +
          r
        );
      }
      return fmoney(val.toString(), 2);
    },
  },
};

import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.2.1/index.vue';
import HotelCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-city-selector/1.1.0/index.vue';
import DomesticTicketAirportSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.2.2/index.vue'
import CompanyInfo from '@/component/company-info/import_latest_version_module'
import consumer_t_od_hotel_member_phone_list from '@/lib/data-service/haolv-default/consumer_t_od_hotel_member_phone_list'
export default {
    data() {
        const validateHotelDateArr = (rule, value, callback) => {
            if (this.hotelParams.hotelCheckInDate === '' || this.hotelParams.hotelCheckOutDate === '') {
                callback(new Error('请输入入住日期及离店日期'))
            } else {
                callback()
            }
        };
        return {
            activeName: 'first',
            flightParams: {
                flightType: 1,
                flightFromStation: null,
                flightToStation: null,
                flightStartTime: '',
                flightEndTime: '',
            },
            flightPickerOptions: {
                disabledDate: this.flightDisabledDate
            },
            flightEndPickerOptions: {
                disabledDate: this.flightEndDisabledDate
            },
            flightRule: {
                flightType: [
                    { required: true, message: '请选择航班类型', trigger: 'change' },
                ],
                flightFromStation: [
                    { required: true, message: '请输入出发城市', trigger: 'blur' },
                ],
                flightToStation: [
                    { required: true, message: '请输入到达城市', trigger: 'blur' },
                ],
                flightStartTime: [
                    { required: true, message: '请输入出发时间', trigger: ['blur', 'change'] },
                ],
                flightEndTime: [
                    { required: true, message: '请输入返回日期', trigger: 'blur' },
                ],
            },
            hotelParams: {
                hotelCitySelector: null,
                hotelKeyword: '',
                hotelStarType: '',
                hotelDateArr: [],
                hotelCheckInDate: '',
                hotelCheckOutDate: '',
            },
            hotelRule: {
                hotelCitySelector: [
                    { required: true, message: '请输入目的地', trigger: 'blur' },
                ],
                hotelDateArr: [
                    { required: true, message: '请输入入住日期及离店日期', trigger: 'blur' },
                    { validator: validateHotelDateArr, trigger: 'blur' }
                ]
            },
            hotelPickerOptions: {
                disabledDate: this.hotelDisabledDate,
                onPick: this.hotelOnPick
            },
            timeOptionRange: null,
            hotelStarList: [
                {
                    value: '',
                    label: "不限"
                },
                {
                    value: '2',
                    label: "经济型"
                },
                {
                    value: '3',
                    label: "舒适型"
                },
                {
                    value: '4',
                    label: "高档型"
                },
                {
                    value: '5',
                    label: "豪华型"
                }
            ],
            hotelNight: 0,
            trainParams: {
                fromStation: null,
                toStation: null,
                time: '',
                onlySearch: 0
            },
            ruleRule: {
                fromStation: [
                    { required: true, message: '请输入出发地', trigger: 'blur' },
                ],
                toStation: [
                    { required: true, message: '请输入目的地', trigger: 'blur' },
                ],
                time: [
                    { required: true, message: '请输入出发时间', trigger: 'change' },
                ],
            },
            pickerOptions: {
                disabledDate: this.disabledDate
            },
            canSubmit: true,
            companyId: '',
            memberPhoneList: [],
            today: '',//今天-'YYY-MM-DD'
            yesterday: '',//昨天-'YYY-MM-DD'
            isMorning: false,//当前时间是否为凌晨00:00-06:00
            isTodayMorning: false,//是否今天凌晨入住
        }
    },
    components: {
        TrainCitySelector,
        HotelCitySelector,
        DomesticTicketAirportSelector,
        CompanyInfo
    },
    created() { },
    mounted() {
        this.$refs.formFlightCitySelector.init({
            placeholder_text: `请选择出发城市`,
        });
        this.$refs.formTrainCitySelector.init({
            placeholder_text: `请选择出发城市`,
        })
    },
    activated() {
        this.companyId = this.$route.query.behalfCompanyId;
        this.activeName = this.$route.query.activeName? this.$route.query.activeName: 'first';
        let curTimeList = this.$moment().toArray();
        this.isMorning = curTimeList[3] < 6;
        if (this.isMorning) {
            //凌晨时进入预订页面需要做的事情
            this.initDateForMorning();
        }
    },
    deactivated() {},
    destroyed() {},
    watch: {
        'flightParams.flightFromStation'(newVal) {
            this.$refs['flightForm'].validateField('flightFromStation')
        },
        'flightParams.flightToStation'(newVal) {
            this.$refs['flightForm'].validateField('flightToStation')
        },
        'hotelParams.hotelCitySelector'(newVal) {
            this.$refs['hotelForm'].validateField('hotelCitySelector')
        },
        'trainParams.fromStation'(newVal) {
            this.$refs['trainForm'].validateField('fromStation')
        },
        'trainParams.toStation'(newVal) {
            this.$refs['trainForm'].validateField('toStation')
        }
    },
    computed: {},
    filters: {},
    methods: {
        back() {
          this.$router.replace({
            name: "admin-replace-orders",
          });
        },
        handleClick() {
        },
        flightDisabledDate(time) {
            if (this.flightParams.flightEndTime === '' || this.flightParams.flightEndTime === null) {
                return (time.getTime() < Date.now() - 8.64e7)
            } else {
                return (time.getTime() < (Date.now() - 8.64e7) || time.getTime() > (new Date(this.flightParams.flightEndTime).getTime() - 8.64e7))
            }
        },
        flightEndDisabledDate(time) {
            if (this.flightParams.flightStartTime === '' || this.flightParams.flightStartTime === null) {
                return (time.getTime() < Date.now() - 8.64e7)
            } else {
                return time.getTime() < (new Date(this.flightParams.flightStartTime).getTime() - 8.64e7)
            }
        },
        changeFlightAdr() {
            let flightFromStation = Object.assign({}, this.flightParams.flightFromStation);
            let flightToStation = Object.assign({}, this.flightParams.flightToStation);
            this.flightParams.flightFromStation = flightToStation;
            this.flightParams.flightToStation = flightFromStation;
            this.$set(this.flightParams, 'flightFromStation', flightToStation);
            this.$set(this.flightParams, 'flightToStation', flightFromStation);
        },
        changeFlightType() {
            this.$set(this.flightParams, 'flightEndTime', '')
        },
        searchFlight() {
            if (!this.flightParams.flightFromStation) {
                this.flightParams.flightFromStation = {
                    "name": "北京",
                    "code": "BJS",
                    "cityCode": "",
                    "initial": "B",
                    "spell": "Beijing",
                    "isHot": "Y",
                    "airports": false,
                };
            }
            if (!this.flightParams.flightToStation) {
                this.flightParams.flightToStation = {
                    "name": "广州",
                    "code": "CAN",
                    "cityCode": "",
                    "initial": "G",
                    "spell": "Guangzhou",
                    "isHot": "Y",
                    "airports": false,
                };
            }
            if (!this.flightParams.flightStartTime) {
                const moment = this.$moment();
                const flightStartTime = moment.format('yyyy-MM-DD');
                this.flightParams.flightStartTime = flightStartTime;
            }
            if (this.flightParams.flightType !== 1 && !this.flightParams.flightEndTime) {
                const moment = this.$moment(this.flightParams.flightStartTime);
                moment.add(1, 'd');
                const flightEndTime = moment.format('yyyy-MM-DD');
                this.flightParams.flightEndTime = flightEndTime;
            }
            this.$router.push({
                name: 'admin-flight-search',
                query: {
                    fromStationCode: this.flightParams.flightFromStation.code,
                    fromStationName: this.flightParams.flightFromStation.name,
                    toStationCode: this.flightParams.flightToStation.code,
                    toStationName: this.flightParams.flightToStation.name,
                    startDate: this.flightParams.flightStartTime,
                    endDate: this.flightParams.flightEndTime,
                    flightType: this.flightParams.flightType,
                    evectionNo: '',
                    redirectType: '000',
                    evectionType: 2, // 1是个人出差，2是出差单出差
                    canShowSendMsg: 1, // 是否可以显示勾选短信 0是不可以， 1是可以
                    companyId: this.companyId,
                    flightIndex: 1,
                },
            });
        },
        hotelDisabledDate(time) {
            // return (time.getTime() < Date.now() - 8.64e7)
            // 获取选中时间
            let timeOptionRange = this.timeOptionRange;
            // 获取时间范围(30天的毫秒数)
            let secondNum = 30 * 24 * 60 * 60 * 1000;
            if (timeOptionRange) {
                //如果有选中时间 设置超过选中时间后的30天||超过选中前的30天||大于当前时间 不可选
                return time.getTime() > timeOptionRange.getTime() + secondNum || time.getTime() <= timeOptionRange.getTime();
            } else {
                //没有选中时间（初始化状态）
                if (this.isMorning) {
                    //如果在凌晨(00:00-06:00)时段进入页面，设置昨天以前的时间不可选
                    let yesterday = new Date();
                    yesterday.setDate(yesterday.getDate() - 1);
                    let timestamp = yesterday.getTime();
                    return time.getTime() < (timestamp - 8.64e7);
                } else {
                    //如果在非凌晨(00:00-06:00)时段进入页面，设置当前时间后的时间不可选
                    return time.getTime() < (Date.now() - 8.64e7);
                }
            }
        },
        hotelOnPick({ maxDate, minDate }) {
            // 当选中了第一个日期还没选第二个
            // 只选中一个的时候自动赋值给minDate，当选中第二个后组件自动匹配，将大小日期分别赋值给maxDate、minDate
            if (minDate && !maxDate) {
                this.timeOptionRange = minDate;
            }
            // 如果有maxDate属性，表示2个日期都选择了，则重置timeOptionRange
            if (maxDate) {
                this.timeOptionRange = null;
            }
        },
        changeHotelDate(val) {
            if (val === null) {
                this.hotelParams.hotelCheckInDate = '';
                this.hotelParams.hotelCheckOutDate = '';
                this.hotelNight = 0
            } else {
                this.hotelParams.hotelCheckInDate = val[0];
                this.hotelParams.hotelCheckOutDate = val[1];
                this.hotelNight = this.$moment(val[1]).diff(this.$moment(val[0]), 'days')

                if (this.isMorning) {
                    let checkInDateStr = this.$moment(val[0]).format('YYYY-MM-DD');
                    this.isTodayMorning = checkInDateStr === this.yesterday ? true : false;
                }
            }
        },
        blurHotelDate() {
            this.timeOptionRange = null
        },
        searchHotel() {
            if (!this.hotelParams.hotelCitySelector) {
                this.hotelParams.hotelCitySelector = {
                    "cityId": "70082",
                    "cityCn": "北京",
                    "cityPinyin": "Beijing",
                    "cityPinyinSimp": "BJ",
                    "initial": "B",
                    "isHot": "Y",
                };
            }
            if (this.hotelParams.hotelDateArr instanceof Array) {
                if (this.hotelParams.hotelDateArr.length === 0) {
                    const moment = this.$moment();
                    const start = moment.format('yyyy-MM-DD');
                    moment.add(1, 'd');
                    const end = moment.format('yyyy-MM-DD');
                    this.hotelParams.hotelDateArr = [start, end];
                }
            } else {
                const moment = this.$moment();
                const start = moment.format('yyyy-MM-DD');
                moment.add(1, 'd');
                const end = moment.format('yyyy-MM-DD');
                this.hotelParams.hotelDateArr = [start, end];
            }

            this.hotelParams.hotelCheckInDate = this.$moment(this.hotelParams.hotelDateArr[0]).format('YYYY-MM-DD');
            this.hotelParams.hotelCheckOutDate = this.$moment(this.hotelParams.hotelDateArr[1]).format('YYYY-MM-DD');

            const query = {
                cityId: this.hotelParams.hotelCitySelector.cityId,
                cityName: this.hotelParams.hotelCitySelector.cityCn,
                checkInDate: this.hotelParams.hotelCheckInDate,
                checkOutDate: this.hotelParams.hotelCheckOutDate,
                keyword: this.hotelParams.hotelKeyword,
                hotelStar: this.hotelParams.hotelStarType,
                evectionNo: '',
                redirectType: '000',
                evectionType: 2, // 1是个人出差，2是出差单出差
                canShowSendMsg: 1, // 是否可以显示勾选短信 0是不可以， 1是可以
                companyId: this.companyId,
                memberPhone: this.hotelParams.memberPhone ? this.hotelParams.memberPhone.slice(0, 11) : '',
            };

            this.$router.push({
                name: 'admin-hotel-search',
                query,
            });
        },
        initDateForMorning() {
            this.today = this.$moment().format('YYYY-MM-DD');
            this.yesterday = this.$moment().add(-1,'days').format('YYYY-MM-DD');
        },
        disabledDate(time) {
            let month = this.$moment().month();
            let day = 0;
            if (month === 0 || month === 1) {
                day = 61
            } else if (month === 6) {
                day = 64
            } else {
                day = 63
            }
            return (time.getTime() < Date.now() - 8.64e7) || (time.getTime() > (Date.now() - 8.64e7 + day * 24 * 60 * 60 * 1000))
        },
        changeTrainAdr() {
            let fromStation = Object.assign({}, this.trainParams.fromStation);
            let toStation = Object.assign({}, this.trainParams.toStation);
            this.trainParams.fromStation = toStation;
            this.trainParams.toStation = fromStation;
            this.$set(this.trainParams, 'fromStation', toStation);
            this.$set(this.trainParams, 'toStation', fromStation);
        },
        searchTrain() {
            if (!this.trainParams.fromStation) {
                this.trainParams.fromStation = {
                    "sn": 131,
                    "stationName": "北京",
                    "stationPinYin": "beijing",
                    "firstLetter": "B",
                    "jianpin": "bj",
                    "stationCode": "BJP",
                    "isHotCity": 1,
                    "stationLevel": 1,
                };
            }
            if (!this.trainParams.toStation) {
                this.trainParams.toStation = {
                    "sn": 704,
                    "stationName": "广州",
                    "stationPinYin": "guangzhou",
                    "firstLetter": "G",
                    "jianpin": "gz",
                    "stationCode": "GZQ",
                    "isHotCity": 1,
                    "stationLevel": 1,
                };
            }
            if (!this.trainParams.time) {
                this.trainParams.time = this.$moment().format('yyyy-MM-DD');
            }
            this.$router.push({
                name: 'admin-train-search',
                query: {
                    fromStationCode: this.trainParams.fromStation.stationCode,
                    toStationCode: this.trainParams.toStation.stationCode,
                    time: this.trainParams.time,
                    onlySearch: this.trainParams.onlySearch,
                    evectionNo: '',
                    redirectType: '000',
                    evectionType: 2, // 1是个人出差，2是出差单出差
                    canShowSendMsg: 1, // 是否可以显示勾选短信 0是不可以， 1是可以
                    companyId: this.companyId,
                }
            });
        },
        async getMeituanMemberPhone(queryString, cb){
            let res = await consumer_t_od_hotel_member_phone_list();
            if(!res || !res.pageResult || !res.pageResult.pageData) {
                return cb([]);
            }
            this.memberPhoneList = res.pageResult.pageData.map(item => {
                let memberPhoneObj = {
                    id: item.id,
                    value: item.memberPhone + '\xa0\xa0\xa0\xa0' + item.memberLevel
                }
                return memberPhoneObj;
            });
            cb(this.memberPhoneList)
        }
    }
};

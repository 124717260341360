import _ from 'underscore';
import OrderLog from '@/component/orderLog/2.0.0/index.vue'
import consumer_admin_trains_order_trainsRefundOrderDetails
    from "@/lib/data-service/haolv-default/consumer_admin_trains_order_trainsRefundOrderDetails";//获取退订单详情
import consumer_log_trainsList from '@/lib/data-service/haolv-default/consumer_log_trainsList';//获取订单详情
import consumer_admin_trains_order_remarkSaveOrUpdate
    from "@/lib/data-service/haolv-default/consumer_admin_trains_order_remarkSaveOrUpdate";
import ChangeService from "@/component/change-service/index.vue";
//火车票订单保存备注操作

export default {
    data() {
        return {
            loading: false,
            logLoading: false,
            activeName: 'detail',
            orderNo: '',//订单号
            refundNo: '',//退票订单号
            details: {},//详情
            refundOrderDetails: [],//订单信息
            journeyInfo: [],//行程信息
            trainsRefundPassenger: [],//乘客信息
            refundInformation: [],//退票信息
            remark: ''//备注
        };
    },
    components: {ChangeService, OrderLog},
    methods: {
        //初始化
        async init() {
            let {
                orderNo,
                refundNo,
            } = this.$route.query;
            this.orderNo = orderNo;
            this.refundNo = refundNo;
            await this.getDetail();
            await this.getOrderLog();
        },

        //获取详情
        async getDetail() {
            this.loading = true;
            let res = await consumer_admin_trains_order_trainsRefundOrderDetails({refundNo: this.refundNo});
            this.details = res.datas;
            this.remark = res.datas.remark;
            this.refundOrderDetails = this.details.refundOrderDetails;
            this.journeyInfo = this.details.journeyInfo;
            this.trainsRefundPassenger = this.journeyInfo[0].trainsRefundPassenger;
            this.refundInformation = this.details.refundInformation;
            this.loading = false;
        },

        //获取订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.logLoading = true
                    // 调用获取订单日志的接口
                    return consumer_log_trainsList({
                        orderNo: this.orderNo
                    }).then(res => {
                        // 组装组件需要的list
                        const list = _.chain(res.datas)
                            .map(function (m) {
                                // 替换名字
                                const o = {
                                    operator: m.operator,
                                    date: m.operatorTime,
                                    type: m.logSource,
                                    detail: m.logContents
                                }
                                return o
                            }).value()
                        const d = {
                            entityList: list
                        }
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.logLoading = false
                    })
                }
            })
        },

        //保存备注
        submit() {
            if (!this.remark) {
                this.$message({
                    type: 'warning',
                    message: '请输入备注！'
                });
                return
            }
            this.loading = true;
            let params = {
                businessType: 3,
                orderNo: this.refundNo,
                remark: this.remark
            };
            consumer_admin_trains_order_remarkSaveOrUpdate(params).then(res => {
                this.$message({
                    type: 'success',
                    message: '保存成功！'
                });
                this.getDetail();
                this.loading = false;
            })
        },

        //返回
        back() {
            this.$router.go(-1);
        },
        // 调整服务费
        changeService() {
            let params = {
                businessType: 23,
                orderNo: this.refundNo,
                oldService: this.refundInformation[0].serviceChargePrice,
            };
            this.$refs.changeService.init(params).then(res=>{
                this.getDetail();
            }).catch((e)=>{})
        },
    },
    activated() {
        this.init();
    }
}
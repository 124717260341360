const __request = require(`./__request/__request_contentType_json`);
const consumer_logManage_bizlog_pageList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/logManage/bizlog/pageList',
        data: pParameter,
        enable_error_alert: false // 临时隐藏
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_logManage_bizlog_pageList;
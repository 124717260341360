const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_flight_equity_getOrderProductInfo = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/flight/equity/getOrderProductInfo',
        data: pParameter,
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_flight_equity_getOrderProductInfo;
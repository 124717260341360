

const __request = require(`./__request/__request_contentType_json`)
const consumer_admin_car_order_adminCarOrderBillDetailsExcel = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/admin/car/order/adminCarOrderBillDetailsExcel',
    data: data
  };
  return __request(pParameter)
};
export default consumer_admin_car_order_adminCarOrderBillDetailsExcel
import Q from 'q';
import consumer_notice_removeById from "@/lib/data-service/haolv-default/consumer_notice_removeById.js";
import SearchBox from '@/component/search-box/1.0.1/index.vue'
import consumer_sys_app_publishList from "@/lib/data-service/haolv-default/consumer_sys_app_publishList.js";
import consumer_sys_app_addOrDelete from "@/lib/data-service/haolv-default/consumer_sys_app_addOrDelete.js";
import moment from 'moment';

export default {
    data() {
        return {
            loading: true,
            pager: {
                pageSizes: [10, 15, 20, 25, 30],
                totalCount: 0,
            },
            list: [],
            form: {
                appName: '',
                appVersion: '',
                content: '',
                publishTime: '',
                forceUpdate: 0,
                currentPage: 1,
                pageSize: 10,
            },
            forceUpdateList: [
                {}
            ],
            dialogVisible: false,
            loadingDialog: false,
            rules: {
                appName: [
                    { required: true, message: '请输入APP包名', trigger: 'blur' },
                ],
                appVersion: [
                    { required: true, message: '请输入APP版本', trigger: 'blur' },
                ],
                forceUpdate: [
                    { required: true, message: '请选择是否强制更新', trigger: 'blur' },
                ],
                publishTime: [
                    { required: true, message: '请选择发布时间', trigger: 'blur' },
                ],
                content: [
                    { required: true, message: '请填写更新内容', trigger: 'blur' },
                ],
            },
            ruleForm: {
                appName: '',
                appVersion: '',
                content: '',
                forceUpdate: '1',
                publishTime: '',
            },
        }
    },
    components: {
        SearchBox,
    },
    created() {
    },
    mounted(){
        // this.$refs.searchBoxRef.moreConditionSwitch = false;
    },
    activated() {
        this.setList();
    },
    methods: {
        toPublish() {
            this.$router.push({
                name: 'admin-publish-notice'
            })
        },
        reset(){},
        // 获取列表
        setList() {
            consumer_sys_app_publishList(this.form).then((res) => {
                    this.form.totalCount = res.totalCount;
                    this.list = res.pageData;
                    this.loading = false;
                }).finally(() => {
                    this.loading = false
                })
        },
        handleDelete(row) {
            this.$confirm('是否删除此版本?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true;
                consumer_sys_app_addOrDelete({ id: row.id })
                    .then(() => {
                        this.loading = false;
                        this.setList();
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            })
        },
        addVersion(){
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {

                    this.loadingDialog = true;
                    let box = {
                        appName: this.ruleForm.appName,
                        appVersion: this.ruleForm.appVersion,
                        content: this.ruleForm.content,
                        forceUpdate: Number(this.ruleForm.forceUpdate),
                        publishTime: this.$moment(this.ruleForm.publishTime).format('YYYY-MM-DD HH:mm:ss'),
                    };
                    consumer_sys_app_addOrDelete(box)
                        .then((res) => {
                            this.loadingDialog = false;
                            this.dialogVisible = false;
                            this.$message({
                                message: '添加成功',
                                type: 'success'
                            });
                            this.setList();
                        })
                        .finally(() => {
                            this.loadingDialog = false;
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        clickAdd(){
            this.dialogVisible = true;
        },
        // 切换页码
        handleCurrentChange(page) {
            this.form.currentPage = page;
            this.setList();
        },
        // 切换每页数量
        handleSizeChange(size) {
            this.form.pageSize = size;
            this.setList();
        },
    },
}
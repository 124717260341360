import Q from 'q';
import _ from 'underscore';

import DomesticTicketAirportSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.2.2/index.vue';

export default {
    components: {
        DomesticTicketAirportSelector,
    },
    data() {
        return {
            selected_entity_aDomesticTicketAirportSelector: null,
            // selected_entity_aDomesticTicketAirportSelector: {
            //     "name": "北京",
            //     "code": "BJS",
            //     "cityCode": "",
            //     "initial": "B",
            //     "spell": "Beijing",
            //     "isHot": "Y",
            //     "airports": false
            // },
        };
    },
    methods: {
        __btn_get_value_click() {
            const __this = this;
            console.log(__this.selected_entity_aDomesticTicketAirportSelector);
        },

        __btn_set_value_click() {
            const __this = this;
            //这样赋值是不可以的，因为监听的是对象！
            // __this.selected_entity_aDomesticTicketAirportSelector.stationCode = `GZQ`;
            //这样赋值才可以！
            __this.selected_entity_aDomesticTicketAirportSelector = {
                "name": "北京",
                "code": "BJS",
                "cityCode": "",
                "initial": "B",
                "spell": "Beijing",
                "isHot": "Y",
                "airports": false
            };
        },

        __btn_reset_value_click() {
            const __this = this;
            //这样赋值是不可以的，因为监听的是对象！
            // __this.selected_entity_aDomesticTicketAirportSelector.stationCode = ``;
            //这样赋值才可以！
            __this.selected_entity_aDomesticTicketAirportSelector = {
                'code': '',
            };
            // __this.selected_entity_aDomesticTicketAirportSelector = null;
        },

        __btn_set_entity_click() {
            const __this = this;
            __this.$refs.aDomesticTicketAirportSelector.set_entity({
                type: `000`,
                type_parameter: {
                    '000': {
                        name: `深圳`,
                    },
                }
            });
        },

    },
    watch: {
        selected_entity_aDomesticTicketAirportSelector(pNewValue, pOldValue) {
            const __this = this;
            // console.log(`pNewValue=`);
            // console.log(pNewValue);
            // console.log(`pOldValue=`);
            // console.log(pOldValue);
        },
    },
    mounted() {
        const __this = this;
        // __this.selected_entity_aDomesticTicketAirportSelector = {
        //     "name": "北京",
        //     "code": "BJS",
        //     "cityCode": "",
        //     "initial": "B",
        //     "spell": "Beijing",
        //     "isHot": "Y",
        //     "airports": false
        // };
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}

// consumer_admin_visa_billAction
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_visa_billAction = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/visa/billAction',
        data: pParameter
    };
    return new Promise(function (resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_visa_billAction;
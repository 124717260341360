const __request = require(`./__request/__request_contentType_json`)
const consumer_statement_invoiceList = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: 'consumer/statement/invoiceList',
        data: data
    };
    return __request(pParameter)
};
export default consumer_statement_invoiceList

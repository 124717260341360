// consumer_behalfOrder
const __request = require(`./__request/__request_contentType_json`);
const consumer_behalfOrder = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/payment/behalfOrder',
        // urlSuffix: '/admin/payment/planetbehalfOrder',
        data: pParameter,
        enable_error_alert: false // 临时隐藏
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_behalfOrder;
// consumer_admin_insurance_accreditCusList 客服平台-授权客户列表
const __request = require(`./__request/__request_contentType_json`);
const consumer_admin_insurance_accreditCusList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/insurance/accreditCusList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_insurance_accreditCusList;
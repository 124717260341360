// 封装异步组件
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";
// 客户审核详情
import consumer_web_company_detail from "@/lib/data-service/haolv-default/consumer_web_company_detail.js";
//公司发票类型枚举
import consumerweb_company_queryCompanyInvoiceType from "@/lib/data-service/haolv-default/consumerweb_company_queryCompanyInvoiceType.js";
//获取协议卡等级数据集合
import consumer_web_company_getPnrSetType from "@/lib/data-service/haolv-default/consumer_web_company_getPnrSetType.js";
// 行业
import consumer_web_industry_selectIndustry from '@/lib/data-service/haolv-default/consumer_web_industry_selectIndustry.js';
import consumer_sys_inform_getEnum from "@/lib/data-service/haolv-default/consumer_sys_inform_getEnum";
import consumer_admin_company_query from "@/lib/data-service/haolv-default/consumer_admin_company_query";
export default {
    data() {
        return {
            loading: false,
            cardInfo: [
                {
                    more: true,
                    edit: false,
                },
                {
                    more: false,
                    edit: false,
                },
                {
                    more: false,
                    edit: false,
                },
                {
                    more: false,
                    edit: false,
                },
                {
                    more: false,
                    edit: [false, false, false, false],
                },
                {
                    more: false,
                    edit: false,
                },
                {
                    more: false,
                    edit: false,
                },
                {
                    more: false,
                    edit: false,
                }, // 结算设置
            ],
            detail: {},

            serviceChargeData: true,

            allocation: {
                provinceList: [], // 省级列表
                cityList: [], // 市级列表
                bankList: [], // 银行列表
                settlementList: [], // 服务商
                industryType: [],
                enterpriseScale: [
                    {value: 1, label: "0~20人"},
                    {value: 2, label: "20~50人"},
                    {value: 3, label: "50~100人"},
                    {value: 4, label: "100~300人"},
                    {value: 5, label: "300~1000人"},
                    {value: 6, label: "1000人以上"},
                ],
                serviceAmountSet: [
                    {label: 0, text: "不收取"},
                    {label: 1, text: "收取"},
                ],
                serviceAmountType: [
                    {label: 1, text: "百分比"},
                    {label: 2, text: "定额"},
                ],
                serviceAmountTypeCar: [
                    {label: 2, text: "定额"},
                ],
                manager: [],
                carryRules: [
                    {label: 0, text: "进位到元"},
                    {label: 1, text: "进位到分"},
                ],
                audit: [
                    {label: true, text: "开通"},
                    {label: false, text: "不开通"},
                ],
                allowUseCar: [
                    {label: 1, text: "开通"},
                    {label: 0, text: "不开通"},
                ],
                openMemberMt: [
                    {label: 1, text: "开通"},
                    {label: 0, text: "不开通"},
                ],
                setServiceAmountType: [
                    {label: 0, text: "不加价"},
                    {label: 1, text: "加价"},
                ],
                settlementInterval: 5,
                creditPaymentDays: 28,
                dueDate: 30,
                overdueDays: 15,
            },

            matchSetDetail: {
                "companyName": "",
                "companyId": "",
                "hotelSplit": true,
                "settlingDay": '',
                "invoiceType": {
                    "flight": "",
                    "itinerary": "",
                    "flightRef": "",
                    "train": "",
                    "hotel": "",
                    "car": "",
                    "ship": "",
                    "door": "",
                    "visa": "",
                    "extra": "",
                    "serviceFee": ""
                }
            },
        }
    },
    components: {},
    created() {
    },
    mounted() {
        consumer_web_industry_selectIndustry().then((res) => {
            __this.allocation.industryType = res.datas;
        });
        // consumer_web_company_getPnrSetType().then(res => {
        //     this.pnrSetOptions = res.datas.result;
        // });
        consumer_sys_inform_getEnum(["settlement"]).then((res) => {
            this.allocation.settlementList = res.datas.settlement;
        });
    },
    async activated() {
        await this.init();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        format_pass(val) {
            switch (val) {
                case 1:
                    return "是";
                    break;
                case 0:
                    return "否";
                    break;
            }
        },
        format_carryRules(val) {
            switch (Number(val)) {
                case 0:
                    return "进位到元";
                    break;
                case 1:
                    return "进位到分";
                    break;
            }
        },
        format_amountSet(val) {
            switch (Number(val)) {
                case 0:
                    return "不收取";
                    break;
                case 1:
                    return "收取";
                    break;
            }
        },
        format_amountType(val) {
            switch (Number(val)) {
                case 1:
                    return "百分比";
                    break;
                case 2:
                    return "定额";
                    break;
                default:
                    return ''
            }
        },
        format_scale(val) {
            if (val === 1) {
                return '0~20人'
            } else if (val === 2) {
                return '20~50人'
            } else if (val === 3) {
                return '50~100人'
            } else if (val === 4) {
                return '100~300人'
            } else if (val === 5) {
                return '300~1000人'
            } else if (val === 6) {
                return '1000人以上'
            } else {
                return ''
            }
        },
        format_audit(val) {
            switch (Boolean(val)) {
                case true:
                    return "是";
                    break;
                case false:
                    return "否";
                    break;
            }
        },
        format_allowUseCar(val) {
            switch (val) {
                case 1:
                    return "是";
                    break;
                case 0:
                    return "否";
                    break;
            }
        },
        format_openMemberMt(val) {
            switch (val) {
                case 1:
                    return "是";
                    break;
                case 0:
                    return "否";
                    break;
            }
        },
        format_type(val) {
            // "业务类型：1.机票，2.火车票，3.酒店，4.汽车"
            switch (Number(val)) {
                case 4:
                    return "用车";
                    break;
                case 2:
                    return "火车票";
                    break;
                case 3:
                    return "酒店";
                    break;
                case 1:
                    return "机票";
                    break;
            }
        },
        format_policyStatus(val) {
            switch (Number(val)) {
                case 1:
                    return "申请中";
                    break;
                case 2:
                    return "是";
                    break;
                case 3:
                    return "否";
                    break;
            }
        },
        format_startAirPortOptions(val) {
            switch (Number(val)) {
                case 1:
                    return "全部";
                    break;
                case 2:
                    return "限定";
                    break;
            }
        },
        format_shareAirlineType(val) {
            switch (Number(val)) {
                case 1:
                    return "全部";
                    break;
                case 2:
                    return "限定";
                    break;
                case 3:
                    return "不适用";
                    break;
            }
        },
    },
    methods: {
        async init() {
            const __this = this;
            this.loading = true;
            await this.reqDetail();

            // if (this.detail.agreement.dcCompanyArgee.rateplanActiveList.length > 0) {
            //     this.changeRrateplanList(this.detail.agreement.dcCompanyArgee.rateplanActiveList)
            // }
        },
        async reqDetail() {
            const __this = this;
            consumerweb_company_queryCompanyInvoiceType().then((res) => {
                this.invoiceType = res.datas;
            });
            let [err, res] = await awaitWrap(consumer_web_company_detail({id: __this.$route.query.id}))
            if (err) {
                this.loading = false;
                // loading.close()
                return
            }
            let [configErr, configRes] = await awaitWrap(consumer_admin_company_query({bizType: 0,companyId: __this.$route.query.id, companyName: res.datas.companyName}));
            if (configErr) {
                this.loading = false;
                return;
            }
            this.matchSetDetail = configRes.datas;
            this.loading = false
            let detail = res.datas

            // const bankList = __this.form.bankList;
            const linkman = {name: '', phone: ''};
            detail.reserveLinkmanObj = detail.reserveLinkman ? JSON.parse(detail.reserveLinkman) : linkman;
            detail.contractLinkmanObj = detail.reserveLinkman ? JSON.parse(detail.contractLinkman) : linkman;
            detail.financeLinkmanObj = detail.reserveLinkman ? JSON.parse(detail.financeLinkman) : linkman;
            detail.urgencyLinkmanObj = detail.reserveLinkman ? JSON.parse(detail.urgencyLinkman) : linkman;
            if (!detail.agreement.hzCompanyArgee) {
                detail.agreement.hzCompanyArgee = {
                    enable: 0,
                    cardNumber: '',
                    groupType: 6,
                    groupTypeText: '华住',
                }
            }
            if (!detail.agreement.dcCompanyArgee) {
                detail.agreement.dcCompanyArgee = {
                    enable: 0,
                    cardNumber: '',
                    groupType: 9,
                    groupTypeText: '东呈',
                    rateplanList: [],
                    rateplanActiveList: [], // 选中的
                }
            } else {
                let rateplanActiveList = []
                if (detail.agreement.dcCompanyArgee.rateplanList) {
                    detail.agreement.dcCompanyArgee.rateplanList.forEach(value => {
                        let params = `${value.rateplanCode}-${value.rateplanLevel}`
                        rateplanActiveList.push(params)
                    })
                    detail.agreement.dcCompanyArgee.rateplanActiveList = rateplanActiveList
                } else {
                    detail.agreement.dcCompanyArgee.rateplanList = []
                    detail.agreement.dcCompanyArgee.rateplanActiveList = []
                }
            }
            if (!detail.agreement.ydCompanyArgee) {
                detail.agreement.ydCompanyArgee = {
                    enable: 0,
                    cardNumber: '',
                    ydCardNumberInfoList: [
                        {
                            ydCardNumber: '',
                            ydCardNumberName: ''
                        }
                    ],
                }
            } else {
                if (!detail.agreement.ydCompanyArgee.ydCardNumberInfoList) {
                    detail.agreement.ydCompanyArgee.ydCardNumberInfoList = [
                        {
                            ydCardNumber: '',
                            ydCardNumberName: ''
                        }
                    ]
                }
            }
            if (!detail.agreement.jjCompanyArgee) {
                detail.agreement.jjCompanyArgee = {
                    enable: 0,
                    cardNumber: '',
                    jjSignKey: ''
                }
            }

            //BUG--10589 【差旅后台】客户管理列表，已有客户开启协议配置，“折扣”默认保存为1，应该默认存为空
            if (detail.agreement.dcCompanyArgee != null && detail.agreement.dcCompanyArgee.discount == null) {
                detail.agreement.dcCompanyArgee.discount = undefined;
            }
            if (detail.agreement.hzCompanyArgee != null && detail.agreement.hzCompanyArgee.discount == null) {
                detail.agreement.hzCompanyArgee.discount = undefined;
            }
            if (detail.agreement.jjCompanyArgee != null && detail.agreement.jjCompanyArgee.discount == null) {
                detail.agreement.jjCompanyArgee.discount = undefined;
            }
            if (detail.agreement.ydCompanyArgee != null && detail.agreement.ydCompanyArgee.discount == null) {
                detail.agreement.ydCompanyArgee.discount = undefined;
            }

            try {
                let hzCompanyArgeeEnable = detail.agreement.hzCompanyArgee.enable
                let dcCompanyArgeeEnable = detail.agreement.dcCompanyArgee.enable
                let ydCompanyArgeeEnable = detail.agreement.ydCompanyArgee.enable
                let jjCompanyArgeeEnable = detail.agreement.jjCompanyArgee.enable
                if (hzCompanyArgeeEnable || dcCompanyArgeeEnable || ydCompanyArgeeEnable || jjCompanyArgeeEnable) {
                    this.serviceChargeData = true;
                    throw new Error("EndIterative");
                } else {
                    this.serviceChargeData = false;
                }
            }catch (e) {}

            detail.companyId = detail.id;
            // 初始化行业
            if (detail.industryId) {
                __this.allocation.industryType.forEach((item, index) => {
                    if (detail.industryId === item.id) {
                        detail.industryName = item.industryName;
                    }
                });
            }
            // 服务费排序
            const serverArr = [1, 2, 4, 3];
            // 服务费设置
            detail.pdProductSetList.forEach((item, index) => {
                detail.pdProductSetList[index].serviceAmount = item.serviceAmount ? item.serviceAmount + '' : '0';
                detail.pdProductSetList[index].robTicket = item.robTicket ? item.robTicket + '' : '0';
                item.sort = serverArr[index]
            });
            detail.pdProductSetList.sort((e1, e2) => {
                return e1.sort - e2.sort
            });
            // 服务费设置 end
            /*if(__this.form.agreement.agreeInfoList[1].cardGrade == null){
                __this.form.agreement.agreeInfoList[1].cardGrade = 'RackRate';
            }*/

            __this.detail = detail;
            __this.detail.accessoryUrlArr = this.detail.accessoryUrl ? JSON.parse(this.detail.accessoryUrl) : [{name:'',url:''}];

        },

        openOrCloseCard(val) {
            this.cardInfo.forEach((value, index) => {
                if (val === index) {
                    value.more = !value.more;
                } else {
                    value.more = false;
                }
            });
        },
// 上传附件 end
        read_accessory(item) {
            const eleLink = document.createElement('a');
            eleLink.href = item.url;
            eleLink.download = item.name;
            document.body.appendChild(eleLink);
            eleLink.click();
            document.body.removeChild(eleLink);
        },
        format_number(str) {
            return str
                .split("")
                .map((item) => {
                    if (/[0-9\.]/.test(item)) {
                        return item;
                    } else {
                        return "";
                    }
                })
                .join()
                .replace(/\,/g, "");
        },
        formatPnrSet(val) {
            let text = '';
            this.pnrSetOptions.forEach(value => {
                if (value.value === val) {
                    text = value.text
                }
            });
            return text
        },
        formatDCCardLevelList(val) {
            let text = '';
            this.DCCardLevelList.forEach(value => {
                if (value.value === val) {
                    text = value.text
                }
            });
            return text
        },
        formatInvoiceType(val) {
            let text = '';
            this.invoiceType.forEach(value => {
                if (value.code === val) {
                    text = value.msg
                }
            })
            return text
        },
        formatRateplan(val) {
            let text = '';
            this.rateplanOptions.forEach(value => {
                if (value.value === val) {
                    text = value.label
                }
            });
            return text
        },
        formatSettlement(val) {
            let text = '';
            this.allocation.settlementList.forEach(value => {
                if (value.code === val) {
                    text = value.msg
                }
            });
            return text
        },
        back() {
            this.$router.go(-1)
        },
    }
}

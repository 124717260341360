import _ from 'underscore';
import moment from "moment";
import history_tag_api from '@/component/admin-layout/component/history-tag/api'
import OrderLog from '@/component/orderLog/2.0.0/index.vue'
import NewChangeClause from "@/page/admin/order-management/flight/component/newChangeClause/index.vue"
import NewLuggageClause from "@/page/admin/order-management/flight/component/newLuggageClause/index.vue"
import consumer_air_ticket_fligthRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules"
import consumer_admin_flight_order_details from '@/lib/data-service/haolv-default/consumer_admin_flight_order_details'
import consumer_log_flightList from "@/lib/data-service/haolv-default/consumer_log_flightList"
import consumer_admin_flight_order_cancel from '@/lib/data-service/haolv-default/consumer_admin_flight_order_cancel'
import consumer_admin_flight_order_saveOrderRemark from '@/lib/data-service/haolv-default/consumer_admin_flight_order_saveOrderRemark'
import consumer_admin_insurance_refundInsuranceOrder from '@/lib/data-service/haolv-default/consumer_admin_insurance_refundInsuranceOrder'
import consumer_admin_insurance_orderInsuranceAgain from '@/lib/data-service/haolv-default/consumer_admin_insurance_orderInsuranceAgain'
import consumer_admin_insurance_tradeRefundInsuranceOrder from '@/lib/data-service/haolv-default/consumer_admin_insurance_tradeRefundInsuranceOrder'
import consumer_admin_insurance_checkAllowRefundInsurance from '@/lib/data-service/haolv-default/consumer_admin_insurance_checkAllowRefundInsurance'
import consumer_insurance_getEInsuranceOrderUrl from '@/lib/data-service/haolv-default/consumer_insurance_getEInsuranceOrderUrl'
import consumer_admin_insurance_cancelInsuranceOrder from '@/lib/data-service/haolv-default/consumer_admin_insurance_cancelInsuranceOrder'
import consumer_admin_flight_equity_getOrderProductInfo from '@/lib/data-service/haolv-default/consumer_admin_flight_equity_getOrderProductInfo'
import ChangeClausePopup from '@/page/admin/replace-orders/component/changeClausePopup/1.0.0/index.vue'
import PolicyTypeLabel from '@/page/admin/replace-orders/component/policyTypeLabel/index.vue'
import {awaitWrap} from "@/page/admin/replace-orders/common/unit";
import consumer_flight_order_flightsStop from '@/lib/common-service/consumer_flight_order_flightsStop'
import ChangeClause from "@/page/admin/replace-orders/component/changeClause/1.0.0";
import consumer_flight_search_queryMeals from '@/lib/data-service/haolv-default/consumer_flight_search_queryMeals'
import consumer_flight_search_queryPlaneNameByCodes from '@/lib/data-service/haolv-default/consumer_flight_search_queryPlaneNameByCodes'
import ChangeService from "@/component/change-service/index.vue";
export default {
    data() {
        const validateNumber = (rule, value, callback) => {
            const regExp = /^(0|[1-9]\d*)(\.\d{1,2})?$/
            if (regExp.test(value)) {
                if (value >= 0) {
                    callback()
                } else {
                    callback(new Error('请输入不小于0的数字'))
                }
            } else {
                callback(new Error('请输入合法数字'))
            }
        }
        return {
            orderProductInfo: {},
            dialogEnjoyMore: false,
            activeName: 'detail',
            orderNo: '',
            detailLoading: false,
            loading: false,
            detail: {},
            orderSource: '1',
            activities:[],
            ruleText: {},
            ruleLoading: false,
            ruleShow: false, // 退改签说明弹窗
            stopInfoVisible: false,
            form: {
                remark: ''
            },

            rules: {
                rebate: [
                    { required: true, message: '请输入返佣', trigger: 'blur' },
                    { validator: validateNumber, trigger: 'blur' }
                ],
                purchaseprice: [
                    { required: true, message: '请输采购价', trigger: 'blur' },
                    { validator: validateNumber, trigger: 'blur' }
                ]
            },
            formList: [],
        }
    },
    components: {ChangeClause, OrderLog, NewChangeClause, NewLuggageClause, ChangeClausePopup, PolicyTypeLabel, ChangeService},
    created() {
    },
    mounted() {
    },
    activated() {
        this.orderNo = this.$route.query.orderNo;
        this.orderSource = this.$route.query.orderSource ? this.$route.query.orderSource : '1';
        this.getDetail();
        this.getOrderLog()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterMoney(val) {
            if (val || val === 0) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return '0.00'
            }
        },
        filterDuration(val) {
            let duration = '';
            if (val != null) {
                duration = val.replaceAll('小时', 'h').replaceAll('分钟', 'm');
            }
            return duration;
        }
    },
    methods: {
        clickEnjoyMore() {
            consumer_admin_flight_equity_getOrderProductInfo({orderNo: this.orderNo}).then((res) => {
                this.orderProductInfo = res.datas;
            });
            this.dialogEnjoyMore = true;
        },
        getDetail() {
            this.detailLoading = true
            consumer_admin_flight_order_details({
                orderNo: this.orderNo
            }).then(async res => {
                let detail = res.detail


                this.orderSource = detail.orderSource;
                if (detail.changeDetails) {
                    detail.changeDetails.forEach(value => {
                        if (!value) {
                            return
                        }
                        let passengerNameList = []
                        value.passengerList.forEach(value1 => {
                            passengerNameList.push(value1.psgName)
                            let tickerNumberList = []
                            value1.segmentResultList.forEach(value2 => {
                                tickerNumberList.push(value2.newTicketNumber)
                            })
                            value1.tickerNumberList = tickerNumberList
                        })
                        value.passengerNameList = passengerNameList
                    })
                }
                if (detail.insuranceInfo) {
                    detail.insuranceInfo.forEach(value => {
                        if (value.effectTime) {
                            value.effectTime = moment(value.effectTime).format('YYYY-MM-DD HH:mm:ss')
                        }
                        if (value.failureTime) {
                            value.failureTime = moment(value.failureTime).format('YYYY-MM-DD HH:mm:ss')
                        }
                    })
                }
                if (detail.purchasingList) {
                    detail.purchasingList.forEach((value, index) => {
                        value.rebate = value.rebate !== null ? value.rebate.toString() : ''
                        value.purchaseprice = value.purchaseprice !== null ? value.purchaseprice.toString() : ''
                        if (value.information) {
                            this.formList.push(`form${index}1`)
                            this.formList.push(`form${index}2`)
                        }
                    })
                }
                if(detail.segmentList){
                    detail.segmentList.forEach(i=>{
                        i.planeName='';
                        i.hasMeals='';
                    })
                }
                this.detail = detail
                this.form.remark = detail.remark
                if (detail.segmentList) {
                    this.queryMeals();
                    this.queryPlaneNames();
                }
                console.log('最后返回的数据', this.detail)
            }).catch(() => {
                this.$message({
                    type: "error",
                    message: "获取机票订单详情失败！"
                })
            }).finally(() => {
                this.detailLoading = false
            })
        },
        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    return consumer_log_flightList({
                        orderNo: this.orderNo
                    }).then(res => {
                        // 组装组件需要的list
                        const list = _.chain(res.datas)
                            .map(function (m) {
                                // 替换名字
                                const o = {
                                    operator: m.operator,
                                    date: m.operatorTime,
                                    type: m.logSource,
                                    detail: m.logContents
                                }
                                return o
                            }).value()
                        const d = {
                            entityList: list
                        }
                        return d || {}
                    }).catch(() => {
                        return []
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },
        // 去支付
        handlePayClick(val) {
            if (val.orderSource === '1' || val.orderSource === '2') {
                // 正常支付
                this.$router.push({
                    name: "admin-flight-payment",
                    query: {
                        orderNo: val.orderNo,
                        orderType: 1,
                        pageType: 2,
                        behalfCompanyId: val.companyId
                    }
                })
            } else {
                // 线下单支付
                this.$router.push({
                    name: "admin-flight-create-order-pay",
                    query: {
                        orderNo: val.orderNo,
                        behalfCompanyId: val.companyId
                    }
                })
            }

        },
        // 取消订单
        handleCancelClick(val) {
            this.$confirm("是否确认取消订单?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                consumer_admin_flight_order_cancel({
                    orderNo: val.orderNo,
                    supplierOrderNo: val.supplierOrderNo
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '取消订单成功！'
                    })
                    this.handleBackClick()
                }).catch(() => { })

            }).catch(() => { })
        },
        // 申请改签
        handleChangeClick(val) {
            if (this.orderSource === '3' || this.orderSource === '4') {
                // admin-flight-change-offline
                this.$router.push({
                    name: "admin-flight-change-offline",
                    query: {
                        orderNo: val.orderNo,
                    }
                })
            } else {
                this.$router.push({
                    name: "admin-flight-change",
                    query: {
                        orderNo: val.orderNo,
                        evectionNo: val.evectionNo,
                        evectionType: 1
                    }
                })
            }
        },
        // 申请退票
        handleRefundClick(val) {
            if (this.orderSource === '3' || this.orderSource === '4') {
                this.$router.push({
                    name: "admin-flight-offline-refund-handle",
                    query: {
                        orderNo: val.orderNo
                    }
                })
            } else {
                this.$router.push({
                    name: "admin-flight-refund",
                    query: {
                        orderNo: val.orderNo,
                        orderStatus: 1
                    }
                })
            }
        },
        handleSubmitClick(val) {
            this.$router.push({
                name: "admin-flight-edit-submit-order",
                query: {
                    orderNo: val.orderNo,
                    type: 'submit'
                }
            })
        },
        // 返回
        handleBackClick() {
            history_tag_api.remove_tag_by_route({
                route: this.$route
            })
            this.$router.replace({
                name: "admin-flight-order-list",
                query: {
                    refresh: true
                }
            })
        },
        // 出差单类型：1.因私，2.因公
        getEvectionTypeToClass(val) {
            switch (val) {
                case (1):
                    return 'type2'
                case (2):
                    return 'type1'
                default: return ''
            }
        },
        // 订单状态
        getOrderStatusToClass(val) {
            switch (val) {
                case (23):
                    return 'status2'
                case (22):
                    return 'status1'
                default: return 'status3'
            }
        },
        // 退票退改规则
        showRefundChangeClause(val) {
            this.ruleText = {}
            this.ruleShow = true
            let data = {
                segmentNo: val.segmentNo
            }
            this.ruleLoading = true
            consumer_air_ticket_fligthRules(data).then(res => {
                this.ruleLoading = false
                this.ruleText = res.datas.rsData
            }).catch(() => {
                this.ruleLoading = false
            })
        },
        // 改签详情
        handleChangeDetailClick(val) {
            console.log(val);
            if (this.detail.orderSource == 4 || this.detail.orderSource == 3) {
                this.$router.push({
                    name: 'admin-flight-change-detail-offline',
                    query: {
                        chaOrderNo: val.changeOrderNo,
                        orderNo: val.orderNo,
                    }
                })
            } else {
                this.$router.push({
                    name: "admin-flight-change-detail",
                    query: {
                        orderNo: val.orderNo,
                        chaOrderNo: val.changeOrderNo
                    }
                })
            }
        },
        // 退票详情
        handleRefundDetailClick(val) {
            if (this.detail.orderSource === '1' || this.detail.orderSource === '2') {
                this.$router.push({
                    name: "admin-flight-refund-detail",
                    query: {
                        refOrderNo: val.refOrderNo,
                        orderNo: this.orderNo
                    }
                })
            } else {
                this.$router.push({
                    name: "admin-flight-offline-refund-detail",
                    query: {
                        refOrderNo: val.refOrderNo
                    }
                })
            }
        },
        // 政策类型文本
        getPolicyTypeTextToClassName(val) {
            switch (val) {
                case ('大客户'):
                    return 'red'
                case ('自营'):
                    return 'green'
                case ('供应商'):
                    return 'blue'
                default: return ''
            }
        },
        /*getPolicyTypeTextToClass(val) {
            //政策类型文本
            // switch (val) {
            //   case ('大客户'):
            //     return 'red'
            //   case ('自营'):
            //     return 'green'
            //   case ('供应商'):
            //     return 'blue'
            //   default: return ''
            // }
            //"supplier": "1,2,3,4,12,13,9,OPL9,SGL1,OPL6,OPL11,OPL18,OPL17,OPL20,OPL16,OPL26,OPL23,OPL30,60,FD,NFD,VARI",
            //"supplierText": "其他渠道(供应商)【平台优选】",
            // "bigCustomers": "QCYX",
            //"bigCustomersText": "企业协议(大客户)",
            //"lowPrice": "41,42,43,44,45,26,YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL1,NSTD,62",
            //"lowPriceText": "低价推荐",
            // "website": "21,22,23,24,25,6,CXF1,WS",
            //"websiteText": "航司官网(官网)",
            //"platformProprietary": "61",
            //"platformProprietaryText": "商旅优选(自营)【商旅推荐】"
            const btoList = ['1','2','3','4','12','13', '9', 'OPL9', 'SGL1','OPL6','OPL11','OPL18','OPL17','OPL20','OPL16','OPL26','OPL23','OPL30','60','FD','NFD','VARI']
            const lprList = ['41','42','43','44','45','26','YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL1','NSTD','62']
            const owList = ['21','22','23','24','25','6','CXF1','WS']
            const qcList = ['QCYX']
            const ptList = ['61']
            if (btoList.indexOf(val) > -1) {
                return 'type1'
            } else if (lprList.indexOf(val) > -1) {
                return 'type2'
            } else if (owList.indexOf(val) > -1) {
                return 'type3'
            } else if (qcList.indexOf(val) > -1) {
                return 'type4'
            } else if (ptList.indexOf(val) > -1) {
                return 'type5'
            } else {
                return 'type6'
            }
        },*/
        validateAll() {
            return new Promise((resolve, reject) => {
                if (this.formList.length === 0) {
                    resolve(true)
                    return
                }
                let formValidateList = []
                this.formList.forEach(value => {
                    const item = function() {
                        return new Promise(resolve1 => {
                            this.$refs[value].validate(valid => {
                                resolve1(valid)
                            })
                        })
                    }
                    formValidateList.push(item)
                })
                Promise.all(formValidateList).then(res => {
                    let pass = true
                    res.forEach(value => {
                        if (!value) {
                            pass = false
                        }
                    })
                    resolve(pass)
                })
            })
        },
        async submit() {
            if (this.formList.length === 0 && this.form.remark === '') {
                this.$message({
                    type: "warning",
                    message: '请输入备注'
                })
                return
            }
            let pass = await this.validateAll()
            if (!pass) {
                return
            }
            let form = JSON.parse(JSON.stringify(this.form))
            form.orderNo = this.orderNo
            if (this.formList.length > 0) {
                form.orderPrices = this.detail.purchasingList
            } else {
                form.orderPrices = null
            }
            this.detailLoading = true
            console.log(form)
            consumer_admin_flight_order_saveOrderRemark(form).then(res => {
                this.detailLoading = false
                this.$message({
                    type: "success",
                    message: '提交成功'
                })
                this.form.remark = ''
                this.getDetail()
                this.getOrderLog()
            }, error => {
                this.detailLoading = false
            }).catch(() => {
                this.detailLoading = false
            })
        },
        back() {
            //this.$router.go(-1)
            this.$router.push({
                name: 'admin-flight-order-list'
            })
        },
        async refundInsurance(val) {
            const params = {
                insuranceOrderId: val.insuranceOrderId
            }
            let [err, res] = await awaitWrap(consumer_admin_insurance_checkAllowRefundInsurance(params))
            if (err) {
                this.$message({
                    type: "warning",
                    message: '保险已生效,无法退保'
                })
                return
            }
            const h = this.$createElement;
            this.$msgbox({
                title: '确认退保',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                this.detailLoading = true;
                let data = {
                    insuranceOrderId: params.insuranceOrderId,
                    orderNo: this.orderNo,
                };
                consumer_admin_insurance_refundInsuranceOrder(data).then(res => {
                    this.detailLoading = false
                    this.$message({
                        type: "success",
                        message: '退保成功'
                    })
                    this.getDetail()
                })
            }).catch(() => {
                this.detailLoading = false
            });
        },
        cancelInsurance(val) {
            const params = {
                orderNo: this.orderNo,
                userId: val.userId,
            }
            const h = this.$createElement;
            this.$msgbox({
                title: '确认取消投保',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                this.detailLoading = true
                consumer_admin_insurance_cancelInsuranceOrder(params).then(res => {
                    this.detailLoading = false
                    this.$message({
                        type: "success",
                        message: '取消投保成功'
                    })
                    this.getDetail()
                })
            }).catch(() => {
                this.detailLoading = false
            });
        },
        reactInsurance(val) {
            const h = this.$createElement;
            this.$msgbox({
                title: '确认投保',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                const params = {
                    businessType: 1,   // 业务类型
                    userId: val.userId,
                    productCode: val.productCode,
                    orderNo: this.orderNo,
                    chaOrderNo: '',
                    segmentNo: val.segmentNo,
                };
                this.detailLoading = true;
                consumer_admin_insurance_orderInsuranceAgain(params).then(res => {
                    this.detailLoading = false;
                    this.$message({
                        type: "success",
                        message: '投保成功'
                    });
                    this.getDetail()
                }).finally(() => {
                    this.detailLoading = false;
                })
            }).catch(() => {
                this.detailLoading = false
            });
        },
        refundMoney(val) {
            let companyIdBox = '';
            this.detail.passengerList.forEach((item,index) => {
                if (val.userId == item.userId) {
                    companyIdBox = item.companyId;
                }
            });
            const h = this.$createElement;
            this.$msgbox({
                title: '确认退款',
                message: h('p', null, [
                    h('span', null, `参保人：${val.insured}`),
                    h('span', { style: 'padding-left: 30px' }, `险种：${val.insuranceType}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true
            }).then(() => {
                const params = {
                    orderNo: this.orderNo,
                    productCode: val.productCode,
                    userId: val.userId,
                    companyId: companyIdBox,
                };
                this.detailLoading = true;
                consumer_admin_insurance_tradeRefundInsuranceOrder(params).then(res => {
                    this.detailLoading = false;
                    this.$message({
                        type: "success",
                        message: '退款成功'
                    });
                    this.getDetail()
                }).finally(() => {
                    this.detailLoading = false;
                })
            }).catch(() => {
                this.detailLoading = false
            });
        },
        checkInsuranceDetail(val) {
            this.detailLoading = true
            const params = {
                insuranceOrderId: val.insuranceOrderId
            }
            consumer_insurance_getEInsuranceOrderUrl(params).then(res => {
                const url = res.datas.epolicyUrl
                if (url) {
                    window.open(url)
                }
            }).finally(() => {
                this.detailLoading = false
            })
        },
        stopInfo(val) {
            let params = {
                "rqData": {
                    "depCityCode": val.depCityCode,
                    "arrCityCode":val.arrCityCode,
                    "date":val.depDate,
                    "flightNo": val.flightNo,
                    "deptTime": val.depTime
                }
            };
            consumer_flight_order_flightsStop(params).then(res => {

                if(res.code === '000000' && res.datas!=null){
                    for (let i = 0; i < res.datas.stopInfos.length; i++) {
                        let stopInfo = res.datas.stopInfos[i];
                        if(i==2){
                            stopInfo.depTime = stopInfo.arrTime;
                        }
                        stopInfo.data = val.depDate;
                        stopInfo.size = 'large';
                        stopInfo.type = 'primary';
                        res.datas.stopInfos[i]=stopInfo;
                    }
                    this.activities= res.datas.stopInfos;
                }
                this.stopInfoVisible = true;
            })
        },
        queryMeals() {
            // let detail  = JSON.parse(JSON.stringify(this.detail))
            let segmentList = this.detail.segmentList;
            let promises = [];
            for (let i = 0; i < segmentList.length; i++) {
                let val = this.detail.segmentList[i];

                if (val.depTime) {
                    const [hours, minutes, seconds] = val.depTime.split(":");
                    val.depTime = `${hours}:${minutes}`;
                }
                if (val.depTime) {
                    const [hours, minutes, seconds] = val.arrTime.split(":");
                    val.arrTime = `${hours}:${minutes}`;
                }
                let param = {
                    flightNo: val.flightNo,
                    deptTime: val.depTime,
                    arrTime: val.arrTime
                };
                promises.push(consumer_flight_search_queryMeals(param));
            }
            if(promises.length>0){
                Promise.all(promises).then( (posts) =>{
                    for (let i = 0; i < segmentList.length; i++) {
                        let meals = posts[i].datas;
                        this.detail.segmentList[i].hasMeals = meals? '有餐食':'无餐食';
                    }
                    // this.detail = detail;
                    this.$forceUpdate();
                }).catch(function(reason){
                    console.log('出错了',reason)
                });
            }
        },
       queryPlaneNames(){
            const planeTypes = this.detail.segmentList.map(e => {
                return e.planeType
            })
            consumer_flight_search_queryPlaneNameByCodes(planeTypes).then(res=>{
                console.log("planeTypes",res);
                let planeMap = new Map();
                if(res.datas){
                    res.datas.forEach(i=>{
                        planeMap.set(i.planeType,i.planeName);
                    })
                }
                this.detail.segmentList.forEach(i=>{
                    let planeName = planeMap.get(i.planeType);
                    i.planeName = planeName? planeName:i.planeType;
                })
                this.$forceUpdate();
                console.log("detail--",this.detail);

            }).catch(err=>{
                console.log('机型查询出错了',err);
            })
        },
        // 调整服务费
        changeService() {
            let params = {
                businessType: 1,
                orderNo: this.orderNo,
                oldService: this.detail.serviceFee,
            };
            this.$refs.changeService.init(params).then(res=>{
                this.getDetail();
            }).catch((e)=>{})
        },
    }
}

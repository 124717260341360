import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/base-pagination/index.vue'

import consumer_admin_extra_initCount from '@/lib/data-service/haolv-default/consumer_admin_extra_initCount';
import consumer_admin_extra_list from '@/lib/data-service/haolv-default/consumer_admin_extra_list';
import consumer_admin_extra_action from '@/lib/data-service/haolv-default/consumer_admin_extra_action';
import consumer_admin_extra_billDetails from '@/lib/data-service/haolv-default/consumer_admin_extra_billDetails';

import DictionarySelect
    from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary-select/index.vue'
import Dictionary from 'yinzhilv-js-sdk/frontend/common-project/component/common/dictionary/dictionary.js';
import moment from "moment";

export default {
    data() {
        const validateFine = (rule, value, callback) => {
            if (this.cancelForm.fine === '' || this.cancelForm.fine === null) {
                callback(new Error('请输入罚金(单人)'));
                return;
            }
            callback();
        }
        return {
            loading: false,
            cancelS1DialogVisible: false,
            confirmDialogVisible: false,
            progressDialogVisible: false,
            cancelS2DialogVisible: false,
            curOrder: {},
            tableData: [],
            orderChannelList: [], //采购渠道
            gmtCreate: [],   //创建时间
            updateTime: [],   //操作时间
            searchForm: {
                orderNo: '',   //订单编号
                companyText: '',//客人名称
                seatNumber: '',//坐位号/确认号
                bizType: '',//业务类型
                bizName: '',//产品名称
                billStatus: '',//业务状态
                orderSource: '',//采购渠道
                companyName: '',//所属企业
                updateUser: '',//操作客服
                gmtCreateBegin: '',//创建时间-开始
                gmtCreateEnd: '',//创建时间-结束
                updateTimeBegin: '',//操作时间-开始
                updateTimeEnd: '',//操作时间-结束
            },
            pageBean: {
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            },
            cancelForm: {
                userName: '',
                fine: 0,
            },
            totalCount: 0,   //总条数
            orderSourceList: [],
            cancelRules: {
                fine: [{required: true, validator: validateFine, trigger: ['blur', 'change']},]
            },
            activeName: '-1',
            tabList: [
                {
                    name: -1,
                    label: '全部',
                    value: 0
                },
                {
                    name: 0,
                    label: '待支付',
                    value: 0
                },
                {
                    name: 1,
                    label: '待预订',
                    value: 0
                },
                {
                    name: 2,
                    label: '已预订',
                    value: 0
                },
                {
                    name: 3,
                    label: '已取消',
                    value: 0
                }
            ],
            tabNumberList: [],
            tableLoading: false,
            orderList: [],
            companyInfo: {},
            confirmForm: {},
            progressList: [],
            progressForm: {},
            visaBillList: [],
            confirmRules: {
                channel: [
                    {required: true, message: '请选择采购渠道', trigger: 'blur'},
                ],
                paymentType: [
                    {required: true, message: '请选择付款方式', trigger: 'blur'},
                ],
                paymentTime: [
                    {required: true, message: '请选择付款时间', trigger: 'blur'},
                ],
                paymentNumber: [
                    {required: true, message: '请输入付款流水号', trigger: 'blur'},
                ],

            },
            billStatusList: [
                {
                    code: -1,
                    msg: '全部'
                },
                {
                    code: 0,
                    msg: '待预订'
                },
                {
                    code: 1,
                    msg: '已预订'
                },
                {
                    code: 2,
                    msg: '已退订'
                },
                {
                    code: 3,
                    msg: '已取消'
                }
            ],
        }
    },
    components: {
        SearchBox,
        Pagination,
        DictionarySelect
    },
    created() {
        // this.initDictionarySelect();
    },
    async activated() {
        await this.getTabNum();
        await this.getOrderList();
    },
    filters: {
        filterMoney(val) {
            if (val || val === 0) {
                let money = parseFloat(val).toFixed(2)
                return money
            } else {
                return '0.00'
            }
        }
    },
    methods: {
        //初始化字段下拉框组件
        initDictionarySelect() {
            console.log(Dictionary);
            Dictionary.init(this, {
                typeGroupKey: ['tmc'],
                typeKey: [],
                parentId: 0
            });
        },

        //后台生单
        toRecordOrder() {
            this.$router.push({
                name: "admin-extra-create-order",
                query: {
                    type: 'create'
                }
            })
        },

        //获取每个tab的数值
        async getTabNum() {
            this.tabList = [];
            let res = await consumer_admin_extra_initCount();
            this.tabList = res.datas;
        },

        //获取附加订单列表
        async getOrderList() {
            try {
                this.tableLoading = true;
                const res = await consumer_admin_extra_list({ent: this.searchForm, pageBean: this.pageBean});
                this.orderList = res.datas.list;
                if(this.orderList) {
                    let bizTypeText = '';
                    let orderSourceText = '';
                    this.orderList.forEach(async (item) => {
                        orderSourceText = await Dictionary.returnDictionary('tmc', 'extraOrderSource', item.orderSource);
                        this.$set(item, 'orderSourceText', orderSourceText.name);
                        bizTypeText = await Dictionary.returnDictionary('tmc', 'extraBizType', item.bizType);
                        this.$set(item, 'bizTypeText', bizTypeText.name);
                    })
                }
                this.totalCount = res.datas.totalCount;
            } catch (error) {
                this.orderList = [];
                this.totalCount = 0;
                this.$message({
                    type: "error",
                    message: "获取附加业务订单列表失败！"
                })
            } finally {
                this.$nextTick(() => {
                    this.$refs.mainTable.doLayout()
                })
                this.tableLoading = false
            }
        },

        //搜索
        onSearch() {
            this.searchForm.status = this.activeName == -1 ? '' : this.activeName
            this.pageBean.currentPage = 1
            this.getOrderList()
        },

        //重置
        onReset() {
            this.searchForm = {
                orderNo: '',   //订单编号
                companyText: '',//客人名称
                seatNumber: '',//坐位号/确认号
                bizType: '',//业务类型
                bizName: '',//产品名称
                billStatus: '',//业务状态
                orderSource: '',//采购渠道
                companyName: '',//所属企业
                updateUser: '',//操作客服
                gmtCreateBegin: '',//创建时间-开始
                gmtCreateEnd: '',//创建时间-结束
                updateTimeBegin: '',//操作时间-开始
                updateTimeEnd: '',//操作时间-结束
            };
            this.pageBean = {
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            };
            this.gmtCreate = [];  //创建时间
            this.updateTime = [];   //操作时间
            this.onSearch()
        },

        //修改创建时间
        changeGmtCreate(val) {
            if (!val) {
                this.searchForm.gmtCreateBegin = 0;
                this.searchForm.gmtCreateEnd = 0;
            } else {
                this.searchForm.gmtCreateBegin = val[0] + ' 00:00:00';
                this.searchForm.gmtCreateEnd = val[1] + ' 23:59:59';
            }
        },

        //修改操作时间
        changeUpdateTime(val) {
            if (!val) {
                this.searchForm.updateTimeBegin = 0;
                this.searchForm.updateTimeEnd = 0;
            } else {
                this.searchForm.updateTimeBegin = val[0] + ' 00:00:00';
                this.searchForm.updateTimeEnd = val[1] + ' 23:59:59';
            }
        },

        //设置出差单类型样式
        getEvectionTypeToClass(val) {
            //出差单类型：1.因私，2.因公
            switch (val) {
                case (1):
                    return 'grey'
                case (2):
                    return 'orange'
                default:
                    return ''
            }
        },

        //查看订单
        handleDetailClick(val) {
            this.$router.push({
                name: 'admin-extra-order-detail',
                query: {
                    id: val.id,
                    orderNo: val.orderNo
                }
            });
        },

        //取消订单
        handleCancelClick(val) {
            this.$confirm("确定取消此附加业务订单？", "取消提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.loading = true;
                let params = {
                    id: val.id,
                    orderNo: val.orderNo,
                    status: 3
                };
                consumer_admin_extra_action(params).then((res) => {
                    this.$message({
                        type: 'success',
                        message: '取消订单成功！'
                    })
                    this.getOrderList()
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                })
            }).catch(() => {
            })
        },

        //去支付
        handlePayClick(val) {
            this.$router.push({
                name: "admin-extra-create-order-pay",
                query: {
                    id: val.id,
                    orderNo: val.orderNo,
                    behalfCompanyId: val.companyId
                }
            })
        },

        //编辑
        handleEditClick(val) {
            this.$router.push({
                name: "admin-extra-create-order",
                query: {
                    type: 'edit',
                    id: val.id
                }
            })
        },

        //确认操作-显示弹框
        async onShowConfirmDialog(row) {
            let confirmForm2 = JSON.parse(JSON.stringify(row));
            let params = {
                id: row.id,
                orderNo: row.orderNo
            };
            confirmForm2.tableData = [];
            const res = await consumer_admin_extra_billDetails(params);
            res.datas.forEach((item) => {
                confirmForm2.tableData.push({id: item.id, userName: item.userName, ticketNumber: item.ticketNumber, seatNumber: ''});
            });
            console.log(confirmForm2.tableData);
            confirmForm2.type = '1';
            confirmForm2.paymentType = confirmForm2.paymentType || 'ALIPAY';
            confirmForm2.paymentTime = confirmForm2.paymentTime || moment().format('yyyy-MM-DD HH:mm:ss');
            this.confirmForm = confirmForm2;
            this.confirmDialogVisible = true;
        },

        //确认退订s1-提交
        cancelS1Submit() {
            let salesPriceTotal = 0;
            this.cancelForm.ids = [];
            this.selectedUserList.forEach(user => {
                salesPriceTotal += user.salesPrice;
                this.cancelForm.ids.push(user.id);
            });
            this.cancelForm.refundAmount = salesPriceTotal - (this.cancelForm.fine * this.selectedUserList.length);
            this.cancelS1DialogVisible = false;
            this.cancelS2DialogVisible = true;
        },

        //确认退订s2-提交
        async cancelS2Submit() {
            try {
                let params = {
                    ids: this.cancelForm.ids,
                    fine: this.cancelForm.fine,
                    refundAmount: this.cancelForm.refundAmount
                };
                await consumer_admin_extra_cancelBill(params);
                this.$message({
                    type: "success",
                    message: '退订成功'
                });
                this.cancelS2DialogVisible = false;
            } catch (e) {
                this.cancelS2DialogVisible = false;
            }
        },

        //提交确定操作
        async confirmSubmit() {
            this.$refs['confirmForm'].validate(async (valid) => {
                if(valid) {
                    try {
                        this.loading = true;
                        let params = {};
                        if (this.confirmForm.type === '1') {
                            this.$refs['confirmForm'].validate(async (valid) => {
                                if (valid) {
                                    let billInfo = [];
                                    this.confirmForm.tableData.forEach((item) => {
                                        billInfo.push({id: item.id, seatNumber: item.seatNumber});
                                    });
                                    params = {
                                        id: this.confirmForm.id,
                                        orderNo: this.confirmForm.orderNo,
                                        status: 2,
                                        supOrderNo: this.confirmForm.supOrderNo,
                                        paymentType: this.confirmForm.paymentType || 0,
                                        paymentTime: this.confirmForm.paymentTime,
                                        paymentNum: this.confirmForm.paymentNumber,
                                        remark: this.confirmForm.remark,
                                        billInfo: billInfo
                                    };
                                    await consumer_admin_extra_action(params);
                                    this.$message.success("保存成功");
                                    this.confirmDialogVisible = false;
                                    await this.getTabNum();
                                    await this.getOrderList();
                                    this.loading = false;
                                }
                            })
                        } else {
                            params = {
                                id: this.confirmForm.id,
                                orderNo: this.confirmForm.orderNo,
                                remark: this.confirmForm.remark,
                                refundAmount: this.confirmForm.orderMoney,
                                status: 3,
                            };
                            await consumer_admin_extra_action(params);
                            this.$message.success("取消预订成功");
                            this.issueDialogVisible = false;
                            await this.getTabNum();
                            await this.getOrderList();
                            this.loading = false;
                        }
                    } catch (e) {
                        this.issueDialogVisible = false;
                        this.loading = false;
                    }
                }
            })
        }
    }
}

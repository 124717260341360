import extend from 'extend';
import Q from 'q';
import _ from 'underscore';

import aRouter from "../index";
import all_routes_processor from "./all_routes_processor";
import global_query_processor from "./global_query_processor";

const init_route_guard = function () {
    const get_all_route_list = all_routes_processor.get_all_route_list;

    aRouter.beforeEach((to, from, next) => {
        try {
            global_query_processor.next({to, from, next});
        } catch (ex) {
            console.error(ex);
        }
    });
};
export default init_route_guard;

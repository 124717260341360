// consumer_trains_order_behalfTrainOrderV2
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-alpha.haolvtrip.com/doc.html#/yinzhilv-tmc/火车票-订单操作/behalfTrainOrderUsingPOST
const consumer_trains_order_behalfTrainOrderV2 = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/order/behalfTrainOrderV2',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_order_behalfTrainOrderV2;
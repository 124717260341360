const __request = require(`./__request/__request_contentType_json`);
// 火车退款订单列表
// http://219.128.52.2:18765/doc.html?tdsourcetag=s_pctim_aiomsg#/haolv-biz/%E5%B9%B3%E5%8F%B0%E7%81%AB%E8%BD%A6%E8%AE%A2%E5%8D%95/orderRefundListUsingPOST
const consumer_admin_trains_order_orderRefundList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/admin/trains/order/orderRefundList',
        data: pParameter
    };
    return new Promise(function (resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_admin_trains_order_orderRefundList;
const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 差旅平台客户管理列表接口文档地址：http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-company-controller/pageListUsingPOST_1
const consumer_web_company_pageList = (pParameter) => {
  if (!pParameter) pParameter = {};

  const p = {
    method: 'post',
    urlSuffix: '/consumer/web/company/pageList',
    data: pParameter,
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;
};
module.exports = consumer_web_company_pageList;

// consumer_air_ticket_confirmAirChange
const __request = require(`./__request/__request_contentType_json`)
const consumer_air_ticket_confirmAirChange = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/air/ticket/confirmAirChange',
        data: data
    }
    return __request(pParameter)
}
export default consumer_air_ticket_confirmAirChange